import React from 'react';
import QRCode from 'qrcode';
import { pdf } from '@react-pdf/renderer';
import { RegionLocale } from 'src/app/i18n';
import { EventItem } from '../../../../types';
import { QRCodePDF } from '../components/qr-code-pdf.component';

export const downloadQRCodePDF = async (qrCodeContent: string, eventItem: EventItem) => {
  const qrCodeDataURL = await QRCode.toDataURL(qrCodeContent);
  const blob = await pdf(
    <QRCodePDF qrCodeDataURL={qrCodeDataURL} eventName={eventItem.name[RegionLocale.ENGLISH]} />,
  ).toBlob();
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = `${eventItem.name[RegionLocale.ENGLISH]}_QR.pdf`;
  link.click();
};
