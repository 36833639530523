// for production env

import { BDMdashboardName, LMdashboardName, TopDashboardDetail } from './dashboard-types';

//please revise all dashboardLeft,dashboardRight,chartID into uat
//notice :
//'Both' -> both are numbers
//'Among' -> one number and one table
//'Unique' -> only one table

export enum LostReportTop {
  type = 'Both',
  dashboardLeft = '62e80a26-41d9-4e11-8e7f-5b0ac5e73277',
  dashboardRight = '6267bd98-d717-4757-8bff-7d34608e2384',
}

export enum SubmissionReportTop {
  type = 'Both',
  dashboardLeft = '1d9a81b7-6c42-4a5f-b2f2-ff42604f3c24',
  dashboardRight = '8f2af670-2ef7-454d-b3f6-4e927ef5e5b8',
}

export enum SubsourceTop {
  type = 'Both',
  dashboardLeft = '6273854d-2c86-442a-8275-5b28174f1bbd',
}

export enum AssignedTodayTop {
  type = 'Both',
  dashboardLeft = 'db45b716-bffb-4f64-8323-ed00315c9bb8',
}

export enum WeekCreateTop {
  type = 'Both',
  dashboardLeft = '6274f2de-c2b3-41a1-83a1-70e4924fc069',
}

export enum AverageTop {
  type = 'Both',
  dashboardLeft = '6274ea1f-f164-469e-8d95-81f43ee918de',
}

export enum StatusTop {
  type = 'Among',
  dashboardLeft = '62739ce3-94a3-44e5-84e0-071509249dba',
  dashboardRight = '62739a5d-c2b3-4483-84b0-70e492764a26',
}

export enum PerformanceTop {
  type = 'Among',
  dashboardLeft = '6273900f-2c86-4e2a-8779-5b2817527640',
  dashboardRight = '3d51dd35-dfe9-421e-91f6-b27e73ab343a',
}

export enum LostReasonTop {
  type = 'Unique',
  dashboardLeft = '62733279-8d58-42c8-893c-0a94cc74661f',
}

export const TopDashboardsProd: Map<string, any> = new Map<BDMdashboardName, TopDashboardDetail>([
  [BDMdashboardName.LOSTREPORT, LostReportTop],
  [BDMdashboardName.SUBMIISSIONREPORT, SubmissionReportTop],
  [BDMdashboardName.SUBSOURCE, SubsourceTop],
  [BDMdashboardName.ASSIGNEDTODAY, AssignedTodayTop],
  [BDMdashboardName.WEEKCREATE, WeekCreateTop],
  [BDMdashboardName.AVERAGE, AverageTop],
  [BDMdashboardName.STATUS, StatusTop],
  [BDMdashboardName.SOURCE, PerformanceTop],
  [BDMdashboardName.LOSTREASON, LostReasonTop],
]);

export const LMdashboardDataProd: Map<string, any> = new Map<LMdashboardName, any>([
  [LMdashboardName.PARTICIPANTS, '6460e833-2da3-41f3-808a-6df9ee85fcf4'],
  [LMdashboardName.CAMPAIGN, '6460e833-2da3-4883-8119-6df9ee85fcf6'],
  [LMdashboardName.LEADS, '6460e833-2da3-4f82-8add-6df9ee85fcfa'],
]);

export const BDMdashboardDataProd = '6461459c-6cd4-40c8-812c-d817f4d794d4';

export const DashboardLibraryProd = {
  report: [
    {
      chartName: 'List of Participants',
      chartID: '6460e833-2da3-4a41-832e-6df9ee85fcf1',
      chartType: 'Table',
    },
    {
      chartName: 'List of Campaign',
      chartID: '6460e833-2da3-42ad-89e3-6df9ee85fced',
      chartType: 'Table',
    },
    {
      chartName: 'List of Leads',
      chartID: '6460e833-2da3-4dae-85b9-6df9ee85fcef',
      chartType: 'Table',
    },
    {
      chartName: 'Appointment to Lost Report',
      chartID: '64614289-6cd4-43a6-8743-d817f4d60623',
      chartType: 'Table',
    },
    {
      chartName: 'Appointment to Lost Report',
      chartID: '64614289-6cd4-4b4c-8f6c-d817f4d60625',
      chartType: 'Chart',
    },
    {
      chartName: 'Appointment to Submission Report',
      chartID: '646142f2-ed0f-4462-8326-19f687f63e6a',
      chartType: 'Table',
    },
    {
      chartName: 'Appointment to Submission Report',
      chartID: '646142f2-ed0f-480c-8650-19f687f63e6c',
      chartType: 'Chart',
    },
    {
      chartName: 'Cumulative Leads Aging Report',
      chartID: '6460f890-6cd4-4af8-890d-d817f49fa9f1',
      chartType: 'Table',
    },
    {
      chartName: 'Cumulative Leads Aging Report',
      chartID: '6460f890-6cd4-4e60-8118-d817f49fa9ef',
      chartType: 'Chart',
    },
    {
      chartName: 'Leads Aging Report - Engagement',
      chartID: '6460f86c-2da3-4ebf-8795-6df9ee95e08d',
      chartType: 'Table',
    },
    {
      chartName: 'Leads Aging Report - Engagement',
      chartID: '6460f86c-2da3-4e73-81bd-6df9ee95e08b',
      chartType: 'Chart',
    },
    {
      chartName: 'Leads Aging Report - Pending Call',
      chartID: '6460f858-c8d5-4d0d-84a6-98fcf11186ef',
      chartType: 'Table',
    },
    {
      chartName: 'Leads Aging Report - Pending Call',
      chartID: '6460f858-c8d5-4ed8-80c9-98fcf11186ed',
      chartType: 'Chart',
    },
    {
      chartName: 'Leads Aging Report - Submission',
      chartID: '6460f87d-6e2f-464f-8939-41609f617a97',
      chartType: 'Table',
    },
    {
      chartName: 'Leads Aging Report - Submission',
      chartID: '6460f87d-6e2f-4be2-8133-41609f617a95',
      chartType: 'Chart',
    },
    {
      chartName: 'Dropped-Off Report',
      chartID: '64613e59-ed26-4a64-8ef4-d7a9766e33ad',
      chartType: 'Table',
    },
    {
      chartName: 'Dropped-Off Report',
      chartID: '64613e59-ed26-4d4a-80f3-d7a9766e33af',
      chartType: 'Chart',
    },
    {
      chartName: 'Lead Lost Reason Report',
      chartID: '627e1c20-4e6c-4f30-8944-752b4011d461',
      chartType: 'Table',
    },
    {
      chartName: 'Lead Lost Reason Report',
      chartID: '626a5a58-5899-42d5-861a-f60be6099147',
      chartType: 'Chart',
    },
    {
      chartName: 'Lead Stage Report',
      chartID: '64613dca-d4d9-4cf7-89e9-2dd970455574',
      chartType: 'Table',
    },
    {
      chartName: 'Lead Stage Report',
      chartID: '64613dca-d4d9-428c-84ea-2dd970455572',
      chartType: 'Chart',
    },
    {
      chartName: 'Lead Stage by Consultants',
      chartID: '64613ef4-5837-4159-83c4-055a4e2e0424',
      chartType: 'Table',
    },
    {
      chartName: 'Lead Stage by Consultants',
      chartID: '64613ef4-5837-4d7c-80c4-055a4e2e0422',
      chartType: 'Chart',
    },
    {
      chartName: 'Leads Report by Sub Source',
      chartID: '64613d48-8373-4035-8b93-365aef41985e',
      chartType: 'Table',
    },
    {
      chartName: 'Leads Report by Sub Source',
      chartID: '64613d48-8373-47e0-8b52-365aef41985c',
      chartType: 'Chart',
    },
    {
      chartName: 'Leads Assigned Today by Source',
      chartID: '00a9f905-b8ca-476f-ab50-c7bee5c797e4',
      chartType: 'Table',
    },
    {
      chartName: 'Leads Assigned Today by Source',
      chartID: '62738a79-c2b3-4d07-89ca-70e492715b80',
      chartType: 'Chart',
    },
    {
      chartName: 'Leads Create Per Month',
      chartID: '64613fa9-c04b-4717-8392-0eeea733c476',
      chartType: 'Table',
    },
    {
      chartName: 'Leads Create Per Month',
      chartID: '64613fa9-c04b-4960-886b-0eeea733c474',
      chartType: 'Chart',
    },
    {
      chartName: 'Average Conversion Time Report',
      chartID: '6274f450-2e4a-4b45-883b-d81646a94385',
      chartType: 'Table',
    },
    {
      chartName: 'Conversion Performance Report by Source',
      chartID: '6460f422-c04b-47f4-8395-0eeea7fb5b7e',
      chartType: 'Table',
    },
    {
      chartName: 'Conversion Performance Report by Source',
      chartID: '6460f422-c04b-47d7-8d40-0eeea7fb5b7c',
      chartType: 'Chart',
    },
    {
      chartName: 'Conversion performance by Lead Assigned Date',
      chartID: '6460f4ae-ed0f-4ba5-8811-19f687bc04d1',
      chartType: 'Chart',
    },
    {
      chartName: 'Conversion performance by Lead Assigned Date',
      chartID: '6460f4ae-ed0f-4755-8c7b-19f687bc04d3',
      chartType: 'Table',
    },
    {
      chartName: 'Leads Create Per Month - phase 2',
      chartID: '6e4bf812-c05a-4311-8b16-b5d7e0393ba9',
      chartType: 'Chart',
    },
    {
      chartName: 'Leads Create Per Month - phase 2',
      chartID: 'a3236853-e8fe-4879-8e39-c54393886500',
      chartType: 'Table',
    },
    {
      chartName: 'Conversion Performance by Last Updated Date',
      chartID: '6460f56a-fdc5-4b93-8a32-b4de58f075cc',
      chartType: 'Chart',
    },
    {
      chartName: 'Conversion Performance by Last Updated Date',
      chartID: '6460f56a-fdc5-421c-8a94-b4de58f075ca',
      chartType: 'Table',
    },
    {
      chartName: 'Assigned to Call Report',
      chartID: '64614415-6e2f-43e5-8220-41609f975625',
      chartType: 'Chart',
    },
    {
      chartName: 'Assigned to Call Report',
      chartID: '64614415-6e2f-413e-8725-41609f975627',
      chartType: 'Table',
    },
    {
      chartName: 'Average Conversion Days by Consultants',
      chartID: '6460f61a-5837-4e10-83b0-055a4ef88a73',
      chartType: 'Chart',
    },
    {
      chartName: 'Average Conversion Days by Consultants',
      chartID: '6460f61a-5837-4945-8ab2-055a4ef88a71',
      chartType: 'Table',
    },
    {
      chartName: 'Top Consultants by Lead Capture',
      chartID: '6461447e-6e2f-4bf5-8827-41609f97a453',
      chartType: 'Chart',
    },
    {
      chartName: 'Top Consultants by Lead Capture',
      chartID: '6461447e-6e2f-4974-8251-41609f97a455',
      chartType: 'Table',
    },
    {
      chartName: 'Unaccepted Leads by Consultants',
      chartID: '6461401c-d4d9-4d26-8ba5-2dd97046d46a',
      chartType: 'Chart',
    },
    {
      chartName: 'Unaccepted Leads by Consultants',
      chartID: '6461401c-d4d9-4b0f-8f17-2dd97046d46d',
      chartType: 'Table',
    },
  ],
};
