import React, { FC, useState } from 'react';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { useIntl } from 'react-intl';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useDispatch } from 'react-redux';
import { useDataProvider } from 'src/app/common/utils';
import { PaginateList } from 'src/app/common/types/common-types';
import { AmdEmailItem } from 'src/app/modules/Recruit/types/amd-email-types';
import { AmdEmailListParam, fetchAmdEmailList } from 'src/app/modules/Recruit/network/amdEmailCrud';
import AmdEmailList from './components/AmdEmailList';

type AmdEmailListingPageProps = {} & ParamsProps;

const initialState: AmdEmailListParam = {
  page: 1,
  limit: 5,
};

export const amdEmailFilterKeys = ['sourceName'];

const paramsInitiator = (initialParams?: Record<string, string>): AmdEmailListParam => {
  return initialParams
    ? {
        page: 1,
        limit: 5,
      }
    : initialState;
};

const AmdEmailListingPage: FC<AmdEmailListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const [filterState, setFilterState] = useState<AmdEmailListParam>(paramsInitiator(initialParams));
  const [amdEmailList, setAmdEmailList] = useState<PaginateList<AmdEmailItem>>();

  const { isLoading, refreshData } = useDataProvider<PaginateList<AmdEmailItem>>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return (await fetchAmdEmailList(filterState, dispatch)).result;
      } catch (err) {}
    },
    setAmdEmailList,
    false,
  );
  return (
    <>
      <AmdEmailList
        isLoading={isLoading}
        amdEmailList={amdEmailList}
        onRefresh={refreshData}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default AmdEmailListingPage;
