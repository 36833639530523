import React, { FC, HTMLAttributes } from 'react';
import { makeStyles } from 'tss-react/mui';
import { FormControl, Select, MenuItem, FormHelperText } from '@mui/material';
import { useIntl } from 'react-intl';
import { MANDATORY_PRIORITY_ERROR_TEXT } from '../../constants';
import { ErrorState } from '../../utils';

export type DropdownItemDef = {
  displayText: string;
  value: string;
};

export type HierachyLayerDef = {
  name: string;
  mandatory?: boolean;
};

type HierachyDropdownProps = {
  disabled?: boolean;
  fieldContainerCSS?: string;
  disableAfterChosen?: boolean;
  layers: HierachyLayerDef[];
  selectedItems: string[];
  dropdownList: DropdownItemDef[];
  onChangeDropdown: (result: string[]) => void;
  errorState?: ErrorState;
  onDismissErrorHandler?: (field: string, value: any) => void;
  errorTextDetail?: string[];
};

const useStyles = makeStyles()((theme) => ({
  subHeader: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
  },
  divideMargin: {
    marginBottom: 10,
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  fieldContainer: {
    width: 160,
    boxSizing: 'border-box',
  },
}));

const HierachyDropdown: FC<HierachyDropdownProps & HTMLAttributes<HTMLDivElement>> = ({
  disabled,
  fieldContainerCSS,
  disableAfterChosen,
  layers,
  selectedItems,
  dropdownList,
  errorState,
  errorTextDetail,
  onDismissErrorHandler,
  onChangeDropdown,
  ...rest
}) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const onChange = (value: string, currentLayer: number) => {
    let newArr = [...selectedItems];
    const optionItem = dropdownList.find((item) => item.value === value);
    if (optionItem !== undefined) {
      const item = newArr[currentLayer - 1];
      if (!item) {
        newArr.push(value);
      } else {
        newArr[currentLayer - 1] = value;
      }
    } else {
      newArr = newArr.filter((_, index) => index < currentLayer - 1);
    }
    newArr = newArr.splice(0, currentLayer);
    onChangeDropdown(newArr);
  };

  return (
    <div {...rest}>
      {layers.map((layer, index) => {
        const currentLayer = index + 1;
        const isDisplayRow = currentLayer === 1 || (currentLayer > 1 && selectedItems[index - 1]);
        return (
          isDisplayRow && (
            <div key={`criteria-${currentLayer}`} className={classes.rowContainer} style={{ marginBottom: 10 }}>
              <div className={fieldContainerCSS || classes.fieldContainer}>
                <span className={classes.field}>{layer.name}</span>
              </div>
              <FormControl
                style={{ margin: 0 }}
                margin="dense"
                variant="outlined"
                error={errorState?.mandatory[`criteriaPriority`]}
              >
                <Select
                  disabled={disabled}
                  style={{ minWidth: 200 }}
                  value={selectedItems[index] || Translation('common.dropdown.emptyValue')}
                  onChange={(e) => {
                    if (onDismissErrorHandler) {
                      onDismissErrorHandler(
                        `criteriaPriority`,
                        e.target.value !== Translation('common.dropdown.emptyValue'),
                      );
                    }
                    onChange(e.target.value as string, currentLayer);
                  }}
                >
                  <MenuItem key={'option-default'} value={Translation('common.dropdown.emptyValue')}>
                    {Translation('common.dropdown.emptyValue')}
                  </MenuItem>
                  {dropdownList
                    .filter(
                      (dropdown) =>
                        !!!selectedItems.find((item, itemIndex) => itemIndex < index && item === dropdown.value),
                    )
                    .map((dropdown) => (
                      <MenuItem key={`option-${dropdown.value}`} value={dropdown.value}>
                        {dropdown.displayText}
                      </MenuItem>
                    ))}
                </Select>
                {errorState?.mandatory[`criteriaPriority`] && (
                  <FormHelperText>
                    {MANDATORY_PRIORITY_ERROR_TEXT}
                    {errorTextDetail && `: ${errorTextDetail.join(', ')}`}
                  </FormHelperText>
                )}
              </FormControl>
            </div>
          )
        );
      })}
    </div>
  );
};

export default HierachyDropdown;
