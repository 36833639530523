import { makeStyles } from 'tss-react/mui';

export const commonStyles = {
  ruleSetting: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  colorRed: {
    color: 'red',
  },
  ml70: {
    marginLeft: 70,
  },
  list: {
    marginLeft: 160,
    marginTop: 10,
  },
  mt20: {
    marginTop: 20,
  },
  groupRuleArea: {
    paddingTop: 10,
    marginLeft: 20,
  },
  groupRuleTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  ml50: {
    marginLeft: 50,
  },
  nameInput: {
    width: '100%',
    marginRight: 80,
  },
  desArea: {
    paddingBottom: 20,
    marginLeft: 20,
  },
  desInput: {
    width: '100%',
    marginRight: 80,
  },
  maxPointArea: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'space-between',
    alignContent: 'flex-start',
    flex: 1,
    marginLeft: 20,
  },
  maxPointTitle: {
    width: 150,
    marginRight: 10,
  },
  minW150: {
    minWidth: 150,
  },
  minW100: {
    minWidth: 100,
  },
  suggestedPointArea: {
    display: 'flex',
    alignItems: 'baseline',
    marginLeft: 20,
    marginBottom: 20,
  },
  flex_row: {
    display: 'flex',
    flexDirection: 'row' as 'row',
  },
  pointTitleWidth: {
    width: 150,
    paddingTop: 15,
  },
  titleWidth: {
    width: 150,
    display: 'flex',
    alignItems: 'center',
  },
  backButton: {
    marginLeft: 10,
    marginBottom: 10,
    marginTop: 20,
  },
  Area: {
    marginLeft: 60,
    marginRight: 10,
  },
  tableMargin: {
    marginLeft: 10,
    marginTop: 10,
  },
  border: {
    borderBottom: 'none',
  },
  buttonGroup: {
    marginBottom: 15,
    marginTop: 15,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  mr15: {
    marginRight: 15,
  },
  tableBg: {
    marginLeft: 20,
    marginRight: 50,
    marginBottom: 20,
  },
  addButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 10,
  },
  mb10: {
    marginBottom: 10,
  },
  w200: {
    width: 200,
  },
  w300: {
    width: 300,
  },
  flex_row_center: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    alignItems: 'center',
  },
  mr15_mt15: {
    marginRight: 15,
    marginTop: 15,
  },
  flex_flexStart_mt5: {
    display: 'flex',
    alignSelf: 'flex-start',
    marginTop: 5,
  },
  flex_flexStart: {
    display: 'flex',
    alignSelf: 'flex-start',
  },
  ml20: {
    marginLeft: 20,
  },
  flex_row_ml50: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    marginLeft: 50,
  },
  w150: {
    width: 150,
  },
  groupActionPoint: {
    marginLeft: 230,
    width: '',
    position: 'undefined',
  },
  groupActionCount: {
    width: '',
    position: 'undefined',
  },
  defaultInput: {
    minWidth: 100,
    backgroundColor: 'white',
  },
  disabledInput: {
    minWidth: 100,
    backgroundColor: '#D9D9D9',
  },
};

export const useStyles = makeStyles()({
  moveBtn: {
    margin: '5px',
    width: '16px',
    height: '16px',
    borderRadius: '50%',
    borderWidth: '1px',
    borderColor: 'black',
    borderStyle: 'solid',
    cursor: 'pointer',
  },
  actionBtns: {
    width: '80px',
    textAlign: 'left',
    marginLeft: '15px',
  },
  closeIcon: {
    marginLeft: '5px',
    verticalAlign: 'middle',
  },
  firstRowBorder: {
    borderLeft: '1px solid black',
    borderBottom: '1px solid black',
  },
  midRowBorder: {
    borderBottom: '1px solid black',
  },
  lastRowBorder: {
    borderRight: '1px solid black',
    borderBottom: '1px solid black',
  },
});
