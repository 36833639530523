import { DesignationItem, AgentSegmentItem } from 'src/app/common/types';
import { TargetApplicantMode } from './common-types';
import { SelectModeFormState } from '../select-mode';

export enum TargetApplicantTypeEnum {
  ALL = 'ALL',
  DESIGNATIONS = 'DESIGNATIONS',
  AGENTS = 'AGENTS',
  SEGMENTS = 'SEGMENTS',
}

export type TargetApplicantSelectModeProps = {
  mode: TargetApplicantMode.SELECT;
  isDisabled: boolean;
  module: string;
  designationList: DesignationItem[];
  agentSegmentList: AgentSegmentItem[];
  initialFormState: SelectModeFormState;
  onChange: (newFormState: SelectModeFormState) => void;
};
