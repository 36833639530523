import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { FormControlLabel, TextField } from '@mui/material';
import { CheckCircle, RadioButtonUnchecked } from '@mui/icons-material';
import { MANDATORY_FIELD_ERROR_TEXT } from 'src/app/common/constants';
import AcmCheckbox from 'src/app/modules/AgencyCampaign/common/AcmCheckbox';
import { useStyles } from 'src/app/modules/AgencyCampaign/pages/CampaignType/Form/CampaignTypeDetailPage';
import { SectionFieldItem, OperatorEnum } from 'src/app/modules/AgencyCampaign/types/campaign-type-types';

type ValidationDaysRuleProps = {
  field: SectionFieldItem;
  ruleIndex: number;
  setField: (field: SectionFieldItem) => void;
  setErrorState: (index: number, hasError: boolean) => void;
};

const ValidationDaysRule: FC<ValidationDaysRuleProps> = ({ field, ruleIndex, setField, setErrorState }) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const rule = field.rules[ruleIndex];

  const [enableInputDays, setEnableInputDays] = useState<boolean>(
    (rule.value as number) > 0 || (field.key === 'campaignEndDate' && rule.operator !== OperatorEnum.EQ),
  );
  const [inputDays, setInputDays] = useState(rule.value);

  const renderDaysStr = () => {
    let validationDateStr = '';
    switch (rule.dependsOn) {
      case 'submitDate':
        validationDateStr = 'submit date(exclude weekends)';
        break;
      case 'campaignStartDate':
        validationDateStr = 'start date';
        break;
      default:
        break;
    }
    return intl.formatMessage(
      { id: 'agencyCampaign.campaignType.section.editField.daysCompareToValidationDate' },
      {
        compareTo: rule.operator === OperatorEnum.LTE ? 'before' : 'after',
        validationDateStr,
      },
    );
  };

  return (
    <div className={classes.sectionMargin}>
      <div>
        {ruleIndex + 1}. {Translation(`agencyCampaign.field.${field.key}`)}{' '}
        {Translation('agencyCampaign.campaignType.section.editField.shouldBe')}
      </div>
      <div>
        <FormControlLabel
          style={{ margin: '2px 12px 2px 0' }}
          control={
            <AcmCheckbox
              icon={<RadioButtonUnchecked />}
              checkedIcon={<CheckCircle />}
              checked={enableInputDays}
              onChange={(e) => {
                if (e.target.checked) {
                  setErrorState(ruleIndex, inputDays === '');
                  const newField = { ...field };
                  if (field.key === 'campaignEndDate') {
                    newField.rules[ruleIndex].operator = OperatorEnum.GTE;
                  }
                  newField.rules[ruleIndex].value = Number(inputDays);
                  setField(newField);
                  setEnableInputDays(true);
                }
              }}
            />
          }
          label={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                style={{ width: 80, margin: '4px 0' }}
                type="number"
                disabled={!enableInputDays}
                margin="dense"
                variant="outlined"
                error={enableInputDays && inputDays === ''}
                value={inputDays}
                onChange={(e) => {
                  setErrorState(ruleIndex, e.target.value === '');
                  const newField = { ...field };
                  newField.rules[ruleIndex].value = Number(e.target.value);
                  setField(newField);
                  setInputDays(e.target.value);
                }}
              />
              <span style={{ margin: '0 12px' }}>{renderDaysStr()}</span>
            </div>
          }
          labelPlacement="end"
        />
        {field.key !== 'campaignEndDate' ? (
          <FormControlLabel
            style={{ margin: '2px 0' }}
            control={
              <AcmCheckbox
                icon={<RadioButtonUnchecked />}
                checkedIcon={<CheckCircle />}
                checked={!enableInputDays}
                onChange={(e) => {
                  if (e.target.checked) {
                    setErrorState(ruleIndex, false);
                    const newField = { ...field };
                    newField.rules[ruleIndex].value = 0;
                    setField(newField);
                    setEnableInputDays(false);
                  }
                }}
              />
            }
            label={Translation('app.checkbox.no')}
            labelPlacement="end"
          />
        ) : (
          <FormControlLabel
            style={{ margin: '2px 0' }}
            control={
              <AcmCheckbox
                icon={<RadioButtonUnchecked />}
                checkedIcon={<CheckCircle />}
                checked={!enableInputDays}
                onChange={(e) => {
                  if (e.target.checked) {
                    setErrorState(ruleIndex, false);
                    const newField = { ...field };
                    newField.rules[ruleIndex].operator = OperatorEnum.EQ;
                    newField.rules[ruleIndex].value = 0;
                    setField(newField);
                    setEnableInputDays(false);
                  }
                }}
              />
            }
            label={intl.formatMessage(
              { id: 'agencyCampaign.campaignType.section.editField.sameDateAsValidationDate' },
              { validationDateStr: 'start date' },
            )}
            labelPlacement="end"
          />
        )}
      </div>
      {enableInputDays && inputDays === '' && (
        <div className={classes.errorText} style={{ paddingLeft: 37.47 }}>
          {MANDATORY_FIELD_ERROR_TEXT}
        </div>
      )}
    </div>
  );
};

export default ValidationDaysRule;
