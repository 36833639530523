export const PULSELEADS_STRAIGHT_THROUGH_TITLE = 'title.pulseleads.straightThrough';
export const PULSELEADS_STRAIGHT_THROUGH_EVENT_CAMPAIGN_TITLE = 'title.pulseleads.straightThrough.eventCampaign';
export const PULSELEADS_STRAIGHT_THROUGH_PRODUCT_CAMPAIGN_TITLE = 'title.pulseleads.straightThrough.productCampaign';
export const PULSELEADS_STRAIGHT_THROUGH_CHANNEL_CAMPAIGN_TITLE = 'title.pulseleads.straightThrough.channelCampaign';
export const PULSELEADS_STRAIGHT_THROUGH_STAGING_LEAD_TITLE = 'title.pulseleads.straightThrough.stagingLead';

export const PULSELEADS_STRAIGHT_THROUGH_PATH = '/straight-through';
export const PULSELEADS_STRAIGHT_THROUGH_EVENT_CAMPAIGN_PATH = '/event-campaign';
export const PULSELEADS_STRAIGHT_THROUGH_PRODUCT_CAMPAIGN_PATH = '/product-campaign';
export const PULSELEADS_STRAIGHT_THROUGH_CHANNEL_CAMPAIGN_PATH = '/channel-campaign';
export const PULSELEADS_STRAIGHT_THROUGH_STAGING_LEAD_PATH = '/staging-lead';

export const PULSELEADS_STRAIGHT_THROUGH_EVENT_CAMPAIGN_NAMESPACE = 'PULSELEADS-STRAIGHT-THROUGH-EVENT-CAMPAIGN';
export const PULSELEADS_STRAIGHT_THROUGH_PRODUCT_CAMPAIGN_NAMESPACE = 'PULSELEADS-STRAIGHT-THROUGH-PRODUCT-CAMPAIGN';
export const PULSELEADS_STRAIGHT_THROUGH_CHANNEL_CAMPAIGN_NAMESPACE = 'PULSELEADS-STRAIGHT-THROUGH-CHANNEL-CAMPAIGN';
export const PULSELEADS_STRAIGHT_THROUGH_STAGING_LEAD_NAMESPACE = 'PULSELEADS-STRAIGHT-THROUGH-STAGING-LEAD';
