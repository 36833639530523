import { PaginateList } from '../../../../../common/types/common-types';

export enum CategoryPublishedMode {
  ALL = 'All',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}

export enum CategoryLevel {
  ONE = 'one',
  TWO = 'two',
  THREE = 'three',
}

export type CategoryListParam = {
  pageSize?: number;
  page?: number;
  isActive?: string;
  module?: string;
};

export type CategoryItem = {
  createdAt: Date;
  id: string;
  localizations?: localeItem[];
  name: string;
  publishedAt: Date;
  updatedAt: Date;
  lastUpdatedBy: string;
  description: string;
  module?: string;
  children?: any;
  childrenItem?: Object | CategoryItem[];
  parent?: Object | string;
  level?: string;
};

export type CategoryEditItem = {
  createdAt?: Date;
  created_by?: Date;
  id?: string;
  locale?: string;
  localizations?: string[];
  publishedAt?: Date;
  updatedAt?: Date;
  module: string;
  name: string;
  description: string;
  parent?: string;
  lastUpdatedBy?: string;
  level?: string;
};

export type CategoryFormState = {
  category: string;
  subCategory: string;
  thirdCategory: string;
  description: string;
  // lastUpdatedBy?: string;
  level?: string;
};

export type localeItem = {
  id: string;
  locale: string;
  publishedAt: Date;
  _id: string;
};

export type CategoryPaginateList = PaginateList<CategoryItem> & { categoryList: CategoryItem[]; totalRecords: number };

export interface AllCategories {
  [key: string]: string | { id: string; children: AllCategories };
}
