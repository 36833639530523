import React, { FC, useState, useEffect } from 'react';
import { useCommonStyles } from '../../../../../common/styles/common-styles';
import { makeStyles } from 'tss-react/mui';
import { FormControl, Select, TextField, Button, FormHelperText } from '@mui/material';
import { useIntl } from 'react-intl';
import { ProductionTargetType } from '../../../types/production-target-types';
import useFilterStyles from '../../../../../common/styles/filter-styles';
import { map } from 'lodash';
import { PruDatePicker, MonthOnlyCalendarHeader } from 'src/app/common/components/PruDatePicker';
import { GetProductionTargetParams } from '../../../network/productionCrud';
import { useErrorHandler, ErrorFieldType, preFillZero } from 'src/app/common/utils';
import { MANDATORY_FIELD_ERROR_TEXT } from '../../../constants';
import { ParamsProps } from '../../../../../common/components/ParamsProvider';

type SetTargetSearchProps = {
  searchData: (params: GetProductionTargetParams) => void;
} & ParamsProps;

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  mandatory: {
    color: 'red',
  },
}));

type SearchParams = {
  target: string;
  targetRef: string;
  year: Date | null;
  month: Date | null;
};

const initialState: SearchParams = {
  target: '',
  targetRef: '',
  year: null,
  month: null,
};

type ErrorState = {
  mandatory: {
    target: boolean;
    targetRef: boolean;
    year: boolean;
  };
  immediate: {};
};

const paramsInitiator = (initialParams?: Record<string, string>): SearchParams => {
  return initialParams
    ? {
        target: initialParams.target ?? '',
        targetRef: initialParams.targetRef ?? '',
        year: initialParams.year ? new Date(initialParams.year) : null,
        month: initialParams.month ? new Date(initialParams.month) : null,
      }
    : initialState;
};

const SetTargetSearch: FC<SetTargetSearchProps> = ({ initialParams, searchData }) => {
  const { classes } = useStyles();
  const { classes: filterClasses } = useFilterStyles();
  const { classes: commonClasses } = useCommonStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const [searchState, setSearchState] = useState<SearchParams>(paramsInitiator(initialParams));

  useEffect(() => {
    if (initialParams && initialParams.target && initialParams.targetRef && initialParams.year) {
      onSearch();
    }
    // eslint-disable-next-line
  }, []);

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler } = useErrorHandler<ErrorState>(searchState, [
    {
      name: 'target',
      fieldType: ErrorFieldType.MANDATORY,
    },
    {
      name: 'targetRef',
      fieldType: ErrorFieldType.MANDATORY,
    },
    {
      name: 'year',
      fieldType: ErrorFieldType.MANDATORY,
    },
  ]);

  const onSearch = () => {
    const { hasError } = onSubmitErrorValidator();
    if (!hasError) {
      let { target, targetRef, year, month } = searchState;
      if (target === ProductionTargetType.AGENT) {
        targetRef = preFillZero(targetRef);
      }
      searchData({
        target: target as ProductionTargetType,
        targetRef,
        year: String(year?.getFullYear()),
        month: month ? String(month.getMonth() + 1) : undefined,
      });
    }
  };

  return (
    <div style={{ marginBottom: 20 }} className={filterClasses.root}>
      <div className={classes.headerContainer}>
        <div className={commonClasses.header}>{Translation('productionTarget.search')}</div>
      </div>
      <div className="PruFilter-row" style={{ marginBottom: 20 }}>
        <span className="PruFilter-criteria">
          {Translation('productionTarget.common.target')} <span className={classes.mandatory}>*</span> :
        </span>
        <FormControl error={errorState.mandatory.target} style={{ marginRight: 20 }} margin="dense" variant="outlined">
          <Select
            native
            error={errorState.mandatory.target}
            value={searchState.target}
            onChange={(e) => {
              onDismissErrorHandler('target', e);
              setSearchState({
                ...searchState,
                target: e.target.value as string,
              });
            }}
          >
            <option aria-label="None" value="" />
            {map(ProductionTargetType, (option: string) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Select>
          {errorState.mandatory.target && <FormHelperText>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>}
        </FormControl>
        <span className="PruFilter-criteria">
          {Translation('productionTarget.common.targetRef')} <span className={classes.mandatory}>*</span> :
        </span>
        <TextField
          error={errorState.mandatory.targetRef}
          style={{ marginRight: 20 }}
          margin="dense"
          variant="outlined"
          value={searchState.targetRef}
          helperText={errorState.mandatory.targetRef && MANDATORY_FIELD_ERROR_TEXT}
          onChange={(e) => {
            onDismissErrorHandler('targetRef', e);
            setSearchState({
              ...searchState,
              targetRef: e.target.value as string,
            });
          }}
        />
        <span className="PruFilter-criteria">
          {Translation('productionTarget.common.year')} <span className={classes.mandatory}>*</span> :
        </span>
        <PruDatePicker
          slotProps={{
            textField: {
              error: errorState.mandatory.year,
              helperText: errorState.mandatory.year && MANDATORY_FIELD_ERROR_TEXT,
              style: { marginRight: 20, width: 160 },
            },
          }}
          views={['year']}
          format="YYYY"
          value={searchState.year}
          onChange={(e) => {
            onDismissErrorHandler('year', e);
            setSearchState({
              ...searchState,
              year: e,
            });
          }}
        />
        <span className="PruFilter-criteria">{Translation('productionTarget.common.month')} :</span>
        <PruDatePicker
          slots={{ calendarHeader: MonthOnlyCalendarHeader }}
          slotProps={{
            textField: {
              style: { width: 160 },
            },
          }}
          views={['month']}
          format="MMMM"
          value={searchState.month}
          onChange={(e) => {
            setSearchState({
              ...searchState,
              month: e,
            });
          }}
        />
      </div>
      <div className="PruFilter-row">
        <Button variant="contained" color="secondary" onClick={onSearch}>
          {Translation('app.button.search')}
        </Button>
      </div>
    </div>
  );
};

export default SetTargetSearch;
