import React, { FC, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import CategoryList from './components/CategoryList';
import { FeedbackCategoryPaginateList } from '../../types/feedback-type';
import { fetchFeedbackCategoryList, FeedbackCategoryListParam } from '../../network/feedbackCrud';
import { useDataProvider } from 'src/app/common/utils';
import { useDispatch } from 'react-redux';

const initialFilterState: FeedbackCategoryListParam = {
  page: 1,
  limit: 5,
};

const FeedbackCategoryPage: FC<RouteComponentProps> = () => {
  const dispatch = useDispatch();
  const [filterState, setFilterState] = useState(initialFilterState);
  const [feedbackCategoryList, setFeedbackCategoryList] = useState<FeedbackCategoryPaginateList>();

  const { isLoading, refreshData } = useDataProvider<FeedbackCategoryPaginateList>(
    async () => {
      try {
        return await fetchFeedbackCategoryList(filterState, dispatch);
      } catch (err) {}
    },
    setFeedbackCategoryList,
    false,
  );

  return (
    <CategoryList
      isLoading={isLoading}
      onRefresh={refreshData}
      feedbackCategoryList={feedbackCategoryList}
      onChangePage={(page, rowsPerPage) => {
        setFilterState({
          ...filterState,
          page,
          limit: rowsPerPage,
        });
        refreshData();
      }}
    />
  );
};

export default FeedbackCategoryPage;
