import { useState, useCallback } from 'react';
import { find } from 'lodash';
import moment from 'moment';
import { RegionLocale } from 'src/app/i18n';

export enum ErrorFieldType {
  MANDATORY = 'mandatory',
  IMMEDIATE = 'immediate',
}

type FormState = Record<string, unknown>;

export type ErrorState = {
  mandatory: Record<string, boolean>;
  immediate: Record<string, boolean>;
};

export type ErrorFieldDef = {
  name: string;
  fieldType: ErrorFieldType;
  validateDate?: boolean; // only check immediate fieldType
  condition?: () => boolean;
};

export type DismissErrorHandler = (field: string | string[], value: any) => void;

const errorStateInitialiser = <T extends ErrorState>(errorFieldDef: ErrorFieldDef[]): T => {
  const errorState = {
    mandatory: {},
    immediate: {},
  } as T;
  errorFieldDef.forEach((field) => {
    if (!errorState[field.fieldType][field.name]) {
      errorState[field.fieldType][field.name] = false;
    }
  });
  return errorState;
};

type ErrorHandler<T extends ErrorState> = {
  errorState: T;
  onSubmitErrorValidator: () => { hasError: boolean; currentErrorState: T };
  onDismissErrorHandler: (field: keyof T['mandatory'] | (keyof T['mandatory'])[], value: any) => void;
  immediateErrorValidator: () => void;
};

export const useErrorHandler = <T extends ErrorState>(
  formState: FormState,
  errorFieldDef: ErrorFieldDef[],
): ErrorHandler<T> => {
  const [errorState, setErrorState] = useState<T>(errorStateInitialiser<T>(errorFieldDef));

  const onSubmitErrorValidator = () => {
    const mandatory: ErrorState['mandatory'] = {};

    errorFieldDef.forEach((def) => {
      if (def.fieldType === ErrorFieldType.MANDATORY) {
        if (def.condition !== undefined) {
          mandatory[def.name] = def.condition();
        } else {
          mandatory[def.name] =
            typeof formState[def.name] === 'boolean' ? formState[def.name] === undefined : !!!formState[def.name];
        }
      }
    });

    setErrorState({
      ...errorState,
      mandatory,
    });
    return {
      hasError: !!!(
        find(mandatory, (status) => status === true) === undefined &&
        find(errorState.immediate, (status) => status === true) === undefined
      ),
      currentErrorState: {
        ...errorState,
        mandatory,
      },
    };
  };

  const onDismissErrorHandler = useCallback(
    (field: keyof T['mandatory'] | (keyof T['mandatory'])[], value: any) => {
      if (value) {
        const newErrorState = { ...errorState };
        if (field instanceof Array) {
          field.forEach((key) => {
            newErrorState.mandatory = {
              ...newErrorState.mandatory,
              [key]: false,
            };
          });
        } else {
          newErrorState.mandatory = {
            ...newErrorState.mandatory,
            [field]: false,
          };
        }
        setErrorState(newErrorState);
      }
    },
    [errorState],
  );

  const immediateErrorValidator = () => {
    const immediate: ErrorState['immediate'] = {};

    errorFieldDef.forEach((def) => {
      if (def.fieldType === ErrorFieldType.IMMEDIATE) {
        if (def.condition !== undefined) {
          immediate[def.name] = def.condition();
        } else if (def.validateDate) {
          immediate[def.name] = !!formState[def.name] && !moment(formState[def.name] as any).isValid();
        } else {
          immediate[def.name] = false;
        }
      }
    });

    setErrorState({
      ...errorState,
      immediate,
    });
  };

  return {
    errorState,
    onSubmitErrorValidator,
    onDismissErrorHandler,
    immediateErrorValidator,
  };
};

export const dismissTinymceError = <T extends Record<string, any>>(
  formState: T,
  field: keyof T & string,
  onDismissErrorHandler: DismissErrorHandler,
  regionLocale?: RegionLocale[],
) => {
  if (regionLocale) {
    const dismissFields: string[] = [];
    regionLocale.forEach((locale) => {
      if (formState[field][locale]) {
        dismissFields.push(`${field}-${locale}`);
      }
    });
    onDismissErrorHandler(dismissFields, true);
  } else {
    if (formState[field]) {
      onDismissErrorHandler(`${field}`, formState[field]);
    }
  }
};
