import React from 'react';
import { getDefaultDisplayDate } from 'src/app/common/utils';
import { displayPublishStatus } from './utils';

interface tableConfigType {
  title: string;
  prop: string;
  align?: any;
  render?: any;
  minWidth?: number;
  sorter?: boolean | false;
  order?: 'asc' | 'desc';
}

const tableConfig = (Translation: any, locale: any): tableConfigType[] => {
  return [
    {
      title: Translation('component.formLabel.name'),
      prop: 'name',
    },
    {
      title: Translation('component.formLabel.publish-status'),
      prop: 'publishStatus',
      render: (row: any) => <span>{displayPublishStatus(row.publishStatus, Translation)?.label}</span>,
    },
    {
      title: Translation('form.pulseleads.engagement.topic.effective.period.start'),
      prop: 'startTime',
      render: (row: any) => <span>{row.startTime ? getDefaultDisplayDate(row.startTime, 'datetime') : '-'}</span>,
    },
    {
      title: Translation('form.pulseleads.engagement.topic.effective.period.end'),
      prop: 'endTime',
      render: (row: any) => <span>{row.endTime ? getDefaultDisplayDate(row.endTime, 'datetime') : '-'}</span>,
    },

    {
      title: Translation('component.formLabel.last-updated-time'),
      sorter: true,
      order: 'desc',
      prop: 'updatedAt',
      render: (row: any) => <span>{row.updatedAt ? getDefaultDisplayDate(row.updatedAt, 'datetime') : '-'}</span>,
    },
    {
      title: Translation('component.formLabel.last-updated-by'),
      prop: 'lastUpdatedBy',
    },
  ];
};

export default tableConfig;
