import { FC } from 'react';
import { FormMode } from 'src/app/common/types';
import SurveyCreator from 'src/app/common/components/event-survey-creator/event-survey-creator.component';
import { ComponentProps } from 'src/app/common/components/pru-stepped-form';
import { EventFormCommonProps } from 'src/app/modules/event-v2/types';
import { useRegistrationForm } from './registration-form.hook';

type EventRegistrationFormProps = ComponentProps<EventFormCommonProps>;

export const EventRegistrationForm: FC<EventRegistrationFormProps> = ({ formCommonProps, ...rest }) => {
  const { formMode, isPublished, disableEdit, eventItem } = formCommonProps;
  const { surveyData, onSubmit } = useRegistrationForm({
    formCommonProps,
    ...rest,
  });
  const viewMode = formMode === FormMode.CREATE || formMode === FormMode.VIEW;

  return (
    <SurveyCreator
      content={surveyData}
      onChangeContent={onSubmit}
      module="event"
      saveVisible={!viewMode && !isPublished && !disableEdit}
      publishStatus={eventItem?.publishStatus}
    />
  );
};
