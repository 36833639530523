import moment from 'moment';
import { Dispatch } from 'react';
import { apiErrorHandler } from 'src/app/common/network';
import axiosUtil from 'src/app/common/network/axios';
import { PaginateList } from 'src/app/common/types';
import { AlertType, appendAlertItem } from 'src/redux/common/commonSlice';
import { ApplicationFormEditHistoryListParam } from './types/edit-history-types';

export const utcToLocalDate = (date: any): string => {
  if (date) {
    return moment.utc(date).local().format('DD/MM/YYYY HH:mm');
  }
  return date;
};

export const uppercaseToTitlecase = (str: string) => {
  return str.toLowerCase().replace(/(^|\s)\w/g, (match) => {
    return match.toUpperCase();
  });
};
// translate DD/MM/YYYY to YYYY-MM-DD if date is DD/MM/YYYY
export const dateFormatToServer = (date: string) => {
  if (date && date.includes('/')) {
    return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
  }
  return date;
};

// translate   YYYY-MM-DD to DD/MM/YYYY if date is YYYY-MM-DD
export const dateFormatToLocal = (date: string) => {
  if (date && date.includes('-')) {
    return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
  }
  return date;
};

export const formatIsPresent = (isPresent: boolean) => {
  return isPresent ? 'Yes' : 'No';
};

export enum YesAndNo {
  yes = 'true',
  no = 'false',
}

export const csvReader = (file: File): Promise<string[][]> => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      let csvData = String(reader.result);
      csvData = csvData.replace(/ï»¿/g, '');
      csvData = csvData.replace(/\r/g, '');
      const rows = csvData.split('\n');
      resolve(rows.map((row) => row.split(',')));
    };
    if (file) {
      reader.readAsBinaryString(file);
    }
  });
};

interface ResWithMessage extends PaginateList<ApplicationFormEditHistoryListParam> {
  message: string;
  statusCode: string;
}

export const handleDowloadFileError = (err: any, dispatch: Dispatch<any>) => {
  //read blob error message to text
  const reader = new FileReader();
  reader.readAsText(err as Blob, 'utf-8');
  reader.onload = function () {
    try {
      // format text to json object
      const json = JSON.parse(reader.result as any);
      if (json?.statusCode) {
        const resWithMessage = json as ResWithMessage;
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.ERROR,
              title: resWithMessage.statusCode,
              content: resWithMessage.message,
            },
          ]),
        );
      } else {
        return;
      }
    } catch (err) {
      console.error(err);
    }
  };
};
/** get next-day date.
 * example: assume current date is: 05/15/2024, then the result is: 05/16/2024 00:00
 */
export function getNextDay(): Date {
  const currentDate = new Date(); // Get the current date and time

  currentDate.setDate(currentDate.getDate() + 1); // Add 1 day to the current date
  currentDate.setHours(0, 0, 0, 0); // Set the time to 00:00:00
  return currentDate;
}
