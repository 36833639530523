import { makeStyles } from 'tss-react/mui';

export const useProfileStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  sectionContainer: {
    marginBottom: 30,
  },
  fieldContainer: {
    width: 130,
    boxSizing: 'border-box',
  },
  longFieldContainer: {
    width: 160,
    boxSizing: 'border-box',
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },

  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  centerFooterContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorText: {
    fontSize: 9,
    color: '#F018A6',
  },
  downloadTemplateBtn: {
    color: 'blue',
    cursor: 'pointer',
    textDecoration: 'underline',
    whiteSpace: 'nowrap',
  },
  chooseFileBtn: {
    marginRight: 25,
  },
  divideMargin: {
    marginBottom: 10,
  },
  materialName: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '10px',
    paddingLeft: 0,
  },
  subText: {
    fontSize: '14px',
    fontWeight: 400,
    marginLeft: '10px',
  },
  mandatory: {
    color: 'red',
    fontSize: '14px',
    fontWeight: 400,
  },
  closeIcon: {
    marginLeft: '5px',
    verticalAlign: 'middle',
  },
  group: {
    margin: '0 10px',
    marginRight: 0,
  },
  radio: {
    margin: 0,
    marginRight: 10,
  },
  sectionMargin: {
    marginBottom: 15,
  },
  textArea: {
    lineHeight: 1.5,
    minHeight: 80,
  },
  imageContainer: {
    width: 160,
    // height: 160,
    overflow: 'hidden',
    borderRadius: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },
  selectPadding: {
    margin: '0 10px 0 0',
  },
}));
