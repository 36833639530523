import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { PaginateList } from 'src/app/common/types/common-types';
import { useDataProvider } from 'src/app/common/utils';
import { AwardListParam, fetchAgentAwardList } from 'src/app/modules/Profile/network/agentProfileCrud';
import { AwardListItem, awardTypeEnum } from 'src/app/modules/Profile/types/agent-profile-types';
import { useLang } from 'src/app/i18n';

import AwardList from './components/AwardList';

type AwardListingPageProps = ParamsProps;

const initialState: any = {
  agentName: '',
  agentCode: '',
  awardCode: '',
  awardYear: '',
  awardNameEn: '',
  awardName: '',
  awardNameLocal: '',
  type: '',
  page: 1,
  limit: 5,
};

export const agentAwardFilterKeys = [
  'name',
  'awardCode',
  'awardCode',
  'awardYear',
  'awardNameLocal',
  'awardNameEn',
  'awardName',
  'type',
];

const paramsInitiator = (initialParams?: Record<string, string>): any => {
  return initialParams
    ? {
        agentName: initialParams.agentName || '',
        agentCode: initialParams.agentCode || '',
        awardCode: initialParams.awardCode || '',
        awardYear: initialParams.awardYear || '',
        awardNameLocal: initialParams.awardNameLocal || '',
        awardNameEn: initialParams.awardNameEn || '',
        awardName: initialParams.awardName || '',
        type: initialParams.type || '',
        page: 1,
        limit: 5,
      }
    : initialState;
};
export const AwardListingPage: FC<AwardListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const lang = useLang();
  const [filterState, setFilterState] = useState<AwardListParam>(paramsInitiator(initialParams));
  const [awardList, setAwardList] = useState<PaginateList<AwardListItem>>();
  const { isLoading, refreshData } = useDataProvider<PaginateList<AwardListItem>>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchAgentAwardList(filterState, lang, dispatch);
      } catch (err) {}
    },
    setAwardList,
    false,
  );

  return (
    <>
      <PruFilter
        title={Translation('agentProfile.ad_agent_awards_filter')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'agentName',
            initialValue: filterState.agentName,
            displayName: Translation('agentProfile.ad_agent_name_label'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'agentCode',
            initialValue: filterState.agentCode,
            displayName: Translation('agentProfile.ad_agent_code_label'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'awardName',
            initialValue: filterState.awardName,
            displayName: Translation('agentProfile.ad_award_name_label'),
          },

          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'awardCode',
            initialValue: filterState.awardCode,
            displayName: Translation('agentProfile.ad_award_code_label'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'awardYear',
            initialValue: filterState.awardYear,
            displayName: Translation('agentProfile.ad_awarded_year_label'),
          },

          {
            type: PruFilterItemType.DROPDOWN,
            field: 'type',
            initialValue: filterState.type,
            displayName: Translation('agentProfile.ad_type_label'),
            choices: [
              { displayName: '', value: '' },
              ...Object.entries(awardTypeEnum).map(([key, value]) => ({
                displayName: Translation(`dropdown_label_${value}`),
                value: value,
              })),
            ],
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            agentName: data.agentName,
            awardCode: data.awardCode,
            agentCode: data.agentCode,
            awardYear: data.awardYear,
            awardName: data.awardName,
            type: data.type,
            // awardNameEn: data.awardNameEn,
            // awardNameLocal: data.awardNameLocal,
          })
        }
        fetchData={refreshData}
      />

      <AwardList
        isLoading={isLoading}
        awardList={awardList}
        onRefresh={refreshData}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};
