import { makeStyles } from 'tss-react/mui';
import { DialogProps, ModalProps } from '@mui/material';
import React from 'react';
import { ModalView } from './modal-view';

export interface dModalType {
  visible?: boolean;
  message?: string;
  duration?: number;
}

export interface CloseModalProps {
  reopenPromise?: Promise<void>;
}

export interface PruModalProps extends Omit<DialogProps, 'onClose' | 'open'> {
  open: boolean;
  isTransparent: boolean;
  onClose: ModalProps['onClose'];
  title?: string;
  children?: any;
  subModalProps?: SubModalProps;
  customHeader?: JSX.Element;
}

export interface SubModalProps {
  subModalOpen: boolean;
  onSubClose: () => void;
  title?: string;
  subModalChildren?: any;
}
export interface ModalStyle {
  // containerStyle?: ViewStyle;
  // dialogStyle?: ViewStyle;
}
export const ModalServiceContext = React.createContext<{
  close: (options: CloseModalProps) => any;
  open: (options: PruModalProps) => Promise<void>;
  openSubModal: (options: { title: string; children: JSX.Element }) => void;
}>({ close: () => null, open: Promise.reject, openSubModal: () => {} });

let modalStateRef: any;
export const ModalServiceProvider = ({ children }: any) => {
  const [modalState, setModalState] = React.useState<PruModalProps | null>(null);
  const [hidden, setHidden] = React.useState(false);

  const awaitingPromiseRef = React.useRef<{
    resolve: (resolveData?: any) => void;
    reject: (rejectData?: any) => void;
  }>();

  const closeModal = ({ reopenPromise }: CloseModalProps) => {
    if (!reopenPromise) {
      if (modalStateRef && awaitingPromiseRef.current) {
        awaitingPromiseRef.current.resolve();
      }
      setModalState(null);
      return;
    }
    setHidden(true);
    reopenPromise?.then?.(
      () => {
        setHidden(false);
      },
      () => {
        if (modalStateRef && awaitingPromiseRef.current) {
          awaitingPromiseRef.current.resolve();
        }
        setModalState(null);
      },
    );
    return () => {
      setHidden(false);
    };
  };

  const openModal = (options: PruModalProps | any) => {
    setModalState(options);
    setHidden(false);
    modalStateRef = options;
    return new Promise<any>((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const openSubModal = ({ children }: any) => {
    modalStateRef = {
      ...modalStateRef,
      children: modalStateRef?.children,
      subModalProps: {
        subModalOpen: true,
        onSubClose: () => {
          modalStateRef = {
            ...modalStateRef,
            children: modalStateRef?.children,
            subModalProps: { ...modalStateRef?.subModalProps, subModalOpen: false },
          };
          setModalState(modalStateRef);
        },
        // onSubClose: () => {},
        subModalChildren: children,
      },
    };
    setModalState(modalStateRef);
  };

  const handleClose = (event: any, result: any) => {
    if (modalState && awaitingPromiseRef.current) {
      if (event?.type === 'reject') {
        awaitingPromiseRef.current.reject(result);
      } else {
        awaitingPromiseRef.current.resolve(result);
      }
    }
    setHidden(false);
    if (!modalState?.subModalProps?.subModalOpen) {
      setModalState(null);
    }
  };

  return (
    <>
      <ModalServiceContext.Provider value={{ close: closeModal, open: openModal, openSubModal: openSubModal }}>
        {children}
        <ModalView open={Boolean(modalState)} isTransparent={hidden} onClose={handleClose} {...modalState} />
      </ModalServiceContext.Provider>
    </>
  );
};

export const useStyles = makeStyles()((theme) => ({
  container: {
    position: 'absolute',
  },
}));
