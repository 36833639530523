import React, { FC, useState, useMemo, useRef, useEffect } from 'react';
import SurveyCreator from '../../../../common/components/survey-v2/survey-creator';
import { useCommonStyles } from '../../../../../app/common/styles/common-styles';
import { Button, CircularProgress, IconButton, MenuItem, Select, Tooltip } from '@mui/material';
import { useTranslation } from 'src/app/common/hook/translation.hook';
import { ErrorComponent } from 'src/app/common/components/error/error.component';

export type ComponentProps = {
  templateType: string;
  onSurveyUpdate: (surveyJson: any) => void;
  surveyJson?: any;
  readOnly: boolean;
};

export const templateTypeTranslation = 'Recruitment.onboarding_type_';

export const OnboardingApplicationPage: FC<ComponentProps> = ({
  templateType,
  surveyJson,
  readOnly = false,
  onSurveyUpdate,
}) => {
  // const dispatch = useDispatch();
  const { classes: commonClasses } = useCommonStyles();
  const t = useTranslation();
  const updateSurveyContent = (json: any) => {
    onSurveyUpdate({ content: json });
  };

  if (!surveyJson) {
    return <ErrorComponent content={t('component.form-required-text')} />;
  }

  return (
    <div className="tw-w-full mx-auto tw-h-full tw-overflow-y-hidden">
      <div className="tw-w-full tw-flex tw-justify-start tw-items-center tw-mb-4 tw-p-4  tw-bg-white tw-rounded-md">
        <div className={`${commonClasses.header} tw-pl-1`}>Application Form Setting</div>
        <div className="tw-ml-auto tw-flex-row"></div>
      </div>
      <div className="tw-w-full tw-h-5/6" id="onboarding">
        <SurveyCreator
          data={{ content: surveyJson }}
          onModifiedContent={updateSurveyContent}
          module="recruitment"
          type={templateType}
          readOnly={readOnly}
        />
      </div>
    </div>
  );
};
