import { AgentSegmentListParam } from 'src/app/common/network';
import { TargetApplicantSelectModeProps } from './select-mode-types';
import { TargetApplicantRadioModeProps } from './radio-mode-types';

export enum TargetApplicantMode {
  SELECT = 'SELECT',
  RADIO = 'RADIO',
}

type TargetApplicantPropsByMode =
  | Omit<TargetApplicantSelectModeProps, 'designationList' | 'agentSegmentList'>
  | Omit<TargetApplicantRadioModeProps, 'designationList' | 'zoneList'>;

export type TargetApplicantSectionProps = {
  agentSegmentListParams?: AgentSegmentListParam;
} & TargetApplicantPropsByMode;
