import React, { FC, useState } from "react";
import { makeStyles } from 'tss-react/mui';
import { Dialog, Button } from '@mui/material';
import { appendAlertItem, AlertType } from "../../../../../../redux/common/commonSlice";
import { useDispatch } from "react-redux";
import { deleteFeedbackCategory, modifyFeedbackCategory } from "../../../network/feedbackCrud";
import { FeedbackCategoryItem, FeedbackCategoryBody } from "../../../types/feedback-type";
import { useIntl } from "react-intl";

type CategoryDeleteDialogProps = {
  open: boolean;
  isSubCat: boolean;
  id: string;
  mainCatRow?: FeedbackCategoryItem;
  subCatId?: string;
  onClose: () => void;
  onRefresh: () => void;
};

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  header: {
    fontSize: '1.2rem',
    lineHeight: 1.2,
    marginBottom: 15
  },
  fieldContainer: {
    paddingTop: 5,
    flexBasis: '20%',
    minWidth: 120,
    boxSizing: 'border-box'
  },
  noPaddingFieldContainer: {
    flexBasis: '20%',
    minWidth: 120,
    boxSizing: 'border-box'
  },
  footer: {
    marginTop: 20
  },
  rowContainer: {
    display: 'flex'
  }
}));

const CategoryDeleteDialog: FC<CategoryDeleteDialogProps> = ({
  open,
  isSubCat,
  id,
  mainCatRow,
  subCatId,
  onClose,
  onRefresh
}) => {

  const { classes } = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const onDelete = async () => {
    if (isSubCat) {
      if (mainCatRow && subCatId) {
        let newSubCat = [...mainCatRow.subCategories];
        const foundIndex = newSubCat.findIndex(item => item._id === subCatId);
        if (foundIndex !== -1) {
          newSubCat.splice(foundIndex, 1);
          const body: FeedbackCategoryBody = {
            name: mainCatRow.name,
            subCategories: newSubCat
          };
          setIsDeleting(true);
          try {
            await modifyFeedbackCategory(id, body, dispatch);
            dispatch(appendAlertItem([{
              severity: AlertType.SUCCESS,
              title: 'Success',
              content: `Feedback Sub Category deleted successfully - ${subCatId}`
            }]));
            onRefresh();
            onClose();
          } catch (err) {
          } finally {
            setIsDeleting(false);
          }
        }
      }
    } else {
      if (mainCatRow) {
        setIsDeleting(true);
        try {
          await deleteFeedbackCategory(id, dispatch);
          dispatch(appendAlertItem([{
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Feedback Main Category deleted successfully - ${mainCatRow._id}`
          }]));
          onRefresh();
          onClose();
        } catch (err) {
        } finally {
          setIsDeleting(false);
        }
      }
    }
  }

  return (
    <Dialog onClose={() => !isDeleting && onClose()} open={open}>
      <div className={classes.container}>
        <div className={classes.header}>Warning</div>
        <div>{`Are you sure you want to delete this ${isSubCat ? `Sub Category` : `Main Category`} ? This action cannot be reverted.`}</div>
        <div className={classes.footer}>
          <Button disabled={isDeleting} variant="contained" onClick={onClose}>
            {Translation("app.button.cancel")}
          </Button>
          <Button disabled={isDeleting} style={{ marginLeft: 25 }} variant="contained" color="secondary" onClick={() => onDelete()}>
          {Translation("app.button.submit")}
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

export default CategoryDeleteDialog;
