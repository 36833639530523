import React, { FC, useState, useEffect, useMemo } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchDistributionRule } from 'src/app/modules/PulseLeads/network/distributionRuleCrud';
import { DistributionRule, DistributionRuleFormMode } from 'src/app/modules/PulseLeads/types/distribution-rule-types';
import { ApiErrorResponse } from 'src/app/common/network';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { LayoutSplashScreen } from 'src/app/layout';
import DistributionRuleForm from './components/DistributionRuleForm';

const DistributionRuleDetailPage: FC<RouteComponentProps> = ({ history, location }) => {
  const { id } = useParams<{ id: string }>();
  const { formMode } = useMemo(
    () => ({
      formMode: id ? DistributionRuleFormMode.EDIT : DistributionRuleFormMode.CREATE,
    }),
    [id],
  );

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [distributionRule, setDistributionRule] = useState<DistributionRule>();

  const reloadData = () => {
    if (id) {
      setIsLoading(true);
      fetchDistributionRule(id, dispatch)
        .then((result) => {
          setDistributionRule(result);
        })
        .catch((err: ApiErrorResponse) => {
          history.push(NOT_FOUND_ERROR_PAGE_PATH);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line
  }, [id]);

  return (
    <>
      {formMode === DistributionRuleFormMode.CREATE && <DistributionRuleForm formMode={formMode} />}
      {formMode === DistributionRuleFormMode.EDIT &&
        (isLoading || !distributionRule ? (
          <LayoutSplashScreen />
        ) : (
          <DistributionRuleForm formMode={formMode} distributionRule={distributionRule} />
        ))}
    </>
  );
};

export default DistributionRuleDetailPage;
