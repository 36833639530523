import { useEffect, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import { useErrorHandler, ErrorFieldType } from 'src/app/common/utils';
import { EventAssistantItem } from 'src/app/modules/event-v2/types';
import {
  CreateUpdateEventAssistantBody,
  createEventAssistantItem,
  modifyEventAssistantItem,
} from 'src/app/modules/event-v2/network';
import { EventAssistantFormState, eventAssistantFormReducer } from '../../../../reducers';

type HookProps = {
  eventId: string;
  eventAssistantItem?: EventAssistantItem;
  onClose: () => void;
  refreshEventAssistantList: () => void;
};

const detailToStateConvertor = (eventAssistantItem?: EventAssistantItem): EventAssistantFormState => {
  return eventAssistantItem
    ? {
        name: eventAssistantItem.name,
        email: eventAssistantItem.email,
      }
    : {
        name: undefined,
        email: undefined,
      };
};

export const useEventAssistantDialog = ({
  eventId,
  eventAssistantItem,
  onClose,
  refreshEventAssistantList,
}: HookProps) => {
  const dispatch = useDispatch();

  const [formState, formDispatch] = useReducer(eventAssistantFormReducer, detailToStateConvertor(eventAssistantItem));
  const { errorState, onSubmitErrorValidator, immediateErrorValidator, onDismissErrorHandler } = useErrorHandler(
    formState,
    [
      {
        name: 'name',
        fieldType: ErrorFieldType.MANDATORY,
      },
      {
        name: 'email',
        fieldType: ErrorFieldType.MANDATORY,
      },
      {
        name: 'emailFormat',
        fieldType: ErrorFieldType.IMMEDIATE,
        condition: () => {
          if (formState.email) {
            const re =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
            return !re.test(formState.email);
          } else {
            return false;
          }
        },
      },
    ],
  );

  useEffect(() => {
    immediateErrorValidator();
  }, [formState.email]);

  const onSubmit = async () => {
    const { hasError } = onSubmitErrorValidator();
    if (!hasError) {
      const body: CreateUpdateEventAssistantBody = {
        name: formState.name,
        email: formState.email,
      };
      try {
        if (!eventAssistantItem) {
          await createEventAssistantItem(eventId, body, dispatch);
        } else {
          await modifyEventAssistantItem(eventId, eventAssistantItem._id, body, dispatch);
        }
        refreshEventAssistantList();
        onClose();
      } catch (err) {}
    }
  };

  return {
    formState,
    errorState,
    formDispatch,
    onDismissErrorHandler,
    onSubmit,
  };
};
