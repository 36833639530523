import React, { FC, HTMLAttributes } from "react";
import { makeStyles } from 'tss-react/mui';
import { useIntl } from "react-intl";

type PerformanceIndicatorProps = {
  width: string | number;
  height: string | number;
  min: number;
  max: number;
  value: number;
} & HTMLAttributes<HTMLDivElement>;

const useStyles = makeStyles()(theme => ({
  root: {
    padding: "26px 6px 6px 6px",
    position: "relative",
  },
  colorIndicator: {
    position: "relative",
    width: "100%",
    borderRadius: 10,
    backgroundImage: "linear-gradient(90deg, #ED1B2E 33%, #e6d450 33%, #e6d450 66%, #8bcf69 66%, #8bcf69 100%)",
    marginBottom: 12,
    display: "flex",
    alignItems: "center",
  },
  indicator: {
    position: "absolute",
    width: 1,
    height: "160%",
    borderLeft: "2px dotted #000000",
    "&::after": {
      content: "'Sentiment'",
      position: "absolute",
      bottom: "calc(100% + 3px)",
      transform: "translateX(-50%)"
    }
  },
  textRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  text: {
    color: "#666",
    fontWeight: 600
  }
}));

const getIndicatorPos = (min: number, max: number, value: number) => {
  const num = max - min;
  const percentage = ((value - min) * 100) / num;
  return Math.abs(percentage).toFixed(2);
}

const PerformanceIndicator: FC<PerformanceIndicatorProps> = ({
  width,
  height,
  min,
  max,
  value,
  ...rest
}) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { classes } = useStyles();
  
  return (
    <div className={classes.root} {...rest}>
      <div style={{ width, position: "relative"}}>
        <div style={{ width, height }} className={classes.colorIndicator}>
          <div style={{ left: `${getIndicatorPos(min, max, value)}%` }} className={classes.indicator}/>
        </div>
        <div className={classes.textRow}>
          <div className={classes.text}>{Translation("performanceIndicator.poor")}</div>
          <div className={classes.text}>{Translation("performanceIndicator.neutral")}</div>
          <div className={classes.text}>{Translation("performanceIndicator.good")}</div>
        </div>
      </div>
    </div>
  )
}

export default PerformanceIndicator;