import {
  InsuranceSummaryDetail,
  InsuranceSummaryInitalState,
  InsuranceSummaryState,
} from 'src/app/modules/sales-kit/pages/insurance-summary/type/insurance-summary-types';
import { isEmpty } from 'lodash';
export type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof InsuranceSummaryState;
    value: any;
  };
};

export type FormAction = ModifyFieldAction;

export const formReducer = (state: InsuranceSummaryState, action: FormAction) => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };

    default:
      return InsuranceSummaryInitalState;
  }
};

export const detailToStateConvertor = (data?: InsuranceSummaryDetail): InsuranceSummaryState => {
  return data
    ? {
        disclaimer: data?.disclaimer ? data.disclaimer : InsuranceSummaryInitalState.disclaimer,
        commentaryMessage:
          data.commentaryMessage && !isEmpty(data.commentaryMessage)
            ? data.commentaryMessage
            : InsuranceSummaryInitalState.commentaryMessage,
        bannerId: data.bannerId ? data.bannerId : InsuranceSummaryInitalState.bannerId,

        completeMessage: data.completeMessage ? data.completeMessage : InsuranceSummaryInitalState.completeMessage,
        bannerData: data.bannerData ? data.bannerData : InsuranceSummaryInitalState.bannerData,
      }
    : InsuranceSummaryInitalState;
};
