import { FC, ReactElement, ChangeEvent } from 'react';
import { useIntl } from 'react-intl';
import { Accordion, AccordionSummary, AccordionDetails, Checkbox, FormControlLabel } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { regionLocale, RegionLocale } from 'src/app/i18n';
import { useStyles } from './multi-lang-accordion.style';

type MultiLangAccordionProps = {
  disabled?: boolean;
  accordionClassName?: string;
  error?: (locale: RegionLocale) => boolean;
  fillInOnChange: (e: ChangeEvent<HTMLInputElement>, locale: RegionLocale) => void;
  renderChildren: (locale: RegionLocale, index: number) => ReactElement;
};

export const MultiLangAccordion: FC<MultiLangAccordionProps> = ({
  disabled,
  accordionClassName,
  error,
  fillInOnChange,
  renderChildren,
}) => {
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);
  const { classes } = useStyles();

  return (
    <>
      {regionLocale.map((locale, index) => (
        <Accordion
          key={locale}
          className={`${classes.accordion} ${accordionClassName} ${error && error(locale) ? classes.error : undefined}`}
          expanded={index === 0 ? true : undefined}
        >
          <AccordionSummary
            className={`${classes.accordionSummary} ${index === 0 ? classes.disablePointer : undefined}`}
            expandIcon={index === 0 ? undefined : <ExpandMore />}
          >
            {Translation(`navBar.lang.${locale}`)}
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetail}>
            <>
              {index > 0 && (
                <div className={classes.fillInContainer}>
                  <FormControlLabel
                    disabled={disabled}
                    control={<Checkbox onChange={(e) => fillInOnChange(e, locale)} />}
                    label={Translation('common.use_lang_to_fill_in', {
                      lang: Translation(`navBar.lang.${regionLocale[0]}`),
                    })}
                    labelPlacement="end"
                  />
                </div>
              )}
              {renderChildren(locale, index)}
            </>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};
