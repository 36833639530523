import { IntlShape } from 'react-intl';
import { ModuleHierachy } from '../../common/module/types';
import {
  AdminOperationPermissionType,
  BANNER_BASE_PATH,
  OPERATION_BANNER_NAMESPACE,
  OPERATION_BANNER_TITLE,
  OPERATION_BASE_PATH,
  OPERATION_POPUP_NAMESPACE,
  OPERATION_POPUP_TITLE,
  OPERATION_TITLE,
  POPUP_BASE_PATH,
} from './constants';
import { isPermitted } from 'src/app/common/utils';
import BannerPage from './pages/Banner/BannerPage';
import PopupPage from './pages/Popup/PopupPage';

export const operationModuleConfig = (permissions: string[], intl: IntlShape): ModuleHierachy => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return {
    title: Translation(OPERATION_TITLE),
    path: OPERATION_BASE_PATH,
    icon: '/media/svg/icons/Communication/Write.svg',
    enableRead: isPermitted(
      [
        AdminOperationPermissionType.OPERATION_BANNER_READ,
        AdminOperationPermissionType.OPERATION_BANNER_CREATE,
        AdminOperationPermissionType.OPERATION_BANNER_UPDATE,
        AdminOperationPermissionType.OPERATION_POPUP_READ,
        AdminOperationPermissionType.OPERATION_POPUP_CREATE,
        AdminOperationPermissionType.OPERATION_POPUP_UPDATE,
      ],
      permissions,
    ),
    child: [
      {
        title: Translation(OPERATION_BANNER_TITLE),
        path: `${OPERATION_BASE_PATH}${BANNER_BASE_PATH}`,
        namespace: OPERATION_BANNER_NAMESPACE,
        component: BannerPage,
        enableRead: isPermitted(
          [
            AdminOperationPermissionType.OPERATION_BANNER_READ,
            AdminOperationPermissionType.OPERATION_BANNER_CREATE,
            AdminOperationPermissionType.OPERATION_BANNER_UPDATE,
          ],
          permissions,
        ),
        enableCreate: isPermitted([AdminOperationPermissionType.OPERATION_BANNER_CREATE], permissions),
        enableUpdate: isPermitted([AdminOperationPermissionType.OPERATION_BANNER_UPDATE], permissions),
      },
      {
        title: Translation(OPERATION_POPUP_TITLE),
        path: `${OPERATION_BASE_PATH}${POPUP_BASE_PATH}`,
        namespace: OPERATION_POPUP_NAMESPACE,
        component: PopupPage,
        enableRead: isPermitted(
          [
            AdminOperationPermissionType.OPERATION_POPUP_READ,
            AdminOperationPermissionType.OPERATION_POPUP_UPDATE,
            AdminOperationPermissionType.OPERATION_POPUP_CREATE,
          ],
          permissions,
        ),
        enableCreate: isPermitted([AdminOperationPermissionType.OPERATION_POPUP_CREATE], permissions),
        enableUpdate: isPermitted([AdminOperationPermissionType.OPERATION_POPUP_UPDATE], permissions),
      },
    ],
  };
};
