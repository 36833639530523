import { downloadFile } from 'src/app/common/utils';
import { Dispatch } from 'react';
import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';

export const UPLOADED_FILE_URL = `${ADMIN_URL}/messages/variable-contents`;

export const uploadExcel = (
  { file, id, inclusive }: { file: File; id: string; inclusive?: boolean },
  dispatch?: Dispatch<any>,
) => {
  const data = new FormData();
  data.append('file', file);
  if (inclusive !== undefined) {
    data.append('inclusive', (inclusive || false).toString());
  }
  return apiClient
    .post(`${ADMIN_URL}/messages/variable-contents`, data)
    .then((result: any) => {
      const { ret, data } = result.data;
      if (ret === 1) {
        return { id: data.importId, fileName: data.fileName, url: `${UPLOADED_FILE_URL}/${data.importId}` };
      }
      throw new Error('upload fail');
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const getEventTemplate = async (dispatch?: Dispatch<any>, Translation?: any) => {
  return downloadFile(
    'template.xlsx',
    `${ADMIN_URL}/course-collections/templates/uploadfile/template`,
    {},
    dispatch,
    Translation,
  );
};

export const getCourseListByKeyword = async (params?: any) => {
  return apiClient.get(`${ADMIN_URL}/history/courses`, { params });
};

export const getCategoryList = async (params?: any) => {
  return apiClient.get(`${ADMIN_URL}/course-collections/categories`, { params });
};

export const getActiveCategory = async (params?: any) => {
  return apiClient.get(`${ADMIN_URL}/course-collections/categories/active`);
};

export const addCollection = async (dto?: any) => {
  return apiClient.post(`${ADMIN_URL}/course-collections/templates`, dto);
};

export const getCollectionDetail = async (id: string) => {
  return apiClient.get(`${ADMIN_URL}/course-collections/templates/${id}`);
};

export const updateCollection = async (id: string, dto?: any) => {
  return apiClient.put(`${ADMIN_URL}/course-collections/templates/${id}`, dto);
};
