import { forEach } from 'lodash';
import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import { Dispatch } from 'react';
import { ApplicationItem, ApplicationDetail, LanguageText, PlatformApplication } from '../types/application-types';

export type ApplicationBody = {
  name: string;
  bundleIdentifier: string;
  targetVersion: string;
  isPublic: boolean;
  role: string[];
  tags: string[];
  iconId: string;
  hardUpdateDate: Date;
  downloadLink: string;
  description: LanguageText;
  releaseNote: LanguageText;
  ios: PlatformApplication;
  android: PlatformApplication;
};

export type ApplicationListParam = {
  bundleIdentifier: string;
  name: string;
  role: string;
  isPublic?: string;
};

export const createApplication = async (body: ApplicationBody, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .post<void>(`${ADMIN_URL}/application`, body, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const updateApplication = async (id: string, body: ApplicationBody, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .put<void>(`${ADMIN_URL}/application/${id}`, body, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchApplicationItem = async (id: string, dispatch?: Dispatch<any>): Promise<ApplicationDetail> => {
  return apiClient
    .get<ApplicationDetail>(`${ADMIN_URL}/application/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchApplicationList = async (
  params: ApplicationListParam,
  dispatch?: Dispatch<any>,
): Promise<ApplicationItem[]> => {
  let queryPath = `${ADMIN_URL}/application?`;
  forEach(params, (param, key) => {
    if (key === 'isPublic') {
      queryPath += param !== undefined ? `${key}=${encodeURIComponent(param as any)}&` : '';
    } else {
      queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
    }
  });
  return apiClient
    .get<ApplicationItem[]>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const deleteApplication = async (id: string, dispatch?: Dispatch<any>): Promise<void> => {
  await apiClient
    .delete(`${ADMIN_URL}/application/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
