import React, { FC } from 'react';
import { Redirect, RouteComponentProps, Switch } from 'react-router-dom';
import PruRoute from '../../../../common/components/PruRoute';
import NewsHeadlineListingPage from './List/NewsHeadlineListingPage';


const NewsHeadlinePage: FC<RouteComponentProps> = ({ history, location, match }) => {
  return (
    <Switch>
      <Redirect exact from={match.path} to={`${match.path}/list`}/>
      <PruRoute
        exact
        path={`${match.path}/list`}
        component={NewsHeadlineListingPage}
      />
    </Switch>
  )
};

export default NewsHeadlinePage;
