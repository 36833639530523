import { Dispatch } from 'react';
import { apiClient, apiErrorHandler, ADMIN_URL, PUBLIC_URL } from 'src/app/common/network';
import { PaginateList } from 'src/app/common/types/common-types';
import { CampaignItem } from '../../PulseLeads/types/campaign-types';
import {
  CampaignListParam,
  MicrositeListItem,
  MircrositeDetail,
  PaginateResult,
  PaginationDtoItem,
} from '../types/microsite.type';
import QueryString from 'qs';
import axios from 'axios';

const campaignEndpoint = 'campaigns';
const micrositeEndPoint = 'microsite';

export const fetchActiveCampaignList = async (
  params: Partial<CampaignListParam>,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<CampaignItem>> => {
  const queryPath = `${ADMIN_URL}/${campaignEndpoint}`;

  return apiClient
    .get<PaginateList<CampaignItem>>(queryPath, { params: params })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchMicrositeList = async (
  params: any,
  sortKeys: { key: string; value?: string }[],
  dispatch?: Dispatch<any>,
): Promise<PaginateResult<MicrositeListItem>> => {
  let queryPath = `${ADMIN_URL}/${micrositeEndPoint}?`;
  const queryString = QueryString.stringify(params);
  const sortObj: any = {};
  sortKeys.map((item) => {
    sortObj[`${item.key}`] = item.value === 'asc' ? 'ascending' : 'descending';
  });
  const sortString = '&' + QueryString.stringify({ pagination: { sort: sortObj } });

  queryPath = queryPath + queryString + sortString;

  return apiClient
    .get(queryPath, {})
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchMicrositeDetail = async (id: string, dispatch?: Dispatch<any>): Promise<any> => {
  const queryPath = `${ADMIN_URL}/${micrositeEndPoint}/${id}`;

  return apiClient
    .get<PaginateList<MircrositeDetail>>(queryPath, {})
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const updateMicrosite = async (id: string, body: any, dispatch?: Dispatch<any>): Promise<any> => {
  const queryPath = `${ADMIN_URL}/${micrositeEndPoint}/${id}`;

  return apiClient
    .put(queryPath, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createMicrosite = async (body: any, dispatch?: Dispatch<any>): Promise<any> => {
  const queryPath = `${ADMIN_URL}/${micrositeEndPoint}`;

  return apiClient
    .post(queryPath, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

/**
 * get golbal country-code & LBU cities
 * @param region sample: HK
 * @param channel sample: AGENCY
 * @returns
 */
export function getCountryCodeAndCity(
  region: string,
  channel: string,
): Promise<{ location: unknown; regionPhone: unknown }> {
  const url = `${PUBLIC_URL}/material/country-code-and-city`;
  return axios.get(url, { params: { region, channel } }).then((res) => res.data);
}

export function getMicrositeResult(id: string, dispatch?: Dispatch<any>): Promise<any> {
  // const queryPath = `http://localhost:3000/${micrositeEndPoint}/result/${id}`;
  const queryPath = `${ADMIN_URL}/${micrositeEndPoint}/result/${id}`;
  return apiClient
    .get<PaginateList<MircrositeDetail>>(queryPath, {})
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
}

export function getMicrositeAnswers(
  id: string,
  params?: { pagination: PaginationDtoItem },
  dispatch?: Dispatch<any>,
): Promise<any> {
  let queryPath = `${ADMIN_URL}/${micrositeEndPoint}/answers?`;
  const newParams = {
    ...params,
    refId: id,
  };
  const queryString = QueryString.stringify(newParams);
  queryPath = queryPath + queryString;
  return apiClient
    .get<PaginateList<MircrositeDetail>>(queryPath, {})
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
}
