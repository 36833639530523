import React, { FC } from "react";
import { Route, Switch } from "react-router-dom";
import NotFoundErrorPage from "./NotFoundErrorPage";
import ForbiddenErrorPage from "./ForbiddenErrorPage";
import { NOT_FOUND_ERROR_PAGE_PATH, FORBIDDEN_ERROR_PAGE_PATH } from "src/app/common/constants";

const ErrorsPage: FC = () => {
  return (
    <Switch>
      <Route path={NOT_FOUND_ERROR_PAGE_PATH} component={NotFoundErrorPage} />
      <Route path={FORBIDDEN_ERROR_PAGE_PATH} component={ForbiddenErrorPage} />
    </Switch>
  );
};

export default ErrorsPage;
