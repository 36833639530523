export type CourseListQueryParam = {
  courseCode?: string;
  courseType?: string;
  courseName?:
    | {
        [key: string]: string;
      }
    | string;
  status?: string;
  lastUpdatedBy?: string;
  lastUpdatedAt?: string;
  limit: number;
  page: number;
  orders?: Order[];
  lang?: string;
  systemReferral?: any;
  currLang?: string;
};
interface Order {
  direction: string;
  property: string;
}

export type CourseItem = {
  channel: string;
  region: string;
  id: string;
  lastUpdatedAt: string;
  lastUpdatedBy: string;
};

export type PaginateList<T> = {
  totalNumbers: number;
  totalPages: number;
  limit: number;
  data: T[];
  hasNextPage: boolean;
  hasPrevPage: boolean;
  nextPage: number | null;
  prevPage: number | null;
  page: number;
  status?: string;
};

export enum PublishStatusEnum {
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
}
export interface CourseListDetailParam extends CourseListQueryParam {
  courseName?: {
    [key: string]: string;
  };
}

export interface OnboardingCourseFormState {
  courseName: Record<string, string>;
  courseCode: string;
  systemReferral?: string;
  referralCode?: string;
  status?: string;
  materialUpload?: {
    [key: string]: any;
  };
  lessons?: any[];
  quiz: any;
}

export interface ApplicationTrainingCourseListParam {
  id?: string;
  i18nData?: I18nDatum[];
  order?: number;
  courseType?: string;
  courseCode?: string;
  systemReferral?: boolean;
  referralCode?: string;
  publishedAt?: string;
  status?: string;
  region?: string;
  channel?: string;
  lessons?: Lesson[];
  quiz?: Quiz;
  deleted?: boolean;
  audit?: Audit;
}

interface Lesson {
  id: string;
  i18nData: I18nDatum[];
  order: number;
  lessonCode: string;
  systemReferral: boolean;
  referralCode: string;
  quiz: Quiz;
  audit: Audit;
}

interface Quiz {
  id: string;
  quizType: string;
  quizCode: string;
  passingScore: number;
  numQuestions: number;
  audit: Audit;
  quizName: i18nBase;
}

interface i18nBase {
  [key: string]: string;
}

interface Audit {
  createdBy: string;
  createdByRole: string;
  createdAt: string;
  lastUpdatedBy: string;
  lastUpdatedByRole: string;
  lastUpdatedAt: string;
}
interface I18nDatum {
  name: string;
  lang: string;
}
export interface OnboardingLessonFormState {
  status: string;
  lessonName: Record<string, string>;
  type: string;
  lessonCode: string;
  isSourceSystemReferral: boolean;
  referralCode?: string;
  linkedCourse?: string;
  material?: {
    [key: string]: any;
  };
  systemReferral?: string | boolean;
  quiz?: Quiz;
}

export interface OnboardingQuizFormState {
  name: Record<string, string>;
  code: string;
  passingScore: number;
  numQuestions: number;
  linkedResource: string;
  quizNature: string;
  linkedCourse?: string;
  material?: {
    [key: string]: any;
  };
  courseId?: string;
  lessonId?: string;
}
export interface OnboardingTrainingOptions {
  category: string;
  options: OnboardingTrainingOptionItem[];
}

export interface OnboardingTrainingOptionItem {
  key: string;
  value: string;
}

export enum OptionType {
  CourseType = 'COURSE_TYPE',
  CourseCode = 'COURSE_CODE',
}

export interface OnboardingQuizReportParams {
  lessonId?: string;
  startDate?: string;
  endDate?: string;
  currLang?: string;
}
