import { IntlShape } from 'react-intl';

export const LEAD_MICROSITE_BASE_PATH = '/lead-microsite';
export const LEAD_MICROSITE_TITLE = 'title.lead_microsite';
export const LEAD_MICROSITE_RESOURCE = 'lead-microsite';
export const LEAD_MICROSITE_NAMESPACE = 'LEAD-MICROSITE';

export const MICROSITE_BASE_PATH = '/microsite';
export const MICROSITE_TITLE = 'title.microsite';
export const MICROSITE_RESOURCE = 'microsite';
export const MICROSITE_NAMESPACE = 'MICROSITE';

export enum AdminLeadMicrositePermissionType {
  MICROSITE_READ = 'READ_ANY_MICROSITE',
  MICROSITE_CREATE = 'CREATE_ANY_MICROSITE',
  MICROSITE_UPDATE = 'UPDATE_ANY_MICROSITE',
}

export const activeType: any = (intl: IntlShape) => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return {
    Active: {
      value: '1',
      text: Translation('profile.agent.common.status.active'),
    },
    Inactive: {
      value: '0',
      text: Translation('profile.agent.common.status.inactive'),
    },
  };
};

export const bannerStatus: any = (intl: IntlShape) => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return {
    default: {
      value: '0',
      text: Translation('default_label'),
    },
    custom: {
      value: '1',
      text: Translation('profile.agent.common.tierType.custom'),
    },
  };
};

export const targetAgentType = {
  allAgent: 'ALL',
  targetAgent: 'TARGET_AGENTS',
};

export enum MTmode {
  CREATE = 'add',
  EDIT = 'edit',
  COPY = 'copy',
  VIEW = 'view',
}
