import { get, set, find, forEach, has } from 'lodash';

interface City {
  key: string;
  label: { en: string; local: string };
}

type Choice = {
  value: string;
  text: string | { [key: string]: string };
};

type RegionPhone = {
  code: string;
  displayName: string;
  regex?: string;
};

export function formatCity(arr: City[]): Choice[] {
  if (!arr || arr.length < 1) {
    return [];
  }
  const arrResult: Choice[] = [];
  arr.forEach((item) => {
    const arrCity = get(item, 'city', []);
    arrCity.forEach((city: City) => {
      const labelEn = get(city, 'label.en');
      const labelLocal = get(city, 'label.local', labelEn);
      arrResult.push({
        value: city.key,
        text: {
          default: labelEn,
          local: labelLocal,
          'zh-tw': labelLocal,
        },
      });
    });
  });
  return arrResult;
}

function updateElementValueByName(surveyJson: any, elementName: string, key: string, newValue: any) {
  forEach(surveyJson.pages, (page) => {
    const element = find(page.elements, { name: elementName });
    if (element) {
      set(element, key, newValue);
      return false;
    }

    forEach(page.elements, (elem) => {
      if (has(elem, 'elements')) {
        const childElement = find(elem.elements, { name: elementName });
        if (childElement) {
          set(childElement, key, newValue);
          return false;
        }
      }
    });
  });

  return surveyJson;
}

export function setCityChoices(jsonObj: object, location: Array<City>) {
  const cityData = formatCity(location);
  const newJsonObj = updateElementValueByName(jsonObj, 'city', 'choices', cityData);
  return newJsonObj;
}

export function setRegionChoices(jsonObj: object, regionPhone: { [key: string]: RegionPhone }) {
  const regionData: Choice[] = [];
  Object.getOwnPropertyNames(regionPhone).forEach(function (key: any) {
    const elem = {
      value: regionPhone[key].code,
      text: regionPhone[key].code,
    };
    regionData.push(elem);
  });

  const newJsonObj = updateElementValueByName(jsonObj, 'countryCode', 'choices', regionData);
  return newJsonObj;
}

export function convert2SurveyLocale(lang: string): string {
  const langMapping: { [key: string]: string } = {
    en: 'default',
    'en-US': 'default',
    zhhk: 'zh-tw',
    zhHk: 'zh-tw',
    'zh-Hant': 'zh-tw',
    'zh-Hant-TW': 'zh-tw',
    id: 'id', // indonesia
    my: 'my',
    ph: 'ph',
    km: 'km',
  };
  return langMapping[lang] || 'default';
}
