import { Dispatch } from 'react';
import { forEach } from 'lodash';
import { I18nGenericData } from 'src/app/i18n';
import { getDayEnd, getDayStart } from 'src/app/common/utils';
import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import { PaginateList } from 'src/app/common/types/common-types';
import {
  AgenProfilePaginateList,
  AgentProfileItem,
  ConfigAboutMePaginateList,
  ConfigPromotionsPaginateList,
} from '../types/agent-profile-types';
import { ConfigAboutMeParam } from '../pages/Configuration/Aboutme/List/ConfigAboutMeListingPage';
import { LibraryItem } from '../../sales-kit/components/Library/types/library-types';
import { debounce } from 'lodash';

const agentProfileEndpoint = 'e-profiles';
const agentProfileConfigEndpoints = 'e-profiles/configs';

export type AgentProfileListParam = {
  name: string;
  agentCode: string;
  tier: string;
  status: string;
  enableMyAboutMe: string;
  enableMyHighlight: string;
  page: number;
  limit: number;
};
export type AwardListParam = {
  agentName: string;
  agentCode: string;
  awardCode: string;
  awardYear: string;
  awardName: string;
  awardNameLocal: string;
  awardNameEn: string;
  type: string;
  icon: string;
  page: number;
  limit: number;
};
export type AwardTypeListParam = {
  displayAwardYear: string;
  awardYear: string;
  awardCode: string;
  awardNameLocal: string;
  awardNameEn: string;
  awardName: string;
  name: string;
  typeId: string;
  image: string;
  type: string;
  isGroupedYear: boolean;
  year: string;
  page: number;
  limit: number;
};

export type MassUploadStatusListParam = {
  fileName: string;
  uploadTypes: string;
  createdAtFrom: Date | null;
  createdAtTo: Date | null;
  uploadedAtFrom: Date | null;
  uploadedAtTo: Date | null;
  page: number;
  limit: number;
};

export type ConfigPromotionListParam = {
  name: string;
  effectiveDateFrom: Date | null;
  effectiveDateTo: Date | null;
  status: string;
  limit: number;
  page: number;
};
export type ConfigBannerTierListParam = {
  isDefault: string;
  tierCode: string;
  status: string;
  tierName: string;
  limit: number;
  page: number;
};

export type MassUploadBody = {
  blobId?: string;
  filename?: string;
};

export type CreateAgentProfileByMassUploadBody = {
  agent?: MassUploadBody;
  aboutMe?: MassUploadBody;
};
export type CreateAwardListByMassUploadBody = {
  award?: MassUploadBody;
};
export type CreateAwardTypeListByMassUploadBody = {
  awardType?: MassUploadBody;
};
export type UpdateAgentProfileBody = {
  tier: string;
  status: string;
  myAboutMe: any;
  myHighlight: any;
  myBanner: any;
  enableMyAboutMe: boolean;
  enableMyHighlight: boolean;
};
export type createBannerTierBody = {
  name: I18nGenericData<string>;
  banner?: I18nGenericData<string>;
  tierCode: string;
  isDefault?: boolean;
};

export const fetchAgentProfileList = async (
  params: AgentProfileListParam,
  lang: string,
  dispatch?: Dispatch<any>,
): Promise<AgenProfilePaginateList> => {
  const language = lang === 'en' ? 'enUs' : 'zhHk';
  let queryPath = `${ADMIN_URL}/${agentProfileEndpoint}?`;
  forEach(params, (param, key) => {
    if (key === 'page') {
      queryPath += `${key}=${encodeURIComponent(param as any)}&`;
    } else {
      queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
    }
  });
  queryPath += `lang=${language}`;
  return apiClient
    .get<PaginateList<AgentProfileItem>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchAgentProfile = async (id: string, dispatch?: Dispatch<any>): Promise<AgentProfileItem> => {
  return apiClient
    .get<AgentProfileItem>(`${ADMIN_URL}/${agentProfileEndpoint}/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const downloadMassUploadCsv = async (templateType: string, dispatch?: Dispatch<any>): Promise<string> => {
  const queryPath = `${ADMIN_URL}/${agentProfileConfigEndpoints}/csv-template/download?fileType=${templateType}`;
  return apiClient
    .get<string>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createAgentProfileByMassUpload = debounce(
  async (body: CreateAgentProfileByMassUploadBody, dispatch?: Dispatch<any>): Promise<void> => {
    const id = body.agent?.blobId;

    return apiClient
      .patch<void>(`${ADMIN_URL}/${agentProfileEndpoint}/batch/${id}`)
      .then((response) => response.data)
      .catch((err) => {
        throw apiErrorHandler(err, dispatch);
      });
  },
  400,
);

export const modifyAgentProfile = async (
  id: string,
  body: UpdateAgentProfileBody,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .patch<void>(`${ADMIN_URL}/${agentProfileEndpoint}/${id}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const deleteAgentProfile = async (id: string, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .delete<void>(`${ADMIN_URL}/${agentProfileEndpoint}/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

//=============AwardList=================//

export const fetchAgentAwardList = async (
  params: AwardListParam,
  lang: string,
  dispatch?: Dispatch<any>,
): Promise<any> => {
  const language = lang ? 'enUs' : 'zhHk';
  let queryPath = `${ADMIN_URL}/${agentProfileEndpoint}/agent-awards/admin?`;

  forEach(params, (param, key) => {
    if (key === 'page') {
      queryPath += `${key}=${encodeURIComponent(param as any)}&`;
    } else if (key === 'awardNameEn' && param !== '') {
      queryPath += `awardName=${param}&matchLanguage=enUs&`;
    } else if (key === 'awardNameLocal' && param !== '') {
      queryPath += `awardName=${param}&matchLanguage=zhHk&`;
    } else if (key === 'agentName' && param !== '') {
      queryPath += `agentName=${param}&matchLanguage=${language}&`;
    } else if (key === 'awardName' && param !== '') {
      queryPath += `awardName=${param}&matchLanguage=${language}&`;
    } else {
      queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
    }
  });

  if (!queryPath.includes('matchLanguage')) queryPath += `matchLanguage=${language}`;

  return apiClient
    .get<any>(queryPath)
    .then((res) => res.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchAwardTypeList = async (params: any, lang: string, dispatch?: Dispatch<any>): Promise<any> => {
  // ): Promise<PaginateList<AgentlistItem>> => {
  const language = lang ? 'enUS' : 'zhHk';

  let queryPath = `${ADMIN_URL}/${agentProfileEndpoint}/award-types/admin?`;

  forEach(params, (param, key) => {
    if (key === 'page') {
      queryPath += `${key}=${encodeURIComponent(param as any)}&`;
    } else if (key === 'awardNameEn' && param !== '') {
      queryPath += `name=${param}&matchLanguage=enUs&`;
    } else if (key === 'awardNameLocal' && param !== '') {
      queryPath += `name=${param}&matchLanguage=zhHk&`;
    } else if (key === 'awardName' && param !== '') {
      queryPath += `name=${param}&matchLanguage=${language}&`;
    } else {
      queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
    }
  });
  if (!queryPath.includes('matchLanguage')) queryPath += `matchLanguage=${language}`;
  return apiClient
    .get<any>(queryPath)
    .then((res) => res.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
export const createAwardListByMassUpload = debounce(
  async (body: CreateAwardListByMassUploadBody, dispatch?: Dispatch<any>): Promise<void> => {
    const id = body.award?.blobId;

    return apiClient
      .patch<void>(`${ADMIN_URL}/${agentProfileEndpoint}/agent-awards/batch-upload/${id}`)
      .then((response) => response.data)
      .catch((err) => {
        throw apiErrorHandler(err, dispatch);
      });
  },
  400,
);
export const createAwardTypeListByMassUpload = debounce(
  async (body: CreateAwardTypeListByMassUploadBody, dispatch?: Dispatch<any>): Promise<void> => {
    const id = body.awardType?.blobId;

    return apiClient
      .patch<void>(`${ADMIN_URL}/${agentProfileEndpoint}/award-types/batch-upload/${id}`)
      .then((response) => response.data)
      .catch((err) => {
        throw apiErrorHandler(err, dispatch);
      });
  },
  400,
);
export const createAwardType = async (body: any, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .post<void>(`${ADMIN_URL}/${agentProfileEndpoint}/award-types`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
export const modifyAwardType = async (body: any, id: string, dispatch?: Dispatch<any>): Promise<void> => {
  console.log('modifyAwardType', body);

  return apiClient
    .patch<void>(`${ADMIN_URL}/${agentProfileEndpoint}/award-types/${id}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
export const deleteAwardType = async (id: string, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .delete<void>(`${ADMIN_URL}/${agentProfileEndpoint}/award-types/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

//=============Configuration=================//
export const fetchConfigPromotionsList = async (
  params: ConfigPromotionListParam,
  lang: string,
  dispatch?: Dispatch<any>,
): Promise<ConfigPromotionsPaginateList> => {
  // ): Promise<any> => {
  const mock = true;

  let queryPath = `${ADMIN_URL}/${agentProfileEndpoint}/promotions?`;
  forEach(params, (param, key) => {
    if (key === 'page') {
      queryPath += `${key}=${encodeURIComponent(param as any)}&`;
    } else {
      queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
    }
  });
  // queryPath += `lang=${lang}`;
  return apiClient
    .get<ConfigPromotionsPaginateList>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchConfigBannerTierList = async (params: any, dispatch?: Dispatch<any>): Promise<any> => {
  // ): Promise<any> => {

  let queryPath = `${ADMIN_URL}/${agentProfileConfigEndpoints}/tiers?`;
  forEach(params, (param, key) => {
    if (key === 'page') {
      queryPath += `${key}=${encodeURIComponent(param as any)}&`;
    } else {
      queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
    }
  });

  return apiClient
    .get<any>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchConfigAboutMeList = async (
  params: ConfigAboutMeParam,
  dispatch?: Dispatch<any>,
): Promise<ConfigAboutMePaginateList> => {
  let queryPath = `${ADMIN_URL}/${agentProfileConfigEndpoints}/templates?`;
  forEach(params, (param, key) => {
    if (key === 'page') {
      queryPath += `${key}=${encodeURIComponent(param as any)}&`;
    } else {
      queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
    }
  });
  return apiClient
    .get<ConfigAboutMePaginateList>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createAboutMeTemplate = async (body: any, dispatch?: Dispatch<any>): Promise<any> => {
  const url = `${ADMIN_URL}/${agentProfileConfigEndpoints}/templates`;

  return apiClient
    .post<any>(url, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const getTemplateById = async (id: string, dispatch?: Dispatch<any>): Promise<any> => {
  const url = `${ADMIN_URL}/${agentProfileConfigEndpoints}/templates/${id}`;

  return apiClient
    .get<any>(url)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const modifyAboutMeTemplate = async (body: any, id: string, dispatch?: Dispatch<any>): Promise<any> => {
  // const local = `http://localhost:3000/${agentProfileConfigEndpoints}/templates/${id}`;
  const url = `${ADMIN_URL}/${agentProfileConfigEndpoints}/templates/${id}`;

  return apiClient
    .patch<any>(url, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const deleteAboutmeTemplate = async (id: string, dispatch: Dispatch<any>): Promise<void> => {
  const url = `${ADMIN_URL}/${agentProfileConfigEndpoints}/templates/${id}`;
  return apiClient
    .delete<any>(url)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchLibraryItem = (id: string, dispatch?: Dispatch<any>): Promise<LibraryItem> => {
  const queryPath = `${ADMIN_URL}/content-manager/collection-types/api::material.material/${id}`;
  return apiClient
    .get<LibraryItem>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createPromotionTemplate = (body: any, dispatch?: Dispatch<any>): Promise<any> => {
  const url = `${ADMIN_URL}/${agentProfileEndpoint}/promotions`;
  return apiClient
    .post<any>(url, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const deletePromotionTemplate = (id: string, dispatch?: Dispatch<any>): Promise<any> => {
  const url = `${ADMIN_URL}/${agentProfileEndpoint}/promotions/${id}`;
  return apiClient
    .delete<any>(url)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
export const modifyBannerTier = async (
  body: createBannerTierBody,
  id?: string,
  dispatch?: Dispatch<any>,
): Promise<any> => {
  const url = `${ADMIN_URL}/${agentProfileConfigEndpoints}/tiers/${id}`;

  return apiClient
    .patch<any>(url, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createBannerTier = (body: createBannerTierBody, dispatch?: Dispatch<any>): Promise<any> => {
  const url = `${ADMIN_URL}/${agentProfileConfigEndpoints}/tiers`;

  return apiClient
    .post<any>(url, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
export const deleteBannerTier = async (id: string, dispatch?: Dispatch<any>): Promise<any> => {
  const url = `${ADMIN_URL}/${agentProfileConfigEndpoints}/tiers/${id}`;

  return apiClient
    .delete<any>(url)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const getBannerTierById = async (id: string, dispatch?: Dispatch<any>): Promise<any> => {
  const url = `${ADMIN_URL}/${agentProfileConfigEndpoints}/tiers/${id}`;

  return apiClient
    .get<any>(url)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const getAwardTypeById = async (id: string, dispatch?: Dispatch<any>): Promise<any> => {
  const url = `${ADMIN_URL}/${agentProfileEndpoint}/award-types/${id}`;

  return apiClient
    .get<any>(url)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
export const fetchTierList = async (params: any, dispatch?: Dispatch<any>): Promise<any> => {
  let queryPath = `${ADMIN_URL}/${agentProfileConfigEndpoints}/tiers?`;
  forEach(params, (param, key) => {
    if (key === 'page') {
      queryPath += `${key}=${encodeURIComponent(param as any)}&`;
    } else {
      queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
    }
  });
  return apiClient
    .get<any>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

//=============Mass upload status=================//

export const fetchMassUploadStatusList = async (
  params: MassUploadStatusListParam,
  dispatch?: Dispatch<any>,
): Promise<any> => {
  let queryPath = `${ADMIN_URL}/${agentProfileEndpoint}/batch-upload-logs/list?`;
  forEach(params, (param, key) => {
    if (typeof param === 'object') {
      if (param) {
        if (key.toLowerCase().includes('from')) {
          queryPath += `${key}=${encodeURIComponent(getDayStart(param).toISOString())}&`;
        }
        if (key.toLowerCase().includes('to')) {
          queryPath += `${key}=${encodeURIComponent(getDayEnd(param).toISOString())}&`;
        }
      }
    } else {
      if (key === 'page') {
        queryPath += `${key}=${encodeURIComponent(param as any)}&`;
      } else {
        queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
      }
    }
  });
  return apiClient
    .get<any>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchMassUploadFailStatusById = async (id: string, dispatch?: Dispatch<any>): Promise<any> => {
  const url = `${ADMIN_URL}/${agentProfileEndpoint}/batch-upload-logs/${id}`;

  return apiClient
    .get<any>(url)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const downloadInsight = async (params: any, lang: string, dispatch?: Dispatch<any>): Promise<string> => {
  let queryPath = `${ADMIN_URL}/${agentProfileEndpoint}/insights/download?`;

  forEach(params, (param, key) => {
    console.log('typeof param', typeof param);

    if (typeof param === 'object') {
      if (param) {
        if (key.includes('start')) {
          queryPath += `${key}=${encodeURIComponent(getDayStart(param).toISOString())}&`;
        }
        if (key.includes('end')) {
          queryPath += `${key}=${encodeURIComponent(getDayEnd(param).toISOString())}&`;
        }
      }
    }
  });

  return apiClient
    .get<any>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
