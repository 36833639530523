export const SURVEY_BASE_PATH = '/survey';
export const SURVEY_MANAGEMENT_PATH = '/mgmt';

export const FLYERMESSAGELIMIT = 650;

export const MANDATORY_FIELD_ERROR_TEXT = 'This field is mandatory';
export const DATE_ERROR_TEXT = 'End date must be after Begin date';

//  These constants map to aside menu, header menu to display title
export const SURVEY_TITLE = 'title.survey';
export const SURVEY_MANAGEMENT_TITLE = 'title.surveyManagement';

//  These constants map to permission control, identical to permission enum defined below
export const SURVEY_NAMESPACE = 'SURVEY';

export enum AdminSurveyPermissionType {
  SURVEY_READ = 'READ_ANY_SURVEY',
  SURVEY_CREATE = 'CREATE_ANY_SURVEY',
  SURVEY_UPDATE = 'UPDATE_ANY_SURVEY',
}
