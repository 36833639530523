import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import PruRoute from 'src/app/common/components/PruRoute';
import { RECRUIT_BASE_PATH, RECRUIT_AMD_EMAIL_PATH } from '../../constants';
import ParamsProvider from 'src/app/common/components/ParamsProvider';
import AmdEmailListingPage, { amdEmailFilterKeys } from './List/AmdEmailListingPage';
import AmdEmailDetailPage from './Form/AmdEmailDetailPage';

export const amdEmailPath = `${RECRUIT_BASE_PATH}${RECRUIT_AMD_EMAIL_PATH}`;

const AmdEmailRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect exact from={amdEmailPath} to={`${amdEmailPath}/list`} />
      <PruRoute exact path={`${amdEmailPath}/list`} render={(props) => (
        <ParamsProvider {...props} acceptKeys={amdEmailFilterKeys}>
          <AmdEmailListingPage />
        </ParamsProvider>
      )}/>
      <PruRoute exact path={`${amdEmailPath}/edit/:id`} component={AmdEmailDetailPage}/>
      <PruRoute exact path={`${amdEmailPath}/create`} component={AmdEmailDetailPage}/>
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  )
}

export default AmdEmailRoutes;