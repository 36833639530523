import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useDataProvider } from 'src/app/common/utils';
import { PaginateList } from 'src/app/common/types';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { PruTableSortTypeEnum } from 'src/app/common/components/PruTable/PruTable';
import { FormDialogState, EventCategoryItem } from '../../../types';
import { EventCategoryListParam, fetchEventCategoryList } from '../../../network';

const initialState: EventCategoryListParam = {
  accessibility: '',
  audience: '',
  status: '',
  page: 1,
  limit: 20,
};

export const eventCategoryFilterKeys = ['accessibility', 'audience', 'status'];

const paramsInitiator = (initialParams?: Record<string, string>): EventCategoryListParam => {
  return initialParams
    ? {
        accessibility: initialParams.accessibility || '',
        audience: initialParams.audience || '',
        status: initialParams.status || '',
        page: 1,
        limit: 20,
      }
    : initialState;
};

export const useEventCategoryListingPage = ({ initialParams, onChangeQueryParams }: ParamsProps) => {
  const dispatch = useDispatch();
  const [filterState, setFilterState] = useState<EventCategoryListParam>(paramsInitiator(initialParams));
  const [formDialogState, setFormDialogState] = useState<FormDialogState>({ open: false });
  const [eventCategoryList, setEventCategoryList] = useState<PaginateList<EventCategoryItem>>();

  const { isLoading, refreshData } = useDataProvider<PaginateList<EventCategoryItem>>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchEventCategoryList(filterState, dispatch);
      } catch (err) {}
    },
    setEventCategoryList,
    false,
  );

  const onSort = (newSortState: { key: string; value?: string }) => {
    const { key, value } = newSortState;
    if (value) {
      let newSort = '';
      switch (value) {
        case PruTableSortTypeEnum.ASC:
          newSort = key;
          break;
        case PruTableSortTypeEnum.DESC:
          newSort = `-${key}`;
          break;
      }
      setFilterState({ ...filterState, sort: newSort });
    } else {
      setFilterState({ ...filterState, sort: undefined });
    }
    refreshData();
  };

  return {
    filterState,
    formDialogState,
    eventCategoryList,
    isLoading,
    setFilterState,
    setFormDialogState,
    refreshData,
    onSort,
  };
};
