import React, { FC } from 'react';
import { makeStyles } from 'tss-react/mui';
import { CheckCircle, RadioButtonUnchecked } from '@mui/icons-material';

type ProgressBarItemProps = {
  isFinished: boolean;
  disableLine: boolean;
}

const useStyles = makeStyles()((theme) => ({
  progressBarContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    marginRight: 15,
  },
  line: {
    width: 4,
    height: '100%',
    position: 'absolute',
    top: 9,
  },
  red: {
    backgroundColor: '#E8192C',
  },
  grey: {
    backgroundColor: '#EDEDEE',
  },
  iconContainer: {
    position: 'relative',
    zIndex: 1,
  },
  iconBackground: {
    position: 'absolute',
    width: 14,
    height: 14,
    top: 3,
    left: 2,
    borderRadius: 14,
    backgroundColor: theme.palette.common.white,
    zIndex: 2,
  },
  icon: {
    color: '#E8192C',
    position: 'relative',
    zIndex: 3,
  },
}));

const ProgressBarItem: FC<ProgressBarItemProps> = ({ isFinished, disableLine }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.progressBarContainer}>
      <div className={classes.iconContainer}>
        {isFinished ? (
          <CheckCircle className={classes.icon} />
        ) : (
          <RadioButtonUnchecked className={classes.icon} />
        )}
        <div className={classes.iconBackground} />
      </div>
      {!disableLine &&
        <div
          className={`
            ${classes.line}
            ${isFinished ? classes.red : classes.grey}
          `}
        />
      }
    </div>
  );
}

export default ProgressBarItem;