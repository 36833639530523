import React, { FC, useEffect, useRef, useState } from 'react';
import {
  TableContainer,
  TableFooter,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
} from '@mui/material';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { styles } from './styles';
import { useLang } from 'src/app/i18n';
import { getTopicList } from '../../api';
import { PruForm } from 'src/app/common/components/PruForm';
import filterConfig from './filterConfig';
import tableHeader from './tableHeader';
import PruTableLoading from 'src/app/common/components/Table/PruTableLoading';
import PruTableEmptyRow from 'src/app/common/components/Table/PruTableEmptyRow';
import StickyTableCell from 'src/app/common/components/ProTable/StickyTableCell';
import PruTablePaginationActions from 'src/app/common/components/Table/PruTablePaginationActions';
import tableConfig from './tableConfig';
import ProTableCell from 'src/app/common/components/ProTable/ProTableCell';
import { publishStatusEnum } from './utils';
import moment from 'moment';

const List: FC<any> = (props) => {
  const intl = useIntl();
  const { classes } = styles();
  const Translation = (id: string) => intl.formatMessage({ id, defaultMessage: id });
  const locale = useLang();
  const filterInitData = {};
  const sort = useRef('updatedAt:DESC');
  const { enableCreate, enableUpdate } = props;
  const [filterData, setFilterData] = useState<{ [key: string]: any }>(filterInitData);
  const [tableData, setTableData] = useState<{ [key: string]: any }[]>([]);
  const pageData = useRef<{ [key: string]: any }>({
    page: 1,
    pageSize: 20,
  });

  const [formLoading, setFormLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchAPI = async () => {
      setFormLoading(true);
      await fetchData();
      setFormLoading(false);
    };

    fetchAPI();

    return;
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.addEventListener('keypress', keyPressSearch);
    return () => {
      window.removeEventListener('keypress', keyPressSearch);
    };
    // eslint-disable-next-line
  }, [filterData]);

  const fetchData = async () => {
    const endTime = filterData?.timeRange?.[1]
      ? moment(filterData?.timeRange?.[1]).endOf('day').utc().format()
      : undefined;
    const startTime = filterData?.timeRange?.[0]
      ? moment(filterData?.timeRange?.[0]).startOf('day').utc().format()
      : undefined;

    const requestParams = {
      sort: [sort.current],
      filters: {
        resource: {
          $containsi: filterData.name,
        },
        updatedAt: {
          $gte: startTime,
          $lte: endTime,
        },
        publishStatus: reTransformStatus(filterData.publishStatus),
      },
      pagination: {
        page: pageData.current.page,
        pageSize: pageData.current.pageSize,
      },
      publicationState: 'preview',
      locale,
    };

    await getTopicList(requestParams)
      .then((res: any) => {
        const { results, pagination } = res?.data;
        const formatData = results.map((item: any) => {
          return {
            ...item,
            name: item?.material?.name || item?.resource?.name,
            publishStatus: transformStatus(item?.publishStatus),
          };
        });
        setTableData(formatData || []);
        pageData.current.total = pagination.total || 0;
        setFormLoading(false);
      })
      .catch((err: any) => {
        console.log(err.message);
        setFormLoading(false);
      });
  };

  const transformStatus = (status: any) => {
    if (status === publishStatusEnum.draft) return 0;
    else if (status === publishStatusEnum.published) return 1;
    else if (status === publishStatusEnum.unpublished) return 2;
    else return 0;
  };

  const reTransformStatus = (status: any) => {
    if (status === '0') return publishStatusEnum.draft;
    else if (status === '1') return publishStatusEnum.published;
    else if (status === '2') return publishStatusEnum.unpublished;
  };

  const onSearch = () => {
    pageData.current.page = 1;
    fetchData();
  };

  const keyPressSearch = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      onSearch();
    }
  };

  const onChangePage = (page: number) => {
    pageData.current.page = page + 1;
    fetchData();
  };

  const onChangePageRows = (pageSize: any) => {
    pageData.current.page = 1;
    pageData.current.pageSize = pageSize;
    fetchData();
  };

  const handleSort = (sorter: any) => {
    let sortItem;
    let sortOrder;

    Object.entries(sorter).forEach(([item, order]) => {
      sortItem = item;
      sortOrder = order;
    });

    sort.current = `${sortItem || 'updatedAt'}:${(sortOrder || 'DESC').toLocaleUpperCase()}`;
    pageData.current.page = 1;

    fetchData();
  };

  const AddTask = () => {
    // setPopUpVisible(true);
    props.history.push('/pulseleads/engagement/topic/add');
  };

  const refreshData = async () => {
    pageData.current.page = 1;
    fetchData();
  };

  const onReset = () => {
    setFilterData(filterInitData);
  };

  return (
    <>
      <PruForm
        config={filterConfig(Translation, onSearch, onReset, locale)}
        data={filterData}
        onChange={setFilterData}
        space={20}
        style={{ padding: 20 }}
      ></PruForm>

      <PruForm
        config={tableHeader(Translation, AddTask, refreshData, enableCreate)}
        data={[]}
        onChange={() => {}}
        space={20}
        style={{ padding: '15px 20px', marginTop: 20, borderTopLeftRadius: 5, borderTopRightRadius: 5 }}
      ></PruForm>

      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {tableConfig(Translation, locale).map((item) => (
                <ProTableCell
                  key={item.prop}
                  dataIndex={item.prop}
                  sorter={item.sorter}
                  order={item.order}
                  handleSort={handleSort}
                  style={{ minWidth: item.minWidth || 140 }}
                >
                  {item.title}
                </ProTableCell>
              ))}
              <StickyTableCell className={`sticky-cell`}>
                {Translation('component.formLabel.operation')}
              </StickyTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <PruTableLoading isLoading={formLoading} />
            <PruTableEmptyRow isEmpty={tableData.length === 0} />
            {tableData.length > 0 &&
              tableData.map((row) => (
                <TableRow>
                  {tableConfig(Translation, locale).map((config) => (
                    <TableCell>{config.render ? config.render(row) : row[config.prop]}</TableCell>
                  ))}
                  <StickyTableCell className={`sticky-cell`}>
                    <div className={classes.operationContainer}>
                      {enableUpdate && (
                        <Link to={`/pulseleads/engagement/topic/edit/${row._id}`}>
                          <div className={`${classes.operationBtn}`} style={{ marginRight: 10 }}>
                            {Translation('app.button.edit')}
                          </div>
                        </Link>
                      )}
                    </div>
                  </StickyTableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TableFooter component="div" className={`table-footer-css`}>
        <TableRow component="div">
          <TablePagination
            align="right"
            rowsPerPageOptions={[5, 10, 20, 50]}
            colSpan={12}
            component="div"
            count={pageData.current.total || 0}
            rowsPerPage={pageData.current.pageSize || 20}
            page={pageData.current.page - 1}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true,
            }}
            onPageChange={(e, page) => onChangePage(page)}
            onRowsPerPageChange={(e) => onChangePageRows(e.target.value)}
            ActionsComponent={PruTablePaginationActions}
          />
        </TableRow>
      </TableFooter>
    </>
  );
};

export default List;
