import { any } from 'joi';
import { PaginateList } from '../../../common/types/common-types';
import { AgencyCampaignTypeStatusEnum } from '../constants';
import { TimeSetting } from './common-types';

export enum CampaignFormMode {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
}

export enum CampaignDetailPageStep {
  CAMPAIGN_INFO = 1,
  PARTICIPANT = 2,
  ATTACHMENT = 3,
  APPROVAL = 4,
  EVNET_DOCUMENT = 5,
  LEAD = 6,
}

export type louItem = {
  isRequired: boolean;
  dayBeforeSend: number;
  sendTypes: string[];
  resourceIds: string[];
};

export type campaignSectionFieldItem = {
  key: string;
  title: string;
  type: string;
  value: string | string[] | any[];
  priority: number;
  rules: any[];
  isActive: boolean;
  isRequired: boolean;
  options: any;
};

export type campaignStructureSection = {
  priority: number;
  sectionFields: campaignSectionFieldItem[];
  key: string;
  title: string;
};

export type ApprovalSettingItem = {
  labelName: string;
  selectBy: string;
  roleList: {
    type: string;
    values: string[];
  };
};

export type AddLeadSettingItem = {
  selfAddEnabled: string | boolean;
  needACMApproved: boolean;
  startDateSetting: TimeSetting;
  endDateSetting: TimeSetting;
  startDate: Date;
  endDate: Date;
};

export type CampaignStructureItem = {
  _id: string;
  role?: string[];
  name: string;
  code: string;
  isActive: boolean;
  agentLeadSource?: string;
  agentLeadSubSource?: string[];
  lou: louItem;
  timezone: number;
  workflowTemplateId: string;
  sections: campaignStructureSection[];
  createdAt: Date;
  updatedAt: Date;
  approvalSetting: ApprovalSettingItem[];
  addLeadSetting: AddLeadSettingItem;
};

export type CampaignListParam = {
  limit: number;
  page: number;
  status?: AgencyCampaignTypeStatusEnum[];
  campaignType?: string[];
  name: string;
  createdBy: string;
};

export type CampaignItem = {
  campaignId: string;
  type?: string;
  startDate?: Date;
  endDate?: Date;
  name?: string;
  createdAt?: Date;
  updatedAt?: Date;
  createdBy?: string;
  updatedBy?: string;
  status?: string;
  agencyCampaign?: { typeId: CampaignTypeItem };
};

export type CampaignTypeItem = {
  _id: string;
  name: string;
  status: string;
};

export type CampaignTypeListParam = {
  roleType: string;
  roles: string[];
};

export type AgentItem = {
  campaign?: string;
  agentCode: string;
  agency?: string;
  branch?: string;
  designation?: any;
  displayName?: any;
  division?: any;
  name?: any;
  unit?: string;
  uplineAgent?: any[];
  invitationTime?: string;
  rsvpIndicator?: string;
};

export type AgentListParam = {
  limit: number;
  page: number;
  search: string;
  id?: string;
};

export type LeadUploadItem = {
  campaignId: string;
  resourceId: string;
  filename: string;
};

export type CampaignDetailItem = any;

export type CampaignPaginateList = PaginateList<CampaignItem> & { docs: CampaignItem[]; totalDocs: number };

export type AgentPaginateList = PaginateList<AgentItem> & { docs: AgentItem[]; totalDocs: number };

export type DownlineVerifyList = { downline: AgentItem[]; exclude: String[] };

export type FailAgentItem = {
  agentCode: string;
  notEligibleReason: string;
  isEligible: boolean;
  businessName?: string;
  unitCode?: string;
};
export type SuccessAgentItem = {
  agentCode: string;
  notEligibleReason: string;
  isEligible: boolean;
  agency: string;
  unit: string;
};
export type NonDownlineVerifyList = { fail?: FailAgentItem[]; success?: SuccessAgentItem[] };
export type ImportVerify = {
  agentCodeArr: string[];
  campaignID: string;
  campaignTypeID: string;
  rules: string[];
  isApprover?: boolean;
};
