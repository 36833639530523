import React from 'react';
import { getDefaultDisplayDate } from 'src/app/common/utils';
import { Link } from 'react-router-dom';
import { leadNotDistrubutedPath } from '../Routes';
import { defaultDateRange, dontDistributeStatus } from '../util';

const getDistributionStatus: any = (currentStatus: any) => {
  return dontDistributeStatus[currentStatus];
};

const columns = (Translation: any, currentStatus: any, setCurrentStatus: any, filterData: any, setFilterData: any) => {
  const columns = [
    {
      title: `${Translation('component.formLabel.status')}`,
      dataIndex: 'status',
      valueEnum: {
        '0': { text: 'Failed Leads', status: 'NotExpired' },
        '1': { text: "Don't Distribute Leads", status: 'Upcoming' },
        '2': { text: 'In-Progress Leads', status: 'On-going' },
        '3': { text: 'Invalid Lead Data', status: 'InvalidLead' },
      },
      hideInTable: true,
      onChange: (value: string) => {
        setCurrentStatus(value);
        setFilterData({
          ...filterData,
          ...{
            distributionStatus: [],
          },
        });
      },
    },
    {
      title: Translation('title.pulseleads.not.distributed.lead.name'),
      dataIndex: 'firstName',
      hideInSearch: true,
      sorter: true,
      render: (_: any, row: any) => {
        return <span>{`${row.firstName} ${row.lastName}`}</span>;
      },
    },
    {
      title: Translation('title.pulseleads.not.distributed.lead.campaign'),
      dataIndex: 'campaignId',
      sorter: true,
    },
    {
      title: Translation('title.pulseleads.not.distributed.lead.source'),
      dataIndex: 'leadSource',
      hideInSearch: true,
      sorter: true,
    },
    {
      title: Translation('title.pulseleads.not.distributed.status'),
      dataIndex: 'distributionStatus',
      hideInSearch: true,
      sorter: true,
    },
    {
      title: Translation('event.list.detail.date'),
      dataIndex: 'dateRange',
      key: 'dateRange',
      hideInTable: true,
      valueType: 'dateRange',
      initialValue: defaultDateRange,
      require: true,
    },
    {
      title: `${Translation('title.pulseleads.not.distributed.status')}`,
      dataIndex: 'distributionStatus',
      valueEnum: getDistributionStatus(currentStatus),
      multiple: true,
      hideInTable: true,
      onChange: (value: any) => {
        setFilterData({
          ...filterData,
          ...{
            distributionStatus: value,
          },
        });
      },
      renderValue: filterData.distributionStatus,
    },
    {
      title: `${Translation('title.pulseleads.not.distributed.isCustomerDedupApiConnectionFail')}`,
      dataIndex: 'isCustomerDedupApiConnectionFail',
      valueEnum: {
        '': { text: '' },
        true: { text: 'True' },
        false: { text: 'False' },
      },
      hideInTable: true,
      onChange: (value: string) => {
        setFilterData({
          ...filterData,
          isCustomerDedupApiConnectionFail: value === '' ? undefined : value === 'true' ? true : false,
        });
      },
    },
    {
      title: Translation('title.pulseleads.not.distributed.date'),
      dataIndex: 'updatedAt',
      sorter: true,
      hideInSearch: true,
      render: (data: any) => {
        return <span>{data ? getDefaultDisplayDate(data, 'datetime') : '-'}</span>;
      },
    },
    {
      title: Translation('title.pulseleads.not.distributed.reason'),
      dataIndex: 'failureReason',
      key: 'failureReason',
      hideInSearch: true,
      sorter: true,
    },
    // {
    //   title: Translation('title.pulseleads.not.distributed.country.national.id'),
    //   dataIndex: 'nationality',
    //   hideInSearch: true,
    //   sorter: true,
    // },
    {
      title: Translation('component.formLabel.operation'),
      dataIndex: 'option',
      key: 'option',
      valueType: 'option',
      hideInSearch: true,
      sticky: true,
      render: (_: any, row: any) => {
        return (
          <Link to={`${leadNotDistrubutedPath}/detail/${row._id}`}>
            <div style={{ marginRight: 10 }}>{Translation('app.button.view')}</div>
          </Link>
        );
      },
    },
  ];

  return [...columns];
};

export default columns;
