import React, { FC, useState, useEffect, useMemo } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ApiErrorResponse } from 'src/app/common/network';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { LayoutSplashScreen } from 'src/app/layout';
import { fetchDeduplicationRuleItem } from 'src/app/modules/PulseLeads/network/deduplicationRuleCrud';
import {
  DeduplicationRuleFormMode,
  DeduplicationRule,
} from 'src/app/modules/PulseLeads/types/deduplication-rule-types';
import DeduplicationRuleForm from './components/DeduplicationRuleForm';

const DeduplicationRuleDetailPage: FC<RouteComponentProps> = ({ history, location }) => {
  const { id } = useParams<{ id: string }>();
  const { formMode } = useMemo(
    () => ({
      formMode: id ? DeduplicationRuleFormMode.EDIT : DeduplicationRuleFormMode.CREATE,
    }),
    [id],
  );

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [deduplicationRule, setDeduplicationRule] = useState<DeduplicationRule>();

  const reloadData = () => {
    if (id) {
      setIsLoading(true);
      fetchDeduplicationRuleItem(id, dispatch)
        .then((result) => {
          setDeduplicationRule(result);
        })
        .catch((err: ApiErrorResponse) => {
          history.push(NOT_FOUND_ERROR_PAGE_PATH);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line
  }, [id]);

  return (
    <>
      {formMode === DeduplicationRuleFormMode.CREATE && <DeduplicationRuleForm formMode={formMode} />}
      {formMode === DeduplicationRuleFormMode.EDIT &&
        (isLoading || !deduplicationRule ? (
          <LayoutSplashScreen />
        ) : (
          <DeduplicationRuleForm formMode={formMode} deduplicationRule={deduplicationRule} />
        ))}
    </>
  );
};

export default DeduplicationRuleDetailPage;
