import React, { FC, useState, useEffect } from 'react';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useDataProvider } from 'src/app/common/utils';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { PaginateList } from 'src/app/common/types/common-types';
import { EventCampaignItem } from 'src/app/modules/PulseLeads/types/event-campaign-types';
import { fetchEventCampaignList, EventCampaignListParam } from 'src/app/modules/PulseLeads/network/eventCampaignCrud';
import { fetchConfig, ConfigParam } from 'src/app/modules/PulseLeads/network/campaignCrud';
import EventCampaignList from './components/EventCampaignList';

type EventCampaignListingPageProps = {} & ParamsProps;

const initialState: EventCampaignListParam = {
  eventCode: '',
  campaignName: '',
  page: 1,
  limit: 5,
};

export const eventCampaignFilterKeys = ['eventCode', 'campaignName'];

const paramsInitiator = (initialParams?: Record<string, string>): EventCampaignListParam => {
  return initialParams
    ? {
        eventCode: initialParams.eventCode || '',
        campaignName: initialParams.campaignName || '',
        page: 1,
        limit: 5,
      }
    : initialState;
};

const EventCampaignListingPage: FC<EventCampaignListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const intl = useIntl();
  const history = useHistory();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const [filterState, setFilterState] = useState<EventCampaignListParam>(paramsInitiator(initialParams));
  const [eventCampaignList, setEventCampaignList] = useState<PaginateList<EventCampaignItem>>();
  const [config, setConfig] = useState<any>();

  const { isLoading, refreshData } = useDataProvider<PaginateList<EventCampaignItem>>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchEventCampaignList(filterState, dispatch);
      } catch (err) {
        history.push(NOT_FOUND_ERROR_PAGE_PATH);
      }
    },
    setEventCampaignList,
    false,
  );

  const reloadConfig = async () => {
    const configParams: ConfigParam = {
      name: 'sales-sdk',
      namespace: 'default',
    };
    const configRes = await fetchConfig(configParams, dispatch);
    setConfig(configRes.data[0]);
  };

  useEffect(() => {
    reloadConfig();
  }, []);

  return (
    <>
      <PruFilter
        title={Translation('pulseleads.straightThrough.eventCampaignFilter')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'eventCode',
            initialValue: filterState.eventCode,
            displayName: Translation('pulseleads.straightThrough.eventCode'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'campaignName',
            initialValue: filterState.campaignName,
            displayName: Translation('pulseleads.campaign.common.name'),
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            eventCode: data.eventCode,
            campaignName: data.campaignName,
          })
        }
        fetchData={refreshData}
      />
      <EventCampaignList
        isLoading={isLoading}
        eventCampaignList={eventCampaignList}
        config={config}
        onRefresh={refreshData}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default EventCampaignListingPage;
