import Button from '@mui/material/Button';
import React from 'react';
import { ModalService } from 'src/app/common/components/pru-modal/modal-service';

const onDelete = (row: any, data: string[], setSeletAgent: any, actionRef: any) => {
  const index = data.findIndex((item) => {
    return item === row;
  });
  if (index !== -1) {
    const newAgentList = data.splice(index, 1);
    setSeletAgent(newAgentList);
    actionRef.current?.reload();
  }
};

const columns = (Translation: any, data: string[], setSeletAgent: any, actionRef: any) => {
  const columns = [
    {
      title: Translation('pulseleads.activityPoint.ruleSettings.common.agentCode'),
      dataIndex: 'agentCode',
      hideInSearch: true,
    },
    {
      title: Translation('component.formLabel.action'),
      dataIndex: 'agentCode',
      hideInSearch: true,
      align: 'right' as const,
      render: (_: any, row: any) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'row-reverse', marginRight: -12, marginLeft: 24 }}>
            <Button
              color="secondary"
              onClick={() => {
                ModalService.confirm({
                  title: Translation('survey.common.delete.title'),
                  onConfirm: () => {
                    onDelete(row, data, setSeletAgent, actionRef);
                  },
                  confirmText: Translation('button_confirm'),
                  cancelText: Translation('button_cancel'),
                });
                return;
              }}
            >
              {Translation('app.button.delete')}
            </Button>
          </div>
        );
      },
    },
    {
      title: '',
      hideInSearch: true,
      align: 'right' as const,
      render: (_: any, row: any) => {
        return <div className="tw-w-0">{''}</div>;
      },
    },
  ];

  return [...columns];
};

export default columns;
