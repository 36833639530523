import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import PruRoute from 'src/app/common/components/PruRoute';
import { RECRUIT_BASE_PATH, RECRUIT_PERSONAL_PARTICULAR_PATH } from '../../constants';
import ParamsProvider from 'src/app/common/components/ParamsProvider';
import PersonalParticularListingPage, { personalParticularFilterKeys } from './List/PersonalParticularListingPage';
import PersonalParticularDetailPage from './Form/PersonalParticularDetailPage';
import PersonalParticularDocPage from './Document/PersonalParticularDocPage';

export const personalParticularPath = `${RECRUIT_BASE_PATH}${RECRUIT_PERSONAL_PARTICULAR_PATH}`;

const PersonalParticularRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect exact from={personalParticularPath} to={`${personalParticularPath}/list`} />
      <PruRoute exact path={`${personalParticularPath}/list`} render={(props) => (
        <ParamsProvider {...props} acceptKeys={personalParticularFilterKeys}>
          <PersonalParticularListingPage />
        </ParamsProvider>
      )}/>
      <PruRoute exact path={`${personalParticularPath}/detail/:appId`} component={PersonalParticularDetailPage}/>
      <PruRoute exact path={`${personalParticularPath}/doc/:appId`} component={PersonalParticularDocPage}/>
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  )
}

export default PersonalParticularRoutes;