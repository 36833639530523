import React, { FC, useRef, useCallback, useState, useEffect, useMemo, ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  Checkbox,
  DialogActions,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { get, set, debounce, forEach, sum } from 'lodash';
import './weight-column.css';
import { Translation } from 'src/app/i18n';
import { modifyLibrary } from '../../../../network/libraryCrud';
import { LibraryColumnRefItem, LibraryEditItem } from '../../../../types/library-types';
import { validateColumWeight } from 'src/app/modules/sales-kit/components/Column/network/columnCrud';
import { useJWT } from 'src/app/common/utils';

/** table column: weight or column in the PruTable */
export const UpdateWeightModal: FC<{
  rawData: { id: string; columnReferences: LibraryColumnRefItem[]; localizations: any[] };
  arrWeight: number[];
  arrColumn: string[];
  onSave: (data?: LibraryColumnRefItem[]) => void;
  onClose?: () => void; // auto inject by modal service
}> = ({ arrWeight = [], arrColumn = [], onSave, rawData, onClose }) => {
  const dispatch = useDispatch();
  const jwt = useJWT();
  const [loading, setLoading] = useState(false);
  const [errorObj, setErrorObj] = useState<{ [key: number]: string } | undefined>();

  const columnReferencesRaw = rawData?.columnReferences;

  const columnReferences: LibraryColumnRefItem[] = useMemo(() => {
    if (!columnReferencesRaw) {
      return [];
    }
    // sort by weight desc
    columnReferencesRaw.sort((a, b) => b.weight - a.weight);
    return columnReferencesRaw;
  }, [columnReferencesRaw]);

  const refColumnReferences = useRef(columnReferences);

  const saveWeight = useCallback(async () => {
    if (errorObj) {
      return;
    }
    const sumbitData = {
      // original column id from raw-data
      columnIds: columnReferences.map((item) => get(item, 'column.id')),
      columnReferences: refColumnReferences.current.map((item, index) => {
        return {
          column: get(item, 'column.id'),
          module: item.module,
          weight: item.weight,
        };
      }),
    } as any;
    setLoading(true);
    // update weight for all materials(multi-lang)
    const apiPromises: Promise<any>[] = [modifyLibrary(rawData.id, sumbitData, dispatch)];
    forEach(rawData.localizations, async (localeItem) => {
      apiPromises.push(modifyLibrary(localeItem.id, sumbitData, dispatch));
    });
    await Promise.all(apiPromises).finally(() => setLoading(false));
    onSave([...refColumnReferences.current]);
    onClose && onClose();
  }, [rawData, errorObj]);
  const onWeightChange = useCallback(
    debounce(async (event: ChangeEvent, index: number) => {
      let value = get(event, 'target.value');
      if (!value) {
        return setErrorObj({ [index]: Translation('component.form-required-text') });
      }
      // value = Number(value);
      const module = get(refColumnReferences.current, `[${index}].module`, '');
      const id = get(refColumnReferences.current, `[${index}].id`, 0);
      setLoading(true);
      const isValid = await validateColumWeight(module, value, id).finally(() => setLoading(false));
      if (!isValid) {
        return setErrorObj({ [index]: Translation('component.form-weight-diff-text') });
      } else {
        setErrorObj(undefined);
      }
      set(refColumnReferences.current, `[${index}].weight`, value);
    }, 500),
    [],
  );

  return (
    <>
      <TableContainer component={Paper}>
        <Table className="material-weight-table">
          <TableHead>
            <TableRow>
              <TableCell align="left">No</TableCell>
              <TableCell align="left">{Translation('component.formLabel.published-to-module')}</TableCell>
              <TableCell align="left">{Translation('component.formLabel.weight')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {arrWeight.map((weight, index) => (
              <TableRow key={index}>
                <TableCell align="left">{index + 1}</TableCell>
                <TableCell align="left">{arrColumn[index]}</TableCell>
                <TableCell align="left">
                  <TextField
                    type="number"
                    inputProps={{ min: 1 }}
                    variant="outlined"
                    margin="dense"
                    onChange={(e) => onWeightChange(e, index)}
                    defaultValue={weight}
                  />
                  {errorObj && errorObj[index] ? (
                    <div className="tw-text-red-600 tw-w-80">{errorObj[index]}</div>
                  ) : null}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <DialogActions className="tw-mr-2.5 tw-flex tw-justify-end">
        <Button onClick={onClose} variant="outlined" color="inherit">
          {Translation('app.button.cancel')}
        </Button>
        <Button disabled={loading} onClick={saveWeight} variant="contained" color="secondary">
          {Translation('app.button.save')}
        </Button>
      </DialogActions>
    </>
  );
};
