export const displayPublishStatus = (value: string, Translation: any) => {
    if (value === null || value === '') {
        return {
            label: '',
            value: ''
        }
    }

    switch (value.toString()) {
        case '0':
            return {
                label: Translation('event.publish.status.Draft.label'),
                value: '0'
            }
        case '1':
            return {
                label: Translation('event.publish.status.Published.label'),
                value: '1'
            }
        case '2':
            return {
                label: Translation('event.publish.status.Unpublished.label'),
                value: '2'
            }
        default:
            return {
                label: '',
                value: ''
            }
            break;
    }
}

export enum publishStatusEnum {
    draft = 'draft',
    published = 'published',
    unpublished = 'unpublished',
}