import React, { FC } from 'react';
import { useIntl } from "react-intl";
import { FormControl, MenuItem, Select, TextField } from '@mui/material';
import { useStyles } from 'src/app/modules/AgencyCampaign/pages/CampaignType/Form/CampaignTypeDetailPage';
import { useWorkflowStyles, initialApprovalSettingOneItem } from 'src/app/modules/AgencyCampaign/pages/CampaignType/Form/pages/PageThree/CampaignTypeFormPageThree';
import { ApprovalSettingItem, ApprovalRoleOptionList } from 'src/app/modules/AgencyCampaign/types/campaign-type-types';
import ApprovalLevelForm from './ApprovalLevelForm';

type ApprovalSettingProps = {
  approvalSetting: ApprovalSettingItem[];
  errorState: boolean[];
  approvalRoleOptionList: ApprovalRoleOptionList;
  setApprovalSetting: (approvalSetting: ApprovalSettingItem[]) => void;
  setErrorState: (errorState: boolean[]) => void;
}

const approvalLevelDropdownList = [
  { key: 0, value: '0' },
  { key: 1, value: '1' },
  { key: 2, value: '2' },
  { key: 3, value: '3' },
];

const ApprovalSetting: FC<ApprovalSettingProps> = ({ approvalSetting, errorState, approvalRoleOptionList, setApprovalSetting, setErrorState }) => {
  const { classes } = useStyles();
  const { classes: workflowClasses } = useWorkflowStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const setInputErrorState = (index: number, hasError: boolean) => {
    const newErrorState = [...errorState];
    newErrorState[index] = hasError;
    setErrorState(newErrorState);
  };

  return (
    <>
      <div className={`${workflowClasses.rowContainer} ${workflowClasses.largerBottomSpacing}`}>
        <div className="col-3">
          <span className={workflowClasses.settingTitle}>
            {Translation("agencyCampaign.campaignType.workflow.approvalSetting")}
          </span>
        </div>
        <div className="col-9">
          <div><span className={workflowClasses.settingSubTitle}>{Translation("agencyCampaign.campaignType.workflow.levelsOfApprovalNeeded")}<span className={classes.mandatory}>*</span></span></div>
          <div>
            <FormControl
              margin="dense"
            >
              <Select
                variant="standard"
                style={{ minWidth: 250 }}
                value={approvalSetting.length}
                onChange={e => {
                  const selectedLevel: number = e.target.value as number;
                  if (selectedLevel > approvalSetting.length) {
                    const newLevelNeeded = [];
                    const newErrorState = [];
                    for (let i = 0; i < selectedLevel - approvalSetting.length; i++) {
                      newLevelNeeded.push({...initialApprovalSettingOneItem});
                      newErrorState.push(false);
                    }
                    setApprovalSetting([...approvalSetting, ...newLevelNeeded]);
                    setErrorState([...errorState, ...newErrorState]);
                  } else if (selectedLevel < approvalSetting.length) {
                    let newApprovalSetting = [...approvalSetting];
                    newApprovalSetting.splice(selectedLevel);
                    setApprovalSetting(newApprovalSetting);
                  }
                }}
              >
                {approvalLevelDropdownList.map(option => (
                  <MenuItem key={option.key} value={option.key}>
                    {Translation(`agencyCampaign.campaignType.workflow.approvalLevelOption.${option.value}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
      {approvalSetting.map((approval, index) => (
        <ApprovalLevelForm
          approvalSetting={approvalSetting}
          approvalIndex={index}
          approvalRoleOptionList={approvalRoleOptionList}
          setApprovalSetting={setApprovalSetting}
          setErrorState={setInputErrorState}
        />
      ))}
    </>
  )
}

export default ApprovalSetting;