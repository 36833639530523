import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import PruRoute from 'src/app/common/components/PruRoute';
import { PULSELEADS_BASE_PATH } from '../../../constants';
import { PULSELEADS_STRAIGHT_THROUGH_PATH, PULSELEADS_STRAIGHT_THROUGH_STAGING_LEAD_PATH } from '../constants';
import ParamsProvider from 'src/app/common/components/ParamsProvider';
import StagingLeadListingPage, { stagingLeadFilterKeys } from './List/StagingLeadListingPage';

export const stagingLeadPath = `${PULSELEADS_BASE_PATH}${PULSELEADS_STRAIGHT_THROUGH_PATH}${PULSELEADS_STRAIGHT_THROUGH_STAGING_LEAD_PATH}`;

const StagingLeadRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect exact from={stagingLeadPath} to={`${stagingLeadPath}/list`} />
      <PruRoute exact path={`${stagingLeadPath}/list`} render={(props) => (
        <ParamsProvider {...props} acceptKeys={stagingLeadFilterKeys}>
          <StagingLeadListingPage />
        </ParamsProvider>
      )}/>
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default StagingLeadRoutes;