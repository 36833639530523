import { makeStyles } from 'tss-react/mui';

export const useCommonStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
    paddingBottom: 50
  },
  header: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    "&:before": {
      content: "''",
      display: 'inline-block',
      height: 16,
      width: 3,
      backgroundColor: 'red',
      marginRight: 10,
      marginLeft: 10,
      borderTopLeftRadius: 2
    }
  },
  sessionHeader: {
    fontSize: '1.3rem',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 20
  },
  subHeader: {
    fontSize: '1.1rem',
    fontWeight: 'bold'
  },
  noBorderHeader: {
    "&:before": {
      width: 0,
    }
  },
  popupCard: {
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
    boxShadow: '1px 2px 2px 1px rgba(0, 0, 0, 0.2)',
    padding: 10,
    outline: 'none'
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  errorText: {
    marginTop: 4,
    marginLeft: '1rem',
    marginRight: '1rem',
    color: '#f018a6',
    fontSize: '0.75rem',
    fontWeight: 400,
    fontFamily: 'Poppins'
  },
  errorRedText: {
    marginTop: 10,
    color: 'red',
    fontSize: '13px',
    fontWeight: 400,
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20
  },
  textArea: {
    lineHeight: 1.5,
    minHeight: 40
  },
  backdrop: {
    position: 'fixed',
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.common.white,
    backgroundColor: `${theme.palette.common.white} !important`,
    opacity: '0.5 !important'
  },
  link: {
    textDecoration:'underline'
  },
}));
