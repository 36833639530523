import { PaginateList } from 'src/app/common/types/common-types';
import { SurveyListItem } from 'src/app/modules/Survey/types/survey-types';
import { completeMessage } from '../redux/CustomerRedux';

export enum SurveyFormMode {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
}

export type CustomerSurveyDetail = {
  _id: string;
  startDate: Date;
  endDate: Date;
  createdAt: Date;
  updatedAt: Date;
  body: Record<string, unknown>;
  consents: string[];
  numberofTnc: number;
  completeMessage: completeMessage;
  surveyImage?: string;
  bannerId: string;
  status: string;
  flyerMessage: string;
  enableTargetAgents: boolean;
  targetAgents: Array<{ agentCode: string }>;
};

export type SurveyPaginateList = PaginateList<SurveyListItem> & {
  totalRecords: number;
};

export type CustomerSurveyResultsPaginate = {
  data: any[];
  columns: Record<string, string>;
  page: number;
  hasNextPage: boolean;
};

export type SurveyResultItem = {
  agentCode: string;
  body: Record<string, unknown>;
  createdAt: Date;
  updatedAt: Date;
};

export type SurveyResult = {
  surveyId: string;
  surveyBody: Record<string, unknown>;
  surveyResults: SurveyResultItem[];
  page: number;
  hasNextPage: boolean;
};

export type SurveySummaryResult = {
  totalViews: number;
  totalSubmits: number;
  totalRecords: number;
  totalUnread: number;
  totalIncomplete: number;
};
