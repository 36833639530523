import { apiClient, ADMIN_URL } from 'src/app/common/network';

const apis = {
  getSummaryReport: async (params?: any) => {
    return apiClient.get(`${ADMIN_URL}/report/prudna/specific/summary`, {
      params,
      headers: { Accept: 'application/json' },
    });
  },
  getSummaryReportExcel: async (params?: any) => {
    return apiClient.get(`${ADMIN_URL}/report/prudna/specific/summary/excel`, {
      params,
      responseType: 'blob',
      headers: { Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
    });
  },
  getDetailReport: async (params?: any) => {
    return apiClient.get(`${ADMIN_URL}/report/prudna/specific/detail`, {
      params,
      headers: { Accept: 'application/json' },
    });
  },
  getDetailReportExcel: async (params?: any) => {
    return apiClient.get(`${ADMIN_URL}/report/prudna/specific/detail/excel`, {
      params,
      responseType: 'blob',
      headers: { Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
    });
  },
  getMassSummaryReport: async (params?: any) => {
    return apiClient.get(`${ADMIN_URL}/report/prudna/mass/summary`, {
      params,
      headers: { Accept: 'application/json' },
    });
  },
  getMassSummaryReportExcel: async (params?: any) => {
    return apiClient.get(`${ADMIN_URL}/report/prudna/mass/summary/excel`, {
      params,
      responseType: 'blob',
      headers: { Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
    });
  },
  getMassDetailReport: async (params?: any) => {
    return apiClient.get(`${ADMIN_URL}/report/prudna/mass/detail`, {
      params,
      headers: { Accept: 'application/json' },
    });
  },
  getMassDetailReportExcel: async (params?: any) => {
    return apiClient.get(`${ADMIN_URL}/report/prudna/mass/detail/excel`, {
      params,
      responseType: 'blob',
      headers: { Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
    });
  },
  getZones: async (params?: any) => {
    return apiClient.get(`${ADMIN_URL}/agents/zones`, { params });
  },
  getDesignation: async (params?: any) => {
    return apiClient.get(`${ADMIN_URL}/agents/designations`, { params });
  },
  getAggregateFields: async (params?: any) => {
    const searchParams = new URLSearchParams();
    const { paths, ...restParams } = params;
    paths.forEach((path: string) => {
      searchParams.append('paths', path);
    });
    return apiClient.get(`${ADMIN_URL}/agents/profile-fields-aggregate?${searchParams.toString()}`, {
      params: restParams,
    });
  },
};
export default apis;
