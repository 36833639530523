import React from 'react';
import { FormControlLabel, Checkbox} from '@mui/material';

interface CheckGroupProps {
  value?: any[];
  onChange?: (e: any[]) => void;
  options: { label: string; value: string }[]
  disabled: boolean;
}

export default function CheckGroup(props: CheckGroupProps) {
  const { value = [], onChange, options, disabled } = props

  const handleChange = (key: string) => (e: any) => {  
    const checked = e.target.checked
    const index = value.indexOf(key)
    const newValue = [...value]
    if (!onChange) return
    if (checked) {
      if (index === -1) {
        newValue.push(key)
      }
    } else {
      if (index > -1) {
        newValue.splice(index, 1)
      }
    }

    onChange(newValue);
  }

  return (
    <div
      style={{ display: 'flex-wrap', flexDirection: 'row' }}
    >
      {
        options.map(({label, value: itemValue}, index) => (
          <FormControlLabel
            key={itemValue || index}
            control={<Checkbox disabled={disabled} checked={value.indexOf(itemValue) > -1} onChange={handleChange(itemValue)} name={label} />}
            label={label}
          />
        ))
      }
    </div>
  )
}
