import React, { FC } from "react";
import { RouteComponentProps, Switch, Redirect } from "react-router-dom";
import ParamsProvider from "src/app/common/components/ParamsProvider";
import PruRoute from "../../../../common/components/PruRoute";
import { ModulePermissionProps } from "../../../../common/module/types";
import { CONFIG_AGENT_PATH, CONFIG_BASE_PATH } from "../../constants";
import ConfigAgentListPage, { configAgentFilterKeys } from "../Agent/List/ConfigAgentListPage";
import ConfigAgentDetailPage from "./View/ConfigAgentDetailPage";

const configAgentPath = `${CONFIG_BASE_PATH}${CONFIG_AGENT_PATH}`;

const ConfigAgentPage: FC<RouteComponentProps & ModulePermissionProps> = () => {
  return (
    <Switch>
      <Redirect exact from={configAgentPath}
        to={`${configAgentPath}/list`} />
      <PruRoute
        exact
        path={`${configAgentPath}/list`}
        render={(props) => (
          <ParamsProvider {...props} acceptKeys={configAgentFilterKeys}>
            <ConfigAgentListPage />
          </ParamsProvider>
        )}
      />
      
      <PruRoute 
        exact 
        path={`${configAgentPath}/edit/:id`}
        component={ConfigAgentDetailPage} 
      />

      <PruRoute
        exact
        path={`${configAgentPath}/create`}
        component={ConfigAgentDetailPage}
      />
    </Switch>
  )
};

export default ConfigAgentPage;