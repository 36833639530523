import { FC, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { map } from 'lodash';
import { useDataProvider } from 'src/app/common/utils';
import { PaginateList } from 'src/app/common/types';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { PruTableSortTypeEnum } from 'src/app/common/components/PruTable/PruTable';
import {
  AgentStatusEnum,
  SecretaryStatusEnum,
  SecretaryStatusReasonEnum,
  SecretaryListItem,
  SecretaryTypeItem,
  SecretaryAuthPolicyItem,
} from '../../types/secretary-types';
import {
  SecretaryListParam,
  fetchSecretaryList,
  fetchSecretaryTypes,
  fetchSecretaryAuthPolicy,
} from '../../network/secretaryCrud';
import SecretaryList from './components/SecretaryList';

type SecretaryListingPageProps = ParamsProps;

const initialState: SecretaryListParam = {
  firstName: '',
  lastName: '',
  secretaryId: '',
  type: '',
  agentCode: '',
  agentName: '',
  agentStatus: '',
  secretaryStatus: '',
  statusReasons: [],
  page: 1,
  limit: 20,
};

export const secretaryFilterKeys = [
  'firstName',
  'lastName',
  'secretaryId',
  'type',
  'agentCode',
  'agentName',
  'agentStatus',
  'secretaryStatus',
  'statusReasons',
];

const paramsInitiator = (initialParams?: Record<string, string>): SecretaryListParam => {
  return initialParams
    ? {
        firstName: initialParams.firstName || '',
        lastName: initialParams.lastName || '',
        secretaryId: initialParams.secretaryId || '',
        type: initialParams.type || '',
        agentCode: initialParams.agentCode || '',
        agentName: initialParams.agentName || '',
        agentStatus: initialParams.agentStatus || '',
        secretaryStatus: initialParams.secretaryStatus || '',
        statusReasons: initialParams.statusReasons ? initialParams.statusReasons.split(',') : [],
        page: 1,
        limit: 20,
      }
    : initialState;
};

const SecretaryListingPage: FC<SecretaryListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const [filterState, setFilterState] = useState<SecretaryListParam>(paramsInitiator(initialParams));
  const [secretaryList, setSecretaryList] = useState<PaginateList<SecretaryListItem>>();
  const [secretaryTypes, setSecretaryTypes] = useState<SecretaryTypeItem[]>([]);
  const [secretaryAuthPolicy, setSecretaryAuthPolicy] = useState<SecretaryAuthPolicyItem>();

  const { isLoading, refreshData } = useDataProvider<PaginateList<SecretaryListItem>>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchSecretaryList(filterState, dispatch);
      } catch (err) {}
    },
    setSecretaryList,
    false,
  );

  const reloadConfigData = async () => {
    try {
      const typeRes = await fetchSecretaryTypes(dispatch);
      setSecretaryTypes(typeRes.types);
      const policyRes = await fetchSecretaryAuthPolicy(dispatch);
      setSecretaryAuthPolicy(policyRes);
    } catch (err) {}
  };

  useEffect(() => {
    reloadConfigData();
  }, []);

  const onSort = (newSortState: { key: string; value?: string }) => {
    const { key, value } = newSortState;
    if (value) {
      let newSort = '';
      switch (value) {
        case PruTableSortTypeEnum.ASC:
          newSort = key;
          break;
        case PruTableSortTypeEnum.DESC:
          newSort = `-${key}`;
          break;
      }
      if (key === 'updatedAt') {
        newSort = `${newSort} secretaryId`;
      }
      setFilterState({ ...filterState, sort: newSort });
    } else {
      setFilterState({ ...filterState, sort: undefined });
    }
    refreshData();
  };

  return (
    <>
      <PruFilter
        title={Translation('secretary.filter')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'firstName',
            initialValue: filterState.firstName,
            displayName: Translation('secretary.common.first_name'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'lastName',
            initialValue: filterState.lastName,
            displayName: Translation('secretary.common.last_name'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'secretaryId',
            initialValue: filterState.secretaryId,
            displayName: Translation('secretary.common.pruforce_id'),
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'type',
            initialValue: filterState.type,
            displayName: Translation('secretary.common.access_type'),
            choices: [
              { displayName: '', value: '' },
              ...secretaryTypes.map((item) => ({ displayName: item.name, value: item.code })),
            ],
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'agentCode',
            initialValue: filterState.agentCode,
            displayName: Translation('secretary.common.agent_leader_code'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'agentName',
            initialValue: filterState.agentName,
            displayName: Translation('secretary.common.agent_leader_name'),
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'agentStatus',
            initialValue: filterState.agentStatus,
            displayName: Translation('secretary.common.agent_leader_status'),
            choices: [
              { displayName: '', value: '' },
              {
                displayName: Translation(`secretary.common.agent_leader_status.${AgentStatusEnum.ACTIVE}`),
                value: AgentStatusEnum.ACTIVE,
              },
              {
                displayName: Translation(`secretary.common.agent_leader_status.${AgentStatusEnum.TERMINATED}`),
                value: AgentStatusEnum.TERMINATED,
              },
            ],
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'secretaryStatus',
            initialValue: filterState.secretaryStatus,
            displayName: Translation('secretary.common.secretary_status'),
            choices: [
              { displayName: '', value: '' },
              ...map(SecretaryStatusEnum, (option: SecretaryStatusEnum) => ({
                displayName: Translation(`secretary.common.secretary_status.${option}`),
                value: option,
              })),
            ],
          },
          {
            type: PruFilterItemType.MULTIPLE_DROPDOWN,
            style: { minWidth: 250, maxWidth: 250 },
            field: 'statusReasons',
            initialValue: filterState.statusReasons,
            displayName: Translation('secretary.common.status_reason'),
            choices: [
              ...map(SecretaryStatusReasonEnum, (option: SecretaryStatusReasonEnum) => ({
                displayName: Translation(`secretary.common.status_reason.${option}`),
                value: option,
              })),
            ],
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            firstName: data.firstName,
            lastName: data.lastName,
            secretaryId: data.secretaryId,
            type: data.type,
            agentCode: data.agentCode,
            agentName: data.agentName,
            agentStatus: data.agentStatus,
            secretaryStatus: data.secretaryStatus,
            statusReasons: data.statusReasons,
          })
        }
        fetchData={refreshData}
      />
      <SecretaryList
        isLoading={isLoading}
        secretaryList={secretaryList}
        secretaryTypes={secretaryTypes}
        secretaryAuthPolicy={secretaryAuthPolicy}
        onRefresh={refreshData}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
        onSort={onSort}
      />
    </>
  );
};

export default SecretaryListingPage;
