import React, { FC, Fragment, useState } from 'react';
import { FeedbackCategoryPaginateList, FeedbackCategoryItem } from '../../../types/feedback-type';
import { usePaginateHandler, getDefaultDisplayDate } from 'src/app/common/utils';
import { useCommonStyles } from '../../../../../common/styles/common-styles';
import RefreshIcon from '@mui/icons-material/Refresh';
import { makeStyles } from 'tss-react/mui';
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Tooltip,
  IconButton,
  Button,
  TableBody,
  TableFooter,
  TablePagination,
} from '@mui/material';
import { useIntl } from 'react-intl';
import { PruTableHeader } from '../../../../../common/components/Table/PruTableHeader';
import PruTableLoading from '../../../../../common/components/Table/PruTableLoading';
import PruTableEmptyRow from '../../../../../common/components/Table/PruTableEmptyRow';
import { PruTableRow } from '../../../../../common/components/Table/PruTableRow';
import PruTablePaginationActions from '../../../../../common/components/Table/PruTablePaginationActions';
import CategoryDeleteDialog from './CategoryDeleteDialog';
import CategoryCreateDialog, { CategoryDialogMode } from './CategoryContentDialog';
import { getAvailableServerLocale } from 'src/app/i18n';

type CategoryListProps = {
  isLoading: boolean;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
  feedbackCategoryList?: FeedbackCategoryPaginateList;
};

const useStyles = makeStyles()({
  table: {
    minWidth: 700,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  tableHeader: {
    width: '100%',
    padding: '20px 0 20px 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  footer: {
    width: '100%',
  },
  operationContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  operationBtn: {
    color: 'blue',
    cursor: 'pointer',
    textDecoration: 'underline',
    fontSize: '0.85rem',
    whiteSpace: 'nowrap',
  },
  disabled: {
    color: '#BBBBBB',
    cursor: 'default',
  },
  subCatOuterCol: {
    padding: 0,
    position: 'relative',
    overflow: 'hidden',
  },
  subCatInnerCol: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: '0 16px',
    overflow: 'hidden',
  },
  subCatContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    border: '1px solid black',
    transform: 'translate(50%, -50%)',
  },
  subCatLinker: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderLeft: '1px solid black',
    transform: 'translate(50%, 50%)',
  },
});

type DialogContentState = {
  open: boolean;
  mode: CategoryDialogMode;
  id?: string;
  mainCatRow?: FeedbackCategoryItem;
  subCatIndex?: number;
};

type DeleteDialogState = {
  open: boolean;
  isSubCat: boolean;
  id: string;
  mainCatRow?: FeedbackCategoryItem;
  subCatId?: string;
};

const initialDialogContentState: DialogContentState = {
  open: false,
  mode: CategoryDialogMode.ADD_MAIN,
  id: undefined,
  mainCatRow: undefined,
  subCatIndex: undefined,
};

const initialDeleteDialogState: DeleteDialogState = {
  open: false,
  id: '',
  isSubCat: false,
  mainCatRow: undefined,
  subCatId: undefined,
};

const CategoryList: FC<CategoryListProps> = ({ isLoading, onRefresh, onChangePage, feedbackCategoryList }) => {
  const availableLocales = getAvailableServerLocale();
  const { classes } = useStyles();
  const { classes: commonClasses } = useCommonStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const [dialogContentState, setDialogContentState] = useState<DialogContentState>(initialDialogContentState);
  const [deleteDialogState, setDeleteDialogState] = useState<DeleteDialogState>(initialDeleteDialogState);

  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } = usePaginateHandler(
    onChangePage,
    feedbackCategoryList?.totalPages,
  );

  return (
    <>
      <CategoryCreateDialog
        key={`category-dialog-${dialogContentState.open}`}
        mode={dialogContentState.mode}
        open={dialogContentState.open}
        id={dialogContentState.id}
        mainCatRow={dialogContentState.mainCatRow}
        subCatIndex={dialogContentState.subCatIndex}
        onClose={() => setDialogContentState(initialDialogContentState)}
        onRefresh={onRefresh}
      />
      <CategoryDeleteDialog
        key={`delete-category-dialog-${deleteDialogState.open}`}
        open={deleteDialogState.open}
        id={deleteDialogState.id}
        isSubCat={deleteDialogState.isSubCat}
        mainCatRow={deleteDialogState.mainCatRow}
        subCatId={deleteDialogState.subCatId}
        onClose={() => setDeleteDialogState(initialDeleteDialogState)}
        onRefresh={onRefresh}
      />
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell colSpan={3 + availableLocales.length}>
                <div className={commonClasses.header}>{Translation('feedbackCategory.list')}</div>
              </TableCell>
              <TableCell colSpan={3} align="right">
                <div style={{ justifyContent: 'flex-end' }} className={classes.rowContainer}>
                  <Tooltip title="Refresh">
                    <IconButton onClick={onRefresh}>
                      <RefreshIcon />
                    </IconButton>
                  </Tooltip>
                  <Button
                    style={{ marginLeft: 15 }}
                    variant="contained"
                    color="secondary"
                    onClick={() =>
                      setDialogContentState({
                        open: true,
                        mode: CategoryDialogMode.ADD_MAIN,
                        id: undefined,
                        mainCatRow: undefined,
                        subCatIndex: undefined,
                      })
                    }
                  >
                    {Translation('feedbackCategory.newFeedbackCategory')}
                  </Button>
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <PruTableHeader align="center">{Translation('feedbackCategory.common.id')}</PruTableHeader>
              {availableLocales.map((locale) => (
                <PruTableHeader key={`feedback-category-row-name-${locale}`} align="center">
                  {Translation(`feedbackCategory.common.name.${locale}`)}
                </PruTableHeader>
              ))}
              <PruTableHeader align="center">{Translation('feedbackCategory.common.responsibleParty')}</PruTableHeader>
              <PruTableHeader align="center">{Translation('feedbackCategory.common.emails')}</PruTableHeader>
              <PruTableHeader align="center">{Translation('feedbackCategory.common.createdAt')}</PruTableHeader>
              <PruTableHeader align="center">{Translation('feedbackCategory.common.updatedAt')}</PruTableHeader>
              <PruTableHeader align="center">{Translation('section.common.operation')}</PruTableHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            <PruTableLoading isLoading={isLoading} />
            <PruTableEmptyRow
              isEmpty={
                !!(
                  feedbackCategoryList &&
                  feedbackCategoryList.categories &&
                  feedbackCategoryList.categories.length <= 0
                )
              }
            />
            {feedbackCategoryList &&
              feedbackCategoryList.categories &&
              feedbackCategoryList.categories.map((row, index) => (
                <Fragment key={index}>
                  <PruTableRow>
                    <TableCell align="center">{row._id}</TableCell>
                    {availableLocales.map((locale) => (
                      <TableCell key={`feedback-category-column-name-${locale}`} align="center">
                        {row.name[locale] || '-'}
                      </TableCell>
                    ))}
                    <TableCell align="center">-</TableCell>
                    <TableCell align="center">-</TableCell>
                    <TableCell align="center">{getDefaultDisplayDate(row.createdAt, 'datetime')}</TableCell>
                    <TableCell align="center">{getDefaultDisplayDate(row.updatedAt, 'datetime')}</TableCell>
                    <TableCell align="center">
                      <div className={classes.operationContainer}>
                        <Tooltip title="Add Sub Category">
                          <div
                            style={{ marginRight: 10 }}
                            className={classes.operationBtn}
                            onClick={() =>
                              setDialogContentState({
                                open: true,
                                mode: CategoryDialogMode.ADD_SUB,
                                id: row._id,
                                mainCatRow: row,
                                subCatIndex: undefined,
                              })
                            }
                          >
                            {Translation('section.common.operation.addSubCategory')}
                          </div>
                        </Tooltip>
                        <Tooltip title="Edit Category">
                          <div
                            style={{ marginRight: 10 }}
                            className={classes.operationBtn}
                            onClick={() =>
                              setDialogContentState({
                                open: true,
                                mode: CategoryDialogMode.EDIT_MAIN,
                                id: row._id,
                                mainCatRow: row,
                                subCatIndex: undefined,
                              })
                            }
                          >
                            {Translation('section.common.operation.edit')}
                          </div>
                        </Tooltip>
                        <Tooltip title="Delete Category">
                          <div
                            className={classes.operationBtn}
                            onClick={() =>
                              setDeleteDialogState({
                                open: true,
                                id: row._id,
                                isSubCat: false,
                                mainCatRow: row,
                                subCatId: undefined,
                              })
                            }
                          >
                            {Translation('section.common.operation.delete')}
                          </div>
                        </Tooltip>
                      </div>
                    </TableCell>
                  </PruTableRow>
                  {row.subCategories &&
                    row.subCategories.length > 0 &&
                    row.subCategories.map((subCat, subCatIndex) => (
                      <PruTableRow key={`${row._id}-sub-category-${subCatIndex}`}>
                        <TableCell className={classes.subCatOuterCol}>
                          <div className={classes.subCatInnerCol}>
                            <div className={classes.subCatContainer} />
                            {row.subCategories[subCatIndex + 1] && <div className={classes.subCatLinker} />}
                          </div>
                        </TableCell>
                        {availableLocales.map((locale) => (
                          <TableCell key={`feedback-category-column-subcat-name-${locale}`} align="center">
                            {subCat.name[locale] || '-'}
                          </TableCell>
                        ))}
                        <TableCell align="center">{subCat.responsibleParty}</TableCell>
                        <TableCell align="center">{subCat.emails}</TableCell>
                        <TableCell align="center">{getDefaultDisplayDate(row.createdAt, 'datetime')}</TableCell>
                        <TableCell align="center">{getDefaultDisplayDate(row.updatedAt, 'datetime')}</TableCell>
                        <TableCell align="center">
                          <div className={classes.operationContainer}>
                            <Tooltip title="Edit Sub Category">
                              <div
                                style={{ marginRight: 10 }}
                                className={classes.operationBtn}
                                onClick={() =>
                                  setDialogContentState({
                                    open: true,
                                    mode: CategoryDialogMode.EDIT_SUB,
                                    id: row._id,
                                    mainCatRow: row,
                                    subCatIndex: subCatIndex,
                                  })
                                }
                              >
                                {Translation('section.common.operation.edit')}
                              </div>
                            </Tooltip>
                            <Tooltip title="Delete Sub Category">
                              <div
                                className={classes.operationBtn}
                                onClick={() =>
                                  setDeleteDialogState({
                                    open: true,
                                    id: row._id,
                                    isSubCat: true,
                                    mainCatRow: row,
                                    subCatId: subCat._id,
                                  })
                                }
                              >
                                {Translation('section.common.operation.delete')}
                              </div>
                            </Tooltip>
                          </div>
                        </TableCell>
                      </PruTableRow>
                    ))}
                </Fragment>
              ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                align="right"
                rowsPerPageOptions={[5, 10, 20, 50]}
                colSpan={12}
                count={feedbackCategoryList ? feedbackCategoryList.totalRecords : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={PruTablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};

export default CategoryList;
