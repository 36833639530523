import React, { FC } from "react";
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import PruRoute from 'src/app/common/components/PruRoute';
import { PULSELEADS_BASE_PATH } from '../../../constants';
import { PULSELEADS_AGENT_PATH, PULSELEADS_AGENT_REDISTRIBUTE_LEAD_PATH } from '../constants';
import RedistributeLeadPage from './Form/RedistributeLeadPage';

export const redistributeLeadPath = `${PULSELEADS_BASE_PATH}${PULSELEADS_AGENT_PATH}${PULSELEADS_AGENT_REDISTRIBUTE_LEAD_PATH}`;

const RedistributeLeadRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <PruRoute exact path={`${redistributeLeadPath}`} component={RedistributeLeadPage}/>
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default RedistributeLeadRoutes;