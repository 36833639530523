import React, { FC, useState, useEffect, useImperativeHandle } from 'react';
import { Button, Dialog, FormHelperText } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useIntl } from 'react-intl';
import ProTable from 'src/app/common/components/ProTable';
import columns from './columns';
import { regionLocale } from 'src/app/i18n';
import {
  fetchSurveyItem,
  fetchSurveyResultList,
  SurveyResultListParam,
} from 'src/app/modules/Survey/network/surveyCrud';
import { useLang } from 'src/app/i18n';

const useStyles = makeStyles()((theme) => ({
  modalButtons: {
    marginTop: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialog: {
    padding: '15px',
  },
}));

const SpecificDeeplinkList: FC<any> = (props) => {
  const { selectId, onSelectItem, isView } = props;
  const locale = useLang();

  const filterInitData = {
    timeRange: [],
  };
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [popUpVisible, setPopUpVisible] = useState<boolean>(false);
  const [selectedRowsState, setSelectedRows] = useState<any>(null);
  let initParam: SurveyResultListParam = {
    startDateFrom: null,
    startDateTo: null,
    endDateTo: null,
    endDateFrom: new Date(),
    page: 1,
    limit: 20,
  };
  useEffect(() => {
    const fetchAPI = async () => {
      await fecthSurveyById(selectId);
    };
    fetchAPI();

    return;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setPopUpVisible(false);
  };

  const onSelect = () => {
    setPopUpVisible(true);
  };

  const formatSurveyData = async (newFilterState?: any) => {
    const newFilter = newFilterState || {};
    const requestParams = {
      ...newFilter,
    };

    requestParams.pageSize = requestParams.pageSize || 20;
    requestParams.surveyTimeStart = requestParams.timeRange?.[0] || '';
    requestParams.surveyTimeEnd = requestParams.timeRange?.[1] || '';
    delete requestParams.timeRange;
    const result = await fetchSurveyResultList(initParam);
    const res = {
      data: result.items || [],
      success: true,
      total: result.totalRecords,
    };

    return res;
  };

  const fecthSurveyById = async (surveyId: string) => {
    const surveyDetail = await fetchSurveyItem(surveyId);
    setSelectedRows(surveyDetail?.body);
  };

  const fetchData = (newFilterState?: any): Promise<any> => {
    return formatSurveyData(newFilterState);
  };

  const onSubmit = () => {
    onSelectItem(selectedRowsState?.id);
    setPopUpVisible(false);
  };

  return (
    <div style={{ width: '100%', alignSelf: 'flex-start' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {selectedRowsState && <span style={{ marginRight: 15 }}>{`${selectedRowsState.title}`}</span>}

        {!selectedRowsState && !isView && (
          <Button color="secondary" variant="contained" style={{ marginTop: 15 }} onClick={onSelect}>
            {Translation('deeplink.select.survey.label')}
          </Button>
        )}

        {!!selectedRowsState && !isView && (
          <Button color="secondary" variant="contained" style={{ marginTop: 15 }} onClick={onSelect}>
            {Translation('deeplink.clear.select.event.label')}
          </Button>
        )}
      </div>

      <Dialog open={popUpVisible} onClose={onCancel} fullScreen>
        <>
          <div style={{ display: 'flex', marginTop: 20 }}>
            <Button onClick={onCancel} style={{ marginLeft: 20 }} variant="contained">
              {Translation('global.back.btnText')}
            </Button>
          </div>
          <div style={{ padding: 20 }}>
            <ProTable
              rowKey="key"
              filterTitle={'Select Survey Item'}
              headerTitle={Translation('survey.list')}
              columns={columns(Translation, locale)}
              request={(params, sorter, filter) => {
                return fetchData(params);
              }}
              toolBarRender={() => []}
              rowSelection={{
                onChange: (_: any, selectedRows: any) => {
                  setSelectedRows(selectedRows[0]);
                },
                getCheckboxProps: (record: any) => {
                  return {
                    disabled: false,
                  };
                },
                selectItems: [selectedRowsState],
              }}
              singleSelect={true}
              pageSize={20}
            />
          </div>

          <div className={classes.modalButtons}>
            <Button onClick={onCancel} style={{ marginLeft: 20 }} variant="contained">
              {Translation('app.button.cancel')}
            </Button>
            <Button onClick={onSubmit} style={{ marginLeft: 20 }} variant="contained" color="secondary">
              {Translation('app.button.submit')}
            </Button>
          </div>
        </>
      </Dialog>
    </div>
  );
};

export default SpecificDeeplinkList;
