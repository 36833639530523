import { FC, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import CompanyPickList from './components/company-pick-list';
import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { getConfigurations, useDataProvider } from 'src/app/common/utils';
import { CompanyPickPaginateList, CompanyPickQueryParams, PublishedMode } from '../../type/types';
import { fetchCompanyPickList } from '../../network/api';
import { useLang } from 'src/app/i18n';
import { ModuleTypeList } from '../detail/components/MaterialArea/types';
import moment from 'moment';

const ListPage: FC<RouteComponentProps> = ({ history, location }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const locale = useLang();
  const enableRecruitLead = !!getConfigurations()?.Sales?.lead?.enableRecruitLead;
  const [filterState, setFilterState] = useState<Partial<CompanyPickQueryParams>>({
    page: 1,
    pageSize: 20,
    excludeType: enableRecruitLead ? '' : ModuleTypeList.Recruit,
  });
  const [sortKey, setSortKey] = useState<{ key: string; value?: string }[]>([{ key: 'updatedAt', value: 'desc' }]);
  const [list, setList] = useState<CompanyPickPaginateList>();
  const updateSortingKey = (sortingKey: { key: string; value?: string }) => {
    const sortingKeyArray = sortKey.filter((currentValue, index, arr) => {
      return currentValue.key !== sortingKey.key;
    });
    sortingKeyArray.unshift(sortingKey);
    setSortKey(sortingKeyArray);
  };

  const { isLoading, refreshData } = useDataProvider<CompanyPickPaginateList>(
    async () => {
      try {
        return await fetchCompanyPickList(filterState, sortKey, locale, dispatch);
      } catch (err) {}
    },
    setList,
    false,
    undefined,
    true,
  );
  React.useEffect(() => {
    if (sortKey) {
      refreshData();
    }
  }, [sortKey]);

  return (
    <>
      <PruFilter
        title={Translation('saleskit.companyPick.list.filter')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'name',
            displayName: Translation('saleskit.companyPick.list.name'),
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'status',
            displayName: Translation('saleskit.companyPick.list.status'),
            choices: [
              { displayName: Translation('component.status.all'), value: PublishedMode.ALL },
              { displayName: Translation('component.status.published'), value: PublishedMode.PUBLISHED },
              { displayName: Translation('component.status.unpublished'), value: PublishedMode.UNPUBLISHED },
              { displayName: Translation('component.status.draft'), value: PublishedMode.DRAFT },
            ],
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'effectiveDate',
            fieldTo: 'expiredDate',
            displayName: Translation('saleskit.companyPick.list.effectiveTime'),
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            name: data.name,
            status: data.status,
            effectiveDate: data.effectiveDate ? moment(data.effectiveDate).startOf('day').toDate() : null,
            expiredDate: data.expiredDate ? moment(data.expiredDate).endOf('day').toDate() : null,
          })
        }
        fetchData={refreshData}
      />
      <CompanyPickList
        isLoading={isLoading}
        onRefresh={refreshData}
        list={list}
        onSort={(dataKey) => updateSortingKey(dataKey)}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page: page,
            pageSize: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default ListPage;
