import React, { FC, Fragment } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import PruTable from '../../../../../../../common/components/PruTable/PruTable';
import { getDefaultDisplayDate } from 'src/app/common/utils';
import { PaginateList } from '../../../../../../../common/types/common-types';
import { BulkUploadStatusItem } from '../../../../../types/bulk-upload-types';
import { statusPath } from '../../BulkUploadStatusRoutes';

type BulkUploadStatusListProps = {
  isLoading: boolean;
  bulkUploadStatusList?: PaginateList<BulkUploadStatusItem>;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
};

const BulkUploadStatusList: FC<BulkUploadStatusListProps> = ({
  isLoading,
  bulkUploadStatusList,
  onRefresh,
  onChangePage,
}) => {
  const history = useHistory();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  return (
    <Fragment>
      <PruTable
        title={Translation('pulseleads.bulkUpload.list')}
        disableBulkSelect
        operationDef={[]}
        columnDef={[
          {
            isId: true,
            hidden: true,
            keyIndex: '_id',
            displayName: '',
            renderData: (row) => '',
          },
          {
            keyIndex: 'filename',
            displayName: Translation('pulseleads.bulkUpload.common.filename'),
            renderData: (row) => row.filename || '-',
          },
          {
            keyIndex: 'bulkUploadType',
            align: 'center',
            displayName: Translation('pulseleads.bulkUpload.common.bulkUploadType'),
            renderData: (row) => (row.type ? Translation(`pulseleads.bulkUpload.common.${row.type}`) : '-'),
          },
          {
            keyIndex: 'totalRow',
            align: 'center',
            displayName: Translation('pulseleads.bulkUpload.common.totalRow'),
            renderData: (row) => row.totalRow.toString() || '-',
          },
          {
            keyIndex: 'totalDistributed',
            align: 'center',
            displayName: Translation('pulseleads.bulkUpload.common.totalDistributed'),
            renderData: (row) => row.totalDistributed.toString() || '-',
          },
          {
            keyIndex: 'notDistributed',
            align: 'center',
            displayName: Translation('pulseleads.bulkUpload.common.notDistributed'),
            renderData: (row) => row.notDistributed.toString() || '-',
          },
          {
            keyIndex: 'rowSuccess',
            align: 'center',
            displayName: Translation('pulseleads.bulkUpload.common.rowSuccess'),
            renderData: (row) => row.rowSuccess.toString() || '-',
          },
          {
            keyIndex: 'rowFailed',
            align: 'center',
            displayName: Translation('pulseleads.bulkUpload.common.rowFailed'),
            renderData: (row) =>
              row.rowFailed > 0 ? (
                <a style={{ color: 'red' }} onClick={() => history.push(`${statusPath}/${row.resourceId}/failed/list`)}>
                  {row.rowFailed.toString()}
                </a>
              ) : (
                row.rowFailed.toString()
              ),
          },
          {
            keyIndex: 'scheduledUploadTime',
            align: 'center',
            displayName: Translation('pulseleads.bulkUpload.common.scheduleUploadAt'),
            renderData: (row) =>
              row.scheduledUploadTime ? getDefaultDisplayDate(new Date(row.scheduledUploadTime), 'datetime') : '',
          },
          {
            keyIndex: 'updatedAt',
            align: 'center',
            displayName: Translation('pulseleads.bulkUpload.common.updatedAt'),
            renderData: (row) => getDefaultDisplayDate(new Date(row.updatedAt), 'datetime'),
          },
        ]}
        isLoading={isLoading}
        onRefresh={onRefresh}
        dataSource={bulkUploadStatusList?.docs}
        totalPages={bulkUploadStatusList?.totalPages}
        totalRecords={bulkUploadStatusList?.totalDocs}
        onChangePage={onChangePage}
      />
    </Fragment>
  );
};

export default BulkUploadStatusList;
