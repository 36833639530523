import React, { FC, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { ColumnItem, ColumnPaginateList, ColumnPublishedMode } from '../../../types/column-types';
import PruTable, { PruTableColumnDef } from '../../../../../../../common/components/PruTable/PruTable';
import { getDefaultDisplayDate, getEnabledCustomWeight } from 'src/app/common/utils';
import { regionLocale, regionLocaleMap } from 'src/app/i18n';
import { fetchAllColumns } from '../../../network/columnCrud';
import _, { get } from 'lodash';

type ColumnListProps = {
  enableUpdate: boolean;
  enableCreate: boolean;
  columnTableTitle: string;
  onAddColumn: () => void;
  onEditColumn: (rowData: ColumnItem, mode: string) => void;
  module: string;
  status: any;
};

const PAGESIZE = 20;
const PAGE = 1;

const ColumnList = forwardRef(
  (
    { enableUpdate, enableCreate, columnTableTitle, onAddColumn, onEditColumn, module, status }: ColumnListProps,
    ref: any,
  ) => {
    const intl = useIntl();
    const Translation = (id: string) => intl.formatMessage({ id });
    const regionLocales = regionLocale;
    const [list, setList] = useState<any>([]);
    const isLoading = useRef(true);
    const columns = useRef([]);
    const pagesize = useRef(PAGESIZE);
    const page = useRef(PAGE);
    const sort = useRef<any>({
      updatedAt: 'desc',
    });
    const handleFilter = (list: any) => {
      if (status.current !== ColumnPublishedMode.ALL) {
        if (status.current === ColumnPublishedMode.ACTIVE) {
          return list.filter((item: any) => item.publishedAt !== null);
        } else {
          return list.filter((item: any) => item.publishedAt === null);
        }
      }

      return list;
    };
    const totalRecords = handleFilter(columns.current).length;

    useEffect(() => {
      refreshTable();
    }, []);

    const refreshTable = (reset = true) => {
      isLoading.current = true;
      setList((list: any) => [...list]);

      fetchAllColumns(module).then((data) => {
        isLoading.current = false;
        columns.current = data;

        if (reset) {
          page.current = PAGE;
          pagesize.current = PAGESIZE;
        }

        handleChangePages();
      });
    };

    useImperativeHandle(ref, () => ({
      refreshTable,
    }));

    const handleChangePages = (currentPage: number = page.current, currentPageSize: number = pagesize.current) => {
      if (currentPageSize !== pagesize.current) {
        currentPage = 1;
      }

      const startIndex = (currentPage - 1) * currentPageSize;
      const endIndex = startIndex + currentPageSize;
      const pagedData = handleFilter(handleSort(columns.current)).slice(startIndex, endIndex);

      page.current = currentPage;
      pagesize.current = currentPageSize;

      setList(pagedData);
    };

    const handleSort = (list: any) => {
      for (let orderBy in sort.current) {
        let order = sort.current[orderBy];
        if (order) {
          list = stableSort(list, getComparator(order, orderBy));
        }
      }

      return list;
    };

    const onSort: any = (current: any) => {
      sort.current = current;

      handleChangePages();
    };

    return (
      <PruTable
        title={Translation(columnTableTitle)}
        disableBulkSelect
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('app.button.add'),
            onClick: () => {
              onAddColumn();
            },
            condition: () => !!enableCreate,
          },
        ]}
        operationDef={[
          {
            title: Translation('app.button.view'),
            tooltipText: Translation('app.button.view'),
            onClick: (row) => {
              onEditColumn(row, 'view');
            },
          },
          {
            title: Translation('app.button.edit'),
            tooltipText: Translation('app.button.edit'),
            onClick: (row) => {
              onEditColumn(row, 'edit');
            },
            condition: () => !!enableUpdate,
          },
        ]}
        operationSticky={true}
        columnDef={[
          {
            isId: true,
            keyIndex: 'id',
            hidden: true,
            displayName: '',
            renderData: (row) => '',
          },
          ...regionLocales.map(
            (localeItem) =>
              ({
                keyIndex: `name.${localeItem}`,
                align: 'left',
                displayName: `${Translation('component.formLabel.published-to-module')}(${Translation(
                  regionLocaleMap[localeItem].name,
                )})`,
                renderData: (row: any) => String(row.name[localeItem] ? row.name[localeItem] : ''),
                sortable: true,
                onSort,
              }) as PruTableColumnDef<ColumnItem>,
          ),
          {
            keyIndex: 'description',
            align: 'left',
            displayName: Translation('component.formLabel.description'),
            renderData: (row) => String(row.description ? row.description : ''),
            sortable: true,
            onSort,
          },
          {
            keyIndex: 'weight',
            align: 'left',
            displayName: Translation('component.formLabel.weight'),
            renderData: (row) => String(row.weight ? row.weight : ''),
            sortable: true,
            onSort,
          },
          {
            keyIndex: 'status',
            align: 'left',
            displayName: Translation('component.formLabel.status'),
            renderData: (row) =>
              String(
                row.publishedAt
                  ? Translation('component.formSelectItem.active')
                  : Translation('component.formSelectItem.inactive'),
              ),
            sortable: true,
            onSort,
          },
          {
            keyIndex: 'updatedAt',
            align: 'left',
            displayName: Translation('component.formLabel.last-updated-time'),
            renderData: (row) => (row.updatedAt ? getDefaultDisplayDate(new Date(row.updatedAt), 'datetime') : ''),
            sortable: true,
            onSort,
          },
          {
            keyIndex: 'lastUpdatedBy',
            align: 'left',
            displayName: Translation('component.formLabel.last-updated-by'),
            renderData: (row) => String(row.lastUpdatedBy ? row.lastUpdatedBy : ''),
            sortable: true,
            onSort,
          },
        ]}
        defaultRowsPerPage={pagesize.current}
        defaultPageNumber={page.current}
        isLoading={isLoading.current}
        dataSource={list}
        onRefresh={() => refreshTable(false)}
        totalPages={Math.ceil(totalRecords / pagesize.current)}
        totalRecords={totalRecords}
        onChangePage={handleChangePages}
      />
    );
  },
);

export default ColumnList;

function descendingComparator(a: any, b: any, orderBy: string) {
  a = _.get(a, orderBy);
  b = _.get(b, orderBy);
  if (orderBy.includes('.')) {
    return a.localeCompare(b);
  }
  if (b < a) {
    return -1;
  }
  if (b > a) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator(order: Order, orderBy: string) {
  return order === 'desc'
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy);
}

function stableSort(array: any, comparator: any) {
  const stabilizedThis = array.map((el: any, index: number) => [el, index]);
  stabilizedThis.sort((a: any, b: any) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el: any) => el[0]);
}
