import React, { FC } from 'react';
import { Redirect, RouteComponentProps, Switch } from 'react-router-dom';
import PruRoute from '../../../../common/components/PruRoute';
import ParamsProvider from '../../../../common/components/ParamsProvider';
import FeedbackListingPage, { feedbackResultFilterKeys } from './List/FeedbackListingPage';
import FeedbackDetailPage from './View/FeedbackDetailPage';

const FeedbackResultPage: FC<RouteComponentProps> = ({ history, location, match }) => {
  return (
    <Switch>
      <Redirect exact from={match.path} to={`${match.path}/list`}/>
      <PruRoute
        exact
        path={`${match.path}/list`}
        render={(props) => (
          <ParamsProvider {...props} acceptKeys={feedbackResultFilterKeys}>
            <FeedbackListingPage />
          </ParamsProvider>
        )}
      />
      <PruRoute
        exact
        path={`${match.path}/view/:id`}
        component={FeedbackDetailPage}
      />
    </Switch>
  )
};

export default FeedbackResultPage;
