import React, { PropsWithChildren } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Button, Dialog, DialogTitle } from '@mui/material';
import { useIntl } from "react-intl";

interface BottonDialogProps {
  dialogTitle?: string;
  onCancel?: () => void;
  onOk: () => void;
  canncelBtnText?: string;
  confirmBtnText?: string;
  canCloseDialog?: boolean;
  open?: boolean;
  hideCancelBtn?: boolean;
  hideConfirmBtn?: boolean;
}

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: '20px'
  },
  btnContainer: {
    padding: '0 20px 20px 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
}));

const PruDialog: React.FC<PropsWithChildren<BottonDialogProps>> = ({onCancel = () => {}, onOk, canncelBtnText,
  canCloseDialog, confirmBtnText, children, dialogTitle, open = true, hideCancelBtn, hideConfirmBtn}) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  return (
    <Dialog open={open} onClose={canCloseDialog ? onCancel : () => {}}>
      {
        dialogTitle ? <DialogTitle>{ dialogTitle }</DialogTitle> : null
      }
      <div className={classes.container}>
        {
          children
        }
      </div>
      <div className={classes.btnContainer}>
        {
          hideCancelBtn ? null :
          <Button
            style={{ marginRight: 20 }}
            variant="contained"
            color="inherit"
            onClick={onCancel}
          >
            {canncelBtnText || Translation('global.text.no')}
          </Button>
        }
        {
          hideConfirmBtn ? null : <Button
            variant="contained"
            color="secondary"
            onClick={onOk}
          >
            {confirmBtnText || Translation('global.text.yes')}
          </Button>
        }
      </div>
    </Dialog>
  )
}

export default PruDialog;