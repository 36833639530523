import React, { useState, useEffect } from 'react';
import Form from 'src/app/common/components/Form';
import { useIntl } from 'react-intl';
import { TextField, MenuItem } from '@mui/material';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { getCategoryLabel } from '../../../../util';
import { getActiveCategory } from '../../../../network/collectionDetailCrud';
import DeadLineSection from './../../../../components/DeadLineSection';
import { NumberGreaterThanZeroPattern } from 'src/app/common/utils';
import { SelectTypes } from '../../../../constants';
import { styles as useDetailClass } from './../style';
import { regionLocale } from 'src/app/i18n';
import MutiLangList from './../../../../components/MutiLangList';

const CollectionDetail: React.FC<any> = (props) => {
  const { classes: commonClasses } = useCommonStyles();
  const { classes: detailClass } = useDetailClass();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const TranslationWithVariable = (key: string, name: number | string) => intl.formatMessage({ id: key }, { name });
  // const [categoryList, setCategoryList] = useState<{ [key: string]: any }>([])
  const { form, disabled = false, allCategoryList } = props;

  const DEFAULT_REQUIRED_RULES = {
    required: true,
    message: Translation('component.pulseleads.prospect.form-required-text'),
  };
  const placeSelect = Translation('app.input.placeholder.please-select');

  useEffect(() => {
    const fetchAPI = async () => {
      // await initialCategoryList();
    };

    fetchAPI();

    return;
    // eslint-disable-next-line
  }, []);

  const DEADLINE_VALIDATE = [
    {
      validator(_: any, data: any) {
        if (!data) {
          return Promise.resolve();
        }

        if (data.type && !data.value) {
          return Promise.reject(new Error(Translation('component.pulseleads.prospect.form-required-text')));
        }

        if (data.type && data.type !== SelectTypes.FIX_DATE.value && data.value) {
          const isGreaterThenZero = new RegExp(NumberGreaterThanZeroPattern).test(data.value);
          if (data.type !== SelectTypes.FIX_DATE.value && !isGreaterThenZero) {
            return Promise.reject(
              new Error(Translation('form.pulseleads.prospect.catefory.target.frequency.validate.message')),
            );
          }
        }

        return Promise.resolve();
      },
    },
  ];

  const CATEGORY_VALIDATE = {
    validator(_: any, value: any) {
      const selectItem = allCategoryList.find((item: any) => item.value === value);

      if (selectItem && selectItem?.status === 'inactive') {
        return Promise.reject(new Error(Translation('mandatory.training.category.inactive.msg')));
      }

      return Promise.resolve();
    },
  };

  const onDeadLineTypeChange = (key: string, value: any) => {
    const deadlineValues = form.getFieldValue('completionDeadline');

    const newData = {
      ...deadlineValues,
      [key]: value,
    };

    form.setFieldsValue({ completionDeadline: newData });
  };

  const onNameChange = (index: number, value: string) => {
    const formatedString = TranslationWithVariable(
      `mandatroy.training.reminder.notification.fixed.content.inbox.${regionLocale[index]}`,
      value,
    );

    const reminderData = form.getFieldValue(['reminder', 'i18nData']);

    let updateItem = reminderData.find((item: any) => item.locale === regionLocale[index]);
    updateItem['msgContent'] = formatedString;

    form.setFieldsValue({
      reminder: {
        i18nData: reminderData,
      },
    });
  };

  return (
    <>
      <div className={detailClass.divideMargin}>
        <div className={commonClasses.sessionHeader} style={{ marginTop: 0 }}>
          {Translation('mandatory.training.component.collection.detail.title')}{' '}
          <span className={detailClass.mandatory}>*</span>
        </div>
      </div>

      <Form.Item noStyle>
        {(_, meta, { getFieldValue }) => {
          return (
            <MutiLangList
              listName="i18nData"
              name="name"
              required={true}
              disabled={disabled}
              getFieldValue={getFieldValue}
              label={Translation('component.formLabel.name')}
              onTextChange={onNameChange}
            />
          );
        }}
      </Form.Item>

      <Form.Item
        label={Translation('mandatory.training.component.formLabel.completion.deadline.text')}
        labelTop={true}
        required={false}
      >
        <Form.Item name="completionDeadline" rules={DEADLINE_VALIDATE}>
          <DeadLineSection onDeadLineTypeChange={onDeadLineTypeChange} disabled={disabled} />
        </Form.Item>
      </Form.Item>

      <Form.Item noStyle>
        {(_, meta, { getFieldValue }) => {
          return (
            <MutiLangList
              listName="i18nData"
              name="remark"
              disabled={disabled}
              getFieldValue={getFieldValue}
              label={`${Translation('mandatory.training.component.formLabel.remark')}`}
            />
          );
        }}
      </Form.Item>

      <Form.Item
        label={Translation('component.formLabel.category')}
        name="categoryId"
        rules={[DEFAULT_REQUIRED_RULES, CATEGORY_VALIDATE]}
      >
        <TextField disabled={disabled} select margin="dense" variant="outlined" fullWidth>
          {allCategoryList &&
            allCategoryList.map((item: any, index: number) => {
              return (
                <MenuItem key={`category_${index}`} value={item.value}>
                  {item?.label}
                </MenuItem>
              );
            })}
        </TextField>
      </Form.Item>
    </>
  );
};

export default CollectionDetail;
