import React, { FC, useEffect, useState } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import PruRoute from 'src/app/common/components/PruRoute';
import { PROFILE_BASE_PATH, PROFILE_AGENT_PATH, PROFILE_AGENT_SETTING_PATH } from '../../constants';
import ParamsProvider from 'src/app/common/components/ParamsProvider';
import AgentProfileListingPage, { agentProfileFilterKeys } from './List/AgentProfileListingPage';
import AgentProfileMassUploadPage from './Upload/AgentProfileMassUploadPage';
import AgentProfileDetailPage from './Form/AgentProfileDetailPage';
import { fetchTierList } from '../../network/agentProfileCrud';
import { ConfigBannerTierListItem } from '../../types/agent-profile-types';
import { useDispatch } from 'react-redux';

export const agentProfilePath = `${PROFILE_BASE_PATH}${PROFILE_AGENT_PATH}${PROFILE_AGENT_SETTING_PATH}`;

const AgentProfileRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect exact from={agentProfilePath} to={`${agentProfilePath}/list`} />
      <PruRoute
        exact
        path={`${agentProfilePath}/list`}
        render={(props) => (
          <ParamsProvider {...props} acceptKeys={agentProfileFilterKeys}>
            <AgentProfileListingPage />
          </ParamsProvider>
        )}
      />
      <PruRoute exact path={`${agentProfilePath}/massUpload`} component={AgentProfileMassUploadPage} />
      <PruRoute exact path={`${agentProfilePath}/edit/:id`} component={AgentProfileDetailPage} />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default AgentProfileRoutes;
