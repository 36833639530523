import { makeStyles } from 'tss-react/mui';

import React from 'react';
import withCloseButton from './WithCloseButton';

type TextWithCloseButton = {
  content: string;
  onClose?: () => void;
};

export const styles = makeStyles()((theme) => ({
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
}));
const TextWithCloseButton: React.FC<TextWithCloseButton> = (props) => {
  const { classes } = styles();
  return (
    <>
      <span className={classes.field} {...props}>
        {props.content}
      </span>
    </>
  );
};

export default withCloseButton(TextWithCloseButton);
