import React, { useEffect, useState } from 'react';
import { useIntl } from "react-intl";
import { makeStyles } from 'tss-react/mui';
import { Button, TableSortLabel } from '@mui/material';
import Form from '../../../../../../common/components/Form';
import { CampaignTypeItem } from '../../../../types/staff-campaign-types';
import { AgencyCampaignTypeStatusEnum } from '../../../../constants';
import { ArrowDownwardOutlined } from '@mui/icons-material';


const campaignStatus: any[] = [
    { key: AgencyCampaignTypeStatusEnum.DRAFT },
    { key: AgencyCampaignTypeStatusEnum.PENDING },
    { key: AgencyCampaignTypeStatusEnum.APPROVED },
    { key: AgencyCampaignTypeStatusEnum.REJECTED }
];

const useStyles = makeStyles<void, 'checked'>()((theme, _params, classes) => ({
    footerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: 30
    },
    container: {
        padding: 20
    },
    accordHeading: {
        fontWeight: 'bold',
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '15%',
        flexShrink: 0,
    },
    radio: {
        [`&.${classes.checked}`]: {
            color: '#E8192C'
        }
    },
    checked: {},
    radioItem: {
        width: '100%',
        borderRadius: '12px',
        '&:hover': {
            backgroundColor: '#F4F4F4'
        }
    },
    selectButton: {
        height: 30,
        borderRadius: 100,
        border: "1px solid rgba(0, 0, 0, 0.12)",
        padding: "3px 12px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        color: "#999999",
        fontSize: "14px",
        lineHeight: "22px",
        marginRight: 10,
        marginTop: 5,
        marginBottom: 5
    },
    sectionTitle: {
        color: "#999999",
        fontWeight: 500
    },
    whiteButton: {
        backgroundColor: "white",
        color: "#333333",
        width: '50%',
        height: 50,
        borderRadius: 12,
        border: "2px solid #F0F0F0",
        marginRight: 5,
        fontFamily: 'Poppins, Helvetica, "sans-serif"'
    },
    redButton: {
        backgroundColor: "#E8192C",
        color: "white",
        width: '50%',
        height: 50,
        borderRadius: 12,
        fontFamily: 'Poppins, Helvetica, "sans-serif"'
    },
    rowFlexBox:{
        width: '100%',
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap"
    }
}));

interface AddRecordProps {
    onSave: (formData: any) => void;
    onCancel: () => void;
    isSort?: boolean;
    onSort?: () => void;
    direction?: 'asc' | 'desc';
    confirmLoading: boolean;
    campaignTypeList?: CampaignTypeItem[];
    initialValues?: { filterCampaignType: string[], filterCampaignStatus: AgencyCampaignTypeStatusEnum[] };
}


const FilterSelectForm: React.FC<AddRecordProps> = ({ onSave, onCancel, isSort, direction, onSort, confirmLoading, initialValues, campaignTypeList }) => {
    const { classes } = useStyles();
    const intl = useIntl();
    const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);
    const [filterCampaignType, setFilterCampaignType] = useState<string[]>(initialValues?.filterCampaignType ?? []);
    const [filterCampaignStatus, setFilterCampaignStatus] = useState<string[]>(initialValues?.filterCampaignStatus ?? []);
    const campaignType = campaignTypeList;


    const setSelectedCampaignType = (_id: string) => {
        let updatedFilterCampaignType = filterCampaignType;

        if (updatedFilterCampaignType.includes(_id)) {
            let indexToRemove = updatedFilterCampaignType.indexOf(_id);
            updatedFilterCampaignType.splice(indexToRemove, 1);
        } else {
            updatedFilterCampaignType.push(_id);
        }
        setFilterCampaignType([...updatedFilterCampaignType]);
    }

    const setSelectedCampaignStatus = (campaignStatusItem: string) => {
        let updatedFilterCampaignStatus = filterCampaignStatus;
        if (updatedFilterCampaignStatus.includes(campaignStatusItem)) {
            let indexToRemove = updatedFilterCampaignStatus.indexOf(campaignStatusItem);
            updatedFilterCampaignStatus.splice(indexToRemove, 1);
        } else {
            updatedFilterCampaignStatus.push(campaignStatusItem);
        }
        setFilterCampaignStatus([...updatedFilterCampaignStatus]);
    }

    const resetFilter = () => {
        setFilterCampaignType([]);
        setFilterCampaignStatus([]);
    }

    const _renderCampaignType = (campaignTypeItem: any) => {
        let isSelected: boolean = filterCampaignType.includes(campaignTypeItem._id);
        return (
            <div onClick={() => { setSelectedCampaignType(campaignTypeItem._id) }}
                className={classes.selectButton}
                style={{ ...(isSelected && { backgroundColor: "#38527E", color: "white" }) }}
            >{campaignTypeItem.name}</div>
        );
    }

    const _renderCampaignStatus = (campaignStatusItem: any) => {
        let isSelected: boolean = filterCampaignStatus.includes(campaignStatusItem.key);
        return (
            <div onClick={() => { setSelectedCampaignStatus(campaignStatusItem.key) }}
                className={classes.selectButton}
                style={{ ...(isSelected && { backgroundColor: "#38527E", color: "white" }) }}
            >{Translation(`agencyCampaign.common.status.${campaignStatusItem.key}`)}</div>
        );
    }

    return (
        <Form className={classes.container}>
            <div className={classes.sectionTitle}>
                {Translation('agencyCampaign.common.campaignType')}
                <TableSortLabel
                    active={isSort}
                    direction={direction || 'asc'}
                    onClick={onSort}
                    IconComponent={ArrowDownwardOutlined}
                />
            </div>
            <div className={classes.rowFlexBox}>
                {campaignType && campaignType.map((campaignTypeItem) => {
                    return _renderCampaignType(campaignTypeItem)
                })}
            </div>

            <div className={classes.sectionTitle}>{Translation("agencyCampaign.common.status")}</div>
            <div className={classes.rowFlexBox}>
                {campaignStatus.map((campaignStatusItem) => {
                    return _renderCampaignStatus(campaignStatusItem)
                })}
            </div>

            <div className={classes.footerContainer}>
                <>
                    <Button
                        variant="outlined"
                        color="inherit"
                        style={{ flex: 1}}
                        // className={classes.whiteButton}
                        onClick={() => { resetFilter() }}
                    >
                        {Translation('app.button.reset')}
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        style={{ flex: 1,marginLeft:20}}
                        // className={classes.redButton}
                        onClick={() => { onSave({ filterCampaignType: filterCampaignType, filterCampaignStatus: filterCampaignStatus, marginLeft: 5 }) }}
                    >
                        {Translation('app.button.confirm')}
                    </Button>

                </>
            </div>
        </Form>
    )
}

export default FilterSelectForm;