import { withStyles } from 'tss-react/mui';
import { Radio } from '@mui/material';

const AcmRadio = withStyles(Radio, (theme, _params, classes) => ({
  root: {
    [`&.${classes.checked}`]: {
      color: '#E8192C',
    },
    [`&.${classes.disabled}`]: {
      color: 'rgba(0, 0, 0, 0.26)',
    },
  },
  checked: {},
  disabled: {},
}));

export default AcmRadio;
