export const ROOT_PATH = `${window.envConfig['PORTAL_BASE_PATH']}/`;

export const NOT_FOUND_ERROR_PAGE_PATH = '/error/not-found';
export const FORBIDDEN_ERROR_PAGE_PATH = '/error/forbidden';

export const RESOURCE_NAMESPACE = 'RESOURCE';

export const MANDATORY_FIELD_ERROR_TEXT = 'This field is mandatory';
export const DATE_ERROR_TEXT = 'End date must be after Begin date';
export const TIME_ERROR_TEXT = 'End time must be after Start time';
export const DATE_FORMAT_ERROR_TEXT = 'Invalid date format';
export const EMAIL_FORMAT_ERROR_TEXT = 'Please fill in a valid email address';
export const ZERO_ERROR_TEXT = 'Please input a non-zero number';
export const VALIDATION_FIELD_ERROR_TEXT = 'Please fill in correct data';
export const DUPLICATE_ID_ERROR_TEXT = 'This ID already exists';
export const INVALID_PATTERN_ERROR_TEXT = 'Invalid data. Please remove redundant symbols';
export const INVALID_CURRENCY_ERROR_TEXT = 'Please fill in currency symbol followed by numbers';
export const MANDATORY_PRIORITY_ERROR_TEXT = 'Mandatory priorities';
export const NUMBER_NA_ONLY_ERROR_TEXT = 'Please enter a positive integer number or NA';
export const NUMBER_ONLY_ERROR_TEXT = 'Please enter a positive integer number';
export const AUTO_NOTI_FORMAT_ERROR_TEXT = 'Wrong input format: should be in “3d 12h 30m”';
export const AUTO_NOTI_TRIGGER_TIME_DUPLICATED = 'Same notification is existed on same event';

export const CONFIG_STORAGE_KEY = 'config';
export const BASE_APP_QUICK_PATH_CONFIG_STORAGE_KEY = 'base-app-quick-paths';

export const constants = {
  Standard_DateFormat: 'YYYY-MM-DDTHH:mm:ssZ',
  Default_Date_Format: 'DD/MM/YYYY',
  Date_Minute_Scale_Format: 'DD/MM/YYYY HH:mm',
};

export enum Routes {
  AUTH = 'auth',
  SIGN_IN = 'signin',
  FORGOT_PASSWORD = 'forgotpassword',
  DISCLAIMER = 'disclaimer',
  PRIVACY_POLICY = 'privacypolicy',

  //  Home
  HOME = 'home',
  HOME_LANDING = 'homelanding',
  HOME_CalendarDetail = 'homecalendardetail',
  HOME_BirthdayReminder = 'homebirthdayreminder',
  HOME_BirthdayGreetScreen = 'BirthdayGreetScreen',
  HOME_BirthdayCardScreen = 'BirthdayCardScreen',

  COMMON_ResourceSearch = 'commonresourcesearch',

  //  Policy
  POLICY_LANDING = 'policylanding',
  POLICY_SEARCH_RESULT = 'policysearchresult',
  POLICY_BASIC_SEARCH = 'policysearch',
  POLICY_ADV_SEARCH = 'policyadvsearch',
  POLICY_CLIENT_OVERVIEW = 'policyclientoverview',
  POLICY_DETAIL = 'policydetail',
  PROPOSAL_DETAIL = 'proposaldetail',
  PROPOSAL_ITEM = 'proposalitem',
  PROPOSAL_FOLLOW = 'proposalfollow',
  PROPOSAL_CUSTOMER_FOLLOW_UP = 'proposalcustomerfollowup',
  PROPOSAL_WAIT_APPROVAL = 'proposalwaitapproval',
  POLICY_ITEM_LIST = 'policyitemlist',
  POLICY_CLAIM_DETAIL = 'policyclaimdetail',
  POLICY_CLIENT_DETAIL = 'policyclientdetail',
  PREMIUM_DUE_DATE = 'premiumduedate',

  //  Performance
  INCENTIVE = 'incentive',
  Performance_Manager = 'performancemanager',
  Performance_Incentive = 'performanceincentive',
  Performance_Edit = 'performanceedit',
  Performance_MDRT = 'performancemdrt',
  Performance_Ranking = 'performanceranking',
  Performance_Convention = 'performanceconvention',

  //  Recruitment
  RECRUITMENT = 'recruitmenthome',
  Recruitment_CandidateSearch = 'recruitmentcandidatesearch',
  Recruitment_CandidateAdd = 'recruitmentcandidateadd',
  Recruitment_CandidateDetail = 'recruitmentcandidatedetail',
  Recruitment_CandidateProfile = 'recruitmentcandidateprofile',
  Recruitment_Webview = 'recruitmentwebview',
  Recruitment_Resource = 'recruitmentresource',
  Recruitment_Resource_Webview = 'recruitmentresourcewebview',
  Recruitment_Resource_Set = 'recruitmentresourceset',
  Recruitment_AssessLinkShare = 'recruitmentassesslinkshare',
  Recruitment_InterviewMeeting = 'recruitmentinterviewmeeting',
  Recruitment_InterviewNotes = 'recruitmentinterviewnotes',
  Recruitment_ArrangeInterview = 'recruitmentarrangeinterview',
  Recruitment_TraningIndex = 'recruitmenttraningindex',
  Recruitment_TraningMyteam = 'recruitmenttraningmyteam',
  Recruitment_CandidateRecruitStatus = 'recruitmentcandidaterecruitstatus',
  Recruitment_InterviewDetail = 'recruitmentinterviewdetail',
  Recruitment_Choose_Contacts = 'recruitmentcandidatechoosecontacts',
  Recruitment_Pending_Candidates = 'recruitmentpendingcandidates',
  Recruitment_Pru_Application = 'recruitmentpruapplication',
  Recruitment_Career_Status = 'recruitmentcareerstatus',

  //  Training
  TRAINING = 'training',
  Training_MyQrCodeScreen = 'myqrcodescreen',
  Training_WebviewScreen = 'trainwebviewscreen',
  Training_PRUExpert = 'trainpruexpert',
  Training_CPDTrackerScreen = 'traincpdtrackerscreen',
  Training_TrackerInfoScreen = 'traintrackerinfoscreen',

  //  Inbox
  INBOX = 'inbox',
  INBOX_DETAIL = 'inboxdetail',
  INBOX_SEARCH = 'inboxsearch',

  //  Sales
  SALES_KIT = 'saleskit', // landing page(user promotion)
  SALES_KIT_RESOURCE_SEARCH = 'saleskitsearch', // search page
  SALES_KIT_RESOURCE_WEBVIEW = 'saleskitwebview', // open resource in webview
  SALES_KIT_SALES_RESOURCE = 'SalesLandingScreen', // sales resource
  SALES_KIT_ENAME_CARD = 'saleskitenamecard', // eName Card
  SALES_KIT_AGENT_PROFILE = 'saleskitagentprofile', // Agent Profile
  SALES_KIT_LEAD_FORM = 'SalesKitLeadForm', // Agent lead form

  //  Common
  PROFILE = 'profile',
  ATTENDANCE = 'attendance',
  SETTINGS = 'settings',
  PRULEADS = 'pruleads',
  ASKPRU = 'askpru',
  WEBMAIL = 'webmail',
  PIL = 'pil',
  WEBVIEW = 'webview',
  NOTIFICATION_WEBVIEW = 'notificationwebview',
  ONLINE_VIDEO = 'onlinevideo',
  ONLINE_VIDEO_OVERVIEW = 'onlinevideooverview',
  DASHBOARD = 'dashboard',
  STAFFLOGON = 'stafflogon',
  AIS = 'ais',
  FEEDBACK = 'feedbackmain',
  NEWS = 'prunews',

  //Event
  EVENT_MARKET = 'EventListScreen',
  SPECIFIC_EVENT_LINK = 'EventV2DetailsScreen',

  //Survey
  SPECIFIC_SURVEY_LINK = 'surveydetail',
  SPECIFIC_TRAINING_LINK = 'MandatoryTrainingDetailsScreen',

  //Campaign
  SPECIFIC_CAMPAIGN_LINK = 'SalesCampaignDetailScreen',
}

export const SPECIFIC_ROUTES: string[] = [
  Routes.SPECIFIC_EVENT_LINK,
  Routes.SPECIFIC_TRAINING_LINK,
  Routes.SPECIFIC_SURVEY_LINK,
  Routes.SPECIFIC_CAMPAIGN_LINK,
];

export enum MTmode {
  CREATE = 'add',
  EDIT = 'edit',
  COPY = 'copy',
  VIEW = 'view',
}

export const trueFalseOptions = ['true', 'false'];

export enum DateFormat {
  date = 'DD/MM/YYYY', // dayjs not recognize yyyy(lower case)
  shortDate = 'DD/MM',
  datetime = 'DD/MM/YYYY HH:mm',
  longDatetime = 'DD/MM/YYYY HH:mm:ss',
  month = 'MM/YYYY',
  time = 'HH:mm',
  longTime = 'HH:mm:ss',
  /**
   * "THH:mm:ss.SSS" represents the time in hours, minutes, seconds, and milliseconds.
   * "[Z]" represents the literal character 'Z' to indicate that the time is in UTC.
   * usage:
   * Moment: moment.utc(new Date()).local().utc().format(DateFormat.utcTime)
   * Day.js: dayjs().utc().format(DateFormat.utcTime); // refer to: https://day.js.org/docs/en/plugin/utc
   */
  utcTime = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]',
  monthYear = 'MMM YYYY', // used in chart
  monthDay = 'MMM DD', // used in chart
  day = 'DD', // use in chart
}
