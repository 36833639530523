import React, { FC, useState, useEffect } from 'react';
import Form from 'src/app/common/components/Form';
import { useIntl } from 'react-intl';

import { TextField, Button, RadioGroup, FormControlLabel, Radio, Backdrop, CircularProgress } from '@mui/material';

import { useParams } from 'react-router-dom';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { commonStyles, styles } from './style';
import { activeType, dailyTargetType, MTmode } from '../../constants';
import { useDispatch } from 'react-redux';
import RuleSetting from './RuleSettingList';
import { regionLocale } from 'src/app/i18n';
import { AlertType, appendAlertItem } from 'src/redux/common/commonSlice';
import { checkTargetAgentList, createRuleSetting, getRulesettingDetail, updateRuleSetting } from '../../api';
import TargetAgent from './TargetAgent';
import { pointRuleSettingsRoutesPath } from '../Routes';
import { POINT_VIDATOR } from './util';

const Detail: FC<any> = ({ history, formMode }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [form] = Form.useForm();
  const { id } = useParams<{ id: string }>();
  const { classes: commonClasses } = useCommonStyles();
  const { classes: detailClass } = styles();
  const regionLocales = regionLocale;
  const isCreate = formMode === MTmode.CREATE;
  const dispatch = useDispatch();
  const [isDefaultRule, setIsDefaultRule] = useState<boolean>(false);

  useEffect(() => {
    const fetchDetail = async () => {
      try {
        setLoading(true);
        const res = await getRulesettingDetail(id);
        const convertData = {
          ...res?.data,
          isActive: res.data.isActive?.toString(),
          groupList: initRuleGroupData(res.data.ruleGroupData) || [],
          agentList: res.data.targetAgent.map((item: any) => {
            return { agentCode: item };
          }),
        };
        setLoading(false);
        form.setFieldsValue(convertData);
        setInitialValues(convertData);
        setIsDefaultRule(form.getFieldValue('isDefault'));
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    };
    if (formMode === MTmode.EDIT) {
      fetchDetail();
    }
  }, []);

  const DEFAULT_REQUIRED_RULES = { required: true, message: Translation('component.form-required-text') };
  const [initialValues, setInitialValues] = useState<any>({
    dailyTarget: { type: dailyTargetType.Day.value },
    isActive: 'true',
    groupList: [],
  });
  const [loading, setLoading] = useState<boolean>(false);

  const initRuleGroupData = (data: any[]) => {
    const tempData = data.map((item) => {
      let tempItem = item;
      if (Number(item.maxPoint) > 0) {
        tempItem = { ...tempItem, hasMaxPoint: 'yes' };
      } else {
        tempItem = { ...tempItem, hasMaxPoint: 'no' };
      }
      tempItem = { ...tempItem, enableGroupAction: tempItem?.enableGroupAction ? 'true' : 'false' };
      return tempItem;
    });
    return tempData;
  };

  const goBackToList = () => {
    history.push(pointRuleSettingsRoutesPath);
  };

  const sucessOperation = (msg: string) => {
    dispatch(
      appendAlertItem([
        {
          severity: AlertType.SUCCESS,
          title: 'Success',
          content: msg, //"Update RuleSettings Successfully",
        },
      ]),
    );
    goBackToList();
  };

  const onSubmit = () => {
    form
      .validateFields()
      .then((res) => {
        onSave();
      })
      .catch((err) => {
        return;
      });
    form.submit();
    return false;
  };

  const onAgentListCheck = async (params: any) => {
    const res = await checkTargetAgentList(params);
    if (res?.status !== 201) {
      console.log(res.status);
      return false;
    } else if (res.data?.length > 0) {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.ERROR,
            title: 'Error',
            content: 'Agent List have error !',
          },
        ]),
      );
      return false;
    } else {
      return true;
    }
  };

  const onSave = async () => {
    setLoading(true);
    let allValue = form.getFieldsValue(true);
    allValue = {
      ...allValue,
      ruleGroupData: allValue.groupList.map((group: any) => {
        group.enableGroupAction = group.enableGroupAction === 'true' ? true : false;
        if (!group.enableGroupAction) {
          delete group?.groupActionPoint;
          delete group?.groupActionCount;
          return group;
        } else {
          group.groupActionPoint = Number(group.groupActionPoint);
          group.groupActionCount = Number(group.groupActionCount);
          return group;
        }
      }),
      overrideAgent: allValue.agentList
        .map((item: { agentCode: string; isOverride: boolean }) => {
          if (item?.isOverride) {
            return item.agentCode;
          }
        })
        .filter((value: any) => value),
      targetAgent: allValue.agentList
        .map((item: { agentCode: string; isOverride: boolean }) => {
          if (!item?.isOverride) {
            return item.agentCode;
          }
        })
        .filter((value: any) => value),
      isActive: allValue.isActive === 'true' ? true : false,
    };
    delete allValue.groupList;
    try {
      if (isCreate) {
        allValue = { ...allValue, isDefault: false };
        const checkAgentList = {
          agents: allValue.agentList.map((item: any) => item.agentCode),
        };
        if (!onAgentListCheck(checkAgentList)) {
          return;
        }
        await createRuleSetting(allValue);
        sucessOperation('Create RuleSettings Successfully');
      } else {
        const checkAgentList = {
          agents: allValue.agentList.map((item: any) => item.agentCode),
          ruleSetId: id,
        };
        if (!onAgentListCheck(checkAgentList)) {
          return;
        }
        await updateRuleSetting(id, allValue);
        sucessOperation('Update RuleSettings Successfully');
      }
    } catch (error) {
      console.log(error);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.ERROR,
            title: 'Error',
            content: 'Error',
          },
        ]),
      );
    }
    setLoading(false);
  };

  return (
    <div className={`${commonClasses.container} ${detailClass.fixed}`}>
      <div className={detailClass.divideMargin}>
        <span className={commonClasses.header}>
          {isCreate ? Translation('app.button.add') : Translation('app.button.edit')}{' '}
          {Translation('pulseleads.activityPoint.ruleSettings.common.rule')}
        </span>
      </div>
      <div className={detailClass.marginLeft10}>
        <Form form={form} onFinish={onSave} initialValues={{ ...initialValues }} style={commonStyles.paddingBottom20}>
          <Form.Item
            label={Translation('pulseleads.activityPoint.ruleSettings.common.name')}
            name="name"
            rules={[DEFAULT_REQUIRED_RULES]}
          >
            <TextField fullWidth margin="dense" variant="outlined" size="small"></TextField>
          </Form.Item>

          <Form.Item
            name="isActive"
            label={Translation('pulseleads.activityPoint.ruleSettings.common.isActive')}
            rules={[DEFAULT_REQUIRED_RULES]}
          >
            <RadioGroup aria-label="IsActive" name="isActive" style={commonStyles.flexRow}>
              {Object.keys(activeType).map((key: string) => (
                <FormControlLabel
                  key={activeType[key].text}
                  value={activeType[key].value}
                  control={<Radio />}
                  label={activeType[key].text}
                  disabled={isDefaultRule}
                />
              ))}
            </RadioGroup>
          </Form.Item>

          <div>
            <Form.Item
              name={['dailyTarget', 'type']}
              label={Translation('pulseleads.activityPoint.ruleSettings.common.dailyTarget')}
              rules={[DEFAULT_REQUIRED_RULES]}
              labelTop={true}
            >
              <RadioGroup aria-label="dailyTarget" style={commonStyles.flexCol}>
                <FormControlLabel
                  key={dailyTargetType.Day.value}
                  value={dailyTargetType.Day.value}
                  control={<Radio />}
                  label={
                    <span style={commonStyles.flexRow} className="flexRowTip">
                      <span style={commonStyles.w200}>{dailyTargetType.Day.text}</span>
                      <Form.Item>
                        {(_, meta, { getFieldValue }) => {
                          const specifiyTargetType = getFieldValue('dailyTarget')?.type === dailyTargetType.Day.value;
                          return specifiyTargetType ? (
                            <Form.Item name={['dailyTarget', 'day']} rules={POINT_VIDATOR}>
                              <TextField
                                style={commonStyles.textInput}
                                margin="dense"
                                variant="outlined"
                                size="small"
                              />
                            </Form.Item>
                          ) : null;
                        }}
                      </Form.Item>
                    </span>
                  }
                />
                <FormControlLabel
                  key={dailyTargetType.Weekday.value}
                  value={dailyTargetType.Weekday.value}
                  control={<Radio />}
                  label={dailyTargetType.Weekday.text}
                />
              </RadioGroup>
            </Form.Item>
            <Form.Item style={commonStyles.ml200}>
              {(_, meta, { getFieldValue }) => {
                const specifiyTargetType = getFieldValue('dailyTarget')?.type === dailyTargetType.Weekday.value;
                return specifiyTargetType ? (
                  <div style={commonStyles.flexRow} className="flexRowWeekTip">
                    <Form.Item rules={POINT_VIDATOR} name={['dailyTarget', 'weekday']}>
                      <span style={commonStyles.flexRow}>
                        <span style={commonStyles.w150}>
                          <span>{`${Translation('pulseleads.activityPoint.ruleSettings.common.weekdayTarget')}:`}</span>
                          <span style={commonStyles.colorRed}>*</span>
                        </span>
                        <TextField
                          style={commonStyles.textInput}
                          margin="dense"
                          variant="outlined"
                          defaultValue={getFieldValue('dailyTarget')?.weekday}
                          size="small"
                        ></TextField>
                      </span>
                    </Form.Item>

                    <Form.Item rules={POINT_VIDATOR} name={['dailyTarget', 'weekend']}>
                      <span style={commonStyles.flexRow}>
                        <span style={commonStyles.w150}>
                          <span>{`${Translation('pulseleads.activityPoint.ruleSettings.common.weekendTarget')}:`}</span>
                          <span style={commonStyles.colorRed}>*</span>
                        </span>
                        <TextField
                          style={commonStyles.textInput}
                          margin="dense"
                          variant="outlined"
                          defaultValue={getFieldValue('dailyTarget')?.weekend}
                          size="small"
                        ></TextField>
                      </span>
                    </Form.Item>
                  </div>
                ) : null;
              }}
            </Form.Item>
          </div>

          <div>{<RuleSetting {...history} form={form} formMode={formMode} data={initialValues?.groupList} />}</div>

          <div>
            {Translation('pulseleads.activityPoint.ruleSettings.common.remarks')}
            {regionLocales.map((locale: string) => {
              return (
                <div className={detailClass.remarkArea} key={locale}>
                  <span className={detailClass.remarkSubTitle}>{Translation(`navBar.lang.${locale}`)}</span>
                  <Form.Item name={['remarks', locale]}>
                    <TextField
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      multiline
                      InputProps={{
                        classes: {
                          input: detailClass.textArea,
                        },
                      }}
                    />
                  </Form.Item>
                </div>
              );
            })}
          </div>

          <div>
            <TargetAgent formMode={formMode} form={form} data={form.getFieldValue('agentList')} />
          </div>

          <Backdrop className={detailClass.backdrop} open={loading}>
            <CircularProgress color="secondary" />
          </Backdrop>

          <div className={detailClass.margin20}>
            <Form.Item noStyle>
              <div className={detailClass.buttonGroup}>
                <Button
                  variant="contained"
                  color="inherit"
                  disabled={loading}
                  style={commonStyles.ml15}
                  onClick={goBackToList}
                >
                  {Translation('app.button.cancel')}
                </Button>

                <Button
                  style={commonStyles.ml15}
                  variant="contained"
                  disabled={loading}
                  color="secondary"
                  onClick={() => {
                    onSubmit();
                  }}
                >
                  {Translation('app.button.submit')}
                </Button>
              </div>
            </Form.Item>
          </div>
        </Form>

        <Backdrop className={commonClasses.backdrop} open={loading}>
          <CircularProgress color="secondary" />
        </Backdrop>
      </div>
    </div>
  );
};

export default Detail;
