import { Dispatch } from 'react';
import { getQueryPath } from 'src/app/common/utils';
import { PaginateList } from 'src/app/common/types';
import { TranslationWithParams } from 'src/app/i18n';
import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import {
  UpdateSecretaryActionEnum,
  SecretaryApprovalEnum,
  SecretaryListItem,
  SecretaryItem,
  SecretaryTypeRes,
  SecretaryAuthPolicyItem,
} from '../types/secretary-types';

const secretaryEndpoint = 'secretary';

export type SecretaryListParam = {
  firstName: string;
  lastName: string;
  secretaryId: string;
  type: string;
  agentCode: string;
  agentName: string;
  agentStatus: string;
  secretaryStatus: string;
  statusReasons: string[];
  page: number;
  limit: number;
  sort?: string;
};

export type UpdateSecretaryBody = {
  action: UpdateSecretaryActionEnum;
  permissions?: string[];
};

export type SecretaryApprovalBody = {
  status: SecretaryApprovalEnum;
};

export const fetchSecretaryList = async (
  params: SecretaryListParam,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<SecretaryListItem>> => {
  const queryPath = getQueryPath(`${ADMIN_URL}/${secretaryEndpoint}?`, params);
  return apiClient
    .get<PaginateList<SecretaryListItem>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchSecretaryItem = async (id: string, dispatch?: Dispatch<any>): Promise<SecretaryItem> => {
  return apiClient
    .get<SecretaryItem>(`${ADMIN_URL}/${secretaryEndpoint}/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchSecretaryTypes = async (dispatch?: Dispatch<any>): Promise<SecretaryTypeRes> => {
  return apiClient
    .get<SecretaryTypeRes>(`${ADMIN_URL}/${secretaryEndpoint}/types`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchSecretaryAuthPolicy = async (dispatch?: Dispatch<any>): Promise<SecretaryAuthPolicyItem> => {
  return apiClient
    .get<SecretaryAuthPolicyItem>(`${ADMIN_URL}/${secretaryEndpoint}/auth/policies`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchSecretaryFile = async (id: string, blobId: string, dispatch?: Dispatch<any>): Promise<string> => {
  return apiClient
    .get<string>(`${ADMIN_URL}/${secretaryEndpoint}/${id}/files/${blobId}/download`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const modifySecretaryItem = async (
  id: string,
  body: UpdateSecretaryBody,
  dispatch?: Dispatch<any>,
  params?: Record<string, string | number>,
): Promise<void> => {
  return apiClient
    .put<void>(`${ADMIN_URL}/${secretaryEndpoint}/${id}`, body)
    .then((response) => response.data)
    .catch((err) => {
      if (Array.isArray(err.errors) && err.errors[0] && err.errors[0].code) {
        throw apiErrorHandler(
          err.errors[0],
          dispatch,
          TranslationWithParams(`secretary.error.${err.errors[0].code}`, params),
        );
      }
      throw apiErrorHandler(err, dispatch);
    });
};

export const approveRejectSecretaryItem = async (
  id: string,
  body: SecretaryApprovalBody,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .post<void>(`${ADMIN_URL}/${secretaryEndpoint}/${id}/approval`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
