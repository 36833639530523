import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import {
  RECRUITMENT_BASE_PATH,
  RECRUITMENT_LICENSE_EXAM_PATH,
  RECRUITMENT_LICENSE_EXAM_SCHEDULE_PATH,
  RECRUITMENT_LICENSE_EXAM_REGISTRATION_PATH,
  RECRUITMENT_LICENSE_EXAM_RESULTS_PATH,
} from '../../../constants';
import ScheduleListPage, { examScheduleFilterKeys } from './schedule-listing-page';
import ExamScheduleUploadPage from './schedule-upload-page';
import { NOT_FOUND_ERROR_PAGE_PATH } from '../../../../../common/constants';
import PruRoute from '../../../../../common/components/PruRoute';
import ParamsProvider from 'src/app/common/components/ParamsProvider';

export const scheduleBasePath = `${RECRUITMENT_BASE_PATH}${RECRUITMENT_LICENSE_EXAM_PATH}${RECRUITMENT_LICENSE_EXAM_SCHEDULE_PATH}`;
const LicenseExamScheduleRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect exact from={`${scheduleBasePath}`} to={`${scheduleBasePath}/list`} />
      <PruRoute
        exact
        path={`${scheduleBasePath}/list`}
        render={(props) => (
          <ParamsProvider {...props} acceptKeys={examScheduleFilterKeys}>
            <ScheduleListPage />
          </ParamsProvider>
        )}
      />
      <PruRoute exact path={`${scheduleBasePath}/upload`} component={ExamScheduleUploadPage} />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default LicenseExamScheduleRoutes;
