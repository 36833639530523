import React, { FC, useState, useEffect } from 'react';
import { map } from 'lodash';
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from 'react-redux';
import { Button, FormControlLabel } from '@mui/material';
import { CheckCircle, RadioButtonUnchecked } from '@mui/icons-material';
import { RootState } from "src/redux/store";
import { AuthenticationState } from "src/app/modules/Auth/_redux/authSlice";
import { useCommonStyles } from "src/app/common/styles/common-styles";
import AcmCheckbox from 'src/app/modules/AgencyCampaign/common/AcmCheckbox';
import PageFooter from 'src/app/modules/AgencyCampaign/pages/CampaignType/Form/components/PageFooter';
import { CampaignTypeFormPageProps, useStyles } from 'src/app/modules/AgencyCampaign/pages/CampaignType/Form/CampaignTypeDetailPage';
import { CampaignTypeFormMode, CampaignTypeDropdownList, CampaignTypeStatusEnum, CampaignTypeDropdownEnum } from 'src/app/modules/AgencyCampaign/types/campaign-type-types';
import { CampaignTypeDropdownListParam, UpdateCampaignTypeBody, fetchCampaignTypeDropdownList } from 'src/app/modules/AgencyCampaign/network/campaignTypeCrud';
import CampaignTypePreview from './components/CampaignTypePreview';

type CampaignTypeFormPageFourProps = {
  campaignTypeDropdownList?: CampaignTypeDropdownList;
} & CampaignTypeFormPageProps;

const CampaignTypeFormPageFour: FC<CampaignTypeFormPageFourProps> = ({ formMode, campaignTypeItem, campaignTypeDropdownList, renderProgressBar, onPrevPage=()=>{}, onCancel, onShowCancelDialog, onSubmit }) => {
  const { classes } = useStyles();
  const { classes: commonClasses } = useCommonStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { user } = useSelector<RootState, AuthenticationState>(state => state.auth);

  const [status, setStatus] = useState(campaignTypeItem?.status ? campaignTypeItem.status : CampaignTypeStatusEnum.DRAFT);
  const [showPreview, setShowPreview] = useState(false);

  const [subSourceDropdownList, setSubSourceDropdownList] = useState<CampaignTypeDropdownList>();
  const reloadSubSourceDropdown = async () => {
    const params: CampaignTypeDropdownListParam = {
      type: map(CampaignTypeDropdownEnum),
      parent: campaignTypeItem?.agentLeadSource,
    };
    const dropdownListRes = await fetchCampaignTypeDropdownList(params, dispatch);
    setSubSourceDropdownList(dropdownListRes);
  }

  useEffect(() => {
    reloadSubSourceDropdown();
  }, [campaignTypeItem?.agentLeadSource]);

  const onSave = async () => {
    const body: UpdateCampaignTypeBody = {
      status,
      updatedBy: user?.username || "Default"
    }
    await onSubmit(body);
    onCancel();
  }

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <div className={classes.headerRowContainer}>
          <div className={commonClasses.header}>
            {showPreview ? (
              Translation("agencyCampaign.campaignType.form.title.previewCampaign")
            ) : (
              Translation(`agencyCampaign.campaignType.form.title.${formMode === CampaignTypeFormMode.CREATE ? 'create' : 'edit'}`)
            )}
          </div>
        </div>
        {!showPreview && renderProgressBar()}
      </div>

      {!showPreview &&
        <>
          <div className={classes.rowContainer}>
            <div className="col-12">
              <div className={classes.fieldContainer}>
                <span className={classes.field}>{Translation("agencyCampaign.campaignType.isActive")}<span className={classes.mandatory}>*</span></span>
              </div>
              <div style={{ flexGrow: 1 }}>
                <FormControlLabel
                  style={{ margin: "2px 12px 2px 0" }}
                  control={
                    <AcmCheckbox
                      icon={<RadioButtonUnchecked />}
                      checkedIcon={<CheckCircle />}
                      className={classes.leftCheckboxPadding}
                      checked={status === CampaignTypeStatusEnum.ACTIVE}
                      onChange={e => {
                        if (e.target.checked) {
                          setStatus(CampaignTypeStatusEnum.ACTIVE);
                        }
                      }}
                    />
                  }
                  label={Translation("app.checkbox.yes")}
                  labelPlacement="end"
                />
                <FormControlLabel
                  style={{ margin: "2px 0" }}
                  control={
                    <AcmCheckbox
                      icon={<RadioButtonUnchecked />}
                      checkedIcon={<CheckCircle />}
                      checked={status === CampaignTypeStatusEnum.DRAFT}
                      onChange={e => {
                        if (e.target.checked) {
                          setStatus(CampaignTypeStatusEnum.DRAFT);
                        }
                      }}
                    />
                  }
                  label={Translation("app.checkbox.no")}
                  labelPlacement="end"
                />
              </div>
            </div>
          </div>

          <div className={classes.rowContainer} style={{ marginBottom: 10 }}>
            <div className="col-12">
                <span>{Translation("agencyCampaign.campaignType.previewCampaignApplication")}</span>
            </div>
          </div>

          <div className={classes.rowContainer}>
            <div className="col-12">
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setShowPreview(true)}
              >
                {Translation("agencyCampaign.common.preview")}
              </Button>
            </div>
          </div>

          <PageFooter
            disableContinue={true}
            onPrevPage={onPrevPage}
            onCancel={onShowCancelDialog}
            onSave={onSave}
          />
        </>
      }

      {(campaignTypeItem && campaignTypeDropdownList && subSourceDropdownList && showPreview) &&
        <CampaignTypePreview
          campaignTypeItem={campaignTypeItem}
          campaignTypeDropdownList={campaignTypeDropdownList}
          subSourceDropdownList={subSourceDropdownList}
          setShowPreview={setShowPreview}
        />
      }
    </div>
  );
}

export default CampaignTypeFormPageFour;