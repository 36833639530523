import React, { FC } from 'react';
import { useIntl } from "react-intl";
import { FormControlLabel } from '@mui/material';
import { CheckCircle, RadioButtonUnchecked } from '@mui/icons-material';
import AcmCheckbox from 'src/app/modules/AgencyCampaign/common/AcmCheckbox';
import { useStyles } from 'src/app/modules/AgencyCampaign/pages/CampaignType/Form/CampaignTypeDetailPage';
import { SectionFieldItem, RuleKeyEnum, RuleTypeEnum } from 'src/app/modules/AgencyCampaign/types/campaign-type-types';

type MandatoryDependentRuleProps = {
  field: SectionFieldItem;
  ruleIndex: number;
  fieldPool: SectionFieldItem[];
  setField: (field: SectionFieldItem) => void;
}

const MandatoryDependentRule: FC<MandatoryDependentRuleProps> = ({ field, ruleIndex, fieldPool, setField }) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const rule = field.rules[ruleIndex];
  const dependsOnValue = fieldPool.find(item => item.key === field.key)?.rules[ruleIndex].value || 'Yes';

  return (
    <div className={classes.sectionMargin}>
      <div>{ruleIndex + 1}. {Translation(`agencyCampaign.rule.${rule.key}`)} ?</div>
      <div>
        <FormControlLabel
          style={{ margin: "2px 0" }}
          disabled={field.forcedMandatory}
          control={
            <AcmCheckbox
              icon={<RadioButtonUnchecked />}
              checkedIcon={<CheckCircle />}
              checked={rule.key === RuleKeyEnum.MANDATORY && rule.value === true}
              onChange={e => {
                if (e.target.checked) {
                  const newField = {...field};
                  newField.rules[ruleIndex].key = RuleKeyEnum.MANDATORY;
                  newField.rules[ruleIndex].type = RuleTypeEnum.BOOLEAN;
                  newField.rules[ruleIndex].value = true;
                  setField(newField);
                }
              }}
            />
          }
          label={Translation("agencyCampaign.campaignType.section.editField.always")}
          labelPlacement="end"
        />
      </div>
      <div>
        <FormControlLabel
          style={{ margin: "2px 0" }}
          disabled={field.forcedMandatory}
          control={
            <AcmCheckbox
              icon={<RadioButtonUnchecked />}
              checkedIcon={<CheckCircle />}
              checked={rule.key === RuleKeyEnum.MANDATORY_DEPENDS && rule.value === dependsOnValue}
              onChange={e => {
                if (e.target.checked) {
                  const newField = {...field};
                  newField.rules[ruleIndex].key = RuleKeyEnum.MANDATORY_DEPENDS;
                  newField.rules[ruleIndex].type = RuleTypeEnum.STRING;
                  newField.rules[ruleIndex].value = dependsOnValue;
                  setField(newField);
                }
              }}
            />
          }
          label={intl.formatMessage(
            { id: "agencyCampaign.campaignType.section.editField.ifDependentFieldIs"},
            {
              dependentField: <b>{rule.dependsOn ? Translation(`agencyCampaign.field.${rule.dependsOn}`) : ''}</b>,
              dependentValue: dependsOnValue
            }
          )}
          labelPlacement="end"
        />
      </div>
      <div>
        <FormControlLabel
          style={{ margin: "2px 0" }}
          disabled={field.forcedMandatory}
          control={
            <AcmCheckbox
              icon={<RadioButtonUnchecked />}
              checkedIcon={<CheckCircle />}
              checked={rule.key === RuleKeyEnum.MANDATORY && rule.value === false}
              onChange={e => {
                if (e.target.checked) {
                  const newField = {...field};
                  newField.rules[ruleIndex].key = RuleKeyEnum.MANDATORY;
                  newField.rules[ruleIndex].type = RuleTypeEnum.BOOLEAN;
                  newField.rules[ruleIndex].value = false;
                  setField(newField);
                }
              }}
            />
          }
          label={Translation("app.checkbox.no")}
          labelPlacement="end"
        />
      </div>
    </div>
  )
}

export default MandatoryDependentRule;