import { FC } from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { Button } from '@mui/material';
import { FormMode } from 'src/app/common/types';
import { ComponentProps } from 'src/app/common/components/pru-stepped-form';
import { EventFormCommonProps } from 'src/app/modules/event-v2/types';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { useCommonFormStyles } from '../../common';
import { useEventAssistantStyles } from './event-assistant-list.style';
import { useEventAssistantList } from './event-assistant-list.hook';
import { EventAssistantDialog, SendLinkDialog } from './components';

type EventAssistantListProps = ComponentProps<EventFormCommonProps>;

export const EventAssistantList: FC<EventAssistantListProps> = ({ formCommonProps, ...rest }) => {
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);
  const { classes } = useEventAssistantStyles();
  const { classes: commonFormClasses } = useCommonFormStyles();
  const { formMode, eventItem } = formCommonProps;
  const {
    eventId,
    filterState,
    eventAssistantList,
    eventAssistantDialogState,
    sendLinkDialogState,
    isLoading,
    setFilterState,
    setEventAssistantDialogState,
    setSendLinkDialogState,
    refreshEventAssistantList,
    onRemove,
    resetSendLinkDialogState,
  } = useEventAssistantList({ formCommonProps, ...rest });
  const disableAction = !eventId || (eventItem?.eventDate ? moment().isAfter(eventItem.eventDate, 'day') : false);
  const viewMode = formMode === FormMode.VIEW || disableAction;

  return (
    <>
      {eventId && eventAssistantDialogState.open && (
        <EventAssistantDialog
          eventId={eventId}
          dialogState={eventAssistantDialogState}
          onClose={() => setEventAssistantDialogState({ open: false })}
          refreshEventAssistantList={refreshEventAssistantList}
        />
      )}
      {eventId && sendLinkDialogState.open && (
        <SendLinkDialog
          eventId={eventId}
          dialogState={sendLinkDialogState}
          onClose={() => setSendLinkDialogState({ ...sendLinkDialogState, open: false })}
          resetDialogState={resetSendLinkDialogState}
        />
      )}
      <div className={commonFormClasses.container}>
        <div>
          <div className={classes.fieldContainer}>
            <div>{Translation('event.form.event_assistant')}</div>
            <div className={commonFormClasses.remark}>{Translation('event.form.event_assistant.remark')}</div>
          </div>
          <div className={classes.buttonContainer}>
            <div>
              <Button
                disabled={viewMode}
                variant="contained"
                color="secondary"
                onClick={() => setEventAssistantDialogState({ open: true })}
              >
                {Translation('app.button.add')}
              </Button>
            </div>
            <div>
              <Button
                disabled={disableAction || sendLinkDialogState.selectedRows.length === 0}
                variant="contained"
                color="secondary"
                onClick={() => setSendLinkDialogState({ ...sendLinkDialogState, open: true })}
              >
                {Translation('app.button.send_link')}
              </Button>
            </div>
          </div>
          <div>
            <PruTable
              disableToolbar
              disableBulkSelect={disableAction}
              headerBtnDef={[]}
              operationDef={
                viewMode
                  ? []
                  : [
                      {
                        title: Translation('section.common.operation.edit'),
                        tooltipText: 'Edit',
                        onClick: (row) => setEventAssistantDialogState({ open: true, eventAssistantItem: row }),
                      },
                      {
                        title: Translation('app.button.remove'),
                        tooltipText: 'Remove',
                        onClick: (row) => onRemove(row._id),
                      },
                    ]
              }
              columnDef={[
                {
                  isId: true,
                  hidden: true,
                  keyIndex: '_id',
                  displayName: '',
                  renderData: () => '',
                },
                {
                  keyIndex: 'name',
                  displayName: Translation('event.form.assistant_name'),
                  renderData: (row) => row.name || '-',
                },
                {
                  keyIndex: 'email',
                  displayName: Translation('event.form.email_address'),
                  renderData: (row) => row.email || '-',
                },
                {
                  keyIndex: 'createdBy',
                  displayName: Translation('event.common.created_by'),
                  renderData: (row) => row.createdBy || '-',
                },
                {
                  keyIndex: 'lastUpdatedBy',
                  displayName: Translation('event.common.last_updated_by'),
                  renderData: (row) => row.lastUpdatedBy || '-',
                },
              ]}
              isLoading={isLoading}
              onRefresh={refreshEventAssistantList}
              dataSource={eventAssistantList?.docs}
              defaultRowsPerPage={20}
              totalPages={eventAssistantList?.totalPages}
              totalRecords={eventAssistantList?.totalDocs}
              onChangePage={(page, rowsPerPage) => {
                setFilterState({
                  ...filterState,
                  page,
                  limit: rowsPerPage,
                });
                refreshEventAssistantList();
              }}
              updateSelectedRow={sendLinkDialogState.selectedRows}
              currentSelectedRow={(rowData, _, onResetRowSelected) =>
                setSendLinkDialogState({ ...sendLinkDialogState, selectedRows: rowData, onResetRowSelected })
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};
