import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import {
  RECRUITMENT_BASE_PATH,
  RECRUITMENT_APPLICATION_FORM_EDIT_HISTORY_PATH,
  RECRUITMENT_ONBOARDING_TRAINING,
  RECRUITMENT_APPLICATION_COURSES,
} from '../../../constants';
import ListPage, { FilterKeys } from './listing-page';
import CourseDetailPage from './course-detail-page';
import LessonDetailPage from './lesson/lesson-detail-page';
import QuizDetailPage from './quiz/quiz-detail-page';
import { NOT_FOUND_ERROR_PAGE_PATH } from '../../../../../common/constants';
import PruRoute from '../../../../../common/components/PruRoute';
import ParamsProvider from 'src/app/common/components/ParamsProvider';

export const BasePath = `${RECRUITMENT_BASE_PATH}${RECRUITMENT_ONBOARDING_TRAINING}${RECRUITMENT_APPLICATION_COURSES}`;
const OnboardingCourseRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect exact from={`${BasePath}`} to={`${BasePath}/list`} />
      <PruRoute
        exact
        path={`${BasePath}/list`}
        render={(props) => (
          <ParamsProvider {...props} acceptKeys={FilterKeys}>
            <ListPage />
          </ParamsProvider>
        )}
      />
      <PruRoute exact path={`${BasePath}/create`} component={CourseDetailPage} />
      <PruRoute exact path={`${BasePath}/edit/:id`} component={CourseDetailPage} />
      <PruRoute exact path={`${BasePath}/view/:id`} component={CourseDetailPage} />
      <PruRoute exact path={`${BasePath}/:courseId/lesson/create`} component={LessonDetailPage} />
      <PruRoute exact path={`${BasePath}/:courseId/lesson/edit/:id`} component={LessonDetailPage} />
      <PruRoute exact path={`${BasePath}/:courseId/lesson/view/:id`} component={LessonDetailPage} />
      <PruRoute exact path={`${BasePath}/:courseId/quiz/create`} component={QuizDetailPage} />
      <PruRoute exact path={`${BasePath}/:courseId/quiz/edit/:id`} component={QuizDetailPage} />
      <PruRoute exact path={`${BasePath}/:courseId/quiz/view/:id`} component={QuizDetailPage} />
      <PruRoute exact path={`${BasePath}/:courseId/lesson/:id/quiz/create`} component={QuizDetailPage} />
      <PruRoute exact path={`${BasePath}/:courseId/lesson/:id/quiz/edit/:id`} component={QuizDetailPage} />
      <PruRoute exact path={`${BasePath}/:courseId/lesson/:id/quiz/view/:id`} component={QuizDetailPage} />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default OnboardingCourseRoutes;
