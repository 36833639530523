/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC, useContext, useEffect } from 'react';
import { Redirect, Switch, useLocation } from 'react-router-dom';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { Logout, AuthPage } from './modules/Auth';
import ErrorsPage from './modules/Error/ErrorsPage';
import { RootState } from '../redux/store';
import { AlertItem } from '../redux/common/commonSlice';
import TokenHandler from './common/components/TokenHandler';
import { setTokenInfo } from './modules/Auth/_redux/authSlice';
import PruRoute from './common/components/PruRoute';
import { TokenInfo } from './modules/Auth/types/auth-types';
import ParamsProvider from './common/components/ParamsProvider';
import PRUForceRedirectPage from './modules/Auth/pages/PRUForceRedirectPage';
import ModuleProvider from './common/module/ModuleProvider';
import { regionLocale } from 'src/app/i18n';
import { useLang, setLanguage } from 'src/app/i18n';
import { GlobalHelper } from './common/helpers/GlobalHelpers';
import { ModalService } from './common/components/pru-modal/modal-service';
import { ModalServiceContext } from './common/components/pru-modal/modal-context';
import { ToastContext } from './common/components/pru-toast/toast-context';
import { decodeJWT } from './modules/Auth/_redux/authCrud';
import { PruAlert } from './layout';
import Layout from './common/components/layout/layout';

type RoutesInternal = {
  user?: TokenInfo;
  alertState: AlertItem[];
  isExpired: boolean;
};

export const Routes: FC = () => {
  const lang = useLang();
  const dispatch = useDispatch();
  const location = useLocation();

  const toastRef = useContext(ToastContext);
  GlobalHelper.setGlobalToastRef(toastRef);

  const modalRef = useContext(ModalServiceContext);
  GlobalHelper.setGlobalModalRef(modalRef);

  const { user, alertState, isExpired } = useSelector<RootState, RoutesInternal>((state) => {
    return {
      user: state.auth.user,
      alertState: state.common.alertState,
      isExpired: !!state.auth.isExpired,
    };
  }, shallowEqual);

  useEffect(() => {
    if (!regionLocale.find((locale) => locale === lang)) {
      setLanguage(regionLocale[0]);
    }
    const token = window.localStorage.getItem('jwt');
    if (token) {
      decodeJWT(token).then((jwt) => dispatch(setTokenInfo(jwt)));
    } else {
      const redirectLink = window.localStorage.getItem('redirect');
      if (!redirectLink && location.pathname.startsWith('/agency-campaign/approval/list')) {
        window.localStorage.setItem('redirect', location.pathname);
      }
    }
    ModalService.init();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <PruAlert alertState={alertState} />
      <Switch>
        <PruRoute
          path="/pruforce/callback"
          render={(props) => (
            <ParamsProvider {...props} acceptKeys={['authorizationCode']}>
              <PRUForceRedirectPage />
            </ParamsProvider>
          )}
        />
        <PruRoute path="/error" component={ErrorsPage} />
        <PruRoute path="/logout" component={Logout} />
        {!user ? <AuthPage /> : <Redirect from="/auth" to="/" />}
        {user && (
          <ModuleProvider>
            <TokenHandler />
            {!isExpired && <Layout />}
          </ModuleProvider>
        )}
      </Switch>
    </>
  );
};
