import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { personalParticularPath } from 'src/app/modules/Recruit/pages/PersonalParticular/PersonalParticularRoutes';
import { getDefaultDisplayDate } from 'src/app/common/utils';
import { PaginateList } from 'src/app/common/types/common-types';
import { PersonalParticularItem } from 'src/app/modules/Recruit/types/personal-particular-types';

type FollowUpCaseListProps = {
  isLoading: boolean;
  followUpCaseList?: PaginateList<PersonalParticularItem>;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
};

const FollowUpCaseList: FC<FollowUpCaseListProps> = ({ isLoading, followUpCaseList, onRefresh, onChangePage }) => {
  const history = useHistory();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  return (
    <>
      <PruTable
        title={Translation('recruit.followUpCase.list')}
        disableBulkSelect
        operationDef={[
          {
            title: Translation('recruit.common.documents'),
            tooltipText: 'View Documents',
            onClick: (row) => history.push(`${personalParticularPath}/doc/${row.appId}`),
          },
        ]}
        columnDef={[
          {
            isId: true,
            hidden: true,
            keyIndex: '_id',
            displayName: '',
            renderData: (row) => '',
          },
          {
            keyIndex: 'appId',
            displayName: Translation('recruit.common.applicationId'),
            renderData: (row) => row.appId || '-',
          },
          {
            keyIndex: 'reason',
            displayName: Translation('recruit.followUpCase.reason'),
            renderData: (row) => (row.supplementary ? row.supplementary.reason || '-' : '-'),
          },
          // {
          //   keyIndex: "lastUpdateUser",
          //   align: "center",
          //   displayName: Translation("recruit.common.lastUpdateUser"),
          //   renderData: (row) => row.lastUpdateUser || "-"
          // },
          {
            keyIndex: 'lastUpdatedDate',
            align: 'center',
            displayName: Translation('recruit.common.lastUpdatedDate'),
            renderData: (row) => getDefaultDisplayDate(new Date(row.lastUpdatedDate), 'datetime'),
          },
          {
            keyIndex: 'submittedDate',
            align: 'center',
            displayName: Translation('recruit.followUpCase.submittedDate'),
            renderData: (row) => getDefaultDisplayDate(new Date(row.submittedDate), 'datetime'),
          },
        ]}
        isLoading={isLoading}
        onRefresh={onRefresh}
        dataSource={followUpCaseList?.docs}
        totalPages={followUpCaseList?.totalPages}
        totalRecords={followUpCaseList?.totalDocs}
        onChangePage={onChangePage}
      />
    </>
  );
};

export default FollowUpCaseList;
