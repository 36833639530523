import { AttachmentDef } from 'src/app/common/types';
import { TargetAgentsByEnum } from '../types';

export type RadioModeFormState = {
  targetAgentBy?: TargetAgentsByEnum;
  eligibleDesignations: string[];
  eligibleZones: string[];
  eligibleAgents: string[];
  excludedAgents: string[];
  eligibleAgentListFile?: AttachmentDef | null;
  excludedAgentListFile?: AttachmentDef | null;
};

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof RadioModeFormState;
    value: any;
  };
};

type SelectFieldAction = {
  type: 'SELECT_FIELD';
  payload: {
    field: 'eligibleDesignations' | 'eligibleZones';
    value: string;
  };
};

export type RadioModeFormAction = ModifyFieldAction | SelectFieldAction;

export const radioModeFormReducer = (state: RadioModeFormState, action: RadioModeFormAction): RadioModeFormState => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      const newState = {
        ...state,
        [action.payload.field]: action.payload.value,
      };
      if (action.payload.field === 'targetAgentBy') {
        newState.eligibleDesignations = [];
        newState.eligibleZones = [];
        newState.eligibleAgents = [];
        newState.excludedAgents = [];
        newState.eligibleAgentListFile = undefined;
        newState.excludedAgentListFile = undefined;
      }
      return newState;
    case 'SELECT_FIELD':
      const newList = [...state[action.payload.field]];
      const itemIndex = newList.indexOf(action.payload.value);
      if (itemIndex === -1) {
        newList.push(action.payload.value);
      } else {
        newList.splice(itemIndex, 1);
      }
      return {
        ...state,
        [action.payload.field]: newList,
      };
    default:
      return state;
  }
};
