import { makeStyles } from 'tss-react/mui';
import { Button, Checkbox, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { useEffect, useReducer, useState, useMemo, FC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import { isArray } from 'lodash';
import { AutoDropLeadConfig } from '../../../Lead/Campaign/Form/components/CampaignForm';
import { ErrorFieldType, useErrorHandler } from 'src/app/common/utils/form-error-utils';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import {
  LeadConfigDto,
  UpdateAutoDropLeadConfigDto,
  updateLeadAutoDropRule,
} from 'src/app/modules/PulseLeads/network/agentCrud';
import { DropLeadTypeEnum } from 'src/app/modules/PulseLeads/types/campaign-types';
import { AlertType, appendAlertItem } from 'src/redux/common/commonSlice';

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  fieldContainer: {
    width: 250,
    boxSizing: 'border-box',
  },
  checkboxFieldContainer: {
    width: 250,
    boxSizing: 'border-box',
  },
  imageContainer: {
    width: 120,
    height: 120,
    overflow: 'hidden',
    borderRadius: 8,
  },
  dateDivider: {
    marginLeft: 8,
    marginRight: 8,
  },
  optionDefault: {
    color: '#AAAAAA',
  },
  innerFieldContainer: {
    width: 250,
    boxSizing: 'border-box',
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },
  sectionMargin: {
    marginBottom: 25,
  },
  divideMargin: {
    marginBottom: 10,
  },
  subHeader: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
  },
  subSectionHeader: {
    fontSize: '14px',
    fontWeight: 500,
    fontColor: 'black',
  },
  textAreaRowContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  textAreaFieldContainer: {
    paddingTop: 15,
    width: 250,
    boxSizing: 'border-box',
  },
  textArea: {
    lineHeight: 1.5,
    minHeight: 40,
  },
  errorText: {
    fontSize: 9,
    color: '#F018A6',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  checkboxPadding: {
    marginLeft: -9,
  },
}));
const textNumberMinWidth = {
  minWidth: 100,
};

type AutoDropLeadFormState = {
  enableAutoDropLead: boolean;
  autoDropLeadType: DropLeadTypeEnum;
  autoDropLeadConfig?: AutoDropLeadConfig;
};
type ActionPayload = {
  field: keyof AutoDropLeadFormState;
  value: any;
};
type ModifyFieldAction =
  | {
      type: 'MODIFY_FIELD';
      payload: ActionPayload | ActionPayload[];
    }
  | {
      type: 'SUBMIT';
    };

const formReducer = (state: AutoDropLeadFormState, action: ModifyFieldAction): AutoDropLeadFormState => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      const data = isArray(action.payload) ? action.payload : [action.payload];
      let patchData = {};
      for (let index = 0; index < data.length; index++) {
        const element: ActionPayload = data[index];
        patchData = {
          ...patchData,
          [element.field]: element.value,
        };
      }
      return {
        ...state,
        ...patchData,
      };
    case 'SUBMIT': {
      return {
        ...state,
      };
    }
    default:
      return state;
  }
};

const convertToState = (data: LeadConfigDto): AutoDropLeadFormState => {
  const defaultState: AutoDropLeadFormState = {
    enableAutoDropLead: data?.content?.enabled,
    autoDropLeadType: DropLeadTypeEnum.BY_LEAD_UPDATE_TIME,
    autoDropLeadConfig: { [DropLeadTypeEnum.BY_LEAD_UPDATE_TIME]: data?.content?.day } ?? null,
  };
  return defaultState;
};

const DropLeadRuleForm: FC<any> = ({ data }) => {
  const history = useHistory();
  const { classes } = useStyles();
  const { classes: commonClasses } = useCommonStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const Translation = (id: string) => intl.formatMessage({ id });
  const initeState = {
    enableAutoDropLead: true,
    autoDropLeadType: DropLeadTypeEnum.BY_LEAD_UPDATE_TIME,
  };
  const [formState, formDispatch] = useReducer(formReducer, data ? convertToState(data) : initeState);

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler, immediateErrorValidator } = useErrorHandler(
    formState,
    [
      {
        name: 'autoDropLeadDays',
        fieldType: ErrorFieldType.IMMEDIATE,
        condition: () => {
          if (formState.enableAutoDropLead && formState?.autoDropLeadType) {
            const numberRegex = new RegExp(/^\d+$/);
            return !numberRegex.test(String(formState?.autoDropLeadConfig?.[formState.autoDropLeadType]));
          } else {
            return false;
          }
        },
      },
    ],
  );

  useEffect(() => {
    immediateErrorValidator();
  }, [formState.autoDropLeadConfig]);

  const onSave = async () => {
    const { hasError } = onSubmitErrorValidator();
    if (!hasError) {
      const body: UpdateAutoDropLeadConfigDto = {
        enabled: formState.enableAutoDropLead,
        day: formState?.autoDropLeadConfig?.[formState.autoDropLeadType] ?? undefined,
      };
      try {
        await updateLeadAutoDropRule(body);
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: 'Success',
              content: `Rule updated successfully`,
            },
          ]),
        );
      } catch (err) {}
    }
  };

  return (
    <div className="tw-flex tw-flex-1 tw-bg-white tw-flex-col ">
      <div className="tw-m-6">
        <div className={classes.headerContainer}>
          <div className={classes.rowContainer}>
            <div className={commonClasses.header}>{Translation('title.pulseleads.agent.autoDropLead')}</div>
          </div>{' '}
        </div>

        <div className={classes.textAreaRowContainer}>
          <div className={classes.checkboxFieldContainer}>
            <span>{Translation('pulseleads.agent.autoDropLead.leadDrop')} :</span>
          </div>
          <span>
            <FormControlLabel
              control={
                <Checkbox
                  icon={<CircleUnchecked />}
                  checkedIcon={<CircleCheckedFilled />}
                  checked={formState.enableAutoDropLead === true}
                  onChange={(e) => {
                    if (e.target.checked) {
                      formDispatch({
                        type: 'MODIFY_FIELD',
                        payload: {
                          field: 'enableAutoDropLead',
                          value: true,
                        },
                      });
                    }
                  }}
                />
              }
              label={Translation('pulseleads.agent.autoDropLead.enable')}
              labelPlacement="end"
            />
            <FormControlLabel
              control={
                <Checkbox
                  icon={<CircleUnchecked />}
                  checkedIcon={<CircleCheckedFilled />}
                  checked={!formState.enableAutoDropLead}
                  onChange={(e) => {
                    if (e.target.checked) {
                      formDispatch({
                        type: 'MODIFY_FIELD',
                        payload: {
                          field: 'enableAutoDropLead',
                          value: false,
                        },
                      });
                    }
                  }}
                />
              }
              label={Translation('pulseleads.agent.autoDropLead.disable')}
              labelPlacement="end"
            />
          </span>
        </div>
        {formState.enableAutoDropLead && (
          <div>
            <div className="tw-flex tw-flex-row tw-items-center tw-justify-start">
              <span className={classes.checkboxFieldContainer}>
                {Translation('pulseleads.agent.autoDropLead.leadDropDate')} :
              </span>
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<CircleUnchecked />}
                    checkedIcon={<CircleCheckedFilled />}
                    checked={formState.autoDropLeadType === DropLeadTypeEnum.BY_LEAD_UPDATE_TIME}
                    onChange={(e) => {
                      if (e.target.checked) {
                        formDispatch({
                          type: 'MODIFY_FIELD',
                          payload: {
                            field: 'autoDropLeadType',
                            value: DropLeadTypeEnum.BY_LEAD_UPDATE_TIME,
                          },
                        });
                      }
                    }}
                  />
                }
                label={
                  <div className="tw-flex tw-flex-row tw-items-center tw-justify-center">
                    <div className="tw-w-60">{Translation('pulseleads.agent.autoDropLead.lastUpdatedAt')}</div>
                    {formState.autoDropLeadType === DropLeadTypeEnum.BY_LEAD_UPDATE_TIME && (
                      <>
                        <TextField
                          style={textNumberMinWidth}
                          error={errorState.immediate.autoDropLeadDays}
                          margin="dense"
                          variant="outlined"
                          helperText={
                            errorState.immediate.autoDropLeadDays &&
                            Translation('pulseleads.agent.autoDropLead.leadDateErrorMsg')
                          }
                          value={formState.autoDropLeadConfig?.[DropLeadTypeEnum.BY_LEAD_UPDATE_TIME] ?? null}
                          type="number"
                          onChange={(e) => {
                            onDismissErrorHandler('autoDropLeadDays', e.target.value);
                            formDispatch({
                              type: 'MODIFY_FIELD',
                              payload: {
                                field: 'autoDropLeadConfig',
                                value: {
                                  [DropLeadTypeEnum.BY_LEAD_UPDATE_TIME]: Number(e.target.value),
                                },
                              },
                            });
                          }}
                        />
                        <div className="tw-ml-4">{Translation('pulseleads.agent.autoDropLead.days')}</div>
                        <div className="tw-ml-6">
                          {Translation('pulseleads.agent.autoDropLead.lastUpdatedAt.reason')}
                        </div>
                      </>
                    )}
                  </div>
                }
                labelPlacement="end"
              />
            </div>
          </div>
        )}
        <div className="tw-flex tw-items-end tw-justify-end">
          <Button variant="contained" color="secondary" size="large" onClick={onSave}>
            {Translation('app.button.save')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DropLeadRuleForm;
