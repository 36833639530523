import React, { FC, useState } from 'react';
import Form from 'src/app/common/components/Form';
import { useIntl } from 'react-intl';
import {
  TextField,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  IconButton,
  TableHead,
  Dialog,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useLang } from 'src/app/i18n';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import ProTableCell from 'src/app/common/components/ProTable/ProTableCell';
import RuleGroup from './ruleGroup';
import Close from '@mui/icons-material/Close';
import { I18nData } from 'src/app/modules/PulseLeads/pages/Engagement/util';
import { commonStyles, useStyles } from '../style';
import { POINT_VIDATOR } from '../../util';

type selectedRule = {
  type: string;
  name: I18nData[];
  checked: boolean;
  params: { marketDisabled: boolean; agentDisabled: boolean };
};

const SelectRuleTable: FC<any> = ({ index, setAllRules, allRules, form }) => {
  const intl = useIntl();
  const { id } = useParams<{ id: string }>();
  const Translation = (id: string) => intl.formatMessage({ id });
  const locale = useLang();
  const { classes } = useStyles();
  const [ruleGroupVisible, setRuleGroupVisible] = useState<boolean>(false);
  let ruleGroup = form.getFieldValue('groupList')[index]?.rules;
  const matchRuleByType = (type: string) => {
    const rule = allRules.filter((item: any) => item?.type === type)[0];
    return rule;
  };
  ruleGroup = ruleGroup?.map((item: { type: string }) => matchRuleByType(item?.type));
  const [ruleList, setRuleList] = useState<any[]>(ruleGroup || []);

  const onDelete = (index: number, groupIndex: number, type: any) => {
    const tableData = [...(form.getFieldValue('groupList')[index]?.rules || [])];
    tableData.splice(groupIndex, 1);
    const ruleIndex: number = allRules.findIndex((item: any) => item?.type === type);
    let convertList = allRules;
    convertList[ruleIndex].checked = false;
    setAllRules(convertList);
    setRuleList(tableData);
    let groupList = form.getFieldValue('groupList');
    groupList[index]['rules'] = tableData;
    form.setFieldsValue({ groupList: groupList });
  };

  const swapArray = (index1: number, index2: number) => {
    let tableData = [...(form.getFieldValue('groupList')[index]?.rules || [])];
    if (tableData) {
      [tableData[index1], tableData[index2]] = [tableData[index2], tableData[index1]];
    }
    setRuleList(tableData);
    let groupList = form.getFieldValue('groupList');
    groupList[index]['rules'] = tableData;
    form.setFieldsValue({ groupList: groupList });
  };

  return (
    <div style={commonStyles.tableBg}>
      <div style={commonStyles.addButton}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            setRuleGroupVisible(true);
          }}
          size="small"
        >
          {Translation('pulseleads.activityPoint.ruleSettings.common.addRules')}
        </Button>
      </div>
      <Paper elevation={0} variant="elevation" style={commonStyles.mb10}>
        <TableContainer>
          <Table aria-label="input table">
            <TableHead>
              <TableRow>
                <ProTableCell>{Translation('pulseleads.activityPoint.ruleName.title')}</ProTableCell>
                <ProTableCell>
                  {Translation('pulseleads.activityPoint.ruleSettings.common.marketSourcePoint')}
                </ProTableCell>
                <ProTableCell>
                  {Translation('pulseleads.activityPoint.ruleSettings.common.agentSourcePoint')}
                </ProTableCell>
                <ProTableCell align="center">{Translation('component.formLabel.operation')}</ProTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(ruleGroup || [])?.map((row: selectedRule, tableIndex: number) => {
                return (
                  <TableRow key={row?.type}>
                    <TableCell padding="normal" style={commonStyles.w300}>
                      <span>{matchRuleByType(row?.type)?.name?.[locale]}</span>
                    </TableCell>
                    <TableCell>
                      {row?.params?.marketDisabled ? (
                        <TextField
                          margin="dense"
                          variant="outlined"
                          size="small"
                          style={commonStyles.disabledInput}
                          disabled={true}
                        />
                      ) : (
                        <Form.Item
                          name={['groupList', index, 'rules', tableIndex, 'marketSourcePoint']}
                          rules={POINT_VIDATOR}
                        >
                          <TextField margin="dense" variant="outlined" size="small" style={commonStyles.defaultInput} />
                        </Form.Item>
                      )}
                    </TableCell>
                    <TableCell>
                      {row?.params?.agentDisabled ? (
                        <TextField
                          margin="dense"
                          variant="outlined"
                          size="small"
                          style={commonStyles.disabledInput}
                          disabled={true}
                        />
                      ) : (
                        <Form.Item
                          name={['groupList', index, 'rules', tableIndex, 'agentSourcePoint']}
                          rules={POINT_VIDATOR}
                        >
                          <TextField margin="dense" variant="outlined" size="small" style={commonStyles.defaultInput} />
                        </Form.Item>
                      )}
                    </TableCell>
                    <TableCell align="center" size="small" padding="none">
                      {tableIndex === 0 ? null : (
                        <ArrowUpward
                          className={classes.moveBtn}
                          onClick={() => {
                            swapArray(tableIndex, tableIndex - 1);
                          }}
                        />
                      )}
                      {tableIndex !== ruleGroup?.length - 1 && (
                        <ArrowDownward
                          className={classes.moveBtn}
                          onClick={() => {
                            swapArray(tableIndex + 1, tableIndex);
                          }}
                        />
                      )}
                      <IconButton
                        onClick={() => {
                          onDelete(index, tableIndex, row?.type);
                        }}
                      >
                        <Close />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {!(ruleGroup?.length > 0) && (
        <span style={commonStyles.colorRed}>
          {Translation('pulseleads.activityPoint.ruleSettings.common.addRuleMsg')}
        </span>
      )}
      <Dialog
        open={ruleGroupVisible}
        onClose={() => {
          setRuleGroupVisible(false);
        }}
        scroll={'paper'}
        fullWidth
      >
        <RuleGroup
          setRuleGroupVisible={setRuleGroupVisible}
          setAllRules={setAllRules}
          allRules={allRules}
          ruleGroup={ruleGroup}
          form={form}
          index={index}
        />
      </Dialog>
    </div>
  );
};

export default SelectRuleTable;
