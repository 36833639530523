import { PaginateList } from 'src/app/common/types/common-types';
import { I18nGenericData } from 'src/app/i18n';

export enum TierEnum {
  RED = 'red',
  BLUE = 'blue',
  GOLD = 'gold',
  CUSTOM = 'custom',
}

export enum StatusEnum {
  DISABLE = 'disabled',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum awardTypeEnum {
  BADGE = 'badge',
  AWARD = 'award',
  ACHIEVEMENT = 'achievement',
}

export enum SalesMaterialStatusEnum {
  Draft = 'draft',
  Pending = 'pending',
  Published = 'published',
}

export enum isDefault {
  YES = 'yes',
  No = 'no',
}
export enum AboutMe {
  STANDARD = 'standard',
  CUSTOM = 'custom',
}

export enum MyHighlight {
  YES = 'yes',
  No = 'no',
}
export type AgentProfileItem = {
  id: string;
  _id?: string;
  name: I18nGenericData<string>;
  agentCode: string;
  tier: string;
  status: StatusEnum;
  myAboutMe: Record<string, string>;
  myBanner: Record<string, string>;
  myHighlight: any;
  enableMyAboutMe: boolean;
  enableMyHighlight: boolean;
};

export type AwardListItem = {
  _id: string;
  agentName: I18nGenericData<string>;
  agentCode: string;
  awardName: I18nGenericData<string>;
  awardYear: string;
  awardCode: string;
  type: string;
  icon: string;
};
export type AwardTypeListItem = {
  typeId: string;
  name: I18nGenericData<string>;
  image: string;
  isGroupedYear?: boolean;
  displayAwardYear: string;
  year?: string;
  awardCode: string;
  type: string;
};

export type ConfigPromtoionsListItem = {
  uuid: string;
  _id: string;
  name: string;
  effectiveDate: Date;
  expiredDate: Date;
  lastUpdated: Date;
  lastUpdatedAt: Date;
  status: string;
};
export type ConfigBannerTierListItem = {
  id: string;
  tierCode: string;
  isDefault: boolean;
  name: I18nGenericData<string>;
  banner: I18nGenericData<string>;
  status: string;
  createdAt: Date;
  updatedAt: Date;
};

export enum BatchUploadTypeEnum {
  agentSettings = 'agent-settings',
  agentAward = 'agent-award',
  awardType = 'award-type',
}

export type FailedDetails = {
  rowNumber: number;
  failureReason: string;
};
export type MassUploadStatusListItem = {
  _id: string;
  blobId: string;
  fileName: string;
  uploadType: BatchUploadTypeEnum;

  totalRowCount: number;
  failedRowCount: number;
  failedDetails: Array<FailedDetails>;
  createdAt: Date;
  uploadedAtFrom: Date;
  uploadAtTo: Date;

  uploadedAt: Date;
  region: string;
  channel: string;
};

export type ConfigAboutMeListItem = {
  id: string;
  templateCode: string;
  status: string;
  isDefault?: string;
  createdAt: Date;
  updatedAt: Date;
};

export type AgenProfilePaginateList = PaginateList<AgentProfileItem>;
export type ConfigPromotionsPaginateList = PaginateList<ConfigPromtoionsListItem>;
export type ConfigAboutMePaginateList = PaginateList<ConfigAboutMeListItem>;
export type ConfigBannerTierPaginateList = PaginateList<ConfigBannerTierListItem>;
export type MassUploadStatusPaginateList = PaginateList<MassUploadStatusListItem>;
export const ModuleTypeList: { [key: string]: string } = {
  Sales: 'Sales Material',
  // Promotion: 'Promotion Material',
  // CustomerSurvey: 'Customer Survey',
};

export const ModuleTypeTranslate: { [key: string]: string } = {
  Sales: 'salesMaterial',
  // Promotion: 'promotionMaterial',
  // CustomerSurvey: 'customerSurvey',
};
export enum TargetProspectType {
  ALL = 'ALL',
  SPECIFIY = 'SPECIFIC_RULE',
}

export enum fieldsType {
  EngagementType = 'Engagement Type',
  CommucationMethod = 'Commucation Method',
  Effectivity = 'Effectivity',
}

export enum AboutMeformMode {
  CREATE = 'create',
  EDIT = 'edit',
}
export enum BannerTierformMode {
  CREATE = 'create',
  EDIT = 'edit',
}
export enum FormMode {
  CREATE = 'create',
  EDIT = 'edit',
}

export interface MultiLanguage {
  enUs: string;
  zhHk: string;
}

export type ConfigBannerErrorState = {
  mandatory: {
    bannerUrl: boolean;
  };
  immediate: Record<string, string>;
};

export const csvUploadType: Map<string, any> = new Map<string, number>([
  ['.csv', 1],
  ['text/csv', 2],
]);

export const imageUploadType: Map<string, any> = new Map<string, number>([
  ['image/jpg', 1],
  ['image/jpeg', 2],
  ['image/png', 3],
  ['.png', 4],
  ['.jpeg', 5],
  ['.jpg', 6],
]);

export const MANDATORY_FIELD_ERROR_TEXT = 'This field is mandatory';
export const DATE_ERROR_TEXT = 'End date must be after Begin date';
