import React, { FC, useReducer, useEffect, useState, useMemo } from 'react';
import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import { makeStyles } from 'tss-react/mui';
import {
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  FormControl,
  Select,
  MenuItem,
  Chip,
  LinearProgress,
  Link,
  Tooltip,
} from '@mui/material';
import { Info } from '@mui/icons-material';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import {
  ErrorFieldType,
  useErrorHandler,
  ErrorFieldDef,
  isValidURL,
  useDebounce,
  fileUpload,
} from 'src/app/common/utils';
import {
  MANDATORY_FIELD_ERROR_TEXT,
  DATE_ERROR_TEXT,
  EMAIL_FORMAT_ERROR_TEXT,
  DUPLICATE_ID_ERROR_TEXT,
} from 'src/app/common/constants';
import { useDispatch, useSelector } from 'react-redux';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import { RootState } from 'src/redux/store';
import { AuthenticationState } from 'src/app/modules/Auth/_redux/authSlice';
import { campaignPath } from '../../CampaignRoutes';
import {
  CampaignFormMode,
  CampaignItem,
  MaximumLeadsItem,
  LeadWarningMessageItem,
  CampaignDropdownList,
  CampaignDropdownType,
  CampaignMandatoryDataEnum,
  CampaignTypeEnum,
  CampaignPriorityEnum,
  CampaignStatusEnum,
  CampaignCustomerDedupEnum,
  DistributionChannelEnum,
  TargetProductTypeEnum,
  RoundRobinModeEnum,
  LeadWithPartialConditionEnum,
  LeadWithPartialConditionItem,
  DropLeadTypeEnum,
  LeadActionTypeEnum,
  ProspectCategoryItem,
  ReferralAgentCheckTypeEnum,
} from 'src/app/modules/PulseLeads/types/campaign-types';
import { AttachmentDef } from 'src/app/common/types/common-types';
import {
  CampaignLinkBody,
  CampaignPriorityBody,
  CampaignFlyerBody,
  CampaignFaqBody,
  CampaignDropdownListParam,
  CreateCampaignBody,
  UpdateCampaignBody,
  fetchCampaignDropdownList,
  checkDuplicateCampaign,
  createCampaign,
  modifyCampaign,
} from 'src/app/modules/PulseLeads/network/campaignCrud';
import { PruDateTimePicker, PruDatePicker } from 'src/app/common/components/PruDatePicker';
import moment from 'moment';
import { map } from 'lodash';
import { NOT_VALID_URL_TEXT, LAUNCH_DATE_ERROR_TEXT } from 'src/app/modules/PulseLeads/constants';
import FormDialog, { FormDialogFieldDef, FormDialogItemType } from 'src/app/common/components/FormDialog';
import LinkItem from './LinkItem';
import HierachyDropdown from 'src/app/common/components/Dropdown/HierachyDropdown';
import { createBlob, getBlob } from 'src/app/common/network';
import { PruToast } from 'src/app/common/components/pru-toast';

type CampaignFormProps = {
  formMode: CampaignFormMode;
  campaignDropdownList?: CampaignDropdownList;
  campaignItem?: CampaignItem;
  config?: any;
  salesCommonConfig?: any;
  prospectCategoryList?: ProspectCategoryItem[];
};

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  fieldContainer: {
    width: 250,
    boxSizing: 'border-box',
  },
  checkboxFieldContainer: {
    paddingTop: 10,
    width: 250,
    boxSizing: 'border-box',
  },
  imageContainer: {
    width: 120,
    height: 120,
    overflow: 'hidden',
    borderRadius: 8,
  },
  dateDivider: {
    marginLeft: 8,
    marginRight: 8,
  },
  optionDefault: {
    color: '#AAAAAA',
  },
  innerFieldContainer: {
    width: 250,
    boxSizing: 'border-box',
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },
  sectionMargin: {
    marginBottom: 25,
  },
  divideMargin: {
    marginBottom: 10,
  },
  subHeader: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
  },
  subSectionHeader: {
    fontSize: '14px',
    fontWeight: 500,
    fontColor: 'black',
  },
  textAreaRowContainer: {
    width: '100%',
    display: 'flex',
  },
  textAreaFieldContainer: {
    paddingTop: 15,
    width: 250,
    boxSizing: 'border-box',
  },
  textArea: {
    lineHeight: 1.5,
    minHeight: 40,
  },
  errorText: {
    fontSize: 9,
    color: '#F018A6',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  checkboxPadding: {
    marginLeft: -9,
  },
}));

const styles = {
  minWidthNumber: { minWidth: 100 },
  inputMarginLeft: { marginLeft: 240, flex: 1 },
  minSubTitle: { minWidth: 250 },
};

type CampaignMessageState = {
  title?: string;
  body?: string;
};

type CampaignFormState = {
  name?: string;
  campaignId?: string;
  campaignType?: string;
  description?: string;
  regionalCampaignName?: string;
  type: string;
  sort?: string;
  startDate: Date | null;
  endDate: Date | null;
  launchDate: Date | null;
  emailMessage?: CampaignMessageState;
  notificationMessage?: CampaignMessageState;
  mandatoryData: string[];
  image?: AttachmentDef;
  maxLeadPerAgent?: string;
  marketingOwnerName?: string;
  marketingOwnerEmail?: string;
  isNewLeadNotificationEnabled: boolean;
  callScripts?: string;
  saleTipsInsight?: string;
  faq: CampaignFaqBody[];
  links: CampaignLinkBody[];
  priorities: CampaignPriorityBody[];
  flyers: CampaignFlyerBody[];
  source?: string;
  subSource?: string[];
  acceptSLA?: string;
  contactSLA?: string;
  meetSLA?: string;
  proposalSLA?: string;
  applicationSLA?: string;
  customerIncentive?: string;
  agentIncentive?: string;
  isSelfCreateLeadEnabled: boolean;
  maximumMarketingLeads: MaximumLeadsItem;
  // maximumLeadSourceLeads: MaximumLeadsItem;
  maxLeadLimit?: number;
  isAutoAssignLeadIfAgentInactive: boolean;
  isLeadWithPartialInfo: boolean;
  leadWithPartialInfoWarning: LeadWarningMessageItem;
  leadWithPartialCondition?: LeadWithPartialConditionItem;
  distributionRule?: string;
  eligibilityRule?: string;
  leadDedupRule?: string;
  customerDedup?: string;
  customerDedupRule?: string[];
  exclusionRule?: string;
  isEngagementRequired: boolean;
  isStaging: boolean;
  leadAction: string;
  prospectCategory?: string;
  distributionChannel?: string;
  targetProductType?: string[];
  feedbacks?: string[];
  feedbackGroup?: string;
  roundRobinMode: string;
  enableAutoDropLead: boolean;
  autoDropLeadType: DropLeadTypeEnum | string;
  autoDropLeadConfig?: AutoDropLeadConfig;
  referralAgentCheck?: string;
};

export type AutoDropLeadConfig = {
  [key in DropLeadTypeEnum | string]: number;
};
const defaultMaximumLeadsItem = {
  isMaximum: false,
  limit: undefined,
  isEngage: true,
};

const initialState = (fieldConfig?: any): CampaignFormState => {
  return {
    name: undefined,
    campaignId: undefined,
    campaignType: undefined,
    description: undefined,
    regionalCampaignName: undefined,
    type: CampaignTypeEnum.NORMAL,
    sort: undefined,
    startDate: null,
    endDate: null,
    launchDate: null,
    mandatoryData: [],
    image: undefined,
    maxLeadPerAgent: undefined,
    marketingOwnerName: undefined,
    marketingOwnerEmail: undefined,
    isNewLeadNotificationEnabled: false,
    callScripts: undefined,
    saleTipsInsight: undefined,
    faq: [],
    links: [],
    priorities: [],
    flyers: [],
    source: undefined,
    subSource: undefined,
    acceptSLA: undefined,
    contactSLA: undefined,
    meetSLA: undefined,
    proposalSLA: undefined,
    applicationSLA: undefined,
    customerIncentive: undefined,
    agentIncentive: undefined,
    isSelfCreateLeadEnabled: false,
    maximumMarketingLeads: { ...defaultMaximumLeadsItem },
    // maximumLeadSourceLeads: { ...defaultMaximumLeadsItem },
    isAutoAssignLeadIfAgentInactive: false,
    isLeadWithPartialInfo: false,
    leadWithPartialInfoWarning: {
      title: undefined,
      body: undefined,
      tnc: undefined,
    },
    leadWithPartialCondition: undefined,
    distributionRule: undefined,
    eligibilityRule: undefined,
    leadDedupRule: undefined,
    referralAgentCheck: ReferralAgentCheckTypeEnum.ENABLE,
    customerDedup: undefined,
    customerDedupRule: undefined,
    exclusionRule: undefined,
    isEngagementRequired: false,
    isStaging: false,
    leadAction: LeadActionTypeEnum.DIRECT_DISTRIBUTE,
    prospectCategory: undefined,
    distributionChannel: undefined,
    targetProductType: undefined,
    feedbacks: undefined,
    feedbackGroup: undefined,
    roundRobinMode: fieldConfig?.defaultRoundRobinMode ?? RoundRobinModeEnum.ROUND_ROBIN,
    // PACS Customization | PACSPFAP-1445 | hide lead drop
    enableAutoDropLead: false,
    autoDropLeadType: DropLeadTypeEnum.BY_LEAD_UPDATE_TIME,
  };
};

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof CampaignFormState;
    value: any;
  };
};

type ToggleMessageAction = {
  type: 'TOGGLE_MESSAGE';
  payload: {
    field: keyof Pick<CampaignFormState, 'emailMessage' | 'notificationMessage'>;
  };
};

type ModifyMessageFieldAction = {
  type: 'MODIFY_MESSAGE_FIELD';
  payload: {
    field: keyof Pick<CampaignFormState, 'emailMessage' | 'notificationMessage'>;
    messageField: keyof CampaignMessageState;
    value: any;
  };
};

type AddItemAction = {
  type: 'APPEND_ITEM';
  payload: {
    field: keyof Pick<CampaignFormState, 'faq' | 'links' | 'flyers'>;
    item: any;
  };
};

type ModifyItemAction = {
  type: 'MODIFY_ITEM';
  payload: {
    field: keyof Pick<CampaignFormState, 'faq' | 'links' | 'flyers'>;
    index: number;
    item: any;
  };
};

type DeleteItemAction = {
  type: 'DELETE_ITEM';
  payload: {
    field: keyof Pick<CampaignFormState, 'faq' | 'links' | 'flyers'>;
    index: number;
  };
};

type CampaignFormAction =
  | ModifyFieldAction
  | ToggleMessageAction
  | ModifyMessageFieldAction
  | AddItemAction
  | ModifyItemAction
  | DeleteItemAction;

const formReducer = (state: CampaignFormState, action: CampaignFormAction): CampaignFormState => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      let newState = { ...state };
      if (action.payload.field === 'isEngagementRequired') {
        newState.contactSLA = undefined;
      }
      if (action.payload.field === 'customerDedup' && !action.payload.value) {
        newState.customerDedupRule = undefined;
      }
      if (action.payload.field === 'type') {
        if (action.payload.value === CampaignTypeEnum.NORMAL) {
          newState.emailMessage = undefined;
          newState.notificationMessage = undefined;
          newState.launchDate = null;
        } else if (
          action.payload.value === CampaignTypeEnum.MASS ||
          action.payload.value === CampaignTypeEnum.SERVICING
        ) {
          newState.acceptSLA = undefined;
          newState.contactSLA = undefined;
          newState.meetSLA = undefined;
          newState.proposalSLA = undefined;
          newState.applicationSLA = undefined;
          newState.leadDedupRule = undefined;
          newState.referralAgentCheck = undefined;
          newState.customerDedupRule = undefined;
          newState.customerDedup = undefined;
          newState.exclusionRule = undefined;
          newState.distributionRule = undefined;
          newState.eligibilityRule = undefined;
        }
      }
      return {
        ...newState,
        [action.payload.field]: action.payload.value,
      };
    case 'TOGGLE_MESSAGE':
      return {
        ...state,
        [action.payload.field]: state[action.payload.field]
          ? undefined
          : {
              title: undefined,
              body: undefined,
            },
      };
    case 'MODIFY_MESSAGE_FIELD':
      return {
        ...state,
        [action.payload.field]: {
          ...state[action.payload.field],
          [action.payload.messageField]: action.payload.value,
        },
      };
    case 'APPEND_ITEM':
      return {
        ...state,
        [action.payload.field]: [...state[action.payload.field], action.payload.item],
      };
    case 'MODIFY_ITEM':
      let modifyItemList = state[action.payload.field];
      modifyItemList[action.payload.index] = action.payload.item;
      return { ...state };
    case 'DELETE_ITEM':
      let deleteItemList = state[action.payload.field];
      deleteItemList.splice(action.payload.index, 1);
      return { ...state };
    default:
      return state;
  }
};

const detailToStateConvertor = (campaignItem: CampaignItem, fieldConfig: any): CampaignFormState => {
  return {
    name: campaignItem.name,
    campaignId: campaignItem.campaignId,
    campaignType: campaignItem.campaignType || 'Campaign',
    description: campaignItem.description,
    regionalCampaignName: campaignItem.regionalCampaignName,
    type: campaignItem.type,
    sort: campaignItem.sort,
    startDate: campaignItem.startDate,
    endDate: campaignItem.endDate,
    launchDate: campaignItem.launchDate || null,
    emailMessage: campaignItem.emailMessage,
    notificationMessage: campaignItem.notificationMessage,
    mandatoryData: campaignItem.mandatoryData || [],
    image: campaignItem.image,
    maxLeadPerAgent: campaignItem.maxLeadPerAgent ? String(campaignItem.maxLeadPerAgent) : undefined,
    marketingOwnerName: campaignItem.marketingOwnerName,
    marketingOwnerEmail: campaignItem.marketingOwnerEmail,
    isNewLeadNotificationEnabled: campaignItem.isNewLeadNotificationEnabled,
    callScripts: campaignItem.callScripts,
    saleTipsInsight: campaignItem.saleTipsInsight,
    faq: campaignItem.faq || [],
    links: campaignItem.links || [],
    priorities: campaignItem.priorities || [],
    flyers: campaignItem.flyers || [],
    source: campaignItem.source,
    subSource: campaignItem.subSource,
    acceptSLA:
      campaignItem.acceptSLA &&
      (typeof campaignItem.acceptSLA === 'object' ? campaignItem.acceptSLA._id : campaignItem.acceptSLA),
    contactSLA:
      campaignItem.contactSLA &&
      (typeof campaignItem.contactSLA === 'object' ? campaignItem.contactSLA._id : campaignItem.contactSLA),
    meetSLA:
      campaignItem.meetSLA &&
      (typeof campaignItem.meetSLA === 'object' ? campaignItem.meetSLA._id : campaignItem.meetSLA),
    proposalSLA:
      campaignItem.proposalSLA &&
      (typeof campaignItem.proposalSLA === 'object' ? campaignItem.proposalSLA._id : campaignItem.proposalSLA),
    applicationSLA:
      campaignItem.applicationSLA &&
      (typeof campaignItem.applicationSLA === 'object' ? campaignItem.applicationSLA._id : campaignItem.applicationSLA),
    customerIncentive: campaignItem.customerIncentive,
    agentIncentive: campaignItem.agentIncentive,
    isSelfCreateLeadEnabled: campaignItem.isSelfCreateLeadEnabled ? true : false,
    maximumMarketingLeads: campaignItem.maximumMarketingLeads ?? initialState().maximumMarketingLeads,
    // maximumLeadSourceLeads: campaignItem.maximumLeadSourceLeads ?? initialState().maximumLeadSourceLeads,
    isAutoAssignLeadIfAgentInactive: campaignItem.isAutoAssignLeadIfAgentInactive ? true : false,
    isLeadWithPartialInfo: campaignItem.isLeadWithPartialInfo ? true : false,
    leadWithPartialInfoWarning: campaignItem.leadWithPartialInfoWarning ?? initialState().leadWithPartialInfoWarning,
    leadWithPartialCondition: campaignItem.leadWithPartialCondition,
    distributionRule:
      campaignItem.distributionRule &&
      (typeof campaignItem.distributionRule === 'object'
        ? campaignItem.distributionRule._id
        : campaignItem.distributionRule),
    eligibilityRule:
      campaignItem.eligibilityRule &&
      (typeof campaignItem.eligibilityRule === 'object'
        ? campaignItem.eligibilityRule._id
        : campaignItem.eligibilityRule),
    leadDedupRule:
      campaignItem.leadDedupRule &&
      (typeof campaignItem.leadDedupRule === 'object' ? campaignItem.leadDedupRule._id : campaignItem.leadDedupRule),
    referralAgentCheck: campaignItem.referralAgentCheck
      ? campaignItem.referralAgentCheck
      : ReferralAgentCheckTypeEnum.ENABLE,
    customerDedup: campaignItem.customerDedup,
    customerDedupRule: campaignItem.customerDedupRule
      ? campaignItem.customerDedupRule instanceof Array
        ? campaignItem.customerDedupRule.map((item) => (typeof item === 'object' ? item._id : item))
        : [
            typeof campaignItem.customerDedupRule === 'object'
              ? campaignItem.customerDedupRule._id
              : campaignItem.customerDedupRule,
          ]
      : undefined,
    exclusionRule:
      campaignItem.exclusionRule &&
      (typeof campaignItem.exclusionRule === 'object' ? campaignItem.exclusionRule._id : campaignItem.exclusionRule),
    isEngagementRequired: campaignItem.isEngagementRequired,
    isStaging: campaignItem.isStaging ? true : false,
    leadAction: campaignItem.leadAction
      ? campaignItem.leadAction
      : campaignItem.isStaging
      ? LeadActionTypeEnum.STAGING
      : LeadActionTypeEnum.DIRECT_DISTRIBUTE,
    prospectCategory: campaignItem.prospectCategory,
    distributionChannel: campaignItem.distributionChannel,
    targetProductType: campaignItem.targetProductType,
    feedbacks: campaignItem.feedbacks,
    feedbackGroup: campaignItem.feedbackGroup,
    roundRobinMode: campaignItem?.roundRobinMode ?? fieldConfig.defaultRoundRobinMode,
    enableAutoDropLead: campaignItem.autoDropRule?.enabled ?? initialState().enableAutoDropLead,
    autoDropLeadType: campaignItem.autoDropRule?.type ?? initialState().autoDropLeadType,
    autoDropLeadConfig:
      campaignItem.autoDropRule?.day && campaignItem.autoDropRule?.type
        ? {
            [campaignItem.autoDropRule?.type]: campaignItem.autoDropRule?.day,
          }
        : undefined,
  };
};

type FormDialogState = {
  title?: string;
  open: boolean;
  dataSource?: Record<string, unknown>;
  fieldDef: FormDialogFieldDef[];
  onFinish: (data: Record<string, unknown>) => void;
  onClose: () => void;
};

const initialDialogState: FormDialogState = {
  title: undefined,
  open: false,
  dataSource: undefined,
  fieldDef: [],
  onFinish: () => {},
  onClose: () => {},
};

const getFieldConfig = (config: any) => {
  return {
    enableSubSource: config?.lead?.enableSubSource ? true : false,
    mandatorySubSource: config?.lead?.mandatorySubSource ? true : false,
    defaultRoundRobinMode: config?.lead?.defaultRoundRobinMode ?? RoundRobinModeEnum.ROUND_ROBIN,
    enableCampaignType: config?.campaign?.enableCampaignType ? true : false,
    mandatoryCampaignType: config?.campaign?.mandatoryCampaignType ? false : false,
    enableDistributionChannel: config?.campaign?.enableDistributionChannel ? true : false,
    mandatoryDistributionChannel: config?.campaign?.mandatoryDistributionChannel ? true : false,
    enableServicingCampaign: config?.campaign?.enableServicingCampaign ? true : false,
    enableEngagementRequired: config?.campaign?.enableEngagementRequired ? true : false,
    enableLeadWithPartialInfoWarning: config?.campaign?.enableLeadWithPartialInfoWarning ?? false,
  };
};

const getSalesCommonFieldConfig = (config: any) => {
  return {
    leadAction: config?.campaign?.leadAction?.map((value: any) => value.name) ?? [
      LeadActionTypeEnum.DIRECT_DISTRIBUTE,
      LeadActionTypeEnum.STAGING,
    ],
  };
};

const validatePriorities = (formState: CampaignFormState): boolean => {
  let hasError = false;
  if (formState.leadDedupRule) {
    hasError = !!!formState.priorities.find((item) => item.step === CampaignPriorityEnum.LEAD_DEDUP);
  }
  if (formState.customerDedup) {
    hasError = !!!formState.priorities.find((item) => item.step === CampaignPriorityEnum.CUSTOMER_DEDUP);
  }
  if (formState.referralAgentCheck !== ReferralAgentCheckTypeEnum.DISABLE) {
    hasError = !!!formState.priorities.find((item) => item.step === CampaignPriorityEnum.REFERRAL_AGENT);
  }
  return hasError;
};
const getRequiredPriorities = (formState: CampaignFormState): string[] => {
  let result = [];
  if (
    formState.leadDedupRule ||
    formState.customerDedup ||
    formState.referralAgentCheck !== ReferralAgentCheckTypeEnum.DISABLE
  ) {
    if (formState.referralAgentCheck !== ReferralAgentCheckTypeEnum.DISABLE) {
      result.push('Step 3');
    }
    if (formState.leadDedupRule) {
      result.push('Step 4');
    }
    if (formState.customerDedup) {
      result.push('Step 5');
    }
  }
  return result;
};

const CampaignForm: FC<CampaignFormProps> = ({
  formMode,
  campaignDropdownList,
  campaignItem,
  config,
  salesCommonConfig,
  prospectCategoryList,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { classes: commonClasses } = useCommonStyles();
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { user } = useSelector<RootState, AuthenticationState>((state) => state.auth);

  const fieldConfig = getFieldConfig(config?.content?.Sales);
  const salesCommonfieldConfig = getSalesCommonFieldConfig(salesCommonConfig?.content?.Sales);

  const { enableDraftUpdate, enableActiveUpdate } = useMemo(() => {
    return {
      enableDraftUpdate: !campaignItem || (campaignItem && campaignItem.status === CampaignStatusEnum.DRAFT),
      enableActiveUpdate: campaignItem && campaignItem.status === CampaignStatusEnum.ACTIVE,
    };
  }, [campaignItem]);

  const [imageUploadProgress, setImageUploadProgress] = useState<number>();
  const [formDialogState, setFormDialogState] = useState<FormDialogState>(initialDialogState);
  const [formState, formDispatch] = useReducer(
    formReducer,
    campaignItem ? detailToStateConvertor(campaignItem, fieldConfig) : initialState(fieldConfig),
  );

  const [duplicateIdErrorState, setDuplicateIdErrorState] = useState<boolean>(false);

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler, immediateErrorValidator } = useErrorHandler(
    formState,
    [
      {
        name: 'image',
        fieldType: ErrorFieldType.MANDATORY,
      },
      {
        name: 'name',
        fieldType: ErrorFieldType.MANDATORY,
      },
      {
        name: 'source',
        fieldType: ErrorFieldType.MANDATORY,
      },
      {
        name: 'startDate',
        fieldType: ErrorFieldType.MANDATORY,
      },
      {
        name: 'endDate',
        fieldType: ErrorFieldType.MANDATORY,
      },
      {
        name: 'endDateBeforeStartDate',
        fieldType: ErrorFieldType.IMMEDIATE,
        condition: () => {
          if (formState.startDate && formState.endDate) {
            return !!moment(new Date(formState.startDate)).isAfter(moment(new Date(formState.endDate)));
          } else {
            return false;
          }
        },
      },
      {
        name: 'description',
        fieldType: ErrorFieldType.MANDATORY,
      },
      {
        name: 'marketingOwnerName',
        fieldType: ErrorFieldType.MANDATORY,
      },
      {
        name: 'marketingOwnerEmail',
        fieldType: ErrorFieldType.MANDATORY,
      },
      {
        name: 'marketingOwnerEmailFormat',
        fieldType: ErrorFieldType.IMMEDIATE,
        condition: () => {
          if (formState.marketingOwnerEmail) {
            // const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            const re = new RegExp(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            );
            return !re.test(String(formState.marketingOwnerEmail).toLowerCase());
          } else {
            return false;
          }
        },
      },
      {
        name: 'maxLeadLimit',
        fieldType: ErrorFieldType.IMMEDIATE,
        condition: () => {
          if (formState.type === CampaignTypeEnum.NORMAL && formState.maximumMarketingLeads.isMaximum) {
            const numberRegex = new RegExp(/^\d+$/);
            return !numberRegex.test(String(formState?.maximumMarketingLeads.limit));
          } else {
            return false;
          }
        },
      },
      // PACS Customization | PACSPFAP-1445 hide lead drop
      // {
      //   name: 'autoDropLeadDays',
      //   fieldType: ErrorFieldType.IMMEDIATE,
      //   condition: () => {
      //     if (
      //       formState.type !== CampaignTypeEnum.SERVICING &&
      //       formState.enableAutoDropLead &&
      //       formState?.autoDropLeadType
      //     ) {
      //       const numberRegex = new RegExp(/^\d+$/);
      //       return !numberRegex.test(String(formState?.autoDropLeadConfig?.[formState.autoDropLeadType]));
      //     } else {
      //       return false;
      //     }
      //   },
      // },
      ...(() => {
        const errorFieldDef: ErrorFieldDef[] = [];
        if (formState.type === CampaignTypeEnum.NORMAL) {
          errorFieldDef.push({
            name: 'acceptSLA',
            fieldType: ErrorFieldType.MANDATORY,
          });

          // PACS Customization | PACSPFAP-1930 | Made distributionRule & eligibilityRule mandatory only for normal campaign type.
          errorFieldDef.push({
            name: 'distributionRule',
            fieldType: ErrorFieldType.MANDATORY,
          });

          errorFieldDef.push({
            name: 'eligibilityRule',
            fieldType: ErrorFieldType.MANDATORY,
          });

          if (formState.isLeadWithPartialInfo) {
            errorFieldDef.push(
              {
                name: 'leadPartialInfoTitle',
                fieldType: ErrorFieldType.MANDATORY,
                condition: () => {
                  return !!!formState.leadWithPartialInfoWarning?.title;
                },
              },
              {
                name: 'leadPartialInfoBody',
                fieldType: ErrorFieldType.MANDATORY,
                condition: () => {
                  return !!!formState.leadWithPartialInfoWarning?.body;
                },
              },
              {
                name: 'leadPartialInfoTnc',
                fieldType: ErrorFieldType.MANDATORY,
                condition: () => {
                  return !!!formState.leadWithPartialInfoWarning?.tnc;
                },
              },
            );
          }
          if (
            formState.leadDedupRule ||
            formState.customerDedup ||
            formState.referralAgentCheck !== ReferralAgentCheckTypeEnum.DISABLE
          ) {
            errorFieldDef.push({
              name: 'criteriaPriority',
              fieldType: ErrorFieldType.MANDATORY,
              condition: () => validatePriorities(formState),
            });
          }
        } else {
          errorFieldDef.push(
            {
              name: 'launchDate',
              fieldType: ErrorFieldType.MANDATORY,
            },
            {
              name: 'launchDateNotBetweenStartDateEndDate',
              fieldType: ErrorFieldType.IMMEDIATE,
              condition: () => {
                if (
                  formState.startDate &&
                  formState.launchDate &&
                  moment(new Date(formState.launchDate)).isBefore(moment(new Date(formState.startDate)))
                ) {
                  return true;
                }
                if (
                  formState.endDate &&
                  formState.launchDate &&
                  moment(new Date(formState.launchDate)).isAfter(moment(new Date(formState.endDate)))
                ) {
                  return true;
                }
                return false;
              },
            },
          );
          if (formState.emailMessage) {
            errorFieldDef.push(
              {
                name: 'emailTitle',
                fieldType: ErrorFieldType.MANDATORY,
                condition: () => {
                  return !!!formState.emailMessage?.title;
                },
              },
              {
                name: 'emailBody',
                fieldType: ErrorFieldType.MANDATORY,
                condition: () => {
                  return !!!formState.emailMessage?.body;
                },
              },
            );
          }
          if (formState.notificationMessage) {
            errorFieldDef.push(
              {
                name: 'notificationTitle',
                fieldType: ErrorFieldType.MANDATORY,
                condition: () => {
                  return !!!formState.notificationMessage?.title;
                },
              },
              {
                name: 'notificationBody',
                fieldType: ErrorFieldType.MANDATORY,
                condition: () => {
                  return !!!formState.notificationMessage?.body;
                },
              },
            );
          }
          if (formState.type === CampaignTypeEnum.SERVICING) {
            errorFieldDef.push(
              {
                name: 'subSourceMustBeServicingCampaignOnly',
                fieldType: ErrorFieldType.IMMEDIATE,
                condition: () => {
                  if (subSourceDropdownList && formState.subSource && formState.subSource.length > 0) {
                    if (formState.subSource.length > 1) {
                      return true;
                    }
                    const subSourceKey = formState.subSource[0];
                    return (
                      subSourceDropdownList[CampaignDropdownType.LEAD_SOURCE].find((item) => item.key === subSourceKey)
                        ?.value !== 'Servicing Communication'
                    );
                  } else {
                    return false;
                  }
                },
              },
              {
                name: 'feedbackGroup',
                fieldType: ErrorFieldType.MANDATORY,
              },
              // {
              //   name: "feedbacks",
              //   fieldType: ErrorFieldType.MANDATORY,
              // },
              // {
              //   name: "emptyFeedbackList",
              //   fieldType: ErrorFieldType.IMMEDIATE,
              //   condition: () => {
              //     if (formState.feedbacks) {
              //       return formState.feedbacks.length === 0;
              //     } else {
              //       return false;
              //     }
              //   },
              // }
            );
            if (fieldConfig.enableCampaignType) {
              errorFieldDef.push({
                name: 'campaignTypeMustBeServicingCampaign',
                fieldType: ErrorFieldType.IMMEDIATE,
                condition: () => {
                  if (formState.campaignType) {
                    return formState.campaignType !== 'Servicing Campaign';
                  } else {
                    return false;
                  }
                },
              });
            }
          }
        }

        if (fieldConfig.enableSubSource && fieldConfig.mandatorySubSource) {
          errorFieldDef.push(
            {
              name: 'subSource',
              fieldType: ErrorFieldType.MANDATORY,
            },
            {
              name: 'emptySubSourceList',
              fieldType: ErrorFieldType.IMMEDIATE,
              condition: () => {
                if (formState.subSource) {
                  return formState.subSource.length === 0;
                } else {
                  return false;
                }
              },
            },
          );
        }

        if (fieldConfig.enableCampaignType && fieldConfig.mandatoryCampaignType) {
          errorFieldDef.push({
            name: 'campaignType',
            fieldType: ErrorFieldType.MANDATORY,
          });
        }

        if (fieldConfig.enableDistributionChannel && fieldConfig.mandatoryDistributionChannel) {
          errorFieldDef.push({
            name: 'distributionChannel',
            fieldType: ErrorFieldType.MANDATORY,
          });
        }

        return errorFieldDef;
      })(),
    ],
  );

  const [subSourceDropdownList, setSubSourceDropdownList] = useState<CampaignDropdownList>();
  const reloadSubSourceDropdown = async () => {
    const params: CampaignDropdownListParam = {
      type: [CampaignDropdownType.LEAD_SOURCE],
      parent: formState.source,
    };
    const dropdownListRes = await fetchCampaignDropdownList(params, dispatch);
    setSubSourceDropdownList(dropdownListRes);
  };

  const [requiredPriorities, setRequiredPriorities] = useState<string[]>([]);
  useEffect(() => {
    setRequiredPriorities(getRequiredPriorities(formState));
  }, [formState.leadDedupRule, formState.customerDedup, formState.referralAgentCheck]);

  useEffect(
    () => {
      immediateErrorValidator();
    },
    // eslint-disable-next-line
    [
      formState.marketingOwnerEmail,
      formState.startDate,
      formState.endDate,
      formState.launchDate,
      formState.subSource,
      formState.type,
      formState.campaignType,
      formState.autoDropLeadConfig,
      formState.enableAutoDropLead,
      formState.maximumMarketingLeads,
      // formState.feedbacks,
    ],
  );

  useEffect(() => {
    reloadSubSourceDropdown();
  }, [formState.source]);

  const faqFieldDef: FormDialogFieldDef[] = [
    {
      key: 'question',
      displayName: Translation('pulseleads.campaign.faq.question'),
      errorDef: [
        {
          key: 'question',
          type: ErrorFieldType.MANDATORY,
          message: MANDATORY_FIELD_ERROR_TEXT,
        },
      ],
      item: {
        type: FormDialogItemType.TEXT,
      },
    },
    {
      key: 'answer',
      displayName: Translation('pulseleads.campaign.faq.answer'),
      errorDef: [
        {
          key: 'answer',
          type: ErrorFieldType.MANDATORY,
          message: MANDATORY_FIELD_ERROR_TEXT,
        },
      ],
      item: {
        type: FormDialogItemType.TEXT_AREA,
      },
    },
  ];

  const linksFieldDef: FormDialogFieldDef[] = [
    {
      key: 'title',
      displayName: Translation('pulseleads.campaign.links.title'),
      errorDef: [
        {
          key: 'title',
          type: ErrorFieldType.MANDATORY,
          message: MANDATORY_FIELD_ERROR_TEXT,
        },
      ],
      item: {
        type: FormDialogItemType.TEXT,
      },
    },
    {
      key: 'link',
      displayName: Translation('pulseleads.campaign.links.link'),
      errorDef: [
        {
          key: 'link',
          type: ErrorFieldType.MANDATORY,
          message: MANDATORY_FIELD_ERROR_TEXT,
        },
        {
          key: 'linkNotUrl',
          type: ErrorFieldType.IMMEDIATE,
          condition: (currentState) => !isValidURL(currentState.link as string),
          message: NOT_VALID_URL_TEXT,
        },
      ],
      item: {
        type: FormDialogItemType.TEXT,
      },
    },
  ];

  const flyersFieldDef: FormDialogFieldDef[] = [
    {
      key: 'title',
      displayName: Translation('pulseleads.campaign.flyers.title'),
      errorDef: [
        {
          key: 'title',
          type: ErrorFieldType.MANDATORY,
          message: MANDATORY_FIELD_ERROR_TEXT,
        },
      ],
      item: {
        type: FormDialogItemType.TEXT,
      },
    },
    {
      key: 'attachment',
      displayName: Translation('pulseleads.campaign.flyers.attachment'),
      errorDef: [
        {
          key: 'attachment',
          type: ErrorFieldType.MANDATORY,
          condition: (currentState) => {
            //@ts-ignore
            if (
              currentState.attachment &&
              currentState.attachment.filename &&
              currentState.attachment.blobId &&
              currentState.attachment.url
            ) {
              return false;
            } else {
              return true;
            }
          },
          message: MANDATORY_FIELD_ERROR_TEXT,
        },
      ],
      item: {
        type: FormDialogItemType.ATTACHMENT,
      },
    },
  ];

  const handleFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      try {
        const createBlobRes = await createBlob(
          { mimeType: file.type, accessLevel: 'public', module: 'pulseLeads' },
          dispatch,
        );
        await fileUpload(createBlobRes.url, file, setImageUploadProgress);
        const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);
        const result = blobDetail[0];
        if (result) {
          const currentState = {
            blobId: result.blobId,
            filename: file.name,
            url: result.url,
          };
          formDispatch({
            type: 'MODIFY_FIELD',
            payload: {
              field: 'image',
              value: currentState,
            },
          });
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: `Upload file successfully - ${file.name}`,
              },
            ]),
          );
          onDismissErrorHandler('image', true);
        }
      } finally {
        setImageUploadProgress(undefined);
      }
    }
  };

  const onCheckDuplicateCampaign = async (campaignId: string) => {
    if (campaignId && campaignId !== '') {
      const isDuplicate = await checkDuplicateCampaign(campaignId);
      setDuplicateIdErrorState(isDuplicate);
    } else {
      setDuplicateIdErrorState(false);
    }
  };
  const debounceCheckDuplicate = useDebounce(onCheckDuplicateCampaign, 1000);

  const getLeadWithPartialCondition = async (leadWithPartialCondition: string | undefined) => {
    if (!leadWithPartialCondition) return undefined;

    let obj = {};
    const items = [];

    switch (leadWithPartialCondition) {
      case LeadWithPartialConditionEnum.COUNTRY_NATIONAL_ID:
        items.push({ type: 'countryNationalId', operator: 'include' });
        break;
      case LeadWithPartialConditionEnum.PHONE_NUMBER:
        items.push({ type: 'phoneNumber', operator: 'include' });
        break;
      case LeadWithPartialConditionEnum.EMAIL_ADDRESS:
        items.push({ type: 'email', operator: 'include' });
        break;
      case LeadWithPartialConditionEnum.LOCATION:
        items.push({ type: 'city', operator: 'and' });
        items.push({ type: 'state', operator: 'and' });
        break;
      case LeadWithPartialConditionEnum.COUNTRY_NATIONAL_ID_AND_PHONE_NUMBER_AND_EMAIL_ADDRESS_AND_LOCATION:
        items.push({ type: 'countryNationalId', operator: 'and' });
        items.push({ type: 'phoneNumber', operator: 'and' });
        items.push({ type: 'email', operator: 'and' });
        items.push({ type: 'city', operator: 'and' });
        items.push({ type: 'state', operator: 'and' });
        break;
      case LeadWithPartialConditionEnum.COUNTRY_NATIONAL_ID_OR_PHONE_NUMBER_OR_EMAIL_ADDRESS_OR_LOCATION:
        items.push({ type: 'countryNationalId', operator: 'or' });
        items.push({ type: 'phoneNumber', operator: 'or' });
        items.push({ type: 'email', operator: 'or' });
        items.push({ type: 'city', operator: 'or' });
        items.push({ type: 'state', operator: 'or' });
        break;
    }

    obj = { name: leadWithPartialCondition, items: items };
    return obj;
  };

  const onSubmit = async (status: CampaignStatusEnum) => {
    const { hasError } = onSubmitErrorValidator();
    if (
      formState.type === CampaignTypeEnum.NORMAL &&
      formState.referralAgentCheck === ReferralAgentCheckTypeEnum.DISABLE &&
      !formState.leadDedupRule &&
      !formState.customerDedup &&
      !formState.distributionRule
    ) {
      PruToast({
        message: Translation('pulseleads.campaign.common.no.lead.distribution.error'),
      });
      return;
    }
    // const openLeadLimitValidator = onValidateOpenLeadLimit();
    if (!hasError && !duplicateIdErrorState) {
      if (formMode === CampaignFormMode.CREATE) {
        let body: CreateCampaignBody = {
          campaignId: formState.campaignId || '',
          name: formState.name || '',
          campaignType: formState.campaignType || 'Campaign',
          description: formState.description || '',
          regionalCampaignName: formState.regionalCampaignName,
          type: formState.type,
          sort: formState.sort,
          startDate: formState.startDate || null,
          endDate: formState.endDate || null,
          launchDate: formState.launchDate || undefined,
          emailMessage: formState.emailMessage
            ? {
                title: formState.emailMessage.title || '',
                body: formState.emailMessage.body || '',
              }
            : undefined,
          notificationMessage: formState.notificationMessage
            ? {
                title: formState.notificationMessage.title || '',
                body: formState.notificationMessage.body || '',
              }
            : undefined,
          mandatoryData: formState.mandatoryData,
          isNewLeadNotificationEnabled: formState.isNewLeadNotificationEnabled,
          callScripts: formState.callScripts,
          saleTipsInsight: formState.saleTipsInsight,
          faq: formState.faq || [],
          links: formState.links || [],
          priorities: formState.priorities || [],
          flyers: formState.flyers || [],
          //  TODO
          image: formState.image,
          maxLeadPerAgent: formState.maxLeadPerAgent ? Number(formState.maxLeadPerAgent) : undefined,
          marketingOwnerName: formState.marketingOwnerName || '',
          marketingOwnerEmail: formState.marketingOwnerEmail || '',
          status,
          source: formState.source || '',
          subSource: formState.subSource,
          acceptSLA: formState.acceptSLA,
          contactSLA: formState.contactSLA,
          meetSLA: formState.meetSLA,
          proposalSLA: formState.proposalSLA,
          applicationSLA: formState.applicationSLA,
          customerIncentive: formState.customerIncentive,
          agentIncentive: formState.agentIncentive,
          isSelfCreateLeadEnabled: formState.isSelfCreateLeadEnabled,
          maximumMarketingLeads: {
            ...formState.maximumMarketingLeads,
            limit: formState.maximumMarketingLeads.limit ? Number(formState.maximumMarketingLeads.limit) : undefined,
          },
          // maximumLeadSourceLeads: {
          //   ...formState.maximumLeadSourceLeads,
          //   limit: formState.maximumLeadSourceLeads.limit ? Number(formState.maximumLeadSourceLeads.limit) : undefined,
          // },
          isAutoAssignLeadIfAgentInactive: formState.isAutoAssignLeadIfAgentInactive,
          isLeadWithPartialInfo: formState.isLeadWithPartialInfo,
          leadWithPartialInfoWarning: formState.isLeadWithPartialInfo
            ? formState.leadWithPartialInfoWarning
            : undefined,
          leadWithPartialCondition: await getLeadWithPartialCondition(formState.leadWithPartialCondition?.name),
          distributionRule: formState.distributionRule,
          eligibilityRule: formState.eligibilityRule,
          leadDedupRule: formState.leadDedupRule,
          referralAgentCheck: formState.referralAgentCheck,
          customerDedupRule: formState.customerDedupRule,
          customerDedup: formState.customerDedup,
          exclusionRule: formState.exclusionRule,
          isEngagementRequired: formState.isEngagementRequired,
          isStaging: formState.isStaging,
          leadAction: formState.leadAction,
          prospectCategory: formState.prospectCategory,
          distributionChannel: formState.distributionChannel,
          targetProductType: formState.targetProductType,
          // feedbacks: formState.feedbacks,
          feedbackGroup: formState.feedbackGroup,
          roundRobinMode: formState.roundRobinMode,
          createdBy: user?.username || 'Default',
          updatedBy: user?.username || 'Default',
        };
        if (body.type !== CampaignTypeEnum.SERVICING) {
          body = {
            ...body,
            autoDropRule: {
              enabled: formState.enableAutoDropLead,
              type: formState.autoDropLeadType,
              day: Number(formState.autoDropLeadConfig?.[formState.autoDropLeadType]),
            },
          };
        }
        try {
          await createCampaign(body, dispatch);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: 'Campaign saved successfully',
              },
            ]),
          );
          history.push(campaignPath);
        } catch (err) {}
      } else if (campaignItem) {
        let body: UpdateCampaignBody = {
          campaignId: formState.campaignId || '',
          name: formState.name || '',
          campaignType: formState.campaignType || 'Campaign',
          description: formState.description || '',
          regionalCampaignName: formState.regionalCampaignName,
          type: formState.type,
          sort: formState.sort,
          startDate: formState.startDate || null,
          endDate: formState.endDate || null,
          launchDate: formState.launchDate || undefined,
          emailMessage: formState.emailMessage
            ? {
                title: formState.emailMessage.title || '',
                body: formState.emailMessage.body || '',
              }
            : undefined,
          notificationMessage: formState.notificationMessage
            ? {
                title: formState.notificationMessage.title || '',
                body: formState.notificationMessage.body || '',
              }
            : undefined,
          mandatoryData: formState.mandatoryData,
          isNewLeadNotificationEnabled: formState.isNewLeadNotificationEnabled,
          callScripts: formState.callScripts,
          saleTipsInsight: formState.saleTipsInsight,
          faq: formState.faq || [],
          links: formState.links || [],
          priorities: formState.priorities || [],
          flyers: formState.flyers || [],
          //  TODO
          image: formState.image,
          maxLeadPerAgent: formState.maxLeadPerAgent ? Number(formState.maxLeadPerAgent) : undefined,
          marketingOwnerName: formState.marketingOwnerName || '',
          marketingOwnerEmail: formState.marketingOwnerEmail || '',
          status,
          source: formState.source || '',
          subSource: formState.subSource,
          acceptSLA: formState.acceptSLA,
          contactSLA: formState.contactSLA,
          meetSLA: formState.meetSLA,
          proposalSLA: formState.proposalSLA,
          applicationSLA: formState.applicationSLA,
          customerIncentive: formState.customerIncentive,
          agentIncentive: formState.agentIncentive,
          isSelfCreateLeadEnabled: formState.isSelfCreateLeadEnabled,
          maximumMarketingLeads: {
            ...formState.maximumMarketingLeads,
            limit: formState.maximumMarketingLeads.limit ? Number(formState.maximumMarketingLeads.limit) : undefined,
          },
          // maximumLeadSourceLeads: {
          //   ...formState.maximumLeadSourceLeads,
          //   limit: formState.maximumLeadSourceLeads.limit ? Number(formState.maximumLeadSourceLeads.limit) : undefined,
          // },
          isAutoAssignLeadIfAgentInactive: formState.isAutoAssignLeadIfAgentInactive,
          isLeadWithPartialInfo: formState.isLeadWithPartialInfo,
          leadWithPartialInfoWarning: formState.isLeadWithPartialInfo
            ? formState.leadWithPartialInfoWarning
            : campaignItem.leadWithPartialInfoWarning,
          leadWithPartialCondition: await getLeadWithPartialCondition(formState.leadWithPartialCondition?.name),
          distributionRule: formState.distributionRule,
          eligibilityRule: formState.eligibilityRule,
          leadDedupRule: formState.leadDedupRule,
          referralAgentCheck: formState.referralAgentCheck,
          customerDedupRule: formState.customerDedupRule,
          customerDedup: formState.customerDedup,
          exclusionRule: formState.exclusionRule,
          isEngagementRequired: formState.isEngagementRequired,
          isStaging: formState.isStaging,
          leadAction: formState.leadAction,
          prospectCategory: formState.prospectCategory,
          distributionChannel: formState.distributionChannel,
          targetProductType: formState.targetProductType,
          // feedbacks: formState.feedbacks,
          feedbackGroup: formState.feedbackGroup,
          roundRobinMode: formState.roundRobinMode,
          updatedBy: user?.username || 'Default',
        };
        if (body.type !== CampaignTypeEnum.SERVICING) {
          body = {
            ...body,
            autoDropRule: {
              enabled: formState.enableAutoDropLead,
              type: formState.autoDropLeadType,
              day: Number(formState.autoDropLeadConfig?.[formState.autoDropLeadType]),
            },
          };
        }
        try {
          await modifyCampaign(campaignItem._id, body, dispatch);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: `Campaign updated successfully`,
              },
            ]),
          );
          history.push(campaignPath);
        } catch (err) {}
      }
    }
  };

  const campaignTypeDropDown = ['Servicing Campaign', 'Campaign'];

  return (
    <>
      {formDialogState.open && (
        <FormDialog
          disabled={!enableDraftUpdate && !enableActiveUpdate}
          title={formDialogState.title}
          dataSource={formDialogState.dataSource}
          fieldDef={formDialogState.fieldDef}
          onFinish={formDialogState.onFinish}
          onClose={formDialogState.onClose}
          module="pulseLeads"
        />
      )}
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <div className={classes.rowContainer}>
            <div className={commonClasses.header}>
              {Translation(`pulseleadsCampaign.form.title.${formMode === CampaignFormMode.CREATE ? 'create' : 'edit'}`)}
            </div>
          </div>
          <Button variant="contained" color="inherit" onClick={() => history.push(campaignPath)}>
            {Translation('app.button.back')}
          </Button>
        </div>

        {/* General */}
        <div className={classes.sectionMargin}>
          <div className={classes.divideMargin}>
            <span className={classes.subHeader}>{Translation('pulseleads.campaign.form.section.general')}</span>
          </div>

          <div style={{ marginBottom: 5 }} className={classes.rowContainer}>
            <div className={classes.fieldContainer} />
            {formState.image && formState.image.url && (
              <div className={classes.imageContainer}>
                <img style={{ width: '100%', height: '100%' }} src={formState.image.url} alt="App Icon" />
              </div>
            )}
          </div>
          <div style={{ marginBottom: 15 }} className={classes.rowContainer}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>{Translation('pulseleads.campaign.common.campaignId')}:</span>
            </div>
            <div style={{ flexGrow: 1 }}>{formState.campaignId ? formState.campaignId : '--'}</div>
          </div>
          <div style={{ marginBottom: 10 }} className={classes.textAreaRowContainer}>
            <div style={{ paddingTop: 3 }} className={classes.fieldContainer}>
              <span className={classes.field}>
                {Translation('pulseleads.campaign.common.image')}
                <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <input
                id="upload-campaign-image"
                hidden
                type="file"
                accept="image/*"
                onClick={(e) => {
                  const element = e.target as HTMLInputElement;
                  element.value = '';
                }}
                onChange={handleFile}
              />
              <div className={classes.rowContainer}>
                <Button
                  disabled={!enableDraftUpdate && !enableActiveUpdate}
                  variant="contained"
                  color="secondary"
                  onClick={() => document.getElementById('upload-campaign-image')!.click()}
                >
                  {Translation('app.button.chooseFile')}
                </Button>
                {formState.image && formState.image.filename && (
                  <Link
                    style={{ marginLeft: 14 }}
                    target="_blank"
                    rel="noopener"
                    color="secondary"
                    href={formState.image.url}
                  >
                    {formState.image.filename}
                  </Link>
                )}
                {errorState.mandatory.image && (
                  <div style={{ marginLeft: 10 }} className={classes.errorText}>
                    {MANDATORY_FIELD_ERROR_TEXT}
                  </div>
                )}
              </div>
              {!!imageUploadProgress && (
                <LinearProgress
                  style={{ marginTop: 10 }}
                  variant="determinate"
                  color="secondary"
                  value={imageUploadProgress}
                />
              )}
            </div>
          </div>

          {fieldConfig.enableCampaignType && (
            <div className={classes.rowContainer}>
              <div className={classes.fieldContainer}>
                <span className={classes.field}>
                  {Translation('pulseleads.campaign.common.campaignType')}
                  {fieldConfig.mandatoryCampaignType && <span className={classes.mandatory}>*</span>} :
                </span>
              </div>
              <FormControl
                error={errorState.immediate.campaignTypeMustBeServicingCampaign || errorState.mandatory.campaignType}
                margin="dense"
                variant="outlined"
              >
                <Select
                  disabled={!enableDraftUpdate}
                  style={{ minWidth: 350 }}
                  // MenuProps={{
                  //   anchorOrigin: {
                  //     vertical: 'bottom',
                  //     horizontal: 'left',
                  //   }
                  // }}
                  value={formState.campaignType || 'Campaign'}
                  onChange={(e) => {
                    onDismissErrorHandler('campaignType', e.target.value);
                    formDispatch({
                      type: 'MODIFY_FIELD',
                      payload: { field: 'campaignType', value: e.target.value || 'Campaign' },
                    });
                  }}
                >
                  {campaignTypeDropDown.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
                {errorState.immediate.campaignTypeMustBeServicingCampaign ? (
                  <FormHelperText>Campaign Type must be 'Servicing Campaign' only</FormHelperText>
                ) : (
                  errorState.mandatory.campaignType && <FormHelperText>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>
                )}
              </FormControl>
            </div>
          )}
          <div className={classes.rowContainer}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>
                {Translation('pulseleads.campaign.common.name')}
                <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <TextField
                disabled={!enableDraftUpdate}
                style={{ minWidth: 350 }}
                error={errorState.mandatory.name}
                margin="dense"
                variant="outlined"
                helperText={errorState.mandatory.name && MANDATORY_FIELD_ERROR_TEXT}
                value={formState.name}
                onChange={(e) => {
                  onDismissErrorHandler('name', e.target.value);
                  formDispatch({
                    type: 'MODIFY_FIELD',
                    payload: { field: 'name', value: e.target.value },
                  });
                }}
              />
            </div>
          </div>

          <div className={classes.rowContainer}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>{Translation('pulseleads.campaign.common.regionalCampaignName')} :</span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <TextField
                disabled={!enableDraftUpdate && !enableActiveUpdate}
                style={{ minWidth: 350 }}
                margin="dense"
                variant="outlined"
                value={formState.regionalCampaignName}
                onChange={(e) => {
                  formDispatch({
                    type: 'MODIFY_FIELD',
                    payload: {
                      field: 'regionalCampaignName',
                      value: e.target.value,
                    },
                  });
                }}
              />
            </div>
          </div>

          <div className={classes.rowContainer}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>
                {Translation('pulseleads.campaign.form.duration')}
                <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <PruDatePicker
              disabled={!enableDraftUpdate}
              slotProps={{
                textField: {
                  error: errorState.immediate.endDateBeforeStartDate || errorState.mandatory.startDate,
                  helperText: errorState.mandatory.startDate && MANDATORY_FIELD_ERROR_TEXT,
                },
              }}
              format="DD/MM/YYYY HH:mm"
              value={formState.startDate}
              onChange={(date) => {
                if (date) {
                  date.setHours(0, 0, 0, 0);
                  onDismissErrorHandler('startDate', date);
                  formDispatch({
                    type: 'MODIFY_FIELD',
                    payload: { field: 'startDate', value: date },
                  });
                }
              }}
            />
            <div className={classes.dateDivider}>-</div>
            <PruDatePicker
              disabled={!enableDraftUpdate}
              slotProps={{
                textField: {
                  error: errorState.immediate.endDateBeforeStartDate || errorState.mandatory.endDate,
                  helperText: errorState.immediate.endDateBeforeStartDate
                    ? DATE_ERROR_TEXT
                    : errorState.mandatory.endDate && MANDATORY_FIELD_ERROR_TEXT,
                },
              }}
              format="DD/MM/YYYY HH:mm"
              value={formState.endDate}
              onChange={(date) => {
                if (date) {
                  date.setHours(23, 59, 0, 0);
                  onDismissErrorHandler('endDate', date);
                  formDispatch({
                    type: 'MODIFY_FIELD',
                    payload: { field: 'endDate', value: date },
                  });
                }
              }}
            />
          </div>

          <div className={classes.rowContainer}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>
                {Translation('pulseleads.campaign.common.source')}
                <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <FormControl error={errorState.mandatory.source} margin="dense" variant="outlined">
              <Select
                disabled={!enableDraftUpdate}
                style={{ minWidth: 350 }}
                value={formState.source || ''}
                onChange={(e) => {
                  onDismissErrorHandler('source', e.target.value);
                  formDispatch({
                    type: 'MODIFY_FIELD',
                    payload: { field: 'source', value: e.target.value },
                  });
                  formDispatch({
                    type: 'MODIFY_FIELD',
                    payload: { field: 'subSource', value: undefined },
                  });
                }}
              >
                {campaignDropdownList &&
                  campaignDropdownList[CampaignDropdownType.LEAD_SOURCE] &&
                  campaignDropdownList[CampaignDropdownType.LEAD_SOURCE].map((dropdownItem) => (
                    <MenuItem key={dropdownItem.key} value={dropdownItem.key}>
                      {dropdownItem.value}
                    </MenuItem>
                  ))}
              </Select>
              {errorState.mandatory.source && <FormHelperText>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>}
            </FormControl>
          </div>

          {fieldConfig.enableSubSource && (
            <div className={classes.rowContainer}>
              <div className={classes.fieldContainer}>
                <span className={classes.field}>
                  {Translation('pulseleads.campaign.common.subSource')}
                  {fieldConfig.mandatorySubSource && <span className={classes.mandatory}>*</span>} :
                </span>
              </div>
              <FormControl
                error={
                  fieldConfig.mandatorySubSource &&
                  (errorState.immediate.emptySubSourceList ||
                    errorState.mandatory.subSource ||
                    errorState.immediate.subSourceMustBeServicingCampaignOnly)
                }
                margin="dense"
                variant="outlined"
              >
                <Select
                  disabled={!enableDraftUpdate}
                  style={{ minWidth: 350 }}
                  multiple
                  value={formState.subSource || []}
                  onChange={(e) => {
                    if (fieldConfig.mandatorySubSource) {
                      onDismissErrorHandler('subSource', e.target.value);
                    }
                    formDispatch({
                      type: 'MODIFY_FIELD',
                      payload: {
                        field: 'subSource',
                        value: e.target.value || [],
                      },
                    });
                  }}
                >
                  {formState.source &&
                    subSourceDropdownList &&
                    subSourceDropdownList[CampaignDropdownType.LEAD_SOURCE] &&
                    subSourceDropdownList[CampaignDropdownType.LEAD_SOURCE].map((dropdownItem) => (
                      <MenuItem key={dropdownItem.key} value={dropdownItem.key}>
                        {dropdownItem.value}
                      </MenuItem>
                    ))}
                </Select>
                {fieldConfig.mandatorySubSource &&
                  (errorState.immediate.emptySubSourceList || errorState.mandatory.subSource) && (
                    <FormHelperText>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>
                  )}
                {errorState.immediate.subSourceMustBeServicingCampaignOnly && (
                  <FormHelperText>Sub source must be "Servicing Communication" only</FormHelperText>
                )}
              </FormControl>
            </div>
          )}

          {fieldConfig.enableDistributionChannel && (
            <div className={classes.rowContainer}>
              <div className={classes.fieldContainer}>
                <span className={classes.field}>
                  {Translation('pulseleads.campaign.common.distributionChannel')}
                  {fieldConfig.mandatoryDistributionChannel && <span className={classes.mandatory}>*</span>} :
                </span>
              </div>
              <FormControl error={errorState.mandatory.distributionChannel} margin="dense" variant="outlined">
                <Select
                  disabled={!enableDraftUpdate}
                  style={{ minWidth: 350 }}
                  value={formState.distributionChannel || ''}
                  onChange={(e) => {
                    onDismissErrorHandler('distributionChannel', e.target.value);
                    formDispatch({
                      type: 'MODIFY_FIELD',
                      payload: {
                        field: 'distributionChannel',
                        value: e.target.value,
                      },
                    });
                  }}
                >
                  {Object.values(DistributionChannelEnum).map((value) => (
                    <MenuItem key={value} value={value}>
                      {Translation(`pulseleads.campaign.common.distributionChannel.${value}`)}
                    </MenuItem>
                  ))}
                </Select>
                {errorState.mandatory.distributionChannel && (
                  <FormHelperText>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>
                )}
              </FormControl>
            </div>
          )}

          {formState.type === CampaignTypeEnum.NORMAL && (
            <div className={classes.rowContainer}>
              <div className={classes.fieldContainer}>
                <span className={classes.field}>{Translation('pulseleads.campaign.common.mandatoryData')} :</span>
              </div>
              <div style={{ flexGrow: 1 }}>
                <FormControl margin="dense" variant="outlined">
                  <Select
                    multiple
                    style={{ minWidth: 350 }}
                    value={formState.mandatoryData}
                    renderValue={(selected) => (
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {((selected || []) as string[]).map(
                          (value) =>
                            value && (
                              <Chip
                                key={value}
                                label={Translation(`pulseleads.campaign.mandatoryData.option.${value}`)}
                                style={{ margin: 2 }}
                              />
                            ),
                        )}
                      </div>
                    )}
                    onChange={(e) => {
                      formDispatch({
                        type: 'MODIFY_FIELD',
                        payload: {
                          field: 'mandatoryData',
                          value: e.target.value || [],
                        },
                      });
                    }}
                  >
                    {map(CampaignMandatoryDataEnum, (option: string) => (
                      <MenuItem key={option} value={option}>
                        {Translation(`pulseleads.campaign.mandatoryData.option.${option}`)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          )}

          <div className={classes.rowContainer}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>
                {Translation('pulseleads.campaign.common.marketingOwnerName')}
                <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <TextField
                disabled={!enableDraftUpdate && !enableActiveUpdate}
                style={{ width: 250 }}
                error={errorState.mandatory.marketingOwnerName}
                margin="dense"
                variant="outlined"
                helperText={errorState.mandatory.marketingOwnerName && MANDATORY_FIELD_ERROR_TEXT}
                value={formState.marketingOwnerName}
                onChange={(e) => {
                  onDismissErrorHandler('marketingOwnerName', e.target.value);
                  formDispatch({
                    type: 'MODIFY_FIELD',
                    payload: {
                      field: 'marketingOwnerName',
                      value: e.target.value,
                    },
                  });
                }}
              />
            </div>
          </div>

          <div className={classes.rowContainer}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>
                {Translation('pulseleads.campaign.common.marketingOwnerEmail')}
                <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <TextField
                disabled={!enableDraftUpdate && !enableActiveUpdate}
                style={{ width: 250 }}
                error={errorState.immediate.marketingOwnerEmailFormat || errorState.mandatory.marketingOwnerEmail}
                margin="dense"
                variant="outlined"
                helperText={
                  errorState.immediate.marketingOwnerEmailFormat
                    ? EMAIL_FORMAT_ERROR_TEXT
                    : errorState.mandatory.marketingOwnerEmail && MANDATORY_FIELD_ERROR_TEXT
                }
                value={formState.marketingOwnerEmail}
                onChange={(e) => {
                  onDismissErrorHandler('marketingOwnerEmail', e.target.value);
                  formDispatch({
                    type: 'MODIFY_FIELD',
                    payload: {
                      field: 'marketingOwnerEmail',
                      value: e.target.value,
                    },
                  });
                }}
              />
            </div>
          </div>

          <div className={classes.textAreaRowContainer}>
            <div className={classes.textAreaFieldContainer}>
              <span className={classes.field}>
                {Translation('pulseleads.campaign.common.description')}
                <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <TextField
                disabled={!enableDraftUpdate && !enableActiveUpdate}
                fullWidth
                error={errorState.mandatory.description}
                multiline
                margin="dense"
                variant="outlined"
                helperText={errorState.mandatory.description && MANDATORY_FIELD_ERROR_TEXT}
                value={formState.description}
                InputProps={{
                  classes: {
                    input: classes.textArea,
                  },
                }}
                onChange={(e) => {
                  onDismissErrorHandler('description', e.target.value);
                  formDispatch({
                    type: 'MODIFY_FIELD',
                    payload: { field: 'description', value: e.target.value },
                  });
                }}
              />
            </div>
          </div>

          {formState.type === CampaignTypeEnum.NORMAL && (
            <div className={classes.rowContainer}>
              <div className={classes.fieldContainer}>
                <span className={classes.field}>{Translation('pulseleads.campaign.common.isStaging')} :</span>
              </div>
              <FormControl margin="dense" variant="outlined">
                <Select
                  //PACS Customization
                  disabled={true}
                  defaultValue={Translation('pulseleads.campaign.common.leadAction.straight')}
                  // disabled={!enableDraftUpdate}
                  style={{ minWidth: 350 }}
                  value={formState.leadAction}
                  onChange={(e) => {
                    formDispatch({
                      type: 'MODIFY_FIELD',
                      payload: { field: 'leadAction', value: e.target.value },
                    });
                  }}
                >
                  {salesCommonfieldConfig.leadAction?.map((value: string) => (
                    <MenuItem key={value} value={value}>
                      {Translation(`pulseleads.campaign.common.leadAction.${value}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}

          <div className={classes.textAreaRowContainer}>
            <div className={classes.checkboxFieldContainer}>
              <span className={classes.field}>{Translation('pulseleads.campaign.form.type')} :</span>
            </div>
            <FormControlLabel
              style={{ margin: '2px 12px 2px 0' }}
              control={
                <Checkbox
                  disabled={!enableDraftUpdate}
                  icon={<CircleUnchecked />}
                  checkedIcon={<CircleCheckedFilled />}
                  checked={formState.type === CampaignTypeEnum.NORMAL}
                  onChange={(e) => {
                    if (e.target.checked) {
                      formDispatch({
                        type: 'MODIFY_FIELD',
                        payload: {
                          field: 'type',
                          value: CampaignTypeEnum.NORMAL,
                        },
                      });
                    }
                  }}
                />
              }
              label={Translation('pulseleads.campaign.type.option.normal')}
              labelPlacement="end"
            />
            <FormControlLabel
              style={{ margin: '2px 12px 2px 0' }}
              control={
                <Checkbox
                  disabled={!enableDraftUpdate}
                  icon={<CircleUnchecked />}
                  checkedIcon={<CircleCheckedFilled />}
                  checked={formState.type === CampaignTypeEnum.MASS}
                  onChange={(e) => {
                    if (e.target.checked) {
                      formDispatch({
                        type: 'MODIFY_FIELD',
                        payload: {
                          field: 'type',
                          value: CampaignTypeEnum.MASS,
                        },
                      });
                    }
                  }}
                />
              }
              label={Translation('pulseleads.campaign.type.option.mass')}
              labelPlacement="end"
            />
            {fieldConfig.enableServicingCampaign && (
              <FormControlLabel
                style={{ margin: '2px 0' }}
                control={
                  <Checkbox
                    disabled={!enableDraftUpdate}
                    icon={<CircleUnchecked />}
                    checkedIcon={<CircleCheckedFilled />}
                    checked={formState.type === CampaignTypeEnum.SERVICING}
                    onChange={(e) => {
                      if (e.target.checked) {
                        formDispatch({
                          type: 'MODIFY_FIELD',
                          payload: {
                            field: 'type',
                            value: CampaignTypeEnum.SERVICING,
                          },
                        });
                      }
                    }}
                  />
                }
                label={Translation('pulseleads.campaign.type.option.servicing')}
                labelPlacement="end"
              />
            )}
          </div>

          {formState.type === CampaignTypeEnum.SERVICING && (
            // <div className={classes.rowContainer}>
            //   <div className={classes.fieldContainer}>
            //     <span className={classes.field}>
            //       {Translation("pulseleads.campaign.common.feedbacks")}
            //       <span className={classes.mandatory}>*</span> :
            //     </span>
            //   </div>
            //   <FormControl
            //     error={
            //       errorState.immediate.emptyFeedbackList ||
            //       errorState.mandatory.feedbacks
            //     }
            //     margin="dense"
            //     variant="outlined"
            //   >
            //     <Select
            //       disabled={!enableDraftUpdate}
            //       style={{ minWidth: 350 }}
            //       multiple
            //       value={formState.feedbacks || []}
            //       onChange={(e) => {
            //         onDismissErrorHandler("feedbacks", e.target.value);
            //         formDispatch({
            //           type: "MODIFY_FIELD",
            //           payload: {
            //             field: "feedbacks",
            //             value: e.target.value || [],
            //           },
            //         });
            //       }}
            //     >
            //       {campaignDropdownList &&
            //         campaignDropdownList[CampaignDropdownType.FEEDBACK] &&
            //         campaignDropdownList[CampaignDropdownType.FEEDBACK].map(
            //           (dropdownItem) => (
            //             <MenuItem
            //               key={dropdownItem.key}
            //               value={dropdownItem.key}
            //             >
            //               {dropdownItem.value}
            //             </MenuItem>
            //           )
            //         )}
            //     </Select>
            //     {(errorState.immediate.emptyFeedbackList ||
            //       errorState.mandatory.feedbacks) && (
            //       <FormHelperText>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>
            //     )}
            //   </FormControl>
            // </div>
            <div className={classes.rowContainer}>
              <div className={classes.fieldContainer}>
                <span className={classes.field}>
                  {Translation('pulseleads.campaign.common.feedbacks')}
                  <span className={classes.mandatory}>*</span> :
                </span>
              </div>
              <FormControl error={errorState.mandatory.feedbackGroup} margin="dense" variant="outlined">
                <Select
                  disabled={!enableDraftUpdate}
                  style={{ minWidth: 350 }}
                  value={formState.feedbackGroup || ''}
                  onChange={(e) => {
                    onDismissErrorHandler('feedbackGroup', e.target.value);
                    formDispatch({
                      type: 'MODIFY_FIELD',
                      payload: { field: 'feedbackGroup', value: e.target.value },
                    });
                  }}
                >
                  {campaignDropdownList &&
                    campaignDropdownList[CampaignDropdownType.FEEDBACK_GROUP] &&
                    campaignDropdownList[CampaignDropdownType.FEEDBACK_GROUP].map((dropdownItem) => (
                      <MenuItem key={dropdownItem.key} value={dropdownItem.key}>
                        {dropdownItem.value}
                      </MenuItem>
                    ))}
                </Select>
                {errorState.mandatory.feedbackGroup && <FormHelperText>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>}
              </FormControl>
            </div>
          )}

          <div className={classes.rowContainer}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>{Translation('pulseleads.campaign.common.targetProductType')} :</span>
            </div>
            <FormControl margin="dense" variant="outlined">
              <Select
                disabled={!enableDraftUpdate && !enableActiveUpdate}
                style={{ minWidth: 350 }}
                multiple
                value={formState.targetProductType || []}
                onChange={(e) => {
                  formDispatch({
                    type: 'MODIFY_FIELD',
                    payload: {
                      field: 'targetProductType',
                      value: e.target.value || [],
                    },
                  });
                }}
              >
                {Object.values(TargetProductTypeEnum).map((value) => (
                  <MenuItem key={value} value={value}>
                    {Translation(`pulseleads.campaign.common.targetProductType.${value}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          {(formState.type === CampaignTypeEnum.MASS || formState.type === CampaignTypeEnum.SERVICING) && (
            <>
              <div className={classes.rowContainer}>
                <div className={classes.fieldContainer}>
                  <span className={classes.field}>
                    {Translation('pulseleads.campaign.common.launchDate')}
                    <span className={classes.mandatory}>*</span> :
                  </span>
                </div>
                <PruDateTimePicker
                  disabled={!enableDraftUpdate}
                  slotProps={{
                    textField: {
                      error:
                        errorState.immediate.launchDateNotBetweenStartDateEndDate || errorState.mandatory.launchDate,
                      helperText: errorState.immediate.launchDateNotBetweenStartDateEndDate
                        ? LAUNCH_DATE_ERROR_TEXT
                        : errorState.mandatory.launchDate && MANDATORY_FIELD_ERROR_TEXT,
                    },
                  }}
                  ampm={false}
                  format="DD/MM/YYYY HH:mm"
                  value={formState.launchDate}
                  onChange={(date) => {
                    onDismissErrorHandler('launchDate', date);
                    formDispatch({
                      type: 'MODIFY_FIELD',
                      payload: { field: 'launchDate', value: date },
                    });
                  }}
                />
              </div>
              <div className={classes.textAreaRowContainer}>
                <div className={classes.checkboxFieldContainer}>
                  <span className={classes.field}>{Translation('pulseleads.campaign.form.oneTimeMessage')} :</span>
                </div>
                <FormControlLabel
                  style={{ margin: '2px 12px 2px 0' }}
                  control={
                    <Checkbox
                      disabled={!enableDraftUpdate}
                      icon={<CircleUnchecked />}
                      checkedIcon={<CircleCheckedFilled />}
                      checked={!!formState.emailMessage}
                      onChange={(e) => {
                        formDispatch({
                          type: 'TOGGLE_MESSAGE',
                          payload: { field: 'emailMessage' },
                        });
                      }}
                    />
                  }
                  label={Translation('pulseleads.campaign.form.enableEmailMessage')}
                  labelPlacement="end"
                />
                <FormControlLabel
                  style={{ margin: '2px 0' }}
                  control={
                    <Checkbox
                      disabled={!enableDraftUpdate}
                      icon={<CircleUnchecked />}
                      checkedIcon={<CircleCheckedFilled />}
                      checked={!!formState.notificationMessage}
                      onChange={(e) => {
                        formDispatch({
                          type: 'TOGGLE_MESSAGE',
                          payload: { field: 'notificationMessage' },
                        });
                      }}
                    />
                  }
                  label={Translation('pulseleads.campaign.form.emailNotificationMessage')}
                  labelPlacement="end"
                />
              </div>

              {formState.emailMessage && (
                <>
                  <div className={classes.rowContainer}>
                    <div className={classes.fieldContainer}>
                      <span className={classes.field}>
                        {Translation('pulseleads.campaign.form.emailTitle')}
                        <span className={classes.mandatory}>*</span> :
                      </span>
                    </div>
                    <div style={{ flexGrow: 1 }}>
                      <TextField
                        disabled={!enableDraftUpdate}
                        style={{ minWidth: 350 }}
                        error={errorState.mandatory.emailTitle}
                        margin="dense"
                        variant="outlined"
                        helperText={errorState.mandatory.emailTitle && MANDATORY_FIELD_ERROR_TEXT}
                        value={formState.emailMessage.title}
                        onChange={(e) => {
                          onDismissErrorHandler('emailTitle', e.target.value);
                          formDispatch({
                            type: 'MODIFY_MESSAGE_FIELD',
                            payload: {
                              field: 'emailMessage',
                              messageField: 'title',
                              value: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className={classes.textAreaRowContainer}>
                    <div className={classes.textAreaFieldContainer}>
                      <span className={classes.field}>
                        {Translation('pulseleads.campaign.form.emailBody')}
                        <span className={classes.mandatory}>*</span> :
                      </span>
                    </div>
                    <div style={{ flexGrow: 1 }}>
                      <TextField
                        disabled={!enableDraftUpdate}
                        fullWidth
                        error={errorState.mandatory.emailBody}
                        multiline
                        margin="dense"
                        variant="outlined"
                        helperText={errorState.mandatory.emailBody && MANDATORY_FIELD_ERROR_TEXT}
                        value={formState.emailMessage.body}
                        InputProps={{
                          classes: {
                            input: classes.textArea,
                          },
                        }}
                        onChange={(e) => {
                          onDismissErrorHandler('emailBody', e.target.value);
                          formDispatch({
                            type: 'MODIFY_MESSAGE_FIELD',
                            payload: {
                              field: 'emailMessage',
                              messageField: 'body',
                              value: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                </>
              )}

              {formState.notificationMessage && (
                <>
                  <div className={classes.rowContainer}>
                    <div className={classes.fieldContainer}>
                      <span className={classes.field}>
                        {Translation('pulseleads.campaign.form.notificationTitle')}
                        <span className={classes.mandatory}>*</span> :
                      </span>
                    </div>
                    <div style={{ flexGrow: 1 }}>
                      <TextField
                        disabled={!enableDraftUpdate}
                        style={{ minWidth: 350 }}
                        error={errorState.mandatory.notificationTitle}
                        margin="dense"
                        variant="outlined"
                        helperText={errorState.mandatory.notificationTitle && MANDATORY_FIELD_ERROR_TEXT}
                        value={formState.notificationMessage.title}
                        onChange={(e) => {
                          onDismissErrorHandler('notificationTitle', e.target.value);
                          formDispatch({
                            type: 'MODIFY_MESSAGE_FIELD',
                            payload: {
                              field: 'notificationMessage',
                              messageField: 'title',
                              value: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className={classes.textAreaRowContainer}>
                    <div className={classes.textAreaFieldContainer}>
                      <span className={classes.field}>
                        {Translation('pulseleads.campaign.form.notificationBody')}
                        <span className={classes.mandatory}>*</span> :
                      </span>
                    </div>
                    <div style={{ flexGrow: 1 }}>
                      <TextField
                        disabled={!enableDraftUpdate}
                        fullWidth
                        error={errorState.mandatory.notificationBody}
                        multiline
                        margin="dense"
                        variant="outlined"
                        helperText={errorState.mandatory.notificationBody && MANDATORY_FIELD_ERROR_TEXT}
                        value={formState.notificationMessage.body}
                        InputProps={{
                          classes: {
                            input: classes.textArea,
                          },
                        }}
                        onChange={(e) => {
                          onDismissErrorHandler('notificationBody', e.target.value);
                          formDispatch({
                            type: 'MODIFY_MESSAGE_FIELD',
                            payload: {
                              field: 'notificationMessage',
                              messageField: 'body',
                              value: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                </>
              )}
            </>
          )}

          {formState.type === CampaignTypeEnum.NORMAL && (
            <div className={classes.textAreaRowContainer}>
              <div className={classes.checkboxFieldContainer}>
                <span className={classes.field}>
                  {Translation('pulseleads.campaign.common.considerNoOfOpenLead')} :
                </span>
              </div>
              <FormControlLabel
                style={{ margin: '2px 12px 2px 0' }}
                control={
                  <Checkbox
                    disabled={!enableDraftUpdate}
                    icon={<CircleUnchecked />}
                    checkedIcon={<CircleCheckedFilled />}
                    checked={formState.roundRobinMode === RoundRobinModeEnum.ROUND_ROBIN}
                    onChange={(e) => {
                      if (e.target.checked) {
                        formDispatch({
                          type: 'MODIFY_FIELD',
                          payload: { field: 'roundRobinMode', value: RoundRobinModeEnum.ROUND_ROBIN },
                        });
                      }
                    }}
                  />
                }
                label={
                  <>
                    <span style={{ marginRight: 10 }}>{Translation('app.checkbox.yes')}</span>
                    <Tooltip
                      arrow
                      title={
                        <span style={{ fontSize: 10 }}>
                          {intl.formatMessage(
                            { id: 'pulseleads.campaign.common.considerNoOfOpenLeadYesTooltip' },
                            { br: <br /> },
                          )}
                        </span>
                      }
                    >
                      <Info style={{ color: 'grey' }} />
                    </Tooltip>
                  </>
                }
                labelPlacement="end"
              />
              <FormControlLabel
                style={{ margin: '2px 0' }}
                control={
                  <Checkbox
                    disabled={!enableDraftUpdate}
                    icon={<CircleUnchecked />}
                    checkedIcon={<CircleCheckedFilled />}
                    checked={formState.roundRobinMode === RoundRobinModeEnum.RANDOM}
                    onChange={(e) => {
                      if (e.target.checked) {
                        formDispatch({
                          type: 'MODIFY_FIELD',
                          payload: { field: 'roundRobinMode', value: RoundRobinModeEnum.RANDOM },
                        });
                      }
                    }}
                  />
                }
                label={
                  <>
                    <span style={{ marginRight: 10 }}>{Translation('app.checkbox.no')}</span>
                    <Tooltip
                      arrow
                      title={
                        <span style={{ fontSize: 10 }}>
                          {Translation('pulseleads.campaign.common.considerNoOfOpenLeadNoTooltip')}
                        </span>
                      }
                    >
                      <Info style={{ color: 'grey' }} />
                    </Tooltip>
                  </>
                }
                labelPlacement="end"
              />
            </div>
          )}
        </div>

        {/* Lead Information */}
        <div className={classes.sectionMargin}>
          <div className={classes.divideMargin}>
            <span className={classes.subHeader}>{Translation('pulseleads.campaign.form.section.leadInfo')}</span>
          </div>

          <div className={classes.textAreaRowContainer}>
            <div className={classes.checkboxFieldContainer}>
              <span className={classes.field}>
                {Translation('pulseleads.campaign.common.isNewLeadNotificationEnabled')} :
              </span>
            </div>
            <FormControlLabel
              style={{ margin: '2px 12px 2px 0' }}
              control={
                <Checkbox
                  disabled={!enableDraftUpdate && !enableActiveUpdate}
                  icon={<CircleUnchecked />}
                  checkedIcon={<CircleCheckedFilled />}
                  checked={formState.isNewLeadNotificationEnabled === true}
                  onChange={(e) => {
                    if (e.target.checked) {
                      formDispatch({
                        type: 'MODIFY_FIELD',
                        payload: {
                          field: 'isNewLeadNotificationEnabled',
                          value: true,
                        },
                      });
                    }
                  }}
                />
              }
              label={Translation('app.checkbox.yes')}
              labelPlacement="end"
            />
            <FormControlLabel
              style={{ margin: '2px 0' }}
              control={
                <Checkbox
                  disabled={!enableDraftUpdate && !enableActiveUpdate}
                  icon={<CircleUnchecked />}
                  checkedIcon={<CircleCheckedFilled />}
                  checked={formState.isNewLeadNotificationEnabled === false}
                  onChange={(e) => {
                    if (e.target.checked) {
                      formDispatch({
                        type: 'MODIFY_FIELD',
                        payload: {
                          field: 'isNewLeadNotificationEnabled',
                          value: false,
                        },
                      });
                    }
                  }}
                />
              }
              label={Translation('app.checkbox.no')}
              labelPlacement="end"
            />
          </div>

          {fieldConfig.enableEngagementRequired && (
            <div className={classes.textAreaRowContainer}>
              <div className={classes.checkboxFieldContainer}>
                <span className={classes.field}>
                  {Translation('pulseleads.campaign.common.isEngagementRequired')} :
                </span>
              </div>
              <FormControlLabel
                style={{ margin: '2px 12px 2px 0' }}
                control={
                  <Checkbox
                    disabled={!enableDraftUpdate && !enableActiveUpdate}
                    icon={<CircleUnchecked />}
                    checkedIcon={<CircleCheckedFilled />}
                    checked={formState.isEngagementRequired === true}
                    onChange={(e) => {
                      if (e.target.checked) {
                        formDispatch({
                          type: 'MODIFY_FIELD',
                          payload: { field: 'isEngagementRequired', value: true },
                        });
                      }
                    }}
                  />
                }
                label={Translation('app.checkbox.yes')}
                labelPlacement="end"
              />
              <FormControlLabel
                style={{ margin: '2px 0' }}
                control={
                  <Checkbox
                    disabled={!enableDraftUpdate && !enableActiveUpdate}
                    icon={<CircleUnchecked />}
                    checkedIcon={<CircleCheckedFilled />}
                    checked={formState.isEngagementRequired === false}
                    onChange={(e) => {
                      if (e.target.checked) {
                        formDispatch({
                          type: 'MODIFY_FIELD',
                          payload: {
                            field: 'isEngagementRequired',
                            value: false,
                          },
                        });
                      }
                    }}
                  />
                }
                label={Translation('app.checkbox.no')}
                labelPlacement="end"
              />
            </div>
          )}

          <div className={classes.rowContainer}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>{Translation('pulseleads.campaign.common.maxLeadPerAgent')} :</span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <TextField
                disabled={!enableDraftUpdate && !enableActiveUpdate}
                style={{ width: 250 }}
                margin="dense"
                variant="outlined"
                value={formState.maxLeadPerAgent}
                onChange={(e) => {
                  let onlyNums = e.target.value.replace(/[^0-9]/g, '');
                  formDispatch({
                    type: 'MODIFY_FIELD',
                    payload: { field: 'maxLeadPerAgent', value: onlyNums },
                  });
                }}
              />
            </div>
          </div>

          {formState.type === CampaignTypeEnum.NORMAL && (
            <>
              <div className={classes.rowContainer}>
                <div className={classes.fieldContainer}>
                  <span className={classes.field}>
                    {Translation('pulseleads.campaign.common.acceptSLA')}
                    <span className={classes.mandatory}>*</span> :
                  </span>
                </div>
                <FormControl error={errorState.mandatory.acceptSLA} margin="dense" variant="outlined">
                  <Select
                    disabled={!enableDraftUpdate}
                    style={{ minWidth: 350 }}
                    value={formState.acceptSLA || ''}
                    onChange={(e) => {
                      onDismissErrorHandler('acceptSLA', e.target.value);
                      formDispatch({
                        type: 'MODIFY_FIELD',
                        payload: { field: 'acceptSLA', value: e.target.value },
                      });
                    }}
                  >
                    {campaignDropdownList &&
                      campaignDropdownList[CampaignDropdownType.ACCEPT_SLA] &&
                      campaignDropdownList[CampaignDropdownType.ACCEPT_SLA].map((dropdownItem) => (
                        <MenuItem key={dropdownItem.key} value={dropdownItem.key}>
                          {dropdownItem.value}
                        </MenuItem>
                      ))}
                  </Select>
                  {errorState.mandatory.acceptSLA && <FormHelperText>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>}
                </FormControl>
              </div>

              {formState.isEngagementRequired === false && (
                <div className={classes.rowContainer}>
                  <div className={classes.fieldContainer}>
                    <span className={classes.field}>{Translation('pulseleads.campaign.common.contactSLA')} :</span>
                  </div>
                  <FormControl error={errorState.mandatory.contactSLA} margin="dense" variant="outlined">
                    <Select
                      disabled={formState.isEngagementRequired || !enableDraftUpdate}
                      style={{ minWidth: 350 }}
                      value={formState.contactSLA || ''}
                      onChange={(e) => {
                        onDismissErrorHandler('contactSLA', e.target.value);
                        formDispatch({
                          type: 'MODIFY_FIELD',
                          payload: {
                            field: 'contactSLA',
                            value: e.target.value,
                          },
                        });
                      }}
                    >
                      <MenuItem className={classes.optionDefault} key={'option-default'} value={undefined}>
                        {Translation('common.dropdown.emptyValue')}
                      </MenuItem>
                      {campaignDropdownList &&
                        campaignDropdownList[CampaignDropdownType.CONTACT_SLA] &&
                        campaignDropdownList[CampaignDropdownType.CONTACT_SLA].map((dropdownItem) => (
                          <MenuItem key={dropdownItem.key} value={dropdownItem.key}>
                            {dropdownItem.value}
                          </MenuItem>
                        ))}
                    </Select>
                    {errorState.mandatory.contactSLA && <FormHelperText>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>}
                  </FormControl>
                </div>
              )}

              {formState.isEngagementRequired === false && (
                <div>
                  <div className={classes.rowContainer}>
                    <div className={classes.fieldContainer}>
                      <span className={classes.field}>{Translation('pulseleads.campaign.common.meetSLA')} :</span>
                    </div>
                    <FormControl error={errorState.mandatory.meetSLA} margin="dense" variant="outlined">
                      <Select
                        disabled={formState.isEngagementRequired || !enableDraftUpdate}
                        style={{ minWidth: 350 }}
                        value={formState.meetSLA || ''}
                        onChange={(e) => {
                          onDismissErrorHandler('meetSLA', e.target.value);
                          formDispatch({
                            type: 'MODIFY_FIELD',
                            payload: {
                              field: 'meetSLA',
                              value: e.target.value,
                            },
                          });
                        }}
                      >
                        <MenuItem className={classes.optionDefault} key={'option-default'} value={undefined}>
                          {Translation('common.dropdown.emptyValue')}
                        </MenuItem>
                        {campaignDropdownList &&
                          campaignDropdownList[CampaignDropdownType.MEET_SLA] &&
                          campaignDropdownList[CampaignDropdownType.MEET_SLA].map((dropdownItem) => (
                            <MenuItem key={dropdownItem.key} value={dropdownItem.key}>
                              {dropdownItem.value}
                            </MenuItem>
                          ))}
                      </Select>
                      {errorState.mandatory.contactSLA && <FormHelperText>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>}
                    </FormControl>
                  </div>

                  <div className={classes.rowContainer}>
                    <div className={classes.fieldContainer}>
                      <span className={classes.field}>{Translation('pulseleads.campaign.common.proposalSLA')} :</span>
                    </div>
                    <FormControl error={errorState.mandatory.proposalSLA} margin="dense" variant="outlined">
                      <Select
                        disabled={formState.isEngagementRequired || !enableDraftUpdate}
                        style={{ minWidth: 350 }}
                        value={formState.proposalSLA || ''}
                        onChange={(e) => {
                          onDismissErrorHandler('proposalSLA', e.target.value);
                          formDispatch({
                            type: 'MODIFY_FIELD',
                            payload: {
                              field: 'proposalSLA',
                              value: e.target.value,
                            },
                          });
                        }}
                      >
                        <MenuItem className={classes.optionDefault} key={'option-default'} value={undefined}>
                          {Translation('common.dropdown.emptyValue')}
                        </MenuItem>
                        {campaignDropdownList &&
                          campaignDropdownList[CampaignDropdownType.PROPOSAL_SLA] &&
                          campaignDropdownList[CampaignDropdownType.PROPOSAL_SLA].map((dropdownItem) => (
                            <MenuItem key={dropdownItem.key} value={dropdownItem.key}>
                              {dropdownItem.value}
                            </MenuItem>
                          ))}
                      </Select>
                      {errorState.mandatory.contactSLA && <FormHelperText>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>}
                    </FormControl>
                  </div>

                  <div className={classes.rowContainer}>
                    <div className={classes.fieldContainer}>
                      <span className={classes.field}>
                        {Translation('pulseleads.campaign.common.applicationSLA')} :
                      </span>
                    </div>
                    <FormControl error={errorState.mandatory.applicationSLA} margin="dense" variant="outlined">
                      <Select
                        disabled={formState.isEngagementRequired || !enableDraftUpdate}
                        style={{ minWidth: 350 }}
                        value={formState.applicationSLA || ''}
                        onChange={(e) => {
                          onDismissErrorHandler('applicationSLA', e.target.value);
                          formDispatch({
                            type: 'MODIFY_FIELD',
                            payload: {
                              field: 'applicationSLA',
                              value: e.target.value,
                            },
                          });
                        }}
                      >
                        <MenuItem className={classes.optionDefault} key={'option-default'} value={undefined}>
                          {Translation('common.dropdown.emptyValue')}
                        </MenuItem>
                        {campaignDropdownList &&
                          campaignDropdownList[CampaignDropdownType.APPLICATION_SLA] &&
                          campaignDropdownList[CampaignDropdownType.APPLICATION_SLA].map((dropdownItem) => (
                            <MenuItem key={dropdownItem.key} value={dropdownItem.key}>
                              {dropdownItem.value}
                            </MenuItem>
                          ))}
                      </Select>
                      {errorState.mandatory.contactSLA && <FormHelperText>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>}
                    </FormControl>
                  </div>
                </div>
              )}
            </>
          )}

          <div className={classes.rowContainer}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>{Translation('pulseleads.campaign.common.prospect_category')} :</span>
            </div>
            <FormControl margin="dense" variant="outlined">
              <Select
                disabled={!enableDraftUpdate}
                style={{ minWidth: 350 }}
                value={formState.prospectCategory || undefined}
                onChange={(e) => {
                  formDispatch({
                    type: 'MODIFY_FIELD',
                    payload: { field: 'prospectCategory', value: e.target.value },
                  });
                }}
                displayEmpty={true}
              >
                <MenuItem className={classes.optionDefault} key={'Skip'} value={undefined}>
                  {Translation('pulseleads.campaign.common.prospect_category.Skip')}
                </MenuItem>
                {prospectCategoryList?.map((value: ProspectCategoryItem) => (
                  <MenuItem key={value.type} value={value._id}>
                    {value.type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className={classes.rowContainer}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>{Translation('pulseleads.campaign.common.customerIncentive')} :</span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <TextField
                disabled={!enableDraftUpdate && !enableActiveUpdate}
                fullWidth
                multiline
                style={{ minWidth: 350 }}
                InputProps={{
                  classes: {
                    input: classes.textArea,
                  },
                }}
                margin="dense"
                variant="outlined"
                value={formState.customerIncentive}
                onChange={(e) => {
                  formDispatch({
                    type: 'MODIFY_FIELD',
                    payload: {
                      field: 'customerIncentive',
                      value: e.target.value,
                    },
                  });
                }}
              />
            </div>
          </div>

          <div className={classes.rowContainer}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>{Translation('pulseleads.campaign.common.agentIncentive')} :</span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <TextField
                disabled={!enableDraftUpdate && !enableActiveUpdate}
                fullWidth
                multiline
                style={{ minWidth: 350 }}
                InputProps={{
                  classes: {
                    input: classes.textArea,
                  },
                }}
                margin="dense"
                variant="outlined"
                value={formState.agentIncentive}
                onChange={(e) => {
                  formDispatch({
                    type: 'MODIFY_FIELD',
                    payload: { field: 'agentIncentive', value: e.target.value },
                  });
                }}
              />
            </div>
          </div>

          <div className={classes.textAreaRowContainer}>
            <div className={classes.checkboxFieldContainer}>
              <span className={classes.field}>{Translation('pulseleads.campaign.common.allowSelfCreateLead')} :</span>
            </div>
            <FormControlLabel
              style={{ margin: '2px 12px 2px 0' }}
              control={
                <Checkbox
                  disabled={!enableDraftUpdate && !enableActiveUpdate}
                  icon={<CircleUnchecked />}
                  checkedIcon={<CircleCheckedFilled />}
                  checked={formState.isSelfCreateLeadEnabled === true}
                  onChange={(e) => {
                    if (e.target.checked) {
                      formDispatch({
                        type: 'MODIFY_FIELD',
                        payload: {
                          field: 'isSelfCreateLeadEnabled',
                          value: true,
                        },
                      });
                    }
                  }}
                />
              }
              label={Translation('app.checkbox.yes')}
              labelPlacement="end"
            />
            <FormControlLabel
              style={{ margin: '2px 0' }}
              control={
                <Checkbox
                  disabled={!enableDraftUpdate && !enableActiveUpdate}
                  icon={<CircleUnchecked />}
                  checkedIcon={<CircleCheckedFilled />}
                  checked={formState.isSelfCreateLeadEnabled === false}
                  onChange={(e) => {
                    if (e.target.checked) {
                      formDispatch({
                        type: 'MODIFY_FIELD',
                        payload: {
                          field: 'isSelfCreateLeadEnabled',
                          value: false,
                        },
                      });
                    }
                  }}
                />
              }
              label={Translation('app.checkbox.no')}
              labelPlacement="end"
            />
          </div>

          {formState.type === CampaignTypeEnum.NORMAL && (
            <>
              <div className={classes.textAreaRowContainer}>
                <div className={classes.checkboxFieldContainer}>
                  <span className={classes.field}>
                    {Translation('pulseleads.campaign.common.isAutoAssignLeadIfAgentInactive')} :
                  </span>
                </div>
                <FormControlLabel
                  style={{ margin: '2px 12px 2px 0' }}
                  control={
                    <Checkbox
                      disabled={!enableDraftUpdate && !enableActiveUpdate}
                      icon={<CircleUnchecked />}
                      checkedIcon={<CircleCheckedFilled />}
                      checked={formState.isAutoAssignLeadIfAgentInactive === true}
                      onChange={(e) => {
                        if (e.target.checked) {
                          formDispatch({
                            type: 'MODIFY_FIELD',
                            payload: {
                              field: 'isAutoAssignLeadIfAgentInactive',
                              value: true,
                            },
                          });
                        }
                      }}
                    />
                  }
                  label={Translation('app.checkbox.yes')}
                  labelPlacement="end"
                />
                <FormControlLabel
                  style={{ margin: '2px 0' }}
                  control={
                    <Checkbox
                      disabled={!enableDraftUpdate && !enableActiveUpdate}
                      icon={<CircleUnchecked />}
                      checkedIcon={<CircleCheckedFilled />}
                      checked={formState.isAutoAssignLeadIfAgentInactive === false}
                      onChange={(e) => {
                        if (e.target.checked) {
                          formDispatch({
                            type: 'MODIFY_FIELD',
                            payload: {
                              field: 'isAutoAssignLeadIfAgentInactive',
                              value: false,
                            },
                          });
                        }
                      }}
                    />
                  }
                  label={Translation('app.checkbox.no')}
                  labelPlacement="end"
                />
              </div>
              {fieldConfig.enableLeadWithPartialInfoWarning && (
                <div className={classes.textAreaRowContainer}>
                  <div className={classes.checkboxFieldContainer}>
                    <span className={classes.field}>
                      {Translation('pulseleads.campaign.common.isLeadWithPartialInfo')} :
                    </span>
                    <Tooltip
                      arrow
                      title={
                        <span style={{ fontSize: 10 }}>
                          {intl.formatMessage(
                            { id: 'pulseleads.campaign.common.isLeadWithPartialInfoToolTip' },
                            { br: <br /> },
                          )}
                        </span>
                      }
                    >
                      <Info style={{ color: 'grey' }} />
                    </Tooltip>
                  </div>
                  <FormControlLabel
                    style={{ margin: '2px 12px 2px 0' }}
                    control={
                      <Checkbox
                        disabled={!enableDraftUpdate && !enableActiveUpdate}
                        icon={<CircleUnchecked />}
                        checkedIcon={<CircleCheckedFilled />}
                        checked={formState.isLeadWithPartialInfo === true}
                        onChange={(e) => {
                          if (e.target.checked) {
                            formDispatch({
                              type: 'MODIFY_FIELD',
                              payload: {
                                field: 'isLeadWithPartialInfo',
                                value: true,
                              },
                            });
                          }
                        }}
                      />
                    }
                    label={Translation('app.checkbox.yes')}
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    style={{ margin: '2px 0' }}
                    control={
                      <Checkbox
                        disabled={!enableDraftUpdate && !enableActiveUpdate}
                        icon={<CircleUnchecked />}
                        checkedIcon={<CircleCheckedFilled />}
                        checked={formState.isLeadWithPartialInfo === false}
                        onChange={(e) => {
                          if (e.target.checked) {
                            formDispatch({
                              type: 'MODIFY_FIELD',
                              payload: {
                                field: 'isLeadWithPartialInfo',
                                value: false,
                              },
                            });
                          }
                        }}
                      />
                    }
                    label={Translation('app.checkbox.no')}
                    labelPlacement="end"
                  />
                </div>
              )}
              {formState.isLeadWithPartialInfo === true && (
                <div style={{ paddingTop: 5 }}>
                  <div className={`${classes.rowContainer} ${classes.divideMargin}`}>
                    <div className={classes.checkboxFieldContainer} />
                    <div style={{ flexGrow: 1 }}>
                      <div>
                        <span className={classes.field}>
                          {Translation('pulseleads.campaign.common.leadPartialCondition.condition')}
                          <span className={classes.mandatory}>*</span>
                        </span>
                      </div>
                      <FormControl margin="dense" variant="outlined">
                        <Select
                          disabled={!enableDraftUpdate && !enableActiveUpdate}
                          style={{ minWidth: 350 }}
                          value={
                            formState.leadWithPartialCondition?.name ? formState.leadWithPartialCondition.name : ''
                          }
                          onChange={(e) =>
                            formDispatch({
                              type: 'MODIFY_FIELD',
                              payload: {
                                field: 'leadWithPartialCondition',
                                value: { ...formState.leadWithPartialCondition, name: e.target.value },
                              },
                            })
                          }
                        >
                          {map(LeadWithPartialConditionEnum, (option: string) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div className={`${classes.rowContainer} ${classes.divideMargin}`}>
                    <div className={classes.checkboxFieldContainer} />
                    <div style={{ flexGrow: 1 }}>
                      <div>
                        <span className={classes.field}>
                          {Translation('pulseleads.campaign.common.leadPartialInfo.title')}
                          <span className={classes.mandatory}>*</span>
                        </span>
                      </div>
                      <TextField
                        disabled={!enableDraftUpdate && !enableActiveUpdate}
                        style={{ minWidth: 350 }}
                        error={errorState.mandatory.leadPartialInfoTitle}
                        margin="dense"
                        variant="outlined"
                        helperText={errorState.mandatory.leadPartialInfoTitle && MANDATORY_FIELD_ERROR_TEXT}
                        value={formState.leadWithPartialInfoWarning.title}
                        onChange={(e) => {
                          onDismissErrorHandler('leadPartialInfoTitle', e.target.value);
                          formDispatch({
                            type: 'MODIFY_FIELD',
                            payload: {
                              field: 'leadWithPartialInfoWarning',
                              value: { ...formState.leadWithPartialInfoWarning, title: e.target.value },
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className={`${classes.rowContainer} ${classes.divideMargin}`}>
                    <div className={classes.checkboxFieldContainer} />
                    <div style={{ flexGrow: 1 }}>
                      <div>
                        <span className={classes.field}>
                          {Translation('pulseleads.campaign.common.leadPartialInfo.body')}
                          <span className={classes.mandatory}>*</span>
                        </span>
                      </div>
                      <TextField
                        disabled={!enableDraftUpdate && !enableActiveUpdate}
                        fullWidth
                        style={{ minWidth: 350 }}
                        error={errorState.mandatory.leadPartialInfoBody}
                        multiline
                        margin="dense"
                        variant="outlined"
                        helperText={errorState.mandatory.leadPartialInfoBody && MANDATORY_FIELD_ERROR_TEXT}
                        value={formState.leadWithPartialInfoWarning.body}
                        InputProps={{
                          classes: {
                            input: classes.textArea,
                          },
                        }}
                        onChange={(e) => {
                          onDismissErrorHandler('leadPartialInfoBody', e.target.value);
                          formDispatch({
                            type: 'MODIFY_FIELD',
                            payload: {
                              field: 'leadWithPartialInfoWarning',
                              value: { ...formState.leadWithPartialInfoWarning, body: e.target.value },
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className={classes.rowContainer}>
                    <div className={classes.checkboxFieldContainer} />
                    <div style={{ flexGrow: 1 }}>
                      <div>
                        <span className={classes.field}>
                          {Translation('pulseleads.campaign.common.leadPartialInfo.tnc')}
                          <span className={classes.mandatory}>*</span>
                        </span>
                      </div>
                      <TextField
                        disabled={!enableDraftUpdate && !enableActiveUpdate}
                        fullWidth
                        style={{ minWidth: 350 }}
                        error={errorState.mandatory.leadPartialInfoTnc}
                        multiline
                        margin="dense"
                        variant="outlined"
                        helperText={errorState.mandatory.leadPartialInfoTnc && MANDATORY_FIELD_ERROR_TEXT}
                        value={formState.leadWithPartialInfoWarning.tnc}
                        InputProps={{
                          classes: {
                            input: classes.textArea,
                          },
                        }}
                        onChange={(e) => {
                          onDismissErrorHandler('leadPartialInfoTnc', e.target.value);
                          formDispatch({
                            type: 'MODIFY_FIELD',
                            payload: {
                              field: 'leadWithPartialInfoWarning',
                              value: { ...formState.leadWithPartialInfoWarning, tnc: e.target.value },
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>

        {[CampaignTypeEnum.NORMAL, CampaignTypeEnum.MASS].includes(formState.type as CampaignTypeEnum) && (
          <div className={classes.sectionMargin}>
            <div className={classes.divideMargin}>
              <span className={classes.subHeader}>{Translation('pulseleads.campaign.form.section.rules')}</span>
            </div>
            {formState.type === CampaignTypeEnum.NORMAL && (
              <div>
                <div className={classes.divideMargin}>
                  <span className={classes.subSectionHeader}>
                    {Translation('pulseleads.campaign.form.leadDistribution')}
                  </span>
                </div>

                <div className={classes.rowContainer}>
                  <div className={classes.fieldContainer}>
                    <span className={classes.field}>{Translation('pulseleads.campaign.common.rule_step_3')} :</span>
                  </div>
                  <FormControl margin="dense" variant="outlined">
                    <Select
                      disabled={true}
                      style={{ minWidth: 350 }}
                      value={formState.referralAgentCheck || ''}
                      onChange={(e) => {
                        formDispatch({
                          type: 'MODIFY_FIELD',
                          payload: {
                            field: 'referralAgentCheck',
                            value: e.target.value,
                          },
                        });
                      }}
                    >
                      <MenuItem
                        className={classes.optionDefault}
                        key={ReferralAgentCheckTypeEnum.ENABLE}
                        value={ReferralAgentCheckTypeEnum.ENABLE}
                      >
                        {Translation('pulseleads.campaign.common.dropdown.enable')}
                      </MenuItem>
                      {/* PACS Customization - PACSPFAP-1258 */}
                      {/* <MenuItem key={'option-default'} value={ReferralAgentCheckTypeEnum.DISABLE}>
                        {Translation('common.dropdown.emptyValue')}
                      </MenuItem> */}
                    </Select>
                  </FormControl>
                </div>

                <div className={classes.rowContainer}>
                  <div className={classes.fieldContainer}>
                    <span className={classes.field}>{Translation('pulseleads.campaign.common.rule_step_4')} :</span>
                  </div>
                  <FormControl margin="dense" variant="outlined">
                    <Select
                      disabled={!enableDraftUpdate}
                      style={{ minWidth: 350 }}
                      value={formState.leadDedupRule || ''}
                      onChange={(e) =>
                        formDispatch({
                          type: 'MODIFY_FIELD',
                          payload: {
                            field: 'leadDedupRule',
                            value: e.target.value,
                          },
                        })
                      }
                    >
                      <MenuItem className={classes.optionDefault} key={'option-default'} value={undefined}>
                        {Translation('common.dropdown.emptyValue')}
                      </MenuItem>
                      {campaignDropdownList &&
                        campaignDropdownList[CampaignDropdownType.LEAD_DEDUPLICATION_RULE] &&
                        campaignDropdownList[CampaignDropdownType.LEAD_DEDUPLICATION_RULE].map((dropdownItem) => (
                          <MenuItem key={dropdownItem.key} value={dropdownItem.key}>
                            {dropdownItem.value}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>

                <div className={classes.rowContainer}>
                  <div className={classes.fieldContainer}>
                    <span className={classes.field}>{Translation('pulseleads.campaign.common.rule_step_5')} :</span>
                  </div>
                  <div style={{ flexGrow: 1 }}>
                    <FormControl margin="dense" variant="outlined">
                      <Select
                        disabled={!enableDraftUpdate}
                        style={{ minWidth: 350 }}
                        value={formState.customerDedup || ''}
                        onChange={(e) =>
                          formDispatch({
                            type: 'MODIFY_FIELD',
                            payload: {
                              field: 'customerDedup',
                              value: e.target.value,
                            },
                          })
                        }
                      >
                        <MenuItem className={classes.optionDefault} key={'option-default'} value={undefined}>
                          {Translation('common.dropdown.emptyValue')}
                        </MenuItem>
                        {map(CampaignCustomerDedupEnum, (option: string) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>

                <div className={classes.rowContainer}>
                  <div className={classes.fieldContainer}>
                    <span className={classes.field}>
                      {Translation('pulseleads.campaign.common.customerDedupRule')} :
                    </span>
                  </div>
                  <FormControl margin="dense" variant="outlined">
                    <Select
                      disabled={!formState.customerDedup || !enableDraftUpdate}
                      multiple
                      style={{ minWidth: 350 }}
                      value={formState.customerDedupRule || []}
                      renderValue={(selected) => (
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                          {((selected || []) as string[]).map((value) => {
                            if (
                              campaignDropdownList &&
                              campaignDropdownList[CampaignDropdownType.CUSTOMER_DEDUPLICATION_RULE]
                            ) {
                              const foundItem = campaignDropdownList[
                                CampaignDropdownType.CUSTOMER_DEDUPLICATION_RULE
                              ].find((item) => item.key === value);
                              if (foundItem) {
                                return <Chip key={value} label={foundItem.value} style={{ margin: 2 }} />;
                              }
                            }
                            return <></>;
                          })}
                        </div>
                      )}
                      onChange={(e) =>
                        formDispatch({
                          type: 'MODIFY_FIELD',
                          payload: {
                            field: 'customerDedupRule',
                            value: e.target.value || [],
                          },
                        })
                      }
                    >
                      {campaignDropdownList &&
                        campaignDropdownList[CampaignDropdownType.CUSTOMER_DEDUPLICATION_RULE] &&
                        campaignDropdownList[CampaignDropdownType.CUSTOMER_DEDUPLICATION_RULE].map((dropdownItem) => (
                          <MenuItem key={dropdownItem.key} value={dropdownItem.key}>
                            {dropdownItem.value}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>

                <div className={classes.rowContainer}>
                  <div className={classes.fieldContainer}>
                    <span className={classes.field}>{Translation('pulseleads.campaign.common.exclusionRule')} :</span>
                  </div>
                  <FormControl margin="dense" variant="outlined">
                    <Select
                      disabled={!enableDraftUpdate && !enableActiveUpdate}
                      style={{ minWidth: 350 }}
                      value={formState.exclusionRule || ''}
                      onChange={(e) =>
                        formDispatch({
                          type: 'MODIFY_FIELD',
                          payload: {
                            field: 'exclusionRule',
                            value: e.target.value,
                          },
                        })
                      }
                    >
                      <MenuItem className={classes.optionDefault} key={'option-default'} value={undefined}>
                        {Translation('common.dropdown.emptyValue')}
                      </MenuItem>
                      {campaignDropdownList &&
                        campaignDropdownList[CampaignDropdownType.EXCLUSION_RULE] &&
                        campaignDropdownList[CampaignDropdownType.EXCLUSION_RULE].map((dropdownItem) => (
                          <MenuItem key={dropdownItem.key} value={dropdownItem.key}>
                            {dropdownItem.value}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>

                <div className={classes.rowContainer}>
                  <div className={classes.fieldContainer}>
                    <span className={classes.field}>
                      {Translation('pulseleads.campaign.common.distributionRule')}
                      <span className={classes.mandatory}>*</span> :{/* PACS Customization - PACSPFAP-1258 */}
                    </span>
                  </div>
                  <FormControl error={errorState.mandatory.distributionRule} margin="dense" variant="outlined">
                    <Select
                      disabled={!enableDraftUpdate}
                      style={{ minWidth: 350 }}
                      value={formState.distributionRule || ''}
                      onChange={(e) => {
                        onDismissErrorHandler('distributionRule', e.target.value);
                        formDispatch({
                          type: 'MODIFY_FIELD',
                          payload: {
                            field: 'distributionRule',
                            value: e.target.value,
                          },
                        });
                      }}
                      displayEmpty={true}
                    >
                      {/* PACS Customization - PACSPFAP-1258 */}
                      {/* <MenuItem className={classes.optionDefault} key={'option-default'} value={undefined}>
                        {Translation('common.dropdown.emptyValue')}
                      </MenuItem> */}
                      {campaignDropdownList &&
                        campaignDropdownList[CampaignDropdownType.DISTRIBUTION_RULE] &&
                        campaignDropdownList[CampaignDropdownType.DISTRIBUTION_RULE].map((dropdownItem) => (
                          <MenuItem key={dropdownItem.key} value={dropdownItem.key}>
                            {dropdownItem.value}
                          </MenuItem>
                        ))}
                    </Select>
                    {/* PACS Customization - PACSPFAP-1258 */}
                    {errorState.mandatory.distributionRule && (
                      <FormHelperText>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>
                    )}
                  </FormControl>
                </div>

                <div className={classes.rowContainer}>
                  <div className={classes.fieldContainer}>
                    <span className={classes.field}>
                      {Translation('pulseleads.campaign.common.eligibilityRule')}
                      <span className={classes.mandatory}>*</span> :{/* PACS Customization - PACSPFAP-1258 */}
                    </span>
                  </div>
                  <FormControl error={errorState.mandatory.eligibilityRule} margin="dense" variant="outlined">
                    <Select
                      disabled={!enableDraftUpdate}
                      style={{ minWidth: 350 }}
                      value={formState.eligibilityRule || ''}
                      onChange={(e) => {
                        onDismissErrorHandler('eligibilityRule', e.target.value);
                        formDispatch({
                          type: 'MODIFY_FIELD',
                          payload: {
                            field: 'eligibilityRule',
                            value: e.target.value,
                          },
                        });
                      }}
                      displayEmpty={true}
                    >
                      {/* PACS Customization - PACSPFAP-1258 */}
                      {/* <MenuItem className={classes.optionDefault} key={'option-default'} value={undefined}>
                        {Translation('common.dropdown.emptyValue')}
                      </MenuItem> */}
                      {campaignDropdownList &&
                        campaignDropdownList[CampaignDropdownType.ELIGIBILITY_RULE] &&
                        campaignDropdownList[CampaignDropdownType.ELIGIBILITY_RULE].map((dropdownItem) => (
                          <MenuItem key={dropdownItem.key} value={dropdownItem.key}>
                            {dropdownItem.value}
                          </MenuItem>
                        ))}
                    </Select>
                    {errorState.mandatory.eligibilityRule && (
                      <FormHelperText>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>
                    )}
                  </FormControl>
                </div>
              </div>
            )}
            {formState.type === CampaignTypeEnum.NORMAL && (
              <div className="tw-flex tw-flex-1 tw-flex-row tw-items-center">
                <div className={classes.checkboxFieldContainer}>
                  <div>{Translation('pulseleads.campaign.common.isMaxMarketingOpenLead')} :</div>
                </div>
                <div className="tw-flex tw-flex-row tw-items-center tw-mt-4">
                  <div className="tw-flex tw-mt-3">
                    <FormControlLabel
                      control={
                        <Checkbox
                          icon={<CircleUnchecked />}
                          checkedIcon={<CircleCheckedFilled />}
                          checked={formState.maximumMarketingLeads.isMaximum === true}
                          onChange={(e) => {
                            if (e.target.checked) {
                              formDispatch({
                                type: 'MODIFY_FIELD',
                                payload: {
                                  field: 'maximumMarketingLeads',
                                  value: {
                                    ...formState.maximumMarketingLeads,
                                    isMaximum: true,
                                  },
                                },
                              });
                            }
                          }}
                        />
                      }
                      label={Translation('pulseleads.marketingLeadLimitRule.enable')}
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          icon={<CircleUnchecked />}
                          checkedIcon={<CircleCheckedFilled />}
                          checked={!formState.maximumMarketingLeads.isMaximum}
                          onChange={(e) => {
                            if (e.target.checked) {
                              formDispatch({
                                type: 'MODIFY_FIELD',
                                payload: {
                                  field: 'maximumMarketingLeads',
                                  value: {
                                    ...formState.maximumMarketingLeads,
                                    isMaximum: false,
                                  },
                                },
                              });
                            }
                          }}
                        />
                      }
                      label={Translation('pulseleads.marketingLeadLimitRule.disable')}
                      labelPlacement="end"
                    />
                  </div>
                  {formState.maximumMarketingLeads.isMaximum === true && (
                    <span className="tw-flex tw-flex-row tw-items-center ">
                      <TextField
                        error={errorState.immediate.maxLeadLimit}
                        margin="dense"
                        variant="outlined"
                        helperText={
                          errorState.immediate.maxLeadLimit &&
                          Translation('pulseleads.agent.autoDropLead.leadDateErrorMsg')
                        }
                        value={formState.maximumMarketingLeads.limit ?? null}
                        type="number"
                        onChange={(e) => {
                          onDismissErrorHandler('maxLeadLimit', e.target.value);
                          formDispatch({
                            type: 'MODIFY_FIELD',
                            payload: {
                              field: 'maximumMarketingLeads',
                              value: { ...formState.maximumMarketingLeads, limit: Number(e.target.value) },
                            },
                          });
                        }}
                      />
                      <span className="tw-ml-4">{Translation('pulseleads.marketingLeadLimitRule.maxLeads')}</span>
                    </span>
                  )}
                </div>
              </div>
            )}
            {/* PACS Customization | PACSPFAP-1445 hide lead drop */}
            {/* <div className={classes.textAreaRowContainer}>
              <div className={classes.checkboxFieldContainer}>
                <span className={classes.field}>{Translation('pulseleads.agent.autoDropLead.leadDrop')} :</span>
              </div>
              <span>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={!enableDraftUpdate && !enableActiveUpdate}
                      icon={<CircleUnchecked />}
                      checkedIcon={<CircleCheckedFilled />}
                      checked={formState.enableAutoDropLead === true}
                      onChange={(e) => {
                        if (e.target.checked) {
                          formDispatch({
                            type: 'MODIFY_FIELD',
                            payload: {
                              field: 'enableAutoDropLead',
                              value: true,
                            },
                          });
                        }
                      }}
                    />
                  }
                  label={Translation('pulseleads.agent.autoDropLead.enable')}
                  labelPlacement="end"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={!enableDraftUpdate && !enableActiveUpdate}
                      icon={<CircleUnchecked />}
                      checkedIcon={<CircleCheckedFilled />}
                      checked={formState.enableAutoDropLead === false}
                      onChange={(e) => {
                        if (e.target.checked) {
                          formDispatch({
                            type: 'MODIFY_FIELD',
                            payload: {
                              field: 'enableAutoDropLead',
                              value: false,
                            },
                          });
                        }
                      }}
                    />
                  }
                  label={Translation('pulseleads.agent.autoDropLead.disable')}
                  labelPlacement="end"
                />
              </span>
            </div>
            {formState.enableAutoDropLead === true && (
              <div>
                <div>
                  <div>
                    <div className="tw-flex tw-flex-row tw-items-center">
                      <span style={styles.minSubTitle}>
                        {Translation('pulseleads.agent.autoDropLead.leadDropDate')} :
                      </span>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={!enableDraftUpdate && !enableActiveUpdate}
                            icon={<CircleUnchecked />}
                            checkedIcon={<CircleCheckedFilled />}
                            checked={formState.autoDropLeadType === DropLeadTypeEnum.BY_LEAD_UPDATE_TIME}
                            onChange={(e) => {
                              if (e.target.checked) {
                                formDispatch({
                                  type: 'MODIFY_FIELD',
                                  payload: {
                                    field: 'autoDropLeadType',
                                    value: DropLeadTypeEnum.BY_LEAD_UPDATE_TIME,
                                  },
                                });
                                onDismissErrorHandler('autoDropLeadDays', null);
                              }
                            }}
                          />
                        }
                        label={
                          <div className="tw-flex tw-items-center tw-justify-center tw-flex-row">
                            <div className="tw-w-60">{Translation('pulseleads.agent.autoDropLead.lastUpdatedAt')}</div>
                            {formState.autoDropLeadType === DropLeadTypeEnum.BY_LEAD_UPDATE_TIME && (
                              <>
                                <TextField
                                  disabled={!enableDraftUpdate && !enableActiveUpdate}
                                  style={styles.minWidthNumber}
                                  error={errorState.immediate.autoDropLeadDays}
                                  margin="dense"
                                  variant="outlined"
                                  helperText={
                                    errorState.immediate.autoDropLeadDays &&
                                    Translation('pulseleads.agent.autoDropLead.leadDateErrorMsg')
                                  }
                                  value={formState.autoDropLeadConfig?.[DropLeadTypeEnum.BY_LEAD_UPDATE_TIME] ?? null}
                                  type="number"
                                  onChange={(e) => {
                                    onDismissErrorHandler('autoDropLeadDays', e.target.value);
                                    formDispatch({
                                      type: 'MODIFY_FIELD',
                                      payload: {
                                        field: 'autoDropLeadConfig',
                                        value: {
                                          [DropLeadTypeEnum.BY_LEAD_UPDATE_TIME]: Number(e.target.value),
                                        },
                                      },
                                    });
                                  }}
                                />
                                <div className="tw-ml-4">{Translation('pulseleads.agent.autoDropLead.days')}</div>
                                <div className="tw-ml-6">
                                  {Translation('pulseleads.agent.autoDropLead.lastUpdatedAt.reason')}
                                </div>
                              </>
                            )}
                          </div>
                        }
                        labelPlacement="end"
                      />
                    </div>
                    <div className="tw-flex tw-flex-col">
                      <FormControlLabel
                        style={styles.inputMarginLeft}
                        control={
                          <Checkbox
                            disabled={!enableDraftUpdate && !enableActiveUpdate}
                            icon={<CircleUnchecked />}
                            checkedIcon={<CircleCheckedFilled />}
                            checked={formState.autoDropLeadType === DropLeadTypeEnum.BY_CAMPAIGN_END_DATE}
                            onChange={(e) => {
                              if (e.target.checked) {
                                formDispatch({
                                  type: 'MODIFY_FIELD',
                                  payload: {
                                    field: 'autoDropLeadType',
                                    value: DropLeadTypeEnum.BY_CAMPAIGN_END_DATE,
                                  },
                                });
                                formDispatch({
                                  type: 'MODIFY_FIELD',
                                  payload: {
                                    field: 'autoDropLeadConfig',
                                    value: null,
                                  },
                                });
                              }
                            }}
                          />
                        }
                        label={
                          <div className="tw-flex tw-flex-row tw-items-center tw-justify-center">
                            <div className="tw-w-60">
                              {Translation('pulseleads.agent.autoDropLead.campaignEndDate')}
                            </div>
                            {formState.autoDropLeadType === DropLeadTypeEnum.BY_CAMPAIGN_END_DATE && (
                              <>
                                <TextField
                                  disabled={!enableDraftUpdate && !enableActiveUpdate}
                                  style={styles.minWidthNumber}
                                  error={errorState.immediate.autoDropLeadDays}
                                  margin="dense"
                                  variant="outlined"
                                  helperText={
                                    errorState.immediate.autoDropLeadDays &&
                                    Translation('pulseleads.agent.autoDropLead.leadDateErrorMsg')
                                  }
                                  value={formState.autoDropLeadConfig?.[DropLeadTypeEnum.BY_CAMPAIGN_END_DATE] ?? null}
                                  type="number"
                                  onChange={(e) => {
                                    onDismissErrorHandler('autoDropLeadDays', e.target.value);
                                    formDispatch({
                                      type: 'MODIFY_FIELD',
                                      payload: {
                                        field: 'autoDropLeadConfig',
                                        value: {
                                          [DropLeadTypeEnum.BY_CAMPAIGN_END_DATE]: Number(e.target.value),
                                        },
                                      },
                                    });
                                  }}
                                />
                                <div className="tw-ml-4">{Translation('pulseleads.agent.autoDropLead.days')}</div>
                                <div className="tw-ml-6">
                                  {Translation('pulseleads.agent.autoDropLead.campaignEndDate.reason')}
                                </div>
                              </>
                            )}
                          </div>
                        }
                        labelPlacement="end"
                      />

                      <FormControlLabel
                        style={styles.inputMarginLeft}
                        control={
                          <Checkbox
                            disabled={!enableDraftUpdate && !enableActiveUpdate}
                            icon={<CircleUnchecked />}
                            checkedIcon={<CircleCheckedFilled />}
                            checked={formState.autoDropLeadType === DropLeadTypeEnum.BY_LEAD_CREATE_DATE}
                            onChange={(e) => {
                              if (e.target.checked) {
                                formDispatch({
                                  type: 'MODIFY_FIELD',
                                  payload: {
                                    field: 'autoDropLeadType',
                                    value: DropLeadTypeEnum.BY_LEAD_CREATE_DATE,
                                  },
                                });
                              }
                            }}
                          />
                        }
                        label={
                          <div className="tw-flex tw-flex-row tw-items-center tw-justify-center">
                            <div className="tw-w-60">{Translation('pulseleads.agent.autoDropLead.leadDate')}</div>
                            {formState.autoDropLeadType === DropLeadTypeEnum.BY_LEAD_CREATE_DATE && (
                              <>
                                <TextField
                                  disabled={!enableDraftUpdate && !enableActiveUpdate}
                                  style={styles.minWidthNumber}
                                  error={errorState.immediate.autoDropLeadDays}
                                  margin="dense"
                                  variant="outlined"
                                  helperText={
                                    errorState.immediate.autoDropLeadDays &&
                                    Translation('pulseleads.agent.autoDropLead.leadDateErrorMsg')
                                  }
                                  value={formState.autoDropLeadConfig?.[DropLeadTypeEnum.BY_LEAD_CREATE_DATE] ?? null}
                                  type="number"
                                  onChange={(e) => {
                                    onDismissErrorHandler('autoDropLeadDays', e.target.value);
                                    formDispatch({
                                      type: 'MODIFY_FIELD',
                                      payload: {
                                        field: 'autoDropLeadConfig',
                                        value: {
                                          [DropLeadTypeEnum.BY_LEAD_CREATE_DATE]: Number(e.target.value),
                                        },
                                      },
                                    });
                                  }}
                                />
                                <div className="tw-ml-4">{Translation('pulseleads.agent.autoDropLead.days')}</div>
                                <div className="tw-ml-6">
                                  {Translation('pulseleads.agent.autoDropLead.leadDate.reason')}
                                </div>
                              </>
                            )}
                          </div>
                        }
                        labelPlacement="end"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )} */}
          </div>
        )}
        <div className={classes.sectionMargin}>
          <div className={classes.divideMargin}>
            <span className={classes.subHeader}>{Translation('pulseleads.campaign.form.section.campaignInfo')}</span>
          </div>

          <div className={classes.textAreaRowContainer}>
            <div className={classes.textAreaFieldContainer}>
              <span className={classes.field}>{Translation('pulseleads.campaign.common.callScripts')} :</span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <TextField
                disabled={!enableDraftUpdate && !enableActiveUpdate}
                fullWidth
                multiline
                margin="dense"
                variant="outlined"
                value={formState.callScripts}
                InputProps={{
                  classes: {
                    input: classes.textArea,
                  },
                }}
                onChange={(e) => {
                  formDispatch({
                    type: 'MODIFY_FIELD',
                    payload: { field: 'callScripts', value: e.target.value },
                  });
                }}
              />
            </div>
          </div>

          <div className={classes.textAreaRowContainer}>
            <div className={classes.textAreaFieldContainer}>
              <span className={classes.field}>{Translation('pulseleads.campaign.common.saleTipsInsight')} :</span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <TextField
                disabled={!enableDraftUpdate && !enableActiveUpdate}
                fullWidth
                multiline
                margin="dense"
                variant="outlined"
                value={formState.saleTipsInsight}
                InputProps={{
                  classes: {
                    input: classes.textArea,
                  },
                }}
                onChange={(e) => {
                  formDispatch({
                    type: 'MODIFY_FIELD',
                    payload: {
                      field: 'saleTipsInsight',
                      value: e.target.value,
                    },
                  });
                }}
              />
            </div>
          </div>

          <div className={classes.rowContainer} style={{ margin: '12px 0' }}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>{Translation('pulseleads.campaign.common.faq')} :</span>
            </div>
            <div className={classes.rowContainer}>
              {(formState.faq || []).map((item, index) => (
                <LinkItem
                  disabled={!enableDraftUpdate && !enableActiveUpdate}
                  key={`faq-item-${index}`}
                  style={{ marginRight: 10 }}
                  name={item.question}
                  onSelectItem={() =>
                    setFormDialogState({
                      open: true,
                      title: Translation('pulseleads.campaign.faq.edit'),
                      dataSource: item,
                      fieldDef: faqFieldDef,
                      onFinish: (data) =>
                        formDispatch({
                          type: 'MODIFY_ITEM',
                          payload: { field: 'faq', index, item: data },
                        }),
                      onClose: () => setFormDialogState(initialDialogState),
                    })
                  }
                  onDeleteItem={() =>
                    formDispatch({
                      type: 'DELETE_ITEM',
                      payload: { field: 'faq', index },
                    })
                  }
                />
              ))}
              <Button
                disabled={!enableDraftUpdate && !enableActiveUpdate}
                variant="contained"
                color="secondary"
                onClick={() =>
                  setFormDialogState({
                    open: true,
                    title: Translation('pulseleads.campaign.faq.create'),
                    fieldDef: faqFieldDef,
                    onFinish: (data) =>
                      formDispatch({
                        type: 'APPEND_ITEM',
                        payload: { field: 'faq', item: data },
                      }),
                    onClose: () => setFormDialogState(initialDialogState),
                  })
                }
              >
                {Translation('pulseleads.campaign.faq.addFaq')}
              </Button>
            </div>
          </div>

          <div className={classes.rowContainer} style={{ margin: '12px 0' }}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>{Translation('pulseleads.campaign.common.links')} :</span>
            </div>
            <div className={classes.rowContainer}>
              {(formState.links || []).map((item, index) => (
                <LinkItem
                  disabled={!enableDraftUpdate && !enableActiveUpdate}
                  key={`links-item-${index}`}
                  style={{ marginRight: 10 }}
                  name={item.title}
                  onSelectItem={() =>
                    setFormDialogState({
                      open: true,
                      title: Translation('pulseleads.campaign.links.edit'),
                      dataSource: item,
                      fieldDef: linksFieldDef,
                      onFinish: (data) =>
                        formDispatch({
                          type: 'MODIFY_ITEM',
                          payload: { field: 'links', index, item: data },
                        }),
                      onClose: () => setFormDialogState(initialDialogState),
                    })
                  }
                  onDeleteItem={() =>
                    formDispatch({
                      type: 'DELETE_ITEM',
                      payload: { field: 'links', index },
                    })
                  }
                />
              ))}
              <Button
                disabled={!enableDraftUpdate && !enableActiveUpdate}
                variant="contained"
                color="secondary"
                onClick={() =>
                  setFormDialogState({
                    open: true,
                    title: Translation('pulseleads.campaign.links.create'),
                    fieldDef: linksFieldDef,
                    onFinish: (data) =>
                      formDispatch({
                        type: 'APPEND_ITEM',
                        payload: { field: 'links', item: data },
                      }),
                    onClose: () => setFormDialogState(initialDialogState),
                  })
                }
              >
                {Translation('pulseleads.campaign.links.addLink')}
              </Button>
            </div>
          </div>

          <div className={classes.rowContainer} style={{ margin: '12px 0' }}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>{Translation('pulseleads.campaign.common.flyers')} :</span>
            </div>
            <div className={classes.rowContainer}>
              {(formState.flyers || []).map((item, index) => (
                <LinkItem
                  disabled={!enableDraftUpdate && !enableActiveUpdate}
                  key={`flyers-item-${index}`}
                  style={{ marginRight: 10 }}
                  name={item.title}
                  onSelectItem={() =>
                    setFormDialogState({
                      open: true,
                      title: Translation('pulseleads.campaign.flyers.edit'),
                      dataSource: item,
                      fieldDef: flyersFieldDef,
                      onFinish: (data) =>
                        formDispatch({
                          type: 'MODIFY_ITEM',
                          payload: { field: 'flyers', index, item: data },
                        }),
                      onClose: () => setFormDialogState(initialDialogState),
                    })
                  }
                  onDeleteItem={() =>
                    formDispatch({
                      type: 'DELETE_ITEM',
                      payload: { field: 'flyers', index },
                    })
                  }
                />
              ))}
              <Button
                disabled={!enableDraftUpdate && !enableActiveUpdate}
                variant="contained"
                color="secondary"
                onClick={() =>
                  setFormDialogState({
                    open: true,
                    title: Translation('pulseleads.campaign.flyers.create'),
                    fieldDef: flyersFieldDef,
                    onFinish: (data) =>
                      formDispatch({
                        type: 'APPEND_ITEM',
                        payload: { field: 'flyers', item: data },
                      }),
                    onClose: () => setFormDialogState(initialDialogState),
                  })
                }
              >
                {Translation('pulseleads.campaign.flyers.addFlyer')}
              </Button>
            </div>
          </div>

          {formState.type === CampaignTypeEnum.NORMAL && (
            <HierachyDropdown
              errorState={errorState}
              onDismissErrorHandler={onDismissErrorHandler}
              errorTextDetail={requiredPriorities}
              disabled={!enableDraftUpdate && !enableActiveUpdate}
              fieldContainerCSS={classes.fieldContainer}
              layers={[
                { name: Translation('pulseleads.campaign.priorities.1') },
                { name: Translation('pulseleads.campaign.priorities.2') },
                { name: Translation('pulseleads.campaign.priorities.3') },
              ]}
              selectedItems={(formState.priorities || []).map((priority) => priority.step)}
              dropdownList={map(CampaignPriorityEnum, (option: string) => ({
                value: option,
                displayText: Translation(`pulseleads.campaign.priorities.option.${option}`),
              }))}
              onChangeDropdown={(result) =>
                formDispatch({
                  type: 'MODIFY_FIELD',
                  payload: {
                    field: 'priorities',
                    value: result.map((item) => ({ step: item })),
                  },
                })
              }
            />
          )}
        </div>

        <div className={classes.footerContainer}>
          {!campaignItem && (
            <>
              <Button variant="contained" color="secondary" onClick={() => onSubmit(CampaignStatusEnum.DRAFT)}>
                {Translation('app.button.saveAsDraft')}
              </Button>
              <Button
                style={{ marginLeft: 25 }}
                variant="contained"
                color="secondary"
                onClick={() => onSubmit(CampaignStatusEnum.ACTIVE)}
              >
                {Translation('app.button.activateCampaign')}
              </Button>
            </>
          )}
          {campaignItem && (
            <>
              {campaignItem.status === CampaignStatusEnum.DRAFT && (
                <>
                  <Button variant="contained" color="secondary" onClick={() => onSubmit(CampaignStatusEnum.DRAFT)}>
                    {Translation('app.button.saveAsDraft')}
                  </Button>
                  <Button
                    style={{ marginLeft: 25 }}
                    variant="contained"
                    color="secondary"
                    onClick={() => onSubmit(CampaignStatusEnum.ACTIVE)}
                  >
                    {Translation('app.button.activateCampaign')}
                  </Button>
                </>
              )}
              {campaignItem.status === CampaignStatusEnum.ACTIVE && (
                <>
                  <Button variant="contained" color="secondary" onClick={() => onSubmit(CampaignStatusEnum.ACTIVE)}>
                    {Translation('app.button.update')}
                  </Button>
                  <Button
                    style={{ marginLeft: 25 }}
                    variant="contained"
                    color="secondary"
                    onClick={() => onSubmit(CampaignStatusEnum.PAUSED)}
                  >
                    {Translation('app.button.pauseCampaign')}
                  </Button>
                </>
              )}
              {campaignItem.status === CampaignStatusEnum.PAUSED && (
                <>
                  <Button variant="contained" color="secondary" onClick={() => onSubmit(CampaignStatusEnum.ACTIVE)}>
                    {Translation('app.button.activateCampaign')}
                  </Button>
                  <Button
                    style={{ marginLeft: 25 }}
                    variant="contained"
                    color="secondary"
                    onClick={() => onSubmit(CampaignStatusEnum.CANCELED)}
                  >
                    {Translation('app.button.cancelCampaign')}
                  </Button>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CampaignForm;
