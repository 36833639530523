import { PopperPlacementType } from "@mui/material";
import React, { ReactElement, useState } from "react";
import RichTooltip from "./RichTooltip";

interface Props {
  content: ReactElement | (() => ReactElement);
  children: ReactElement;
  disabled?: boolean;
  arrow?: boolean;
  fontColor?: string;
  backgroundColor?: string;
  placement?: PopperPlacementType;
  open: boolean;
  setOpen?: (data: boolean) => void;
}

const ClickableRichTooltip = ({
  placement,
  arrow,
  fontColor,
  backgroundColor,
  content,
  children,
  disabled = false,
  open = false,
  setOpen
}: Props) => {

  if (disabled) {
    return React.cloneElement(children, { ...children.props, disabled: true });
  }

  const existingOnClick = children.props.onClick;
  const contentNode = typeof content === "function" ? content() : content;

  return (
    <RichTooltip
      open={open}
      onClose={() => { if (setOpen) { setOpen(false) } }}
      arrow={arrow}
      fontColor={fontColor}
      backgroundColor={backgroundColor}
      placement={placement}
      content={contentNode}
    >
      {React.cloneElement(children, { ...children.props })}
    </RichTooltip>
  );
};

export default ClickableRichTooltip;
