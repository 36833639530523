import { makeStyles } from 'tss-react/mui';
import { DOUBLE_FIELD_CONTAINER_WIDTH } from 'src/app/modules/event-v2/pages/event-list/form/common';

const CONTENT_PADDING = 40;
const CONTENT_WIDTH = 680;

export const useStyles = makeStyles()(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: CONTENT_PADDING,
    overflowY: 'auto',
  },
  subSessionContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '20px 0',
    margin: '0 -35px 16px',
    border: '1px solid #000000',
  },
  accordionContainer: {
    width: CONTENT_WIDTH,
    marginBottom: 32,
  },
  accordion: {
    width: '100%',
  },
  sectionContainer: {
    width: DOUBLE_FIELD_CONTAINER_WIDTH,
  },
  remarkMargin: {
    margin: '10px 0 20px',
  },
  subSessionDeleteContainer: {
    position: 'absolute',
    top: '50%',
    right: -40,
  },
  deleteIcon: {
    color: 'black',
  },
  footerContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 20,
  },
}));
