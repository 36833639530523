import { Dispatch } from 'react';
import { getDayStart } from 'src/app/common/utils';
import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import { forEach } from 'lodash';
import { PaginateList } from 'src/app/common/types/common-types';
import { RecruitGenericItem } from '../types/recruit-generic-types';
import { PersonalParticularItem } from '../types/personal-particular-types';

const personalParticularEndpoint = 'recruit-personal-particular';

export type FollowUpCaseListParam = {
  documentUploadDate: Date | null;
  appStatus: string;
  page: number;
  limit: number;
};

export const fetchFollowUpCaseList = async (
  params: FollowUpCaseListParam,
  dispatch?: Dispatch<any>,
): Promise<RecruitGenericItem<PaginateList<PersonalParticularItem>>> => {
  let queryPath = `${ADMIN_URL}/${personalParticularEndpoint}?`;
  forEach(params, (param, key) => {
    if (typeof param === 'object') {
      if (param) {
        if (key.toLowerCase().includes('date')) {
          queryPath += `${key}=${encodeURIComponent(getDayStart(param).toISOString())}&`;
        }
      }
    } else {
      if (key === 'page') {
        queryPath += `${key}=${encodeURIComponent(param as any)}&`;
      } else {
        queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
      }
    }
  });
  return apiClient
    .get<RecruitGenericItem<PaginateList<PersonalParticularItem>>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
