import React, { FC, useState } from 'react';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { resultsBasePath } from './results';
import { dateFormatToLocal, formatIsPresent, utcToLocalDate } from '../../../utils';
import { TimeSlotsListItem, PaginateList } from '../../../types/license-exam-types';
import { useLang } from 'src/app/i18n';
type TimeSlotsListProps = {
  isLoading: boolean;
  timeSlotsList?: PaginateList<TimeSlotsListItem>;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
  onSort: (sort: any) => void;
};

const ResultsList: FC<TimeSlotsListProps> = ({ isLoading, timeSlotsList, onRefresh, onChangePage, onSort }) => {
  const history = useHistory();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const locale = useLang();

  return (
    <>
      <PruTable
        disableBulkSelect
        isLoading={isLoading}
        onRefresh={onRefresh}
        onChangePage={onChangePage}
        title={Translation('recruitment.exam.results.title')}
        operationDef={[]}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('recruitment.exam.results.add'),
            onClick: () => history.push(`${resultsBasePath}/upload`),
          },
        ]}
        columnDef={[
          {
            isId: true,
            hidden: true,
            keyIndex: 'id',
            displayName: '',
            renderData: () => '',
          },
          {
            keyIndex: 'examType',
            displayName: `${Translation('recruitment.exam.examType')}`,
            renderData: (row) => `${row.examType}` || '-',
          },
          {
            keyIndex: 'examName',
            displayName: `${Translation('recruitment.exam.examName')}`,
            renderData: (row) => `${row.examName?.[locale] || row.examName?.['en']}` || '-',
          },
          {
            keyIndex: 'examCode',
            displayName: `${Translation('recruitment.exam.examCode')}`,
            renderData: (row) => `${row.examCode}` || '-',
          },
          {
            keyIndex: 'candidateName',
            displayName: `${Translation('recruitment.exam.candidateName')}`,
            renderData: (row) => `${row.candidateName}` || '-',
          },
          {
            keyIndex: 'candidateId',
            displayName: `${Translation('recruitment.exam.candidateId')}`,
            renderData: (row) => `${row.candidateId}` || '-',
          },
          {
            keyIndex: 'examDate',
            displayName: `${Translation('recruitment.exam.examDate')}`,
            renderData: (row) => `${dateFormatToLocal(row.examDate)}` || '-',
            sortable: true,
            onSort: (sort) => {
              onSort({ key: 'EXAM_DATE', value: sort['examDate']?.toLocaleUpperCase() });
            },
          },
          {
            keyIndex: 'examCentre',
            displayName: `${Translation('recruitment.exam.examCentre')}`,
            renderData: (row) => `${row.examCentre}` || '-',
          },
          {
            keyIndex: 'isPresent',
            displayName: `${Translation('recruitment.exam.isPresent')}`,
            renderData: (row) => `${formatIsPresent(row.isPresent)}` || '-',
          },
          {
            keyIndex: 'result',
            displayName: `${Translation('recruitment.exam.examResults')}`,
            renderData: (row) => `${row.result}` || '-',
          },
          {
            keyIndex: 'lastUpdatedAt',
            displayName: `${Translation('recruitment.common.lastUpdatedAt')}`,
            renderData: (row) => `${utcToLocalDate(row.lastUpdatedAt)}` || '-',
            sortable: true,
            onSort: (sort) => {
              onSort({ key: 'LAST_UPDATED_AT', value: sort['lastUpdatedAt']?.toLocaleUpperCase() });
            },
          },
          {
            keyIndex: 'lastUpdatedBy',
            displayName: `${Translation('recruitment.common.lastUpdatedBy')}`,
            renderData: (row) => `${row.lastUpdatedBy}` || '-',
          },
        ]}
        dataSource={timeSlotsList?.data as any[]}
        totalPages={timeSlotsList?.totalPages}
        totalRecords={timeSlotsList?.totalNumbers}
      />
    </>
  );
};

export default ResultsList;
