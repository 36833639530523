import React, { FC, memo, useMemo } from 'react';
import { CircularProgress } from '@mui/material';

export const LoadingComponent: React.FC = memo(() => {
  return (
    <div className="tw-flex tw-justify-center tw-items-center">
      <CircularProgress size="2rem" color="secondary" />
    </div>
  );
});
