import { Dispatch } from 'react';
import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import { forEach } from 'lodash';
import { RecruitGenericItem } from '../types/recruit-generic-types';
import { AmdEmailItem } from '../types/amd-email-types';
import { PaginateList } from 'src/app/common/types/common-types';

const amdEmailEndpoint = 'amd-email';

export type AmdEmailListParam = {
  page: number;
  limit: number;
};

export type CreateAmdEmailBody = {
  name: string;
  email: string;
};

export type UpdateAmdEmailBody = {
  name: string;
  email: string;
};

export const fetchAmdEmailList = async (
  params: AmdEmailListParam,
  dispatch?: Dispatch<any>,
): Promise<RecruitGenericItem<PaginateList<AmdEmailItem>>> => {
  let queryPath = `${ADMIN_URL}/${amdEmailEndpoint}?`;
  forEach(params, (param, key) => {
    if (key === 'page') {
      queryPath += `${key}=${encodeURIComponent(param as any)}&`;
    } else {
      queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
    }
  });
  return apiClient
    .get<RecruitGenericItem<PaginateList<AmdEmailItem>>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchAmdEmail = async (
  id: string,
  dispatch?: Dispatch<any>,
): Promise<RecruitGenericItem<AmdEmailItem>> => {
  return apiClient
    .get<RecruitGenericItem<AmdEmailItem>>(`${ADMIN_URL}/${amdEmailEndpoint}/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createAmdEmail = async (body: CreateAmdEmailBody, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .post<void>(`${ADMIN_URL}/${amdEmailEndpoint}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const modifyAmdEmail = async (id: string, body: UpdateAmdEmailBody, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .put<void>(`${ADMIN_URL}/${amdEmailEndpoint}/${id}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const deleteAmdEmail = async (id: string, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .delete<void>(`${ADMIN_URL}/${amdEmailEndpoint}/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
