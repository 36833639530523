export const APPLICATION_BASE_PATH = '/application';

export const DATE_ERROR_TEXT = 'End date must be after Begin date';

export const MANDATORY_FIELD_ERROR_TEXT = 'This field is mandatory';
export const CHOOSE_ONE_ERROR_TEXT = 'One of the fields must be filled';

export const DATE_FORMAT_ERROR_TEXT = 'Invalid date format';

//  These constants map to aside menu, header menu to display title
export const APPLICATION_TITLE = "title.application";

//  These constants map to permission control, identical to permission enum defined below
export const APPLICATION_NAMESPACE = "APPLICATION";

export enum AdminApplicationPermissionType {
  APPLICATION_READ = "READ_ANY_APPLICATION",
  APPLICATION_CREATE = "CREATE_ANY_APPLICATION",
  APPLICATION_UPDATE = "UPDATE_ANY_APPLICATION"
};