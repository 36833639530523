import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { PaginateList } from 'src/app/common/types/common-types';
import { BulkUploadFailedItem } from 'src/app/modules/PulseLeads/types/bulk-upload-types';

type BulkUploadFailedListProps = {
  isLoading: boolean;
  bulkUploadFailedList?: PaginateList<BulkUploadFailedItem>
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
};

const BulkUploadFailedList: FC<BulkUploadFailedListProps> = ({
  isLoading,
  bulkUploadFailedList,
  onRefresh,
  onChangePage,
}) => {
  const history = useHistory();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  return (
    <>
      <PruTable
        title={Translation("pulseleads.bulkUpload.common.failedList")}
        disableBulkSelect
        operationDef={[]}
        columnDef={[
          {
            isId: true,
            hidden: true,
            keyIndex: "_id",
            displayName: "",
            renderData: (row) => ""
          },
          {
            keyIndex: "csvRowNumber",
            displayName: Translation("pulseleads.bulkUpload.common.rowNumber"),
            renderData: (row) => row.csvRowNumber ? row.csvRowNumber.toString() : "-"
          },
          {
            keyIndex: "failureReason",
            displayName: Translation("pulseleads.bulkUpload.common.failureReason"),
            renderData: (row) => row.failureReason || "-"
          },
        ]}
        isLoading={isLoading}
        onRefresh={onRefresh}
        dataSource={bulkUploadFailedList?.docs}
        totalPages={bulkUploadFailedList?.totalPages}
        totalRecords={bulkUploadFailedList?.totalDocs}
        onChangePage={onChangePage}
      />
    </>
  );
}

export default BulkUploadFailedList;