import { IntlShape } from 'react-intl';
import { ModuleHierachy } from '../../common/module/types';
import {
  AdminLeadMicrositePermissionType,
  LEAD_MICROSITE_BASE_PATH,
  LEAD_MICROSITE_TITLE,
  MICROSITE_BASE_PATH,
  MICROSITE_NAMESPACE,
  MICROSITE_TITLE,
} from './constants';
import { isPermitted } from 'src/app/common/utils';
import MicrositeRoutes from './pages/microsite/microsite-routes';
export const leadMicrositeModuleConfig = (permissions: string[], intl: IntlShape): ModuleHierachy => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return {
    title: Translation(LEAD_MICROSITE_TITLE),
    path: LEAD_MICROSITE_BASE_PATH,
    icon: '/media/svg/icons/Files/Share.svg',
    enableRead: isPermitted(
      [
        AdminLeadMicrositePermissionType.MICROSITE_CREATE,
        AdminLeadMicrositePermissionType.MICROSITE_READ,
        AdminLeadMicrositePermissionType.MICROSITE_UPDATE,
      ],
      permissions,
    ),
    child: [
      {
        title: Translation(MICROSITE_TITLE),
        path: `${LEAD_MICROSITE_BASE_PATH}${MICROSITE_BASE_PATH}`,
        namespace: MICROSITE_NAMESPACE,
        component: MicrositeRoutes,
        enableRead: isPermitted(
          [
            AdminLeadMicrositePermissionType.MICROSITE_CREATE,
            AdminLeadMicrositePermissionType.MICROSITE_READ,
            AdminLeadMicrositePermissionType.MICROSITE_UPDATE,
          ],
          permissions,
        ),
        enableCreate: isPermitted([AdminLeadMicrositePermissionType.MICROSITE_CREATE], permissions),
        enableUpdate: isPermitted([AdminLeadMicrositePermissionType.MICROSITE_UPDATE], permissions),
      },
    ],
  };
};
