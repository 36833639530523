import React from "react";
import { Button } from '@mui/material';

const filterConfig = (Translation: any, onSearch: any, onReset: any) => {
    return [
        [
            { type: 'header', label: Translation('title.mandatory.training.category.filter'), sm: true },
            {
                type: 'custom',
                sm: false,
                render: () =>
                    <Button variant="contained" color="inherit"  onClick={onReset}>
                        {Translation('golbal.filters.reset')}
                    </Button>
            },
            {
                type: 'custom',
                sm: false,
                render: () =>
                    <Button variant="contained" color="secondary" onClick={onSearch} >
                        {Translation('golbal.filters.search')}
                    </Button>
            },
        ],
        [
            {
                type: 'select', label: Translation('title.event.category.table.header.status'), prop: 'status', opts: [
                    { label: Translation('report.recruitment.summary.all'), value: '' },
                    { label: Translation('component.formSelectItem.active'), value: 'active' },
                    { label: Translation('component.formSelectItem.inactive'), value: 'inactive' },
                ]
            },
        ]
    ];
};

export default filterConfig;