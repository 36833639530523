import { makeStyles } from 'tss-react/mui';

export const styles = makeStyles()((theme) => ({
  divideMargin: {
    marginBottom: 10,
  },
  materialName: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '10px',
    paddingLeft: 0,
  },
  subText: {
    fontSize: '14px',
    fontWeight: 400,
    marginLeft: '10px',
  },
  mandatory: {
    color: 'red',
    fontSize: '14px',
    fontWeight: 400,
  },
  closeIcon: {
    marginLeft: '5px',
    verticalAlign: 'middle',
  },
  group: {
    margin: '0 10px',
    marginRight: 0,
  },
  radio: {
    margin: 0,
    marginRight: 10,
  },
}));
