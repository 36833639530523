import React, { FC } from 'react';
import { useIntl } from "react-intl";
import { makeStyles } from 'tss-react/mui';
import { Button } from '@mui/material';

type PageFooterProps = {
  disablePrevious?: boolean;
  disableContinue?: boolean;
  onPrevPage?: () => void;
  onCancel: () => void;
  onSave: (toNextPage?: boolean) => void;
}

const useStyles = makeStyles()((theme) => ({
  footerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  buttonSpace: {
    marginRight: 50
  },
}));

const PageFooter: FC<PageFooterProps> = ({ disablePrevious=false, disableContinue=false, onPrevPage=()=>{}, onCancel, onSave }) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  return (
    <div className={classes.footerContainer}>
      <Button
        className={classes.buttonSpace}
        variant="contained"
        style={{ backgroundColor: 'white' }}
        onClick={() => onCancel()}
      >
        {Translation("app.button.cancel")}
      </Button>
      {!disablePrevious &&
        <Button
          className={classes.buttonSpace}
          variant="contained"
          style={{ backgroundColor: 'white' }}
          onClick={() => onPrevPage()}
        >
          {Translation("agencyCampaign.common.previous")}
        </Button>
      }
      <Button
        className={classes.buttonSpace}
        variant="contained"
        color="secondary"
        onClick={() => onSave()}
      >
        {Translation("agencyCampaign.common.save")}
      </Button>
      {!disableContinue &&
        <Button
          variant="contained"
          color="secondary"
          onClick={() => onSave(true)}
        >
          {Translation("agencyCampaign.common.saveAndContinue")}
        </Button>
      }
    </div>
  );
}

export default PageFooter;