export enum EligibilityRuleFormMode {
  CREATE = "CREATE",
  EDIT = "EDIT"
}

export enum EligibilityAgentType {
  ALL_AGENTS = "all",
  PARTIAL_AGENTS = "part",
  ROOKIE_AGENTS = "new",
  AGENCY = "agency",
}

export enum EligibilityRuleType {
  // 20210804 - R1 Descope
  // ATTEND_PRULEADS_TRAINING = "attendPruleadsTraining",
  // AGREED_TO_PRULEADS_SLA = "agreedToPruleadsSla",
  // DISCIPLINARY_RECORDS = "disciplinaryRecords",
  // DESIGNATION_OF_AGENT = "designationOfAgent"
}

export type EligibilityRuleNewAgent = {
  name: string;
  startDaySinceHired: number;
  endDaySinceHired: number;
  targetWeeklyLeads: number;
  priority: number;
}

export type EligibilityRuleOperator = {
  type: string;
  operator: string;
  priority?: number;
  value?: string | string[];
}

export type EligibilityRule = {
  _id: string;
  name: string;
  description?: string;
  agentType: string;
  PRULeadsTraining: boolean;
  newAgentRules?: EligibilityRuleNewAgent[];
  rules: EligibilityRuleOperator[];
  listOfAgents?: string[];
  createdBy: string;
  updatedBy: string;
  createdAt: Date;
  updatedAt: Date;
}