import React, { FC, useState, useEffect, useMemo } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { LayoutSplashScreen } from 'src/app/layout';
import {
  CampaignFeedbackFormMode,
  CampaignFeedbackGroupItem,
} from 'src/app/modules/PulseLeads/types/campaign-feedback-types';
import { CampaignDropdownType, CampaignDropdownList } from 'src/app/modules/PulseLeads/types/campaign-types';
import { fetchCampaignFeedbackGroupItem } from 'src/app/modules/PulseLeads/network/campaignFeedbackCrud';
import { fetchCampaignDropdownList, CampaignDropdownListParam } from 'src/app/modules/PulseLeads/network/campaignCrud';
import CampaignFeedbackGroupForm from './components/CampaignFeedbackGroupForm';

const CampaignFeedbackGroupDetailPage: FC<RouteComponentProps> = ({ history }) => {
  const { id } = useParams<{ id: string }>();
  const { formMode } = useMemo(
    () => ({
      formMode: id ? CampaignFeedbackFormMode.EDIT : CampaignFeedbackFormMode.CREATE,
    }),
    [id],
  );

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [campaignFeedbackGroupItem, setCampaignFeedbackGroupItem] = useState<CampaignFeedbackGroupItem>();
  const [feedbackDropdownList, setFeedbackDropdownList] = useState<CampaignDropdownList>();

  const reloadData = async () => {
    if (id) {
      setIsLoading(true);
      try {
        const res = await fetchCampaignFeedbackGroupItem(id, dispatch);
        setCampaignFeedbackGroupItem(res);
      } catch (err) {
        history.push(NOT_FOUND_ERROR_PAGE_PATH);
      }
    }
    try {
      const params: CampaignDropdownListParam = {
        type: [CampaignDropdownType.FEEDBACK],
      };
      const dropdownListRes = await fetchCampaignDropdownList(params, dispatch);
      setFeedbackDropdownList(dropdownListRes);
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line
  }, [id]);

  return (
    <>
      {formMode === CampaignFeedbackFormMode.CREATE && (
        <CampaignFeedbackGroupForm formMode={formMode} feedbackDropdownList={feedbackDropdownList} />
      )}
      {formMode === CampaignFeedbackFormMode.EDIT &&
        (isLoading || !campaignFeedbackGroupItem ? (
          <LayoutSplashScreen />
        ) : (
          <CampaignFeedbackGroupForm
            formMode={formMode}
            campaignFeedbackGroupItem={campaignFeedbackGroupItem}
            feedbackDropdownList={feedbackDropdownList}
          />
        ))}
    </>
  );
};

export default CampaignFeedbackGroupDetailPage;
