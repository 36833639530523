import React, { FC, useReducer } from 'react';
import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import {
  DistributionRuleFormMode,
  DistributionRule,
  DistributionRuleItem,
  DistributionRuleType,
  DistributionRulePriority,
  OpenLeadOptionEnum,
} from 'src/app/modules/PulseLeads/types/distribution-rule-types';
import { makeStyles } from 'tss-react/mui';
import {
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Tooltip,
} from '@mui/material';
import { Info } from '@mui/icons-material';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { distributionRulePath } from '../../DistributionRuleRoutes';
import { ErrorFieldType, useErrorHandler } from 'src/app/common/utils';
import { MANDATORY_FIELD_ERROR_TEXT } from 'src/app/common/constants';
import { map } from 'lodash';
import {
  CreateDistributionRuleBody,
  UpdateDistributionRuleBody,
  createDistributionRule,
  modifyDistributionRule,
} from 'src/app/modules/PulseLeads/network/distributionRuleCrud';
import { useDispatch, useSelector } from 'react-redux';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import { RootState } from 'src/redux/store';
import { AuthenticationState } from 'src/app/modules/Auth/_redux/authSlice';
import { RuleOperator } from 'src/app/modules/PulseLeads/enum/rule-enum';
import { SAME_PRIORITY_ERROR_TEXT, AT_MOST_THREE_RULES_ERROR_TEXT } from 'src/app/modules/PulseLeads/constants';

type DistributionRuleFormProps = {
  formMode: DistributionRuleFormMode;
  distributionRule?: DistributionRule;
};

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  fieldContainer: {
    width: 160,
    boxSizing: 'border-box',
  },
  innerFieldContainer: {
    width: 140,
    boxSizing: 'border-box',
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },
  sectionMargin: {
    marginBottom: 15,
  },
  divideMargin: {
    marginBottom: 10,
  },
  subHeader: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
  },
  textAreaRowContainer: {
    width: '100%',
    display: 'flex',
  },
  textAreaFieldContainer: {
    paddingTop: 15,
    minWidth: 160,
    boxSizing: 'border-box',
  },
  textArea: {
    lineHeight: 1.5,
    minHeight: 40,
  },
  errorText: {
    fontSize: 9,
    color: '#F018A6',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));

type DistributionRuleFormState = {
  name?: string;
  method?: string;
  description?: string;
  openLeadOption?: OpenLeadOptionEnum;
  rules: DistributionRuleItem[];
  priorityOptions: number[];
};

const initialState: DistributionRuleFormState = {
  name: undefined,
  //  TODO: Remove hardcode after implementing more methods
  method: 'roundRobin',
  description: undefined,
  openLeadOption: OpenLeadOptionEnum.ALL_CAMPAIGN,
  rules: map(DistributionRuleType, (type) => ({
    type,
    operator: RuleOperator.EXCLUDE,
  })),
  priorityOptions: [],
};

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof DistributionRuleFormState;
    value: any;
  };
};

type ModifyRuleItemAction = {
  type: 'MODIFY_RULE_ITEM';
  payload: {
    field: keyof DistributionRuleItem;
    index: number;
    value: any;
    increPriorityOptions?: boolean;
  };
};

type DistributionRuleFormAction = ModifyFieldAction | ModifyRuleItemAction;

const formReducer = (
  state: DistributionRuleFormState,
  action: DistributionRuleFormAction,
): DistributionRuleFormState => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    case 'MODIFY_RULE_ITEM':
      let item = state.rules[action.payload.index];
      //@ts-ignore
      item[action.payload.field] = action.payload.value;
      if (action.payload.field === 'priority' && !!action.payload.value) {
        item[action.payload.field] = Number(item[action.payload.field]);
      }
      if (action.payload.field === 'operator') {
        if (action.payload.value === RuleOperator.EXCLUDE) {
          for (const ruleItem of state.rules) {
            ruleItem.priority = undefined;
          }
          state.priorityOptions.pop();
        } else if (action.payload.value === RuleOperator.INCLUDE && action.payload.increPriorityOptions) {
          state.priorityOptions.push(state.priorityOptions.length + 1);
        }
      }
      return { ...state };
    default:
      return state;
  }
};

const detailToStateConvertor = (distributionRule: DistributionRule): DistributionRuleFormState => {
  const rules = distributionRule.rules || [];
  const priorityOptions: number[] = [];
  rules.forEach((rule) => {
    if (rule?.priority) {
      priorityOptions.push(rule.priority);
    }
  });
  priorityOptions.sort();
  return {
    name: distributionRule.name,
    //  TODO: Remove hardcode after implementing more methods
    method: 'roundRobin',
    description: distributionRule.description,
    openLeadOption: distributionRule.openLeadOption ?? OpenLeadOptionEnum.ALL_CAMPAIGN,
    rules: map(DistributionRuleType, (type) => {
      const correspondingRule = rules.find((rule) => rule.type === type);
      return (
        correspondingRule ?? {
          type,
          operator: RuleOperator.EXCLUDE,
        }
      );
    }),
    priorityOptions,
  };
};

const checkIfPriorityDuplicate = (rules: DistributionRuleItem[]): boolean => {
  let obj: Record<string, boolean> = {};
  let flag = false;
  rules.forEach((rule) => {
    if (rule.priority !== undefined) {
      let key: string = `${rule.priority}`;
      if (obj[key] !== undefined) {
        flag = true;
      } else {
        obj[key] = true;
      }
    }
  });
  return flag;
};

const DistributionRuleForm: FC<DistributionRuleFormProps> = ({ formMode, distributionRule }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { classes: commonClasses } = useCommonStyles();
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { user } = useSelector<RootState, AuthenticationState>((state) => state.auth);

  const [formState, formDispatch] = useReducer(
    formReducer,
    distributionRule ? detailToStateConvertor(distributionRule) : initialState,
  );

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler } = useErrorHandler(formState, [
    {
      name: 'name',
      fieldType: ErrorFieldType.MANDATORY,
    },
    {
      name: 'method',
      fieldType: ErrorFieldType.MANDATORY,
    },
    {
      name: 'openLeadOption',
      fieldType: ErrorFieldType.MANDATORY,
    },
    {
      name: 'samePriority',
      fieldType: ErrorFieldType.MANDATORY,
      condition: () => {
        return checkIfPriorityDuplicate(formState.rules);
      },
    },
    {
      name: 'atMostThreeRules',
      fieldType: ErrorFieldType.MANDATORY,
      condition: () => {
        return formState.rules.filter((item) => item.operator !== RuleOperator.EXCLUDE).length > 3;
      },
    },
    ...map(DistributionRuleType, (type) => ({
      name: `priority-${type}`,
      fieldType: ErrorFieldType.MANDATORY,
      condition: () => {
        const foundItem = formState.rules.find((item) => item.type === type);
        return !!(foundItem && foundItem.operator !== RuleOperator.EXCLUDE && !foundItem.priority);
      },
    })),
  ]);

  const onSubmit = async () => {
    const { hasError } = onSubmitErrorValidator();
    if (!hasError) {
      if (formMode === DistributionRuleFormMode.CREATE) {
        const body: CreateDistributionRuleBody = {
          name: formState.name || '',
          method: formState.method || '',
          description: formState.description,
          openLeadOption: formState.openLeadOption,
          rules: formState.rules,
          createdBy: user?.username || 'Default',
          updatedBy: user?.username || 'Default',
        };
        try {
          await createDistributionRule(body, dispatch);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: 'Distribution Rule saved successfully',
              },
            ]),
          );
          history.push(distributionRulePath);
        } catch (err) {}
      } else if (distributionRule) {
        const body: UpdateDistributionRuleBody = {
          method: formState.method || '',
          description: formState.description,
          openLeadOption: formState.openLeadOption,
          rules: formState.rules,
          updatedBy: user?.username || 'Default',
        };
        try {
          await modifyDistributionRule(distributionRule._id, body, dispatch);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: `Distribution Rule updated successfully - ${distributionRule._id}`,
              },
            ]),
          );
          history.push(distributionRulePath);
        } catch (err) {}
      }
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <div className={classes.rowContainer}>
          <div className={commonClasses.header}>
            {Translation(
              `pulseleadsDistributionRule.form.title.${
                formMode === DistributionRuleFormMode.CREATE ? 'create' : 'edit'
              }`,
            )}
          </div>
        </div>
        <Button variant="contained" color="inherit" onClick={() => history.push(distributionRulePath)}>
          {Translation('app.button.back')}
        </Button>
      </div>

      <div className={classes.sectionMargin}>
        <div className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>
              {Translation('pulseleads.distributionRule.common.name')}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <div style={{ flexGrow: 1 }}>
            <TextField
              style={{ width: 250 }}
              disabled={formMode === DistributionRuleFormMode.EDIT}
              error={errorState.mandatory.name}
              margin="dense"
              variant="outlined"
              helperText={errorState.mandatory.name && MANDATORY_FIELD_ERROR_TEXT}
              value={formState.name}
              onChange={(e) => {
                onDismissErrorHandler('name', e.target.value);
                formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'name', value: e.target.value } });
              }}
            />
          </div>
        </div>
        <div className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>
              {Translation('pulseleads.distributionRule.common.method')}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <div style={{ flexGrow: 1 }}>
            <TextField
              disabled
              style={{ width: 250 }}
              error={errorState.mandatory.method}
              margin="dense"
              variant="outlined"
              helperText={errorState.mandatory.method && MANDATORY_FIELD_ERROR_TEXT}
              value={formState.method}
              onChange={(e) => {
                onDismissErrorHandler('method', e.target.value);
                formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'method', value: e.target.value } });
              }}
            />
          </div>
        </div>
        <div className={classes.textAreaRowContainer}>
          <div className={classes.textAreaFieldContainer}>
            <span className={classes.field}>{Translation('pulseleads.distributionRule.common.description')} :</span>
          </div>
          <div style={{ flexGrow: 1 }}>
            <TextField
              fullWidth
              multiline
              margin="dense"
              variant="outlined"
              value={formState.description}
              InputProps={{
                classes: {
                  input: classes.textArea,
                },
              }}
              onChange={(e) => {
                formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'description', value: e.target.value } });
              }}
            />
          </div>
        </div>
        <div className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>
              {Translation('pulseleads.distributionRule.common.openLeadForLeadCampaign')}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <div style={{ flexGrow: 1 }}>
            <FormControl error={errorState.mandatory.openLeadOption} margin="dense" variant="outlined">
              <div>
                <Select
                  style={{ minWidth: 250, marginRight: 10 }}
                  value={formState.openLeadOption || ''}
                  onChange={(e) => {
                    onDismissErrorHandler('openLeadOption', e.target.value);
                    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'openLeadOption', value: e.target.value } });
                  }}
                >
                  {Object.values(OpenLeadOptionEnum).map((value) => (
                    <MenuItem key={value} value={value}>
                      {Translation(`pulseleads.distributionRule.common.openLeadForLeadCampaign.${value}`)}
                    </MenuItem>
                  ))}
                </Select>
                <Tooltip
                  arrow
                  title={
                    <span style={{ fontSize: 10 }}>
                      {intl.formatMessage(
                        { id: 'pulseleads.distributionRule.common.openLeadForLeadCampaign.tooltip' },
                        { br: <br /> },
                      )}
                    </span>
                  }
                >
                  <Info style={{ color: 'grey' }} />
                </Tooltip>
              </div>
              {errorState.mandatory.openLeadOption && <FormHelperText>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>}
            </FormControl>
          </div>
        </div>
      </div>

      {map(DistributionRuleType, (type) => {
        const foundIndex = formState.rules.findIndex((item) => item.type === type);
        return (
          foundIndex !== -1 && (
            <div className={classes.divideMargin}>
              <span className={classes.subHeader}>
                {Translation(`pulseleads.distributionRule.type.${formState.rules[foundIndex].type}`)}
              </span>
              <div style={{ padding: 10 }}>
                <div className={classes.rowContainer}>
                  <div className={classes.innerFieldContainer}>
                    <span className={classes.field}>{Translation('common.operator.include')} :</span>
                  </div>
                  <div>
                    <FormControlLabel
                      style={{ margin: '0 30px 0 0' }}
                      control={
                        <Checkbox
                          icon={<CircleUnchecked />}
                          checkedIcon={<CircleCheckedFilled />}
                          checked={formState.rules[foundIndex].operator !== RuleOperator.EXCLUDE}
                          onChange={(e) => {
                            if (e.target.checked) {
                              formDispatch({
                                type: 'MODIFY_RULE_ITEM',
                                payload: {
                                  field: 'operator',
                                  index: foundIndex,
                                  value: RuleOperator.INCLUDE,
                                  increPriorityOptions: true,
                                },
                              });
                            }
                          }}
                        />
                      }
                      label={Translation('app.checkbox.true')}
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      style={{ margin: 0 }}
                      control={
                        <Checkbox
                          icon={<CircleUnchecked />}
                          checkedIcon={<CircleCheckedFilled />}
                          checked={formState.rules[foundIndex].operator === RuleOperator.EXCLUDE}
                          onChange={(e) => {
                            if (e.target.checked) {
                              onDismissErrorHandler(
                                'atMostThreeRules',
                                formState.rules.filter((item) => item.operator === RuleOperator.INCLUDE).length - 1 <=
                                  3,
                              );
                              formDispatch({
                                type: 'MODIFY_RULE_ITEM',
                                payload: { field: 'operator', index: foundIndex, value: RuleOperator.EXCLUDE },
                              });
                            }
                          }}
                        />
                      }
                      label={Translation('app.checkbox.false')}
                      labelPlacement="end"
                    />
                    {errorState.mandatory['atMostThreeRules'] &&
                      formState.rules[foundIndex].operator !== RuleOperator.EXCLUDE && (
                        <div style={{ marginLeft: 14, marginBottom: 5 }}>
                          <FormHelperText error>{AT_MOST_THREE_RULES_ERROR_TEXT}</FormHelperText>
                        </div>
                      )}
                  </div>
                </div>
                {formState.rules[foundIndex].operator !== RuleOperator.EXCLUDE && (
                  <>
                    <div className={classes.rowContainer} style={{ marginTop: 4 }}>
                      <div className={classes.innerFieldContainer}>
                        <span className={classes.field}>{Translation('pulseleads.distributionRule.priority')} :</span>
                      </div>
                      <FormControl
                        error={errorState.mandatory[`priority-${type}`] || errorState.mandatory.samePriority}
                        style={{ margin: '0 0 0 8px' }}
                        margin="dense"
                        variant="outlined"
                      >
                        <Select
                          style={{ minWidth: 200 }}
                          value={formState.rules[foundIndex].priority || ''}
                          onChange={(e) => {
                            const dismissArr = (() => {
                              let keyArr: string[] = [];
                              if (e.target.value) {
                                keyArr.push(`priority-${type}`);
                              }
                              if (checkIfPriorityDuplicate(formState.rules)) {
                                keyArr.push('samePriority');
                              }
                              return keyArr;
                            })();
                            onDismissErrorHandler(dismissArr, true);
                            formDispatch({
                              type: 'MODIFY_RULE_ITEM',
                              payload: { field: 'priority', index: foundIndex, value: e.target.value },
                            });
                          }}
                        >
                          {map(formState.priorityOptions, (option: number) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                        {errorState.mandatory[`priority-${type}`] && (
                          <FormHelperText>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>
                        )}
                        {errorState.mandatory['samePriority'] && (
                          <FormHelperText>{SAME_PRIORITY_ERROR_TEXT}</FormHelperText>
                        )}
                      </FormControl>
                    </div>
                    <div className={classes.rowContainer} style={{ marginTop: 4 }}>
                      <div className={classes.innerFieldContainer}>
                        <span className={classes.field}>{Translation('common.operator.mandatory')} :</span>
                      </div>
                      <FormControlLabel
                        style={{ margin: '0 30px 0 0' }}
                        control={
                          <Checkbox
                            icon={<CircleUnchecked />}
                            checkedIcon={<CircleCheckedFilled />}
                            checked={formState.rules[foundIndex].operator === RuleOperator.MANDATORY}
                            onChange={(e) => {
                              if (e.target.checked) {
                                formDispatch({
                                  type: 'MODIFY_RULE_ITEM',
                                  payload: { field: 'operator', index: foundIndex, value: RuleOperator.MANDATORY },
                                });
                              }
                            }}
                          />
                        }
                        label={Translation('app.checkbox.true')}
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        style={{ margin: 0 }}
                        control={
                          <Checkbox
                            icon={<CircleUnchecked />}
                            checkedIcon={<CircleCheckedFilled />}
                            checked={formState.rules[foundIndex].operator === RuleOperator.INCLUDE}
                            onChange={(e) => {
                              if (e.target.checked) {
                                formDispatch({
                                  type: 'MODIFY_RULE_ITEM',
                                  payload: { field: 'operator', index: foundIndex, value: RuleOperator.INCLUDE },
                                });
                              }
                            }}
                          />
                        }
                        label={Translation('app.checkbox.false')}
                        labelPlacement="end"
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          )
        );
      })}

      <div className={classes.footerContainer}>
        <Button variant="contained" color="secondary" onClick={onSubmit}>
          {Translation('app.button.submit')}
        </Button>
      </div>
    </div>
  );
};

export default DistributionRuleForm;
