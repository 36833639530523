import React, { useReducer, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
// import ClickableRichTooltip from '../../../../../../../common/components/RichTooltip/ClickableRichTooltip';
import { toAbsoluteUrl } from '../../../../../common/utils';
import AsyncCsvLink from 'src/app/common/components/AsyncCsvLink';
import { downloadParticipantUploadCsv } from 'src/app/modules/AgencyCampaign/network/staffCampaignCrud';
import { verifyNonDownlineList } from 'src/app/modules/AgencyCampaign/network/approvalCrud';

import {
  AgentItem,
  AgentListParam,
  AgentPaginateList,
  ImportVerify,
} from 'src/app/modules/AgencyCampaign/types/staff-campaign-types';
import { useDispatch } from 'react-redux';
import { useCommonStyles } from 'src/app/common/styles/common-styles';

const useStyles = makeStyles()((theme) => ({
  menuItem: {
    display: 'flex',
    flexDirection: 'row',
    height: 40,
    alignItems: 'center',
    paddingLeft: 10,
    paddingRight: 10,
    '&:hover': {
      backgroundColor: '#E7E7E7',
    },
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
    flexDirection: 'row',
  },
  searchInput: {
    height: 46,
    padding: 0,
    fontFamily: 'Poppins, Helvetica, "sans-serif"',
  },
  btnGroupWrap: { flex: 1, padding: 20, display: 'flex', flexDirection: 'row', justifyContent: 'center' },
  btnGroup: { margin: '0 10px' },
  dialogWrap: { paddingLeft: 20, paddingRight: 20, minHeight: 200, minWidth: 500 },
  importFormat: { border: '1px solid #ccc', padding: 10, borderRadius: 8, marginBottom: 20 },
}));

const agentCodeLength = 5;
interface ImportParticipantPopupProps {
  selectFromFile: boolean;
  campaignObjId: string;
  campaignTypeStructureData: any;
  doneImport: (data: AgentItem[]) => void;
  onCancel: () => void;
}

const initialState: any = {
  participantListfromFile: [],
  participantListFormatError: [],
  successImportList: [],
  successEligibilityList: [],
};

type ManualUpdateFormState = {
  filename?: string;
  participantListfromFile: any[];
  participantListFormatError: string[];
  successImportList: AgentItem[];
  successEligibilityList: AgentItem[];
};

const formReducer = (state: ManualUpdateFormState, action: ManualUpdateFormAction): ManualUpdateFormState => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    default:
      return state;
  }
};

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof ManualUpdateFormState;
    value: any;
  };
};

type ManualUpdateFormAction = ModifyFieldAction;

const ImportParticipantPopup: React.FC<ImportParticipantPopupProps> = ({
  campaignObjId,
  campaignTypeStructureData,
  doneImport,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const TranslationWithVariable = (key: string, count: number | string) =>
    intl.formatMessage({ id: key }, { num: count });
  const { classes } = useStyles();
  const { classes: commonStyle } = useCommonStyles();
  const [formState, formDispatch] = useReducer(formReducer, initialState);
  const [loading, setLoading] = useState<boolean>(false);

  const participantSectionTypeStructureData = campaignTypeStructureData.sections.filter(
    (item: any) => item.key == 'participant',
  )[0];
  const participantNameRules =
    participantSectionTypeStructureData.sectionFields.filter((item: any) => item.key == 'participantName').length > 0
      ? participantSectionTypeStructureData.sectionFields.filter((item: any) => item.key == 'participantName')[0].rules
      : [];
  const eligibilityRules: string[] =
    participantNameRules.filter((item: any) => item.key == 'eligibility').length > 0
      ? participantNameRules.filter((item: any) => item.key == 'eligibility')[0].value
      : [];

  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      let reader = new FileReader();
      reader.onload = () => {
        let csvData = String(reader.result);
        let row = csvData.split('\n');
        let participantList: ImportItem[] = [];
        let arrParticipantListFormatError: any[] = [];
        row.shift();
        row.forEach((item, index) => {
          if (!item) {
            return;
          }
          // item format: 10006,Name F,U102(AgentCode|BusinessName|UnitCode)
          let [agentCode, businessName, unitCode] = item.split(',');
          agentCode = agentCode?.trim()?.padStart(agentCodeLength, '0');
          businessName = businessName?.trim();
          unitCode = unitCode?.trim();
          participantList.push({ agentCode, businessName, unitCode });
          let err = TranslationWithVariable('agencyCampaign.import.row', index + 1);
          if (!agentCode) {
            err += Translation('agencyCampaign.import.agentcode.required');
          } else if (agentCode.length !== agentCodeLength) {
            err += TranslationWithVariable('agencyCampaign.import.agentode.invalid', agentCodeLength);
          }
          if (!businessName) {
            err += Translation('agencyCampaign.import.businessName.required');
          }
          if (!unitCode) {
            err += Translation('agencyCampaign.import.unitCode.required');
          }
          if (err.length > 10) {
            arrParticipantListFormatError.push(err);
          }
        });
        formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'participantListfromFile', value: participantList } });
        formDispatch({
          type: 'MODIFY_FIELD',
          payload: { field: 'participantListFormatError', value: arrParticipantListFormatError },
        });
      };
      if (e.target.files) {
        reader.readAsBinaryString(e.target.files[0]);
        formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'filename', value: e.target.files[0].name } });
      }
    }
  };

  const removeFile = () => {
    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'filename', value: null } });
    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'participantListfromFile', value: [] } });
  };

  const onImportFromFile = async () => {
    setLoading(true);
    const { successList } = await checkAgentImportFail(formState.participantListfromFile).catch(() => {
      setLoading(false);
      return { failList: [], successList: [] };
    });
    setLoading(false);
    doneImport(successList);
  };

  const checkAgentImportFail = async (importList: ImportItem[]): Promise<any> => {
    const arrAgentCode = importList.map((item) => item.agentCode);
    const param: ImportVerify = {
      agentCodeArr: arrAgentCode,
      campaignID: campaignObjId,
      campaignTypeID: campaignTypeStructureData._id,
      rules: eligibilityRules,
      isApprover: true,
    };
    /** only response succeed agent code */
    let { success, fail } = await verifyNonDownlineList(param, dispatch);
    const res = { failList: fail || [], successList: success || [] };
    return res;
  };

  const _renderFileUpload = () => {
    return (
      <>
        <AsyncCsvLink
          isDisabled={false}
          filename={`AgentUpload.csv`}
          dataParser={(str) => str}
          asyncCall={() => downloadParticipantUploadCsv()}
        >
          <a className={commonStyle.link}>{Translation('agencyCampaign.create.downloadTemplate')}</a>
        </AsyncCsvLink>

        <div>
          {!formState.filename && (
            <>
              <input
                id="upload-participant-list-csv"
                hidden
                type="file"
                accept=".csv"
                onClick={(e) => {
                  const element = e.target as HTMLInputElement;
                  element.value = '';
                }}
                onChange={handleFile}
              />
              <div className={classes.rowContainer}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => document.getElementById('upload-participant-list-csv')!.click()}
                >
                  {Translation('app.button.upload')}
                </Button>
              </div>
            </>
          )}
          {formState.filename && (
            <>
              <div className={classes.rowContainer}>
                <div>{formState.filename}</div>
                <div onClick={removeFile}>
                  <img
                    src={toAbsoluteUrl('/media/icon/bin.png')}
                    alt={'Select from list'}
                    style={{ width: 24, height: 24, margin: 10 }}
                  />
                </div>
              </div>
              {formState.participantListFormatError.length > 0 ? (
                <>
                  <p style={{ color: '#E8192C' }}>{Translation('agencyCampaign.import.failed')}</p>
                  <div className={classes.importFormat}>
                    {formState.participantListFormatError.map((item, index) => (
                      <p key={index}>{Translation(item)}</p>
                    ))}
                  </div>
                </>
              ) : (
                <div className={classes.rowContainer}>
                  <div
                    style={{ flex: 1, paddingTop: 50, display: 'flex', flexDirection: 'row', justifyContent: 'center' }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      disabled={loading}
                      onClick={onImportFromFile}
                    >
                      {loading ? Translation('common.loading') : Translation('app.button.import')}
                    </Button>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </>
    );
  };

  return <div className={classes.dialogWrap}>{_renderFileUpload()}</div>;
};
interface ImportItem {
  agentCode: string;
  businessName?: string;
  unitCode?: string;
  unit?: string;
  reason?: string;
}

export default ImportParticipantPopup;
