import React, { FC, HTMLAttributes, useState } from 'react';
import { withStyles } from 'tss-react/mui';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
  IconButton,
  Button,
  LinearProgress,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { NewsAttachmentItem } from '../../../../types/news-type';
import { createBlob, getBlob } from 'src/app/common/network';
import { fileUpload } from 'src/app/common/utils';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

type DisplayAttachmentsTableProps = {
  isDisabled: boolean;
  attachments: NewsAttachmentItem[];
  onAddRow: () => void;
  onDeleteRow: (index: number) => void;
  onMoveRow: (from: number, to: number) => void;
  onModifyField: (index: number, field: keyof NewsAttachmentItem, value: string) => void;
};

const CustomTableCell = withStyles(TableCell, (theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: '1rem',
    paddingTop: 10,
    paddingBottom: 10,
  },
  body: {
    fontWeight: 'bold',
    paddingTop: 10,
    paddingBottom: 10,
  },
}));

const DisplayAttachmentsTable: FC<DisplayAttachmentsTableProps & HTMLAttributes<HTMLDivElement>> = ({
  isDisabled,
  attachments,
  onAddRow,
  onMoveRow,
  onDeleteRow,
  onModifyField,
  ...rest
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [uploadFileProgress, setUploadFileProgress] = useState<number>();

  const uploadFile = async (e: React.ChangeEvent<HTMLInputElement>, index: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      try {
        const createBlobRes = await createBlob(
          { mimeType: file.type, accessLevel: 'public', module: 'prunews' },
          dispatch,
        );
        await fileUpload(createBlobRes.url, file, setUploadFileProgress);
        const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);
        const result = blobDetail[0];
        if (result) {
          onModifyField(index, 'filename', file.name);
          onModifyField(index, 'blobId', result.blobId);
          onModifyField(index, 'url', result.url);
        }
      } catch (err) {
      } finally {
        setUploadFileProgress(undefined);
      }
    }
  };

  return (
    <>
      {/* {attachments && attachments.length > 0 && */}
      <TableContainer {...rest} component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <CustomTableCell></CustomTableCell>
              <CustomTableCell>{Translation('newsArticle.common.attachments')}</CustomTableCell>
              <CustomTableCell align="right">
                <IconButton disabled={isDisabled} style={{ padding: 5, color: 'white' }} onClick={onAddRow}>
                  <AddIcon />
                </IconButton>
              </CustomTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {attachments.map((attachment, index) => (
              <TableRow key={`attachment-row-${index}`}>
                <CustomTableCell style={{ width: 150 }}>
                  <input
                    id={`upload-attachment-${index}`}
                    hidden
                    type="file"
                    accept=".pdf, .xls, .xlsx, .ppt, .pptx, .doc, .docx, video/mp4, image/*"
                    disabled={isDisabled}
                    onChange={(e) => {
                      uploadFile(e, index);
                    }}
                  />
                  <div>
                    <Button
                      disabled={isDisabled}
                      variant="contained"
                      color="secondary"
                      onClick={() => document.getElementById(`upload-attachment-${index}`)!.click()}
                    >
                      {Translation('newsArticle.common.chooseFile')}
                    </Button>
                  </div>
                </CustomTableCell>
                <CustomTableCell>
                  {/* eslint-disable-next-line */}
                  <a href={attachment.url} target="_blank">
                    {attachment.filename}
                  </a>
                </CustomTableCell>
                <CustomTableCell align="right">
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <IconButton style={{ padding: 5 }} onClick={() => onDeleteRow(index)} disabled={isDisabled}>
                      <HighlightOffIcon />
                    </IconButton>
                  </div>
                </CustomTableCell>
              </TableRow>
            ))}
          </TableBody>
          {!!uploadFileProgress && (
            <LinearProgress style={{ marginTop: 10 }} variant="determinate" value={uploadFileProgress} />
          )}
        </Table>
      </TableContainer>
      {/* } */}
    </>
  );
};

export default DisplayAttachmentsTable;
