import React, { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  IconButton,
  Link,
  MenuItem,
  Checkbox,
  ListItemText,
  FormControl,
  Select,
  Snackbar,
  TextField,
} from '@mui/material';
import { Edit, Delete, Done, Clear, CloseOutlined } from '@mui/icons-material';
import { getDefaultDisplayDate, downloadAs } from 'src/app/common/utils';
import { PaginateList } from 'src/app/common/types/common-types';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import { TokenInfo } from 'src/app/modules/Auth/types/auth-types';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { trimRoleName } from 'src/app/modules/AgencyCampaign/utils/common-utils';
import { PruDateTimePicker } from 'src/app/common/components/PruDatePicker';
import { approvalPath } from '../../ApprovalRoutes';
import {
  ApprovalCampaignItem,
  ResourceItem,
  ParticipantIndividualItem,
  AttachmentDetailItem,
  RemarkItem,
  LouTemplateItem,
  AgencyCampaignStatusEnum,
  WorkflowApprovalStatusEnum,
  ContentEnum,
} from 'src/app/modules/AgencyCampaign/types/approval-types';
import ImportParticipantPopup from 'src/app/modules/AgencyCampaign/pages/Approval/components/ImportParticipantPopup';

import {
  generateLouTemplate,
  modifyApproval,
  modifyRemark,
  GenerateLouTemplateBody,
  UpdateApprovalBody,
  UpdateRemarkBody,
  updateParticipantsRsvpStatusToAccepted,
} from 'src/app/modules/AgencyCampaign/network/approvalCrud';
import { removeParticipant, updateCampaign } from 'src/app/modules/AgencyCampaign/network/staffCampaignCrud';
import { addParticipant } from 'src/app/modules/AgencyCampaign/network/approvalCrud';

import ApprovalDialog from '../../components/ApprovalDialog';
import AttachmentDialog from './AttachmentDialog/AttachmentDialog';
import ProgressBarItem from './ProgressBar/ProgressBarItem';
import { MANDATORY_FIELD_ERROR_TEXT, VALIDATION_FIELD_ERROR_TEXT } from 'src/app/common/constants';
import { isEmpty, map, get, snakeCase } from 'lodash';
import { ModalService } from 'src/app/common/components/pru-modal/modal-service';
import { Translation } from 'src/app/i18n';
import { ConfirmModalWithReasonView } from './modals/confirm-with-reason-view';
import AddParticipantMenu from '../../components/AddParticipantMenu';
import { removeFirstLetterIfU } from 'src/app/modules/AgencyCampaign/utils/common-utils';
import moment from 'moment';
import { campaignStructureSection } from 'src/app/modules/AgencyCampaign/types/staff-campaign-types';

type ApprovalFormProps = {
  previousDataDispatch: (data: any) => void;
  previousDataState: any;
  formDispatch: (data: any) => void;
  formState: any;
  errorState: any;
  validationHandler: any;
  approvalItem: ApprovalCampaignItem;
  approvalResources: ResourceItem[];
  user?: TokenInfo | undefined;
  userRoleList: string[];
  participantList?: PaginateList<ParticipantIndividualItem>;
  louTemplateList: LouTemplateItem[];
  reloadResources: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
  onCancel?: (data?: any) => void;
  refreshParticipantList: () => void;
  campaignEndDateEqualToStartDate?: any;
  campaignEndDateGreaterThanTarget?: any;
};

type ApprovalDialogState = {
  open: boolean;
  _id: string | undefined;
  campaignId: string | undefined;
  newStatus: WorkflowApprovalStatusEnum | undefined;
};

type AttachmentDialogState = {
  open: boolean;
  action: string;
  remarkIndex: number | undefined;
};

type EditModeEnableState = {
  vendorName: boolean;
  vendorContact: boolean;
  vendorDesignation: boolean;
  vendorAddress: boolean;
  isAlfcLevelChallenge: boolean;
  //PACS Customization | Edit provision for all campaign fields | Start
  campaignStartDate: boolean;
  campaignEndDate: boolean;
  //PACS Customization | Edit provision for all campaign fields | End
};

type AgentItem = {
  unit?: string;
  name?: any;
  agentCode: string;
  displayName?: any;
  rsvpIndicator?: string;
  invitationTime?: string;
};

const initialApprovalDialogState: ApprovalDialogState = {
  open: false,
  _id: undefined,
  campaignId: undefined,
  newStatus: undefined,
};

const initialAttachmentDialogState: AttachmentDialogState = {
  open: false,
  action: '',
  remarkIndex: undefined,
};

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  rowContainer: {
    display: 'flex',
    marginBottom: 25,
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  basicInfoContainer: {
    padding: 16,
    backgroundColor: theme.palette.common.white,
  },
  footerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  bottomSpacing: {
    marginBottom: 5,
  },
  largerBottomSpacing: {
    marginBottom: 15,
  },
  sectionTitle: {
    fontSize: '1.4rem',
    fontWeight: 'bold',
  },
  sectionContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  fieldContainer: {
    width: '50%',
    boxSizing: 'border-box',
    marginBottom: 16,
  },
  participantRowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  participantCellContainer: {
    paddingLeft: 0,
  },
  disabled: {
    color: 'rgba(0, 0, 0, 0.5)',
  },
  olderRecord: {
    color: '#C1C1C1',
  },
  iconButton: {
    padding: 8,
    marginTop: -8,
  },
  dialogPaper: {
    width: 400,
    padding: '16px 8px',
    background: 'white',
  },
  dialogTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 20,
  },
  addBtnWrap: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-end',
    paddingLeft: 20,
    marginBottom: 12,
    alignItems: 'center',
  },
  // PACS Customization | Edit provision for all campaign fields | Start
  btnRemoveWrap: {
    margin: 10,
    display: 'flex',
    justifyContent: 'space-between',
  },
  bottomWhiteButton: {
    width: '200px',
    margin: 10,
    fontFamily: 'Poppins, Helvetica, "sans-serif"',
  },
  errorText: {
    fontSize: 10,
    color: '#f018a6',
    paddingLeft: 14,
  },
  formInput: { width: '250', height: 45, marginBottom: 8 },
  // PACS Customization | Edit provision for all campaign fields | End
}));

const getTranslations = () => {
  return {
    are_you_sure_to_cancel_the_campaign: Translation('are_you_sure_to_cancel_the_campaign'),
  };
};

const initialEditModeEnableState: EditModeEnableState = {
  vendorName: false,
  vendorContact: false,
  vendorDesignation: false,
  vendorAddress: false,
  isAlfcLevelChallenge: false,
  // PACS Customization | Edit provision for all campaign fields | Start
  campaignEndDate: false,
  campaignStartDate: false,
  // PACS Customization | Edit provision for all campaign fields | End
};

const ApprovalForm: FC<ApprovalFormProps> = ({
  previousDataDispatch,
  previousDataState,
  formDispatch,
  formState,
  errorState,
  validationHandler,
  approvalItem,
  approvalResources,
  user,
  userRoleList,
  participantList,
  louTemplateList,
  reloadResources,
  onChangePage,
  onCancel,
  refreshParticipantList,
  campaignEndDateEqualToStartDate,
  campaignEndDateGreaterThanTarget,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { classes } = useStyles();
  const { classes: commonClasses } = useCommonStyles();
  const onPressCancel = () => {
    ModalService.open({
      title: getTranslations().are_you_sure_to_cancel_the_campaign,
      children: <ConfirmModalWithReasonView onConfirm={onCancel} />,
      classes: { paper: classes.dialogPaper },
      customHeader: (
        <div className={classes.dialogTitle}>
          <span>{getTranslations().are_you_sure_to_cancel_the_campaign}</span>
        </div>
      ),
    });
  };

  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const {
    typeId,
    agentLeadSource,
    agentLeadSubSource,
    applicant,
    attachments,
    approvalHistoryList,
    remark,
    postDocuments,
  } = approvalItem.agencyCampaign;
  const hasRsvp = typeId.sections
    .find((item) => item.key === 'participant')
    ?.sectionFields.find((item) => item.key === 'rsvpResponse');

  const [remarkList, setRemarkList] = useState<RemarkItem[]>(remark ? [...remark] : []);

  const [editModeEnableStats, setEditModeEnableState] = useState<any>(initialEditModeEnableState);

  const [approvalDialogState, setApprovalDialogState] = useState<ApprovalDialogState>(initialApprovalDialogState);
  const [openImportFromFile, setOpenImportFromFile] = useState<boolean>(false);
  const [snackBar, setSnackBar] = useState<{ msg: string; visible: boolean }>({
    msg: '',
    visible: false,
  });

  const checkIfFieldHasValue = (sectionItem: any, fieldsItem: any) => {
    let value = ' ';

    switch (fieldsItem.key) {
      case 'numsOfPolicy':
        value =
          formState[sectionItem.key] && formState[sectionItem.key][fieldsItem.key]
            ? formState[sectionItem.key][fieldsItem.key]
            : '0';
        break;
      case 'totalApe':
        value =
          formState[sectionItem.key] && formState[sectionItem.key][fieldsItem.key]
            ? formState[sectionItem.key][fieldsItem.key]
            : '$0';
        break;
      default:
        break;
    }

    return value;
  };

  const onApprovalCancel = () => {
    setApprovalDialogState(initialApprovalDialogState);
  };
  const onApprovalConfirm = async (comment: string) => {
    if (approvalDialogState._id && approvalDialogState.newStatus) {
      const body: UpdateApprovalBody = {
        assignee: user?.sub || '',
        assigneeName: user?.username || '',
        role: userRoleList,
        agencyCampaignId: approvalDialogState._id,
        status: approvalDialogState.newStatus,
        comment: comment,
      };
      await modifyApproval(body, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Campaign Approval status updated successfully`,
          },
        ]),
      );
      onApprovalCancel();
      history.push(approvalPath);
    }
  };

  const [attachmentDialogState, setAttachmentDialogState] =
    useState<AttachmentDialogState>(initialAttachmentDialogState);
  const onAttachmentCancel = () => {
    setAttachmentDialogState(initialAttachmentDialogState);
  };

  const setDoneImport = (data: AgentItem[]) => {
    var agentCodeArray = data.map((data) => {
      return { ...data, campaign: approvalItem._id };
    });
    addParticipant(agentCodeArray, dispatch)
      .then((res) => {
        setSnackBar({
          msg: res.length + Translation('agencyCampaign.create.importDone'),
          visible: true,
        });
        updateParticipantsRsvpStatusToAccepted(approvalItem.campaignId, dispatch).then((res) => {
          refreshParticipantList();
        });
      })
      .catch((e) => {});
  };

  const onAttachmentConfirm = (newRemarkItem: RemarkItem) => {
    const newRemarkList = [...remarkList];
    if (attachmentDialogState.remarkIndex !== undefined) {
      newRemarkList[attachmentDialogState.remarkIndex] = newRemarkItem;
    } else {
      newRemarkList.push(newRemarkItem);
    }
    setRemarkList(newRemarkList);
    onUpdateRemark(newRemarkList);
    onAttachmentCancel();
  };
  const onDeleteRemarkItem = (remarkIndex: number) => {
    const newRemarkList = [...remarkList];
    newRemarkList.splice(remarkIndex, 1);
    setRemarkList(newRemarkList);
    onUpdateRemark(newRemarkList);
  };
  const onUpdateRemark = async (newRemarkList: RemarkItem[]) => {
    const body: UpdateRemarkBody = {
      id: approvalItem._id,
      remark: newRemarkList,
    };
    await modifyRemark(body, dispatch);
    dispatch(
      appendAlertItem([
        {
          severity: AlertType.SUCCESS,
          title: 'Success',
          content: `Campaign remark updated successfully`,
        },
      ]),
    );
    reloadResources();
  };
  const onSaveDocuments = async (event: any, section: any, field: any) => {
    const { currentErrorState } = validationHandler.onSubmitErrorValidator();
    if (!currentErrorState.mandatory[event.currentTarget.id]) {
      previousDataDispatch({
        type: 'MODIFY_FIELD',
        payload: {
          section: section.key,
          field: field.key,
          value: formState[section.key][field.key],
        },
      });
      setEditModeEnableState({
        ...editModeEnableStats,
        [event.currentTarget.id]: false,
      });
      const updatedState = {
        ...formState,
        typeId: formState.typeId._id,
      };
      await updateCampaign(updatedState, approvalItem.campaignId, dispatch);
    }
    return false;
  };

  // PACS Customization | Edit provision for all campaign fields | Start
  const TranslationWithVariable = (key: string, count: number | string) =>
    intl.formatMessage({ id: key }, { num: count });
  const handleChange = (val: string | string[], sectionKey: string, fieldKey: string) => {
    const value = typeof val === 'string' ? val.split(',') : val;
    validationHandler.onDismissErrorHandler(fieldKey, value);
    formDispatch({
      type: 'MODIFY_FIELD',
      payload: { section: sectionKey, field: fieldKey, value: value },
    });
  };

  const renderMultiSelectOptions = (section: any, field: any) => {
    const arrSelectedItem = formState[section.key][field.key] || [];
    return field.options.map((item: { key: string; label: string }) => {
      const { key, label } = item;
      return (
        <MenuItem key={key} value={key}>
          <Checkbox checked={arrSelectedItem.indexOf(label) > -1} />
          <ListItemText primary={label} />
        </MenuItem>
      );
    });
  };
  const checkIsAutoPopulatedField = (fieldsItem: any) => {
    let isAutoPopulatedField = false;
    if (
      fieldsItem.key === 'campaignEndDate' &&
      campaignEndDateGreaterThanTarget &&
      campaignEndDateGreaterThanTarget.setGreateThan
    ) {
      isAutoPopulatedField = true;
    }
    return isAutoPopulatedField;
  };
  const handleDateError = (errorState: any, sectionItem: campaignStructureSection, fieldItem: any) => {
    const path = fieldItem;
    let errMsg = '';
    if (errorState.mandatory[path]) {
      errMsg = MANDATORY_FIELD_ERROR_TEXT;
    }
    if (errorState.immediate[path]) {
      errMsg = VALIDATION_FIELD_ERROR_TEXT;
      const arr = fieldItem.rules?.filter((item: any) => item.key === 'compare');
      if (arr.length > 0) {
        // rules format:
        // { "key": "compare", "operator": "gte","value": 30, "dependsOn": "submitDate" }
        const { operator, value, dependsOn } = arr[0];
        let fieldTitle = '';
        if (dependsOn) {
          const dependsOnField = sectionItem.sectionFields.filter((field) => field.key === dependsOn);
          if (dependsOnField.length > 0) {
            fieldTitle = dependsOnField[0].title || dependsOn;
          }
        }
        errMsg = TranslationWithVariable(`common.date.${operator}`, value) + fieldTitle;
      }
    }

    return errMsg;
  };
  // PACS Customization | Edit provision for all campaign fields | End

  const renderSection = (section: any) => (
    <div className={classes.sectionContainer}>
      {section.sectionFields.map((field: { key: string; type: string; options: [] }) => {
        let isAutoPopulatedField = checkIsAutoPopulatedField(field);
        return (
          <div className={classes.fieldContainer}>
            <div className={classes.bottomSpacing}>
              <span className={classes.disabled}>{Translation(`agencyCampaign.field.${field.key}`)}</span>
              {/* PACS Customization | Edit provision for all campaign fields | Start */}
              {section.key === 'campaign' &&
                (!editModeEnableStats[field.key] ? (
                  !(
                    ['campaignStartDate', 'campaignEndDate'].includes(field.key) &&
                    moment().isAfter(approvalItem.startDate)
                  ) ? (
                    <IconButton
                      id={field.key}
                      className={classes.iconButton}
                      style={{ marginLeft: 5 }}
                      onClick={(event) => {
                        setEditModeEnableState({
                          ...editModeEnableStats,
                          [event.currentTarget.id]: true,
                        });
                        return validationHandler.onSubmitErrorValidator();
                      }}
                    >
                      <Edit />
                    </IconButton>
                  ) : null
                ) : (
                  <>
                    <IconButton
                      id={field.key}
                      className={classes.iconButton}
                      style={{ marginLeft: 5 }}
                      onClick={(event) => {
                        formDispatch({
                          type: 'MODIFY_FIELD',
                          payload: {
                            section: section.key,
                            field: field.key,
                            value: previousDataState[section.key][field.key],
                          },
                        });
                        return setEditModeEnableState({
                          ...editModeEnableStats,
                          [event.currentTarget.id]: false,
                        });
                      }}
                    >
                      <Clear />
                    </IconButton>
                    <IconButton
                      id={field.key}
                      className={classes.iconButton}
                      style={{ marginLeft: 5 }}
                      onClick={(event) => onSaveDocuments(event, section, field)}
                    >
                      <Done />
                    </IconButton>
                  </>
                ))}
            </div>
            {section.key === 'campaign' && !editModeEnableStats[field.key] && field.type === 'date' ? (
              <div style={{ flexGrow: 1 }}>
                {typeof formState[section.key][field.key] === 'string' &&
                  getDefaultDisplayDate(new Date(formState[section.key][field.key]), 'datetime')}
                {formState[section.key][field.key] instanceof Date &&
                  getDefaultDisplayDate(formState[section.key][field.key], 'datetime')}
              </div>
            ) : (
              <div style={{ flexGrow: 1 }}>
                {section.key === 'campaign' && editModeEnableStats[field.key] ? (
                  <>
                    {field.type === 'dropdown' && (
                      <FormControl margin="dense" variant="outlined">
                        <Select
                          // MenuProps={{
                          //   anchorOrigin: {
                          //     vertical: 'bottom',
                          //     horizontal: 'left',
                          //   },
                          // }}
                          style={{ width: 250 }}
                          margin="dense"
                          error={errorState.mandatory[field.key]}
                          value={
                            formState[section.key] && formState[section.key][field.key]
                              ? formState[section.key][field.key]
                              : ''
                          }
                          onChange={(e) => {
                            formDispatch({
                              type: 'MODIFY_FIELD',
                              payload: {
                                section: section.key,
                                field: field.key,
                                value: e.target.value,
                              },
                            });
                            validationHandler.onDismissErrorHandler(field.key, e.target.value);
                          }}
                        >
                          {field.options.map((item: { key: string; label: string }) => (
                            <MenuItem key={item.key} value={item.label}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                    {field.type === 'text' && (
                      <TextField
                        type={field.type}
                        style={{ width: 250 }}
                        margin="dense"
                        error={errorState.mandatory[field.key]}
                        helperText={errorState.mandatory[field.key] && MANDATORY_FIELD_ERROR_TEXT}
                        value={
                          formState[section.key] && formState[section.key][field.key]
                            ? formState[section.key][field.key]
                            : ''
                        }
                        inputProps={{ maxLength: 50 }}
                        onChange={(e) => {
                          formDispatch({
                            type: 'MODIFY_FIELD',
                            payload: {
                              section: section.key,
                              field: field.key,
                              value: e.target.value,
                            },
                          });
                          validationHandler.onDismissErrorHandler(field.key, e.target.value);
                        }}
                      />
                    )}
                    {field.type === 'date' && (
                      <PruDateTimePicker
                        disabled={isAutoPopulatedField}
                        slotProps={{
                          textField: {
                            style: { width: 250 },
                            error: errorState.mandatory[field.key] || errorState.immediate[field.key],
                            helperText: handleDateError(errorState, section, field),
                            variant: 'standard',
                            margin: 'normal',
                          },
                        }}
                        ampm={false}
                        format="DD/MM/YYYY HH:mm"
                        value={
                          formState[section.key] && formState[section.key][field.key]
                            ? typeof formState[section.key][field.key] === 'string'
                              ? moment(formState[section.key][field.key]).toDate()
                              : formState[section.key][field.key]
                            : null
                        }
                        onChange={(date: any) => {
                          if (moment(date).isValid()) {
                            validationHandler.onDismissErrorHandler(field.key, moment(date).toISOString());
                            formDispatch({
                              type: 'MODIFY_FIELD',
                              payload: {
                                section: section.key,
                                field: field.key,
                                value: moment(date).toISOString(),
                              },
                            });
                          }
                        }}
                        // KeyboardButtonProps={{
                        //   'aria-label': 'change date',
                        // }}
                      />
                    )}
                    {field.type === 'number' && (
                      <TextField
                        type="number"
                        style={{ width: 250 }}
                        margin="dense"
                        InputProps={{ inputProps: { min: 1 } }}
                        error={errorState.mandatory[field.key]}
                        helperText={errorState.mandatory[field.key] && MANDATORY_FIELD_ERROR_TEXT}
                        value={
                          formState[section.key] && formState[section.key][field.key]
                            ? formState[section.key][field.key]
                            : ''
                        }
                        onBlur={(e) => {
                          if (formState[section.key][field.key].includes('postalCode'))
                            validationHandler.validatePostalCode(field.key, e.target.value);
                        }}
                        onChange={(e) => {
                          validationHandler.onDismissErrorHandler(field.key, e.target.value);
                          formDispatch({
                            type: 'MODIFY_FIELD',
                            payload: {
                              section: section.key,
                              field: field.key,
                              value: e.target.value,
                            },
                          });
                        }}
                      />
                    )}
                    {field.type === 'multipleChoice' && (
                      <>
                        <Select
                          labelId="multiple-name-label"
                          id="multiple-name"
                          className={classes.formInput}
                          multiple
                          value={
                            formState[section.key] && formState[section.key][field.key]
                              ? formState[section.key][field.key]
                              : []
                          }
                          onChange={(e: any) => handleChange(e?.target?.value, section.key, field.key)}
                          renderValue={(selected: any) => selected.join(', ')}
                        >
                          {field.options ? renderMultiSelectOptions(section, field) : null}
                        </Select>
                        {errorState.mandatory[field.key] && (
                          <span className={classes.errorText}>{MANDATORY_FIELD_ERROR_TEXT}</span>
                        )}
                      </>
                    )}
                    {field.type === 'currency' && (
                      <TextField
                        style={{ width: 250 }}
                        margin="dense"
                        error={errorState.mandatory[field.key]}
                        helperText={errorState.mandatory[field.key] && MANDATORY_FIELD_ERROR_TEXT}
                        value={
                          formState[section.key] && formState[section.key][field.key]
                            ? formState[section.key][field.key]
                            : '$0'
                        }
                        inputProps={{ maxLength: 50 }}
                        onChange={(e) => {
                          formDispatch({
                            type: 'MODIFY_FIELD',
                            payload: {
                              section: section.key,
                              field: field.key,
                              value: e.target.value,
                            },
                          });
                          validationHandler.onDismissErrorHandler(field.key, e.target.value);
                        }}
                      />
                    )}
                  </>
                ) : typeof formState[section.key][field.key] === 'string' &&
                  formState[section.key] &&
                  formState[section.key][field.key] ? (
                  formState[section.key][field.key]
                ) : (
                  checkIfFieldHasValue(section, field)
                )}
                {formState[section.key] instanceof Date && getDefaultDisplayDate(formState[section.key], 'datetime')}
                {!editModeEnableStats[field.key] &&
                  Array.isArray(formState[section.key][field.key]) &&
                  formState[section.key][field.key].join(', ')}
                {/* PACS Customization | Edit provision for all campaign fields | End */}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );

  const renderAttachment = (attachments: any) => {
    return map(attachments, (item, key) => {
      const arrResourceIds = get(item, 'resourceIds', []);
      if (!arrResourceIds || arrResourceIds.length === 0) {
        return null;
      }
      const campaignName = approvalItem.name;
      const campaignId = approvalItem.campaignId;
      const keySnakeCase = snakeCase(key);
      return (
        <div key={key} className="tw-mt-5 tw-grid tw-grid-cols-12">
          <span className="tw-text-sm tw-col-span-3">{Translation(`agencyCampaign.field.${key}`)}</span>
          <span className=" tw-col-span-9">
            {arrResourceIds.map((resourceId: any, index: number) => {
              const file = approvalResources.find((item) => item.blobId === resourceId);
              if (!file) {
                return null;
              }
              const { originalFilename, blobName, url } = file;
              const fileName = originalFilename || blobName;
              const rename = `${campaignName}_${keySnakeCase}_${campaignId}_${fileName}`.replaceAll(' ', '_');
              return (
                <span key={index} className="tw-ml-4">
                  <Link color="secondary" onClick={() => downloadAs(url, rename)}>
                    {fileName}
                  </Link>
                </span>
              );
            })}
          </span>
        </div>
      );
    });
  };

  const renderPostEventDocuments = (eventDocuments: any) => {
    return map(eventDocuments, (item, key) => {
      const arrResourceIds = get(item, 'resourceIds', []);
      if (!arrResourceIds || arrResourceIds.length === 0) {
        return null;
      }
      const campaignName = approvalItem.name;
      const campaignId = approvalItem.campaignId;
      const keySnakeCase = snakeCase(key);
      return (
        <div key={key} className="tw-mt-5 tw-grid tw-grid-cols-12">
          <span className="tw-text-xl tw-font-bold tw-col-span-3">{Translation(`agencyCampaign.field.${key}`)}</span>
          <span className=" tw-col-span-9">
            {arrResourceIds.map((resource: any, index: number) => {
              if (resource?.submitDate) {
                const file = approvalResources.find((item) => item.blobId === resource.id);
                if (!file) {
                  return null;
                }
                const { originalFilename, blobName, url } = file;
                const fileName = originalFilename || blobName;
                const rename = `${campaignName}_${keySnakeCase}_${campaignId}_${fileName}`.replaceAll(' ', '_');
                return (
                  <span key={index} className="tw-ml-4">
                    <Link color="secondary" onClick={() => downloadAs(url, rename)}>
                      {fileName}
                    </Link>
                  </span>
                );
              }
            })}
          </span>
        </div>
      );
    });
  };
  const getLouTemplateContent = async (templateId: string) => {
    const body: GenerateLouTemplateBody = { id: approvalItem._id, templateId };
    const templateContent = await generateLouTemplate(body, dispatch);
    const newTab = window.open('', '_blank');
    if (newTab) {
      newTab.document.body.innerHTML = templateContent;
    }
  };

  const closeSnackBar = () =>
    setSnackBar({
      ...snackBar,
      visible: false,
    });

  // PACS Customization | Remove participant | Start
  const [participantSelected, setParticipantSelected] = useState<any[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const toggleOpen = () => setOpen(!open);
  const handleOk = () => {
    setOpen(false);
    removeSelectedParticipant();
  };

  const removeSelectedParticipant = () => {
    const agentCodeList = participantSelected.map(({ agentCode }: any) => {
      return agentCode;
    });

    if (approvalItem.campaignId) {
      const data = {
        id: approvalItem._id,
        agentList: agentCodeList,
      };
      removeParticipant(data, dispatch).then(() => {
        setParticipantSelected([]);
        refreshParticipantList();
      });
    }
  };
  // PACS Customization | Remove participant | End

  return (
    <>
      {approvalDialogState._id !== undefined &&
        approvalDialogState.campaignId !== undefined &&
        approvalDialogState.newStatus !== undefined && (
          <ApprovalDialog
            open={approvalDialogState.open}
            campaignId={approvalDialogState.campaignId}
            newStatus={approvalDialogState.newStatus}
            onConfirm={onApprovalConfirm}
            onCancel={onApprovalCancel}
          />
        )}

      {(attachmentDialogState.action === 'create' ||
        (attachmentDialogState.action === 'edit' && attachmentDialogState.remarkIndex !== undefined)) && (
        <AttachmentDialog
          open={attachmentDialogState.open}
          action={attachmentDialogState.action}
          remarkList={remarkList}
          remarkIndex={attachmentDialogState.remarkIndex}
          resources={approvalResources}
          louTemplateList={louTemplateList}
          approverName={user?.username || ''}
          onConfirm={onAttachmentConfirm}
          onCancel={onAttachmentCancel}
          getLouTemplateContent={getLouTemplateContent}
        />
      )}

      <div>
        <div className={`${classes.rowContainer} ${classes.basicInfoContainer}`}>
          <div className="col">
            <div className={classes.bottomSpacing}>{Translation('agencyCampaign.common.campaignType')}</div>
            <div>{typeId.name}</div>
          </div>
          <div className="col">
            <div className={classes.bottomSpacing}>{Translation('agencyCampaign.common.campaignId')}</div>
            <div>{approvalItem.campaignId}</div>
          </div>
          <div className="col">
            <div className={classes.bottomSpacing}>{Translation('agencyCampaign.common.status')}</div>
            <div>{Translation(`agencyCampaign.common.status.${approvalItem.status}`)}</div>
          </div>
          <div className="col">
            <div className={classes.bottomSpacing}>{Translation('agencyCampaign.common.startDate')}</div>
            <div>{getDefaultDisplayDate(approvalItem.startDate)}</div>
          </div>
          <div className="col">
            <div className={classes.bottomSpacing}>{Translation('agencyCampaign.common.leadSource')}</div>
            <div>{agentLeadSource.sourceName}</div>
          </div>
          <div className="col">
            <div className={classes.bottomSpacing}>{Translation('agencyCampaign.common.leadSubSource')}</div>
            <div>{agentLeadSubSource.map((subSource) => subSource.sourceName).join(', ')}</div>
          </div>
        </div>

        <div>
          <div className={classes.headerContainer}>
            <div className={`${commonClasses.header} ${classes.sectionTitle}`}>
              {Translation('agencyCampaign.approval.applicationDetails')}
            </div>
          </div>

          <div className={classes.container}>
            <div className={classes.rowContainer}>
              <div className="col-3">
                <span className={classes.sectionTitle}>{Translation('agencyCampaign.approval.applicantInfo')}</span>
              </div>
              <div className="col-9">
                {renderSection(typeId.sections.find((section) => section.key === 'applicant') || {})}
              </div>
            </div>

            <div className={classes.rowContainer}>
              <div className="col-3">
                <span className={classes.sectionTitle}>{Translation('agencyCampaign.approval.campaignInfo')}</span>
              </div>
              <div className="col-9">
                {renderSection(typeId.sections.find((section) => section.key === 'campaign') || {})}
              </div>
            </div>

            <div className={classes.rowContainer}>
              <div className="col-3">
                <span className={classes.sectionTitle}>{Translation('agencyCampaign.approval.participant')}</span>
              </div>
              <div className="col-9">
                {/* PACS Customization | Edit provision for all campaign fields | Start */}
                {moment().isBefore(approvalItem.startDate) && (
                  <div className={classes.addBtnWrap}>
                    {
                      <AddParticipantMenu
                        campaignObjId={approvalItem.campaignId}
                        onImportFromFile={() => setOpenImportFromFile(true)}
                      />
                    }
                  </div>
                )}

                <Snackbar
                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                  open={snackBar.visible}
                  autoHideDuration={5000}
                  onClose={closeSnackBar}
                  message={snackBar.msg}
                />
                <PruTable
                  disableBulkSelect={moment().isAfter(approvalItem.startDate)}
                  disableToolbar
                  headerBtnDef={[]}
                  operationDef={[]}
                  columnDef={[
                    {
                      keyIndex: 'unit',
                      displayName: Translation('agencyCampaign.approval.participant.unitCode'),
                      renderData: (row) => removeFirstLetterIfU(row.unit) || '-',
                    },
                    {
                      keyIndex: 'businessName',
                      displayName: Translation('agencyCampaign.approval.participant.businessName'),
                      renderData: (row) => row.name?.enUs?.displayName || '-',
                    },
                    {
                      isId: true,
                      keyIndex: 'agentCode',
                      displayName: Translation('agencyCampaign.approval.participant.agentCode'),
                      renderData: (row) => row.agentCode || '-',
                    },
                    ...(hasRsvp
                      ? [
                          {
                            keyIndex: 'rsvp',
                            displayName: Translation('agencyCampaign.approval.participant.rsvp'),
                            renderData: (row: ParticipantIndividualItem) => row.rsvpIndicator || '-',
                          },
                        ]
                      : []),
                  ]}
                  isLoading={false}
                  dataSource={participantList?.docs ? participantList.docs : []}
                  totalPages={participantList?.totalPages ?? 1}
                  totalRecords={participantList?.totalDocs ?? 0}
                  onChangePage={onChangePage}
                  currentSelectedRow={(data) => {
                    setParticipantSelected(data);
                  }}
                  bulkSelectCheckboxDisable={() => moment().isAfter(approvalItem.startDate)}
                  updateSelectedRow={participantSelected}
                  type="participants"
                />

                {participantSelected.length > 0 && (
                  <div className={classes.addBtnWrap}>
                    <Button
                      variant="outlined"
                      color="inherit"
                      size="large"
                      className={classes.bottomWhiteButton}
                      onClick={toggleOpen}
                    >
                      {Translation('app.button.remove')}
                    </Button>
                  </div>
                )}
                {/* PACS Customization | Edit provision for all campaign fields | End */}
              </div>
            </div>

            <div className={classes.rowContainer}>
              <div className="col-3">
                <span className={classes.sectionTitle}>{Translation('agencyCampaign.approval.attachment')}</span>
              </div>
              <div className="col-9">{attachments && renderAttachment(attachments)}</div>
            </div>
          </div>
        </div>

        <div>
          <div className={classes.headerContainer}>
            <div className={`${commonClasses.header} ${classes.sectionTitle}`}>
              {Translation('agencyCampaign.approval.approvalRecord')}
            </div>
          </div>

          <div className={classes.container}>
            <div style={{ display: 'flex' }}>
              <ProgressBarItem isFinished={true} disableLine={approvalHistoryList.length === 0} />
              <div
                className={`
                  ${classes.largerBottomSpacing}
                  ${approvalHistoryList.length > 0 ? classes.olderRecord : ''}
                `}
              >
                <div>{Translation('agencyCampaign.approval.submit')}</div>
                <div>
                  {Translation('agencyCampaign.approval.submittedBy')}{' '}
                  {`${applicant.applicantName} ${getDefaultDisplayDate(
                    approvalItem.agencyCampaign.submitDate,
                    'datetime',
                  )}`}
                </div>
              </div>
            </div>
            {approvalHistoryList.map((approvalHistory, index) => (
              <div style={{ display: 'flex' }}>
                <ProgressBarItem
                  isFinished={approvalHistory.status === WorkflowApprovalStatusEnum.APPROVED}
                  disableLine={index === approvalHistoryList.length - 1}
                />
                <div
                  className={`
                    ${index !== approvalHistoryList.length - 1 ? classes.largerBottomSpacing : ''}
                    ${
                      index !== approvalHistoryList.length - 1 &&
                      approvalHistory.status === WorkflowApprovalStatusEnum.APPROVED
                        ? classes.olderRecord
                        : ''
                    }
                  `}
                >
                  <div>
                    {approvalHistory.role.map((roleStr) => trimRoleName(roleStr)).join(', ')}{' '}
                    {Translation('agencyCampaign.approval')}
                  </div>
                  <div>
                    {Translation(`agencyCampaign.common.status.${approvalHistory.status}`)}
                    {approvalHistory.status !== WorkflowApprovalStatusEnum.PENDING &&
                      ` by ${approvalHistory.assigneeName} ${getDefaultDisplayDate(
                        approvalHistory.submitDate,
                        'datetime',
                      )}`}
                  </div>
                  {approvalHistory.comment && (
                    <div>
                      {Translation('agencyCampaign.approval.comment')}: {approvalHistory.comment}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div>
          <div className={classes.headerContainer}>
            <div className={`${commonClasses.header} ${classes.sectionTitle}`}>
              {Translation('agencyCampaign.approval.remark')}
            </div>
          </div>

          <div className={classes.container}>
            {remarkList.map((remarkItem, remarkIndex) => (
              <div className={classes.largerBottomSpacing}>
                <div>
                  <span>{`${remarkItem.updatedBy} ${getDefaultDisplayDate(remarkItem.updatedAt, 'datetime')}`}</span>
                  <IconButton
                    className={classes.iconButton}
                    style={{ marginLeft: 5 }}
                    onClick={() =>
                      setAttachmentDialogState({
                        open: true,
                        action: 'edit',
                        remarkIndex,
                      })
                    }
                  >
                    <Edit />
                  </IconButton>
                  <IconButton className={classes.iconButton} onClick={() => onDeleteRemarkItem(remarkIndex)}>
                    <Delete />
                  </IconButton>
                </div>
                {remarkItem.type === ContentEnum.FIXED && remarkItem.resourceIds.length > 0 && (
                  <div>
                    {`${Translation('agencyCampaign.approval.attachment')}: `}
                    {remarkItem.resourceIds.map((resourceId) => {
                      const file = approvalResources.find((item) => item.blobId === resourceId);
                      return file ? (
                        <Link color="secondary" target="_blank" href={file.url} style={{ marginRight: 5 }}>
                          {file.originalFilename}
                        </Link>
                      ) : (
                        <></>
                      );
                    })}
                  </div>
                )}
                {remarkItem.type === ContentEnum.VARIABLE && remarkItem.templateId !== '' && (
                  <div>
                    {`${Translation('agencyCampaign.approval.attachment')}: `}
                    <Link
                      color="secondary"
                      target="_blank"
                      onClick={(event: React.MouseEvent<any>) => {
                        event.preventDefault();
                        getLouTemplateContent(remarkItem.templateId);
                      }}
                    >
                      {Translation(`agencyCampaign.approval.remark.louTemplate.${remarkItem.templateId}`)}
                    </Link>
                  </div>
                )}
              </div>
            ))}
            <Button
              variant="contained"
              color="secondary"
              onClick={() =>
                setAttachmentDialogState({
                  open: true,
                  action: 'create',
                  remarkIndex: undefined,
                })
              }
            >
              {Translation('agencyCampaign.approval.attachment')}
            </Button>
          </div>
        </div>

        <div>
          <div className={classes.headerContainer}>
            <div className={`${commonClasses.header} ${classes.sectionTitle}`}>
              {Translation('agencyCampaign.approval.postDocuments')}
            </div>
          </div>

          <div className={classes.container}>
            <div>{!isEmpty(postDocuments) && renderPostEventDocuments(postDocuments)}</div>
            {isEmpty(postDocuments) && <div className="col-9"> {'No post event document required'}</div>}
          </div>
        </div>

        <div className={classes.footerContainer}>
          <Button variant="contained" style={{ backgroundColor: 'white', marginLeft: 20 }} onClick={onPressCancel}>
            {Translation('app.button.cancel')}
          </Button>
          {approvalItem.status === AgencyCampaignStatusEnum.PENDING && (
            <>
              <Button
                variant="contained"
                style={{ backgroundColor: 'white', marginLeft: 20 }}
                onClick={() =>
                  setApprovalDialogState({
                    open: true,
                    _id: approvalItem._id,
                    campaignId: approvalItem.campaignId,
                    newStatus: WorkflowApprovalStatusEnum.REJECTED,
                  })
                }
              >
                {Translation('section.common.operation.reject')}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                style={{ marginLeft: 20 }}
                onClick={() =>
                  setApprovalDialogState({
                    open: true,
                    _id: approvalItem._id,
                    campaignId: approvalItem.campaignId,
                    newStatus: WorkflowApprovalStatusEnum.APPROVED,
                  })
                }
              >
                {Translation('section.common.operation.approve')}
              </Button>
            </>
          )}
        </div>
      </div>

      <Dialog
        disableEscapeKeyDown
        open={openImportFromFile}
        onClose={(event, reason) => {
          if (reason && reason === 'backdropClick') {
            return;
          }

          setOpenImportFromFile(false);
        }}
        maxWidth="md"
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <DialogTitle>{Translation('agencyCampaign.create.button.importFromFile')}</DialogTitle>
          <div
            style={{ padding: '16px 20px' }}
            onClick={() => {
              setOpenImportFromFile(false);
            }}
          >
            <CloseOutlined />
          </div>
        </div>
        <ImportParticipantPopup
          selectFromFile={true}
          campaignObjId={approvalItem._id || ''}
          campaignTypeStructureData={typeId}
          doneImport={(data: any) => {
            setDoneImport(data);
            setOpenImportFromFile(false);
          }}
          onCancel={() => {
            setOpenImportFromFile(false);
          }}
        />
      </Dialog>

      {/* PACS Customization | Remove participant | Start */}
      <Dialog
        open={open}
        onClose={toggleOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{Translation('agencyCampaign.create.removeTitle')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {Translation('agencyCampaign.create.removeText')}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.btnRemoveWrap}>
          <Button onClick={toggleOpen} variant="outlined" color="inherit">
            {Translation('app.button.cancel')}
          </Button>
          <Button onClick={handleOk} variant="contained" color="secondary" autoFocus>
            {Translation('app.button.confirm')}
          </Button>
        </DialogActions>
      </Dialog>
      {/* PACS Customization | Remove participant | End */}
    </>
  );
};

export default ApprovalForm;
