import React, { FC, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Dialog, Button } from '@mui/material';

type ItemDeleteDialogProps = {
  open: boolean;
  onDelete: () => Promise<void>;
  onClose: () => void;
  onRefresh: () => void;
  content?: string;
};

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  header: {
    fontSize: '1.2rem',
    lineHeight: 1.2,
    marginBottom: 15,
  },
  fieldContainer: {
    paddingTop: 5,
    flexBasis: '20%',
    minWidth: 120,
    boxSizing: 'border-box',
  },
  noPaddingFieldContainer: {
    flexBasis: '20%',
    minWidth: 120,
    boxSizing: 'border-box',
  },
  footer: {
    marginTop: 20,
  },
  rowContainer: {
    display: 'flex',
  },
}));

const ItemDeleteDialog: FC<ItemDeleteDialogProps> = ({ open, onDelete, onClose, onRefresh, content }) => {
  const { classes } = useStyles();

  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const deleteItem = async () => {
    setIsDeleting(true);
    await onDelete();
    onRefresh();
    onClose();
  };

  return (
    <Dialog onClose={() => !isDeleting && onClose()} open={open}>
      <div className={classes.container}>
        <div className={classes.header}>Warning</div>
        <div>{content ? content : 'Are you sure you want to delete this item ? This action cannot be reverted.'}</div>
        <div className={classes.footer}>
          <Button disabled={isDeleting} variant="contained" onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={isDeleting}
            style={{ marginLeft: 25 }}
            variant="contained"
            color="secondary"
            onClick={() => deleteItem()}
          >
            Submit
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default ItemDeleteDialog;
