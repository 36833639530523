import { Translation } from 'src/app/i18n';
import { utils, writeFile } from 'xlsx';

export const newTemplate = () => {
  const exp = [['10001']];
  const worksheet = utils.json_to_sheet(exp);
  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, worksheet, 'AgentCode');
  utils.sheet_add_aoa(worksheet, [['AgentCode']], { origin: 'A1' });
  writeFile(workbook, 'template.xlsx');
};

export const POINT_VIDATOR = [
  {
    validator(_: any, value: string | number) {
      if (!value && value !== 0) {
        return Promise.reject(new Error(Translation('component.form-required-text')));
      }

      if (Number(value) < 0 || Number(value) % 0.5 !== 0) {
        return Promise.reject(new Error(Translation('must_be_multiple_of_0.5')));
      }
      return Promise.resolve();
    },
  },
];
