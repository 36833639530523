import { FC } from 'react';
import { useIntl } from 'react-intl';
import { Button, FormControl, FormHelperText, FormLabel } from '@mui/material';
import { regionLocale } from 'src/app/i18n';
import { MANDATORY_FIELD_ERROR_TEXT } from 'src/app/common/constants';
import { AttachmentDef, FormMode } from 'src/app/common/types';
import { ComponentProps } from 'src/app/common/components/pru-stepped-form';
import { UploadArea } from 'src/app/common/components/upload-area';
import Tinymce from 'src/app/common/components/Tinymce-storage-blob';
import { EventFormCommonProps } from 'src/app/modules/event-v2/types';
import { useCommonFormStyles, MultiLangAccordion } from '../../../common';
import { useDetailsStyles } from './details-form.style';
import { useDetailsForm } from './details-form.hook';

type EventDetailsFormProps = ComponentProps<EventFormCommonProps>;

export const EventDetailsForm: FC<EventDetailsFormProps> = ({ formCommonProps, ...rest }) => {
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);
  const { classes } = useDetailsStyles();
  const { classes: commonFormClasses } = useCommonFormStyles();
  const { formMode, disableEdit } = formCommonProps;
  const { formState, errorState, formDispatch, onDismissErrorHandler, onSubmit } = useDetailsForm({
    formCommonProps,
    ...rest,
  });
  const viewMode = formMode === FormMode.CREATE || formMode === FormMode.VIEW;

  return (
    <div className={commonFormClasses.container}>
      <div>
        <div className={commonFormClasses.fieldContainer}>
          <MultiLangAccordion
            disabled={viewMode || disableEdit}
            accordionClassName={classes.accordion}
            error={(locale) =>
              errorState.mandatory[`eventImage-${locale}`] || errorState.mandatory[`description-${locale}`]
            }
            fillInOnChange={(e, locale) => {
              if (e.target.checked) {
                onDismissErrorHandler([`eventImage-${locale}`, `description-${locale}`], true);
                formDispatch({
                  type: 'FILL_IN_DETAIL',
                  payload: { fromLocale: regionLocale[0], toLocale: locale },
                });
              }
            }}
            renderChildren={(locale) => {
              const eventImage = formState.eventImage[locale];
              const attachments = formState.attachments[locale] as AttachmentDef[];
              return (
                <>
                  <div className={commonFormClasses.fieldContainer}>
                    <FormControl
                      disabled={viewMode || disableEdit}
                      error={errorState.mandatory[`eventImage-${locale}`]}
                    >
                      <FormLabel className={classes.label}>
                        {Translation('event.form.image')}
                        <span className={commonFormClasses.mandatory}>*</span>
                      </FormLabel>
                    </FormControl>
                    <div className={commonFormClasses.remark}>{Translation('event.form.image.remark')}</div>
                    <UploadArea
                      id={`eventImage-${locale}`}
                      module="event"
                      accessLevel="public"
                      disabled={viewMode || disableEdit}
                      error={errorState.mandatory[`eventImage-${locale}`]}
                      enableImageRatioReminder
                      accept="image/jpeg,image/png"
                      sources={eventImage ? [eventImage] : []}
                      maxFileSize={300}
                      maxFileSizeUnit="KB"
                      onChange={(file) => {
                        onDismissErrorHandler(`eventImage-${locale}`, true);
                        formDispatch({
                          type: 'MODIFY_FIELD',
                          payload: { field: 'eventImage', value: { ...formState.eventImage, [locale]: file } },
                        });
                      }}
                      onRemove={() =>
                        formDispatch({
                          type: 'MODIFY_FIELD',
                          payload: { field: 'eventImage', value: { ...formState.eventImage, [locale]: undefined } },
                        })
                      }
                    />
                  </div>
                  <div className={commonFormClasses.fieldContainer}>
                    <FormControl
                      disabled={viewMode || disableEdit}
                      error={errorState.mandatory[`description-${locale}`]}
                    >
                      <FormLabel className={classes.label}>
                        {Translation('event.form.about_this_event')}
                        <span className={commonFormClasses.mandatory}>*</span>
                      </FormLabel>
                    </FormControl>
                    <div className={commonFormClasses.remark}>{Translation('event.form.about_this_event.remark')}</div>
                    <Tinymce
                      disabled={viewMode || disableEdit}
                      module="event"
                      accessLevel="anonymous"
                      value={formState.description[locale] || ''}
                      onChange={(value) => {
                        formDispatch({
                          type: 'MODIFY_DESCRIPTION',
                          payload: { value: { [locale]: value } },
                        });
                      }}
                    />
                    {errorState.mandatory[`description-${locale}`] && (
                      <FormHelperText error={errorState.mandatory[`description-${locale}`]}>
                        {MANDATORY_FIELD_ERROR_TEXT}
                      </FormHelperText>
                    )}
                  </div>
                  <div className={commonFormClasses.fieldContainer}>
                    <FormControl disabled={viewMode || disableEdit}>
                      <FormLabel className={classes.label}>
                        {Translation('event.list.detail.additional_attachment')}
                      </FormLabel>
                    </FormControl>
                    <div className={commonFormClasses.remark}>{Translation('event.form.attachments.remark')}</div>
                    <UploadArea
                      id={`attachments-${locale}`}
                      module="event"
                      accessLevel="public"
                      disabled={viewMode || disableEdit}
                      multiple
                      accept="image/jpeg,image/png,application/pdf,video/mp4"
                      sources={attachments || []}
                      maxFileNum={5}
                      maxFileSize={20}
                      maxFileSizeUnit="MB"
                      onChange={(file) =>
                        formDispatch({
                          type: 'MODIFY_FIELD',
                          payload: {
                            field: 'attachments',
                            value: { ...formState.attachments, [locale]: [...attachments, file] },
                          },
                        })
                      }
                      onRemove={(sourceIndex) =>
                        formDispatch({
                          type: 'REMOVE_ATTACHMENT',
                          payload: { locale, index: sourceIndex },
                        })
                      }
                    />
                  </div>
                </>
              );
            }}
          />
        </div>
      </div>
      {!viewMode && !disableEdit && (
        <div className={commonFormClasses.footerContainer}>
          <Button variant="contained" color="secondary" onClick={() => onSubmit()}>
            {Translation('app.button.save_and_continue')}
          </Button>
        </div>
      )}
    </div>
  );
};
