import {
  PruTableColumnDef,
  PruTableOperationDef,
  PruTableButtonDef,
} from 'src/app/common/components/PruTable/PruTable';

import { ConfigAboutMeListItem, ConfigAboutMePaginateList } from 'src/app/modules/Profile/types/agent-profile-types';
import { configAboutMePath } from '../../ConfigAboutMeRoutes';

export const columns = (Translation: any, getDefaultDisplayDate: any) => {
  const columns: PruTableColumnDef<ConfigAboutMeListItem>[] = [
    {
      isId: true,
      hidden: true,
      keyIndex: '_id',
      displayName: '',
      renderData: () => '',
    },
    {
      keyIndex: 'id',
      align: 'center',
      displayName: Translation('template_code_label'),
      renderData: (row) => row.templateCode || '-',
    },

    {
      keyIndex: 'default',
      align: 'center',
      displayName: Translation('agentProfile.ad_default_label'),
      renderData: (row) => (row.isDefault ? 'Yes' : 'No' || '-'),
    },
    {
      keyIndex: 'createdAt',
      align: 'center',
      displayName: Translation('agentProfile.ad_created_time_label'),
      renderData: (row) => getDefaultDisplayDate(row.createdAt, 'datetime'),
    },
    {
      keyIndex: 'updatedAt',
      align: 'center',
      displayName: Translation('agentProfile.ad_last_updated_time_label'),
      renderData: (row) => getDefaultDisplayDate(row.updatedAt, 'datetime') || '-',
    },
  ];
  return columns;
};

export const operation = (Translation: any, setDialogState: any, history: any) => {
  const columns: PruTableOperationDef<ConfigAboutMeListItem>[] = [
    {
      title: Translation('agentProfile.ad_edit_label'),
      tooltipText: Translation('agentProfile.ad_edit_introduction_hover_label'),
      onClick: (row) => history.push(`${configAboutMePath}/edit/${row.id}`),
    },
    {
      title: Translation('section.common.operation.delete'),
      tooltipText: Translation('agentProfile.ad_delete_introduction_hover_label'),
      onClick: (row: { id: any }) => setDialogState({ open: true, id: row.id }),
    },
  ];
  return columns;
};

export const headerBtn = (Translation: any, history: any) => {
  const columns: PruTableButtonDef[] = [
    {
      color: 'primary',
      title: Translation('agentProfile.ad_add_introduction_label'),
      onClick: () => {
        history.push(`${configAboutMePath}/create`);
      },
    },
  ];
  return columns;
};
