export const FEEDBACK_BASE_PATH = '/feedback';
export const FEEDBACK_CATEGORY_PATH = "/category";
export const FEEDBACK_RESULT_PATH = "/result";

//  These constants map to aside menu, header menu to display title
export const FEEDBACK_TITLE = "title.feedback";
export const FEEDBACK_CATEGORY_TITLE = "title.feedback.category";
export const FEEDBACK_RESULT_TITLE = "title.feedback.result";

//  These constants map to permission control, identical to permission enum defined below
export const FEEDBACK_CATEGORIES_NAMESPACE = "FEEDBACK-CATEGORIES";
export const FEEDBACK_RESULT_NAMESPACE = "FEEDBACK-RESULT";

export enum AdminFeedbackPermissionType {
  FEEDBACK_CATEGORIES_READ = "READ_ANY_FEEDBACK-CATEGORIES",
  FEEDBACK_CATEGORIES_CREATE = "CREATE_ANY_FEEDBACK-CATEGORIES",
  FEEDBACK_CATEGORIES_UPDATE = "UPDATE_ANY_FEEDBACK-CATEGORIES",
  FEEDBACK_RESULT_READ = "READ_ANY_FEEDBACK-RESULT",
  FEEDBACK_RESULT_CREATE = "CREATE_ANY_FEEDBACK-RESULT",
  FEEDBACK_RESULT_UPDATE = "UPDATE_ANY_FEEDBACK-RESULT"
};