import React, { FC } from 'react';
import { Redirect, RouteComponentProps, Switch } from 'react-router-dom';
import PruRoute from '../../../../common/components/PruRoute';
import ParamsProvider from '../../../../common/components/ParamsProvider';
import NewsListingPage, { newsArticleFilterKeys } from './List/NewsListingPage';
import NewsDetailPage from './View/NewsDetailPage';

const NewsArticlePage: FC<RouteComponentProps> = ({ history, location, match }) => {
  return (
    <Switch>
      <Redirect exact from={match.path} to={`${match.path}/list`}/>
      <PruRoute
        exact
        path={`${match.path}/list`}
        render={(props) => (
          <ParamsProvider {...props} acceptKeys={newsArticleFilterKeys}>
            <NewsListingPage />
          </ParamsProvider>
        )}
      />
      <PruRoute
        exact
        path={`${match.path}/view/:id`}
        component={NewsDetailPage}
      />
      <PruRoute
        exact
        path={`${match.path}/create`}
        component={NewsDetailPage}
      />
    </Switch>
  )
};

export default NewsArticlePage;
