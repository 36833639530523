import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import {persistStore} from "redux-persist";
import {rootReducer} from "./rootReducer";

const middleware = [
  ...getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
    thunk: true
  })
];

const store = configureStore({
  reducer: rootReducer,
  middleware,
  devTools: process.env.NODE_ENV !== "production"
});

export type RootStore = typeof store;

/**
 * @see https://github.com/rt2zz/redux-persist#persiststorestore-config-callback
 * @see https://github.com/rt2zz/redux-persist#persistor-object
 */
export const persistor = persistStore(store);

export type RootPersistor = typeof persistor;

const rootState = store.getState();

export type RootState = typeof rootState;
export type RootDispatch = typeof store.dispatch;

export const useProjectDispatch: () => RootDispatch = useDispatch;
export const useProjectSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
