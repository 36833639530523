import React, { useEffect, useState } from 'react';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { styles, useStyles } from '../../../style';
import Form from 'src/app/common/components/Form';
import { MenuItem, Select, TextField } from '@mui/material';
import Autocomplete from '@mui/lab/Autocomplete';
import { fetchActiveCampaignList } from 'src/app/modules/lead-microsite/network/api';
import { CampaignTypeEnum } from 'src/app/modules/PulseLeads/types/campaign-types';
import { MTmode } from 'src/app/common/constants';
import { FormSectionProps } from '../../../type/types';

const LeadDistributionSection: FC<FormSectionProps> = (props) => {
  const { form, formMode } = props;
  const isView = formMode === MTmode.VIEW;
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const DEFAULT_REQUIRED_RULES = { required: true, message: Translation('component.form-required-text') };
  const [campaignIdList, setCampaignIdList] = useState<string[]>(['']);
  const [value, setValue] = React.useState(form.getFieldValue('campaignId'));
  const fetchCampaignIdList = async () => {
    const list = await fetchActiveCampaignList({ status: 'active', type: CampaignTypeEnum.NORMAL }, dispatch);
    setCampaignIdList(list.docs.map((item) => item.campaignId));
  };

  useEffect(() => {
    fetchCampaignIdList();
  }, []);

  return (
    <div className="tw-bg-white tw-pt-6 tw-pb-6 tw-mt-6">
      <div className="tw-flex">
        <div className={classes.subHeader}>{Translation('agent_referral_link_lead_dis_rule')}</div>
      </div>

      <div className={classes.campaignId}>
        <Form.Item
          name="campaignId"
          label={Translation('agent_referral_link_map_campaign')}
          rules={[DEFAULT_REQUIRED_RULES]}
        >
          <div className="tw-w-full">
            <Autocomplete
              disabled={isView}
              disablePortal
              options={campaignIdList}
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
                form.setFieldsValue({ campaignId: newValue });
              }}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField {...params} margin="dense" variant="outlined" fullWidth style={styles.mb12} />
              )}
            />
          </div>
        </Form.Item>
      </div>
    </div>
  );
};
export default LeadDistributionSection;
