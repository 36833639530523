import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { CategoryItem, CategoryLevel, CategoryPaginateList } from '../../../types/category-types';
import PruTable from '../../../../../../../common/components/PruTable/PruTable';
import { getDefaultDisplayDate } from 'src/app/common/utils';

type CategoryListProps = {
  isLoading: boolean;
  categoryList?: CategoryPaginateList;
  enableUpdate: boolean;
  enableCreate: boolean;
  categoryTableTitle: string;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
  onSort: (sortingState: { key: string; value?: string }) => void;
  onAddCategory: (levelType: string) => void;
  onEditCategory: (rowData: CategoryItem, mode: string) => void;
  onDeleteCategory: (rowData: CategoryItem) => void;
};

const CategoryList: FC<CategoryListProps> = ({
  isLoading,
  categoryList,
  enableUpdate,
  enableCreate,
  categoryTableTitle,
  onRefresh,
  onChangePage,
  onSort,
  onAddCategory,
  onEditCategory,
  onDeleteCategory,
}) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  return (
    <PruTable
      title={Translation(categoryTableTitle)}
      disableBulkSelect
      headerBtnDef={[
        {
          color: 'primary',
          title: Translation('app.button.add-1st'),
          onClick: () => {
            onAddCategory(CategoryLevel.ONE);
          },
          condition: () => !!enableCreate,
        },
        {
          color: 'primary',
          title: Translation('app.button.add-2nd'),
          onClick: () => {
            onAddCategory(CategoryLevel.TWO);
          },
          condition: () => !!enableCreate,
        },
        {
          color: 'primary',
          title: Translation('app.button.add-3rd'),
          onClick: () => {
            onAddCategory(CategoryLevel.THREE);
          },
          condition: () => !!enableCreate,
        },
      ]}
      operationDef={[
        {
          title: Translation('app.button.view'),
          tooltipText: Translation('app.button.view'),
          onClick: (row) => {
            onEditCategory(row, 'view');
          },
        },
        {
          title: Translation('app.button.edit'),
          tooltipText: Translation('app.button.edit'),
          onClick: (row) => {
            onEditCategory(row, 'edit');
          },
          condition: () => !!enableUpdate,
        },
        {
          title: Translation('app.button.delete'),
          tooltipText: Translation('app.button.delete'),
          onClick: (row) => {
            onDeleteCategory(row);
          },
          // eslint-disable-next-line
          condition: (row) =>
            (!!enableUpdate && (!!!row.children || (row.children as CategoryItem[]).length === 0)) ||
            (row.children as { count: number }).count === 0,
        },
      ]}
      operationSticky={true}
      columnDef={[
        {
          keyIndex: 'name',
          align: 'left',
          displayName: Translation('component.formLabel.category'),
          renderData: (row) => String(row.name ? row.name : ''),
          sortable: true,
          onSort: (sort) => {
            onSort({ key: 'name', value: sort['name'] });
          },
        },
        {
          keyIndex: 'description',
          align: 'left',
          displayName: Translation('component.formLabel.description'),
          renderData: (row) => String(row.description ? row.description : ''),
          sortable: true,
          onSort: (sort) => {
            onSort({ key: 'description', value: sort['description'] });
          },
        },
        {
          keyIndex: 'lastUpdatedTime',
          align: 'left',
          displayName: Translation('component.formLabel.last-updated-time'),
          renderData: (row) => (row.updatedAt ? getDefaultDisplayDate(new Date(row.updatedAt), 'datetime') : ''),
          sortable: true,
          onSort: (sort) => {
            onSort({ key: 'updatedAt', value: sort['lastUpdatedTime'] });
          },
        },
        {
          keyIndex: 'lastUpdatedBy',
          align: 'left',
          displayName: Translation('component.formLabel.last-updated-by'),
          renderData: (row) => String(row.lastUpdatedBy ? row.lastUpdatedBy : ''),
          sortable: true,
          onSort: (sort) => {
            onSort({ key: 'lastUpdatedBy', value: sort['lastUpdatedBy'] });
          },
        },
        {
          isId: true,
          hidden: true,
          keyIndex: 'id',
          childKeyIndex: 'children',
          displayName: '',
          renderData: () => '',
        },
      ]}
      isLoading={isLoading}
      onRefresh={onRefresh}
      dataSource={categoryList?.categoryList}
      totalPages={categoryList?.totalPages}
      totalRecords={categoryList?.totalRecords}
      onChangePage={onChangePage}
      disablePagination={true}
      renderChildren={true}
    />
  );
};

export default CategoryList;
