export const NEWS_BASE_PATH = '/news';
export const NEWS_CATEGORY_PATH = '/category';
export const NEWS_ARTICLE_PATH = '/article';
export const NEWS_HEADLINE_PATH = '/headline';

//  These constants map to aside menu, header menu to display title
export const NEWS_TITLE = 'title.news';
export const NEWS_CATEGORY_TITLE = 'title.news.category';
export const NEWS_ARTICLE_TITLE = 'title.news.article';
export const NEWS_HEADLINE_TITLE = 'title.news.headline';

//  These constants map to permission control, identical to permission enum defined below
export const NEWS_CATEGORIES_NAMESPACE = 'NEWS-CATEGORIES';
export const NEWS_ARTICLE_NAMESPACE = 'NEWS-ARTICLE';
export const NEWS_HEADLINE_NAMESPACE = 'NEWS-HEADLINE';

export enum AdminNewsPermissionType {
  NEWS_CATEGORIES_READ = 'READ_ANY_NEWS-CATEGORIES',
  NEWS_CATEGORIES_CREATE = 'CREATE_ANY_NEWS-CATEGORIES',
  NEWS_CATEGORIES_UPDATE = 'UPDATE_ANY_NEWS-CATEGORIES',
  NEWS_ARTICLE_READ = 'READ_ANY_NEWS-ARTICLE',
  NEWS_ARTICLE_CREATE = 'CREATE_ANY_NEWS-ARTICLE',
  NEWS_ARTICLE_UPDATE = 'UPDATE_ANY_NEWS-ARTICLE',
  NEWS_ARTICLE_APPROVE = 'APPROVE_ANY_NEWS-ARTICLE',
  NEWS_ARTICLE_ARCHIVE = 'ARCHIVE_ANY_NEWS-ARTICLE',
  NEWS_HEADLINE_READ = 'READ_ANY_NEWS-HEADLINE',
  NEWS_HEADLINE_CREATE = 'CREATE_ANY_NEWS-HEADLINE',
  NEWS_HEADLINE_UPDATE = 'UPDATE_ANY_NEWS-HEADLINE',
}
