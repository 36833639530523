import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import PruRoute from 'src/app/common/components/PruRoute';
import { PULSELEADS_BASE_PATH, } from '../../constants';
import { PULSELEADS_LEADS_PATH, PULSELEADS_LEADS_NOT_DISTRIBUTED_PATH } from './constants';
import List from './List';
import Detail from './Detail';
import { ModulePermissionProps } from 'src/app/common/module/types';

export const leadNotDistrubutedPath = `${PULSELEADS_BASE_PATH}${PULSELEADS_LEADS_PATH}${PULSELEADS_LEADS_NOT_DISTRIBUTED_PATH}`;

const LeadNotDistributionPage: FC<RouteComponentProps & ModulePermissionProps> = ({
    enableRead,
    enableCreate,
    enableUpdate
}) => {
    return (
        <Switch>
            <Redirect exact from={leadNotDistrubutedPath} to={`${leadNotDistrubutedPath}/list`} />
            <PruRoute exact path={`${leadNotDistrubutedPath}/list`} component={List} />
            <PruRoute exact path={`${leadNotDistrubutedPath}/detail/:id`} render={(props) => (
                <Detail
                    {...props}
                    enableRead={enableRead}
                    enableCreate={enableCreate}
                    enableUpdate={enableUpdate}
                />
            )} />
            <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
        </Switch>
    )
};

export default LeadNotDistributionPage;