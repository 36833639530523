import { makeStyles } from 'tss-react/mui';

const RADIO_GROUP_LABEL_WIDTH = 70;
const TEXT_FIELD_WIDTH = 600;

export const useParticipantListStyles = makeStyles()(() => ({
  reminderDialogPaper: {
    width: 450,
    maxWidth: 450,
  },
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 18,
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  sectionContainer: {
    backgroundColor: 'white',
    padding: 20,
  },
  sectionSpacing: {
    marginBottom: 15,
  },
  sessionSelectContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 8,
  },
  sessionSelect: {
    backgroundColor: 'white',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 15,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 18,
    fontWeight: 600,
    '&:before': {
      content: "''",
      display: 'inline-block',
      height: 24,
      width: 4,
      backgroundColor: 'red',
      marginRight: 10,
      marginLeft: 10,
    },
  },
  exportButtonContainer: {
    display: 'flex',
    columnGap: 20,
  },
  overviewSessionTitle: {
    margin: '20px 25px',
  },
  overviewContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 50,
    marginLeft: 25,
  },
  overviewDataContainer: {
    textAlign: 'center',
  },
  overviewData: {
    fontSize: 24,
    fontWeight: 700,
  },
  overviewLabel: {
    fontSize: 16,
  },
  filter: {
    padding: '0px 25px',
  },
  blueText: {
    color: '#1976D2',
  },
  detailContainer: {
    backgroundColor: 'white',
    padding: '20px 40px',
  },
  backContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: 10,
  },
  radioGroupLabel: {
    width: RADIO_GROUP_LABEL_WIDTH,
    color: '#3F4254',
  },
  textField: {
    width: TEXT_FIELD_WIDTH,
  },
  bottomSpacing: {
    marginBottom: 10,
  },
}));
