import React, { FC, useReducer } from 'react';
import { useCommonStyles } from '../../../../../../common/styles/common-styles';
import { MANDATORY_FIELD_ERROR_TEXT, CONFIG_BASE_PATH, CONFIG_AGENT_PATH } from '../../../../constants';
import { makeStyles } from 'tss-react/mui';
import { Button, TextField } from '@mui/material';
import { useErrorHandler, ErrorFieldType } from 'src/app/common/utils';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import {
  ConfigAgentEditItem,
  ConfigAgentKeyValueItem,
  ConfigAgentFormMode,
  ConfigAgentItem,
} from '../../../../types/config-type';
import DisplayKeyValueTable from './DisplayKeyValueTable';
import { appendAlertItem, AlertType } from '../../../../../../../redux/common/commonSlice';
import { createConfigAgent, modifyConfigAgent } from '../../../../network/configCrud';

type NewsCategoryDetailFormProps = {
  formMode: ConfigAgentFormMode;
  configAgentDetail?: ConfigAgentItem;
  code?: string;
  onRouteTo: (route: string) => void;
};

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
    //marginTop: 30
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  fieldContainer: {
    width: 300,
    boxSizing: 'border-box',
  },
  noPaddingFieldContainer: {
    flexBasis: '20%',
    minWidth: 120,
    boxSizing: 'border-box',
  },
  footer: {
    marginTop: 20,
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },
  settingTable: {
    width: '50%',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingTop: 20,
  },
}));

export type ConfigAgentFormState = {
  keyValue?: ConfigAgentKeyValueItem[];
  agentCode: string;
  type: string;
};

const initialState: ConfigAgentFormState = {
  keyValue: [],
  agentCode: '',
  type: 'user',
};

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof ConfigAgentFormState;
    value: any;
  };
};

type AddKeyValueRowAction = {
  type: 'ADD_KEYVALUE_ROW';
};

type ModifyKeyValueRowAction = {
  type: 'MODIFY_KEYVALUE_ROW';
  payload: {
    index: number;
    field: keyof ConfigAgentKeyValueItem;
    value: string;
  };
};

type DeleteKeyValueRowAction = {
  type: 'DELETE_KEYVALUE_ROW';
  payload: {
    index: number;
  };
};

type ConfigAgentFormAction =
  | ModifyFieldAction
  | ModifyKeyValueRowAction
  | AddKeyValueRowAction
  | DeleteKeyValueRowAction;

const configAgentReducer = (state: ConfigAgentFormState, action: ConfigAgentFormAction) => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    case 'ADD_KEYVALUE_ROW':
      return {
        ...state,
        keyValue: state.keyValue ? [...state.keyValue, { key: '', value: '' }] : [{ key: '', value: '' }],
      };
    case 'MODIFY_KEYVALUE_ROW':
      if (state.keyValue && state.keyValue[action.payload.index]) {
        let item = state.keyValue[action.payload.index];
        item[action.payload.field] = action.payload.value;
      }
      return { ...state };
    case 'DELETE_KEYVALUE_ROW':
      if (state.keyValue && state.keyValue[action.payload.index]) {
        let emailArr = state.keyValue;
        emailArr.splice(action.payload.index, 1);
      }
      return { ...state };
    default:
      return initialState;
  }
};

const detailToStateConvertor = (configAgentDetail?: ConfigAgentItem): ConfigAgentFormState => {
  let keyValueArray: ConfigAgentKeyValueItem[] = [];
  if (configAgentDetail?.keyValue) {
    Object.entries(configAgentDetail.keyValue).map((item, index) => {
      return keyValueArray.push({ key: item[0], value: item[1] });
    });
  }

  return configAgentDetail
    ? {
        keyValue: keyValueArray,
        agentCode: configAgentDetail.agentCode,
        type: configAgentDetail.type,
      }
    : initialState;
};

export type NewsCategoryErrorState = {
  mandatory: {
    agentCode: boolean;
    type: boolean;
  };
  immediate: {};
};

const NewsCategoryDetailForm: FC<NewsCategoryDetailFormProps> = ({ formMode, configAgentDetail, code, onRouteTo }) => {
  const dispatch = useDispatch();
  const { classes: commonClasses } = useCommonStyles();
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const [formState, formDispatch] = useReducer(
    configAgentReducer,
    configAgentDetail ? detailToStateConvertor(configAgentDetail) : initialState,
  );

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler } = useErrorHandler<NewsCategoryErrorState>(
    formState,
    [
      {
        name: 'agentCode',
        fieldType: ErrorFieldType.MANDATORY,
      },
      {
        name: 'type',
        fieldType: ErrorFieldType.MANDATORY,
      },
    ],
  );

  const onSubmit = async () => {
    let { hasError } = onSubmitErrorValidator();
    if (!hasError) {
      // Call Api and submit
      let keyValueJson: { [key: string]: string } = {};

      formState.keyValue?.map((item, index) => {
        if (item.key !== '' && item.value !== '') {
          return (keyValueJson[item.key] = item.value);
        }
        return true;
      });
      const details: ConfigAgentEditItem = {
        keyValue: keyValueJson,
        agentCode: formState.agentCode,
        type: formState.type,
      };
      try {
        if (formMode === ConfigAgentFormMode.CREATE) {
          await createConfigAgent(details, dispatch);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: `New agent config created successfully`,
              },
            ]),
          );
        } else if (code && formMode === ConfigAgentFormMode.EDIT) {
          await modifyConfigAgent(formState.agentCode, details, dispatch);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: `Agent config updated successfully `,
              },
            ]),
          );
        }
        onRouteTo(`${CONFIG_BASE_PATH}${CONFIG_AGENT_PATH}`);
      } catch (err) {}
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <div className={classes.rowContainer}>
          <div className={commonClasses.header}>
            {formMode === ConfigAgentFormMode.CREATE
              ? Translation('configAgent.common.newConfig')
              : Translation('configAgent.common.editConfig')}
          </div>
        </div>
        <Button
          variant="contained"
          color="inherit"
          onClick={() => {
            onRouteTo(`${CONFIG_BASE_PATH}${CONFIG_AGENT_PATH}`);
          }}
        >
          {Translation('app.button.back')}
        </Button>
      </div>
      <div className={classes.rowContainer}>
        <div className={classes.fieldContainer}>
          <span className={classes.field}>
            {Translation('configAgent.common.agentCode')}
            <span className={classes.mandatory}>*</span> :
          </span>
        </div>
        <div style={{ flexGrow: 1 }}>
          <TextField
            disabled={formMode === ConfigAgentFormMode.EDIT}
            error={errorState.mandatory.agentCode}
            margin="dense"
            variant="outlined"
            fullWidth
            helperText={errorState.mandatory.agentCode && MANDATORY_FIELD_ERROR_TEXT}
            value={formState.agentCode}
            onChange={(e) => {
              onDismissErrorHandler('agentCode', e.target.value);
              formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'agentCode', value: e.target.value } });
            }}
          />
        </div>
      </div>
      <div className={classes.rowContainer}>
        <div className={classes.fieldContainer}>
          <span className={classes.field}>
            {Translation('configAgent.common.type')}
            <span className={classes.mandatory}>*</span> :
          </span>
        </div>
        <div style={{ flexGrow: 1 }}>
          <TextField
            disabled={true}
            error={errorState.mandatory.type}
            margin="dense"
            variant="outlined"
            fullWidth
            helperText={errorState.mandatory.type && MANDATORY_FIELD_ERROR_TEXT}
            value={formState.type}
            onChange={(e) => {
              onDismissErrorHandler('type', e.target.value);
              formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'type', value: e.target.value } });
            }}
          />
        </div>
      </div>

      <div className={classes.rowContainer} style={{ alignItems: 'start' }}>
        <div className={classes.fieldContainer}>
          <span className={classes.field}>{Translation('configAgent.common.keyValue')}:</span>
        </div>
        <DisplayKeyValueTable
          isDisabled={false}
          className={classes.settingTable}
          keyValue={formState.keyValue || []}
          onMoveRow={(from, to) => {
            return true;
          }}
          onAddRow={() => formDispatch({ type: 'ADD_KEYVALUE_ROW' })}
          onModifyField={(index, field, value) =>
            formDispatch({ type: 'MODIFY_KEYVALUE_ROW', payload: { index, field, value } })
          }
          onDeleteRow={(index) => formDispatch({ type: 'DELETE_KEYVALUE_ROW', payload: { index } })}
        />
      </div>

      <div className={classes.footerContainer}>
        <Button variant="contained" color="secondary" onClick={onSubmit}>
          {Translation('app.button.submit')}
        </Button>
      </div>
    </div>
  );
};

export default NewsCategoryDetailForm;
