import React, { FC, useReducer, useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ErrorFieldType, useErrorHandler } from 'src/app/common/utils';
import {
  InsuranceSummaryBody,
  InsuranceSummaryDetail,
  InsuranceSummaryErrorState,
} from 'src/app/modules/sales-kit/pages/insurance-summary/type/insurance-summary-types';
import { AlertType, appendAlertItem } from 'src/redux/common/commonSlice';
import { submitInsuranceSummary } from '../network/insuranceSummaryCrud';
import { detailToStateConvertor, formReducer } from '../redux/insuranceSummaryState';
import InsuranceSummaryConfig from './config/InsuranceSummaryConfig';

type InsuranceSummaryFromProps = {
  insuranceSummary: InsuranceSummaryDetail;
};
const InsuranceSummaryFrom = ({ insuranceSummary }: InsuranceSummaryFromProps) => {
  const dispatch = useDispatch();
  const [formState, formDispatch] = useReducer(formReducer, detailToStateConvertor(insuranceSummary));

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler, immediateErrorValidator } =
    useErrorHandler<InsuranceSummaryErrorState>(formState, [
      {
        name: 'disclaimer',
        fieldType: ErrorFieldType.MANDATORY,
      },
    ]);
  const onSubmitInsuranceSummary = async () => {
    const { hasError } = onSubmitErrorValidator();
    console.log('if has error', hasError);

    if (!hasError) {
      const summaryBody: InsuranceSummaryBody = {
        disclaimer: formState.disclaimer || [],
        completeMessage: formState.completeMessage,
        commentaryMessage: formState.commentaryMessage,
        bannerId: formState.bannerId,
      };

      await submitInsuranceSummary(summaryBody, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Insurance summary saved successfully`,
          },
        ]),
      );
    }
  };
  return (
    <>
      <InsuranceSummaryConfig
        formState={formState}
        errorState={errorState}
        onDismissErrorHandler={onDismissErrorHandler}
        onSubmit={onSubmitInsuranceSummary}
        onChangeImage={(image) => {
          formDispatch({
            type: 'MODIFY_FIELD',
            payload: {
              field: 'bannerData',
              value: image,
            },
          });
        }}
        onChangeImageId={(bannerId) => {
          formDispatch({
            type: 'MODIFY_FIELD',
            payload: {
              field: 'bannerId',
              value: bannerId,
            },
          });
        }}
        onChangeThankyouPageContent={(content) => {
          formDispatch({
            type: 'MODIFY_FIELD',
            payload: {
              field: 'completeMessage',

              value: content,
            },
          });
        }}
        onChangeDisclaimer={(disclaimer) => {
          formDispatch({
            type: 'MODIFY_FIELD',
            payload: { field: 'disclaimer', value: disclaimer },
          });
        }}
        onChangeCommentary={(commentary) => {
          formDispatch({
            type: 'MODIFY_FIELD',
            payload: { field: 'commentaryMessage', value: commentary },
          });
        }}
      />
    </>
  );
};

export default InsuranceSummaryFrom;
