import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { useDataProvider } from 'src/app/common/utils';
import { MicrositePaginateList, MicrositeQueryParams } from '../../../types/microsite.type';
import { fetchMicrositeList } from '../../../network/api';
import MicrositeList from './components/microsite-list';
type RuleSettingPageProps = any & ParamsProps;

const List: FC<RuleSettingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const [filterState, setFilterState] = useState<MicrositeQueryParams>({ pagination: { page: 1, limit: 5 } });
  const [sortKey, setSortKey] = useState<{ key: string; value?: string }[]>([]);
  const [list, setList] = useState<MicrositePaginateList>();

  const updateSortingKey = (sortingKey: { key: string; value?: string }) => {
    const sortingKeyArray = sortKey.filter((currentValue, index, arr) => {
      return currentValue.key !== sortingKey.key;
    });
    sortingKeyArray.unshift(sortingKey);
    setSortKey(sortingKeyArray);
  };
  const { isLoading, refreshData } = useDataProvider<MicrositePaginateList>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchMicrositeList(filterState, sortKey, dispatch);
      } catch (err) {}
    },
    setList,
    false,
  );
  React.useEffect(() => {
    refreshData();
  }, [sortKey]);

  return (
    <>
      <PruFilter
        title={Translation('microsite_filter')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'refId',
            displayName: Translation('microsite_id'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'title',
            displayName: Translation('microsite_title'),
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            refId: data.refId,
            title: data.title,
          })
        }
        fetchData={refreshData}
      />
      <MicrositeList
        isLoading={isLoading}
        onRefresh={refreshData}
        list={list}
        onSort={(dataKey) => updateSortingKey(dataKey)}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            pagination: { page: page, limit: rowsPerPage },
          });
          refreshData();
        }}
      />
    </>
  );
};

export default List;
