import { Dispatch } from 'react';
import { forEach } from 'lodash';
import { getDayStart, getDayEnd } from 'src/app/common/utils';
import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import {
  NewsCategoryPaginateList,
  NewsArticlePaginateList,
  NewsArticleItem,
  NewsCategoryItem,
  NewsCategoryEditItem,
  NewsArticleEditItem,
  NewsHeadlineItem,
  NewsHeadlinePaginateList,
} from '../types/news-type';

export type NewsCategoryListParam = {
  limit: number;
  page: number;
};

export type NewsCategoryTypeParam = {
  category?: string;
};

export type NewsArticleListParam = {
  limit: number;
  page: number;
  categoryId: string;
  search: string;
  isPublished: string;
  isHeadline: string;
  isArchived: string;
};

export type NewsHeadlineListParam = {
  limit: number;
  page: number;
};

export const fetchNewsCategoryList = async (
  params: NewsCategoryListParam,
  dispatch?: Dispatch<any>,
): Promise<NewsCategoryPaginateList> => {
  let queryPath = `${ADMIN_URL}/news/categories?`;
  forEach(params, (param, key) => {
    queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
  });
  return apiClient
    .get<NewsCategoryPaginateList>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const modifyNewsCategory = async (
  id: string,
  body: NewsCategoryEditItem,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .patch<void>(`${ADMIN_URL}/news/categories/${id}`, body, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const moveNewsCategoryUpward = async (id: string, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .post<void>(
      `${ADMIN_URL}/news/categories/${id}/up`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const moveNewsCategoryDownward = async (id: string, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .post<void>(
      `${ADMIN_URL}/news/categories/${id}/down`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchNewsCategoryItem = async (id: string, dispatch?: Dispatch<any>): Promise<NewsCategoryItem> => {
  return apiClient
    .get<NewsCategoryItem>(`${ADMIN_URL}/news/categories/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchNewsArticleList = async (
  params: NewsArticleListParam,
  dispatch?: Dispatch<any>,
): Promise<NewsArticlePaginateList> => {
  let queryPath = `${ADMIN_URL}/news?`;
  forEach(params, (param, key) => {
    if (typeof param === 'object') {
      if (param) {
        if (key.toLowerCase().includes('from')) {
          queryPath += `${key}=${encodeURIComponent(getDayStart(param).toISOString())}&`;
        }
        if (key.toLowerCase().includes('to')) {
          queryPath += `${key}=${encodeURIComponent(getDayEnd(param).toISOString())}&`;
        }
      }
    } else {
      queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
    }
  });
  return apiClient
    .get<NewsArticlePaginateList>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchNewsArticleItem = async (id: string, dispatch?: Dispatch<any>): Promise<NewsArticleItem> => {
  return apiClient
    .get<NewsArticleItem>(`${ADMIN_URL}/news/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createNewNewsArticle = async (
  data: NewsArticleEditItem,
  dispatch?: Dispatch<any>,
): Promise<NewsArticleItem> => {
  return apiClient
    .post<NewsArticleItem>(`${ADMIN_URL}/news`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const modifyNewsArticle = async (
  code: string,
  data: NewsArticleEditItem,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .put<void>(`${ADMIN_URL}/news/${code}`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createAndApproveNewNewsArticle = async (
  data: NewsArticleEditItem,
  dispatch?: Dispatch<any>,
): Promise<NewsArticleItem> => {
  return apiClient
    .post<NewsArticleItem>(`${ADMIN_URL}/news/approve`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const modifyAndApproveNewsArticle = async (
  code: string,
  data: NewsArticleEditItem,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .put<void>(`${ADMIN_URL}/news/approve/${code}`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchNewsHeadlineList = async (
  params: NewsHeadlineListParam,
  dispatch?: Dispatch<any>,
): Promise<NewsHeadlinePaginateList> => {
  let queryPath = `${ADMIN_URL}/news/headline?`;
  forEach(params, (param, key) => {
    queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
  });
  return apiClient
    .get<NewsHeadlinePaginateList>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const setNewsAsHeadline = async (data: NewsHeadlineItem, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .put<void>(`${ADMIN_URL}/news/headline`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const unsetNewsHeadline = async (newsId: string, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .delete<void>(`${ADMIN_URL}/news/headline/${newsId}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const moveNewsHeadlineUpward = async (data: NewsHeadlineItem, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .post<void>(`${ADMIN_URL}/news/headline/up`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const moveNewsHeadlineDownward = async (data: NewsHeadlineItem, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .post<void>(`${ADMIN_URL}/news/headline/down`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
