import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import PruRoute from '../../../common/components/PruRoute';
import { PUBLIC_PORTAL_CONSENT_BASE_PATH } from '../constants';
import { NOT_FOUND_ERROR_PAGE_PATH } from '../../../common/constants';
import PublicPortalConsentDetailPage from './consent-detail/public-portal-consent-page';

const PublicPortalConsentRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect exact from={`${PUBLIC_PORTAL_CONSENT_BASE_PATH}`} to={`${PUBLIC_PORTAL_CONSENT_BASE_PATH}/detail`} />
      <PruRoute exact path={`${PUBLIC_PORTAL_CONSENT_BASE_PATH}/detail`} component={PublicPortalConsentDetailPage} />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default PublicPortalConsentRoutes;
