import React, { FC, useEffect } from 'react';
import { SurveyModel } from 'survey-react';
import { VisualizationPanel } from 'survey-analytics';
import 'survey-analytics/survey.analytics.css';

type SurveyAnalyticsProps = {
  questions: Record<string, unknown>;
  results: Record<string, unknown>[];
  options?: Record<string, unknown>;
};

export enum CUSTOM_OPTION {
  HIDE_INVISIBLE_QUESTION = 'hideInvisibleQuestion',
}

const SurveyAnalytics: FC<SurveyAnalyticsProps> = ({ questions, results, options }) => {
  useEffect(() => {
    const survey = new SurveyModel(questions);
    let questionData = survey.getAllQuestions();
    questionData = questionData.filter((question) => {
      if (options && options[CUSTOM_OPTION.HIDE_INVISIBLE_QUESTION]) {
        return question.visible;
      }
      return true;
    });

    const visualPanel = new VisualizationPanel(questionData, results, options);
    const container = document.getElementById('summary-container');
    if (container) {
      visualPanel.render(container);
    }
    // eslint-disable-next-line
  }, []);

  return <div id="summary-container" />;
};

export default SurveyAnalytics;
