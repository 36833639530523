import { useDispatch } from 'react-redux';
import { Button } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { surveyLocalization, Serializer } from 'survey-core';
import 'survey-core/defaultV2.css';
import 'survey-core/survey.i18n';
import { ICreatorOptions, settings as SurveyCreatorSetting } from 'survey-creator-core';
import 'survey-creator-core/survey-creator-core.css';
import './survey-patch-style.css';
import { SurveyCreator as SurveyJSCreator, SurveyCreatorComponent } from 'survey-creator-react';
import './survey-patch-style.css';
import { regionLocale } from 'src/app/i18n';
import { useIntl } from 'react-intl';
import { handleFile } from 'src/app/common/components/survey-v2/survey-utils';
import { defaultRegistrationFormQuestionNames } from './constants';

interface localeInterface {
  [key: string]: string;
}

const localeMap: localeInterface = {
  'zh-Hant': 'zh-tw',
  en: 'default',
};

const localeList = regionLocale;

//format locale to surveyjs format
const getLocale = (locale: string) => {
  if (localeMap[locale]) {
    return localeMap[locale];
  }
  return locale;
};

export const supportedLocales = localeList.map((locale) => getLocale(locale));

const initSurveyCreator = () => {
  surveyLocalization.supportedLocales = supportedLocales;
  surveyLocalization.defaultLocale = 'en';
  surveyLocalization.currentLocale = 'en';
  Serializer.removeProperty('survey', 'logo');
};

interface I18nData {
  default: string;
  [key: string]: string;
}

interface Pages {
  name: string;
  title: I18nData;
  elements: any[];
  fieldType?: string;
  state: string;
}

interface Content {
  pages: Pages[];
}

type SurveyCreatorProps = {
  content: Content;
  onChangeContent: (content: Record<string, unknown>) => void;
  module: string;
  saveVisible?: boolean;
  publishStatus?: string;
};

const options: ICreatorOptions = {
  showJSONEditorTab: false,
  showTranslationTab: true,
  // questionTypes: enabledQuestionTypes,
  // pageEditMode: 'bypage',
  isAutoSave: false,
};

const SurveyCreator: FC<SurveyCreatorProps> = ({ content, onChangeContent, saveVisible, module, publishStatus }) => {
  const dispatch = useDispatch();
  const [creator, setCreator] = useState(new SurveyJSCreator(options));
  creator.haveCommercialLicense = true;
  creator.showSurveyTitle = true;
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);

  const handleSave = () => {
    const json = creator.JSON;
    const saveData = {
      content: json,
    };
    onChangeContent(saveData);
  };

  //replace the key by localeMap
  const replaceKeys = (obj: any): any => {
    if (typeof obj === 'object') {
      if (Array.isArray(obj)) {
        return obj.map((item) => replaceKeys(item));
      } else {
        const modifiedObj: any = {};
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            let newKey = key;
            if (localeMap.hasOwnProperty(key)) {
              newKey = localeMap[key];
            }
            modifiedObj[newKey] = replaceKeys(obj[key]);
          }
        }
        return modifiedObj;
      }
    } else {
      return obj;
    }
  };

  useEffect(() => {
    initSurveyCreator();
  }, []);

  creator.showSaveButton = false;
  useEffect(() => {
    creator.toolbox.removeItem('file');

    creator.onElementAllowOperations.add(function (_, options) {
      options.allowCopy = false;
      if (
        !saveVisible ||
        defaultRegistrationFormQuestionNames.includes(options.obj.name) ||
        options.obj.name?.startsWith('register_session')
      ) {
        options.allowDelete = false;
        options.allowDragging = false;
        options.allowChangeType = false;
        options.allowChangeInputType = false;
        options.allowChangeRequired = false;
      }
    });
    creator.onGetPropertyReadOnly.add(function (_, options) {
      if (
        !saveVisible ||
        defaultRegistrationFormQuestionNames.includes(options.obj.name) ||
        options.obj.name?.startsWith('register_session')
      ) {
        options.readOnly = true;
      } else {
        options.readOnly = false;
      }
    });
    creator.onQuestionAdded.add(function (_, options) {
      options.question.locRequiredErrorText.setLocaleText('default', 'Field is required');
      options.question.locRequiredErrorText.setLocaleText('zh-tw', '欄位為必填項');
    });

    creator.onTranslationLocaleInitiallySelected.add(function (_, options) {
      options.isSelected = supportedLocales.includes(options.locale);
    });

    creator.onUploadFile.add((_, option) => {
      option.files.forEach(async (file: any) => {
        const result = await handleFile(file, module, dispatch);
        option.callback('success', result?.url);
      });
    });

    if (publishStatus === 'PUBLISHED' || !saveVisible) {
      creator.onShowingProperty.add(function (_, options) {
        options.canShow = false;
      });
      SurveyCreatorSetting.designer.showAddQuestionButton = false;
    } else {
      SurveyCreatorSetting.designer.showAddQuestionButton = true;
    }

    const surveyContent = replaceKeys(content);
    if (content) {
      creator.text = JSON.stringify(surveyContent);
    } else {
      creator.JSON = {};
    }
    creator.saveSurveyFunc = () => handleSave();

    setCreator(creator);
  }, [content]);

  return (
    <div>
      <div id="event">
        <SurveyCreatorComponent creator={creator} />
      </div>
      {saveVisible && (
        <div className="tw-flex tw-pb-6 tw-items-center tw-justify-center tw-pt-6 tw-overflow-y-auto">
          <Button variant="contained" color="secondary" onClick={handleSave}>
            {Translation('app.button.save_and_continue')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default SurveyCreator;
