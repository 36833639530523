import { Document, Image, Page, Text } from '@react-pdf/renderer';

type QRCodePDFProps = {
  qrCodeDataURL: string;
  eventName?: string;
};

export const QRCodePDF = ({ qrCodeDataURL, eventName = '' }: QRCodePDFProps) => (
  <Document>
    <Page>
      <Image style={{ width: 480, height: 480, alignSelf: 'center' }} src={qrCodeDataURL} />
      <Text style={{ textAlign: 'center' }}>{eventName}</Text>
    </Page>
  </Document>
);
