import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import CategoryPage from '../../../components/Category/pages/List';
import { ModulePermissionProps } from '../../../../../common/module/types';

const SystemConfig = {
  filterName: 'component.filterTitle.resource-category',
  blockName: 'component.formTitle.resource-category-table',
  blockType: 'resourceCategory',
  moduleType: 'Recruit',
  addSuccessText: 'global.submit.success',
};

const RecruitCategoryPage: FC<RouteComponentProps & ModulePermissionProps> = (props) => {
  return <CategoryPage {...props} {...SystemConfig} />;
};

export default RecruitCategoryPage;
