import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { getDefaultDisplayDate } from '../../../../../../../common/utils';
import {
  FormTemplateItem,
  FormTemplatePublishStatus,
  FormTemplateStatus,
  PaginateList,
} from '../../../../../types/form-template-types';
import { formTemplatePath } from '../../form-template-routes';
import OperateDialog from './operate-dialog';

type FormTemplateListProps = {
  isLoading: boolean;
  formTemplateList?: PaginateList<FormTemplateItem>;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
  onSort: (newSortState: { key: string; value?: string }) => void;
  publish: (templateId: string) => void;
  unpublish: (templateId: string) => void;
  deleteTemplate: (templateId: string) => void;
};

// export const templateTypeTranslation = 'Recruitment.onboarding_type_';

enum OperateType {
  PUBLISH = 'publish',
  UNPUBLISH = 'unpublish',
}

type DialogState = {
  open: boolean;
  row: any;
  type: OperateType;
};

export const FormTemplateList: FC<FormTemplateListProps> = ({
  isLoading,
  formTemplateList,
  onRefresh,
  onChangePage,
  onSort,
  deleteTemplate,
  publish,
  unpublish,
}) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const history = useHistory();
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogContent, setDialogContent] = useState<string>('');
  const [dialogOperateTxt, setDialogOperateTxt] = useState<string>('');
  const [dialogState, setDialogState] = useState<DialogState>({
    open: false,
    row: undefined,
    type: OperateType.PUBLISH,
  });

  useEffect(() => {
    setDialogTitle(
      dialogState.type == OperateType.PUBLISH
        ? Translation('publish_form_template_title')
        : Translation('unpublish_form_template_title'),
    );
    setDialogContent(
      dialogState.type == OperateType.PUBLISH
        ? intl.formatMessage(
            { id: 'publish_form_template_content' },
            {
              validFrom: getDefaultDisplayDate(dialogState?.row?.validDateFrom),
              validTo: getDefaultDisplayDate(dialogState?.row?.validDateTo),
            },
          )
        : Translation('unpublish_form_template_content'),
    );
    setDialogOperateTxt(
      dialogState.type == OperateType.PUBLISH
        ? Translation('publish_form_template_btn')
        : Translation('unpublish_form_template_btn'),
    );
  }, [dialogState]);

  const confirmTemplate = () => {
    if (dialogState.type === OperateType.PUBLISH) {
      publish(dialogState?.row.templateId);
    } else {
      unpublish(dialogState?.row.templateId);
    }
  };

  return (
    <>
      <OperateDialog
        open={dialogState.open}
        onClose={() => setDialogState({ open: false, row: undefined, type: OperateType.PUBLISH })}
        onRefresh={onRefresh}
        onConfirm={confirmTemplate}
        title={dialogTitle}
        content={dialogContent}
        confirmTxt={dialogOperateTxt}
      />
      <PruTable
        title={Translation('form.template.list')}
        disableBulkSelect
        headerBtnDef={[
          {
            color: 'secondary',
            title: 'ADD FORM',
            onClick: () => {
              history.push(`${formTemplatePath}/detail?action=create`);
            },
          },
        ]}
        operationDef={[
          {
            title: Translation('section.common.operation.view'),
            tooltipText: 'View',
            onClick: (row) => history.push(`${formTemplatePath}/detail/${row.templateId}?action=view`),
            condition: (row) => row.publishStatus !== FormTemplatePublishStatus.DRAFT.toUpperCase(),
          },
          {
            title: Translation('section.common.operation.edit'),
            tooltipText: 'Edit',
            onClick: (row) => history.push(`${formTemplatePath}/detail/${row.templateId}?action=edit`),
            condition: (row) => row.publishStatus === FormTemplatePublishStatus.DRAFT.toUpperCase(),
          },
          {
            title: Translation('section.common.operation.publish'),
            tooltipText: 'Publish',
            onClick: (row) => setDialogState({ open: true, row: row, type: OperateType.PUBLISH }),
            condition: (row) => row.publishStatus === FormTemplatePublishStatus.DRAFT.toUpperCase(),
          },
          {
            title: Translation('section.common.operation.unpublish'),
            tooltipText: 'Unpublish',
            onClick: (row) => setDialogState({ open: true, row: row, type: OperateType.UNPUBLISH }),
            condition: (row) =>
              row.status === FormTemplateStatus.INACTIVE.toUpperCase() &&
              row.publishStatus === FormTemplatePublishStatus.PUBLISHED.toUpperCase(),
          },
          {
            title: Translation('section.common.operation.duplicate'),
            tooltipText: 'Duplicate',
            onClick: (row) =>
              history.push(`${formTemplatePath}/detail/${row.templateId}?action=duplicate&type=${row.type}`),
            condition: (row) => row.publishStatus !== FormTemplatePublishStatus.DRAFT.toUpperCase(),
          },
          {
            title: Translation('section.common.operation.delete'),
            tooltipText: 'Delete',
            onClick: (row) => deleteTemplate(row.templateId),
            condition: (row) => row.publishStatus === FormTemplatePublishStatus.DRAFT.toUpperCase(),
          },
        ]}
        columnDef={[
          {
            isId: true,
            hidden: true,
            keyIndex: 'templateId',
            displayName: '',
            renderData: () => '',
          },
          {
            keyIndex: 'type',
            displayName: Translation('onboarding.common.formType'),
            renderData: (row) => (row.type ? Translation(row.type) : '-'),
          },
          {
            keyIndex: 'displayVersion',
            displayName: Translation('onboarding.common.displayVersion'),
            renderData: (row) => row.version || '-',
          },
          {
            keyIndex: 'validDateFrom',
            displayName: Translation('onboarding.common.validDateFrom'),
            renderData: (row) => getDefaultDisplayDate(row.validDateFrom) || '-',
          },
          {
            keyIndex: 'validDateTo',
            displayName: Translation('onboarding.common.validDateTo'),
            renderData: (row) => getDefaultDisplayDate(row.validDateTo) || '-',
          },
          {
            keyIndex: 'createdBy',
            displayName: Translation('onboarding.common.createdBy'),
            renderData: (row) => row.createdBy || '-',
          },
          {
            keyIndex: 'lastUpdatedBy',
            displayName: Translation('list.common.lastUpdatedBy'),
            renderData: (row) => row.lastUpdatedBy || '-',
          },
          {
            keyIndex: 'createdAt',
            displayName: Translation('onboarding.common.createdAt'),
            renderData: (row) => getDefaultDisplayDate(row.createdAt, 'datetime') || '-',
          },
          {
            keyIndex: 'lastUpdatedAt',
            displayName: Translation('onboarding.common.lastUpdatedAt'),
            renderData: (row) => getDefaultDisplayDate(row.lastUpdatedAt, 'datetime') || '-',
            sortable: true,
            onSort: (sort) => {
              onSort({ key: 'LAST_UPDATED_AT', value: sort['lastUpdatedAt'] });
            },
          },
          {
            keyIndex: 'publishedAt',
            displayName: Translation('onboarding.common.publishedAt'),
            renderData: (row) => getDefaultDisplayDate(row.publishedAt, 'datetime') || '-',
          },
          {
            keyIndex: 'formStatus',
            displayName: Translation('onboarding.common.formStatus'),
            renderData: (row) =>
              row.status ? Translation(`onboarding.common.form_status.${row.status.toLowerCase()}`) : '-',
          },
          {
            keyIndex: 'publishStatus',
            displayName: Translation('onboarding.common.publishStatus'),
            renderData: (row) =>
              row.publishStatus
                ? Translation(`onboarding.common.publish_status.${row.publishStatus.toLowerCase()}`)
                : '-',
          },
        ]}
        isLoading={isLoading}
        onRefresh={onRefresh}
        dataSource={formTemplateList?.data || []}
        totalPages={formTemplateList?.totalPages}
        totalRecords={formTemplateList?.totalNumbers}
        onChangePage={onChangePage}
      />
    </>
  );
};
