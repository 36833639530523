import { CSSProperties } from 'react';
import { withStyles } from 'tss-react/mui';
import { TableCell } from '@mui/material';


const StickyTableCell = withStyles(TableCell, (theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    right: 0,
    position: "sticky",
    zIndex: 10,
    textAlign: 'center'
  },
  body: {
    backgroundColor: theme.palette.common.white,
    right: 0,
    position: "sticky",
    zIndex: 11,
    textAlign: 'center',
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    backgroundClip: 'padding-box',
    transform: 'scale(0.999)'
  },
} as Record<string, CSSProperties>));

export default StickyTableCell;
