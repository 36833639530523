import React, { FC, useEffect } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import PruRoute from '../../../../../common/components/PruRoute';
import { SALESKIT_BASE_PATH, RECRUIT_BASE_PATH, RECRUIT_LIBRARY_BASE_PATH } from '../../../constants';
import RecruitLibraryCreatePage from './RecruitLibraryCreatePage';
import { NOT_FOUND_ERROR_PAGE_PATH } from '../../../../../common/constants';
import RecruitLibraryListPage from './RecruitLibraryListPage';
import { ModulePermissionProps } from '../../../../../common/module/types';
import { RecruitLibraryPerformancePage } from './RecruitLibraryPerformancePage';
import { resetState } from '../../../redux/saleskit.slice';
import { useDispatch } from 'react-redux';

const RecruitLibraryRoutes: FC<RouteComponentProps & ModulePermissionProps> = ({
  enableRead,
  enableCreate,
  enableUpdate,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      // clear list state
      dispatch(resetState());
    };
  }, []);
  return (
    <Switch>
      <Redirect
        exact
        from={`${SALESKIT_BASE_PATH}${RECRUIT_BASE_PATH}${RECRUIT_LIBRARY_BASE_PATH}`}
        to={`${SALESKIT_BASE_PATH}${RECRUIT_BASE_PATH}${RECRUIT_LIBRARY_BASE_PATH}/list`}
      />
      <PruRoute
        exact
        path={`${SALESKIT_BASE_PATH}${RECRUIT_BASE_PATH}${RECRUIT_LIBRARY_BASE_PATH}/list`}
        render={(props) => (
          <RecruitLibraryListPage
            {...props}
            enableRead={enableRead}
            enableCreate={enableCreate}
            enableUpdate={enableUpdate}
          />
        )}
      />
      <PruRoute
        exact
        path={`${SALESKIT_BASE_PATH}${RECRUIT_BASE_PATH}${RECRUIT_LIBRARY_BASE_PATH}/create`}
        component={RecruitLibraryCreatePage}
      />
      <PruRoute
        exact
        path={`${SALESKIT_BASE_PATH}${RECRUIT_BASE_PATH}${RECRUIT_LIBRARY_BASE_PATH}/edit/:id`}
        component={RecruitLibraryCreatePage}
      />
      <PruRoute
        exact
        path={`${SALESKIT_BASE_PATH}${RECRUIT_BASE_PATH}${RECRUIT_LIBRARY_BASE_PATH}/view/:id`}
        component={RecruitLibraryCreatePage}
      />
      <PruRoute
        exact
        path={`${SALESKIT_BASE_PATH}${RECRUIT_BASE_PATH}${RECRUIT_LIBRARY_BASE_PATH}/performance/:id`}
        component={RecruitLibraryPerformancePage}
      />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default RecruitLibraryRoutes;
