import React, { FC, useEffect } from 'react';
import { SurveyModel } from 'survey-react';
import { VisualizationPanel } from 'survey-analytics';
import 'survey-analytics/survey.analytics.css';
import { makeStyles } from 'tss-react/mui';
import { Button } from '@mui/material';
import { SurveySummaryResult } from 'src/app/modules/Survey/types/survey-types';
import { useIntl } from 'react-intl';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { TypeOf } from 'yup';
type SurveySummaryProp = {
  summary: SurveySummaryResult;
};

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: '#F7F7F7',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 350,
    },
    [theme.breakpoints.between('sm', 'xl')]: {
      flex: 0.4,
    },
    '@media (min-width: 1280px)': {
      flex: 0.3,
    },
  },

  headerContainer: {
    display: 'flex',
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
  },
  summaryItem: {
    [theme.breakpoints.down('sm')]: {
      minWidth: 70,
    },
    [theme.breakpoints.between('sm', 'xl')]: {
      minWidth: 100,
    },
    '@media (min-width: 1280px)': {
      minWidth: 100,
    },
  },
  summarySubtext: {
    fontFamily: '-apple-system, BlinkMacSystemFont, sans-serif',
    fontSize: '14px',
    lineHeight: '22px',
    color: '#999999',
    fontWeight: 400,
  },

  summaryText: {
    fontWeight: 600,
    fontSize: '24px',
    fontFamily: 'Montserrat',
    lineHeight: '30px',
    color: '#333333',
  },
}));

interface SummaryType {
  totalRecords: string;
  totalUnread: string;
  totalIncomplete: string;
  totalSubmits: string;
  totalViews: string;
}
let summaryResult: SummaryType = {
  totalRecords: 'survey.viewResults.totalRecords',
  totalUnread: 'survey.viewResults.totalUnread',
  totalIncomplete: 'survey.viewResults.totalIncomplete',
  totalSubmits: 'survey.viewResults.totalSubmits',
  totalViews: 'survey.viewResults.totalViews',
};
let orderArray = Object.keys(summaryResult);

const SurveySummary = ({ summary }: SurveySummaryProp) => {
  const { classes } = useStyles();
  const { classes: commonClasses } = useCommonStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const SummaryForm = (data: SurveySummaryResult) => {
    let result: React.ReactNode[] = [];
    let item: string | boolean;

    for (let [key, value] of Object.entries(data)) {
      let index = orderArray.indexOf(key);
      item = !!summaryResult[key as keyof SummaryType] && summaryResult[key as keyof SummaryType];

      item &&
        result.insert(
          index,
          <div key={key} className={classes.summaryItem}>
            <div className={classes.summaryText}>{item && value}</div>
            <div className={classes.summarySubtext}>{item && Translation(item)}</div>
          </div>,
        );
    }

    return result;
  };

  return (
    <div className={classes.headerContainer}>
      <div className={classes.container}>
        <div className={commonClasses.subHeader}>{Translation('survey.viewResults.title')}</div>
        <div className={classes.rowContainer}>{SummaryForm(summary)}</div>
      </div>
    </div>
  );
};

export default SurveySummary;
