import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import PruRoute from 'src/app/common/components/PruRoute';

import ParamsProvider from 'src/app/common/components/ParamsProvider';
import { agentAwardFilterKeys, AwardListingPage } from './List/AwardListingPage';
import { AddAwardListPage } from './Form/AddAwardListPage';
import { AddNewAwardPage } from './Form/AddNewAwardPage';
import { PROFILE_AGENT_PATH, PROFILE_AWARD_PATH, PROFILE_BASE_PATH } from '../../../constants';

export const awardListPath = `${PROFILE_BASE_PATH}${PROFILE_AGENT_PATH}${PROFILE_AWARD_PATH}`;

const AwardListRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect exact from={awardListPath} to={`${awardListPath}/list`} />
      <PruRoute
        exact
        path={`${awardListPath}/list`}
        render={(props) => (
          <ParamsProvider {...props} acceptKeys={agentAwardFilterKeys}>
            <AwardListingPage />
          </ParamsProvider>
        )}
      />
      <PruRoute exact path={`${awardListPath}/addList`} component={AddAwardListPage} />
      <PruRoute exact path={`${awardListPath}/addAward`} component={AddNewAwardPage} />

      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default AwardListRoutes;
