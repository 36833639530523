import { Dispatch } from 'react';
import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import { RuleItem, RuleSetItem } from '../types/rule-types';

export type RuleSetBody = {
  name: string;
  items: RuleItem[];
};

export const fetchLeadsRuleList = async (dispatch?: Dispatch<any>): Promise<RuleSetItem[]> => {
  return apiClient
    .get(`${ADMIN_URL}/rule`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createLeadsRuleItem = async (body: RuleSetBody, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .post<void>(`${ADMIN_URL}/rule`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const downloadLeadsRuleCsv = async (id: string, dispatch?: Dispatch<any>): Promise<string> => {
  return apiClient
    .get<string>(`${ADMIN_URL}/rule/${id}/download`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const modifyLeadsRuleItem = async (id: string, body: RuleSetBody, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .put<void>(`${ADMIN_URL}/rule/${id}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const deleteLeadsRuleItem = async (id: string, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .delete<void>(`${ADMIN_URL}/rule/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
