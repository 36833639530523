import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { ConfigAgentItem } from 'src/app/modules/Config/types/config-type';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { CONFIG_BASE_PATH, CONFIG_AGENT_PATH } from 'src/app/modules/Config/constants';
import { getDefaultDisplayDate } from 'src/app/common/utils';

type AgentListProps = {
  isLoading: boolean;
  configAgentList?: ConfigAgentItem[];
  onRefresh: () => void;
};

const AgentList: FC<AgentListProps> = ({ isLoading, configAgentList, onRefresh }) => {
  const history = useHistory();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  return (
    <PruTable
      disableBulkSelect
      disablePagination
      dataSource={configAgentList}
      isLoading={isLoading}
      onRefresh={onRefresh}
      title={Translation('configAgent.list')}
      headerBtnDef={[
        {
          color: 'primary',
          title: Translation('configAgent.common.newConfig'),
          onClick: () => history.push(`${CONFIG_BASE_PATH}${CONFIG_AGENT_PATH}/create`),
        },
      ]}
      operationDef={[
        {
          title: Translation('section.common.operation.edit'),
          tooltipText: 'Edit Application',
          onClick: (row) => history.push(`${CONFIG_BASE_PATH}${CONFIG_AGENT_PATH}/edit/${row.agentCode}`),
        },
      ]}
      columnDef={[
        {
          isId: true,
          hidden: true,
          keyIndex: '_id',
          displayName: '',
          renderData: () => '',
        },
        {
          keyIndex: 'agentCode',
          align: 'center',
          displayName: Translation('configAgent.common.agentCode'),
          renderData: (row) => row.agentCode || '-',
        },
        {
          keyIndex: 'type',
          align: 'center',
          displayName: Translation('configAgent.common.type'),
          renderData: (row) => row.type || '-',
        },
        {
          keyIndex: 'keyValue',
          align: 'center',
          displayName: Translation('configAgent.common.keyValue'),
          renderData: (row) => (
            <>
              {row.keyValue &&
                Object.entries(row.keyValue).map((item, index) => {
                  return (
                    <div style={{ display: 'flex', flexDirection: 'row' }} key={`keyValue-${index}`}>
                      <div style={{ width: '47%', textAlign: 'right' }}>{item[0]}</div>
                      <div style={{ width: '6%', textAlign: 'center' }}> : </div>
                      <div>{item[1]}</div>
                    </div>
                  );
                })}
            </>
          ),
        },
        {
          keyIndex: 'createdAt',
          align: 'center',
          displayName: Translation('configAgent.common.createdAt'),
          renderData: (row) => getDefaultDisplayDate(row.createdAt, 'datetime'),
        },
        {
          keyIndex: 'updatedAt',
          align: 'center',
          displayName: Translation('configAgent.common.updatedAt'),
          renderData: (row) => getDefaultDisplayDate(row.updatedAt, 'datetime'),
        },
      ]}
    />
  );
};

export default AgentList;
