import React, { FC, useState, useEffect } from 'react';
import NotificationList from './components/notificationList';
import NotificationFilter from './components/notificationFilter';
import OnTop from '../../OnTop';
import { RouteComponentProps } from 'react-router-dom';
import { NOTIFICATION_BASE_PATH, NOTIFICATION_MANUAL_PATH } from '../../../constants';
import { fetchNotificationList, NotificationListParams } from '../../../redux/notificationCrud';
import { useDispatch } from 'react-redux';
import { PaginateList, NotificationItem } from '../../../types/notification-types';
import { useDataProvider } from 'src/app/common/utils';
import { ModulePermissionProps } from '../../../../../common/module/types';
import { fetchDesignationAndZone } from 'src/app/common/network';

const NotificationListingPage: FC<RouteComponentProps & ModulePermissionProps> = ({
  history,
  enableRead,
  enableCreate,
  enableUpdate,
}) => {
  const dispatch = useDispatch();
  const [isOpen, openModal] = useState<boolean>(false);
  const [rowData, setRowData] = useState<NotificationItem>();
  const [filterState, setFilterState] = useState<NotificationListParams>({
    pageNum: 1,
    pageSize: 20,
    from: 'PORTAL',
  });
  const [filterSavedState, setFilterSavedState] = useState<NotificationListParams>({
    pageNum: 1,
    pageSize: 20,
    from: 'PORTAL',
  });

  const [notificationList, setNotificationList] = useState<PaginateList>();
  const [roleData, setRoleData] = useState<any>();
  // eslint-disable-next-line
  let [descSortItems, setDescSortItems] = useState<string[]>([]);
  // eslint-disable-next-line
  let [ascSortItems, setAscSortItems] = useState<string[]>([]);

  const { isLoading, refreshData } = useDataProvider<PaginateList>(
    async () => {
      try {
        return await fetchNotificationList(
          {
            ...filterState,
            msgTitle: filterSavedState.msgTitle?.trim(),
            modifier: filterSavedState.modifier?.trim(),
          },
          dispatch,
        );
      } catch (err) {}
    },
    setNotificationList,
    false,
  );

  const MANUAL_PATH = NOTIFICATION_BASE_PATH + NOTIFICATION_MANUAL_PATH;

  useEffect(() => {
    fetchDesignationAndZone().then((res) => {
      setRoleData(res);
    });
  }, []);

  return (
    <>
      <NotificationFilter
        onSearch={() => {
          filterState.pageNum = 1;
          setFilterSavedState(filterState);
          refreshData();
        }}
        filterState={filterState}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            ...data,
          })
        }
      />
      <NotificationList
        enableRead={enableRead}
        enableCreate={enableCreate}
        enableUpdate={enableUpdate}
        isLoading={isLoading}
        notificationList={notificationList}
        onRefresh={() => {
          setFilterState(filterSavedState);
          refreshData();
        }}
        onAdd={() => history.push(`${MANUAL_PATH}/create/portal`)}
        onEdit={(id) => history.push(`${MANUAL_PATH}/edit/${id}`)}
        onView={(id) => history.push(`${MANUAL_PATH}/view/${id}`)}
        roleData={roleData}
        toSetOnTop={(row) => {
          setRowData(row);
          openModal(true);
        }}
        onChangePage={(page, rowsPerPage) => {
          page = page === 0 ? 1 : page;
          setFilterSavedState({
            ...filterSavedState,
            pageNum: page,
            pageSize: rowsPerPage,
          });
          setFilterState({
            ...filterSavedState,
            pageNum: page,
            pageSize: rowsPerPage,
          });
          refreshData();
        }}
        handleSort={(sorter) => {
          let sortItem;
          let sortOrder;
          Object.entries(sorter).forEach(([item, order]) => {
            sortItem = item;
            sortOrder = order;
          });
          setFilterSavedState({
            ...filterSavedState,
            sortItem,
            sortOrder: sortOrder ? (sortOrder as string).toUpperCase() : sortOrder,
          });
          setFilterState({
            ...filterSavedState,
            sortItem,
            sortOrder: sortOrder ? (sortOrder as string).toUpperCase() : sortOrder,
          });
          refreshData();
        }}
      />
      <OnTop row={rowData} open={isOpen} openDialog={openModal} refreshData={refreshData} />
    </>
  );
};

export default NotificationListingPage;
