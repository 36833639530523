import React from 'react';
import { Redirect, RouteComponentProps, Switch } from 'react-router-dom';
import PruRoute from 'src/app/common/components/PruRoute';
import ParamsProvider from 'src/app/common/components/ParamsProvider';
import SettingViewManagerPage from './View/SettingViewManagerPage';

const settingViewManagerFilterKeys = ['id'];

const TodoListSettingPage = ({ match }: RouteComponentProps) => {
  return (
    <Switch>
      <Redirect exact from={match.path} to={`${match.path}/setup`} />
      <PruRoute
        exact
        path={`${match.path}/setup`}
        render={(props) => (
          <ParamsProvider {...props} acceptKeys={settingViewManagerFilterKeys}>
            <SettingViewManagerPage />
          </ParamsProvider>
        )}
      />
    </Switch>
  );
};

export default TodoListSettingPage;
