import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow, TextField } from '@mui/material';
import { AddCircle, ArrowUpward, RemoveCircleOutlineOutlined } from '@mui/icons-material';
import { useIntl } from 'react-intl';
import { IconButton } from '@mui/material';
import { FieldOption, I18nData } from '../../util';
import { PropertyEditorStringsViewModel } from 'survey-creator';
import Form from 'src/app/common/components/Form';
import { regionLocale, RegionLocale } from 'src/app/i18n';
import { isEqual } from 'lodash';
// import { Table } from 'antd';
export interface TableInputProps {
  value?: any[];
  onChange?: (value: any[]) => void;
  disabled?: boolean;
}
const useStyles = makeStyles()({
  moveBtn: {
    margin: '5px',
    width: '16px',
    height: '16px',
    borderRadius: '50%',
    borderWidth: '1px',
    borderColor: 'black',
    borderStyle: 'solid',
    cursor: 'pointer',
  },
  actionBtns: {
    width: '80px',
    textAlign: 'left',
    marginLeft: '15px',
  },
});

const TableInput: React.FC<TableInputProps> = (props) => {
  const { classes } = useStyles();
  const { disabled, value, onChange } = props;
  const maxLength = {
    optionChi: 15,
    optionEn: 30,
  };
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const placeEnter = Translation('app.input.placeholder.please-enter');
  const DEFAULT_REQUIRED_RULES = {
    required: true,
    message: Translation('component.pulseleads.prospect.form-required-text'),
  };
  const onDelete = (index: number) => {
    const tableData = [...(value || [])];
    tableData.splice(index, 1);
    onChange && onChange(tableData);
  };
  const swapArray = (index1: number, index2: number) => {
    let tableData = [...(value || [])];
    if (tableData) {
      const temp = tableData[index1];
      tableData[index1] = tableData[index2];
      tableData[index2] = temp;
    }
    onChange && onChange(tableData);
  };
  const onAdd = () => {
    const tableData = [...(value || [])];
    const newData: FieldOption = {
      i18nData: regionLocale.map((locale: string) => {
        return {
          locale: locale,
          name: '',
        };
      }),
      deleted: false,
    };
    onChange && onChange([...tableData, newData]);
  };
  useEffect(() => {
    const inputLocale = (value || []).map((item) => item.i18nData)?.[0]?.map((item: any) => item.locale);
    let tableData = [...(value || [])];
    const difference = regionLocale?.filter((item) => !inputLocale?.includes(item));
    if (difference.length > 0) {
      difference.map((locale) => {
        return (tableData = tableData.map((item) => {
          return {
            ...item,
            i18nData: item.i18nData.concat({ locale: locale, name: '' }),
          };
        }));
      });
    }
    if (!isEqual(tableData, value)) {
      onChange && onChange(tableData);
    }
  }, [value]);

  const STRING_LENGTH_VALIDATE_OPTIONCHI = [
    DEFAULT_REQUIRED_RULES,
    {
      validator(_: any, value: string) {
        if (value.length > maxLength.optionChi) {
          return Promise.reject(
            new Error(Translation('form.pulseleads.engagement.fields.option.chi.length.validate.message')),
          );
        }
        return Promise.resolve();
      },
    },
  ];

  const STRING_LENGTH_VALIDATE_OPTIONEN = [
    DEFAULT_REQUIRED_RULES,
    {
      validator(_: any, value: string) {
        if (value.length > maxLength.optionEn) {
          return Promise.reject(
            new Error(Translation('form.pulseleads.engagement.fields.option.en.length.validate.message')),
          );
        }
        return Promise.resolve();
      },
    },
  ];

  const getIndex = (index: number, locale: string) => {
    const i = props.value && props.value[index].i18nData.findIndex((x: { locale: string }) => x.locale === locale);
    return i;
  };
  return (
    <Paper elevation={0} variant="elevation">
      <TableContainer>
        <Table aria-label="input table">
          <TableBody>
            {(value || []).map((row: { id: React.Key | null | undefined; i18nData: Array<any> }, index: number) => {
              return (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row" size="small" style={{ borderBottom: 'none' }}>
                    {index === 0 ? null : (
                      <IconButton
                        onClick={() => {
                          console.log(index);
                          onDelete(index);
                        }}
                      >
                        <RemoveCircleOutlineOutlined></RemoveCircleOutlineOutlined>
                      </IconButton>
                    )}
                  </TableCell>
                  {regionLocale.map((locale: string) => {
                    return (
                      <TableCell align="right" size="small" style={{ borderBottom: 'none' }}>
                        <Form.Item
                          name={['options', index, 'i18nData', getIndex(index, locale), 'name']}
                          rules={
                            locale === RegionLocale.TRADITIONAL_CHINESE
                              ? STRING_LENGTH_VALIDATE_OPTIONCHI
                              : STRING_LENGTH_VALIDATE_OPTIONEN
                          }
                        >
                          <TextField
                            margin="dense"
                            variant="outlined"
                            id="en"
                            size="small"
                            style={{ position: 'relative', width: '100%' }}
                            placeholder={placeEnter}
                          />
                        </Form.Item>
                      </TableCell>
                    );
                  })}
                  <TableCell align="right" size="small" style={{ borderBottom: 'none' }}>
                    {disabled ? (
                      '--'
                    ) : (
                      <>
                        <span className={classes.actionBtns}>
                          {index === 0 ? null : (
                            <ArrowUpward
                              className={classes.moveBtn}
                              onClick={() => {
                                swapArray(index, index - 1);
                              }}
                            />
                          )}
                        </span>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
            <TableCell align="center" style={{ borderBottom: 'none' }}>
              <IconButton
                aria-label="add"
                onClick={() => {
                  onAdd();
                }}
                size="small"
              >
                <AddCircle></AddCircle>
              </IconButton>
            </TableCell>
            <TableCell style={{ borderBottom: 'none' }}>
              {Translation('form.pulseleads.engagement.fields.add.option')}
            </TableCell>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
export default TableInput;
