import { createSlice } from '@reduxjs/toolkit';
import { LibraryListParam } from '../components/Library/types/library-types';

export type SalesKitState = {
  materialFilter?: LibraryListParam;
  sortKey: { key: string; value?: string }[];
};

const initialState: SalesKitState = {
  materialFilter: undefined,
  sortKey: [],
};

type SetFilterAction = {
  payload: LibraryListParam;
};
type SetSortAction = {
  payload: { key: string; value?: string }[];
};

export const salesKitSlice = createSlice({
  name: 'saleskit',
  initialState,
  reducers: {
    setFilterState: (state: SalesKitState, action: SetFilterAction) => {
      // set resource list filter
      return {
        ...state,
        materialFilter: action.payload,
      };
    },
    setSortKey: (state: SalesKitState, action: SetSortAction) => {
      // set resource list sortKey
      return {
        ...state,
        sortKey: action.payload,
      };
    },
    resetState: (state: SalesKitState) => {
      // clear list filter
      return initialState;
    },
  },
});

export const { setFilterState, setSortKey, resetState } = salesKitSlice.actions;
