import React, { FC, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Dialog, Button } from '@mui/material';

type ItemConfirmDialogProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => Promise<void>;
  onRefresh: () => void;
  title?: string;
  content?: string;
};

const useStyles = makeStyles()(() => ({
  container: {
    padding: 13.5,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 80,
    width: 240,
    height: 184,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 16.2,
    lineHeight: 1.2,
    color: '#333333',
    fontWeight: 600,
  },
  fieldContainer: {
    paddingTop: 13.5,
    flexBasis: '20%',
    minWidth: 213,
    height: 37,
    boxSizing: 'border-box',
  },
  contentText: {
    fontSize: 9.5,
    color: '#333333',
    lineHeight: 1.2,
  },
  buttonContainer: {
    textTransform: 'none',
    marginTop: 10,
    width: 212,
    height: 32.5,
  },
  confirmButtonText: {
    color: '#FFFFFF',
    backgroundColor: '#E8192C',
    '&:hover': {
      backgroundColor: '#E8192C',
    },
  },
  footer: {
    marginTop: 11,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
  },
}));

const ConfirmDialog: FC<ItemConfirmDialogProps> = ({ open, onClose, onConfirm, onRefresh, title, content }) => {
  const [isConfirming, setIsConfirming] = useState<boolean>(false);
  const { classes } = useStyles();
  const confirmItem = async () => {
    setIsConfirming(true);
    await onConfirm();
    onRefresh();
    onClose();
  };

  return (
    <Dialog
      PaperProps={{
        style: { borderRadius: 13 },
      }}
      onClose={() => !isConfirming && onClose()}
      open={open}
    >
      <div className={classes.container}>
        <div className={classes.header}>{title || 'Warning'}</div>
        <div className={classes.fieldContainer}>
          <p className={classes.contentText}>
            {content || 'Are you sure you want to confirm this action ? This action cannot be reverted.'}
          </p>
        </div>
        <div className={classes.footer}>
          <Button
            className={`${classes.buttonContainer} ${classes.confirmButtonText}`}
            variant="contained"
            color="primary"
            onClick={confirmItem}
          >
            Confirm
          </Button>
          <Button className={classes.buttonContainer} onClick={() => !isConfirming && onClose()} variant="outlined">
            Cancel
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default ConfirmDialog;
