export enum SLAFormMode {
  CREATE = "CREATE",
  EDIT = "EDIT"
}

export enum SLAType {
  ACCEPT = 'accept',
  CONTACT = 'contact',
  MEET = 'meet',
  PROPOSAL = 'proposal',
  APPLICATION = 'application'
}

export type SLAConfigItem = {
  _id: string;
  name: string;
  type: string;
  startTime: string;
  endTime: string;
  durationInMinutes: number;
  durationInDays: number;
  isRunAllDay: boolean;
  isPullBackLeadEnabled: boolean;
  isWeekendIncluded: boolean;
  waitInMinutesToReassignLead?: number;
  createdBy: string;
  updatedBy: string;
  createdAt: Date;
  updatedAt: Date;
}