import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { useIntl } from 'react-intl';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useDataProvider } from 'src/app/common/utils';
import { PaginateList } from 'src/app/common/types/common-types';
import {
  DeduplicationRuleListParam,
  fetchDeduplicationRuleList,
} from 'src/app/modules/PulseLeads/network/deduplicationRuleCrud';
import { DeduplicationRule } from 'src/app/modules/PulseLeads/types/deduplication-rule-types';
import DeduplicationRuleList from './components/DeduplicationRuleList';

type DeduplicationRuleListingPageProps = {} & ParamsProps;

const initialState: DeduplicationRuleListParam = {
  name: '',
  limit: 5,
  page: 1,
};

const paramsInitiator = (initialParams?: Record<string, string>): DeduplicationRuleListParam => {
  return initialParams
    ? {
        name: initialParams.name ?? '',
        limit: 5,
        page: 1,
      }
    : initialState;
};

export const deduplicationRuleFilterKeys = ['name'];

const DeduplicationRuleListingPage: FC<DeduplicationRuleListingPageProps> = ({
  initialParams,
  onChangeQueryParams,
}) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const [filterState, setFilterState] = useState<DeduplicationRuleListParam>(paramsInitiator(initialParams));

  const [deduplicationRuleList, setDeduplicationRuleList] = useState<PaginateList<DeduplicationRule>>();
  const { isLoading, refreshData } = useDataProvider<PaginateList<DeduplicationRule>>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchDeduplicationRuleList(filterState, dispatch);
      } catch (err) {}
    },
    setDeduplicationRuleList,
    false,
  );

  return (
    <>
      <PruFilter
        title={Translation('pulseleads.deduplicationRule.filter')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'name',
            initialValue: filterState.name,
            displayName: Translation('pulseleads.deduplicationRule.common.name'),
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            name: data.name,
          })
        }
        fetchData={refreshData}
      />
      <DeduplicationRuleList
        isLoading={isLoading}
        deduplicationRuleList={deduplicationRuleList}
        onRefresh={refreshData}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default DeduplicationRuleListingPage;
