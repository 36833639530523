import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ParamsProps } from '../../../../../common/components/ParamsProvider';
import { SurveyResultListParam, fetchSurveyResultList } from '../../../network/surveyCrud';
import { getDayStart, getDayEnd, useDataProvider } from 'src/app/common/utils';
import { SurveyPaginateList } from '../../../types/survey-types';
import SurveyList from './components/SurveyList';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { useIntl } from 'react-intl';

type SurveyListingPageProps = {} & ParamsProps;

const initialState: SurveyResultListParam = {
  startDateFrom: null,
  startDateTo: null,
  endDateFrom: null,
  endDateTo: null,
  page: 1,
  limit: 5,
};

export const surveyFilterKeys = ['id', 'startDateFrom', 'startDateTo', 'endDateFrom', 'endDateTo'];

const paramsInitiator = (initialParams?: Record<string, string>): SurveyResultListParam => {
  return initialParams
    ? {
        startDateFrom: initialParams.startDateFrom ? getDayStart(new Date(initialParams.startDateFrom)) : null,
        startDateTo: initialParams.startDateTo ? getDayEnd(new Date(initialParams.startDateTo)) : null,
        endDateFrom: initialParams.endDateFrom ? getDayStart(new Date(initialParams.endDateFrom)) : null,
        endDateTo: initialParams.endDateTo ? getDayEnd(new Date(initialParams.endDateTo)) : null,
        page: 1,
        limit: 5,
      }
    : initialState;
};

const SurveyListingPage: FC<SurveyListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const [filterState, setFilterState] = useState<SurveyResultListParam>(paramsInitiator(initialParams));
  const [surveyList, setSurveyList] = useState<SurveyPaginateList>();

  const { isLoading, refreshData } = useDataProvider<SurveyPaginateList>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchSurveyResultList(filterState, dispatch);
      } catch (err) {}
    },
    setSurveyList,
    false,
  );

  return (
    <>
      <PruFilter
        title={Translation('survey.filter')}
        itemDef={[
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'startDateFrom',
            fieldTo: 'startDateTo',
            initialDateFrom: filterState.startDateFrom,
            initialDateTo: filterState.startDateTo,
            displayName: Translation('survey.common.startDate'),
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'endDateFrom',
            fieldTo: 'endDateTo',
            initialDateFrom: filterState.endDateFrom,
            initialDateTo: filterState.endDateTo,
            displayName: Translation('survey.common.endDate'),
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            startDateFrom: data.startDateFrom,
            startDateTo: data.startDateTo,
            endDateFrom: data.endDateFrom,
            endDateTo: data.endDateTo,
          })
        }
        fetchData={refreshData}
      />
      <SurveyList
        isLoading={isLoading}
        onRefresh={refreshData}
        surveyList={surveyList}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default SurveyListingPage;
