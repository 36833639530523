import React, { FC, useState } from 'react';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useDataProvider } from 'src/app/common/utils';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { PaginateList } from 'src/app/common/types/common-types';
import { ChannelCampaignItem } from 'src/app/modules/PulseLeads/types/channel-campaign-types';
import {
  fetchChannelCampaignList,
  ChannelCampaignListParam,
} from 'src/app/modules/PulseLeads/network/channelCampaignCrud';
import ChannelCampaignList from './components/ChannelCampaignList';

// eslint-disable-next-line
type ChannelCampaignListingPageProps = {} & ParamsProps;

const initialState: ChannelCampaignListParam = {
  channelCode: '',
  campaignName: '',
  page: 1,
  limit: 5,
};

export const channelCampaignFilterKeys = ['channelCode', 'campaignName'];

const paramsInitiator = (initialParams?: Record<string, string>): ChannelCampaignListParam => {
  return initialParams
    ? {
        channelCode: initialParams.channelCode || '',
        campaignName: initialParams.campaignName || '',
        page: 1,
        limit: 5,
      }
    : initialState;
};

const ChannelCampaignListingPage: FC<ChannelCampaignListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const intl = useIntl();
  const history = useHistory();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const [filterState, setFilterState] = useState<ChannelCampaignListParam>(paramsInitiator(initialParams));
  const [channelCampaignList, setChannelCampaignList] = useState<PaginateList<ChannelCampaignItem>>();

  const { isLoading, refreshData } = useDataProvider<PaginateList<ChannelCampaignItem>>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchChannelCampaignList(filterState, dispatch);
      } catch (err) {
        history.push(NOT_FOUND_ERROR_PAGE_PATH);
      }
    },
    setChannelCampaignList,
    false,
  );

  return (
    <>
      <PruFilter
        title={Translation('pulseleads.straightThrough.channelCampaignFilter')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'channelCode',
            initialValue: filterState.channelCode,
            displayName: Translation('pulseleads.straightThrough.channelCode'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'campaignName',
            initialValue: filterState.campaignName,
            displayName: Translation('pulseleads.campaign.common.name'),
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            channelCode: data.channelCode,
            campaignName: data.campaignName,
          })
        }
        fetchData={refreshData}
      />
      <ChannelCampaignList
        isLoading={isLoading}
        channelCampaignList={channelCampaignList}
        onRefresh={refreshData}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default ChannelCampaignListingPage;
