import moment from "moment";

export const dontDistributeReasonOpts : any = {
    'excluded': {
        value: 'Excluded',
        text: 'Excluded'
    },
    'dontDistribute': {
        value: "Don't Distribute",
        text: "Don't Distribute",
    },
    'bankLead': {
        value: 'Bank Lead',
        text: 'Bank Lead'
    },
    'brokerLead': {
        value: 'Broker Lead',
        text: 'Broker Lead'
    },
    'existingAgent': {
        value: 'Existing Agent',
        text: 'Existing Agent'
    },
    'existingEmployee': {
        value: 'Existing Employee',
        text: 'Existing Employee'
    }
}

export const dontDistributeStatus: any = {
    '0': {
        'Failed to Distribute': { text: 'Failed', status: 'Failed to Distribute' }
    },
    '1': {
        'Excluded': { text: 'Excluded', status: 'excluded' },
        "Don't Distribute": { text: "Don't Distribute", status: "Don't Distribute" },
        'Bank Lead': { text: 'Bank Lead', status: 'Bank Lead' },
        'Broker Lead': { text: 'Broker Lead', status: 'Broker Lead' },
        'Existing Agent': { text: 'Existing Agent', status: 'Existing Agent' },
        'Existing Employee': { text: 'Existing Employee', status: 'Existing Employee' },
    },
    '2': {
        'Queue': { text: 'Queue', status: 'Queue' },
        'Distributing': { text: 'Distributing', status: 'Distributing' },
    },
    '3': {
        'Invalid Lead Data': { text: 'Invalid Lead Data', status: 'Invalid Lead Data' }
    },
}


export const defaultDateRange = [moment(new Date()).add(-7, 'd').format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD')];