import React, { useState, useEffect } from 'react';

import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { Button, Checkbox, FormControlLabel, Box, IconButton } from '@mui/material';
import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useIntl } from 'react-intl';
import { useStyles } from '../../styles/TaskSectionStyles';
import { ModalService } from 'src/app/common/components/pru-modal/modal-service';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import { TODOLIST_BASE_PATH, TODOLIST_TASKSECTION_PATH } from 'src/app/modules/ToDoList/constants';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { modifyTodoListTaskSection } from '../../../../network/todolistCrud';
import { useJWT, useDataProvider } from 'src/app/common/utils';
import { TaskItemList } from 'src/app/modules/ToDoList/types/todolist-type';
import { fecthTodoListTask } from '../../../../network/todolistCrud';
import { LayoutSplashScreen } from 'src/app/layout';

interface HistorySectionProps {
  taskSectionStatus: boolean;
}

const EditTaskSectionPage = ({}) => {
  const history = useHistory();
  const location = useLocation();
  const state = location.state as HistorySectionProps;
  const { taskSectionStatus } = state;
  const { classes: commonClasses } = useCommonStyles();
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const [show, SetShow] = useState(false);
  const [hide, SetHide] = useState(false);
  const [disableButton, SetDisableButton] = useState(true);
  const urlPath = window.location.pathname.split('/');
  const taskSectionIdUrlParam = urlPath[urlPath.length - 2];
  const taskSectionIdChildUrlParam = urlPath.pop();

  const jwt = useJWT();
  const emailInfo = jwt.sub;
  const [taskData, setTaskData] = useState<TaskItemList>();
  const { isLoading, refreshData } = useDataProvider<TaskItemList>(
    async () => {
      try {
        return await fecthTodoListTask(dispatch);
      } catch (err) {}
    },
    setTaskData,
    false,
  );
  const taskValue = taskData?.groups.sort((a, b) => a.order - b.order);
  const taskVal = taskValue ?? [];
  const getCountIsShow = taskValue?.filter((valTask) => {
    return valTask.isShow === true;
  });
  const minTask = 0;
  const maxTask = 3;
  const countIsShow = getCountIsShow?.length ?? minTask;
  const onGetCountShowTask = countIsShow <= maxTask;

  const getDataTaskSectionByID = taskVal?.filter((valTask) => valTask.key === taskSectionIdUrlParam);
  const valDataTaskSection = getDataTaskSectionByID[0]?.categories ?? [];
  const getCountIsShowSection = valDataTaskSection.filter((valTask) => valTask.isShow === true);
  const maxNumberTaskSection = 1;
  const maxTaskSectioCanEdit = getCountIsShowSection.length === maxNumberTaskSection && onGetCountShowTask;

  const onGetCountShowTaskAndStatus = maxTaskSectioCanEdit && taskSectionStatus;

  useEffect(() => {
    if (taskSectionStatus) {
      SetShow(true);
    } else {
      SetHide(true);
    }
  }, []);

  const onSaveChanges = async (target: any) => {
    if (target.name === 'show') {
      if (target.checked) {
        SetShow(true);
        SetHide(false);
        SetDisableButton(false);
      }
    } else {
      if (target.checked) {
        SetShow(false);
        SetHide(true);
        SetDisableButton(false);
      }
    }
  };

  const onSaveUpdateData = async () => {
    const payload = {
      isShow: show,
      updatedBy: emailInfo,
    };

    try {
      await modifyTodoListTaskSection(taskSectionIdChildUrlParam ?? '', payload, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: Translation('todolist.messageResponse.success'),
          },
        ]),
      );

      history.push(`${TODOLIST_BASE_PATH}${TODOLIST_TASKSECTION_PATH}`);
    } catch (err) {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.ERROR,
            title: 'Error',
            content: Translation('todolist.messageResponse.failed'),
          },
        ]),
      );
    }
  };

  const onUpdate = async () => {
    refreshData();
    if (maxTaskSectioCanEdit && !show) {
      ModalService.confirm({
        title: Translation('todolist.modal.cannotSave'),
        description: Translation('todolist.modal.body.cannotSave'),
        onConfirm: () => true,
      });
    } else {
      ModalService.confirm({
        title: Translation('todolist.modal.title'),
        description: Translation('todolist.modal.body'),
        onConfirm: () => {
          onSaveUpdateData();
        },
        confirmText: Translation('todolist.modal.button.save'),
        cancelText: Translation('todolist.modal.button.cancel'),
      });
    }
  };

  return (
    <>
      {isLoading ? (
        <LayoutSplashScreen />
      ) : (
        <>
          <div className={classes.container}>
            <div className={classes.headerContainer}>
              <div className={classes.rowContainer}>
                <div className={commonClasses.header}>{`${Translation('todolist.taskSection.edit.title')} ${Translation(
                  `todolist.taskSection.${taskSectionIdUrlParam}.title`,
                )} - ${Translation(`todolist.taskSection.title.${taskSectionIdChildUrlParam}`)}`}</div>
              </div>
              <div className={classes.rowContainer}>
                <Button
                  style={{ marginLeft: 25 }}
                  variant="contained"
                  onClick={() => {
                    history.push(`${TODOLIST_BASE_PATH}${TODOLIST_TASKSECTION_PATH}`);
                  }}
                >
                  {Translation('todolist.taskgroup.button.back')}
                </Button>
              </div>
            </div>
            <div className={classes.subHeader}>{Translation('todolist.subheader.taskSection')}</div>
            <div className={classes.sectionMargin}>
              <div className={classes.divideMargin}></div>
            </div>

            <div className={classes.rowContainer}>
              <div className={classes.fieldContainer}>
                <span className={classes.field}>{Translation('todolist.task.field.statusHide')}</span>
              </div>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={false}
                    icon={<CircleUnchecked />}
                    checkedIcon={<CircleCheckedFilled />}
                    checked={show}
                    name="show"
                    onChange={(e) => onSaveChanges(e.target)}
                  />
                }
                label={Translation('todolist.task.field.showed')}
                labelPlacement="end"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={onGetCountShowTaskAndStatus ? true : false}
                    icon={<CircleUnchecked />}
                    checkedIcon={<CircleCheckedFilled />}
                    checked={hide}
                    name="hide"
                    onChange={(e) => onSaveChanges(e.target)}
                  />
                }
                label={Translation('todolist.task.field.hidden')}
                labelPlacement="end"
              />
            </div>

            <div className={classes.footerContainer}>
              {
                <>
                  <Button
                    disabled={(maxTaskSectioCanEdit && !show) || onGetCountShowTaskAndStatus || disableButton}
                    style={{ marginLeft: 25 }}
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      onUpdate();
                    }}
                  >
                    {Translation('todolist.taskgroup.button.save')}
                  </Button>
                </>
              }
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default EditTaskSectionPage;
