import { mainLang, subLang } from 'src/app/modules/Operation/constants';
import { PaginateList } from '../../../../../common/types/common-types';

export enum ColumnPublishedMode {
  ALL = 'All',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}

export type ColumnListParam = {
  pageSize: number;
  page: number;
  isActive?: string;
  module?: string;
};

export type nameItem = {
  [mainLang]: string;
  [subLang]?: string;
};

export type ColumnItem = {
  createdAt: Date;
  id: string;
  localizations?: localeItem[];
  name: nameItem;
  publishedAt: Date;
  updatedAt: Date;
  lastUpdatedBy: string;
  description: string;
  weight: number | null;
  module?: string;
  locale?: string;
};

export type ColumnEditItem = {
  createdAt?: Date;
  created_by?: Date;
  id?: string;
  locale?: string;
  localizations?: string[];
  name: nameItem;
  publishedAt?: Date;
  updatedAt?: Date;
  // lastUpdatedBy: string;
  weight: number | null;
  description: string;
  module: string;
};

export type ColumnFormState = {
  [mainLang]?: string;
  [subLang]?: string;
  description: string;
  status: string;
  weight: number | null;
};

export type localeItem = {
  id: string;
  locale: string;
  publishedAt: Date;
  _id: string;
};

export type ColumnPaginateList = PaginateList<ColumnItem> & { columnList: ColumnItem[]; totalRecords: number };

export interface AllColumns {
  [key: string]: string | { id: string; children: AllColumns };
}
