import React, { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
} from '@mui/material';
import { useIntl } from 'react-intl';
import { Button } from '@mui/material';
import SearchCourseBox from '../SearchCourseBox';
import ImportCourseForm from '../ImportCourseForm';
import ProTableCell from 'src/app/common/components/ProTable/ProTableCell';
import { getItemByLang } from '../../../../../util';

export interface TableInputProps {
  value?: any[];
  onChange?: (value: any[]) => void;
  disabled?: boolean;
  getRemovedMaterial: (items: any) => void;
  initialValue: any;
}

const useStyles = makeStyles()((theme) => ({
  actionCell: {
    display: 'flex'
  },
  moveBtn: {
    margin: '5px',
    width: '22px',
    height: '22px',
    borderRadius: '50%',
    borderWidth: '1px',
    borderColor: 'black',
    borderStyle: 'solid',
    cursor: 'pointer',
    "&:hover": {
      backgroundColor: '#e0e0e0'
    }
  },
  actionBtns: {
    width: '80px',
    textAlign: 'left',
    marginLeft: '15px'
  },
  noCourseArea: {
    padding: "30px 0",
    textAlign: "center",
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  }
}));

const CourseList: React.FC<TableInputProps> = (props) => {
  const { classes } = useStyles();
  const { value, onChange, disabled, getRemovedMaterial, initialValue } = props;
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id })
  const tableData = [...(value || [])];
  const [popUpVisible, setPopUpVisible] = useState<boolean>(false);
  const [importPopUpVisible, setImportPopUpVisible] = useState<boolean>(false);
  const [selectedCourse, setSelectedCourse] = useState<any>({});
  const [courseCode, setCourseCode] = useState<string>('');

  const triggerChange = (changedValue: any[]) => {
    if (onChange) {
      onChange([...changedValue]);
    }
  };

  const onDelete = (index: number) => () => {
    const removeItem = tableData[index];
    const removeId = removeItem.id;
    const removeItemInFact = initialValue && initialValue.filter((item: any) => {
      return item.id === removeId;
    });
    removeItemInFact && getRemovedMaterial(removeItemInFact[0]);
    tableData.splice(index, 1);
    triggerChange(tableData);
  };

  const onEdit = (index: number) => () => {
    const editItem = tableData[index];
    const courseCode = editItem.courseCode;
    setCourseCode(courseCode);
    setSelectedCourse(editItem);
    setImportPopUpVisible(true);
  };

  const onSelectData = (selectedRow: any) => {
    setSelectedCourse(selectedRow);
    setImportPopUpVisible(true);
    setCourseCode('');
  }

  const onSave = (courseVaues: any) => {
    if (!courseCode) {
      tableData.push(courseVaues);
    } else {
      let editIndex;
      tableData.map((item, index) => {
        if (item.courseCode === courseVaues.courseCode) {
          editIndex = index;
        }
      });
      if (editIndex !== undefined) {
        tableData[editIndex] = courseVaues;
      }
    }
    triggerChange(tableData);
    setImportPopUpVisible(false);
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%'}}>
      <div style={{ width: '90%'}}>
        <TableContainer component={Paper}>
          <Table aria-label="input table" >
            <TableHead>
              <TableRow>
                <ProTableCell align="center">{Translation('mandatory.training.component.table.course.name')}</ProTableCell>
                <ProTableCell align="center">{Translation('mandatory.training.component.table.course.type')}</ProTableCell>
                <ProTableCell align="center">{Translation('mandatory.training.component.table.course.deeplink')}</ProTableCell>
                <ProTableCell align="center">{Translation('mandatory.training.component.table.course.description')}</ProTableCell>
                {
                  !disabled && <ProTableCell align="center">{Translation('component.formLabel.operation')}</ProTableCell>
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {(value || []).map((row, index) => {
                return (
                  <TableRow key={row.id}>
                    <ProTableCell align="center">{row.name}</ProTableCell>
                    <ProTableCell align="center">{getItemByLang(row.i18nData, 'type')[0]}</ProTableCell>
                    <ProTableCell align="center">{row.deepLink}</ProTableCell>
                    <ProTableCell align="center">{getItemByLang(row.i18nData, 'description')[0]}</ProTableCell>
                    {
                      !disabled && <ProTableCell align="center" >
                        <Button key="edit" onClick={onEdit(index)}>{Translation('app.button.edit')}</Button>
                        <Button key="delete" onClick={onDelete(index)}>{Translation('app.button.delete')}</Button>
                      </ProTableCell>
                    }
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
          {
            ( !value || value?.length === 0 ) && <div className={classes.noCourseArea}>No Course found</div>
          }
        </TableContainer>
      </div>
      <div>
        {
          !disabled && <Button
            style={{ float: 'left', marginTop: 15 }}
            variant="contained"
            color="secondary"
            onClick={() => {
              setPopUpVisible(true);
            }}
          >
            {Translation('mandatory.training.component.table.add.course.title')}
          </Button>
        }
      </div>
      <Dialog open={popUpVisible} onClose={() => { setPopUpVisible(false) }} scroll={"paper"} fullWidth maxWidth="lg">
        <SearchCourseBox
          popUpVisible={popUpVisible}
          onSelectData={onSelectData}
          setPopUpVisible={setPopUpVisible}
          couseList={tableData}
        />
      </Dialog>
      <Dialog open={importPopUpVisible} scroll={"paper"} fullWidth maxWidth="lg" >
        <DialogTitle>{Translation('mandatory.training.component.table.add.course.title')}</DialogTitle>
        <ImportCourseForm
          importPopUpVisible={importPopUpVisible}
          setImportPopUpVisible={setImportPopUpVisible}
          onSave={onSave}
          courseSource={selectedCourse}
          isEdit={!!courseCode}
        />
      </Dialog>
    </div>
  );
};

export default CourseList;
