import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { REPORT_BASE_PATH, REPORT_NEWS_PATH, REPORT_NEWS_INFO } from '../../../../constants';
import { ReportType } from '../../../../types/report-types';
import { useIntl } from 'react-intl';
import { createNewInfoReport } from '../../../../network/reportCrud';
import CreateReport from '../../../../components/CreateReport';

const InfoReportPage: FC<RouteComponentProps> = ({ history, location }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  
  return (
    <CreateReport
      title={Translation("report.news.info.title")}
      reportType={ReportType.NEWS_INFO}
      onCreate={createNewInfoReport}
      onBack={() => history.push(`${REPORT_BASE_PATH}${REPORT_NEWS_PATH}${REPORT_NEWS_INFO}`)}
      fields={[{
        name: "publishDateFrom",
        displayText: Translation("report.news.info.publishDateFrom"),
        type: "date",
        mandatory: true,
      },
      {
        name: "publishDateTo",
        displayText: Translation("report.news.info.publishDateTo"),
        type: "date",
        mandatory: true,
      }]}
    />
  )
};

export default InfoReportPage;
