export const RECRUIT_BASE_PATH = '/recruit';
export const RECRUIT_AMD_EMAIL_PATH = '/amd-email';
export const RECRUIT_PERSONAL_PARTICULAR_PATH = '/personal-particular';
export const RECRUIT_GPS_LOCATION_PATH = '/gps-location';
export const RECRUIT_FOLLOW_UP_CASE_PATH = '/follow-up-case';

export const RECRUIT_TITLE = 'title.recruit';
export const RECRUIT_AMD_EMAIL_TITLE = 'title.recruit.amdEmail';
export const RECRUIT_PERSONAL_PARTICULAR_TITLE = 'title.recruit.personalParticular';
export const RECRUIT_GPS_LOCATION_TITLE = 'title.recruit.gpsLocation';
export const RECRUIT_FOLLOW_UP_CASE_TITLE = 'title.recruit.followUpCase';

export const RECRUIT_AMD_EMAIL_NAMESPACE = 'RECRUIT-AMD-EMAIL';
export const RECRUIT_PERSONAL_PARTICULAR_NAMESPACE = 'RECRUIT-PERSONAL-PARTICULAR';
export const RECRUIT_GPS_LOCATION_NAMESPACE = 'RECRUIT-GPS-LOCATION';
export const RECRUIT_FOLLOW_UP_CASE_NAMESPACE = 'RECRUIT-FOLLOW-UP-CASE';

export enum AdminRecruitPermissionType {
  RECRUIT_AMD_EMAIL_READ = 'READ_ANY_RECRUIT-AMD-EMAIL',
  RECRUIT_AMD_EMAIL_CREATE = 'CREATE_ANY_RECRUIT-AMD-EMAIL',
  RECRUIT_AMD_EMAIL_UPDATE = 'UPDATE_ANY_RECRUIT-AMD-EMAIL',
  RECRUIT_PERSONAL_PARTICULAR_READ = 'READ_ANY_RECRUIT-PERSONAL-PARTICULAR',
  RECRUIT_PERSONAL_PARTICULAR_CREATE = 'CREATE_ANY_RECRUIT-PERSONAL-PARTICULAR',
  RECRUIT_PERSONAL_PARTICULAR_UPDATE = 'UPDATE_ANY_RECRUIT-PERSONAL-PARTICULAR',
  RECRUIT_GPS_LOCATION_READ = 'READ_ANY_RECRUIT-GPS-LOCATION',
  RECRUIT_GPS_LOCATION_CREATE = 'CREATE_ANY_RECRUIT-GPS-LOCATION',
  RECRUIT_GPS_LOCATION_UPDATE = 'UPDATE_ANY_RECRUIT-GPS-LOCATION',
  RECRUIT_FOLLOW_UP_CASE_READ = 'READ_ANY_RECRUIT-FOLLOW-UP-CASE',
  RECRUIT_FOLLOW_UP_CASE_CREATE = 'CREATE_ANY_RECRUIT-FOLLOW-UP-CASE',
  RECRUIT_FOLLOW_UP_CASE_UPDATE = 'UPDATE_ANY_RECRUIT-FOLLOW-UP-CASE',
}
