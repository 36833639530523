import React, { FC } from 'react';
import { staffLogin } from '../_redux/authCrud';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

const StaffLogin: FC = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.staffLoginWrapper}>
      <h3 className={classes.title}>
        <FormattedMessage id="auth.login.title" />
      </h3>
      <p className={classes.subtitle}>
        <FormattedMessage id="auth.login.description" />
      </p>
      <div className={classes.separator}></div>
      <button type="submit" className={classes.btn} onClick={staffLogin}>
        <span>
          <FormattedMessage id="auth.login.staffLogin" />
        </span>
      </button>
    </div>
  );
};

export default StaffLogin;

const useStyles = makeStyles()((theme: Theme) => ({
  staffLoginWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
  },
  title: {
    fontSize: 26,
    margin: '8px 0',
  },
  subtitle: {
    color: '#B5B5C3 !important',
    fontWeight: 500,
  },
  separator: {
    borderBottom: '1px dashed #EBEDF3',
    margin: '22px 0',
  },
  btn: {
    cursor: 'pointer',
    color: '#FFFFFF',
    backgroundColor: '#3699FF',
    borderColor: '#3699FF',
    borderRadius: 5.5,
    padding: '13px 30px',
    margin: '6px 0px',
  },
}));
