import React from 'react';
import withCloseButton from './WithCloseButton';

type ImageWithCloseButton = {
  url: string;
  onClose: () => void;
  imageAlt: string;
  imageStyle: React.CSSProperties;
  imageContainerStyle?: React.CSSProperties;
};

const ImageWithCloseButton: React.FC<ImageWithCloseButton> = (props) => {
  const { url, imageStyle, imageAlt, imageContainerStyle } = props;

  return (
    <span
      style={{
        width: 120,
        height: 120,
        overflow: 'hidden',
        borderRadius: 8,
        justifyContent: 'center',
        alignItems: 'center',
        ...imageContainerStyle,
      }}
    >
      <img src={url} style={imageStyle} alt={imageAlt} {...props} />
    </span>
  );
};

export default withCloseButton(ImageWithCloseButton);
