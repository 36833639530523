import { useSelector } from 'react-redux';
import { useState, useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment';
// import { getNextDay } from '../../../utils';
import dayjs from 'dayjs';
import { DateFormat } from 'src/app/common/constants';
import { RecruitmentStepProps } from '../template-detail-page.type';
import { get } from 'lodash';

export function useTemplateDetailStep1Hook(props: RecruitmentStepProps) {
  const { validDateTo, queryAction } = props;
  const intl = useIntl();
  const t = (id: string) => intl.formatMessage({ id });

  const onboardingTemplate = useSelector((state: any) => get(state.config.data, 'Recruitment.onboardingTemplate', []));
  const formTypeOptions = onboardingTemplate.map((item: any) => item.type);
  const validToValue = validDateTo ? dayjs(validDateTo).format(DateFormat.date) : 'N/A';

  const [formType, setFormType] = useState(props.type); // formTypeDefault
  const [validDateFrom, setValidDateFrom] = useState<Date | null>(props.validDateFrom || null);

  const [validateText, setValidateText] = useState({
    fromError: '',
    toError: '',
  });

  const formTypeChangeHandler = useCallback(
    ({ target }: { target: { value: string } }) => {
      setFormType(target.value);
      props.setTemplate({ type: target.value });
      props.onTypeChange && props.onTypeChange(target.value);
    },
    [props],
  );
  const dateChangeHandler = useCallback(
    (value: Date | null) => {
      // setFormType(target.value);\valu
      setValidDateFrom(value);
      props.setTemplate({ validDateFrom: value });
    },
    [props],
  );

  useEffect(() => {
    if (queryAction === 'view') {
      return;
    }
    const emptyError = !validDateFrom;
    const notTodayError =
      validDateFrom && moment(validDateFrom).toDate().getTime() <= moment().endOf('day').toDate().getTime();
    // const timeRangeError =
    //   validDateFrom && validTo && moment(validTo).toDate().getTime() <= moment(validDateFrom).toDate().getTime();
    // const noError = !emptyError && !notTodayError && !timeRangeError;
    const noError = !emptyError && !notTodayError;

    props.setValidate(noError);

    setValidateText({
      fromError: emptyError ? t('pdf_valid_tip1') : notTodayError ? t('pdf_valid_tip2') : '',
      toError: '',
      // toError: timeRangeError ? t('component.form-date-compare') : '',
    });
  }, [validDateFrom, queryAction]);

  return {
    formType,
    validateText,
    formTypeOptions,
    validDateFrom,
    validTo: validToValue,
    dateChangeHandler,
    formTypeChangeHandler,
  };
}
