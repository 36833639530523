import React, { FC, useReducer, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import {
  Button,
  TextField,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  LinearProgress,
  Link,
} from '@mui/material';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { ErrorFieldType, useErrorHandler } from 'src/app/common/utils';
import { MANDATORY_FIELD_ERROR_TEXT } from 'src/app/common/constants';
import { useDispatch, useSelector } from 'react-redux';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import { RootState } from 'src/redux/store';
import { AuthenticationState } from 'src/app/modules/Auth/_redux/authSlice';
import { AmdEmailFormMode, AmdEmailItem } from 'src/app/modules/Recruit/types/amd-email-types';
import { amdEmailPath } from '../../AmdEmailRoutes';
import {
  createAmdEmail,
  CreateAmdEmailBody,
  UpdateAmdEmailBody,
  modifyAmdEmail,
} from 'src/app/modules/Recruit/network/amdEmailCrud';

type AmdEmailFormProps = {
  formMode: AmdEmailFormMode;
  amdEmail?: AmdEmailItem;
};

const FIELD_CONTAINER_WIDTH = 180;

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  fieldContainer: {
    width: FIELD_CONTAINER_WIDTH,
    boxSizing: 'border-box',
  },
  innerFieldContainer: {
    width: FIELD_CONTAINER_WIDTH,
    boxSizing: 'border-box',
  },
  iconContainer: {
    width: 60,
    height: 60,
    overflow: 'hidden',
    borderRadius: 12,
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },
  sectionMargin: {
    marginBottom: 15,
  },
  divideMargin: {
    marginBottom: 10,
  },
  subHeader: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
  },
  textAreaRowContainer: {
    width: '100%',
    display: 'flex',
  },
  textAreaFieldContainer: {
    paddingTop: 15,
    minWidth: FIELD_CONTAINER_WIDTH,
    boxSizing: 'border-box',
  },
  textArea: {
    lineHeight: 1.5,
    minHeight: 40,
  },
  errorText: {
    fontSize: 9,
    color: '#F018A6',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));

type AmdEmailFormState = {
  name?: string;
  email?: string;
};

const initialState: AmdEmailFormState = {
  name: undefined,
  email: undefined,
};

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof AmdEmailFormState;
    value: any;
  };
};

type AmdEmailFormAction = ModifyFieldAction;

const formReducer = (state: AmdEmailFormState, action: AmdEmailFormAction): AmdEmailFormState => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    default:
      return state;
  }
};

const detailToStateConvertor = (amdEmail?: AmdEmailItem): AmdEmailFormState => {
  return amdEmail
    ? {
        name: amdEmail.name,
        email: amdEmail.email,
      }
    : initialState;
};

const AmdEmailForm: FC<AmdEmailFormProps> = ({ formMode, amdEmail }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { classes: commonClasses } = useCommonStyles();
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { user } = useSelector<RootState, AuthenticationState>((state) => state.auth);

  const [imageUploadProgress, setImageUploadProgress] = useState<number>();
  const [formState, formDispatch] = useReducer(formReducer, detailToStateConvertor(amdEmail));

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler } = useErrorHandler(formState, [
    {
      name: 'name',
      fieldType: ErrorFieldType.MANDATORY,
    },
    {
      name: 'email',
      fieldType: ErrorFieldType.MANDATORY,
    },
  ]);

  const onSubmit = async () => {
    const { hasError } = onSubmitErrorValidator();
    if (!hasError) {
      if (formMode === AmdEmailFormMode.CREATE) {
        const body: CreateAmdEmailBody = {
          name: formState.name || '',
          email: formState.email || '',
        };
        try {
          await createAmdEmail(body, dispatch);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: 'AMD Email saved successfully',
              },
            ]),
          );
          history.push(amdEmailPath);
        } catch (err) {}
      } else if (amdEmail) {
        const body: UpdateAmdEmailBody = {
          name: formState.name || '',
          email: formState.email || '',
        };
        try {
          await modifyAmdEmail(amdEmail._id, body, dispatch);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: `AMD Email updated successfully - ${amdEmail._id}`,
              },
            ]),
          );
          history.push(amdEmailPath);
        } catch (err) {}
      }
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <div className={classes.rowContainer}>
          <div className={commonClasses.header}>
            {Translation(`recruit.amdEmail.form.title.${formMode === AmdEmailFormMode.CREATE ? 'create' : 'edit'}`)}
          </div>
        </div>
        <Button variant="contained" color="inherit" onClick={() => history.push(amdEmailPath)}>
          {Translation('app.button.back')}
        </Button>
      </div>

      <div className={classes.sectionMargin}>
        <div className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>
              {Translation('recruit.amdEmail.name')}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <div style={{ flexGrow: 1 }}>
            <TextField
              style={{ width: 250 }}
              error={errorState.mandatory.name}
              margin="dense"
              variant="outlined"
              helperText={errorState.mandatory.name && MANDATORY_FIELD_ERROR_TEXT}
              value={formState.name}
              onChange={(e) => {
                onDismissErrorHandler('name', e.target.value);
                formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'name', value: e.target.value } });
              }}
            />
          </div>
        </div>

        <div className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>
              {Translation('recruit.amdEmail.email')}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <div style={{ flexGrow: 1 }}>
            <TextField
              style={{ width: 250 }}
              error={errorState.mandatory.email}
              margin="dense"
              variant="outlined"
              helperText={errorState.mandatory.email && MANDATORY_FIELD_ERROR_TEXT}
              value={formState.email}
              onChange={(e) => {
                onDismissErrorHandler('email', e.target.value);
                formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'email', value: e.target.value } });
              }}
            />
          </div>
        </div>
      </div>

      <div className={classes.footerContainer}>
        <Button variant="contained" color="secondary" onClick={onSubmit}>
          {Translation('app.button.submit')}
        </Button>
      </div>
    </div>
  );
};

export default AmdEmailForm;
