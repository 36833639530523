export const defaultWalkInFormQuestionNames = [
  'page1',
  'registrant_role',
  'registrant_agentCode',
  'registrant_referredAgentCode',
  'registrant_firstName',
  'registrant_lastName',
  'registrant_email',
  'countryCode_phoneNumber',
  'registrant_countryCode',
  'registrant_phoneNumber',
];
