export const getPermissionNameFromKey = (key: string, Translation: (id: string) => string) => {
  const permissionMapping: Record<string, string> = {
    READ_OWN_LEAD: Translation('Home.pruleads'),
    READ_OWN_POLICY: Translation('Home.policy'),
    'READ_OWN_POLICY-NO-DOCUMENT': Translation('Home.policy_excl'),
    'READ_OWN_POLICY-DOCUMENT': Translation('Home.policy_document'),
    'READ_OWN_PREMIUM-DUE': Translation('Home.premium_due'),
    READ_OWN_PERFORMANCE: Translation('Home.performance'),
    READ_OWN_INCENTIVE: Translation('Home.incentive'),
    READ_OWN_TRAINING: Translation('Home.training'),
    READ_OWN_RECRUITMENT: Translation('Home.recruitment'),
    READ_OWN_NEWS: Translation('Home.prunews'),
    READ_OWN_DASHBOARD: Translation('Home.dashboard'),
    'READ_OWN_DASHBOARD-SUBMISSION': Translation('Home.submission'),
    'READ_OWN_DASHBOARD-PRODUCTION': Translation('Home.production'),
    'READ_OWN_DASHBOARD-PERSISTENCY': Translation('Home.persistency'),
    'READ_OWN_DASHBOARD-POLICY-STATUS': Translation('Home.policy_status'),
    'READ_OWN_DASHBOARD-PREMIUM-DUE': Translation('Home.premium_due'),
    'READ_OWN_DASHBOARD-CLIENTS': Translation('Home.clients'),
    'READ_OWN_DASHBOARD-RECRUITMENT': Translation('Home.recruitment'),
    READ_OWN_TODO: Translation('Home.my_task'),
    READ_OWN_EVENT: Translation('Home.event'),
    READ_OWN_CALENDAR: Translation('Home.calendar'),
    READ_OWN_INBOX: Translation('Home.notification'),
    READ_OWN_BANNER: Translation('Home.carousel'),
    'READ_OWN_E-PROFILE': Translation('Home.agent_profile'),
    READ_OWN_COMMISSION: Translation('Home.commission'),
    'READ_OWN_ASK-PRU': Translation('Home.askpru'),
    READ_OWN_WEBMAIL: Translation('Home.webmail'),
    READ_OWN_AWARDS: Translation('Home.personal_awards'),
    READ_OWN_SURVEY: Translation('Home.form_and_survey'),
    READ_OWN_FEEDBACK: Translation('Home.system_enquiry_feedback'),
    READ_OWN_BOOKING: Translation('Home.prubooking'),
    'READ_OWN_PULSE-GENIUS': Translation('Home.pulse_genius'),
    READ_OWN_SETTING: Translation('Home.system_setting'),
  };
  return permissionMapping[key];
};
