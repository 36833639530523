import React, { FC, useState } from 'react';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { useIntl } from 'react-intl';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useDispatch } from 'react-redux';
import { useDataProvider } from 'src/app/common/utils';
import {
  PersonalParticularListParam,
  fetchPersonalParticularList,
} from 'src/app/modules/Recruit/network/personalParticularCrud';
import { PaginateList } from 'src/app/common/types/common-types';
import { PersonalParticularItem } from 'src/app/modules/Recruit/types/personal-particular-types';
import GpsLocationList from './components/GpsLocationList';

type GpsLocationListingPageProps = {} & ParamsProps;

const initialState: PersonalParticularListParam = {
  appId: '',
  isFollowUpByZone: '',
  page: 1,
  limit: 5,
};

export const gpsLocationFilterKeys = ['appId'];

const paramsInitiator = (initialParams?: Record<string, string>): PersonalParticularListParam => {
  return initialParams
    ? {
        appId: initialParams.appId || '',
        isFollowUpByZone: initialParams.isFollowUpByZone || '',
        page: 1,
        limit: 5,
      }
    : initialState;
};

const GpsLocationListingPage: FC<GpsLocationListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const [filterState, setFilterState] = useState<PersonalParticularListParam>(paramsInitiator(initialParams));
  const [personalParticularList, setPersonalParticularList] = useState<PaginateList<PersonalParticularItem>>();

  const { isLoading, refreshData } = useDataProvider<PaginateList<PersonalParticularItem>>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return (await fetchPersonalParticularList(filterState, dispatch)).result;
      } catch (err) {}
    },
    setPersonalParticularList,
    false,
  );

  return (
    <>
      <PruFilter
        title={Translation('recruit.gpsLocation.filter')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'appId',
            initialValue: filterState.appId,
            displayName: Translation('recruit.common.applicationId'),
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            appId: data.appId,
          })
        }
        fetchData={refreshData}
      />
      <GpsLocationList
        isLoading={isLoading}
        personalParticularList={personalParticularList}
        onRefresh={refreshData}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default GpsLocationListingPage;
