import { Dispatch } from 'react';
import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import { ProductCampaignItem } from '../types/product-campaign-types';
import { forEach } from 'lodash';
import { PaginateList } from 'src/app/common/types/common-types';

const productCampaignEndPoint = 'product-campaign';

export type ProductCampaignListParam = {
  productCode: string;
  campaignName: string;
  page: number;
  limit: number;
};

export type CreateProductCampaignBody = {
  productCode: string;
  campaign: string;
  product?: string;
  interestedProductPlan?: string;
  leadDetail?: string;
  createdBy: string;
  updatedBy: string;
};

export type UpdateProductCampaignBody = {
  productCode: string;
  campaign: string;
  product?: string;
  interestedProductPlan?: string;
  leadDetail?: string;
  updatedBy: string;
};

export const fetchProductCampaignList = async (params: ProductCampaignListParam, dispatch?: Dispatch<any>) => {
  let queryPath = `${ADMIN_URL}/${productCampaignEndPoint}?`;
  forEach(params, (param, key) => {
    if (key === 'page') {
      queryPath += `${key}=${encodeURIComponent(param as any)}&`;
    } else {
      queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
    }
  });
  return apiClient
    .get<PaginateList<ProductCampaignItem>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchProductCampaignItem = async (id: string, dispatch?: Dispatch<any>): Promise<ProductCampaignItem> => {
  return apiClient
    .get<ProductCampaignItem>(`${ADMIN_URL}/${productCampaignEndPoint}/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchProductCampaignOptions = async (dispatch?: Dispatch<any>): Promise<any> => {
  return apiClient
    .get<any>(`${ADMIN_URL}/${productCampaignEndPoint}/options`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createProductCampaign = async (
  body: CreateProductCampaignBody,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .post<void>(`${ADMIN_URL}/${productCampaignEndPoint}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const modifyProductCampaign = async (
  id: string,
  body: UpdateProductCampaignBody,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .put<void>(`${ADMIN_URL}/${productCampaignEndPoint}/${id}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const deleteProductCampaign = async (id: string, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .delete<void>(`${ADMIN_URL}/${productCampaignEndPoint}/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
