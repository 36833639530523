import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  label: {
    fontSize: '14px',
    fontWeight: '500',
    color: '#333',
    '&.required:after': {
      content: '"*"',
      color: 'red',
      position: 'relative',
      top: '2px',
      left: '2px',
    },
  },
  item: {
    marginBottom: '20px',
    '.ct-bg': {
      boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
    },
    // '.empty-box': {
    //   display: 'none',
    // },
    '.pdf-title': {
      fontSize: '16px',
    },
    '.size-info': {
      fontSize: '13px',
      color: 'rgba(0, 0, 0, 0.6)',
    },
    '&.empty': {
      '.ct-bg': {
        boxShadow: 'none',
        border: 'dashed 1px rgba(0, 0, 0, 0.12)',
        padding: '16px',
      },
      // '.content-box': {
      //   display: 'none',
      // },
      '.empty-box': {
        display: 'block',
      },
      '.upload-btn': {
        marginRight: '20px',
      },
      '.upload-link': {
        color: 'rgba(67, 134, 229, 1)',
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    },
    '.r-btn, .error-info, .size-info': {
      display: 'none',
    },
    '&.uploaded': {
      '.delete-btn': {
        display: 'inline-block',
      },
      '.size-info': {
        display: 'block',
      },
    },
    '&.error': {
      '.error-btn,.show-error-btn,.delete-btn': {
        display: 'inline-block',
      },
      '.pdf-title': {
        display: 'block',
        color: 'rgba(232, 25, 44, 1)',
        fontSize: '16px',
      },
      '.error-info': {
        display: 'block',
        color: 'rgba(232, 25, 44, 1)',
        fontSize: '14px',
      },
    },
    '&.checked': {
      '.checked-btn,.download-btn,.delete-btn': {
        display: 'inline-block',
      },
      '.size-info': {
        display: 'block',
      },
    },
    '.opt-ct': {
      width: '180px',
    },
  },
  icon: {
    width: '32px',
  },
  btns: {
    '& > *:nth-child(2)': {
      marginLeft: '15px',
    },
    '& > *:nth-child(3)': {
      marginLeft: '15px',
    },
  },
}));
