import React from 'react';
import { getDefaultDisplayDate } from 'src/app/common/utils';

const Columns = (Translation: any, categoryList: any, locale: string) => {
  // const namesColumns = getNameColumns(Translation);
  const categoryFilterOptions: any = {
    '': { text: 'All' },
  };
  Object.entries(categoryList).forEach(([key, value]: any) => {
    categoryFilterOptions[key] = {
      text: value[locale]?.name,
    };
  });
  const columns = [
    {
      title: Translation('component.formLabel.name'),
      dataIndex: 'name',
      render: (_: any, data: any) => {
        return (
          <span>
            {
              data?.i18nData?.find((item: any) => {
                return item.locale === locale;
              })?.name
            }
          </span>
        );
      },
    },
    {
      title: Translation('component.formLabel.category'),
      dataIndex: 'categoryId',
      key: 'categoryId',
      render: (_: any, data: any) => {
        return <span>{categoryList[data.categoryId]?.[locale]?.name || '-'}</span>;
      },
      valueEnum: categoryFilterOptions,
    },
    {
      title: Translation('mandatory.training.component.formLabel.completion.deadline.type'),
      dataIndex: 'completionDeadline.value',
      render: (_: any, data: any) => {
        return (
          <span>
            {!data?.completionDeadline
              ? ''
              : data?.completionDeadline?.type === 0
              ? Translation('mandatory.training.component.formLabel.completion.deadline.type.fixed_date')
              : data?.completionDeadline?.type === 1
              ? Translation('mandatory.training.component.formLabel.completion.deadline.type.condition')
              : data?.completionDeadline?.type === 2
              ? Translation('mandatory.training.component.formLabel.completion.deadline.type.condition')
              : ''}
          </span>
        );
      },
      hideInSearch: true,
    },
    {
      title: Translation('mandatory.training.component.formLabel.completion.deadline.text'),
      dataIndex: 'completionDeadline',
      render: (_: any, data: any) => {
        return (
          <span>
            {!data?.completionDeadline
              ? ''
              : data?.completionDeadline?.type === 0
              ? getDefaultDisplayDate(data?.completionDeadline?.value)
              : data?.completionDeadline?.type === 1
              ? Translation(
                  'mandatory.training.component.formLabel.completion.deadline.type.condition.value.month',
                ).replace('%d', data?.completionDeadline.value)
              : data?.completionDeadline?.type === 2
              ? Translation(
                  'mandatory.training.component.formLabel.completion.deadline.type.condition.value.day',
                ).replace('%d', data?.completionDeadline.value)
              : ''}
          </span>
        );
      },
      hideInSearch: true,
    },
    {
      title: Translation('component.formLabel.publish-status'),
      prop: 'publishStatus',
      render: (_: any, row: any) => (
        <span>
          {
            [
              Translation('event.publish.status.Draft.label'),
              Translation('event.publish.status.Published.label'),
              Translation('event.publish.status.Unpublished.label'),
              Translation('event.publish.status.WaitingForPublish.label'),
            ][row.publishStatus]
          }
        </span>
      ),
      hideInSearch: true,
    },
    {
      title: Translation('component.formLabel.last-updated-time'),
      sorter: true,
      dataIndex: 'updateAt',
      render: (_: any, row: any) => (
        <span>{row.updatedAt ? getDefaultDisplayDate(row.updatedAt, 'datetime') : '-'}</span>
      ),
      hideInSearch: true,
    },
    {
      title: Translation('component.formLabel.last-updated-by'),
      dataIndex: 'modifier',
      render: (_: any, row: any) => <span>{row.updatedBy}</span>,
    },
  ];

  return columns;
};

export default Columns;
