import React, { useState, useEffect } from 'react';
import Table from './ProTable';
import Filter from './ProFilter';
import { useDataProvider } from './hook';

export interface ProCoreActionType {
  /**
   * @name 刷新
   */
  reload: (resetPageIndex?: boolean) => void;
  /**
   * @name 刷新并清空
   */
  // reloadAndRest?: () => void;
  // /**
  //  * @name 重置
  //  */
  // reset?: () => void;

  // /**
  //  * @name 清空选择
  //  */
  // clearSelected?: () => void;
}

export type ActionType = ProCoreActionType;

export type ParamsType = {
  [key: string]: React.ReactText | React.ReactText[];
};

type ProSchemaValueEnumType = {
  text: React.ReactNode;
  status: string;
};

export type ProSchemaValueEnumObj = {
  [key: string]: any;
};

export interface FilterDropdownParams {
  // selectedKeys: ,
  confirm?: () => void;
  clearFilters?: () => void;
}

export interface ProColumns<T> {
  key?: React.ReactText;
  // dataIndex?: string | number | (string | number)[];
  align?: 'inherit' | 'left' | 'right' | 'center' | 'justify';
  dataIndex?: string;
  valueType?: string;
  title: React.ReactNode;
  render?: (dom: React.ReactNode, entity: T) => React.ReactNode;
  valueEnum?: ProSchemaValueEnumObj;
  hideInForm?: boolean;
  hideInSearch?: boolean;
  sorter?: boolean;
  request?: () => Promise<{ label: string; value: string; key: number }[]>;
  filterDropdown?: (params: FilterDropdownParams) => React.ReactNode;
  sticky?: boolean;
  hideInTable?: boolean;
  onChange?: (value?: any) => void;
  initialValue?: any;
  require?: boolean;
  multiple?: boolean;
  renderValue?: any;
  validate?: any;
}

export interface RequestData<T> {
  data: T[];
  includes?: {
    Entry: T[];
  };
  success?: boolean;
  total?: number;
  [key: string]: any;
}

export type ToolBarRender = () => React.ReactNode[];
export type HeaderTitle = React.ReactText | React.ReactNode;

export type RowSelection<T> = {
  onChange: (_: any, selectedRows: T[]) => void;
  getCheckboxProps: (record: T) => {
    disabled?: boolean;
  };
  selectItems?: any;
};

export interface ProTableProps<T, U extends ParamsType> {
  columns: ProColumns<T>[];
  request: (
    params: U & {
      pageSize?: number;
      current?: number;
      keyword?: string;
    },
    sort: {
      [key: string]: any;
    },
    filter: { [key: string]: React.ReactText[] },
  ) => Promise<RequestData<T>>;
  toolBarRender: ToolBarRender;
  filterTitle: string;
  headerTitle: HeaderTitle;
  headerTitleStyle?: string | any;
  rowKey?: string;
  actionRef?: React.MutableRefObject<ProCoreActionType | undefined>;
  rowSelection?: RowSelection<T>;
  hideReset?: boolean;
  hidePagination?: boolean;
  hideToolBar?: boolean;
  hideAllSelect?: boolean;
  onLoad?: (record: T[]) => void;
  singleSelect?: boolean;
  filterNumberPerRow?: number;
  pageSize?: number;
  titleStyle?: React.CSSProperties;
  titleFontStyle?: React.CSSProperties;
}

// interface PageConfig {
//   page: number;
//   pageSize: number;
// }

// interface FilterParams extends PageConfig,

// const ProTable: FC<ProTableProps<T>> = ({ history }) => {
const ProTable = <T extends { [key: string]: any }, U extends { [key: string]: any }>({
  request,
  columns,
  filterTitle,
  headerTitle,
  hideReset,
  toolBarRender,
  rowKey,
  actionRef,
  rowSelection,
  hidePagination,
  onLoad,
  headerTitleStyle,
  singleSelect,
  filterNumberPerRow,
  pageSize,
  hideToolBar,
  hideAllSelect,
  titleStyle,
  titleFontStyle,
}: ProTableProps<T, U>) => {
  const [filterState, setFilterState] = useState<any>(
    hidePagination
      ? {}
      : {
          page: 0,
          pageSize: pageSize,
        },
  );
  const [filterSavedState, setFilterSavedState] = useState<any>(
    hidePagination
      ? {}
      : {
          page: 0,
          pageSize: pageSize,
        },
  );
  const [sorter, setSorter] = useState<{ [key: string]: string }>({});
  const { loading, reload, dataSource, total } = useDataProvider<RequestData<T>, T>(async () => {
    return await request(filterSavedState, sorter, {});
  }, onLoad);

  // todo: query data after reset
  // const resetPageIndex = () => {
  //   setFilterState((state: any) => ({
  //     ...state,
  //     page: 0,
  //     pageSize: 20
  //   }))
  // }

  // useUpdateEffect(() => {
  //   if (page !== filterState.page) {
  //     setFilterSavedState({
  //       ...filterState,
  //       page
  //     })
  //     setFilterState({
  //       ...filterState,
  //       page
  //     })
  //   }
  // // eslint-disable-next-line
  // }, [page])

  useEffect(() => {
    if (actionRef) actionRef.current = { reload };
    reload();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Filter
        title={filterTitle}
        columns={columns}
        filterState={filterState}
        setFilterState={setFilterState}
        filterNumberPerRow={filterNumberPerRow || 3}
        // onChangeFilter={data => setFilterState({
        //   ...filterState,
        //   ...data
        // })}
        onSave={(data) => {
          setFilterState({
            ...data,
            page: 0,
          });
          setFilterSavedState({
            ...data,
            page: 0,
          });
          reload();
        }}
        hideReset={hideReset}
      />
      <Table<T>
        hidePagination={hidePagination}
        hideToolBar={hideToolBar}
        hideAllSelect={hideAllSelect}
        rowSelection={rowSelection}
        singleSelect={singleSelect}
        isLoading={loading}
        dataSource={dataSource}
        columns={columns}
        onRefresh={() => {
          setFilterState(filterSavedState);
          reload();
        }}
        page={filterState.page}
        pageSize={filterState.pageSize}
        total={total}
        toolBarRender={toolBarRender}
        headerTitle={headerTitle}
        rowKey={rowKey}
        headerTitleStyle={headerTitleStyle}
        titleStyle={titleStyle}
        titleFontStyle={titleFontStyle}
        onChangePage={(params) => {
          setFilterSavedState({
            ...filterSavedState,
            ...params,
          });
          setFilterState({
            ...filterSavedState,
            ...params,
          });
          reload();
        }}
        handleSort={(sorter) => {
          setSorter((oldSorter) => {
            // only support latest sorter; only support one sorter.
            // currently requirement just need to sort by one field.
            const newDiffSort: { [key: string]: string } = {};
            Object.entries(sorter).some(([key, value]) => {
              if (oldSorter[key] !== sorter[key]) {
                newDiffSort[key] = value;
                return true;
              }
              return false;
            });
            return newDiffSort;
          });
          reload();
        }}
      />
    </>
  );
};

export default ProTable;
