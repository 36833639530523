import React, { FC, useReducer, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  AccordionActions,
  Divider,
  Button,
  CircularProgress,
  TextField,
  InputAdornment,
  IconButton,
  Tooltip,
} from '@mui/material';
import ModulePermissionTree from './ModulePermissionTree';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { useCommonStyles } from '../../../../../common/styles/common-styles';
import {
  PermissionItem,
  RolePermissionItem,
  PermissionType,
  PermissionPossession,
} from '../../../types/permission-types';
import { ModuleHierachy } from '../../../../../common/module/types';
import { useIntl } from 'react-intl';
import { UpdateRolePermissionsBody, updateRolePermissions, deleteRole } from '../../../network/permissionCrud';
import { useDispatch } from 'react-redux';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import { useErrorHandler, ErrorFieldType, useUpdateEffect } from 'src/app/common/utils';
import { MANDATORY_FIELD_ERROR_TEXT, RESOURCE_NAMESPACE } from 'src/app/common/constants';
import ItemDeleteDialog from 'src/app/common/components/ItemDeleteDialog';
import ModulePermissionItem from './ModulePermissionItem';

type ModuleStaffSectionProps = {
  expanded: boolean;
  rolePermission: RolePermissionItem;
  selectedModule: string;
  moduleMap: ModuleHierachy[];
  onToggle: (event: React.ChangeEvent<{}>, isExpanded: boolean) => void;
  onClose: () => void;
  onSubmitted: () => void;
};

const useStyles = makeStyles()((theme) => ({
  modal: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.grey[300],
    opacity: 0.35,
    borderRadius: 8,
  },
  loading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  section: {
    backgroundColor: theme.palette.grey[100],
    padding: '10px 10px 10px 15px',
    borderRadius: 8,
    fontSize: '1rem',
    fontWeight: 'normal',
    marginBottom: 10,
  },
  root: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  header: {
    fontSize: '1.15rem',
    fontWeight: 400,
  },
  rowContainer: {
    padding: '0 0 0 15px',
    display: 'flex',
  },
  fieldContainer: {
    width: 150,
    paddingTop: 16,
    boxSizing: 'border-box',
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },
}));

type RolePermissionState = {
  groupId: string;
  groupEmail?: string;
  type: string;
  name: string;
  permissions: PermissionItem[];
};

type InitAction = {
  type: 'INIT_STATE';
  payload: RolePermissionItem;
};

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof Pick<RolePermissionState, 'groupId' | 'groupEmail'>;
    value: string;
  };
};

type ModifyByResourceAction = {
  type: 'MODIFY_PERMISSIONS_BY_RESOURCE';
  payload: {
    resource: string;
    permissions: PermissionItem[];
  };
};

type ToggleResourcePermissionAction = {
  type: 'TOGGLE_RESOURCE_PERMISSIONS';
};

type RolePermissionAction = InitAction | ModifyFieldAction | ModifyByResourceAction | ToggleResourcePermissionAction;

const isFoundResource = (permissions: PermissionItem[]) => {
  return !!permissions.find((item) => {
    if (item.resource.toLowerCase() === RESOURCE_NAMESPACE.toLowerCase() && item.possession === 'own') {
      if (
        item.action.toLowerCase() === PermissionType.READ.toLowerCase() ||
        item.action.toLowerCase() === PermissionType.CREATE.toLowerCase() ||
        item.action.toLowerCase() === PermissionType.UPDATE.toLowerCase()
      ) {
        return true;
      }
    }
    return false;
  });
};

const formReducer = (state: RolePermissionState, action: RolePermissionAction): RolePermissionState => {
  switch (action.type) {
    case 'INIT_STATE':
      return { ...action.payload };
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    case 'TOGGLE_RESOURCE_PERMISSIONS':
      const resourceExist = isFoundResource(state.permissions);
      return resourceExist
        ? {
            ...state,
            permissions: [
              ...state.permissions,
              {
                action: PermissionType.READ.toLowerCase(),
                possession: 'own',
                resource: RESOURCE_NAMESPACE.toLowerCase(),
                attributes: ['*'],
              },
              {
                action: PermissionType.CREATE.toLowerCase(),
                possession: 'own',
                resource: RESOURCE_NAMESPACE.toLowerCase(),
                attributes: ['*'],
              },
              {
                action: PermissionType.UPDATE.toLowerCase(),
                possession: 'own',
                resource: RESOURCE_NAMESPACE.toLowerCase(),
                attributes: ['*'],
              },
            ],
          }
        : {
            ...state,
            permissions: state.permissions.filter(
              (item) =>
                !(item.resource.toLowerCase() === RESOURCE_NAMESPACE.toLowerCase() && item.possession === 'own'),
            ),
          };
    case 'MODIFY_PERMISSIONS_BY_RESOURCE':
      const originalItems = state.permissions.filter(
        (item) => item.resource.toLowerCase() !== action.payload.resource.toLowerCase(),
      );
      return {
        ...state,
        permissions: [...originalItems, ...action.payload.permissions],
      };
  }
};

type DialogState = {
  open: boolean;
  type: string;
  name: string;
};

const ModuleStaffSection: FC<ModuleStaffSectionProps> = ({
  expanded,
  selectedModule,
  rolePermission,
  moduleMap,
  onToggle,
  onSubmitted,
  onClose,
}) => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const { classes: commonClasses } = useCommonStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const [isDisableEditGroupId, setIsDisableEditGroupId] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [dialogState, setDialogState] = useState<DialogState>({
    open: false,
    type: '',
    name: '',
  });

  const [formState, formDispatch] = useReducer(formReducer, {
    groupId: rolePermission.groupId || '',
    groupEmail: rolePermission.groupEmail,
    type: rolePermission.type,
    name: rolePermission.name,
    permissions: rolePermission.permissions,
  });

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler } = useErrorHandler(formState, [
    {
      name: 'groupId',
      fieldType: ErrorFieldType.MANDATORY,
      condition: () => {
        if (formState.name !== '*' && !formState.groupId) {
          return true;
        } else {
          return false;
        }
      },
    },
  ]);

  useUpdateEffect(() => {
    if (!expanded) {
      formDispatch({ type: 'INIT_STATE', payload: rolePermission });
    }
  }, [expanded]);

  const onUpdate = (resource: string, permissions: PermissionItem[]) => {
    formDispatch({
      type: 'MODIFY_PERMISSIONS_BY_RESOURCE',
      payload: {
        resource,
        permissions,
      },
    });
  };

  const onDelete = async (type: string, name: string) => {
    try {
      await deleteRole({ type, name }, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Role deleted successfully - ${name}`,
          },
        ]),
      );
    } catch (err) {}
  };

  const onSubmit = async (body: UpdateRolePermissionsBody) => {
    const { hasError } = onSubmitErrorValidator();
    if (!hasError) {
      setIsSubmitting(true);
      try {
        await updateRolePermissions(body, dispatch);
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: 'Success',
              content: `Record updated for staff - ${body.name}`,
            },
          ]),
        );
        onSubmitted();
      } catch (err) {
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <>
      <ItemDeleteDialog
        key={`delete-role-dialog-${dialogState.open}`}
        open={dialogState.open}
        onClose={() => setDialogState({ open: false, type: '', name: '' })}
        onRefresh={onSubmitted}
        onDelete={() => onDelete(dialogState.type, dialogState.name)}
      />
      <Accordion
        expanded={expanded}
        onChange={(event, isExpanded) => onToggle(event, isExpanded)}
        TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography
            className={expanded ? commonClasses.header : ''}
            style={{ fontSize: '1.1rem', fontWeight: 'bold' }}
          >
            {rolePermission.name}
            {expanded && (
              <Tooltip title="Delete Role">
                <IconButton
                  style={{ padding: 5, marginLeft: 8 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setDialogState({
                      open: true,
                      type: formState.type,
                      name: formState.name,
                    });
                  }}
                >
                  <DeleteForeverOutlinedIcon />
                </IconButton>
              </Tooltip>
            )}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {isSubmitting && (
            <>
              <div className={classes.modal} />
              <CircularProgress color="secondary" className={classes.loading} size={45} />
            </>
          )}
          <div className={classes.root}>
            <div className={classes.section}>
              <div className={classes.header}>{Translation('roleControl.role.basicInformation')}</div>
              <div className={classes.rowContainer}>
                <div className={classes.fieldContainer}>
                  <span className={classes.field}>
                    {Translation('roleControl.common.groupId')}
                    <span className={classes.mandatory}>*</span> :
                  </span>
                </div>
                <div style={{ flexGrow: 1 }}>
                  <TextField
                    style={{ width: 350 }}
                    disabled={isDisableEditGroupId}
                    error={errorState.mandatory.groupId}
                    margin="dense"
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton style={{ padding: 5 }} onClick={() => setIsDisableEditGroupId(false)}>
                            <EditIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    helperText={
                      errorState.mandatory.groupId
                        ? MANDATORY_FIELD_ERROR_TEXT
                        : Translation('roleControl.groupId.warning')
                    }
                    value={formState.groupId}
                    onBlur={() => setIsDisableEditGroupId(true)}
                    onChange={(e) => {
                      onDismissErrorHandler('groupId', e.target.value);
                      formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'groupId', value: e.target.value } });
                    }}
                  />
                </div>
              </div>
              <div className={classes.rowContainer}>
                <div className={classes.fieldContainer}>
                  <span className={classes.field}>{Translation('roleControl.common.groupEmail')} :</span>
                </div>
                <div style={{ flexGrow: 1 }}>
                  {/* <TextField
                    style={{ width: 350 }}
                    margin="dense"
                    variant="outlined"
                    value={formState.groupEmail}
                    onChange={(e) => {
                      formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'groupEmail', value: e.target.value } });
                    }}
                  /> */}
                </div>
              </div>
            </div>
            <div className={classes.section}>
              <div className={classes.header}>{Translation('roleControl.title.resource')}</div>
              <div style={{ padding: '5px 0 5px 15px' }}>
                <ModulePermissionItem
                  title={Translation('roleControl.form.selfResource')}
                  onUpdate={onUpdate}
                  possession={PermissionPossession.OWN}
                  permissions={formState.permissions}
                  namespace={RESOURCE_NAMESPACE}
                />
              </div>
            </div>
            <ModulePermissionTree
              style={{ width: '100%' }}
              isRoot
              onUpdate={onUpdate}
              permissions={formState.permissions}
              selectedModule={selectedModule}
              treeNode={moduleMap}
            />
          </div>
        </AccordionDetails>
        <Divider />
        <AccordionActions>
          <Button size="small" onClick={onClose}>
            {Translation('global.text.cancel')}
          </Button>
          <Button size="small" color="secondary" onClick={() => onSubmit(formState)}>
            {Translation('global.text.confirm')}
          </Button>
        </AccordionActions>
      </Accordion>
    </>
  );
};

export default ModuleStaffSection;
