import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import PruRoute from 'src/app/common/components/PruRoute';
import ParamsProvider from 'src/app/common/components/ParamsProvider';
import { INSIGHTS_BASE_PATH, INSIGHTS_DASHBOARD_PATH, INSIGHTS_DASHBOARD_USER_PATH } from '../../../constants';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import UserReportListingPage, { userReportFilterKeys } from './List/UserReportListingPage';

const userReportPath = `${INSIGHTS_BASE_PATH}${INSIGHTS_DASHBOARD_PATH}${INSIGHTS_DASHBOARD_USER_PATH}`;

const UserReportRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect exact from={`${userReportPath}`} to={`${userReportPath}/list`} />
      <PruRoute
        exact
        path={`${userReportPath}/list`}
        render={(props) => (
          <ParamsProvider {...props} acceptKeys={userReportFilterKeys}>
            <UserReportListingPage />
          </ParamsProvider>
        )}
      />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default UserReportRoutes;
