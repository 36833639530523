import { FC } from 'react';
import { RouteComponentProps, Switch, Redirect } from 'react-router-dom';
import PruRoute from 'src/app/common/components/PruRoute';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { ModulePermissionProps } from 'src/app/common/module/types';
import { SALESKIT_BASE_PATH, INSURANCE_SUMMARY_PATH, INSURANCE_SUMMARY_FEEDBACK_PATH } from '../../../constants';
import InsuranceSummaryInsightPage, { InsuranceSummaryFilterKeys } from '../page/insurance-summary-feedback-page';
import ParamsProvider from 'src/app/common/components/ParamsProvider';

const InsuranceSummaryFeedbackPath = `${SALESKIT_BASE_PATH}${INSURANCE_SUMMARY_PATH}${INSURANCE_SUMMARY_FEEDBACK_PATH}`;
const InsuranceSummaryPath = `${SALESKIT_BASE_PATH}${INSURANCE_SUMMARY_PATH}`;

const InsuranceSummaryFeedbackRoutes: FC<RouteComponentProps & ModulePermissionProps> = ({ history }) => {
  return (
    <Switch>
      <PruRoute
        exact
        path={InsuranceSummaryFeedbackPath}
        render={(props) => (
          <ParamsProvider {...props} acceptKeys={InsuranceSummaryFilterKeys}>
            <InsuranceSummaryInsightPage history={history} />
          </ParamsProvider>
        )}
      />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default InsuranceSummaryFeedbackRoutes;
