import { useEffect, useReducer, Dispatch } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { regionLocale, initI18nGenericData, RegionLocale } from 'src/app/i18n';
import { useErrorHandler, ErrorFieldType, ErrorFieldDef } from 'src/app/common/utils';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import { SessionTypeEnum, EventSessionItem } from 'src/app/modules/event-v2/types';
import {
  BasicsFormAction,
  SessionFormState,
  sessionFormReducer,
} from 'src/app/modules/event-v2/pages/event-list/form/reducers';
import { checkInvalidQuotaFormat } from 'src/app/modules/event-v2/pages/event-list/form/common';

interface HookProps {
  sessionNum: number;
  sessionItem?: EventSessionItem;
  updateBasicsForm: Dispatch<BasicsFormAction>;
  onClose: () => void;
}

const detailToStateConvertor = (
  regionLocale: RegionLocale[],
  sessionNum: number,
  sessionItem?: EventSessionItem | SessionFormState,
): SessionFormState => {
  const initI18nStringData = initI18nGenericData<string>(regionLocale, '');
  return sessionItem
    ? {
        key: sessionItem.key,
        type: sessionItem.type,
        startTime: sessionItem.startTime,
        endTime: sessionItem.endTime,
        name: sessionItem.name,
        venue: sessionItem.venue,
        multiSubSession: sessionItem.multiSubSession,
        quota: sessionItem.quota,
        subSessions: sessionItem.subSessions,
        subSessionEnrollment: sessionItem.subSessionEnrollment,
      }
    : {
        key: `session${sessionNum}`,
        type: SessionTypeEnum.SESSION,
        startTime: null,
        endTime: null,
        name: initI18nStringData,
        venue: initI18nStringData,
        multiSubSession: undefined,
        quota: undefined,
        subSessions: [],
        subSessionEnrollment: undefined,
      };
};

const getErrorFieldDef = (
  formState: SessionFormState,
  errorNameSuffix: string,
  quotaEnabled: boolean,
  isSubSession?: boolean,
) => {
  return [
    {
      name: `startTime${errorNameSuffix}`,
      fieldType: ErrorFieldType.MANDATORY,
      condition: () => !!!formState.startTime,
    },
    {
      name: `endTime${errorNameSuffix}`,
      fieldType: ErrorFieldType.MANDATORY,
      condition: () => !!!formState.endTime,
    },
    {
      name: `endTimeBeforeStartTime${errorNameSuffix}`,
      fieldType: ErrorFieldType.IMMEDIATE,
      condition: () => {
        if (formState.startTime && formState.endTime) {
          return !!moment(new Date(formState.startTime)).isSameOrAfter(moment(new Date(formState.endTime)));
        } else {
          return false;
        }
      },
    },
    {
      name: `quota${errorNameSuffix}`,
      fieldType: ErrorFieldType.MANDATORY,
      condition: () => quotaEnabled && !!!formState.quota,
    },
    {
      name: `quotaFormat${errorNameSuffix}`,
      fieldType: ErrorFieldType.IMMEDIATE,
      condition: () => quotaEnabled && checkInvalidQuotaFormat(formState.quota),
    },
    ...(() => {
      let errorFieldDef: ErrorFieldDef[] = [];
      regionLocale.forEach((locale) => {
        errorFieldDef = [
          ...errorFieldDef,
          {
            name: `name-${locale}${errorNameSuffix}`,
            fieldType: ErrorFieldType.MANDATORY,
            condition: () => !!!formState.name[locale],
          },
        ];
      });
      if (!isSubSession) {
        errorFieldDef = [
          ...errorFieldDef,
          {
            name: 'multiSubSession',
            fieldType: ErrorFieldType.MANDATORY,
          },
          {
            name: 'subSessionEnrollment',
            fieldType: ErrorFieldType.MANDATORY,
            condition: () => formState.multiSubSession === true && !!!formState.subSessionEnrollment,
          },
        ];
      }
      formState.subSessions.forEach((subSessionItem, index) => {
        errorFieldDef = [
          ...errorFieldDef,
          ...getErrorFieldDef(subSessionItem as SessionFormState, `-sub${index + 1}`, true, true),
        ];
      });
      return errorFieldDef;
    })(),
  ];
};

export const useSessionForm = ({ sessionNum, sessionItem, updateBasicsForm, onClose }: HookProps) => {
  const dispatch = useDispatch();
  const [formState, formDispatch] = useReducer(
    sessionFormReducer,
    detailToStateConvertor(regionLocale, sessionNum, sessionItem),
  );

  const { errorState, onSubmitErrorValidator, immediateErrorValidator, onDismissErrorHandler } = useErrorHandler(
    formState,
    getErrorFieldDef(formState, '', formState.multiSubSession === false),
  );

  useEffect(() => {
    immediateErrorValidator();
  }, [formState.startTime, formState.endTime, formState.quota, formState.subSessions]);

  const onSubmit = async () => {
    const { hasError } = onSubmitErrorValidator();
    if (!hasError) {
      if (formState.multiSubSession && formState.subSessions.length === 0) {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.WARNING,
              title: 'Notice',
              content: 'Please set up at least one sub session',
            },
          ]),
        );
      } else {
        if (sessionItem) {
          updateBasicsForm({
            type: 'MODIFY_SESSION',
            payload: {
              index: sessionNum - 1,
              value: formState as EventSessionItem,
            },
          });
        } else {
          updateBasicsForm({
            type: 'ADD_SESSION',
            payload: {
              value: formState as EventSessionItem,
            },
          });
        }
        onClose();
      }
    }
  };

  return {
    formState,
    errorState,
    formDispatch,
    onDismissErrorHandler,
    onSubmit,
  };
};
