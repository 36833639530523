import React, { useEffect, useReducer, useState } from 'react';
import { useIntl } from 'react-intl';
import { makeStyles } from 'tss-react/mui';
import { Button, Popper, Snackbar } from '@mui/material';
import ClickableRichTooltip from 'src/app/common/components/RichTooltip/ClickableRichTooltip';
import { toAbsoluteUrl } from 'src/app/common/utils';

const useStyles = makeStyles()(() =>
  ({
    menuItem: {
      display: 'flex',
      flexDirection: 'row',
      height: 40,
      alignItems: 'center',
      paddingLeft: 10,
      paddingRight: 10,
      '&:hover': {
        backgroundColor: '#E7E7E7',
      },
    }
  }));

interface AddParticipantMenuProps {
  campaignObjId: string | undefined;
  onImportFromFile: () => void;
  onSelectFromList: () => void;
}

const initialState: any = {};

const AddParticipantMenu: React.FC<AddParticipantMenuProps> = ({
  campaignObjId,
  onSelectFromList,
  onImportFromFile,
}) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { classes } = useStyles();
  const msg = Translation('agencyCampaign.create.saveNotice');
  // const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [snackBar, setSnackBar] = useState<{ msg: string; visible: boolean }>({ msg: '', visible: false });
  const closeSnackBar = () => setSnackBar({ msg, visible: false });

  const handleClick = (command?: boolean) => {
    if (!campaignObjId) {
      return setSnackBar({ msg, visible: true });
    }
    if (command) {
      setIsOpen(command);
    } else {
      setIsOpen(!isOpen);
    }
  };

  const id = undefined;

  return (
    <React.Fragment>
      <ClickableRichTooltip
        setOpen={(data) => {
          handleClick(data);
        }}
        open={isOpen}
        content={
          <>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div
                className={classes.menuItem}
                onClick={() => {
                  onSelectFromList();
                  handleClick(false);
                }}
              >
                <img
                  src={toAbsoluteUrl('/media/icon/selectFromList.png')}
                  alt={'Select from list'}
                  style={{ width: 19, height: 19, margin: 10 }}
                />
                <div
                  style={{
                    borderBottom: '1px solid #E7E7E7',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <div>{Translation('agencyCampaign.create.button.selectFromList')}</div>
                </div>
              </div>
              {(window as any).ReactNativeWebView ? null : (
                <div
                  className={classes.menuItem}
                  onClick={() => {
                    onImportFromFile();
                    handleClick(false);
                  }}
                >
                  <img
                    src={toAbsoluteUrl('/media/icon/importFromFile.png')}
                    alt={'Import from file'}
                    style={{ width: 19, height: 19, margin: 10 }}
                  />
                  <div>{Translation('agencyCampaign.create.button.importFromFile')}</div>
                </div>
              )}
            </div>
          </>
        }
        placement="bottom-end"
      >
        <Button
          aria-describedby={id}
          variant="contained"
          color="secondary"
          size="large"
          style={{ width: 200 }}
          onClick={() => {
            handleClick();
          }}
        >
          {Translation('app.button.add')}
        </Button>
      </ClickableRichTooltip>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackBar.visible}
        autoHideDuration={5000}
        onClose={closeSnackBar}
        message={snackBar.msg}
      />
    </React.Fragment>
  );
};

export default AddParticipantMenu;
