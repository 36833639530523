import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { LibraryItem, LibraryPaginateList } from '../../../types/library-types';
import PruTable from '../../../../../../../common/components/PruTable/PruTable';
import { getDefaultDisplayDate, getEnabledCustomWeight } from 'src/app/common/utils';
import { ResourceType, LibraryPublishStatus } from '../../../constants';
import { getType, renderDisplayStatusField } from '../../../utils/common-utils';
import './LibraryList.css';
import { WeightColumn } from './weight-column/weight-column.component';
import { get } from 'lodash';

type LibraryListProps = {
  isLoading: boolean;
  libraryList?: LibraryPaginateList;
  enableUpdate: boolean;
  enableCreate: boolean;
  libraryTableTitle: string;
  currentLanguage: string;
  enablePerformance: boolean;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
  onSort: (sortingState: { key: string; value?: string }) => void;
  onAddLibrary: (type?: string, selectedRow?: LibraryItem[]) => void;
  onEditLibrary: (rowData: LibraryItem, mode: string) => void;
  onPerformance: (rowData: LibraryItem) => void;
  onUnpublishLibraryClicked: (rowData: LibraryItem) => void;
  defaultPageNumber?: number;
};

const LibraryList: FC<LibraryListProps> = ({
  isLoading,
  libraryList,
  enableUpdate,
  enableCreate,
  libraryTableTitle,
  currentLanguage,
  enablePerformance,
  onRefresh,
  onChangePage,
  onSort,
  onAddLibrary,
  onEditLibrary,
  onPerformance,
  onUnpublishLibraryClicked,
  defaultPageNumber,
}) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const enabledCustomWeight = getEnabledCustomWeight();
  const [selectedRowsState, setSelectedRows] = useState<any[]>([]);
  // const getColumnDisplayList = (columnRefList: any): string[] => {
  //   let resultList: string[] = [];
  //   columnRefList.map((item: any) => {
  //     if (item && item.column) {
  //       return resultList.push(` ${item.column.name[currentLanguage]}`);
  //     }
  //     return null;
  //   });

  //   return resultList;
  // };

  return (
    <div id="material-table">
      <PruTable
        title={Translation(libraryTableTitle)}
        defaultPageNumber={defaultPageNumber}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('app.button.add'),
            onClick: () => {
              onAddLibrary();
            },
            condition: () => !!enableCreate,
          },
          {
            color: 'primary',
            title: Translation('app.button.add-set'),
            onClick: () => {
              onAddLibrary(ResourceType.materialSet, selectedRowsState);
            },
            condition: () => !!enableCreate && selectedRowsState.length > 0,
          },
        ]}
        operationDef={[
          {
            title: Translation('app.button.view'),
            tooltipText: Translation('app.button.view'),
            onClick: (row) => {
              onEditLibrary(row, 'view');
            },
          },
          {
            title: Translation('app.button.edit'),
            tooltipText: Translation('app.button.edit'),
            onClick: (row) => {
              onEditLibrary(row, 'edit');
            },
            condition: () => !!enableUpdate,
          },
          {
            title: Translation('app.button.unpublish'),
            tooltipText: Translation('app.button.unpublish'),
            onClick: (row) => onUnpublishLibraryClicked(row),
            condition: (row) => row.status === LibraryPublishStatus.Publish && !!enableUpdate && !row.publishToSetOnly,
          },
          {
            title: Translation('app.button.performance'),
            tooltipText: Translation('app.button.performance'),
            onClick: (row) => {
              onPerformance(row);
            },
            condition: () => !!enablePerformance,
          },
        ]}
        columnDef={[
          {
            isId: true,
            keyIndex: 'id',
            hidden: true,
            displayName: '',
            renderData: (row) => '',
          },
          {
            keyIndex: 'name',
            align: 'left',
            displayName: Translation('component.formLabel.name'),
            renderData: (row) => String(row.name ? row.name : ''),
            sortable: true,
            onSort: (sort) => {
              onSort({ key: 'name', value: sort['name'] });
            },
          },
          {
            keyIndex: 'category',
            align: 'left',
            displayName: Translation('component.formLabel.category'),
            renderData: (row) => String(row.category?.['name'] ? row.category?.['name'] : ''),
            sortable: true,
            onSort: (sort) => {
              onSort({ key: 'category.name', value: sort['category'] });
            },
          },
          {
            keyIndex: 'status',
            align: 'left',
            displayName: Translation('component.formLabel.status'),
            renderData: (row) => (row ? Translation(renderDisplayStatusField(row)) : ''),
            sortable: true,
            onSort: (sort) => {
              onSort({ key: 'status', value: sort['status'] });
            },
          },
          {
            keyIndex: 'type',
            align: 'left',
            displayName: Translation('component.formLabel.type'),
            renderData: (row) => String(row.type ? getType(row.type) : ''),
            sortable: true,
            onSort: (sort) => {
              onSort({ key: 'type', value: sort['type'] });
            },
          },
          {
            keyIndex: 'maxWeight',
            hidden: !enabledCustomWeight,
            align: 'left',
            displayName: Translation('component.formLabel.weight'),
            renderData: (row) => <WeightColumn rawData={row} currentLanguage={currentLanguage} columnName="weight" />,
            sortable: true,
            onSort: (sort) => {
              onSort({ key: 'maxWeight', value: sort['maxWeight'] });
            },
          },
          {
            keyIndex: 'moduleColumn',
            align: 'left',
            displayName: Translation('component.formLabel.published-to-module'),
            renderData: (row) => <WeightColumn rawData={row} currentLanguage={currentLanguage} columnName="column" />,
            // renderData: (row) => String(row.columnReferences ? getColumnDisplayList(row.columnReferences) : ''),
          },
          {
            keyIndex: 'createdAt',
            align: 'left',
            displayName: Translation('component.formLabel.created-time'),
            renderData: (row) => (row.updatedAt ? getDefaultDisplayDate(new Date(row.createdAt), 'datetime') : ''),
            sortable: true,
            onSort: (sort) => {
              onSort({ key: 'createdAt', value: sort['createdAt'] });
            },
          },
          {
            keyIndex: 'updatedAt',
            align: 'left',
            displayName: Translation('component.formLabel.last-updated-time'),
            renderData: (row) => (row.updatedAt ? getDefaultDisplayDate(new Date(row.updatedAt), 'datetime') : ''),
            sortable: true,
            onSort: (sort) => {
              onSort({ key: 'updatedAt', value: sort['updatedAt'] });
            },
          },
          {
            keyIndex: 'lastUpdatedBy',
            align: 'left',
            displayName: Translation('component.formLabel.last-updated-by'),
            renderData: (row) => String(row.lastUpdatedBy ? row.lastUpdatedBy : ''),
            sortable: true,
            onSort: (sort) => {
              onSort({ key: 'lastUpdatedBy', value: sort['lastUpdatedBy'] });
            },
          },
          {
            keyIndex: 'effectiveDate',
            align: 'left',
            displayName: Translation('component.formLabel.effective-date-range-start'),
            sortable: true,
            onSort: (sort) => onSort({ key: 'effectiveDate', value: sort['effectiveDate'] }),
            renderData: (row) =>
              row.effectiveDate ? getDefaultDisplayDate(new Date(row.effectiveDate), 'datetime') : '',
          },
          {
            keyIndex: 'expiredDate',
            align: 'left',
            displayName: Translation('component.formLabel.effective-date-range-end'),
            sortable: true,
            onSort: (sort) => onSort({ key: 'expiredDate', value: sort['expiredDate'] }),
            renderData: (row) =>
              String(row.expiredDate ? getDefaultDisplayDate(new Date(row.expiredDate), 'datetime') : ''),
          },
        ]}
        operationSticky={true}
        defaultRowsPerPage={20}
        isLoading={isLoading}
        onRefresh={onRefresh}
        dataSource={libraryList?.libraryList}
        totalPages={libraryList?.totalPages}
        totalRecords={libraryList?.totalRecords}
        onChangePage={onChangePage}
        currentSelectedRow={(data) => setSelectedRows(data)}
        disableBulkSelect={!!!enableCreate}
        bulkSelectCheckboxDisable={(rowData) => {
          return rowData.type === ResourceType.materialSet || !rowData.publishedAt;
        }}
      />
    </div>
  );
};

export default LibraryList;
