import React, { FC, useReducer, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { Button, LinearProgress, TextField } from '@mui/material';

import { awardListPath } from '../AwardListRoutes';

import { useProfileStyles } from 'src/app/modules/Profile/profileStyle';
type UploadFormState = {
  blobId?: string;
  filename?: string;
};
type ManualUpdateFormState = {
  award: UploadFormState;
};
const initialState: ManualUpdateFormState = {
  award: {
    blobId: undefined,
    filename: undefined,
  },
};

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof ManualUpdateFormState;
    value: any;
  };
};

type ManualUpdateFormAction = ModifyFieldAction;

const formReducer = (state: ManualUpdateFormState, action: ManualUpdateFormAction): ManualUpdateFormState => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    default:
      return state;
  }
};
//handle file
//onsibmit
export const AddNewAwardPage: FC<RouteComponentProps> = () => {
  const dispatch = useDispatch();
  const { classes } = useProfileStyles();
  const history = useHistory();
  const { classes: commonClasses } = useCommonStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [formState, formDispatch] = useReducer(formReducer, initialState);

  return (
    <>
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <div className={classes.rowContainer}>
            <div className={commonClasses.header}>{Translation(`title.profile.addNewAward`)}</div>
          </div>
          <Button variant="contained" color="inherit" onClick={() => history.push(`${awardListPath}/list`)}>
            {Translation('app.button.back')}
          </Button>
        </div>
        <div className={classes.rowContainer} style={{ marginTop: 8 }}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>
              {Translation('profile.agent.common.awardName')} <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <div>
            <div style={{ flexGrow: 1 }}>
              <TextField
                // disabled
                style={{ minWidth: 310 }}
                // error
                margin="dense"
                variant="outlined"
                //value
                //onchange
              />
            </div>
          </div>
        </div>
        <div className={classes.rowContainer} style={{ marginTop: 8 }}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>
              {Translation('agentProfile.ad_award_code_label')} <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <div>
            <div style={{ flexGrow: 1 }}>
              <TextField
                // disabled
                style={{ minWidth: 310 }}
                // error
                margin="dense"
                variant="outlined"
                //value
                //onchange
              />
            </div>
          </div>
        </div>
        <div className={classes.rowContainer} style={{ marginTop: 8 }}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>
              {Translation('profile.agent.common.uploadIcon')} <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <div>
            <div>
              <Button variant="contained" color="secondary">
                {Translation('app.button.chooseFile')}
              </Button>
            </div>
          </div>
        </div>

        <div className={classes.footerContainer}>
          <Button variant="contained" color="secondary" onClick={() => ''}>
            {Translation('app.button.submit')}
          </Button>
        </div>
      </div>
    </>
  );
};
