import React, { FC, useState, useEffect, useMemo } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ApiErrorResponse } from 'src/app/common/network';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { LayoutSplashScreen } from 'src/app/layout';
import { SLAFormMode, SLAConfigItem, SLAType } from '../../../types/sla-types';
import { fetchSLAConfigItem } from '../../../network/slaCrud';
import SLAForm from './components/SLAForm';
import { getSLAType } from '../SLARoutes';

const SLADetailPage: FC<RouteComponentProps> = ({ history, location }) => {
  const { id } = useParams<{ id: string }>();
  const { formMode } = useMemo(
    () => ({
      formMode: id ? SLAFormMode.EDIT : SLAFormMode.CREATE,
    }),
    [id],
  );

  const dispatch = useDispatch();

  // eslint-disable-next-line
  const slaType = useMemo(() => getSLAType(location.pathname) || SLAType.ACCEPT, []);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [slaConfig, setSlaConfig] = useState<SLAConfigItem>();

  const reloadData = () => {
    if (id) {
      setIsLoading(true);
      fetchSLAConfigItem(id, dispatch)
        .then((result) => {
          setSlaConfig(result);
        })
        .catch((err: ApiErrorResponse) => {
          history.push(NOT_FOUND_ERROR_PAGE_PATH);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line
  }, [id]);

  return (
    <>
      {formMode === SLAFormMode.CREATE && <SLAForm slaType={slaType} formMode={formMode} />}
      {formMode === SLAFormMode.EDIT &&
        (isLoading || !slaConfig ? (
          <LayoutSplashScreen />
        ) : (
          <SLAForm slaType={slaType} formMode={formMode} slaConfig={slaConfig} />
        ))}
    </>
  );
};

export default SLADetailPage;
