import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { makeStyles } from 'tss-react/mui';
import { Button, Dialog, FormControlLabel, Link } from '@mui/material';
import { Close, CheckCircle, RadioButtonUnchecked, RemoveCircleOutline } from '@mui/icons-material';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import AcmCheckbox from 'src/app/modules/AgencyCampaign/common/AcmCheckbox';
import { createBlob, getBlob } from 'src/app/common/network';
import { fileUpload } from 'src/app/common/utils';
import {
  RemarkItem,
  ResourceItem,
  LouTemplateItem,
  ContentEnum,
  SendTypesEnum,
} from 'src/app/modules/AgencyCampaign/types/approval-types';

type AttachmentDialogProps = {
  open: boolean;
  action: string;
  remarkList: RemarkItem[];
  remarkIndex: number | undefined;
  resources: ResourceItem[];
  louTemplateList: LouTemplateItem[];
  approverName: string;
  onConfirm: (newRemarkItem: RemarkItem) => void;
  onCancel: () => void;
  getLouTemplateContent: (templateId: string) => void;
};

const useStyles = makeStyles()((theme) => ({
  dialogPaper: {
    width: 450,
    height: 550,
    maxWidth: 450,
    padding: 16,
    background: 'white',
  },
  header: {
    fontSize: '1.4rem',
    fontWeight: 'bold',
    marginBottom: 20,
  },
  closeBtn: {
    float: 'right',
    cursor: 'pointer',
  },
  subTitle: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  rowContainer: {
    marginBottom: 10,
  },
  uploadSectionContainer: {
    marginLeft: 9,
  },
  templateSectionContainer: {
    marginLeft: 9,
    padding: 9,
    border: '1px solid rgba(0, 0, 0, 0.2)',
  },
  removeBtnContainer: {
    paddingRight: 10,
  },
  removeBtn: {
    borderRadius: '50%',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#e0e0e0',
    },
  },
  bottomSpacing: {
    marginBottom: 25,
  },
  actionBtn: {
    width: 100,
  },
  footerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    flexGrow: 1,
    marginBottom: 20,
  },
}));

const initialRemarkItem: RemarkItem = {
  type: ContentEnum.FIXED,
  resourceIds: [],
  templateId: '',
  sendTypes: [SendTypesEnum.APPLICANT],
  createdBy: '',
  updatedBy: '',
  createdAt: new Date(),
  updatedAt: new Date(),
};

const AttachmentDialog: FC<AttachmentDialogProps> = ({
  open,
  action,
  remarkList,
  remarkIndex,
  resources,
  louTemplateList,
  approverName,
  onConfirm,
  onCancel,
  getLouTemplateContent,
}) => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const [remarkItem, setRemarkItem] = useState<RemarkItem>(
    action === 'edit' && remarkIndex !== undefined
      ? { ...remarkList[remarkIndex] }
      : {
          ...initialRemarkItem,
          createdBy: approverName,
          updatedBy: approverName,
        },
  );
  const [filenameList, setFilenameList] = useState<Record<string, string>>({});

  const removeFile = (index: number) => {
    const newRemarkItem = { ...remarkItem, resourceIds: [...remarkItem.resourceIds] };
    newRemarkItem.resourceIds.splice(index, 1);
    newRemarkItem.updatedAt = new Date();
    newRemarkItem.updatedBy = approverName;
    setRemarkItem(newRemarkItem);
  };

  const addFile = (blobId: string) => {
    const newRemarkItem = { ...remarkItem, resourceIds: [...remarkItem.resourceIds] };
    newRemarkItem.resourceIds.push(blobId);
    newRemarkItem.updatedAt = new Date();
    newRemarkItem.updatedBy = approverName;
    setRemarkItem(newRemarkItem);
  };

  const handleFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const filename = file.name;
      const extensions = ['png', 'jpg', 'jpeg', 'xlsx', 'docx', 'pdf'];

      if (!filename || !extensions.some((ext) => filename.toLowerCase().includes(`.${ext}`))) {
        return dispatch(
          appendAlertItem([
            {
              severity: AlertType.ERROR,
              title: Translation('global.max.file.type.notSupported'),
              content: intl.formatMessage(
                {
                  id: 'global.max.file.type.allow',
                },
                {
                  num: extensions.join('/'),
                },
              ),
            },
          ]),
        );
      }

      const createBlobRes = await createBlob(
        { mimeType: file.type, accessLevel: 'public', originalFilename: file.name, module: 'agencyCampaign' },
        dispatch,
      );
      await fileUpload(createBlobRes.url, file);
      const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);
      const result = blobDetail[0];
      if (result) {
        addFile(result.blobId);
        setFilenameList({
          ...filenameList,
          [result.blobId]: file.name,
        });
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: 'Success',
              content: `Upload file successfully - ${file.name}`,
            },
          ]),
        );
      }
    }
  };

  const handleSelectSendType = async (value: SendTypesEnum) => {
    const newRemarkItem = { ...remarkItem, sendTypes: [...remarkItem.sendTypes] };
    const newSendTypes = newRemarkItem.sendTypes;
    if (newSendTypes.includes(value)) {
      newSendTypes.splice(newSendTypes.indexOf(value), 1);
    } else {
      newSendTypes.push(value);
    }
    setRemarkItem(newRemarkItem);
  };

  return (
    <Dialog classes={{ paper: classes.dialogPaper }} open={open}>
      <div className={classes.header}>
        <span>{Translation(`agencyCampaign.approval.remark.${action}`)}</span>
        <span className={classes.closeBtn} onClick={() => onCancel()}>
          <Close />
        </span>
      </div>

      <div className={classes.subTitle}>{Translation('agencyCampaign.approval.remark.contentType')}</div>
      <div className={classes.rowContainer}>
        <FormControlLabel
          style={{ margin: '2px 12px 2px 0' }}
          control={
            <AcmCheckbox
              icon={<RadioButtonUnchecked />}
              checkedIcon={<CheckCircle />}
              checked={remarkItem.type === ContentEnum.FIXED}
              onChange={(e) => {
                if (e.target.checked) {
                  const newRemarkItem = { ...remarkItem };
                  newRemarkItem.type = ContentEnum.FIXED;
                  setRemarkItem(newRemarkItem);
                }
              }}
            />
          }
          label={Translation('agencyCampaign.approval.remark.fixContent')}
          labelPlacement="end"
        />
        <FormControlLabel
          style={{ margin: '2px 0' }}
          control={
            <AcmCheckbox
              icon={<RadioButtonUnchecked />}
              checkedIcon={<CheckCircle />}
              checked={remarkItem.type === ContentEnum.VARIABLE}
              onChange={(e) => {
                if (e.target.checked) {
                  const newRemarkItem = { ...remarkItem };
                  newRemarkItem.type = ContentEnum.VARIABLE;
                  newRemarkItem.sendTypes = [SendTypesEnum.APPLICANT];
                  setRemarkItem(newRemarkItem);
                }
              }}
            />
          }
          label={Translation('agencyCampaign.approval.remark.variableContent')}
          labelPlacement="end"
        />
      </div>

      {remarkItem.type === ContentEnum.FIXED && (
        <div className={`${classes.uploadSectionContainer} ${classes.bottomSpacing}`}>
          <div style={{ fontWeight: 'bold', marginBottom: 10 }}>
            {Translation('agencyCampaign.approval.remark.uploadFiles')}
          </div>
          <div className={classes.rowContainer}>
            {remarkItem.resourceIds.map((resourceId, index) => (
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 5 }}>
                <div className={classes.removeBtnContainer}>
                  <RemoveCircleOutline className={classes.removeBtn} onClick={() => removeFile(index)} />
                </div>
                <div>
                  {resources.find((item) => item.blobId === resourceId)?.originalFilename || filenameList[resourceId]}
                </div>
              </div>
            ))}
          </div>
          <div>
            <input
              id="upload-file"
              hidden
              type="file"
              onClick={(e) => {
                const element = e.target as HTMLInputElement;
                element.value = '';
              }}
              onChange={(e) => handleFile(e)}
            />
            <Button
              variant="contained"
              color="secondary"
              onClick={() => document.getElementById('upload-file')!.click()}
            >
              {Translation('app.button.upload')}
            </Button>
          </div>
        </div>
      )}

      {remarkItem.type === ContentEnum.VARIABLE && (
        <div className={`${classes.templateSectionContainer} ${classes.bottomSpacing}`}>
          <div style={{ fontWeight: 'bold', marginBottom: 10 }}>
            {Translation('agencyCampaign.approval.remark.selectTemplate')}
          </div>
          {louTemplateList.map((louTemplate) => (
            <div>
              <AcmCheckbox
                icon={<RadioButtonUnchecked />}
                checkedIcon={<CheckCircle />}
                checked={remarkItem.templateId === louTemplate.templateId}
                onChange={(e) => {
                  if (e.target.checked) {
                    const newRemarkItem = { ...remarkItem };
                    newRemarkItem.templateId = louTemplate.templateId;
                    setRemarkItem(newRemarkItem);
                  }
                }}
              />
              <Link
                color="secondary"
                target="_blank"
                onClick={(event: React.MouseEvent<any>) => {
                  event.preventDefault();
                  getLouTemplateContent(louTemplate.templateId);
                }}
              >
                {Translation(`agencyCampaign.approval.remark.louTemplate.${louTemplate.templateId}`)}
              </Link>
            </div>
          ))}
        </div>
      )}

      <div className={classes.bottomSpacing}>
        <span className={classes.subTitle}>{Translation('agencyCampaign.approval.remark.receiver')}</span>
        {Object.values(SendTypesEnum).map((value) => (
          <div>
            <FormControlLabel
              style={{ margin: '2px 0' }}
              disabled={remarkItem.type === ContentEnum.VARIABLE}
              control={
                <AcmCheckbox
                  checked={remarkItem.sendTypes.includes(value)}
                  onClick={() => handleSelectSendType(value)}
                />
              }
              label={Translation(`agencyCampaign.approval.remark.${value}`)}
              labelPlacement="end"
            />
          </div>
        ))}
      </div>

      <div className={classes.footerContainer}>
        <div>
          <Button
            className={classes.actionBtn}
            style={{ marginBottom: 15 }}
            variant="contained"
            disabled={
              (remarkItem.type === ContentEnum.FIXED && !remarkItem.resourceIds?.length) ||
              (remarkItem.type === ContentEnum.VARIABLE && !remarkItem.templateId)
            }
            color="secondary"
            onClick={() => onConfirm(remarkItem)}
          >
            {Translation('global.text.confirm')}
          </Button>
        </div>
        <div>
          <Button
            className={classes.actionBtn}
            variant="contained"
            style={{ backgroundColor: 'white' }}
            onClick={() => onCancel()}
          >
            {Translation('app.button.cancel')}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default AttachmentDialog;
