import React, { HTMLAttributes, CSSProperties } from 'react';
import { makeStyles, withStyles } from 'tss-react/mui';
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  Tooltip,
  IconButton,
  TableBody,
  TextField,
  FormControl,
  Select,
  TableCell,
  FormHelperText,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import InfoIcon from '@mui/icons-material/Info';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ErrorState, DismissErrorHandler } from '../utils';
import { MANDATORY_FIELD_ERROR_TEXT } from '../constants';

export enum FormTableItemType {
  FREE_TEXT = 'FREE_TEXT',
  DROPDOWN = 'DROPDOWN',
}

type FormTableTextDef = {
  type: FormTableItemType.FREE_TEXT;
  regExp?: RegExp;
  style?: CSSProperties;
};

type FormTableDropdownDef = {
  type: FormTableItemType.DROPDOWN;
  style?: CSSProperties;
  options: string[];
};

type FormTableItemDef = FormTableTextDef | FormTableDropdownDef;

type FormTableColumnDef<T> = {
  keyIndex: keyof T;
  displayName: string | JSX.Element;
  tooltipTitle?: string;
  item: FormTableItemDef;
};

type FormTableProps<T> = {
  disabled?: boolean;
  dataSource: T[];
  columnDef: FormTableColumnDef<T>[];
  disableOrder?: boolean;
  onAddRow: () => void;
  onDeleteRow: (index: number) => void;
  onModifyField: (index: number, field: keyof T, value: string) => void;
  onMoveRow?: (from: number, to: number) => void;
  errorState?: ErrorState;
  onDismissErrorHandler?: DismissErrorHandler;
};

const useStyles = makeStyles()((theme) => ({
  inputBox: {
    paddingTop: 6,
    paddingBottom: 8,
  },
  headerOperationField: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const CustomTableCell = withStyles(TableCell, (theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: '1rem',
    paddingTop: 10,
    paddingBottom: 10,
  },
  body: {
    fontWeight: 'bold',
    paddingTop: 10,
    paddingBottom: 10,
  },
}));

const FormTable = <T extends Record<string, unknown>>({
  columnDef,
  dataSource,
  disabled,
  disableOrder,
  onAddRow,
  onModifyField,
  onDeleteRow,
  onMoveRow,
  errorState,
  onDismissErrorHandler,
  ...rest
}: FormTableProps<T> & HTMLAttributes<HTMLDivElement>) => {
  const { classes } = useStyles();

  return (
    <>
      {columnDef.length > 0 && (
        <TableContainer {...rest} component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {columnDef.map((def) => (
                  <CustomTableCell key={`form-table-header-column-${def.keyIndex as string}`}>
                    {def.displayName}
                    {def.tooltipTitle && (
                      <Tooltip title={def.tooltipTitle}>
                        <IconButton style={{ padding: 5, color: 'white', fontSize: '0.5rem' }} disabled={disabled}>
                          <InfoIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </CustomTableCell>
                ))}
                <CustomTableCell align="right">
                  <IconButton disabled={disabled} style={{ padding: 5, color: 'white' }} onClick={onAddRow}>
                    <AddIcon />
                  </IconButton>
                </CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataSource.map((data, index) => (
                <TableRow key={`form-table-row-${index}`}>
                  {columnDef.map((def) => {
                    switch (def.item.type) {
                      case FormTableItemType.FREE_TEXT:
                        const textItem = def.item as FormTableTextDef;
                        const textKeyName = `${def.keyIndex as string}-${index}`;
                        return (
                          <CustomTableCell key={`form-table-row-column-${textKeyName}`}>
                            <TextField
                              error={errorState?.mandatory[textKeyName]}
                              helperText={errorState?.mandatory[textKeyName] && MANDATORY_FIELD_ERROR_TEXT}
                              disabled={disabled}
                              fullWidth
                              style={{
                                ...textItem.style,
                                margin: 0,
                              }}
                              InputProps={{
                                classes: {
                                  input: classes.inputBox,
                                },
                              }}
                              margin="dense"
                              variant="outlined"
                              value={data[def.keyIndex]}
                              onChange={(e) => {
                                let text = e.target.value;
                                if (textItem.regExp) {
                                  text = e.target.value.replace(textItem.regExp, '');
                                }
                                onDismissErrorHandler && onDismissErrorHandler(textKeyName, text);
                                onModifyField(index, def.keyIndex, text);
                              }}
                            />
                          </CustomTableCell>
                        );
                      case FormTableItemType.DROPDOWN:
                        const dropdownItem = def.item as FormTableDropdownDef;
                        const dropdownKeyName = `${def.keyIndex as string}-${index}`;
                        return (
                          <CustomTableCell key={`form-table-row-column-${dropdownKeyName}`}>
                            <FormControl
                              error={errorState?.mandatory[dropdownKeyName]}
                              fullWidth
                              style={{
                                ...dropdownItem.style,
                                margin: 0,
                              }}
                              margin="dense"
                              variant="outlined"
                            >
                              <Select
                                disabled={disabled}
                                native
                                value={data[def.keyIndex]}
                                inputProps={{
                                  classes: {
                                    root: classes.inputBox,
                                  },
                                }}
                                onChange={(e) => {
                                  onDismissErrorHandler && onDismissErrorHandler(textKeyName, e.target.value);
                                  onModifyField(index, def.keyIndex, (e.target.value as string) || '');
                                }}
                              >
                                <option aria-label="None" value="" />
                                {dropdownItem.options.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </Select>
                            </FormControl>
                            {errorState?.mandatory[dropdownKeyName] && (
                              <FormHelperText style={{ color: '#f018a6' }}>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>
                            )}
                          </CustomTableCell>
                        );
                      default:
                        return <></>;
                    }
                  })}
                  <CustomTableCell align="right">
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                      {disableOrder && onMoveRow && (
                        <>
                          <IconButton
                            style={{ padding: 5 }}
                            onClick={() => dataSource[index + 1] && onMoveRow(index, index + 1)}
                            disabled={disabled || !!!dataSource[index + 1]}
                          >
                            <ArrowDropDownIcon />
                          </IconButton>
                          <IconButton
                            style={{ padding: 5 }}
                            onClick={() => dataSource[index - 1] && onMoveRow(index, index - 1)}
                            disabled={disabled || !!!dataSource[index - 1]}
                          >
                            <ArrowDropUpIcon />
                          </IconButton>
                        </>
                      )}
                      <IconButton style={{ padding: 5 }} onClick={() => onDeleteRow(index)} disabled={disabled}>
                        <HighlightOffIcon />
                      </IconButton>
                    </div>
                  </CustomTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default FormTable;
