import { makeStyles } from 'tss-react/mui';

const ACCORDION_WIDTH = 725;

export const useStyles = makeStyles()(() => ({
  accordion: {
    width: ACCORDION_WIDTH,
    '&.MuiAccordion-root:before': {
      display: 'none',
    },
  },
  error: {
    border: '1px solid #F018A6',
  },
  accordionSummary: {
    fontSize: 18,
    fontWeight: 600,
  },
  disablePointer: {
    '&.MuiAccordionSummary-root': {
      cursor: 'default',
      '&:hover': {
        cursor: 'default',
      },
    },
  },
  accordionDetail: {
    padding: '0 20px 20px',
  },
  fillInContainer: {
    marginBottom: 20,
  },
}));
