import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import PruRoute from 'src/app/common/components/PruRoute';
import { PULSELEADS_BASE_PATH, PULSELEADS_RULE_PATH, PULSELEADS_ELIGIBILITY_RULE_PATH } from '../../../constants';
import ParamsProvider from 'src/app/common/components/ParamsProvider';
import EligibilityRuleListingPage, { eligibilityRuleFilterKeys } from './List/EligibilityRuleListingPage';
import EligibilityRuleDetailPage from './Form/EligibilityRuleDetailPage';

export const eligibilityRulePath = `${PULSELEADS_BASE_PATH}${PULSELEADS_RULE_PATH}${PULSELEADS_ELIGIBILITY_RULE_PATH}`;

const EligibilityRuleRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect exact from={eligibilityRulePath} to={`${eligibilityRulePath}/list`} />
      <PruRoute exact path={`${eligibilityRulePath}/list`} render={(props) => (
        <ParamsProvider {...props} acceptKeys={eligibilityRuleFilterKeys}>
          <EligibilityRuleListingPage />
        </ParamsProvider>
      )}/>
      <PruRoute exact path={`${eligibilityRulePath}/edit/:id`} component={EligibilityRuleDetailPage}/>
      <PruRoute exact path={`${eligibilityRulePath}/create`} component={EligibilityRuleDetailPage}/>
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  )
};

export default EligibilityRuleRoutes;