import { Dispatch } from 'react';
import { getDayStart, getDayEnd } from 'src/app/common/utils';
import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import { forEach } from 'lodash';
import { PaginateList } from 'src/app/common/types/common-types';
import { CampaignItem, LeadSource } from '../types/insights-types';

const campaignEndpoint = 'campaigns';
const sourceEndpoint = 'sources';
const agentEndpoint = 'agent';

export type CampaignListParam = {
  name: string;
  startDateFrom: Date | null;
  startDateTo: Date | null;
  endDateFrom: Date | null;
  endDateTo: Date | null;
  sort: string;
  sourceId: string;
  status: string;
  page: number;
  limit: number;
};

export const fetchCampaignList = async (
  params: CampaignListParam,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<CampaignItem>> => {
  let queryPath = `${ADMIN_URL}/${campaignEndpoint}/list?`;
  forEach(params, (param, key) => {
    if (typeof param === 'object') {
      if (param) {
        if (key.toLowerCase().includes('from')) {
          queryPath += `${key}=${encodeURIComponent(getDayStart(param).toISOString())}&`;
        }
        if (key.toLowerCase().includes('to')) {
          queryPath += `${key}=${encodeURIComponent(getDayEnd(param).toISOString())}&`;
        }
      }
    } else {
      if (key === 'page') {
        queryPath += `${key}=${encodeURIComponent(param as any)}&`;
      } else {
        queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
      }
    }
  });
  return apiClient
    .get<PaginateList<CampaignItem>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
export const fetchCampaignItem = async (id: string, dispatch?: Dispatch<any>): Promise<CampaignItem> => {
  return apiClient
    .get<CampaignItem>(`${ADMIN_URL}/${campaignEndpoint}/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchLeadSource = async (id: string, dispatch?: Dispatch<any>): Promise<LeadSource> => {
  return apiClient
    .get<LeadSource>(`${ADMIN_URL}/${sourceEndpoint}/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchAgentLeadSource = async (id: string, dispatch?: Dispatch<any>): Promise<LeadSource> => {
  return apiClient
    .get<LeadSource>(`${ADMIN_URL}/${agentEndpoint}/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
