import { makeStyles } from 'tss-react/mui';
import { Button, Drawer, Icon, Theme, Tooltip, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toAbsoluteUrl } from 'src/app/common/utils';

export default function QuickUserToggler() {
  const { user } = useSelector((state: any) => state.auth);
  const { classes } = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState(false);

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  const onClick = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  const handleSignOut = useCallback(() => {
    history.push('/logout');
  }, [history]);

  return (
    <div>
      <Tooltip title="view user">
        <div className={classes.wrapper} onClick={onClick}>
          <span style={{ color: '#b5b5c3', fontWeight: 500 }}>Hi, </span>
          <span className={classes.name}>{user.username}</span>
          <span className={classes.box}>
            <span className={classes.text}>{user.username[0] || ''}</span>
          </span>
        </div>
      </Tooltip>
      <Drawer anchor="right" open={open} onClose={onClose}>
        <div className={classes.drawer}>
          <div className={classes.drawerHeader}>
            <h3>User Profile</h3>
            <Icon className={`fa fa-window-close ${classes.closeIcon}`} onClick={onClose} />
          </div>
          <div className={classes.user}>
            <div className={classes.userImg}>
              <img src={toAbsoluteUrl('/media/users/profile_place_holder@3x.png')} alt="user" />
              <i className={classes.online} />
            </div>
            <div className={classes.infoWrapper}>
              <span className={classes.username}>{user.username}</span>
              <span className={classes.info}>{user.sub}</span>
              <span className={classes.info}>{user.role}</span>
              <span className={classes.info}>
                {user && user.region && user.channel ? `${user.region} - ${user.channel}` : ''}
              </span>
              <Button variant="contained" disableElevation className={classes.button} onClick={handleSignOut}>
                Sign out
              </Button>
            </div>
          </div>
          <div className={classes.separator}></div>
        </div>
      </Drawer>
    </div>
  );
}

const useStyles = makeStyles()((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    whiteSpace: 'pre-wrap',
  },
  name: {
    fontSize: 13,
    fontWeight: 600,
    color: '#7e8299',
  },
  box: {
    width: 35,
    height: 35,
    borderRadius: 5,
    backgroundColor: '#C9F7F5',
    color: '#1BC5BD',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 12,
  },
  text: {
    fontSize: 15,
  },
  drawer: {
    minWidth: 300,
    padding: 30,
  },
  drawerHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 500,
  },
  closeIcon: {
    fontSize: 24,
    color: '#E4E6EF',
    '&:hover': {
      color: '#3699FF',
    },
  },
  user: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.spacing(3),
  },
  userImg: {
    width: 100,
    height: 100,
    position: 'relative',
  },
  online: {
    width: 12,
    height: 12,
    backgroundColor: '#1bc5bd',
    border: '2px solid #fff',
    borderRadius: '100%',
    position: 'absolute',
    top: '-6px',
    right: '-6px',
  },
  infoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(2),
    color: '#B5B5C3',
  },
  username: {
    color: '#3F4254',
    fontWeight: 500,
    fontSize: '1.25rem',
  },
  info: {
    marginTop: theme.spacing(1),
  },
  button: {
    color: '#3699FF',
    backgroundColor: '#E1F0FF',
    marginTop: 5,
    borderRadius: '0.42rem',
    textTransform: 'none',
    '&:hover': {
      color: '#FFFFFF',
      backgroundColor: '#3699FF',
    },
  },
  separator: {
    borderBottom: '1px dashed #EBEDF3',
    marginTop: 26,
    marginBottom: 16,
  },
}));
