import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  alertContainer: {
    position: 'fixed',
    right: 0,
    top: '10vh',
    zIndex: 1000000000,
  },
}));
