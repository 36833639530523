import React, { useEffect, useRef } from 'react';
import Form from '../../../../../../../common/components/Form';
import { makeStyles, withStyles } from 'tss-react/mui';
import { TextField, FormControlLabel, RadioGroup, Radio, Tooltip } from '@mui/material';
import { useIntl } from 'react-intl';
import FileUploader from '../../../../../../../common/components/FileUploader';
import AddTags from './AddTag';
import { ResourceType, YesAndNo } from '../../../constants';
import Tinymce from './Tinymce';
import { createBlob, getBlob } from 'src/app/common/network';
import { fileUpload } from 'src/app/common/utils';
import { useDispatch } from 'react-redux';
import InfoIcon from '@mui/icons-material/Info';

const StyledRadioGroup = withStyles(RadioGroup, {
  root: {
    flexDirection: 'row',
    display: 'flex',
    '& .MuiFormControlLabel-root': {
      marginBottom: 0,
      marginLeft: 0,
      marginRight: '15px',
    },
    '& .MuiButtonBase-root': {
      padding: 0,
    },
  },
});

const StyledTooltip = withStyles(Tooltip, {
  tooltip: {
    maxWidth: '450px',
  },
});
// import CheckGroup from './CheckGroup';

const TranslationWithVariable = (key: string, enCount: number | string, cnCount: number | string) =>
  useIntl().formatMessage({ id: key }, { enCount, cnCount });

const useStyles = makeStyles()((theme) => ({
  textArea: {
    lineHeight: 1.5,
    minHeight: 40,
  },
  container: {
    '& .pru-form-item': {
      margin: '10px 0',
    },
    '& .MuiFormControl-root': {
      margin: 0,
    },
  },
  lineheight: {
    lineHeight: '22px',
    fontSize: '14px',
  },
  tooltip: {
    marginLeft: '5px',
    color: '#CCC',
  },
}));

interface FormListProp {
  disabled: boolean;
  activeLang: string;
  form: any;
  resourceType: string;
  moduleType: string;
  nameMaxSize?: any;
  descriptMaxSize?: any;
  getFieldValue?: (name: any) => any;
}

const SubFormList: React.FC<FormListProp> = (props) => {
  const { classes } = useStyles();
  const { activeLang, disabled, resourceType, moduleType, nameMaxSize, descriptMaxSize, getFieldValue } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const Translation = (id: string) => intl.formatMessage({ id });

  // validate rules
  const DEFAULT_REQUIRED_RULES = {
    required: true,
    message: Translation('component.form-required-text'),
  };

  const DEFAULT_NOT_REQUIRED_RULES = [{ required: false }];

  const LINK_VIDATOR = [
    DEFAULT_REQUIRED_RULES,
    {
      validator(_: any, value: string) {
        if (value && (value.startsWith('https://') || value.startsWith('http://'))) {
          return Promise.resolve();
        }
        return Promise.reject(new Error(Translation('component.form-link-text')));
      },
    },
  ];

  const CONTENT_VALIDATE = {
    validator(_: any, value: string) {
      const canBeShare = getFieldValue && getFieldValue([activeLang, 'canShared']);

      if (canBeShare === 'Y' && value && value.includes('<video preload="metadata"')) {
        return Promise.reject(new Error(Translation('component.conent.can-not-be-shared')));
      }

      return Promise.resolve();
    },
  };

  const TEXT_VALIDATE = {
    validator(_: any, value: string) {
      // const regex = /<\/?!?(source|video|img|(-- pagebreak --))[^>]*>/gi;
      const regex = new RegExp(/<\/?!?(source|video|img|(-- pagebreak --))[^>]*>/, 'gi');
      if (value && regex.test(value)) {
        return Promise.reject(new Error(Translation('component.text.file-can-not-be-shared-as-text')));
      }

      return Promise.resolve();
    },
  };
  const getInputMaxRules = (count: number) => {
    const errMessage = TranslationWithVariable('component.form-max-characters-input-text', count, count / 2);

    return {
      validator: async (_: any, inputString: string) => {
        // const re = /[\u4E00-\u9FA5]/g;
        const re = new RegExp(/[\u4E00-\u9FA5]/, 'g');
        let stringLength = inputString ? inputString.length : 0;
        const chineseCount = inputString ? (inputString.match(re) || '').length : 0;
        stringLength = stringLength + chineseCount;

        if (stringLength <= count) {
          return Promise.resolve();
        } else {
          throw new Error(errMessage);
        }
      },
    };
  };

  const handleUploadFile = async ({
    file,
    fileName,
    fileType,
    description = '',
  }: {
    file: any;
    fileName: string;
    fileType: string;
    description?: string;
  }) => {
    try {
      const createBlobRes = await createBlob(
        {
          mimeType: fileType,
          accessLevel: 'anonymous',
          module: `material_${moduleType.toLowerCase()}`,
        },
        dispatch,
      );
      await fileUpload(createBlobRes.url, file, () => {});
      const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);
      const result = blobDetail[0];
      if (result) {
        return {
          id: result.blobId,
          fileName: fileName,
          url: result.url,
          type: fileType,
        };
      }
    } catch (err) {
    } finally {
    }
  };
  const fileRef = useRef<any>();
  const fileTypes = ['pdf', 'jpg', 'png', 'jpeg', 'mp4'];
  const imageTypes = ['jpg', 'jpeg', 'png'];
  const fileSize = 20;
  const placeEnter = Translation('app.input.placeholder.please-enter');
  const fileLabel = Translation('component.formSelectItem.file');
  const showAllowText = useIntl().formatMessage(
    { id: 'component.formSelectItem.fielType' },
    {
      formats: (resourceType === ResourceType.file ? fileTypes : imageTypes).join('/').toUpperCase(),
      size: `${fileSize}M`,
    },
  );
  const linkLabel = Translation('component.formSelectItem.link');
  const contentLabel = Translation('component.formSelectItem.content');
  const textLabel = Translation('component.formSelectItem.content');
  const introductionForShare = 'Default Message' || Translation('component.formLabel.introduction-for-share');
  const messageRule = getInputMaxRules(500);

  useEffect(() => {
    fileRef?.current?.clear();
  }, [resourceType]);

  return (
    <div className={classes.container}>
      <Form.Item
        name={[activeLang, 'name']}
        label={Translation('component.formLabel.name')}
        rules={[DEFAULT_REQUIRED_RULES, getInputMaxRules(nameMaxSize)]}
      >
        <TextField disabled={disabled} margin="dense" variant="outlined" fullWidth placeholder={placeEnter} />
      </Form.Item>
      <Form.Item
        name={[activeLang, 'coverpage']}
        label={Translation('component.formLabel.coverpage')}
        rules={[DEFAULT_REQUIRED_RULES]}
      >
        <FileUploader
          disabled={disabled}
          upload={handleUploadFile}
          color="inherit"
          showAllowText={Translation('component.resource.create.coverpage')}
          maxFileSize={0.57} // 600kb
          showFileSize={true}
          allowedFileTypes={['jpg', 'png', 'jpeg']}
        />
      </Form.Item>
      <Form.Item
        name={[activeLang, 'introduction']}
        label={Translation('component.formLabel.coverpage-introduction')}
        rules={[DEFAULT_REQUIRED_RULES, getInputMaxRules(descriptMaxSize)]}
      >
        <TextField
          disabled={disabled}
          margin="dense"
          variant="outlined"
          fullWidth
          multiline
          placeholder={placeEnter}
          InputProps={{
            classes: {
              input: classes.textArea,
            },
          }}
        />
      </Form.Item>
      <Form.Item
        required={false}
        name={[activeLang, 'tag']}
        label={Translation('component.formLabel.tags')}
        rules={DEFAULT_NOT_REQUIRED_RULES}
      >
        <AddTags
          disabled={disabled}
          btnTxt={Translation('component.tag-list.add-btn')}
          // sameTag={Translation('components.addtag.sametag')}
        />
      </Form.Item>
      {(resourceType === ResourceType.file || resourceType === ResourceType.picture) && (
        <Form.Item name={[activeLang, 'file']} label={fileLabel} rules={[DEFAULT_REQUIRED_RULES]}>
          <FileUploader
            ref={fileRef}
            color="secondary"
            disabled={disabled}
            showAllowText={showAllowText}
            upload={handleUploadFile}
            allowedFileTypes={resourceType === ResourceType.file ? fileTypes : imageTypes}
            maxFileSize={fileSize}
          />
        </Form.Item>
      )}
      {resourceType === ResourceType.link && (
        <Form.Item name={[activeLang, 'link']} label={linkLabel} rules={LINK_VIDATOR}>
          <TextField disabled={disabled} margin="dense" variant="outlined" fullWidth placeholder={placeEnter} />
        </Form.Item>
      )}
      {resourceType === ResourceType.content && (
        <Form.Item
          name={[activeLang, 'content']}
          label={contentLabel}
          rules={[DEFAULT_REQUIRED_RULES, CONTENT_VALIDATE]}
        >
          <Tinymce disabled={disabled} moduleType={moduleType} lang={activeLang} />
        </Form.Item>
      )}
      {resourceType === ResourceType.text && (
        <Form.Item name={[activeLang, 'content']} label={textLabel} rules={[DEFAULT_REQUIRED_RULES, TEXT_VALIDATE]}>
          <Tinymce disabled={disabled} moduleType={moduleType} lang={activeLang} />
        </Form.Item>
      )}

      {
        <Form.Item
          name={[activeLang, 'canShared']}
          label={Translation('component.formLabel.can-be-shared')}
          rules={[DEFAULT_REQUIRED_RULES]}
          shouldUpdate
          initialValue={YesAndNo.no}
        >
          <StyledRadioGroup aria-label="contentType" name="contentType">
            {Object.entries(YesAndNo).map(([label, value]) => (
              <FormControlLabel
                key={value}
                value={value}
                control={<Radio disabled={disabled} />}
                label={Translation(`app.select.${label}`)}
              />
            ))}
          </StyledRadioGroup>
        </Form.Item>
      }
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues[activeLang].canShared !== currentValues[activeLang].canShared
        }
      >
        {(_, meta, { getFieldValue }) => {
          const canShared = getFieldValue([activeLang, 'canShared']) === YesAndNo.yes;
          const isSalesAndPromotion = moduleType === 'Sales' || moduleType === 'Promotion';
          const showDefaultMessage =
            [ResourceType.link, ResourceType.file, ResourceType.content, ResourceType.picture].includes(
              resourceType as ResourceType,
            ) && canShared;
          const canShow =
            [ResourceType.link, ResourceType.file, ResourceType.content].includes(resourceType as ResourceType) &&
            isSalesAndPromotion;

          return (
            <>
              {canShared && canShow && (
                <Form.Item
                  name={[activeLang, 'allowedForEProfile']}
                  label={
                    <span>
                      {Translation('component.formLabel.can-be-shared-by-e-profile')}
                      <StyledTooltip
                        className={classes.tooltip}
                        placement="right"
                        title={
                          <div className={classes.lineheight}>
                            <p>{Translation('component.formLabel.can-be-shared-by-e-profile-tip')}</p>
                            <p>{Translation('component.formLabel.can-be-shared-by-e-profile-tip1')}</p>
                            <p>{Translation('component.formLabel.can-be-shared-by-e-profile-tip2')}</p>
                            <p>{Translation('component.formLabel.can-be-shared-by-e-profile-tip3')}</p>
                          </div>
                        }
                        arrow
                      >
                        <InfoIcon fontSize="small" />
                      </StyledTooltip>
                    </span>
                  }
                  rules={[DEFAULT_REQUIRED_RULES]}
                  shouldUpdate
                >
                  <StyledRadioGroup aria-label="contentType" name="contentType">
                    {Object.entries(YesAndNo).map(([label, value]) => (
                      <FormControlLabel
                        key={value}
                        value={value}
                        control={<Radio disabled={disabled} />}
                        label={Translation(`app.select.${label}`)}
                      />
                    ))}
                  </StyledRadioGroup>
                </Form.Item>
              )}
              {showDefaultMessage && (
                <Form.Item
                  name={[activeLang, 'sharedIntroduction']}
                  label={introductionForShare}
                  rules={[...DEFAULT_NOT_REQUIRED_RULES, messageRule]}
                  required={false}
                >
                  <TextField
                    disabled={disabled}
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    multiline
                    InputProps={{
                      classes: {
                        input: classes.textArea,
                      },
                    }}
                    placeholder={placeEnter}
                  />
                </Form.Item>
              )}
            </>
          );
        }}
      </Form.Item>
    </div>
  );
};

export default SubFormList;
