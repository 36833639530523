import { Dispatch } from 'react';
import { I18nGenericData } from 'src/app/i18n';
import { createBlob, getBlob } from 'src/app/common/network';
import { fileUpload } from 'src/app/common/utils';
import { getLocale } from './survey-creator';

export const localeMapping = (data: I18nGenericData<string>) => {
  return Object.keys(data).reduce((result, key) => {
    return {
      ...result,
      [getLocale(key)]: data[key],
    };
  }, {});
};

export const handleFile = async (file: any, module: string, dispatch: Dispatch<any>) => {
  const createBlobRes = await createBlob({ mimeType: file.type, accessLevel: 'anonymous', module }, dispatch);
  await fileUpload(createBlobRes.url, file);
  const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);
  return blobDetail[0];
};
