import React, { FC, useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import PruFilter, {
  PruFilterDateRangeDef,
  PruFilterDropdownDef,
  PruFilterItemType,
} from '../../../../../common/components/PruTable/PruFilter';
import { useIntl } from 'react-intl';
import { map } from 'lodash';
import DashboardDetailPage from './components/DashboardDetailPage';
import { ParamsProps } from '../../../../../common/components/ParamsProvider';
import { ChartListParam, fetchChartList } from '../../../network/dashboardCrud';
import {
  DashboardItem,
  BDMdashboardName,
  DashboardType,
  BDMdashboardFilter,
  BDMdashboardGroupName,
} from '../../../types/dashboard-types';
import { useDataProvider, useJWT } from 'src/app/common/utils';
const ExportJsonExcel = require('js-export-excel');
type DashboardListingPageProps = {} & ParamsProps;
type PruFilterItemDef = PruFilterDateRangeDef | PruFilterDropdownDef;
const initialState: ChartListParam = {
  chartName: 'Conversion Dashboard',
  chartType: 'Table',
  campaignId: '',
  consultantCode: '',
  campaignStatus: '',
  campaignType: '',
  campaignRecordType: '',
  leadSubSource: '',
  startDate: [],
  campaignStartDt: [],
  assignDate: [],
  assignedDate: [],
  type: '',
  leadSource: '',
  unitCode: '',
  businessManagerName: '',
  consultantName: '',
  region: '',
  channel: '',
};

const paramsInitiator = (initialParams?: Record<string, string>): ChartListParam => {
  return initialParams
    ? {
        chartName: 'Conversion Dashboard',
        //chartID: "62625264-8055-4590-8c78-8bf995f6bcd1",
        chartType: 'Table',
        campaignId: '',
        consultantCode: '',
        campaignStatus: '',
        campaignType: '',
        campaignRecordType: '',
        leadSubSource: '',
        startDate: [],
        campaignStartDt: [],
        assignDate: [],
        assignedDate: [],
        type: '',
        leadSource: '',
        unitCode: '',
        businessManagerName: '',
        consultantName: '',
        region: '',
        channel: '',
      }
    : initialState;
};

export const dashboardFilterKeys = ['bundleIdentifier', 'name', 'role', 'isPublic'];

const DashboardListingPage: FC<DashboardListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const [filterList, setFilterList] = useState<PruFilterItemDef[]>([]);
  const [myDataList, setMyDataList] = useState<Record<string, string[]>>({
    leadSource: [],
    unitCode: [],
    businessManagerName: [],
    consultantName: [],
    channel: [],
    region: [],
  });
  const jwt = useJWT() || {};
  const { region, channel } = jwt;
  const [filterState, setFilterState] = useState<ChartListParam>(
    paramsInitiator({ ...initialParams, region, channel }),
  );

  const [shouldRender, setShouldRender] = useState<any>(false);

  const resetFilters = () => {
    setFilterState({
      ...filterState,
      chartType: 'Table',
      campaignId: 'all',
      consultantCode: 'all',
      campaignStatus: 'all',
      campaignType: 'all',
      campaignRecordType: 'all',
      leadSubSource: 'all',
      type: 'all',
      leadSource: 'all',
      startDate: [],
      campaignStartDt: [],
      assignDate: [],
      assignedDate: [],
      unitCode: 'all',
      businessManagerName: 'all',
      consultantName: 'all',
    });
  };

  const [, setDashboardList] = useState<DashboardItem[]>();
  const { isLoading, refreshData } = useDataProvider<DashboardItem[]>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchChartList(filterState, dispatch);
      } catch (err) {}
    },
    setDashboardList,
    false,
  );

  const [excelData, setExcelData] = useState<{ chartName: string; promise: Promise<Object> }[]>([]);

  const exportExcel = async () => {
    excelData.forEach((chart) =>
      chart.promise.then((res) => {
        let sheetHeader: any[] = [];
        let obj = JSON.parse(JSON.stringify(res)).fields;

        for (let key in obj) {
          sheetHeader.push(obj[key]);
        }

        let sheetData: any = [];
        let response = JSON.parse(JSON.stringify(res));
        let documents = response.documents;
        if (documents && documents.length > 0) {
          if (filterState.chartType == 'Table') {
            documents.forEach((element: any) => {
              let documentObj: any = {};

              if (element.length != sheetHeader.length) {
                documentObj['group'] = element.group ? element['group'] : '';

                let sheetHeaderLength = element.value ? sheetHeader.length - 2 : sheetHeader.length - 1;
                let str = 'group_series_';
                for (let i = 0; i < sheetHeaderLength; i++) {
                  documentObj[str.concat(i.toString())] = element[str.concat(i.toString())]
                    ? element[str.concat(i.toString())]
                    : '';
                }

                documentObj['value'] = element['value'];
              }

              sheetData.push(element.length != sheetHeader.length ? documentObj : element);
            });
          }
          // over
          var option = {
            fileName: chart.chartName,
            datas: [
              {
                sheetData: sheetData,
                sheetName: 'sheet',
                sheetHeader: sheetHeader,
              },
            ],
          };
          var toExcel = new ExportJsonExcel(option);

          toExcel.saveExcel();
        }
      }),
    );
  };

  useEffect(() => {
    const filterMap = BDMdashboardFilter.get(filterState.chartName);
    let tmpList: PruFilterItemDef[] = [];
    for (const key in filterMap) {
      if (myDataList[key]) {
        tmpList.push({
          type: PruFilterItemType.DROPDOWN,
          field: key,
          displayName: filterMap[key],
          choices: [
            { displayName: 'All', value: 'all' },
            ...map(myDataList[key], (option: string) => ({ displayName: option, value: option })),
          ],
        });
      } else if (filterMap[key] == 'Region') {
        tmpList.push({
          type: PruFilterItemType.DROPDOWN,
          field: key,
          displayName: filterMap[key],
          choices: [{ displayName: region, value: region }],
        });
      } else if (filterMap[key] == 'Channel') {
        tmpList.push({
          type: PruFilterItemType.DROPDOWN,
          field: key,
          displayName: filterMap[key],
          choices: [{ displayName: channel, value: channel }],
        });
      }
    }
    setFilterList(tmpList);
  }, [filterState.chartName, myDataList]);

  return (
    <>
      <PruFilter
        title={Translation('dashboard.common.controlPanel')}
        itemDef={[
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'chartName',
            initialValue: filterState.chartName,
            defaultValue: filterState.chartName || initialState.chartName,
            displayName: Translation('dashboard.common.name'),
            choices: [...map(BDMdashboardGroupName, (option: string) => ({ displayName: option, value: option }))],
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'chartType',
            initialValue: filterState.chartType,
            defaultValue: initialState.chartType,
            displayName: Translation('dashboard.common.type'),
            choices: [...map(DashboardType, (option: string) => ({ displayName: option, value: option }))],
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'type',
            displayName: BDMdashboardFilter.get(filterState.chartName).type,
            choices: [
              {
                displayName: 'All',
                value: 'all',
              },
              {
                displayName: 'Yes',
                value: 'Yes',
              },
              {
                displayName: 'No',
                value: 'No',
              },
            ],
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'startDate',
            fieldTo: 'endDate',
            displayName: BDMdashboardFilter.get(filterState.chartName).assignedDate,
          },
          ...map(filterList, (option: PruFilterItemDef) => option),
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'region',
            initialValue: filterState.region,
            defaultValue: initialState.region,
            displayName: 'Region',
            choices: [{ displayName: region, value: region }],
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'channel',
            initialValue: filterState.channel,
            defaultValue: initialState.channel,
            displayName: 'Channel',
            choices: [{ displayName: channel, value: channel }],
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            chartName: data.chartName,
            chartType: data.chartType,
            type: data.type,
            assignedDate: [!!data.startDate ? data.startDate : null, !!data.endDate ? data.endDate : null],
            leadSource: data.leadSource,
            campaignId: data.campaignId,
            consultantCode: data.consultantCode,
            campaignStatus: data.campaignStatus,
            campaignType: data.campaignType,
            campaignRecordType: data.campaignRecordType,
            unitCode: data.unitCode,
            businessManagerName: data.businessManagerName,
            consultantName: data.consultantName,
            region: data.region,
            channel: data.channel,
          })
        }
        fetchData={refreshData}
        downloadExcel={exportExcel}
        resetFilters={resetFilters}
      />
      <DashboardDetailPage
        filter={filterState}
        onRefresh={true}
        refreshdata={refreshData}
        setExcelData={(data) => setExcelData(data)}
        getDataList={(list) => setMyDataList(list)}
      />
    </>
  );
};

export default DashboardListingPage;
