import { AttachmentDef } from 'src/app/common/types';
import { TargetApplicantTypeEnum } from '../types';

export type SelectModeFormState = {
  targetApplicantType: TargetApplicantTypeEnum;
  eligibleDesignations: string[];
  eligibleSegments: string[];
  eligibleAgents: string[];
  excludedAgents: string[];
  agentListFile?: AttachmentDef | null;
};

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof SelectModeFormState;
    value: any;
  };
};

type SelectFieldAction = {
  type: 'SELECT_FIELD';
  payload: {
    field: 'eligibleDesignations' | 'eligibleSegments';
    value: string;
  };
};

type SelectModeFormAction = ModifyFieldAction | SelectFieldAction;

export const selectModeFormReducer = (
  state: SelectModeFormState,
  action: SelectModeFormAction,
): SelectModeFormState => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      const newState = {
        ...state,
        [action.payload.field]: action.payload.value,
      };
      if (action.payload.field === 'targetApplicantType') {
        newState.eligibleDesignations = [];
        newState.eligibleSegments = [];
        newState.eligibleAgents = [];
        newState.excludedAgents = [];
        newState.agentListFile = null;
      }
      return newState;
    case 'SELECT_FIELD':
      const newList = [...state[action.payload.field]];
      const itemIndex = newList.indexOf(action.payload.value);
      if (itemIndex === -1) {
        newList.push(action.payload.value);
      } else {
        newList.splice(itemIndex, 1);
      }
      return {
        ...state,
        [action.payload.field]: newList,
      };
    default:
      return state;
  }
};
