import React, { FC, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { TextField, Button } from '@mui/material';
import { useIntl } from 'react-intl';
import { SurveyFormState, SurveyErrorState } from './SurveyDetailForm';
import { PruDatePicker } from 'src/app/common/components/PruDatePicker';
import { MANDATORY_FIELD_ERROR_TEXT, DATE_ERROR_TEXT } from 'src/app/modules/Survey/constants';
import DesignationSelectSection from 'src/app/common/components/DesignationSelectSection';

const enableDesignation = (window.envConfig['ENABLE_DESIGNATION'] || 'false') === 'true';

type SurveyConfigSectionProps = {
  formState: SurveyFormState;
  errorState: SurveyErrorState;
  onChangeStartDate: (startDate: Date | null) => void;
  onChangeEndDate: (endDate: Date | null) => void;
  onChangeDesignations: (designations: string[]) => void;
  onChangeEligibleAgents: (eligibleAgents: string[]) => void;
  onChangeExcludedAgents: (excludedAgents: string[]) => void;
  onNextPage: () => void;
  onDismissErrorHandler: (field: keyof SurveyErrorState['mandatory'], value: any) => void;
  immediateErrorValidator: () => void;
};

const useStyles = makeStyles()((theme) => ({
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  designationRow: {
    display: 'flex',
  },
  fieldContainer: {
    width: 130,
    boxSizing: 'border-box',
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },
  dateDivider: {
    marginLeft: 8,
    marginRight: 8,
  },
  sectionMargin: {
    marginBottom: 20,
  },
  divideMargin: {
    marginBottom: 10,
  },
  subHeader: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
  },
  designationContainer: {
    padding: '10px',
    borderRadius: 5,
    backgroundColor: '#F5F5F5',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));

const SurveyConfigSection: FC<SurveyConfigSectionProps> = ({
  formState,
  errorState,
  onChangeStartDate,
  onChangeEndDate,
  onChangeDesignations,
  onChangeEligibleAgents,
  onChangeExcludedAgents,
  onNextPage,
  onDismissErrorHandler,
  immediateErrorValidator,
}) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  useEffect(
    () => {
      immediateErrorValidator();
    },
    // eslint-disable-next-line
    [formState.startDate, formState.endDate],
  );

  return (
    <div className={classes.sectionMargin}>
      <div className={classes.divideMargin}>
        <div className={classes.subHeader}>{Translation('survey.form.basicConfiguration')}</div>
      </div>

      <div style={{ marginBottom: 10 }} className={classes.rowContainer}>
        <div className={classes.fieldContainer}>
          <span className={classes.field}>
            {Translation('survey.form.duration')}
            <span className={classes.mandatory}>*</span> :
          </span>
        </div>
        <PruDatePicker
          slotProps={{
            textField: {
              variant: 'outlined',
              margin: 'dense',
              error: errorState.mandatory.startDate || errorState.immediate.endDateBeforeStartDate,
              helperText: errorState.mandatory.startDate && MANDATORY_FIELD_ERROR_TEXT,
            },
          }}
          format="DD/MM/YYYY"
          value={formState.startDate}
          onChange={(date) => {
            onDismissErrorHandler('startDate', date);
            onChangeStartDate(date);
          }}
          // KeyboardButtonProps={{
          // 'aria-label': 'change date',
          // }}
        />
        <div className={classes.dateDivider}>-</div>
        <PruDatePicker
          slotProps={{
            textField: {
              variant: 'outlined',
              margin: 'dense',
              error: errorState.mandatory.endDate || errorState.immediate.endDateBeforeStartDate,
              helperText: errorState.mandatory.endDate
                ? MANDATORY_FIELD_ERROR_TEXT
                : errorState.immediate.endDateBeforeStartDate && DATE_ERROR_TEXT,
            },
          }}
          format="DD/MM/YYYY"
          value={formState.endDate}
          onChange={(date) => {
            onDismissErrorHandler('endDate', date);
            onChangeEndDate(date);
          }}
          // KeyboardButtonProps={{
          // 'aria-label': 'change date',
          // }}
        />
      </div>

      {enableDesignation && (
        <div style={{ marginBottom: 10 }} className={classes.designationRow}>
          <div style={{ paddingTop: 12 }} className={classes.fieldContainer}>
            <span className={classes.field}>{Translation('survey.form.designations')} :</span>
          </div>
          <div className={classes.designationContainer}>
            <DesignationSelectSection
              style={{ width: 900 }}
              designations={formState.designations}
              onChangeDesignations={onChangeDesignations}
            />
          </div>
        </div>
      )}

      <div className={classes.rowContainer}>
        <div className={classes.fieldContainer}>
          <span className={classes.field}>{Translation('survey.form.extraInclude')} : </span>
        </div>
        <div style={{ flexGrow: 1 }}>
          <TextField
            margin="dense"
            variant="outlined"
            fullWidth
            placeholder={'Input agent code separate by comma. e.g. 10001, 10002'}
            value={formState.eligibleAgents.join(',')}
            onChange={(e) => {
              const agentCode = e.target.value.replace(/[^0-9,]/g, '');
              onChangeEligibleAgents(agentCode ? agentCode.split(',') : []);
            }}
          />
        </div>
      </div>

      <div style={{ marginBottom: 20 }} className={classes.rowContainer}>
        <div className={classes.fieldContainer}>
          <span className={classes.field}>{Translation('survey.form.extraExclude')} : </span>
        </div>
        <div style={{ flexGrow: 1 }}>
          <TextField
            margin="dense"
            variant="outlined"
            fullWidth
            value={formState.excludedAgents.join(',')}
            placeholder={'Input agent code separate by comma. e.g. 10001, 10002'}
            onChange={(e) => {
              const agentCode = e.target.value.replace(/[^0-9,]/g, '');
              onChangeExcludedAgents(agentCode ? agentCode.split(',') : []);
            }}
          />
        </div>
      </div>

      <div className={classes.footerContainer}>
        <Button variant="contained" color="secondary" onClick={onNextPage}>
          {Translation('app.button.next')}
        </Button>
      </div>
    </div>
  );
};

export default SurveyConfigSection;
