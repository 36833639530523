import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  content: {
    flexGrow: 1,
  },
  remark: { color: '#979797' },
  radioGroup: { display: 'flex', flexDirection: 'row' },
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  loadingContainer: {
    width: '100%',
    padding: 50,
    display: 'flex',
    justifyContent: 'center',
  },
  textRowHeight: {
    height: 50,
  },
  textAreaRowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  subHeader: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
  },
  fieldContainer: {
    width: 160,
    boxSizing: 'border-box',
  },
  checkboxFieldContainer: {
    paddingTop: 9,
    width: 160,
    boxSizing: 'border-box',
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },

  margin_r8: {
    marginRight: 8,
  },
  margin_l30_r8: {
    marginLeft: 30,
    marginRight: 8,
  },
  margin_l8: {
    marginLeft: 8,
  },
  margin_b30: {
    marginBottom: 30,
  },
  margin_l160: {
    marginLeft: 160,
  },

  dialogContainer: {
    padding: 60,
  },
  dialogButton: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  formHelperText: {
    color: '#f018a6',
  },
}));
