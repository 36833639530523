import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import { makeStyles } from 'tss-react/mui';
import { Collapse, List, ListItem, ListItemButton, ListItemText, Theme } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { ModuleHierachy } from 'src/app/common/module/types';
import { toAbsoluteUrl } from 'src/app/common/utils';
import { useProjectSelector } from 'src/redux/store';

const NestedMenu = ({ menuList, isExpand }: { menuList: ModuleHierachy[]; isExpand: boolean }) => {
  const [openMenu, setOpenMenu] = useState<{ [k: string]: boolean }>({});
  const { classes, cx } = useStyles();
  const { pathname } = useLocation();
  const isAsideMenuCollapse = useProjectSelector((state) => state.layout.isAsideMenuCollapse);

  useEffect(() => {
    const activeMenu = menuList?.filter((item) => pathname?.startsWith(item.path))[0];
    if (activeMenu?.child && activeMenu?.child?.length > 0) {
      const { title, path } = activeMenu;
      setOpenMenu((prev) => {
        return { ...prev, [`${title}-${path}`]: true };
      });
    }
  }, [menuList]);

  useEffect(() => {
    if (isAsideMenuCollapse && !isExpand) {
      setOpenMenu({});
    }
  }, [isAsideMenuCollapse, isExpand]);

  const handleClickMenuItem = useCallback((menu: ModuleHierachy) => {
    const { title, path, child } = menu ?? {};
    if (child && child.length > 0) {
      const key = `${title}-${path}`;
      setOpenMenu((prevState) => ({
        [key]: !prevState[key],
      }));
    }
  }, []);

  return (
    <List disablePadding>
      {menuList?.map((menu, index) => {
        const { title, path, enableRead } = menu;
        const activeClass = path && pathname?.startsWith(path) ? `${classes.menu} ${classes.active}` : classes.menu;
        const isSubMenuOpen = !!openMenu[`${title}-${path}`];
        const hasSubMenu = menu?.child && menu?.child?.length > 0;

        return enableRead ? (
          <li key={`${menu.title}-${menu.path}`}>
            <ListItemButton
              onClick={() => handleClickMenuItem(menu)}
              component={hasSubMenu ? 'div' : NavLink}
              to={hasSubMenu ? undefined : menu?.path}
              className={activeClass}
            >
              {menu?.icon ? (
                <span className={`${classes.icon} icon`}>
                  <SVG src={toAbsoluteUrl(menu.icon)} />
                </span>
              ) : (
                <i className={`${classes.dot} dot`} />
              )}

              <span
                className={cx(classes.menuContent, { [classes.hideMenuContent]: isAsideMenuCollapse && !isExpand })}
              >
                <ListItemText primary={title} className={classes.menuText} />
                {hasSubMenu ? isSubMenuOpen ? <ExpandLess /> : <ExpandMore /> : null}
              </span>
            </ListItemButton>
            {hasSubMenu && (
              <Collapse in={isSubMenuOpen} timeout="auto" unmountOnExit className={classes.collapseMenu}>
                <NestedMenu menuList={menu.child!} isExpand={isExpand} />
              </Collapse>
            )}
          </li>
        ) : null;
      })}
    </List>
  );
};

export default NestedMenu;

const useStyles = makeStyles()((theme: Theme) => ({
  menu: {
    '--normal-color': '#9899ac !important',
    '--active-color': '#FFFFFF !important',
    '--active-icon-color': '#3699FF',
    color: 'var(--normal-color)',
    '&:hover': {
      color: 'var(--active-color)',
      background: '#1b1b28',
    },
    '& .dot': {
      backgroundColor: 'var(--normal-color)',
    },
    '&:hover .dot': {
      backgroundColor: 'var(--active-icon-color)',
    },
    '&:hover .icon svg g [fill]': {
      fill: 'var(--active-icon-color)',
    },
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '9px 25px',
  },
  active: {
    background: '#1b1b28',
    color: 'var(--active-color)',
    '& .dot': {
      backgroundColor: 'var(--active-icon-color)',
    },
    '& .icon svg g [fill]': {
      fill: 'var(--active-icon-color)',
    },
  },
  menuContent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  hideMenuContent: {
    display: 'none',
  },
  menuText: {
    flex: 1,
  },
  collapseMenu: {
    paddingLeft: theme.spacing(2),
  },
  icon: {
    height: 23,
    width: 23,
    marginRight: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg g [fill]': {
      fill: '#494b74',
    },
  },
  dot: {
    height: 4,
    width: 4,
    borderRadius: '100%',
    marginRight: 12,
    flexShrink: 0,
  },
}));
