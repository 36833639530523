import { BDMdashboardName, LMdashboardName, TopDashboardDetail } from './dashboard-types';

//please revise all dashboardLeft,dashboardRight,chartID into uat
//notice :
//'Both' -> both are numbers
//'Among' -> one number and one table
//'Unique' -> only one table

export enum LostReportTop {
  type = 'Both',
  dashboardLeft = '628c44e1-1caa-42f0-8bc4-1512e01ab3b5',
  dashboardRight = '628c44e1-1caa-462f-87e4-1512e01ab3b3',
}

export enum SubmissionReportTop {
  type = 'Both',
  dashboardLeft = '62e7629f-8e95-4240-8bcb-c2bb67cacaa0',
  dashboardRight = '62e7629f-8e95-4e8d-8cd2-c2bb67caca9c',
}

export enum SubsourceTop {
  type = 'Both',
  dashboardLeft = '62b269e6-b622-4792-86cc-e9ffaba428b3',
}

export enum AssignedTodayTop {
  type = 'Both',
  dashboardLeft = '628c4645-3ceb-407c-8baa-b52ae38cf441',
}

export enum WeekCreateTop {
  type = 'Both',
  dashboardLeft = '628c4698-0b13-46df-86d4-94ce59f37183',
}

export enum AverageTop {
  type = 'Both',
  dashboardLeft = '62b26a7b-2b11-459b-8dcc-6d176c490089',
}

export enum StatusTop {
  type = 'Among',
  dashboardLeft = '628c45ef-b3f9-4efa-804d-6191e58a6b4d',
  dashboardRight = '628c45ef-b3f9-4855-870f-6191e58a6b49',
}

export enum PerformanceTop {
  type = 'Among',
  dashboardLeft = '628c4534-b3f9-48c8-8c92-6191e58a1f8e',
  dashboardRight = '628c4534-b3f9-4912-8f76-6191e58a1f8c',
}

export enum LostReasonTop {
  type = 'Unique',
  dashboardLeft = '63060e5c-6dc9-488f-8fed-95757e40d86e',
}

export const TopDashboardsUat: Map<string, any> = new Map<BDMdashboardName, TopDashboardDetail>([
  [BDMdashboardName.LOSTREPORT, LostReportTop],
  [BDMdashboardName.SUBMIISSIONREPORT, SubmissionReportTop],
  [BDMdashboardName.SUBSOURCE, SubsourceTop],
  [BDMdashboardName.ASSIGNEDTODAY, AssignedTodayTop],
  [BDMdashboardName.WEEKCREATE, WeekCreateTop],
  [BDMdashboardName.AVERAGE, AverageTop],
  [BDMdashboardName.STATUS, StatusTop],
  [BDMdashboardName.SOURCE, PerformanceTop],
  [BDMdashboardName.LOSTREASON, LostReasonTop],
]);

export const LMdashboardDataUat: Map<string, any> = new Map<LMdashboardName, any>([
  [LMdashboardName.PARTICIPANTS, '635b95af-6f8f-4548-87a6-b4fcbdc2cbe8'],
  [LMdashboardName.CAMPAIGN, '635b95af-6f8f-4ce5-8818-b4fcbdc2cbea'],
  [LMdashboardName.LEADS, 'b75fd677-e18c-424a-a146-0a1b76a9a4de'],
]);

export const BDMdashboardDataUat = '6364a40a-0ffb-4da4-8fd2-4f791c6cd778';

export const DashboardLibraryUat = {
  report: [
    {
      chartName: 'List of Participants',
      chartID: '635b95af-6f8f-45dc-82fe-b4fcbdc2cbe6',
      chartType: 'Table',
    },
    {
      chartName: 'List of Campaign',
      chartID: '635b95af-6f8f-452d-8e13-b4fcbdc2cbe2',
      chartType: 'Table',
    },
    {
      chartName: 'List of Leads',
      chartID: '635b95af-6f8f-4c82-856c-b4fcbdc2cbe4',
      chartType: 'Table',
    },
    {
      chartName: 'Appointment to Lost Report',
      chartID: '628c44e1-1caa-48c3-8eea-1512e01ab3b1',
      chartType: 'Table',
    },
    {
      chartName: 'Appointment to Lost Report',
      chartID: '628c44e1-1caa-462f-87e4-1512e01ab3b3',
      chartType: 'Chart',
    },
    {
      chartName: 'Appointment to Submission Report',
      chartID: '62e7629f-8e95-43bb-83ec-c2bb67caca9a',
      chartType: 'Table',
    },
    {
      chartName: 'Appointment to Submission Report',
      chartID: '62e7629f-8e95-4e8d-8cd2-c2bb67caca9c',
      chartType: 'Chart',
    },
    {
      chartName: 'Assigned to Call Report',
      chartID: '63060d84-fd14-43d8-8d16-17b14254ff69',
      chartType: 'Chart',
    },
    {
      chartName: 'Assigned to Call Report',
      chartID: '63060d84-fd14-4a50-8489-17b14254ff67',
      chartType: 'Table',
    },
    {
      chartName: 'Top Consultants by Lead Capture',
      chartID: '63060dc3-e486-4149-8feb-2cc9ce9d5e29',
      chartType: 'Chart',
    },
    {
      chartName: 'Top Consultants by Lead Capture',
      chartID: '14cf9bbf-45d2-4ece-ad9e-5c0b158ae641',
      chartType: 'Table',
    },
    {
      chartName: 'Leads Aging Report - Pending Call',
      chartID: '62f45d2d-2b22-4ea1-88f2-949b797df525',
      chartType: 'Table',
    },
    {
      chartName: 'Leads Aging Report - Pending Call',
      chartID: '62f45d2d-2b22-436a-8195-949b797df523',
      chartType: 'Chart',
    },
    {
      chartName: 'Leads Aging Report - Engagement',
      chartID: '6303427c-df9a-41c5-80c3-687fa35f68da',
      chartType: 'Table',
    },
    {
      chartName: 'Leads Aging Report - Engagement',
      chartID: '6303427c-df9a-48f8-8a01-687fa35f68d8',
      chartType: 'Chart',
    },
    {
      chartName: 'Leads Aging Report - Submission',
      chartID: '628c4619-1caa-4bec-8e85-1512e01b5467',
      chartType: 'Table',
    },
    {
      chartName: 'Leads Aging Report - Submission',
      chartID: '628c4619-1caa-4f8f-8d86-1512e01b5465',
      chartType: 'Chart',
    },
    {
      chartName: 'Cumulative Leads Aging Report',
      chartID: '62e762d6-e5b8-4fd2-8730-c87aeb2929ee',
      chartType: 'Table',
    },
    {
      chartName: 'Cumulative Leads Aging Report',
      chartID: '62e762d6-e5b8-466b-867b-c87aeb2929ec',
      chartType: 'Chart',
    },
    {
      chartName: 'Leads Report by Sub Source',
      chartID: '62b269e6-b622-43cb-8727-e9ffaba428b1',
      chartType: 'Table',
    },
    {
      chartName: 'Leads Report by Sub Source',
      chartID: '62b269e6-b622-49e4-8949-e9ffaba428af',
      chartType: 'Chart',
    },
    {
      chartName: 'Lead Stage Report',
      chartID: '628c45c4-ea37-44dc-8286-740bc83913d7',
      chartType: 'Table',
    },
    {
      chartName: 'Lead Stage Report',
      chartID: '628c45c4-ea37-4791-859d-740bc83913d5',
      chartType: 'Chart',
    },
    {
      chartName: 'Dropped-Off Report',
      chartID: '628c4586-100c-4c64-8f38-8ff55b6eaa44',
      chartType: 'Table',
    },
    {
      chartName: 'Dropped-Off Report',
      chartID: '628c4586-100c-471b-858b-8ff55b6eaa46',
      chartType: 'Chart',
    },
    {
      chartName: 'Lead Stage by Consultants',
      chartID: '628c45ef-b3f9-495c-8ce8-6191e58a6b4b',
      chartType: 'Table',
    },
    {
      chartName: 'Lead Stage by Consultants',
      chartID: '628c45ef-b3f9-4bd9-8d84-6191e58a6b47',
      chartType: 'Chart',
    },
    {
      chartName: 'Leads Create Per Month',
      chartID: '628c4698-0b13-40f7-8853-94ce59f37181',
      chartType: 'Table',
    },
    {
      chartName: 'Leads Create Per Month',
      chartID: '628c4698-0b13-46e0-82cd-94ce59f3717f',
      chartType: 'Chart',
    },
    {
      chartName: 'Unaccepted Leads by Consultants',
      chartID: '63060de1-6dc9-4853-8138-95757e40a784',
      chartType: 'Chart',
    },
    {
      chartName: 'Unaccepted Leads by Consultants',
      chartID: '63060de1-6dc9-4a0a-86a8-95757e40a782',
      chartType: 'Table',
    },
    {
      chartName: 'Lead Lost Reason Report',
      chartID: '63060e5c-6dc9-4ebe-8e0a-95757e40d86c',
      chartType: 'Table',
    },
    {
      chartName: 'Lead Lost Reason Report',
      chartID: '63060e5c-6dc9-4209-83d2-95757e40d86a',
      chartType: 'Chart',
    },
    {
      chartName: 'Leads Assigned Today by Source',
      chartID: '628c4645-3ceb-4244-847f-b52ae38cf443',
      chartType: 'Table',
    },
    {
      chartName: 'Leads Assigned Today by Source',
      chartID: '628c4645-3ceb-485d-8c41-b52ae38cf43f',
      chartType: 'Chart',
    },
    {
      chartName: 'Average Conversion Time Report',
      chartID: '62b26a7b-2b11-4430-894a-6d176c49008b',
      chartType: 'Table',
    },
    {
      chartName: 'Average Conversion Days',
      chartID: '62b26a7b-2b11-4430-894a-6d176c49008b',
      chartType: 'Table',
    },
    {
      chartName: 'Average Conversion Days',
      chartID: '62b26a7b-2b11-459b-8dcc-6d176c490089',
      chartType: 'Chart',
    },
    {
      chartName: 'Conversion Performance Report by Source',
      chartID: '628c4534-b3f9-4df5-8891-6191e58a1f90',
      chartType: 'Table',
    },
    {
      chartName: 'Conversion Performance Report by Source',
      chartID: '628c4534-b3f9-47d4-8d0f-6191e58a1f8a',
      chartType: 'Chart',
    },
    {
      chartName: 'Conversion performance by Lead Assigned Date',
      chartID: '630446cc-cd9c-447e-8c70-a8124aa04c31',
      chartType: 'Chart',
    },
    {
      chartName: 'Conversion performance by Lead Assigned Date',
      chartID: '630446cc-cd9c-4ff8-8a8c-a8124aa04c33',
      chartType: 'Table',
    },
    {
      chartName: 'Conversion Performance by Last Updated Date',
      chartID: '63060d6f-3efe-4d3a-8f37-ef3e40082a46',
      chartType: 'Chart',
    },
    {
      chartName: 'Conversion Performance by Last Updated Date',
      chartID: '63060d6f-3efe-40cd-89e7-ef3e40082a44',
      chartType: 'Table',
    },
    {
      chartName: 'Average Conversion Days by Consultants',
      chartID: '63060daa-09a6-4b5b-8fea-b2af48cf28bf',
      chartType: 'Table',
    },
    {
      chartName: 'Average Conversion Days by Consultants',
      chartID: '63060daa-09a6-40a7-8d14-b2af48cf28c1',
      chartType: 'Chart',
    },
    {
      chartName: 'Leads Create Per Month - phase 2',
      chartID: '63060d3e-0989-4d49-8304-795bb4e5dae2',
      chartType: 'Chart',
    },
    {
      chartName: 'Leads Create Per Month - phase 2',
      chartID: '63060d3e-0989-42f9-8362-795bb4e5dae4',
      chartType: 'Table',
    },
  ],
};
