import { PULSELEADS_BASE_PATH } from '../../constants';

export const PULSELEADS_ENGAGEMENT_TITLE = "title.pulseleads.engagement";
export const PULSELEADS_ENGAGEMENT_FIELDS_TITLE = "title.pulseleads.engagement.fields";
export const PULSELEADS_ENGAGEMENT_TOPIC_TITLE = "title.pulseleads.engagement.topic";
export const PULSELEADS_ENGAGEMENT_TASK_TITLE = "title.pulseleads.engagement.task";

export const PULSELEADS_ENGAGEMENT_FIELDS_NAMESPACE = "PULSELEADS-ENGAGEMENT-FIELDS";
export const PULSELEADS_ENGAGEMENT_TOPIC_NAMESPACE = "PULSELEADS-ENGAGEMENT-TOPIC";
export const PULSELEADS_ENGAGEMENT_TASK_NAMESPACE = "PULSELEADS-ENGAGEMENT-TASK";


export const PULSELEADS_LEAD_ENGAGEMENT_PATH = `${PULSELEADS_BASE_PATH}/engagement`;

export const PULSELEADS_LEAD_ENGAGEMENT_FIELDS_PATH = "/fields";
export const PULSELEADS_LEAD_ENGAGEMENT_TOPIC_PATH = "/topic";
export const PULSELEADS_LEAD_ENGAGEMENT_TASK_PATH = "/task";

export enum MTmode {
	CREATE = 'add',
	EDIT = 'edit',
	COPY = 'copy',
	VIEW = 'view'
}
export const PublishStatus: { [item: string]: any } = {
	Draft: {
		label: "component.formSelectItem.save-as-draft",
		key: "0",
	},
	Publish: {
		label: "section.common.operation.publish",
		key: "1",
	},
	UnPublish: {
		label: "section.common.operation.unpublish",
		key: "2",
	},
	Publishing: {
		label: "Publishing",
		key: "3",
	}

};
export enum PublishTimeOptions {
	Immediately = "true",
	SpecificTime = "false",
};

export const publishingCode = 'E024';

export const publishingSameVersionCode = 'E025';