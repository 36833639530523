import { FC } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import PruRoute from 'src/app/common/components/PruRoute';
import ParamsProvider from 'src/app/common/components/ParamsProvider';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { EVENT_BASE_PATH, EVENT_LIST_PATH } from '../../constants';
import { eventFilterKeys } from './list/event-listing-page.hook';
import { EventListingPage } from './list/event-listing-page.screen';
import { EventDetailPage } from './form/event-detail-page.screen';

export const eventListPath = `${EVENT_BASE_PATH}${EVENT_LIST_PATH}`;

const EventListRoutes: FC = () => {
  return (
    <Switch>
      <Redirect exact from={eventListPath} to={`${eventListPath}/list`} />
      <PruRoute
        exact
        path={`${eventListPath}/list`}
        render={(props) => (
          <ParamsProvider {...props} acceptKeys={eventFilterKeys}>
            <EventListingPage />
          </ParamsProvider>
        )}
      />
      <PruRoute exact path={`${eventListPath}/edit/:id`} component={EventDetailPage} />
      <PruRoute exact path={`${eventListPath}/view/:id`} component={EventDetailPage} />
      <PruRoute exact path={`${eventListPath}/create`} component={EventDetailPage} />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default EventListRoutes;
