
import React, { useState } from 'react';
import { PruTableRow } from '../Table/PruTableRow';
import { Checkbox, IconButton } from '@mui/material';
import { ProColumns, RowSelection } from './index';
import { TableCell } from '@mui/material';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import StickyTableCell from './StickyTableCell';

interface ProTableRowItemProps<T> {
  columns: ProColumns<T>[];
  rowSelection?: RowSelection<T>;
  row: T;
  rowIndex: number;
  selectedRow: T[];
  handleSelectSingleRow: (event: any, row: T) => void;
  open: boolean;
  setOpen: (params: boolean) => void;
  level?: number;
}

interface ProTableRowProps<T> {
  columns: ProColumns<T>[];
  rowSelection?: RowSelection<T>;
  row: T;
  rowIndex: number;
  selectedRow: T[];
  handleSelectSingleRow: (event: any, row: T) => void;
  level?: number;
}

const ProTableRowItem = <T extends { [key: string]: any } = {}>({ columns, rowSelection, row, selectedRow, rowIndex, handleSelectSingleRow, open, setOpen, level = 0 } : ProTableRowItemProps<T>) => {

  const { getCheckboxProps } = rowSelection || { getCheckboxProps: () => ({ disabled: false }) };
  const renderTableCellItem = <T extends { [key: string]: any } = {}>(row: T, col: ProColumns<T>, rowIndex: number, colIndex: number, level: number) => {
    const { render, dataIndex, valueType } = col
    let result: React.ReactNode
    if (valueType === 'index') {
      result = rowIndex + 1
    } else if (render) {
      try {
        result = render(dataIndex && row[dataIndex], row)
      } catch (err) {
        console.error(err)
        result = ''
      }
    } else {
      result=  dataIndex ? row[dataIndex] : ''
    }
    if (colIndex === 0 && row.children) {
      result = (
        <span style={{ marginLeft: 18 * (level - 1) }}>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {!open ? <AddBoxOutlinedIcon /> : <IndeterminateCheckBoxOutlinedIcon />}
          </IconButton>
          { result }
        </span>
      )
    } else if (colIndex === 0) {
      result = (
        <span style={{ marginLeft: 18 * level }}>
          { result }
        </span>
      )
    }
    return result
  }
  
  const labelId = `enhanced-table-checkbox-${rowIndex}`;

  const hasSelected = JSON.stringify(selectedRow).indexOf(JSON.stringify(row)) > -1;


  return (
    <PruTableRow
      onClick={(event) => handleSelectSingleRow(event, row)}
      selected={hasSelected}
    >
      {
        rowSelection ? <TableCell padding="checkbox" align="left">
        <Checkbox
          disabled={getCheckboxProps(row).disabled}
          checked={hasSelected}
          inputProps={{ 'aria-labelledby': labelId }}
        />
        </TableCell> : null
      }
      {
        columns.map((col, colIndex) => (
          col.sticky  ? 
            <StickyTableCell
              component="th"
              scope="row"
              key={`cell-level${level}-${col.dataIndex}-${rowIndex}-${colIndex}`}
              align={col.align}
              width='300px'
              className={`sticky-cell`}
            >
              {
                renderTableCellItem(row, col, rowIndex, colIndex, level)
              }
            </StickyTableCell>
          :
          <TableCell
            component="th"
            scope="row"
            key={`cell-level${level}-${col.dataIndex}-${rowIndex}-${colIndex}`}
            align={col.align}
          >
            {
              renderTableCellItem(row, col, rowIndex, colIndex, level)
            }
          </TableCell>
        ))
      }
    </PruTableRow>
  )
}

const ProTableRow = <T extends { [key: string]: any } = {}>({ columns, rowSelection, row, selectedRow, rowIndex, handleSelectSingleRow, level = 0 } : ProTableRowProps<T>) => {
  
  const [ open, setOpen ] = useState<boolean>(false)

  return (
    <>
      <ProTableRowItem
        level={level}
        columns={columns}
        rowSelection={rowSelection}
        row={row}
        selectedRow={selectedRow}
        rowIndex={rowIndex}
        handleSelectSingleRow={handleSelectSingleRow}
        open={open}
        setOpen={setOpen}
      />
      {
        open ? ((row && row.children) || []).map((rowChild: T, i: number) => (
          <ProTableRow
            level={level + 1}
            key={`childrow-level${level}-${i}`}
            columns={columns}
            rowSelection={rowSelection}
            row={rowChild}
            selectedRow={selectedRow}
            rowIndex={rowIndex}
            handleSelectSingleRow={handleSelectSingleRow}
          />
        )) : null
      }
    </>
  )
}


export default ProTableRow;