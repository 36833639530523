import React, { FC } from "react";
import { ProductionTarget } from "../../../types/production-target-types";
import ProductionTargetItem from "./ProductionTargetItem";

type ProductionTargetListProps = {
  productionTargets: ProductionTarget[];
  refreshData: () => void;
};

const ProductionTargetList: FC<ProductionTargetListProps> = ({ productionTargets, refreshData }) => {
  return (
    <>
      {productionTargets.map(target => (
        <ProductionTargetItem
          key={`production-target-item-${target._id}`}
          target={target}
          onRefresh={refreshData}
        />
      ))}
    </>
  )
}

export default ProductionTargetList;
