import { TimeSetting } from './common-types';

export enum CampaignTypeFormMode {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
}

export enum CampaignTypeDropdownEnum {
  AGENT_LEAD_SOURCE = 'agent-lead-source',
}

export enum CampaignTypeStatusEnum {
  ACTIVE = 'active',
  DRAFT = 'draft',
}

export enum TypeEnum {
  TEXT = 'text',
  DATE = 'date',
  DROPDOWN = 'dropdown',
  ARRAY = 'array',
  NUMBER = 'number',
  SINGLE_CHOICE = 'singleChoice',
  MULTI_CHOICE = 'multipleChoice',
  PICTURE = 'picture',
  DOCUMENT = 'document',
  CHECKBOX = 'checkbox',
  POSTALCODE = 'postalCode',
  CURRENCY = 'currency',
}

export enum WorkflowRoleTypeEnum {
  AGENT = 'AGENT',
  STAFF = 'STAFF',
  INCLUDE = 'include',
  EXCLUDE = 'exclude',
}

export enum RuleKeyEnum {
  MANDATORY = 'mandatory',
  MANDATORY_DEPENDS = 'mandatoryDepends',
  LENGTH = 'length',
  COMPARE = 'compare',
  ELIGIBILITY = 'eligibility',
}

export enum RuleTypeEnum {
  STRING = 'string',
  BOOLEAN = 'boolean',
  ARRAY = 'array',
  NUMBER = 'number',
  DATE = 'date',
}

export enum OperatorEnum {
  EQ = 'eq',
  GTE = 'gte',
  LTE = 'lte',
  LT = 'lt',
  GT = 'gt',
}

export enum SendTypesEnum {
  APPLICANT = 'applicant',
  PARTICIPANT = 'participant',
}

export enum ContentEnum {
  FIXED = 'fixedContent',
  VARIABLE = 'variableContent',
}

export type CampaignTypeItem = {
  _id: string;
  name: string;
  description: string;
  code: string;
  status: CampaignTypeStatusEnum;
  agentLeadSource: string;
  agentLeadSubSource: string[];
  sections: SectionItem[];
  fieldPool: SectionFieldItem[];
  creatorSetting: CreatorSettingItem;
  approvalSetting: ApprovalSettingItem[];
  addLeadSetting: AddLeadSettingItem;
  sendDocumentSetting: SendDocumentSettingItem;
  updatedBy: string;
  createdAt: Date;
  updatedAt: Date;
};

export type SectionItem = {
  key: string;
  title: string;
  priority: number;
  sectionFields: SectionFieldItem[];
  isRestricted: boolean;
};

export type SectionFieldItem = {
  key: string;
  title: string;
  priority: number;
  type: TypeEnum;
  value: string | FieldValueItem[];
  rules: RuleItem[];
  isActive: boolean;
  removable: boolean;
  forcedMandatory: boolean;
  restrictedSection: string[];
  options?: OptionItem[];
};

export type FieldValueItem = {
  name: string;
  value: string;
};

export type RuleItem = {
  key: RuleKeyEnum;
  title: string;
  type: RuleTypeEnum;
  operator: OperatorEnum;
  value: string | boolean | number | string[];
  dependsOn?: string;
};

export type OptionItem = {
  key: string;
  label: string;
};

export type CampaignTypeKeyValueItem = {
  key: string;
  value: string;
};

export type CampaignTypeDropdownList = {
  [id: string]: CampaignTypeKeyValueItem[];
};

export type EligibilityRuleOption = {
  _id: string;
  name: string;
};

export type ApprovalRoleOptionList = {
  administratorOfficerRoles: string[];
  agentRoles: string[];
};

export type WorkflowRoleItem = {
  type: WorkflowRoleTypeEnum;
  values: string[];
};

export type SelectSettingItem = {
  selectBy: string;
  roleList: WorkflowRoleItem[];
};

export type CreatorSettingItem = {} & SelectSettingItem;

export type ApprovalSettingItem = {
  labelName: string;
  resetApprovalIndicator: boolean;
} & SelectSettingItem;

export type AddLeadSettingItem = {
  selfAddEnabled: string | boolean;
  needACMApproved: boolean;
  startDateSetting: TimeSetting;
  endDateSetting: TimeSetting;
};

export type SendDocumentSettingItem = {
  sendTypes: SendTypesEnum[];
  resourceIds: string[];
  type: ContentEnum;
  publishTimeSetting: TimeSetting;
  needACMApproved: boolean;
  enableSendDocument: string | boolean;
};
