import React, { FC, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Button, IconButton, TableFooter, TablePagination, Tooltip } from '@mui/material';
import { PaginateList, NotificationItem } from '../../../../types/notification-types';
import { useCommonStyles } from '../../../../../../common/styles/common-styles';
import { useUpdateEffect } from '../../../../utils/hook';
// import { PruTableHeader } from '../../../../../common/components/Table/PruTableHeader';
import { PruTableRow } from '../../../../../../common/components/Table/PruTableRow';
import PruTablePaginationActions from '../../../../../../common/components/Table/PruTablePaginationActions';
import { NotificationType } from '../../../../constants';
import { useIntl } from 'react-intl';
import { getDefaultDisplayDate, getCurrentConfig } from 'src/app/common/utils';
import PruTableEmptyRow from '../../../../../../common/components/Table/PruTableEmptyRow';
import PruTableLoading from '../../../../../../common/components/Table/PruTableLoading';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ProTableCell from '../../../../../../common/components/ProTable/ProTableCell';
import StickyTableCell from '../../../../../../common/components/ProTable/StickyTableCell';
import { useSelector } from 'react-redux';
import { AuthenticationState } from 'src/app/modules/Auth/_redux/authSlice';
import { RootState } from 'src/redux/store';

const formatDate = (date: Date) => {
  if (!date) return '-';
  return getDefaultDisplayDate(new Date(date), 'datetime');
};

type ListProps = {
  isLoading: boolean;
  notificationList?: PaginateList;
  onRefresh: () => void;
  onAdd: () => void;
  onView: (id: string) => void;
  onEdit: (id: string) => void;
  toSetOnTop: (item: NotificationItem) => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
  handleSort: (sorter: { [key: string]: string }) => void;
  onExport: () => void;
};

const useIncentiveStyles = makeStyles()({
  table: {
    minWidth: 700,
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    background: 'white',
    padding: 15,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  headerButtons: {
    display: 'inline-block',
  },
  footer: {
    width: '100%',
  },
  operationContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  operationBtn: {
    color: 'blue',
    cursor: 'pointer',
    textDecoration: 'underline',
    fontSize: '0.85rem',
    whiteSpace: 'nowrap',
  },
  disabled: {
    color: '#BBBBBB',
    cursor: 'default',
  },
});

const NotificationList: FC<ListProps> = ({
  isLoading,
  notificationList,
  onRefresh,
  onAdd,
  onView,
  onEdit,
  toSetOnTop,
  onChangePage,
  handleSort,
  onExport,
}) => {
  const { classes } = useIncentiveStyles();
  const { classes: commonClasses } = useCommonStyles();
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(20);
  const intl = useIntl();
  const locale = intl.locale;
  const defaultLocale = intl.defaultLocale;
  const Translation = (id: string) => intl.formatMessage({ id });

  const { user } = useSelector<RootState, AuthenticationState>((state) => state.auth);

  const currentConfig = getCurrentConfig(user);
  const CategoryType = currentConfig?.notificationCategoryType as { [key: string]: string };

  // const editBtnTxt = Translation('app.button.edit')

  const handleChangePage = (event: any, newPage: number) => {
    onChangePage(newPage + 1, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value));
    onChangePage(1, parseInt(event.target.value));
  };

  const showCategoryType = (itemValue: any) => {
    let showTitle = '';
    Object.entries(CategoryType).some(([label, value]) => {
      if (itemValue === value) {
        showTitle = Translation(`notification.category.type.${label}`);
        return true;
      }
      return false;
    });
    return showTitle;
  };

  return (
    <>
      <div className={classes.headerContainer}>
        <div className={commonClasses.header}>{Translation('component.formTitle.notification-table')}</div>
        <div style={{ justifyContent: 'flex-end' }} className={classes.headerButtons}>
          <Tooltip title="Refresh">
            <IconButton onClick={onRefresh} style={{ marginRight: 15 }}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
          <Button variant="contained" color="secondary" onClick={onExport} style={{ marginLeft: 10 }}>
            <ArrowDownwardIcon />
            {Translation('export.list.text')}
          </Button>
        </div>
      </div>
      <TableContainer component={Paper} className="auto-notification-container">
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <ProTableCell sorter handleSort={handleSort} dataIndex="msgTitle">
                {Translation('component.formLabel.notification-title')}
              </ProTableCell>
              <ProTableCell handleSort={handleSort} dataIndex="agentCodeList">
                {Translation('feedbackResult.common.agentCode')}
              </ProTableCell>
              <ProTableCell sorter handleSort={handleSort} dataIndex="category">
                {Translation('component.formLabel.category')}
              </ProTableCell>
              <ProTableCell sorter handleSort={handleSort} dataIndex="notificationType">
                {Translation('component.formLabel.notification-type')}
              </ProTableCell>
              <ProTableCell sorter handleSort={handleSort} dataIndex="sendingTime">
                {Translation('component.formLabel.sent-time')}
              </ProTableCell>
              <ProTableCell sorter handleSort={handleSort} dataIndex="modifier">
                {Translation('component.formLabel.last-updated-by')}
              </ProTableCell>

              <StickyTableCell className={`sticky-cell`}>
                {Translation('component.formLabel.operation')}
              </StickyTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <PruTableLoading isLoading={isLoading} />
            <PruTableEmptyRow
              isEmpty={!!(notificationList && notificationList.values && notificationList.values.length <= 0)}
            />
            {notificationList &&
              notificationList.values &&
              notificationList.values.map((row, index) => {
                return (
                  <PruTableRow key={`messaging_${index}`}>
                    <TableCell component="th" scope="row">
                      {row.i18nData
                        ? row.i18nData[locale]?.pushTitle ||
                          row.i18nData[locale]?.msgTitle ||
                          row.i18nData[defaultLocale]?.pushTitle ||
                          row.i18nData[defaultLocale]?.msgTitle
                        : row.pushTitle || row.msgTitle}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.agentCodeList && row.agentCodeList[0]}
                    </TableCell>
                    <TableCell>{showCategoryType(row.category)}</TableCell>
                    <TableCell>
                      {Translation(`component.formSelectItem.${NotificationType[row.notificationType]}`)}
                    </TableCell>
                    <TableCell>
                      <span className="show-text-in-single-row">
                        {row.sendingTime ? formatDate(row.sendingTime as Date) : '-'}
                      </span>
                    </TableCell>
                    <TableCell>{row.modifier}</TableCell>
                    <StickyTableCell className={`sticky-cell`}>
                      <div className={classes.operationContainer}>
                        <div
                          style={{ marginRight: 10 }}
                          className={`${classes.operationBtn}`}
                          onClick={() => onView(row.id)}
                        >
                          {Translation('app.button.view')}
                        </div>
                      </div>
                    </StickyTableCell>
                  </PruTableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TableFooter component="div" className={`table-footer-css`}>
        <TableRow component="div">
          <TablePagination
            align="right"
            rowsPerPageOptions={[5, 10, 20, 50]}
            colSpan={12}
            component="div"
            count={notificationList ? notificationList.total : 0}
            rowsPerPage={rowsPerPage}
            page={notificationList ? notificationList.page - 1 : 0}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true,
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={PruTablePaginationActions}
          />
        </TableRow>
      </TableFooter>
    </>
  );
};

export default NotificationList;
