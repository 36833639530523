import React, { useEffect, useReducer, useState } from 'react';
import { useIntl } from "react-intl";
import { makeStyles } from 'tss-react/mui';
import { getBlobAsAgent } from 'src/app/common/network';



const useStyles = makeStyles()(() =>
  ({
    tNcItem: {}
  }));

interface TnCItemProps {
  tNcItem: any,
  index: number
}



const TnCItem: React.FC<TnCItemProps> = ({ tNcItem, index }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { classes } = useStyles();
  const [url, setUrl] = useState<string>();

  const getUrlFromItem = (item: any) => {
    getBlobAsAgent({ resourceIds: item.value }).then((value) => {
      const url = value[0].url;
      setUrl(url);
    }).catch(() => {
      return null
    })
  }

  useEffect(() => {
    getUrlFromItem(tNcItem);
  }, [tNcItem]);


  return (
    <>
      {index > 0 ? <div>{`,`}</div>:null}
      {
        url ?
          <>
            <div>
              &nbsp;
            </div>
            <div
              style={{textDecoration:'underline', cursor:'pointer'}}
              onClick={() => {
                if ((window as any).ReactNativeWebView) {
                  (window as any).ReactNativeWebView.postMessage(`{ "action" : "loadurlwithtarget", "payload" : { "target" : "_blank", "url" : "${encodeURIComponent(url)}" } }`);
                } else {
                  window.open(url, '_blank');
                }
              }}>{`${tNcItem.name}`}</div>
          </>
          : null
      }
    </>
  )
}

export default TnCItem;