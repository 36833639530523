export const RECRUITMENT_BASE_PATH = '/recruitment';
export const RECRUITMENT_APPLICATION_FORM_PATH = '/application-form';
export const RECRUITMENT_APPLICATION_FORM_SETTING_PATH = '/setting';
export const FORM_TEMPLATE_PATH = '/form-template';
export const PDF_TEMPLATE_PATH = '/pdf-template';

export const RECRUITMENT_APPLICATION_FORM_EDIT_HISTORY_PATH = '/edit-history';

export const RECRUITMENT_LICENSE_EXAM_PATH = '/license-exam';
export const RECRUITMENT_LICENSE_EXAM_SCHEDULE_PATH = '/schedule';
export const RECRUITMENT_LICENSE_EXAM_REGISTRATION_PATH = '/registration';
export const RECRUITMENT_LICENSE_EXAM_RESULTS_PATH = '/results';
export const RECRUITMENT_QUIZ_REPORT_PATH = '/quiz-report';

export const RECRUITMENT_APPLICATION_COURSES = '/course-list';
export const RECRUITMENT_ONBOARDING_TRAINING = '/onboarding-training';

export const RECRUITMENT_TITLE = 'title.recruitment';

export const RECRUITMENT_APPLICATION_FORM_NAMESPACE = 'RECRUITMENT-APPLICATION-FORM';
export const RECRUITMENT_APPLICATION_FORM_SETTING_NAMESPACE = 'RECRUITMENT-APPLICATION-FORM-SETTING';
export const RECRUITMENT_APPLICATION_FORM_EDIT_HISTORY_NAMESPACE = 'RECRUITMENT-APPLICATION-FORM-EDIT-HISTORY';
export const RECRUITMENT_APPLICATION_TRAINING_COURSES_NAMESPACE = 'RECRUITMENT-APPLICATION-TRAINING-COURSES';
export const RECRUITMENT_APPLICATION_TRAINING_QUIZ_REPORT_NAMESPACE = 'RECRUITMENT-APPLICATION-TRAINING-QUIZ-REPORT';

export const RECRUITMENT_LICENSE_EXAM_SCHEDULE_NAMESPACE = 'RECRUITMENT-LICENSE-EXAM-SCHEDULE';
export const RECRUITMENT_LICENSE_EXAM_REGISTRATION_NAMESPACE = 'RECRUITMENT-LICENSE-EXAM-REGISTRATION';
export const RECRUITMENT_LICENSE_EXAM_RESULTS_NAMESPACE = 'RECRUITMENT-LICENSE-EXAM-RESULTS';

export enum AdminRecruitmentPermissionType {
  RECRUITMENT_APPLICATION_FORM_SETTING_READ = 'READ_ANY_RECRUITMENT-APPLICATION-FORM-SETTING',
  RECRUITMENT_APPLICATION_FORM_SETTING_CREATE = 'CREATE_ANY_RECRUITMENT-APPLICATION-FORM-SETTING',
  RECRUITMENT_APPLICATION_FORM_SETTING_UPDATE = 'UPDATE_ANY_RECRUITMENT-APPLICATION-FORM-SETTING',
  RECRUITMENT_APPLICATION_FORM_EDIT_HISTORY_READ = 'READ_ANY_RECRUITMENT-APPLICATION-FORM-EDIT-HISTORY',
  RECRUITMENT_APPLICATION_FORM_EDIT_HISTORY_CREATE = 'CREATE_ANY_RECRUITMENT-APPLICATION-FORM-EDIT-HISTORY',
  RECRUITMENT_APPLICATION_TRAINING_COURSES_READ = 'READ_ANY_RECRUITMENT-APPLICATION-TRAINING-COURSES',
  RECRUITMENT_APPLICATION_TRAINING_COURSES_CREATE = 'CREATE_ANY_RECRUITMENT-APPLICATION-TRAINING-COURSES',
  RECRUITMENT_APPLICATION_TRAINING_QUIZ_REPORT_READ = 'READ_ANY_RECRUITMENT-APPLICATION-TRAINING-QUIZ-REPORT',
  RECRUITMENT_APPLICATION_TRAINING_QUIZ_REPORT_CREATE = 'CREATE_ANY_RECRUITMENT-APPLICATION-TRAINING-QUIZ-REPORT',
  RECRUITMENT_LICENSE_EXAM_SCHEDULE_READ = 'READ_ANY_RECRUITMENT-LICENSE-EXAM-SCHEDULE',
  RECRUITMENT_LICENSE_EXAM_SCHEDULE_CREATE = 'CREATE_ANY_RECRUITMENT-LICENSE-EXAM-SCHEDULE',
  RECRUITMENT_LICENSE_EXAM_SCHEDULE_UPDATE = 'UPDATE_ANY_RECRUITMENT-LICENSE-EXAM-SCHEDULE',
  RECRUITMENT_LICENSE_EXAM_REGISTRATION_READ = 'READ_ANY_RECRUITMENT-LICENSE-EXAM-REGISTRATION',
  RECRUITMENT_LICENSE_EXAM_REGISTRATION_CREATE = 'CREATE_ANY_RECRUITMENT-LICENSE-EXAM-REGISTRATION',
  RECRUITMENT_LICENSE_EXAM_REGISTRATION_UPDATE = 'UPDATE_ANY_RECRUITMENT-LICENSE-EXAM-REGISTRATION',
  RECRUITMENT_LICENSE_EXAM_RESULTS_READ = 'READ_ANY_RECRUITMENT-LICENSE-EXAM-RESULTS',
  RECRUITMENT_LICENSE_EXAM_RESULTS_CREATE = 'CREATE_ANY_RECRUITMENT-LICENSE-EXAM-RESULTS',
  RECRUITMENT_LICENSE_EXAM_RESULTS_UPDATE = 'UPDATE_ANY_RECRUITMENT-LICENSE-EXAM-RESULTS',
}
