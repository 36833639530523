import React, { FC } from "react";
import ParamsProvider from "../../../../common/components/ParamsProvider";
import SetTargetDetail from "./SetTargetDetail";
import { RouteComponentProps } from "react-router-dom";

const SetTargetPage: FC<RouteComponentProps> = ({ ...props }) => {
  return (
    <ParamsProvider {...props} acceptKeys={["target", "targetRef", "year", "month"]}>
      <SetTargetDetail/>
    </ParamsProvider>
  )
}

export default SetTargetPage;