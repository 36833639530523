import { RoleType } from './role-types';

export enum YesOrNo {
  YES = 'YES',
  NO = 'NO',
}

export enum PermissionPossession {
  ANY = 'any',
  OWN = 'own',
}

export enum PermissionType {
  READ = 'Read',
  CREATE = 'Create',
  UPDATE = 'Update',
  APPROVE = 'Approve',
  ARCHIVE = 'Archive',
}

export type PermissionItem = {
  action: string;
  possession: string;
  resource: string;
  attributes: string[];
};

export type RolePermissionItem = {
  groupId: string;
  groupEmail?: string;
  type: RoleType;
  name: string;
  permissions: PermissionItem[];
};
