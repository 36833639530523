import Button from '@mui/material/Button';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ModulePermissionProps } from 'src/app/common/module/types';
import { getDateAndGenerateExcel } from '../../../components/Library/pages/performance/components/export/api';
import { MaterialDashboardExport } from '../../../components/Library/pages/performance/components/export/component';
export type MaterialDashboardProps = {
  blockName: string;
  blockType: string;
  moduleType: string;
} & ModulePermissionProps;

export const RecruitLibraryPerformancePage: FC<MaterialDashboardProps> = () => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const history = useHistory();
  const location = useLocation();
  const { name } = (location.state || {}) as { name: string };
  const { id } = useParams<{ id: string }>();
  return (
    <>
      <div style={rowWrap}>
        <div style={rowLeft}>
          <span>{Translation('title.resource.library.performance')}</span>
        </div>
        <div style={rowRight}>
          <Button variant="contained" color="inherit" onClick={history.goBack}>
            {Translation('app.button.back')}
          </Button>
        </div>
      </div>
      <MaterialDashboardExport onExport={getDateAndGenerateExcel} feature="recruitment" id={id} name={name} />
    </>
  );
};

const rowWrap = {
  display: 'flex',
  alignItems: 'center',
};
const rowLeft = {
  display: 'flex',
  flex: 1,
};
const rowRight = {
  display: 'flex',
  flex: 1,
  justifyContent: 'end',
};
