import { IntlShape } from 'react-intl';
export enum ResourceType {
  file = 'file',
  link = 'link',
  content = 'content',
  text = 'text',
  materialSet = 'materialSet',
  picture = 'picture',
}

export enum YesAndNo {
  'yes' = 'Y',
  'no' = 'N',
}

export enum ProductType {
  'Conventional' = 'conventional',
  'Sharia' = 'sharia',
}

export enum BottomBarShared {
  // 'recruit-link' = 'link',
  'name-card' = 'card',
}

export enum IsPublish {
  'save-publish' = 'Y',
  'save-as-draft' = 'N',
}

// eslint-disable-next-line
type PublishStatusProps = {
  label: string;
  key: string;
};

export const targetReceiver = (intl: IntlShape) => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return {
    allAgent: { label: Translation('resource_targetReceiver_allAgent'), key: 'all' },
    targetAgent: { label: Translation('resource_targetReceiver_targetAgent'), key: 'target' },
  };
};

export const targetType = (intl: IntlShape) => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return {
    byList: { label: Translation('resource_targetType_byList'), key: 'agentList' },
    byGroup: { label: Translation('resource_targetType_byGroup'), key: 'agentGroup' },
  };
};

export const productType = (intl: IntlShape) => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return {
    conventional: { label: Translation('product_type_conventional'), key: 'conventional' },
    sharia: { label: Translation('product_type_sharia'), key: 'sharia' },
  };
};

export const PublishStatus: { [item: string]: any } = {
  Draft: {
    label: 'component.formSelectItem.save-as-draft',
    key: 'Draft',
  },
  Published: {
    label: 'section.common.operation.publish',
    key: 'Published',
  },
  PublishedToSetOnly: {
    label: 'component.formSelectItem.publish-to-set-only',
    key: 'PublishedToSetOnly',
  },
  Unpublished: {
    label: 'section.common.operation.unpublish',
    key: 'Unpublished',
  },
};

export const MaterialStatus: { [item: string]: any } = {
  Draft: {
    label: 'Draft',
  },
  Published: {
    label: 'Published',
  },
  PublishedToSetOnly: {
    label: 'Published to Set Only',
  },
  Unpublished: {
    label: 'Unpublished',
  },
};

export enum LibraryPublishStatus {
  Publish = 'Published',
  Unpublished = 'Unpublished',
  Draft = 'Draft',
  PublishToSetOnly = 'PublishedToSetOnly',
}

export const MAX_DATE = '2969-12-31T16:00:00.000Z';

export const MIN_DATE = '1969-12-31T16:00:00.000Z';
