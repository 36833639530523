import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import PruRoute from 'src/app/common/components/PruRoute';
import { PULSELEADS_BASE_PATH, PULSELEADS_LEAD_CAMPAIGN_PATH, PULSELEADS_LEAD_PATH } from '../../../constants';
import ParamsProvider from 'src/app/common/components/ParamsProvider';
import CampaignListingPage, { campaignFilterKeys } from './List/CampaignListingPage';
import CampaignDetailPage from './Form/CampaignDetailPage';
import CampaignLeadListingPage from './Lead/List/CampaignLeadListingPage';
import CampaignLeadDetailPage from './Lead/Detail/CampaignLeadDetailPage';

export const campaignPath = `${PULSELEADS_BASE_PATH}${PULSELEADS_LEAD_PATH}${PULSELEADS_LEAD_CAMPAIGN_PATH}`;

const CampaignRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect exact from={campaignPath} to={`${campaignPath}/list`} />
      <PruRoute exact path={`${campaignPath}/list`} render={(props) => (
        <ParamsProvider {...props} acceptKeys={campaignFilterKeys}>
          <CampaignListingPage />
        </ParamsProvider>
      )}/>
      <PruRoute exact path={`${campaignPath}/edit/:id`} component={CampaignDetailPage}/>
      <PruRoute exact path={`${campaignPath}/create`} component={CampaignDetailPage}/>
      <PruRoute exact path={`${campaignPath}/:id/lead/list`} component={CampaignLeadListingPage} />
      <PruRoute exact path={`${campaignPath}/lead/:id`} component={CampaignLeadDetailPage}/>
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  )
};

export default CampaignRoutes;