import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { REPORT_BASE_PATH, REPORT_PERFORMANCE_PATH, REPORT_PERFORMANCE_PRODUCTION_TARGET_MTD_PATH } from '../../../../constants';
import { ReportType } from '../../../../types/report-types';
import { useIntl } from 'react-intl';
import { createNewProductionTargetMTDReport } from '../../../../network/reportCrud';
import CreateReport from '../../../../components/CreateReport';

const ProductionMTDReportPage: FC<RouteComponentProps> = ({ history, location }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  
  return (
    <CreateReport
      title={Translation("productionTargetMtd.title")}
      reportType={ReportType.PRODUCTION_TARGET_MTD}
      onCreate={createNewProductionTargetMTDReport}
      onBack={() => history.push(`${REPORT_BASE_PATH}${REPORT_PERFORMANCE_PATH}${REPORT_PERFORMANCE_PRODUCTION_TARGET_MTD_PATH}`)}
      fields={[{
        name: "year",
        displayText: Translation("productionTarget.common.year"),
        type: "date-year",
        mandatory: true
      }, {
        name: "month",
        displayText: Translation("productionTarget.common.month"),
        type: "date-month",
        mandatory: true
      }]}
    />
  )
};

export default ProductionMTDReportPage;
