import { Dispatch } from 'react';
import { mergeWith } from 'lodash';
import { preFillZero } from 'src/app/common/utils';
import { apiClient, CORE_URL, apiErrorHandler } from 'src/app/common/network';
import { ConfigAgentEditItem, ConfigAgentItem, ConfigAgentPaginateList, Configuration } from '../types/config-type';
import { setAppQuickPaths, setConfig } from '../_redux/configSlice';

export type ConfigAgentTypeParam = {
  agentCode?: string;
};
function readConfigs(rawConfigs: any[] = []) {
  let appConfigs: any = {};

  rawConfigs.forEach((item) => {
    const groupSuffix = item.group.split('pruforce.')[1];
    const content = item.content;

    if (!appConfigs[groupSuffix]) {
      appConfigs[groupSuffix] = {};
    }

    Object.keys(content).forEach((key) => {
      if (key === groupSuffix) {
        appConfigs[groupSuffix] = {
          ...appConfigs[groupSuffix],
          ...content[key],
        };
      } else {
        appConfigs[groupSuffix][key] = content[key];
      }
    });
  });

  appConfigs = mergeWith(appConfigs, appConfigs.Global);
  return appConfigs;
}
export const fetchConfigAgentList = async (
  params: ConfigAgentTypeParam,
  dispatch?: Dispatch<any>,
): Promise<ConfigAgentPaginateList> => {
  const queryPath = `${CORE_URL}/configz`;
  return apiClient
    .get<ConfigAgentPaginateList>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
const CONFIGURATION: any = {
  Sales: {
    prospect: { disableEngagement: true },
  },
};
export const fetchConfigs = async (
  dispatch: Dispatch<any>,
  options?: { region: string; channel: string },
): Promise<void> => {
  let requestHeader;
  if (options?.region && options?.channel) {
    requestHeader = {
      region: options.region,
      channel: options.channel,
    };
  }
  const [response, _]: any = await Promise.all([
    apiClient.get<any>(`${CORE_URL}/configs/appConfigs?app=admin`, {
      headers: requestHeader,
    }),
    fetchAppQuickPaths(dispatch, options),
  ]);

  let configs: Configuration = { ...CONFIGURATION };

  try {
    const rawData: any = response.data.data;
    dispatch?.(setConfig(readConfigs(rawData as any[]) as any));
  } catch (error: any) {
    throw apiErrorHandler(error, dispatch);
  }
};

export const fetchAppQuickPaths = async (
  dispatch: Dispatch<any>,
  options?: { region: string; channel: string },
): Promise<void> => {
  let requestHeader;
  if (options?.region && options?.channel) {
    requestHeader = {
      region: options.region,
      channel: options.channel,
    };
  }
  const response: any = await apiClient.get<any>(`${CORE_URL}/configs/home-sdk?group=pruforce.Home`, {
    headers: requestHeader,
  });

  try {
    const rawContent: any = response.data?.content;
    if (rawContent) {
      const appQuickPaths = rawContent.Home?.quickpath;
      dispatch?.(setAppQuickPaths(appQuickPaths));
    }
  } catch (error: any) {
    throw apiErrorHandler(error, dispatch);
  }
};

export const fetchConfigAgentItem = async (code: string, dispatch?: Dispatch<any>): Promise<ConfigAgentItem> => {
  const queryPath = `${CORE_URL}/configz/${preFillZero(String(code))}`;
  return apiClient
    .get<ConfigAgentItem>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const modifyConfigAgent = async (
  code: string,
  body: ConfigAgentEditItem,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .put<void>(`${CORE_URL}/configz/${code}`, body, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createConfigAgent = async (body: ConfigAgentEditItem, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .post<void>(`${CORE_URL}/configz`, body, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
