import { Dispatch } from 'react';
import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import { forEach } from 'lodash';
import { PaginateList } from 'src/app/common/types/common-types';
import { RecruitGenericItem } from '../types/recruit-generic-types';
import {
  ApprovalStatusEnum,
  PersonalParticularItem,
  PersonalParticularDocItem,
} from '../types/personal-particular-types';

const personalParticularEndpoint = 'recruit-personal-particular';

export type PersonalParticularListParam = {
  appId: string;
  isFollowUpByZone: string;
  page: number;
  limit: number;
};

export type GetDocumentParams = {
  filename: string;
};

export type UpdateApplicationStatusBody = {
  appId: string;
  approvalStatus: ApprovalStatusEnum;
  remarks: string;
  approverCode: string;
};

export type UpdateZoneFollowUpBody = {
  appId: string;
};

export type UploadToCusSearchBody = {
  appId: string;
  fileName: string;
};

export const fetchPersonalParticularList = async (
  params: PersonalParticularListParam,
  dispatch?: Dispatch<any>,
): Promise<RecruitGenericItem<PaginateList<PersonalParticularItem>>> => {
  let queryPath = `${ADMIN_URL}/${personalParticularEndpoint}?`;
  forEach(params, (param, key) => {
    if (key === 'page') {
      queryPath += `${key}=${encodeURIComponent(param as any)}&`;
    } else {
      queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
    }
  });
  return apiClient
    .get<RecruitGenericItem<PaginateList<PersonalParticularItem>>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchPersonalParticularItem = async (
  appId: string,
  dispatch?: Dispatch<any>,
): Promise<RecruitGenericItem<PersonalParticularItem>> => {
  return apiClient
    .get<RecruitGenericItem<PersonalParticularItem>>(`${ADMIN_URL}/${personalParticularEndpoint}/${appId}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchDocument = async (
  appId: string,
  params: GetDocumentParams,
  dispatch?: Dispatch<any>,
): Promise<RecruitGenericItem<PersonalParticularDocItem>> => {
  return apiClient
    .get<RecruitGenericItem<PersonalParticularDocItem>>(
      `${ADMIN_URL}/${personalParticularEndpoint}/${appId}/document`,
      { params },
    )
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchDocumentNameList = async (
  appId: string,
  dispatch?: Dispatch<any>,
): Promise<RecruitGenericItem<string[]>> => {
  return apiClient
    .get<RecruitGenericItem<string[]>>(`${ADMIN_URL}/${personalParticularEndpoint}/${appId}/documentNameList`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const updateApplicationStatus = async (
  body: UpdateApplicationStatusBody,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .put<void>(`${ADMIN_URL}/${personalParticularEndpoint}/updateStatus`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const updateZoneFollowUp = async (body: UpdateZoneFollowUpBody, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .put<void>(`${ADMIN_URL}/${personalParticularEndpoint}/updateZoneFollowUp`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const uploadToCusSearch = async (body: UploadToCusSearchBody, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .put<void>(`${ADMIN_URL}/${personalParticularEndpoint}/uploadToCusSearch`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
