export const MANDATORY_TRAINING_BASE_PATH = '/mandatoryhistory';

export const MANDATORY_TRAINING_CATEGORY_PATH = "/category";
export const MANDATORY_TRAINING_COLLECTION_PATH = "/collection";

//  These constants map to aside menu, header menu to display title
export const MANDATORY_TRAINING_TITLE = "title.mandatory.training";
export const MANDATORY_TRAINING_CATEGORY_TITLE = "title.mandatory.training.category";
export const MANDATORY_TRAINING_COLLECTION_TITLE = "title.mandatory.training.collection";


//  These constants map to permission control, identical to permission enum defined below
export const MANDATORY_TRAINING_CATEGORY_NAMESPACE = "MANDATROY-TRAINING-CATEGORY";
export const MANDATORY_TRAINING_COLLECTION_NAMESPACE = "MANDATROY-TRAINING-COLLECTION";


export enum AdminLeadsPermissionType {
  MANDATORY_TRAINING_CATEGORY_READ = "READ_ANY_MANDATROY-TRAINING-CATEGORY",
  MANDATORY_TRAINING_CATEGORY_CREATE = "CREATE_ANY_MANDATROY-TRAINING-CATEGORY",
  MANDATORY_TRAINING_CATEGORY_UPDATE = "UPDATE_ANY_MANDATROY-TRAINING-CATEGORY",
  MANDATORY_TRAINING_COLLECTION_READ = "READ_ANY_MANDATROY-TRAINING-COLLECTION",
  MANDATORY_TRAINING_COLLECTION_CREATE = "CREATE_ANY_MANDATROY-TRAINING-COLLECTION",
  MANDATORY_TRAINING_COLLECTION_UPDATE = "UPDATE_ANY_MANDATROY-TRAINING-COLLECTION",
};

export const SelectTypes: any = {
  FIX_DATE: {
    value: '0',
    label: 'fixed.date'
  },
  // ACTICATION_MONTH: {
  //   value: '1',
  //   label: 'month'
  // },
  // ACTICATION_DATE: {
  //   value: '2',
  //   label: 'date'
  // },
  NONE: {
    value: '',
    label: 'na'
  }
}

export enum MTmode {
  CREATE = 'add',
  EDIT = 'edit',
  COPY = 'copy',
  VIEW = 'view'
}


export enum completionRuleType {
  ALL = '0',
  SPECIFIY = '1',
}; 

export enum ReminderSwitchType {
  ENABLE = "true",
  DISABLE = "false",
};

export enum ReminderFrequencyType {
  ONCE = "0",
  DAILY = "1",
  WEEKLY = "2",
  MONTHLY = "3",
};

export enum ReceiverType {
  Segment = '0',
  TargetReceiver = '1',
};


export enum PublishTimeOptions {
  Immediately = "true",
  SpecificTime = "false",
};


export const PublishStatus: { [item: string]: any } = {
  Draft: {
    label: 'component.formSelectItem.save-as-draft',
    key: '0'
  },
  Publish: {
    label: 'section.common.operation.publish',
    key: '1'
  },
  UnPublish: {
    label: 'section.common.operation.unpublish',
    key: '2'
  },
  WaitingPublish: {
    label: 'section.common.operation.waiting.publish',
    key: '3'
  },
}

export const publishingCode = 'E024';

export const publishingSameVersionCode = 'E025';