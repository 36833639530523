import React, { useCallback, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { AutoNotiTemplateEnum, NotificationTemplateItem } from 'src/app/modules/Notification/types/notification-types';
import { Routes, SPECIFIC_ROUTES } from 'src/app/common/constants';
import { AutoNotiErrorAction, AutoNotiFormAction, ErrorState } from '../../notification-config-edit.hook';

interface HookProps {
  index: number;
  formState: NotificationTemplateItem;
  errorState: ErrorState;
  formDispatch: (value: AutoNotiFormAction) => void;
  errorDispatch: (value: AutoNotiErrorAction) => void;
}

export const useNotiTemplateForm = ({ index, formState, errorState, formDispatch, errorDispatch }: HookProps) => {
  const AppRoutes =
    formState?.template === AutoNotiTemplateEnum.SLA_EXPIRY_REMINDER
      ? {
          'route.pulseleads.detail': 'LeadDetailScreen',
          'route.pulseleads.opportunity': 'SalesLandingScreen',
        }
      : { 'route.pulseleads.opportunity': 'SalesLandingScreen' };

  // redux
  // const dispatch = useDispatch();
  const [dialogState, setDialogState] = useState<{ open: boolean; id: number }>({ open: false, id: index });

  // Deep link configuration
  const [deeplink, setDeepLink] = useState<Record<string, any> | string>('');
  const isSpecificLink =
    formState?.redirectParams?.link === Routes.SPECIFIC_TRAINING_LINK ||
    formState?.redirectParams?.link === Routes.SPECIFIC_SURVEY_LINK ||
    formState?.redirectParams?.link === Routes.SPECIFIC_EVENT_LINK;

  // Custom function
  const validateSpecificLink = (id: any) => {
    const linkParamState = isSpecificLink ? (id ? false : true) : false;

    errorDispatch({
      type: 'MODIFY_ERROR',
      index: index,
      payload: { field: 'redirectParams', value: { ...errorState.redirectParams, ['params']: linkParamState } },
    });
  };
  const onSelectItem = useCallback((id: any, idName = 'id') => {
    validateSpecificLink(id);
    formDispatch({
      type: 'MODIFY_TEMPLATE',
      index: index,
      payload: { field: 'redirectParams', value: { ...formState.redirectParams, params: { [idName]: id } } },
    });
  }, []);

  useEffect(() => {
    if (formState) {
      const link = formState.redirectParams?.link ?? '';
      const params = formState.redirectParams?.params;
      const isSpecificRoute = SPECIFIC_ROUTES.includes(link);
      if (!params || isEmpty(params) || isSpecificRoute) {
        setDeepLink(link);
      } else {
        setDeepLink(
          JSON.stringify({
            link,
            params,
          }),
        );
      }
    }
  }, [formState]);

  return {
    formState,
    deeplink,
    setDeepLink,
    formDispatch,
    AppRoutes,
    errorState,
    isSpecificLink,
    onSelectItem,
    dialogState,
    setDialogState,
  };
};
