import React, { FC, useState } from 'react';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { stagingLeadPath } from '../StagingLeadRoutes';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useDataProvider } from 'src/app/common/utils';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { PaginateList } from 'src/app/common/types/common-types';
import { StagingLeadItem } from 'src/app/modules/PulseLeads/types/straight-through-types';
import { fetchStagingLeadList, StagingLeadListParam } from 'src/app/modules/PulseLeads/network/stagingLeadCrud';
import StagingLeadList from './components/StagingLeadList';

type StagingLeadListingPageProps = {} & ParamsProps;

const initialState: StagingLeadListParam = {
  campaignId: '',
  leadSource: '',
  micrositeId: '',
  leadDateFrom: '',
  leadDateTo: '',
  page: 1,
  limit: 5,
};

export const stagingLeadFilterKeys = [];

const paramsInitiator = (initialParams?: Record<string, string>): StagingLeadListParam => {
  return initialParams
    ? {
        campaignId: initialParams.campaignId || '',
        leadSource: '',
        micrositeId: '',
        leadDateFrom: '',
        leadDateTo: '',
        page: 1,
        limit: 5,
      }
    : initialState;
};

const StagingLeadListingPage: FC<StagingLeadListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const intl = useIntl();
  const history = useHistory();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const [filterState, setFilterState] = useState<StagingLeadListParam>(paramsInitiator(initialParams));
  const [stagingLeadList, setStagingLeadList] = useState<PaginateList<StagingLeadItem>>();

  const { isLoading, refreshData } = useDataProvider<PaginateList<StagingLeadItem>>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchStagingLeadList(filterState, dispatch);
      } catch (err) {
        // history.push(NOT_FOUND_ERROR_PAGE_PATH);
      }
    },
    setStagingLeadList,
    false,
  );

  return (
    <>
      <PruFilter
        title={Translation('pulseleads.campaign.filter')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'campaignId',
            initialValue: filterState.campaignId,
            displayName: Translation('pulseleads.straightThrough.stagingLead.campaignId'),
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'leadSource',
            displayName: Translation('pulseleads.straightThrough.stagingLead.leadSource'),
            choices: [
              { displayName: '', value: '' },
              {
                displayName: Translation('pulseleads.straightThrough.stagingLead.leadSource.straightThrough'),
                value: 'Pulse Lead Source',
              },
              {
                displayName: Translation('pulseleads.straightThrough.stagingLead.leadSource.marketingLeadSource'),
                value: 'Marketing Lead Source',
              },
            ],
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'micrositeId',
            displayName: Translation('pulseleads.straightThrough.stagingLead.micrositeId'),
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'leadDateFrom',
            fieldTo: 'leadDateTo',
            displayName: Translation('pulseleads.straightThrough.stagingLead.leadDate'),
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            campaignId: data.campaignId,
            leadSource: data.leadSource,
            micrositeId: data.micrositeId,
            leadDateFrom: data.leadDateFrom?.toISOString(),
            leadDateTo: data.leadDateTo?.toISOString(),
          })
        }
        fetchData={refreshData}
      />
      <StagingLeadList
        isLoading={isLoading}
        stagingLeadList={stagingLeadList}
        onRefresh={refreshData}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default StagingLeadListingPage;
