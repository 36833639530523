import { useEffect } from 'react';

export const useListenerResize = (handleResize: () => void) => {
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);
};
