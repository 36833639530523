import { Dispatch } from 'react';
import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import { EventCampaignItem } from '../types/event-campaign-types';
import { forEach } from 'lodash';
import { PaginateList } from 'src/app/common/types/common-types';

const eventCampaignEndPoint = 'event-campaign';

export type EventCampaignListParam = {
  eventCode: string;
  campaignName: string;
  page: number;
  limit: number;
};

export type CreateEventCampaignBody = {
  eventCode: string;
  campaign: string;
  product?: string;
  interestedProductPlan?: string;
  leadDetail?: string;
  createdBy: string;
  updatedBy: string;
};

export type UpdateEventCampaignBody = {
  eventCode: string;
  campaign: string;
  product?: string;
  interestedProductPlan?: string;
  leadDetail?: string;
  updatedBy: string;
};

export const fetchEventCampaignList = async (params: EventCampaignListParam, dispatch?: Dispatch<any>) => {
  let queryPath = `${ADMIN_URL}/${eventCampaignEndPoint}?`;
  forEach(params, (param, key) => {
    if (key === 'page') {
      queryPath += `${key}=${encodeURIComponent(param as any)}&`;
    } else {
      queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
    }
  });
  return apiClient
    .get<PaginateList<EventCampaignItem>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchEventCampaignItem = async (id: string, dispatch?: Dispatch<any>): Promise<EventCampaignItem> => {
  return apiClient
    .get<EventCampaignItem>(`${ADMIN_URL}/${eventCampaignEndPoint}/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchEventCampaignOptions = async (dispatch?: Dispatch<any>): Promise<any> => {
  return apiClient
    .get<any>(`${ADMIN_URL}/${eventCampaignEndPoint}/options`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createEventCampaign = async (body: CreateEventCampaignBody, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .post<void>(`${ADMIN_URL}/${eventCampaignEndPoint}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const modifyEventCampaign = async (
  id: string,
  body: UpdateEventCampaignBody,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .put<void>(`${ADMIN_URL}/${eventCampaignEndPoint}/${id}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const deleteEventCampaign = async (id: string, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .delete<void>(`${ADMIN_URL}/${eventCampaignEndPoint}/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
