import { map } from 'lodash';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { FormTemplatePublishStatus, FormTemplateStatus } from '../../../../types/form-template-types';
import { FormTemplateList } from './components/form-template-list.component';
import { useFormTemplateListingPage } from './form-template-listing-page.hook';

type FormTemplateListingPageProps = ParamsProps;

export const FormTemplateListingPage: FC<FormTemplateListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const {
    filterState,
    formTemplateList,
    isLoading,
    formTypeOptions,
    setFilterState,
    refreshData,
    onSort,
    publish,
    unpublish,
    deleteTemplate,
  } = useFormTemplateListingPage({
    initialParams,
    onChangeQueryParams,
  });

  return (
    <>
      <PruFilter
        title={Translation('form.template.filter')}
        itemDef={[
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'type',
            initialValue: filterState.formType,
            displayName: Translation('onboarding.common.formType'),
            style: { width: 200 },
            choices: [
              { displayName: 'ALL', value: '' },
              ...map(formTypeOptions, (option: string) => ({
                displayName: Translation(option),
                value: option,
              })),
            ],
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'formStatus',
            style: { width: 200 },
            initialValue: filterState.formStatus,
            displayName: Translation('onboarding.common.formStatus'),
            choices: [
              { displayName: 'ALL', value: '' },
              ...map(FormTemplateStatus, (option: string) => ({ displayName: option, value: option })),
            ],
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'publishStatus',
            style: { width: 200 },
            initialValue: filterState.publishStatus,
            displayName: Translation('onboarding.common.publishStatus'),
            choices: [
              { displayName: 'ALL', value: '' },
              ...map(FormTemplatePublishStatus, (option: string) => ({ displayName: option, value: option })),
            ],
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'from',
            fieldTo: 'to',
            initialDateFrom: filterState.validDateFrom,
            initialDateTo: filterState.validDateTo,
            displayName: Translation('validity_period'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            style: { width: 200 },
            field: 'version',
            initialValue: filterState.version,
            displayName: Translation('onboarding.common.displayVersion'),
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            formType: data.type,
            formStatus: data.formStatus.toUpperCase(),
            publishStatus: data.publishStatus.toUpperCase(),
            version: data.version,
            validDateFrom: data.from,
            validDateTo: data.to,
          })
        }
        fetchData={refreshData}
      />
      <FormTemplateList
        isLoading={isLoading}
        formTemplateList={formTemplateList}
        onRefresh={refreshData}
        publish={publish}
        unpublish={unpublish}
        deleteTemplate={deleteTemplate}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
        onSort={onSort}
      />
    </>
  );
};
