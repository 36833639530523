export const templateFileData = [
  [
    'Exam Type',
    'Exam Code',
    'Exam Name (Local Language)',
    'Exam Name (English)',
    'Exam Date',
    'Start Time',
    'End Time',
    'Exam Centre',
    'Last Registration Date',
    'Quota',
    'Minimum Quota',
    'isDeleted',
  ],
  [
    'F',
    'AAJI',
    'Exam Paper 1',
    'Exam Paper 1',
    '19/08/2023',
    '09:30',
    '15:30',
    'Jakarta Exam Centre',
    '14/08/2023',
    50,
    40,
  ],
  [
    'S',
    'AASI',
    'Exam Paper 1',
    'Exam Paper 1',
    '19/08/2023',
    '14:30',
    '15:30',
    'Jakarta Exam Centre',
    '14/08/2023',
    50,
    40,
    'Yes',
  ],
];
