import { useStyles } from './pdf-template.style';
import Cancel from '@mui/icons-material/Cancel';
import Delete from '@mui/icons-material/Delete';
import CheckCircle from '@mui/icons-material/CheckCircle';
import UploadFile from '@mui/icons-material/UploadFile';
import { useIntl } from 'react-intl';
import { downloadFileByBlodId, fileUpload, toAbsoluteUrl } from 'src/app/common/utils';
import FileUploader, { FileObj } from 'src/app/common/components/FileUploader';
import { createBlob, getBlob } from 'src/app/common/network';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { PDFFile } from '../../../../types/form-template-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { AlertType, appendAlertItem } from 'src/redux/common/commonSlice';
import { lowerCase, upperFirst } from 'lodash';

const pdfIcon = toAbsoluteUrl('/media/icon/pdf.png');

export interface PDFTemplateProps {
  index: number; // array index, use to update pdfList
  key: string;
  type: string;
  label: string;
  status: 'checked' | 'uploaded' | 'error' | 'empty';
  blobId?: string;
  fileName?: string;
  fileSize?: string;
  errorMsg?: string;
  onChange: (fileData: any, index: number) => any;
}
export function PDFTemplate(props: PDFTemplateProps) {
  const { classes } = useStyles();
  const intl = useIntl();
  const t = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  // FileUploader format
  const pdfFile = props.blobId
    ? {
        size: props.fileSize,
        id: props.blobId,
        fileName: props.fileName,
      }
    : undefined;
  const [file, setFile] = useState<any>(pdfFile);

  const handleUploadFile = async ({
    file,
    fileName,
    fileType,
    description = '',
  }: {
    file: any;
    fileName: string;
    fileType: string;
    description?: string;
  }) => {
    try {
      const createBlobRes = await createBlob(
        {
          mimeType: fileType,
          accessLevel: 'anonymous',
          module: 'material_sales',
          // module: `recruitment_template`,
        },
        dispatch,
      );
      await fileUpload(createBlobRes.url, file, () => {});
      let size = 0;
      try {
        // file-size in KB
        size = Math.round(parseInt(file.size, 10) / 1024);
      } catch (error) {}
      const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);
      const result = blobDetail[0];
      if (result) {
        const fileResult = {
          size,
          id: result.blobId,
          fileName: fileName,
          url: result.url,
          // api format to save
          fileSize: size,
          blobId: result.blobId,
          // type: fileType,
        };
        setFile(fileResult);

        props.onChange(fileResult, props.index);
        return fileResult;
      }
    } catch (err) {
    } finally {
    }
  };
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  /**
   * props.type convert
   * upperFirst(lowerCase(props.type)
   * e.g. 'APPLICANT_DECLARATION' => 'Applicant declaration'
   */
  return (
    <>
      <div className={`tw-flex tw-items-center tw-mb-2 ${classes.item} ${props.status}`}>
        <div className={`tw-w-60 tw-pr-2 ${classes.label} required`}>{upperFirst(lowerCase(props.type))}</div>
        <div className="tw-rounded-sm tw-p-4 tw-flex-1 tw-max-w-4xl ct-bg">
          <div className="tw-flex tw-items-center content-box">
            <div className="tw-flex tw-items-center tw-flex-1">
              {file ? <img src={pdfIcon} alt="pdf" className={`${classes.icon} tw-mr-3`} /> : null}
              <div className="tw-full tw-flex-1">
                <FileUploader
                  value={file}
                  upload={handleUploadFile}
                  onChange={(data) => {
                    if (!data) {
                      // delete file
                      setFile(null);
                      props.onChange(null, props.index);
                    }
                  }}
                  color="inherit"
                  showAllowText={t('click_to_upload_pdf')}
                  maxFileSize={2} // 2MB
                  showFileSize={true}
                  enabledDownload={false}
                  allowedFileTypes={['pdf']}
                />
                <div className={file ? 'tw-show error-info' : 'tw-hidden'}>{t('pdf_validation_error')}</div>
              </div>
            </div>
            <div className={file ? 'tw-show' : 'tw-hidden'}>
              <div className={classes.btns}>
                <Cancel className="tw-text-red-600 error-btn r-btn" />
                <CheckCircle className="tw-text-green-700 checked-btn r-btn" />
              </div>
            </div>
          </div>
        </div>
        <div className={file ? 'tw-visible opt-ct' : 'tw-invisible opt-ct'}>
          <div
            className="tw-text-blue-600 tw-w-15 tw-ml-5 tw-cursor-pointer download-btn"
            onClick={() => {
              downloadFileByBlodId(props.blobId || '').then(() =>
                dispatch(
                  appendAlertItem([
                    {
                      severity: AlertType.SUCCESS,
                      title: t('global.download.success'),
                      content: t('global.view.pdf'),
                    },
                  ]),
                ),
              );
            }}
          >
            {t('download')}
          </div>

          <div className="tw-text-blue-600 tw-w-15 tw-ml-5 tw-cursor-pointer show-error-btn r-btn" onClick={handleOpen}>
            {t('display_error_details')}
          </div>
        </div>
      </div>

      {/* Validation Error dialog */}
      <Dialog onClose={handleClose} fullWidth maxWidth="sm" aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Validation Error
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div className="tw-text-lg tw-mb-2">{file?.fileName}</div>
          <Typography gutterBottom>{props.errorMsg}</Typography>
        </DialogContent>
      </Dialog>
    </>
  );
}
