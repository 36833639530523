import React, { FC } from 'react';
import { Redirect, RouteComponentProps, Switch } from 'react-router-dom';
import PruRoute from '../../../../common/components/PruRoute';
import ParamsProvider from '../../../../common/components/ParamsProvider';
import PopupListingPage, { popupFilterKeys } from './List/PopupListingPage';
import { ModulePermissionProps } from 'src/app/common/module/types';

const PopupPage: FC<RouteComponentProps & ModulePermissionProps> = ({ history, location, match, enableRead, enableCreate, enableUpdate }) => {
  return (
    <Switch>
      <Redirect exact from={match.path} to={`${match.path}/list`}/>
      <PruRoute
        exact
        path={`${match.path}/list`}
        render={(props) => (
          <ParamsProvider {...props} acceptKeys={popupFilterKeys}>
            <PopupListingPage 
               enableUpdate={enableUpdate}
               enableCreate={enableCreate}
               enableRead={enableRead}
            />
          </ParamsProvider>
        )}
      />
    </Switch>
  )
};

export default PopupPage;
