import React, { FC, useState, useEffect, useMemo } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { LayoutSplashScreen } from 'src/app/layout';
import { ApiErrorResponse } from 'src/app/common/network';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { makeStyles } from 'tss-react/mui';
import { Button } from '@mui/material';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { PersonalParticularItem } from 'src/app/modules/Recruit/types/personal-particular-types';
import { fetchPersonalParticularItem } from 'src/app/modules/Recruit/network/personalParticularCrud';
import { personalParticularPath } from '../PersonalParticularRoutes';

const FIELD_CONTAINER_WIDTH = 240;

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: 5,
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  fieldContainer: {
    width: FIELD_CONTAINER_WIDTH,
    boxSizing: 'border-box',
  },
  innerFieldContainer: {
    width: FIELD_CONTAINER_WIDTH,
    boxSizing: 'border-box',
  },
  iconContainer: {
    width: 60,
    height: 60,
    overflow: 'hidden',
    borderRadius: 12,
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },
  sectionMargin: {
    marginBottom: 15,
  },
  divideMargin: {
    marginBottom: 10,
  },
  subHeader: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
  },
  textAreaRowContainer: {
    width: '100%',
    display: 'flex',
  },
  textAreaFieldContainer: {
    paddingTop: 15,
    minWidth: FIELD_CONTAINER_WIDTH,
    boxSizing: 'border-box',
  },
  textArea: {
    lineHeight: 1.5,
    minHeight: 40,
  },
  errorText: {
    fontSize: 9,
    color: '#F018A6',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));

const PersonalParticularDetailPage: FC<RouteComponentProps> = ({ history, location }) => {
  const { appId } = useParams<{ appId: string }>();

  const dispatch = useDispatch();
  const { classes: commonClasses } = useCommonStyles();
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [personalParticularItem, setPersonalParticularItem] = useState<PersonalParticularItem>();
  const { recruiterInfo, assignDesignation } = personalParticularItem || {};

  const reloadData = () => {
    if (appId) {
      setIsLoading(true);
      fetchPersonalParticularItem(appId, dispatch)
        .then((result) => {
          setPersonalParticularItem(result.result);
        })
        .catch((err: ApiErrorResponse) => {
          history.push(NOT_FOUND_ERROR_PAGE_PATH);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line
  }, [appId]);

  return (
    <>
      {isLoading ? (
        <LayoutSplashScreen />
      ) : (
        <>
          <div className={classes.container}>
            <div className={classes.headerContainer}>
              <div className={classes.rowContainer}>
                <div className={commonClasses.header}>{Translation(`recruit.personalParticular.form.title.view`)}</div>
              </div>
              <Button variant="contained" color="inherit" onClick={() => history.push(personalParticularPath)}>
                {Translation('app.button.back')}
              </Button>
            </div>

            <div className={classes.sectionMargin}>
              <div className={classes.rowContainer}>
                <div className={classes.fieldContainer}>
                  <span className={classes.field}>{Translation('recruit.common.applicationId')} :</span>
                </div>
                <div style={{ flexGrow: 1 }}>{personalParticularItem?.appId || '-'}</div>
              </div>

              <div className={classes.rowContainer}>
                <div className={classes.fieldContainer}>
                  <span className={classes.field}>{Translation('recruit.personalParticular.status')} :</span>
                </div>
                <div style={{ flexGrow: 1 }}>{personalParticularItem?.appStatus || '-'}</div>
              </div>

              <div className={classes.rowContainer}>
                <div className={classes.fieldContainer}>
                  <span className={classes.field}>{Translation('recruit.personalParticular.recruiterCode')} :</span>
                </div>
                <div style={{ flexGrow: 1 }}>{recruiterInfo?.recruiterCode || '-'}</div>
              </div>

              <div className={classes.rowContainer}>
                <div className={classes.fieldContainer}>
                  <span className={classes.field}>{Translation('recruit.personalParticular.recruiterName')} :</span>
                </div>
                <div style={{ flexGrow: 1 }}>{recruiterInfo?.recruiterName || '-'}</div>
              </div>

              <div className={classes.rowContainer}>
                <div className={classes.fieldContainer}>
                  <span className={classes.field}>{Translation('recruit.personalParticular.recruiterDsgnCode')} :</span>
                </div>
                <div style={{ flexGrow: 1 }}>{recruiterInfo?.recruiterDSGNCode || '-'}</div>
              </div>

              <div className={classes.rowContainer}>
                <div className={classes.fieldContainer}>
                  <span className={classes.field}>
                    {Translation('recruit.personalParticular.potentialRecruiterName')} :
                  </span>
                </div>
                <div style={{ flexGrow: 1 }}>{assignDesignation?.potentialRecruiterName || '-'}</div>
              </div>

              <div className={classes.rowContainer}>
                <div className={classes.fieldContainer}>
                  <span className={classes.field}>
                    {Translation('recruit.personalParticular.potentialRecruiterIdNo')} :
                  </span>
                </div>
                <div style={{ flexGrow: 1 }}>{assignDesignation?.potentialRecruiterHKID || '-'}</div>
              </div>

              <div className={classes.rowContainer}>
                <div className={classes.fieldContainer}>
                  <span className={classes.field}>{Translation('recruit.personalParticular.araAgentCode')} :</span>
                </div>
                <div style={{ flexGrow: 1 }}>{assignDesignation?.araAgentCode || '-'}</div>
              </div>

              <div className={classes.rowContainer}>
                <div className={classes.fieldContainer}>
                  <span className={classes.field}>{Translation('recruit.personalParticular.araAgentName')} :</span>
                </div>
                <div style={{ flexGrow: 1 }}>{assignDesignation?.araAgentName || '-'}</div>
              </div>

              <div className={classes.rowContainer}>
                <div className={classes.fieldContainer}>
                  <span className={classes.field}>
                    {Translation('recruit.personalParticular.designationRecommendCode')} :
                  </span>
                </div>
                <div style={{ flexGrow: 1 }}>{assignDesignation?.designationRecommendCode || '-'}</div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PersonalParticularDetailPage;
