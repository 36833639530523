import { I18nGenericData } from 'src/app/i18n';
import { AttachmentDef } from 'src/app/common/types';

export enum AgentStatusEnum {
  ACTIVE = 'active',
  TERMINATED = 'terminated',
  DEMOTED_TO_FC = 'demotedToFc',
}

export enum SecretaryStatusEnum {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum SecretaryStatusReasonEnum {
  ACTIVE = 'active',
  APPLICATION_APPROVED = 'applicationApproved',
  APPLICATION_PENDING = 'applicationPending',
  APPLICATION_REJECTED = 'applicationRejected',
  DEACTIVATED_BY_AL = 'deactivatedByAL',
  DEACTIVATED_BY_AO = 'deactivatedByAO',
  DORMANT = 'dormant',
  AGENT_LEADER_TERMINATED = 'agentLeaderTerminated',
  AGENT_LEADER_DEMOTED = 'agentLeaderDemoted',
}

export enum UpdateSecretaryActionEnum {
  ACTIVATED = 'activated',
  DEACTIVATED = 'deactivated',
  UPDATE_PERMISSIONS = 'updatePermissions',
}

export enum SecretaryApprovalEnum {
  APPROVED = 'approved',
  REJECTED = 'rejected',
}

export enum AccessActionEnum {
  ENABLE = 'enable',
  DISABLE = 'disable',
}

export type SecretaryListItem = {
  id: string;
  firstName: string;
  lastName: string;
  secretaryId: string;
  type: string;
  agentCode: string;
  agentOfficeCode: string;
  agentName: I18nGenericData<string>;
  agentStatus: AgentStatusEnum;
  createdAt: Date;
  decisionDate?: Date;
  lastLoginDate?: Date;
  updatedAt: Date;
  lastUpdatedBy?: string;
  secretaryStatus: SecretaryStatusEnum;
  statusReason: SecretaryStatusReasonEnum;
};

export type SecretaryPermissionItem = {
  _id: string;
  name: string;
  action: string;
  resource: string;
  possession: string;
  selectable: boolean;
  defaultGranted: boolean;
  child?: SecretaryPermissionItem[];
};

export type DependentPermissionItem = {
  parent?: SecretaryPermissionItem;
  permission: SecretaryPermissionItem;
  dependencies: SecretaryPermissionItem[];
};

export type DependentPermissionKeyItem = {
  parent?: string;
  permission: string;
  dependencies: string[];
};

export type SecretaryItem = {
  agentCode: string;
  type: string;
  statusReason: SecretaryStatusReasonEnum;
  secretaryId: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  identityNumber: string;
  identityDocuments: AttachmentDef[];
  profilePicture: AttachmentDef;
  contractDocuments: AttachmentDef[];
  permissions?: string[];
  fullPermissionList?: SecretaryPermissionItem[];
  secretaryStatus: SecretaryStatusEnum;
};

export type SecretaryTypeItem = {
  name: string;
  code: string;
  maxNumberOfActive: number;
};

export type SecretaryTypeRes = {
  types: SecretaryTypeItem[];
};

export type LoginPolicy = {
  userTypes: string[];
  maxLoginFailures: number;
  failureResetTime: string;
  maxDaysWithoutLogin?: number;
};

export type SecretaryAuthPolicyItem = {
  loginPolicy: LoginPolicy;
};
