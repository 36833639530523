import { IntlShape } from 'react-intl';
import { ModuleHierachy } from 'src/app/common/module/types';
import {
  RoleToDoListPermissionType,
  TODOLIST_BASE_PATH,
  TODOLIST_TASKGROUP_PATH,
  TODOLIST_SETTING_PATH,
  TODOLIST_TASKGROUP_NAMESPACE,
  TODOLIST_SETTING_NAMESPACE,
  TODOLIST_TASKGROUP_TITLE,
  TODOLIST_TASKSECTION_TITLE,
  TODOLIST_SETTING_TITLE,
  TODOLIST_TITLE,
  TODOLIST_TASKSECTION_PATH,
  TODOLIST_TASKSECTION_NAMESPACE,
} from './constants';
import { isPermitted } from 'src/app/common/utils';
import ToDoListTaskGroupPage from './pages/TaskGroup/ToDoListTaskGroupPage';
import TodoListTaskSectionPage from './pages/TaskSection/TodoListTaskSectionPage';
import TodoListSettingPage from './pages/Setting/TodoListSettingPage';

export const toDoListModuleConfig = (permissions: string[], intl: IntlShape): ModuleHierachy => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return {
    title: Translation(TODOLIST_TITLE),
    path: TODOLIST_BASE_PATH,
    icon: '/media/svg/icons/Communication/Clipboard-list.svg',
    enableRead: isPermitted(
      [
        RoleToDoListPermissionType.TODOLIST_TASKGROUP_READ,
        RoleToDoListPermissionType.TODOLIST_TASKGROUP_CREATE,
        RoleToDoListPermissionType.TODOLIST_TASKGROUP_UPDATE,
        RoleToDoListPermissionType.TODOLIST_TASKSECTION_READ,
        RoleToDoListPermissionType.TODOLIST_TASKSECTION_CREATE,
        RoleToDoListPermissionType.TODOLIST_TASKSECTION_UPDATE,
        RoleToDoListPermissionType.TODOLIST_SETTING_READ,
        RoleToDoListPermissionType.TODOLIST_SETTING_CREATE,
        RoleToDoListPermissionType.TODOLIST_SETTING_UPDATE,
      ],
      permissions,
    ),
    child: [
      {
        title: Translation(TODOLIST_TASKGROUP_TITLE),
        path: `${TODOLIST_BASE_PATH}${TODOLIST_TASKGROUP_PATH}`,
        namespace: TODOLIST_TASKGROUP_NAMESPACE,
        component: ToDoListTaskGroupPage,
        enableRead: isPermitted(
          [
            RoleToDoListPermissionType.TODOLIST_TASKGROUP_READ,
            RoleToDoListPermissionType.TODOLIST_TASKGROUP_CREATE,
            RoleToDoListPermissionType.TODOLIST_TASKGROUP_UPDATE,
          ],
          permissions,
        ),
        enableCreate: isPermitted([RoleToDoListPermissionType.TODOLIST_TASKGROUP_CREATE], permissions),
        enableUpdate: isPermitted([RoleToDoListPermissionType.TODOLIST_TASKGROUP_UPDATE], permissions),
        options: {
          enableApprove: true,
        },
      },
      {
        title: Translation(TODOLIST_TASKSECTION_TITLE),
        path: `${TODOLIST_BASE_PATH}${TODOLIST_TASKSECTION_PATH}`,
        namespace: TODOLIST_TASKSECTION_NAMESPACE,
        component: TodoListTaskSectionPage,
        enableRead: isPermitted(
          [
            RoleToDoListPermissionType.TODOLIST_TASKSECTION_READ,
            RoleToDoListPermissionType.TODOLIST_TASKSECTION_CREATE,
            RoleToDoListPermissionType.TODOLIST_TASKSECTION_UPDATE,
          ],
          permissions,
        ),
        enableCreate: isPermitted([RoleToDoListPermissionType.TODOLIST_TASKSECTION_CREATE], permissions),
        enableUpdate: isPermitted([RoleToDoListPermissionType.TODOLIST_TASKSECTION_UPDATE], permissions),
        options: {
          enableApprove: true,
        },
      },
      {
        title: Translation(TODOLIST_SETTING_TITLE),
        path: `${TODOLIST_BASE_PATH}${TODOLIST_SETTING_PATH}`,
        namespace: TODOLIST_SETTING_NAMESPACE,
        component: TodoListSettingPage,
        enableRead: isPermitted(
          [
            RoleToDoListPermissionType.TODOLIST_SETTING_READ,
            RoleToDoListPermissionType.TODOLIST_SETTING_CREATE,
            RoleToDoListPermissionType.TODOLIST_SETTING_UPDATE,
          ],
          permissions,
        ),
        enableCreate: isPermitted([RoleToDoListPermissionType.TODOLIST_SETTING_CREATE], permissions),
        enableUpdate: isPermitted([RoleToDoListPermissionType.TODOLIST_SETTING_UPDATE], permissions),
        options: {
          enableApprove: true,
        },
      },
    ],
  };
};
