import React, { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import ListItem, { hasMaxPoint } from './RuleSettingItem/listItem';
import { getRuleNameList } from '../../../api';
import { MTmode, commonSelectType } from '../../../constants';
import { commonStyles } from './style';

const RuleSettingList: FC<any> = ({ form, formMode, data }) => {
  const intl = useIntl();
  const { id } = useParams<{ id: string }>();
  const { classes: commonClasses } = useCommonStyles();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [hasRuleList, setHasRuleList] = useState<boolean>(false);
  const isCreate = formMode === MTmode.CREATE;
  const isEdit = formMode === MTmode.EDIT;
  const [groupRuleList, setGroupRuleList] = useState<any[]>([]);
  const [allRules, setAllRules] = useState<any[]>([]);

  const onAdd = () => {
    const tempData = form.getFieldValue('groupList').concat({
      hasMaxPoint: hasMaxPoint.YES,
      ruleGroup: [],
      enableGroupAction: commonSelectType.No.value,
    });
    form.setFieldsValue({ groupList: tempData });
    setGroupRuleList(tempData);
    setHasRuleList(true);
  };

  const onDelete = (index: number) => {
    const tableData = [...(form.getFieldValue('groupList') || [])];
    const selectedRuleIds = tableData[index]?.rules?.map((rule: any) => rule.id) ?? [];
    let tempRules = allRules.map((rule: any) => {
      if (selectedRuleIds.includes(rule.id)) {
        return { ...rule, checked: false };
      } else {
        return rule;
      }
    });
    setAllRules(tempRules);
    tableData.splice(index, 1);
    setGroupRuleList(tableData);
    form.setFieldsValue({ groupList: tableData });
    if (tableData.length < 1) {
      setHasRuleList(false);
    }
  };

  const swapArray = (index1: number, index2: number) => {
    let tableData = [...(form.getFieldValue('groupList') || [])];
    if (tableData) {
      [tableData[index1], tableData[index2]] = [tableData[index2], tableData[index1]];
    }
    form.setFieldsValue({ groupList: tableData });
    setGroupRuleList(tableData);
  };

  useEffect(() => {
    form.setFieldsValue({ groupList: groupRuleList });
  }, [groupRuleList]);

  useEffect(() => {
    if (data?.length > 0) {
      setGroupRuleList(data);
      setHasRuleList(true);
    }
  }, [data]);

  useEffect(() => {
    const getBasicRuleForInit = async () => {
      const res = await getRuleNameList();
      const tempData = res.map((item: any) => {
        return { ...item, checked: false };
      });
      setAllRules(tempData);
    };

    const getBasicRuleForEdit = async () => {
      const res = await getRuleNameList();
      const tempData = res.map((item: any) => {
        return { ...item, checked: false };
      });
      const groupList = data;
      let selectList: any[] = [];
      groupList?.map((item: any) => {
        return item?.rules && item.rules.map((rule: any) => selectList.push(rule.type));
      });
      const convertData = tempData.map((item: any) => {
        let index = selectList.indexOf(item.type);
        if (index !== -1) {
          return { ...item, checked: true };
        } else {
          return item;
        }
      });
      setAllRules(convertData);
    };

    if (isCreate) {
      getBasicRuleForInit();
    } else if (isEdit) {
      getBasicRuleForEdit();
    }
  }, [data]);

  return (
    <div style={commonStyles.ruleSetting}>
      <span style={commonStyles.flex}>
        <span>{Translation('pulseleads.activityPoint.ruleSettings.common.ruleSettings')}</span>
        <span style={commonStyles.colorRed}>*</span>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            onAdd();
          }}
          size="small"
          style={commonStyles.ml70}
        >
          {Translation('app.button.add')}
        </Button>
      </span>
      <div style={commonStyles.list}>
        {(groupRuleList || []).map((item, index) => {
          return (
            <ListItem
              data={item}
              key={`rule_${index}`}
              index={index}
              onDelete={onDelete}
              swapArray={swapArray}
              setAllRules={setAllRules}
              allRules={allRules}
              form={form}
              length={groupRuleList?.length ?? 0}
            />
          );
        })}
        {!hasRuleList && (
          <span className={commonClasses.errorRedText}>
            {Translation('pulseleads.activityPoint.ruleSettings.common.addRuleSettingMsg')}
          </span>
        )}
      </div>
    </div>
  );
};

export default RuleSettingList;
