import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { makeStyles } from 'tss-react/mui';
import { Button, FormControlLabel, Radio } from '@mui/material';
import { toAbsoluteUrl } from 'src/app/common/utils';

const useStyles = makeStyles()((theme) => ({
  redLine: {
    backgroundColor: '#E8192C',
    width: 4,
    flex: 1,
  },
  greyLine: {
    backgroundColor: '#EDEDEE',
    width: 4,
    flex: 1,
  },
  transparentLin: {
    backgroundColor: 'transparent',
    width: 4,
    flex: 1,
  },
}));

interface ProgressBarItemProps {
  showTopLine?: boolean;
  showBottomLine?: boolean;
  isFinished?: boolean;
  inProgress?: boolean;
  showWarning?: boolean;
}

const ProgressBarItem: React.FC<ProgressBarItemProps> = ({
  showTopLine,
  showBottomLine,
  isFinished,
  inProgress,
  showWarning,
}) => {
  const { classes } = useStyles();

  const comingImg = toAbsoluteUrl('/media/icon/progressBar/progress_future.png');
  const finishedImg = toAbsoluteUrl('/media/icon/progressBar/progress_tick.png');
  const currentImg = toAbsoluteUrl('/media/icon/progressBar/progress_current.png');
  const warningImg = toAbsoluteUrl('/media/icon/progressBar/progress_tick_warning.png');

  const showImage = inProgress ? currentImg : isFinished ? finishedImg : showWarning ? warningImg : comingImg;
  return (
    <>
      <div
        style={{ display: 'flex', flexDirection: 'column', justifyItems: 'center', alignItems: 'center', height: 55 }}
      >
        <div style={{ position: 'absolute', height: 55, display: 'flex', alignItems: 'center' }}>
          <img style={{ height: '16px', width: '16px', marginLeft: '1px' }} alt="bulletpoint" src={showImage} />
        </div>
      </div>
    </>
  );
};

export default ProgressBarItem;
