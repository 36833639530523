import React, { useCallback, useEffect, useState } from 'react';
import { useRequest } from 'ahooks';
import { NotificationTemplateListItem } from '../../../types/notification-types';
import { fetchNotificationTemplateList } from '../../../redux/notificationCrud';
import { NOTIFICATION_AUTO_CONFIG_PATH, NOTIFICATION_AUTO_PATH, NOTIFICATION_BASE_PATH } from '../../../constants';

interface HookProps {
  id: number;
  history?: any;
}

export const useNotificationConfigList = ({ history }: HookProps) => {
  const CONFIG_PAGE_PATH = NOTIFICATION_BASE_PATH + NOTIFICATION_AUTO_PATH + NOTIFICATION_AUTO_CONFIG_PATH;

  const [templateList, setTemplateList] = useState<NotificationTemplateListItem>({});

  // useRequest to wrap api request.
  // DO NOT use useDataProvider except for <PruFilter />
  const { data, error, loading, run, runAsync } = useRequest(() => fetchNotificationTemplateList(), { manual: true });

  useEffect(() => {
    // manual call api request
    run();
  }, []);

  useEffect(() => {
    if (data) {
      setTemplateList(data);
    }
  }, [data]);

  const onEdit = useCallback((template: string) => {
    history.push(`${CONFIG_PAGE_PATH}/edit/${template}`);
  }, []);

  return {
    loading,
    data,
    run,
    onEdit,
    templateList,
  };
};
