import React, { FC, useReducer, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import {
  Button,
  TextField,
  FormControl,
  FormHelperText,
  Select,
  MenuItem,
  IconButton,
} from '@mui/material';
import { AddCircle, RemoveCircle } from '@mui/icons-material';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { ErrorFieldType, useErrorHandler } from 'src/app/common/utils';
import { MANDATORY_FIELD_ERROR_TEXT } from 'src/app/common/constants';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import {
  CampaignFeedbackFormMode,
  CampaignFeedbackGroupItem,
} from 'src/app/modules/PulseLeads/types/campaign-feedback-types';
import { CampaignDropdownList, CampaignDropdownType } from 'src/app/modules/PulseLeads/types/campaign-types';
import { campaignFeedbackPath } from '../../CampaignFeedbackRoutes';
import {
  createCampaignFeedbackGroup,
  modifyCampaignFeedbackGroup,
  CampaignFeedbackGroupBody,
} from 'src/app/modules/PulseLeads/network/campaignFeedbackCrud';

type CampaignFeedbackGroupFormProps = {
  formMode: CampaignFeedbackFormMode;
  campaignFeedbackGroupItem?: CampaignFeedbackGroupItem;
  feedbackDropdownList?: CampaignDropdownList;
};

const FIELD_CONTAINER_WIDTH = 240;

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  fieldContainer: {
    width: FIELD_CONTAINER_WIDTH,
    boxSizing: 'border-box',
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },
  sectionMargin: {
    marginBottom: 15,
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  iconButton: {
    padding: 9,
  },
  icon: {
    width: '1.2em',
    height: '1.2em',
  },
}));

type CampaignFeedbackGroupFormState = {
  name?: string;
  feedbacks: string[];
};

const initialState: CampaignFeedbackGroupFormState = {
  name: undefined,
  feedbacks: [''],
};

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof CampaignFeedbackGroupFormState;
    value: any;
  };
};

type CampaignFeedbackGroupFormAction = ModifyFieldAction;

const formReducer = (
  state: CampaignFeedbackGroupFormState,
  action: CampaignFeedbackGroupFormAction,
): CampaignFeedbackGroupFormState => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    default:
      return state;
  }
};

const detailToStateConvertor = (
  campaignFeedbackGroupItem?: CampaignFeedbackGroupItem,
): CampaignFeedbackGroupFormState => {
  return campaignFeedbackGroupItem
    ? {
        name: campaignFeedbackGroupItem.name,
        feedbacks: campaignFeedbackGroupItem.feedbacks as string[],
      }
    : initialState;
};

const CampaignFeedbackGroupForm: FC<CampaignFeedbackGroupFormProps> = ({
  formMode,
  campaignFeedbackGroupItem,
  feedbackDropdownList,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { classes: commonClasses } = useCommonStyles();
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const [formState, formDispatch] = useReducer(formReducer, detailToStateConvertor(campaignFeedbackGroupItem));
  const [feedbackErrorState, setFeedbackErrorState] = useState<boolean[]>(
    campaignFeedbackGroupItem ? (campaignFeedbackGroupItem.feedbacks as string[]).map((item) => item === '') : [false],
  );

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler } = useErrorHandler(formState, [
    {
      name: 'name',
      fieldType: ErrorFieldType.MANDATORY,
    },
  ]);

  const onValidateFeedback = () => {
    const newFeedbackErrorState = formState.feedbacks.map((item) => item === '');
    setFeedbackErrorState(newFeedbackErrorState);
    return {
      hasError: newFeedbackErrorState.includes(true),
    };
  };

  const onSubmit = async () => {
    const { hasError } = onSubmitErrorValidator();
    const feedbackValidator = onValidateFeedback();
    if (!hasError && !feedbackValidator.hasError) {
      if (formMode === CampaignFeedbackFormMode.CREATE) {
        const body: CampaignFeedbackGroupBody = {
          name: formState.name || '',
          feedbacks: formState.feedbacks || [],
        };
        try {
          await createCampaignFeedbackGroup(body, dispatch);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: 'Feedback Group saved successfully',
              },
            ]),
          );
          history.push(campaignFeedbackPath);
        } catch (err) {}
      } else if (campaignFeedbackGroupItem) {
        const body: CampaignFeedbackGroupBody = {
          name: formState.name || '',
          feedbacks: formState.feedbacks || [],
        };
        try {
          await modifyCampaignFeedbackGroup(campaignFeedbackGroupItem._id, body, dispatch);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: `Feedback Group updated successfully`,
              },
            ]),
          );
          history.push(campaignFeedbackPath);
        } catch (err) {}
      }
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <div className={classes.rowContainer}>
          <div className={commonClasses.header}>
            {Translation(
              `pulseleads.campaignFeedbackGroup.form.title.${
                formMode === CampaignFeedbackFormMode.CREATE ? 'create' : 'edit'
              }`,
            )}
          </div>
        </div>
        <Button variant="contained" color="inherit" onClick={() => history.push(campaignFeedbackPath)}>
          {Translation('app.button.back')}
        </Button>
      </div>

      <div className={classes.sectionMargin}>
        <div className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>
              {Translation('pulseleads.campaignFeedback.common.name')}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <div style={{ flexGrow: 1 }} className={classes.rowContainer}>
            <TextField
              style={{ width: 250, marginRight: 10 }}
              error={errorState.mandatory.name}
              margin="dense"
              variant="outlined"
              helperText={errorState.mandatory.name && MANDATORY_FIELD_ERROR_TEXT}
              value={formState.name}
              onChange={(e) => {
                onDismissErrorHandler('name', e.target.value);
                formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'name', value: e.target.value } });
              }}
            />
          </div>
        </div>

        {formState.feedbacks.map((feedback, index) => (
          <div className={classes.rowContainer}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>
                {Translation('pulseleads.campaignFeedback.common.feedback')} {index + 1}
                <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <FormControl margin="dense" variant="outlined" error={feedbackErrorState[index]}>
              <Select
                style={{ minWidth: 350 }}
                value={feedback || ''}
                onChange={(e) => {
                  const newFeedbacks = [...formState.feedbacks];
                  newFeedbacks[index] = e.target.value as string;
                  formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'feedbacks', value: newFeedbacks } });

                  const newFeedbackErrorState = [...feedbackErrorState];
                  newFeedbackErrorState[index] = false;
                  setFeedbackErrorState(newFeedbackErrorState);
                }}
              >
                {feedbackDropdownList &&
                  feedbackDropdownList[CampaignDropdownType.FEEDBACK] &&
                  feedbackDropdownList[CampaignDropdownType.FEEDBACK].map((dropdownItem) => (
                    <MenuItem key={dropdownItem.key} value={dropdownItem.key}>
                      {dropdownItem.value}
                    </MenuItem>
                  ))}
              </Select>
              {feedbackErrorState[index] && <FormHelperText>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>}
            </FormControl>
            {index === 0 ? (
              <IconButton
                className={classes.iconButton}
                onClick={() => {
                  formDispatch({
                    type: 'MODIFY_FIELD',
                    payload: { field: 'feedbacks', value: [...formState.feedbacks, ''] },
                  });
                  setFeedbackErrorState([...feedbackErrorState, false]);
                }}
              >
                <AddCircle color="secondary" className={classes.icon} />
              </IconButton>
            ) : (
              <IconButton
                className={classes.iconButton}
                onClick={() => {
                  const newFeedbacks = [...formState.feedbacks];
                  newFeedbacks.splice(index, 1);
                  formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'feedbacks', value: newFeedbacks } });

                  const newFeedbackErrorState = [...feedbackErrorState];
                  newFeedbackErrorState.splice(index, 1);
                  setFeedbackErrorState(newFeedbackErrorState);
                }}
              >
                <RemoveCircle color="secondary" className={classes.icon} />
              </IconButton>
            )}
          </div>
        ))}
      </div>

      <div className={classes.footerContainer}>
        <Button variant="contained" color="secondary" onClick={onSubmit}>
          {Translation('app.button.submit')}
        </Button>
      </div>
    </div>
  );
};

export default CampaignFeedbackGroupForm;
