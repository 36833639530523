import { Dispatch } from 'react';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { getQueryPath } from 'src/app/common/utils';
import { PaginateList } from 'src/app/common/types';
import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import { RegistrationTypeEnum, ParticipantOverview, ParticipantItem, ParticipantDetailItem } from '../types';
import { eventEndpoint, participantEndpoint } from './constants';

export type ParticipantListParam = {
  registrationType: RegistrationTypeEnum;
  firstName: string;
  lastName: string;
  role: string;
  referer: string;
  contact: string[];
  regStartDate: Date | null;
  regEndDate: Date | null;
  qrCode: string;
  requestAttendanceStatus: string;
  sessionId?: string;
  page: number;
  limit: number;
};

export const fetchParticipantOverview = async (
  eventId: string,
  dispatch?: Dispatch<any>,
): Promise<ParticipantOverview> => {
  return apiClient
    .get<ParticipantOverview>(`${ADMIN_URL}/${eventEndpoint}/${eventId}/${participantEndpoint}/overview`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchParticipantList = async (
  eventId: string,
  params: ParticipantListParam,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<ParticipantItem>> => {
  const queryPath = getQueryPath(`${ADMIN_URL}/${eventEndpoint}/${eventId}/${participantEndpoint}?`, params, true);
  return apiClient
    .get<PaginateList<ParticipantItem>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchParticipantDetailItem = async (
  eventId: string,
  id: string,
  dispatch?: Dispatch<any>,
): Promise<ParticipantDetailItem> => {
  return apiClient
    .get<ParticipantDetailItem>(`${ADMIN_URL}/${eventEndpoint}/${eventId}/${participantEndpoint}/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const exportParticipantList = async (
  eventId: string,
  params: ParticipantListParam,
  dispatch?: Dispatch<any>,
) => {
  const queryPath = getQueryPath(
    `${ADMIN_URL}/${eventEndpoint}/${eventId}/${participantEndpoint}/download?`,
    params,
    true,
  );
  return apiClient
    .get(queryPath, { responseType: 'blob' })
    .then((response) => {
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(blob, `Participant_with_${params.registrationType}_${moment().format('YYYYMMDDHHmm')}.xlsx`);
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const exportRegistrationFormResult = async (
  eventId: string,
  params: ParticipantListParam,
  eventName = '',
  dispatch?: Dispatch<any>,
) => {
  const queryPath = getQueryPath(
    `${ADMIN_URL}/${eventEndpoint}/${eventId}/${participantEndpoint}/form-result?`,
    params,
    true,
  );
  return apiClient
    .get(queryPath, { responseType: 'blob' })
    .then((response) => {
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(blob, `${eventName}_registration_form_result_${moment().format('YYYYMMDDHHmm')}.xlsx`);
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const deleteParticipantItem = async (eventId: string, id: string, dispatch?: Dispatch<any>) => {
  return apiClient
    .delete<void>(`${ADMIN_URL}/${eventEndpoint}/${eventId}/${participantEndpoint}/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
