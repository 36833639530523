import { Dispatch } from 'react';
import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import { AgentReferralLink } from '../type/types';

const agentReferralLinkEndpoint = 'agent-referral-link';

export const updateARLConfig = async (
  id: string,
  data: any,
  dispatch?: Dispatch<any>,
): Promise<AgentReferralLink | void> => {
  return apiClient
    .put<void>(`${ADMIN_URL}/${agentReferralLinkEndpoint}/${id}`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const getARLConfig = async (id: string, dispatch?: Dispatch<any>): Promise<AgentReferralLink | void> => {
  return apiClient
    .get<void>(`${ADMIN_URL}/${agentReferralLinkEndpoint}/${id}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const getARLConfigs = async (dispatch?: Dispatch<any>): Promise<AgentReferralLink[] | void> => {
  return apiClient
    .get<void>(`${ADMIN_URL}/${agentReferralLinkEndpoint}/configs`, {
      params: {
        withPublishDetail: true, //get full ARL information
      },
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
