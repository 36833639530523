import React, { FC, useEffect, useState } from 'react';
import { ParamsProps } from '../../../../../common/components/ParamsProvider';
import {
  createNewPopup,
  fetchPopupItem,
  fetchPopupList,
  modifyPopup,
  publishPopup,
  unpublishPopup,
} from '../../../network/popupCrud';
import { useDataProvider, useJWT } from 'src/app/common/utils';
import {
  PopupEditItem,
  PopupFormState,
  PopupItem,
  PopupPaginateList,
  PopupPublishedMode,
  PopupValidMode,
  CanBeShared,
  IsPublish,
  OpenByType,
  PopupListParam,
  RedirectType,
} from '../../../types/operation-type';
import { useDispatch } from 'react-redux';
import PopupList from './components/PopupList';
import PruFilter, { PruFilterItemType } from '../../../../../common/components/PruTable/PruFilter';
import { useIntl } from 'react-intl';
import { Dialog, DialogTitle } from '@mui/material';
import AddForm from './components/Create';
import { appendAlertItem, AlertType } from '../../../../../../redux/common/commonSlice';
import { forEach } from 'lodash';
import { MAX_POPUP_PUBLISHED_LIMIT } from '../../../constants';
import PruDialog from '../../../../../common/components/PruDialog';
import { ModulePermissionProps } from 'src/app/common/module/types';
import { useLang } from 'src/app/i18n';
import { regionLocale } from 'src/app/i18n';
type PopupListingPageProps = {} & ParamsProps & ModulePermissionProps;

const initialState: PopupListParam = {
  pageSize: 20,
  page: 1,
  name: '',
  isPublish: '',
  isValid: '',
};

const paramsInitiator = (initialParams?: Record<string, string>): PopupListParam => {
  return initialParams
    ? {
        page: 1,
        pageSize: 20,
        name: initialParams.name || '',
        isPublish: initialParams.isPublish || '',
        isValid: initialParams.isValid || '',
      }
    : initialState;
};

export const popupFilterKeys = ['name', 'isPublish', 'isValid'];

const DEDAULT_FORM_DATA: PopupFormState = {
  location: 'Homepage',
  linkType: '',
  linkTo: '',
  openBy: '',
  canShared: '',
  frequency: 'once',
  effectiveRange: ['', ''],
  lastUpdatedBy: '',
  status: '',
  isPublish: 'N',
};

const PopupListingPage: FC<PopupListingPageProps> = ({
  initialParams,
  onChangeQueryParams,
  enableUpdate,
  enableCreate,
}) => {
  const locale = useLang();
  const dispatch = useDispatch();
  const regionLocales = regionLocale;
  const intl = useIntl();
  const jwt = useJWT() || {};
  const Translation = (id: string) => intl.formatMessage({ id });
  const TranslationWithVariable = (key: string, count: number | string) =>
    intl.formatMessage({ id: key }, { num: count });
  const [createModalVisible, handleModalVisible] = useState<boolean>(false);
  const [isDiasbledForm, handleDisabledForm] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>('');
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<PopupFormState>(DEDAULT_FORM_DATA);
  const [totalPublished, setTotalPublished] = useState<number>(0);
  const [publishedList, setPublishedList] = useState<PopupItem[]>();

  const [filterState, setFilterState] = useState<PopupListParam>(paramsInitiator(initialParams));
  const [sortKey, setSortKey] = useState<{ key: string; value?: string }[]>([]);

  const [popupList, setPopupList] = useState<PopupPaginateList>();
  const [openWarning, handleOpenWarning] = useState<boolean>(false);

  const [submitLocalizationsArray, setsubmitLocalizationsArray] = useState<string[]>([]);

  const filterOptions = {
    publishStatus: [
      { displayName: Translation('component.status.all'), value: PopupPublishedMode.ALL },
      { displayName: Translation('component.status.published'), value: PopupPublishedMode.PUBLISHED },
      { displayName: Translation('component.status.unpublished'), value: PopupPublishedMode.UNPUBLISHED },
    ],
    validStatus: [
      { displayName: Translation('component.status.all'), value: PopupValidMode.ALL },
      { displayName: Translation('component.status.valid'), value: PopupValidMode.VALID },
      { displayName: Translation('component.status.invalid'), value: PopupValidMode.INVALID },
    ],
  };

  const { isLoading, refreshData } = useDataProvider<PopupPaginateList>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        getTotalPublishList();
        return await fetchPopupList(locale, filterState, sortKey, dispatch);
      } catch (err) {}
    },
    setPopupList,
    false,
  );

  useEffect(() => {
    refreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortKey]);

  const getTotalPublishList = async () => {
    const publishList = await fetchPopupList(locale, { isPublish: PopupPublishedMode.PUBLISHED }, [], dispatch);
    setTotalPublished(publishList.total);
    setPublishedList(publishList.popupList);
  };

  const updateSortingKey = (sortingKey: { key: string; value?: string }) => {
    const sortingKeyArray = sortKey.filter((currentValue, index, arr) => {
      return currentValue.key !== sortingKey.key;
    });
    sortingKeyArray.unshift(sortingKey);
    setSortKey(sortingKeyArray);
  };

  const convertEditParams = async (rowData: PopupItem, lang: string) => {
    let result: any = {};
    const effectiveRange: [string, string] = [
      rowData.effectiveDate ? new Date(rowData.effectiveDate).toISOString() : '',
      rowData.expiredDate ? new Date(rowData.expiredDate).toISOString() : '',
    ];
    result = {
      ...DEDAULT_FORM_DATA,
      location: rowData.location || 'Homepage',
      linkType: rowData.linkType || '',
      linkTo: rowData.linkTo || '',
      openBy: rowData.openBy || OpenByType['pruforce-webview'],
      canShared: rowData.canShare ? CanBeShared['yes'] : CanBeShared['no'],
      frequency: rowData.frequency || 'once',
      effectiveRange: effectiveRange,
      linkParams: rowData.linkParams,
    };

    result[rowData.locale] = { ...rowData.i18nData, name: rowData.name };
    if (rowData.localizations && rowData.localizations.length >= 1) {
      for (var index = 0; index < rowData.localizations?.length; index++) {
        const itemResult = await fetchPopupItem(rowData.localizations[index].id, dispatch);
        result[itemResult.locale] = { ...itemResult.i18nData, name: itemResult.name };
        if (index === rowData.localizations?.length - 1) {
          return result;
        }
      }
    } else {
      return result;
    }
  };

  const editPopup = async (rowData: PopupItem, mode: string, lang: string) => {
    setSelectedId(rowData.id);
    const editParam = await convertEditParams(rowData, lang);
    const isPublish = rowData.publishedAt ? 'Y' : 'N';
    setInitialValues({ ...editParam, isPublish });
    if (mode === 'view') {
      handleDisabledForm(true);
    }
    handleModalVisible(true);
  };

  const onCancel = () => {
    handleDisabledForm(false);
    handleModalVisible(false);
    setSelectedId('');
    setInitialValues({ ...DEDAULT_FORM_DATA });
  };

  const submitForm = async (formData: PopupFormState) => {
    let timeRangeOverlap = false;
    const formDataEffectiveDate = (
      formData.effectiveRange[0] ? new Date(formData.effectiveRange[0]) : new Date(0)
    ).getTime();
    const formDataExpiredDate = (
      formData.effectiveRange[1] ? new Date(formData.effectiveRange[1]) : new Date('9999-12-31T23:59:59')
    ).getTime();

    publishedList?.map((item) => {
      const itemEffectiveDate = (item.effectiveDate ? new Date(item.effectiveDate) : new Date(0)).getTime();
      const itemExpiredDate = (
        item.expiredDate ? new Date(item.expiredDate) : new Date('9999-12-31T23:59:59')
      ).getTime();

      if (
        formData.isPublish === IsPublish['save-publish'] &&
        (!selectedId || selectedId != item.id) &&
        formDataEffectiveDate <= itemExpiredDate &&
        itemEffectiveDate <= formDataExpiredDate
      ) {
        handleOpenWarning(true);
        timeRangeOverlap = true;
        return;
      }
    });

    if (!timeRangeOverlap) {
      // @ts-ignore
      const localeData = formData[locale];

      const currentLocaleSubmitData: PopupEditItem = {
        openBy: formData.openBy,
        canShare: formData.canShared === 'Y' ? true : false,
        effectiveDate: formData.effectiveRange[0] ? new Date(formData.effectiveRange[0]).toISOString() : null,
        expiredDate: formData.effectiveRange[1] ? new Date(formData.effectiveRange[1]).toISOString() : null,
        i18nData: { popup: localeData.popup },
        linkTo: formData.linkType === RedirectType.no ? '' : formData.linkTo,
        linkType: formData.linkType,
        name: localeData.name || '',
        frequency: formData.frequency,
        location: formData.location,
        linkParams: formData.linkParams,
      };
      if (selectedId) {
        //Edit
        await callSubmitEditAction(selectedId, currentLocaleSubmitData, formData, true);
      } else {
        // Create
        await callSubmitCreateAction(currentLocaleSubmitData, formData, locale, true);
      }
    }
  };

  const callSubmitEditAction = async (
    selectedId: string,
    sumbitData: PopupEditItem,
    formData: PopupFormState,
    isOriginalLocale: boolean = false,
  ) => {
    try {
      const resultData = await modifyPopup(selectedId, sumbitData, dispatch);
      const localizationArray = submitLocalizationsArray;
      localizationArray.push(resultData.id as string);
      setsubmitLocalizationsArray(localizationArray);
      console.log(resultData);
      if (formData.isPublish === 'N' && resultData.publishedAt) {
        //If existing is in Publish mode, and user want to save as draft
        await unpublishPopup(resultData.id);
      }
      if (formData.isPublish === 'Y' && !resultData.publishedAt) {
        //If existing is in draft mode, and user want to save as publish
        await publishPopup(resultData.id);
      }
      if (isOriginalLocale) {
        //Call edit action for other language
        for (let i = 0; i < regionLocales.length; i++) {
          const regionLocaleItem = regionLocales[i];
          // @ts-ignore
          if (regionLocaleItem !== resultData.locale) {
            const localeItem = resultData.localizations?.filter((item) => regionLocaleItem === item.locale)[0];
            //@ts-ignore
            const localeData = formData[regionLocaleItem];
            const newLocalSubmitData = {
              ...sumbitData,
              name: localeData.name,
              localizations: localizationArray,
              i18nData: { popup: localeData.popup },
            };
            if (localeItem) {
              await callSubmitEditAction(localeItem.id, newLocalSubmitData, formData, false);
            } else {
              await callSubmitCreateAction(newLocalSubmitData, formData, regionLocaleItem, false, resultData);
            }
          }
        }
        submitSuccess();
      }
    } catch (err) {
      submitFail();
    }
  };

  const callSubmitCreateAction = async (
    sumbitData: PopupEditItem,
    formData: PopupFormState,
    locale: string,
    isOriginalLocale: boolean = false,
    prevResultData?: PopupItem,
  ) => {
    try {
      const resultData = await createNewPopup(sumbitData, locale, prevResultData ? prevResultData.id : '', dispatch);
      const localizationArray = submitLocalizationsArray;
      localizationArray.push(resultData.id as string);
      setsubmitLocalizationsArray(localizationArray);
      if (formData.isPublish === 'Y') {
        // call publish again
        await publishPopup(resultData.id, dispatch);
      }
      if (isOriginalLocale) {
        //Call create action for other language
        for (let i = 0; i < regionLocales.length; i++) {
          const localItem = regionLocales[i];
          if (localItem !== locale) {
            // @ts-ignore
            const localeData = formData[localItem];
            const newLocalSubmitData = {
              ...sumbitData,
              name: localeData.name,
              i18nData: { popup: localeData.popup },
              localizations: localizationArray,
            };
            await callSubmitCreateAction(newLocalSubmitData, formData, localItem, false, resultData);
          }
        }
        submitSuccess();
      }
    } catch (err) {
      submitFail();
    }
  };

  const submitSuccess = () => {
    dispatch(
      appendAlertItem([
        {
          severity: AlertType.SUCCESS,
          title: Translation('global.submit.success'),
          content: Translation('global.submit.success'),
        },
      ]),
    );
    handleModalVisible(false);
    refreshData();
    setFormLoading(false);
    setInitialValues({ ...DEDAULT_FORM_DATA });
    setSelectedId('');
    setsubmitLocalizationsArray([]);
  };

  const submitFail = () => {
    dispatch(
      appendAlertItem([
        {
          severity: AlertType.ERROR,
          title: Translation('global.submit.fail'),
          content: Translation('global.submit.fail'),
        },
      ]),
    );
    setFormLoading(false);
  };

  const onPublishPopupClicked = async (rowItem: PopupItem) => {
    if (totalPublished >= MAX_POPUP_PUBLISHED_LIMIT) {
      handleOpenWarning(true);
      return;
    }
    try {
      await publishPopup(rowItem.id, dispatch);
      forEach(rowItem.localizations, async (localeItem, key) => {
        await publishPopup(localeItem.id, dispatch);
      });
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Publish successfully `,
          },
        ]),
      );
      refreshData();
    } catch (err) {}
  };

  const onUnpublishPopupClicked = async (rowItem: PopupItem) => {
    try {
      await unpublishPopup(rowItem.id, dispatch);
      forEach(rowItem.localizations, async (localeItem, key) => {
        await unpublishPopup(localeItem.id, dispatch);
      });
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Unpublish successfully `,
          },
        ]),
      );
      refreshData();
    } catch (err) {}
  };

  const closeWaringDialog = () => {
    handleOpenWarning(false);
  };

  return (
    <>
      <PruFilter
        title={Translation('component.filterTitle.operation-popup')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'name',
            initialValue: filterState.name,
            displayName: Translation('component.formLabel.name'),
          },
          {
            type: PruFilterItemType.DROPDOWN,
            style: { width: 150 },
            field: 'isPublish',
            initialValue: filterState.isPublish,
            displayName: Translation('component.formLabel.publish-status'),
            choices: filterOptions.publishStatus,
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'publishDateStart',
            fieldTo: 'publishDateEnd',
            initialDateFrom: filterState.publishDateStart,
            initialDateTo: filterState.publishDateEnd,
            displayName: Translation('component.formLabel.published-time'),
          },
          {
            type: PruFilterItemType.DROPDOWN,
            style: { width: 150 },
            field: 'isValid',
            initialValue: filterState.isValid,
            displayName: Translation('component.formLabel.valid-status'),
            choices: filterOptions.validStatus,
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            name: data.name,
            isPublish: data.isPublish,
            publishDateStart: data.publishDateStart,
            publishDateEnd: data.publishDateEnd,
            isValid: data.isValid,
          })
        }
        fetchData={refreshData}
      />
      <PopupList
        isLoading={isLoading}
        popupList={popupList}
        onRefresh={refreshData}
        onSort={(dataKey) => updateSortingKey(dataKey)}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            pageSize: rowsPerPage,
          });
          refreshData();
        }}
        onAddPopup={() => handleModalVisible(true)}
        onEditPopup={(rowData, mode) => editPopup(rowData, mode, locale)}
        onPublishPopupClicked={(rowData) => onPublishPopupClicked(rowData)}
        onUnpublishPopupClicked={(rowData) => onUnpublishPopupClicked(rowData)}
        enableUpdate={enableUpdate}
        enableCreate={enableCreate}
      />

      <Dialog open={createModalVisible} onClose={isDiasbledForm ? onCancel : () => {}} fullWidth maxWidth="md">
        <DialogTitle>
          {isDiasbledForm
            ? Translation('global.view.text')
            : selectedId
              ? Translation('global.edit.text')
              : Translation('global.add.text')}
        </DialogTitle>
        {createModalVisible ? (
          <AddForm
            onSave={submitForm}
            onCancel={onCancel}
            confirmLoading={formLoading}
            initialValues={initialValues}
            disabled={isDiasbledForm}
            id={selectedId}
          />
        ) : null}
      </Dialog>

      <PruDialog
        open={openWarning}
        canCloseDialog={true}
        hideCancelBtn={true}
        onCancel={closeWaringDialog}
        onOk={closeWaringDialog}
      >
        {TranslationWithVariable('component.popup.reachlimit', MAX_POPUP_PUBLISHED_LIMIT)}
      </PruDialog>
    </>
  );
};

export default PopupListingPage;
