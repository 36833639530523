import { RadioGroup, FormControlLabel, Radio, Button } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getConvertedClasses } from 'src/app/common/helpers/tw-convert-helpers';
import Form from 'src/app/common/components/Form';
import { MTmode } from 'src/app/modules/MandatoryTraining/constants';
import { styles, useStyles } from '../style';
import ContentSection from './components/content-section';
import LeadDistributionSection from './components/lead-distribution-section';
import SubmissonSection from './components/submission-section';
import { activeType, productType } from 'src/app/modules/lead-microsite/types/microsite.type';
import { RootState } from 'src/redux/store';
import { Configuration } from 'src/app/modules/Config/types/config-type';
import { ModalService } from 'src/app/common/components/pru-modal/modal-service';
import { useHistory } from 'react-router-dom';
import { LEAD_MICROSITE_BASE_PATH, MICROSITE_BASE_PATH } from 'src/app/modules/lead-microsite/constants';

type MicrositeProps = {
  formMode: MTmode;
  data: any;
  originData: any;
  setData: (value: any) => void;
  onNextPage: () => void;
};

const PageOneDetail: FC<MicrositeProps> = ({ formMode, data, originData, setData, onNextPage }) => {
  const [form] = Form.useForm();
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const DEFAULT_REQUIRED_RULES = { required: true, message: Translation('component.form-required-text') };
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const config = useSelector<RootState, Configuration | undefined>((state) => state.config.data);
  const displayProductTypes = config?.productTypes ?? [];
  const history = useHistory();
  const onCancel = async () => {
    await ModalService.confirm({
      title: Translation('component.hint.cancel-warning'),
      onConfirm: () => {
        history.push(`${LEAD_MICROSITE_BASE_PATH}${MICROSITE_BASE_PATH}/list`);
      },
    });
  };

  const onNext = async () => {
    await form.validateFields();
    const allValue = form.getFieldsValue(true);
    setData(allValue);
    onNextPage();
  };

  useEffect(() => {
    if (form.getFieldsValue(true) !== data) {
      setData(form.getFieldsValue(true));
    }
  }, [form.getFieldsValue(true)]);

  return (
    <div>
      <Form form={form} initialValues={{ ...data }}>
        <div className="tw-bg-white tw-pt-6 tw-pb-6 tw-mt-6">
          <div className="tw-flex">
            <div className={classes.subHeader}>{Translation('microsite_setting')}</div>
          </div>

          <div className={classes.fieldMargin}>
            <Form.Item name="status" label={Translation('agent_referral_link_status')} rules={[DEFAULT_REQUIRED_RULES]}>
              <RadioGroup
                aria-label="status"
                name="isActive"
                style={{ ...getConvertedClasses('flex flex-row items-center'), flexDirection: 'row' }}
              >
                {Object.keys(activeType(intl)).map((key: string) => (
                  <FormControlLabel
                    key={activeType(intl)[key].text}
                    value={activeType(intl)[key].value}
                    control={<Radio />}
                    label={activeType(intl)[key].text}
                    disabled={formMode === MTmode.VIEW}
                  />
                ))}
              </RadioGroup>
            </Form.Item>
          </div>

          {displayProductTypes.length > 1 && (
            <div className={classes.fieldMargin}>
              <Form.Item
                name="productType"
                label={Translation('microsite_productType')}
                rules={[DEFAULT_REQUIRED_RULES]}
              >
                <RadioGroup
                  aria-label="productType"
                  name="productType"
                  style={{ ...getConvertedClasses('flex flex-row items-center'), flexDirection: 'row' }}
                >
                  {displayProductTypes.map((key: { id: string }) => (
                    <FormControlLabel
                      key={productType(intl)[key.id].text}
                      value={productType(intl)[key.id].value}
                      control={<Radio />}
                      label={productType(intl)[key.id].text}
                      disabled={formMode === MTmode.VIEW}
                    />
                  ))}
                </RadioGroup>
              </Form.Item>
            </div>
          )}
        </div>

        <ContentSection form={form} formMode={formMode} />

        <SubmissonSection form={form} formMode={formMode} />

        <LeadDistributionSection form={form} formMode={formMode} idList={data.campaignIdList} />

        <div className="tw-flex tw-bg-white tw-pb-6 tw-items-center tw-justify-center">
          <Button variant="contained" disabled={loading} color="inherit" onClick={onCancel}>
            {Translation('app.button.cancel')}
          </Button>

          <span className={classes.ml_24}>
            <Button variant="contained" disabled={loading} color="secondary" onClick={onNext}>
              {Translation('app.button.next')}
            </Button>
          </span>
        </div>
      </Form>
    </div>
  );
};

export default PageOneDetail;
