import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { makeStyles } from 'tss-react/mui';
import { Button } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useStyles } from 'src/app/modules/AgencyCampaign/pages/CampaignType/Form/CampaignTypeDetailPage';
import AcmCheckbox from 'src/app/modules/AgencyCampaign/common/AcmCheckbox';
import { SectionFieldItem } from 'src/app/modules/AgencyCampaign/types/campaign-type-types';

type SelectFieldFormProps = {
  initialSelectedFields: SectionFieldItem[];
  sectionKey: string;
  isRestricted: boolean;
  fieldPool: SectionFieldItem[];
  handleClose: () => void;
  handleSubmit: (rowSelected: SectionFieldItem[]) => void;
};

const useFormStyles = makeStyles()((theme) => ({
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
    border: '2px solid #FFFFFF',
    borderRadius: 12,
    marginBottom: 8,
    cursor: 'pointer',
    "&:hover": {
      backgroundColor: theme.palette.action.hover
    }
  },
  selectedRow: {
    backgroundColor: '#F4F4F4',
    borderColor: '#F0F0F0',
  },
  checkboxContainer: {
    paddingRight: 10
  },
}));

const SelectFieldForm: FC<SelectFieldFormProps> = ({ initialSelectedFields, sectionKey, isRestricted, fieldPool, handleClose, handleSubmit }) => {
  const { classes } = useStyles();
  const { classes: formClasses } = useFormStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const [rowSelected, setRowSelected] = useState<SectionFieldItem[]>([...initialSelectedFields]);

  const isApplicable = (row: SectionFieldItem) => (
    (!isRestricted && row.restrictedSection.length === 0) || (isRestricted && row.restrictedSection.includes(sectionKey))
  );

  const handleSelectRow = (row: SectionFieldItem) => {
    const foundIndex = rowSelected.findIndex(selectedItem => selectedItem.key === row.key);
    if (foundIndex === -1) {
      setRowSelected([...rowSelected, row]);
    } else {
      let newArr = [...rowSelected];
      newArr.splice(foundIndex, 1);
      setRowSelected(newArr);
    }
  }

  return (
    <>
      <div className={classes.header}>
        <span>{Translation("agencyCampaign.campaignType.section.selectField")}</span>
        <span
          className={classes.closeBtn}
          onClick={handleClose}
        >
          <Close />
        </span>
      </div>
      <div>
        {fieldPool.filter(isApplicable).map(row => {
          const initialRow = rowSelected.find(selectedItem => selectedItem.key === row.key);
          const isRowSelected = !!initialRow;
          if (isRowSelected && !initialRow?.removable) {
            return (
              <div
                className={`${formClasses.rowContainer} ${isRowSelected ? formClasses.selectedRow : ""}`}
              >
                <div className={formClasses.checkboxContainer}>
                  <AcmCheckbox disabled={true} checked={isRowSelected} />
                </div>
                <div>
                  {Translation(`agencyCampaign.field.${row.key}`)}
                </div>
              </div>
            );
          } else {
            return (
              <div
                className={`${formClasses.rowContainer} ${isRowSelected ? formClasses.selectedRow : ""}`}
                onClick={() => handleSelectRow(row)}
              >
                <div className={formClasses.checkboxContainer}>
                  <AcmCheckbox checked={isRowSelected} />
                </div>
                <div>
                  {Translation(`agencyCampaign.field.${row.key}`)}
                </div>
              </div>
            );
          }
        })}
      </div>
      <div className={classes.footerContainer}>
        <Button
          variant="contained"
          onClick={handleClose}
          style={{ marginRight: 20 }}
        >
          {Translation("app.button.cancel")}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleSubmit(rowSelected)}
        >
          {Translation("app.button.submit")}
        </Button>
      </div>
    </>
  );
};

export default SelectFieldForm;