import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import PruRoute from 'src/app/common/components/PruRoute';
import { PULSELEADS_BASE_PATH, PULSELEADS_DISTRIBUTION_RULE_PATH, PULSELEADS_RULE_PATH } from '../../../constants';
import ParamsProvider from 'src/app/common/components/ParamsProvider';
import DistributionRuleListingPage, { distributionRuleFilterKeys } from './List/DistributionRuleListingPage';
import DistributionRuleDetailPage from './Form/DistributionRuleDetailPage';

export const distributionRulePath = `${PULSELEADS_BASE_PATH}${PULSELEADS_RULE_PATH}${PULSELEADS_DISTRIBUTION_RULE_PATH}`;

const DistributionRuleRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect exact from={distributionRulePath} to={`${distributionRulePath}/list`} />
      <PruRoute exact path={`${distributionRulePath}/list`} render={(props) => (
        <ParamsProvider {...props} acceptKeys={distributionRuleFilterKeys}>
          <DistributionRuleListingPage />
        </ParamsProvider>
      )}/>
      <PruRoute exact path={`${distributionRulePath}/edit/:id`} component={DistributionRuleDetailPage}/>
      <PruRoute exact path={`${distributionRulePath}/create`} component={DistributionRuleDetailPage}/>
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  )
};

export default DistributionRuleRoutes;