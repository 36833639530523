import { FC } from 'react';
import { useIntl } from 'react-intl';
import { Button, Dialog, TextField } from '@mui/material';
import { MANDATORY_FIELD_ERROR_TEXT, EMAIL_FORMAT_ERROR_TEXT } from 'src/app/common/constants';
import { EventAssistantDialogState } from 'src/app/modules/event-v2/types';
import { useCommonFormStyles } from '../../../../common';
import { useEventAssistantStyles } from '../../event-assistant-list.style';
import { useEventAssistantDialog } from './event-assistant-dialog.hook';

type EventAssistantDialogProps = {
  eventId: string;
  dialogState: EventAssistantDialogState;
  onClose: () => void;
  refreshEventAssistantList: () => void;
};

export const EventAssistantDialog: FC<EventAssistantDialogProps> = ({
  eventId,
  dialogState,
  onClose,
  refreshEventAssistantList,
}) => {
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);
  const { classes } = useEventAssistantStyles();
  const { classes: commonFormClasses } = useCommonFormStyles();
  const { open, eventAssistantItem } = dialogState;
  const { formState, errorState, formDispatch, onDismissErrorHandler, onSubmit } = useEventAssistantDialog({
    eventId,
    eventAssistantItem,
    onClose,
    refreshEventAssistantList,
  });

  return (
    <Dialog classes={{ paper: classes.dialogPaper }} open={open}>
      <div className={classes.container}>
        <div className={classes.dialogFieldContainer}>
          <TextField
            fullWidth
            variant="standard"
            label={
              <>
                {Translation('event.form.assistant_name')}
                <span className={commonFormClasses.mandatory}>*</span>
              </>
            }
            placeholder={Translation('app.input.placeholder.please-enter')}
            InputLabelProps={{ shrink: true, className: commonFormClasses.inputLabel }}
            error={errorState.mandatory.name}
            helperText={errorState.mandatory.name && MANDATORY_FIELD_ERROR_TEXT}
            value={formState.name || ''}
            onChange={(e) => {
              onDismissErrorHandler('name', e.target.value);
              formDispatch({
                type: 'MODIFY_FIELD',
                payload: { field: 'name', value: e.target.value },
              });
            }}
          />
        </div>
        <div className={classes.dialogFieldContainer}>
          <TextField
            fullWidth
            variant="standard"
            label={
              <>
                {Translation('event.form.assistant_email_address')}
                <span className={commonFormClasses.mandatory}>*</span>
              </>
            }
            placeholder={Translation('app.input.placeholder.please-enter')}
            InputLabelProps={{ shrink: true, className: commonFormClasses.inputLabel }}
            error={errorState.immediate.emailFormat || errorState.mandatory.email}
            helperText={
              errorState.immediate.emailFormat
                ? EMAIL_FORMAT_ERROR_TEXT
                : errorState.mandatory.email && MANDATORY_FIELD_ERROR_TEXT
            }
            value={formState.email || ''}
            onChange={(e) => {
              onDismissErrorHandler('email', e.target.value);
              formDispatch({
                type: 'MODIFY_FIELD',
                payload: { field: 'email', value: e.target.value },
              });
            }}
          />
        </div>
        <div className={classes.footerContainer}>
          <Button variant="contained" color="inherit" onClick={() => onClose()}>
            {Translation('app.button.cancel')}
          </Button>
          <Button variant="contained" color="secondary" onClick={() => onSubmit()}>
            {Translation('app.button.confirm')}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
