import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { getConfig } from 'src/app/i18n/I18nConfig';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import ItemDeleteDialog from 'src/app/common/components/ItemDeleteDialog';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import { deleteAgentProfile } from 'src/app/modules/Profile/network/agentProfileCrud';

import { PaginateList } from 'src/app/common/types/common-types';
import { AwardListItem } from 'src/app/modules/Profile/types/agent-profile-types';
import { awardListPath } from '../../AwardListRoutes';
import { toTitleCase } from 'src/app/common/utils';

type AwardListProps = {
  isLoading: boolean;
  awardList?: PaginateList<AwardListItem>;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
};

type DialogState = {
  open: boolean;
  id: string;
};

const AwardList: FC<AwardListProps> = ({ isLoading, awardList, onRefresh, onChangePage }) => {
  const history = useHistory();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const lang = getConfig().selectedLang;

  const [dialogState, setDialogState] = useState<DialogState>({
    open: false,
    id: '',
  });

  const onDelete = async (id: string) => {
    try {
      await deleteAgentProfile(id, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Award deleted successfully`,
          },
        ]),
      );
    } catch (err) {}
  };

  return (
    <>
      <ItemDeleteDialog
        key={`delete-application-dialog-${dialogState.open}`}
        open={dialogState.open}
        onClose={() => setDialogState({ open: false, id: '' })}
        onRefresh={onRefresh}
        onDelete={() => onDelete(dialogState.id)}
      />
      <PruTable
        title={Translation('agentProfile.ad_agent_awards_list_header_title')}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('agentProfile.ad_add_list_button'),

            onClick: () => history.push(`${awardListPath}/addList`),
          },
        ]}
        disableBulkSelect
        operationDef={[]}
        columnDef={[
          {
            isId: true,
            hidden: true,
            keyIndex: '_id',
            displayName: '',
            // eslint-disable-next-line
            renderData: (row) => '',
          },
          {
            keyIndex: 'name',
            align: 'left',
            displayName: Translation('agentProfile.ad_agent_name_label'),
            renderData: (row) => row.agentName[lang === 'en' ? 'enUs' : 'zhHk'] || row.agentName['en'] || '-',
          },
          {
            keyIndex: 'agentCode',
            align: 'center',
            displayName: Translation('agentProfile.ad_agent_code_label'),
            renderData: (row) => row.agentCode || '-',
          },
          {
            keyIndex: 'awardNameLocal',
            align: 'left',
            displayName: Translation('agentProfile.ad_award_local_label'),
            renderData: (row) => row.awardName?.zhHk || '-',
          },
          {
            keyIndex: 'awardNameEn',
            align: 'left',
            displayName: Translation('agentProfile.ad_award_en_label'),
            renderData: (row) => row.awardName?.enUs || '-',
          },
          {
            keyIndex: 'awardCode',
            align: 'center',
            displayName: Translation('agentProfile.ad_award_code_label'),
            renderData: (row) => row.awardCode || '-',
          },

          {
            keyIndex: 'awardYear',
            align: 'center',
            displayName: Translation('agentProfile.ad_award_year_label'),
            renderData: (row) => row.awardYear || '-',
          },
          {
            keyIndex: 'type',
            align: 'center',
            displayName: Translation('agentProfile.ad_type_label'),
            renderData: (row) => toTitleCase(row.type) || '-',
          },
        ]}
        isLoading={isLoading}
        onRefresh={onRefresh}
        dataSource={awardList?.docs}
        totalPages={awardList?.totalPages}
        totalRecords={awardList?.totalDocs}
        onChangePage={onChangePage}
      />
    </>
  );
};

export default AwardList;
