import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import ItemDeleteDialog from 'src/app/common/components/ItemDeleteDialog';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import { deleteAmdEmail } from 'src/app/modules/Recruit/network/amdEmailCrud';
import { amdEmailPath } from '../../AmdEmailRoutes';
import { PaginateList } from 'src/app/common/types/common-types';
import { AmdEmailItem } from 'src/app/modules/Recruit/types/amd-email-types';

type AmdEmailListProps = {
  isLoading: boolean;
  amdEmailList?: PaginateList<AmdEmailItem>;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
};

type DialogState = {
  open: boolean;
  id: string;
};

const AmdEmailList: FC<AmdEmailListProps> = ({ isLoading, amdEmailList, onRefresh, onChangePage }) => {
  const history = useHistory();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const [dialogState, setDialogState] = useState<DialogState>({
    open: false,
    id: '',
  });

  const onDelete = async (id: string) => {
    try {
      await deleteAmdEmail(id, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `AMD Email deleted successfully - ${id}`,
          },
        ]),
      );
    } catch (err) {}
  };

  return (
    <>
      <ItemDeleteDialog
        key={`delete-application-dialog-${dialogState.open}`}
        open={dialogState.open}
        onClose={() => setDialogState({ open: false, id: '' })}
        onRefresh={onRefresh}
        onDelete={() => onDelete(dialogState.id)}
      />
      <PruTable
        title={Translation('recruit.amdEmail.list')}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('recruit.amdEmail.newEmail'),
            onClick: () => history.push(`${amdEmailPath}/create`),
          },
        ]}
        disableBulkSelect
        operationDef={[
          {
            title: Translation('section.common.operation.edit'),
            tooltipText: 'Edit AMD Email',
            onClick: (row) => history.push(`${amdEmailPath}/edit/${row._id}`),
          },
          {
            title: Translation('section.common.operation.delete'),
            tooltipText: 'Delete AMD Email',
            onClick: (row) => setDialogState({ open: true, id: row._id }),
          },
        ]}
        columnDef={[
          {
            isId: true,
            hidden: true,
            keyIndex: '_id',
            displayName: '',
            renderData: (row) => '',
          },
          {
            keyIndex: 'name',
            displayName: Translation('recruit.amdEmail.name'),
            renderData: (row) => row.name || '-',
          },
          {
            keyIndex: 'email',
            displayName: Translation('recruit.amdEmail.email'),
            renderData: (row) => row.email || '-',
          },
        ]}
        isLoading={isLoading}
        onRefresh={onRefresh}
        dataSource={amdEmailList?.docs}
        totalPages={amdEmailList?.totalPages}
        totalRecords={amdEmailList?.totalDocs}
        onChangePage={onChangePage}
      />
    </>
  );
};

export default AmdEmailList;
