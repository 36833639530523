import React, { FC, useState, useEffect, useMemo } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AboutMeformMode, ConfigAboutMeListItem } from 'src/app/modules/Profile/types/agent-profile-types';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import AboutMeForm from './ABoutMeForm';
import { LayoutSplashScreen } from 'src/app/layout';
import { getTemplateById } from 'src/app/modules/Profile/network/agentProfileCrud';

const AboutMeDetailPage: FC<RouteComponentProps> = ({ history }) => {
  const { id } = useParams<{ id: string }>();
  const { formMode } = useMemo(
    () => ({
      formMode: id ? AboutMeformMode.EDIT : AboutMeformMode.CREATE,
    }),
    [id],
  );
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [aboutmeItem, setAboutMeItem] = useState<ConfigAboutMeListItem>();

  const reloadData = async () => {
    try {
      if (id) {
        setIsLoading(true);
        try {
          const data = await getTemplateById(id, dispatch);

          setAboutMeItem(data);
        } catch (err) {
          history.push(NOT_FOUND_ERROR_PAGE_PATH);
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line
  }, [id]);

  return (
    <>
      {formMode === AboutMeformMode.CREATE && (
        <>
          <AboutMeForm formMode={formMode} />
        </>
      )}
      {formMode === AboutMeformMode.EDIT &&
        (isLoading || !aboutmeItem ? (
          <LayoutSplashScreen />
        ) : (
          <>
            <AboutMeForm formMode={formMode} aboutMeDetail={aboutmeItem} />
          </>
        ))}
    </>
  );
};

export default AboutMeDetailPage;
