import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { ModulePermissionProps } from '../../../../../../common/module/types';
import { MaterialDashboardExport } from './components/export/component';
import { getDateAndGenerateExcel } from './components/export/api';

export type MaterialDashboardProps = {
  blockName: string;
  blockType: string;
  moduleType: string;
} & ModulePermissionProps;

const MaterialDashboardPage: FC<MaterialDashboardProps> = () => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const history = useHistory();
  const location = useLocation();
  const { name } = (location.state || {}) as { name: string };
  const { id } = useParams<{ id: string }>();
  return (
    <>
      <div style={rowWrap}>
        <div style={rowLeft}>
          <span>{Translation('title.resource.library.performance')}</span>
        </div>
        <div style={rowRight}>
          <Button variant="contained" color="inherit" onClick={() => history.goBack()}>
            {Translation('app.button.back')}
          </Button>
        </div>
      </div>
      <MaterialDashboardExport
        onExport={getDateAndGenerateExcel}
        feature="resource"
        id={id}
        name={name}
      ></MaterialDashboardExport>
    </>
  );
};
export default MaterialDashboardPage;

const rowWrap = {
  display: 'flex',
  alignItems: 'center',
};
const rowLeft = {
  display: 'flex',
  flex: 1,
};
const rowRight = {
  display: 'flex',
  flex: 1,
  justifyContent: 'end',
};
