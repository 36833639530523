import React from 'react';
import { Redirect, RouteComponentProps, Switch } from 'react-router-dom';
import PruRoute from 'src/app/common/components/PruRoute';
import ParamsProvider from 'src/app/common/components/ParamsProvider';
import TaskGroupListPage, { toDoListTaskGroupFilterKeys } from './List/TaskGroupListPage';
import EditTaskGroupPage from './View/components/EditTaskGroup';

const ToDoListTaskGroupPage = ({ match }: RouteComponentProps) => {
  return (
    <Switch>
      <Redirect exact from={match.path} to={`${match.path}/list`} />
      <PruRoute
        exact
        path={`${match.path}/list`}
        render={(props) => (
          <ParamsProvider {...props} acceptKeys={toDoListTaskGroupFilterKeys}>
            <TaskGroupListPage />
          </ParamsProvider>
        )}
      />
      <PruRoute exact path={`${match.path}/edit/:id`} component={EditTaskGroupPage} />
    </Switch>
  );
};

export default ToDoListTaskGroupPage;
