import { Dispatch } from 'react';
import { ADMIN_URL, apiClient, apiErrorHandler } from 'src/app/common/network';
import { RecruitGenericItem } from '../types/recruit-generic-types';
import { FormTemplateItem, FormTemplateListParam } from '../types/form-template-types';
import { RecruitmentTemplate } from '../pages/template-detail/template-detail-page.type';
import { AlertType, appendAlertItem } from 'src/redux/common/commonSlice';
import { get } from 'lodash';

const formTemplateEndPoint = 'form-template';
// const ADMIN_URL = 'http://localhost:1111';

export const fetchFormTemplateList = async (filterState: any, dispatch?: Dispatch<any>): Promise<any> => {
  const url = `${ADMIN_URL}/${formTemplateEndPoint}`;
  return apiClient
    .get<RecruitGenericItem<FormTemplateListParam>>(url, {
      params: filterState,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

/**
 * get template version number, if error means it's not allow to add/edit template
 * @param formTemplateType: CONVENTIONAL,CONVENTIONAL_TAKAFUL,TAKAFUL
 * @param dispatch
 * @returns version number
 */
export const getTemplateVersion = (formTemplateType: string) => {
  // const url = 'http://192.168.19.228:8088/onboarding/templates/version';
  const url = `${ADMIN_URL}/form-template/template-version`;
  // `${ADMIN_URL}/${applicationFormSettingEndPoint}`
  return apiClient.get(url, { params: { formTemplateType: formTemplateType } }).then((response) => response.data);
};

export const saveFormTemplate = (body: RecruitmentTemplate, dispatch: Dispatch<any>, t: (key: string) => string) => {
  const url = `${ADMIN_URL}/form-template`;
  // return new Promise((res) => res({}));
  return apiClient
    .post(url, body)
    .then((response) => {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: body.saveAsDraft ? t('save_draft_done') : t('save_done'),
            content: t('save_done_desc'),
          },
        ]),
      );
      return response.data;
    })
    .catch((err) => {
      const pdfTemplates = get(err, 'errors.errors.data.pdfTemplates');
      if (pdfTemplates) {
        throw { pdfTemplates: pdfTemplates };
      } else {
        const msg = get(err, 'errors.message');
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.ERROR,
              title: t('unable_to_save'),
              content: msg || t('unable_to_save_desc'),
            },
          ]),
        );
        throw err;
      }
    });
};
export const getTemplateById = (templateId: string): Promise<RecruitmentTemplate> => {
  const url = `${ADMIN_URL}/form-template/template-detail?templateId=${templateId}`;
  // const url = `http://localhost:1111/form-template/template-detail?templateId=${templateId}`;

  return apiClient.get(url).then((response) => response.data);
};

export const publishTemplate = async (templateId: string, dispatch?: Dispatch<any>): Promise<any> => {
  const url = `${ADMIN_URL}/${formTemplateEndPoint}/${templateId}/publish`;
  return apiClient.post<RecruitGenericItem<FormTemplateItem>>(url).then((response) => response.data);
};

export const unpublishTemplate = async (templateId: string, dispatch?: Dispatch<any>): Promise<any> => {
  const url = `${ADMIN_URL}/${formTemplateEndPoint}/${templateId}/unpublish`;
  return apiClient
    .post<RecruitGenericItem<FormTemplateItem>>(url)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const deleteTemplateById = async (templateId: string, dispatch?: Dispatch<any>): Promise<any> => {
  const url = `${ADMIN_URL}/${formTemplateEndPoint}/${templateId}`;
  return apiClient
    .delete<RecruitGenericItem<FormTemplateItem>>(url)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
