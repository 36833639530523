import React, { FC } from "react";
import { Switch, Redirect, RouteComponentProps } from "react-router-dom";
import PruRoute from "../../../../common/components/PruRoute";
import { AGENCY_CAMPAIGN_BASE_PATH, AGENCY_CAMPAIGN_STAFF_CAMPAIGN_PATH } from "../../constants";
import { NOT_FOUND_ERROR_PAGE_PATH } from "../../../../common/constants";
import CampaignListPage from "./List/CampaignListingPage";
import CampaignDetailPage from "./Details/CampaignDetailPage";

export const staffCampaignPath = `${AGENCY_CAMPAIGN_BASE_PATH}${AGENCY_CAMPAIGN_STAFF_CAMPAIGN_PATH}`;

const StaffCampaignRoutes: FC<RouteComponentProps> = (props) => {
  return (
    <Switch>
        <PruRoute path={`${staffCampaignPath}/create/:typeId`} component={CampaignDetailPage} />
        <PruRoute path={`${staffCampaignPath}/detail/:id`} component={CampaignDetailPage} />
        <PruRoute path={`${staffCampaignPath}`} component={CampaignListPage} />
        <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  )
};

export default StaffCampaignRoutes;