import React, { useState, useEffect } from 'react';
import Form from 'src/app/common/components/Form';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { makeStyles } from 'tss-react/mui';
import { Button, TextField, RadioGroup, FormControlLabel, Radio, Checkbox, MenuItem } from '@mui/material';
import { useDispatch } from 'react-redux';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { isAgentIdExisting, batchUpdateAgentCode, batchUpdateLeadStatus } from '../api';
import { dontDistributeReasonOpts } from '../util';

const useStyles = makeStyles()((theme) => ({
  textArea: {
    lineHeight: 1.5,
    minHeight: 40,
  },
  backdrop: {
    position: 'fixed',
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.common.white,
    backgroundColor: `${theme.palette.common.white} !important`,
    opacity: '0.5 !important',
  },
}));

interface ManuallyAssignProp {
  setAssignFormPopupVisible: any;
  selectRows: any[];
  onRefresh: any;
}

const ManuallyAssignForm: React.FC<ManuallyAssignProp> = (props) => {
  const { setAssignFormPopupVisible, selectRows, onRefresh } = props;
  const { classes } = useStyles();
  const { classes: commonClasses } = useCommonStyles();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const DEFAULT_REQUIRED_RULES = {
    required: true,
    message: Translation('component.pulseleads.prospect.form-required-text'),
  };
  const placeEnter = Translation('app.input.placeholder.please-enter');
  const placeSelect = Translation('app.input.placeholder.please-select');
  const [loading, setLoading] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<any>({
    dontDistribute: false,
  });

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    const fetchAPI = async () => {
      try {
        setLoading(true);
        // await getCategory();
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchAPI();

    return;
    // eslint-disable-next-line
  }, []);

  const onFinish = async () => {
    const allValues = form.getFieldsValue(true);
    const ids = selectRows.map((item) => item._id);

    const saveData: any = {
      ...allValues,
    };

    setLoading(true);
    if (!saveData.dontDistribute) {
      saveData.leadRawIdList = ids;
      await batchUpdateAgentCode(saveData, dispatch);
    } else {
      saveData.ids = ids;
      saveData.leadStatus = "Don't Distribute";
      await batchUpdateLeadStatus(saveData, dispatch);
    }

    setLoading(false);

    dispatch(
      appendAlertItem([
        {
          severity: AlertType.SUCCESS,
          title: 'Success',
          content: 'Manually Assign successfully',
        },
      ]),
    );

    setAssignFormPopupVisible(false);
    onRefresh();
  };

  const agentIdValidaton = () => {
    return {
      validator: async (_: any, value: string) => {
        if (!value) {
          return Promise.reject(Translation('component.pulseleads.prospect.form-required-text'));
        }

        const res = await isAgentIdExisting(value);

        const isExisting = res.data;

        if (!isExisting) {
          return Promise.reject(new Error('This Agent ID is not existing'));
        }

        return Promise.resolve();
      },
    };
  };

  return (
    <div className={commonClasses.container}>
      <Form
        form={form}
        className={`add-form-wraper form-wraper`}
        onFinish={onFinish}
        initialValues={{ ...initialValues }}
      >
        <Form.Item>
          {(_, meta, { getFieldValue }) => {
            const isCheck = getFieldValue('dontDistribute');
            return !isCheck ? (
              <Form.Item
                name="agentCode"
                label={`${Translation('pulseleads.not.distributed.reassign.agent.id.text')}`}
                rules={[DEFAULT_REQUIRED_RULES, agentIdValidaton()]}
              >
                <TextField
                  type="number"
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  disabled={isCheck}
                  placeholder={placeEnter}
                />
              </Form.Item>
            ) : null;
          }}
        </Form.Item>

        <Form.Item name="dontDistribute">
          {(_, meta, { getFieldValue }) => {
            const isCheck = getFieldValue('dontDistribute');
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    // disabled={isDefaultOrigin}
                    checked={isCheck}
                    color="secondary"
                    onChange={(e: any) => {
                      console.log(!isCheck);
                      form.setFieldsValue({ dontDistribute: !isCheck });
                    }}
                  />
                }
                label={Translation('pulseleads.not.distributed.reassign.dont.distributed.text')}
              />
            );
          }}
        </Form.Item>

        <Form.Item>
          {(_, meta, { getFieldValue }) => {
            const isCheck = getFieldValue('dontDistribute');
            return isCheck ? (
              <Form.Item
                name="reason"
                label={Translation('pulseleads.not.distributed.reassign.dont.distributed.reason.text')}
                rules={[DEFAULT_REQUIRED_RULES]}
              >
                <TextField
                  select
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  placeholder={placeSelect}
                  className="tree-select"
                >
                  {Object.keys(dontDistributeReasonOpts).map((key) => {
                    const value = dontDistributeReasonOpts[key].value;
                    const label = dontDistributeReasonOpts[key].text;
                    return (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Form.Item>
            ) : null;
          }}
        </Form.Item>

        <Form.Item noStyle>
          <>
            <Button type="submit" style={{ float: 'right' }} variant="contained" color="secondary">
              {Translation('app.button.submit')}
            </Button>

            <Button
              variant="contained"
              color="inherit"
              style={{ float: 'right', marginRight: 15 }}
              onClick={() => {
                setAssignFormPopupVisible(false);
              }}
            >
              {Translation('app.button.cancel')}
            </Button>
          </>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ManuallyAssignForm;
