import dayjs, { Dayjs } from 'dayjs';
import { MobileDateTimePicker, MobileDateTimePickerProps } from '@mui/x-date-pickers';
import { InputAdornment, IconButton, TextFieldProps } from '@mui/material';
import { Event } from '@mui/icons-material';
import { useStyles } from './date-picker.style';

type PruDateTimePickerProps = {
  value: Date | null;
  minDate?: Date | null;
  maxDate?: Date | null;
  minTime?: Date | null;
  maxTime?: Date | null;
  minDateTime?: Date | null;
  maxDateTime?: Date | null;
  onChange?: (value: Date | null) => void;
} & Omit<
  MobileDateTimePickerProps<Dayjs>,
  'value' | 'minDate' | 'maxDate' | 'minTime' | 'maxTime' | 'minDateTime' | 'maxDateTime' | 'onChange'
>;

const PruDateTimePicker = ({
  disabled,
  value,
  minDate,
  maxDate,
  minTime,
  maxTime,
  minDateTime,
  maxDateTime,
  onChange,
  slotProps,
  ...props
}: PruDateTimePickerProps) => {
  const { classes } = useStyles();
  const textFieldProps = (slotProps?.textField as TextFieldProps) || {};
  return (
    <MobileDateTimePicker
      disabled={disabled}
      value={value ? dayjs(value) : null}
      minDate={minDate ? dayjs(minDate) : undefined}
      maxDate={maxDate ? dayjs(maxDate) : undefined}
      minTime={minTime ? dayjs(minTime) : undefined}
      maxTime={maxTime ? dayjs(maxTime) : undefined}
      minDateTime={minDateTime ? dayjs(minDateTime) : undefined}
      maxDateTime={maxDateTime ? dayjs(maxDateTime) : undefined}
      onChange={(newValue) => onChange && onChange(newValue ? newValue.toDate() : null)}
      slotProps={{
        ...slotProps,
        actionBar: slotProps?.actionBar
          ? slotProps.actionBar
          : {
              actions: ['clear', 'cancel', 'accept'],
            },
        textField: {
          ...textFieldProps,
          variant: textFieldProps.variant ? textFieldProps.variant : 'outlined',
          InputProps: {
            ...textFieldProps.InputProps,
            endAdornment: textFieldProps.InputProps?.endAdornment ? (
              textFieldProps.InputProps?.endAdornment
            ) : (
              <InputAdornment position="end">
                <IconButton
                  disabled={disabled}
                  className={
                    textFieldProps.variant === 'standard'
                      ? classes.standardDateTimePickerIcon
                      : classes.dateTimePickerIcon
                  }
                >
                  <Event />
                </IconButton>
              </InputAdornment>
            ),
          },
        },
      }}
      {...props}
    />
  );
};

export default PruDateTimePicker;
