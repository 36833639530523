import React, { FC } from "react";
import { Switch, Redirect, RouteComponentProps } from "react-router-dom";
import PruRoute from "../../../../common/components/PruRoute";
import { AGENCY_CAMPAIGN_BASE_PATH, AGENCY_CAMPAIGN_BDM_DASHBOARD_PATH } from "../../constants";
import { NOT_FOUND_ERROR_PAGE_PATH } from "../../../../common/constants";
import DashboardListPage from "./List/DashboardListingPage";


export const dashboardPath = `${AGENCY_CAMPAIGN_BASE_PATH}${AGENCY_CAMPAIGN_BDM_DASHBOARD_PATH}`;

const BDMDashboardRoutes: FC<RouteComponentProps> = (props) => {
  return (
    <Switch>
      <PruRoute path={`${dashboardPath}`} component={DashboardListPage} />
        <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  )
};

export default BDMDashboardRoutes;