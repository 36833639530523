import { Button } from '@mui/material';
import { useIntl } from 'react-intl';
import customComponents from '../index';
import {
  InsuranceSummaryErrorState,
  InsuranceSummaryState,
} from 'src/app/modules/sales-kit/pages/insurance-summary/type/insurance-summary-types';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { InsuranceSummaryStyles } from '../../style/insuranceSummaryStyle';

type InsuranceSummaryConfigProps = {
  formState: InsuranceSummaryState;

  errorState: InsuranceSummaryErrorState;
  onDismissErrorHandler: (field: keyof InsuranceSummaryErrorState['mandatory'], value: any) => void;
  onChangeImage: (image: string) => void;
  onChangeImageId: (bannerId: string) => void;
  onChangeThankyouPageContent: (content: string) => void;
  onChangeDisclaimer: (disclaimer: Array<any>) => void;
  onChangeCommentary: (commentary: any) => void;
  onSubmit: () => Promise<void>;
};

interface formList {
  title: string;
  theme: string;

  subTitle?: string[];

  fun?: (item: any) => void;
  subFun?: (item: any) => void;
}
function InsuranceSummaryConfig(props: InsuranceSummaryConfigProps) {
  const {
    formState,
    onChangeImage,
    onChangeImageId,
    onChangeThankyouPageContent,
    onChangeDisclaimer,
    onChangeCommentary,
    onSubmit,
  } = props;
  const { classes } = InsuranceSummaryStyles();

  const { classes: commonClasses } = useCommonStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  let formList: formList[] = [
    {
      title: 'component.formLabel.banner',
      theme: 'BannerModule',
      fun: onChangeImage,
      subFun: onChangeImageId,
    },
    {
      title: 'protection.review.disclaimer',
      theme: 'DisclaimerModule',

      fun: onChangeDisclaimer,
    },
    {
      title: 'protection.review.completed',
      theme: 'CompleteMessageModule',
      fun: onChangeThankyouPageContent,
    },
    {
      title: 'protection.review.commentary',
      theme: 'CommentaryModule',
      fun: onChangeCommentary,
      // subFun:onDismissErrorHandler;
    },
  ];

  function renderList() {
    return formList.map((item, index) => {
      const ItemComponent = customComponents[item.theme];

      return (
        <div key={`${item.title}+${index}`}>
          <ItemComponent
            title={item.title}
            style={classes}
            fun={item.fun}
            subFun={item?.subFun}
            formState={props.formState}
          />
        </div>
      );
    });
  }

  return (
    <>
      <div className={classes.container}>
        <div className={commonClasses.header}>Create Configuration</div>
        <div style={{ padding: '10px 10px 10px 0px' }}>{renderList()} </div>
        <div style={{ marginTop: 20 }} className={classes.footerContainer}>
          <Button style={{ marginLeft: 25 }} variant="contained" color="secondary" onClick={onSubmit}>
            {Translation('app.button.submit')}
          </Button>
        </div>
      </div>
    </>
  );
}

export default InsuranceSummaryConfig;
