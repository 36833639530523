import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { REPORT_BASE_PATH, REPORT_NEWS_PATH, REPORT_NEWS_AGENT_ACTIVITY } from '../../../../constants';
import { ReportType } from '../../../../types/report-types';
import { useIntl } from 'react-intl';
import { createNewAgentActivityReport } from '../../../../network/reportCrud';
import CreateReport from '../../../../components/CreateReport';

const AgentActivityReportPage: FC<RouteComponentProps> = ({ history, location }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  
  return (
    <CreateReport
      title={Translation("report.news.agentActivity.title")}
      reportType={ReportType.NEWS_AGENT_ACTIVITY}
      onCreate={createNewAgentActivityReport}
      onBack={() => history.push(`${REPORT_BASE_PATH}${REPORT_NEWS_PATH}${REPORT_NEWS_AGENT_ACTIVITY}`)}
      fields={[{
        name: "actionDateFrom",
        displayText: Translation("report.news.agentActivity.actionDateFrom"),
        type: "date",
        mandatory: true,
      },
      {
        name: "actionDateTo",
        displayText: Translation("report.news.agentActivity.actionDateTo"),
        type: "date",
        mandatory: true,
      }]}
    />
  )
};

export default AgentActivityReportPage;
