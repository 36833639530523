import { makeStyles } from 'tss-react/mui';

const styles = makeStyles()((theme) => ({
  pruform: {
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
    "& .prulabel": {
      fontSize: '1rem',
      marginRight: 10
    },
    "& .pruitem": {
      fontSize: '1rem',
    },
    "& .MuiGrid-root": {
      // marginBottom: 5
    },
    "& .MuiAccordionSummary-content": {
      margin:0,
    },
    "& .MuiAccordionDetail-content": {
      margin:0,
      padding:'0 16px'
    },
    "& .MuiAccordionSummary-root.Mui-focused": {
      backgroundColor: 'white'
    },
    "& .MuiInput-underline.Mui-error:after": {
      transform: 'unset',
      borderBottomColor: 'red',
      borderBottomWidth: '1px'
    },
    "& .subpruform": {
      padding:0,
      width: '100%'
    }
  },

  pruheader: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    height:'100%',
    "&:before": {
      content: "''",
      display: 'inline-block',
      height: 16,
      width: 3,
      backgroundColor: 'red',
      marginRight: 10,
      marginLeft: 10,
      borderTopLeftRadius: 2
    }
  },
  error: {
    width: '100%',
    border: 'solid 1px red',
    borderRadius:4
  },
  accordion: {
    boxShadow: 'none !important'
  }
}));

export default styles;