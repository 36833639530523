import { Dispatch } from 'react';
import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';

const followUpEndpoint = 'follow-up';

export type DownloadLeadListCsvBody = {
  page: number;
  limit: number;
};

export type FollowUpStatusBody = {
  status: string;
  submissionDate: Date;
  policyNumber?: string;
};

export type UpdateLeadFollowUpStatusBody = {
  followUp: FollowUpStatusBody[];
  updatedBy: string;
};

export const downloadLeadListCsv = async (body: DownloadLeadListCsvBody, dispatch?: Dispatch<any>): Promise<string> => {
  return apiClient
    .post<string>(`${ADMIN_URL}/${followUpEndpoint}/lead-csv`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const modifyLeadFollowUpStatus = async (
  id: string,
  body: UpdateLeadFollowUpStatusBody,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .patch<void>(`${ADMIN_URL}/${followUpEndpoint}/${id}`, body)
    .then((response) => response.data)
    .catch((err) => {
      if (!err.message.includes(id)) {
        err.message = `Lead ${id} - ${err.message}`;
      }
      throw apiErrorHandler(err, dispatch);
    });
};
