import { CSSProperties, FC } from 'react';
import { useDispatch } from 'react-redux';
import { initJson } from './survey-json';
import { FormControlLabel, Radio, RadioGroup, Select, TextField } from '@mui/material';
import { Autocomplete } from '@mui/lab';
import { useIntl } from 'react-intl';

type StylesObject = {
  [key: string]: CSSProperties;
};
const styles: StylesObject = {
  img: {
    height: 233,
    objectFit: 'cover',
  },
  paperMargin: {
    marginLeft: 24,
    marginRight: 24,
  },
  countryCodeWitdh: {
    width: 100,
    marginRight: 12,
  },
  radioGroup: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 6,
    flex: 1,
  },
  textYes: {
    borderWidth: 1,
    borderRadius: 8,
    borderColor: '#cccccc',
    flex: 1,
    marginLeft: 3,
  },
  textNo: {
    borderWidth: 1,
    borderRadius: 8,
    flex: 1,
    borderColor: '#cccccc',
    marginLeft: 3,
    marginRight: -3,
  },
  titleFont: {
    color: '#333333',
    fontSize: 20,
    fontWeight: 600,
  },
  subTitleFont: {
    color: '#666666',
    fontSize: 16,
    fontWeight: 500,
  },
  bodyFont: {
    color: '#333333',
    fontSize: 14,
    fontWeight: 400,
  },
  lableFont: {
    color: '#333333',
    fontSize: 14,
    fontWeight: 500,
  },
  tncFont: {
    color: '#333333',
    fontSize: 12,
    fontWeight: 500,
  },
  font: {
    color: '#333333',
    fontSize: 16,
    fontWeight: 400,
  },
  button: {
    marginTop: 24,
    marginBottom: 47,
  },
};
const SurveyForm: FC<any> = (data) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const formElements = data?.data?.pages?.[0]?.elements ?? initJson.pages[0].elements;
  const renderForm = () => {
    return (
      <>
        {formElements.map((e: any) => {
          if (e?.visible === false) {
            return null;
          }
          switch (e.type) {
            case 'text':
              return (
                <div key={e.name}>
                  <DisplayTextField title={e.title?.default ?? e.title} required={e?.isRequired} />
                </div>
              );
            case 'boolean':
              return (
                <div key={e.name}>
                  <DisplayRadio title={e.title?.default ?? e.title} required={e?.isRequired} />
                </div>
              );
            case 'dropdown':
              return (
                <div key={e.name}>
                  <DisplayDropDown title={e.title?.default ?? e.title} required={e?.isRequired} />
                </div>
              );
            case 'regionphone':
              return (
                <div key={e.name}>
                  <DisplayRegionPhone required={e?.isRequired} />
                </div>
              );
            default:
              return <></>;
          }
        })}
      </>
    );
  };

  const DisplayTextField: FC<any> = ({ title, required }) => {
    return (
      <div className="tw-mt-11" style={styles.lableFont}>
        <span>{title}</span>
        {required && <span className="tw-text-red-600">*</span>}
        <TextField disabled={true} margin="dense" variant="outlined" placeholder="Please enter" fullWidth />
      </div>
    );
  };

  const DisplayRadio: FC<any> = ({ title, yesText, noText, required }) => {
    return (
      <div className="tw-mt-6" style={styles.lableFont}>
        <span>{title}</span>
        {required && <span className="tw-text-red-600">*</span>}
        <RadioGroup style={styles.radioGroup}>
          <FormControlLabel
            style={styles.textYes}
            value="yes"
            control={<Radio />}
            label={<span style={styles.font}>{'Yes'}</span>}
            disabled={true}
          />
          <FormControlLabel
            style={styles.textNo}
            value="no"
            control={<Radio />}
            label={<span style={styles.font}>{'No'}</span>}
            disabled={true}
          />
        </RadioGroup>
      </div>
    );
  };

  const DisplayDropDown: FC<any> = ({ title, required }) => {
    return (
      <div className="tw-mt-6" style={styles.lableFont}>
        <span>{title}</span>
        {required && <span className="tw-text-red-600">*</span>}
        <Select disabled={true} placeholder="Please select" margin="dense" variant="outlined" fullWidth />
      </div>
    );
  };

  const DisplayRegionPhone: FC<any> = ({ required }) => {
    return (
      <div className="tw-mt-6" style={styles.lableFont}>
        <span>{Translation('pulseleads.campaign.lead.phoneNumber')}</span>
        {required && <span className="tw-text-red-600">*</span>}
        <div>
          <span className="tw-flex tw-flex-row">
            <Autocomplete
              style={styles.countryCodeWitdh}
              disabled={true}
              disablePortal
              options={['+62']}
              value={'+62'}
              getOptionLabel={(option) => option}
              renderInput={(params) => <TextField {...params} margin="dense" variant="outlined" />}
            />
            <TextField disabled={true} margin="dense" variant="outlined" placeholder="E.g.13123123123" fullWidth />
          </span>
        </div>
      </div>
    );
  };

  return <>{renderForm()}</>;
};

export default SurveyForm;
