import { Dayjs } from 'dayjs';
import { Typography } from '@mui/material';
import { PickersCalendarHeaderProps } from '@mui/x-date-pickers';

const MonthOnlyCalendarHeader = (props: PickersCalendarHeaderProps<Dayjs>) => {
  const { currentMonth } = props;

  return (
    <Typography sx={{ px: 4, margin: '16px 0px 8px', padding: '0px 24px' }}>{currentMonth.format('MMMM')}</Typography>
  );
};

export default MonthOnlyCalendarHeader;
