import React, { FC, Fragment } from 'react';
import { FeedbackResultItem } from '../../../../types/feedback-type';
import { useCommonStyles } from '../../../../../../common/styles/common-styles';
import { makeStyles } from 'tss-react/mui';
import { Button, Chip } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { FEEDBACK_BASE_PATH, FEEDBACK_RESULT_PATH } from '../../../../constants';
import { useIntl } from 'react-intl';
import { useServerLocale } from 'src/app/i18n';
import { Rating } from '@mui/lab';
import PerformanceIndicator from '../../../../../../common/components/PerformanceIndicator/PerformanceIndicator';

type FeedbackDetailProps = {
  feedbackResult: FeedbackResultItem;
};

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  fieldContainer: {
    width: 130,
    boxSizing: 'border-box',
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  sectionMargin: {
    marginBottom: 20,
  },
  divideMargin: {
    marginBottom: 10,
  },
  subHeader: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
  },
  feedbackText: {
    lineHeight: 1.8,
  },
  imgRow: {
    marginTop: 20,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
  },
  imgItem: {
    margin: 10,
    maxWidth: 500,
  },
  tag: {
    padding: '8px 10px',
    fontWeight: 600,
    fontSize: 12,
  },
}));

const FeedbackDetail: FC<FeedbackDetailProps> = ({ feedbackResult }) => {
  const locale = useServerLocale();
  const { classes } = useStyles();
  const { classes: commonClasses } = useCommonStyles();
  const history = useHistory();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <div className={classes.rowContainer}>
          <div className={commonClasses.header}>{Translation('feedbackResult.view')}</div>
        </div>
        <Button
          variant="contained"
          color="inherit"
          onClick={() => history.push(`${FEEDBACK_BASE_PATH}${FEEDBACK_RESULT_PATH}`)}
        >
          {Translation('app.button.back')}
        </Button>
      </div>

      <div className={classes.sectionMargin}>
        <div className={classes.divideMargin}>
          <div className={classes.subHeader}>{Translation('feedbackResult.common.basicInformation')}</div>
        </div>
        <div style={{ marginBottom: 10 }} className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>{Translation('feedbackResult.common.category')} :</span>
          </div>
          {feedbackResult.category[locale] || 'NaN'}
        </div>
        <div style={{ marginBottom: 10 }} className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>{Translation('feedbackResult.common.subCategory')} :</span>
          </div>
          {feedbackResult.subCategory[locale] || 'NaN'}
        </div>
        <div style={{ marginBottom: 10 }} className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>{Translation('feedbackResult.common.rating')} :</span>
          </div>
          <Rating readOnly value={feedbackResult.rating ?? 0} />
        </div>
      </div>

      <div className={classes.sectionMargin}>
        <div className={classes.divideMargin}>
          <div className={classes.subHeader}>{Translation('feedbackResult.common.feedback')}</div>
        </div>
        <div className={classes.feedbackText}>{feedbackResult.feedback || 'NaN'}</div>
        {feedbackResult.fileUrls && feedbackResult.fileUrls.length > 0 && (
          <div className={classes.imgRow}>
            {feedbackResult.fileUrls.map((url, index) => (
              <img className={classes.imgItem} src={url} alt={`Feedback Result ${index}`} />
            ))}
          </div>
        )}
      </div>

      {/* @ts-ignore */}
      {feedbackResult.sentiment?.documentSentiment?.score !== undefined && (
        <div className={classes.sectionMargin}>
          <div className={classes.divideMargin}>
            <div className={classes.subHeader}>{Translation('feedbackResult.common.sentimentalAnalysis')}</div>
          </div>
          <PerformanceIndicator
            width={400}
            height={16}
            min={-1.0}
            max={1.0}
            //@ts-ignore
            value={feedbackResult.sentiment?.documentSentiment?.score}
          />
        </div>
      )}

      {/* @ts-ignore */}
      {feedbackResult.entities?.entities !== undefined && feedbackResult.entities.entities?.length > 0 && (
        <div className={classes.sectionMargin}>
          <div className={classes.divideMargin}>
            <div className={classes.subHeader}>{Translation('feedbackResult.common.entities')}</div>
          </div>
          <div className={classes.rowContainer}>
            {/* @ts-ignore */}
            {feedbackResult.entities.entities?.map((item: any, index: number) => (
              <Fragment key={index}>
                {item && item.name && (
                  <div style={{ margin: 5 }}>
                    <Chip className={classes.tag} color={'secondary'} label={item.name} />
                  </div>
                )}
              </Fragment>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default FeedbackDetail;
