import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { FC, useState } from 'react';
import { useIntl } from 'react-intl';

type OperateDialogProps = {
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
  onRefresh: () => void;
  title: string;
  content: string;
  confirmTxt: string;
};

const OperateDialog: FC<OperateDialogProps> = ({ open, onConfirm, onClose, onRefresh, content, title, confirmTxt }) => {
  const intl = useIntl();
  const t = (id: string) => intl.formatMessage({ id });
  const [isConfirming, setIsConfirming] = useState<boolean>(false);
  const confirmItem = async () => {
    setIsConfirming(true);
    await onConfirm();
    onRefresh();
    onClose();
  };

  return (
    <Dialog open={open} onClose={() => !isConfirming && onClose()} maxWidth="xs">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <Box marginBottom="1rem">
        <DialogActions>
          <Button variant="contained" onClick={onClose}>
            {t('cancel_form_template_btn')}
          </Button>
          <Button variant="contained" color="secondary" onClick={() => confirmItem()}>
            {confirmTxt}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default OperateDialog;
