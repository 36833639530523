import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { ModulePermissionProps } from '../../../../../common/module/types';
import MaterialDashboardPage from '../../../components/Library/pages/performance';

export const SystemConfig = {
  blockName: 'component.formTitle.promotion-material',
  blockType: 'SalesMaterialDashboard',
  moduleType: 'Promotion',
};

const PerformancePage: FC<RouteComponentProps & ModulePermissionProps> = (props) => {
  return <MaterialDashboardPage {...props} {...SystemConfig} />;
};

export default PerformancePage;
