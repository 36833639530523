export const PULSELEADS_RULE_PROSPECT_MASTER_MATCHING_TITLE = "title.pulseleadsRule.prospect.master.matching";
export const PULSELEADS_MASTER_MATCHING_RULE_PATH = "/mastermatching";
export const PULSELEADS_PROSPECT_MASTER_MATCHING_RULE_NAMESPACE = "PULSELEADS-PROSPECT-MASTER-MATCHING-RULE";


export const  activeType: any = {
    'Yes': {
        value: 'true',
        text: 'Yes'
    },
    'No': {
        value: 'false',
        text: 'No'
    }
}