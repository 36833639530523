import DisclaimerModule from './DisclaimerModule';
import BannerModule from './BannerModule';
import CompleteMessageModule from './CompleteMessage';
import CommentaryModule from './CommentaryModule';
const config: { [key: string]: any } = {
  DisclaimerModule,
  BannerModule,
  CompleteMessageModule,
  CommentaryModule,
};
export default config;
