import { IntlShape } from 'react-intl';
import { RuleEnum, RuleDropdown } from 'src/app/modules/PulseLeads/enum/rule-enum';

export const renderOptions = (intl: IntlShape): RuleDropdown[] => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return [
    {
      optionId: 'nric',
      optionText: Translation('pulseleads.ruleCriteria.option.nric'),
      optionValue: [RuleEnum.NRIC],
    },
    {
      optionId: 'passportNumber',
      optionText: Translation('pulseleads.ruleCriteria.option.passportNumber'),
      optionValue: [RuleEnum.PASSPORT_NUMBER],
    },
    {
      optionId: 'phoneNumber',
      optionText: Translation('pulseleads.ruleCriteria.option.phoneNumber'),
      optionValue: [RuleEnum.PHONE_NUMBER],
    },
    {
      optionId: 'firstName+lastName+phoneNumber+birthDate',
      optionText: Translation('pulseleads.ruleCriteria.option.firstName+lastName+phoneNumber+birthDate'),
      optionValue: [RuleEnum.FIRST_NAME, RuleEnum.LAST_NAME, RuleEnum.PHONE_NUMBER, RuleEnum.BIRTH_DATE],
    },
    {
      optionId: 'firstName+lastName+phoneNumber',
      optionText: Translation('pulseleads.ruleCriteria.option.firstName+lastName+phoneNumber'),
      optionValue: [RuleEnum.FIRST_NAME, RuleEnum.LAST_NAME, RuleEnum.PHONE_NUMBER],
    },
    {
      optionId: 'firstName+phoneNumber',
      optionText: Translation('pulseleads.ruleCriteria.option.firstName+phoneNumber'),
      optionValue: [RuleEnum.FIRST_NAME, RuleEnum.PHONE_NUMBER],
    },
    {
      optionId: 'firstName+lastName+email',
      optionText: Translation('pulseleads.ruleCriteria.option.firstName+lastName+emailAddress'),
      optionValue: [RuleEnum.FIRST_NAME, RuleEnum.LAST_NAME, RuleEnum.EMAIL_ADDRESS],
    },
    {
      optionId: 'firstName+email',
      optionText: Translation('pulseleads.ruleCriteria.option.firstName+emailAddress'),
      optionValue: [RuleEnum.FIRST_NAME, RuleEnum.EMAIL_ADDRESS],
    },
    {
      optionId: 'firstName+lastName+birthDate',
      optionText: Translation('pulseleads.ruleCriteria.option.firstName+lastName+birthDate'),
      optionValue: [RuleEnum.FIRST_NAME, RuleEnum.LAST_NAME, RuleEnum.BIRTH_DATE],
    },
    {
      optionId: 'nricSuffix+birthDate',
      optionText: Translation('pulseleads.ruleCriteria.option.nricSuffix+birthDate'),
      optionValue: [RuleEnum.NRIC_SUFFIX, RuleEnum.BIRTH_DATE],
    },
  ];
};
