import { getCurrentConfig } from 'src/app/common/utils';

enum RouteType {
  Banner = 'Banner',
  AppRoutes = 'AppRoutes',
}

export function getRouteConfig(user: any, type: string = RouteType.AppRoutes): { [key: string]: string } {
  const currentConfig = getCurrentConfig(user);
  const AppRoutes = currentConfig?.AppRoutes as { [key: string]: string };
  if (AppRoutes) {
    if (type === RouteType.Banner) {
      const orderedMap = new Map();
      Object.keys(AppRoutes).forEach((key, i) => {
        orderedMap.set(key, AppRoutes[key]);
        if (key === 'route.pulseleads.campaign.mass') {
          orderedMap.set('route.pruleads.specific.campaign.link', 'SalesCampaignDetailScreen');
        }
      });
      return Object.fromEntries(orderedMap);
    }
    return AppRoutes;
  }
  return {};
}
