import { FC, Dispatch } from 'react';
import moment from 'moment';
import { map } from 'lodash';
import { useIntl } from 'react-intl';
import {
  Button,
  Dialog,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  RadioGroup,
  Radio,
} from '@mui/material';
import { useLang } from 'src/app/i18n';
import { MANDATORY_FIELD_ERROR_TEXT } from 'src/app/common/constants';
import { ErrorState, DismissErrorHandler } from 'src/app/common/utils';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { SessionEnrollmentEnum } from 'src/app/modules/event-v2/types';
import { BasicsFormState, BasicsFormAction } from 'src/app/modules/event-v2/pages/event-list/form/reducers';
import { useCommonFormStyles } from 'src/app/modules/event-v2/pages/event-list/form/common';
import { useStyles } from './session-setting.style';
import { useSessionSetting } from './session-setting.hook';
import { SessionForm } from './session-form/session-form.component';

type SessionSettingProps = {
  viewMode: boolean;
  isPublished: boolean;
  disableEdit: boolean;
  formState: BasicsFormState;
  errorState: ErrorState;
  formDispatch: Dispatch<BasicsFormAction>;
  onDismissErrorHandler: DismissErrorHandler;
};

export const SessionSetting: FC<SessionSettingProps> = ({
  viewMode,
  isPublished,
  disableEdit,
  formState,
  errorState,
  formDispatch,
  onDismissErrorHandler,
}) => {
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);
  const locale = useLang();
  const { classes } = useStyles();
  const { classes: commonFormClasses } = useCommonFormStyles();
  const { formDialogState, setFormDialogState } = useSessionSetting();

  return (
    <>
      {formDialogState.open && formDialogState.sessionNum && (
        <Dialog classes={{ paper: classes.dialogPaper }} open={formDialogState.open}>
          <SessionForm
            viewMode={viewMode}
            isPublished={isPublished}
            disableEdit={disableEdit}
            sessionNum={formDialogState.sessionNum}
            sessionItem={formDialogState.sessionItem}
            updateBasicsForm={formDispatch}
            onClose={() => setFormDialogState({ open: false })}
          />
        </Dialog>
      )}
      <div>
        <Button
          disabled={viewMode || isPublished || disableEdit}
          variant="contained"
          color="secondary"
          onClick={() => setFormDialogState({ open: true, sessionNum: formState.sessions.length + 1 })}
        >
          {Translation('event.list.detail.add_session')}
        </Button>
        <div className={`${commonFormClasses.remark} ${classes.remarkMargin}`}>
          {Translation('event.form.session.add.remark')}
        </div>
        <div className={commonFormClasses.fieldContainer}>
          <PruTable
            disableBulkSelect
            disablePagination
            disableToolbar
            renderChildren
            headerBtnDef={[]}
            operationDef={[
              {
                title: viewMode
                  ? Translation('section.common.operation.view')
                  : Translation('section.common.operation.edit'),
                tooltipText: viewMode ? 'View' : 'Edit',
                onClick: (row, index) => setFormDialogState({ open: true, sessionNum: index + 1, sessionItem: row }),
                condition: (_, parent) => !parent,
              },
              {
                title: Translation('app.button.remove'),
                tooltipText: 'Remove',
                onClick: (_, index) => formDispatch({ type: 'REMOVE_SESSION', payload: { index } }),
                condition: (_, parent) => !parent && !viewMode && !isPublished && !disableEdit,
              },
            ]}
            columnDef={[
              {
                keyIndex: 'session',
                displayName: Translation('event.list.detail.sessions'),
                renderData: (_, index, parent) =>
                  parent
                    ? `${Translation('event.form.sub_session')} ${index + 1}`
                    : `${Translation('event.form.session')} ${index + 1}`,
              },
              {
                keyIndex: 'name',
                displayName: Translation('event.list.detail.session_name'),
                renderData: (row) => row.name[locale] || '-',
              },
              {
                keyIndex: 'session_time',
                displayName: Translation('event.form.session.time'),
                renderData: (row) =>
                  `${moment(row.startTime).format('HH:mm')} - ${moment(row.endTime).format('HH:mm')}` || '-',
              },
              {
                keyIndex: 'quota',
                displayName: Translation('event.form.quota'),
                renderData: (row) => row.quota || '-',
              },
              {
                isId: true,
                hidden: true,
                keyIndex: 'key',
                childKeyIndex: 'subSessions',
                displayName: '',
                renderData: () => '',
              },
            ]}
            isLoading={false}
            dataSource={formState.sessions}
            defaultOpenedRows={formState.sessions.map((session) => session.key)}
          />
        </div>
        <div className={commonFormClasses.fieldContainer}>
          <FormControl
            disabled={viewMode || isPublished || disableEdit}
            className={commonFormClasses.radioGroupContainer}
            error={errorState.mandatory.sessionEnrollment}
          >
            <FormLabel className={commonFormClasses.radioGroupLabel}>
              {Translation('event.form.session.session_enrollment')}
              <span className={commonFormClasses.mandatory}>*</span>
            </FormLabel>
            <RadioGroup
              className={commonFormClasses.radioGroup}
              value={formState.sessionEnrollment || ''}
              onChange={(e) => {
                onDismissErrorHandler('sessionEnrollment', e.target.value);
                formDispatch({
                  type: 'MODIFY_FIELD',
                  payload: { field: 'sessionEnrollment', value: e.target.value },
                });
              }}
            >
              {map(SessionEnrollmentEnum, (option) => (
                <FormControlLabel
                  className={commonFormClasses.formControlLabel}
                  key={`session-enroll-${option}`}
                  control={<Radio />}
                  label={Translation(`event.form.session.session_enrollment.${option.toLowerCase()}`)}
                  value={option}
                />
              ))}
            </RadioGroup>
          </FormControl>
          {errorState.mandatory.sessionEnrollment && (
            <FormHelperText
              className={commonFormClasses.radioGroupMargin}
              error={errorState.mandatory.sessionEnrollment}
            >
              {MANDATORY_FIELD_ERROR_TEXT}
            </FormHelperText>
          )}
        </div>
      </div>
    </>
  );
};
