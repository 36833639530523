import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';

type SummaryReportListingPageProps = {} & ParamsProps;

export const summaryReportFilterKeys = [];

const SummaryReportListingPage: FC<SummaryReportListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const [filterState, setFilterState] = useState<any>({});

  return (
    <>
      <PruFilter
        title={Translation('insights.dashboard.filter')}
        itemDef={[]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
          })
        }
        fetchData={() => {}}
      />
    </>
  );
};

export default SummaryReportListingPage;
