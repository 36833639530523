import React, { FC, useState, useRef } from 'react';
import Form from 'src/app/common/components/Form';
import { makeStyles } from 'tss-react/mui';
import { TextField } from '@mui/material';
import { useIntl } from 'react-intl';
import { regionLocale } from 'src/app/i18n';
import { initialI18nObj } from '../../util';

const useStyles = makeStyles()((theme) => ({
  textArea: {
    lineHeight: 1.5,
    minHeight: 40,
  },
}));

const MutiLangList: FC<any> = (props: any) => {
  const intl = useIntl();
  const { classes } = useStyles();
  const Translation = (id: string) => intl.formatMessage({ id });
  const {
    listName,
    name,
    label,
    multiLine,
    subTitle,
    required = false,
    disabled = false,
    pureText = false,
    onTextChange,
    getFieldValue,
  } = props;

  return (
    <div key={`${label}`}>
      {
        <Form.List name={listName} initialValue={initialI18nObj()}>
          {(fields, a, b) => {
            return fields.map((field: any, index: any) => {
              const code = regionLocale[index];

              let displayContent = '';

              if (pureText) {
                const data = getFieldValue(b.name);
                displayContent = data[index][`${name}`];
              }

              return (
                <Form.Item
                  label={
                    subTitle
                      ? Translation(`navBar.lang.${code}`)
                      : (label || '') + ' (' + Translation(`navBar.lang.${code}`) + ')'
                  }
                  name={[index, `${name}`]}
                  rules={[
                    { required: required, message: Translation('component.pulseleads.prospect.form-required-text') },
                  ]}
                  required={required}
                >
                  {!pureText ? (
                    <TextField
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      disabled={disabled}
                      multiline={multiLine}
                      placeholder={Translation('app.input.placeholder.please-enter')}
                      onChange={(e) => {
                        onTextChange && onTextChange(index, e.target.value);
                      }}
                      InputProps={{
                        classes: {
                          input: multiLine ? classes.textArea : '',
                        },
                      }}
                    />
                  ) : (
                    <p style={{ marginTop: '9px' }}>{displayContent}</p>
                  )}
                </Form.Item>
              );
            });
          }}
        </Form.List>
      }
    </div>
  );
};

export default MutiLangList;
