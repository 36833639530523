export enum ProductionTargetType {
  AGENT = 'AGENT',
  AGENCY = 'AGENCY',
  DIVISION = 'DIVISION',
  BRANCH = 'BRANCH',
  UNIT = 'UNIT'
}

export type ProductionDefaultTarget = {
  netAfyp?: number;
  netCase?: number;
  netAfypHnp?: number;
}

export type ProductionTarget = {
  _id: string;
  target: ProductionTargetType;
  targetRef: string;
  year: number;
  month: number | null;
  netAfypHnp: number | null;
  netCase: number | null;
  netAfyp: number | null;
  defaults: ProductionDefaultTarget;
  enableEdit: boolean;
  createdDate: Date;
  updatedDate: Date;
}
