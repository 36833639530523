import { FC } from 'react';
import { RouteComponentProps, Switch, Redirect } from 'react-router-dom';
import PruRoute from 'src/app/common/components/PruRoute';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { ModulePermissionProps } from 'src/app/common/module/types';
import { SALESKIT_BASE_PATH, INSURANCE_SUMMARY_PATH, INSURANCE_SUMMARY_CONFIG_PATH } from '../../../constants';
import InsuranceSummaryPage from '../page/insurance-summary-config-page';

const InsuranceSummaryConfigPath = `${SALESKIT_BASE_PATH}${INSURANCE_SUMMARY_PATH}${INSURANCE_SUMMARY_CONFIG_PATH}`;
const InsuranceSummaryPath = `${SALESKIT_BASE_PATH}${INSURANCE_SUMMARY_PATH}`;

const InsuranceSummaryConfigRoutes: FC<RouteComponentProps & ModulePermissionProps> = ({}) => {
  return (
    <Switch>
      <PruRoute exact path={InsuranceSummaryConfigPath} component={InsuranceSummaryPage} />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default InsuranceSummaryConfigRoutes;
