import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PruFilter, {
  PruFilterDateRangeDef,
  PruFilterDropdownDef,
  PruFilterItemType,
} from '../../../../../common/components/PruTable/PruFilter';
import { useIntl } from 'react-intl';
import { map } from 'lodash';
import DashboardDetailPage from './components/DashboardDetailPage';
import { ParamsProps } from '../../../../../common/components/ParamsProvider';
import { ChartListParam, fetchChartList } from '../../../network/dashboardCrud';
import { DashboardItem, LMdashboardName, LMdashboardFilter, LMDashboardType } from '../../../types/dashboard-types';
import { useDataProvider, useJWT } from 'src/app/common/utils';

const ExportJsonExcel = require('js-export-excel');
type DashboardListingPageProps = {} & ParamsProps;
type PruFilterItemDef = PruFilterDateRangeDef | PruFilterDropdownDef;

const initialState: ChartListParam = {
  chartName: 'List of Participants',
  chartType: 'Table',
  campaignId: '',
  consultantCode: '',
  campaignStatus: '',
  campaignType: '',
  campaignRecordType: '',
  leadSubSource: '',
  startDate: [],
  campaignStartDt: [],
  assignDate: [],
  assignedDate: [],
  type: '',
  leadSource: '',
  unitCode: '',
  businessManagerName: '',
  consultantName: '',
};

const paramsInitiator = (initialParams?: Record<string, string>): ChartListParam => {
  return initialParams
    ? {
        chartName: 'List of Participants',
        //chartID: "62625264-8055-4590-8c78-8bf995f6bcd1",
        chartType: 'Table',
        campaignId: '',
        consultantCode: '',
        campaignStatus: '',
        campaignType: '',
        campaignRecordType: '',
        leadSubSource: '',
        startDate: [],
        campaignStartDt: [],
        assignDate: [],
        assignedDate: [],
        type: '',
        leadSource: '',
        unitCode: '',
        businessManagerName: '',
        consultantName: '',
        region: '',
        channel: '',
      }
    : initialState;
};

export const dashboardFilterKeys = ['bundleIdentifier', 'name', 'role', 'isPublic'];

const DashboardListingPage: FC<DashboardListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const jwt = useJWT() || {};
  const { region, channel } = jwt;
  const [filterState, setFilterState] = useState<ChartListParam>({
    ...paramsInitiator(initialParams),
    region,
    channel,
  });
  const [filterList, setFilterList] = useState<PruFilterItemDef[]>([]);
  const [, setDashboardList] = useState<DashboardItem[]>();
  const { isLoading, refreshData } = useDataProvider<DashboardItem[]>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchChartList(filterState, dispatch);
      } catch (err) {}
    },
    setDashboardList,
    false,
  );

  const resetFilters = () => {
    setFilterState({
      chartName: filterState.chartName,
      chartType: 'Table',
      campaignId: 'all',
      consultantCode: 'all',
      campaignStatus: 'all',
      campaignType: 'all',
      campaignRecordType: 'all',
      leadSubSource: 'all',
      startDate: [],
      campaignStartDt: [],
      assignDate: [],
      assignedDate: [],
      type: 'all',
      leadSource: 'all',
      unitCode: 'all',
      businessManagerName: 'all',
      consultantName: 'all',
    });
  };

  const [myDataList, setMyDataList] = useState<Record<string, string[]>>({
    campaignId: [],
    consultantCode: [],
    campaignStatus: [],
    campaignType: [],
    campaignRecordType: [],
    leadSubSource: [],
    channel: [],
    region: [],
  });

  const [myDatePicker, setMyDatePicker] = useState<string>('Date');

  const [excelData, setExcelData] = useState<{ fields: object; documents: object[] }>();

  const exportExcel = async () => {
    let sheetHeader: any[] = [];
    let sheetData: any[] = [];

    if (excelData) {
      type fieldsType = keyof typeof excelData.fields;

      let fields = excelData.fields;
      let fieldsKey: string[] = [];

      for (let key in fields) {
        fieldsKey.push(key as fieldsType);
        sheetHeader.push(fields[key as fieldsType]);
      }

      //adjust the order of data : group -> group_series_(index) -> value
      excelData.documents.forEach((doc: any) => {
        let tmpDoc: Record<string, string> = {};
        fieldsKey.forEach((key) => {
          tmpDoc[key] = doc[key] ?? '';
        });
        sheetData.push(tmpDoc);
      });
      // over

      var option = {
        fileName: filterState.chartName,
        datas: [
          {
            sheetData: sheetData,
            sheetName: 'sheet',
            sheetHeader: sheetHeader,
          },
        ],
      };
      var toExcel = new ExportJsonExcel(option);

      toExcel.saveExcel();
    }
  };

  useEffect(() => {
    const obj = LMdashboardFilter.get(filterState.chartName);
    let tmpList: PruFilterItemDef[] = [];

    for (const key in obj) {
      if (obj[key].includes('Date')) {
        setMyDatePicker(obj[key]);
      } else if (obj[key] == 'Region') {
        tmpList.push({
          type: PruFilterItemType.DROPDOWN,
          field: key,
          displayName: obj[key],
          choices: [{ displayName: region, value: region }],
        });
      } else if (obj[key] == 'Channel') {
        tmpList.push({
          type: PruFilterItemType.DROPDOWN,
          field: key,
          displayName: obj[key],
          choices: [{ displayName: channel, value: channel }],
        });
      } else if (myDataList[key]) {
        tmpList.push({
          type: PruFilterItemType.DROPDOWN,
          field: key,
          displayName: obj[key],
          choices: [
            { displayName: 'All', value: 'all' },
            ...map(myDataList[key], (option: string) => ({ displayName: option, value: option })),
          ],
        });
      } else if (obj[key].includes('Date')) {
        setMyDatePicker(obj[key]);
      } else if (obj[key] == 'Region') {
        tmpList.push({
          type: PruFilterItemType.DROPDOWN,
          field: key,
          displayName: obj[key],
          choices: [{ displayName: region, value: region }],
        });
      } else if (obj[key] == 'Channel') {
        tmpList.push({
          type: PruFilterItemType.DROPDOWN,
          field: key,
          displayName: obj[key],
          choices: [{ displayName: channel, value: channel }],
        });
      }
    }
    setFilterList(tmpList);
  }, [filterState.chartName, myDataList]);

  return (
    <>
      <PruFilter
        title={Translation('dashboard.common.controlPanel')}
        itemDef={[
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'chartName',
            initialValue: filterState.chartName,
            defaultValue: filterState.chartName || initialState.chartName,
            displayName: Translation('dashboard.common.name'),
            choices: [...map(LMdashboardName, (option: string) => ({ displayName: option, value: option }))],
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'chartType',
            initialValue: filterState.chartType,
            defaultValue: initialState.chartType,
            displayName: Translation('dashboard.common.type'),
            choices: [...map(LMDashboardType, (option: string) => ({ displayName: option, value: option }))],
          },
          ...map(filterList, (option: PruFilterItemDef) => option),
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'startDate',
            fieldTo: 'endDate',
            displayName: myDatePicker,
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'region',
            initialValue: filterState.region,
            defaultValue: initialState.region,
            displayName: 'Region',
            choices: [{ displayName: region, value: region }],
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'channel',
            initialValue: filterState.channel,
            defaultValue: initialState.channel,
            displayName: 'Channel',
            choices: [{ displayName: channel, value: channel }],
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            chartName: data.chartName,
            chartType: data.chartType,
            campaignId: data.campaignId,
            consultantCode: data.consultantCode,
            campaignStatus: data.campaignStatus,
            campaignType: data.campaignType,
            campaignRecordType: data.campaignRecordType,
            leadSubSource: data.leadSubSource,
            startDate: [!!data.startDate ? data.startDate : null, !!data.endDate ? data.endDate : null],
            campaignStartDt: [!!data.startDate ? data.startDate : null, !!data.endDate ? data.endDate : null],
            assignDate: [!!data.startDate ? data.startDate : null, !!data.endDate ? data.endDate : null],
            region: data.region,
            channel: data.channel,
          })
        }
        fetchData={refreshData}
        downloadExcel={exportExcel}
        resetFilters={resetFilters}
      />
      <DashboardDetailPage
        filter={filterState}
        onRefresh={true}
        refreshdata={refreshData}
        setExcelData={(data) => setExcelData(data)}
        getDataList={(data) => setMyDataList(data)}
      />
    </>
  );
};

export default DashboardListingPage;
