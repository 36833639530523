import { useReducer } from 'react';
import { useDispatch } from 'react-redux';
import { regionLocale, RegionLocale } from 'src/app/i18n';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import { SendLinkDialogState } from 'src/app/modules/event-v2/types';
import { SendLinkBody, sendLink } from 'src/app/modules/event-v2/network';
import { SendLinkFormState, sendLinkFormReducer } from '../../../../reducers';
import { linkTitle, linkBody } from './constants';

type HookProps = {
  eventId: string;
  dialogState: SendLinkDialogState;
  resetDialogState: () => void;
};

const initialState: SendLinkFormState = {
  lang: RegionLocale.ENGLISH,
  title: regionLocale.reduce((acc, locale) => ({ ...acc, [locale]: linkTitle[locale] }), {}),
  body: regionLocale.reduce((acc, locale) => ({ ...acc, [locale]: linkBody[locale] }), {}),
};

export const useSendLinkDialog = ({ eventId, dialogState, resetDialogState }: HookProps) => {
  const dispatch = useDispatch();
  const { selectedRows, onResetRowSelected } = dialogState;
  const [formState, formDispatch] = useReducer(sendLinkFormReducer, initialState);

  const onSubmit = async () => {
    const body: SendLinkBody = {
      langCode: formState.lang,
      assistantIds: selectedRows.map((row) => row._id),
    };
    try {
      await sendLink(eventId, body, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: 'Instruction has been triggered.',
          },
        ]),
      );
      if (onResetRowSelected) {
        onResetRowSelected();
      }
      resetDialogState();
    } catch (err) {}
  };

  return {
    formState,
    formDispatch,
    onSubmit,
  };
};
