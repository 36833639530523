import { PaginateList } from '../../../common/types/common-types';

export enum ConfigAgentFormMode {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
}

export type ConfigAgentKeyValueItem = {
  key: string;
  value: string;
};

export type ConfigAgentItem = {
  _id: string;
  agentCode: string;
  //keyValue: ConfigAgentKeyValueItem[];
  keyValue?: Object;
  type: string;
  createdAt: Date;
  updatedAt: Date;
};

export type ConfigAgentEditItem = {
  agentCode: string;
  // keyValue: ConfigAgentKeyValueItem[];
  keyValue?: Object;
  type: string;
};

export type ConfigAgentPaginateList = PaginateList<ConfigAgentItem> & {
  userConfig: ConfigAgentItem[];
  totalRecords: number;
};

export type Configuration = {
  InsuranceSummary: any;
  Sales: SalesConfig;
  productTypes: Array<{ id: string }>;
};

type SalesConfig = {
  prospect: { disableEngagement: true };
};
