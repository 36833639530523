import React, { useState } from 'react';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import TaskSectionProposal from './components/TaskSectionProposalList';
import { useStyles } from '../styles/TaskSectionStyles';
import { useDispatch } from 'react-redux';
import { TaskItemList } from '../../../types/todolist-type';
import { fecthTodoListTask } from '../../../network/todolistCrud';
import { useDataProvider } from 'src/app/common/utils';

type TaskSectionListProps = ParamsProps;

export const todoListTaskSectionFilterKeys = [];

const TaskSectionListPage = ({}: TaskSectionListProps) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const [taskData, setTaskData] = useState<TaskItemList>();
  const { isLoading, refreshData } = useDataProvider<TaskItemList>(
    async () => {
      try {
        return await fecthTodoListTask(dispatch);
      } catch (err) {}
    },
    setTaskData,
    false,
  );
  const taskValue = taskData?.groups.sort((a, b) => a.order - b.order);

  return (
    <>
      {taskValue?.map((val) => (
        <div className={classes.padding}>
          <TaskSectionProposal
            isShow={val.isShow}
            tableName={val.key}
            data={val.categories}
            isLoading={isLoading}
            onRefresh={() => refreshData()}
          />
        </div>
      ))}
    </>
  );
};

export default TaskSectionListPage;
