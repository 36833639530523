import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  wrap: {
    margin: theme.spacing(1),
    borderRadius: 5,
  },
  error: {
    backgroundColor: 'red',
  },
  dashboardContainer: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  loadingContainer: {
    width: '100%',
    textAlign: 'center',
  },
  loadingBackground: {
    backgroundColor: '#f7f7f7',
    padding: '255px 0',
  },
  loadingText: {
    color: '#333333',
    textTransform: 'uppercase',
    fontSize: 14,
    fontWeight: 500,
    paddingTop: 14,
  },
}));
