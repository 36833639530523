export type TopDashboardType = 'Both' | 'Among' | 'Unique'; //'Both' -> numbers | 'Among' -> number & table | 'Unique' -> table

export enum LMDashboardType {
  TABLE = 'Table',
}

export enum DashboardType {
  TABLE = 'Table',
  CHART = 'Chart',
}

export enum LMdashboardName {
  PARTICIPANTS = 'List of Participants',
  CAMPAIGN = 'List of Campaign',
  LEADS = 'List of Leads',
}

export enum BDMdashboardGroupName {
  CONVERSION = 'Conversion Dashboard',
  LEADAGING = 'Lead Aging Dashboard',
  MANAGEMENT = 'Lead Management Dashboard',
  PERFORMANCE = 'Performance Dashboard',
}

export enum BDMdashboardName {
  LOSTREPORT = 'Appointment to Lost Report',
  SUBMIISSIONREPORT = 'Appointment to Submission Report',
  CUMULATIVE = 'Cumulative Leads Aging Report',
  ENGAGEMENT = 'Leads Aging Report - Engagement',
  PENDINGCALL = 'Leads Aging Report - Pending Call',
  AGINGSUBMISSION = 'Leads Aging Report - Submission',
  DROPPEDOFF = 'Dropped-Off Report',
  LOSTREASON = 'Lead Lost Reason Report',
  STAGE = 'Lead Stage Report',
  STATUS = 'Lead Stage by Consultants',
  SUBSOURCE = 'Leads Report by Sub Source',
  ASSIGNEDTODAY = 'Leads Assigned Today by Source',
  WEEKCREATE = 'Leads Create Per Month',
  AVERAGE = 'Average Conversion Time Report',
  SOURCE = 'Conversion Performance Report by Source',
  ASSIGNEDDAY = 'Conversion performance by Lead Assigned Date',
  WEEKCREATE2 = 'Leads Create Per Month - phase 2',
  UPDATEDDATE = 'Conversion Performance by Last Updated Date',
  ASSIGNEDCALL = 'Assigned to Call Report',
  AVERAGEAGENT = 'Average Conversion Days by Consultants',
  TOPAGENT = 'Top Consultants by Lead Capture',
  UNACCEPTED = 'Unaccepted Leads by Consultants',
  CONVERSION = 'Conversion Dashboard',
  LEADAGING = 'Lead Aging Dashboard',
  MANAGEMENT = 'Lead Management Dashboard',
  PERFORMANCE = 'Performance Dashboard',
  AVERAGEDAYS = 'Average Conversion Days',
}

enum ParticipantFilter {
  campaignStatus = 'Campaign Status',
  campaignStartDt = 'Start Date',
  campaignId = 'Campaign ID',
  campaignRecordType = 'Campaign Record Type',
}

enum CampaignFilter {
  campaignStatus = 'Campaign Status',
  startDate = 'Start Date',
  campaignRecordType = 'Campaign Record Type',
}
enum LeadsFilter {
  campaignType = 'Campaign Record Type',
  campaignId = 'Campaign ID',
  assignDate = 'Created Date',
  leadSubSource = 'Sub-Source',
}

enum NormalFilter {
  type = 'Existing Customer',
  assignedDate = 'Assigned Date',
  leadSource = 'Lead Source',
  region = 'Region',
  channel = 'Channel',
}

enum Phase2Filter {
  type = 'Existing Customer',
  assignedDate = 'Assigned Date',
  leadSource = 'Lead Source',
  businessManagerName = 'BDM',
  consultantName = 'Consultant Name',
  unitCode = 'Unit Code',
  region = 'Region',
  channel = 'Channel',
}

export const LMdashboardFilter: Map<string, any> = new Map<LMdashboardName, any>([
  [LMdashboardName.PARTICIPANTS, ParticipantFilter],
  [LMdashboardName.CAMPAIGN, CampaignFilter],
  [LMdashboardName.LEADS, LeadsFilter],
]);

export const BDMdashboardFilter: Map<string, any> = new Map<BDMdashboardName, any>([
  [BDMdashboardName.LOSTREPORT, NormalFilter],
  [BDMdashboardName.SUBMIISSIONREPORT, NormalFilter],
  [BDMdashboardName.CUMULATIVE, NormalFilter],
  [BDMdashboardName.ENGAGEMENT, NormalFilter],
  [BDMdashboardName.PENDINGCALL, NormalFilter],
  [BDMdashboardName.AGINGSUBMISSION, NormalFilter],
  [BDMdashboardName.DROPPEDOFF, NormalFilter],
  [BDMdashboardName.LOSTREASON, NormalFilter],
  [BDMdashboardName.STAGE, NormalFilter],
  [BDMdashboardName.STATUS, NormalFilter],
  [BDMdashboardName.SUBSOURCE, NormalFilter],
  [BDMdashboardName.ASSIGNEDTODAY, NormalFilter],
  [BDMdashboardName.WEEKCREATE, NormalFilter],
  [BDMdashboardName.AVERAGE, NormalFilter],
  [BDMdashboardName.SOURCE, NormalFilter],
  [BDMdashboardName.ASSIGNEDDAY, Phase2Filter],
  [BDMdashboardName.WEEKCREATE2, Phase2Filter],
  [BDMdashboardName.UPDATEDDATE, Phase2Filter],
  [BDMdashboardName.ASSIGNEDCALL, Phase2Filter],
  [BDMdashboardName.AVERAGEAGENT, Phase2Filter],
  [BDMdashboardName.TOPAGENT, Phase2Filter],
  [BDMdashboardName.UNACCEPTED, Phase2Filter],
  [BDMdashboardName.CONVERSION, Phase2Filter],
  [BDMdashboardName.LEADAGING, Phase2Filter],
  [BDMdashboardName.MANAGEMENT, Phase2Filter],
  [BDMdashboardName.PERFORMANCE, Phase2Filter],
  [BDMdashboardName.AVERAGEDAYS, Phase2Filter],
]);

export const ConversionList: Array<string> = [
  BDMdashboardName.AVERAGEDAYS,
  // BDMdashboardName.AVERAGE,
  BDMdashboardName.SOURCE,
  BDMdashboardName.ASSIGNEDDAY,
  BDMdashboardName.UPDATEDDATE,
  BDMdashboardName.AVERAGEAGENT,
];

export const LeadAgingList: Array<string> = [
  BDMdashboardName.PENDINGCALL,
  BDMdashboardName.ENGAGEMENT,
  BDMdashboardName.AGINGSUBMISSION,
  BDMdashboardName.CUMULATIVE,
];

export const ManagementList: Array<string> = [
  BDMdashboardName.SUBSOURCE,
  BDMdashboardName.STAGE,
  BDMdashboardName.DROPPEDOFF,
  BDMdashboardName.STATUS,
  BDMdashboardName.WEEKCREATE,
  BDMdashboardName.UNACCEPTED,
];

export const PerformanceList: Array<string> = [
  BDMdashboardName.LOSTREPORT,
  BDMdashboardName.SUBMIISSIONREPORT,
  BDMdashboardName.ASSIGNEDCALL,
  BDMdashboardName.TOPAGENT,
];

export const BDMdashboardGroupMap = new Map<BDMdashboardGroupName, Array<string>>([
  [BDMdashboardGroupName.CONVERSION, ConversionList],
  [BDMdashboardGroupName.LEADAGING, LeadAgingList],
  [BDMdashboardGroupName.MANAGEMENT, ManagementList],
  [BDMdashboardGroupName.PERFORMANCE, PerformanceList],
]);

export type DashboardItem = {
  chartName: string;
  chartID: string;
  chartType: string;
  filter: string;
  value: string;
  createdAt: Date;
  updatedAt: Date;
};

export type PlatformDashboard = {
  fileId: string;
  fileName: string;
  fileSize: number;
};

export type DashboardDetail = {
  id: string;
  name: string;
  ios: PlatformDashboard;
  android: PlatformDashboard;
  createdAt: Date;
  updatedAt: Date;
};

export type TopDashboardDetail = {
  type: TopDashboardType;
  dashboardLeft: string;
  dashboardRight?: string;
};
