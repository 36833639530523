import React, { FC } from 'react';
import { Dialog } from '@mui/material';
import { useStyles } from 'src/app/modules/AgencyCampaign/pages/CampaignType/Form/CampaignTypeDetailPage';
import { SectionItem, SectionFieldItem, EligibilityRuleOption } from 'src/app/modules/AgencyCampaign/types/campaign-type-types';
import EditFieldForm from './EditFieldForm';

type EditFieldDialogProps = {
  sections: SectionItem[];
  sectionIndex: number;
  fieldIndex: number;
  fieldPool: SectionFieldItem[];
  eligibilityRuleOptionList: EligibilityRuleOption[];
  open: boolean;
  handleClose: () => void;
  setSections: (sections: SectionItem[]) => void;
}

const EditFieldDialog: FC<EditFieldDialogProps> = ({ sections, sectionIndex, fieldIndex, fieldPool, eligibilityRuleOptionList, open, handleClose, setSections }) => {
  const { classes } = useStyles();
  const initialField: SectionFieldItem = sections[sectionIndex].sectionFields[fieldIndex];

  const handleSubmit = (editedField: SectionFieldItem) => {
    const newSections = [...sections];
    newSections[sectionIndex].sectionFields[fieldIndex] = editedField;
    setSections(newSections);
    handleClose();
  }

  return (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      open={open}
    >
      <EditFieldForm
        initialField={initialField}
        fieldPool={fieldPool}
        eligibilityRuleOptionList={eligibilityRuleOptionList}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
      />
    </Dialog>
  )
}

export default EditFieldDialog;