import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { makeStyles } from 'tss-react/mui';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { toAbsoluteUrl } from 'src/app/common/utils';
import ProgressBarItem from './ProgressBarItem';
import { CampaignDetailPageStep, CampaignStructureItem } from '../../../../types/staff-campaign-types';
import { AgencyCampaignTypeStatusEnum } from '../../../../constants';
import { Translation } from 'src/app/i18n';
import moment from 'moment';
import { PruToast } from 'src/app/common/components/pru-toast';
import { ModalService } from 'src/app/common/components/pru-modal/modal-service';

const useStyles = makeStyles()((theme) => ({
  container: {
    width: 224,
    borderRight: '1px solid #F0F0F0',
    display: 'flex',
    flexDirection: 'column',
    marginRight: 5,
  },
  backButtonContainer: {
    backgroundColor: 'white',
    height: 52,
    marginBottom: 5,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontWeight: 600,
  },
  leftBarContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: 'white',
  },
  progressContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  level1: {
    borderBottom: '1px solid #F0F0F0',
  },
  level1Title: {
    paddingTop: 15,
    paddingBottom: 15,
    fontWeight: 'bold',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  level2: {
    borderTop: '1px solid #F0F0F0',
    marginLeft: 15,
    height: 55,
    display: 'flex',
    flexDirection: 'row',
  },
  level2Title: {
    padding: 15,
    fontWeight: 'bold',
    display: 'flex',
    flex: 1,
  },
  level2Container: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },
  footContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: 15,
  },
  btnCancelWrap: {
    margin: 10,
    display: 'flex',
    justifyContent: 'space-between',
  },
  tips: {
    color: '#858585',
    marginTop: 15,
  },
}));

interface DetailPageLeftBarProps {
  campaignTypeStructureData?: CampaignStructureItem;
  onSubmit: () => Promise<void>;
  onCancel: () => void;
  formState: any;
  currentStep: number;
  step1Warning: boolean;
  step2Warning: boolean;
  step3Warning: boolean;
  onStepChange: (step: number) => void;
  onBack: () => void;
  disabledEdit?: boolean;
  campaginStatus: string;
  startDate: any;
  submitDateTips: string;
}
const getTranslations = () => {
  return {
    campaign_has_been_approved_please_contanct_staff: Translation('campaign_has_been_approved_please_contanct_staff'),
    cannot_cancel_campaign_after_campaign_start_date: Translation('cannot_cancel_campaign_after_campaign_start_date'),
  };
};

const CampaignDetailPageLeftBar: React.FC<DetailPageLeftBarProps> = ({
  campaignTypeStructureData,
  onSubmit,
  onCancel,
  currentStep,
  step1Warning,
  step2Warning,
  step3Warning,
  onStepChange,
  onBack,
  submitDateTips,
  campaginStatus,
  startDate,
  formState,
}) => {
  const [submitRequesting, setSubmitRequesting] = useState(false);
  const [cancelRequesting, setCancelRequesting] = useState(false);
  const { stepPopulated } = useMemo(() => {
    const stepPopulated = formState.stepPopulated;
    return { accessedMaxStep: Math.max.apply(Math, stepPopulated.concat(currentStep)), stepPopulated };
  }, [currentStep, formState.stepPopulated]);
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);
  const onPressCancel = async () => {
    if (moment(new Date()).isAfter(moment(new Date(startDate)))) {
      PruToast({
        message: getTranslations().cannot_cancel_campaign_after_campaign_start_date,
      });
      return;
    }
    if (
      [
        AgencyCampaignTypeStatusEnum.APPROVED.toString(),
        AgencyCampaignTypeStatusEnum.ACTIVE.toString(),
        AgencyCampaignTypeStatusEnum.COMPLETED.toString(),
      ].includes(campaginStatus)
    ) {
      PruToast({
        message: getTranslations().campaign_has_been_approved_please_contanct_staff,
      });
      return;
    }

    setCancelRequesting(true);
    await ModalService.confirm({
      title: Translation('agencyCampaign.detail.leftBar.cancelTitle'),
      description: Translation('agencyCampaign.detail.leftBar.cancelText'),
      onConfirm: async () => {
        await onCancel();
      },
    }).finally(() => {
      setCancelRequesting(false);
    });
  };

  return (
    <div className={classes.container}>
      <div className={classes.backButtonContainer} onClick={onBack}>
        <img
          style={{
            height: '20px',
            width: '20px',
            marginRight: '10px',
            marginLeft: '5px',
          }}
          alt="arrow"
          src={toAbsoluteUrl('/media/icon/back.png')}
        />
        {Translation('agencyCampaign.detail.leftBar.backToList')}
      </div>
      <div className={classes.leftBarContainer}>
        <div className={classes.progressContainer}>
          <div className={classes.level1}>
            <div className={classes.level1Title}>
              <img
                style={{ height: '10px', width: '10px', margin: 7 }}
                alt="bulletpoint"
                src={toAbsoluteUrl('/media/icon/bulletpoint2.png')}
              />
              {Translation('agencyCampaign.detail.leftBar.application')}
            </div>
            <div className={classes.level2Container}>
              <div style={{ height: '100%', marginLeft: 40, marginTop: -2 }}>
                <ProgressBarItem
                  isFinished={!step1Warning}
                  inProgress={
                    currentStep === CampaignDetailPageStep.CAMPAIGN_INFO &&
                    step1Warning &&
                    !stepPopulated.includes(CampaignDetailPageStep.CAMPAIGN_INFO)
                  }
                  showWarning={stepPopulated.includes(CampaignDetailPageStep.CAMPAIGN_INFO) && step1Warning}
                  showTopLine={false}
                  showBottomLine={true}
                />
                <ProgressBarItem
                  isFinished={!step2Warning}
                  inProgress={
                    currentStep === CampaignDetailPageStep.PARTICIPANT &&
                    step2Warning &&
                    !stepPopulated.includes(CampaignDetailPageStep.PARTICIPANT)
                  }
                  showWarning={stepPopulated.includes(CampaignDetailPageStep.PARTICIPANT) && step2Warning}
                  showTopLine={true}
                  showBottomLine={true}
                />
                <ProgressBarItem
                  isFinished={!step3Warning}
                  inProgress={
                    currentStep === CampaignDetailPageStep.ATTACHMENT &&
                    step3Warning &&
                    !stepPopulated.includes(CampaignDetailPageStep.ATTACHMENT)
                  }
                  showWarning={stepPopulated.includes(CampaignDetailPageStep.ATTACHMENT) && step3Warning}
                  showTopLine={true}
                  showBottomLine={false}
                />
              </div>
              <div style={{ flex: 1 }}>
                <div
                  className={classes.level2}
                  onClick={() => {
                    onStepChange(CampaignDetailPageStep.CAMPAIGN_INFO);
                  }}
                >
                  <div className={classes.level2Title}>
                    {' '}
                    {Translation('agencyCampaign.detail.leftBar.campaignInfo')}
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      style={{
                        height: '16px',
                        width: '16px',
                        marginRight: '10px',
                      }}
                      alt="arrow"
                      src={toAbsoluteUrl('/media/icon/arrow/right.png')}
                    />
                  </div>
                </div>
                <div
                  className={classes.level2}
                  onClick={() => {
                    onStepChange(CampaignDetailPageStep.PARTICIPANT);
                  }}
                >
                  <div className={classes.level2Title}>{Translation('agencyCampaign.detail.leftBar.participant')}</div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      style={{
                        height: '16px',
                        width: '16px',
                        marginRight: '10px',
                      }}
                      alt="arrow"
                      src={toAbsoluteUrl('/media/icon/arrow/right.png')}
                    />
                  </div>
                </div>
                <div
                  className={classes.level2}
                  onClick={() => {
                    onStepChange(CampaignDetailPageStep.ATTACHMENT);
                  }}
                >
                  <div className={classes.level2Title}>{Translation('agencyCampaign.detail.leftBar.attachment')}</div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      style={{
                        height: '16px',
                        width: '16px',
                        marginRight: '10px',
                      }}
                      alt="arrow"
                      src={toAbsoluteUrl('/media/icon/arrow/right.png')}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={classes.level1}
            style={{ display: 'flex', flexDirection: 'row' }}
            onClick={() => {
              onStepChange(CampaignDetailPageStep.APPROVAL);
            }}
          >
            <div className={classes.level1Title}>
              <img
                style={{ height: '10px', width: '10px', margin: 7 }}
                alt="buttelpoint"
                src={toAbsoluteUrl('/media/icon/bulletpoint2.png')}
              />
              {Translation('agencyCampaign.detail.leftBar.approval')}
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                style={{ height: '16px', width: '16px', marginRight: '10px' }}
                alt="arrow"
                src={toAbsoluteUrl('/media/icon/arrow/right.png')}
              />
            </div>
          </div>

          {campaignTypeStructureData?.sections.filter((item) => item.key === 'postDocuments')[0] && (
            <div
              className={classes.level1}
              style={{ display: 'flex', flexDirection: 'row' }}
              onClick={() => {
                onStepChange(CampaignDetailPageStep.EVNET_DOCUMENT);
              }}
            >
              <div className={classes.level1Title}>
                <img
                  style={{ height: '10px', width: '10px', margin: 7 }}
                  alt="buttelpoint"
                  src={toAbsoluteUrl('/media/icon/bulletpoint2.png')}
                />
                {Translation('agencyCampaign.detail.leftBar.postEventDocs')}
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  style={{ height: '16px', width: '16px', marginRight: '10px' }}
                  alt="arrow"
                  src={toAbsoluteUrl('/media/icon/arrow/right.png')}
                />
              </div>
            </div>
          )}
          {/* <div
            className={classes.level1}
            style={{ display: 'flex', flexDirection: 'row' }}
            onClick={() => {
              onStepChange(CampaignDetailPageStep.LEAD);
            }}
          >
            <div className={classes.level1Title}>
              <img
                style={{ height: '10px', width: '10px', margin: 7 }}
                alt="buttelpoint"
                src={toAbsoluteUrl('/media/icon/bulletpoint2.png')}
              />
              {Translation('agencyCampaign.detail.leftBar.lead')}
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                style={{ height: '16px', width: '16px', marginRight: '10px' }}
                alt="arrow"
                src={toAbsoluteUrl('/media/icon/arrow/right.png')}
              />
            </div>
          </div> */}
        </div>

        <div className={classes.footContainer}>
          {campaginStatus &&
            (campaginStatus == AgencyCampaignTypeStatusEnum.DRAFT ||
              campaginStatus == AgencyCampaignTypeStatusEnum.REJECTED ||
              campaginStatus == AgencyCampaignTypeStatusEnum.CANCELED) && (
              <>
                <Button
                  disabled={submitRequesting}
                  variant="contained"
                  color="secondary"
                  size="large"
                  onClick={async () => {
                    setSubmitRequesting(true);
                    await onSubmit().finally(() => setSubmitRequesting(false));
                  }}
                >
                  {Translation('app.button.submit')}
                </Button>
                {!!submitDateTips && <div className={classes.tips}>* {submitDateTips}</div>}
              </>
            )}
          {campaginStatus &&
            (campaginStatus == AgencyCampaignTypeStatusEnum.PENDING ||
              campaginStatus == AgencyCampaignTypeStatusEnum.ACTIVE ||
              campaginStatus == AgencyCampaignTypeStatusEnum.APPROVED) && (
              <Button
                disabled={cancelRequesting}
                variant="outlined"
                color="inherit"
                size="large"
                onClick={onPressCancel}
              >
                {Translation('app.button.cancel')}
              </Button>
            )}
        </div>
      </div>
    </div>
  );
};

export default CampaignDetailPageLeftBar;
