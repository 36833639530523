import React, { FC, useEffect, useState } from 'react';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { useIntl } from 'react-intl';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useDispatch } from 'react-redux';
import { useDataProvider, getDayStart, getDayEnd } from 'src/app/common/utils';
import { fetchCampaignList, CampaignListParam } from '../../../network/campaignCrud';
import { PaginateList } from 'src/app/common/types/common-types';
import { CampaignItem, CampaignStatusEnum } from '../../../types/insights-types';
import { map } from 'lodash';
import CampaignList from './components/CampaignList';
import { CampaignDropdownList, CampaignDropdownType } from 'src/app/modules/PulseLeads/types/campaign-types';
import { CampaignDropdownListParam, fetchCampaignDropdownList } from 'src/app/modules/PulseLeads/network/campaignCrud';

type CampaignListingPageProps = {} & ParamsProps;

const initialState: CampaignListParam = {
  name: '',
  startDateFrom: null,
  startDateTo: null,
  endDateFrom: null,
  endDateTo: null,
  sourceId: '',
  sort: '',
  status: '',
  limit: 5,
  page: 1,
};

export const campaignFilterKeys = [
  'name',
  'startDateFrom',
  'startDateTo',
  'endDateFrom',
  'endDateTo',
  'sort',
  'status',
  'sourceName',
];

const paramsInitiator = (initialParams?: Record<string, string>): CampaignListParam => {
  return initialParams
    ? {
        name: initialParams.name || '',
        startDateFrom: initialParams.startDateFrom ? getDayStart(new Date(initialParams.startDateFrom)) : null,
        startDateTo: initialParams.startDateTo ? getDayEnd(new Date(initialParams.startDateTo)) : null,
        endDateFrom: initialParams.endDateFrom ? getDayStart(new Date(initialParams.endDateFrom)) : null,
        endDateTo: initialParams.endDateTo ? getDayEnd(new Date(initialParams.endDateTo)) : null,
        sort: initialParams.sort || '',
        status: initialParams.status || '',
        sourceId: initialParams.sourceId || '',
        limit: 5,
        page: 1,
      }
    : initialState;
};

const CampaignListingPage: FC<CampaignListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const [filterState, setFilterState] = useState<CampaignListParam>(paramsInitiator(initialParams));
  const [campaignList, setCampaignList] = useState<PaginateList<CampaignItem>>();
  const [leadSourceDropDownList, setLeadSourceDropDownList] = useState<CampaignDropdownList>();
  const { isLoading, refreshData } = useDataProvider<PaginateList<CampaignItem>>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchCampaignList(filterState, dispatch);
      } catch (err) {}
    },
    setCampaignList,
    false,
  );

  const loadSourceDropdown = async () => {
    const params: CampaignDropdownListParam = {
      type: [CampaignDropdownType.LEAD_SOURCE, CampaignDropdownType.AGENT_LEAD_SOURCE],
    };
    const dropdownListRes = await fetchCampaignDropdownList(params, dispatch);
    setLeadSourceDropDownList(dropdownListRes);
  };
  useEffect(() => {
    loadSourceDropdown();
  }, []);

  return (
    <>
      {leadSourceDropDownList && (
        <PruFilter
          title={Translation('pulseleads.campaign.filter')}
          itemDef={[
            {
              type: PruFilterItemType.FREE_TEXT,
              field: 'name',
              initialValue: filterState.name,
              displayName: Translation('pulseleads.campaign.common.name'),
            },
            {
              type: PruFilterItemType.DROPDOWN,
              field: 'status',
              initialValue: filterState.status,
              displayName: Translation('pulseleads.campaign.common.status'),
              choices: [
                { displayName: '', value: '' },
                ...map(CampaignStatusEnum, (option: string) => ({
                  displayName: Translation(`pulseleads.campaign.status.option.${option}`),
                  value: option,
                })),
              ],
            },
            {
              type: PruFilterItemType.DROPDOWN,
              //type: PruFilterItemType.FREE_TEXT,
              field: 'sourceId',
              initialValue: filterState.sourceId,
              displayName: Translation('pulseleads.campaign.common.source'),
              choices: [
                { displayName: '', value: '' },
                ...leadSourceDropDownList[CampaignDropdownType.LEAD_SOURCE].map((dropdownItem) => ({
                  displayName: dropdownItem.value,
                  value: dropdownItem.key,
                })),
                ...leadSourceDropDownList[CampaignDropdownType.AGENT_LEAD_SOURCE].map((dropdownItem) => ({
                  displayName: dropdownItem.value,
                  value: dropdownItem.key,
                })),
              ],
            },
            {
              type: PruFilterItemType.DATE_RANGE,
              fieldFrom: 'startDateFrom',
              fieldTo: 'startDateTo',
              initialDateFrom: filterState.startDateFrom,
              initialDateTo: filterState.startDateTo,
              displayName: Translation('pulseleads.campaign.common.startDate'),
            },
            {
              type: PruFilterItemType.DATE_RANGE,
              fieldFrom: 'endDateFrom',
              fieldTo: 'endDateTo',
              initialDateFrom: filterState.endDateFrom,
              initialDateTo: filterState.endDateTo,
              displayName: Translation('pulseleads.campaign.common.endDate'),
            },
          ]}
          onChangeFilter={(data) =>
            setFilterState({
              ...filterState,
              name: data.name,
              startDateFrom: data.startDateFrom,
              startDateTo: data.startDateTo,
              endDateFrom: data.endDateFrom,
              endDateTo: data.endDateTo,
              sort: data.sort,
              sourceId: data.sourceId,
              status: data.status,
            })
          }
          fetchData={refreshData}
        />
      )}
      <CampaignList
        isLoading={isLoading}
        campaignList={campaignList}
        onRefresh={refreshData}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default CampaignListingPage;
