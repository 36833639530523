import React from 'react';

import { useIntl } from 'react-intl';
import { makeStyles } from 'tss-react/mui';
import { TextField, Button, RadioGroup, Radio, FormControlLabel, LinearProgress } from '@mui/material';

import { InsuranceSummaryStyles } from '../style/insuranceSummaryStyle';
import { InsuranceSummaryState } from 'src/app/modules/sales-kit/pages/insurance-summary/type/insurance-summary-types';
import Form from 'src/app/common/components/Form/index';

interface DisclaimerModuleProps<T> {
  title: string;
  theme: string;

  fun: (item: any) => void;

  formState: InsuranceSummaryState;
}

const DisclaimerModule = <T extends Record<string, unknown>>({ title, fun, formState }: DisclaimerModuleProps<T>) => {
  const { classes } = InsuranceSummaryStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  return (
    <>
      <div style={{ paddingTop: 12 }} className={classes.fieldContainer}>
        <span className={classes.title}>{Translation(title)}</span>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 30 }}>
        <div style={{ marginBottom: 20 }} className={classes.rowContainer}>
          <div
            style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}
            className={classes.fieldContainer}
          >
            <span className={classes.field}>Local Language</span>
          </div>
          <div className={classes.textfieldContainer}>
            <TextField
              multiline
              variant="outlined"
              fullWidth
              value={formState?.disclaimer['local'] || ''}
              onChange={(e) => {
                formState.disclaimer['local'] = e.target.value;
                fun(formState.disclaimer);
              }}
            />
          </div>
        </div>

        <div style={{ marginBottom: 20 }} className={classes.rowContainer}>
          <div
            style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'flex-end' }}
            className={classes.fieldContainer}
          >
            <span className={classes.field}> English </span>
          </div>
          <div className={classes.textfieldContainer}>
            <TextField
              variant="outlined"
              multiline
              fullWidth
              value={formState.disclaimer['enUs']}
              onChange={(e) => {
                formState.disclaimer['enUs'] = e.target.value;
                fun(formState.disclaimer);
              }}
            />
            {/* <div style={{ flex: 1, minWidth: 18 }} /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default DisclaimerModule;
