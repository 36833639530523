import React, { FC, useEffect, useState } from 'react';
import { AlertType, appendAlertItem } from 'src/redux/common/commonSlice';

import { useDispatch } from 'react-redux';
import { useDataProvider } from 'src/app/common/utils';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useIntl } from 'react-intl';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import ResultsList from './results-list';
import { fetchLicenseExamExamOptions, fetchLicenseExamResultsList } from '../../../network/license-exam-crud';
import { examResultsParam } from '../../../types/license-exam-types';
import { useLang } from 'src/app/i18n';
import moment from 'moment';

type ExamResultsListPageProps = ParamsProps;

const initialState: examResultsParam = {
  examType: '',
  examDate: undefined,
  examCode: '',
  examCentre: '',
  examName: {
    en: '',
  },
  limit: 5,
  page: 1,
};

export const examResultsFilterKeys = [
  'examType',
  'examDate',
  'examCode',
  'examCentre',
  'examName',
  'candidateId',
  'result',
];

const paramsInitiator = (initialParams?: Record<string, any>): examResultsParam => {
  return initialParams
    ? {
        examType: initialParams.examType,
        examDate: initialParams.examDate,
        examCode: initialParams.examCode || '',
        examCentre: initialParams.examCentre || '',
        examName: initialParams.examName?.en || '',
        limit: 5,
        page: 1,
      }
    : initialState;
};

const ResultsListPage: FC<ExamResultsListPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [sortKey, setSortKey] = useState<{ key: string; value?: string }>({ key: '', value: '' });
  const [filterState, setFilterState] = useState<any>(paramsInitiator(initialParams));
  const [timeSlotsList, setTimeSlotsList] = useState<any>();
  const locale = useLang();
  const { isLoading, refreshData } = useDataProvider(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState as any);
        }
        if (filterState?.examDate) {
          filterState.examDate = moment(filterState.examDate).format('YYYY-MM-DD');
        }
        const res = await fetchLicenseExamResultsList(
          {
            ...filterState,
            lang: locale,
            sortBy: sortKey.key,
            orderBy: sortKey.value,
          },
          dispatch,
        );
        if (res?.status) {
          const resWithMessage = res as any;
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.ERROR,
                title: resWithMessage.status,
                content: resWithMessage.message,
              },
            ]),
          );
        } else {
          return res;
        }
      } catch (err) {}
    },
    setTimeSlotsList,
    false,
  );

  useEffect(() => {
    refreshData();
  }, [sortKey]);

  const updateSortingKey = (sortingKey: { key: string; value?: string }) => {
    if (!sortingKey?.value) {
      sortingKey.key = '';
    }
    setSortKey(sortingKey);
  };

  return (
    <>
      <PruFilter
        title={Translation('app.button.search')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'examName',
            initialValue: filterState.examName,
            displayName: Translation('recruitment.exam.examName'),
          },
          {
            type: PruFilterItemType.DATE,
            field: 'examDate',
            initialDate: filterState.examDate,
            displayName: Translation('recruitment.exam.examDate'),
          },

          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'examCode',
            initialValue: filterState.examCode,
            displayName: Translation('recruitment.exam.examCode'),
          },
          {
            type: PruFilterItemType.ASYNC_DROPDOWN,
            style: { width: 180 },
            field: 'examType',
            initialValue: filterState.examType,
            displayName: Translation('recruitment.exam.examType'),
            fetchList: async () => {
              const res = await fetchLicenseExamExamOptions('EXAM_TYPE', { lang: locale }, dispatch);
              return res.map((item) => ({
                displayName: item || '-',
                value: item || '-',
              }));
            },
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'candidateId',
            initialValue: filterState.candidateId,
            displayName: Translation('recruitment.exam.candidateId'),
          },
          {
            type: PruFilterItemType.DROPDOWN,
            style: { width: 180 },
            field: 'result',
            initialValue: filterState.result,
            displayName: Translation('recruitment.exam.examResults'),
            choices: [
              { displayName: '', value: '' },
              { displayName: 'Pass', value: 'Pass' },
              { displayName: 'Fail', value: 'Fail' },
            ],
          },
        ]}
        onChangeFilter={(filter) => {
          setFilterState({
            ...filterState,
            examType: filter.examType,
            examDate: filter.examDate,
            examCode: filter.examCode || '',
            examCentre: filter.examCentre || '',
            examName: filter.examName || '',
            candidateId: filter.candidateId,
            result: filter.result,
          });
        }}
        fetchData={refreshData}
      />
      <ResultsList
        isLoading={isLoading}
        timeSlotsList={timeSlotsList}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
        onRefresh={refreshData}
        onSort={(dataKey: any) => updateSortingKey(dataKey)}
      />
    </>
  );
};
export default ResultsListPage;
