import React, { FC, useState, useReducer } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { Button, LinearProgress } from '@mui/material';
import { createBlob, getBlob } from 'src/app/common/network';
import { fileUpload } from 'src/app/common/utils';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import AsyncCsvLink from 'src/app/common/components/AsyncCsvLink';
import {
  createAgentProfileByMassUpload,
  downloadMassUploadCsv,
  CreateAgentProfileByMassUploadBody,
} from 'src/app/modules/Profile/network/agentProfileCrud';
import { agentProfilePath } from '../AgentProfileRoutes';
import { csvUploadType } from '../../../types/agent-profile-types';
import ConfirmDialog from 'src/app/modules/PulseLeads/pages/Rule/ActivityPoint/PointRuleSettings/List/component/ComfirnDialog';
import { PruToast } from 'src/app/common/components/pru-toast';

const FIELD_CONTAINER_WIDTH = 150;

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  sectionContainer: {
    marginBottom: 30,
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },
  template: {
    color: 'grey',
  },
  fieldContainer: {
    width: FIELD_CONTAINER_WIDTH,
    boxSizing: 'border-box',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  centerFooterContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorText: {
    fontSize: 9,
    color: '#F018A6',
  },
  chooseFileBtn: {
    marginRight: 25,
  },
  downloadTemplateBtn: {
    color: 'blue',
    cursor: 'pointer',
    textDecoration: 'underline',
    whiteSpace: 'nowrap',
  },
}));

type UploadFormState = {
  blobId?: string;
  filename?: string;
};

type AgentProfileUploadFormState = {
  agent: UploadFormState;
  aboutMe: UploadFormState;
};

const initialState: AgentProfileUploadFormState = {
  agent: {
    blobId: undefined,
    filename: undefined,
  },
  aboutMe: {
    blobId: undefined,
    filename: undefined,
  },
};

type UploadFileAction = {
  type: 'UPLOAD_FILE';
  payload: {
    field: keyof AgentProfileUploadFormState;
    value: UploadFormState;
  };
};

type ResetAction = {
  type: 'RESET_STATE';
};

type UploadFormAction = UploadFileAction | ResetAction;

const formReducer = (state: AgentProfileUploadFormState, action: UploadFormAction): AgentProfileUploadFormState => {
  switch (action.type) {
    case 'UPLOAD_FILE':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    case 'RESET_STATE':
      return initialState;
    default:
      return state;
  }
};
type DialogState = {
  open: boolean;

  method: string;
};
enum ToolTip {
  CANCEL = 'agentProfile.ad_reminder_cancel_message',
  DELETE = 'agentProfile.ad_delete_message',
}
const AgentProfileMassUploadPage: FC<RouteComponentProps> = () => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const { classes: commonClasses } = useCommonStyles();
  const history = useHistory();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const [formState, formDispatch] = useReducer(formReducer, initialState);
  const [uploadFileProgress, setUploadFileProgress] = useState<number>();

  const [toolTip, setToolTip] = useState<string>();

  const [dialogState, setDialogState] = useState<DialogState>({
    open: false,

    method: '',
  });

  const onConfirm = () => {
    if (dialogState.method === 'onCancel') {
      PruToast({
        message: Translation('agentProfile.ad_cancelled_successfully_label'),
      });
      history.push(agentProfilePath);
      return;
    }
  };

  const handleFile = async (e: React.ChangeEvent<HTMLInputElement>, fileType: keyof AgentProfileUploadFormState) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      try {
        const createBlobRes = await createBlob(
          { mimeType: file.type, accessLevel: 'public', originalFilename: file.name, module: 'agentProfile' },
          dispatch,
        );
        await fileUpload(createBlobRes.url, file, setUploadFileProgress);
        const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);
        const result = blobDetail[0];
        if (result) {
          formDispatch({
            type: 'UPLOAD_FILE',
            payload: {
              field: fileType,
              value: {
                blobId: result.blobId,
                filename: file.name,
              },
            },
          });
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: `Upload file successfully - ${file.name}`,
              },
            ]),
          );
        }
      } finally {
        setUploadFileProgress(undefined);
      }
    }
  };
  const onCancel = () => {
    try {
      setToolTip(Translation(ToolTip.CANCEL));
      setDialogState({ open: true, method: 'onCancel' });
    } catch (err) {}
  };
  const onClose = () => {
    setDialogState({ open: false, method: '' });
    setToolTip('');
  };
  const onSubmit = async () => {
    const body: CreateAgentProfileByMassUploadBody = {
      agent: formState.agent,
    };

    try {
      await createAgentProfileByMassUpload(body, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `${Translation('agentProfile.ad_uploaded_successfully_label')}`,
          },
        ]),
      );
      formDispatch({ type: 'RESET_STATE' });
      history.push(`${agentProfilePath}`);
    } catch (err) {}
  };

  return (
    <>
      <div className={classes.container} style={{ height: '100%', display: 'flex' }}>
        <div className={classes.sectionContainer}>
          <div className={classes.headerContainer}>
            <ConfirmDialog
              key={`delete-settings-rule-dialog-${dialogState.open}`}
              open={dialogState.open}
              onClose={() => onClose()}
              onRefresh={() => ''}
              onConfirm={async () => onConfirm()}
              title={Translation('agentProfile.ad_reminder_title')}
              content={toolTip}
            />
            <div className={commonClasses.header}>
              {Translation(`agentProfile.ad_agent_settings_mass_upload_title`)}
            </div>
          </div>
          <div className={classes.rowContainer}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>{Translation('agentProfile.ad_agent_list_header_title')}:</span>
            </div>
            <input
              id="upload-file"
              hidden
              type="file"
              accept=".csv"
              onClick={(e) => {
                const element = e.target as HTMLInputElement;
                element.value = '';
              }}
              onChange={(e) => {
                if (e.target.files && e.target.files.length > 0) {
                  const fileType = e.target.files[0].type;

                  if (csvUploadType.has(fileType)) {
                    handleFile(e, 'agent');
                  } else {
                    dispatch(
                      appendAlertItem([
                        {
                          severity: AlertType.WARNING,
                          title: Translation('fail_title'),
                          content: Translation('agentProfile.ad_upload_fail_file_format_message'),
                        },
                      ]),
                    );
                  }
                }
              }}
            />
            <div className={classes.rowContainer}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => document.getElementById('upload-file')!.click()}
                className={classes.chooseFileBtn}
              >
                {Translation('app.button.chooseFile')}
              </Button>
              {formState.agent?.filename && <div style={{ marginLeft: 10 }}>{formState.agent.filename}</div>}
              <AsyncCsvLink
                isDisabled={false}
                filename={`agent_settings_template.csv`}
                dataParser={(str) => str}
                asyncCall={() => downloadMassUploadCsv('agent_settings_template')}
              >
                <span className={classes.downloadTemplateBtn}>
                  {Translation(`agentProfile.ad_download_template_button`)}
                </span>
              </AsyncCsvLink>
            </div>
            {!!uploadFileProgress && (
              <LinearProgress
                style={{ marginTop: 10 }}
                variant="determinate"
                color="secondary"
                value={uploadFileProgress}
              />
            )}
          </div>
        </div>

        <div className={classes.centerFooterContainer} style={{ alignItems: 'end' }}>
          <div
            style={{
              display: 'flex',
              boxSizing: 'border-box',
              minWidth: 350,
              justifyContent: 'space-around',
            }}
          >
            <Button variant="contained" color="secondary" onClick={onCancel}>
              {Translation('app.button.cancel')}
            </Button>
            <Button variant="contained" color="secondary" onClick={onSubmit}>
              {Translation('app.button.save')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgentProfileMassUploadPage;
