import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  fieldContainer: {
    width: 160,
    boxSizing: 'border-box',
  },
  field: {
    fontSize: '0.9rem',
    marginRight: 10,
    fontWeight: 'bold',
    padding: '0px 0px 0px 10px',
  },
  sectionMargin: {
    marginBottom: 20,
  },
  divideMargin: {
    marginBottom: 10,
  },
  subHeader: {
    fontSize: '0.9rem',
    fontWeight: 'bold',
    padding: '0px 0px 0px 10px',
    color: '#979797',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  padding: {
    paddingBottom: 14,
  },
  operationBtn: {
    color: 'blue',
    cursor: 'pointer',
    textDecoration: 'underline',
    fontSize: '0.85rem',
    whiteSpace: 'nowrap',
  },
  colorBox: {
    width: 20,
    height: 20,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  chipHidden: {
    color: 'red',
    borderColor: 'red',
  },
  tableFieldName: {
    fontWeight: 'bold',
  },
  boxNotify: {
    padding: '8px 20px 8px 8px',
    border: '2px dashed #00A3FF',
    margin: '5px 5px 5px 0px',
    color: '#7E8299',
    backgroudColor: '#00A3FF',
  },
  marginBottom: {
    marginBottom: 16,
  },
  font14: {
    fontSize: 7.8,
  },
}));
