import React, { FC } from 'react';
import { useIntl } from "react-intl";
import { useStyles } from 'src/app/modules/AgencyCampaign/pages/CampaignType/Form/CampaignTypeDetailPage';
import { useWorkflowStyles } from 'src/app/modules/AgencyCampaign/pages/CampaignType/Form/pages/PageThree/CampaignTypeFormPageThree';
import { CreatorSettingItem, ApprovalRoleOptionList } from 'src/app/modules/AgencyCampaign/types/campaign-type-types';
import SelectSettingForm from '../common/SelectSettingForm';

type CreateCampaignSettingProps = {
  creatorSetting: CreatorSettingItem;
  approvalRoleOptionList: ApprovalRoleOptionList;
  setCreatorSetting: (creatorSetting: CreatorSettingItem) => void;
}

const CreateCampaignSetting: FC<CreateCampaignSettingProps> = ({ creatorSetting, approvalRoleOptionList, setCreatorSetting }) => {
  const { classes } = useStyles();
  const { classes: workflowClasses } = useWorkflowStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  return (
    <div className={workflowClasses.bottomSpacing}>
      <div className={workflowClasses.rowContainer}>
        <div className="col-3">
          <span className={workflowClasses.settingTitle}>
            {Translation("agencyCampaign.campaignType.workflow.createCampaign")}
          </span>
        </div>
        <div className="col-9">
          <div><span className={workflowClasses.settingSubTitle}>{Translation("agencyCampaign.campaignType.workflow.selectCreator")}<span className={classes.mandatory}>*</span></span></div>
        </div>
      </div>
      <div className={workflowClasses.rowContainer}>
        <div className="col-3" />
        <div className="col-9">
          <SelectSettingForm
            settingFor="creator"
            settingFormState={creatorSetting}
            approvalRoleOptionList={approvalRoleOptionList}
            setSettingFormState={setCreatorSetting}
          />
        </div>
      </div>
    </div>
  )
}

export default CreateCampaignSetting;