export enum ApplicationFormMode {
  CREATE = "CREATE",
  EDIT = "EDIT"
}

export enum ApplicationAccessLevel {
  PUBLIC = "PUBLIC",
  PRIVATE = "PRIVATE"
}

export enum ApplicationTag {
  LATEST = "Latest",
  IPAD = "iPad"
};

export enum ApplicationRole {
  AGENT = "AGENT",
  STAFF = "STAFF",
  MANAGER = "MANAGER",
  USER = "USER"
}

export type ApplicationItem = {
  id: string;
  name: string;
  targetVersion: string;
  iconUrl: string;
  isPublic: boolean;
  role: string[];
  tags: string[];
  hardUpdateDate: Date;
  createdAt: Date;
  updatedAt: Date;
};

export type LanguageText = Record<string, string>;

export type PlatformApplication = {
  fileId: string;
  fileName: string;
  fileSize: number;
};

export type ApplicationDetail = {
  id: string;
  name: string;
  bundleIdentifier: string;
  targetVersion: string;
  iconId: string;
  iconUrl: string;
  isPublic: boolean;
  role: string[];
  tags: string[];
  hardUpdateDate: Date;
  downloadLink: string;
  description: LanguageText;
  releaseNote: LanguageText;
  ios: PlatformApplication;
  android: PlatformApplication;
  createdAt: Date;
  updatedAt: Date;
};
