import React, { useState, useEffect, FC } from 'react';
import Form from 'src/app/common/components/Form';
import { Dialog, Button, Menu, MenuItem } from '@mui/material';
import { useIntl } from 'react-intl';
import SearchMaterialsBox from './SearchMaterialsBox';
import { fetchLibraryList, fetchLibraryItem } from 'src/app/modules/sales-kit/components/Library/network/libraryCrud';
import CloseIcon from '@mui/icons-material/Close';
import { styles } from '../style';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { ModuleTypeList } from '../utils';
import { getConfigurations } from '../../../../../../../common/utils/common-utils';
import {
  SALESKIT_BASE_PATH,
  SALES_MATERIAL_BASE_PATH,
  PROMOTION_MATERIAL_BASE_PATH,
  SURVEY_CUSTOMER_PATH,
} from 'src/app/modules/sales-kit/constants';

const MaterialArea: FC<any> = (props) => {
  const intl = useIntl();
  const { classes } = styles();
  const { classes: commonClasses } = useCommonStyles();
  const Translation = (id: string) => intl.formatMessage({ id });
  // const [form] = Form.useForm();
  const { form, onDeleteMatieral, selectedMaterial, onSelectMaterial, onChange } = props;
  const [popUpVisible, setPopUpVisible] = useState<boolean>(false);
  const [moudleType, setModuleType] = useState<string>('');

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectMaterialHandler = (data: any) => {
    console.log('selectMaterialHandler', data);
    onChange(data.uuid || data.id);
    onSelectMaterial(data);
  };

  const deleteMatieralHandler = () => {
    onChange('');
    onDeleteMatieral();
  };

  const pathMap: any = {
    Sales: `${SALESKIT_BASE_PATH}${SALES_MATERIAL_BASE_PATH}/library/view/`,
    Promotion: `${SALESKIT_BASE_PATH}${PROMOTION_MATERIAL_BASE_PATH}/library/view/`,
    CustomerSurvey: `${SALESKIT_BASE_PATH}${SURVEY_CUSTOMER_PATH}/edit/`,
  };

  const selectJumpParams = {
    path: pathMap[selectedMaterial?.module || selectedMaterial?.resourceType.split(' ').join('')],
    id: selectedMaterial?.resourceId || selectedMaterial?.id,
    name: selectedMaterial?.name || selectedMaterial?.title || selectedMaterial?.resource?.name || '',
  };

  return (
    <div>
      <Form.Item noStyle>
        <>
          <div>
            <span className={commonClasses.subHeader}>Material</span>
            <span style={{ color: 'red' }}>*</span>
            <span>(Can only add one material/material set as a topic)</span>
          </div>
          {!selectedMaterial && (
            <>
              <Button variant="contained" color="secondary" onClick={handleClick} style={{ marginTop: 15 }}>
                Select
              </Button>
              <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                {Object.entries(ModuleTypeList).map(
                  ([type, value]) =>
                    (type !== 'CustomerSurvey' || getConfigurations()?.Survey?.enableCustomerSurvey) && (
                      <MenuItem
                        key={type}
                        onClick={() => {
                          setModuleType(type);
                          setPopUpVisible(true);
                          handleClose();
                        }}
                      >
                        {value}
                      </MenuItem>
                    ),
                )}
              </Menu>
            </>
          )}

          {selectedMaterial && (
            <div className={classes.materialName}>
              <a href={`${selectJumpParams.path}${selectJumpParams.id}`} target="_blank" rel="noreferrer">
                {selectJumpParams.name}
              </a>
              <CloseIcon className={classes.closeIcon} onClick={deleteMatieralHandler} />
            </div>
          )}
        </>
      </Form.Item>

      <Dialog
        open={popUpVisible}
        onClose={() => {
          setPopUpVisible(false);
        }}
        scroll={'paper'}
        fullWidth
        maxWidth="lg"
      >
        <SearchMaterialsBox
          popUpVisible={popUpVisible}
          onSelectData={selectMaterialHandler}
          setPopUpVisible={setPopUpVisible}
          moduleType={moudleType}
        />
      </Dialog>
    </div>
  );
};

export default MaterialArea;
