import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { getDefaultDisplayDate } from 'src/app/common/utils';
import ConfirmDialog from '../component/ComfirnDialog';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import { deleteRuleSetting, ruleSettingListItem } from 'src/app/modules/PulseLeads/network/ruleConfigCrud';
import { columns, headerBtn, operation } from '../columns';

type ruleSettingsListProps = {
  isLoading: boolean;
  ruleSettingsList?: ruleSettingListItem[];
  onRefresh: () => void;
};

type DialogState = {
  open: boolean;
  id: string;
};

const RuleSettingList: FC<ruleSettingsListProps> = ({ isLoading, ruleSettingsList, onRefresh }) => {
  const history = useHistory();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const [dialogState, setDialogState] = useState<DialogState>({
    open: false,
    id: '',
  });

  const onDelete = async (id: string) => {
    try {
      await deleteRuleSetting(id, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Rule setting deleted successfully - ${id}`,
          },
        ]),
      );
    } catch (err) {}
  };

  return (
    <>
      <ConfirmDialog
        key={`delete-settings-rule-dialog-${dialogState.open}`}
        open={dialogState.open}
        onClose={() => setDialogState({ open: false, id: '' })}
        onRefresh={onRefresh}
        onConfirm={() => onDelete(dialogState.id)}
        title={'Remove The Rule?'}
        content={`All related agents will start to follow the default activity point rule`}
      />
      <PruTable
        title={Translation('pulseleads.activityPoint.ruleSettings.title')}
        headerBtnDef={headerBtn(Translation, history)}
        disableBulkSelect
        disablePagination
        operationDef={operation(Translation, setDialogState, history)}
        columnDef={columns(Translation, getDefaultDisplayDate)}
        isLoading={isLoading}
        onRefresh={onRefresh}
        dataSource={ruleSettingsList}
      />
    </>
  );
};

export default RuleSettingList;
