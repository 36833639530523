import React, { FC, useState } from 'react';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { useIntl } from 'react-intl';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useDispatch } from 'react-redux';
import { useDataProvider } from 'src/app/common/utils';
import { FollowUpCaseListParam, fetchFollowUpCaseList } from 'src/app/modules/Recruit/network/followUpCaseCrud';
import { PaginateList } from 'src/app/common/types/common-types';
import { AppStatusEnum, PersonalParticularItem } from 'src/app/modules/Recruit/types/personal-particular-types';
import FollowUpCaseList from './components/FollowUpCaseList';

type FollowUpCaseListingPageProps = {} & ParamsProps;

const initialState: FollowUpCaseListParam = {
  documentUploadDate: new Date(),
  appStatus: AppStatusEnum.FOLLOW_UP_REQUIRED,
  page: 1,
  limit: 5,
};

export const followUpCaseFilterKeys = ['documentUploadDate'];

const paramsInitiator = (initialParams?: Record<string, string>): FollowUpCaseListParam => {
  return initialParams
    ? {
        documentUploadDate: initialParams.documentUploadDate ? new Date(initialParams.documentUploadDate) : new Date(),
        appStatus: AppStatusEnum.FOLLOW_UP_REQUIRED,
        page: 1,
        limit: 5,
      }
    : initialState;
};

const FollowUpCaseListingPage: FC<FollowUpCaseListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const [filterState, setFilterState] = useState<FollowUpCaseListParam>(paramsInitiator(initialParams));
  const [followUpCaseList, setFollowUpCaseList] = useState<PaginateList<PersonalParticularItem>>();

  const { isLoading, refreshData } = useDataProvider<PaginateList<PersonalParticularItem>>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return (await fetchFollowUpCaseList(filterState, dispatch)).result;
      } catch (err) {}
    },
    setFollowUpCaseList,
    false,
  );

  return (
    <>
      <PruFilter
        title={Translation('recruit.followUpCase.filter')}
        itemDef={[
          {
            type: PruFilterItemType.DATE,
            field: 'documentUploadDate',
            initialDate: filterState.documentUploadDate,
            displayName: Translation('recruit.followUpCase.documentUploadDate'),
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            documentUploadDate: data.documentUploadDate,
          })
        }
        disableReset
        fetchData={refreshData}
      />
      <FollowUpCaseList
        isLoading={isLoading}
        followUpCaseList={followUpCaseList}
        onRefresh={refreshData}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default FollowUpCaseListingPage;
