import React, { FC, useState, useEffect, useMemo } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ApiErrorResponse } from 'src/app/common/network';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { LayoutSplashScreen } from 'src/app/layout';
import { AmdEmailFormMode, AmdEmailItem } from 'src/app/modules/Recruit/types/amd-email-types';
import { fetchAmdEmail } from 'src/app/modules/Recruit/network/amdEmailCrud';
import AmdEmailForm from './components/AmdEmailForm';

const AmdEmailDetailPage: FC<RouteComponentProps> = ({ history, location }) => {
  const { id } = useParams<{ id: string }>();
  const { formMode } = useMemo(
    () => ({
      formMode: id ? AmdEmailFormMode.EDIT : AmdEmailFormMode.CREATE,
    }),
    [id],
  );

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [amdEmail, setAmdEmail] = useState<AmdEmailItem>();

  const reloadData = () => {
    if (id) {
      setIsLoading(true);
      fetchAmdEmail(id, dispatch)
        .then((result) => {
          setAmdEmail(result.result);
        })
        .catch((err: ApiErrorResponse) => {
          history.push(NOT_FOUND_ERROR_PAGE_PATH);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line
  }, [id]);

  return (
    <>
      {formMode === AmdEmailFormMode.CREATE && <AmdEmailForm formMode={formMode} />}
      {formMode === AmdEmailFormMode.EDIT &&
        (isLoading || !amdEmail ? <LayoutSplashScreen /> : <AmdEmailForm formMode={formMode} amdEmail={amdEmail} />)}
    </>
  );
};

export default AmdEmailDetailPage;
