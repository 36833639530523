import axios from 'axios';
import { getConfigurations } from 'src/app/common/utils';
import { apiClient, apiErrorHandler, CORE_URL, PUBLIC_URL } from 'src/app/common/network';
import { Dispatch } from 'react';
import { PublicPortalConsent } from '../types/public-portal-consent-types';
import { TokenInfo } from '../../Auth/types/auth-types';

type PublicPortalConsentResponse = any;

export const createConsent = async (
  body: PublicPortalConsent,
  jwt: TokenInfo,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  const data = {
    name: 'engagement-public-portal',
    group: 'pruforce.Engagement',
    desc: '',
    access: 'public',
    namespace: getConfigNamespace(jwt),
    content: body,
  };
  return apiClient
    .post<void>(`${CORE_URL}/configs`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createOrUpdateConsent = async (
  body: PublicPortalConsent,
  jwt: TokenInfo,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  const configData = await fetchConsent(jwt, dispatch).catch((err) => err);
  if (Number(configData?.response?.data?.code) === 404) {
    return createConsent(body, jwt);
  }
  if (!configData?.response?.data?.code) {
    // no err throw
    const data = {
      id: configData.id,
      name: 'engagement-public-portal',
      group: 'pruforce.Engagement',
      desc: configData.desc,
      type: configData.type,
      access: configData.access,
      content: body,
    };
    return apiClient
      .put<void>(`${CORE_URL}/configs`, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => response.data)
      .catch((err) => {
        throw apiErrorHandler(err, dispatch);
      });
  } else {
    throw configData;
  }
};
const getConfigNamespace = (jwt: TokenInfo): string => {
  let namespace = 'default';
  if (jwt) {
    const enableRegion = !!getConfigurations()?.regionChannelConfig?.enableRegion;
    const enableChannel = !!getConfigurations()?.regionChannelConfig?.enableChannel;
    if (enableRegion && enableChannel) {
      namespace = `${jwt.region?.toLocaleUpperCase()}.${jwt.channel?.toLocaleUpperCase()}`;
    }
  }
  return namespace;
};
export const fetchConsent = async (jwt: TokenInfo, dispatch?: Dispatch<any>): Promise<PublicPortalConsentResponse> => {
  return axios
    .get<PublicPortalConsentResponse>(
      `${PUBLIC_URL}/configs/engagement-public-portal?group=pruforce.Engagement&namespace=${getConfigNamespace(jwt)}`,
    )
    .then((response) => {
      return response?.data;
    })
    .catch((err) => {
      throw err;
    });
};
