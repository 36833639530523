import { forEach } from 'lodash';
import { Dispatch } from 'react';
import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import {
  AgentListParam,
  AgentPaginateList,
  CampaignDetailItem,
  CampaignListParam,
  CampaignTypeListParam,
  CampaignPaginateList,
  CampaignStructureItem,
  CampaignTypeItem,
  DownlineVerifyList,
  ImportVerify,
  LeadUploadItem,
  NonDownlineVerifyList,
} from '../types/staff-campaign-types';

const staffCampaignEndpoint = 'agency-campaign-staff-campaign';
const agentsEndpoint = 'agents';

export const fetchCampaignList = async (
  params: CampaignListParam,
  sortKeys: { key: string; value?: string }[],
  dispatch?: Dispatch<any>,
): Promise<CampaignPaginateList> => {
  let queryPath = `${ADMIN_URL}/${staffCampaignEndpoint}/agency-campaign?`;
  forEach(params, (param, key) => {
    queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
  });

  //Query for sorting
  let sortingCount = 0;
  queryPath += `sort=`;
  forEach(sortKeys, (sortParam, key) => {
    if (sortParam.value) {
      queryPath += `${sortingCount === 0 ? `` : `,`}${encodeURIComponent(sortParam.key)}:${encodeURIComponent(
        sortParam.value,
      )}`;
      sortingCount++;
    }
  });
  if (sortingCount === 0) {
    queryPath += `updatedAt:desc`;
  }

  return apiClient
    .get<CampaignPaginateList>(queryPath)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchCampaignTypeList = async (
  params: CampaignTypeListParam,
  dispatch?: Dispatch<any>,
): Promise<CampaignTypeItem[]> => {
  let queryPath = `${ADMIN_URL}/${staffCampaignEndpoint}/campaign-type/list?`;
  forEach(params, (param, key) => {
    if (typeof param === 'object') {
      if (param) {
        if (key === 'roles') {
          queryPath += `${key}=${JSON.stringify(param)}&`;
        }
      }
    } else {
      queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
    }
  });
  return apiClient
    .get<CampaignTypeItem[]>(queryPath)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchCampaignTypeStructure = async (
  campaignTypeId: string,
  dispatch?: Dispatch<any>,
): Promise<CampaignStructureItem> => {
  return apiClient
    .get<CampaignStructureItem>(`${ADMIN_URL}/${staffCampaignEndpoint}/campaign-type/${campaignTypeId}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const downloadParticipantUploadCsv = async (dispatch?: Dispatch<any>): Promise<string> => {
  return apiClient
    .get<string>(`${ADMIN_URL}/${staffCampaignEndpoint}/agency-campaign/participant/template/download`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchDownlineList = async (
  params: AgentListParam,
  sortKeys: { key: string; value?: string }[],
  dispatch?: Dispatch<any>,
): Promise<AgentPaginateList> => {
  let queryPath = `${ADMIN_URL}/${agentsEndpoint}/list?`;
  forEach(params, (param, key) => {
    queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
  });
  //Query for sorting
  let sortingCount = 0;
  queryPath += `sort=`;
  forEach(sortKeys, (sortParam, key) => {
    if (sortParam.value) {
      queryPath += `${sortingCount === 0 ? `` : `,`}${encodeURIComponent(sortParam.key)}:${encodeURIComponent(
        sortParam.value,
      )}`;
      sortingCount++;
    }
  });
  if (sortingCount === 0) {
    queryPath += `updatedAt:desc`;
  }
  return apiClient
    .get<AgentPaginateList>(queryPath)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const verifyDownlineList = async (
  param: ImportVerify,
  dispatch?: Dispatch<any>,
): Promise<NonDownlineVerifyList> => {
  let path = `${ADMIN_URL}/${staffCampaignEndpoint}/agency-campaign/downline/verification`;
  return apiClient
    .post<NonDownlineVerifyList>(path, param, { headers: { 'Content-Type': 'application/json' } })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const verifyNonDownlineList = async (
  param: ImportVerify,
  dispatch?: Dispatch<any>,
): Promise<NonDownlineVerifyList> => {
  let path = `${ADMIN_URL}/${staffCampaignEndpoint}/agency-campaign/non-downline/verification`;
  return apiClient
    .post<NonDownlineVerifyList>(path, param, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const verifyEligibilityList = async (
  agentList: string[],
  rules: string[],
  dispatch?: Dispatch<any>,
): Promise<DownlineVerifyList> => {
  let path = `${ADMIN_URL}/${staffCampaignEndpoint}/agency-campaign/eligibility-check`;
  return apiClient
    .post<DownlineVerifyList>(
      path,
      {
        agents: agentList,
        rules: rules,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createNewCampaign = async (
  data: CampaignDetailItem,
  dispatch?: Dispatch<any>,
): Promise<CampaignDetailItem> => {
  return apiClient
    .post<CampaignDetailItem>(`${ADMIN_URL}/${staffCampaignEndpoint}/agency-campaign`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const updateCampaign = async (
  data: CampaignDetailItem,
  campaignId: string,
  dispatch?: Dispatch<any>,
): Promise<CampaignDetailItem> => {
  return apiClient
    .patch<CampaignDetailItem>(`${ADMIN_URL}/${staffCampaignEndpoint}/agency-campaign/${campaignId}`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const submitCampaign = async (
  data: CampaignDetailItem,
  campaignId: string,
  dispatch?: Dispatch<any>,
): Promise<CampaignDetailItem> => {
  return apiClient
    .patch<CampaignDetailItem>(`${ADMIN_URL}/${staffCampaignEndpoint}/agency-campaign/submit/${campaignId}`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const cancelCampaignSubmission = async (
  data: CampaignDetailItem,
  dispatch?: Dispatch<any>,
): Promise<CampaignDetailItem> => {
  return apiClient
    .post<CampaignDetailItem>(`${ADMIN_URL}/${staffCampaignEndpoint}/agency-campaign/discard-status`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchCampaignItem = async (id: string, dispatch?: Dispatch<any>): Promise<CampaignDetailItem> => {
  return apiClient
    .get<CampaignDetailItem>(`${ADMIN_URL}/${staffCampaignEndpoint}/agency-campaign/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const downloadLeadsTemplateCsv = async (dispatch?: Dispatch<any>): Promise<string> => {
  return apiClient
    .get<string>(`${ADMIN_URL}/${staffCampaignEndpoint}/agency-campaign/lead/template/download`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const uploadLead = async (data: LeadUploadItem, dispatch?: Dispatch<any>): Promise<LeadUploadItem> => {
  return apiClient
    .post<CampaignDetailItem>(`${ADMIN_URL}/${staffCampaignEndpoint}/agency-campaign/lead/create`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchParticipantList = async (
  params: AgentListParam,
  sortKeys: { key: string; value?: string }[],
  dispatch?: Dispatch<any>,
): Promise<AgentPaginateList> => {
  let queryPath = `${ADMIN_URL}/${staffCampaignEndpoint}/agency-campaign/participant/list?`;
  forEach(params, (param, key) => {
    queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
  });
  //Query for sorting
  let sortingCount = 0;
  queryPath += `sortBy=`;
  forEach(sortKeys, (sortParam, key) => {
    if (sortParam.value) {
      queryPath += `${sortingCount === 0 ? `` : `,`}${encodeURIComponent(sortParam.key)}:${encodeURIComponent(
        sortParam.value,
      )}`;
      sortingCount++;
    }
  });
  if (sortingCount === 0) {
    queryPath += `updatedAt:desc`;
  }
  return apiClient
    .get<AgentPaginateList>(queryPath)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const addParticipant = async (data: any[], dispatch?: Dispatch<any>): Promise<LeadUploadItem[]> => {
  return apiClient
    .post<CampaignDetailItem>(`${ADMIN_URL}/${staffCampaignEndpoint}/agency-campaign/participant`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const removeParticipant = async (data: any, dispatch?: Dispatch<any>): Promise<LeadUploadItem> => {
  return apiClient
    .post<CampaignDetailItem>(`${ADMIN_URL}/${staffCampaignEndpoint}/agency-campaign/participant/remove`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const sendInvitation = async (data: any, dispatch?: Dispatch<any>): Promise<LeadUploadItem> => {
  return apiClient
    .post<CampaignDetailItem>(`${ADMIN_URL}/${staffCampaignEndpoint}/agency-campaign/invitation`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const checkAllRsvpResponded = async (campaignObjId: string, dispatch?: Dispatch<any>): Promise<any> => {
  return apiClient
    .get<any>(`${ADMIN_URL}/${staffCampaignEndpoint}/agency-campaign/participant/all-responded?id=${campaignObjId}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
