import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import PruRoute from 'src/app/common/components/PruRoute';
import { PULSELEADS_BASE_PATH } from '../../../constants';
import { PULSELEADS_STRAIGHT_THROUGH_PATH, PULSELEADS_STRAIGHT_THROUGH_EVENT_CAMPAIGN_PATH } from '../constants';
import ParamsProvider from 'src/app/common/components/ParamsProvider';
import EventCampaignListingPage, { eventCampaignFilterKeys } from './List/EventCampaignListingPage';
import EventCampaignDetailPage from './Form/EventCampaignDetailPage';

export const eventCampaignPath = `${PULSELEADS_BASE_PATH}${PULSELEADS_STRAIGHT_THROUGH_PATH}${PULSELEADS_STRAIGHT_THROUGH_EVENT_CAMPAIGN_PATH}`;

const EventCampaignRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect exact from={eventCampaignPath} to={`${eventCampaignPath}/list`} />
      <PruRoute exact path={`${eventCampaignPath}/list`} render={(props) => (
        <ParamsProvider {...props} acceptKeys={eventCampaignFilterKeys}>
          <EventCampaignListingPage />
        </ParamsProvider>
      )}/>
      <PruRoute exact path={`${eventCampaignPath}/create`} component={EventCampaignDetailPage}/>
      <PruRoute exact path={`${eventCampaignPath}/edit/:id`} component={EventCampaignDetailPage}/>
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default EventCampaignRoutes;