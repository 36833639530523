import { FC, useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Button } from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { getDayEnd, getDayStart, isDateValid, ErrorFieldType, useErrorHandler } from 'src/app/common/utils';
import { useIntl } from 'react-intl';
import { PruDatePicker } from 'src/app/common/components/PruDatePicker';
import { useDispatch } from 'react-redux';
import { downloadInsight } from '../../../network/agentProfileCrud';
import { getConfig } from 'src/app/i18n';
import AsyncCsvLink from 'src/app/common/components/AsyncCsvLink';
import moment from 'moment';
import { DATE_ERROR_TEXT, MANDATORY_FIELD_ERROR_TEXT } from '../../../types/agent-profile-types';
import { DATE_FORMAT_ERROR_TEXT } from 'src/app/modules/Application/constants';

type BulkUploadRecordProps = Record<string, unknown> & ParamsProps;
export type BulkUploadRecordParam = {
  startDate: Date | null;
  endDate: Date | null;
};

const initialState: BulkUploadRecordParam = {
  startDate: null,
  endDate: null,
};

const paramsInitiator = (initialParams?: Record<string, string>): any => {
  return initialParams
    ? {
        startDate: initialParams.startDate ? getDayStart(new Date(initialParams.startDate)) : null,
        endDate: initialParams.endDate ? getDayEnd(new Date(initialParams.endDate)) : null,
      }
    : initialState;
};
const useStyles = makeStyles()(() => ({
  rowContainer: {
    // display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  rowContainerWithBorder: {
    border: '1px solid rgb(221, 221, 221)',
    padding: '10px',
    paddingBottom: 0,
    width: 700,
  },
  fieldContainer: {
    minWidth: 100,
    boxSizing: 'border-box',
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  popupWrapper: {
    position: 'relative',
    display: 'inline-block',
    marginLeft: '60px',
  },
  cardPos: {
    position: 'absolute',
    left: '100%',
    transform: 'translate(5px, -50%)',
    zIndex: 1,
  },
  mandatory: {
    color: 'red',
  },
  divider: {
    alignItems: 'center',
    display: 'flex',
    '&:before': {
      content: "'-'",
      marginRight: 8,
      marginLeft: 8,
    },
  },
  title: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
  },
}));
type ErrorState = {
  mandatory: Record<string, boolean>;
  immediate: Record<string, boolean>;
};
export const BulkUploadRecordFilterKeys = ['startDate', 'endDate'];
const BulkUploadRecord: FC<BulkUploadRecordProps> = ({ initialParams, onChangeQueryParams }) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [filterState, setFilterState] = useState<BulkUploadRecordParam>(paramsInitiator(initialParams));
  const dispatch = useDispatch();
  const lang = getConfig().selectedLang;
  const { errorState, onSubmitErrorValidator, immediateErrorValidator, onDismissErrorHandler } =
    useErrorHandler<ErrorState>(filterState, [
      {
        name: 'startDate',
        fieldType: ErrorFieldType.MANDATORY,
      },
      {
        name: 'endDate',
        fieldType: ErrorFieldType.MANDATORY,
      },
      {
        name: 'endDateBeforeStartDate',
        fieldType: ErrorFieldType.IMMEDIATE,
        condition: () => {
          if (filterState.startDate && filterState.endDate) {
            return !!moment(new Date(filterState.startDate)).isAfter(moment(new Date(filterState.endDate)));
          } else {
            return false;
          }
        },
      },
      {
        name: 'isValidEndDate',
        fieldType: ErrorFieldType.IMMEDIATE,
        condition: () => {
          if (filterState.endDate) {
            return filterState.endDate && isDateValid(filterState.endDate) ? false : true;
          } else {
            return false;
          }
        },
      },
      {
        name: 'isValidStartDate',
        fieldType: ErrorFieldType.IMMEDIATE,
        condition: () => {
          if (filterState.startDate) {
            return filterState.startDate && isDateValid(filterState.startDate) ? false : true;
          } else {
            return false;
          }
        },
      },
    ]);

  useEffect(
    () => {
      immediateErrorValidator();
    },
    // eslint-disable-next-line
    [filterState.startDate, filterState.endDate, errorState.mandatory.startDate, errorState.mandatory.endDate],
  );

  const onSubmit = async () => {
    const { hasError } = onSubmitErrorValidator();
    if (!hasError) {
      return await downloadInsight(filterState, lang, dispatch);
    } else {
      return '';
    }
  };

  return (
    <div>
      <span className={classes.title}>{Translation('agentProfile.ad_agent_export_header_title')}</span>
      <div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>{Translation('agentProfile.ad_date_picker_label')}:</span>
          </div>
          <>
            <PruDatePicker
              slotProps={{
                textField: {
                  error: errorState.mandatory.startDate || errorState.immediate.isValidStartDate,
                  helperText: errorState.mandatory.startDate
                    ? MANDATORY_FIELD_ERROR_TEXT
                    : errorState.immediate.isValidStartDate && DATE_FORMAT_ERROR_TEXT,
                },
              }}
              format="DD/MM/YYYY"
              value={filterState.startDate}
              onChange={(data) => {
                onDismissErrorHandler('startDate', data);
                setFilterState({ ...filterState, startDate: data });
              }}
            />
            {/* <div style={{ padding: 20 }} /> */}
            <div className={classes.divider} />
            <PruDatePicker
              slotProps={{
                textField: {
                  error:
                    errorState.mandatory.endDate ||
                    errorState.immediate.endDateBeforeStartDate ||
                    errorState.immediate.isValidEndDate,
                  helperText: errorState.mandatory.endDate
                    ? MANDATORY_FIELD_ERROR_TEXT
                    : errorState.immediate.endDateBeforeStartDate
                    ? DATE_ERROR_TEXT
                    : errorState.immediate.isValidEndDate && DATE_FORMAT_ERROR_TEXT,
                  style: { marginRight: 20 },
                },
              }}
              format="DD/MM/YYYY"
              minDate={filterState.startDate}
              value={filterState.endDate}
              onChange={(data) => {
                onDismissErrorHandler('endDate', data);
                setFilterState({ ...filterState, endDate: data });
              }}
            />
          </>
        </div>
        <div style={{ marginTop: 20 }}>
          <AsyncCsvLink
            isDisabled={false}
            filename={`AgentProfileSite_Records_${moment(new Date()).format('YYYYMMDD')}.csv`}
            dataParser={(str) => str}
            asyncCall={() => onSubmit()}
          >
            <Button variant="contained" color="secondary" style={{ borderRadius: 12 }}>
              {Translation('agentProfile.ad_export_button')}
            </Button>
          </AsyncCsvLink>
        </div>
      </div>
    </div>
  );
};

export default BulkUploadRecord;
