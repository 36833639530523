import React, { FC, useState, useEffect } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchSurveyResults } from '../../../network/surveyCrud';
import { NOT_FOUND_ERROR_PAGE_PATH } from '../../../../../common/constants';
import { SurveyResult } from '../../../types/survey-types';
import { LayoutSplashScreen } from 'src/app/layout';
import SurveyAnalyticSection from './components/SurveyAnalyticSection';

const SurveyAnalyticPage: FC<RouteComponentProps> = ({ history, location }) => {
  const { id } = useParams<{ id: string }>();

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [surveyResults, setSurveyResults] = useState<SurveyResult>();

  const reloadData = () => {
    if (id) {
      setIsLoading(true);
      fetchSurveyResults(id, dispatch)
        .then((result) => {
          setSurveyResults(result);
        })
        .catch(() => {
          history.push(NOT_FOUND_ERROR_PAGE_PATH);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line
  }, [id]);

  return (
    <>{isLoading || !surveyResults ? <LayoutSplashScreen /> : <SurveyAnalyticSection results={surveyResults} />}</>
  );
};

export default SurveyAnalyticPage;
