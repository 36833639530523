import React, { FC, useState, useEffect } from 'react';
import { Button, Dialog } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useIntl } from 'react-intl';
import ProTable from 'src/app/common/components/ProTable';
import columns  from './columns';
import { getCourseListByKeyword } from '../../../../../network/collectionDetailCrud';

const useStyles = makeStyles()((theme) => ({
    modalButtons: {
        marginTop: '15px',
        marginBottom: 15,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    dialog: {
        padding: '15px'
    },
    dialogBox: {
        with: '12000px'
    },
    noCourseError: {
        textAlign: 'center',
        color: 'red'
    }
}));


const SearchCourseBox: FC<any> = (props) => {

    const { onSelectData, popUpVisible, setPopUpVisible, couseList} = props;

    const filterInitData = {
        courseName: ''
    }

    const { classes } = useStyles();
    const intl = useIntl();
    const Translation = (id: string) => intl.formatMessage({ id });
    // eslint-disable-next-line
    const [formLoading, setFormLoading] = useState<boolean>(false); 
    // eslint-disable-next-line
    const [filterData, setFilterData] = useState<{ [key: string]: any }>(filterInitData);
    // eslint-disable-next-line
    const [pageData, setPageData] = useState<{ [key: string]: any }>({
        pageNum: 0,
        pageSize: 5
    });
    const [selectedRowsState, setSelectedRows] = useState<any>(null);
    const [displayNoCourseError, setDisplayNoCourseError] = useState<boolean>(false);


    const onCancel = () => {
        setPopUpVisible(false);
    }

    const formatEventData = async (newFilterState?: any) => {
        const newFilter = newFilterState || {}
        const requestParams = {
            ...filterData,
            ...newFilter,
        }

        requestParams.pageNum = requestParams.page;

        setFilterData(requestParams);


        const result = await getCourseListByKeyword(requestParams);

        const res = {
            data: result.data?.data || [],
            success: true,
            total: result.data?.total
        };

        return res;
    }


    const fetchData = (newFilterState?: any): Promise<any> => {
        setFormLoading(true);
        setDisplayNoCourseError(false);
        return formatEventData(newFilterState);
    }

    const onImport = () => {
        if (!selectedRowsState) {
            setDisplayNoCourseError(true);
        } else {
            onSelectData(selectedRowsState);
            setPopUpVisible(false);
            setSelectedRows(null)
            setDisplayNoCourseError(false);
        }
    }

    return (
        <div style={{ width: '100%', alignSelf: 'flex-start' }}>
            <Dialog open={popUpVisible} onClose={onCancel} scroll={"paper"} fullWidth maxWidth="lg">
                <>
                    <div style={{ display: 'flex', marginTop: 20 }}>
                        <Button
                            onClick={onCancel}
                            style={{ marginLeft: 20 }}
                            variant="contained"
                        >
                            {Translation('global.back.btnText')}
                        </Button>
                    </div>
                    <div style={{ padding: 20, paddingTop: 0 }}>
                        <ProTable
                            rowKey="key"
                            filterTitle={Translation("mandatory.training.component.search.course.filter")}
                            headerTitle={''}
                            columns={columns(Translation, filterData.courseName)}
                            pageSize={50}
                            request={(params, sorter, filter) => {
                                return fetchData(params)
                            }}
                            toolBarRender={() => [

                            ]}
                            rowSelection={{
                                onChange: (_: any, selectedRows: any) => {
                                    const selectCourse = selectedRows && selectedRows[0];

                                    if (selectCourse) {
                                        delete selectCourse.updatedAt;
                                        delete selectCourse.updatedBy;
                                        setSelectedRows(selectedRows[0]);
                                        setDisplayNoCourseError(false);
                                    }

                                },
                                getCheckboxProps: (record: any) => {
                                    return {
                                        disabled: !!couseList.find((item: any) => item.courseCode === record.courseCode)
                                    }
                                },
                                selectItems: [selectedRowsState]
                            }}
                            singleSelect={true}
                        />
                    </div>
                    { displayNoCourseError && <div className={classes.noCourseError}>Please select the course</div> }
                    <div className={classes.modalButtons}>
                        <Button
                            onClick={onCancel}
                            style={{ marginLeft: 20 }}
                            variant="contained"
                        >
                            {Translation('app.button.cancel')}
                        </Button>
                        <Button
                            onClick={onImport}
                            style={{ marginLeft: 20 }}
                            variant="contained"
                            color="secondary"
                        >
                            {Translation('mandatory.training.component.table.course.import')}
                        </Button>
                    </div>
                </>
            </Dialog>
        </div>
    )
};

export default SearchCourseBox;
