import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import PruRoute from 'src/app/common/components/PruRoute';
import { PROFILE_BASE_PATH, PROFILE_CONFIG_BANNER_PATH, PROFILE_CONFIG_PATH } from '../../../constants';
import ParamsProvider from 'src/app/common/components/ParamsProvider';
import { configBannerTierFilterKeys, ConfigBannerTierListingPage } from './List/ConfigBannerTierListingPage';
import BannerTierDetailPage from './Form/BannerTierDetailPage';

export const configBannerTierPath = `${PROFILE_BASE_PATH}${PROFILE_CONFIG_PATH}${PROFILE_CONFIG_BANNER_PATH}`;

const ConfigBannerTierRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect exact from={configBannerTierPath} to={`${configBannerTierPath}/list`} />
      <PruRoute
        exact
        path={`${configBannerTierPath}/list`}
        render={(props) => (
          <ParamsProvider {...props} acceptKeys={configBannerTierFilterKeys}>
            <ConfigBannerTierListingPage />
          </ParamsProvider>
        )}
      />

      <PruRoute exact path={`${configBannerTierPath}/create`} component={BannerTierDetailPage} />
      <PruRoute exact path={`${configBannerTierPath}/edit/:id`} component={BannerTierDetailPage} />

      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default ConfigBannerTierRoutes;
