import { NotificationType as NotificitionEnumType, OpenInDevice, RedirectType } from '../constants';

export type NotificationType = keyof typeof NotificitionEnumType;

export enum FetchStatus {
  WAIT_FOR_FETCH = 'WAIT_FOR_FETCH',
  FETCHING = 'FETCHING',
  FETCHED = 'FETCHED',
}

// PCAAEB-39681
export enum AutoNotiTemplateEnum {
  SLA_EXPIRY_REMINDER = 'sla_expiry_reminder',
  SYS_TURN_OFF_ACCEPT_REMINDER = 'system_turns_off_accept_lead_toggle_reminder',
  TURNED_OFF_ACCEPT_REMINDER = 'turned_off_accept_reminder',
}

export type NotificationTemplateListItem = {
  [key: string]: { count: number; lastUpdatedAt: Date; lastUpdatedBy: string };
};

export enum AutoNotiTriggerTypeEnum {
  // sla_expiry_reminder
  ACCEPT_SLA_EXPIRY = 'accept_sla_expiry',
  CONTACT_SLA_EXPIRY = 'contact_sla_expiry',
  MEET_SLA_EXPIRY = 'meet_sla_expiry',
  PROPOSAL_SLA_EXPIRY = 'proposal_sla_expiry',
  APPLICATION_SLA_EXPIRY = 'application_sla_expiry',
  ACCEPT_SLA_PULL_BACK = 'accept_sla_pull_back',
  CONTACT_SLA_PULL_BACK = 'contact_sla_pull_back',

  // system_turns_off_accept_lead_toggle_reminder
  SYS_TURNS_OFF_ACCEPT_TOGGLE = 'sys_turns_off_accept_toggle',

  // turned_off_accept_reminder
  TURNED_OFF_ACCEPT_TOGGLE = 'accept_toggle_off',
}

export enum AutoNotiTriggerMethodEnum {
  BEFORE = 'before',
  WHEN = 'when',
  AFTER = 'after',
  PERIODIC = 'periodic',
}

export enum AutoNotiChannelEnum {
  PUSH = 'push',
  Inbox = 'inbox',
}

export type NotificationTemplateItem = {
  _id?: string;
  category: string;
  template: AutoNotiTemplateEnum;
  triggerTime: { method: AutoNotiTriggerMethodEnum; type: AutoNotiTriggerTypeEnum; param?: string; value?: number };
  channel: Array<AutoNotiChannelEnum>;
  title: { en: string; [locale: string]: string };
  content: { en: string; [locale: string]: string };
  redirectParams: {
    redirectTo: RedirectType;
    openBy?: OpenInDevice | '';
    link?: string;
    params?: { [id: string]: string };
  };
};

export type NotificationItem = {
  id: string;
  category: string;
  creator?: string;
  createTime: Date;
  modifier?: string;
  modifyTime?: Date;
  sendingTime: Date;
  notificationType: NotificationType;
  receiverType: string;
  msgTitle: string;
  msgContent: string;
  pushTitle?: string;
  pushContent?: string;
  contentType: string;
  extendValue?: {
    redirectTo: string;
    openBy: string;
    link: string;
  };
  readStatus?: boolean;
  top?: boolean;
  triggertime?: string;
  inboxStatus?: string;
  sendImmediately?: boolean;
  receivers?: {
    id: string;
    fileName: string;
    url?: string;
  };
  i18nData?: {
    [code: string]: I18DataItem;
  };
  importId?: string;
  topEnd?: Date;
  topStart?: Date;
  agentCodeList?: string[];
};

export type PaginateList = {
  total: number;
  size: number;
  page: number;
  values: NotificationItem[];
};

export type I18DataItem = {
  msgTitle?: string;
  msgContent?: string;
  pushTitle?: string;
  pushContent?: string;
};

export type FileObj = {
  url?: string;
  importId: string;
  fileName: string;
  inclusive?: boolean;
  receiverType?: string;
  contentType?: string;
};

export type NotificationDetail = {
  creator?: string;
  notificationType: NotificationType | '';
  category: string;
  contentType: string;
  msgTitle: string;
  msgContent: string;
  pushTitle: string;
  pushContent: string;
  extendValue?: {
    redirectTo: string;
    openBy: string;
    link: string;
    params: any;
  };
  receiverType?: string;
  // receivers?: {
  //   id: string;
  //   fileName: string;
  //   url?: string
  // }
  sendingTime?: Date | null;
  triggertime?: string;
  sendImmediately?: boolean;
  importId?: string;
  fileName: string;
  i18nData: {
    [code: string]: I18DataItem;
  };
  roleList: string[];
  zoneList: string[];
  filters: FileObj[];
  historyReceiverType?: string;
  from: string;
};

export interface OnTop {
  modifier: string;
  top: boolean | string;
  topEnd?: Date;
  topStart?: Date;
}

export type i18nConfigItem = {
  code: string;
  name: string;
};
