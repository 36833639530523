import { Dispatch } from 'react';
import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import { ChannelCampaignItem } from '../types/channel-campaign-types';
import { forEach } from 'lodash';
import { PaginateList } from 'src/app/common/types/common-types';

const channelCampaignEndPoint = 'channel-campaign';

export type ChannelCampaignListParam = {
  channelCode: string;
  campaignName: string;
  page: number;
  limit: number;
};

export type CreateChannelCampaignBody = {
  channelCode: string;
  campaign: string;
  createdBy: string;
  updatedBy: string;
};

export type UpdateChannelCampaignBody = {
  channelCode: string;
  campaign: string;
  updatedBy: string;
};

export const fetchChannelCampaignList = async (params: ChannelCampaignListParam, dispatch?: Dispatch<any>) => {
  let queryPath = `${ADMIN_URL}/${channelCampaignEndPoint}?`;
  forEach(params, (param, key) => {
    if (key === 'page') {
      queryPath += `${key}=${encodeURIComponent(param as any)}&`;
    } else {
      queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
    }
  });
  return apiClient
    .get<PaginateList<ChannelCampaignItem>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchChannelCampaignItem = async (id: string, dispatch?: Dispatch<any>): Promise<ChannelCampaignItem> => {
  return apiClient
    .get<ChannelCampaignItem>(`${ADMIN_URL}/${channelCampaignEndPoint}/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createChannelCampaign = async (
  body: CreateChannelCampaignBody,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .post<void>(`${ADMIN_URL}/${channelCampaignEndPoint}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const modifyChannelCampaign = async (
  id: string,
  body: UpdateChannelCampaignBody,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .put<void>(`${ADMIN_URL}/${channelCampaignEndPoint}/${id}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const deleteChannelCampaign = async (id: string, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .delete<void>(`${ADMIN_URL}/${channelCampaignEndPoint}/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
