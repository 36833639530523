import { ComponentCollection, Serializer } from 'survey-core';
export const RegionPhone = {
  // A unique name; must use lowercase
  name: 'regionphone',
  // A display name used in the Toolbox
  title: 'Region Phone',
  fieldType: 'standard',
  // An array of JSON schemas that configure the nested questions
  elementsJSON: [
    {
      type: 'dropdown',
      name: 'region',
      title: 'Region',
      titleLocation: 'hidden',
      isRequired: true,
      fieldType: 'Standard',
      width: '40%',
      minWidth: '40%',
      maxWidth: '40%',
      startWithNewLine: false,
    },
    {
      type: 'text',
      name: 'phone',
      title: 'Phone',
      titleLocation: 'hidden',
      isRequired: true,
      fieldType: 'Standard',
      width: '60%',
      minWidth: '60%',
      maxWidth: '60%',
      startWithNewLine: false,
    },
  ],
  onInit() {
    //SurveyJS will create a new class "fullname". We can add properties for this class onInit()
    Serializer.addProperty('fieldType', {
      name: 'fieldType',
      default: 'Standard',
      category: 'general',
    });
  },
};
