import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { useIntl } from 'react-intl';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useDataProvider } from 'src/app/common/utils';
import {
  DistributionRuleListParam,
  fetchDistributionRuleList,
} from 'src/app/modules/PulseLeads/network/distributionRuleCrud';
import { DistributionRule } from 'src/app/modules/PulseLeads/types/distribution-rule-types';
import { PaginateList } from 'src/app/common/types/common-types';
import DistributionRuleList from './components/DistributionRuleList';

type DistributionRuleListingPageProps = {} & ParamsProps;

const initialState: DistributionRuleListParam = {
  name: '',
  sortBy: '',
  limit: 5,
  page: 1,
};

const paramsInitiator = (initialParams?: Record<string, string>): DistributionRuleListParam => {
  return initialParams
    ? {
        name: initialParams.name ?? '',
        sortBy: '',
        limit: 5,
        page: 1,
      }
    : initialState;
};

export const distributionRuleFilterKeys = ['name'];

const DistributionRuleListingPage: FC<DistributionRuleListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const [filterState, setFilterState] = useState<DistributionRuleListParam>(paramsInitiator(initialParams));

  const [distributionRuleList, setDistributionRuleList] = useState<PaginateList<DistributionRule>>();
  const { isLoading, refreshData } = useDataProvider<PaginateList<DistributionRule>>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchDistributionRuleList(filterState, dispatch);
      } catch (err) {}
    },
    setDistributionRuleList,
    false,
  );

  return (
    <>
      <PruFilter
        title={Translation('pulseleads.distributionRule.filter')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'name',
            initialValue: filterState.name,
            displayName: Translation('pulseleads.distributionRule.common.name'),
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            name: data.name,
          })
        }
        fetchData={refreshData}
      />
      <DistributionRuleList
        isLoading={isLoading}
        distributionRuleList={distributionRuleList}
        onRefresh={refreshData}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default DistributionRuleListingPage;
