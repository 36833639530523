import React, { FC, useState } from 'react';
import { ParamsProps } from '../../../../../common/components/ParamsProvider';
import { NewsArticleListParam, fetchNewsArticleList, fetchNewsCategoryList } from '../../../network/newsCrud';
import { useDataProvider } from 'src/app/common/utils';
import { NewsArticlePaginateList, NewsCategoryPaginateList } from '../../../types/news-type';
import { useDispatch } from 'react-redux';
import NewsArticleList from './components/NewsArticleList';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { useIntl } from 'react-intl';
import { useServerLocale } from 'src/app/i18n';

type NewsListingPageProps = {} & ParamsProps;

const categoryListParam = {
  limit: 5,
  page: 1,
};

const initialState: NewsArticleListParam = {
  limit: 5,
  page: 1,
  categoryId: '',
  search: '',
  isHeadline: '',
  isPublished: '',
  isArchived: '',
};

const paramsInitiator = (initialParams?: Record<string, string>): NewsArticleListParam => {
  return initialParams
    ? {
        page: 1,
        limit: 5,
        categoryId: initialParams.categoryId ?? '',
        search: initialParams.search ?? '',
        isHeadline: initialParams.isHeadline ?? '',
        isPublished: initialParams.isPublished ?? '',
        isArchived: initialParams.isArchived ?? '',
      }
    : initialState;
};

export const newsArticleFilterKeys = ['categoryId', 'search', 'isHeadline', 'isPublished'];

const NewsListingPage: FC<NewsListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const locale = useServerLocale();
  const dispatch = useDispatch();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const [filterState, setFilterState] = useState<NewsArticleListParam>(paramsInitiator(initialParams));

  const [newsArticleList, setNewsArticleList] = useState<NewsArticlePaginateList>();
  const { isLoading, refreshData } = useDataProvider<NewsArticlePaginateList>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchNewsArticleList(filterState, dispatch);
      } catch (err) {}
    },
    setNewsArticleList,
    false,
  );

  const [categoryList, setCategoryList] = useState<NewsCategoryPaginateList>();
  useDataProvider<NewsCategoryPaginateList>(
    async () => {
      try {
        return await fetchNewsCategoryList(categoryListParam, dispatch);
      } catch (err) {}
    },
    setCategoryList,
    false,
  );

  return (
    <>
      <PruFilter
        title={Translation('newsArticle.filter')}
        itemDef={[
          {
            type: PruFilterItemType.DROPDOWN,
            style: { width: 200 },
            field: 'categoryId',
            initialValue: filterState.categoryId,
            displayName: Translation('newsArticle.common.category'),
            choices: [
              { displayName: '', value: '' },
              ...(categoryList?.categories?.map((category) => {
                return {
                  displayName: category.name[locale],
                  value: category._id,
                };
              }) || []),
            ],
          },

          {
            type: PruFilterItemType.DROPDOWN,
            style: { width: 200 },
            field: 'isHeadline',
            initialValue: filterState.isHeadline,
            displayName: Translation('newsArticle.common.isHeadline'),
            choices: [
              { displayName: Translation('component.status.all'), value: '' },
              { displayName: Translation('newsArticle.common.true'), value: 'true' },
              { displayName: Translation('newsArticle.common.false'), value: 'false' },
            ],
          },
          {
            type: PruFilterItemType.DROPDOWN,
            style: { width: 200 },
            field: 'isPublished',
            initialValue: filterState.isPublished,
            displayName: Translation('newsArticle.common.isPublished'),
            choices: [
              { displayName: Translation('component.status.all'), value: '' },
              { displayName: Translation('newsArticle.common.true'), value: 'true' },
              { displayName: Translation('newsArticle.common.false'), value: 'false' },
            ],
          },
          {
            type: PruFilterItemType.DROPDOWN,
            style: { width: 200 },
            field: 'isArchived',
            initialValue: filterState.isArchived,
            displayName: Translation('newsArticle.common.isArchived'),
            choices: [
              { displayName: Translation('component.status.all'), value: '' },
              { displayName: Translation('newsArticle.common.true'), value: 'true' },
              { displayName: Translation('newsArticle.common.false'), value: 'false' },
            ],
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            style: { width: 250 },
            field: 'search',
            initialValue: filterState.search,
            displayName: Translation('newsArticle.common.keyword'),
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            categoryId: data.categoryId,
            search: data.search,
            isPublished: data.isPublished || undefined,
            isHeadline: data.isHeadline || undefined,
            isArchived: data.isArchived || undefined,
          })
        }
        fetchData={refreshData}
      />
      <NewsArticleList
        isLoading={isLoading}
        newsArticleList={newsArticleList}
        onRefresh={refreshData}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default NewsListingPage;
