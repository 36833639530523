import React, { useState, useEffect, FC } from 'react';
import Form from 'src/app/common/components/Form';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { styles } from '../style';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { makeStyles } from 'tss-react/mui';
import { Radio, RadioGroup, FormControlLabel, TextField, Button, Dialog, DialogTitle, Link } from '@mui/material';
import { TargetProspectType } from './../utils';
import FileUploader, { FileObj } from 'src/app/common/components/FileUploader';
import { fileUpload } from 'src/app/common/utils';
import { createBlob, getBlob } from 'src/app/common/network';
import { getTemplate, checkTemplate } from './../api';
import ErrorList from './ErrorList';

const MaterialArea: FC<any> = (props) => {
  const intl = useIntl();
  const { classes } = styles();
  const { classes: commonClasses } = useCommonStyles();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const DEFAULT_REQUIRED_RULES = { required: true, message: Translation('component.form-required-text') };
  const { form } = props;

  const errorList = form.getFieldValue('errorList');
  const initialValues = form.getFieldsValue(true);
  const [errorListVisible, setErrorListVisible] = useState<boolean>(false);
  const [isCheckingTemplate, setIsCheckingTemplate] = useState<boolean>(false);
  const [isCheckingFailed, setIsCheckingFailed] = useState<boolean>(false);

  const uploadFileMockStatus = async ({
    file,
  }: {
    file: any;
    fileName: string;
    fileType: string;
    description?: string;
    setLoadPercentage: (p: number) => void;
  }) => {
    const createBlobRes = await createBlob(
      { mimeType: file.type, accessLevel: 'public', module: 'lead-engagement' },
      dispatch,
    );
    await fileUpload(createBlobRes.url, file);
    const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);
    const result = blobDetail[0];
    if (result) {
      return result;
    } else {
      throw new Error('upload failed');
    }
  };

  const onDownloadTemplate = () => {
    getTemplate(dispatch);
  };

  const resetFormFields = () => {
    form.setFieldsValue({
      templateId: '',
      errorList: undefined,
    });
  };

  const onCheckFile = async (file: any) => {
    setIsCheckingTemplate(true);

    checkTemplate(
      {
        id: file.blobId,
        name: file.fileName,
      },
      dispatch,
    )
      .then((res) => {
        setIsCheckingTemplate(false);
        setIsCheckingFailed(false);

        form.setFieldsValue({
          templateId: res?.data?._id,
          errorList: res?.data?.errorLog,
        });
      })
      .catch((err) => {
        console.error(err);
        resetFormFields();
        setIsCheckingTemplate(false);
        setIsCheckingFailed(true);
      });
  };

  const displayErrorList = async () => {
    setErrorListVisible(true);
  };

  return (
    <div>
      <div style={{ marginBottom: 30 }}>
        <span className={commonClasses.subHeader}>Target Prospect</span>
        <span style={{ color: 'red' }}>*</span>
        <span>
          (Please note if the prospect’s related agent is not the material target receiver, will not display the
          material to the prospect)
        </span>
      </div>

      <Form.Item name={'targetType'} rules={[DEFAULT_REQUIRED_RULES]}>
        <RadioGroup className={classes.group}>
          {Object.entries(TargetProspectType).map(([label, value]) => {
            return (
              <FormControlLabel
                className={classes.radio}
                key={value}
                value={value}
                control={<Radio />}
                label={Translation(`engagement.topic.target.prospect.type.${label}`)}
              />
            );
          })}
        </RadioGroup>
      </Form.Item>

      <Form.Item
        // noStyle
        style={{ marginLeft: 20 }}
      >
        {(_, meta, { getFieldValue }) => {
          const specifiyTargetType = getFieldValue('targetType') === TargetProspectType.SPECIFIY;

          return specifiyTargetType ? (
            <Form.Item label={''} hideDot={true} noStyle name="targeProspectFile">
              {(_, meta, { getFieldValue }) => {
                return (
                  <div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <div>
                        <FileUploader
                          maxFileSize={10}
                          disabled={false}
                          upload={uploadFileMockStatus}
                          download={() => {}}
                          value={initialValues.targeProspectFile}
                          allowedFileTypes={['xlsx', 'csv']}
                          onChange={(data) => {
                            form.setFieldsValue({ targeProspectFile: data });
                            if (!data) {
                              resetFormFields();
                            }
                            data && onCheckFile(data);
                          }}
                        />

                        {!initialValues.targeProspectFile && (
                          <Button color="secondary" onClick={onDownloadTemplate}>
                            Download template
                          </Button>
                        )}
                      </div>

                      {errorList && errorList.length === 0 && initialValues.targeProspectFile && (
                        <Button style={{ color: 'green', marginLeft: 15 }}>Passed</Button>
                      )}

                      {errorList && errorList.length > 0 && initialValues.targeProspectFile && (
                        <Button
                          style={{ color: 'red', marginLeft: 15, textDecoration: 'underline' }}
                          onClick={displayErrorList}
                        >
                          {errorList.length} errors
                        </Button>
                      )}

                      {!errorList && initialValues.targeProspectFile && (
                        <Button
                          style={{ color: isCheckingFailed ? 'red' : '', marginLeft: 15, textDecoration: 'underline' }}
                          onClick={() => onCheckFile(initialValues.targeProspectFile)}
                          disabled={isCheckingTemplate}
                        >
                          {isCheckingFailed
                            ? 'Check File API down, please retry'
                            : isCheckingTemplate
                            ? 'checking'
                            : 'check template'}
                        </Button>
                      )}
                    </div>
                  </div>
                );
              }}
            </Form.Item>
          ) : null;
        }}
      </Form.Item>

      <Dialog
        open={errorListVisible}
        onClose={() => {
          setErrorListVisible(false);
        }}
        scroll={'paper'}
        fullWidth
      >
        <ErrorList errorList={errorList} popUpVisible={errorListVisible} setPopUpVisible={setErrorListVisible} />
      </Dialog>
    </div>
  );
};

export default MaterialArea;
