import { ReactElement } from 'react';

export enum StepStatusEnum {
  DRAFT = 'draft',
  PROGRESS = 'progress',
  FINISHED = 'finished',
  WARNING = 'warning',
}

export type FormCommonProps = {
  isLoading: boolean;
  changesUnsaved: boolean;
  isReset: boolean;
  setIsLoading: (value: boolean) => void;
  setChangesUnsaved: (value: boolean) => void;
  setIsReset: (value: boolean) => void;
};

export type OnStepChangeProps = {
  newActiveStep: StepIndicator;
  currentStepStatus?: StepStatusEnum;
  forceNavigation?: boolean;
  saveChanges?: boolean;
};

export type ComponentUpdateStepStatusProps = {
  newStatus: StepStatusEnum;
  forceNavigation?: boolean;
  saveChanges?: boolean;
  stepIndicatorList?: StepIndicator[];
};

export type ComponentProps<T extends FormCommonProps> = {
  formCommonProps: T;
  onStepChange: (props: OnStepChangeProps) => void;
  componentUpdateStepStatus: (props: ComponentUpdateStepStatusProps) => void;
  showToast: (message: string) => void;
  setDiscardChanges: (discardChanges?: () => void) => void;
};

export type StepItem<T extends FormCommonProps> = {
  label: string;
  title?: string;
  status?: StepStatusEnum;
  disableStatus?: boolean;
  hidden?: (formCommonProps: T) => boolean;
  component?: (props: ComponentProps<T>) => ReactElement;
  componentClassName?: string;
  customActiveStep?: StepIndicator;
  child?: StepItem<T>[];
};

export type StepIndicator = {
  stepIndex: number;
  child?: StepIndicator;
};

export type UpdateCheckerProps = {
  formState: any;
  isReset: boolean;
  setChangesUnsaved: (value: boolean) => void;
  setIsReset: (value: boolean) => void;
};
