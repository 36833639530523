import { makeStyles } from 'tss-react/mui';

export const InsuranceSummaryStyles = makeStyles()((theme) => ({
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,

    backgroundColor: theme.palette.common.white,
  },
  designationRow: {
    display: 'flex',
  },
  fieldContainer: {
    width: 150,

    boxSizing: 'border-box',
  },
  input: {},
  field: {
    fontSize: '1rem',
    fontWeight: 500,

    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },
  dateDivider: {
    marginLeft: 8,
    marginRight: 8,
  },
  textAreaRowContainer: {
    width: '100%',
    display: 'flex',
  },
  sectionMargin: {
    marginBottom: 20,
  },
  divideMargin: {
    marginBottom: 10,
  },
  subHeader: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
  },
  accordHeading: {
    fontSize: '16px',
    fontWeight: 500,
  },
  designationContainer: {
    padding: '10px',
    borderRadius: 5,
    backgroundColor: '#F5F5F5',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  consentText: {
    fontSize: '12px',
    fontWeight: 500,
  },
  imageContainer: {
    width: 160,
    // height: 160,
    overflow: 'hidden',
    borderRadius: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },
  textfieldContainer: {
    flex: 1,
    display: 'flex',
  },
  closeIcon: {
    width: 24,
    height: 24,
    verticalAlign: 'middle',
    top: '0px',
    right: '0px',
    // left: '10%',
    zIndex: 1,
    position: 'absolute',
  },
  formCloseIcon: {
    width: 18,
    height: 18,
  },
  title: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '22px',
  },
}));
