export const INSIGHTS_BASE_PATH = '/insights';

export const INSIGHTS_CAMPAIGN_PATH = '/campaign';
export const INSIGHTS_DASHBOARD_PATH = '/dashboard';
export const INSIGHTS_DASHBOARD_USER_PATH = '/user';
export const INSIGHTS_DASHBOARD_REGION_PATH = '/region';
export const INSIGHTS_DASHBOARD_OVERVIEW_PATH = '/overview';
export const INSIGHTS_DASHBOARD_SUMMARY_PATH = '/summary';

//  These constants map to aside menu, header menu to display title
export const INSIGHTS_TITLE = 'title.insights';
export const INSIGHTS_CAMPAIGN_TITLE = 'title.insights.campaign';
export const INSIGHTS_DASHBOARD_TITLE = 'title.insights.dashboard';
export const INSIGHTS_DASHBOARD_USER_TITLE = 'title.insights.dashboard.aolUser';
export const INSIGHTS_DASHBOARD_REGION_TITLE = 'title.insights.dashboard.aolRegionBranch';
export const INSIGHTS_DASHBOARD_OVERVIEW_TITLE = 'title.insights.dashboard.aolOverview';
export const INSIGHTS_DASHBOARD_SUMMARY_TITLE = 'title.insights.dashboard.aolSummary';

//  These constants map to permission control, identical to permission enum defined below
export const INSIGHTS_NAMESPACE = 'INSIGHTS';
export const INSIGHTS_DASHBOARD_USER_NAMESPACE = 'INSIGHTS-DASHBOARD-USER';
export const INSIGHTS_DASHBOARD_REGION_NAMESPACE = 'INSIGHTS-DASHBOARD-REGION';
export const INSIGHTS_DASHBOARD_OVERVIEW_NAMESPACE = 'INSIGHTS-DASHBOARD-OVERVIEW';
export const INSIGHTS_DASHBOARD_SUMMARY_NAMESPACE = 'INSIGHTS-DASHBOARD-SUMMARY';

export enum AdminInsightsPermissionType {
  INSIGHTS_LEAD_CAMPAIGN_READ = 'READ_ANY_INSIGHTS',
  INSIGHTS_LEAD_CAMPAIGN_CREATE = 'CREATE_ANY_INSIGHTS',
  INSIGHTS_LEAD_CAMPAIGN_UPDATE = 'UPDATE_ANY_INSIGHTS',

  INSIGHTS_DASHBOARD_USER_READ = 'READ_ANY_INSIGHTS-DASHBOARD-USER',
  INSIGHTS_DASHBOARD_USER_CREATE = 'CREATE_ANY_INSIGHTS-DASHBOARD-USER',
  INSIGHTS_DASHBOARD_USER_UPDATE = 'UPDATE_ANY_INSIGHTS-DASHBOARD-USER',

  INSIGHTS_DASHBOARD_REGION_READ = 'READ_ANY_INSIGHTS-DASHBOARD-REGION',
  INSIGHTS_DASHBOARD_REGION_CREATE = 'CREATE_ANY_INSIGHTS-DASHBOARD-REGION',
  INSIGHTS_DASHBOARD_REGION_UPDATE = 'UPDATE_ANY_INSIGHTS-DASHBOARD-REGION',

  INSIGHTS_DASHBOARD_OVERVIEW_READ = 'READ_ANY_INSIGHTS-DASHBOARD-OVERVIEW',
  INSIGHTS_DASHBOARD_OVERVIEW_CREATE = 'CREATE_ANY_INSIGHTS-DASHBOARD-OVERVIEW',
  INSIGHTS_DASHBOARD_OVERVIEW_UPDATE = 'UPDATE_ANY_INSIGHTS-DASHBOARD-OVERVIEW',

  INSIGHTS_DASHBOARD_SUMMARY_READ = 'READ_ANY_INSIGHTS-DASHBOARD-SUMMARY',
  INSIGHTS_DASHBOARD_SUMMARY_CREATE = 'CREATE_ANY_INSIGHTS-DASHBOARD-SUMMARY',
  INSIGHTS_DASHBOARD_SUMMARY_UPDATE = 'UPDATE_ANY_INSIGHTS-DASHBOARD-SUMMARY',
}
