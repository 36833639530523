import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import PruRoute from 'src/app/common/components/PruRoute';
import { PULSELEADS_BASE_PATH } from '../../../constants';
import { PULSELEADS_STRAIGHT_THROUGH_PATH, PULSELEADS_STRAIGHT_THROUGH_PRODUCT_CAMPAIGN_PATH } from '../constants';
import ParamsProvider from 'src/app/common/components/ParamsProvider';
import ProductCampaignListingPage, { productCampaignFilterKeys } from './List/ProductCampaignListingPage';
import ProductCampaignDetailPage from './Form/ProductCampaignDetailPage';

export const productCampaignPath = `${PULSELEADS_BASE_PATH}${PULSELEADS_STRAIGHT_THROUGH_PATH}${PULSELEADS_STRAIGHT_THROUGH_PRODUCT_CAMPAIGN_PATH}`;

const ProductCampaignRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect exact from={productCampaignPath} to={`${productCampaignPath}/list`} />
      <PruRoute
        exact
        path={`${productCampaignPath}/list`}
        render={(props) => (
          <ParamsProvider {...props} acceptKeys={productCampaignFilterKeys}>
            <ProductCampaignListingPage />
          </ParamsProvider>
        )}
      />
      <PruRoute exact path={`${productCampaignPath}/create`} component={ProductCampaignDetailPage} />
      <PruRoute exact path={`${productCampaignPath}/edit/:id`} component={ProductCampaignDetailPage} />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default ProductCampaignRoutes;
