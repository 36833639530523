import React, { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { FormControlLabel, RadioGroup } from '@mui/material';
import { CheckCircle, RadioButtonUnchecked } from '@mui/icons-material';
import { useWorkflowStyles } from 'src/app/modules/AgencyCampaign/pages/CampaignType/Form/pages/PageThree/CampaignTypeFormPageThree';
import { AddLeadSettingItem, OperatorEnum } from 'src/app/modules/AgencyCampaign/types/campaign-type-types';
import { Translation } from 'src/app/i18n';
import AddLeadSettingForm from './add-lead-setting-form';
import Form from 'src/app/common/components/Form';
import AcmRadio from 'src/app/modules/AgencyCampaign/common/AcmRadio';
import { getConvertedClasses } from 'src/app/common/helpers/tw-convert-helpers';

type AddLeadSettingProps = {
  onLoaded: Function;
  addLeadSetting?: AddLeadSettingItem | any;
};
export const initialAddLeadSetting: AddLeadSettingItem = {
  selfAddEnabled: '',
  needACMApproved: true,
  startDateSetting: {
    operator: OperatorEnum.EQ,
    dependsOn: 'startDate',
    value: 0,
  },
  endDateSetting: {
    operator: OperatorEnum.GTE,
    dependsOn: 'startDate',
    // value: 100,
  },
};

const getTranslations = () => {
  return {
    add_lead: Translation('add_lead'),
    enable_self_create_lead: Translation('enable_self_create_lead'),
  };
};

const AddLeadSetting: FC<AddLeadSettingProps> = ({ onLoaded, addLeadSetting }) => {
  useEffect(() => {
    onLoaded({ form });
    if (addLeadSetting) {
      setShow(addLeadSetting.selfAddEnabled === 'true');
    }
  }, []);
  const currentLeadSetting = { ...initialAddLeadSetting, ...addLeadSetting };
  const DEFAULT_REQUIRED_RULES = {
    required: true,
    message: Translation('component.pulseleads.prospect.form-required-text'),
  };
  const [form] = Form.useForm();
  const [showSection, setShow] = useState(currentLeadSetting.selfAddEnabled === 'true');
  const { classes: workflowClasses } = useWorkflowStyles();

  return (
    <Form form={form} className={`add-form-wraper form-wraper`} initialValues={currentLeadSetting}>
      <div className={`${workflowClasses.rowContainer} ${workflowClasses.largerBottomSpacing}`}>
        <div className="col-3">
          <span className={workflowClasses.settingTitle}>{getTranslations().add_lead}</span>
        </div>

        <div className="col-9">
          <div className="tw-flex tw-flex-row">
            <Form.Item name="selfAddEnabled" rules={[DEFAULT_REQUIRED_RULES]}>
              <span className="tw-flex tw-flex-row  tw-items-center">
                <span className="tw-whitespace-nowrap tw-w-44">{getTranslations().enable_self_create_lead}</span>
                <RadioGroup
                  onChange={(e) => {
                    setShow(e.target.value === 'true');
                  }}
                  defaultValue={currentLeadSetting.selfAddEnabled}
                  style={{ ...getConvertedClasses('flex flex-row items-center pt-2'), marginLeft: '11px', flexDirection: 'row' }}
                >
                  <FormControlLabel
                    value="true"
                    control={<AcmRadio icon={<RadioButtonUnchecked />} checkedIcon={<CheckCircle />} />}
                    label={Translation(`app.checkbox.yes`)}
                  />
                  <FormControlLabel
                    value="false"
                    control={<AcmRadio icon={<RadioButtonUnchecked />} checkedIcon={<CheckCircle />} />}
                    label={Translation(`app.checkbox.no`)}
                  />
                </RadioGroup>
              </span>
            </Form.Item>
          </div>
        </div>
      </div>
      {showSection && <AddLeadSettingForm form={form} initialValues={currentLeadSetting} />}
    </Form>
  );
};

export default AddLeadSetting;
