import React, { FC } from "react";
import { SurveyResult, SurveyResultItem } from "../../../../types/survey-types";
import { useCommonStyles } from "../../../../../../common/styles/common-styles";
import { makeStyles } from 'tss-react/mui';
import { Button } from '@mui/material';
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import {
  SURVEY_BASE_PATH,
  SURVEY_MANAGEMENT_PATH,
} from "../../../../constants";
import SurveyAnalytics from "../../../../../../common/components/Survey/SurveyAnalytics";
import AsyncCsvLink from "../../../../../../common/components/AsyncCsvLink";
import { useDispatch } from "react-redux";
import { downloadSurveyResults } from "../../../../network/surveyCrud";

type SurveyAnalyticSectionProps = {
  results: SurveyResult;
};

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  btnContainer: {
    display: "flex",
  },
  rowContainer: {
    display: "flex",
    alignItems: "center",
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 15,
  },
}));

const SurveyAnalyticSection: FC<SurveyAnalyticSectionProps> = ({ results }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { classes: commonClasses } = useCommonStyles();
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  let result: Record<string, unknown>[] = results.surveyResults.reduce(
    (res: Record<string, unknown>[], item: SurveyResultItem) => {
      if (item.body) res.push(item.body);
      return res;
    },
    []
  );

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <div className={classes.rowContainer}>
          <div className={commonClasses.header}>
            {Translation("survey.viewResults")}
          </div>
        </div>
        <div className={classes.btnContainer}>
          <AsyncCsvLink
            isDisabled={false}
            filename={`survey-result-${results.surveyId}.csv`}
            dataParser={(str) => str}
            asyncCall={() => downloadSurveyResults(results.surveyId, dispatch)}
          >
            <Button
              style={{ marginRight: 25 }}
              variant="contained"
              color="secondary"
            >
              {Translation("app.button.exportCsv")}
            </Button>
          </AsyncCsvLink>
          <Button
            variant="contained"
            color="inherit"
            onClick={() =>
              history.push(`${SURVEY_BASE_PATH}${SURVEY_MANAGEMENT_PATH}`)
            }
          >
            {Translation("app.button.back")}
          </Button>
        </div>
      </div>

      <SurveyAnalytics
        questions={results.surveyBody}
        results={result}
        options={{
          //  Enable Commercial License
          haveCommercialLicense: true,
        }}
      />
    </div>
  );
};

export default SurveyAnalyticSection;
