import { CSSProperties, FC, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';
import { useIntl } from 'react-intl';
import { fetchMicrositeDetail } from 'src/app/modules/lead-microsite/network/api';
import { LayoutSplashScreen } from 'src/app/layout';
import { useLang } from 'src/app/i18n';
import { LeadMicrositeContentTypeEnum, I18nData } from 'src/app/modules/lead-microsite/types/microsite.type';
import SurveyPreview from 'src/app/common/components/microsite-survey-form/survey-preview/survey-preview';

type StylesObject = {
  [key: string]: CSSProperties;
};

const styles: StylesObject = {
  img: {
    height: 233,
    objectFit: 'cover',
  },
  paperMargin: {
    marginLeft: 24,
    marginRight: 24,
  },
  countryCodeWitdh: {
    width: 100,
    marginRight: 12,
  },
  radioGroup: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 6,
    flex: 1,
  },
  textYes: {
    borderWidth: 1,
    borderRadius: 8,
    borderColor: '#cccccc',
    flex: 1,
    marginLeft: 3,
  },
  textNo: {
    borderWidth: 1,
    borderRadius: 8,
    flex: 1,
    borderColor: '#cccccc',
    marginLeft: 3,
    marginRight: -3,
  },
  titleFont: {
    color: '#333333',
    fontSize: 20,
    fontWeight: 600,
  },
  subTitleFont: {
    color: '#666666',
    fontSize: 16,
    fontWeight: 500,
  },
  bodyFont: {
    color: '#333333',
    fontSize: 14,
    fontWeight: 400,
    marginTop: 24,
    overflowWrap: 'break-word',
  },
  lableFont: {
    color: '#333333',
    fontSize: 14,
    fontWeight: 500,
  },
  tncFont: {
    color: '#333333',
    fontSize: 12,
    fontWeight: 500,
  },
  font: {
    color: '#333333',
    fontSize: 16,
    fontWeight: 400,
  },
  button: {
    marginTop: 24,
    marginBottom: 47,
  },
};

const ulStyle = '.body ol { margin-left: 24px; list-style-type: decimal; }';
const olStyle = `.body ul { margin-left: 24px; list-style-type: disc;; }`;

export type PreviewItem = {
  banner: string;
  title: string;
  subTitle: string;
  inputFieldOptions: any;
  body: string;
  callToAction: string;
  tncList: I18nData<string>[];
};

type PreviewProps = {
  id: string;
  onClose: (value: boolean) => void;
};

const PreviewPage: FC<PreviewProps> = ({ id, onClose }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const lang = useLang();
  const [loading, setLoading] = useState<boolean>(false);
  const defaultBannerUrl = '/media/users/agent_referral_banner.png';
  const [data, setData] = useState<PreviewItem>({
    banner: defaultBannerUrl,
    title: '',
    subTitle: '',
    inputFieldOptions: {},
    body: '',
    callToAction: '',
    tncList: [{ en: '' }],
  });

  useEffect(() => {
    const getDetail = async (id: string) => {
      setLoading(true);
      const detail = await fetchMicrositeDetail(id);
      setLoading(false);

      try {
        const convertData = {
          banner: '',
          title: detail.title?.[lang],
          subTitle: detail.subTitle?.[lang],
          inputFieldOptions: detail.inputFieldsConfig,
          body: detail.body?.[lang],
          callToAction: detail.callToActionText?.[lang],
          tncList: detail.customerDisclaimer,
        };
        convertData.banner =
          detail.bannerType !== LeadMicrositeContentTypeEnum.DEFAULT ? detail.bannerData.fileUrl : defaultBannerUrl;
        setData(convertData);
      } catch (error) {
        console.log(error);
      }
    };
    getDetail(id);
  }, []);

  return (
    <div>
      {loading ? (
        <LayoutSplashScreen />
      ) : (
        <div className="tw-flex tw-flex-1  tw-flex-col">
          <span className="tw-flex tw-item-end tw-justify-end tw-h-8">
            <CloseIcon
              onClick={() => {
                onClose(false);
              }}
            />
          </span>

          <img src={data?.banner} alt="logo" style={styles.img}></img>

          <div style={styles.paperMargin}>
            {data?.title && (
              <div className="tw-mt-10  tw-break-words" style={styles.titleFont}>
                {data?.title}
              </div>
            )}

            {data?.subTitle && (
              <div className="tw-mt-3  tw-break-words" style={styles.subTitleFont}>
                {data?.subTitle}
              </div>
            )}

            <div>
              <style>{ulStyle}</style>
              <style>{olStyle}</style>
              <div dangerouslySetInnerHTML={{ __html: data?.body }} style={styles.bodyFont} className="body" />
            </div>
            <SurveyPreview data={data?.inputFieldOptions ?? []} />

            <div className="tw-mt-9" style={styles.tncFont}>
              {data?.tncList?.map((item, index) => {
                return (
                  <div className="tw-mt-3 tw-break-words" key={index.toString()}>
                    {item?.[lang]}
                  </div>
                );
              })}
            </div>
            <Button style={styles.button} variant="contained" disabled={true} color="secondary" fullWidth>
              {data?.callToAction}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PreviewPage;
