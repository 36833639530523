import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import PruRoute from 'src/app/common/components/PruRoute';
import { PULSELEADS_BASE_PATH, PULSELEADS_RULE_PATH, PULSELEADS_EXCLUSION_RULE_PATH } from '../../../constants';
import ParamsProvider from 'src/app/common/components/ParamsProvider';
import ExclusionRuleListingPage, { exclusionRuleFilterKeys } from './List/ExclusionRuleListingPage';
import ExclusionRuleDetailPage from './Form/ExclusionRuleDetailPage';

export const exclusionRulePath = `${PULSELEADS_BASE_PATH}${PULSELEADS_RULE_PATH}${PULSELEADS_EXCLUSION_RULE_PATH}`;

const ExclusionRuleRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect exact from={exclusionRulePath} to={`${exclusionRulePath}/list`} />
      <PruRoute exact path={`${exclusionRulePath}/list`} render={(props) => (
        <ParamsProvider {...props} acceptKeys={exclusionRuleFilterKeys}>
          <ExclusionRuleListingPage />
        </ParamsProvider>
      )}/>
      <PruRoute exact path={`${exclusionRulePath}/edit/:id`} component={ExclusionRuleDetailPage}/>
      <PruRoute exact path={`${exclusionRulePath}/create`} component={ExclusionRuleDetailPage}/>
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  )
};

export default ExclusionRuleRoutes;