import { IntlShape } from 'react-intl';
import { ModuleHierachy } from '../../common/module/types';
import {
  AdminAgentSegmentPermissionType,
  AdminNewsPermissionType,
  CONFIG_AGENT_NAMESPACE,
  CONFIG_AGENT_PATH,
  CONFIG_AGENT_SEGMENT_NAMESPACE,
  CONFIG_AGENT_SEGMENT_PATH,
  CONFIG_AGENT_TITLE,
  CONFIG_BASE_PATH,
  CONFIG_TITLE,
} from './constants';
import { isPermitted } from 'src/app/common/utils';
import ConfigAgentPage from './pages/Agent/ConfigAgentPage';
import ConfigAgentSegmentPage from './pages/AgentSegment/ConfigAgentSegmentPage';

export const configModuleConfig = (permissions: string[], intl: IntlShape): ModuleHierachy => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return {
    title: Translation(CONFIG_TITLE),
    path: CONFIG_BASE_PATH,
    icon: '/media/svg/icons/General/Settings-2.svg',
    enableRead: isPermitted(
      [
        AdminNewsPermissionType.CONFIG_AGENT_READ,
        AdminNewsPermissionType.CONFIG_AGENT_CREATE,
        AdminNewsPermissionType.CONFIG_AGENT_UPDATE,
        // AdminAgentSegmentPermissionType.CONFIG_AGENT_SEGMENT_READ,
        // AdminAgentSegmentPermissionType.CONFIG_AGENT_SEGMENT_CREATE,
        // AdminAgentSegmentPermissionType.CONFIG_AGENT_SEGMENT_UPDATE,
      ],
      permissions,
    ),
    child: [
      {
        title: Translation(CONFIG_AGENT_TITLE),
        path: `${CONFIG_BASE_PATH}${CONFIG_AGENT_PATH}`,
        namespace: CONFIG_AGENT_NAMESPACE,
        component: ConfigAgentPage,
        enableRead: isPermitted(
          [
            AdminNewsPermissionType.CONFIG_AGENT_READ,
            AdminNewsPermissionType.CONFIG_AGENT_CREATE,
            AdminNewsPermissionType.CONFIG_AGENT_UPDATE,
          ],
          permissions,
        ),
        enableCreate: isPermitted([AdminNewsPermissionType.CONFIG_AGENT_CREATE], permissions),
        enableUpdate: isPermitted([AdminNewsPermissionType.CONFIG_AGENT_UPDATE], permissions),
      },
      // {
      //   // title: Translation(CONFIG_AGENT_TITLE),
      //   title: 'Agent Segment',
      //   path: `${CONFIG_BASE_PATH}${CONFIG_AGENT_SEGMENT_PATH}`,
      //   namespace: CONFIG_AGENT_SEGMENT_NAMESPACE,
      //   component: ConfigAgentSegmentPage,
      //   enableRead: isPermitted(
      //     [
      //       AdminAgentSegmentPermissionType.CONFIG_AGENT_SEGMENT_READ,
      //       AdminAgentSegmentPermissionType.CONFIG_AGENT_SEGMENT_CREATE,
      //       AdminAgentSegmentPermissionType.CONFIG_AGENT_SEGMENT_UPDATE,
      //     ],
      //     permissions,
      //   ),
      //   enableCreate: isPermitted([AdminAgentSegmentPermissionType.CONFIG_AGENT_SEGMENT_CREATE], permissions),
      //   enableUpdate: isPermitted([AdminAgentSegmentPermissionType.CONFIG_AGENT_SEGMENT_UPDATE], permissions),
      // },
    ],
  };
};
