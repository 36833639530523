import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getConfigurations, useJWT } from 'src/app/common/utils';
import { ChartListParam, fetchDashboardJWT } from 'src/app/modules/AgencyCampaign/network/dashboardCrud';
import {
  DashboardLibraryCoeDev,
  LMdashboardDataCoeDev,
} from 'src/app/modules/AgencyCampaign/types/dashboard-chartID-coedev';
import { DashboardLibraryProd, LMdashboardDataProd } from 'src/app/modules/AgencyCampaign/types/dashboard-chartID-prod';
import { DashboardLibraryUat, LMdashboardDataUat } from 'src/app/modules/AgencyCampaign/types/dashboard-chartID-uat';
import { LMdashboardFilter } from 'src/app/modules/AgencyCampaign/types/dashboard-types';
import { RootState } from 'src/redux/store';
import { getMongoChartToken } from '../../../../../Auth/_redux/authCrud';
import { TokenInfo } from '../../../../../Auth/types/auth-types';

const baseUrl = window.envConfig['REACT_APP_MONGODB_CHART_BASE_URL'];
const chartSubscriptionKey = window.envConfig['REACT_APP_MONGODB_CHART_SUBSCRIPTION_KEY'];

var libarary = DashboardLibraryUat;
var dataMap = LMdashboardDataUat;
type ChartListProps = {
  filter: ChartListParam;
  onRefresh?: boolean;
  refreshdata: () => void;
  setExcelData: (data: any) => void;
  getDataList: (data: any) => void;
};

const CommonFilters: string[] = [];

const DashboardDetailPage: FC<ChartListProps> = ({ filter, onRefresh, refreshdata, setExcelData, getDataList }) => {
  const user = useSelector<RootState, TokenInfo | undefined>((state) => state.auth.user);
  const jwt = useJWT() || {};
  const { region, channel } = jwt;

  const getEnv = useMemo(() => {
    const env = window.envConfig['REACT_APP_ENV'];
    if (env === 'uat') {
      libarary = DashboardLibraryUat;
      dataMap = LMdashboardDataUat;
    } else {
      if (env === 'prod') {
        libarary = DashboardLibraryProd;
        dataMap = LMdashboardDataProd;
      }
    }
  }, []);

  const sdk = new ChartsEmbedSDK({
    baseUrl,
    getUserToken: async function () {
      return await fetchDashboardJWT(user?.sub, chartSubscriptionKey);
    },
    // async function () {
    //   const response = await getMongoChartToken();
    //   return response.accessToken;
    // },
  });
  var chartID = '';

  for (var i = 0; i < libarary.report.length; i++) {
    var object = libarary.report[i];
    if (object.chartName == filter.chartName) {
      chartID = object.chartID;
      if (object.chartType == filter.chartType) {
        chartID = object.chartID;
        break;
      }
    }
    // chartID = ""
  }

  const chartDiv = useRef<HTMLDivElement>(null);
  const dataChartDiv = useRef<HTMLDivElement>(null);
  const [rendered, setRendered] = useState(false);
  const [chart, reRender] = useState(
    sdk.createChart({
      chartId: chartID,
      height: '400px',
      theme: 'light',
      filter: { $and: [{ region: region }, { channel: channel }] },
    }),
  );

  const [dataChart, reRenData] = useState(
    sdk.createChart({
      chartId: dataMap.get(filter.chartName),
      height: '400px',
      theme: 'light',
      filter: { $and: [{ region: region }, { channel: channel }] },
    }),
  );

  const [myFilter, setMyFilter] = useState<any>({
    campaignId: '',
    consultantCode: '',
    campaignStatus: '',
    campaignType: '',
    campaignRecordType: '',
    leadSubSource: '',
    startDate: [],
    campaignStartDt: [],
    assignDate: [],
    region: region,
    channel: channel,
  });

  useEffect(() => {
    if (chartDiv.current && dataChartDiv.current) {
      setExcelData(null);
      chart
        .render(chartDiv.current)
        .then(() => {
          setRendered(true);
          chart.getData().then((result) => setExcelData(result));
          chart.getData().then((res) => console.log('documents' + JSON.parse(JSON.stringify(res)).documents.length));
        })
        .catch((err) => console.log('Error during Charts rendering.', err));
      dataChart
        .render(dataChartDiv.current)
        .then(() => {
          dataChart.getData().then((result) => {
            type resultType = keyof typeof result;
            type fieldsType = keyof typeof fields;
            type dataType = keyof (typeof data)[0];
            const fields = result['fields' as resultType] as object;
            const data = result['documents' as resultType] as Array<{ group: string }>;

            const fieldMappings = [
              { source: 'Campaign ID', target: 'campaignId' },
              { source: 'Campaign Status', target: 'campaignStatus' },
              { source: 'Lead Sub Source', target: 'leadSubSource' },
            ];
            let nameObj = {
              campaignId: '',
              campaignStatus: '',
              consultantCode: '',
              campaignType: '',
              campaignRecordType: '',
              leadSubSource: '',
              region: '',
              channel: '',
            };

            type nameType = keyof typeof nameObj;

            // fields[fieldName as fieldsType] -> chart's field name
            for (const fieldName in fields) {
              const filteredMapping = fieldMappings.find(
                (mapping) => mapping.source === fields[fieldName as fieldsType],
              );
              if (filteredMapping) {
                nameObj[filteredMapping.target as nameType] = fieldName;
              } else {
                nameObj[fields[fieldName as fieldsType] as nameType] = fieldName;
              }
            }

            let dataList: Record<string, any[]> = {
              campaignId: [],
              campaignStatus: [],
              consultantCode: [],
              campaignType: [],
              campaignRecordType: [],
              leadSubSource: [],
              region: [],
              channel: [],
            };
            // filter duplicate choices

            data.forEach((item) => {
              for (const key in dataList) {
                if (
                  !!item[nameObj[key as nameType] as dataType] &&
                  !dataList[key]
                    .toString()
                    .trim()
                    .includes(item[nameObj[key as nameType] as dataType].toString().trim())
                ) {
                  dataList[key].push(item[nameObj[key as nameType] as dataType]);
                }
              }
            });
            getDataList(dataList);
          });
        })
        .catch((err) => console.log('Error during Charts rendering.', err));
    } else {
      console.log('Error');
    }
  }, [chartDiv, dataChartDiv, chart, dataChart, rendered, reRenData]);

  useEffect(() => {
    let tmpFilter = myFilter;
    for (const name in filter) {
      type filterKey = keyof typeof filter;
      if (Object.keys(tmpFilter).includes(name)) {
        tmpFilter[name] =
          !!filter[name as filterKey] && filter[name as filterKey] != 'all' ? filter[name as filterKey] : undefined;
      }
    }
    setMyFilter({
      campaignId: !!filter['campaignId'] && filter['campaignId'] != 'all' ? filter['campaignId'] : undefined,
      consultantCode:
        !!filter['consultantCode'] && filter['consultantCode'] != 'all' ? filter['consultantCode'] : undefined,
      campaignStatus:
        !!filter['campaignStatus'] && filter['campaignStatus'] != 'all' ? filter['campaignStatus'] : undefined,
      campaignType: !!filter['campaignType'] && filter['campaignType'] != 'all' ? filter['campaignType'] : undefined,
      campaignRecordType:
        !!filter['campaignRecordType'] && filter['campaignRecordType'] != 'all'
          ? filter['campaignRecordType']
          : undefined,
      leadSubSource:
        !!filter['leadSubSource'] && filter['leadSubSource'] != 'all' ? filter['leadSubSource'] : undefined,
      startDate: !!filter['startDate'] ? filter['startDate'] : undefined,
      campaignStartDt: !!filter['campaignStartDt'] ? filter['campaignStartDt'] : undefined,
      assignDate: !!filter['assignDate'] ? filter['assignDate'] : undefined,
      region: !!filter['region'] ? filter['region'] : region,
      channel: !!filter['channel'] ? filter['channel'] : channel,
    });
  }, [chart, filter, rendered]);

  useEffect(() => {
    let obj = JSON.parse(JSON.stringify(myFilter));
    let chartFilter = LMdashboardFilter.get(filter.chartName);
    // delete the no filterName
    for (const key in obj) {
      if (!chartFilter[key] && !CommonFilters.includes(key)) {
        delete obj[key];
      }
    }
    // pls put the date type to be filtered here
    if (obj?.startDate) {
      if (!!myFilter?.startDate[0] && !!myFilter?.startDate[1]) {
        const startDateWithTime = myFilter.startDate[0].setHours(0, 0, 0);
        const endDateWithTime = myFilter.startDate[1].setHours(23, 59, 59);
        obj.$and = [
          { startDate: { $gte: new Date(startDateWithTime) } },
          { startDate: { $lte: new Date(endDateWithTime) } },
        ];
      }
      delete obj.startDate;
    }
    if (obj?.campaignStartDt) {
      if (!!myFilter?.campaignStartDt[0] && !!myFilter?.campaignStartDt[1]) {
        const campaignStartDateWithTime = myFilter.campaignStartDt[0].setHours(0, 0, 0);
        const campaignEndDateWithTime = myFilter.campaignStartDt[1].setHours(23, 59, 59);
        obj.$and = [
          { campaignStartDt: { $gte: new Date(campaignStartDateWithTime) } },
          { campaignStartDt: { $lte: new Date(campaignEndDateWithTime) } },
        ];
      }
      delete obj.campaignStartDt;
    }
    if (obj?.assignDate) {
      if (!!myFilter?.assignDate[0] && !!myFilter?.assignDate[1]) {
        const assignStartDateWithTime = myFilter.assignDate[0].setHours(0, 0, 0);
        const assignEndDateWithTime = myFilter.assignDate[1].setHours(23, 59, 59);
        obj.$and = [
          { assignDate: { $gte: new Date(assignStartDateWithTime) } },
          { assignDate: { $lte: new Date(assignEndDateWithTime) } },
        ];
      }
      delete obj.assignDate;
    }
    obj = { ...obj, region, channel };

    reRender(sdk.createChart({ chartId: chartID, height: '400px', theme: 'light', filter: obj }));
  }, [filter]);

  useEffect(() => {
    reRender(
      sdk.createChart({
        chartId: chartID,
        height: '400px',
        theme: 'light',
        filter: { $and: [{ region: region }, { channel: channel }] },
      }),
    );
    reRenData(
      sdk.createChart({
        chartId: dataMap.get(filter.chartName),
        height: '400px',
        theme: 'light',
        filter: { $and: [{ region: region }, { channel: channel }] },
      }),
    );
  }, [filter.chartName, filter.chartType]);

  useEffect(() => {
    if (chartDiv.current) {
      setExcelData(null);
      chart
        .render(chartDiv.current)
        .then(() => {
          setRendered(true);
          chart.getData().then((result) => setExcelData(result));
          chart.getData().then((res) => console.log('documents' + JSON.parse(JSON.stringify(res)).documents.length));
        })
        .catch((err) => console.log('Error during get data.', err));
    } else {
      console.log('Error');
    }
  }, [filter.chartName, filter.chartType, rendered]);

  return (
    <>
      <div className="chart" ref={chartDiv} />
      <div className="data-chart" ref={dataChartDiv} hidden />
    </>
  );
};

export default DashboardDetailPage;
