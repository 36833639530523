import { Dispatch } from 'react';
import { DesignationItem, ZoneItem } from 'src/app/common/types';
import { ErrorState, DismissErrorHandler } from 'src/app/common/utils';
import { TargetApplicantMode } from './common-types';
import { RadioModeFormState, RadioModeFormAction } from '../radio-mode';

export enum TargetAgentsByEnum {
  UPLOAD_AGENT_LIST = 'UPLOAD_AGENT_LIST',
  PRESET_GROUP = 'PRESET_GROUP',
}

export type TargetApplicantRadioModeProps = {
  mode: TargetApplicantMode.RADIO;
  isDisabled: boolean;
  module: string;
  designationList: DesignationItem[];
  zoneList: ZoneItem[];
  initialFormState: RadioModeFormState;
  errorState: ErrorState;
  errorFieldPrefix?: string;
  onChange: (newFormState: RadioModeFormState) => void;
  onDismissErrorHandler: DismissErrorHandler;
};

export type TargetApplicantRadioModeConfig = RadioModeFormState;

export type UploadAgentListProps = {
  isDisabled: boolean;
  formState: RadioModeFormState;
  formDispatch: Dispatch<RadioModeFormAction>;
  handleFile: (
    e: React.ChangeEvent<HTMLInputElement>,
    fileField: keyof RadioModeFormState,
    valueField: keyof RadioModeFormState,
  ) => Promise<void>;
};

export type DeleteAgentDialogState = {
  open: boolean;
  agentList: string[];
  onResetRowSelected?: () => void;
};

export type AgentCodeItem = {
  agentCode: string;
};

export type PresetAgentGroupProps = {
  isDisabled: boolean;
  designationList: DesignationItem[];
  zoneList: ZoneItem[];
  formState: RadioModeFormState;
  errorState: ErrorState;
  errorFieldPrefix?: string;
  formDispatch: Dispatch<RadioModeFormAction>;
  onDismissErrorHandler: DismissErrorHandler;
  handleFile: (
    e: React.ChangeEvent<HTMLInputElement>,
    fileField: keyof RadioModeFormState,
    valueField: keyof RadioModeFormState,
  ) => Promise<void>;
};
