import { Dispatch } from 'react';
import { forEach } from 'lodash';
import { getDayEnd, getDayStartForStrapi, getDayEndForStrapi, ignoreTimezone } from 'src/app/common/utils';
import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import {
  LibraryPaginateList,
  LibraryItem,
  LibraryEditItem,
  LibraryPublishedMode,
  LibraryListParam,
} from '../types/library-types';
import { CategoryPaginateList } from '../../Category/types/category-types';
import { ColumnPaginateList } from '../../Column/types/column-types';
import moment from 'moment';
import { InsightMaterialReportResponseDtoItem } from '../pages/performance/components/export/interface';
import qs from 'qs';

export const validateColumWeight = async (column: string, weight: number) => {
  if (!column || !weight) {
    return true;
  }

  const id = location.pathname.split('/').pop();
  const isEdit = id?.match(/^\d+$/);
  const queryPath = `${ADMIN_URL}/content-manager/api/materials`;
  const query = {
    locale: 'all',
    populate: {
      columnReferences: {
        populate: '*',
      },
      localizations: {
        populate: '*',
      },
    },
    filters: {
      columnReferences: {
        column,
        weight,
      },
    },
  };
  const result = await apiClient.get(`${queryPath}?${qs.stringify(query)}`, {
    headers: {
      'raw-path': 'true',
    },
  });

  const data = result.data.data || [];

  return (
    (isEdit
      ? data
          .filter((item: any) => item.id != id)
          .filter((item: any) => !item.attributes.localizations.data.some((lo: any) => lo.id == id))
      : data
    ).length === 0
  );
};

export const fetchLibraryList = async (
  locale: string,
  params: LibraryListParam,
  sortKeys: { key: string; value?: string }[],
  dispatch?: Dispatch<any>,
): Promise<LibraryPaginateList> => {
  let queryPath = `${ADMIN_URL}/content-manager/collection-types/api::material.material?`;

  // Query for active status filter
  queryPath += filterQueryForPublish(params['status'], queryPath);

  // Query for corresponding module
  // queryPath += `module_eq=${params['module']}&`;
  queryPath += `filters[module][$eq]=${params['module']}&`;

  // Query for other filter
  forEach(params, (param, key) => {
    // console.log(param, key)
    if (key === 'name') {
      const escapedParam = (param as string).replace(/([!@#$%^&*()+=\[\]\\';,./{}|":<>?~_-])/g, '\\$1');
      // queryPath += param ? `${key}_contains=${escapedParam}&` : '';
      queryPath += param ? `filters[${key}][$containsi]=${escapedParam}&` : '';
    } else if (typeof param === 'object') {
      if (param) {
        if (key.toLowerCase().includes('createtimestart') && !isNaN(param.getTime())) {
          // queryPath += `_where[0][createdAt_gte]=${getDayStartForStrapi(param)}&`;
          queryPath += `filters[createdAt][$gte]=${getDayStartForStrapi(param)}&`;
        } else if (key.toLowerCase().includes('createtimeend') && !isNaN(param.getTime())) {
          // queryPath += `_where[0][createdAt_lte]=${ignoreTimezone(getDayEnd(param))}&`;
          queryPath += `filters[createdAt][$lte]=${getDayEndForStrapi(param)}&`;
        } else if (key.toLowerCase().includes('effectivedate') && !isNaN(param.getTime())) {
          // PCAAEB-37270: must use utc date here
          // const curTime = moment(param).format('YYYY-MM-DDThh:mm:ss.sss');
          const curTime = moment(new Date()).utc().format();
          const query = qs.stringify({
            filters: {
              status: 'Published',
              $or: [
                {
                  effectiveDate: {
                    $lte: curTime,
                  },
                  expiredDate: {
                    $gte: curTime,
                  },
                },
                {
                  effectiveDate: {
                    $lte: curTime,
                  },
                  expiredDate: {
                    $null: true,
                  },
                },
                {
                  effectiveDate: {
                    $null: true,
                  },
                  expiredDate: {
                    $gte: curTime,
                  },
                },
                {
                  effectiveDate: {
                    $null: true,
                  },
                  expiredDate: {
                    $null: true,
                  },
                },
              ],
            },
          });
          queryPath += `${query}&`;
        } else if (key === 'type_eq') {
          forEach(param, (param, index) => {
            queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
          });
        }
      }
    } else if (key !== 'isPublish' && key !== 'isValid') {
      if (typeof param === 'string') {
        if (key === 'category') {
          // queryPath += param ? `category.name_eq=${param}&` : '';
          queryPath += param ? `filters[category][name][$eq]=${param}&` : '';
        } else if (key === 'status') {
        } else if (key === 'column') {
          if (param) {
            const query = {
              populate: {
                columnReferences: {
                  populate: '*',
                },
              },
              filters: {
                columnReferences: {
                  column: {
                    id: param,
                  },
                },
              },
            };
            queryPath += `${qs.stringify(query)}&`;
          }
        } else if (key == 'type_eq') {
          queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
        } else {
          // queryPath += param ? `${key}_contains=${param}&` : '';
          queryPath += param ? `filters[${key}][$containsi]=${param}&` : '';
        }
      } else {
        queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
      }
    }
  });

  //Query for sorting
  let sortingCount = 0;
  queryPath += `sort=`;
  forEach(sortKeys, (sortParam, key) => {
    if (sortParam.value) {
      queryPath += `${sortingCount === 0 ? `` : `,`}${encodeURIComponent(sortParam.key)}:${encodeURIComponent(
        sortParam.value,
      )}`;
      sortingCount++;
    }
  });
  if (sortingCount === 0) {
    queryPath += `updatedAt:DESC`;
  }

  // Query for current language
  if (locale !== '') {
    queryPath += `&locale=${locale}`;
  }

  return apiClient
    .get(queryPath)
    .then((response) => {
      let libraryList = response.data.results;
      const data = {
        libraryList: libraryList,
        total: response.data.pagination.total,
        totalPages: response.data.pagination.pageCount,
        limit: response.data.pagination.pageSize,
        items: response.data.results,
        totalRecords: response.data.pagination.total,
        docs: response.data.results,
        hasNextPage: true,
        hasPrevPage: false,
        totalDocs: response.data.pagination.total,
      };

      if (libraryList.length == 0) {
        return data;
      }

      const uuid = libraryList.map((result: { uuid: string }) => result.uuid);
      const columnReferencesUrl = `${ADMIN_URL}/content-manager/api/materials?${qs.stringify({
        fields: ['uuid'],
        populate: {
          columnReferences: {
            populate: '*',
          },
        },
        filters: {
          uuid,
        },
        publicationState: 'preview',
      })}`;
      return apiClient
        .get(columnReferencesUrl, {
          headers: {
            'raw-path': true,
          },
        })
        .then((res) => {
          const formattedData = {
            data: res.data.data.map((item: { id: string; attributes: { columnReferences: any[]; uuid: string } }) => {
              const formattedColumnReferences = item.attributes.columnReferences.map((reference) => {
                const columnAttributes = reference.column.data?.attributes;
                if (columnAttributes) {
                  columnAttributes.id = reference.column.data.id;
                }

                return {
                  id: reference.id,
                  weight: reference.weight,
                  module: reference.module,
                  column: columnAttributes || null,
                };
              });
              return {
                id: item.id,
                attributes: {
                  uuid: item.attributes.uuid,
                  columnReferences: formattedColumnReferences,
                },
              };
            }),
            meta: res.data.meta,
          };

          // Modify libraryList with column references
          forEach(data.libraryList, (libraryItem) => {
            const columnReferences = formattedData.data.find(
              (item: { attributes: { uuid: string; columnReferences: any[] } }) =>
                item.attributes.uuid === libraryItem.uuid,
            );
            libraryItem.columnReferences = columnReferences?.attributes?.columnReferences;
          });

          return data;
        })
        .catch((error) => {
          console.log('error:', error);
          throw error;
        });
    })
    .catch((error) => {
      console.log('error:', error);
      throw error;
    });
};

export const fetchLibraryItem = (id: string, dispatch?: Dispatch<any>): Promise<LibraryItem> => {
  const queryPath = `${ADMIN_URL}/content-manager/api/materials/${id}?${qs.stringify({
    populate: {
      columnReferences: {
        populate: '*',
      },
      childItems: {
        populate: '*',
      },
      category: {
        populate: '*',
      },
      localizations: {
        populate: '*',
      },
    },
  })}`;

  return apiClient
    .get(queryPath, {
      headers: {
        'raw-path': true,
      },
    })
    .then((response) => {
      const item = response.data.data.attributes;
      item.category.data.attributes.id = item.category.data.id;
      item.category = item.category.data.attributes;

      item.columnReferences = item.columnReferences.map(
        (i: { column: { data: { id: string; attributes: any } }; module: string; weight: number; id: string }) => {
          i.column.data.attributes.id = i.column.data.id;
          i.column = i.column.data.attributes;
          return i;
        },
      );
      item.childItems = item.childItems.data.map((material: any) => {
        material.attributes.id = material.id;
        material.attributes.localizations = (material.attributes.localizations?.data || []).map((lo: any) => {
          lo.attributes.id = lo.id;
          return lo.attributes;
        });
        return material.attributes;
      });
      item.localizations = item.localizations.data.map((i: { id: string; attributes: any }) => {
        i.attributes.id = i.id;
        return i.attributes;
      });
      console.log(item, 11);
      return item;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createNewLibrary = async (
  data: LibraryEditItem,
  locale: string,
  relatedId?: string,
  dispatch?: Dispatch<any>,
): Promise<LibraryItem> => {
  let createURL = `${ADMIN_URL}/content-manager/collection-types/api::material.material?plugins[i18n][locale]=${locale}`;
  if (relatedId) {
    createURL += `&plugins[i18n][relatedEntityId]=${relatedId}`;
  }

  return apiClient
    .post<LibraryItem>(createURL, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const modifyLibrary = async (
  code: string,
  data: LibraryEditItem,
  dispatch?: Dispatch<any>,
): Promise<LibraryItem> => {
  const modifyURL = `${ADMIN_URL}/content-manager/collection-types/api::material.material/${code}`;
  return apiClient
    .put<LibraryItem>(modifyURL, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const editUnpublishLibrary = async (
  dataId: string,
  data: LibraryEditItem,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  const publishURL = `${ADMIN_URL}/content-manager/collection-types/api::material.material/${dataId}/actions/unpublish`;
  return apiClient
    .post<void>(publishURL, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const publishLibrary = async (dataId: string, dispatch?: Dispatch<any>): Promise<void> => {
  const publishURL = `${ADMIN_URL}/content-manager/collection-types/api::material.material/${dataId}/actions/publish`;
  return apiClient
    .post<void>(
      publishURL,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const unpublishLibrary = async (dataId: string, dispatch?: Dispatch<any>): Promise<void> => {
  const unpublishURL = `${ADMIN_URL}/content-manager/collection-types/api::material.material/${dataId}/actions/unpublish`;
  return apiClient
    .post<void>(
      unpublishURL,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const deleteLibrary = async (dataId: string, dispatch?: Dispatch<any>): Promise<void> => {
  const deleteURL = `${ADMIN_URL}/content-manager/collection-types/api::material.material/${dataId}`;
  return apiClient
    .delete<void>(deleteURL, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchAllCategoryList = async (
  moduleType: string,
  dispatch?: Dispatch<any>,
): Promise<CategoryPaginateList> => {
  let queryPath = `${ADMIN_URL}/content-manager/collection-types/api::category.category?`;

  // Query for corresponding module
  // queryPath += `module_eq=${moduleType}`;
  queryPath += `filters[module][$eq]=${moduleType}&page=1&pageSize=1000`;

  return apiClient
    .get(queryPath)
    .then((response) => {
      return {
        categoryList: response.data.results,
        total: response.data.pagination.total,
        totalPages: response.data.pagination.pageCount,
        limit: response.data.pagination.pageSize,
        items: response.data.results,
        totalRecords: response.data.pagination.total,
        docs: response.data.results,
        hasNextPage: true,
        hasPrevPage: false,
        totalDocs: response.data.pagination.total,
      };
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchAllColumnList = async (moduleType: string, dispatch?: Dispatch<any>): Promise<ColumnPaginateList> => {
  let queryPath = `${ADMIN_URL}/content-manager/collection-types/api::column.column?`;

  // Query for corresponding module
  // queryPath += `module_eq=${moduleType}`;
  queryPath += `filters[module][$eq]=${moduleType}&page=1&pageSize=1000`;

  return apiClient
    .get(queryPath)
    .then((response) => {
      return {
        columnList: response.data.results,
        total: response.data.pagination.total,
        totalPages: response.data.pagination.pageCount,
        limit: response.data.pagination.pageSize,
        items: response.data.results,
        totalRecords: response.data.pagination.total,
        docs: response.data.results,
        hasNextPage: true,
        hasPrevPage: false,
        totalDocs: response.data.pagination.total,
      };
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const filterQueryForPublish = (isActiveParam = '', queryPath: string): string => {
  let extendQuery = '';

  if (isActiveParam != '' && isActiveParam !== LibraryPublishedMode.ALL) {
    // extendQuery = `status_eq=${isActiveParam}&`;
    extendQuery = `filters[status][$eq]=${isActiveParam}&`;
  }

  return extendQuery;
};

/**
 * @param feature 'resource' | 'recruitment'
 * @param start start-time
 * @param end end-time
 * @param id resource uuid
 * @param dispatch
 * @returns
 */
export const fetchMaterialPerformance = async (
  feature: FeatureType,
  start: string,
  end: string,
  id: string,
  dispatch?: Dispatch<any>,
): Promise<InsightMaterialReportResponseDtoItem[]> => {
  const queryPath = `${ADMIN_URL}/resource/performance`;
  // const local = 'http://localhost:1313'
  // let queryPath = `${local}/resource/performance`;

  return apiClient
    .get(queryPath, {
      params: {
        feature,
        id,
        startDate: start,
        endDate: end,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export type FeatureType = 'resource' | 'recruitment';
