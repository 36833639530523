import React, { FC, useState, Dispatch } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { forEach } from 'lodash';
import { makeStyles } from 'tss-react/mui';
import { Button, TextField } from '@mui/material';
import { useCommonStyles } from '../../../common/styles/common-styles';
import { ReportType, ReportDetail } from '../types/report-types';
import { appendAlertItem, AlertType } from '../../../../redux/common/commonSlice';
import { MANDATORY_FIELD_ERROR_TEXT } from '../constants';
import { PruDateTimePicker, PruDatePicker, MonthOnlyCalendarHeader } from 'src/app/common/components/PruDatePicker';
import { useErrorHandler, ErrorFieldDef, ErrorFieldType } from 'src/app/common/utils';

type FieldType = 'text' | 'date-month' | 'date-year' | 'date';

type FieldDef = {
  name: string;
  displayText: string;
  type: FieldType;
  mandatory?: boolean;
  rule?: { [Symbol.replace](string: string, replaceValue: string): string };
};

type CreateReportProps = {
  title: string;
  fields: FieldDef[];
  reportType: ReportType;
  onCreate: (params: Record<string, unknown>, dispatch?: Dispatch<any>) => Promise<ReportDetail>;
  onBack: () => void;
};

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  loadingContainer: {
    width: '100%',
    padding: 50,
    display: 'flex',
    justifyContent: 'center',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  fieldContainer: {
    width: 160,
    boxSizing: 'border-box',
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },
  footerContainer: {
    marginTop: 20,
  },
}));

type FieldInternal = {
  value: unknown;
  type: FieldType;
};

type FormState = Record<string, FieldInternal>;

const formStateInitialiser = (fields: FieldDef[]): FormState => {
  let formState: FormState = {};
  fields.forEach((field) => {
    if (field.type === 'date-month' || field.type === 'date-year' || field.type === 'date') {
      formState[field.name] = {
        value: null,
        type: field.type,
      };
    } else if (field.type === 'text') {
      formState[field.name] = {
        value: null,
        type: field.type,
      };
    }
  });
  return formState;
};

const submitConvertor = (formState: FormState) => {
  let submitState: Record<string, unknown> = {};
  forEach(formState, (formItem, key) => {
    if (formItem.value) {
      if (formItem.type === 'date-month') {
        submitState[key] = (formItem.value as Date).getMonth() + 1;
      } else if (formItem.type === 'date-year') {
        submitState[key] = (formItem.value as Date).getFullYear();
      } else if (formItem.type === 'date') {
        submitState[key] = formItem.value as Date;
      } else if (formItem.type === 'text') {
        submitState[key] = formItem.value;
      }
    }
  });
  return submitState;
};

const errorFieldConvertor = (fields: FieldDef[]): ErrorFieldDef[] => {
  const errorFieldDef: ErrorFieldDef[] = [];
  fields.forEach((field) => {
    if (field.mandatory) {
      errorFieldDef.push({
        name: field.name,
        fieldType: ErrorFieldType.MANDATORY,
      });
    }
  });
  return errorFieldDef;
};

const formStateConvertor = (fields: FormState): Record<string, unknown> => {
  let obj: Record<string, unknown> = {};
  forEach(fields, (field, key) => {
    if (!obj[key]) {
      obj[key] = field.value;
    }
  });
  return obj;
};

const CreateReport: FC<CreateReportProps> = ({ title, fields, reportType, onCreate, onBack }) => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const { classes: commonClasses } = useCommonStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const [formState, setFormState] = useState<FormState>(formStateInitialiser(fields));

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler } = useErrorHandler(
    formStateConvertor(formState),
    errorFieldConvertor(fields),
  );

  const onSubmit = async () => {
    const { hasError } = onSubmitErrorValidator();
    if (!hasError) {
      try {
        await onCreate(submitConvertor(formState), dispatch);
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: 'Success',
              content: `Request of following report is submitted successfully - ${reportType}`,
            },
          ]),
        );
        onBack();
      } catch (err) {}
    }
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <div className={classes.rowContainer}>
            <div className={commonClasses.header}>
              {intl.formatMessage(
                { id: 'report.create' },
                {
                  title,
                  reportType,
                },
              )}
            </div>
          </div>
          <Button variant="contained" color="inherit" onClick={onBack}>
            {Translation('global.back.btnText')}
          </Button>
        </div>
        {fields.map((field) => {
          if (field.type === 'text')
            return (
              <div key={`create-${reportType}-report-field-${field.name}`} className={classes.rowContainer}>
                <div className={classes.fieldContainer}>
                  <span className={classes.field}>
                    {field.displayText}
                    {field.mandatory && <span className={classes.mandatory}>*</span>}:
                  </span>
                </div>
                <TextField
                  error={errorState.mandatory[field.name]}
                  margin="dense"
                  variant="outlined"
                  helperText={errorState.mandatory[field.name] && MANDATORY_FIELD_ERROR_TEXT}
                  value={formState[field.name].value}
                  onChange={(e) => {
                    const filteredText = field.rule ? e.target.value.replace(field.rule, '') : e.target.value;
                    onDismissErrorHandler(field.name, filteredText);
                    setFormState({
                      ...formState,
                      [field.name]: {
                        ...formState[field.name],
                        value: filteredText,
                      },
                    });
                  }}
                />
              </div>
            );
          if (field.type === 'date-month')
            return (
              <div key={`create-${reportType}-report-field-${field.name}`} className={classes.rowContainer}>
                <div className={classes.fieldContainer}>
                  <span className={classes.field}>
                    {field.displayText}
                    {field.mandatory && <span className={classes.mandatory}>*</span>}:
                  </span>
                </div>
                <PruDatePicker
                  slots={{ calendarHeader: MonthOnlyCalendarHeader }}
                  slotProps={{
                    textField: {
                      error: errorState.mandatory[field.name],
                      helperText: errorState.mandatory[field.name] && MANDATORY_FIELD_ERROR_TEXT,
                    },
                  }}
                  views={['month']}
                  format="MM"
                  value={formState[field.name].value as Date}
                  onChange={(e) => {
                    onDismissErrorHandler(field.name, e);
                    setFormState({
                      ...formState,
                      [field.name]: {
                        ...formState[field.name],
                        value: e,
                      },
                    });
                  }}
                />
              </div>
            );
          if (field.type === 'date-year')
            return (
              <div key={`create-${reportType}-report-field-${field.name}`} className={classes.rowContainer}>
                <div className={classes.fieldContainer}>
                  <span className={classes.field}>
                    {field.displayText}
                    {field.mandatory && <span className={classes.mandatory}>*</span>}:
                  </span>
                </div>
                <PruDatePicker
                  slotProps={{
                    textField: {
                      error: errorState.mandatory[field.name],
                      helperText: errorState.mandatory[field.name] && MANDATORY_FIELD_ERROR_TEXT,
                    },
                  }}
                  views={['year']}
                  disableFuture
                  format="YYYY"
                  value={formState[field.name].value as Date}
                  onChange={(e) => {
                    onDismissErrorHandler(field.name, e);
                    setFormState({
                      ...formState,
                      [field.name]: {
                        ...formState[field.name],
                        value: e,
                      },
                    });
                  }}
                />
              </div>
            );
          if (field.type === 'date')
            return (
              <div key={`create-${reportType}-report-field-${field.name}`} className={classes.rowContainer}>
                <div className={classes.fieldContainer}>
                  <span className={classes.field}>
                    {field.displayText}
                    {field.mandatory && <span className={classes.mandatory}>*</span>}:
                  </span>
                </div>
                <PruDateTimePicker
                  slotProps={{
                    textField: {
                      error: errorState.mandatory[field.name],
                      helperText: errorState.mandatory[field.name] && MANDATORY_FIELD_ERROR_TEXT,
                    },
                  }}
                  format="DD/MM/YYYY HH:mm"
                  value={formState[field.name].value as Date}
                  onChange={(e) => {
                    onDismissErrorHandler(field.name, e);
                    setFormState({
                      ...formState,
                      [field.name]: {
                        ...formState[field.name],
                        value: e,
                      },
                    });
                  }}
                />
              </div>
            );
          return <></>;
        })}
        <div className={classes.footerContainer}>
          <Button variant="contained" color="secondary" onClick={onSubmit}>
            {Translation('app.button.submit')}
          </Button>
        </div>
      </div>
    </>
  );
};

export default CreateReport;
