import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { makeStyles } from 'tss-react/mui';
import { Button, Dialog, TextField } from '@mui/material';
import { MANDATORY_FIELD_ERROR_TEXT } from "src/app/common/constants";
import { WorkflowApprovalStatusEnum } from 'src/app/modules/AgencyCampaign/types/approval-types';

type ApprovalDialogProps = {
  open: boolean;
  campaignId: string;
  newStatus: WorkflowApprovalStatusEnum;
  onConfirm: (comment: string) => void;
  onCancel: () => void;
}

const useStyles = makeStyles()((theme) => ({
  dialogPaper: {
    width: 400,
    height: 500,
    maxWidth: 400,
    padding: 16,
    background: 'white',
  },
  dialogTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 20,
  },
  textArea: {
    lineHeight: 1.5,
    minHeight: 250,
  },
  bottomSpacing: {
    marginBottom: 25,
  }
}));

const ApprovalDialog: FC<ApprovalDialogProps> = ({ open, campaignId, newStatus, onConfirm, onCancel }) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const [comment, setComment] = useState("");

  return (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      open={open}
    >
      <div className={classes.dialogTitle}>
        <span>
          {Translation(`agencyCampaign.approval.${newStatus === WorkflowApprovalStatusEnum.APPROVED ? 'approveDialogTitle': 'rejectDialogTitle'}`)} {campaignId} ?
        </span>
      </div>
      <div className={classes.bottomSpacing}>
        <div>
          {Translation("agencyCampaign.approval.comment")}
        </div>
        <TextField
          fullWidth
          multiline
          InputProps={{
            classes: {
              input: classes.textArea,
            },
          }}
          margin="dense"
          variant="outlined"
          value={comment}
          onChange={e => {
            setComment(e.target.value);
          }}
        />
      </div>
      <Button
        style={{ marginBottom: 15 }}
        variant="contained"
        color="secondary"
        onClick={() => onConfirm(comment)}
      >
        {Translation("global.text.confirm")}
      </Button>
      <Button
        variant="contained"
        style={{ backgroundColor: 'white' }}
        onClick={() => onCancel()}
      >
        {Translation("app.button.cancel")}
      </Button>
    </Dialog>
  )
}

export default ApprovalDialog;