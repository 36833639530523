import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  table: {
    minWidth: 700,
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    background: 'white',
    padding: 15,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  headerButtons: {
    display: 'inline-block',
  },
  footer: {
    width: '100%',
  },
  operationContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  operationBtn: {
    color: 'blue',
    cursor: 'pointer',
    textDecoration: 'underline',
    fontSize: '0.85rem',
    whiteSpace: 'nowrap',
  },
  disabled: {
    color: '#BBBBBB',
    cursor: 'default',
  },
  action: {
    marginRight: 10,
  },
}));
