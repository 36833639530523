import React from 'react';

const columns = (Translation: any) => {
  const noteType = {
    CONFLICT: (
      <span className="tw-flex tw-justify-end tw-flex-1 tw-mr-20 tw-text-red-500">
        {Translation('pulseleads.activityPoint.ruleSettings.errorMsg.conflictMsg')}
      </span>
    ),
    OVERRIDE: (
      <span className="tw-flex tw-justify-end tw-flex-1 tw-mr-20 tw-text-black">
        {Translation('pulseleads.activityPoint.ruleSettings.button.override')}
      </span>
    ),
  };
  const columns = [
    {
      title: Translation('pulseleads.activityPoint.ruleSettings.common.agentCode'),
      dataIndex: 'agentCode',
      hideInSearch: true,
    },
    {
      title: ' ',
      hideInSearch: true,
      render: (_: any, row: any) => {
        if (row.conflictStatus && !row.isOverride) {
          return noteType.CONFLICT;
        } else if (row.isOverride) {
          return noteType.OVERRIDE;
        } else return '';
      },
    },
  ];

  return [...columns];
};

export default columns;
