import { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import PruRoute from '../../../../common/components/PruRoute';
import { NOT_FOUND_ERROR_PAGE_PATH } from '../../../../common/constants';
import { AGENTLEADFORM_PATH, SALESKIT_BASE_PATH } from '../../constants';
import AgentLeadFormPage from './agent-lead-form-page';

const AgentLeadFormRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect
        exact
        from={`${SALESKIT_BASE_PATH}${AGENTLEADFORM_PATH}`}
        to={`${SALESKIT_BASE_PATH}${AGENTLEADFORM_PATH}/detail`}
      />
      <PruRoute
        exact
        path={`${SALESKIT_BASE_PATH}${AGENTLEADFORM_PATH}/detail`}
        render={(props) => <AgentLeadFormPage {...props} />}
      />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default AgentLeadFormRoutes;
