import { RuleItem, RuleOperatorItem } from "../enum/rule-enum"

export enum ExclusionRuleFormMode {
  CREATE = "CREATE",
  EDIT = "EDIT"
}

export type ExclusionRuleOperator = {
  type: string;
  operator: string;
  priority?: number;
  value?: string | string[];
} & RuleOperatorItem

export type ExclusionRuleItem = {
  name?: string;
  items: ExclusionRuleOperator[];
} & RuleItem

export type ExclusionRule = {
  _id: string;
  name: string;
  description?: string;
  enforcedBy: string;
  rules: ExclusionRuleItem[];
  isAllLead: boolean;
  daysOfAgingLead?: number;
  createdBy: string;
  updatedBy: string;
  createdAt: Date;
  updatedAt: Date;
}