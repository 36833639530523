import React, { FC, useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Button } from '@mui/material';
import { toAbsoluteUrl } from 'src/app/common/utils';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { appendAlertItem, AlertType } from '../../../../redux/common/commonSlice';
import { useHistory } from 'react-router-dom';

type PRUForceRedirectPageProps = {
  initialParams?: Record<string, string>;
};

const useStyles = makeStyles()((theme) => ({
  container: {
    position: 'relative',
    height: '100%',
    width: '100%',
  },
  contentContainer: {
    position: 'absolute',
    width: '70%',
    maxWidth: 350,
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'inline',
    textAlign: 'center',
  },
  imgContainer: {
    width: '80%',
    marginBottom: 30,
  },
  loginSuccessText: {
    fontWeight: 600,
    fontSize: 24,
    lineHeight: 2,
    color: '#333333',
    marginBottom: 36,
  },
  btnRoot: {
    fontSize: 16,
    lineHeight: 1.5,
    fontWeight: 600,
    borderRadius: 12,
    textTransform: 'none',
    color: '#FFFFFF',
    backgroundColor: '#ED1B2E',
    padding: '14px 0',
    '&:hover': {
      backgroundColor: '#ED1B2E',
    },
  },
}));

const PRUForceRedirectPage: FC<PRUForceRedirectPageProps> = ({ initialParams }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const { classes } = useStyles();
  const authorizationCode = initialParams ? initialParams.authorizationCode : '';

  if (!authorizationCode) {
    dispatch(
      appendAlertItem([
        {
          severity: AlertType.ERROR,
          title: 'Error',
          content: 'Authentication failure',
        },
      ]),
    );
    history.push('/');
  }

  const origin = useMemo(() => {
    const env = window.envConfig['REACT_APP_ENV'];
    if (env === 'uat') {
      return 'https://pruforceagentuat.prudential.com.hk';
    }
    if (env === 'prod') {
      return 'https://pruforceagent.prudential.com.hk';
    }
    return window.location.origin;
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.contentContainer}>
        <img
          className={classes.imgContainer}
          src={toAbsoluteUrl('/media/pruforce-logo/mainlogo.png')}
          alt="PRUForce logo"
        />
        <div className={classes.loginSuccessText}>{Translation('stafflogon.success')}</div>
        <a href={`${origin}/pruforce/stafflogon?authorizationCode=${authorizationCode}`}>
          <Button
            fullWidth
            variant="contained"
            classes={{
              root: classes.btnRoot,
            }}
          >
            {Translation('stafflogon.btn.redirect')}
          </Button>
        </a>
      </div>
    </div>
  );
};

export default PRUForceRedirectPage;
