import { Dispatch } from 'react';
import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import { forEach } from 'lodash';
import { PaginateList } from 'src/app/common/types/common-types';
import { EligibilityRule, EligibilityRuleNewAgent, EligibilityRuleOperator } from '../types/eligibility-rule-types';

const eligibilityRuleEndpoint = 'eligibility-rules';

export type EligibilityRuleListParam = {
  name: string;
  page: number;
  limit: number;
};

export type CreateEligibilityRuleBody = {
  name: string;
  description?: string;
  agentType: string;
  PRULeadsTraining: boolean;
  newAgentRules?: EligibilityRuleNewAgent[];
  rules: EligibilityRuleOperator[];
  listOfAgents?: string[];
  createdBy: string;
  updatedBy: string;
};

export type UpdateEligibilityRuleBody = {
  name: string;
  description?: string;
  agentType: string;
  PRULeadsTraining: boolean;
  newAgentRules?: EligibilityRuleNewAgent[];
  rules: EligibilityRuleOperator[];
  listOfAgents?: string[];
  updatedBy: string;
};

export const fetchEligibilityRuleList = async (
  params: EligibilityRuleListParam,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<EligibilityRule>> => {
  let queryPath = `${ADMIN_URL}/${eligibilityRuleEndpoint}?`;
  forEach(params, (param, key) => {
    if (key === 'page') {
      queryPath += `${key}=${encodeURIComponent(param as any)}&`;
    } else {
      queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
    }
  });
  return apiClient
    .get<PaginateList<EligibilityRule>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchEligibilityRuleItem = async (id: string, dispatch?: Dispatch<any>): Promise<EligibilityRule> => {
  return apiClient
    .get<EligibilityRule>(`${ADMIN_URL}/${eligibilityRuleEndpoint}/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createEligibilityRule = async (
  body: CreateEligibilityRuleBody,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .post<void>(`${ADMIN_URL}/${eligibilityRuleEndpoint}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const modifyEligibilityRule = async (
  id: string,
  body: UpdateEligibilityRuleBody,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .put<void>(`${ADMIN_URL}/${eligibilityRuleEndpoint}/${id}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const deleteEligibilityRule = async (id: string, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .delete<void>(`${ADMIN_URL}/${eligibilityRuleEndpoint}/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
