import React, { FC, useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PaginateList } from 'src/app/common/types/common-types';
import { ProductCampaignItem } from 'src/app/modules/PulseLeads/types/product-campaign-types';
import { deleteProductCampaign } from 'src/app/modules/PulseLeads/network/productCampaignCrud';
import { productCampaignPath } from '../../ProductCampaignRoutes';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import ItemDeleteDialog from 'src/app/common/components/ItemDeleteDialog';

type ProductCampaignListProps = {
  isLoading: boolean;
  productCampaignList?: PaginateList<ProductCampaignItem>;
  config?: any;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
};

type DialogState = {
  open: boolean;
  id: string;
};

const getFieldConfig = (config: any) => {
  return {
    enableProductCampaignOptions: config?.content?.Sales?.campaign?.enableProductCampaignOptions ? true : false,
  };
};

const ProductCampaignList: FC<ProductCampaignListProps> = ({
  isLoading,
  productCampaignList,
  config,
  onRefresh,
  onChangePage,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const fieldConfig = getFieldConfig(config);
  const [dialogState, setDialogState] = useState<DialogState>({
    open: false,
    id: '',
  });

  const onDelete = async (id: string) => {
    try {
      await deleteProductCampaign(id, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Product Campaign deleted successfully - ${id}`,
          },
        ]),
      );
    } catch (err) {}
  };

  return (
    <>
      <ItemDeleteDialog
        key={`delete-application-dialog-${dialogState.open}`}
        open={dialogState.open}
        onClose={() => setDialogState({ open: false, id: '' })}
        onRefresh={onRefresh}
        onDelete={() => onDelete(dialogState.id)}
      />
      <PruTable
        title={Translation('pulseleads.straightThrough.productCampaignList')}
        disableBulkSelect
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('pulseleads.straightThrough.createProductCampaign'),
            onClick: () => history.push(`${productCampaignPath}/create`),
          },
        ]}
        operationDef={[
          {
            title: Translation('section.common.operation.edit'),
            tooltipText: 'Edit Product Code',
            onClick: (row) => history.push(`${productCampaignPath}/edit/${row._id}`),
          },
          {
            title: Translation('section.common.operation.delete'),
            tooltipText: 'Delete Product Code',
            onClick: (row) => setDialogState({ open: true, id: row._id }),
          },
        ]}
        columnDef={[
          {
            isId: true,
            hidden: true,
            keyIndex: '_id',
            displayName: '',
            renderData: (row) => '',
          },
          {
            keyIndex: 'productCode',
            displayName: Translation('pulseleads.straightThrough.productCode'),
            renderData: (row) => row.productCode || '-',
          },
          {
            keyIndex: 'campaignName',
            displayName: Translation('pulseleads.straightThrough.campaignName'),
            renderData: (row) => row.campaignName || '-',
          },
          ...(fieldConfig.enableProductCampaignOptions
            ? [
                {
                  keyIndex: 'product',
                  displayName: Translation('pulseleads.straightThrough.matchedProduct'),
                  renderData: (row: ProductCampaignItem) => row.product || '-',
                },
                {
                  keyIndex: 'interestedProductPlan',
                  displayName: Translation('pulseleads.straightThrough.interestedProduct'),
                  renderData: (row: ProductCampaignItem) => row.interestedProductPlan || '-',
                },
                {
                  keyIndex: 'leadDetail',
                  displayName: Translation('pulseleads.straightThrough.otherLeadComments'),
                  renderData: (row: ProductCampaignItem) => row.leadDetail || '-',
                },
              ]
            : []),
        ]}
        isLoading={isLoading}
        onRefresh={onRefresh}
        dataSource={productCampaignList?.docs}
        totalPages={productCampaignList?.totalPages}
        totalRecords={productCampaignList?.totalDocs}
        onChangePage={onChangePage}
      />
    </>
  );
};

export default ProductCampaignList;
