import { makeStyles } from 'tss-react/mui';

export default makeStyles()((theme) => ({
    setBox: {
        padding: 10,
        borderWidth: '1px',
        borderColor: 'gray',
        borderStyle: 'solid',
        marginBottom: 15
    },
    setName: {
        fontWeight: 'bold',
        marginRight: 15
    }
}));