import { Dispatch } from 'react';
import { forEach } from 'lodash';
import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import {
  ColumnPaginateList,
  ColumnItem,
  ColumnEditItem,
  ColumnPublishedMode,
  ColumnListParam,
} from '../types/column-types';
import qs from 'qs';

export const validateColumWeight = async (module: string, weight: string | number, id: number) => {
  const queryPath = `${ADMIN_URL}/content-manager/api/columns`;
  const query = {
    publicationState: 'preview',
    filters: {
      module,
      weight,
    },
  };
  const result = await apiClient.get(`${queryPath}?${qs.stringify(query)}`, {
    headers: {
      'raw-path': 'true',
    },
  });
  const data = result.data.data || [];

  return data.filter((item: any) => item.id !== id).length === 0;
};

export const fetchAllColumns = async (module: string) => {
  const query = {
    publicationState: 'preview',
    pagination: {
      limit: -1,
    },
    filters: {
      module,
    },
  };

  const queryPath = `${ADMIN_URL}/content-manager/api/columns?${qs.stringify(query)}`;

  return apiClient
    .get(queryPath, {
      headers: {
        'raw-path': true,
      },
    })
    .then((response) => {
      const data = response.data;
      data.data = (data.data || []).map((item: any) => {
        item.attributes.id = item.id;
        item.attributes._id = item.id;
        return item.attributes;
      });

      return data.data;
    });
};

export const fetchColumnItem = (id: string, dispatch?: Dispatch<any>): Promise<ColumnItem> => {
  let queryPath = `${ADMIN_URL}/content-manager/collection-types/api::column.column/${id}`;
  return apiClient
    .get<ColumnItem>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createNewColumn = async (data: ColumnEditItem, dispatch?: Dispatch<any>): Promise<ColumnItem> => {
  let createURL = `${ADMIN_URL}/content-manager/collection-types/api::column.column`;
  return apiClient
    .post<ColumnItem>(createURL, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const modifyColumn = async (
  code: string,
  data: ColumnEditItem,
  dispatch?: Dispatch<any>,
): Promise<ColumnItem> => {
  const modifyURL = `${ADMIN_URL}/content-manager/collection-types/api::column.column/${code}`;
  return apiClient
    .put<ColumnItem>(modifyURL, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const publishColumn = async (dataId: string, dispatch?: Dispatch<any>): Promise<void> => {
  const publishURL = `${ADMIN_URL}/content-manager/collection-types/api::column.column/${dataId}/actions/publish`;
  return apiClient
    .post<void>(
      publishURL,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const unpublishColumn = async (dataId: string, dispatch?: Dispatch<any>): Promise<void> => {
  const unpublishURL = `${ADMIN_URL}/content-manager/collection-types/api::column.column/${dataId}/actions/unpublish`;
  return apiClient
    .post<void>(
      unpublishURL,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
