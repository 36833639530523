export type usersReportParams = {
  businessUnit: string;
  region: string;
  branch: string;
  unit: string;
  mda: string;
  agentCode: string;
  agentName: string;
  position: string;
  segment: string;
  pvm: string;
  dateRange: {
    startDate: Date | undefined;
    endDate: Date | undefined;
  };
  others: string;
  channel: string;
};

export type regionReportParams = {
  businessUnit: string;
  region: string;
  branch: string;
  unit: string;
  mda: string;
  dateRange: {
    startDate: Date | undefined;
    endDate: Date | undefined;
  };
  segment: string;
  channel: string;
};

export type overviewReportParams = {
  startDt: Date | undefined;
  endDt: Date | undefined;
  region: string;
  channel: string;
};

export type reportType = {
  chartName: string;
  chartId: string;
};

export const dataReportLibraryDev: reportType = {
  chartName: 'AOL Data',
  // chartId: '632ae2fe-7895-41cc-835c-76db7343aabb', // DEV HK
  // chartId: '6513c490-953f-4332-81c9-2dbf35a1c94e', // COE
  chartId: '6554322d-a77b-4e72-841a-3e14aee5cb66', // COE GCP
};

export const usersReportLibraryDev: reportType = {
  chartName: 'AOL Users',
  // chartId: '63298aee-ebcf-476f-8066-864e79f4b5ab', // DEV HK
  // chartId: '6513c490-953f-4224-8124-2dbf35a1c944', // COE
  chartId: '6554322d-a77b-4f72-8a66-3e14aee5cb5e', // COE GCP
};

export const regionReportLibraryDev: reportType = {
  chartName: 'AOL Region & Branch',
  // chartId: '63298aee-ebcf-45e1-8054-864e79f4b5af', // DEV HK
  // chartId: '6513c490-953f-4167-80b6-2dbf35a1c948', // COE
  chartId: '6554322d-a77b-4ba0-8567-3e14aee5cb62', // COE GCP
};

export const overviewReportsLibraryDev: reportType[] = [
  {
    chartName: 'AOL Overview - Region',
    // chartId: '63298aee-ebcf-4d8d-8d7d-864e79f4b5ad', // DEV HK
    // chartId: '6513c490-953f-4296-8131-2dbf35a1c946', // COE
    chartId: '6554322d-a77b-4f7b-8a3d-3e14aee5cb68', // COE GCP
  },
  {
    chartName: 'AOL Overview - Business Unit',
    // chartId: '63298aee-ebcf-4ebf-8618-864e79f4b5b1', // DEV HK
    // chartId: '6513c490-953f-47b5-82b7-2dbf35a1c94a', // COE
    chartId: '6554322d-a77b-46bd-8d9d-3e14aee5cb6a', // COE GCP
  },
  {
    chartName: 'AOL Overview - Position',
    // chartId: '63298aee-ebcf-4a21-8c6d-864e79f4b5b3', // DEV HK
    // chartId: '6513c490-953f-45ad-870c-2dbf35a1c94c', // COE
    chartId: '6554322d-a77b-4a85-8ab6-3e14aee5cb60', // COE GCP
  },
  {
    chartName: 'AOL Overview - Segment',
    // chartId: '632d5adb-d3bd-447f-8233-347800b24607', //tbc: hidden in admin portal // DEV HK
    // chartId: '6513c490-953f-4894-8dda-2dbf35a1c950', // COE
    chartId: '6554322d-a77b-420e-8e3c-3e14aee5cb64', // COE GCP
  },
];

export const dataReportLibraryUat: reportType = {
  chartName: 'AOL Data',
  // chartId: '632ae2fe-7895-41cc-835c-76db7343aabb', // DEV HK
  // chartId: '6513c490-953f-4332-81c9-2dbf35a1c94e', // COE
  chartId: '6554322d-a77b-4e72-841a-3e14aee5cb66', // COE GCP
};

export const usersReportLibraryUat: reportType = {
  chartName: 'AOL Users',
  // chartId: '63298aee-ebcf-476f-8066-864e79f4b5ab', // DEV HK
  // chartId: '6513c490-953f-4224-8124-2dbf35a1c944', // COE
  chartId: '6554322d-a77b-4f72-8a66-3e14aee5cb5e', // COE GCP
};

export const regionReportLibraryUat: reportType = {
  chartName: 'AOL Region & Branch',
  // chartId: '63298aee-ebcf-45e1-8054-864e79f4b5af', // DEV HK
  // chartId: '6513c490-953f-4167-80b6-2dbf35a1c948', // COE
  chartId: '6554322d-a77b-4ba0-8567-3e14aee5cb62', // COE GCP
};

export const overviewReportsLibraryUat: reportType[] = [
  {
    chartName: 'AOL Overview - Region',
    // chartId: '63298aee-ebcf-4d8d-8d7d-864e79f4b5ad', // DEV HK
    // chartId: '6513c490-953f-4296-8131-2dbf35a1c946', // COE
    chartId: '6554322d-a77b-4f7b-8a3d-3e14aee5cb68', // COE GCP
  },
  {
    chartName: 'AOL Overview - Business Unit',
    // chartId: '63298aee-ebcf-4ebf-8618-864e79f4b5b1', // DEV HK
    // chartId: '6513c490-953f-47b5-82b7-2dbf35a1c94a', // COE
    chartId: '6554322d-a77b-46bd-8d9d-3e14aee5cb6a', // COE GCP
  },
  {
    chartName: 'AOL Overview - Position',
    // chartId: '63298aee-ebcf-4a21-8c6d-864e79f4b5b3', // DEV HK
    // chartId: '6513c490-953f-45ad-870c-2dbf35a1c94c', // COE
    chartId: '6554322d-a77b-4a85-8ab6-3e14aee5cb60', // COE GCP
  },
  {
    chartName: 'AOL Overview - Segment',
    // chartId: '632d5adb-d3bd-447f-8233-347800b24607', // DEV HK
    // chartId: '6513c490-953f-4894-8dda-2dbf35a1c950', // COE
    chartId: '6554322d-a77b-420e-8e3c-3e14aee5cb64', // COE GCP
  },
];

export const dataReportLibraryProd: reportType = {
  chartName: 'AOL Data',
  // chartId: '632ae2fe-7895-41cc-835c-76db7343aabb', // DEV HK
  // chartId: '6513c490-953f-4332-81c9-2dbf35a1c94e', // COE
  chartId: '6554322d-a77b-4e72-841a-3e14aee5cb66', // COE GCP
};

export const usersReportLibraryProd: reportType = {
  chartName: 'AOL Users',
  // chartId: '63298aee-ebcf-476f-8066-864e79f4b5ab', // DEV HK
  // chartId: '6513c490-953f-4224-8124-2dbf35a1c944', // COE
  chartId: '6554322d-a77b-4f72-8a66-3e14aee5cb5e', // COE GCP
};

export const regionReportLibraryProd: reportType = {
  chartName: 'AOL Region & Branch',
  // chartId: '63298aee-ebcf-45e1-8054-864e79f4b5af', // DEV HK
  // chartId: '6513c490-953f-4167-80b6-2dbf35a1c948', // COE
  chartId: '6554322d-a77b-4ba0-8567-3e14aee5cb62', // COE GCP
};

export const overviewReportsLibraryProd: reportType[] = [
  {
    chartName: 'AOL Overview - Region',
    // chartId: '63298aee-ebcf-4d8d-8d7d-864e79f4b5ad', // DEV HK
    // chartId: '6513c490-953f-4296-8131-2dbf35a1c946', // COE
    chartId: '6554322d-a77b-4f7b-8a3d-3e14aee5cb68', // COE GCP
  },
  {
    chartName: 'AOL Overview - Business Unit',
    // chartId: '63298aee-ebcf-4ebf-8618-864e79f4b5b1', // DEV HK
    // chartId: '6513c490-953f-47b5-82b7-2dbf35a1c94a', // COE
    chartId: '6554322d-a77b-46bd-8d9d-3e14aee5cb6a', // COE GCP
  },
  {
    chartName: 'AOL Overview - Position',
    // chartId: '63298aee-ebcf-4a21-8c6d-864e79f4b5b3', // DEV HK
    // chartId: '6513c490-953f-45ad-870c-2dbf35a1c94c', // COE
    chartId: '6554322d-a77b-4a85-8ab6-3e14aee5cb60', // COE GCP
  },
  {
    chartName: 'AOL Overview - Segment',
    // chartId: '632d5adb-d3bd-447f-8233-347800b24607', // DEV HK
    // chartId: '6513c490-953f-4894-8dda-2dbf35a1c950', // COE
    chartId: '6554322d-a77b-420e-8e3c-3e14aee5cb64', // COE GCP
  },
];
