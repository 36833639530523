import React, { PropsWithChildren } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Toolbar, lighten } from '@mui/material';
// import DeleteIcon from '@mui/icons-material/Delete';

const useToolbarStyles = makeStyles()((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.mode === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
}));

const ToolBarContainer: React.FC<PropsWithChildren<{ highlight?: boolean }>> = ({ children, highlight }) => {
  const { classes, cx } = useToolbarStyles();

  return (
    <Toolbar
      className={`${cx(classes.root, {
        [classes.highlight]: highlight,
      })} toolbar-container`}
    >
      {children}
    </Toolbar>
  );
};

export default ToolBarContainer;
