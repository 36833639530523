import React, { useEffect, useState } from 'react';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { styles, useStyles } from '../../../style';
import Form from 'src/app/common/components/Form';
import { Button, TextField } from '@mui/material';
import { regionLocale } from 'src/app/i18n';
import { MTmode } from 'src/app/common/constants';
import { FormSectionProps } from '../../../type/types';

const SubmissonSection: FC<FormSectionProps> = (props) => {
  const { form, formMode } = props;
  const isView = formMode === MTmode.VIEW;
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const DEFAULT_REQUIRED_RULES = { required: true, message: Translation('component.form-required-text') };
  const [data, setData] = useState(form.getFieldValue('submissionResponse'));

  useEffect(() => {
    // set default value for title & content
    if (!data?.title?.[0]?.value) {
      const newData = { ...data };
      newData.title[0].value = Translation('survey.form.thankyou.title');
      setData(newData);
    }
    if (!data?.content?.[0]?.value) {
      const newData = { ...data };
      newData.content[0].value = Translation('survey.form.thankyou.content');
      setData(newData);
    }
  }, []);

  useEffect(() => {
    if (form.getFieldValue('submissionResponse') !== data) {
      setData(form.getFieldValue('submissionResponse'));
    }
  }, [form.getFieldValue('submissionResponse')]);

  const handleChange = (value: string, key: string, index: number) => {
    const { ...body } = form.getFieldValue('submissionResponse');
    body[key][index].value = value;
    form.setFieldsValue({ submissionResponse: body });
    setData(body);
  };

  return (
    <div className="tw-bg-white tw-pt-6 tw-pb-6 tw-mt-6">
      <div className="tw-flex tw-mb-3">
        <div className={classes.subHeader}>{Translation('agent_referral_link_submission_setting')}</div>
      </div>

      {regionLocale?.map((language, index) => {
        return (
          <div className={classes.contentTextField} key={language}>
            <Form.Item
              name={['submissionResponse', 'title', index, 'value']}
              label={`${Translation('agent_referral_link_title')}(${language.toLocaleUpperCase()})`}
              rules={[DEFAULT_REQUIRED_RULES]}
            >
              <div className="tw-flex tw-flex-row tw-items-center tw-flex-1">
                <TextField
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  disabled={isView}
                  value={data?.title?.[index]?.value ?? ''}
                  onChange={(e) => {
                    handleChange(e.target.value, 'title', index);
                  }}
                  placeholder={Translation('survey.form.thankyou.title')}
                />
                {index !== 0 && (
                  <Button
                    size="small"
                    variant="text"
                    onClick={() => {
                      if (!isView) {
                        handleChange(form.getFieldValue(['submissionResponse', 'title', 0, 'value']), 'title', index);
                      }
                    }}
                    style={styles.FieldCopyText}
                  >
                    {Translation('agent_referral_link_copy')}
                  </Button>
                )}
              </div>
            </Form.Item>
          </div>
        );
      })}

      {regionLocale?.map((language: string, index: number) => {
        return (
          <div className={classes.contentTextField} key={language}>
            <Form.Item
              name={['submissionResponse', 'content', index, 'value']}
              label={`${Translation('agent_referral_link_content')}(${language.toLocaleUpperCase()})`}
              required={false}
            >
              <div className="tw-flex tw-flex-row tw-items-center tw-flex-1">
                <TextField
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  disabled={isView}
                  value={data?.content?.[index]?.value ?? ''}
                  onChange={(e) => {
                    handleChange(e.target.value, 'content', index);
                  }}
                  placeholder={Translation('survey.form.thankyou.content')}
                />
                {index !== 0 && (
                  <Button
                    size="small"
                    variant="text"
                    onClick={() => {
                      if (!isView) {
                        handleChange(
                          form.getFieldValue(['submissionResponse', 'content', 0, 'value']),
                          'content',
                          index,
                        );
                      }
                    }}
                    style={styles.FieldCopyText}
                  >
                    {Translation('agent_referral_link_copy')}
                  </Button>
                )}
              </div>
            </Form.Item>
          </div>
        );
      })}
    </div>
  );
};
export default SubmissonSection;
