export const PUBLIC_PORTAL_CONSENT_BASE_PATH = '/public-portal-consent';

export const DATE_ERROR_TEXT = 'End date must be after Begin date';

export const MANDATORY_FIELD_ERROR_TEXT = 'This field is mandatory';
export const CHOOSE_ONE_ERROR_TEXT = 'One of the fields must be filled';

export const DATE_FORMAT_ERROR_TEXT = 'Invalid date format';

//  These constants map to aside menu, header menu to display title
export const PUBLIC_PORTAL_CONSENT_TITLE = 'title.public_portal_consent';

//  These constants map to permission control, identical to permission enum defined below
export const PUBLIC_PORTAL_CONSENT_NAMESPACE = 'PUBLIC-PORTAL-CONSENT';

export enum AdminApplicationPermissionType {
  PUBLIC_PORTAL_CONSENT_READ = 'READ_ANY_PUBLIC-PORTAL-CONSENT',
  PUBLIC_PORTAL_CONSENT_CREATE = 'CREATE_ANY_PUBLIC-PORTAL-CONSENT',
  PUBLIC_PORTAL_CONSENT_UPDATE = 'UPDATE_ANY_PUBLIC-PORTAL-CONSENT',
}

export const PUBLIC_PORTAL_CONSENT_CONTENT =
  'We use cookies to give you better experience on our website. By continuing to browse this site, you give consent for cookies to be used. Refer to our [Privacy Policy](https://www.prudential.com.hk/tc/privacy-policy/) for details';
