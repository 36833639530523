import { Stepper, Step } from '@mui/material';
import { toAbsoluteUrl } from 'src/app/common/utils';
import { StepStatusEnum, FormCommonProps, StepItem, StepIndicator } from '../types/pru-stepped-form-types';
import { useStyles } from './pru-stepper.style';

type PruStepperProps<T extends FormCommonProps> = {
  level?: number;
  steps: StepItem<T>[];
  activeStep?: StepIndicator;
  formCommonProps: T;
  onStepChange: (newActiveStep: StepIndicator) => void;
};

const stepIcons = {
  [StepStatusEnum.DRAFT]: toAbsoluteUrl('/media/icon/progressBar/progress_future.png'),
  [StepStatusEnum.PROGRESS]: toAbsoluteUrl('/media/icon/progressBar/progress_current.png'),
  [StepStatusEnum.FINISHED]: toAbsoluteUrl('/media/icon/progressBar/progress_tick.png'),
  [StepStatusEnum.WARNING]: toAbsoluteUrl('/media/icon/progressBar/progress_tick_warning.png'),
};

export const PruStepper = <T extends FormCommonProps>({
  level = 0,
  steps,
  activeStep,
  formCommonProps,
  onStepChange,
}: PruStepperProps<T>) => {
  const { classes } = useStyles({ level });

  return (
    <Stepper activeStep={activeStep?.stepIndex ?? -1} orientation="vertical" connector={null}>
      {steps.map((stepItem, stepIndex) => {
        if (!stepItem.hidden || (stepItem.hidden && !stepItem.hidden(formCommonProps))) {
          return (
            <Step key={stepIndex}>
              <div className={classes.stepperContainer}>
                <div
                  className={classes.stepLabel}
                  onClick={() => onStepChange(stepItem.customActiveStep ?? { stepIndex })}
                >
                  {stepItem.disableStatus ? (
                    <img
                      className={classes.mainIcon}
                      alt="bulletpoint"
                      src={toAbsoluteUrl('/media/icon/bulletpoint2.png')}
                    />
                  ) : (
                    <img
                      className={classes.stepIcon}
                      alt="bulletpoint"
                      src={stepIcons[stepItem.status as StepStatusEnum]}
                    />
                  )}
                  <div className={stepItem.status ? classes.stepText : undefined}>{stepItem.label}</div>
                  {stepItem.status && (
                    <img className={classes.arrowIcon} alt="arrow" src={toAbsoluteUrl('/media/icon/arrow/right.png')} />
                  )}
                </div>
                {stepItem.child && (
                  <PruStepper
                    level={level + 1}
                    steps={stepItem.child}
                    activeStep={activeStep?.child}
                    formCommonProps={formCommonProps}
                    onStepChange={(child) => onStepChange({ stepIndex, child })}
                  />
                )}
              </div>
            </Step>
          );
        }
      })}
    </Stepper>
  );
};
