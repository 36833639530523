import React, { FC, useState } from 'react';
import { FormControlLabel, MenuItem, Select, TextField } from '@mui/material';
import { useWorkflowStyles } from 'src/app/modules/AgencyCampaign/pages/CampaignType/Form/pages/PageThree/CampaignTypeFormPageThree';
import { Translation } from 'src/app/i18n';
import AcmCheckbox from 'src/app/modules/AgencyCampaign/common/AcmCheckbox';
import Form from 'src/app/common/components/Form';
import { FormInstance } from 'rc-field-form';
import { AddLeadSettingItem } from 'src/app/modules/AgencyCampaign/types/campaign-type-types';

type AddLeadSettingFormProps = {
  form: FormInstance<any>;
  initialValues: AddLeadSettingItem;
};

const getTranslations = () => {
  return {
    period: Translation('period'),
    start_time: Translation('start_time'),
    end_time: Translation('end_time'),
    campaign_start_date: Translation('campaign_start_date'),
    campaign_end_date: Translation('campaign_end_date'),
    days_after: Translation('days_after'),
    approval_status_required: Translation('approval_status_required'),
    only_when_campaign_is_approved: Translation('only_when_campaign_is_approved'),
    must_be_an_integer_bigger_than_0: Translation('must_be_an_integer_bigger_than_0'),
  };
};

const getMenuFields = () => {
  return [
    {
      label: getTranslations().campaign_start_date,
      value: 'startDate',
    },
    {
      label: getTranslations().campaign_end_date,
      value: 'endDate',
    },
  ];
};
const AddLeadSettingForm: FC<AddLeadSettingFormProps> = ({ form, initialValues }) => {
  const { classes: workflowClasses } = useWorkflowStyles();
  const DEFAULT_REQUIRED_RULES = {
    required: true,
    message: Translation('component.pulseleads.prospect.form-required-text'),
  };
  const [needApprovalChecked, setCheckStatus] = useState(initialValues.needACMApproved);
  const LEADSETTING_ENDDATESETTING_VALUE_VALIDATE = [
    {
      message: getTranslations().must_be_an_integer_bigger_than_0,
      validator(_: any, value: string) {
        if (!value?.match?.(/^\d+$/)) {
          return Promise.reject(new Error(getTranslations().must_be_an_integer_bigger_than_0));
        }
        return Promise.resolve();
      },
    },
  ];
  const placeEnter = Translation('app.input.placeholder.please-input-a-number');
  return (
    <div className={workflowClasses.rowContainer}>
      <div className="col-3" />
      <div className="col-9">
        <div className={workflowClasses.leadSettingContainer}>
          <div className="tw-font-semibold text-sm tw-leading-5 tw-mb-2">{getTranslations().period}</div>
          <div className="tw-ml-5">
            <div className="tw-flex tw-flex-row tw-mb-5">
              <div className="tw-w-2/12">{getTranslations().start_time}</div>
              <div className="tw-w-10/12">{getTranslations().campaign_start_date}</div>
            </div>
            <div className={'tw-flex tw-flex-row tw-mb-5'}>
              <div className="tw-w-2/12 tw-pt-5">{getTranslations().end_time}</div>
              <div className="tw-w-1/4">
                <Form.Item name={['endDateSetting', 'value']} rules={LEADSETTING_ENDDATESETTING_VALUE_VALIDATE}>
                  <TextField type="number" margin="dense" variant="outlined" fullWidth placeholder={placeEnter} />
                </Form.Item>
              </div>
              <span className="tw-leading-5 tw-w-1/6 tw-text-center tw-pt-5">{getTranslations().days_after}</span>
              <div className="tw-w-5/12 tw-pt-2">
                <Form.Item name={['endDateSetting', 'dependsOn']} rules={[DEFAULT_REQUIRED_RULES]}>
                  <Select
                    style={{ minWidth: 250 }}
                    variant="outlined"
                    margin="dense"
                  >
                    {getMenuFields().map((dropdownItem, index) => (
                      <MenuItem key={index} value={dropdownItem.value}>
                        {dropdownItem.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="tw-font-semibold text-sm tw-leading-5 tw-mb-2">
            {getTranslations().approval_status_required}
          </div>
          <div className="tw-ml-5">
            <Form.Item name="needACMApproved">
              <FormControlLabel
                style={{ margin: '2px 12px 2px 0' }}
                control={
                  <AcmCheckbox
                    onChange={(e) => {
                      form.setFieldsValue({
                        needACMApproved: e.target.checked,
                      });
                      setCheckStatus(e.target.checked);
                    }}
                  />
                }
                label={getTranslations().only_when_campaign_is_approved}
                labelPlacement="end"
                checked={needApprovalChecked}
              />
            </Form.Item>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddLeadSettingForm;
