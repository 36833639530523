import { TargetApplicantRadioModeConfig } from './types';

export const ENABLE_DESIGNATION = window.envConfig['ENABLE_DESIGNATION'] === 'true';
export const ENABLE_ZONE = window.envConfig['ENABLE_ZONE'] === 'true';

export const initRadioModeConfig: TargetApplicantRadioModeConfig = {
  targetAgentBy: undefined,
  eligibleDesignations: [],
  eligibleZones: [],
  eligibleAgents: [],
  excludedAgents: [],
  eligibleAgentListFile: undefined,
  excludedAgentListFile: undefined,
};
