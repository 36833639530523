import { Checkbox, FormControlLabel, FormHelperText, TextField } from '@mui/material';
import React, { useState, useEffect, useReducer } from 'react';
import { Button } from '@mui/material';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { useProfileStyles } from 'src/app/modules/Profile/profileStyle';
import {
  BannerTierformMode,
  ConfigBannerTierListItem,
  imageUploadType,
  MultiLanguage,
} from 'src/app/modules/Profile/types/agent-profile-types';
import { createBlob, getBlob } from 'src/app/common/network';
import { AlertType, appendAlertItem } from 'src/redux/common/commonSlice';
import { getConfig, I18nGenericData } from 'src/app/i18n';
import {
  createBannerTier,
  createBannerTierBody,
  deleteBannerTier,
  modifyBannerTier,
} from 'src/app/modules/Profile/network/agentProfileCrud';
import { configBannerTierPath } from '../ConfigBannerTierRoutes';
import { ErrorFieldType, useErrorHandler, fileUpload, getFileExtendingName } from 'src/app/common/utils';
import _, { map } from 'lodash';
import ConfirmDialog from 'src/app/modules/PulseLeads/pages/Rule/ActivityPoint/PointRuleSettings/List/component/ComfirnDialog';
import { PruToast } from 'src/app/common/components/pru-toast';
import ImageWithCloseButton from 'src/app/common/components/ImageWithCloseButton';

type BannerTierFormState = {
  id?: string;
  banner: I18nGenericData<string>;
  bannerUrl: I18nGenericData<string>;
  name: I18nGenericData<string>;
  tierCode: string;
  isDefault?: boolean;
};

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof BannerTierFormState;
    value: any;
  };
};
export type ModifyNestedAction = {
  type: 'MODIFY_CONTENT';
  payload: {
    field: keyof BannerTierFormState;
    subField: any;
    value: any;
  };
};

export type MultiAction = {
  type: 'MULTI_ACTION';
  payload: {
    banner: any;
    bannerUrl: any;
  };
};
type DialogState = {
  open: boolean;
  id: string;
  method: string;
};
type BannerTierFormAction = ModifyFieldAction | ModifyNestedAction | MultiAction;
const formReducer = (state: BannerTierFormState, action: BannerTierFormAction): BannerTierFormState => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    case 'MODIFY_CONTENT':
      if (action.payload.field === 'banner') {
        console.log('banner', action.payload);

        state.banner[action.payload.subField as keyof MultiLanguage] = action.payload.value;
      } else if (action.payload.field === 'bannerUrl') {
        if (state.bannerUrl !== undefined) {
          state.bannerUrl[action.payload.subField as keyof MultiLanguage] = action.payload.value;
        } else {
          state.bannerUrl = { enUs: '', zhHk: '' };
          state.bannerUrl[action.payload.subField as keyof MultiLanguage] = action.payload.value;
        }
      } else if (action.payload.field === 'name') {
        state.name[action.payload.subField as keyof MultiLanguage] = action.payload.value;
      }

      return {
        ...state,
      };
    case 'MULTI_ACTION':
      console.log('MULTI_ACTION', action.payload);

      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
type BannerTierDetailFormProps = {
  formMode: BannerTierformMode;
  bannerTierDetail?: ConfigBannerTierListItem;
};
enum ToolTip {
  CANCEL = 'agentProfile.ad_reminder_cancel_message',
  DELETE = 'agentProfile.ad_delete_message',
  SUBMIT = 'agentProfile.ad_set_default_message',
}

const detailToStateConvertor = (bannerTier?: ConfigBannerTierListItem): BannerTierFormState => {
  return {
    name: bannerTier?.name || { enUs: '', zhHk: '' },
    banner: bannerTier?.banner || { enUs: '', zhHk: '' },
    bannerUrl: { enUs: '', zhHk: '' },
    tierCode: bannerTier?.tierCode || '',
    isDefault: bannerTier?.isDefault,
    id: bannerTier?.id,
  };
};

const BannerTierForm = ({ formMode, bannerTierDetail }: BannerTierDetailFormProps) => {
  const dispatch = useDispatch();
  const { classes } = useProfileStyles();
  const history = useHistory();
  const { classes: commonClasses } = useCommonStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const lang = getConfig().selectedLang;
  const [formState, formDispatch] = useReducer(formReducer, detailToStateConvertor(bannerTierDetail));
  const TranslationWithVariable = (key: string, val: number | string) => intl.formatMessage({ id: key }, { lang: val });
  const checkBoxLabel = Translation('local_language');
  const title =
    formMode === BannerTierformMode.CREATE
      ? `agentProfile.ad_add_agent_tier_header_title`
      : `agentProfile.ad_edit_agent_tier_header_title`;
  const [dialogState, setDialogState] = useState<DialogState>({
    open: false,
    id: '',
    method: '',
  });
  const [toolTip, setToolTip] = useState<string>();
  const changeBanner = async () => {
    formDispatch({
      type: 'MODIFY_CONTENT',
      payload: {
        field: 'banner',
        subField: 'enUs',
        value: formState.banner.zhHk,
      },
    });
  };
  const onClose = () => {
    setDialogState({ open: false, id: '', method: '' });
    setToolTip('');
  };
  const onCancel = () => {
    try {
      setToolTip(Translation(ToolTip.CANCEL));
      setDialogState({ open: true, id: '', method: 'onCancel' });
    } catch (err) {}
  };
  const submit = () => {
    try {
      if (!formState.isDefault) {
        onSubmit();
      } else {
        setToolTip(Translation(ToolTip.SUBMIT));
        setDialogState({ open: true, id: '', method: 'onSubmit' });
      }
    } catch (err) {}
  };
  const onDelete = async (id: string) => {
    try {
      await deleteBannerTier(id, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Tier deleted successfully`,
          },
        ]),
      );
      history.push(configBannerTierPath);
    } catch (err) {}
  };
  const onConfirm = () => {
    if (dialogState.method === 'onCancel') {
      history.push(configBannerTierPath);
      PruToast({
        message: Translation('agentProfile.ad_cancelled_successfully_label'),
      });
    } else if (dialogState.method === 'onSubmit') {
      onSubmit();
    } else if (dialogState.method === 'onDelete') {
      onDelete(formState.tierCode);
    }

    return;
  };
  const getResourceById = async (id: string, lang: string, updateLocalLang?: string) => {
    try {
      if (!id) return;
      const blobDetail = await getBlob({ resourceIds: id }, dispatch);

      const result = blobDetail[0];

      if (result) {
        formDispatch({ type: 'MODIFY_CONTENT', payload: { field: 'bannerUrl', subField: lang, value: result.url } });
        if (updateLocalLang) {
          formDispatch({
            type: 'MODIFY_CONTENT',
            payload: { field: 'bannerUrl', subField: updateLocalLang, value: result.url },
          });
        }
      }
    } catch (e) {
      console.log('get resource error -->', e);
    }
  };
  useEffect(() => {
    const bannerIdEn = formState.banner?.enUs || '';
    const bannerIdLocal = formState.banner?.zhHk || '';

    if (bannerIdLocal && bannerIdLocal === bannerIdEn) {
      getResourceById(bannerIdEn, 'enUs', 'zhHk');
    } else {
      getResourceById(bannerIdEn, 'enUs');
      getResourceById(bannerIdLocal, 'zhHk');
    }
  }, [formState.banner]);

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler, immediateErrorValidator } = useErrorHandler(
    formState,
    [
      {
        name: 'name',
        fieldType: ErrorFieldType.MANDATORY,
        condition() {
          if (!formState.name['enUs']) {
            return true;
          }
          return false;
        },
      },
      {
        name: 'banner',
        fieldType: ErrorFieldType.MANDATORY,
        condition() {
          if (!formState.banner['enUs']) {
            return true;
          }
          return false;
        },
      },
      {
        name: 'tierCode',
        fieldType: ErrorFieldType.MANDATORY,
      },
    ],
  );
  const [uploadFileProgress, setUploadFileProgress] = useState<number>();
  const handleFile = async (
    e: React.ChangeEvent<HTMLInputElement>,
    fileType: keyof BannerTierFormState,
    lang: string,
  ) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];

      try {
        const createBlobRes = await createBlob(
          { mimeType: file.type, accessLevel: 'public', originalFilename: file.name, module: 'agentProfile' },
          dispatch,
        );
        await fileUpload(createBlobRes.url, file, setUploadFileProgress);
        const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);
        const result = blobDetail[0];

        const banner = { ...formState.banner };
        const bannerUrl = { ...formState.bannerUrl };
        banner[lang] = result.blobId;

        bannerUrl[lang] = result.url;

        if (result) {
          formDispatch({
            type: 'MULTI_ACTION',
            payload: {
              banner: banner,
              bannerUrl: bannerUrl,
            },
          });

          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: `Upload file successfully - ${file.name}`,
              },
            ]),
          );
        }
      } finally {
        setUploadFileProgress(undefined);
      }
    }
  };
  const onSubmit = async () => {
    const { hasError, currentErrorState } = onSubmitErrorValidator();
    console.log('submit-formState', formState);

    try {
      const body: createBannerTierBody = {
        name: formState.name,
        tierCode: formState.tierCode,
        isDefault: formState.isDefault,
        banner: Object.fromEntries(
          Object.entries(formState.banner).filter(([_, v]) => v && v !== '' && !v.includes('https')),
        ),
      };
      if (_.isEmpty(body.banner)) delete body.banner;

      if (!hasError) {
        if (formMode === BannerTierformMode.CREATE) {
          const createResult = await createBannerTier(body, dispatch);

          if (createResult.statusCode === 200 || createResult.id) {
            dispatch(
              appendAlertItem([
                {
                  severity: AlertType.SUCCESS,
                  title: 'Success',
                  content: `Banner Tier updated successfully`,
                },
              ]),
            );
            history.push(configBannerTierPath);
          }
        } else if (formMode === BannerTierformMode.EDIT) {
          const modifyResult = await modifyBannerTier(body, formState.tierCode, dispatch);
          if (modifyResult.statusCode === 200 || modifyResult.id) {
            dispatch(
              appendAlertItem([
                {
                  severity: AlertType.SUCCESS,
                  title: 'Success',
                  content: `Banner Tier updated successfully`,
                },
              ]),
            );
            history.push(configBannerTierPath);
          }
        }
      }
    } catch (e) {
      console.log('error--->', e);
    }
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <ConfirmDialog
            key={`delete-settings-rule-dialog-${dialogState.open}`}
            open={dialogState.open}
            onClose={() => onClose()}
            onRefresh={() => ''}
            onConfirm={async () => onConfirm()}
            title={Translation('agentProfile.ad_reminder_title')}
            content={toolTip}
          />
          <div className={classes.rowContainer}>
            <div className={commonClasses.header}>{Translation(title)}</div>
          </div>
          {/* <Button variant="contained" color="inherit" onClick={() => history.push(`${configBannerTierPath}`)}>
            {Translation('app.button.back')}
          </Button> */}
        </div>
        <div className={classes.rowContainer} style={{ marginTop: 8 }}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>
              {Translation('agentProfile.ad_tier_name_label')} <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <div>
            <div style={{ flexGrow: 1 }}>
              <TextField
                style={{ minWidth: 310 }}
                margin="dense"
                variant="outlined"
                error={errorState.mandatory.name}
                helperText={errorState.mandatory.name && 'Tier name is mandatory'}
                value={formState.name.enUs}
                onChange={(e) => {
                  formDispatch({
                    type: 'MODIFY_CONTENT',
                    payload: { field: 'name', subField: 'enUs', value: e.target.value },
                  });
                }}
              />
            </div>
          </div>
        </div>
        <div className={classes.rowContainer} style={{ marginTop: 8 }}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>
              {Translation('agentProfile.ad_tier_code_label')} <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <div>
            <div style={{ flexGrow: 1 }}>
              <TextField
                disabled={formMode === BannerTierformMode.EDIT}
                style={{ minWidth: 310 }}
                error={errorState.mandatory.tierCode}
                margin="dense"
                variant="outlined"
                value={formState.tierCode}
                helperText={errorState.mandatory.tierCode && 'This field is mandatory'}
                onChange={(e) => {
                  formDispatch({
                    type: 'MODIFY_FIELD',
                    payload: { field: 'tierCode', value: e.target.value },
                  });
                }}
              />
            </div>
          </div>
        </div>
        <div className={classes.rowContainer} style={{ marginTop: 8 }}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>{Translation('agentProfile.ad_banner_local_label')} :</span>
            {formState?.bannerUrl?.zhHk && (
              <>
                <div className="tw-flex tw-flex-row">
                  <ImageWithCloseButton
                    url={formState?.bannerUrl?.zhHk}
                    imageAlt="App Icon"
                    imageStyle={{ width: undefined, height: undefined, aspectRatio: '2:1' }}
                    onClose={() => {
                      formDispatch({
                        type: 'MODIFY_CONTENT',
                        payload: {
                          field: 'bannerUrl',
                          subField: 'zhHk',
                          value: '',
                        },
                      });
                      formDispatch({
                        type: 'MODIFY_CONTENT',
                        payload: {
                          field: 'banner',
                          subField: 'zhHk',
                          value: '',
                        },
                      });
                    }}
                  />
                </div>
              </>
            )}
          </div>
          <div>
            <div>
              <input
                id="upload-banner-tier-image-local"
                hidden
                type="file"
                accept="image/jpg, image/jpeg, image/png"
                onClick={(e) => {
                  const element = e.target as HTMLInputElement;
                  element.value = '';
                }}
                onChange={(e) => {
                  if (e.target.files && e.target.files.length > 0) {
                    const file = e.target.files[0];
                    const fileExtend = getFileExtendingName(file.name).toLowerCase();
                    if (imageUploadType.has(fileExtend)) {
                      const fileSize = file.size / 1024 / 1024;
                      const errMsg = TranslationWithVariable('agentProfile.ad_exceed_max_file_size_reminder', 2) || '';
                      if (fileSize > 2) {
                        dispatch(
                          appendAlertItem([
                            {
                              severity: AlertType.ERROR,
                              title: '',
                              content: errMsg,
                            },
                          ]),
                        );
                        return;
                      }
                      handleFile(e, 'banner', 'zhHk');
                    } else {
                      dispatch(
                        appendAlertItem([
                          {
                            severity: AlertType.WARNING,
                            title: Translation('fail_title'),
                            content: Translation('agentProfile.ad_upload_fail_file_format_message'),
                          },
                        ]),
                      );
                    }
                  }
                }}
              />
              <Button
                variant="contained"
                color="secondary"
                onClick={() => document.getElementById('upload-banner-tier-image-local')!.click()}
              >
                {Translation('app.button.chooseFile')}
              </Button>
              <span style={{ paddingLeft: 12 }}>{Translation('agentProfile.ad_image_upload_recommendation')}</span>
            </div>
          </div>
        </div>
        <FormControlLabel
          control={
            <Checkbox
              onChange={(e) => {
                if (e.target.checked) {
                  formDispatch({
                    type: 'MODIFY_CONTENT',
                    payload: {
                      field: 'bannerUrl',
                      subField: 'enUs',
                      value: formState.bannerUrl.zhHk,
                    },
                  });
                  formDispatch({
                    type: 'MODIFY_CONTENT',
                    payload: {
                      field: 'banner',
                      subField: 'enUs',
                      value: formState.banner.zhHk,
                    },
                  });
                }
              }}
              name="copyMainLangVersion"
              color="secondary"
            />
          }
          label={Translation('agentProfile.ad_use_local_option')}
        />
        <div className={classes.rowContainer} style={{ marginTop: 8 }}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>
              {Translation('agentProfile.ad_banner_en_label')}
              <span className={classes.mandatory}>*</span> :
            </span>
            {formState?.bannerUrl?.enUs && (
              <div className="tw-flex tw-flex-row">
                <ImageWithCloseButton
                  url={formState?.bannerUrl?.enUs}
                  imageAlt="App Icon"
                  imageStyle={{ width: undefined, height: undefined, aspectRatio: '2:1' }}
                  onClose={() => {
                    formDispatch({
                      type: 'MODIFY_CONTENT',
                      payload: {
                        field: 'bannerUrl',
                        subField: 'enUs',
                        value: '',
                      },
                    });
                    formDispatch({
                      type: 'MODIFY_CONTENT',
                      payload: {
                        field: 'banner',
                        subField: 'enUs',
                        value: '',
                      },
                    });
                  }}
                />
              </div>
            )}
          </div>
          {/* <div style={{ backgroundColor: 'red' }}> */}
          <div>
            <input
              id="upload-banner-tier-image-en"
              hidden
              type="file"
              accept="image/jpg, image/jpeg, image/png"
              onClick={(e) => {
                const element = e.target as HTMLInputElement;
                element.value = '';
              }}
              onChange={(e) => {
                if (e.target.files && e.target.files.length > 0) {
                  const file = e.target.files[0];
                  const fileExtend = getFileExtendingName(file.name).toLowerCase();

                  if (imageUploadType.has(fileExtend)) {
                    const fileSize = file.size / 1024 / 1024;
                    const errMsg = TranslationWithVariable('agentProfile.ad_exceed_max_file_size_reminder', 2) || '';
                    if (fileSize > 2) {
                      dispatch(
                        appendAlertItem([
                          {
                            severity: AlertType.ERROR,
                            title: '',
                            content: errMsg,
                          },
                        ]),
                      );
                      return;
                    }
                    handleFile(e, 'banner', 'enUs');
                  } else {
                    dispatch(
                      appendAlertItem([
                        {
                          severity: AlertType.WARNING,
                          title: Translation('fail_title'),
                          content: Translation('agentProfile.ad_upload_fail_file_format_message'),
                        },
                      ]),
                    );
                  }
                }
              }}
            />
            <Button
              variant="contained"
              color="secondary"
              onClick={() => document.getElementById('upload-banner-tier-image-en')!.click()}
            >
              {Translation('app.button.chooseFile')}
            </Button>
            <span style={{ paddingLeft: 12 }}>{Translation('agentProfile.ad_image_upload_recommendation')}</span>
          </div>
          {errorState?.mandatory.banner && (
            <FormHelperText style={{ color: '#f018a6' }}>This field is mandatory</FormHelperText>
          )}
          {/* </div> */}
        </div>
        <div className={classes.rowContainer} style={{ marginTop: 8 }}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>{Translation('agentProfile.ad_set_default_label')} :</span>
          </div>
          <FormControlLabel
            label={''}
            control={
              <Checkbox
                checked={formState.isDefault === true}
                color="secondary"
                onChange={(e: any) => {
                  const target = e.target.checked;
                  if (target === true) {
                    formDispatch({
                      type: 'MODIFY_FIELD',
                      payload: { field: 'isDefault', value: true },
                    });
                  } else if (target === false) {
                    formDispatch({
                      type: 'MODIFY_FIELD',
                      payload: { field: 'isDefault', value: false },
                    });
                  }
                }}
              />
            }
          />
        </div>

        <div className={classes.centerFooterContainer}>
          <div
            style={{
              display: 'flex',
              boxSizing: 'border-box',
              minWidth: 350,
              justifyContent: 'space-around',
            }}
          >
            {formState.tierCode && (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  setToolTip(Translation(ToolTip.DELETE));
                  setDialogState({ open: true, id: '', method: 'onDelete' });
                }}
              >
                {Translation('app.button.delete')}
              </Button>
            )}
            <Button variant="contained" color="secondary" onClick={() => onCancel()}>
              {Translation('app.button.cancel')}
            </Button>
            <Button variant="contained" color="secondary" onClick={() => submit()}>
              {formMode === BannerTierformMode.CREATE ? Translation('app.button.add') : Translation('app.button.save')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default BannerTierForm;
