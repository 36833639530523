import React, { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import Detail from '../../Detail/index';
import { RuleNameItem, updateRuleNameList } from 'src/app/modules/PulseLeads/network/ruleConfigCrud';
import { Dialog } from '@mui/material';
import { regionLocale } from 'src/app/i18n';
import { headerBtn, columns } from '../columns';
type RuleNameListProps = {
  isLoading: boolean;
  listData: RuleNameItem[];
  onRefresh: () => void;
};

const RuleNameList: FC<RuleNameListProps> = ({ isLoading, listData, onRefresh }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [isEditMode, setEditMode] = useState(false);
  const [tableList, setTableList] = useState(listData);
  const [popUpVisible, setPopUpVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState<RuleNameItem>();
  const localeList = regionLocale;
  useEffect(() => {
    setTableList(listData);
  }, [listData]);

  function formatTableData(data: RuleNameItem[]) {
    data.forEach((item: RuleNameItem) => {
      Object.entries(item.name).forEach(([key, value]) => {
        item.name[key] = value.trim();
      });
    });
    setTableList([...data]);
  }

  function handleSave() {
    formatTableData(tableList);
    const editedRuleList = tableList.filter((item: RuleNameItem) => item?.edited === true);
    const validate = validateRuleName(editedRuleList);
    if (validate) {
      for (const i in tableList) {
        if (tableList[i].edited) {
          delete tableList[i].edited;
        }
      }
      onSaveEditedRule(editedRuleList);
      setEditMode(false);
      onRefresh();
    }
  }

  function validateRuleName(list: RuleNameItem[]) {
    const validate = list.every((item: RuleNameItem) => {
      localeList.forEach((lang: string) => {
        if (!item.name[lang]) {
          item.name[lang] = '';
        }
      });
      return !Object.values(item.name).includes('');
    });
    return validate;
  }

  async function onSaveEditedRule(list: RuleNameItem[]) {
    await updateRuleNameList(list);
  }

  function changeRow(e: any, row: RuleNameItem, lang: string) {
    row.name[lang] = e.target.value;
    row.edited = true;
    setTableList([...tableList]);
  }

  function showPopup(e: any, row: RuleNameItem) {
    setPopUpVisible(true);
    setSelectedItem(row);
  }

  return (
    <>
      <PruTable
        title={Translation('pulseleads.activityPoint.ruleName.title')}
        disableBulkSelect
        disablePagination
        disableRefresh
        headerBtnDef={headerBtn(Translation, setEditMode, isEditMode, handleSave)}
        operationDef={[]}
        columnDef={columns(Translation, showPopup, localeList, isEditMode, changeRow)}
        isLoading={isLoading}
        onRefresh={onRefresh}
        dataSource={tableList}
      />
      <Dialog
        open={popUpVisible}
        onClose={() => {
          setPopUpVisible(false);
        }}
        fullWidth
        maxWidth="lg"
      >
        <Detail popUpVisible={popUpVisible} ruleData={selectedItem} setPopUpVisible={setPopUpVisible} />
      </Dialog>
    </>
  );
};

export default RuleNameList;
