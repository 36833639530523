import { PaginateResult } from 'src/app/common/types/common-types';

export type CompanyPickPaginateList = PaginateResult<CompanyPickListItem>;

export type CoverpageType = {
  fileName: string;
  id: string;
  importId?: string;
  type?: string;
  url: string;
};

export type CompanyPickListResponse = {
  results: CompanyPickListItem[];
  pagination: StrapiPagination;
};

export type StrapiPagination = {
  page: number;
  pageSize: number;
  pageCount: number;
  total: number;
};

export type CompanyPickQueryParams = {
  name: string;
  status: string;
  excludeType: string;
  effectiveDate: Date | null;
  expiredDate: Date | null;
  page: number;
  pageSize: number;
};

export type CompanyPickListItem = {
  id: string;
  name: string;
  weight: number;
  status: string;
  effectiveDate: Date | null;
  expiredDate: Date | null;
  lastUpdatedBy: string;
  updatedAt: Date;
};

export type CompanyPickDetail = {
  name: string;
  coverpage: CoverpageType;
  locale: string;
  weight: number;
  status: string;
  effectiveDate: Date;
  expiredDate: Date;
  linkTo: string;
  linkType: string;
  linkParam?: any;
  lastUpdatedBy: string;
  module: string;
  updatedAt: Date;
  type: string;
  resourceId: string;
  uniqueId?: string;
  localizations?: CompanyPickDetail[];
};

export enum PublishedMode {
  ALL = '',
  PUBLISHED = 'Published',
  UNPUBLISHED = 'Unpublished',
  DRAFT = 'Draft',
}
