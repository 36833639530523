import React, { FC, useState } from 'react';
import { ParamsProps } from '../../../../../common/components/ParamsProvider';
import { fetchNewsHeadlineList, NewsHeadlineListParam } from '../../../network/newsCrud';
import { useDataProvider } from 'src/app/common/utils';
import { NewsHeadlinePaginateList } from '../../../types/news-type';
import { useDispatch } from 'react-redux';
import NewsHeadlineList from './components/NewsHeadlineList';

type NewsHeadlineListingPageProps = {} & ParamsProps;

const initialState: NewsHeadlineListParam = {
  limit: 5,
  page: 1,
};

const paramsInitiator = (initialParams?: Record<string, string>): NewsHeadlineListParam => {
  return initialParams
    ? {
        page: 1,
        limit: 5,
      }
    : initialState;
};

const NewsHeadlineListingPage: FC<NewsHeadlineListingPageProps> = ({ onChangeQueryParams }) => {
  const dispatch = useDispatch();
  const [filterState, setFilterState] = useState<NewsHeadlineListParam>(paramsInitiator());
  const [newsHeadlineList, setNewsHeadlineList] = useState<NewsHeadlinePaginateList>();

  const { isLoading, refreshData } = useDataProvider<NewsHeadlinePaginateList>(
    async () => {
      try {
        return await fetchNewsHeadlineList(filterState, dispatch);
      } catch (err) {}
    },
    setNewsHeadlineList,
    false,
  );

  return (
    <>
      <NewsHeadlineList
        isLoading={isLoading}
        newsHeadlineList={newsHeadlineList}
        onRefresh={() => {
          refreshData();
        }}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default NewsHeadlineListingPage;
