import { Dispatch } from 'react';
import { logout } from '../../modules/Auth/_redux/authSlice';
import { authenticateFailed, appendAlertItem, AlertType } from '../../../redux/common/commonSlice';

export type Error = {
  code: string;
  message: string;
};

export type ApiErrorResponse = {
  statusCode: number;
  message: string;
  code: ErrorType | string;
  errors: Error[];
};

export enum ErrorType {
  //  Error enum from auth
  AUTH_UNSUPPORTED_GRANT_TYPE = 'AuthenticationProcessApi-Auth-0',
  AUTH_UNPROCESSABLE_ENTITY = 'AuthenticationProcessApi-Auth-1',
  AUTH_TOKEN_NOT_FOUND = 'AuthenticationProcessApi-Auth-2',
  AUTH_ROLE_NOT_FOUND = 'AuthenticationProcessApi-Auth-3',
}

export const apiErrorHandler = (err?: ApiErrorResponse, dispatch?: Dispatch<any>, customMessage?: string) => {
  const error =
    err ||
    ({
      statusCode: 503,
      message: 'Network failed',
      code: 'Network failed',
      errors: [],
    } as ApiErrorResponse);

  const forceLogout = error.code === ErrorType.AUTH_TOKEN_NOT_FOUND;

  if (dispatch) {
    if (forceLogout) {
      dispatch(authenticateFailed());
      dispatch(logout());
    } else {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.ERROR,
            title: 'Error',
            content: customMessage ? customMessage : error.message,
          },
        ]),
      );
    }
  }

  return error;
};
