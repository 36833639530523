import { Dispatch } from 'react';
import { forEach } from 'lodash';
import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import { PaginateList } from 'src/app/common/types/common-types';
import { getDayStart, getDayEnd } from 'src/app/common/utils';
import {
  ApprovalCampaignItem,
  ResourceItem,
  ParticipantIndividualItem,
  RemarkItem,
  LouTemplateItem,
  WorkflowApprovalStatusEnum,
} from '../types/approval-types';
import { CampaignDetailItem, LeadUploadItem, ImportVerify, NonDownlineVerifyList } from '../types/staff-campaign-types';

const approvalEndpoint = 'agency-campaign-approval';

export type ApprovalListParam = {
  roles: string[];
  status: string;
  campaignName: string;
  campaignType: string;
  campaignId: string;
  applicantName: string;
  page: number;
  limit: number;
};

export type GetUserRoleParam = {
  type: string;
  user: string;
};

export type ParticipantListParam = {
  id: string;
  page: number;
  limit: number;
};

export type GenerateLouTemplateBody = {
  id: string;
  templateId: string;
  campaignTypeId?: string;
};

export type UpdateApprovalBody = {
  assignee: string;
  assigneeName: string;
  role: string[];
  agencyCampaignId: string;
  status: WorkflowApprovalStatusEnum;
  comment?: string;
};

export type UpdateRemarkBody = {
  id: string;
  remark: RemarkItem[];
};

export const fetchApprovalList = async (
  params: ApprovalListParam,
  sortKeys: { key: string; value?: string }[],
  dispatch?: Dispatch<any>,
): Promise<PaginateList<ApprovalCampaignItem>> => {
  let queryPath = `${ADMIN_URL}/${approvalEndpoint}?`;
  forEach(params, (param, key) => {
    if (typeof param === 'object') {
      if (param) {
        if (key === 'roles') {
          param.forEach((role) => {
            queryPath += `${key}=${encodeURIComponent(role)}&`;
          });
        }
      }
    } else {
      if (key === 'page') {
        queryPath += `${key}=${encodeURIComponent(param as any)}&`;
      } else {
        queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
      }
    }
  });

  //Query for sorting
  let sortingCount = 0;
  queryPath += `sortBy=`;
  forEach(sortKeys, (sortParam, key) => {
    if (sortParam.value) {
      queryPath += `${sortingCount === 0 ? `` : `,`}${encodeURIComponent(sortParam.key)}:${encodeURIComponent(
        sortParam.value,
      )}`;
      sortingCount++;
    }
  });
  if (sortingCount === 0) {
    queryPath += `updatedAt:desc`;
  }

  return apiClient
    .get<PaginateList<ApprovalCampaignItem>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchApprovalItem = async (id: string, dispatch?: Dispatch<any>): Promise<ApprovalCampaignItem> => {
  return apiClient
    .get<ApprovalCampaignItem>(`${ADMIN_URL}/${approvalEndpoint}/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchApprovalResources = async (id: string, dispatch?: Dispatch<any>): Promise<ResourceItem[]> => {
  return apiClient
    .get<ResourceItem[]>(`${ADMIN_URL}/${approvalEndpoint}/${id}/resources`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchUserRole = async (params: GetUserRoleParam, dispatch?: Dispatch<any>): Promise<string[]> => {
  let queryPath = `${ADMIN_URL}/user-role?`;
  forEach(params, (param, key) => {
    queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
  });
  return apiClient
    .get<string[]>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchParticipantList = async (
  params: ParticipantListParam,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<ParticipantIndividualItem>> => {
  let queryPath = `${ADMIN_URL}/${approvalEndpoint}/participant/list?`;
  forEach(params, (param, key) => {
    if (typeof param === 'object') {
      if (param) {
        if (key.includes('From')) {
          queryPath += `${key}=${encodeURIComponent(getDayStart(param).toISOString())}&`;
        }
        if (key.includes('To')) {
          queryPath += `${key}=${encodeURIComponent(getDayEnd(param).toISOString())}&`;
        }
      }
    } else {
      if (key === 'page') {
        queryPath += `${key}=${encodeURIComponent(param as any)}&`;
      } else {
        queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
      }
    }
  });

  return apiClient
    .get<PaginateList<ParticipantIndividualItem>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const addParticipant = async (data: any[], dispatch?: Dispatch<any>): Promise<LeadUploadItem[]> => {
  return apiClient
    .post<CampaignDetailItem>(`${ADMIN_URL}/agency-campaign-staff-campaign/agency-campaign/participant`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const verifyNonDownlineList = async (
  param: ImportVerify,
  dispatch?: Dispatch<any>,
): Promise<NonDownlineVerifyList> => {
  let path = `${ADMIN_URL}/agency-campaign-staff-campaign/agency-campaign/non-downline/verification`;
  return apiClient
    .post<any>(path, param, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchLouTemplateList = async (dispatch?: Dispatch<any>): Promise<LouTemplateItem[]> => {
  return apiClient
    .get<LouTemplateItem[]>(`${ADMIN_URL}/${approvalEndpoint}/lou-template/list`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const generateLouTemplate = async (body: GenerateLouTemplateBody, dispatch?: Dispatch<any>): Promise<string> => {
  return apiClient
    .post<string>(`${ADMIN_URL}/${approvalEndpoint}/lou-template`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const modifyApproval = async (body: UpdateApprovalBody, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .patch<void>(`${ADMIN_URL}/${approvalEndpoint}/update-approval`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const modifyRemark = async (body: UpdateRemarkBody, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .patch<void>(`${ADMIN_URL}/${approvalEndpoint}/update-remark`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const cancelCampaignSubmission = async (data: any, dispatch?: Dispatch<any>): Promise<any> => {
  return apiClient
    .post<any>(`${ADMIN_URL}/${approvalEndpoint}/discard-status`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const updateParticipantsRsvpStatusToAccepted = async (
  campaignId: string,
  dispatch?: Dispatch<any>,
): Promise<CampaignDetailItem> => {
  return apiClient
    .patch<CampaignDetailItem>(`${ADMIN_URL}/${approvalEndpoint}/agency-campaign/update-rsvp-accepted/${campaignId}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
