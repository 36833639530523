import { IntlShape } from 'react-intl';
import { ModuleHierachy } from '../../common/module/types';
import {
  PRODUCTION_TITLE,
  PRODUCTION_BASE_PATH,
  AdminProductionPermissionType,
  PRODUCTION_DEFAULT_TARGET_TITLE,
  PRODUCTION_DEFAULT_TARGET_BASE_PATH,
  PRODUCTION_DEFAULT_TARGET_NAMESPACE,
  PRODUCTION_TARGET_TITLE,
  PRODUCTION_TARGET_BASE_PATH,
  PRODUCTION_TARGET_NAMESPACE,
} from './constants';
import { isPermitted } from 'src/app/common/utils';
import DefaultTargetPage from './pages/DefaultTarget/DefaultTargetPage';
import SetTargetPage from './pages/SetTarget/SetTargetPage';

export const productionModuleConfig = (permissions: string[], intl: IntlShape): ModuleHierachy => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return {
    title: Translation(PRODUCTION_TITLE),
    path: PRODUCTION_BASE_PATH,
    icon: '/media/svg/icons/Shopping/Chart-bar2.svg',
    enableRead: isPermitted(
      [
        AdminProductionPermissionType.PRODUCTION_DEFAULT_TARGET_READ,
        AdminProductionPermissionType.PRODUCTION_DEFAULT_TARGET_CREATE,
        AdminProductionPermissionType.PRODUCTION_DEFAULT_TARGET_UPDATE,
        AdminProductionPermissionType.PRODUCTION_TARGET_READ,
        AdminProductionPermissionType.PRODUCTION_TARGET_CREATE,
        AdminProductionPermissionType.PRODUCTION_TARGET_UPDATE,
      ],
      permissions,
    ),
    child: [
      {
        title: Translation(PRODUCTION_DEFAULT_TARGET_TITLE),
        path: `${PRODUCTION_BASE_PATH}${PRODUCTION_DEFAULT_TARGET_BASE_PATH}`,
        namespace: PRODUCTION_DEFAULT_TARGET_NAMESPACE,
        component: DefaultTargetPage,
        enableRead: isPermitted(
          [
            AdminProductionPermissionType.PRODUCTION_DEFAULT_TARGET_READ,
            AdminProductionPermissionType.PRODUCTION_DEFAULT_TARGET_CREATE,
            AdminProductionPermissionType.PRODUCTION_DEFAULT_TARGET_UPDATE,
          ],
          permissions,
        ),
        enableCreate: isPermitted([AdminProductionPermissionType.PRODUCTION_DEFAULT_TARGET_CREATE], permissions),
        enableUpdate: isPermitted([AdminProductionPermissionType.PRODUCTION_DEFAULT_TARGET_UPDATE], permissions),
      },
      {
        title: Translation(PRODUCTION_TARGET_TITLE),
        path: `${PRODUCTION_BASE_PATH}${PRODUCTION_TARGET_BASE_PATH}`,
        namespace: PRODUCTION_TARGET_NAMESPACE,
        component: SetTargetPage,
        enableRead: isPermitted(
          [
            AdminProductionPermissionType.PRODUCTION_TARGET_READ,
            AdminProductionPermissionType.PRODUCTION_TARGET_CREATE,
            AdminProductionPermissionType.PRODUCTION_TARGET_UPDATE,
          ],
          permissions,
        ),
        enableCreate: isPermitted([AdminProductionPermissionType.PRODUCTION_TARGET_CREATE], permissions),
        enableUpdate: isPermitted([AdminProductionPermissionType.PRODUCTION_TARGET_UPDATE], permissions),
      },
    ],
  };
};
