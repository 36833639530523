import { RolePermissionItem, PermissionItem } from '../types/permission-types';
import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import { Dispatch } from 'react';
import { forEach } from 'lodash';

export type CreateRolePermissionsBody = {
  groupId: string;
  groupEmail?: string;
  type: string;
  name: string;
  permissions: PermissionItem[];
};

export type UpdateRolePermissionsBody = {
  groupId: string;
  groupEmail?: string;
  type: string;
  name: string;
  permissions: PermissionItem[];
};

export type GetRolePermissionParam = {
  roleType?: string;
  roleName?: string;
  filter?: string;
};

export type DeleteRoleParam = {
  type: string;
  name: string;
};

export const getRolePermissions = async (
  params: GetRolePermissionParam,
  dispatch?: Dispatch<any>,
): Promise<RolePermissionItem[]> => {
  console.log('GetRolePermissionParam', params);

  let queryPath = `${ADMIN_URL}/role-permission?`;
  forEach(params, (param, key) => {
    queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
  });
  return apiClient
    .get<RolePermissionItem[]>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createRolePermissions = async (
  body: CreateRolePermissionsBody,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  await apiClient.post<void>(`${ADMIN_URL}/role-permission/role`, body).catch((err) => {
    throw apiErrorHandler(err, dispatch);
  });
};

export const updateRolePermissions = async (
  body: UpdateRolePermissionsBody,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  await apiClient.put<void>(`${ADMIN_URL}/role-permission`, body).catch((err) => {
    throw apiErrorHandler(err, dispatch);
  });
};

export const deleteRole = async (params: DeleteRoleParam, dispatch?: Dispatch<any>): Promise<void> => {
  await apiClient.delete<void>(`${ADMIN_URL}/role-permission/role`, { params }).catch((err) => {
    throw apiErrorHandler(err, dispatch);
  });
};
