import { RegionLocale } from 'src/app/i18n';

export const linkTitle: Record<RegionLocale, string> = {
  [RegionLocale.ENGLISH]: 'Check-in Instruction for {eventName}',
  [RegionLocale.TRADITIONAL_CHINESE]: '{eventName} 登記指示',
  [RegionLocale.SIMPLIFIED_CHINESE]: 'Check-in Instruction for {eventName}',
  [RegionLocale.BAHASA]: 'Check-in Instruction for {eventName}',
  [RegionLocale.SPANISH]: 'Check-in Instruction for {eventName}',
  [RegionLocale.JAPANESE]: 'Check-in Instruction for {eventName}',
  [RegionLocale.GERMAN]: 'Check-in Instruction for {eventName}',
  [RegionLocale.FRENCH]: 'Check-in Instruction for {eventName}',
  [RegionLocale.VIETNAMESE]: 'Check-in Instruction for {eventName}',
  [RegionLocale.KHMER]: 'Check-in Instruction for {eventName}',
};

export const linkBody: Record<RegionLocale, string> = {
  [RegionLocale.ENGLISH]:
    'Dear {assistantName},\n\nThe {eventName} is scheduled at {eventDate} {eventStartTime} - {eventEndTime}.\nPlease kindly download the PRUForce App before the event date.\nAfter successfully downloading the App, you can access the check-in scanner via the link provided below.\n\niOS App link: {App center link}\nAndroid App link: {App center link}\n\nYour unique scanner link: {scannerDeepLink}\nPlease launch the scanner using your mobile device. iPad not supported currently.\nPlease do not distribute.',
  [RegionLocale.TRADITIONAL_CHINESE]:
    '尊敬的{assistantName}，\n\n{eventName}定於{eventDate} {eventStartTime} - {eventEndTime}舉行。\n請於活動日期之前，透過以下連結下載PRUForce應用程式。\n成功下載後，您可以透過以下提供的連結訪問活動簽到掃描應用。\n\niOS應用程式下載連結：{App center link}\nAndroid應用程式下載連結：{App center link}\n\n您的唯一活動簽到掃描應用程式連結：{scannerDeepLink}\n請使用您的手機啟動掃描器，目前不支援 iPad。\n請勿轉寄',
  [RegionLocale.SIMPLIFIED_CHINESE]:
    'Dear {assistantName},\n\nThe {eventName} is scheduled at {eventDate} {eventStartTime} - {eventEndTime}.\nPlease kindly download the PRUForce App before the event date.\nAfter successfully downloading the App, you can access the check-in scanner via the link provided below.\n\niOS App link: {App center link}\nAndroid App link: {App center link}\n\nYour unique scanner link: {scannerDeepLink}\nPlease launch the scanner using your mobile device. iPad not supported currently.\nPlease do not distribute.',
  [RegionLocale.BAHASA]:
    'Dear {assistantName},\n\nThe {eventName} is scheduled at {eventDate} {eventStartTime} - {eventEndTime}.\nPlease kindly download the PRUForce App before the event date.\nAfter successfully downloading the App, you can access the check-in scanner via the link provided below.\n\niOS App link: {App center link}\nAndroid App link: {App center link}\n\nYour unique scanner link: {scannerDeepLink}\nPlease launch the scanner using your mobile device. iPad not supported currently.\nPlease do not distribute.',
  [RegionLocale.SPANISH]:
    'Dear {assistantName},\n\nThe {eventName} is scheduled at {eventDate} {eventStartTime} - {eventEndTime}.\nPlease kindly download the PRUForce App before the event date.\nAfter successfully downloading the App, you can access the check-in scanner via the link provided below.\n\niOS App link: {App center link}\nAndroid App link: {App center link}\n\nYour unique scanner link: {scannerDeepLink}\nPlease launch the scanner using your mobile device. iPad not supported currently.\nPlease do not distribute.',
  [RegionLocale.JAPANESE]:
    'Dear {assistantName},\n\nThe {eventName} is scheduled at {eventDate} {eventStartTime} - {eventEndTime}.\nPlease kindly download the PRUForce App before the event date.\nAfter successfully downloading the App, you can access the check-in scanner via the link provided below.\n\niOS App link: {App center link}\nAndroid App link: {App center link}\n\nYour unique scanner link: {scannerDeepLink}\nPlease launch the scanner using your mobile device. iPad not supported currently.\nPlease do not distribute.',
  [RegionLocale.GERMAN]:
    'Dear {assistantName},\n\nThe {eventName} is scheduled at {eventDate} {eventStartTime} - {eventEndTime}.\nPlease kindly download the PRUForce App before the event date.\nAfter successfully downloading the App, you can access the check-in scanner via the link provided below.\n\niOS App link: {App center link}\nAndroid App link: {App center link}\n\nYour unique scanner link: {scannerDeepLink}\nPlease launch the scanner using your mobile device. iPad not supported currently.\nPlease do not distribute.',
  [RegionLocale.FRENCH]:
    'Dear {assistantName},\n\nThe {eventName} is scheduled at {eventDate} {eventStartTime} - {eventEndTime}.\nPlease kindly download the PRUForce App before the event date.\nAfter successfully downloading the App, you can access the check-in scanner via the link provided below.\n\niOS App link: {App center link}\nAndroid App link: {App center link}\n\nYour unique scanner link: {scannerDeepLink}\nPlease launch the scanner using your mobile device. iPad not supported currently.\nPlease do not distribute.',
  [RegionLocale.VIETNAMESE]:
    'Dear {assistantName},\n\nThe {eventName} is scheduled at {eventDate} {eventStartTime} - {eventEndTime}.\nPlease kindly download the PRUForce App before the event date.\nAfter successfully downloading the App, you can access the check-in scanner via the link provided below.\n\niOS App link: {App center link}\nAndroid App link: {App center link}\n\nYour unique scanner link: {scannerDeepLink}\nPlease launch the scanner using your mobile device. iPad not supported currently.\nPlease do not distribute.',
  [RegionLocale.KHMER]:
    'Dear {assistantName},\n\nThe {eventName} is scheduled at {eventDate} {eventStartTime} - {eventEndTime}.\nPlease kindly download the PRUForce App before the event date.\nAfter successfully downloading the App, you can access the check-in scanner via the link provided below.\n\niOS App link: {App center link}\nAndroid App link: {App center link}\n\nYour unique scanner link: {scannerDeepLink}\nPlease launch the scanner using your mobile device. iPad not supported currently.\nPlease do not distribute.',
};
