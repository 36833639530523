export const initJson = {
  pages: [
    {
      name: 'page1',
      elements: [
        {
          type: 'text',
          name: 'firstName',
          title: { default: 'First Name', 'zh-tw': '姓' },
          placeholder: {
            default: 'Please input',
            'zh-tw': '請輸入',
          },
          requiredErrorText: {
            default: 'Field is required',
            'zh-tw': '欄位為必填項',
          },
          validators: [
            {
              type: 'expression',
            },
          ],
          hideNumber: true,
          isRequired: true,
        },
        {
          type: 'panel',
          name: 'regionPhone',
          title: { default: 'Phone Number', 'zh-tw': '電話號碼' },
          hideNumber: true,
          isRequired: true,
          elements: [
            {
              type: 'dropdown',
              name: 'countryCode',
              title: { default: 'Region', 'zh-tw': '地區' },
              titleLocation: 'hidden',
              isRequired: true,
              fieldType: 'Standard',
              width: '40%',
              minWidth: '40%',
              maxWidth: '40%',
              startWithNewLine: false,
              allowClear: false,
              requiredErrorText: {
                default: 'Field is required',
                'zh-tw': '欄位為必填項',
              },
              placeholder: {
                default: 'Please select',
                'zh-tw': '請選擇',
              },
              validators: [
                {
                  type: 'expression',
                  text: {
                    default: 'Please enter a valid phone number',
                    'zh-tw': '無效的電話號碼',
                  },
                  expression: 'phoneNumberValidate({countryCode}, {phoneNumber})',
                },
              ],
            },
            {
              type: 'text',
              name: 'phoneNumber',
              title: { default: 'Phone', 'zh-tw': '電話號碼' },
              titleLocation: 'hidden',
              isRequired: true,
              fieldType: 'Standard',
              width: '60%',
              minWidth: '60%',
              maxWidth: '60%',
              startWithNewLine: false,
              requiredErrorText: {
                default: 'Field is required',
                'zh-tw': '欄位為必填項',
              },
              placeholder: {
                default: 'Please input',
                'zh-tw': '請輸入',
              },
            },
          ],
        },
        {
          type: 'text',
          name: 'lastName',
          title: { default: 'Last Name', 'zh-tw': '名' },
          placeholder: {
            default: 'Please input',
            'zh-tw': '請輸入',
          },
          validators: [
            {
              type: 'expression',
            },
          ],
          hideNumber: true,
          isRequired: false,
          visible: false,
        },
        {
          type: 'text',
          name: 'dateOfBirth',
          hideNumber: true,
          title: { default: 'Date of Birth', 'zh-tw': '出生日期' },
          inputType: 'date',
          validators: [
            {
              type: 'expression',
              text: {
                default: 'Please enter a Date of Birth that is within the allowed range.',
                'zh-tw': '無效的出生日期',
              },
              expression: 'dateOfBirthValidate({dateOfBirth})',
            },
          ],
          visible: false,
        },
        {
          type: 'dropdown',
          name: 'city',
          hideNumber: true,
          title: { default: 'City', 'zh-tw': '城市' },
          placeholder: {
            default: 'Please select',
            'zh-tw': '請選擇',
          },
          choices: [],
          validators: [
            {
              type: 'expression',
            },
          ],
          visible: false,
        },
        {
          type: 'text',
          name: 'employmentCompanyName',
          hideNumber: true,
          title: { default: 'Employment Company Name', 'zh-tw': '受雇公司名稱' },
          validators: [
            {
              type: 'expression',
            },
          ],
          placeholder: {
            default: 'Please input',
            'zh-tw': '請輸入',
          },
          visible: false,
        },
        {
          type: 'boolean',
          name: 'isCustomer',
          hideNumber: true,
          title: {
            default: 'Are you a Prudential Customer?',
            'zh-tw': '你是否為保誠用户',
          },
          labelTrue: {
            default: 'Yes',
            'zh-tw': '是',
          },
          labelFalse: {
            default: 'No',
            'zh-tw': '否',
          },
          validators: [
            {
              type: 'expression',
            },
          ],
          visible: false,
        },
        {
          type: 'text',
          name: 'email',
          title: { default: 'Email', 'zh-tw': '電郵地址' },
          placeholder: {
            default: 'E.g. example@domain.com',
            'zh-tw': 'E.g. example@domain.com',
          },
          validators: [
            {
              type: 'expression',
              text: {
                default: 'Please enter a valid email address',
                'zh-tw': '無效的電郵地址',
              },
              expression: 'emailValidate({email})',
            },
          ],
          hideNumber: true,
          visible: false,
        },
        {
          type: 'text',
          name: 'wechatId',
          title: { default: 'Wechat ID', 'zh-tw': '微信ID' },
          placeholder: {
            default: 'Please input',
            'zh-tw': '請輸入',
          },
          hideNumber: true,
          validators: [
            {
              type: 'expression',
            },
          ],
          visible: false,
        },
        {
          type: 'dropdown',
          name: 'preferredLanguage',
          hideNumber: true,
          title: { default: 'Preferred Language', 'zh-tw': '首選語言' },
          placeholder: {
            default: 'Please select',
            'zh-tw': '請選擇',
          },
          validators: [
            {
              type: 'expression',
            },
          ],
          choices: [
            {
              value: 'english',
              text: {
                'zh-tw': '英文',
                default: 'English',
              },
            },
            {
              value: 'mandarin',
              text: {
                'zh-tw': '簡體中文',
                default: 'Chinese',
              },
            },
            {
              value: 'cantonese',
              text: {
                'zh-tw': '繁體中文',
                default: 'Traditional Chinese',
              },
            },
          ],
          visible: false,
        },
        {
          type: 'dropdown',
          name: 'preferredContactTime',
          hideNumber: true,
          title: { default: 'Preferred Contact Time', 'zh-tw': '首選聯系時間' },
          placeholder: {
            default: 'Please select',
            'zh-tw': '請選擇',
          },
          validators: [
            {
              type: 'expression',
            },
          ],
          choices: [
            {
              value: 'Anytime',
              text: {
                'zh-tw': '任意時間',
                default: 'Anytime',
              },
            },
            {
              value: '09:00 - 12:00',
              text: {
                'zh-tw': '09:00 - 12:00',
                default: '09:00 - 12:00',
              },
            },
            {
              value: '12:00 - 14:00',
              text: {
                'zh-tw': '12:00 - 14:00',
                default: '12:00 - 14:00',
              },
            },
            {
              value: '14:00 - 18:00',
              text: {
                'zh-tw': '14:00 - 18:00',
                default: '14:00 - 18:00',
              },
            },
            {
              value: '18:00 - 22:00',
              text: {
                'zh-tw': '18:00 - 22:00',
                default: '18:00 - 22:00',
              },
            },
          ],
          visible: false,
        },
        {
          type: 'dropdown',
          name: 'financialNeeds',
          hideNumber: true,
          title: { default: 'Financial Needs', 'zh-tw': '財務需求' },
          placeholder: {
            default: 'Please select',
            'zh-tw': '請選擇',
          },
          validators: [
            {
              type: 'expression',
            },
          ],
          choices: [
            {
              value: 'Health Insurance (e.g. critical illness, medical insurance, etc)',
              text: 'Health Insurance (e.g. critical illness, medical insurance, etc)',
            },
            {
              value: 'Life & Savings Insurance',
              text: 'Life & Savings Insurance',
            },
            {
              value: 'Retirement Insurance',
              text: 'Retirement Insurance',
            },
            {
              value: 'MPF',
              text: 'MPF',
            },
            {
              value: 'Accident & Disability Insurance',
              text: 'Accident & Disability Insurance',
            },
            {
              value: 'Investment-linked Insurance',
              text: 'Investment-linked Insurance',
            },
            {
              value: 'Term Insurance',
              text: 'Term Insurance',
            },
          ],
          visible: false,
        },
      ],
    },
  ],
  showQuestionNumbers: 'off',
};
