import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import PruRoute from 'src/app/common/components/PruRoute';
import { AGENCY_CAMPAIGN_BASE_PATH, AGENCY_CAMPAIGN_APPROVAL_PATH } from '../../constants';
import ParamsProvider from 'src/app/common/components/ParamsProvider';
import ApprovalListingPage, { approvalFilterKeys } from './List/ApprovalListingPage';
import ApprovalDetailPage from './Form/ApprovalDetailPage';

export const approvalPath = `${AGENCY_CAMPAIGN_BASE_PATH}${AGENCY_CAMPAIGN_APPROVAL_PATH}`;

const ApprovalRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect exact from={approvalPath} to={`${approvalPath}/list`} />
      <PruRoute exact path={`${approvalPath}/list`} render={(props) => (
        <ParamsProvider {...props} acceptKeys={approvalFilterKeys}>
          <ApprovalListingPage />
        </ParamsProvider>
      )}/>
      <PruRoute exact path={`${approvalPath}/view/:id`} component={ApprovalDetailPage}/>
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  )
};

export default ApprovalRoutes;