import React, { useState, PropsWithChildren } from 'react';
import { FilterDropdownParams } from './index';
import { PruTableHeader } from '../Table/PruTableHeader';
import { makeStyles } from 'tss-react/mui';
import { TableSortLabel, Popover } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
// import Dialog from '@mui/material/Dialog';

interface ProTableCellProps {
  order?: 'asc' | 'desc';
  sorter?: boolean;
  handleSort?: (sorter: { [key: string]: string }) => void;
  dataIndex?: string;
  style?: React.CSSProperties;
  padding?: any;
  align?: 'inherit' | 'left' | 'right' | 'center' | 'justify';
  filterDropdown?: (params: FilterDropdownParams) => React.ReactNode;
}

const useStyles = makeStyles()((theme) => ({
  TabelCellLabel: {
    color: `${theme.palette.common.white} !important`,
  },
  icon: {
    color: `${theme.palette.common.white} !important`,
    opacity: 1,
  },
}));

const AdvanceSort: React.FC<{ title: React.ReactNode; children: any }> = ({ title, children }) => {
  const [open, openDialog] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<any>(null);

  const handleClick = (event: React.MouseEvent<any>) => {
    setAnchorEl(event.currentTarget);
    openDialog(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    openDialog(false);
  };

  return (
    <>
      {title}
      <SearchIcon onClick={handleClick} />
      {/* <Dialog open={open} onClose={() => openDialog(false)}>{ children }</Dialog> */}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {children}
      </Popover>
    </>
  );
};

const ProTableCell: React.FC<PropsWithChildren<ProTableCellProps>> = ({
  sorter,
  handleSort = () => {},
  dataIndex,
  children,
  padding,
  align,
  style,
  filterDropdown,
  order = 'asc',
}) => {
  const { classes } = useStyles();
  const [direction, setDirection] = useState<'asc' | 'desc'>(order);

  const onSorter = (e: any) => {
    const newDirection = direction === 'asc' ? 'desc' : 'asc';
    setDirection(newDirection);
    const sorter = {
      [dataIndex!]: newDirection,
    };
    handleSort(sorter);
  };

  return (
    <PruTableHeader
      component="th"
      scope="row"
      sortDirection={sorter ? direction : false}
      // sortDirection={'asc'}
      padding={padding}
      align={align}
      style={style}
    >
      {sorter ? (
        <TableSortLabel
          active={sorter}
          className={classes.TabelCellLabel}
          // active={true}
          // direction={'asc'}
          direction={direction || 'asc'}
          onClick={onSorter}
          classes={{
            icon: classes.icon,
          }}
        >
          {children}
        </TableSortLabel>
      ) : filterDropdown ? (
        <AdvanceSort title={children}>{filterDropdown({})}</AdvanceSort>
      ) : (
        children
      )}
    </PruTableHeader>
  );
};

export default ProTableCell;
