import { I18nGenericData } from 'src/app/i18n';

export enum LanguageType {
  EN = 'enUs',
  ZH_HK = 'zhHk',
}

export enum FormMode {
  CREATE = 'create',
  EDIT = 'edit',
  VIEW = 'view',
}

export type DesignationType = 'SRD' | 'RD' | 'SRM' | 'RM' | 'BM' | 'SUM' | 'UM' | 'SBSM' | 'BSM' | 'SFC' | 'FC' | 'LA';

export const DesignationValueMap = {
  SRD: 'G5',
  RD: 'G4',
  SRM: 'G3',
  RM: 'G2',
  BM: 'G1L3',
  SUM: 'G1L2',
  UM: 'G1L1',
  SBSM: 'SIC2',
  BSM: 'SIC1',
  SFC: 'SLU',
  FC: 'LU',
  LA: 'LA',
};

export type DesignationType_MO_BROKER = 'SEC';

export const DesignationValueMap_MO_BROKER = {
  SEC: 'SEC',
};

export type DesignationItem = {
  _id: string;
  code: string;
  departmentCode: string;
  level: number;
  description: I18nGenericData<string>;
  shortName: string;
  effectiveDate: Date;
};

export type ZoneItem = {
  _id: string;
  code: string;
  description: I18nGenericData<string>;
  status: string;
};

export type PaginateList<T extends object> = {
  total: number;
  totalPages: number;
  limit: number;
  items: T[];
  data?: T[];
  docs: T[];
  hasNextPage: boolean;
  hasPrevPage: boolean;
  totalDocs: number;
};

export type AttachmentDef = {
  blobId: string;
  filename: string;
  url?: string;
  type?: string;
};

export interface PaginateResult<T> {
  docs: T[];
  totalDocs: number;
  limit: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  page?: number | undefined;
  totalPages: number;
  offset?: number;
  prevPage?: number | null | undefined;
  nextPage?: number | null | undefined;
  pagingCounter: number;
  meta?: any;
  [customLabel: string]: T[] | number | boolean | null | undefined;
}
