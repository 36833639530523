import React from 'react';

import { useIntl } from 'react-intl';
import { TextField } from '@mui/material';

import { InsuranceSummaryStyles } from '../style/insuranceSummaryStyle';
import {
  CompleteMessage,
  InsuranceSummaryState,
} from 'src/app/modules/sales-kit/pages/insurance-summary/type/insurance-summary-types';
type completeMessageKey = keyof CompleteMessage;
type CompleteMessageModuleProps = {
  title: string;
  theme: string;
  fun: (item: any) => void;
  subFun: (item: string) => void;
  formState: InsuranceSummaryState;
};
const displayField = {
  title: 'Title',
  content: 'Content',
} as const;
type values = (typeof displayField)[keyof typeof displayField];
const CompleteMessageModule = (props: CompleteMessageModuleProps) => {
  const { fun, formState, title } = props;

  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { classes } = InsuranceSummaryStyles();

  const renderList: React.ReactNode = Object.values(displayField).map(function (item, index) {
    return (
      <div key={`${item}+${index}`}>
        <FormField fun={fun} title={item} value={item.toLowerCase() as completeMessageKey} formState={formState} />
      </div>
    );
  });
  return (
    <>
      <div style={{ marginBottom: 20 }}>
        <div style={{ paddingTop: 12 }} className={classes.fieldContainer}>
          <span className={classes.title}>{Translation(title)}</span>
        </div>
        <div>{renderList}</div>
      </div>
    </>
  );
};

const FormField = ({
  fun,
  title,
  formState,
  value,
}: {
  fun: (item: any) => void;
  title: values;
  formState: InsuranceSummaryState;
  value: completeMessageKey;
}) => {
  const { classes } = InsuranceSummaryStyles();
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 30 }}>
        <div style={{ marginBottom: 10 }} className={classes.rowContainer}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'flex-end',
            }}
            className={classes.fieldContainer}
          >
            <div style={{ display: 'flex', flexDirection: 'column', width: 80 }}>
              <span className={classes.field}>{title}</span>
              <span className={classes.field}> (Local)</span>
            </div>
          </div>
          <div className={classes.textfieldContainer}>
            <TextField
              variant="outlined"
              multiline
              fullWidth
              value={formState.completeMessage[value]['local'] || ''}
              onChange={(e) => {
                formState.completeMessage[value]['local'] = e.target.value;
                fun(formState.completeMessage);
              }}
            />
          </div>
        </div>
        <div style={{ marginBottom: 10 }} className={classes.rowContainer}>
          <div
            style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'flex-end' }}
            className={classes.fieldContainer}
          >
            <div style={{ display: 'flex', flexDirection: 'column', width: 80 }}>
              <span className={classes.field}>{title}</span>
              <span className={classes.field}> (English)</span>
            </div>
          </div>
          <div className={classes.textfieldContainer}>
            <TextField
              variant="outlined"
              multiline
              fullWidth
              value={formState.completeMessage[value]['enUs'] || ''}
              onChange={(e) => {
                formState.completeMessage[value]['enUs'] = e.target.value;
                fun(formState.completeMessage);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CompleteMessageModule;
