import React, { CSSProperties } from 'react';
import { makeStyles } from 'tss-react/mui';
import { TextField, TextFieldProps } from '@mui/material';

type TextFieldWithLimitProps = {
  placeholder?: string;
  helperText?: string;
  limitHelpText?: string;
  disabled?: boolean;
  value?: string;
  onChange?: (value: React.ChangeEvent<any>) => void;
  limit: number;
} & Omit<TextFieldProps, 'placeholder' | 'onChange' | 'helperText' | 'disabled' | 'value'>;

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  textArea: {
    lineHeight: 1.5,
    minHeight: 80,
  },
  warning: {
    color: 'red',
    textAlign: 'right',
  },
  normal: {
    color: '#858585',
    textAlign: 'left',
  },
  helpTextWarn: {
    color: 'red',
    textAlign: 'left',
  },
}));

const TextFieldWithLimit = (props: TextFieldWithLimitProps) => {
  const { classes } = useStyles();
  const { placeholder, disabled, value, onChange, limit, helperText, style, error, limitHelpText } = props;

  const handleChange = (event: React.ChangeEvent<any>) => {
    // let newValue = event.target.value
    if (!onChange) return;
    // if (newValue.length > limit) {
    //   newValue = newValue.slice(0, limit)
    // }
    // event.target.value = newValue
    onChange(event);
  };

  const display = limitHelpText ? (
    <>
      {!disabled && value && value.length > limit - 5 ? (
        <span className={classes.helpTextWarn}>
          {limit - value.length}
          {limitHelpText}
        </span>
      ) : !disabled && value ? (
        <>
          <span className={classes.normal}>
            {limit - value.length}
            {limitHelpText}
          </span>
        </>
      ) : null}
    </>
  ) : (
    <>
      {!disabled && value && value.length > limit - 5 ? (
        <span className={classes.warning}>
          {value.length}/{limit}
        </span>
      ) : null}
    </>
  );
  return (
    <div className={classes.container}>
      <TextField
        onChange={handleChange}
        style={style}
        disabled={disabled}
        margin="dense"
        variant="outlined"
        fullWidth
        multiline
        error={error}
        helperText={helperText}
        InputProps={{
          classes: {
            input: classes.textArea,
          },
          // maxlength: '66'
          inputProps: { maxLength: limit },
        }}
        placeholder={placeholder}
        value={value}
      />

      {/* {!disabled && value && value.length > limit - 5 ? (
        <span className={classes.warning}>
          {value.length}/{limit}
        </span>
      ) : (
        display
      )} */}
      {display}
    </div>
  );
};

export default TextFieldWithLimit;
