import React from 'react';
import { regionLocale } from 'src/app/i18n';

const getNameFromI18nData = (code: string, row: any) => {
  const data = row.find((item: any) => {
    return item.lang === code;
  });
  // eslint-disable-next-line
  return (data && data.name) || '';
};

const HighlineKeyWord = (str: string, keyword: string) => {
  var substr = '/(' + keyword + ')/g';
  var replaceStr = str.replace(eval(substr), "<span style='color:red;font-weight:bold'>" + keyword + '</span>');

  return <div>{replaceStr}</div>;
};

const columns = (Translation: any, keyword: string) => {
  const columns = [
    {
      title: Translation('mandatory.training.component.table.search.title'),
      dataIndex: 'courseName',
      hideInTable: true,
    },
    {
      title: Translation('mandatory.training.component.table.course.code'),
      dataIndex: 'courseCode',
      hideInSearch: true,
    },
    {
      title: Translation('mandatory.training.component.table.course.name'),
      dataIndex: 'courseDesc',
      key: 'courseDesc',
      hideInSearch: true,
      render: (str: any) => {
        const parts = str.split(new RegExp(`(${keyword})`, 'gi'));
        return (
          <span>
            {parts.map((part: any, i: any) => {
              return (
                <span
                  key={i}
                  style={part.toLowerCase() === keyword.toLowerCase() ? { color: 'red', fontWeight: 'bold' } : {}}
                >
                  {part}
                </span>
              );
            })}
          </span>
        );
      },
    },
  ];

  return [...columns];
};

export default columns;
