export const deepCopy = <T>(obj: T): T => {
  return JSON.parse(JSON.stringify(obj));
};

export const trimRoleName = (role: string): string =>
  role.lastIndexOf('-') !== -1 ? role.slice(role.lastIndexOf('-') + 1) : role;

import axios from 'axios'; // this is for fn downloadAs, DO NOT use apiClient here

/**
 * get file extending name by regex, return empty if not match.
 * e.g. a/b/c/xx.pdf will return '.pdf'
 * @param fileName
 * @returns
 */
export function getFileExtendingName(filename: string) {
  // regex for file extending name
  var reg = /\.[^\.]+$/;
  var matches = reg.exec(filename);
  if (matches) {
    return matches[0];
  }
  return '';
}

/**
 * download file by url, and rename file with provided name
 * @param url file url for download
 * @param name rename name
 */
export function downloadAs(url: string, name: string) {
  // do NOT use apiClient(axios-utils) here
  axios
    .get(url, {
      headers: { 'Content-Type': 'application/octet-stream' },
      responseType: 'blob',
    })
    .then((response) => {
      const a = document.createElement('a');
      const url = window.URL.createObjectURL(response.data);
      a.href = url;
      a.download = name;
      a.click();
    })
    .catch((err) => {
      console.log('download error: ', err);
    });
}

export const removeFirstLetterIfU = (string: string) => {
  if (!string || typeof string !== 'string' || string.length < 0) return;
  if (string.charAt(0).toUpperCase() === 'U') {
    return string.substring(1);
  } else {
    return string;
  }
};
