import {
  PruTableColumnDef,
  PruTableOperationDef,
  PruTableButtonDef,
} from 'src/app/common/components/PruTable/PruTable';
import { ruleSettingListItem } from 'src/app/modules/PulseLeads/network/ruleConfigCrud';

export const columns = (Translation: any, getDefaultDisplayDate: any) => {
  const columns: PruTableColumnDef<ruleSettingListItem>[] = [
    {
      isId: true,
      hidden: true,
      keyIndex: '_id',
      displayName: '',
      renderData: () => '',
    },
    {
      keyIndex: 'name',
      displayName: Translation('pulseleads.activityPoint.ruleSettings.common.name'),
      renderData: (row: { name: any }) => row.name || '-',
    },
    {
      keyIndex: 'status',
      align: 'center',
      displayName: Translation('pulseleads.activityPoint.ruleSettings.common.status'),
      renderData: (row: { isActive: any }) => (row.isActive ? 'Active' : 'Inactive' || '-'),
    },
    {
      keyIndex: 'lastUpdatedTime',
      align: 'center',
      displayName: Translation('pulseleads.activityPoint.ruleSettings.common.lastUpdatedTime'),
      renderData: (row: { updatedAt: string | number | Date }) =>
        getDefaultDisplayDate(new Date(row.updatedAt), 'datetime'),
    },
    {
      keyIndex: 'lastUpdatedBy',
      align: 'center',
      displayName: Translation('pulseleads.activityPoint.ruleSettings.common.lastUpdatedBy'),
      renderData: (row: { updatedBy: any }) => row.updatedBy || '-',
    },
  ];
  return columns;
};

export const operation = (Translation: any, setDialogState: any, history: any) => {
  const columns: PruTableOperationDef<ruleSettingListItem>[] = [
    {
      title: Translation('section.common.operation.edit'),
      tooltipText: 'Edit Activity Point Rule Settings',
      onClick: (row: { _id: any }) => history.push(`/pulseleads/rule/activity-point/rule-settings/edit/${row._id}`),
    },
    {
      title: Translation('section.common.operation.delete'),
      tooltipText: 'Delete Activity Point Settings',
      onClick: (row: { _id: any }) => setDialogState({ open: true, id: row._id }),
      condition: (row: { isDefault: any }) => !row.isDefault,
    },
  ];
  return columns;
};

export const headerBtn = (Translation: any, history: any) => {
  const columns: PruTableButtonDef[] = [
    {
      color: 'primary',
      title: Translation('app.button.add'),
      onClick: () => {
        history.push(`/pulseleads/rule/activity-point/rule-settings/create`);
      },
    },
  ];
  return columns;
};
