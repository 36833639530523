import { makeStyles } from 'tss-react/mui';

export const styles = makeStyles()((theme) => ({
    container: {
        padding: 20,
        marginTop: 20,
        marginBottom: 20,
        borderRadius: 5,
        backgroundColor: theme.palette.common.white,
        paddingBottom: 0
    },
    divideMargin: {
        marginBottom: 10,
    },
    mandatory: {
        color: 'red'
    },
}));