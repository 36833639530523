import React, { FC, useState, useEffect } from 'react';
import { Button, Dialog } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useIntl } from 'react-intl';
import ProTable from 'src/app/common/components/ProTable';
import { columns } from './columns';
import { getCustomerSurveyList } from './../../api';
import { fetchLibraryList, fetchLibraryItem } from 'src/app/modules/sales-kit/components/Library/network/libraryCrud';

import { useLang } from 'src/app/i18n';
import { useDispatch } from 'react-redux';
import { ModuleTypeTranslate } from '../../utils';

const useStyles = makeStyles()((theme) => ({
  modalButtons: {
    marginTop: '15px',
    marginBottom: 15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialog: {
    padding: '15px',
  },
  dialogBox: {
    with: '12000px',
  },
  noCourseError: {
    textAlign: 'center',
    color: 'red',
  },
}));

const SearchMaterialsBox: FC<any> = (props) => {
  const locale = useLang();
  const dispatch = useDispatch();
  const { onSelectData, popUpVisible, setPopUpVisible, moduleType } = props;

  const filterInitData = {
    // page: 1,
    // pageSize: 20,
    name: '',
    module: moduleType,
  };

  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  // eslint-disable-next-line
  const [formLoading, setFormLoading] = useState<boolean>(false);
  // eslint-disable-next-line
  const [filterData, setFilterData] = useState<{ [key: string]: any }>(filterInitData);
  // eslint-disable-next-line
  const [pageData, setPageData] = useState<{ [key: string]: any }>({
    page: 0,
    pageSize: 5,
  });
  const [selectedRowsState, setSelectedRows] = useState<any>(null);
  const [displayNoCourseError, setDisplayNoCourseError] = useState<boolean>(false);

  const onCancel = () => {
    setPopUpVisible(false);
  };

  const formatEventData = async (newFilterState?: any) => {
    const newFilter = newFilterState || {};
    const requestParams = {
      ...filterData,
      ...newFilter,
      effectiveDate: new Date(),
    };

    const surveyReqeustParams = {
      ...requestParams,
      limit: newFilter.pageSize,
      page: newFilter.page,
      status: 'active',
      lastUpdateDateSort: 'descending',
      name: newFilter.title,
    };

    requestParams.page = requestParams.page;

    console.log('requestParams', requestParams);

    setFilterData(requestParams);

    const result =
      moduleType === 'CustomerSurvey'
        ? await getCustomerSurveyList(surveyReqeustParams, locale, dispatch)
        : await fetchLibraryList(locale, requestParams, [], dispatch);

    const res = {
      data: result?.libraryList || [],
      success: true,
      total: result?.total || 0,
    };

    return res;
  };

  const fetchData = (newFilterState?: any): Promise<any> => {
    setFormLoading(true);
    setDisplayNoCourseError(false);
    return formatEventData(newFilterState);
  };

  const onImport = () => {
    if (!selectedRowsState) {
      setDisplayNoCourseError(true);
    } else {
      onSelectData(selectedRowsState);
      setPopUpVisible(false);
      setSelectedRows(null);
      setDisplayNoCourseError(false);
    }
  };
  return (
    <div style={{ width: '100%', alignSelf: 'flex-start' }}>
      <Dialog open={popUpVisible} onClose={onCancel} scroll={'paper'} fullWidth maxWidth="lg">
        <>
          <div style={{ display: 'flex', marginTop: 20 }}>
            <Button onClick={onCancel} style={{ marginLeft: 20 }} variant="contained">
              {Translation('global.back.btnText')}
            </Button>
          </div>
          <div style={{ padding: 20, paddingTop: 0 }}>
            <ProTable
              rowKey="key"
              filterTitle={`${Translation('select.label')} ${Translation(
                `menu.resource.${ModuleTypeTranslate[moduleType]}`,
              )}`}
              headerTitle={''}
              columns={columns(Translation, filterData.courseName, ModuleTypeTranslate[moduleType].toLowerCase())}
              pageSize={20}
              request={(params: any) => {
                return fetchData({
                  ...params,
                  page: params.page + 1,
                });
              }}
              toolBarRender={() => []}
              rowSelection={{
                onChange: (_: any, selectedRows: any) => {
                  const selectCourse = selectedRows && selectedRows[0];

                  if (selectCourse) {
                    setSelectedRows(selectedRows[0]);
                    setDisplayNoCourseError(false);
                  }
                },
                getCheckboxProps: (record: any) => {
                  return {};
                },
                selectItems: [selectedRowsState],
              }}
              singleSelect={true}
            />
          </div>
          {displayNoCourseError && <div className={classes.noCourseError}>Please select a material</div>}
          <div className={classes.modalButtons}>
            <Button onClick={onCancel} style={{ marginLeft: 20 }} variant="contained">
              {Translation('app.button.cancel')}
            </Button>
            <Button onClick={onImport} style={{ marginLeft: 20 }} variant="contained" color="secondary">
              {Translation('select.label')}
            </Button>
          </div>
        </>
      </Dialog>
    </div>
  );
};

export default SearchMaterialsBox;
