import { useState, useMemo, useCallback } from 'react';
import { forEach, find } from 'lodash';

export enum ErrorFieldType {
  MANDATORY = 'mandatory',
  IMMEDIATE = 'immediate',
}

type FormState = Record<string, unknown>;

type ErrorState = {
  mandatory: Record<string, boolean> | any;
  immediate: Record<string, boolean> | any;
};

export type ErrorFieldDef = {
  section?: string;
  name: string;
  fieldType: ErrorFieldType;
  condition?: (data?: any) => boolean;
};

type FieldCheckCondition = {
  mandatory: Pick<ErrorFieldDef, 'name' | 'condition' | 'section'>[];
  immediate: Pick<ErrorFieldDef, 'name' | 'condition' | 'section'>[];
};

const errorStateInitialiser = <T extends ErrorState>(errorFieldDef: ErrorFieldDef[]): T => {
  let errorState = {
    mandatory: {},
    immediate: {},
  } as T;
  errorFieldDef.forEach((field) => {
    if (field.section) {
      if (!errorState[field.fieldType][`${field.section}_${field.name}`]) {
        errorState[field.fieldType][`${field.section}_${field.name}`] = false;
      }
    } else {
      if (!errorState[field.fieldType][field.name]) {
        errorState[field.fieldType][field.name] = false;
      }
    }
  });
  return errorState;
};

type ErrorHandler<T extends ErrorState> = {
  errorState: T;
  onSubmitErrorValidator: (props?: any) => { hasError: boolean; currentErrorState: T; formState: any };
  onDismissErrorHandler: (field: keyof T['mandatory'], value: any) => void;
  immediateErrorValidator: () => void;
};

export const useErrorHandler = <T extends ErrorState>(
  formState: FormState,
  errorFieldDef: ErrorFieldDef[],
): ErrorHandler<T> => {
  const [errorState, setErrorState] = useState<T>(errorStateInitialiser<T>(errorFieldDef));
  const [conditions, setConditions] = useState<FieldCheckCondition>({ mandatory: [], immediate: [] });
  useMemo(() => {
    setErrorState(errorStateInitialiser<T>(errorFieldDef));
    const conditions: FieldCheckCondition = {
      mandatory: [],
      immediate: [],
    };
    errorFieldDef.forEach((item) => {
      if (item.fieldType === ErrorFieldType.MANDATORY && item.condition) {
        conditions.mandatory.push({
          name: item.name,
          condition: item.condition,
          ...(item.section && { section: item.section }),
        });
      }
      if (item.fieldType === ErrorFieldType.IMMEDIATE && item.condition) {
        conditions.immediate.push({
          name: item.name,
          condition: item.condition,
          ...(item.section && { section: item.section }),
        });
      }
    });
    setConditions(conditions);
  }, [errorFieldDef]);

  const onSubmitErrorValidator = ({ exclude, include }: any = {}) => {
    let mandatory: ErrorState['mandatory'] = {};
    conditions.mandatory.forEach((item) => {
      if (item.condition !== undefined) {
        if (item.section) {
          mandatory[`${item.section}_${item.name}`] = item.condition(formState);
        } else {
          mandatory[item.name] = item.condition(formState);
        }
      } else {
        if (item.section) {
          mandatory[`${item.section}_${item.name}`] = false;
        } else {
          mandatory[item.name] = false;
        }
      }
    });

    forEach(errorState.mandatory, (_, key) => {
      if (key.indexOf('_') > -1) {
        const section = key.split('_')[0];
        const field = key.split('_')[1];
        if (
          mandatory[`${section}_${field}`] === undefined &&
          exclude?.[section] !== true &&
          !exclude?.[section]?.[field] &&
          (!include || include?.[section] === true || include?.[section]?.[field])
        ) {
          if (!(formState as any)[section]) {
            mandatory[`${section}_${field}`] = true;
          } else {
            // handle empty array and empty string
            if (Array.isArray((formState as any)[section][field])) {
              mandatory[`${section}_${field}`] = (formState as any)[section][field].length === 0; // handle empty array
            } else {
              mandatory[`${section}_${field}`] = !!!(formState as any)[section][field]; // handle empty string
            }
          }
        }
      } else {
        if (mandatory[key] === undefined) {
          mandatory[key] = !!!formState[key];
        }
      }
    });
    setErrorState({
      ...errorState,
      mandatory,
    });
    return {
      hasError: !(
        find(mandatory, (status) => status === true) === undefined &&
        find(errorState.immediate, (status) => status === true) === undefined
      ),
      currentErrorState: {
        ...errorState,
        mandatory,
      },
      formState,
    };
  };

  const onDismissErrorHandler = useCallback(
    (field: keyof T['mandatory'], value: any) => {
      // PCAAEB-14981: only check mandatory fields
      if (value && errorState.mandatory[field]) {
        setErrorState({
          ...errorState,
          mandatory: {
            ...errorState.mandatory,
            [field]: false,
          },
        });
      }
    },
    [errorState],
  );

  const immediateErrorValidator = () => {
    let immediateErrorState: ErrorState['immediate'] = {};
    forEach(errorState.immediate, (_, key) => {
      immediateErrorState[key] = false;
    });
    conditions.immediate.forEach((item) => {
      if (item.condition !== undefined) {
        if (item.section) {
          immediateErrorState[`${item.section}_${item.name}`] = item.condition(formState);
        } else {
          immediateErrorState[item.name] = item.condition(formState);
        }
      } else {
        if (item.section) {
          immediateErrorState[`${item.section}_${item.name}`] = false;
        } else {
          immediateErrorState[item.name] = false;
        }
      }
    });
    setErrorState({
      ...errorState,
      immediate: immediateErrorState,
    });
  };

  return {
    errorState,
    onSubmitErrorValidator,
    onDismissErrorHandler,
    immediateErrorValidator,
  };
};
