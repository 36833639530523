import React, { FC, useState, useEffect, useMemo } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchSurveyItem } from '../../../network/surveyCrud';
import { NOT_FOUND_ERROR_PAGE_PATH } from '../../../../../common/constants';
import { SurveyDetail, SurveyFormMode } from '../../../types/survey-types';
import { LayoutSplashScreen } from 'src/app/layout';
import SurveyDetailForm from './components/SurveyDetailForm';

const SurveyDetailPage: FC<RouteComponentProps> = ({ history, location }) => {
  const { id } = useParams<{ id: string }>();
  const { formMode, code } = useMemo(
    () => ({
      formMode: id ? SurveyFormMode.EDIT : SurveyFormMode.CREATE,
      code: id ? id : undefined,
    }),
    [id],
  );

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [surveyDetail, setSurveyDetail] = useState<SurveyDetail>();

  const reloadData = () => {
    if (code) {
      setIsLoading(true);
      fetchSurveyItem(code, dispatch)
        .then((result) => {
          setSurveyDetail(result);
        })
        .catch(() => {
          history.push(NOT_FOUND_ERROR_PAGE_PATH);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line
  }, [code]);

  return (
    <>
      {formMode === SurveyFormMode.CREATE && <SurveyDetailForm formMode={formMode} />}
      {formMode === SurveyFormMode.EDIT &&
        (isLoading || !surveyDetail ? (
          <LayoutSplashScreen />
        ) : (
          <SurveyDetailForm formMode={formMode} surveyDetail={surveyDetail} />
        ))}
    </>
  );
};

export default SurveyDetailPage;
