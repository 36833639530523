import React, { FC, useState, useEffect, useMemo } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { LayoutSplashScreen } from 'src/app/layout';
import { ApiErrorResponse } from 'src/app/common/network';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { Button } from '@mui/material';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { fetchApplicationFormEditHistoryDetail } from '../../network/application-form-crud';
import { utcToLocalDate, uppercaseToTitlecase } from '../../utils';

const EditHistoryDetailPage: FC<RouteComponentProps> = ({ history, location }) => {
  const { detailId } = useParams<{ detailId: string }>();
  const dispatch = useDispatch();
  const { classes: commonClasses } = useCommonStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [editHistoryDetail, setEditHistoryDetail] = useState<any>({});

  const reloadData = () => {
    if (detailId) {
      setIsLoading(true);
      fetchApplicationFormEditHistoryDetail(detailId)
        .then((result) => {
          setEditHistoryDetail(result);
        })
        .catch((err: ApiErrorResponse) => {
          history.push(NOT_FOUND_ERROR_PAGE_PATH);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleRenderContent = (field: any) => {
    const fieldType = typeof field.originalValue;
    const changeType = (
      <div className="tw-flex tw-w-full tw-flex-col">
        <span className="tw-text-base tw-font-bold tw-p-2">
          Change Type:<span className="tw-text-gray-400 tw-pl-4">{field.changeType}</span>
        </span>
      </div>
    );
    const originalContent: any[] = [];
    const updateContent: any[] = [];
    switch (fieldType) {
      case 'string':
      case 'boolean':
        originalContent.push(
          <span className="tw-text-base tw-font-bold tw-p-1">
            {field.fieldName}
            <span className="tw-text-gray-400 tw-pl-4">{String(field.originalValue)}</span>
          </span>,
        );
        updateContent.push(
          <span className="tw-text-base tw-font-bold tw-p-1">
            {field.fieldName}
            <span className="tw-text-gray-400 tw-pl-4">{String(field.updatedValue)}</span>
          </span>,
        );
        break;
      case 'object':
        field.originalValue.forEach((option: any, index: number) => {
          originalContent.push(
            <span className="tw-text-base tw-font-bold tw-p-1">
              {Translation('recruitment.applicationForm.editHistory.detail.option')} {index + 1} (
              {Translation('recruitment.applicationForm.editHistory.detail.english')}):
              <span className="tw-text-gray-400 tw-pl-4">{option}</span>
            </span>,
          );
        });
        field.updatedValue.forEach((option: any, index: number) => {
          updateContent.push(
            <span className="tw-text-base tw-font-bold tw-p-1">
              {Translation('recruitment.applicationForm.editHistory.detail.option')} {index + 1} (
              {Translation('recruitment.applicationForm.editHistory.detail.english')}):
              <span className="tw-text-gray-400 tw-pl-4">{option}</span>
            </span>,
          );
        });
        break;
      default:
        break;
    }
    return (
      <>
        {changeType}
        <div className="tw-flex tw-w-full tw-flex-col tw-pl-4">
          <span className="tw-text-base tw-font-bold tw-p-1 tw-">
            {Translation('recruitment.applicationForm.editHistory.detail.originalContent')}
          </span>
          {originalContent.map((item: any) => {
            return item;
          })}
          <span className="tw-text-base tw-font-bold tw-p-1">
            {Translation('recruitment.applicationForm.editHistory.detail.updatedContent')}
          </span>
          {updateContent.map((item: any) => {
            return item;
          })}
        </div>
      </>
    );
  };

  useEffect(() => {
    reloadData();
  }, [detailId]);

  return (
    <>
      {isLoading ? (
        <LayoutSplashScreen />
      ) : (
        <>
          <div className="tw-container tw-bg-white tw-p-5 tw-mb-5 tw-rounded-md">
            <div className="tw-flex tw-items-center tw-p-1">
              <div className="tw-w-full tw-flex">
                <div className={commonClasses.header}>
                  {Translation('recruitment.applicationForm.editHistory.detail.title')}
                </div>
              </div>
              <Button variant="contained" color="inherit" onClick={() => history.goBack()}>
                {Translation('app.button.back')}
              </Button>
            </div>
            <div className="tw-mb-4">
              <div className="tw-flex tw-w-full">
                <div className="tw-p-1 tw-min-w-full tw-box-border">
                  <span className="tw-text-base tw-font-bold">{Translation('recruitment.common.lastUpdatedAt')} :</span>
                  <span className="tw-text-base tw-text-gray-400 tw-pl-4 tw-font-bold">
                    {utcToLocalDate(editHistoryDetail?.lastUpdatedAt) || '-'}
                  </span>
                </div>
              </div>
              <div className="tw-flex tw-w-full">
                <div className="tw-p-1 tw-min-w-full tw-box-border">
                  <span className="tw-text-base tw-font-bold">{Translation('recruitment.common.lastUpdatedBy')} :</span>
                  <span className="tw-text-base tw-text-gray-400 tw-font-bold tw-pl-4">
                    {editHistoryDetail?.lastUpdatedBy || '-'}
                  </span>
                </div>
              </div>
            </div>
            {editHistoryDetail?.changeItems?.map((item: any, index: number) => {
              return (
                <>
                  <div className="tw-flex tw-w-full tw-flex-col">
                    <span className="tw-text-base tw-underline tw-font-bold tw-p-2">
                      {Translation('recruitment.applicationForm.editHistory.detail.change')} {index + 1}
                    </span>
                    <span className="tw-text-base tw-font-bold tw-p-1">
                      {`${uppercaseToTitlecase(item.level)}  ${Translation(
                        'recruitment.applicationForm.editHistory.detail.name',
                      )}:`}
                      <span className="tw-text-gray-400 tw-pl-4">{item.name}</span>
                    </span>
                  </div>
                  {item?.fieldChanges?.map((field: any) => {
                    return handleRenderContent(field);
                  })}
                </>
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

export default EditHistoryDetailPage;
