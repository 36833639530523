import { PaginateList } from '../../../common/types/common-types';
import { mainLang, subLang } from '../constants';

export enum BannerPublishedMode {
  ALL = 'ALL',
  PUBLISHED = 'Published',
  UNPUBLISHED = 'Unpublished',
}

export enum BannerValidMode {
  ALL = 'ALL',
  VALID = 'Valid',
  INVALID = 'Invalid',
}

export enum PopupPublishedMode {
  ALL = 'ALL',
  PUBLISHED = 'Published',
  UNPUBLISHED = 'Unpublished',
}

export enum PopupValidMode {
  ALL = 'ALL',
  VALID = 'Valid',
  INVALID = 'Invalid',
}

export enum RedirectType {
  no = 'no',
  app = 'app',
  external = 'external',
}

export enum OpenByType {
  'pruforce-webview' = 'webview',
  'device-default-browser' = 'browser',
}

export enum CanBeShared {
  'yes' = 'Y',
  'no' = 'N',
}

export enum LanguageType {
  'en' = 'en',
  'zh-Hant' = 'zh-Hant',
}

export type StrapiLang = 'en' | 'zh-Hant';

export enum localeToStrapiLanguage {
  'en' = 'en',
  'zh-Hant' = 'zh-Hant',
}

export enum StrapiLanguageToLocale {
  'en' = 'en',
  'zh-Hant' = 'zhHk',
}

export enum IsPublish {
  'save-publish' = 'Y',
  'save-as-draft' = 'N',
}

export type BannerImageItem = {
  blobId: string;
  type?: string;
};

export type BannerItem = {
  createdAt: Date;
  data: Object;
  effectiveDate: Date;
  expiredDate: Date;
  i18nData: PopupSharedInfoState | null;
  id: string;
  linkType: string;
  linkTo?: string;
  locale: string;
  localizations?: bannerLocaleItem[];
  location?: string;
  name: string;
  publishedAt: Date;
  updatedAt: Date;
  updated_by: Object;
  weight: string;
  openBy?: string;
  canShare?: boolean;
  lastPublishTime?: Date;
  linkParams?: Object;
  lastUpdatedBy?: string;
};

export type bannerLocaleItem = {
  id: string;
  locale: string;
  publishedAt: Date;
  _id: string;
};

export type BannerEditItem = {
  openBy?: string;
  canShare?: boolean;
  createdAt?: Date;
  created_by?: Date;
  data?: Object | null;
  effectiveDate?: string | null;
  expiredDate?: string | null;
  i18nData?: BannerSharedInfoState | null;
  id?: string;
  linkTo?: string;
  linkType: string;
  locale?: string;
  localizations?: string[];
  location?: string;
  name: string;
  publishedAt?: Date;
  updatedAt?: Date;
  updated_by?: string;
  weight: string | number | null;
  linkParams?: Object;
  // lastUpdatedBy?:string;
};

export type BannerSharedInfoState = {
  name?: string;
  banner?: BannerImageItem;
};

export type BannerFormState = {
  openBy: string;
  canShared: string;
  isPublish: string;
  linkType: string;
  linkTo: string;
  weight: string | number;
  status?: string;
  effectiveRange: [string, string];
  [mainLang]?: BannerSharedInfoState;
  [subLang]?: BannerSharedInfoState;
  linkParams?: Object;
  lastUpdatedBy?: string;
};

export type BannerListParam = {
  pageSize?: number;
  page?: number;
  name?: string;
  isPublish?: string;
  publishDateStart?: Date | null;
  publishDateEnd?: Date | null;
  isValid?: string;
};

export type BannerPaginateList = PaginateList<BannerItem> & { bannerList: BannerItem[]; totalRecords: number };

export type PopupImageItem = {
  blobId: string;
  type?: string;
};

export type PopupItem = {
  createdAt: Date;
  data: Object;
  effectiveDate: Date;
  expiredDate: Date;
  i18nData: PopupSharedInfoState | null;
  id: string;
  linkType: string;
  linkTo?: string;
  locale: string;
  localizations?: bannerLocaleItem[];
  location?: string;
  name: string;
  publishedAt: Date;
  updatedAt: Date;
  lastUpdatedBy: string;
  frequency: string;
  openBy?: string;
  canShare?: boolean;
  lastPublishTime?: Date;
  linkParams?: Object;
};

export type popupLocaleItem = {
  id: string;
  locale: string;
  publishedAt: Date;
  _id: string;
};

export type PopupEditItem = {
  openBy?: string;
  canShare?: boolean;
  createdAt?: Date;
  created_by?: Date;
  data?: Object | null;
  effectiveDate?: string | null;
  expiredDate?: string | null;
  i18nData?: PopupSharedInfoState | null;
  id?: string;
  linkTo?: string;
  linkType: string;
  locale?: string;
  localizations?: string[];
  location?: string;
  name: string;
  publishedAt?: Date;
  updatedAt?: Date;
  // lastUpdatedBy: string;
  frequency: string;
  linkParams?: Object;
};

export type PopupSharedInfoState = {
  name?: string;
  popup?: PopupImageItem;
};

export type PopupFormState = {
  location: string;
  openBy: string;
  canShared: string;
  isPublish: string;
  linkType: string;
  linkTo: string;
  frequency: string;
  status?: string;
  lastUpdatedBy?: string;
  effectiveRange: [string, string];
  [mainLang]?: PopupSharedInfoState;
  [subLang]?: PopupSharedInfoState;
  linkParams?: Object;
};

export type PopupListParam = {
  pageSize?: number;
  page?: number;
  name?: string;
  isPublish?: string;
  publishDateStart?: Date | null;
  publishDateEnd?: Date | null;
  isValid?: string;
};

export type PopupPaginateList = PaginateList<PopupItem> & { popupList: PopupItem[]; totalRecords: number };
