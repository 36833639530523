import { makeStyles } from 'tss-react/mui';

const LABEL_CONTAINER_WIDTH = 180;

export const useStyles = makeStyles()(() => ({
  dialogPaper: {
    width: 700,
    maxWidth: 700,
  },
  reminderDialogPaper: {
    width: 450,
    maxWidth: 450,
  },
  container: {
    padding: '30px 50px',
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  rowSpacing: {
    marginBottom: 20,
  },
  labelContainer: {
    width: LABEL_CONTAINER_WIDTH,
    boxSizing: 'border-box',
  },
  label: {
    fontSize: '1rem',
  },
  fieldContainer: {
    flexGrow: 1,
  },
  textArea: {
    lineHeight: 1.5,
    minHeight: 40,
  },
  radioGroup: {
    flexDirection: 'row',
  },
  noMargin: {
    margin: 0,
  },
  formControlLabel: {
    marginBottom: 0,
  },
  mandatory: {
    color: 'red',
  },
  blueText: {
    color: '#1976D2',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    columnGap: 20,
  },
}));
