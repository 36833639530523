import { IntlShape } from 'react-intl';
import { ModuleHierachy } from 'src/app/common/module/types';
import { isPermitted } from 'src/app/common/utils';
import {
  PROFILE_TITLE,
  PROFILE_AGENT_TITLE,
  PROFILE_AGENT_PATH,
  PROFILE_AGENT_NAMESPACE,
  AdminProfilePermissionType,
  PROFILE_AWARD_NAMESPACE,
  PROFILE_BASE_PATH,
  PROFILE_AWARD_PATH,
  PROFILE_CONFIG_TITLE,
  PROFILE_CONFIG_PATH,
  PROFILE_CONFIG_PROMOTIONS_TITLE,
  PROFILE_CONFIG_BANNER_NAMESPACE,
  PROFILE_CONFIG_PROMOTIONS_PATH,
  PROFILE_CONFIG_PROMTOIONS_NAMESPACE,
  PROFILE_AGENT_SETTING_TITLE,
  PROFILE_AGENT_SETTING_PATH,
  PROFILE_CONFIG_INTRODUCTION_TITLE,
  PROFILE_CONFIG_INTRODUCTION_NAMESPACE,
  PROFILE_CONFIG_INTRODUCTION_PATH,
  PROFILE_AGENT_AWARD_TITLE,
  PROFILE_CONFIG_TIER_TITLE,
  PROFILE_CONFIG_BANNER_PATH,
  PROFILE_AWARD_TITLE,
  PROFILE_AWARD_AGENT_NAMESPACE,
  PROFILE_AWARD_TYPE_PATH,
  PROFILE_CONFIG_NAMESPACE,
  PROFILE_MASS_UPLOAD_TITLE,
  PROFILE_MASS_UPLOAD_STATUS,
  PROFILE_MASS_UPLOAD_STATUS_PATH,
  PROFILE_MASS_UPLOAD_PATH,
  PROFILE_BULK_UPLOAD_STATUS_TITLE,
  PROFILE_BULK_UPLOAD_STATUS_PATH,
} from './constants';
import AgentProfileRoutes from './pages/AgentProfile/AgentProfileRoutes';
import AwardListRoutes from './pages/AgentProfile/AwardList/AwardListRoutes';
import BulkUploadStatusRoute from './pages/AgentProfile/BulkUploadStatus/BulkUploadStatusRoute';
import AwardTypeListRoutes, { awardTypeListPath } from './pages/AwardTypeList/AwardTypeListRoute';

import ConfigAboutMeRoutes from './pages/Configuration/Aboutme/ConfigAboutMeRoutes';
import ConfigBannerTierRoutes from './pages/Configuration/BannerTier/ConfigBannerTierRoutes';
import ConfigPromtoionsRoutes from './pages/Configuration/Promotions/ConfigPromtoionsRoutes';
import MassUploadStatusRoutes from './pages/MassUploadStatus/MassUploadStatusRoute';

export const profileModuleConfig = (permissions: string[], intl: IntlShape): ModuleHierachy => {
  const Translation = (id: string) => intl.formatMessage({ id });

  return {
    title: Translation(PROFILE_TITLE),
    path: PROFILE_BASE_PATH,
    icon: '/media/svg/icons/Devices/Diagnostics.svg',
    enableRead: isPermitted(
      [
        AdminProfilePermissionType.PROFILE_AGENT_READ,
        AdminProfilePermissionType.PROFILE_AGENT_CREATE,
        AdminProfilePermissionType.PROFILE_AGENT_UPDATE,

        AdminProfilePermissionType.PROFILE_AWARD_READ,
        AdminProfilePermissionType.PROFILE_AWARD_CREATE,
        AdminProfilePermissionType.PROFILE_AWARD_UPDATE,

        AdminProfilePermissionType.PROFILE_AGENT_AWARD_READ,
        AdminProfilePermissionType.PROFILE_AGENT_AWARD_CREATE,
        AdminProfilePermissionType.PROFILE_AGENT_AWARD_UPDATE,

        AdminProfilePermissionType.PROFILE_CONFIG_READ,
        AdminProfilePermissionType.PROFILE_CONFIG_CREATE,
        AdminProfilePermissionType.PROFILE_CONFIG_UPDATE,

        AdminProfilePermissionType.PROFILE_CONFIG_INTRODUCTION_READ,
        AdminProfilePermissionType.PROFILE_CONFIG_INTRODUCTION_CREATE,
        AdminProfilePermissionType.PROFILE_CONFIG_INTRODUCTION_UPDATE,

        AdminProfilePermissionType.PROFILE_CONFIG_BANNER_TIER_READ,
        AdminProfilePermissionType.PROFILE_CONFIG_BANNER_TIER_CREATE,
        AdminProfilePermissionType.PROFILE_CONFIG_BANNER_TIER_UPDATE,

        AdminProfilePermissionType.PROFILE_MASS_UPLOAD_STATUS_READ,
        AdminProfilePermissionType.PROFILE_MASS_UPLOAD_STATUS_CREATE,
        AdminProfilePermissionType.PROFILE_MASS_UPLOAD_STATUS_UPDATE,
      ],
      permissions,
    ),
    child: [
      {
        title: Translation(PROFILE_AGENT_TITLE),
        path: `${PROFILE_BASE_PATH}${PROFILE_AGENT_PATH}`,
        namespace: PROFILE_AGENT_NAMESPACE,
        // component: AgentProfileRoutes,
        enableRead: isPermitted(
          [
            AdminProfilePermissionType.PROFILE_AGENT_READ,
            AdminProfilePermissionType.PROFILE_AGENT_CREATE,
            AdminProfilePermissionType.PROFILE_AGENT_UPDATE,

            AdminProfilePermissionType.PROFILE_AWARD_READ,
            AdminProfilePermissionType.PROFILE_AWARD_CREATE,
            AdminProfilePermissionType.PROFILE_AWARD_UPDATE,
          ],
          permissions,
        ),
        enableUpdate: isPermitted([AdminProfilePermissionType.PROFILE_AGENT_UPDATE], permissions),
        enableCreate: isPermitted([AdminProfilePermissionType.PROFILE_AGENT_CREATE], permissions),
        child: [
          {
            title: Translation(PROFILE_AGENT_SETTING_TITLE),
            path: `${PROFILE_BASE_PATH}${PROFILE_AGENT_PATH}${PROFILE_AGENT_SETTING_PATH}`,

            namespace: PROFILE_AGENT_NAMESPACE,
            component: AgentProfileRoutes,

            enableRead: isPermitted(
              [
                AdminProfilePermissionType.PROFILE_AGENT_READ,
                AdminProfilePermissionType.PROFILE_AGENT_CREATE,
                AdminProfilePermissionType.PROFILE_AGENT_UPDATE,
              ],
              permissions,
            ),
            enableUpdate: isPermitted([AdminProfilePermissionType.PROFILE_AGENT_UPDATE], permissions),
            enableCreate: isPermitted([AdminProfilePermissionType.PROFILE_AGENT_CREATE], permissions),
          },
          {
            title: Translation(PROFILE_AGENT_AWARD_TITLE),
            // path: `${PROFILE_BASE_PATH}${PROFILE_AGENT_PATH}`,
            path: `${PROFILE_BASE_PATH}${PROFILE_AGENT_PATH}${PROFILE_AWARD_PATH}`,
            namespace: PROFILE_AWARD_AGENT_NAMESPACE,
            component: AwardListRoutes,
            enableRead: isPermitted(
              [
                AdminProfilePermissionType.PROFILE_AWARD_READ,
                AdminProfilePermissionType.PROFILE_AWARD_CREATE,
                AdminProfilePermissionType.PROFILE_AWARD_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted([AdminProfilePermissionType.PROFILE_AWARD_CREATE], permissions),
            enableUpdate: isPermitted([AdminProfilePermissionType.PROFILE_AWARD_UPDATE], permissions),
          },
          {
            title: Translation(PROFILE_BULK_UPLOAD_STATUS_TITLE),
            path: `${PROFILE_BASE_PATH}${PROFILE_AGENT_PATH}${PROFILE_BULK_UPLOAD_STATUS_PATH}`,

            namespace: PROFILE_AGENT_NAMESPACE,
            component: BulkUploadStatusRoute,

            enableRead: isPermitted(
              [
                AdminProfilePermissionType.PROFILE_AGENT_READ,
                AdminProfilePermissionType.PROFILE_AGENT_CREATE,
                AdminProfilePermissionType.PROFILE_AGENT_UPDATE,
              ],
              permissions,
            ),
            enableUpdate: isPermitted([AdminProfilePermissionType.PROFILE_AGENT_UPDATE], permissions),
            enableCreate: isPermitted([AdminProfilePermissionType.PROFILE_AGENT_CREATE], permissions),
          },
        ],
      },
      {
        title: Translation(PROFILE_AWARD_TITLE),
        // path: `${PROFILE_BASE_PATH}${PROFILE_AGENT_PATH}`,
        path: `${PROFILE_BASE_PATH}${PROFILE_AWARD_TYPE_PATH}`,
        namespace: PROFILE_AWARD_NAMESPACE,
        component: AwardTypeListRoutes,
        enableRead: isPermitted(
          [
            AdminProfilePermissionType.PROFILE_AWARD_READ,
            AdminProfilePermissionType.PROFILE_AWARD_CREATE,
            AdminProfilePermissionType.PROFILE_AWARD_UPDATE,
          ],
          permissions,
        ),
        enableCreate: isPermitted([AdminProfilePermissionType.PROFILE_AWARD_CREATE], permissions),
        enableUpdate: isPermitted([AdminProfilePermissionType.PROFILE_AWARD_UPDATE], permissions),
      },
      {
        title: Translation(PROFILE_CONFIG_TITLE),
        // path: `${PROFILE_BASE_PATH}${PROFILE_AGENT_PATH}`,
        path: `${PROFILE_BASE_PATH}${PROFILE_CONFIG_PATH}`,

        enableRead: isPermitted(
          [
            AdminProfilePermissionType.PROFILE_CONFIG_READ,
            AdminProfilePermissionType.PROFILE_CONFIG_CREATE,
            AdminProfilePermissionType.PROFILE_CONFIG_UPDATE,

            AdminProfilePermissionType.PROFILE_CONFIG_INTRODUCTION_READ,
            AdminProfilePermissionType.PROFILE_CONFIG_INTRODUCTION_CREATE,
            AdminProfilePermissionType.PROFILE_CONFIG_INTRODUCTION_UPDATE,

            AdminProfilePermissionType.PROFILE_CONFIG_BANNER_TIER_UPDATE,
            AdminProfilePermissionType.PROFILE_CONFIG_BANNER_TIER_READ,
            AdminProfilePermissionType.PROFILE_CONFIG_BANNER_TIER_CREATE,

            AdminProfilePermissionType.PROFILE_AGENT_READ,
            AdminProfilePermissionType.PROFILE_AGENT_CREATE,
            AdminProfilePermissionType.PROFILE_AGENT_UPDATE,
            AdminProfilePermissionType.PROFILE_AWARD_READ,
            AdminProfilePermissionType.PROFILE_AWARD_CREATE,
            AdminProfilePermissionType.PROFILE_AWARD_UPDATE,
          ],
          permissions,
        ),
        child: [
          {
            title: Translation(PROFILE_CONFIG_TIER_TITLE),
            path: `${PROFILE_BASE_PATH}${PROFILE_CONFIG_PATH}${PROFILE_CONFIG_BANNER_PATH}`,
            namespace: PROFILE_CONFIG_BANNER_NAMESPACE,

            component: ConfigBannerTierRoutes,
            enableRead: isPermitted(
              [
                AdminProfilePermissionType.PROFILE_CONFIG_BANNER_TIER_UPDATE,
                AdminProfilePermissionType.PROFILE_CONFIG_BANNER_TIER_READ,
                AdminProfilePermissionType.PROFILE_CONFIG_BANNER_TIER_CREATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted([AdminProfilePermissionType.PROFILE_CONFIG_BANNER_TIER_CREATE], permissions),
            enableUpdate: isPermitted([AdminProfilePermissionType.PROFILE_CONFIG_BANNER_TIER_UPDATE], permissions),
          },
          {
            title: Translation(PROFILE_CONFIG_INTRODUCTION_TITLE),
            path: `${PROFILE_BASE_PATH}${PROFILE_CONFIG_PATH}${PROFILE_CONFIG_INTRODUCTION_PATH}`,
            namespace: PROFILE_CONFIG_INTRODUCTION_NAMESPACE,

            component: ConfigAboutMeRoutes,
            enableRead: isPermitted(
              [
                AdminProfilePermissionType.PROFILE_CONFIG_INTRODUCTION_READ,
                AdminProfilePermissionType.PROFILE_CONFIG_INTRODUCTION_CREATE,
                AdminProfilePermissionType.PROFILE_CONFIG_INTRODUCTION_UPDATE,
                AdminProfilePermissionType.PROFILE_AGENT_READ,
                AdminProfilePermissionType.PROFILE_AGENT_CREATE,
                AdminProfilePermissionType.PROFILE_AGENT_UPDATE,
                AdminProfilePermissionType.PROFILE_AWARD_READ,
                AdminProfilePermissionType.PROFILE_AWARD_CREATE,
                AdminProfilePermissionType.PROFILE_AWARD_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted([AdminProfilePermissionType.PROFILE_CONFIG_INTRODUCTION_CREATE], permissions),
            enableUpdate: isPermitted([AdminProfilePermissionType.PROFILE_CONFIG_INTRODUCTION_UPDATE], permissions),
          },
          /**
           * @deprecated from PF 23.04.1 (PCAAEB-24055)
           */
          // {
          //   title: Translation(PROFILE_CONFIG_PROMOTIONS_TITLE),
          //   path: `${PROFILE_BASE_PATH}${PROFILE_CONFIG_PATH}${PROFILE_CONFIG_PROMOTIONS_PATH}`,
          //   namespace: PROFILE_CONFIG_PROMTOIONS_NAMESPACE,
          //   component: ConfigPromtoionsRoutes,
          //   enableRead: isPermitted(
          //     [
          //       AdminProfilePermissionType.PROFILE_CONFIG_PROMTOIONS_READ,
          //       AdminProfilePermissionType.PROFILE_CONFIG_PROMTOIONS_CREATE,
          //       AdminProfilePermissionType.PROFILE_CONFIG_PROMTOIONS_UPDATE,
          //       AdminProfilePermissionType.PROFILE_AGENT_READ,
          //       AdminProfilePermissionType.PROFILE_AGENT_CREATE,
          //       AdminProfilePermissionType.PROFILE_AGENT_UPDATE,
          //     ],
          //     permissions,
          //   ),
          //   enableCreate: isPermitted([AdminProfilePermissionType.PROFILE_CONFIG_PROMTOIONS_CREATE], permissions),
          //   enableUpdate: isPermitted([AdminProfilePermissionType.PROFILE_CONFIG_PROMTOIONS_UPDATE], permissions),
          // },
        ],
      },
      {
        title: Translation(PROFILE_MASS_UPLOAD_TITLE),

        path: `${PROFILE_BASE_PATH}${PROFILE_MASS_UPLOAD_PATH}`,
        namespace: PROFILE_AWARD_NAMESPACE,

        enableRead: isPermitted(
          [
            AdminProfilePermissionType.PROFILE_AGENT_READ,
            AdminProfilePermissionType.PROFILE_AGENT_CREATE,
            AdminProfilePermissionType.PROFILE_AGENT_UPDATE,
            AdminProfilePermissionType.PROFILE_MASS_UPLOAD_STATUS_READ,
            AdminProfilePermissionType.PROFILE_MASS_UPLOAD_STATUS_CREATE,
            AdminProfilePermissionType.PROFILE_MASS_UPLOAD_STATUS_UPDATE,
          ],
          permissions,
        ),
        child: [
          {
            title: Translation(PROFILE_MASS_UPLOAD_STATUS),
            path: `${PROFILE_BASE_PATH}${PROFILE_MASS_UPLOAD_PATH}${PROFILE_MASS_UPLOAD_STATUS_PATH}`,
            namespace: PROFILE_AWARD_NAMESPACE,

            component: MassUploadStatusRoutes,
            enableRead: isPermitted(
              [
                AdminProfilePermissionType.PROFILE_AGENT_READ,
                AdminProfilePermissionType.PROFILE_AGENT_CREATE,
                AdminProfilePermissionType.PROFILE_AGENT_UPDATE,
                AdminProfilePermissionType.PROFILE_MASS_UPLOAD_STATUS_READ,
                AdminProfilePermissionType.PROFILE_MASS_UPLOAD_STATUS_CREATE,
                AdminProfilePermissionType.PROFILE_MASS_UPLOAD_STATUS_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted([AdminProfilePermissionType.PROFILE_AGENT_CREATE], permissions),
            enableUpdate: isPermitted([AdminProfilePermissionType.PROFILE_AGENT_UPDATE], permissions),
          },
        ],
      },
    ],
  };
};
