import { FC } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import PruRoute from 'src/app/common/components/PruRoute';
import ParamsProvider from 'src/app/common/components/ParamsProvider';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { RECRUITMENT_BASE_PATH, RECRUITMENT_APPLICATION_FORM_PATH, FORM_TEMPLATE_PATH } from '../../../constants';
import { formTemplateFilterKeys } from './list/form-template-listing-page.hook';
import { FormTemplateListingPage } from './list/form-template-listing-page.screen';
import { RecruitmentTemplateDetailPage } from '../../template-detail/template-detail-page';

export const formTemplatePath = `${RECRUITMENT_BASE_PATH}${RECRUITMENT_APPLICATION_FORM_PATH}${FORM_TEMPLATE_PATH}`;

const FormTemplateRoutes: FC = () => {
  return (
    <Switch>
      <Redirect exact from={formTemplatePath} to={`${formTemplatePath}/list`} />
      <PruRoute
        exact
        path={`${formTemplatePath}/list`}
        render={(props) => (
          <ParamsProvider {...props} acceptKeys={formTemplateFilterKeys}>
            <FormTemplateListingPage />
          </ParamsProvider>
        )}
      />
      {/* create: /recruitment/application-form/form-template/detail/?action=create */}
      <PruRoute exact path={`${formTemplatePath}/detail`} component={RecruitmentTemplateDetailPage} />
      {/* view/edit/duplicate: /recruitment/application-form/form-template/detail/template-object-id?action=view|edit|duplicate */}
      <PruRoute exact path={`${formTemplatePath}/detail/:templateId`} component={RecruitmentTemplateDetailPage} />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default FormTemplateRoutes;
