import React, { FC, useMemo } from 'react';
import { Switch, Redirect, RouteComponentProps, useLocation } from 'react-router-dom';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import PruRoute from 'src/app/common/components/PruRoute';
import ParamsProvider from 'src/app/common/components/ParamsProvider';
import {
  PULSELEADS_BASE_PATH,
  PULSELEADS_SLA_PATH,
  PULSELEADS_ACCEPT_SLA_PATH,
  PULSELEADS_CONTACT_SLA_PATH,
  PULSELEADS_MEET_SLA_PATH,
  PULSELEADS_PROPOSAL_SLA_PATH,
  PULSELEADS_APPLICATION_SLA_PATH,
} from '../../constants';
import { SLAType } from '../../types/sla-types';
import SLAListingPage, { slaFilterKeys } from './List/SLAListingPage';
import SLADetailPage from './Form/SLADetailPage';

export const getSLARootPath = (pathname: string): string => {
  const slaAcceptPath = `${PULSELEADS_BASE_PATH}${PULSELEADS_SLA_PATH}${PULSELEADS_ACCEPT_SLA_PATH}`;
  const slaContactPath = `${PULSELEADS_BASE_PATH}${PULSELEADS_SLA_PATH}${PULSELEADS_CONTACT_SLA_PATH}`;
  const slaMeetPath = `${PULSELEADS_BASE_PATH}${PULSELEADS_SLA_PATH}${PULSELEADS_MEET_SLA_PATH}`;
  const slaProposalPath = `${PULSELEADS_BASE_PATH}${PULSELEADS_SLA_PATH}${PULSELEADS_PROPOSAL_SLA_PATH}`;
  const slaApplicationPath = `${PULSELEADS_BASE_PATH}${PULSELEADS_SLA_PATH}${PULSELEADS_APPLICATION_SLA_PATH}`;
  if (pathname.includes(PULSELEADS_ACCEPT_SLA_PATH)) {
    return slaAcceptPath;
  } else if (pathname.includes(PULSELEADS_CONTACT_SLA_PATH)) {
    return slaContactPath;
  } else if (pathname.includes(PULSELEADS_MEET_SLA_PATH)) {
    return slaMeetPath;
  } else if (pathname.includes(PULSELEADS_PROPOSAL_SLA_PATH)) {
    return slaProposalPath;
  } else if (pathname.includes(PULSELEADS_APPLICATION_SLA_PATH)) {
    return slaApplicationPath;
  } else {
    return NOT_FOUND_ERROR_PAGE_PATH;
  }
};

export const getSLAType = (pathname: string): SLAType | undefined => {
  if (pathname.includes(PULSELEADS_ACCEPT_SLA_PATH)) {
    return SLAType.ACCEPT;
  } else if (pathname.includes(PULSELEADS_CONTACT_SLA_PATH)) {
    return SLAType.CONTACT;
  } else if (pathname.includes(PULSELEADS_MEET_SLA_PATH)) {
    return SLAType.MEET;
  } else if (pathname.includes(PULSELEADS_PROPOSAL_SLA_PATH)) {
    return SLAType.PROPOSAL;
  } else if (pathname.includes(PULSELEADS_APPLICATION_SLA_PATH)) {
    return SLAType.APPLICATION;
  } else {
    return undefined;
  }
};

const SLARoutes: FC<RouteComponentProps> = () => {
  const location = useLocation();
  // eslint-disable-next-line
  const path = useMemo(() => getSLARootPath(location.pathname), []);

  return (
    <Switch>
      <Redirect exact from={path} to={`${path}/list`} />
      <PruRoute
        exact
        path={`${path}/list`}
        render={(props) => (
          <ParamsProvider {...props} acceptKeys={slaFilterKeys}>
            <SLAListingPage />
          </ParamsProvider>
        )}
      />
      <PruRoute exact path={`${path}/edit/:id`} component={SLADetailPage} />
      <PruRoute exact path={`${path}/create`} component={SLADetailPage} />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default SLARoutes;
