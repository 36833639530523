import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import PruRoute from 'src/app/common/components/PruRoute';
import ParamsProvider from 'src/app/common/components/ParamsProvider';
import { INSIGHTS_BASE_PATH, INSIGHTS_DASHBOARD_PATH, INSIGHTS_DASHBOARD_SUMMARY_PATH } from '../../../constants';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import SummaryReportListingPage, { summaryReportFilterKeys } from './List/SummaryReportListingPage';

const summaryReportPath = `${INSIGHTS_BASE_PATH}${INSIGHTS_DASHBOARD_PATH}${INSIGHTS_DASHBOARD_SUMMARY_PATH}`;

const SummaryReportRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect exact from={`${summaryReportPath}`} to={`${summaryReportPath}/list`} />
      <PruRoute
        exact
        path={`${summaryReportPath}/list`}
        render={(props) => (
          <ParamsProvider {...props} acceptKeys={summaryReportFilterKeys}>
            <SummaryReportListingPage />
          </ParamsProvider>
        )}
      />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default SummaryReportRoutes;
