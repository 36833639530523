import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import { Dispatch } from 'react';
import {
  ActivityRuleDataDto,
  ActivityRuleGroupDataDto,
  ActivityRuleSetDetailDto,
  AgentRuleMapCheckDto,
} from './constants';

export const getRuleNameList = async (dispatch?: Dispatch<any>) => {
  try {
    const r = await apiClient.get(`${ADMIN_URL}/activity-point/rule-name`);
    return r.data;
  } catch (err: any) {
    throw apiErrorHandler(err, dispatch);
  }
};

const transformData = (body: ActivityRuleSetDetailDto) => {
  let rawData = body;
  rawData.name = String(rawData.name);

  const transformNumber = (list: ActivityRuleDataDto[]) => {
    return list.map((item: any) => {
      let result = item;
      result = {
        ...result,
        marketSourcePoint: item?.marketSourcePoint ? Number(item.marketSourcePoint) : 0,
      };
      result = {
        ...result,
        agentSourcePoint: item?.agentSourcePoint ? Number(item.agentSourcePoint) : 0,
      };
      return result;
    });
  };

  const transformAgentCode = (list: string[]) => {
    return list.map((agentCode) => {
      if (agentCode.length < 8) {
        return agentCode.padStart(8, '0');
      } else {
        return agentCode;
      }
    });
  };

  rawData.ruleGroupData = rawData.ruleGroupData.map((item: ActivityRuleGroupDataDto) => {
    return {
      ...item,
      maxPoint: item.hasMaxPoint !== 'no' ? Number(item.maxPoint) : 0,
      rules: transformNumber(item.rules),
    };
  });
  rawData.targetAgent = transformAgentCode(rawData.targetAgent);
  if (rawData?.overrideAgent) {
    rawData.overrideAgent = transformAgentCode(rawData.overrideAgent);
  }
  return rawData;
};

export const createRuleSetting = async (body: ActivityRuleSetDetailDto, dispatch?: Dispatch<any>) => {
  try {
    const data = transformData(body);
    const r = await apiClient.post(`${ADMIN_URL}/activity-point/rule-settings/create`, data);
    return r;
  } catch (err: any) {
    throw apiErrorHandler(err, dispatch);
  }
};

export const updateRuleSetting = async (id: string, body: ActivityRuleSetDetailDto, dispatch?: Dispatch<any>) => {
  try {
    const data = transformData(body);
    const r = await apiClient.put(`${ADMIN_URL}/activity-point/rule-settings/update/${id}`, data);
    return r;
  } catch (err: any) {
    throw apiErrorHandler(err, dispatch);
  }
};

export const deleteRuleSetting = async (id: string, dispatch?: Dispatch<any>) => {
  try {
    const r = await apiClient.delete(`${ADMIN_URL}/activity-point/rule-settings/delete/${id}`);
    return r;
  } catch (err: any) {
    throw apiErrorHandler(err, dispatch);
  }
};

export const getRulesettingDetail = async (id: string, dispatch?: Dispatch<any>) => {
  try {
    const r = await apiClient.get(`${ADMIN_URL}/activity-point/rule-settings/detail/${id}`);
    return r;
  } catch (err: any) {
    throw apiErrorHandler(err, dispatch);
  }
};

export const getBasicRuleList = async (params: any, dispatch?: Dispatch<any>) => {
  try {
    const r = await apiClient.get(`${ADMIN_URL}/engagement-topics`, { params });
    return r;
  } catch (err: any) {
    throw apiErrorHandler(err, dispatch);
  }
};

export const checkTargetAgentList = async (body: AgentRuleMapCheckDto, dispatch?: Dispatch<any>) => {
  try {
    const r = await apiClient.post(`${ADMIN_URL}/activity-point/rule-settings/agent-check`, body);
    return r;
  } catch (err: any) {
    throw apiErrorHandler(err, dispatch);
  }
};
