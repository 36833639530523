import React, { useState, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Radio, RadioGroup, FormControlLabel, TextField } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { PruDatePicker } from 'src/app/common/components/PruDatePicker';
import Form from 'src/app/common/components/Form';
import moment from 'moment';
import { NumberGreaterThanZeroPattern, disableInputInvalidValueForWeight } from 'src/app/common/utils';
import { SelectTypes } from '../../constants';
import { useIntl } from 'react-intl';

const useStyles = makeStyles()({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 30,
    height: 30,
    'input:hover ~ &': {
      borderWidth: '2px',
      borderColor: 'black',
      borderStyle: 'solid',
    },
  },
  checkedIcon: {
    borderWidth: '2px',
    borderColor: 'black',
    borderStyle: 'solid',
    display: 'flex',
    justifyContent: 'center',
  },
  tickIcon: {
    color: 'white',
    alignSelf: 'center',
  },
});

const DeadLineSection: React.FC<any> = (props) => {
  const { classes } = useStyles();
  const { onDeadLineTypeChange, disabled } = props;
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const onSelectTypeChange = (key: string, value: any) => {
    onDeadLineTypeChange(key, value);
  };

  const renderTextFiled = (selectType: string, type: string, intialValues: any) => {
    if (selectType === undefined || selectType === null || selectType === '') {
      return null;
    }

    if (selectType.toString() !== type.toString()) {
      return null;
    }

    switch (selectType.toString()) {
      case SelectTypes.FIX_DATE.value:
        return (
          <PruDatePicker
            disabled={disabled}
            format="DD/MM/YYYY"
            minDate={new Date()}
            value={intialValues.value ? intialValues.value : null}
            onChange={(date) => {
              onSelectTypeChange('value', moment(date).local().endOf('day'));
            }}
          />
        );
        break;

      case SelectTypes.ACTICATION_MONTH.value:
      case SelectTypes.ACTICATION_DATE.value:
        return (
          <TextField
            type="number"
            disabled={disabled}
            margin="dense"
            variant="outlined"
            value={intialValues.value}
            inputProps={disableInputInvalidValueForWeight()}
            onChange={(e: any) => {
              onSelectTypeChange('value', e.target.value);
            }}
          />
        );
        break;

      default:
        break;
    }
  };

  return (
    <Form.Item>
      {(_, meta, { getFieldValue }) => {
        const intialValues = getFieldValue('completionDeadline');
        let type = intialValues?.type;

        if (type === 0) {
          type = '0';
        }

        if (!intialValues && !type) {
          type = '';
        }

        // console.log(type)
        // const valjuje = intialValues && intialValues.type || '';

        return (
          <RadioGroup style={{ display: 'flex', flexDirection: 'column' }}>
            {Object.keys(SelectTypes).map((key: string, index) => {
              return (
                <div style={{ display: 'flex', flexDirection: 'row' }} key={`deadline_key_${index}`}>
                  <FormControlLabel
                    key={`key_${index}`}
                    value={SelectTypes[key].value}
                    control={
                      <Radio
                        disabled={disabled}
                        onChange={(e: any) => {
                          onSelectTypeChange('value', '');
                          onSelectTypeChange('type', e.target.value);
                        }}
                        checked={SelectTypes[key].value.toString() === type}
                      />
                    }
                    label={Translation(
                      `mandatory.training.component.collection.detail.deadline.${SelectTypes[key].label}`,
                    )}
                  />

                  {renderTextFiled(intialValues?.type, SelectTypes[key]?.value, intialValues)}
                </div>
              );
            })}
          </RadioGroup>
        );
      }}
    </Form.Item>
  );
};

export default DeadLineSection;
