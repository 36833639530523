import { Dispatch } from 'react';
import { getDayStart, getDayEnd, downloadFile } from 'src/app/common/utils';
import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import { PaginateList } from '../types/recruit-generic-types';
import {
  ExamResultsFileData,
  ExamScheduleFileData,
  LicenseExamRegistrationExportParam,
  LicenseExamResultsListParam,
  examOptionsParam,
  examResultsParam,
  examScheduleParam,
} from '../types/license-exam-types';
import qs from 'qs';
import moment from 'moment';

const licenseExamSchedule = 'license-exam-schedule';
const licenseExamRegistration = 'license-exam-registration';
const licenseExamResults = 'license-exam-results';

// const ADMIN_URL = 'http://localhost:3000';

export const createLicenseExamSchedule = async (
  body: { payload: ExamScheduleFileData[] },
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .post<void>(`${ADMIN_URL}/${licenseExamSchedule}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const deleteLicenseExamSchedule = async (id: string, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .delete<void>(`${ADMIN_URL}/${licenseExamSchedule}/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchLicenseExamExamScheduleList = async (
  params: examScheduleParam,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<LicenseExamResultsListParam>> => {
  const queryPath = `${ADMIN_URL}/${licenseExamSchedule}`;
  return apiClient
    .get<PaginateList<LicenseExamResultsListParam>>(queryPath, { params: params })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchLicenseExamExamOptions = async (
  type: string,
  params: examOptionsParam,
  dispatch?: Dispatch<any>,
): Promise<string[]> => {
  const queryPath = `${ADMIN_URL}/${licenseExamSchedule}/options/${type}`;
  return apiClient
    .get<string[]>(queryPath, { params: params })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createLicenseExamResults = async (body: ExamResultsFileData, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .post<void>(`${ADMIN_URL}/${licenseExamResults}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchLicenseExamResultsList = async (
  params: examResultsParam,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<examResultsParam>> => {
  const queryPath = `${ADMIN_URL}/${licenseExamResults}`;
  return apiClient
    .get<PaginateList<examResultsParam>>(queryPath, { params })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const exportRegistration = async (
  params: LicenseExamRegistrationExportParam,
  dispatch?: Dispatch<any>,
): Promise<any> => {
  const query = qs.stringify(params);
  const queryPath = `${ADMIN_URL}/${licenseExamRegistration}?${query}`;
  const fileName = `licenseExamRegistration_${moment().format('DDMMYYYY')}`;
  return downloadFile(fileName, queryPath);
};
