import React, { FC, useEffect, useState } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import PruRoute from 'src/app/common/components/PruRoute';

import ParamsProvider from 'src/app/common/components/ParamsProvider';

import {
  PROFILE_AGENT_PATH,
  PROFILE_AGENT_SETTING_PATH,
  PROFILE_BASE_PATH,
  PROFILE_BULK_UPLOAD_STATUS_PATH,
} from '../../../constants';
import BulkUploadRecord, { BulkUploadRecordFilterKeys } from './BulkUploadRecord';

export const bulkUploadStatusPath = `${PROFILE_BASE_PATH}${PROFILE_AGENT_PATH}${PROFILE_BULK_UPLOAD_STATUS_PATH}`;

const BulkUploadStatusRoute: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect exact from={bulkUploadStatusPath} to={`${bulkUploadStatusPath}/report`} />
      <PruRoute
        exact
        path={`${bulkUploadStatusPath}/report`}
        render={(props) => (
          <ParamsProvider {...props} acceptKeys={BulkUploadRecordFilterKeys}>
            <BulkUploadRecord />
          </ParamsProvider>
        )}
      />
      {/* <PruRoute exact path={`${bulkUploadStatusPath}/report`} component={BulkUploadRecord} /> */}
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default BulkUploadStatusRoute;
