export enum CampaignMappingFormMode {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
}

export type StagingLeadItem = {
  _id: string;
  firstName: string;
  lastName: string;
  campaignId: string;
  leadSource: string;
  micrositeId: string;
  distributionStatus: string;
  leadDate: string;
};
