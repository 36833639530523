import { Dispatch } from 'react';
import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import { forEach } from 'lodash';
import { PaginateList } from 'src/app/common/types/common-types';
import { DeduplicationRuleItem, DeduplicationRule } from '../types/deduplication-rule-types';

const deduplicationRuleEndpoint = 'deduplication-rules';

export type DeduplicationRuleListParam = {
  name: string;
  page: number;
  limit: number;
};

export type CreateDeduplicationRuleBody = {
  name: string;
  description?: string;
  enforcedBy: string;
  rules: DeduplicationRuleItem[];
  redistributionAfterDays: number;
  createdBy: string;
  updatedBy: string;
};

export type UpdateDeduplicationRuleBody = {
  name: string;
  description?: string;
  enforcedBy: string;
  rules: DeduplicationRuleItem[];
  redistributionAfterDays: number;
  updatedBy: string;
};

export const fetchDeduplicationRuleList = async (
  params: DeduplicationRuleListParam,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<DeduplicationRule>> => {
  let queryPath = `${ADMIN_URL}/${deduplicationRuleEndpoint}?`;
  forEach(params, (param, key) => {
    if (key === 'page') {
      queryPath += `${key}=${encodeURIComponent(param as any)}&`;
    } else {
      queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
    }
  });
  return apiClient
    .get<PaginateList<DeduplicationRule>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchDeduplicationRuleItem = async (id: string, dispatch?: Dispatch<any>): Promise<DeduplicationRule> => {
  return apiClient
    .get<DeduplicationRule>(`${ADMIN_URL}/${deduplicationRuleEndpoint}/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createDeduplicationRule = async (
  body: CreateDeduplicationRuleBody,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .post<void>(`${ADMIN_URL}/${deduplicationRuleEndpoint}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const modifyDeduplicationRule = async (
  id: string,
  body: UpdateDeduplicationRuleBody,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .put<void>(`${ADMIN_URL}/${deduplicationRuleEndpoint}/${id}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const deleteDeduplicationRule = async (id: string, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .delete<void>(`${ADMIN_URL}/${deduplicationRuleEndpoint}/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
