import React, { FC, useEffect, useState } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { fetchFeedbackResultItem } from '../../../network/feedbackCrud';
import { FeedbackResultItem } from '../../../types/feedback-type';
import { NOT_FOUND_ERROR_PAGE_PATH } from '../../../../../common/constants';
import { useDispatch } from 'react-redux';
import { ApiErrorResponse } from 'src/app/common/network';
import { LayoutSplashScreen } from 'src/app/layout';
import FeedbackDetail from './components/FeedbackDetail';

const FeedbackDetailPage: FC<RouteComponentProps> = ({ history }) => {
  const { id } = useParams<{ id: string }>();

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [feedbackResult, setFeedbackResult] = useState<FeedbackResultItem>();

  const reloadData = () => {
    if (id) {
      setIsLoading(true);
      fetchFeedbackResultItem(id, dispatch)
        .then((result) => {
          setFeedbackResult(result);
        })
        .catch((err: ApiErrorResponse) => {
          history.push(NOT_FOUND_ERROR_PAGE_PATH);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line
  }, [id]);

  return (
    <>{isLoading || !feedbackResult ? <LayoutSplashScreen /> : <FeedbackDetail feedbackResult={feedbackResult} />}</>
  );
};

export default FeedbackDetailPage;
