export enum AppStatusEnum {
  EDITING = 'Editing',
  SUBMITTED_TO_A0 = 'SubmittedToAO',
  FOLLOW_UP_REQUIRED = 'FollowUpRequired',
  REJECTED = 'Rejected',
  COMPLETED = 'Completed',
  CANCELLED = 'Cancelled',
}

export enum ApprovalStatusEnum {
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  FOLLOW_UP_REQUIRED = 'FollowUpRequired',
}

export enum PdfFileTypeEnum {
  UploadedFile = 'uploaded_file',
  ApplicationForAppointment = 'application_for_appointment',
  AgencyAgreementPHKL = 'agency_agreement_phkl',
  AgencyAgreementPGHK = 'agency_agreement_pghk',
  AcknowledgmentInMainlandChina = 'acknowledgment_in_mainland_china',
  SupplementaryAgreementForManagers = 'supplementary_agreement_for_managers',
  AcademicQualification = 'authorization_for_reference_checking_on_academic_qualification',
  FinancingAgreement = 'financing_agreement'
}

export type RecruiterInfoItem = {
  recruiterCode: string;
  recruiterName: string;
  recruiterDetails: string;
  recruiterBusinessAddress: string;
  recruiterDSGNCode: string;
}

export type PersonalParticularInfoItem = {
  engSurname: string;
  engFirstName: string;
}

export type AssignDesignationInfoItem = {
  araAgentCode: string;
  araAgentName: string;
  potentialRecruiterName: string;
  potentialRecruiterHKID: string;
  designationRecommendCode: string;
}

export type SupplementaryInfoItem = {
  reason: string;
}

export type GpsLocationItem = {
  latitude: number;
  longitude: number;
}

export type FollowUpDetailsItem = {
  isFollowUpByZone: boolean;
  remarksFromAMD: string;
  remarksFromCaseOfficer: string;
}

export type PersonalParticularItem = {
  _id: string;
  appId: string;
  recruiterInfo: RecruiterInfoItem;
  personalParticular: PersonalParticularInfoItem;
  appStatus: string;
  submittedDate: Date;
  lastUpdatedDate: Date;
  assignDesignation: AssignDesignationInfoItem;
  supplementary: SupplementaryInfoItem;
  gpsLocation: GpsLocationItem;
  followUpDetails: FollowUpDetailsItem;

  // FOLLOW UP
  // lastUpdateUser: string;
}

export type PersonalParticularDocItem = {
  fileBase64Str: string
}