import React, { FC, useState, useReducer, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import {
  FormControlLabel,
  FormControl,
  Checkbox,
  Button,
  ClickAwayListener,
  TextField,
  Select,
  MenuItem,
} from '@mui/material';
import { useServerLocale } from 'src/app/i18n';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { createBlob, getBlob } from 'src/app/common/network';
import { fileUpload } from 'src/app/common/utils';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import { TargetApplicantTypeEnum, TargetApplicantSelectModeProps } from '../types';
import { useSelectStyles } from './target-applicant-select-mode.style';
import { selectModeFormReducer } from './form-reducer';

export const TargetApplicantSelectMode: FC<TargetApplicantSelectModeProps> = ({
  isDisabled,
  module,
  designationList,
  agentSegmentList,
  initialFormState,
  onChange,
}) => {
  const { classes } = useSelectStyles();
  const { classes: commonClasses } = useCommonStyles();
  const serverLocale = useServerLocale();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [formState, formDispatch] = useReducer(selectModeFormReducer, initialFormState);
  const { targetApplicantType, eligibleDesignations, eligibleAgents, excludedAgents } = formState;

  useEffect(() => {
    onChange(formState);
  }, [formState]);

  const handleFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      try {
        const createBlobRes = await createBlob({ mimeType: file.type, accessLevel: 'public', module }, dispatch);
        await fileUpload(createBlobRes.url, file);
        const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);
        const result = blobDetail[0];
        if (result) {
          formDispatch({
            type: 'MODIFY_FIELD',
            payload: { field: 'agentListFile', value: { blobId: result.blobId, filename: file.name, url: result.url } },
          });
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: `Upload file successfully - ${file.name}`,
              },
            ]),
          );
          const reader = new FileReader();
          reader.onload = () => {
            const csvData = String(reader.result);
            const row = csvData.split('\n');
            const agentList: string[] = [];
            row.shift();
            row.forEach((item) => {
              const rowItems = item.split(',');
              if (rowItems[0] && rowItems[0].trim()) {
                // handle prefill zero in parent component if needed
                agentList.push(rowItems[0].trim());
              }
            });
            formDispatch({
              type: 'MODIFY_FIELD',
              payload: { field: 'eligibleAgents', value: agentList },
            });
          };
          reader.readAsBinaryString(file);
        }
      } catch (err) {}
    }
  };

  return (
    <>
      <div className={targetApplicantType !== TargetApplicantTypeEnum.ALL ? classes.rowSpacing : undefined}>
        <FormControl
          disabled={isDisabled}
          className={`${classes.select} ${classes.noMargin}`}
          margin="dense"
          variant="outlined"
        >
          <Select
            value={targetApplicantType}
            onChange={(e) => {
              formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'targetApplicantType', value: e.target.value } });
            }}
          >
            {Object.values(TargetApplicantTypeEnum).map((value) => (
              <MenuItem key={value} value={value}>
                {Translation(`targetApplicantSection.common.target_applicant_type.${value.toLowerCase()}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      {targetApplicantType === TargetApplicantTypeEnum.DESIGNATIONS && (
        <>
          <div className={`${classes.rowContainer} ${classes.rowSpacing}`}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>{Translation('targetApplicantSection.common.designation')} : </span>
            </div>
            <div className={classes.popupWrapper}>
              <Button disabled={isDisabled} variant="contained" color="secondary" onClick={() => setIsOpen(true)}>
                {Translation('targetApplicantSection.common.select')}
              </Button>
              {isOpen && (
                <div className={classes.cardPos}>
                  <ClickAwayListener onClickAway={() => setIsOpen(false)}>
                    <div style={{ width: 480 }} className={commonClasses.popupCard}>
                      <div className={classes.rowContainer}>
                        {designationList.map((designation) => (
                          <div key={`target-applicant-designation-${designation.code}`} className={classes.option}>
                            <FormControlLabel
                              style={{ margin: 0 }}
                              control={
                                <Checkbox
                                  checked={eligibleDesignations.includes(designation.code)}
                                  onChange={() => {
                                    formDispatch({
                                      type: 'SELECT_FIELD',
                                      payload: {
                                        field: 'eligibleDesignations',
                                        value: designation.code,
                                      },
                                    });
                                  }}
                                />
                              }
                              label={designation.shortName || designation.code}
                              labelPlacement="end"
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </ClickAwayListener>
                </div>
              )}
            </div>
          </div>
          <div className={`${classes.rowContainer} ${classes.rowSpacing}`}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>{Translation('targetApplicantSection.common.extra_include')} : </span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <TextField
                disabled={isDisabled}
                className={classes.noMargin}
                margin="dense"
                variant="outlined"
                fullWidth
                placeholder={'Input agent code separate by comma. e.g. 10001, 10002'}
                value={eligibleAgents.join(',')}
                onChange={(e) => {
                  const agentCode = e.target.value.replace(/[^0-9,]/g, '');
                  formDispatch({
                    type: 'MODIFY_FIELD',
                    payload: {
                      field: 'eligibleAgents',
                      value: agentCode ? agentCode.split(',') : [],
                    },
                  });
                }}
              />
            </div>
          </div>
          <div className={classes.rowContainer}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>{Translation('targetApplicantSection.common.extra_exclude')} : </span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <TextField
                disabled={isDisabled}
                className={classes.noMargin}
                margin="dense"
                variant="outlined"
                fullWidth
                value={excludedAgents.join(',')}
                placeholder={'Input agent code separate by comma. e.g. 10001, 10002'}
                onChange={(e) => {
                  const agentCode = e.target.value.replace(/[^0-9,]/g, '');
                  formDispatch({
                    type: 'MODIFY_FIELD',
                    payload: {
                      field: 'excludedAgents',
                      value: agentCode ? agentCode.split(',') : [],
                    },
                  });
                }}
              />
            </div>
          </div>
        </>
      )}
      {targetApplicantType === TargetApplicantTypeEnum.AGENTS && (
        <>
          <div className={classes.rowContainer}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>{Translation('targetApplicantSection.common.upload_csv')} : </span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <input
                id="upload-agent-list"
                hidden
                type="file"
                accept=".csv"
                onClick={(e) => {
                  const element = e.target as HTMLInputElement;
                  element.value = '';
                }}
                onChange={handleFile}
              />
              <div className={classes.rowContainer}>
                <Button
                  disabled={isDisabled}
                  variant="contained"
                  color="secondary"
                  onClick={() => document.getElementById('upload-agent-list')?.click()}
                >
                  {Translation('app.button.chooseFile')}
                </Button>
                <div className={classes.chooseFileSpacing}>
                  {formState.agentListFile?.filename || Translation('app.file.noFileChosen')}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {targetApplicantType === TargetApplicantTypeEnum.SEGMENTS && (
        <div className={classes.segmentContainer}>
          {agentSegmentList &&
            agentSegmentList.map((item) => (
              <FormControlLabel
                key={`agent-segments-${item._id}`}
                disabled={isDisabled}
                style={{ margin: 0 }}
                control={
                  <Checkbox
                    checked={formState.eligibleSegments.includes(item._id)}
                    onChange={() => {
                      formDispatch({
                        type: 'SELECT_FIELD',
                        payload: { field: 'eligibleSegments', value: item._id },
                      });
                    }}
                  />
                }
                label={item.name[serverLocale]}
                labelPlacement="end"
              />
            ))}
        </div>
      )}
    </>
  );
};
