import { IntlShape } from 'react-intl';
import { ModuleHierachy } from '../../common/module/types';
import {
  SURVEY_TITLE,
  SURVEY_BASE_PATH,
  AdminSurveyPermissionType,
  SURVEY_MANAGEMENT_TITLE,
  SURVEY_MANAGEMENT_PATH,
  SURVEY_NAMESPACE,
} from './constants';
import { isPermitted } from 'src/app/common/utils';
import SurveyManagementPage from './pages/Management/SurveyManagementPage';

export const surveyModuleConfig = (permissions: string[], intl: IntlShape): ModuleHierachy => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return {
    title: Translation(SURVEY_TITLE),
    path: SURVEY_BASE_PATH,
    icon: '/media/svg/icons/Communication/Chat6.svg',
    enableRead: isPermitted(
      [
        AdminSurveyPermissionType.SURVEY_READ,
        AdminSurveyPermissionType.SURVEY_CREATE,
        AdminSurveyPermissionType.SURVEY_UPDATE,
      ],
      permissions,
    ),
    child: [
      {
        title: Translation(SURVEY_MANAGEMENT_TITLE),
        path: `${SURVEY_BASE_PATH}${SURVEY_MANAGEMENT_PATH}`,
        namespace: SURVEY_NAMESPACE,
        component: SurveyManagementPage,
        enableRead: isPermitted(
          [
            AdminSurveyPermissionType.SURVEY_READ,
            AdminSurveyPermissionType.SURVEY_CREATE,
            AdminSurveyPermissionType.SURVEY_UPDATE,
          ],
          permissions,
        ),
        enableCreate: isPermitted([AdminSurveyPermissionType.SURVEY_CREATE], permissions),
        enableUpdate: isPermitted([AdminSurveyPermissionType.SURVEY_UPDATE], permissions),
      },
    ],
  };
};
