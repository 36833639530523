import React, { FC, useState, useEffect, useMemo } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ApiErrorResponse } from 'src/app/common/network';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { LayoutSplashScreen } from 'src/app/layout';
import { ExclusionRuleFormMode, ExclusionRule } from 'src/app/modules/PulseLeads/types/exclusion-rule-types';
import { fetchExclusionRuleItem } from 'src/app/modules/PulseLeads/network/exclusionRuleCrud';
import ExclusionRuleForm from './components/ExclusionRuleForm';

const ExclusionRuleDetailPage: FC<RouteComponentProps> = ({ history, location }) => {
  const { id } = useParams<{ id: string }>();
  const { formMode } = useMemo(
    () => ({
      formMode: id ? ExclusionRuleFormMode.EDIT : ExclusionRuleFormMode.CREATE,
    }),
    [id],
  );

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [exclusionRule, setExclusionRule] = useState<ExclusionRule>();

  const reloadData = () => {
    if (id) {
      setIsLoading(true);
      fetchExclusionRuleItem(id, dispatch)
        .then((result) => {
          setExclusionRule(result);
        })
        .catch((err: ApiErrorResponse) => {
          history.push(NOT_FOUND_ERROR_PAGE_PATH);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line
  }, [id]);

  return (
    <>
      {formMode === ExclusionRuleFormMode.CREATE && <ExclusionRuleForm formMode={formMode} />}
      {formMode === ExclusionRuleFormMode.EDIT &&
        (isLoading || !exclusionRule ? (
          <LayoutSplashScreen />
        ) : (
          <ExclusionRuleForm formMode={formMode} exclusionRule={exclusionRule} />
        ))}
    </>
  );
};

export default ExclusionRuleDetailPage;
