import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  fieldContainer: {
    width: 470,
    boxSizing: 'border-box',
  },
  field: {
    fontSize: '0.9rem',
    marginRight: 5,
    fontWeight: 'bold',
    padding: '0px 0px 0px 10px',
  },
  sectionMargin: {
    marginBottom: 20,
  },
  divideMargin: {
    marginBottom: 10,
  },
  subHeader: {
    fontSize: '0.9rem',
    fontWeight: 'bold',
    padding: '0px 0px 0px 10px',
    color: '#979797',
  },
}));
