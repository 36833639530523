import React, { FC, useState, useMemo, useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { Select, FormControl, Button, CircularProgress } from '@mui/material';
import { useCommonStyles } from '../../../../common/styles/common-styles';
import { map } from 'lodash';
import { RolePermissionItem } from '../../types/permission-types';
import { getRolePermissions } from '../../network/permissionCrud';
import { useDataProvider } from 'src/app/common/utils';
import { RoleType } from '../../types/role-types';
import ModuleStaffSection from './components/ModuleStaffSection';
import { useIntl } from 'react-intl';
import { ModuleContext } from '../../../../common/module/ModuleProvider';
import { useDispatch } from 'react-redux';
import { rolePermissionPath } from '../PermissionRoutes';

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 15,
  },
  inputBox: {
    paddingTop: 6,
    paddingBottom: 8,
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '50px 0',
  },
}));

const PermissionControlPage: FC<RouteComponentProps> = ({ history, location, match }) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { classes: commonClasses } = useCommonStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const { moduleMap } = useContext(ModuleContext);

  const [expanded, setExpanded] = useState<string | undefined>(undefined);
  const [selectedModule, setSelectedModule] = useState<string>('');

  const [staffPermissionMap, setStaffPermissionMap] = useState<RolePermissionItem[]>();

  const { refreshData, isLoading } = useDataProvider<RolePermissionItem[]>(async () => {
    try {
      return await getRolePermissions({ roleType: RoleType.STAFF, filter: 'PF-PORTAL' }, dispatch);
    } catch (err) {}
  }, setStaffPermissionMap);

  const filteredModuleMap = useMemo(() => moduleMap.filter((item) => !item.disableRoleControl), [moduleMap]);

  return (
    <div className={classes.root}>
      <div className={classes.headerContainer}>
        <div className={commonClasses.header}>{Translation('roleControl.permission')}</div>
        <div className={classes.rowContainer}>
          <span>{Translation('roleControl.permission.filter')} :</span>
          <FormControl style={{ margin: '0 0 0 10px' }} margin="dense" variant="outlined">
            <Select
              native
              value={selectedModule}
              inputProps={{
                classes: {
                  root: classes.inputBox,
                },
              }}
              onChange={(e) => setSelectedModule(e.target.value as string)}
            >
              <option aria-label="None" value="" />
              {map(filteredModuleMap, (option) => (
                <option key={option.title} value={option.title}>
                  {option.title}
                </option>
              ))}
            </Select>
          </FormControl>
          <Button style={{ marginLeft: 20 }} variant="contained" color="inherit" onClick={() => setSelectedModule('')}>
            {Translation('app.button.reset')}
          </Button>
          <Button
            style={{ marginLeft: 20 }}
            variant="contained"
            color="secondary"
            onClick={() => history.push(`${rolePermissionPath}/create`)}
          >
            {Translation('roleControl.permission.newRole')}
          </Button>
        </div>
      </div>
      <div>
        {isLoading || !staffPermissionMap ? (
          isLoading ? (
            <div className={classes.loadingContainer}>
              <CircularProgress color="secondary" size={60} />
            </div>
          ) : (
            <div className={classes.loadingContainer}>{Translation('common.loading.refreshAgain')}</div>
          )
        ) : staffPermissionMap.length > 0 ? (
          //  Control * Permission (Do not commit below line)
          //staffPermissionMap.map((rolePermission, index) => (
          //  Control non * Permission
          staffPermissionMap
            .filter((item) => item.name !== '*')
            .map((rolePermission, index) => (
              <ModuleStaffSection
                key={`staff-permission-section-${index}`}
                expanded={expanded === rolePermission.name}
                rolePermission={rolePermission}
                selectedModule={selectedModule}
                moduleMap={filteredModuleMap}
                onToggle={(_, isExpanded) => setExpanded(isExpanded ? rolePermission.name : undefined)}
                onClose={() => setExpanded(undefined)}
                onSubmitted={() => {
                  refreshData();
                  setExpanded(undefined);
                }}
              />
            ))
        ) : (
          Translation('prutable.noRecord')
        )}
      </div>
    </div>
  );
};

export default PermissionControlPage;
