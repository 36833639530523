import { IntlShape } from 'react-intl';
import { ModuleHierachy } from '../../common/module/types';
import {
  AdminNewsPermissionType,
  NEWS_ARTICLE_NAMESPACE,
  NEWS_ARTICLE_PATH,
  NEWS_ARTICLE_TITLE,
  NEWS_BASE_PATH,
  NEWS_CATEGORIES_NAMESPACE,
  NEWS_CATEGORY_PATH,
  NEWS_CATEGORY_TITLE,
  NEWS_HEADLINE_NAMESPACE,
  NEWS_HEADLINE_PATH,
  NEWS_HEADLINE_TITLE,
  NEWS_TITLE,
} from './constants';
import { isPermitted } from 'src/app/common/utils';
import NewsCategoryPage from './pages/Category/NewsCategoryPage';
import NewsArticlePage from './pages/Article/NewsArticlePage';
import NewsHeadlinePage from './pages/Headline/NewsHeadlinePage';

export const newsModuleConfig = (permissions: string[], intl: IntlShape): ModuleHierachy => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return {
    title: Translation(NEWS_TITLE),
    path: NEWS_BASE_PATH,
    icon: '/media/svg/icons/Home/Book-open.svg',
    enableRead: isPermitted(
      [
        AdminNewsPermissionType.NEWS_CATEGORIES_READ,
        AdminNewsPermissionType.NEWS_CATEGORIES_CREATE,
        AdminNewsPermissionType.NEWS_CATEGORIES_UPDATE,
        AdminNewsPermissionType.NEWS_ARTICLE_READ,
        AdminNewsPermissionType.NEWS_ARTICLE_CREATE,
        AdminNewsPermissionType.NEWS_ARTICLE_UPDATE,
      ],
      permissions,
    ),
    child: [
      {
        title: Translation(NEWS_CATEGORY_TITLE),
        path: `${NEWS_BASE_PATH}${NEWS_CATEGORY_PATH}`,
        namespace: NEWS_CATEGORIES_NAMESPACE,
        component: NewsCategoryPage,
        enableRead: isPermitted(
          [
            AdminNewsPermissionType.NEWS_CATEGORIES_READ,
            AdminNewsPermissionType.NEWS_CATEGORIES_CREATE,
            AdminNewsPermissionType.NEWS_CATEGORIES_UPDATE,
          ],
          permissions,
        ),
        enableCreate: isPermitted([AdminNewsPermissionType.NEWS_CATEGORIES_CREATE], permissions),
        enableUpdate: isPermitted([AdminNewsPermissionType.NEWS_CATEGORIES_UPDATE], permissions),
      },
      {
        title: Translation(NEWS_ARTICLE_TITLE),
        path: `${NEWS_BASE_PATH}${NEWS_ARTICLE_PATH}`,
        namespace: NEWS_ARTICLE_NAMESPACE,
        component: NewsArticlePage,
        enableRead: isPermitted(
          [
            AdminNewsPermissionType.NEWS_ARTICLE_READ,
            AdminNewsPermissionType.NEWS_ARTICLE_CREATE,
            AdminNewsPermissionType.NEWS_ARTICLE_UPDATE,
            AdminNewsPermissionType.NEWS_HEADLINE_READ,
            AdminNewsPermissionType.NEWS_HEADLINE_CREATE,
            AdminNewsPermissionType.NEWS_HEADLINE_UPDATE,
          ],
          permissions,
        ),
        enableCreate: isPermitted([AdminNewsPermissionType.NEWS_ARTICLE_CREATE], permissions),
        enableUpdate: isPermitted([AdminNewsPermissionType.NEWS_ARTICLE_UPDATE], permissions),
        enableApprove: isPermitted([AdminNewsPermissionType.NEWS_ARTICLE_APPROVE], permissions),
        enableArchive: isPermitted([AdminNewsPermissionType.NEWS_ARTICLE_ARCHIVE], permissions),
        options: {
          enableApprove: true,
          enableArchive: true,
        },
      },
      {
        title: Translation(NEWS_HEADLINE_TITLE),
        path: `${NEWS_BASE_PATH}${NEWS_HEADLINE_PATH}`,
        namespace: NEWS_HEADLINE_NAMESPACE,
        component: NewsHeadlinePage,
        enableRead: isPermitted(
          [
            AdminNewsPermissionType.NEWS_HEADLINE_READ,
            AdminNewsPermissionType.NEWS_HEADLINE_CREATE,
            AdminNewsPermissionType.NEWS_HEADLINE_UPDATE,
          ],
          permissions,
        ),
        enableCreate: isPermitted([AdminNewsPermissionType.NEWS_HEADLINE_CREATE], permissions),
        enableUpdate: isPermitted([AdminNewsPermissionType.NEWS_HEADLINE_UPDATE], permissions),
      },
    ],
  };
};
