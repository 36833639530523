import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { makeStyles } from 'tss-react/mui';
import { Button, RadioGroup, FormControlLabel, Radio, Typography } from '@mui/material';

import Form from '../../../../../../common/components/Form';
import { CampaignTypeItem } from '../../../../types/staff-campaign-types';

const useStyles = makeStyles<void, 'checked'>()((theme, _params, classes) => ({
  footerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    padding: 20,
  },
  accordHeading: {
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '15%',
    flexShrink: 0,
  },
  radio: {
    [`&.${classes.checked}`]: {
      color: '#E8192C',
    },
  },
  checked: {},
  radioItem: {
    width: '100%',
    borderRadius: '12px',
    '&:hover': {
      backgroundColor: '#F4F4F4',
    },
  },
  formControlLabel: {
    fontFamily: 'Poppins, Helvetica, "sans-serif"',
  },
  btn: {
    color: 'white',
    width: '100%',
    // height: 46,
    borderRadius: 12,
    fontFamily: 'Poppins, Helvetica, "sans-serif"',
  },
}));

interface AddRecordProps {
  onSave: (formData: any) => void;
  onCancel: () => void;
  confirmLoading: boolean;
  initialValues?: CampaignTypeItem[];
}

const CampaignTypeSelectForm: React.FC<AddRecordProps> = ({
  onSave,
  onCancel,
  confirmLoading,
  initialValues,
}) => {
  const { classes } = useStyles();
  const [form] = Form.useForm();
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) =>
    intl.formatMessage({ id }, variable);
  const [selectedCampaignType, setSelectedCampaignType] = useState<string>();
  const campaignType = initialValues;
  const btnDisabled = !selectedCampaignType;

  return (
    <Form form={form} className={classes.container}>
      <Form.Item>
        <div style={{ width: '100%', maxHeight: '500px', overflow: 'auto' }}>
          <RadioGroup style={{ width: '100%' }}>
            {campaignType &&
              campaignType.map((campaignTypeItem) => {
                if (campaignTypeItem.status === 'active') {
                  return (
                    <div
                      className={classes.radioItem}
                      key={campaignTypeItem._id}
                    >
                      <FormControlLabel
                        key={campaignTypeItem._id}
                        value={campaignTypeItem._id}
                        control={
                          <Radio
                            onChange={() => {
                              setSelectedCampaignType(campaignTypeItem._id);
                            }}
                            classes={{
                              root: classes.radio,
                              checked: classes.checked,
                            }}
                          />
                        }
                        label={
                          <Typography className={classes.formControlLabel}>
                            {campaignTypeItem.name}
                          </Typography>
                        }
                        style={{ margin: 0 }}
                      />
                    </div>
                  );
                } else {
                  return null;
                }
              })}
          </RadioGroup>
        </div>
      </Form.Item>

      <div className={classes.footerContainer}>
        <>
          <Button
            variant="contained"
            color="secondary"
            size='large'
            disabled={btnDisabled}
            className={classes.btn}
            onClick={() => {
              onSave(selectedCampaignType);
            }}
          >
            {Translation('app.button.next')}
          </Button>
        </>
      </div>
    </Form>
  );
};

export default CampaignTypeSelectForm;
