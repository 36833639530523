import { IntlShape } from 'react-intl';
import { ModuleHierachy } from '../../common/module/types';
import {
  NOTIFICATION_TITLE,
  NOTIFICATION_BASE_PATH,
  AdminNotificationPermissionType,
  NOTIFICATION_MANUAL_TITLE,
  NOTIFICATION_MANUAL_PATH,
  NOTIFICATION_NAMESPACE,
  AUTO_NOTIFICATION_CONFIG_NAMESPACE,
  AUTO_NOTIFICATION_LOG_NAMESPACE,
  NOTIFICATION_AUTO_TITLE,
  NOTIFICATION_AUTO_PATH,
  NOTIFICATION_AUTO_LOG,
  NOTIFICATION_AUTO_LOG_PATH,
  NOTIFICATION_AUTO_CONFIG,
  NOTIFICATION_AUTO_CONFIG_PATH,
} from './constants';
import { isPermitted } from 'src/app/common/utils';
import NotificationPage from './pages/Manual/NotificationPage';
import AutoNotificationPage from './pages/Auto/NotificationPage';

export const notificationModuleConfig = (permissions: string[], intl: IntlShape): ModuleHierachy => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return {
    title: Translation(NOTIFICATION_TITLE),
    path: NOTIFICATION_BASE_PATH,
    icon: '/media/svg/icons/General/Notifications1.svg',
    enableRead: isPermitted(
      [
        AdminNotificationPermissionType.NOTIFICATION_READ,
        AdminNotificationPermissionType.NOTIFICATION_CREATE,
        AdminNotificationPermissionType.NOTIFICATION_UPDATE,
        AdminNotificationPermissionType.AUTO_NOTIFICATION_CONFIG_READ,
        AdminNotificationPermissionType.AUTO_NOTIFICATION_CONFIG_CREATE,
        AdminNotificationPermissionType.AUTO_NOTIFICATION_CONFIG_UPDATE,
        AdminNotificationPermissionType.AUTO_NOTIFICATION_LOG_READ,
        AdminNotificationPermissionType.AUTO_NOTIFICATION_LOG_CREATE,
        AdminNotificationPermissionType.AUTO_NOTIFICATION_LOG_UPDATE,
      ],
      permissions,
    ),
    child: [
      {
        title: Translation(NOTIFICATION_MANUAL_TITLE),
        path: `${NOTIFICATION_BASE_PATH}${NOTIFICATION_MANUAL_PATH}`,
        namespace: NOTIFICATION_NAMESPACE,
        component: NotificationPage,
        enableRead: isPermitted(
          [
            AdminNotificationPermissionType.NOTIFICATION_READ,
            AdminNotificationPermissionType.NOTIFICATION_CREATE,
            AdminNotificationPermissionType.NOTIFICATION_UPDATE,
          ],
          permissions,
        ),
        enableCreate: isPermitted([AdminNotificationPermissionType.NOTIFICATION_CREATE], permissions),
        enableUpdate: isPermitted([AdminNotificationPermissionType.NOTIFICATION_UPDATE], permissions),
      },
      {
        title: Translation(NOTIFICATION_AUTO_TITLE),
        path: `${NOTIFICATION_BASE_PATH}${NOTIFICATION_AUTO_PATH}`,
        enableRead: isPermitted(
          [
            AdminNotificationPermissionType.AUTO_NOTIFICATION_CONFIG_READ,
            AdminNotificationPermissionType.AUTO_NOTIFICATION_CONFIG_CREATE,
            AdminNotificationPermissionType.AUTO_NOTIFICATION_CONFIG_UPDATE,
            AdminNotificationPermissionType.AUTO_NOTIFICATION_LOG_READ,
            AdminNotificationPermissionType.AUTO_NOTIFICATION_LOG_CREATE,
            AdminNotificationPermissionType.AUTO_NOTIFICATION_LOG_UPDATE,
          ],
          permissions,
        ),
        child: [
          {
            title: Translation(NOTIFICATION_AUTO_CONFIG),
            path: `${NOTIFICATION_BASE_PATH}${NOTIFICATION_AUTO_PATH}${NOTIFICATION_AUTO_CONFIG_PATH}`,
            namespace: AUTO_NOTIFICATION_CONFIG_NAMESPACE,
            component: AutoNotificationPage,
            enableRead: isPermitted(
              [
                AdminNotificationPermissionType.AUTO_NOTIFICATION_CONFIG_READ,
                AdminNotificationPermissionType.AUTO_NOTIFICATION_CONFIG_CREATE,
                AdminNotificationPermissionType.AUTO_NOTIFICATION_CONFIG_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted([AdminNotificationPermissionType.AUTO_NOTIFICATION_CONFIG_CREATE], permissions),
            enableUpdate: isPermitted([AdminNotificationPermissionType.AUTO_NOTIFICATION_CONFIG_UPDATE], permissions),
          },
          {
            title: Translation(NOTIFICATION_AUTO_LOG),
            path: `${NOTIFICATION_BASE_PATH}${NOTIFICATION_AUTO_PATH}${NOTIFICATION_AUTO_LOG_PATH}`,
            namespace: AUTO_NOTIFICATION_LOG_NAMESPACE,
            component: AutoNotificationPage,
            enableRead: isPermitted(
              [
                AdminNotificationPermissionType.AUTO_NOTIFICATION_LOG_READ,
                AdminNotificationPermissionType.AUTO_NOTIFICATION_LOG_CREATE,
                AdminNotificationPermissionType.AUTO_NOTIFICATION_LOG_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted([AdminNotificationPermissionType.AUTO_NOTIFICATION_LOG_CREATE], permissions),
            enableUpdate: isPermitted([AdminNotificationPermissionType.AUTO_NOTIFICATION_LOG_UPDATE], permissions),
          },
        ],
      },
    ],
  };
};
