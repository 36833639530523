import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { REPORT_BASE_PATH, REPORT_NEWS_PATH, REPORT_NEWS_COMMENTS } from '../../../../constants';
import { ReportType } from '../../../../types/report-types';
import { useIntl } from 'react-intl';
import { createNewCommentsReport } from '../../../../network/reportCrud';
import CreateReport from '../../../../components/CreateReport';

const CommentsReportPage: FC<RouteComponentProps> = ({ history, location }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  
  return (
    <CreateReport
      title={Translation("report.news.comments.title")}
      reportType={ReportType.NEWS_COMMENTS}
      onCreate={createNewCommentsReport}
      onBack={() => history.push(`${REPORT_BASE_PATH}${REPORT_NEWS_PATH}${REPORT_NEWS_COMMENTS}`)}
      fields={[{
        name: "newsId",
        displayText: Translation("report.news.common.id"),
        type: "text",
        mandatory: true,
      }]}
    />
  )
};

export default CommentsReportPage;
