import { makeStyles } from 'tss-react/mui';
import { DOUBLE_FIELD_CONTAINER_WIDTH } from '../../../common';

const RADIO_GROUP_LABEL_WIDTH = 140;
const RADIO_LABEL_WIDTH = 240;
const TEMPLATE_REMARK_MARGIN = 27;
const LINK_REMARK_MARGIN = 272;
const TABLE_SELECT_FIELD_WIDTH = 180;

export const useRegistrationAttendanceStyles = makeStyles()(() => ({
  field: {
    width: DOUBLE_FIELD_CONTAINER_WIDTH,
    marginTop: 14,
  },
  bottomSpacing: {
    marginBottom: 10,
  },
  radioGroupLabel: {
    width: RADIO_GROUP_LABEL_WIDTH,
    color: '#3F4254',
  },
  label: {
    color: '#3F4254',
  },
  formControlLabel: {
    width: RADIO_LABEL_WIDTH,
    marginBottom: 0,
  },
  templateRemark: {
    maxWidth: DOUBLE_FIELD_CONTAINER_WIDTH - TEMPLATE_REMARK_MARGIN,
    marginLeft: TEMPLATE_REMARK_MARGIN,
  },
  linkRemark: {
    maxWidth: DOUBLE_FIELD_CONTAINER_WIDTH - LINK_REMARK_MARGIN,
    marginLeft: LINK_REMARK_MARGIN,
  },
  radioGroupMargin: {
    maxWidth: DOUBLE_FIELD_CONTAINER_WIDTH - RADIO_GROUP_LABEL_WIDTH,
    marginLeft: RADIO_GROUP_LABEL_WIDTH,
  },
  attendanceRuleLabel: {
    marginTop: 4,
    marginBottom: 10,
  },
  tableSelect: {
    width: TABLE_SELECT_FIELD_WIDTH,
  },
}));
