import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { getDefaultDisplayDate, getIsoString, getLocalDateFormat, ignoreTimezone } from 'src/app/common/utils';
import {
  InsightListItem,
  PaginateList,
} from 'src/app/modules/sales-kit/pages/insurance-summary/type/insurance-summary-types';
import { useDispatch } from 'react-redux';
import { downloadInsuracneSummaryResults } from '../../network/insuranceSummaryCrud';
import { CSVLink } from 'react-csv';

type InsightSummaryListProps = {
  isLoading: boolean;
  insightList?: PaginateList<InsightListItem>;
  onChangePage: (page: number, rowsPerPage: number) => void;
  onRefresh: () => void;
};

const InsightSummaryList: FC<InsightSummaryListProps> = ({ isLoading, insightList, onChangePage, onRefresh }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [csvData, setCsvData] = React.useState<string>();
  const csvInstance = React.useRef<any>(null);

  React.useEffect(() => {
    if (csvData && csvInstance && csvInstance.current && csvInstance.current.link) {
      setTimeout(() => {
        csvInstance.current.link.click();
        setCsvData(undefined);
      });
    }
  }, [csvData]);
  const onExport = async () => {
    try {
      const result = await downloadInsuracneSummaryResults(dispatch);

      setCsvData(result);
    } catch (err) {}
  };

  return (
    <>
      <PruTable
        disableBulkSelect
        dataSource={insightList?.data}
        isLoading={isLoading}
        onRefresh={onRefresh}
        totalPages={insightList?.totalPages}
        totalRecords={insightList?.totalDocs}
        onChangePage={onChangePage}
        title={Translation('protection.review.insightList')}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('protection.review.downloadCsv'),
            onClick: async () => await onExport(),
          },
        ]}
        operationDef={[]}
        columnDef={[
          {
            isId: true,
            hidden: true,
            keyIndex: 'id',
            displayName: '',
            renderData: () => '',
          },
          {
            keyIndex: 'agentName',
            displayName: Translation('protection.review.insight.agentName'),
            renderData: (row) => row.agentName,
          },
          {
            keyIndex: 'agentNumber',
            align: 'center',
            displayName: Translation('protection.review.insight.agentNumber'),
            renderData: (row) => row.agentCode,
          },
          {
            keyIndex: 'prospect',
            align: 'center',
            displayName: Translation('protection.review.insight.prospect'),
            renderData: (row) => row.prospectName,
          },
          {
            keyIndex: 'status',
            align: 'center',
            displayName: Translation('protection.review.insight.status'),

            renderData: (row) => row.statusName,
          },
          {
            keyIndex: 'lastUpdate',
            align: 'center',
            displayName: Translation('protection.review.insight.lastUpdate'),
            renderData: (row) => getLocalDateFormat(row.updatedAt, 'DD/MM/YYYY HH:mm'),
          },
        ]}
      />
      {csvData && (
        <CSVLink ref={csvInstance} data={csvData} filename={'insurance-summary-results.csv'} target="_blank" />
      )}
    </>
  );
};

export default InsightSummaryList;
