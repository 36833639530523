import { FC } from 'react';
import { RouteComponentProps, Switch, Redirect } from 'react-router-dom';
import PruRoute from 'src/app/common/components/PruRoute';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { COMPANYPICK_PATH, SALESKIT_BASE_PATH } from '../../constants';
import ListPage from './page/list/list';
import DetailPage from './page/detail/detail';

const CompanyPickRoute: FC<RouteComponentProps> = ({ history }) => {
  return (
    <Switch>
      <Redirect
        exact
        from={`${SALESKIT_BASE_PATH}${COMPANYPICK_PATH}`}
        to={`${SALESKIT_BASE_PATH}${COMPANYPICK_PATH}/list`}
      />
      <PruRoute exact path={`${SALESKIT_BASE_PATH}${COMPANYPICK_PATH}/list`} component={ListPage} />
      <PruRoute exact path={`${SALESKIT_BASE_PATH}${COMPANYPICK_PATH}/edit/:id`} component={DetailPage} />
      <PruRoute exact path={`${SALESKIT_BASE_PATH}${COMPANYPICK_PATH}/create`} component={DetailPage} />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default CompanyPickRoute;
