import { makeStyles } from 'tss-react/mui';

export const useSelectStyles = makeStyles()(() => ({
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  rowSpacing: {
    marginBottom: 16,
  },
  fieldContainer: {
    minWidth: 100,
    boxSizing: 'border-box',
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  noMargin: {
    margin: 0,
  },
  select: {
    minWidth: 200,
  },
  popupWrapper: {
    position: 'relative',
    display: 'inline-block',
  },
  cardPos: {
    position: 'absolute',
    left: '100%',
    transform: 'translate(5px, -50%)',
    zIndex: 1,
  },
  option: {
    width: '20%',
  },
  chooseFileSpacing: {
    marginLeft: 10,
  },
  segmentContainer: {
    marginLeft: -9,
  },
}));
