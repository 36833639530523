import { FC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Button, Checkbox, FormControlLabel, Link } from '@mui/material';
import { Close } from '@mui/icons-material';
import { AttachmentDef } from 'src/app/common/types';
import { downloadAgentListTemplate } from 'src/app/common/network';
import { downloadAs } from 'src/app/common/utils';
import AsyncCsvLink from 'src/app/common/components/AsyncCsvLink';
import { ENABLE_DESIGNATION, ENABLE_ZONE } from '../../constants';
import { PresetAgentGroupProps } from '../../types';
import { RadioModeFormState } from '../form-reducer';
import { useRadioStyles } from '../target-applicant-radio-mode.style';
import { useStyles } from './preset-agent-group.style';

export const PresetAgentGroup: FC<PresetAgentGroupProps> = ({
  isDisabled,
  designationList,
  zoneList,
  formState,
  errorState,
  errorFieldPrefix,
  formDispatch,
  onDismissErrorHandler,
  handleFile,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { classes } = useStyles();
  const { classes: radioClasses } = useRadioStyles();

  const renderDownloadLink = (fileField: keyof RadioModeFormState, text: string) => {
    const { url, filename } = formState[fileField] as AttachmentDef;

    return (
      <div className={classes.rowContainer}>
        <span className={classes.linkMargin}>{Translation('component.template.rule-apply')}</span>
        <span className={classes.linkMargin}>
          {url ? (
            <Link color="secondary" onClick={() => downloadAs(url, filename)}>
              {filename}
            </Link>
          ) : (
            filename
          )}
        </span>
        <span className={classes.linkMargin}>{text}</span>
        {!isDisabled && (
          <Close
            onClick={() =>
              formDispatch({
                type: 'MODIFY_FIELD',
                payload: { field: fileField, value: undefined },
              })
            }
          />
        )}
      </div>
    );
  };

  return (
    <>
      {ENABLE_DESIGNATION && (
        <div className={classes.presetGroupContainer}>
          <div>
            {Translation('targetApplicantSection.common.designation')}
            <span className={radioClasses.mandatory}>*</span>:
          </div>
          <div className={classes.optionsContainer}>
            {designationList.map((designation) => (
              <div key={`target-applicant-designation-${designation.code}`} className={classes.option}>
                <FormControlLabel
                  disabled={isDisabled}
                  style={{ margin: 0 }}
                  control={
                    <Checkbox
                      checked={formState.eligibleDesignations.includes(designation.code)}
                      onChange={() => {
                        onDismissErrorHandler(`${errorFieldPrefix}eligibleDesignations`, true);
                        formDispatch({
                          type: 'SELECT_FIELD',
                          payload: {
                            field: 'eligibleDesignations',
                            value: designation.code,
                          },
                        });
                      }}
                    />
                  }
                  label={designation.shortName || designation.code}
                  labelPlacement="end"
                />
              </div>
            ))}
          </div>
          {errorState.mandatory[`${errorFieldPrefix}eligibleDesignations`] && (
            <div className={classes.errorText}>{Translation('designation.select.mandatroy.text')}</div>
          )}
        </div>
      )}
      {ENABLE_ZONE && (
        <div className={classes.presetGroupContainer}>
          <div>
            {Translation('zones.text')}
            <span className={radioClasses.mandatory}>*</span>:
          </div>
          <div className={classes.optionsContainer}>
            {zoneList.map((zone) => (
              <div key={`target-applicant-zone-${zone.code}`} className={classes.option}>
                <FormControlLabel
                  disabled={isDisabled}
                  style={{ margin: 0 }}
                  control={
                    <Checkbox
                      checked={formState.eligibleZones.includes(zone.code)}
                      onChange={() => {
                        onDismissErrorHandler(`${errorFieldPrefix}eligibleZones`, true);
                        formDispatch({
                          type: 'SELECT_FIELD',
                          payload: {
                            field: 'eligibleZones',
                            value: zone.code,
                          },
                        });
                      }}
                    />
                  }
                  label={zone.code}
                  labelPlacement="end"
                />
              </div>
            ))}
          </div>
          {errorState.mandatory[`${errorFieldPrefix}eligibleZones`] && (
            <div className={classes.errorText}>{Translation('zone.select.mandatroy.text')}</div>
          )}
        </div>
      )}
      <div className={radioClasses.rightSideMargin}>
        <div className={`${radioClasses.fieldContainer} ${classes.rowContainer}`}>
          <div className={classes.extraLabel}>{Translation('targetApplicantSection.common.extra_include')}:</div>
          {formState.eligibleAgentListFile ? (
            renderDownloadLink('eligibleAgentListFile', 'included')
          ) : (
            <>
              <div className={classes.extraButton}>
                <input
                  id="upload-eligible-agent-list"
                  hidden
                  type="file"
                  accept=".csv"
                  onClick={(e) => {
                    const element = e.target as HTMLInputElement;
                    element.value = '';
                  }}
                  onChange={(e) => handleFile(e, 'eligibleAgentListFile', 'eligibleAgents')}
                />
                <Button
                  disabled={isDisabled}
                  variant="contained"
                  color="secondary"
                  onClick={() => document.getElementById('upload-eligible-agent-list')?.click()}
                >
                  {Translation('app.button.upload')}
                </Button>
              </div>
              <div>{Translation('component.template.includeTips')}</div>
            </>
          )}
        </div>
        <div className={`${radioClasses.fieldContainer} ${classes.rowContainer}`}>
          <div className={classes.extraLabel}>{Translation('targetApplicantSection.common.extra_exclude')}:</div>
          {formState.excludedAgentListFile ? (
            renderDownloadLink('excludedAgentListFile', 'excluded')
          ) : (
            <>
              <div className={classes.extraButton}>
                <input
                  id="upload-excluded-agent-list"
                  hidden
                  type="file"
                  accept=".csv"
                  onClick={(e) => {
                    const element = e.target as HTMLInputElement;
                    element.value = '';
                  }}
                  onChange={(e) => handleFile(e, 'excludedAgentListFile', 'excludedAgents')}
                />
                <Button
                  disabled={isDisabled}
                  variant="contained"
                  color="secondary"
                  onClick={() => document.getElementById('upload-excluded-agent-list')?.click()}
                >
                  {Translation('app.button.upload')}
                </Button>
              </div>
              <div>{Translation('component.template.excludeTips')}</div>
            </>
          )}
        </div>
        <div>
          <AsyncCsvLink
            isDisabled={false}
            filename={'agent-list-template.csv'}
            dataParser={(str) => str}
            asyncCall={() => downloadAgentListTemplate(dispatch)}
          >
            <Button color="secondary">{Translation('download_template_button')}</Button>
          </AsyncCsvLink>
        </div>
      </div>
    </>
  );
};
