import { FC, useState, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Button, Dialog, DialogTitle, DialogActions } from '@mui/material';
import { downloadAgentListTemplate } from 'src/app/common/network';
import AsyncCsvLink from 'src/app/common/components/AsyncCsvLink';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { UploadAgentListProps, DeleteAgentDialogState, AgentCodeItem } from '../../types';
import { useStyles } from './upload-agent-list.style';

const initialDialogState: DeleteAgentDialogState = {
  open: false,
  agentList: [],
};

export const UploadAgentList: FC<UploadAgentListProps> = ({ isDisabled, formState, formDispatch, handleFile }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { classes } = useStyles();

  const agentCodeList: AgentCodeItem[] = useMemo(
    () => formState.eligibleAgents?.map((agentCode) => ({ agentCode })) || [],
    [formState.eligibleAgents],
  );
  const [dialogState, setDialogState] = useState<DeleteAgentDialogState>(initialDialogState);

  const handleDeleteAgent = () => {
    formDispatch({
      type: 'MODIFY_FIELD',
      payload: {
        field: 'eligibleAgents',
        value: formState.eligibleAgents.filter((agentCode) => !dialogState.agentList.includes(agentCode)),
      },
    });
    if (dialogState.onResetRowSelected) {
      dialogState.onResetRowSelected();
    }
    setDialogState(initialDialogState);
  };

  return (
    <>
      {dialogState.open && (
        <Dialog open={dialogState.open} classes={{ paper: classes.deleteAgentDialogPaper }}>
          <DialogTitle>{Translation('targetApplicantSection.common.delete_agent')}</DialogTitle>
          <DialogActions className={classes.deleteAgentDialogActions}>
            <Button variant="outlined" color="inherit" onClick={() => setDialogState({ ...dialogState, open: false })}>
              {Translation('app.button.cancel')}
            </Button>
            <Button variant="contained" color="secondary" onClick={() => handleDeleteAgent()}>
              {Translation('app.button.confirm')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <div className={classes.agentListButtonContainer}>
        <input
          id="upload-agent-list"
          hidden
          type="file"
          accept=".csv"
          onClick={(e) => {
            const element = e.target as HTMLInputElement;
            element.value = '';
          }}
          onChange={(e) => handleFile(e, 'eligibleAgentListFile', 'eligibleAgents')}
        />
        <Button
          disabled={isDisabled}
          variant="contained"
          color="secondary"
          onClick={() => document.getElementById('upload-agent-list')?.click()}
        >
          {Translation('app.button.upload')}
        </Button>
        {agentCodeList.length > 0 && (
          <Button
            disabled={isDisabled}
            variant="contained"
            color="secondary"
            onClick={() => {
              if (dialogState.agentList.length > 0) {
                setDialogState({ ...dialogState, open: true });
              }
            }}
          >
            {Translation('app.button.delete')}
          </Button>
        )}
        <AsyncCsvLink
          isDisabled={false}
          filename={'agent-list-template.csv'}
          dataParser={(str) => str}
          asyncCall={() => downloadAgentListTemplate(dispatch)}
        >
          <Button color="secondary">{Translation('download_template_button')}</Button>
        </AsyncCsvLink>
      </div>
      {agentCodeList.length > 0 && (
        <div className={classes.agentListTableContainer}>
          <PruTable
            disablePagination
            disableToolbar
            disableBulkSelect={isDisabled}
            indeterminateSelectAll={false}
            headerBtnDef={[]}
            operationDef={[]}
            columnHeaderStyle={{ color: 'black', backgroundColor: '#FAFAFA' }}
            columnDef={[
              {
                isId: true,
                keyIndex: 'agentCode',
                displayName: Translation('targetApplicantSection.common.agent_code'),
                renderData: (row) => row.agentCode,
              },
              {
                keyIndex: 'delete',
                align: 'center',
                displayName: Translation('component.formLabel.action'),
                renderData: (row) => (
                  <Button
                    disabled={isDisabled}
                    color="secondary"
                    onClick={() => setDialogState({ ...dialogState, open: true, agentList: [row.agentCode] })}
                  >
                    {Translation('section.common.operation.delete')}
                  </Button>
                ),
              },
            ]}
            isLoading={false}
            dataSource={agentCodeList}
            currentSelectedRow={(rowData, _, onResetRowSelected) => {
              setDialogState({
                ...dialogState,
                agentList: rowData.map((item: AgentCodeItem) => item.agentCode),
                onResetRowSelected,
              });
            }}
          />
        </div>
      )}
    </>
  );
};
