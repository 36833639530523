import React, { FC, useState, useEffect, useImperativeHandle } from 'react';
import { FormControlLabel, Checkbox, FormGroup, FormHelperText } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { map } from 'lodash';
import { useUpdateEffect } from '../utils';
import Form from './Form';
import { useIntl } from 'react-intl';
import CheckGroup from './CheckGroup';
import { fetchDesignationAndZone, TargetApplicantGroup } from '../network';

type TargetApplicantSectionProps = {
  isDisabled: boolean;
  eligibleDesignations: string[];
  eligibleZones: string[];
  eligibleAgents?: string[];
  excludedAgents?: string[];
  onToggleDesignation?: (value: any) => void;
  onToggleZones?: (value: any) => void;
  isDesignationsValid?: boolean;
  isZonesValid?: boolean;
  onRef?: any;
  historyAgent?: any;
  isResoure?: boolean;
  isNotificatoin?: boolean;
  isNew?: boolean;
  enableDesignation?: boolean;
  enableZones?: boolean;
  designationsName?: any;
  zonesName?: any;
};

const useStyles = makeStyles()(() => ({
  rowContainer: {
    // display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  rowContainerWithBorder: {
    border: '1px solid rgb(221, 221, 221)',
    padding: '10px',
    paddingBottom: 0,
    width: 700,
  },
  fieldContainer: {
    minWidth: 100,
    boxSizing: 'border-box',
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  popupWrapper: {
    position: 'relative',
    display: 'inline-block',
    marginLeft: '60px',
  },
  cardPos: {
    position: 'absolute',
    left: '100%',
    transform: 'translate(5px, -50%)',
    zIndex: 1,
  },
  mandatory: {
    color: 'red',
  },
}));

const TargetApplicationSection: FC<TargetApplicantSectionProps> = ({
  isDisabled,
  eligibleDesignations,
  eligibleZones,
  eligibleAgents,
  excludedAgents,
  onToggleDesignation,
  onToggleZones,
  onRef,
  historyAgent,
  isResoure,
  isNotificatoin,
  isNew,
  enableDesignation = true,
  enableZones = true,
  designationsName = 'designations',
  zonesName = 'zones',
}) => {
  const ENABLE_DESIGNATION = window.envConfig['ENABLE_DESIGNATION'];
  const ENABLE_ZONE = window.envConfig['ENABLE_ZONE'];
  const isEnableZones = ENABLE_ZONE === 'true' && enableZones;
  const isEnableDesignations = ENABLE_DESIGNATION === 'true' && enableDesignation;

  useImperativeHandle(onRef, () => ({
    validate: () => {
      const zones = currentData.zones;
      const eligibleDesignations = currentData.eligibleDesignations;
      const isZoneValid = isEnableZones ? !(zones && zones.length === 0) : true;
      const isDesignationValid = isEnableDesignations
        ? !(eligibleDesignations && eligibleDesignations.length === 0)
        : true;
      setIsZonesValid(isZoneValid);
      setIsDesignationsValid(isDesignationValid);
    },
    allSelected: () => {
      return allSelected;
    },
  }));

  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  // eslint-disable-next-line
  const DEFAULT_REQUIRED_RULES = { required: true, message: Translation('component.form-required-text') };

  const [targetApplicantGroup, setTargetApplicantGroup] = useState<TargetApplicantGroup | undefined>(undefined);
  const [designations, setDesignations] = useState<any>({});
  const [designationsValues, setDesignationsValues] = useState<any>([]);
  // const [DesignationValue, setDesignationValue] = useState<any>([]);
  const [zones, setZones] = useState<any>([]);

  const [currentData, setCurrentData] = useState<any>({});
  const [isZoneSelectAll, setIsZoneSelectAll] = useState<any>(false);

  const [isDesignationsValid, setIsDesignationsValid] = useState<boolean>(true);
  const [isZonesValid, setIsZonesValid] = useState<boolean>(true);
  const [zonesAllSelected, setZonesAllSelected] = useState<boolean>(false);
  const [designationsAllSelected, setDesignationsAllSelected] = useState<boolean>(false);
  const [allSelected, setAllSelected] = useState<boolean>(false);

  useEffect(() => {
    getConfigData();
    designationSelect(eligibleDesignations);
    zonesSelect(eligibleZones);
    // eslint-disable-next-line
  }, []);

  useUpdateEffect(() => {
    if (isNew === false && (eligibleDesignations?.length || 0) !== 0) {
      designationSelect(eligibleDesignations);
    }
  }, [eligibleDesignations]);

  const handleHistory = (data: any) => {
    if (isNew === false && !isNotificatoin) {
      return;
    }

    const noData = eligibleZones.length === 0 && eligibleDesignations.length === 0;
    const notificationHistoryAgent = isNotificatoin && historyAgent === 'ALL';

    if (notificationHistoryAgent && !noData) {
      checkedOptions(data);
    }

    if (noData) {
      setTargetApplicantGroup(TargetApplicantGroup.ALL_AGENT_INCLUDE_LA);
      toggleAllZoneCheckbox(true);
      checkedOptions(data);
    }
  };

  const checkedOptions = (data: any) => {
    const tempData: any = currentData;
    tempData.eligibleDesignations = data.designation;
    tempData.zones = data.zone;

    // setTargetApplicantGroup(TargetApplicantGroup.ALL_AGENT_INCLUDE_LA);
    let newDesignations = formatDesignations(TargetApplicantGroup.ALL_AGENT_INCLUDE_LA, data.designation);
    designationSelect(newDesignations.map((designation: any) => designation.code));

    setCurrentData({ ...tempData });
  };

  const getConfigData = async () => {
    const result = await fetchDesignationAndZone().catch((err) => {
      setDesignations([]);
      setZones([]);
    });
    if (result) {
      setDesignations(result.designation);
      setDesignationsValues(Object.keys(result.designationValue).map((k) => result.designationValue[k]?.code));
      // setDesignationValue(result.designationValue);
      setZones(result.zone);
      handleHistory(result);
    }
  };

  const designationSelect = (values: any) => {
    const tempData: any = currentData;
    tempData.eligibleDesignations = values;

    checkAll(tempData);
    setCurrentData({ ...tempData });
    onToggleDesignation && onToggleDesignation(values);
  };

  const zonesSelect = (values: any) => {
    const tempData: any = currentData;
    tempData.zones = values;

    checkAll(tempData);
    setCurrentData({ ...tempData });
    onToggleZones && onToggleZones(values);
  };
  const onChangeZones = (values: any) => {
    toggleAllZoneCheckbox(false);
    zonesSelect(values);
  };
  const onChangeDesignations = (values: any) => {
    if (targetApplicantGroup) {
      setTargetApplicantGroup(undefined);
    }
    designationSelect(values);
  };
  const checkAll = (data: any) => {
    setDesignationsAllSelected(
      designationsValues?.every((cd: any) => data.eligibleDesignations?.some((sd: any) => sd === cd)),
    );
    setZonesAllSelected(zones?.every((cz: any) => data.zones?.some((sz: any) => sz === cz)));
  };
  useEffect(() => {
    setAllSelected(zonesAllSelected && designationsAllSelected);
  }, [zonesAllSelected, designationsAllSelected]);

  const toggleDesinationGroupCheckbox = (selectedOption: TargetApplicantGroup | undefined) => {
    setTargetApplicantGroup(selectedOption);
    let newDesignations = formatDesignations(selectedOption);
    designationSelect(newDesignations.map((designation: any) => designation.code));
  };

  const formatDesignations = (selectedOption: TargetApplicantGroup | undefined, source?: any) => {
    const data = source ? source : designations;
    let newDesignations: any = [];
    if (selectedOption === TargetApplicantGroup.RM_AND_UP) {
      newDesignations = newDesignations.concat(data[TargetApplicantGroup.RM_AND_UP] ?? []);
      newDesignations.map((designation: any) => designation.code);
    } else if (selectedOption === TargetApplicantGroup.ALL_AGENT_EXCLUDE_LA) {
      newDesignations = newDesignations.concat(data[TargetApplicantGroup.RM_AND_UP] ?? []);
      newDesignations = newDesignations.concat(data[TargetApplicantGroup.ALL_AGENT_EXCLUDE_LA] ?? []);
      newDesignations.map((designation: any) => designation.code);
    } else if (selectedOption === TargetApplicantGroup.ALL_AGENT_INCLUDE_LA) {
      newDesignations = newDesignations.concat(data[TargetApplicantGroup.RM_AND_UP] ?? []);
      newDesignations = newDesignations.concat(data[TargetApplicantGroup.ALL_AGENT_EXCLUDE_LA] ?? []);
      newDesignations = newDesignations.concat(data[TargetApplicantGroup.ALL_AGENT_INCLUDE_LA] ?? []);
      newDesignations.map((designation: any) => designation.code);
    }

    return newDesignations;
  };

  const toggleAllZoneCheckbox = (isSelected: boolean) => {
    setIsZoneSelectAll(isSelected);

    if (isSelected) {
      zonesSelect(zones);
    } else {
      zonesSelect([]);
    }
  };

  useUpdateEffect(() => {
    onToggleDesignation && onToggleDesignation(currentData.eligibleDesignations);
    onToggleZones && onToggleZones(currentData.zones);
  }, [currentData, targetApplicantGroup, isZoneSelectAll, historyAgent]);

  useUpdateEffect(() => {
    getConfigData();
    // handleHistory(currentData)
  }, [historyAgent]);

  return (
    <div style={{ width: '100%' }}>
      <div>
        {isEnableDesignations && (
          <div className={`${classes.rowContainer} ${classes.rowContainerWithBorder}`}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>
                {Translation('designation.text')}
                <span className={classes.mandatory}>*</span>:{' '}
              </span>
            </div>
            <div className={classes.popupWrapper}>
              <div style={{ borderBottom: '1px solid #DDDDDD' }} className={classes.rowContainer}>
                {map(TargetApplicantGroup, (option) => (
                  <FormControlLabel
                    key={`target-applicant-group-${option}`}
                    control={
                      <Checkbox
                        disabled={isDisabled}
                        checked={targetApplicantGroup === option}
                        // value={'designation'}
                        onChange={(e) => {
                          if (e.target.checked) {
                            toggleDesinationGroupCheckbox(option);
                          }
                        }}
                      />
                    }
                    label={option}
                    labelPlacement="end"
                  />
                ))}
              </div>

              <Form.Item
                name={designationsName}
                rules={[
                  {
                    validator(_: any, values: any) {
                      if (values.length === 0) {
                        return Promise.reject(new Error(Translation('designation.select.mandatroy.text')));
                      }

                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <FormGroup>
                  <div>
                    {map(designations, (section, index) => {
                      return (
                        <CheckGroup
                          disabled={isDisabled}
                          options={section.map((item: any) => {
                            return { label: item.shortName, value: item.code };
                          })}
                          value={currentData.eligibleDesignations}
                          key={`section_${index}`}
                          onChange={onChangeDesignations}
                        />
                      );
                    })}
                    {!isDesignationsValid ? (
                      <FormHelperText style={{ marginLeft: 14, color: '#f018a6' }}>
                        {Translation('designation.select.mandatroy.text')}
                      </FormHelperText>
                    ) : null}
                  </div>
                </FormGroup>
              </Form.Item>
            </div>
          </div>
        )}

        {isEnableZones && (
          <div className={`${classes.rowContainer} ${classes.rowContainerWithBorder}`} style={{ marginTop: 10 }}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>
                {Translation('zones.text')}
                <span className={classes.mandatory}>*</span>:{' '}
              </span>
            </div>
            <div className={classes.popupWrapper}>
              <div style={{ borderBottom: '1px solid #DDDDDD' }} className={classes.rowContainer}>
                <FormControlLabel
                  key={`target-applicant-group-zone`}
                  control={
                    <Checkbox
                      disabled={isDisabled}
                      checked={isZoneSelectAll}
                      value={''}
                      onChange={(e) => {
                        // if (e.target.checked) {
                        toggleAllZoneCheckbox(e.target.checked);
                        // }
                      }}
                    />
                  }
                  label={'All Zones'}
                  labelPlacement="end"
                />
              </div>

              <Form.Item
                name={zonesName}
                rules={[
                  {
                    validator(_: any, values: any) {
                      if (values.length === 0) {
                        return Promise.reject(new Error(Translation('zone.select.mandatroy.text')));
                      }

                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <FormGroup>
                  <div>
                    <CheckGroup
                      disabled={isDisabled}
                      options={zones.map((item: any) => {
                        return { label: item, value: item };
                      })}
                      value={currentData.zones}
                      onChange={onChangeZones}
                    />
                  </div>
                  {!isZonesValid ? (
                    <FormHelperText style={{ marginLeft: 14, color: '#f018a6' }}>
                      {Translation('zone.select.mandatroy.text')}
                    </FormHelperText>
                  ) : null}
                </FormGroup>
              </Form.Item>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TargetApplicationSection;
