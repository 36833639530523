import { Dispatch } from 'react';
import { forEach } from 'lodash';
import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import { PaginateList } from 'src/app/common/types/common-types';
import { StagingLeadItem } from '../types/straight-through-types';

const stagingLeadEndPoint = 'straight-through';

export type StagingLeadListParam = {
  campaignId: string;
  leadSource: string;
  micrositeId: string;
  leadDateFrom: string;
  leadDateTo: string;
  page: number;
  limit: number;
};

export type BatchSelectedLeadRawBody = {
  idList: string[];
};

export const fetchStagingLeadList = async (params: StagingLeadListParam, dispatch?: Dispatch<any>) => {
  let queryPath = `${ADMIN_URL}/${stagingLeadEndPoint}?`;
  forEach(params, (param, key) => {
    if (key === 'page') {
      queryPath += `${key}=${encodeURIComponent(param as any)}&`;
    } else {
      queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
    }
  });
  return apiClient
    .get<PaginateList<StagingLeadItem>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const distributeSelectedLeadRaw = async (body: BatchSelectedLeadRawBody, dispatch?: Dispatch<any>) => {
  return apiClient
    .patch<void>(`${ADMIN_URL}/${stagingLeadEndPoint}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const exportSelectedLeadRaw = async (body: BatchSelectedLeadRawBody, dispatch?: Dispatch<any>) => {
  return apiClient
    .post<string>(`${ADMIN_URL}/${stagingLeadEndPoint}/export-csv`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
