import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { makeStyles } from 'tss-react/mui';
import { Button, TextField } from '@mui/material';
import { RemoveCircleOutline } from '@mui/icons-material';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import { createBlob, getBlob } from 'src/app/common/network';
import { fileUpload } from 'src/app/common/utils';
import { MANDATORY_FIELD_ERROR_TEXT } from 'src/app/common/constants';
import { useStyles } from 'src/app/modules/AgencyCampaign/pages/CampaignType/Form/CampaignTypeDetailPage';
import { SectionFieldItem, FieldValueItem } from 'src/app/modules/AgencyCampaign/types/campaign-type-types';

type UploadFileFormProps = {
  field: SectionFieldItem;
  errorState: boolean[];
  setField: (field: SectionFieldItem) => void;
  setErrorState: (errorState: boolean[]) => void;
};

const useFormStyles = makeStyles()((theme) => ({
  rowContainer: {
    marginBottom: 10,
  },
  removeBtnContainer: {
    paddingRight: 10,
  },
  removeBtn: {
    borderRadius: '50%',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#e0e0e0',
    },
  },
}));

const UploadFileForm: FC<UploadFileFormProps> = ({ field, errorState, setField, setErrorState }) => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const { classes: formClasses } = useFormStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const fieldValues = [...(field.value as FieldValueItem[])];

  const removeFile = (index: number) => {
    fieldValues.splice(index, 1);
    const newField = { ...field };
    newField.value = fieldValues;
    setField(newField);

    const newErrorState = [...errorState];
    newErrorState.splice(index, 1);
    setErrorState(newErrorState);
  };

  const addFile = (filename: string, blobId: string) => {
    fieldValues.push({ name: filename, value: blobId });
    const newField = { ...field };
    newField.value = fieldValues;
    setField(newField);
    setErrorState([...errorState, false]);
  };

  const handleChangeFilename = (index: number, newName: string) => {
    fieldValues[index].name = newName;
    const newField = { ...field };
    newField.value = fieldValues;
    setField(newField);

    const newErrorState = [...errorState];
    newErrorState[index] = newName === '';
    setErrorState(newErrorState);
  };

  const handleFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const createBlobRes = await createBlob(
        { mimeType: file.type, accessLevel: 'public', originalFilename: file.name, module: 'agencyCampaign' },
        dispatch,
      );
      await fileUpload(createBlobRes.url, file);
      const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);
      const result = blobDetail[0];
      if (result) {
        addFile(file.name, result.blobId);
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: 'Success',
              content: `Upload file successfully - ${file.name}`,
            },
          ]),
        );
      }
    }
  };

  return (
    <div className={classes.sectionMargin}>
      <div className={formClasses.rowContainer}>
        {fieldValues.map((fieldValue, index) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className={formClasses.removeBtnContainer}>
              <RemoveCircleOutline className={formClasses.removeBtn} onClick={() => removeFile(index)} />
            </div>
            <div>
              <TextField
                style={{ width: 200, margin: '4px 0' }}
                margin="dense"
                variant="outlined"
                error={fieldValue.name === ''}
                helperText={fieldValue.name === '' && MANDATORY_FIELD_ERROR_TEXT}
                value={fieldValue.name}
                onChange={(e) => {
                  handleChangeFilename(index, e.target.value);
                }}
              />
            </div>
          </div>
        ))}
      </div>
      <div>
        <input
          id="upload-file"
          hidden
          type="file"
          accept=".pdf"
          onClick={(e) => {
            const element = e.target as HTMLInputElement;
            element.value = '';
          }}
          onChange={(e) => handleFile(e)}
        />
        <Button variant="contained" color="secondary" onClick={() => document.getElementById('upload-file')!.click()}>
          {Translation('app.button.upload')}
        </Button>
      </div>
      {fieldValues.length === 0 && (
        <div className={classes.errorText} style={{ paddingTop: 10 }}>
          {MANDATORY_FIELD_ERROR_TEXT}
        </div>
      )}
    </div>
  );
};

export default UploadFileForm;
