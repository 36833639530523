import React, { FC, useState, useEffect } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import { makeStyles } from 'tss-react/mui';
import { Button, Divider } from '@mui/material';
import { ApiErrorResponse } from 'src/app/common/network';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { CampaignLeadItem } from 'src/app/modules/PulseLeads/types/campaign-types';
import { fetchLeadItem } from 'src/app/modules/PulseLeads/network/campaignCrud';
import { getDefaultDisplayDate } from 'src/app/common/utils';
import { campaignPath } from '../../CampaignRoutes';
import { convertStatus } from '../convertStatus-utils';

const FIELD_CONTAINER_WIDTH = 180;

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  headerRowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 15,
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  fieldName: {
    fontWeight: 'bold',
  },
  fieldContainer: {
    width: FIELD_CONTAINER_WIDTH,
    boxSizing: 'border-box',
  },
  innerFieldContainer: {
    width: FIELD_CONTAINER_WIDTH,
    boxSizing: 'border-box',
  },
  iconContainer: {
    width: 60,
    height: 60,
    overflow: 'hidden',
    borderRadius: 12,
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },
  sectionMargin: {
    marginBottom: 15,
  },
  divideMargin: {
    marginBottom: 10,
  },
  subHeader: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));

const CampaignLeadDetailPage: FC<RouteComponentProps> = ({ history, location }) => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const { classes: commonClasses } = useCommonStyles();
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [campaignLeadItem, setCampaignLeadItem] = useState<CampaignLeadItem>();
  const { prospect, prospectMaster, agentData, campaign, sourceData, leadRaw } = campaignLeadItem || {};
  const prospectInfo = isEmpty(prospect) ? prospectMaster : prospect;

  const reloadData = () => {
    if (id) {
      setIsLoading(true);
      fetchLeadItem(id, dispatch)
        .then((result) => {
          setCampaignLeadItem(result);
        })
        .catch((err: ApiErrorResponse) => {
          history.push(NOT_FOUND_ERROR_PAGE_PATH);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line
  }, [id]);

  return (
    <>
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <div className={classes.headerRowContainer}>
            <div className={commonClasses.header}>{Translation('pulseleads.campaign.leadInquiry.title')}</div>
          </div>
          <Button
            variant="contained"
            color="inherit"
            onClick={() => history.push(`${campaignPath}/${campaignLeadItem?.campaign?._id}/lead/list`)}
          >
            {Translation('app.button.back')}
          </Button>
        </div>

        {/* General */}
        <div>
          <div className={classes.divideMargin}>
            <span className={classes.subHeader}>{Translation('pulseleads.campaign.form.section.general')}</span>
          </div>
          <div className={classes.rowContainer}>
            <div className="col col-md-3">
              <div>{Translation('pulseleads.campaign.lead.idNumber')}</div>
              <div>{prospectInfo?.nric || '-'}</div>
            </div>
            <div className="col col-md-3">
              <div>{Translation('pulseleads.campaign.lead.passportNumber')}</div>
              <div>{prospectInfo?.passportNumber || '-'}</div>
            </div>
          </div>

          <div className={classes.rowContainer}>
            <div className="col-3">
              <div>{Translation('pulseleads.campaign.lead.firstName')}</div>
              <div>{prospectInfo?.firstName || '-'}</div>
            </div>
            <div className="col-3">
              <div>{Translation('pulseleads.campaign.lead.lastName')}</div>
              <div>{prospectInfo?.lastName || '-'}</div>
            </div>
            <div className="col-3">
              <div>{Translation('pulseleads.campaign.lead.birthDate')}</div>
              <div>{prospectInfo?.birthDate ? getDefaultDisplayDate(new Date(prospectInfo?.birthDate)) : '-'}</div>
            </div>
            <div className="col-3">
              <div>{Translation('pulseleads.campaign.lead.gender')}</div>
              <div>{prospectInfo?.gender || '-'}</div>
            </div>
          </div>

          <div className={classes.rowContainer}>
            <div className="col-3">
              <div>{Translation('pulseleads.campaign.lead.phoneNumber')}</div>
              <div>{prospectInfo?.phoneNumber || '-'}</div>
            </div>
            <div className="col-3">
              <div>{Translation('pulseleads.campaign.lead.email')}</div>
              <div>{prospectInfo?.email || '-'}</div>
            </div>
            <div className="col-3">
              <div>{Translation('pulseleads.campaign.lead.fbProfile')}</div>
              <div>{prospectInfo?.fbProfile || '-'}</div>
            </div>
            <div className="col-3">
              <div>{Translation('pulseleads.campaign.lead.wechatId')}</div>
              <div>{prospectInfo?.wechatId || '-'}</div>
            </div>
          </div>

          <div className={classes.rowContainer}>
            <div className="col-12">
              <div>{Translation('pulseleads.campaign.lead.address')}</div>
              <div>{prospectInfo?.address || '-'}</div>
            </div>
          </div>
        </div>

        <Divider className={classes.sectionMargin} />

        <div>
          <div className={classes.divideMargin}>
            <span className={classes.subHeader}>{Translation('pulseleads.campaign.lead.assignedAgent')}</span>
          </div>
          <div className={classes.rowContainer}>
            <div className="col-3">
              <div>{Translation('pulseleads.campaign.lead.agentCode')}</div>
              <div>{agentData?.agentCode || '-'}</div>
            </div>
            <div className="col-3">
              <div>{Translation('pulseleads.campaign.lead.agentName')}</div>
              <div>{agentData?.name?.enUs?.displayName || '-'}</div>
            </div>
            <div className="col-3">
              <div>{Translation('pulseleads.campaign.lead.agentPhoneNumber')}</div>
              <div>{agentData?.phone?.mobile || '-'}</div>
            </div>
            <div className="col-3">
              <div>{Translation('pulseleads.campaign.lead.assignedDate')}</div>
              <div>
                {campaignLeadItem?.createdAt
                  ? getDefaultDisplayDate(new Date(campaignLeadItem?.createdAt), 'datetime')
                  : '-'}
              </div>
            </div>
          </div>
        </div>

        <Divider className={classes.sectionMargin} />

        <div>
          <div className={classes.divideMargin}>
            <span className={classes.subHeader}>{Translation('pulseleads.campaign.lead.referredAgent')}</span>
          </div>
          <div className={classes.rowContainer}>
            <div className="col-3">
              <div>{Translation('pulseleads.campaign.lead.agentCode')}</div>
              <div>{campaignLeadItem?.referredAgentId || leadRaw?.referredAgentCode || '-'}</div>
            </div>
            <div className="col-3">
              <div>{Translation('pulseleads.campaign.lead.agentPhoneNumber')}</div>
              <div>{campaignLeadItem?.referredAgentPhoneNumber || leadRaw?.referredAgentPhone || '-'}</div>
            </div>
          </div>
        </div>

        <Divider className={classes.sectionMargin} />

        <div>
          <div className={classes.divideMargin}>
            <span className={classes.subHeader}>{Translation('pulseleads.campaign.lead')}</span>
          </div>
          <div className={classes.rowContainer}>
            <div className="col-3">
              <div>{Translation('pulseleads.campaign.lead.campaignName')}</div>
              <div>{campaign?.name || '-'}</div>
            </div>
            <div className="col-3">
              <div>{Translation('pulseleads.campaign.lead.campaignId')}</div>
              <div>{campaign?.campaignId || '-'}</div>
            </div>
            <div className="col-3">
              <div>{Translation('pulseleads.campaign.lead.source')}</div>
              <div>{sourceData?.sourceName || '-'}</div>
            </div>
            <div className="col-3">
              <div>{Translation('pulseleads.campaign.lead.leadDate')}</div>
              <div>
                {campaignLeadItem?.leadDate ? getDefaultDisplayDate(new Date(campaignLeadItem?.leadDate)) : '-'}
              </div>
            </div>
          </div>

          <div className={classes.rowContainer}>
            <div className="col-3">
              <div>{Translation('pulseleads.campaign.lead.activityStatus')}</div>
              <div>
                {Translation(
                  'pulseleads.campaign.lead.' +
                    convertStatus(
                      campaignLeadItem?.agentStatus,
                      campaignLeadItem?.lastFollowUpStatus,
                      campaignLeadItem?.isActive,
                      campaignLeadItem?.expiredAt,
                      campaignLeadItem?.contactExpiredAt,
                    ),
                )}
              </div>
            </div>
            <div className="col-3">
              <div>{Translation('pulseleads.campaign.lead.isActive')}</div>
              <div>{campaignLeadItem?.isActive ? 'True' : 'False'}</div>
            </div>
            {campaignLeadItem?.feedback && (
              <div className="col-3">
                <div>{Translation('pulseleads.campaign.lead.feedback')}</div>
                <div>{campaignLeadItem?.feedback.name}</div>
              </div>
            )}
            <div className="col-3">
              <div>{Translation('pulseleads.campaign.lead.lastUpdated')}</div>
              <div>
                {campaignLeadItem?.updatedAt
                  ? getDefaultDisplayDate(new Date(campaignLeadItem?.updatedAt), 'datetime')
                  : '-'}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CampaignLeadDetailPage;
