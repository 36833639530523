import React, { FC, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { PaginateList } from 'src/app/common/types/common-types';
import { useDataProvider } from 'src/app/common/utils';
import {
  AwardListParam,
  AwardTypeListParam,
  fetchAgentAwardList,
  fetchAwardTypeList,
} from 'src/app/modules/Profile/network/agentProfileCrud';
import { AwardListItem, awardTypeEnum, AwardTypeListItem } from 'src/app/modules/Profile/types/agent-profile-types';
import { getConfig } from 'src/app/i18n';
import AwardTypeList from './AwardTypeList';

type AwardTypeListingPageProps = ParamsProps;

const initialState: any = {
  name: '',
  awardNameEn: '',
  awardNameLocal: '',
  awardName: '',
  awardYear: '',
  displayAwardYear: '',
  type: '',
  icon: '',
  page: 1,
  limit: 5,
};

export const awardTypeFilterKeys = ['awardNameEn', 'awardName', 'awardCode', 'awardNameLocal', 'awardYear', 'type'];

const paramsInitiator = (initialParams?: Record<string, string>): any => {
  return initialParams
    ? {
        name: initialParams.name || '',
        awardCode: initialParams.awardCode || '',
        awardNameEn: initialParams.awardNameEn || '',
        awardNameLocal: initialParams.awardNameLocal || '',
        awardName: initialParams.awardName || '',
        awardYear: initialParams.awardYear || '',
        type: initialParams.type || '',
        page: 1,
        limit: 5,
      }
    : initialState;
};
export const AwardTypeListingPage: FC<AwardTypeListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const lang = getConfig().selectedLang;
  const [filterState, setFilterState] = useState<AwardTypeListParam>(paramsInitiator(initialParams));

  const [awardTypeList, setawardTypeList] = useState<PaginateList<AwardTypeListItem>>();
  const { isLoading, refreshData } = useDataProvider<PaginateList<AwardTypeListItem>>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchAwardTypeList(filterState, lang, dispatch);
      } catch (err) {}
    },
    setawardTypeList,
    false,
  );

  return (
    <>
      <PruFilter
        title={Translation('agentProfile.ad_awards_filter')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'awardName',
            initialValue: filterState.awardName,
            displayName: Translation('agentProfile.ad_award_name_label'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'awardCode',
            initialValue: filterState.awardCode,
            displayName: Translation('agentProfile.ad_award_code_label'),
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'type',
            initialValue: filterState.type,
            displayName: Translation('agentProfile.ad_type_label'),
            choices: [
              { displayName: '', value: '' },
              ...Object.entries(awardTypeEnum).map(([key, value]) => ({
                displayName: Translation(`dropdown_label_${value}`),
                value: value,
              })),
            ],
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            name: data.name,
            awardCode: data.awardCode,
            awardName: data.awardName,
            awardYear: data.awardYear,
            type: data.type,
          })
        }
        fetchData={refreshData}
      />

      <AwardTypeList
        isLoading={isLoading}
        awardTypeList={awardTypeList}
        onRefresh={refreshData}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};
