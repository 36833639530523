import React, { FC, useState } from 'react';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { useIntl } from 'react-intl';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useDispatch } from 'react-redux';
import { useDataProvider } from 'src/app/common/utils';
import { LeadSourceListParam, fetchLeadSourceList } from 'src/app/modules/PulseLeads/network/sourceCrud';
import LeadSourceList from './components/LeadSourceList';
import { PaginateList } from 'src/app/common/types/common-types';
import { LeadSource } from 'src/app/modules/PulseLeads/types/source-types';

type LeadSourceListingPageProps = {} & ParamsProps;

const initialState: LeadSourceListParam = {
  sourceName: '',
  sortBy: '',
  page: 1,
  limit: 5,
};

export const leadSourceFilterKeys = ['sourceName'];

const paramsInitiator = (initialParams?: Record<string, string>): LeadSourceListParam => {
  return initialParams
    ? {
        sourceName: initialParams.sourceName || '',
        sortBy: '',
        page: 1,
        limit: 5,
      }
    : initialState;
};

const ListSourceListingPage: FC<LeadSourceListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const [filterState, setFilterState] = useState<LeadSourceListParam>(paramsInitiator(initialParams));
  const [leadSourceList, setLeadSourceList] = useState<PaginateList<LeadSource>>();

  const { isLoading, refreshData } = useDataProvider<PaginateList<LeadSource>>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchLeadSourceList(filterState, dispatch);
      } catch (err) {}
    },
    setLeadSourceList,
    false,
  );

  return (
    <>
      <PruFilter
        title={Translation('pulseleads.leadSource.filter')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'sourceName',
            initialValue: filterState.sourceName,
            displayName: Translation('pulseleads.leadSource.common.name'),
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            sourceName: data.sourceName,
          })
        }
        fetchData={refreshData}
      />
      <LeadSourceList
        isLoading={isLoading}
        leadSourceList={leadSourceList}
        onRefresh={refreshData}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default ListSourceListingPage;
