import { I18nGenericData } from 'src/app/i18n';
import { AttachmentDef } from 'src/app/common/types';

export type DetailsFormState = {
  eventImage: I18nGenericData<AttachmentDef>;
  description: I18nGenericData<string>;
  attachments: I18nGenericData<AttachmentDef[]>;
};

type SetFormStateAction = {
  type: 'SET_FORM_STATE';
  payload: {
    value: Partial<DetailsFormState>;
  };
};

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof DetailsFormState;
    value: any;
  };
};

type ModifyDescriptionAction = {
  type: 'MODIFY_DESCRIPTION';
  payload: {
    value: I18nGenericData<string>;
  };
};

type FillInDetailAction = {
  type: 'FILL_IN_DETAIL';
  payload: {
    fromLocale: string;
    toLocale: string;
  };
};

type RemoveAttachmentAction = {
  type: 'REMOVE_ATTACHMENT';
  payload: {
    locale: string;
    index: number;
  };
};

export type DetailsFormAction =
  | SetFormStateAction
  | ModifyFieldAction
  | ModifyDescriptionAction
  | FillInDetailAction
  | RemoveAttachmentAction;

export const detailsFormReducer = (state: DetailsFormState, action: DetailsFormAction): DetailsFormState => {
  switch (action.type) {
    case 'SET_FORM_STATE':
      return {
        ...state,
        ...action.payload.value,
      };
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    case 'MODIFY_DESCRIPTION':
      return {
        ...state,
        description: {
          ...state.description,
          ...action.payload.value,
        },
      };
    case 'FILL_IN_DETAIL':
      const fromLocale = action.payload.fromLocale;
      const toLocale = action.payload.toLocale;
      return {
        ...state,
        eventImage: {
          ...state.eventImage,
          [toLocale]: state.eventImage[fromLocale],
        },
        description: {
          ...state.description,
          [toLocale]: state.description[fromLocale],
        },
        attachments: {
          ...state.attachments,
          [toLocale]: state.attachments[fromLocale],
        },
      };
    case 'REMOVE_ATTACHMENT':
      const newAttachment = [...(state.attachments[action.payload.locale] as AttachmentDef[])];
      newAttachment.splice(action.payload.index, 1);
      return {
        ...state,
        attachments: {
          ...state.attachments,
          [action.payload.locale]: newAttachment,
        },
      };
    default:
      return state;
  }
};
