import { IntlShape } from 'react-intl';
import { ModuleHierachy } from '../../common/module/types';
import {
  APPLICATION_TITLE,
  APPLICATION_BASE_PATH,
  APPLICATION_NAMESPACE,
  AdminApplicationPermissionType,
} from './constants';
import ApplicationRoutes from './pages/ApplicationRoutes';
import { isPermitted } from 'src/app/common/utils';

export const applicationModuleConfig = (permissions: string[], intl: IntlShape): ModuleHierachy => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return {
    title: Translation(APPLICATION_TITLE),
    path: APPLICATION_BASE_PATH,
    icon: '/media/svg/icons/Devices/Phone.svg',
    namespace: APPLICATION_NAMESPACE,
    component: ApplicationRoutes,
    enableRead: isPermitted(
      [
        AdminApplicationPermissionType.APPLICATION_READ,
        AdminApplicationPermissionType.APPLICATION_CREATE,
        AdminApplicationPermissionType.APPLICATION_UPDATE,
      ],
      permissions,
    ),
    enableCreate: isPermitted([AdminApplicationPermissionType.APPLICATION_CREATE], permissions),
    enableUpdate: isPermitted([AdminApplicationPermissionType.APPLICATION_UPDATE], permissions),
  };
};
