import React, { useState, useEffect } from 'react';
import Form from '../../../../../../../../common/components/Form';
import { useIntl } from 'react-intl';
import { CategoryFormState, CategoryItem, CategoryLevel } from '../../../../types/category-types';
import { makeStyles } from 'tss-react/mui';
import { Button, TextField, MenuItem, CircularProgress } from '@mui/material';
import { forEach } from 'lodash';

const useStyles = makeStyles()((theme) => ({
  footerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  container: {
    padding: 20,
  },
}));

interface AddCategoryProps {
  disabled: boolean;
  onSave: (formData: CategoryFormState) => void;
  onCancel: () => void;
  type: string;
  confirmLoading: boolean;
  initialValues: any;
  allCategories?: CategoryItem[] | null;
  categoryId: string;
}

const Create: React.FC<AddCategoryProps> = ({
  disabled,
  onSave,
  onCancel,
  type,
  confirmLoading,
  initialValues,
  allCategories,
  categoryId,
}) => {
  const { classes } = useStyles();
  const [categoryOptions, setCategoryOptions] = useState<{ label: string; value: string }[]>([]);
  const [subCategoryOptions, setSubCategoryOptions] = useState<{ label: string; value: string }[]>([]);
  const [form] = Form.useForm();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const reCaculateSubOptions = (categoryId: string) => {
    let levelTwocategoryList: any = [];
    if (allCategories) {
      allCategories
        .filter((level1Item) => level1Item.id === categoryId)
        .map((level1Item) => {
          return forEach(level1Item.children, (item) => {
            //@ts-ignore
            levelTwocategoryList.push({ label: item.name, value: item.id });
          });
        });
    }
    setSubCategoryOptions(levelTwocategoryList);
    form.setFieldsValue({
      subCategory: '',
      thirdCategory: '',
    });
  };

  const onFinish = (values: any) => {
    onSave({ ...values, level: type });
  };

  const cancel = () => {
    onCancel();
  };

  useEffect(() => {
    const levelOnecategoryList: any = [];
    const levelTwocategoryList: any = [];

    if (allCategories) {
      Object.entries(allCategories).map(([key, value]) => {
        return levelOnecategoryList.push({ label: value.name, value: value.id });
      });
    }
    setCategoryOptions(levelOnecategoryList);
    if (type === CategoryLevel.THREE && categoryId) {
      const { category } = initialValues;
      if (allCategories) {
        allCategories
          .filter((level1Item) => level1Item.id === category)
          .map((level1Item) => {
            return forEach(level1Item.children, (item) => {
              //@ts-ignore
              levelTwocategoryList.push({ label: item.name, value: item.id });
            });
          });
      }
      setSubCategoryOptions(levelTwocategoryList);
    }
  }, [allCategories, categoryId, initialValues, type]);

  const getCategoryIdBylabel = (categories: CategoryItem[], category: string) => {
    let existId = '';
    if (categories && categories.length > 0) {
      categories
        .filter((categoryItem) => categoryItem.name === category)
        .map((item) => {
          return (existId = item.id);
        });
    }

    return existId;
  };

  const existSameCategory = (categories: CategoryItem[], category: string) => {
    if (!categories) return false;
    const id = getCategoryIdBylabel(categories, category);
    return (categoryId && id && id !== categoryId) || (!categoryId && id);
  };

  const resCategory = Translation('component.formLabel.resource-category');
  const emptyValue = Translation('component.hint.empty-value-not-allowed');
  const selectPlaceholder = Translation('app.input.placeholder.please-select');
  const secCategory = Translation('component.formLabel.2nd-category');
  const thirdCategory = Translation('component.formLabel.3rd-category');
  const description = Translation('component.formLabel.description');
  const placeEnter = Translation('app.input.placeholder.please-enter');

  return (
    <Form
      form={form}
      // className={`${styles.container} add-form-wraper form-wraper`}
      onFinish={onFinish}
      initialValues={{ ...initialValues }}
      className={classes.container}
    >
      <Form.Item
        name="category"
        label={resCategory}
        rules={[
          {
            required: true,
            message: emptyValue,
          },
          type === CategoryLevel.ONE
            ? {
                validator: (_, value) => {
                  if (allCategories && existSameCategory(allCategories, value)) {
                    return Promise.reject(new Error(Translation('component.addcategory.samecategory')));
                  }
                  return Promise.resolve();
                },
              }
            : {
                validator: (_, value) => {
                  return Promise.resolve();
                },
              },
        ]}
      >
        {type === CategoryLevel.ONE ? (
          <TextField margin="dense" variant="outlined" fullWidth placeholder={placeEnter} disabled={disabled} />
        ) : (
          <TextField
            disabled={disabled}
            select
            margin="dense"
            variant="outlined"
            fullWidth
            placeholder={selectPlaceholder}
            // onChange={reCaculateSubOptions}
          >
            {categoryOptions.map((category) => {
              return (
                <MenuItem
                  onClick={() => reCaculateSubOptions(category.value)}
                  key={category.value}
                  value={category.value}
                >
                  {category.label}
                </MenuItem>
              );
            })}
          </TextField>
        )}
      </Form.Item>
      {type !== CategoryLevel.ONE && (
        <Form.Item
          name="subCategory"
          label={secCategory}
          rules={[
            { required: true, message: emptyValue },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                const category = getFieldValue('category');
                let subCategoryList: CategoryItem[] = [];
                if (allCategories) {
                  allCategories
                    .filter((level1Item) => level1Item.id === category)
                    .map((level1Item) => {
                      if (level1Item.children) {
                        return (subCategoryList = level1Item.children as CategoryItem[]);
                      } else {
                        return null;
                      }
                    });
                }
                if (type !== CategoryLevel.TWO) {
                  return Promise.resolve();
                }
                if (subCategoryList && existSameCategory(subCategoryList, value)) {
                  return Promise.reject(new Error(Translation('component.addcategory.samecategory')));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          {type === CategoryLevel.TWO ? (
            <TextField margin="dense" variant="outlined" fullWidth placeholder={placeEnter} disabled={disabled} />
          ) : (
            <TextField
              disabled={disabled}
              select
              margin="dense"
              variant="outlined"
              fullWidth
              placeholder={selectPlaceholder}
            >
              {subCategoryOptions.map((category) => {
                return (
                  <MenuItem key={category.value} value={category.value}>
                    {category.label}
                  </MenuItem>
                );
              })}
            </TextField>
          )}
        </Form.Item>
      )}
      {type === CategoryLevel.THREE && (
        <Form.Item
          name="thirdCategory"
          label={thirdCategory}
          rules={[
            { required: true, message: emptyValue },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                const category = getFieldValue('category');
                const subCategory = getFieldValue('subCategory');
                let thirdLevelCategoryList: CategoryItem[] = [];
                let subCategoryList: CategoryItem[] = [];
                if (allCategories) {
                  allCategories
                    .filter((level1Item) => level1Item.id === category)
                    .map((level1Item) => {
                      if (level1Item.children) {
                        return (subCategoryList = level1Item.children as CategoryItem[]);
                      } else {
                        return null;
                      }
                    });
                  subCategoryList
                    .filter((level2Item) => level2Item.id === subCategory)
                    .map((level2Item) => {
                      if (level2Item.children) {
                        return (thirdLevelCategoryList = level2Item.children as CategoryItem[]);
                      } else {
                        return null;
                      }
                    });
                }

                if (type !== CategoryLevel.THREE || !thirdLevelCategoryList) {
                  return Promise.resolve();
                }
                if (existSameCategory(thirdLevelCategoryList, value)) {
                  return Promise.reject(new Error(Translation('component.addcategory.samecategory')));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <TextField margin="dense" variant="outlined" fullWidth placeholder={placeEnter} disabled={disabled} />
        </Form.Item>
      )}
      <Form.Item
        name="description"
        label={description}
        required={false}
        rules={[{ required: false, message: emptyValue }]}
      >
        <TextField margin="dense" variant="outlined" fullWidth placeholder={placeEnter} disabled={disabled} />
      </Form.Item>
      <div className={classes.footerContainer}>
        {!disabled ? (
          <>
            {/* todo: cancel warling */}
            <Button variant="contained" color="inherit" onClick={cancel}>
              {Translation('app.button.cancel')}
            </Button>
            <Button
              disabled={confirmLoading}
              type="submit"
              style={{ marginLeft: 20 }}
              variant="contained"
              color="secondary"
              onClick={() => {}}
            >
              {Translation('app.button.submit')}
              {confirmLoading && <CircularProgress style={{ marginLeft: 8 }} size={15} />}
            </Button>
          </>
        ) : null}
      </div>
    </Form>
  );
};

export default Create;
