import React, { FC, useState } from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import { RouteComponentProps } from 'react-router-dom';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { makeStyles } from 'tss-react/mui';
import { Button, CircularProgress, Tooltip, IconButton } from '@mui/material';
import { useIntl } from 'react-intl';
import RuleItem from './components/RuleItem';
import { RuleSetItem } from '../../../types/rule-types';
import { useDataProvider } from 'src/app/common/utils';
import { fetchLeadsRuleList } from '../../../network/customerDedupCrud';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  rowContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '50px 0',
  },
}));

const CustomerDeduplicationRuleRoutes: FC<RouteComponentProps> = () => {
  const dispatch = useDispatch();

  const { classes } = useStyles();
  const { classes: commonClasses } = useCommonStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const [isCreating, setIsCreating] = useState<boolean>(false);

  const [ruleSetItems, setRuleSetItems] = useState<RuleSetItem[]>();

  const { isLoading, refreshData } = useDataProvider<RuleSetItem[]>(
    async () => {
      try {
        return await fetchLeadsRuleList(dispatch);
      } catch (err) {}
    },
    setRuleSetItems,
    false,
  );

  return (
    <div className={classes.container}>
      <div className={classes.rowContainer} style={{ marginBottom: 10 }}>
        <div className={commonClasses.header}>{Translation('pulseleads.rule.list')}</div>
        <div className={classes.rowContainer}>
          <Tooltip title="Refresh">
            <IconButton onClick={refreshData}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
          <Button
            style={{ marginLeft: 15 }}
            disabled={isCreating}
            variant="contained"
            color="secondary"
            onClick={() => setIsCreating(true)}
          >
            {Translation('app.button.add')}
          </Button>
        </div>
      </div>
      {isCreating && (
        <RuleItem
          style={{ marginBottom: 10 }}
          onRefresh={refreshData}
          onFinish={() => {
            setIsCreating(false);
            refreshData();
          }}
        />
      )}
      {isLoading || !ruleSetItems ? (
        isLoading ? (
          <div className={classes.loadingContainer}>
            <CircularProgress color="secondary" size={60} />
          </div>
        ) : (
          <div className={classes.loadingContainer}>{Translation('common.loading.refreshAgain')}</div>
        )
      ) : (
        ruleSetItems.map((item) => (
          <RuleItem
            key={`rule-set-${item._id}`}
            style={{ marginBottom: 10 }}
            ruleSetItem={item}
            onRefresh={refreshData}
            onFinish={refreshData}
          />
        ))
      )}
    </div>
  );
};

export default CustomerDeduplicationRuleRoutes;
