import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';

import { useDataProvider } from 'src/app/common/utils';

import { fetchConfigAboutMeList } from 'src/app/modules/Profile/network/agentProfileCrud';
import { ConfigAboutMePaginateList, isDefault, StatusEnum } from 'src/app/modules/Profile/types/agent-profile-types';
import AboutMeList from './Component/ConfigAboutMeList';

type ConfigurationLocationListingPageProps = Record<string, unknown> & ParamsProps;

export type ConfigAboutMeParam = {
  templateId: string;
  page: number;
  limit: number;
  status: string;
  isDefault: string;
  // createdAt: Date | null;
  // updatedAt: Date | null;
};
const initialState: ConfigAboutMeParam = {
  templateId: '',
  page: 1,
  limit: 5,
  status: '',
  isDefault: '',
  // createdAt: null,
  // updatedAt: null,
};

const paramsInitiator = (initialParams?: Record<string, string>): ConfigAboutMeParam => {
  return initialParams
    ? {
        templateId: '',
        page: 1,
        limit: 5,
        status: '',
        isDefault: '',
        // createdAt: initialParams.createdAt ? getDayStart(new Date(initialParams.createdAt)) : null,
        // updatedAt: initialParams.updatedAt ? getDayStart(new Date(initialParams.updatedAt)) : null,
      }
    : initialState;
};
export const configAboutMeFilterKeys = ['templateId', 'status', 'isDefault'];

const ConfigurationLocationListingPage: FC<ConfigurationLocationListingPageProps> = ({ onChangeQueryParams }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [filterState, setFilterState] = useState<ConfigAboutMeParam>(paramsInitiator());
  const [aboutMeList, setAboutMeList] = useState<ConfigAboutMePaginateList>();

  const { isLoading, refreshData } = useDataProvider<ConfigAboutMePaginateList>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchConfigAboutMeList(filterState, dispatch);
      } catch (err) {}
    },
    setAboutMeList,
    false,
  );
  return (
    <>
      <PruFilter
        title={Translation('agentProfile.ad_introduction_list_filter')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'templateId',
            initialValue: filterState.templateId,
            style: { width: 200 },
            displayName: Translation('template_code_label'),
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'isDefault',
            initialValue: filterState.status,
            displayName: Translation('agentProfile.ad_default_label'),
            style: { width: 200 },
            choices: [
              { displayName: '', value: '' },
              ...Object.entries(isDefault).map(([key, value]) => ({
                displayName: Translation(`agentProfile.ad_${value}_label`),
                value: value === isDefault.YES ? 'true' : 'false',
              })),
            ],
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            templateId: data.templateId,
            status: data.status,
            isDefault: data.isDefault,
          })
        }
        fetchData={refreshData}
      />
      <AboutMeList
        isLoading={isLoading}
        list={aboutMeList}
        onRefresh={refreshData}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default ConfigurationLocationListingPage;
