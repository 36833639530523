import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { LibraryItem, LibraryPaginateList } from '../../../types/library-types';
import PruTable from '../../../../../../../common/components/PruTable/PruTable';
import { getDefaultDisplayDate } from 'src/app/common/utils';
import { ResourceType } from '../../../constants';
import { getType, renderDisplayStatusField } from '../../../utils/common-utils';

type MaterialSetListTableProps = {
  isLoading: boolean;
  libraryList?: LibraryPaginateList;
  enableUpdate: boolean;
  enableCreate: boolean;
  libraryTableTitle: string;
  currentLanguage: string;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
  onSort: (sortingState: { key: string; value?: string }) => void;
  setCurrentSelectedRow: (data: any) => void;
  selectedMaterialSet: any;
};

const MaterialSetListTable: FC<MaterialSetListTableProps> = ({
  isLoading,
  libraryList,
  enableUpdate,
  enableCreate,
  libraryTableTitle,
  currentLanguage,
  onRefresh,
  onChangePage,
  onSort,
  setCurrentSelectedRow,
  selectedMaterialSet,
}) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  return (
    <PruTable
      headerBtnDef={[]}
      operationDef={[]}
      columnDef={[
        {
          isId: true,
          keyIndex: 'id',
          hidden: true,
          displayName: '',
          renderData: (row) => '',
        },
        {
          keyIndex: 'name',
          align: 'left',
          displayName: Translation('component.formLabel.name'),
          renderData: (row) => String(row.name ? row.name : ''),
        },
        {
          keyIndex: 'category',
          align: 'left',
          displayName: Translation('component.formLabel.category'),
          renderData: (row) => String(row.category?.['name'] ? row.category?.['name'] : ''),
        },
        {
          keyIndex: 'status',
          align: 'left',
          displayName: Translation('component.formLabel.status'),
          renderData: (row) => (row ? Translation(renderDisplayStatusField(row)) : ''),
          // sortable: true,
          // onSort: (sort) => { onSort({ key: 'status', value: sort['status'] }) },
        },
        {
          keyIndex: 'type',
          align: 'left',
          displayName: Translation('component.formLabel.type'),
          renderData: (row) => String(row.type ? getType(row.type) : ''),
          sortable: true,
          onSort: (sort) => {
            onSort({ key: 'type', value: sort['type'] });
          },
        },
        {
          keyIndex: 'createdAt',
          align: 'left',
          displayName: Translation('component.formLabel.created-time'),
          renderData: (row) => (row.updatedAt ? getDefaultDisplayDate(new Date(row.createdAt), 'datetime') : ''),
          sortable: true,
          onSort: (sort) => {
            onSort({ key: 'createdAt', value: sort['createdAt'] });
          },
        },
        {
          keyIndex: 'updatedAt',
          align: 'left',
          displayName: Translation('component.formLabel.last-updated-time'),
          renderData: (row) => (row.updatedAt ? getDefaultDisplayDate(new Date(row.updatedAt), 'datetime') : ''),
          sortable: true,
          onSort: (sort) => {
            onSort({ key: 'updatedAt', value: sort['updatedAt'] });
          },
        },
        {
          keyIndex: 'lastUpdatedBy',
          align: 'left',
          displayName: Translation('component.formLabel.last-updated-by'),
          renderData: (row) => String(row.lastUpdatedBy ? row.lastUpdatedBy : ''),
          sortable: true,
          onSort: (sort) => {
            onSort({ key: 'lastUpdatedBy', value: sort['lastUpdatedBy'] });
          },
        },
      ]}
      defaultRowsPerPage={20}
      isLoading={isLoading}
      onRefresh={onRefresh}
      dataSource={libraryList?.libraryList}
      totalPages={libraryList?.totalPages}
      totalRecords={libraryList?.totalRecords}
      onChangePage={onChangePage}
      currentSelectedRow={setCurrentSelectedRow}
      disableBulkSelect={!!!enableCreate}
      bulkSelectCheckboxDisable={(rowData) => {
        return (
          rowData.type === ResourceType.materialSet ||
          !rowData.publishedAt ||
          selectedMaterialSet.find((item: LibraryItem) => item.id === rowData.id)
        );
      }}
    />
  );
};

export default MaterialSetListTable;
