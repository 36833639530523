import { downloadFile } from 'src/app/common/utils';
import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import { Dispatch } from 'react';
import { UpdateEngagementFieldsBody } from './util';
import qs from 'qs';

export class ErrorLog {
  rowNumber: number | undefined;
  reason: string | undefined;
}

export const getFieldsList = async (params: any, dispatch?: Dispatch<any>) => {
  try {
    const r = apiClient.get(`${ADMIN_URL}/engagement/fields`);
    return r;
  } catch (err: any) {
    throw apiErrorHandler(err, dispatch);
  }
};

export const getFieldsById = async (id: string, dispatch?: Dispatch<any>) => {
  try {
    return apiClient.get(`${ADMIN_URL}/engagement/fields/${id}`);
  } catch (err: any) {
    throw apiErrorHandler(err, dispatch);
  }
};

export const updateFields = async (id: string, body: UpdateEngagementFieldsBody, dispatch?: Dispatch<any>) => {
  try {
    return apiClient.put(`${ADMIN_URL}/engagement/fields/${id}`, {
      ...body,
    });
  } catch (err: any) {
    throw apiErrorHandler(err, dispatch);
  }
};

export const getTaskList = async (params: any, dispatch?: Dispatch<any>) => {
  try {
    console.log(params);
    const r = apiClient.get(`${ADMIN_URL}/engagement/task`, { params });
    return r;
  } catch (err: any) {
    throw apiErrorHandler(err, dispatch);
  }
};

export const getTaskById = async (id: string, dispatch?: Dispatch<any>) => {
  try {
    return apiClient.get(`${ADMIN_URL}/engagement/task/${id}`);
  } catch (err: any) {
    throw apiErrorHandler(err, dispatch);
  }
};

export const updateTask = async (id: string, body: any, dispatch?: Dispatch<any>) => {
  try {
    return apiClient.put(`${ADMIN_URL}/engagement/task/${id}`, {
      ...body,
    });
  } catch (err: any) {
    throw apiErrorHandler(err, dispatch);
  }
};

export const checkTemplate = async (params: any, dispatch?: Dispatch<any>, Translation?: any) => {
  try {
    return apiClient.get(`${ADMIN_URL}/prospect-templates/validation/task`, { params, timeout: 0 });
  } catch (err: any) {
    throw apiErrorHandler(err, dispatch);
  }
};

export const getTaskTemplate = async (dispatch?: Dispatch<any>, Translation?: any) => {
  return downloadFile(
    'Egmt task template.csv',
    `${ADMIN_URL}/prospect-templates/download/task`,
    {},
    dispatch,
    Translation,
  );
};

export const addTask = async (dto?: any, dispatch?: Dispatch<any>) => {
  try {
    return apiClient.post(`${ADMIN_URL}/engagement/task`, dto);
  } catch (err: any) {
    throw apiErrorHandler(err, dispatch);
  }
};

export const getTopicList = async (params: any, dispatch?: Dispatch<any>) => {
  try {
    const r = await apiClient.get(`${ADMIN_URL}/engagement-topics?${qs.stringify(params)}`);
    return r;
  } catch (err: any) {
    throw apiErrorHandler(err, dispatch);
  }
};

// export const getTopicById = async (id: string, dispatch?: Dispatch<any>) => {
//     try {
//         return apiClient.get(`${ADMIN_URL}/engagement/topic/${id}`);
//     } catch (err: any) {
//         throw apiErrorHandler(err, dispatch);
//     }
// }

// export const updateTopic = async (id: string, body: any, dispatch?: Dispatch<any>) => {
//     try {
//         return apiClient.put(`${ADMIN_URL}/engagement/topic/${id}`, {
//             ...body
//         });
//     } catch (err: any) {
//         throw apiErrorHandler(err, dispatch);
//     }

// }
