import { syncDateToTime } from 'src/app/common/utils';
import { EventSessionItem } from 'src/app/modules/event-v2/types';

export const syncDateToSessionsTime = (date: Date, sessions: EventSessionItem[]): EventSessionItem[] => {
  return sessions.map((sessionItem) => ({
    ...sessionItem,
    startTime: syncDateToTime(date, sessionItem.startTime),
    endTime: syncDateToTime(date, sessionItem.endTime),
    subSessions: syncDateToSessionsTime(date, sessionItem.subSessions),
  }));
};
