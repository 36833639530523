import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Radio } from '@mui/material';
import  CheckIcon  from '@mui/icons-material/Check';


const useStyles = makeStyles()({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 30,
        height: 30,
        'input:hover ~ &': {
            borderWidth: '2px',
            borderColor: 'black',
            borderStyle: 'solid',
        }
    },
    checkedIcon: {
        borderWidth: '2px',
        borderColor: 'black',
        borderStyle: 'solid',
        display: 'flex',
        justifyContent: 'center'
    },
    tickIcon: {
        color: 'white',
        alignSelf: 'center'
    }
});

const ColorLabelRadio = (props: any) => {
    const { classes, cx } = useStyles();
    const { bgColor } = props;

    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={cx(classes.icon, classes.checkedIcon)} style={{ backgroundColor: bgColor }}><CheckIcon className={classes.tickIcon} /></span>}
            icon={<span className={classes.icon} style={{ backgroundColor: bgColor}} />}
            {...props}
        />
    );
}

export default ColorLabelRadio;