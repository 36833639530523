import React from 'react';
import { Link } from 'react-router-dom';
import { regionLocale } from 'src/app/i18n';
import { leadEngagementPath } from '../../EngagementFiledsRoutes';

const getNameFromI18nData = (locale: string, row: any) => {
  const data = row.find((item: any) => {
    return item.locale === locale;
  });
  // eslint-disable-next-line
  return (data && data.name) || '';
};

const i18ConfigData = regionLocale;

const columns = (Translation: any, classes: any): any => {
  const columns = [
    {
      title: Translation('pulseleads.engagement.fields'),
      hideInSearch: true,
      dataIndex: 'field',
    },

    ...i18ConfigData.map((locale) => ({
      title: `Name (${Translation(`navBar.lang.${locale}`)})`,
      hideInSearch: true,
      dataIndex: 'name',
      render: (row: any) => {
        return <div style={{ width: 150 }}>{getNameFromI18nData(locale, row)}</div>;
      },
    })),
    {
      title: Translation('pulseleads.engagement.last.updated.by'),
      hideInSearch: true,
      dataIndex: 'updatedBy',
    },
    {
      title: Translation('pulseleads.engagement.last.updated.date'),
      hideInSearch: true,
      dataIndex: 'updatedAt',
    },
    {
      title: Translation('component.formLabel.operation'),
      dataIndex: 'option',
      key: 'option',
      valueType: 'option',
      hideInSearch: true,
      sticky: true,
      render: (_: any, row: any) => {
        return (
          <Link to={`${leadEngagementPath}/edit/${row._id}`}>
            <div className={`${classes.operationBtn}`} style={{ marginRight: 10 }}>
              {Translation('app.button.edit')}
            </div>
          </Link>
        );
      },
    },
  ];

  return [...columns];
};

export default columns;
