import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { getConfigurations, useJWT } from 'src/app/common/utils';
import { TokenInfo } from 'src/app/modules/Auth/types/auth-types';
import {
  dataReportLibraryDev,
  dataReportLibraryProd,
  dataReportLibraryUat,
  usersReportLibraryDev,
  usersReportLibraryProd,
  usersReportLibraryUat,
  usersReportParams,
} from 'src/app/modules/PruleadInsightsReporting/types/report-types';
import { RootState } from 'src/redux/store';
import { getMongoChartToken } from '../../../../../../Auth/_redux/authCrud';
type UserReportDetailPageProps = {
  filter: usersReportParams;
  selectFilter: (data: any) => void;
  updateData: (data: any) => void;
} & ParamsProps;

// const baseUrl = window.envConfig['REACT_APP_MONGODB_CHART_COE_BASE_URL'];

let library = {
  dataReport: dataReportLibraryDev,
  usersReport: usersReportLibraryDev,
};

const UserReportDetailPage: FC<UserReportDetailPageProps> = ({
  filter,
  initialParams,
  onChangeQueryParams,
  selectFilter,
  updateData,
}) => {
  const user = useSelector<RootState, TokenInfo | undefined>((state) => state.auth.user);
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const jwt = useJWT() || {};
  const { region, channel } = jwt;
  const getEnv = useMemo(() => {
    const env = window.envConfig['REACT_APP_ENV'];
    if (env === 'dev' || env === 'coedev') {
      library = {
        dataReport: dataReportLibraryDev,
        usersReport: usersReportLibraryDev,
      };
    }
    if (env === 'uat') {
      library = {
        dataReport: dataReportLibraryUat,
        usersReport: usersReportLibraryUat,
      };
    }
    if (env === 'prod') {
      library = {
        dataReport: dataReportLibraryProd,
        usersReport: usersReportLibraryProd,
      };
    }
  }, []);

  const baseUrl: string = getConfigurations()?.mongoChart?.baseUrl;

  const sdk = new ChartsEmbedSDK({
    baseUrl,
    getUserToken: async function () {
      // return await fetchDashboardJWT(user?.sub, "chartSubscriptionKey");
      const response = await getMongoChartToken();
      return response.accessToken;
    },
  });

  const chartDiv = useRef<HTMLDivElement>(null);
  const dataDiv = useRef<HTMLDivElement>(null);

  const [chart, reRender] = useState(
    sdk.createChart({
      chartId: library.usersReport.chartId,
      height: 800,
      theme: 'light',
      // filter: { $and: [{ 'agent_profile.region': region }, { 'agent_profile.channel': channel }] },
    }),
  );

  const [dataChart, dataChartreRender] = useState(
    sdk.createChart({
      chartId: library.dataReport.chartId,
      height: 800,
      theme: 'light',
      // filter: { $and: [{ 'agent_profile.region': region }, { 'agent_profile.channel': channel }] },
    }),
  );

  //should correspond to the filter name of mongodb
  // businessUnit: agent_profile.agency
  // branch: agent_profile.branch
  // region: agent_profile.region
  // unit: agent_profile.unit
  // agentCode: agentCode
  // agentName: agent_profile.displayName.enUs
  // position: position
  // mda: agent_group_mda
  // segment: agent_group.segment
  // pvm: agent_group.PVM
  // others: agent_group.other
  const [myFilter, setMyFilter] = useState<any>({
    // 'agent_profile.agency': '',
    // 'agent_profile.branch': '',
    // 'agent_profile.region': '',
    // 'agent_profile.unit': '',
    agentCode: '',
    // 'agent_profile.displayName.enUs': '',
    // startDt: undefined,
    // endDate: undefined,
    position: '',
    // 'agent_group_mda.type': '',
    // 'agent_group.segment': '',
    // 'agent_group.PVM': '',
    // 'agent_group.other': '',
    // 'agent_profile.channel': '',
  });

  useEffect(() => {
    if (chartDiv.current && dataDiv.current) {
      chart
        .render(chartDiv.current)
        .then(() => {
          chart.getData().then((result) => updateData(result));
        })
        .catch((err) => console.log('Error during Charts rendering.', err));
      dataChart
        .render(dataDiv.current)
        .then(() => {
          dataChart.getData().then((result) => {
            type resultType = keyof typeof result;
            type fieldsType = keyof typeof fields;
            type dataType = keyof (typeof data)[0];

            const fields = result['fields' as resultType] as object;
            const data = result['documents' as resultType] as Array<{ group: string }>;

            let nameObj = {
              businessUnit: '',
              branch: '',
              region: '',
              unit: '',
              agentCode: '',
              agentName: '',
              position: '',
              mda: '',
              segment: '',
              pvm: '',
              others: '',
              channel: '',
            };
            type nameType = keyof typeof nameObj;

            // fields[fieldName as fieldsType] -> chart's field name
            for (const fieldName in fields) {
              nameObj[fields[fieldName as fieldsType] as nameType] = fieldName;
            }

            let dataList: Record<string, any[]> = {
              businessUnit: [],
              branch: [],
              region: [],
              unit: [],
              agentCode: [],
              agentName: [],
              position: [],
              mda: [],
              segment: [],
              pvm: [],
              others: [],
              channel: [],
            };

            // filter duplicate choices
            data.forEach((item) => {
              for (const key in dataList) {
                if (
                  !!item[nameObj[key as nameType] as dataType] &&
                  !dataList[key].includes(item[nameObj[key as nameType] as dataType])
                ) {
                  dataList[key].push(item[nameObj[key as nameType] as dataType]);
                }
              }
            });

            selectFilter(dataList);
          });
        })
        .catch((err) => console.log('Error during data Chart rendering.', err));
    } else {
      console.log('Error');
    }
  }, [chartDiv, chart]);

  useEffect(() => {
    setMyFilter({
      // 'agent_profile.agency': !!filter['businessUnit'] && filter['businessUnit'] != 'all' ? filter['businessUnit'] : undefined,
      // 'agent_profile.branch': !!filter['branch'] && filter['branch'] != 'all' ? filter['branch'] : undefined,
      // 'agent_profile.region': !!filter['region'] && filter['region'] != 'all' ? filter['region'] : undefined,
      // 'agent_profile.unit': !!filter['unit'] && filter['unit'] != 'all' ? filter['unit'] : undefined,
      agentCode: !!filter['agentCode'] && filter['agentCode'] != 'all' ? filter['agentCode'] : undefined,
      // 'agent_profile.displayName.enUs': !!filter['agentName'] && filter['agentName'] != 'all' ? filter['agentName'] : undefined,
      // startDt: !!filter['dateRange'].startDate ? filter['dateRange'].startDate : undefined,
      // endDt: !!filter['dateRange'].endDate ? filter['dateRange'].endDate : undefined,
      position: !!filter['position'] && filter['position'] != 'all' ? filter['position'] : undefined,
      // 'agent_group_mda.type': !!filter['mda'] && filter['mda'] != 'all' ? filter['mda'] : undefined,
      // 'agent_group.segment': !!filter['segment'] && filter['segment'] != 'all' ? filter['segment'] : undefined,
      // 'agent_group.PVM': !!filter['pvm'] && filter['pvm'] != 'all' ? filter['pvm'] : undefined,
      // 'agent_group.other': !!filter['others'] && filter['others'] != 'all' ? filter['others'] : undefined,
      // 'agent_profile.channel': !!filter['channel'] && filter['channel'] != 'all' ? filter['channel'] : undefined,
    });
  }, [filter]);

  //date should be handled additionally
  //*** both apply to a date as follows  */
  //*** or not : obj.campaignStartDt =  { $gte: new Date(moment(filter['dateRange'].startDate).format('YYYY-MM-DD')) } */
  useEffect(() => {
    let obj = JSON.parse(JSON.stringify(myFilter));

    // if (obj.startDt && obj.endDt) {
    //   obj.$and = [
    //     { createdAt: { $gte: new Date(moment(filter['dateRange'].startDate).format('yyyy-MM-DD')) } },
    //     { createdAt: { $lte: new Date(moment(filter['dateRange'].endDate).format('yyyy-MM-DD')) } },
    //   ];
    // } else if (obj.startDt || obj.endDt) {
    //   if (obj.startDt) {
    //     obj.createdAt = { $gte: new Date(moment(filter['dateRange'].startDate).format('yyyy-MM-DD')) };
    //   } else {
    //     obj.createdAt = { $lte: new Date(moment(filter['dateRange'].endDate).format('yyyy-MM-DD')) };
    //   }
    // }

    // delete obj.startDt;
    // delete obj.endDt;

    chart.setFilter(obj).catch((err) => console.log('Error while filtering.', err));
    chart.refresh().then(() => {
      chart.getData().then((result) => updateData(result));
    });
  }, [chart, filter, myFilter]);

  return (
    <>
      <div className="chart" ref={chartDiv} />
      <div className="data-chart" ref={dataDiv} hidden />
    </>
  );
};

export default UserReportDetailPage;
