import { makeStyles } from 'tss-react/mui';

const CONTENT_WIDTH = 300;
const CONTENT_HEIGHT = 200;
const CONTENT_PADDING = 10;
const PREVIEW_WIDTH = CONTENT_WIDTH - CONTENT_PADDING * 2;
const PREVIEW_HEIGHT = PREVIEW_WIDTH / 2;

export const useStyles = makeStyles<{ isUploading: boolean }>()((_, { isUploading }) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    backgroundColor: 'white',
  },
  contentContainer: {
    width: CONTENT_WIDTH,
    height: CONTENT_HEIGHT,
    padding: CONTENT_PADDING,
    margin: 10,
    backgroundColor: '#F4F4F4',
    borderRadius: 8,
  },
  uploadArea: {
    width: '100%',
    height: '100%',
    border: '2px dashed #C1C1C1',
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: isUploading ? 'wait' : 'pointer',
  },
  uploadAreaError: {
    borderColor: '#F018A6',
  },
  uploadIcon: {
    width: 46,
    height: 46,
    margin: 10,
  },
  imgPreview: {
    width: '100%',
    height: PREVIEW_HEIGHT,
    textAlign: 'center',
    borderRadius: 8,
    objectFit: 'cover',
  },
  filePreview: {
    width: '100%',
    height: PREVIEW_HEIGHT,
    textAlign: 'center',
    background: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    textOverflow: 'ellipsis',
    wordBreak: 'break-all',
  },
  viewButton: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 10,
    background: '#F4F4F4',
    justifyContent: 'space-between',
  },
  errorText: {
    fontSize: 10,
    color: '#f018a6',
    paddingLeft: 14,
  },
  dialogPaper: {
    width: 450,
    maxWidth: 450,
  },
  reminderContent: {
    padding: '16px 24px 0px',
    marginBottom: 20,
  },
  reminderTitle: {
    fontSize: 20,
    fontWeight: 600,
  },
  reminderImg: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  reminderFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: 8,
  },
  blueText: {
    color: '#1976D2',
  },
}));
