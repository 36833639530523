import React, { FC } from 'react';
import { useIntl } from "react-intl";
import { makeStyles } from 'tss-react/mui';
import { Button, Dialog } from '@mui/material';

type CancelDialogProps = {
  open: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
}

const useStyles = makeStyles()((theme) => ({
  dialogPaper: {
    width: 350,
    height: 200,
    maxWidth: 350,
    padding: 16,
    background: 'white',
  },
  dialogText: {
    fontSize: 14,
    textAlign: 'center',
    marginBottom: 30,
  },
}));

const CancelDialog: FC<CancelDialogProps> = ({ open, handleClose, handleConfirm }) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  return (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      open={open}
    >
      <div className={classes.dialogText}>
        {Translation('agencyCampaign.campaignType.cancelDialogText')}
      </div>
      <Button
        style={{ marginBottom: 15 }}
        variant="contained"
        color="secondary"
        onClick={handleConfirm}
      >
        {Translation("global.text.confirm")}
      </Button>
      <Button
        variant="contained"
        style={{ backgroundColor: 'white' }}
        onClick={handleClose}
      >
        {Translation("app.button.cancel")}
      </Button>
    </Dialog>
  );
}

export default CancelDialog;