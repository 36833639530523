import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { RootState } from 'src/redux/store';
import { AuthenticationState } from 'src/app/modules/Auth/_redux/authSlice';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { deepCopy } from 'src/app/modules/AgencyCampaign/utils/common-utils';
import PageFooter from 'src/app/modules/AgencyCampaign/pages/CampaignType/Form/components/PageFooter';
import {
  CampaignTypeFormPageProps,
  useStyles,
} from 'src/app/modules/AgencyCampaign/pages/CampaignType/Form/CampaignTypeDetailPage';
import {
  CampaignTypeFormMode,
  CreatorSettingItem,
  ApprovalSettingItem,
  ApprovalRoleOptionList,
  WorkflowRoleTypeEnum,
  AddLeadSettingItem,
} from 'src/app/modules/AgencyCampaign/types/campaign-type-types';
import { UpdateCampaignTypeBody } from 'src/app/modules/AgencyCampaign/network/campaignTypeCrud';
import CreateCampaignSetting from './components/CreateCampaignSetting/CreateCampaignSetting';
import ApprovalSetting from './components/ApprovalSetting/ApprovalSetting';
import { uniqueId } from 'lodash';
import { FormInstance } from 'rc-field-form';
import AddLeadSetting from './components/add-lead-setting/add-lead-setting';
import SendDocumentsSetting from './components/SendDocumentsSetting/SendDocumentsSetting';
import { LouTemplateItem, ResourceItem } from 'src/app/modules/AgencyCampaign/types/approval-types';

type CampaignTypeFormPageThreeProps = {
  approvalRoleOptionList?: ApprovalRoleOptionList;
  louTemplateList?: LouTemplateItem[];
  resources?: ResourceItem[];
} & CampaignTypeFormPageProps;

export const useWorkflowStyles = makeStyles()((theme) => ({
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  settingTitle: {
    fontSize: '1.4rem',
    fontWeight: 'bold',
  },
  addLeadTitle: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '22px',
  },
  settingSubTitle: {
    fontSize: '1.2rem',
  },
  selectContainer: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'rgba(0, 0, 0, 0.28)',
    borderRadius: 10,
    padding: '20px 20px 10px',
    margin: '0 0 20px 36px',
    maxWidth: 700,
  },
  leadSettingContainer: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'rgba(0, 0, 0, 0.28)',
    borderRadius: 10,
    padding: '20px 20px 10px',
    margin: '0 0 20px 0',
    maxWidth: 900,
  },
  bottomSpacing: {
    marginBottom: 10,
  },
  largerBottomSpacing: {
    marginBottom: 20,
  },
  uploadRowContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
  },
  uploadLabelContainer: {
    width: 100,
    boxSizing: 'border-box',
  },
  approvalLevelContainer: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'rgba(0, 0, 0, 0.28)',
    borderRadius: 10,
    padding: '20px 20px 10px',
    margin: '0 0 20px 0',
    maxWidth: 900,
  },
  approverSettingContainer: {
    marginLeft: 20,
  },
  downloadTemplateBtn: {
    color: '#38527E',
  },
}));

const initialCreatorSetting: CreatorSettingItem = {
  selectBy: 'role',
  roleList: [
    { type: WorkflowRoleTypeEnum.AGENT, values: [] },
    { type: WorkflowRoleTypeEnum.STAFF, values: [] },
    { type: WorkflowRoleTypeEnum.INCLUDE, values: [] },
    { type: WorkflowRoleTypeEnum.EXCLUDE, values: [] },
  ],
};

export const initialApprovalSettingOneItem: ApprovalSettingItem = {
  labelName: 'Name',
  selectBy: 'role',
  roleList: [
    { type: WorkflowRoleTypeEnum.AGENT, values: [] },
    { type: WorkflowRoleTypeEnum.STAFF, values: [] },
    { type: WorkflowRoleTypeEnum.INCLUDE, values: [] },
    { type: WorkflowRoleTypeEnum.EXCLUDE, values: [] },
  ],
  resetApprovalIndicator: true,
};
export class FormPools {
  /**
   * registerred form pools.
   */
  forms: Record<string, FormInstance<any>>;

  constructor() {
    this.forms = {} as Record<string, FormInstance<any>>;
  }
  /**
   *
   * @param form add form to pools
   * @param key unique id for the form
   */
  register({ form, key }: { form: FormInstance<any>; key?: string }) {
    // this.forms.push(form);
    this.forms[key ?? uniqueId()] = form;
  }

  async validate() {
    let flag = true;
    for (const key in this.forms) {
      if (Object.prototype.hasOwnProperty.call(this.forms, key)) {
        const form = this.forms[key];
        await form
          .validateFields()
          .then(() => {
            form.submit();
          })
          .catch(({ errorFields }) => {
            if (!errorFields?.length || errorFields.length < 1) {
              form.submit();
            } else {
              flag = false;
            }
          });
      }
    }
    return { ok: flag };
  }

  getFormValue(key: string) {
    return this.forms[key]?.getFieldsValue?.(true);
  }
}

const CampaignTypeFormPageThree: FC<CampaignTypeFormPageThreeProps> = ({
  formMode,
  resources,
  campaignTypeItem,
  approvalRoleOptionList,
  renderProgressBar,
  onPrevPage = () => {},
  onNextPage = () => {},
  onCancel,
  onShowCancelDialog,
  onSubmit,
  louTemplateList,
}) => {
  const [formPool] = useState(new FormPools());
  const { classes } = useStyles();
  const { classes: commonClasses } = useCommonStyles();
  const { classes: workflowClasses } = useWorkflowStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { user } = useSelector<RootState, AuthenticationState>((state) => state.auth);

  const [creatorSetting, setCreatorSetting] = useState(() =>
    campaignTypeItem?.creatorSetting ? deepCopy(campaignTypeItem.creatorSetting) : { ...initialCreatorSetting },
  );
  const [approvalSetting, setApprovalSetting] = useState(() =>
    campaignTypeItem?.approvalSetting && campaignTypeItem?.approvalSetting.length != 0
      ? deepCopy(campaignTypeItem.approvalSetting)
      : [],
  );
  const [approvalErrorState, setApprovalErrorState] = useState<boolean[]>(() =>
    campaignTypeItem?.approvalSetting && campaignTypeItem?.approvalSetting.length != 0
      ? campaignTypeItem.approvalSetting.map((item) => false)
      : [],
  );

  const onSave = async (toNextPage: boolean = false) => {
    const body: UpdateCampaignTypeBody = {
      creatorSetting,
      approvalSetting,
      updatedBy: user?.username || 'Default',
    };
    const result = await formPool.validate();
    if (result?.ok !== false) {
      const _addLeadSetting: AddLeadSettingItem = formPool.getFormValue('addLeadSetting');
      const addLeadSetting = {
        ..._addLeadSetting,
        selfAddEnabled: _addLeadSetting?.selfAddEnabled === 'true',
      };
      body.addLeadSetting = addLeadSetting;
      const _sendDocument = formPool.getFormValue('sendDocumentsSetting');
      const sendDucument =
        _sendDocument?.enableSendDocument === 'true'
          ? {
              ..._sendDocument,
              type: _sendDocument.remarkItem.type,
              sendTypes: _sendDocument.remarkItem.sendTypes,
              resourceIds: _sendDocument.remarkItem.resourceIds,
            }
          : { enableSendDocument: 'false' };
      // delete sendDucument.remarkItem;
      body.sendDocumentSetting = sendDucument;

      await onSubmit(body);
      if (toNextPage) {
        onNextPage();
      } else {
        onCancel();
      }
    }
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <div className={classes.headerRowContainer}>
            <div className={commonClasses.header}>
              {Translation(
                `agencyCampaign.campaignType.form.title.${
                  formMode === CampaignTypeFormMode.CREATE ? 'create' : 'edit'
                }`,
              )}
            </div>
          </div>
          {renderProgressBar()}
        </div>

        {approvalRoleOptionList && (
          <div className={workflowClasses.bottomSpacing}>
            <CreateCampaignSetting
              creatorSetting={creatorSetting}
              approvalRoleOptionList={approvalRoleOptionList}
              setCreatorSetting={setCreatorSetting}
            />
            <ApprovalSetting
              approvalSetting={approvalSetting}
              errorState={approvalErrorState}
              approvalRoleOptionList={approvalRoleOptionList}
              setApprovalSetting={setApprovalSetting}
              setErrorState={setApprovalErrorState}
            />

            <SendDocumentsSetting
              sendDocumentSetting={
                campaignTypeItem?.sendDocumentSetting ? { ...campaignTypeItem?.sendDocumentSetting } : null
              }
              action={formMode === CampaignTypeFormMode.CREATE ? 'create' : 'edit'}
              remarkList={[]}
              remarkIndex={undefined}
              resources={resources}
              louTemplateList={louTemplateList}
              approverName={''}
              onLoaded={({ form }: any) => {
                formPool.register({ form, key: 'sendDocumentsSetting' });
              }}
            />

            <AddLeadSetting
              addLeadSetting={{
                ...campaignTypeItem?.addLeadSetting,
                selfAddEnabled: (
                  campaignTypeItem?.addLeadSetting?.selfAddEnabled ??
                  (formMode === CampaignTypeFormMode.CREATE ||
                  !campaignTypeItem?.approvalSetting ||
                  !campaignTypeItem?.creatorSetting
                    ? 'true'
                    : '')
                ).toString(),
              }}
              onLoaded={({ form }: { form: FormInstance<any> }) => {
                formPool.register({ form, key: 'addLeadSetting' });
              }}
            />
          </div>
        )}

        <PageFooter onPrevPage={onPrevPage} onCancel={onShowCancelDialog} onSave={onSave} />
      </div>
    </>
  );
};

export default CampaignTypeFormPageThree;
