import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import {
  RECRUITMENT_BASE_PATH,
  RECRUITMENT_LICENSE_EXAM_PATH,
  RECRUITMENT_LICENSE_EXAM_SCHEDULE_PATH,
  RECRUITMENT_LICENSE_EXAM_REGISTRATION_PATH,
  RECRUITMENT_LICENSE_EXAM_RESULTS_PATH,
} from '../../../constants';
import ResultsListPage, { examResultsFilterKeys } from './results-listing-page';
import ExamResultsUploadPage from './results-upload-page';
import { NOT_FOUND_ERROR_PAGE_PATH } from '../../../../../common/constants';
import PruRoute from '../../../../../common/components/PruRoute';
import ParamsProvider from 'src/app/common/components/ParamsProvider';

export const resultsBasePath = `${RECRUITMENT_BASE_PATH}${RECRUITMENT_LICENSE_EXAM_PATH}${RECRUITMENT_LICENSE_EXAM_RESULTS_PATH}`;
const LicenseExamResultsRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect exact from={`${resultsBasePath}`} to={`${resultsBasePath}/list`} />
      <PruRoute
        exact
        path={`${resultsBasePath}/list`}
        render={(props) => (
          <ParamsProvider {...props} acceptKeys={examResultsFilterKeys}>
            <ResultsListPage />
          </ParamsProvider>
        )}
      />
      <PruRoute exact path={`${resultsBasePath}/upload`} component={ExamResultsUploadPage} />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default LicenseExamResultsRoutes;
