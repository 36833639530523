import React, { FC, useState } from 'react';
import NotificationList from './components/notificationList';
import NotificationFilter from './components/notificationFilter';
import OnTop from '../../OnTop';
import { RouteComponentProps } from 'react-router-dom';
import { NOTIFICATION_BASE_PATH, NOTIFICATION_AUTO_PATH, NOTIFICATION_AUTO_LOG_PATH } from '../../../constants';
import { fetchNotificationList, NotificationListParams, exportNotificationList } from '../../../redux/notificationCrud';
import { useDispatch } from 'react-redux';
import { PaginateList, NotificationItem } from '../../../types/notification-types';
import { useDataProvider } from 'src/app/common/utils';
import { useIntl } from 'react-intl';
import { withStyles } from 'tss-react/mui';
import { CircularProgress, Dialog } from '@mui/material';

const StyledDialog = withStyles(Dialog, {
  root: {
    'z-index': '9999999999 !important',
  },
  paper: {
    background: 'rgba(0, 0, 0, 0)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const NotificationListingPage: FC<RouteComponentProps> = ({ history }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const [isOpen, openModal] = useState<boolean>(false);
  const [rowData, setRowData] = useState<NotificationItem>();
  const [filterState, setFilterState] = useState<NotificationListParams>({
    pageNum: 1,
    pageSize: 20,
    from: 'SYSTEM',
  });
  const [filterSavedState, setFilterSavedState] = useState<NotificationListParams>({
    pageNum: 1,
    pageSize: 20,
    from: 'SYSTEM',
  });

  const [notificationList, setNotificationList] = useState<PaginateList>();
  // eslint-disable-next-line
  const [open, setOpen] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const { isLoading, refreshData } = useDataProvider<PaginateList>(
    async () => {
      try {
        return await fetchNotificationList(
          {
            ...filterSavedState,
            msgTitle: filterSavedState.msgTitle?.trim(),
            modifier: filterSavedState.modifier?.trim(),
          },
          dispatch,
        );
      } catch (err) {}
    },
    setNotificationList,
    false,
  );

  const AUTO_PATH = NOTIFICATION_BASE_PATH + NOTIFICATION_AUTO_PATH + NOTIFICATION_AUTO_LOG_PATH;

  const onClose = () => {
    setOpen(false);
  };

  const onExport = async () => {
    try {
      const conditions = { ...filterSavedState };
      setIsDownloading(true);
      delete conditions.pageSize;
      delete conditions.pageNum;
      await exportNotificationList('Auto Notification.xlsx', conditions, dispatch, Translation);
      setIsDownloading(false);
    } catch (error) {
      setIsDownloading(false);
    }
  };

  return (
    <>
      <StyledDialog open={isDownloading} onClose={onClose} fullScreen={true}>
        <CircularProgress color="secondary" />
      </StyledDialog>
      <NotificationFilter
        onSearch={() => {
          filterState.pageNum = 1;
          setFilterSavedState(filterState);
          refreshData();
        }}
        filterState={filterState}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            ...data,
          })
        }
      />
      <NotificationList
        isLoading={isLoading}
        notificationList={notificationList}
        onRefresh={() => {
          setFilterState(filterSavedState);
          refreshData();
        }}
        // onAdd={() => history.push(`${AUTO_PATH}/create`)}
        onAdd={() => {
          history.push({
            pathname: `${AUTO_PATH}/create/system`,
            state: {
              lastUrl: `${AUTO_PATH}`,
            },
          });
        }}
        onExport={onExport}
        onEdit={(id) => history.push(`${AUTO_PATH}/edit/${id}`)}
        onView={(id) => history.push(`${AUTO_PATH}/view/${id}`)}
        toSetOnTop={(row) => {
          setRowData(row);
          openModal(true);
        }}
        onChangePage={(page, rowsPerPage) => {
          page = page === 0 ? 1 : page;
          setFilterSavedState({
            ...filterSavedState,
            pageNum: page,
            pageSize: rowsPerPage,
          });
          setFilterState({
            ...filterSavedState,
            pageNum: page,
            pageSize: rowsPerPage,
          });
          refreshData();
        }}
        handleSort={(sorter) => {
          let sortItem;
          let sortOrder;
          Object.entries(sorter).forEach(([item, order]) => {
            sortItem = item;
            sortOrder = order;
          });
          setFilterSavedState({
            ...filterSavedState,
            sortItem,
            sortOrder: sortOrder ? (sortOrder as string).toUpperCase() : sortOrder,
          });
          setFilterState({
            ...filterSavedState,
            sortItem,
            sortOrder: sortOrder ? (sortOrder as string).toUpperCase() : sortOrder,
          });
          refreshData();
        }}
      />
      <OnTop row={rowData} open={isOpen} openDialog={openModal} refreshData={refreshData} />
    </>
  );
};

export default NotificationListingPage;
