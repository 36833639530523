import { makeStyles } from 'tss-react/mui';
import { Button, Theme } from '@mui/material';
import React, { FC } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { toAbsoluteUrl } from 'src/app/common/utils';
import { ROOT_PATH } from '../../common/constants';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { logout } from '../Auth/_redux/authSlice';

const ForbiddenErrorPage: FC<RouteComponentProps> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const onLogout = () => {
    dispatch(logout());
    history.push(ROOT_PATH);
  };

  return (
    <div className={classes.wrapper}>
      <Button variant="contained" color="inherit" onClick={onLogout} className={classes.backBtnContainer}>
        {Translation('common.logout')}
      </Button>
      <div className={classes.content}>
        <h1 className={classes.forbiddenText} style={{ fontSize: '60px' }}>
          Forbidden
        </h1>
        <p className={classes.desc}>This account have no permissions in the portal</p>
      </div>
    </div>
  );
};

export default ForbiddenErrorPage;

const useStyles = makeStyles()((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    flex: 1,
    backgroundImage: `url(${toAbsoluteUrl('/media/error/bg1.jpg')})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  backBtnContainer: {
    position: 'absolute',
    right: 40,
    top: 40,
    display: 'inline',
  },
  content: {
    width: '100%',
    height: '100%',
    paddingLeft: 32,
    [theme.breakpoints.up('md')]: {
      padding: 97.5,
    },
  },
  forbiddenText: {
    fontSize: 60,
    marginTop: 48,
    fontWeight: 700,
  },
  desc: {
    fontSize: 20,
    fontWeight: 300,
  },
}));
