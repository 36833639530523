import React, { FC } from 'react';
import { useIntl } from "react-intl";
import { FormControlLabel } from '@mui/material';
import { CheckCircle, RadioButtonUnchecked } from '@mui/icons-material';
import AcmCheckbox from 'src/app/modules/AgencyCampaign/common/AcmCheckbox';
import { useStyles } from 'src/app/modules/AgencyCampaign/pages/CampaignType/Form/CampaignTypeDetailPage';
import { SectionFieldItem, EligibilityRuleOption } from 'src/app/modules/AgencyCampaign/types/campaign-type-types';

type CheckEligibilityRuleProps = {
  field: SectionFieldItem;
  ruleIndex: number;
  eligibilityRuleOptionList: EligibilityRuleOption[];
  setField: (field: SectionFieldItem) => void;
}

const CheckEligibilityRule: FC<CheckEligibilityRuleProps> = ({ field, ruleIndex, eligibilityRuleOptionList, setField }) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const rule = field.rules[ruleIndex];
  let eligibilityRuleSelected = [...(rule.value as string[])];

  const handleSelectRule = (eligibilityRule: any) => {
    const foundIndex = eligibilityRuleSelected.findIndex(selectedItem => selectedItem === eligibilityRule._id);
    if (foundIndex === -1) {
      eligibilityRuleSelected.push(eligibilityRule._id);
    } else {
      eligibilityRuleSelected.splice(foundIndex, 1);
    }
    const newField = {...field};
    newField.rules[ruleIndex].value = eligibilityRuleSelected;
    setField(newField);
  }

  return (
    <div className={classes.sectionMargin}>
      <div>{ruleIndex + 1}. {Translation(`agencyCampaign.rule.${rule.key}`)} ?</div>
      <div>
        <FormControlLabel
          style={{ margin: "2px 12px 2px 0" }}
          disabled={field.forcedMandatory}
          control={
            <AcmCheckbox
              icon={<RadioButtonUnchecked />}
              checkedIcon={<CheckCircle />}
              checked={eligibilityRuleSelected.length > 0}
              onChange={e => {
                if (e.target.checked) {
                  eligibilityRuleSelected = eligibilityRuleOptionList.map(eligibilityRule => eligibilityRule._id);
                  const newField = {...field};
                  newField.rules[ruleIndex].value = eligibilityRuleSelected;
                  setField(newField);
                }
              }}
            />
          }
          label={Translation("app.checkbox.yes")}
          labelPlacement="end"
        />
        <div style={{ paddingLeft: 20 }}>
          {eligibilityRuleOptionList.map(eligibilityRule => {
            const isRuleSelected = !!eligibilityRuleSelected.find(selectedItem => selectedItem === eligibilityRule._id);
            return (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div>
                  <AcmCheckbox
                    checked={isRuleSelected}
                    onClick={() => handleSelectRule(eligibilityRule)}
                  />
                </div>
                <div>{eligibilityRule.name}</div>
              </div>
            );
          })}
        </div>
      </div>
      <div>
        <FormControlLabel
          style={{ margin: "2px 0" }}
          disabled={field.forcedMandatory}
          control={
            <AcmCheckbox
              icon={<RadioButtonUnchecked />}
              checkedIcon={<CheckCircle />}
              checked={eligibilityRuleSelected.length === 0}
              onChange={e => {
                if (e.target.checked) {
                  eligibilityRuleSelected = [];
                  const newField = {...field};
                  newField.rules[ruleIndex].value = eligibilityRuleSelected;
                  setField(newField);
                }
              }}
            />
          }
          label={Translation("app.checkbox.no")}
          labelPlacement="end"
        />
      </div>
    </div>
  )
}

export default CheckEligibilityRule;