import React, { FC, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { makeStyles } from 'tss-react/mui';
import { FormControl, Select, MenuItem, Button, Dialog } from '@mui/material';
import { RootState } from 'src/redux/store';
import { authenticate, setTokenInfo, AuthenticationState } from 'src/app/modules/Auth/_redux/authSlice';
import {
  decodeJWT,
  generateJwtByRegionChannel,
  GenerateJwtByRegionChannelBody,
} from 'src/app/modules/Auth/_redux/authCrud';
import { getConfigurations, useJWT } from 'src/app/common/utils';
import { fetchConfigs } from 'src/app/modules/Config/network/configCrud';

const useStyles = makeStyles()((theme) => ({
  marginSpacing: {
    margin: '0 10px 0 0',
  },
  alertDialog: {
    width: 340,
    padding: 20,
    paddingBottom: 15,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  alertText: {
    fontWeight: 500,
    fontSize: 14,
    marginBottom: 20,
  },
  alertButton: {
    width: 300,
  },
}));

type RegionChannelDropdownFormState = {
  selectedRegion: string;
  selectedChannel: string;
};

const initialState: RegionChannelDropdownFormState = {
  selectedRegion: '',
  selectedChannel: '',
};

const RegionChannelDropdown: FC = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const currentRef = useRef<any>(null);

  const [formState, setFormState] = useState<RegionChannelDropdownFormState>(initialState);
  const [defaultFormState, setDefaultFormState] = useState<RegionChannelDropdownFormState>(initialState);
  const [showButton, setShowButton] = useState<boolean>(false);
  const [showRegionMenu, setShowRegionMenu] = useState<boolean>(false);
  const [showChannelMenu, setShowChannelMenu] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const { regionChannelList = [] } = useSelector<RootState, AuthenticationState>((state) => state.auth);
  const enableRegion = !!getConfigurations()?.regionChannelConfig?.enableRegion;
  const enableChannel = !!getConfigurations()?.regionChannelConfig?.enableChannel;
  const jwt = useJWT();

  useEffect(() => {
    if (jwt) {
      const newFormState = {
        selectedRegion: jwt.region,
        selectedChannel: jwt.channel,
      };
      setFormState(newFormState);
      setDefaultFormState(newFormState);
    }
  }, []);

  const handleClickOutside = (event: MouseEvent) => {
    if (currentRef.current && !currentRef.current.contains(event.target)) {
      setShowAlert(true);
    }
  };

  useEffect(() => {
    if (showButton && !showRegionMenu && !showChannelMenu && !showAlert) {
      window.addEventListener('mousedown', handleClickOutside);
      return () => {
        window.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [currentRef, showButton, showRegionMenu, showChannelMenu, showAlert]);

  const onConfirm = async () => {
    const body: GenerateJwtByRegionChannelBody = {
      region: formState.selectedRegion,
      channel: formState.selectedChannel,
    };
    try {
      const response = await generateJwtByRegionChannel(body, dispatch);
      const { accessToken, expiresIn, refreshToken } = response;
      if (accessToken && expiresIn && refreshToken) {
        const jwt = await decodeJWT(accessToken);
        window.localStorage.setItem('jwt', accessToken);
        dispatch(authenticate(accessToken));
        dispatch(setTokenInfo(jwt));
        const expireDate = moment(new Date()).add(Number(expiresIn), 's').toDate();
        const abondonSession = moment(new Date()).add(1, 'd').toDate();
        window.localStorage.setItem('expireDate', expireDate.toISOString());
        window.localStorage.setItem('abondonSession', abondonSession.toISOString());
        window.localStorage.setItem('refreshToken', refreshToken);
        await fetchConfigs(dispatch, body);
      }
    } catch (err) {}
    setShowButton(false);
    window.location.reload();
  };

  const onCancel = () => {
    setFormState(defaultFormState);
    setShowButton(false);
  };

  return (
    <div className="d-flex align-items-center" ref={currentRef}>
      {enableRegion && (
        <FormControl margin="dense" variant="outlined" className={classes.marginSpacing}>
          <Select
            open={showRegionMenu}
            onOpen={() => setShowRegionMenu(true)}
            onClose={() => setShowRegionMenu(false)}
            value={formState.selectedRegion}
            onChange={(e) => {
              const channelList = regionChannelList.find((item) => item.region === e.target.value)?.channels;
              setFormState({
                selectedRegion: e.target.value as string,
                selectedChannel: channelList ? channelList[0] : '',
              });
              setShowButton(true);
            }}
          >
            {regionChannelList.map((item) => (
              <MenuItem key={item.region} value={item.region}>
                {item.region}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      {enableChannel && (
        <FormControl margin="dense" variant="outlined" className={classes.marginSpacing}>
          <Select
            open={showChannelMenu}
            onOpen={() => setShowChannelMenu(true)}
            onClose={() => setShowChannelMenu(false)}
            value={formState.selectedChannel}
            onChange={(e) => {
              setFormState({
                ...formState,
                selectedChannel: e.target.value as string,
              });
              setShowButton(true);
            }}
          >
            {(regionChannelList.find((item) => item.region === formState.selectedRegion)?.channels || []).map(
              (item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ),
            )}
          </Select>
        </FormControl>
      )}

      {showButton && (
        <>
          <Button variant="contained" color="secondary" className={classes.marginSpacing} onClick={() => onConfirm()}>
            {Translation('app.button.confirm')}
          </Button>
          <Button variant="contained" onClick={() => onCancel()}>
            {Translation('app.button.cancel')}
          </Button>
        </>
      )}

      {showAlert && (
        <Dialog open={showAlert}>
          <div className={classes.alertDialog}>
            <div className={classes.alertText}>{Translation('region_channel.notify_msg')}</div>
            <div>
              <Button
                variant="contained"
                color="secondary"
                className={classes.alertButton}
                onClick={() => setShowAlert(false)}
              >
                {Translation('global.text.okay')}
              </Button>
            </div>
          </div>
        </Dialog>
      )}
    </div>
  );
};

export default RegionChannelDropdown;
