import { FC } from 'react';
import { useIntl } from 'react-intl';
import { useLang } from 'src/app/i18n';
import { FormMode, PaginateList } from 'src/app/common/types';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { getDefaultDisplayDate } from 'src/app/common/utils';
import { FormDialogState, EventCategoryItem } from 'src/app/modules/event-v2/types';

type EventCategoryListProps = {
  isLoading: boolean;
  eventCategoryList?: PaginateList<EventCategoryItem>;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
  onSort: (newSortState: { key: string; value?: string }) => void;
  setFormDialogState: (newState: FormDialogState) => void;
};

export const EventCategoryList: FC<EventCategoryListProps> = ({
  isLoading,
  eventCategoryList,
  onRefresh,
  onChangePage,
  onSort,
  setFormDialogState,
}) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const locale = useLang();

  return (
    <PruTable
      title={Translation('title.event.category')}
      disableBulkSelect
      headerBtnDef={[
        {
          color: 'secondary',
          title: Translation('app.button.add'),
          onClick: () => setFormDialogState({ open: true, formMode: FormMode.CREATE }),
        },
      ]}
      operationDef={[
        {
          title: Translation('section.common.operation.view'),
          tooltipText: 'View Event Category',
          onClick: (row) => setFormDialogState({ open: true, formMode: FormMode.VIEW, eventCategoryItem: row }),
        },
        {
          title: Translation('section.common.operation.edit'),
          tooltipText: 'Edit Event Category',
          onClick: (row) => setFormDialogState({ open: true, formMode: FormMode.EDIT, eventCategoryItem: row }),
        },
      ]}
      columnDef={[
        {
          isId: true,
          hidden: true,
          keyIndex: '_id',
          displayName: '',
          renderData: () => '',
        },
        {
          keyIndex: 'name',
          displayName: Translation('event.common.category'),
          renderData: (row) => row.name[locale] || '-',
        },
        {
          keyIndex: 'description',
          displayName: Translation('event.common.description'),
          renderData: (row) => row.description || '-',
        },
        {
          keyIndex: 'accessibility',
          displayName: Translation('event.common.accessibility'),
          renderData: (row) => Translation(`event.common.accessibility.${row.accessibility.toLowerCase()}`),
        },
        {
          keyIndex: 'audience',
          displayName: Translation('event.common.audience'),
          renderData: (row) => Translation(`event.common.audience.${row.audience.toLowerCase()}`),
        },
        {
          keyIndex: 'status',
          displayName: Translation('event.common.status'),
          renderData: (row) => Translation(`event.common.category_status.${row.status.toLowerCase()}`),
        },
        {
          keyIndex: 'lastUpdatedBy',
          displayName: Translation('component.formLabel.last-updated-by'),
          renderData: (row) => row.lastUpdatedBy || '-',
        },
        {
          keyIndex: 'updatedAt',
          displayName: Translation('component.formLabel.last-updated-time'),
          renderData: (row) => getDefaultDisplayDate(row.updatedAt, 'datetime'),
          sortable: true,
          replaceSortState: true,
          onSort: (sort) => onSort({ key: 'updatedAt', value: sort['updatedAt'] }),
        },
      ]}
      isLoading={isLoading}
      onRefresh={onRefresh}
      dataSource={eventCategoryList?.docs}
      defaultRowsPerPage={20}
      totalPages={eventCategoryList?.totalPages}
      totalRecords={eventCategoryList?.totalDocs}
      onChangePage={onChangePage}
    />
  );
};
