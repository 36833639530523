import React, { FC } from 'react';
import { FeedbackResultPaginateList } from '../../../../types/feedback-type';
import { useServerLocale } from 'src/app/i18n';
import { useIntl } from 'react-intl';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { useHistory } from 'react-router-dom';
import { getDefaultDisplayDate } from 'src/app/common/utils';
import { FEEDBACK_BASE_PATH, FEEDBACK_RESULT_PATH } from '../../../../constants';
import { Rating } from '@mui/lab';

type FeedbackResultListProps = {
  isLoading: boolean;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
  feedbackResultList?: FeedbackResultPaginateList;
};

enum SentimentType {
  POOR = 'performanceIndicator.poor',
  AVERAGE = 'performanceIndicator.neutral',
  GOOD = 'performanceIndicator.good',
}

const getSentimentType = (value?: number) => {
  if (value === undefined) {
    return '-';
  }
  const poor = -1 + 2 / 3;
  const average = poor + 2 / 3;
  if (value < poor) {
    return SentimentType.POOR;
  } else if (value < average) {
    return SentimentType.AVERAGE;
  } else {
    return SentimentType.GOOD;
  }
};

const FeedbackResultList: FC<FeedbackResultListProps> = ({
  isLoading,
  onRefresh,
  onChangePage,
  feedbackResultList,
}) => {
  const history = useHistory();
  const locale = useServerLocale();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  return (
    <PruTable
      disableBulkSelect
      dataSource={feedbackResultList?.feedbacks}
      isLoading={isLoading}
      onRefresh={onRefresh}
      title={Translation('feedbackResult.list')}
      operationDef={[
        {
          title: Translation('section.common.operation.view'),
          tooltipText: 'View Result Detail',
          onClick: (row) => history.push(`${FEEDBACK_BASE_PATH}${FEEDBACK_RESULT_PATH}/view/${row._id}`),
        },
      ]}
      columnDef={[
        {
          isId: true,
          hidden: true,
          keyIndex: '_id',
          displayName: '',
          renderData: () => '',
        },
        {
          keyIndex: 'agentCode',
          displayName: Translation('feedbackResult.common.agentCode'),
          renderData: (row) => row.agentCode,
        },
        {
          keyIndex: 'sentiment',
          align: 'center',
          displayName: Translation('feedbackResult.common.sentiment'),
          renderData: (row) => {
            //@ts-ignore
            const sentimentType = getSentimentType(row.sentiment?.documentSentiment?.score);
            let color: string = 'black';
            if (sentimentType === SentimentType.POOR) {
              color = '#ED1B2E';
            } else if (sentimentType === SentimentType.AVERAGE) {
              color = '#e6d450';
            } else if (sentimentType === SentimentType.GOOD) {
              color = '#8bcf69';
            }
            return <span style={{ color, fontWeight: 600 }}>{Translation(sentimentType)}</span>;
          },
        },
        {
          keyIndex: 'rating',
          align: 'center',
          displayName: Translation('feedbackResult.common.rating'),
          renderData: (row) => <Rating readOnly value={row.rating ?? 0} />,
        },
        {
          keyIndex: 'category',
          align: 'center',
          displayName: Translation('feedbackResult.common.category'),
          renderData: (row) => row.category[locale] || '-',
        },
        {
          keyIndex: 'subCategory',
          align: 'center',
          displayName: Translation('feedbackResult.common.subCategory'),
          renderData: (row) => row.subCategory[locale] || '-',
        },
        {
          keyIndex: 'responsibleParty',
          align: 'center',
          displayName: Translation('feedbackResult.common.responsibleParty'),
          renderData: (row) => row.responsibleParty || '-',
        },
        {
          keyIndex: 'createdAt',
          align: 'center',
          displayName: Translation('feedbackResult.common.submitDate'),
          renderData: (row) => getDefaultDisplayDate(row.createdAt, 'datetime'),
        },
      ]}
      totalPages={feedbackResultList?.totalPages}
      totalRecords={feedbackResultList?.totalRecords}
      onChangePage={onChangePage}
    />
  );
};

export default FeedbackResultList;
