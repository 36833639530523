import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { PruDateTimePicker } from 'src/app/common/components/PruDatePicker';
import { useIntl } from 'react-intl';

interface RangePickerProps {
  value?: any[];
  onChange?: (e: any[]) => void;
  disabled?: boolean;
  defaultValue?: any[];
}

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    // flexWrap: 'wrap',
    flexDirection: 'row',
  },
  // textField: {
  //   marginLeft: theme.spacing(1),
  //   marginRight: theme.spacing(1),
  //   width: 200,
  // },
  divider: {
    display: 'flex',
    // flexWrap: 'wrap',
    alignItems: 'center',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

export default function RangePicker(props: RangePickerProps) {
  const { classes } = useStyles();
  const { value = [], onChange, disabled, defaultValue = [] } = props;
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const handleChange = (key: number) => (date: Date | null) => {
    if (!onChange) return;
    const newValue = [...(value || [])];
    newValue[key] = date;
    onChange(newValue);
  };

  const placeSelect = Translation('app.input.placeholder.please-select');

  return (
    <div className={classes.container}>
      <PruDateTimePicker
        disabled={disabled}
        maxDate={(value && value[1]) || undefined}
        ampm={false}
        format="DD/MM/YYYY HH:mm:ss"
        value={(value && value[0]) || defaultValue[0] || null}
        onChange={handleChange(0)}
        label={placeSelect}
      />
      <div className={classes.divider}>-</div>
      <PruDateTimePicker
        disabled={disabled}
        minDate={(value && value[0]) || undefined}
        ampm={false}
        format="DD/MM/YYYY HH:mm:ss"
        value={(value && value[1]) || defaultValue[1] || null}
        onChange={handleChange(1)}
        label={placeSelect}
      />
    </div>
  );
}
