import React, { FC } from 'react';
import { Redirect, RouteComponentProps, Switch } from 'react-router-dom';
import PruRoute from '../../../../../common/components/PruRoute';
import AgentsListingPage from './List/AgentsListingPage';
import AgentsReportPage from './Create/AgentsReportPage';
import ParamsProvider from '../../../../../common/components/ParamsProvider';
import { reportFilterKeys } from '../../../constants';

const AgentsPage: FC<RouteComponentProps> = ({ history, location, match }) => {
  return (
    <Switch>
      <Redirect exact from={match.path} to={`${match.path}/list`}/>
      <PruRoute
        exact
        path={`${match.path}/list`}
        render={(props) => (
          <ParamsProvider {...props} acceptKeys={reportFilterKeys}>
            <AgentsListingPage />
          </ParamsProvider>
        )}
      />
      <PruRoute
        exact
        path={`${match.path}/create`}
        component={AgentsReportPage}
      />
    </Switch>
  )
};

export default AgentsPage;
