import React, { CSSProperties, useReducer, useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Dialog, Button, TextField, LinearProgress, Link } from '@mui/material';
import { useErrorHandler, fileUpload, ErrorFieldType, ErrorFieldDef } from '../utils';
import { useIntl } from 'react-intl';
import { createBlob, getBlob } from '../network';
import { useDispatch } from 'react-redux';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import { AttachmentDef } from '../types/common-types';

export enum FormDialogItemType {
  TEXT = 'TEXT',
  TEXT_AREA = 'TEXT_AREA',
  ATTACHMENT = 'ATTACHMENT',
}

export type FormItemErrorDef = {
  key: string;
  type: ErrorFieldType;
  condition?: (currentState: any) => boolean;
  message: string;
};

export type FormDialogTextDef = {
  type: FormDialogItemType.TEXT;
  regExp?: RegExp;
  style?: CSSProperties;
};

export type FormDialogTextAreaDef = {
  type: FormDialogItemType.TEXT_AREA;
  regExp?: RegExp;
  style?: CSSProperties;
};

export type FormDialogAttachmentDef = {
  type: FormDialogItemType.ATTACHMENT;
};

export type FormDialogItemDef = FormDialogTextDef | FormDialogTextAreaDef | FormDialogAttachmentDef;

export type FormDialogFieldDef = {
  key: string;
  displayName: string | JSX.Element;
  errorDef?: FormItemErrorDef[];
  item: FormDialogItemDef;
};

type FormDialogProps<T> = {
  disabled?: boolean;
  title?: string;
  dataSource?: T;
  fieldDef: FormDialogFieldDef[];
  onFinish: (data: FormState<T>) => void;
  onClose: () => void;
  module?: string;
};

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 25,
    minWidth: 500,
  },
  header: {
    fontSize: '1.2rem',
    lineHeight: 1.2,
    marginBottom: 10,
  },
  fieldContainer: {
    width: 150,
    boxSizing: 'border-box',
  },
  textAreaRowContainer: {
    width: '100%',
    display: 'flex',
  },
  textAreaFieldContainer: {
    paddingTop: 15,
    width: 150,
    boxSizing: 'border-box',
  },
  textArea: {
    lineHeight: 1.5,
    minHeight: 40,
  },
  noPaddingFieldContainer: {
    flexBasis: '20%',
    width: 150,
    boxSizing: 'border-box',
  },
  footer: {
    marginTop: 20,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },
  errorText: {
    fontSize: 9,
    color: '#F018A6',
  },
}));

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: string;
    value: any;
  };
};

type FormAction = ModifyFieldAction;

const formReducer = <T extends Record<string, unknown>>(state: FormState<T>, action: FormAction): FormState<T> => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    default:
      return state;
  }
};

type FormState<T> = T;

const formStateInitialiser = <T extends Record<string, unknown>>(
  fieldDef: FormDialogFieldDef[],
  dataSource?: T,
): FormState<T> => {
  //@ts-ignore
  let initState: FormState<T> = {};
  fieldDef.forEach((def) => {
    switch (def.item.type) {
      case FormDialogItemType.TEXT:
      case FormDialogItemType.TEXT_AREA:
        //@ts-ignore
        initState[def.key] = dataSource && dataSource[def.key] ? dataSource[def.key] : '';
        break;
      case FormDialogItemType.ATTACHMENT:
        //@ts-ignore
        initState[def.key] = dataSource && dataSource[def.key] ? dataSource[def.key] : undefined;
        break;
      default:
        break;
    }
  });
  return initState;
};

const FormDialog = <T extends Record<string, unknown>>({
  disabled,
  title,
  dataSource,
  fieldDef,
  onClose,
  onFinish,
  module,
}: FormDialogProps<T>) => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const [imageUploadProgress, setImageUploadProgress] = useState<number>();

  const [formState, formDispatch] = useReducer(formReducer, formStateInitialiser<T>(fieldDef, dataSource));

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler, immediateErrorValidator } = useErrorHandler(
    formState,
    (() => {
      let errorDef: ErrorFieldDef[] = [];

      fieldDef.forEach((def) => {
        if (def.errorDef) {
          def.errorDef.forEach((error, index) => {
            errorDef.push({
              name: error.key,
              fieldType: error.type,
              //@ts-ignore
              condition: error.condition ? () => error.condition(formState) : undefined,
            });
          });
        }
      });
      return errorDef;
    })(),
  );

  const retrieveErrorMessage = (errorDef: FormItemErrorDef[]): string | undefined => {
    let errorMessage = undefined;
    for (const def of errorDef) {
      if (errorState[def.type][def.key]) {
        errorMessage = def.message;
        break;
      }
    }
    return errorMessage;
  };

  const dismissMultipleError = (errorDef: FormItemErrorDef[], currentState: any): void => {
    let dismissArr: string[] = [];
    errorDef.forEach((def) => {
      if (def.type === ErrorFieldType.MANDATORY) {
        if (
          def.condition &&
          def.condition({
            ...formState,
            [def.key]: currentState,
          }) === false
        ) {
          dismissArr.push(def.key);
        }
        if (!def.condition && !!currentState) {
          dismissArr.push(def.key);
        }
      }
    });
    onDismissErrorHandler(dismissArr, true);
  };

  useEffect(() => {
    immediateErrorValidator();
    // eslint-disable-next-line
  }, [formState]);

  const handleFile = async (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: string,
    errorDef: FormItemErrorDef[],
  ) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      try {
        const createBlobRes = await createBlob({ mimeType: file.type, accessLevel: 'public', module }, dispatch);
        await fileUpload(createBlobRes.url, file, setImageUploadProgress);
        const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);
        const result = blobDetail[0];
        if (result) {
          const currentState = {
            blobId: result.blobId,
            filename: file.name,
            url: result.url,
          };
          formDispatch({
            type: 'MODIFY_FIELD',
            payload: {
              field: fieldName,
              value: currentState,
            },
          });
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: `Upload file successfully - ${file.name}`,
              },
            ]),
          );
          dismissMultipleError(errorDef, currentState);
        }
      } finally {
        setImageUploadProgress(undefined);
      }
    }
  };

  const onSubmit = () => {
    const { hasError } = onSubmitErrorValidator();
    if (!hasError) {
      onFinish(formState as T);
      onClose();
    }
  };

  return (
    <Dialog
      open
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
    >
      <div className={classes.container}>
        {title && <div className={classes.header}>{title}</div>}

        {fieldDef.map((def) => {
          switch (def.item.type) {
            case FormDialogItemType.TEXT:
              const textItem = def.item as FormDialogTextDef;
              let textErrorMessage = def.errorDef ? retrieveErrorMessage(def.errorDef) : undefined;
              return (
                <div key={`form-dialog-item-${def.key}`} className={classes.rowContainer} style={{ margin: '8px 0' }}>
                  <div className={classes.fieldContainer}>
                    <span className={classes.field}>
                      {def.displayName}
                      {def.errorDef && def.errorDef.find((def) => def.type === ErrorFieldType.MANDATORY) && (
                        <span className={classes.mandatory}>*</span>
                      )}
                      <span> :</span>
                    </span>
                  </div>
                  <TextField
                    disabled={disabled}
                    fullWidth
                    style={textItem.style}
                    error={!!textErrorMessage}
                    margin="dense"
                    variant="outlined"
                    helperText={textErrorMessage}
                    value={formState[def.key]}
                    onChange={(e) => {
                      let text = e.target.value;
                      if (textItem.regExp) {
                        text = text.replace(textItem.regExp, '');
                      }
                      if (def.errorDef) {
                        dismissMultipleError(def.errorDef, text);
                      }
                      formDispatch({ type: 'MODIFY_FIELD', payload: { field: def.key, value: text } });
                    }}
                  />
                </div>
              );
            case FormDialogItemType.TEXT_AREA:
              let textAreaItem = def.item as FormDialogTextAreaDef;
              let textAreaErrorMessage = def.errorDef ? retrieveErrorMessage(def.errorDef) : undefined;
              return (
                <div
                  key={`form-dialog-item-${def.key}`}
                  className={classes.textAreaRowContainer}
                  style={{ margin: '8px 0' }}
                >
                  <div className={classes.textAreaFieldContainer}>
                    <span className={classes.field}>
                      {def.displayName}
                      {def.errorDef && def.errorDef.find((def) => def.type === ErrorFieldType.MANDATORY) && (
                        <span className={classes.mandatory}>*</span>
                      )}
                      <span> :</span>
                    </span>
                  </div>
                  <TextField
                    disabled={disabled}
                    multiline
                    fullWidth
                    style={textAreaItem.style}
                    error={!!textAreaErrorMessage}
                    margin="dense"
                    variant="outlined"
                    InputProps={{
                      classes: {
                        input: classes.textArea,
                      },
                    }}
                    helperText={textAreaErrorMessage}
                    value={formState[def.key]}
                    onChange={(e) => {
                      let text = e.target.value;
                      if (textAreaItem.regExp) {
                        text = text.replace(textAreaItem.regExp, '');
                      }
                      if (def.errorDef) {
                        dismissMultipleError(def.errorDef, text);
                      }
                      formDispatch({ type: 'MODIFY_FIELD', payload: { field: def.key, value: text } });
                    }}
                  />
                </div>
              );
            case FormDialogItemType.ATTACHMENT:
              let attachmentErrorMessage = def.errorDef ? retrieveErrorMessage(def.errorDef) : undefined;
              return (
                <div
                  key={`form-dialog-item-${def.key}`}
                  className={classes.textAreaRowContainer}
                  style={{ margin: '8px 0' }}
                >
                  <div style={{ paddingTop: 3 }} className={classes.fieldContainer}>
                    <span className={classes.field}>
                      {def.displayName}
                      {def.errorDef && def.errorDef.find((def) => def.type === ErrorFieldType.MANDATORY) && (
                        <span className={classes.mandatory}>*</span>
                      )}
                      <span> :</span>
                    </span>
                  </div>
                  <div style={{ width: '100%' }}>
                    <input
                      id={`upload-attachment-${def.key}`}
                      hidden
                      type="file"
                      accept="image/*"
                      onClick={(e) => {
                        const element = e.target as HTMLInputElement;
                        element.value = '';
                      }}
                      onChange={(e) => handleFile(e, def.key, def.errorDef || [])}
                    />
                    <div className={classes.rowContainer}>
                      <Button
                        disabled={disabled}
                        variant="contained"
                        color="secondary"
                        onClick={() => document.getElementById(`upload-attachment-${def.key}`)!.click()}
                      >
                        {Translation('app.button.chooseFile')}
                      </Button>
                      {!!formState[def.key] && !!(formState[def.key] as AttachmentDef)?.filename && (
                        <Link
                          style={{ marginLeft: 14 }}
                          target="_blank"
                          rel="noopener"
                          color="secondary"
                          href={(formState[def.key] as AttachmentDef).url}
                        >
                          {(formState[def.key] as AttachmentDef)?.filename}
                        </Link>
                      )}
                      {attachmentErrorMessage && (
                        <div style={{ marginLeft: 10 }} className={classes.errorText}>
                          {attachmentErrorMessage}
                        </div>
                      )}
                    </div>
                    {!!imageUploadProgress && (
                      <LinearProgress
                        style={{ marginTop: 10 }}
                        variant="determinate"
                        color="secondary"
                        value={imageUploadProgress}
                      />
                    )}
                  </div>
                </div>
              );
            default:
              return <></>;
          }
        })}

        <div className={classes.footer}>
          <div>
            <Button variant="contained" onClick={onClose}>
              {Translation('app.button.cancel')}
            </Button>
            <Button
              disabled={disabled}
              style={{ marginLeft: 25 }}
              variant="contained"
              color="secondary"
              onClick={onSubmit}
            >
              {Translation('app.button.submit')}
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default FormDialog;
