import React, { FC, HTMLAttributes, useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { FormControlLabel, Checkbox, CircularProgress } from '@mui/material';
import { map } from 'lodash';
import { DesignationItem } from '../types/common-types';
import { useDispatch } from 'react-redux';
import { getDesignations } from '../network';
import { useUpdateEffect } from '../utils';
import { useServerLocale } from 'src/app/i18n';

type DesignationSelectSectionProps = {
  designations: string[];
  onChangeDesignations: (designations: string[]) => void;
};

enum TargetApplicantGroup {
  RM_AND_UP = 'RM & Up',
  ALL_AGENT_EXCLUDE_LA = 'All Agent exclude LA',
  ALL_AGENT_INCLUDE_LA = 'All Agent include LA',
}

const useStyles = makeStyles()(() => ({
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 0',
  },
}));

const targetApplicantDesignationMap = {
  [TargetApplicantGroup.RM_AND_UP]: ['G5', 'G4', 'G3', 'G2'],
  [TargetApplicantGroup.ALL_AGENT_EXCLUDE_LA]: ['G1L3', 'G1L2', 'G1L1', 'SIC2', 'SIC1', 'SLU', 'LU'],
  [TargetApplicantGroup.ALL_AGENT_INCLUDE_LA]: ['LA'],
};

export const designationSequence = ['G5', 'G4', 'G3', 'G2', 'G1L3', 'G1L2', 'G1L1', 'SIC2', 'SIC1', 'SLU', 'LU', 'LA'];

const DesignationSelectSection: FC<DesignationSelectSectionProps & HTMLAttributes<HTMLDivElement>> = ({
  designations,
  onChangeDesignations,
  ...rest
}) => {
  const locale = useServerLocale();
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [targetApplicantGroup, setTargetApplicantGroup] = useState<TargetApplicantGroup | undefined>(undefined);
  const [designationList, setDesignationList] = useState<DesignationItem[]>([]);

  useUpdateEffect(() => {
    if (targetApplicantGroup) {
      let newDesignations: string[] = [];
      if (targetApplicantGroup === TargetApplicantGroup.RM_AND_UP) {
        newDesignations = newDesignations.concat(targetApplicantDesignationMap[TargetApplicantGroup.RM_AND_UP]);
      } else if (targetApplicantGroup === TargetApplicantGroup.ALL_AGENT_EXCLUDE_LA) {
        newDesignations = newDesignations.concat(targetApplicantDesignationMap[TargetApplicantGroup.RM_AND_UP]);
        newDesignations = newDesignations.concat(
          targetApplicantDesignationMap[TargetApplicantGroup.ALL_AGENT_EXCLUDE_LA],
        );
      } else if (targetApplicantGroup === TargetApplicantGroup.ALL_AGENT_INCLUDE_LA) {
        newDesignations = newDesignations.concat(targetApplicantDesignationMap[TargetApplicantGroup.RM_AND_UP]);
        newDesignations = newDesignations.concat(
          targetApplicantDesignationMap[TargetApplicantGroup.ALL_AGENT_EXCLUDE_LA],
        );
        newDesignations = newDesignations.concat(
          targetApplicantDesignationMap[TargetApplicantGroup.ALL_AGENT_INCLUDE_LA],
        );
      }
      onChangeDesignations(newDesignations);
    }
  }, [targetApplicantGroup]);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      try {
        const data = await getDesignations(dispatch);
        setDesignationList(data);
      } finally {
        setIsLoading(false);
      }
    };
    getData();
    // eslint-disable-next-line
  }, []);

  return (
    <div {...rest}>
      <div style={{ borderBottom: '1px solid #DDDDDD' }} className={classes.rowContainer}>
        {map(TargetApplicantGroup, (option) => (
          <FormControlLabel
            key={`target-applicant-group-${option}`}
            style={{ flexBasis: '20%', margin: '5px 0' }}
            control={
              <Checkbox
                checked={targetApplicantGroup === option}
                onChange={(e) => {
                  if (e.target.checked) {
                    setTargetApplicantGroup(option);
                  }
                }}
              />
            }
            label={option}
            labelPlacement="end"
          />
        ))}
      </div>
      {isLoading ? (
        <div className={classes.loadingContainer}>
          <CircularProgress color="secondary" size={40} />
        </div>
      ) : (
        <div className={classes.rowContainer}>
          {designationList.map((designation) => (
            <FormControlLabel
              key={`target-applicant-designation-${designation.code}`}
              style={{ flexBasis: '20%', margin: '5px 0' }}
              control={
                <Checkbox
                  checked={!!designations.find((item) => item === designation.code)}
                  onChange={(e) => {
                    const newDesignations = [...designations];
                    const foundIndex = designations.findIndex((item) => item === designation.code);
                    if (foundIndex !== -1) {
                      newDesignations.splice(foundIndex, 1);
                    } else {
                      newDesignations.push(designation.code);
                    }
                    onChangeDesignations(newDesignations);
                    if (targetApplicantGroup) {
                      setTargetApplicantGroup(undefined);
                    }
                  }}
                />
              }
              label={designation.description[locale]}
              labelPlacement="end"
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default DesignationSelectSection;
