import React, { useEffect, useState } from 'react';
import { fetchCampaignFullList, fetchCampaignItem } from 'src/app/modules/PulseLeads/network/campaignCrud';
import { CampaignItem, CampaignTypeEnum, CampaignStatusEnum } from 'src/app/modules/PulseLeads/types/campaign-types';

interface HookProps {
  onSelectItem: (campaign: CampaignItem) => void;
  onChange: (campaign: CampaignItem) => void;
  selectId?: string;
}

export const useCampaign = ({ onSelectItem, selectId, onChange }: HookProps) => {
  const [selectedRowsState, setSelectedRows] = useState<any>(null);
  const [popUpVisible, setPopUpVisible] = useState<boolean>(false);

  const onCancel = () => {
    setPopUpVisible(false);
  };

  const onSelect = () => {
    setPopUpVisible(true);
  };

  const onSubmit = () => {
    onSelectItem(selectedRowsState);
    setPopUpVisible(false);
  };

  const formatCampaignData = async (newFilterState?: any) => {
    const newFilter = newFilterState || {};
    const requestParams = {
      ...newFilter,
      status: CampaignStatusEnum.ACTIVE,
      types: [CampaignTypeEnum.NORMAL, CampaignTypeEnum.MASS],
    };
    requestParams.page = (requestParams.page || 0) + 1;
    requestParams.limit = requestParams.pageSize || 20;
    delete requestParams.pageSize;

    const result = await fetchCampaignFullList(requestParams);
    const res = {
      data: result.docs || [],
      success: true,
      total: result.totalDocs,
    };

    return res;
  };

  const fetchData = (newFilterState?: any): Promise<any> => {
    return formatCampaignData(newFilterState);
  };

  useEffect(() => {
    if (selectId) {
      const fetchCampaign = async () => {
        const result = await fetchCampaignItem(selectId);
        setSelectedRows(result);
        onChange(result);
      };
      fetchCampaign();
    }
  }, []);

  return {
    popUpVisible,
    selectedRowsState,
    setSelectedRows,
    onCancel,
    onSelect,
    onSubmit,
    fetchData,
  };
};
