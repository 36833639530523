import React from 'react';
import { getDefaultDisplayDate } from 'src/app/common/utils';
import { regionLocale } from 'src/app/i18n';
import { getType } from 'src/app/modules/sales-kit/components/Library/utils/common-utils';

export const columns = (Translation: any, keyword: string, type: string) => {
  const columns = [
    {
      title: Translation('component.formLabel.name'),
      dataIndex: 'name',
    },
    {
      title: Translation('component.formLabel.type'),
      dataIndex: 'type',
      hideInSearch: true,
      render: (data: any) => {
        return <span>{data ? getType(data) : ''}</span>;
      },
    },
    {
      title: Translation('title.event.category.table.header.status'),
      dataIndex: 'status',
      hideInSearch: true,
    },
    {
      dataIndex: 'effectiveDate',
      title: Translation('component.formLabel.effective-date-range-start'),
      hideInSearch: true,
      render: (data: any) => {
        return <span>{data ? getDefaultDisplayDate(new Date(data), 'datetime') : ''}</span>;
      },
    },
    {
      dataIndex: 'expiredDate',
      title: Translation('component.formLabel.effective-date-range-end'),
      hideInSearch: true,
      render: (data: any) => {
        return <span>{data ? getDefaultDisplayDate(new Date(data), 'datetime') : ''}</span>;
      },
    },
    {
      dataIndex: 'updatedAt',
      title: Translation('component.formLabel.last-updated-time'),
      hideInSearch: true,
      render: (data: any) => {
        return <span>{data ? getDefaultDisplayDate(new Date(data), 'datetime') : ''}</span>;
      },
    },
  ];
  const surveySolumns = [
    {
      title: Translation('component.formLabel.name'),
      dataIndex: 'title',
    },
    {
      title: Translation('component.formLabel.effective-date-range-start'),
      dataIndex: 'startDate',
      hideInSearch: true,
      render: (data: any) => {
        return <span>{data ? getDefaultDisplayDate(new Date(data), 'datetime') : ''}</span>;
      },
    },
    {
      title: Translation('component.formLabel.effective-date-range-end'),
      dataIndex: 'endDate',
      hideInSearch: true,
      render: (data: any) => {
        return <span>{data ? getDefaultDisplayDate(new Date(data), 'datetime') : ''}</span>;
      },
    },
    {
      title: Translation('component.formLabel.last-updated-time'),
      dataIndex: 'updatedAt',
      hideInSearch: true,
      render: (data: any) => {
        return <span>{data ? getDefaultDisplayDate(new Date(data), 'datetime') : ''}</span>;
      },
    },
  ];

  return type.indexOf('material') > -1 ? [...columns] : [...surveySolumns];
};
