import { DependentPermissionKeyItem } from './types/secretary-types';

export const SECRETARY_BASE_PATH = '/secretary';

export const SECRETARY_TITLE = 'title.secretary';

export const SECRETARY_NAMESPACE = 'SECRETARY';

// Only support checking same level of permission dependency
export const dependentPermissionKeyList: DependentPermissionKeyItem[] = [
  {
    parent: 'READ_OWN_POLICY',
    permission: 'READ_OWN_POLICY-DOCUMENT',
    dependencies: ['READ_OWN_POLICY-NO-DOCUMENT'],
  },
];

export enum AdminSecretaryPermissionType {
  SECRETARY_READ = 'READ_ANY_SECRETARY',
  SECRETARY_UPDATE = 'UPDATE_ANY_SECRETARY',
  SECRETARY_APPROVE = 'APPROVE_ANY_SECRETARY',
}
