//  These constants map to permission control, identical to permission enum defined below
export const PRUDNA_NAMESPACE = 'PRUDNA';
export const PRUDNA_BASE_PATH = 'prudna';

export enum AdminApplicationPermissionType {
  PRUDNA_READ = 'READ_ANY_PRUDNA',
  PRUDNA_CREATE = 'CREATE_ANY_PRUDNA',
  PRUDNA_UPDATE = 'UPDATE_ANY_PRUDNA',
}

export enum TimeRangeOptsType {
  YTD,
  LastMonth,
  CurrentMonth,
  CustomizedTimeRange,
}
export const TimeRangeOpts = [
  { value: TimeRangeOptsType.YTD, label: 'YTD' },
  { value: TimeRangeOptsType.LastMonth, label: 'Last Month' },
  { value: TimeRangeOptsType.CurrentMonth, label: 'Current Month' },
  { value: TimeRangeOptsType.CustomizedTimeRange, label: 'Customized time Range' },
];

export enum NoPruDNAResultType {
  Invited,
  Accepted,
  CandidateFromMassSharing,
  CandidateFromManualAdd,
  Invitations,
  StartedInvitations,
  Completed,
  Proceed,
  ProceedCaution,
  ProceedExtremeCaution,
}
