import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { PruDatePicker } from 'src/app/common/components/PruDatePicker';
import { useIntl } from 'react-intl';

interface RangePickerProps {
  value?: any[];
  onChange?: (e: any[]) => void;
}

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row'
  },
  divider: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
}));

export default function RangePicker(props: RangePickerProps) {
  const { classes } = useStyles();
  const { value = [], onChange } = props;
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id })

  const handleChange = (key: number) => (date: Date | null) => {
    if (!onChange) return
    const newValue = [ ...(value || []) ]
    newValue[key] = date
    onChange(newValue)
  }

  const placeSelect = Translation('app.input.placeholder.please-select');

  return (
    <div
      className={classes.container}
    >
      <PruDatePicker
        maxDate={(value && value[1]) || null}
        format="DD/MM/YYYY"
        value={(value && value[0]) || null}
        onChange={handleChange(0)}
        label={placeSelect}
      />
      <div className={classes.divider}>-</div>
      <PruDatePicker
        minDate={(value && value[0]) || null}
        format="DD/MM/YYYY"
        value={(value && value[1]) || null}
        onChange={handleChange(1)}
        label={placeSelect}
      />
    </div>
  )
}
