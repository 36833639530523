export const commonStyles = {
  targetArea: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  colorRed: {
    color: 'red',
  },
  ml70: {
    marginLeft: 70,
  },
  uploadArea: {
    marginLeft: 10,
    width: '100%',
  },
  ml140: {
    marginLeft: 140,
  },
  ml20: {
    marginLeft: 20,
  },
  errorMsg: {
    marginLeft: 140,
    marginTop: 10,
    color: 'red',
  },
  ml120: {
    marginLeft: 120,
  },
  tableBg: {
    marginTop: 10,
    marginRight: 50,
  },
  downloadText: {
    marginLeft: 20,
    textDecorationLine: 'underline',
  },
};
