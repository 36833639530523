import React, { FC, useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';
import { IconButton } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { NewsCategoryPaginateList, NewsCategoryItem } from 'src/app/modules/News/types/news-type';
import { moveNewsCategoryDownward, moveNewsCategoryUpward } from 'src/app/modules/News/network/newsCrud';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import PruTable, { PruTableColumnDef } from 'src/app/common/components/PruTable/PruTable';
import { useJWT, getDefaultDisplayDate, isPermitted } from 'src/app/common/utils';
import { AdminNewsPermissionType, NEWS_BASE_PATH, NEWS_CATEGORY_PATH } from 'src/app/modules/News/constants';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { getAvailableServerLocale } from 'src/app/i18n';
import { map } from 'lodash';

type CategoryListProps = {
  isLoading: boolean;
  newsCategoryList?: NewsCategoryPaginateList;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
};

const useStyles = makeStyles()({
  operationBtn: {
    color: 'blue',
    cursor: 'pointer',
    textDecoration: 'underline',
    fontSize: '0.85rem',
    whiteSpace: 'nowrap',
  },
  colorBox: {
    width: 20,
    height: 20,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
});

const CategoryList: FC<CategoryListProps> = ({ isLoading, newsCategoryList, onRefresh, onChangePage }) => {
  const availableLocales = getAvailableServerLocale();
  const history = useHistory();
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  let updatePermission = false;
  const jwt = useJWT() || {};

  if (jwt.permissions) {
    const jwtPermission = map(jwt.permissions, (_, key) => key);
    updatePermission = isPermitted([AdminNewsPermissionType.NEWS_CATEGORIES_UPDATE], jwtPermission);
  }

  const onUpwardButtonClicked = async (id: string) => {
    // Call Api and move the category row upward
    try {
      await moveNewsCategoryUpward(id, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Category moved upward successfully `,
          },
        ]),
      );
      onRefresh();
    } catch (err) {}
  };

  const onDownwardButtonClicked = async (id: string) => {
    // Call Api and move the category row downward
    try {
      await moveNewsCategoryDownward(id, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Category moved downward successfully `,
          },
        ]),
      );
      onRefresh();
    } catch (err) {}
  };

  return (
    <PruTable
      title={Translation('newsCategory.list')}
      disableBulkSelect
      disablePagination
      operationDef={
        updatePermission
          ? [
              {
                title: Translation('section.common.operation.edit'),
                tooltipText: 'Edit Category',
                onClick: (row) => history.push(`${NEWS_BASE_PATH}${NEWS_CATEGORY_PATH}/edit/${row._id}`),
              },
              {
                title: (
                  <IconButton size={'small'}>
                    <ArrowUpwardIcon />
                  </IconButton>
                ),
                tooltipText: 'Move Category Upward',
                onClick: (row) => onUpwardButtonClicked(row._id),
              },
              {
                title: (
                  <IconButton size={'small'}>
                    <ArrowDownwardIcon />
                  </IconButton>
                ),
                tooltipText: 'Move Category Downward',
                onClick: (row) => onDownwardButtonClicked(row._id),
              },
            ]
          : []
      }
      columnDef={[
        {
          isId: true,
          keyIndex: '_id',
          align: 'center',
          displayName: Translation('newsCategory.common.id'),
          renderData: (row) => row._id || '-',
        },
        ...availableLocales.map(
          (locale) =>
            ({
              keyIndex: `name${locale}`,
              align: 'center',
              displayName: Translation(`newsCategory.common.name.${locale}`),
              renderData: (row) => row.name[locale] || '-',
            } as PruTableColumnDef<NewsCategoryItem>),
        ),
        {
          keyIndex: 'color',
          align: 'center',
          displayName: Translation('newsCategory.common.color'),
          renderData: (row) => (
            <div className={classes.colorBox} style={{ background: `${row.color ? row.color : '#ffffff'}` }}></div>
          ),
        },
        {
          keyIndex: 'createdAt',
          align: 'center',
          displayName: Translation('newsCategory.common.createdAt'),
          renderData: (row) => getDefaultDisplayDate(new Date(row.createdAt), 'datetime'),
        },
        {
          keyIndex: 'updatedAt',
          align: 'center',
          displayName: Translation('newsCategory.common.updatedAt'),
          renderData: (row) => getDefaultDisplayDate(new Date(row.updatedAt), 'datetime'),
        },
      ]}
      isLoading={isLoading}
      onRefresh={onRefresh}
      dataSource={newsCategoryList?.categories}
      onChangePage={onChangePage}
    />
  );
};

export default CategoryList;
