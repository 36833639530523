import React, { FC, useState, useEffect, useMemo } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { LayoutSplashScreen } from 'src/app/layout';
import { CampaignMappingFormMode } from 'src/app/modules/PulseLeads/types/straight-through-types';
import { CampaignDropdownList, CampaignDropdownType } from 'src/app/modules/PulseLeads/types/campaign-types';
import { EventCampaignItem, EventCampaignOptions } from 'src/app/modules/PulseLeads/types/event-campaign-types';
import {
  fetchCampaignDropdownList,
  fetchConfig,
  CampaignDropdownListParam,
  ConfigParam,
} from 'src/app/modules/PulseLeads/network/campaignCrud';
import {
  fetchEventCampaignItem,
  fetchEventCampaignOptions,
} from 'src/app/modules/PulseLeads/network/eventCampaignCrud';
import EventCampaignForm from './components/EventCampaignForm';

const EventCampaignDetailPage: FC<RouteComponentProps> = ({ history }) => {
  const { id } = useParams<{ id: string }>();
  const { formMode } = useMemo(
    () => ({
      formMode: id ? CampaignMappingFormMode.EDIT : CampaignMappingFormMode.CREATE,
    }),
    [id],
  );

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [eventCampaignItem, setEventCampaignItem] = useState<EventCampaignItem>();
  const [eventCampaignDropdownList, setEventCampaignDropdownList] = useState<CampaignDropdownList>();
  const [eventCampaignOptions, setEventCampaignOptions] = useState<EventCampaignOptions>();
  const [config, setConfig] = useState<any>();

  const reloadData = async () => {
    if (id) {
      setIsLoading(true);
      try {
        const campaignItemRes = await fetchEventCampaignItem(id, dispatch);
        setEventCampaignItem(campaignItemRes);
      } catch (err) {
        history.push(NOT_FOUND_ERROR_PAGE_PATH);
      }
    }
    try {
      const params: CampaignDropdownListParam = {
        type: [CampaignDropdownType.CAMPAIGN],
      };
      const dropdownListRes = await fetchCampaignDropdownList(params, dispatch);
      setEventCampaignDropdownList(dropdownListRes);

      const optionRes = await fetchEventCampaignOptions(dispatch);
      setEventCampaignOptions(optionRes);
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  const reloadConfig = async () => {
    const configParams: ConfigParam = {
      name: 'sales-sdk',
      namespace: 'default',
    };
    const configRes = await fetchConfig(configParams, dispatch);
    setConfig(configRes.data[0]);
  };

  useEffect(() => {
    reloadConfig();
  }, []);

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line
  }, [id]);

  return (
    <>
      {isLoading ? (
        <LayoutSplashScreen />
      ) : (
        <>
          {formMode === CampaignMappingFormMode.CREATE && (
            <EventCampaignForm
              formMode={formMode}
              eventCampaignDropdownList={eventCampaignDropdownList}
              eventCampaignOptions={eventCampaignOptions}
              config={config}
            />
          )}
          {formMode === CampaignMappingFormMode.EDIT &&
            (isLoading || !eventCampaignItem ? (
              <LayoutSplashScreen />
            ) : (
              <EventCampaignForm
                formMode={formMode}
                eventCampaignDropdownList={eventCampaignDropdownList}
                eventCampaignOptions={eventCampaignOptions}
                eventCampaignItem={eventCampaignItem}
                config={config}
              />
            ))}
        </>
      )}
    </>
  );
};

export default EventCampaignDetailPage;
