import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  accordContainer: {
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  divideMargin: {
    // marginBottom: 10
  },
  subHeader: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
  },
  accordHeading: {
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '15%',
    flexShrink: 0,
  },
  accordSecondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  addNewMaterialSetBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '10px',
  },
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalBg: {
    width: '100%',
    backgroundColor: theme.palette.common.white,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    height: '100%',
    overflow: 'scroll',
  },
  modalButtons: {
    marginTop: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
// use style here, not allow to use className for <Form.Item />
export const weightFormItem = { flex: 1, marginLeft: '11px', flexGrow: 1 };
