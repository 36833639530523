import React, { FC, useEffect } from 'react';
import { RouteProps, Route, Redirect } from 'react-router-dom';
import { NOT_FOUND_ERROR_PAGE_PATH } from '../constants';

type PruRouteProps = {
  disabled?: boolean;
  title?: string
} & RouteProps;

const PruRoute: FC<PruRouteProps> = ({ disabled, title, ...rest }) => {
  useEffect(() => {
    let previousTitle = window.document.title;
    if (title) {
      window.document.title = title;
    }

    return () => {
      window.document.title = previousTitle;
    }
  }, [title]);
  
  return disabled ? (<Redirect to={NOT_FOUND_ERROR_PAGE_PATH}/>) : (<Route {...rest}/>);
};

export default PruRoute;
