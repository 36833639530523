import React, { useEffect, useRef } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Dialog } from '@mui/material';
import { toAbsoluteUrl } from 'src/app/common/utils';

const useStyles = makeStyles()((theme) => ({
  container: {
    // backgroundImage: "url('../../assets/android.png')",
    backgroundImage: `url(${toAbsoluteUrl('/media/resource/android.png')})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center 0',
    backgroundSize: '100%',
    width: '352px',
  },
  iframe: {
    opacity: 1,
    background: '#FFF',
    margin: '99px 18px 118px',
  },
}));

const PreviewContent: React.FC<{ html?: string }> = ({ html }) => {
  const iframeRef = useRef<any>();
  const { classes } = useStyles();
  useEffect(() => {
    if (html && iframeRef.current) {
      iframeRef.current!.contentDocument!.body!.innerHTML = html;
      iframeRef.current!.contentDocument!.body!.style.margin = 0;
    }
  }, [html]);

  return (
    <div className={classes.container}>
      <iframe
        ref={iframeRef}
        id="preview-iframe"
        className={classes.iframe}
        title="preview"
        /* srcDoc={html} */ frameBorder="0"
        width="315"
        height="510"
      />
    </div>
  );
};

const Preview: React.FC<{ html?: string; open: boolean; onClose: () => void }> = ({ html, open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <PreviewContent html={html} />
    </Dialog>
  );
};

export default Preview;
