import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import PruRoute from '../../../common/components/PruRoute';
import { ROLE_CONTROL_BASE_PATH, ROLE_CONTROL_PERMISSION_PATH } from '../constants';
import { NOT_FOUND_ERROR_PAGE_PATH } from '../../../common/constants';
import PermissionControlPage from './Permission/PermissionControlPage';
import CreateRolePage from './CreateRole/CreateRolePage';

export const rolePermissionPath = `${ROLE_CONTROL_BASE_PATH}${ROLE_CONTROL_PERMISSION_PATH}`;

const PermissionRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect exact from={rolePermissionPath} to={`${rolePermissionPath}/list`} />
      <PruRoute exact path={`${rolePermissionPath}/list`} component={PermissionControlPage} />
      <PruRoute exact path={`${rolePermissionPath}/create`} component={CreateRolePage}/>
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  )
};

export default PermissionRoutes;