import { makeStyles } from 'tss-react/mui';
import { Icon, Theme } from '@mui/material';
import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { toAbsoluteUrl } from 'src/app/common/utils';
import { toggleIsAsideMenuCollapse } from 'src/redux/layout/layout-reducer';
import { useProjectDispatch, useProjectSelector } from 'src/redux/store';

export default function AsideHeader({ isExpand }: { isExpand: boolean }) {
  const isCollapse = useProjectSelector((state) => state.layout.isAsideMenuCollapse);
  const dispatch = useProjectDispatch();
  const { classes } = useStyles();

  const onClick = useCallback(() => {
    dispatch(toggleIsAsideMenuCollapse());
  }, [dispatch]);

  return (
    <div className={classes.header}>
      {(!isCollapse || isExpand) && (
        <div className={classes.logo}>
          <Link to="">
            <img alt="logo" src={toAbsoluteUrl('/media/pruforce-logo/pruforce_logo_color.png')} />
          </Link>
        </div>
      )}
      <Icon
        className={`${isCollapse ? 'fa fa-angle-double-right' : 'fa fa-angle-double-left'} ${classes.icon}`}
        onClick={onClick}
      />
    </div>
  );
}

const useStyles = makeStyles()((theme: Theme) => ({
  header: {
    width: '100%',
    padding: '0 15px',
    height: 90,
    display: 'flex',
    flexShrink: 0,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#1a1a27',
    [theme.breakpoints.down('md')]: {
      display: 'none',
      paddingTop: 30,
    },
  },
  logo: {
    marginRight: 10,
  },
  icon: {
    color: '#494b74',
    fontSize: 26,
    cursor: 'pointer',
    '&:hover': {
      color: '#3699FF !important',
    },
  },
}));
