import moment from 'moment';
import { I18nGenericData } from 'src/app/i18n/types/i18n-types';
import { localeMapping } from 'src/app/common/components/survey-v2/survey-utils';
import {
  EventItem,
  EventSessionItem,
  RegistrationNotificationMethodEnum,
  SessionEnrollmentEnum,
} from 'src/app/modules/event-v2/types';
import { nameElements, emailElement, phoneElement } from '../constants';

export function convertDataToJSON(eventItem: EventItem, update: 'session' | 'contact' = 'session') {
  if (!eventItem.regFormBody) {
    const pages = [
      {
        name: 'page1',
        elements: [
          ...nameElements,
          contactElement(eventItem),
          ...(eventItem.multiSession ? sessionElements(eventItem) : []),
        ],
      },
    ];
    const regFormBody = { ...header(eventItem), pages: pages };
    return regFormBody;
  } else {
    return {
      ...eventItem.regFormBody,
      title: localeMapping(eventItem.name),
      pages: eventItem.regFormBody.pages.map((page: any) => {
        if (page.name === 'page1') {
          if (update === 'session') {
            return updateSessionElement(page, eventItem);
          } else {
            return updateContactElement(page, eventItem);
          }
        }
        return page;
      }),
    };
  }
}

export const updateSessionElement = (page: any, eventItem: EventItem) => {
  const newElements: any[] = [];
  let inserted = false;
  let contactElementIndex = -1;
  const newSessionElements = eventItem.multiSession ? sessionElements(eventItem) : [];
  page.elements.forEach((element: any, index: number) => {
    if (element.name === 'register_sessions') {
      inserted = true;
      newElements.push(...newSessionElements);
    } else if (!element.name.startsWith('register_session')) {
      if (['registrant_email', 'countryCode_phoneNumber'].includes(element.name)) {
        contactElementIndex = index;
      }
      newElements.push(element);
    }
  });
  if (!inserted && contactElementIndex !== -1) {
    newElements.splice(contactElementIndex + 1, 0, ...newSessionElements);
  }
  return {
    ...page,
    elements: newElements,
  };
};

export const updateContactElement = (page: any, eventItem: EventItem) => {
  const newElements: any[] = [];
  page.elements.forEach((element: any) => {
    if (
      element.name === 'registrant_email' &&
      eventItem.regNotificationMethod === RegistrationNotificationMethodEnum.SMS
    ) {
      newElements.push(phoneElement);
    } else if (
      element.name === 'countryCode_phoneNumber' &&
      eventItem.regNotificationMethod !== RegistrationNotificationMethodEnum.SMS
    ) {
      newElements.push(emailElement);
    } else {
      newElements.push(element);
    }
  });
  return {
    ...page,
    elements: newElements,
  };
};

const header = (eventItem: EventItem) => {
  const header = {
    title: localeMapping(eventItem.name),
    completedHtml: {
      default: '<h3>Thank you! </h3> We have sent the registration confirmation to the contact you provided.',
      'zh-tw': '<h3>感謝您！</h3>我們已將註冊確認寄送到您提供的聯繫方式。',
    },
    logoPosition: 'right',
    showQuestionNumbers: 'off',
    questionErrorLocation: 'bottom',
    completeText: 'Submit',
    questionsOnPageMode: 'singlePage',
    showPreviewBeforeComplete: 'showAnsweredQuestions',
    widthMode: 'static',
    width: '1000px',
  };
  return header;
};

const contactElement = (eventItem: EventItem) => {
  return eventItem.regNotificationMethod === RegistrationNotificationMethodEnum.SMS ? phoneElement : emailElement;
};

const sessionElements = (eventItem: EventItem) => {
  const elements = [
    {
      type:
        eventItem.multiSession && eventItem.sessionEnrollment === SessionEnrollmentEnum.ALL ? 'checkbox' : 'radiogroup',
      name: 'register_sessions',
      title: {
        default: 'Select sessions you will attend',
        'zh-tw': '請選擇您將參加的會議',
      } as I18nGenericData<string>,
      isRequired: true,
      requiredErrorText: {
        default: 'Field is required',
        'zh-tw': '欄位為必填項',
      },
      allowAddPanel: false,
      allowRemovePanel: false,
      choices: eventItem.sessions.map((session: any, index: number) => ({
        value: session.key,
        text: convertToChoice(
          index + 1,
          session.name,
          formatDateTime(session.startTime),
          formatDateTime(session.endTime),
        ),
      })),
    },
  ];

  eventItem.sessions.forEach((session: EventSessionItem, index: number) => {
    if (session.subSessions.length != 0) {
      const sessionElement = {
        type:
          session.multiSubSession && session.subSessionEnrollment === SessionEnrollmentEnum.ALL
            ? 'checkbox'
            : 'radiogroup',
        name: `register_session${index + 1}`,
        visibleIf: `{register_sessions} contains '${session.key}'`,
        title: convertToSubSessionTitle(index + 1, session.name),
        isRequired: true,
        requiredErrorText: {
          default: 'Field is required',
          'zh-tw': '欄位為必填項',
        },
        allowAddPanel: false,
        allowRemovePanel: false,
        choices: session.subSessions.map((subSession: any, index: number) => ({
          value: subSession.key,
          text: convertToSubChoice(
            index + 1,
            subSession.name,
            formatDateTime(subSession.startTime),
            formatDateTime(subSession.endTime),
          ),
        })),
      };

      elements.push(sessionElement);
    }
  });

  return elements;
};

const convertToChoice = (index: number, name: I18nGenericData<string>, startTime: string, endTime: string) => {
  const choice: I18nGenericData<string> = {};
  for (const key in name) {
    choice[convert2SurveyLocale(key)] = `Session ${index} - ${name[key]} (${startTime} - ${endTime})`;
  }
  return choice;
};

const convertToSubSessionTitle = (index: number, name: I18nGenericData<string>) => {
  const title: I18nGenericData<string> = {};
  for (const key in name) {
    title[convert2SurveyLocale(key)] = `Select session ${index} - ${name[key]} you will attend`;
  }
  return title;
};
const convertToSubChoice = (index: number, name: I18nGenericData<string>, startTime: string, endTime: string) => {
  const choice: I18nGenericData<string> = {};
  for (const key in name) {
    choice[convert2SurveyLocale(key)] = `${name[key]} (${startTime} - ${endTime})`;
  }
  return choice;
};

const formatDateTime = (date: string): string => {
  return moment(new Date(date)).format('HH:mm');
};
const convert2SurveyLocale = (lang: string): string => {
  const langMapping: { [key: string]: string } = {
    en: 'default',
    'en-US': 'default',
    zhhk: 'zh-tw',
    zhHk: 'zh-tw',
    'zh-Hant': 'zh-tw',
    'zh-Hant-TW': 'zh-tw',
    id: 'id', // indonesia
    my: 'my',
    ph: 'ph',
    km: 'km',
  };
  return langMapping[lang] || 'default';
};
