import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  designationRow: {
    display: 'flex',
  },
  fieldContainer: {
    width: 208,
    boxSizing: 'border-box',
  },
  input: {},
  field: {
    marginLeft: 24,
    fontSize: '1rem',
    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },
  dateDivider: {
    marginLeft: 8,
    marginRight: 8,
  },
  sectionMargin: {
    marginBottom: 20,
  },
  divideMargin: {
    marginBottom: 10,
  },
  subHeader: {
    marginLeft: 24,
    fontSize: '1.1rem',
    fontWeight: 'bold',
  },
  accordHeading: {
    fontSize: '16px',
    fontWeight: 500,
  },
  designationContainer: {
    padding: '10px',
    borderRadius: 5,
    backgroundColor: '#F5F5F5',
  },
  footerContainer: {
    marginTop: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  consentText: {
    fontSize: '12px',
    fontWeight: 500,
  },
  imageContainer: {
    width: 160,
    height: 80,
    overflow: 'hidden',
    borderRadius: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },
  textfieldContainer: {
    flex: 1,
    display: 'flex',
  },
  closeIcon: {
    width: 24,
    height: 24,
    verticalAlign: 'middle',
    right: '80px',
    position: 'absolute',
  },
  formCloseIcon: {
    width: 18,
    height: 18,
  },
  titleTextContainer: {
    flex: 'none',
    width: 208,
  },
  imageDisplayArea: {
    width: '98%',
    height: '98%',
  },
  mb5: {
    marginBottom: 5,
  },
  button: {
    borderRadius: 16,
    paddingInline: 12,
    minWidth: '100px',
  },
  mt10: {
    marginTop: 10,
  },
  titleTextMargin: {
    marginTop: 12,
    marginLeft: 24,
    marginBottom: 24,
  },
  cancelContainer: {
    flex: 1,
    minWidth: 18,
  },
  radioGroup: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  commonRadio: {
    display: 'flex',
    margin: '0px 0px 0px -12px',
    width: 150,
  },
  agentTableContainer: {
    paddingLeft: 208,
    marginBottom: 24,
  },
  TNC: {
    fontFamily: 'Inter',
    fontStyle: 'italic',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '24px',
    color: '#888994',
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row' as const,
    alignItems: 'center',
  },
  fieldMargin: {
    marginLeft: 24,
    marginTop: 24,
  },
  ml_24: {
    marginLeft: 24,
  },
  previewPaper: {
    width: '414px',
  },
  titleArea: {
    marginLeft: 24,
    width: 160,
  },
  flexRowFull: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row' as const,
    alignItems: 'center',
  },
  flex_1: {
    flex: 1,
    display: 'flex',
  },
  notifyFont: {
    color: '#666672',
    fontSize: 12,
    fontFamily: 'Inter',
    fontStyle: 'italic',
    fontWeight: 400,
  },
  flexCol: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  contentTextField: {
    marginLeft: 24,
    width: '95%',
  },
  tncContainer: {
    marginLeft: 184,
    marginBottom: 24,
  },
  marginVertical_24: {
    marginTop: 24,
    marginBottom: 24,
  },
  CTAtext: {
    marginLeft: 24,
    width: '40%',
    minWidth: '200px',
  },
  campaignId: {
    marginLeft: 24,
    width: '50%',
    marginTop: 24,
  },
}));

export const styles = {
  minW200: { minWidth: 200 },
  mb12: { marginBottom: 12 },
  mt24: { marginTop: 24 },
  FieldCopyText: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '10px',
    color: '#4386E5',
    width: 100,
    textDecoration: 'underline',
    marginLeft: 2,
  },
  bodyCopyText: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '12px',
    width: 200,
    color: '#4386E5',
    textDecoration: 'underline',
    marginLeft: 2,
  },
  displayNone: {
    display: 'none',
  },
};
