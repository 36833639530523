import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import PruRoute from 'src/app/common/components/PruRoute';
import ListPage from './List';

const RuleNameRoutes: FC<RouteComponentProps> = ({ match }) => {
  return (
    <Switch>
      <Redirect exact from={match.path} to={`${match.path}/list`} />
      <PruRoute exact path={`${match.path}/list`} render={(props) => <ListPage {...props} />} />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default RuleNameRoutes;
