import { FormInstance } from 'rc-field-form';
import { IntlShape } from 'react-intl';
import { CampaignTypeEnum } from 'src/app/modules/PulseLeads/types/campaign-types';

export type RuleDropdown = {
  optionText: string;
  optionValue: string;
};

export type FormSectionProps = {
  form: FormInstance<any>;
  formMode: string;
};

export type CampaignListParam = {
  name: string;
  startDateFrom: Date | null;
  startDateTo: Date | null;
  endDateFrom: Date | null;
  endDateTo: Date | null;
  sort: string;
  status: string;
  type: CampaignTypeEnum;
  page: number;
  limit: number;
};

export type Field = {
  id: string;
  default: boolean;
};
export type Content = {
  banner: string;
  bannerBlobId: string;
  bannerName: string;
  title: I18nData[];
  subTitle: I18nData[];
  body: I18nData[];
  tncList: TncI18nData[];
  callToAction: I18nData[];
};
export type SubmissionResponse = {
  title: I18nData[];
  content: I18nData[];
};
export type I18nData = {
  locale: string;
  value: string;
};

export type TncI18nData = {
  locale: string;
  value: string[];
};

export type AgentReferralLink = {
  name: string;
  sourceId: string;
  status: string;
  content: Content;
  inputFieldsConfig: any;
  submissionresponse: SubmissionResponse;
  campaignId: string;
  agentType: 'all' | 'target';
  targetAgentType?: 'agentList' | 'agentGroup';
  targetAgentList?: string[];
  includedAgents?: string[] | null;
  excludedAgents?: string[] | null;
  designations?: string[] | null;
  zones?: string[] | null;
  region: string;
  channel: string;
};

export const activeType: any = (intl: IntlShape) => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return {
    Active: {
      value: '1',
      text: Translation('profile.agent.common.status.active'),
    },
    Inactive: {
      value: '0',
      text: Translation('profile.agent.common.status.inactive'),
    },
  };
};
export const bannerStatus: any = (intl: IntlShape) => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return {
    default: {
      value: '0',
      text: Translation('default_label'),
    },
    custom: {
      value: '1',
      text: Translation('profile.agent.common.tierType.custom'),
    },
  };
};

export enum TargetAgentType {
  agentList = 'agentList',
  agentGroup = 'agentGroup',
}
