import Button from '@mui/material/Button';
import React from 'react';
import { ModalService } from 'src/app/common/components/pru-modal/modal-service';
import { commonStyles } from './style';
import { AgentArray } from '.';

const onDelete = (row: { agentCode: string }, data: AgentArray, actionRef: any) => {
  const index = data.findIndex((item) => {
    return item.agentCode === row.agentCode;
  });
  if (index !== -1) {
    data.splice(index, 1);
    actionRef.current?.reload();
  }
};

const columns = (Translation: any, data: AgentArray, actionRef: any, disabled: boolean) => {
  const columns = [
    {
      title: Translation('pulseleads.activityPoint.ruleSettings.common.agentCode'),
      dataIndex: 'agentCode',
      hideInSearch: true,
    },
    {
      title: Translation('component.formLabel.action'),
      dataIndex: 'agentCode',
      hideInSearch: true,
      align: 'right' as const,
      render: (_: any, row: { agentCode: string }) => {
        return (
          <div style={commonStyles.deleteButton}>
            <Button
              color="secondary"
              disabled={disabled}
              onClick={() => {
                ModalService.confirm({
                  title: Translation('survey.common.delete.title'),
                  onConfirm: () => {
                    onDelete(row, data, actionRef);
                  },
                  confirmText: Translation('button_confirm'),
                  cancelText: Translation('button_cancel'),
                });
              }}
            >
              {Translation('app.button.delete')}
            </Button>
          </div>
        );
      },
    },
    {
      title: '',
      hideInSearch: true,
      align: 'right' as const,
      render: (_: any, row: any) => {
        return <div className="tw-w-0">{''}</div>;
      },
    },
  ];

  return [...columns];
};

export default columns;
