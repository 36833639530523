import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import PruRoute from 'src/app/common/components/PruRoute';
import List from './List';
import Detail from './Detail';
import { ModulePermissionProps } from 'src/app/common/module/types';
import { MTmode, PULSELEADS_POINT_RULE_PATH, PULSELEADS_POINT_RULE_SETTINGS_PATH } from './../constants';
import { PULSELEADS_BASE_PATH, PULSELEADS_RULE_PATH } from 'src/app/modules/PulseLeads/constants';

export const pointRuleSettingsRoutesPath = `${PULSELEADS_BASE_PATH}${PULSELEADS_RULE_PATH}${PULSELEADS_POINT_RULE_PATH}${PULSELEADS_POINT_RULE_SETTINGS_PATH}/list`;

const PointRuleSettingsRoutes: FC<RouteComponentProps & ModulePermissionProps> = ({
  match,
  enableRead,
  enableCreate,
  enableUpdate,
}) => {
  return (
    <Switch>
      <Redirect exact from={match.path} to={`${match.path}/list`} />
      <PruRoute
        exact
        path={`${match.path}/list`}
        render={(props) => (
          <List
            {...props}
            enableRead={enableRead}
            enableCreate={enableCreate}
            enableUpdate={enableUpdate}
            formMode={MTmode.CREATE}
          />
        )}
      />
      <PruRoute
        exact
        path={`${match.path}/edit/:id`}
        render={(props) => (
          <Detail
            {...props}
            enableRead={enableRead}
            enableCreate={enableCreate}
            enableUpdate={enableUpdate}
            formMode={MTmode.EDIT}
          />
        )}
      />
      <PruRoute
        exact
        path={`${match.path}/create`}
        render={(props) => (
          <Detail
            {...props}
            enableRead={enableRead}
            enableCreate={enableCreate}
            enableUpdate={enableUpdate}
            formMode={MTmode.CREATE}
          />
        )}
      />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default PointRuleSettingsRoutes;
