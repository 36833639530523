import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import PruRoute from 'src/app/common/components/PruRoute';

import ParamsProvider from 'src/app/common/components/ParamsProvider';
import { PROFILE_AWARD_TYPE_PATH, PROFILE_BASE_PATH } from '../../constants';
import { awardTypeFilterKeys, AwardTypeListingPage } from './List/AwardTypeListingPage';
import { AddAwardTypeListPage } from './Form/UploadAwardTypeListPage';
import UploadAwardTypePage from './Form/UploadAwardTypePage';

export const awardTypeListPath = `${PROFILE_BASE_PATH}${PROFILE_AWARD_TYPE_PATH}`;

const AwardTypeListRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect exact from={awardTypeListPath} to={`${awardTypeListPath}/list`} />
      <PruRoute
        exact
        path={`${awardTypeListPath}/list`}
        render={(props) => (
          <ParamsProvider {...props} acceptKeys={awardTypeFilterKeys}>
            <AwardTypeListingPage />
          </ParamsProvider>
        )}
      />
      <PruRoute exact path={`${awardTypeListPath}/addList`} component={AddAwardTypeListPage} />
      <PruRoute exact path={`${awardTypeListPath}/addAward`} component={UploadAwardTypePage} />
      <PruRoute exact path={`${awardTypeListPath}/edit/:id`} component={UploadAwardTypePage} />

      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default AwardTypeListRoutes;
