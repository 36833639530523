export const panelProperty = {
  name: 'fieldType',
  displayName: 'Field Type',
  type: 'shorttext',
  category: 'general',
  readOnly: true,
  default: 'Custom',
  isSerializable: true,
  visible: true,
};

export const questionProperty = {
  name: 'fieldType',
  displayName: 'Field Type',
  type: 'shorttext',
  category: 'general',
  readOnly: true,
  default: 'Custom',
  isSerializable: true,
  visible: true,
};

export const pageProperty = {
  name: 'fieldType',
  displayName: 'Field Type',
  type: 'shorttext',
  category: 'general',
  readOnly: true,
  default: 'Custom',
  isSerializable: true,
  visible: true,
};

export const isPIIProperty = {
  name: 'isPII',
  type: 'boolean',
  default: false,
  isRequired: false,
  category: 'general',
  visibleIndex: 3,
  readOnly: true,
};

export const presetProperty = {
  name: 'preset',
  type: 'shorttext',
  default: '',
  isRequired: false,
  category: 'general',
  visibleIndex: 1,
  readOnly: true,
};

export const disableChoicesProperty = {
  name: 'disableChoices',
  type: 'boolean',
  default: false,
  isRequired: false,
  category: 'general',
  visibleIndex: 2,
  readOnly: true,
};

export const isAutoCapitalizeProperty = {
  name: 'isAutoCapitalize',
  type: 'boolean',
  default: false,
  isRequired: false,
  category: 'general',
  visible: false,
  readOnly: true,
};

export const modalTitleProperty = {
  name: 'modalTitle',
  type: 'shorttext',
  default: false,
  isRequired: false,
  category: 'general',
  visible: false,
  readOnly: true,
  isLocalizable: true,
};

export const enabledQuestionTypes = [
  'boolean',
  'checkbox',
  'comment',
  'dropdown',
  'file',
  'radiogroup',
  'signaturepad',
  'text',
  'panel',
  'year-picker',
  'image',
  'imagepicker',
];
