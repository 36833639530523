import { Dispatch } from 'react';
import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';

import { TodoListTaskGroupEditItem, TodoListSettingEditItem, TaskItemList, SettingItems } from '../types/todolist-type';

export const fecthTodoListTask = async (dispatch?: Dispatch<any>): Promise<TaskItemList> => {
  const queryPath = `${ADMIN_URL}/admin/todo/groups`;
  return await apiClient
    .get<TaskItemList>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const modifyTodoListTaskGroup = async (
  id: string,
  body: TodoListTaskGroupEditItem,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return await apiClient
    .put<void>(`${ADMIN_URL}/admin/todo/groups/${id}`, body, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const modifyTodoListTaskSection = async (
  id: string,
  body: TodoListTaskGroupEditItem,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return await apiClient
    .put<void>(`${ADMIN_URL}/admin/todo/categories/${id}`, body, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const moveTaskGroupUpward = async (id: string, dispatch?: Dispatch<any>): Promise<void> => {
  return await apiClient
    .post<void>(
      `${ADMIN_URL}/admin/todo/groups/${id}/up`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const moveTaskGroupDownward = async (id: string, dispatch?: Dispatch<any>): Promise<void> => {
  return await apiClient
    .post<void>(
      `${ADMIN_URL}/admin/todo/groups/${id}/down`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const moveTaskSectionUpward = async (id: string, dispatch?: Dispatch<any>): Promise<void> => {
  return await apiClient
    .post<void>(
      `${ADMIN_URL}/admin/todo/categories/${id}/up`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const moveTaskSectionDownward = async (id: string, dispatch?: Dispatch<any>): Promise<void> => {
  return await apiClient
    .post<void>(
      `${ADMIN_URL}/admin/todo/categories/${id}/down`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchTodoSettingStatus = async (dispatch?: Dispatch<any>): Promise<SettingItems> => {
  const queryPath = `${ADMIN_URL}/admin/todo/manager`;
  return await apiClient
    .get<SettingItems>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const settingViewAgentTask = async (
  body: TodoListSettingEditItem,
  dispatch?: Dispatch<any>,
): Promise<SettingItems> => {
  return await apiClient
    .put<SettingItems>(`${ADMIN_URL}/admin/todo/manager`, body, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
