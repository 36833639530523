import { Routes } from 'src/app/common/constants';

export const ModuleTypeList: { [key: string]: string } = {
  AgentProfile: 'AgentProfile',
  Sales: 'Sales',
  Promotion: 'Promotion',
  CustomerSurvey: 'CustomerSurvey',
  AgentLeadForm: 'AgentLeadForm',
  Recruit: 'Recruit',
};

export const ModuleTypeDisplay: { [key: string]: string } = {
  AgentProfile: 'Agent Profile',
  Sales: 'Sales Resource',
  Promotion: 'Latest Promotion',
  CustomerSurvey: 'Customer Survey',
  AgentLeadForm: 'Agent Lead Form',
  Recruit: 'Recruit',
};

export const ModuleTypeTranslate: { [key: string]: string } = {
  Sales: 'salesMaterial',
  Promotion: 'promotionMaterial',
  CustomerSurvey: 'customerSurvey',
  Recruit: 'recruit',
};

export const ModuleTypeRoute: { [key: string]: string } = {
  AgentProfile: Routes.SALES_KIT_AGENT_PROFILE,
  Sales: Routes.SALES_KIT_RESOURCE_WEBVIEW,
  Promotion: Routes.SALES_KIT_RESOURCE_WEBVIEW,
  Recruit: Routes.SALES_KIT_RESOURCE_WEBVIEW,
  CustomerSurvey: Routes.SPECIFIC_SURVEY_LINK,
  AgentLeadForm: Routes.SALES_KIT_LEAD_FORM,
};
