import { CampaignItem } from 'src/app/modules/PulseLeads/types/campaign-types';
import { CampaignTypeItem } from './campaign-type-types';

export enum AgencyCampaignStatusEnum {
  PENDING = 'pendingApproval',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  ACTIVE = 'active',
  COMPLETED = 'completed',
  PAUSED = 'paused',
  CANCELED = 'canceled',
  DRAFT = 'draft',
}

export enum WorkflowApprovalStatusEnum {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
}

export enum RsvpEnum {
  NOT_INVITE = 'Not invite',
  NO_RESPONSE = 'No response',
  ACCEPTED = 'Accepted',
  REJECTED = 'Rejected',
}

export enum LevelOfApprovalEnum {
  'Level1Approval',
  'Level2Approval',
  'Level3Approval',
}

export enum WorkflowTypeEnum {
  campaign = 'campaign',
}

export enum ContentEnum {
  FIXED = 'fixedContent',
  VARIABLE = 'variableContent',
}

export enum EnableEnum {
  YES = 'yes',
  NO = 'no',
}

export enum SendTypesEnum {
  APPLICANT = 'applicant',
  PARTICIPANT = 'participant',
}

export type ApprovalCampaignItem = {
  agencyCampaign: AgencyCampaignDetailItem;
} & CampaignItem;

export type ResourceItem = {
  _id: string;
  blobId: string;
  blobName: string;
  url: string;
  originalFilename: string;
};

export type AgencyCampaignDetailItem = {
  typeId: CampaignTypeItem;
  agentLeadSource: AgentLeadSourceItem;
  agentLeadSubSource: AgentLeadSourceItem[];
  applicant: ApplicantDetailItem;
  campaign: CampaignDetailItem;
  participant: ParticipantDetailItem;
  attachments: AttachmentDetailItem;
  approvalHistoryList: ApprovalHistoryItem[];
  remark: RemarkItem[];
  submitDate: Date;
  postDocuments: any;
};

export type AgentLeadSourceItem = {
  _id: string;
  sourceName: string;
};

export type ApplicantDetailItem = {
  applicantName: string;
  applicantAgentCode: string;
  applicantPhoneNumber: string;
  applicantEmail: string;
};

export type CampaignDetailItem = {
  campaignName: string;
  campaignDescription: string;
  campaignPurpose: string;
  campaignStartDate: Date;
  campaignEndDate: Date;
};

export type ParticipantDetailItem = {
  tnc: boolean;
  list: ParticipantIdItem[];
};

export type ParticipantIdItem = {
  participantId: ParticipantIndividualItem;
};

export type ParticipantIndividualItem = {
  agentCode: string;
  unit: string;
  name: any;
  displayName: DisplayNameItem;
  designation: DesignationItem;
  rsvpIndicator: RsvpEnum;
};

export type DisplayNameItem = {
  zhHk: string;
  enUs: string;
};

export type DesignationItem = {
  code: string;
  name: string;
};

export type AttachmentDetailItem = {
  proofOfLocation: BlobItem;
  attachment?: BlobItem;
  others?: BlobItem;
};

export type BlobItem = {
  resourceIds: string[];
};

export type ApprovalHistoryItem = {
  assignee: string;
  assigneeName: string;
  role: string[];
  levelOfApproval: LevelOfApprovalEnum;
  assigneeType: WorkflowTypeEnum;
  status: WorkflowApprovalStatusEnum;
  comment?: string;
  updatedBy: string;
  createdAt: Date;
  updatedAt: Date;
  submitDate: Date;
};

export type RemarkItem = {
  type: ContentEnum;
  resourceIds: string[];
  templateId: string;
  sendTypes: SendTypesEnum[];
  createdBy: string;
  updatedBy: string;
  createdAt: Date;
  updatedAt: Date;
};

export type LouTemplateItem = {
  templateId: string;
};
