import React from 'react';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { styles, useStyles } from '../../style';
import Form from 'src/app/common/components/Form';
import { TextField } from '@mui/material';
import Autocomplete from '@mui/lab/Autocomplete';
import { MTmode } from '../../../../../constants';
import { FormSectionProps } from 'src/app/modules/lead-microsite/types/microsite.type';

const LeadDistributionSection: FC<FormSectionProps & { idList: string[] }> = (props) => {
  const { form, formMode, idList } = props;
  const isView = formMode === MTmode.VIEW;
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const DEFAULT_REQUIRED_RULES = { required: true, message: Translation('component.form-required-text') };
  const [value, setValue] = React.useState(form.getFieldValue('campaignId'));

  return (
    <div className="tw-bg-white tw-pt-6 tw-pb-6 tw-mt-6">
      <div className="tw-flex">
        <div className={classes.subHeader}>{Translation('agent_referral_link_lead_dis_rule')}</div>
      </div>

      <div className={classes.campaignId}>
        <Form.Item
          name="campaignId"
          label={Translation('agent_referral_link_map_campaign')}
          rules={[DEFAULT_REQUIRED_RULES]}
        >
          <div className="tw-w-full">
            <Autocomplete
              disabled={isView}
              disablePortal
              options={idList}
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
                form.setFieldsValue({ campaignId: newValue });
              }}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField {...params} margin="dense" variant="outlined" fullWidth style={styles.mb12} />
              )}
            />
          </div>
        </Form.Item>
      </div>
    </div>
  );
};
export default LeadDistributionSection;
