import { IntlShape } from 'react-intl';
import { ModuleHierachy } from 'src/app/common/module/types';
import { isPermitted } from 'src/app/common/utils';
import { SECRETARY_TITLE, SECRETARY_BASE_PATH, SECRETARY_NAMESPACE, AdminSecretaryPermissionType } from './constants';
import SecretaryRoutes from './pages/SecretaryRoutes';

export const secretaryModuleConfig = (permissions: string[], intl: IntlShape): ModuleHierachy => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return {
    title: Translation(SECRETARY_TITLE),
    path: SECRETARY_BASE_PATH,
    icon: '/media/svg/icons/General/Shield-check.svg',
    namespace: SECRETARY_NAMESPACE,
    component: SecretaryRoutes,
    enableRead: isPermitted(
      [
        AdminSecretaryPermissionType.SECRETARY_READ,
        AdminSecretaryPermissionType.SECRETARY_UPDATE,
        AdminSecretaryPermissionType.SECRETARY_APPROVE,
      ],
      permissions,
    ),
    enableUpdate: isPermitted([AdminSecretaryPermissionType.SECRETARY_UPDATE], permissions),
    enableApprove: isPermitted([AdminSecretaryPermissionType.SECRETARY_APPROVE], permissions),
    options: {
      disableCreate: true,
      enableApprove: true,
    },
  };
};
