import { IntlShape } from 'react-intl';
import { ModuleHierachy } from '../../common/module/types';
import {
  PUBLIC_PORTAL_CONSENT_TITLE,
  PUBLIC_PORTAL_CONSENT_BASE_PATH,
  PUBLIC_PORTAL_CONSENT_NAMESPACE,
  AdminApplicationPermissionType,
} from './constants';
import PublicPortalConsentRoutes from './pages/public-portal-consent-routes';
import { isPermitted } from 'src/app/common/utils';

export const publicPortalConsentModuleConfig = (permissions: string[], intl: IntlShape): ModuleHierachy => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return {
    title: Translation(PUBLIC_PORTAL_CONSENT_TITLE),
    path: PUBLIC_PORTAL_CONSENT_BASE_PATH,
    icon: '/media/svg/icons/General/Shield-check.svg',
    namespace: PUBLIC_PORTAL_CONSENT_NAMESPACE,
    component: PublicPortalConsentRoutes,
    enableRead: isPermitted(
      [
        AdminApplicationPermissionType.PUBLIC_PORTAL_CONSENT_READ,
        AdminApplicationPermissionType.PUBLIC_PORTAL_CONSENT_CREATE,
        AdminApplicationPermissionType.PUBLIC_PORTAL_CONSENT_UPDATE,
      ],
      permissions,
    ),
    enableCreate: isPermitted([AdminApplicationPermissionType.PUBLIC_PORTAL_CONSENT_CREATE], permissions),
    enableUpdate: isPermitted([AdminApplicationPermissionType.PUBLIC_PORTAL_CONSENT_UPDATE], permissions),
  };
};
