import React, { FC, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { ConfigAgentFormMode, ConfigAgentItem } from '../../../types/config-type';
import { useDispatch } from 'react-redux';
import ConfigAgentDetailForm from './components/ConfigAgentDetailForm';
import { NOT_FOUND_ERROR_PAGE_PATH } from '../../../../../common/constants';
// import { fetchConfigAgentItem } from "../../../network/configCrud";
import { LayoutSplashScreen } from 'src/app/layout';
import { fetchConfigAgentItem } from '../../../network/configCrud';

const ConfigAgentDetailPage: FC<RouteComponentProps> = ({ history, location }) => {
  const { id } = useParams<{ id: string }>();
  const { formMode, code } = useMemo(
    () => ({
      formMode: id ? ConfigAgentFormMode.EDIT : ConfigAgentFormMode.CREATE,
      code: id ? id : undefined,
    }),
    [id],
  );

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [configAgentDetail, setConfigAgentDetail] = useState<ConfigAgentItem>();

  const reloadData = () => {
    if (code) {
      setIsLoading(true);
      fetchConfigAgentItem(code, dispatch)
        .then((result) => {
          setConfigAgentDetail(result);
        })
        .catch(() => {
          history.push(NOT_FOUND_ERROR_PAGE_PATH);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line
  }, [code]);

  return (
    <>
      {formMode === ConfigAgentFormMode.CREATE && (
        <ConfigAgentDetailForm formMode={formMode} onRouteTo={(route) => history.push(route)} />
      )}

      {formMode === ConfigAgentFormMode.EDIT &&
        (!isLoading && configAgentDetail ? (
          <ConfigAgentDetailForm
            formMode={formMode}
            code={code ? code : ''}
            configAgentDetail={configAgentDetail}
            onRouteTo={(route) => history.push(route)}
          />
        ) : (
          <LayoutSplashScreen />
        ))}
    </>
  );
};

export default ConfigAgentDetailPage;
