import React, { FC } from 'react';
import { useIntl } from "react-intl";
import { makeStyles } from 'tss-react/mui';
import { Button, Select, TextField } from '@mui/material';
import { PruDateTimePicker } from 'src/app/common/components/PruDatePicker';
import { CampaignTypeItem, CampaignTypeDropdownList, SectionItem, SectionFieldItem, CampaignTypeDropdownEnum, RuleKeyEnum, TypeEnum } from 'src/app/modules/AgencyCampaign/types/campaign-type-types';

type CampaignTypePreviewProps = {
  campaignTypeItem: CampaignTypeItem;
  campaignTypeDropdownList: CampaignTypeDropdownList;
  subSourceDropdownList: CampaignTypeDropdownList;
  disableCloseBtn?: boolean;
  setShowPreview?: (showPreview: boolean) => void;
}

const usePreviewStyles = makeStyles()((theme) => ({
  rowContainer: {
    display: 'flex',
    marginBottom: 50,
  },
  campaignTypeFieldBackground: {
    backgroundColor: '#D2E8FF',
    padding: 16,
  },
  campaignTypeFieldValue: {
    fontSize: '1.1rem',
  },
  sectionContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  sectionTitle: {
    fontSize: '1.4rem',
    fontWeight: 'bold',
  },
  fieldContainer: {
    width: '50%',
    boxSizing: 'border-box',
    marginBottom: 16
  },
  fieldWidth: {
    width: '50%',
    minWidth: 200,
  },
  disabled: {
    color: 'rgba(0, 0, 0, 0.5)'
  },
  bottomSpacing: {
    marginBottom: 5,
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
}));

const CampaignTypePreview: FC<CampaignTypePreviewProps> = ({ campaignTypeItem, campaignTypeDropdownList, subSourceDropdownList, disableCloseBtn, setShowPreview }) => {
  const { classes: previewClasses } = usePreviewStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const renderField = (field: SectionFieldItem) => {
    switch (field.type) {
      case TypeEnum.TEXT:
      case TypeEnum.NUMBER:
      case TypeEnum.CURRENCY:
        return (
          <TextField
            className={previewClasses.fieldWidth}
            disabled={true}
            value={""}
          />
        );
      case TypeEnum.DATE:
        return (
          <PruDateTimePicker
            className={previewClasses.fieldWidth}
            slotProps={{
              textField: {
                variant: 'standard',
                style: { marginTop: 0 },
              },
            }}
            disabled={true}
            format="DD/MM/YYYY HH:mm"
            value={new Date()}
            onChange={() => {}}
          />
        );
      case TypeEnum.DROPDOWN:
        return (
          <Select
            variant="standard"
            className={previewClasses.fieldWidth}
            disabled={true}
          />
        );
      case TypeEnum.ARRAY:
        return (
          <Button
            disabled={true}
            variant="contained"
            color="secondary"
          >
            {Translation("app.button.upload")}
          </Button>
        );
      default:
        return <></>;
    }
  }

  const renderSection = (section: SectionItem) => {
    return (
      <div className={previewClasses.sectionContainer}>
        {section.sectionFields.map(field => (
          <div className={previewClasses.fieldContainer}>
            <div className={previewClasses.bottomSpacing}>
              <span className={previewClasses.disabled}>
                {Translation(`agencyCampaign.field.${field.key}`)}{field.rules.find(rule => rule.key === RuleKeyEnum.MANDATORY)?.value && <span>*</span>}
              </span>
            </div>
            <div style={{ flexGrow: 1 }}>
              {renderField(field)}
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      <div className={`${previewClasses.rowContainer} ${previewClasses.campaignTypeFieldBackground}`}>
        <div className="col">
          <div className={previewClasses.bottomSpacing}>
            {Translation("agencyCampaign.campaignType.name")}
          </div>
          <div className={previewClasses.campaignTypeFieldValue}>
            {campaignTypeItem.name}
          </div>
        </div>
        <div className="col">
          <div className={previewClasses.bottomSpacing}>
            {Translation("agencyCampaign.campaignType.id")}
          </div>
          <div className={previewClasses.campaignTypeFieldValue}>
            {campaignTypeItem.code}
          </div>
        </div>
        <div className="col">
          <div className={previewClasses.bottomSpacing}>
            {Translation("agencyCampaign.common.status")}
          </div>
          <div className={previewClasses.campaignTypeFieldValue}>
            {campaignTypeItem.status}
          </div>
        </div>
        <div className="col">
          <div className={previewClasses.bottomSpacing}>
            {Translation("agencyCampaign.common.description")}
          </div>
          <div className={previewClasses.campaignTypeFieldValue}>
            {campaignTypeItem.description}
          </div>
        </div>
        <div className="col">
          <div className={previewClasses.bottomSpacing}>
            {Translation("agencyCampaign.common.leadSource")}
          </div>
          <div className={previewClasses.campaignTypeFieldValue}>
            {campaignTypeDropdownList[CampaignTypeDropdownEnum.AGENT_LEAD_SOURCE].find(item => item.key === campaignTypeItem.agentLeadSource)?.value}
          </div>
        </div>
        <div className="col">
          <div className={previewClasses.bottomSpacing}>
            {Translation("agencyCampaign.common.leadSubSource")}
          </div>
          <div className={previewClasses.campaignTypeFieldValue}>
            {campaignTypeItem.agentLeadSubSource.map(subSource => (
              subSourceDropdownList[CampaignTypeDropdownEnum.AGENT_LEAD_SOURCE].find(item => item.key === subSource)?.value || '-'
            )).join(', ')}
          </div>
        </div>
      </div>

      {campaignTypeItem.sections.filter(section => section.key !== "participant" && section.key !== "attachments").map(section => (
        <div className={previewClasses.rowContainer}>
          <div className="col-3">
            <span className={previewClasses.sectionTitle}>
              {Translation(`agencyCampaign.section.${section.key}`)}
            </span>
          </div>
          <div className="col-9">
            {renderSection(section)}
          </div>
        </div>
      ))}

      {!disableCloseBtn && setShowPreview &&
        <div className={previewClasses.footerContainer}>
          <Button
            variant="contained"
            style={{ backgroundColor: 'white' }}
            onClick={() => setShowPreview(false)}
          >
            {Translation("agencyCampaign.common.close")}
          </Button>
        </div>
      }
    </>
  );
}

export default CampaignTypePreview;