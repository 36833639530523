import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { getConfigurations, useJWT } from 'src/app/common/utils';
import { TokenInfo } from 'src/app/modules/Auth/types/auth-types';
import {
  dataReportLibraryDev,
  dataReportLibraryProd,
  dataReportLibraryUat,
  overviewReportParams,
  overviewReportsLibraryDev,
  overviewReportsLibraryProd,
  overviewReportsLibraryUat,
} from 'src/app/modules/PruleadInsightsReporting/types/report-types';
import { RootState } from 'src/redux/store';
import { getMongoChartToken } from '../../../../../../Auth/_redux/authCrud';
type OverviewReportDetailPageProps = {
  filter: overviewReportParams;
  selectFilter: (data: any) => void;
  updateData: (data: any) => void;
} & ParamsProps;

// const baseUrl = window.envConfig['REACT_APP_MONGODB_CHART_COE_BASE_URL'];

let library = {
  dataReport: dataReportLibraryDev,
  overviewReport: overviewReportsLibraryDev,
};

const OverviewReportDetailPage: FC<OverviewReportDetailPageProps> = ({
  filter,
  selectFilter,
  updateData,
  initialParams,
  onChangeQueryParams,
}) => {
  const user = useSelector<RootState, TokenInfo | undefined>((state) => state.auth.user);
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const [chartData1, setChartData1] = useState<Record<string, any>>();
  const [chartData2, setChartData2] = useState<Record<string, any>>();
  const [chartData3, setChartData3] = useState<Record<string, any>>();
  const [chartData4, setChartData4] = useState<Record<string, any>>();
  const jwt = useJWT() || {};
  const { region, channel } = jwt;

  const getEnv = useMemo(() => {
    const env = window.envConfig['REACT_APP_ENV'];
    if (env === 'dev' || env === 'coedev') {
      library = {
        dataReport: dataReportLibraryDev,
        overviewReport: overviewReportsLibraryDev,
      };
    }
    if (env === 'uat') {
      library = {
        dataReport: dataReportLibraryUat,
        overviewReport: overviewReportsLibraryUat,
      };
    }
    if (env === 'prod') {
      library = {
        dataReport: dataReportLibraryProd,
        overviewReport: overviewReportsLibraryProd,
      };
    }
  }, []);

  const baseUrl: string = getConfigurations()?.mongoChart?.baseUrl;

  const sdk = new ChartsEmbedSDK({
    baseUrl,
    getUserToken: async function () {
      // return await fetchDashboardJWT(user?.sub, chartSubscriptionKey);
      const response = await getMongoChartToken();
      return response.accessToken;
    },
  });

  const chartDiv1 = useRef<HTMLDivElement>(null);
  // const excelChartDiv1 = useRef<HTMLDivElement>(null);
  const chartDiv2 = useRef<HTMLDivElement>(null);
  // const excelChartDiv2 = useRef<HTMLDivElement>(null);
  const chartDiv3 = useRef<HTMLDivElement>(null);
  // const excelChartDiv3 = useRef<HTMLDivElement>(null);
  const chartDiv4 = useRef<HTMLDivElement>(null);
  // const excelChartDiv4 = useRef<HTMLDivElement>(null);
  const dataDiv = useRef<HTMLDivElement>(null);

  // const [excelChart1, excelChartreRender1] = useState<Chart>();
  // const [excelChart2, excelChartreRender2] = useState<Chart>();
  // const [excelChart3, excelChartreRender3] = useState<Chart>();
  // const [excelChart4, excelChartreRender4] = useState<Chart>();

  const [dataChart, dataChartreRender] = useState(
    sdk.createChart({
      chartId: library.dataReport.chartId,
      height: 800,
      theme: 'light',
      // filter: { $and: [{ 'agent_profile.region': region }, { 'agent_profile.channel': channel }] },
    }),
  );

  const [chart1, chart1Rerender] = useState(
    sdk.createChart({
      chartId: library.overviewReport[0].chartId,
      width: 550,
      height: 400,
      theme: 'light',
      // filter: { $and: [{ 'agent_profile.region': region }, { 'agent_profile.channel': channel }] },
    }),
  );

  const [chart2, chart2Rerender] = useState(
    sdk.createChart({
      chartId: library.overviewReport[1].chartId,
      width: 550,
      height: 400,
      theme: 'light',
      // filter: { $and: [{ 'agent_profile.region': region }, { 'agent_profile.channel': channel }] },
    }),
  );

  const [chart3, chart3Rerender] = useState(
    sdk.createChart({
      chartId: library.overviewReport[2].chartId,
      width: 550,
      height: 400,
      theme: 'light',
      // filter: { $and: [{ 'agent_profile.region': region }, { 'agent_profile.channel': channel }] },
    }),
  );

  const [chart4, chart4Rerender] = useState(
    sdk.createChart({
      chartId: library.overviewReport[3].chartId,
      width: 550,
      height: 400,
      theme: 'light',
      // filter: { $and: [{ 'agent_profile.region': region }, { 'agent_profile.channel': channel }] },
    }),
  );

  const [myFilter, setMyFilter] = useState<any>({
    // startDt: undefined,
    // endDt: undefined,
    // 'agent_profile.region': '',
    // 'agent_profile.channel': '',
  });

  useEffect(() => {
    if (chartDiv1.current && chartDiv2.current && chartDiv3.current && chartDiv4.current && dataDiv.current) {
      chart1
        .render(chartDiv1.current)
        .then(() => {
          // chart1.getData().then((result) => {
          //   return setChartData1({ chartName: library.overviewReport[0].chartName, ...result });
          // });
        })
        .catch((err) => console.log('Error during Charts1 rendering.', err));
      chart2
        .render(chartDiv2.current)
        .then(() => {
          // chart2.getData().then((result) => {
          //   return setChartData2({ chartName: library.overviewReport[1].chartName, ...result });
          // });
        })
        .catch((err) => console.log('Error during Charts2 rendering.', err));
      chart3
        .render(chartDiv3.current)
        .then(() => {
          // chart3.getData().then((result) => {
          //   return setChartData3({ chartName: library.overviewReport[2].chartName, ...result });
          // });
        })
        .catch((err) => console.log('Error during Charts3 rendering.', err));
      chart4
        .render(chartDiv4.current)
        .then(() => {
          // chart4.getData().then((result) => {
          //   return setChartData4({ chartName: library.overviewReport[3].chartName, ...result });
          // });
        })
        .catch((err) => console.log('Error during Charts4 rendering.', err));
      dataChart
        .render(dataDiv.current)
        .then(() => {
          dataChart.getData().then((result) => {
            type resultType = keyof typeof result;
            type fieldsType = keyof typeof fields;
            type dataType = keyof (typeof data)[0];

            const fields = result['fields' as resultType] as Record<string, any>;
            const data = result['documents' as resultType] as Array<{ group: string }>;

            const nameObj = {
              region: '',
              channel: '',
            };
            type nameType = keyof typeof nameObj;

            // fields[fieldName as fieldsType] -> chart's field name
            for (const fieldName in fields) {
              nameObj[fields[fieldName as fieldsType] as nameType] = fieldName;
            }

            const dataList: Record<string, any[]> = {
              region: [],
              channel: [],
            };

            // filter duplicate choices
            data.forEach((item) => {
              for (const key in dataList) {
                if (
                  !!item[nameObj[key as nameType] as dataType] &&
                  !dataList[key].includes(item[nameObj[key as nameType] as dataType])
                ) {
                  dataList[key].push(item[nameObj[key as nameType] as dataType]);
                }
              }
            });

            selectFilter(dataList);
          });
        })
        .catch((err) => console.log('Error during data Chart rendering.', err));
    } else {
      console.log('Error');
    }
  }, [chartDiv1, chartDiv2, chartDiv3, chartDiv4, chart1, chart2, chart3, chart4]);

  useEffect(() => {
    setMyFilter({
      ...myFilter,
      // startDt: !!filter['startDt'] ? filter['startDt'] : undefined,
      // endDt: !!filter['startDt'] ? filter['startDt'] : undefined,
      // 'agent_profile.region': filter['region'],
      // 'agent_profile.channel': filter['channel'],
    });
  }, [filter]);

  useEffect(() => {
    const obj = JSON.parse(JSON.stringify(myFilter));

    // if (obj.startDt && obj.endDt) {
    //   obj.$and = [
    //     { createdAt: { $gte: new Date(moment(filter.startDt).format('yyyy-MM-DD')) } },
    //     { createdAt: { $lte: new Date(moment(filter.endDt).format('yyyy-MM-DD')) } },
    //   ];
    // } else if (obj.startDt || obj.endDt) {
    //   if (obj.startDt) {
    //     obj.createdAt = { $gte: new Date(moment(filter.startDt).format('yyyy-MM-DD')) };
    //   } else {
    //     obj.createdAt = { $lte: new Date(moment(filter.endDt).format('yyyy-MM-DD')) };
    //   }
    // }

    // delete obj.startDt;
    // delete obj.endDt;
    // obj['agent_profile.region'] = region;
    // obj['agent_profile.channel'] = channel;

    const hiddenChart1 = sdk.createChart({
      chartId: library.overviewReport[0].chartId,
      height: 800,
      theme: 'light',
      filter: obj,
    });
    const hiddenChart2 = sdk.createChart({
      chartId: library.overviewReport[1].chartId,
      height: 800,
      theme: 'light',
      filter: obj,
    });
    const hiddenChart3 = sdk.createChart({
      chartId: library.overviewReport[2].chartId,
      height: 800,
      theme: 'light',
      filter: obj,
    });
    const hiddenChart4 = sdk.createChart({
      chartId: library.overviewReport[3].chartId,
      height: 800,
      theme: 'light',
      filter: obj,
    });
    // excelChartreRender1(hiddenChart1);
    // excelChartreRender2(hiddenChart2);
    // excelChartreRender3(hiddenChart3);
    // excelChartreRender4(hiddenChart4);
    chart1.setFilter(obj).catch((err) => console.log('Error while filtering.', err));
    chart2.setFilter(obj).catch((err) => console.log('Error while filtering.', err));
    chart3.setFilter(obj).catch((err) => console.log('Error while filtering.', err));
    chart4.setFilter(obj).catch((err) => console.log('Error while filtering.', err));

    chart1.refresh().then(() => {
      chart1.getData().then((result) => {
        return setChartData1({ chartName: library.overviewReport[0].chartName, ...result });
      });
    });
    chart2.refresh().then(() => {
      chart2.getData().then((result) => {
        return setChartData2({ chartName: library.overviewReport[1].chartName, ...result });
      });
    });
    chart3.refresh().then(() => {
      chart3.getData().then((result) => {
        return setChartData3({ chartName: library.overviewReport[2].chartName, ...result });
      });
    });
    chart4.refresh().then(() => {
      chart4.getData().then((result) => {
        return setChartData4({ chartName: library.overviewReport[3].chartName, ...result });
      });
    });
  }, [chart1, chart2, chart3, chart4, myFilter]);

  // useEffect(() => {
  //   if (excelChartDiv1.current && excelChart1) {
  //     excelChart1
  //       .render(excelChartDiv1.current)
  //       .then(() => {
  //         excelChart1.getData().then((result) => {
  //           return setChartData1({ chartName: library.overviewReport[0].chartName, ...result });
  //         });
  //       })
  //       .catch((err) => console.log('Error during Charts rendering.', err));
  //   }
  //   if (excelChartDiv2.current && excelChart2) {
  //     excelChart2
  //       .render(excelChartDiv2.current)
  //       .then(() => {
  //         excelChart2.getData().then((result) => {
  //           return setChartData2({ chartName: library.overviewReport[1].chartName, ...result });
  //         });
  //       })
  //       .catch((err) => console.log('Error during Charts rendering.', err));
  //   }
  //   if (excelChartDiv3.current && excelChart3) {
  //     excelChart3
  //       .render(excelChartDiv3.current)
  //       .then(() => {
  //         excelChart3.getData().then((result) => {
  //           return setChartData3({ chartName: library.overviewReport[2].chartName, ...result });
  //         });
  //       })
  //       .catch((err) => console.log('Error during Charts rendering.', err));
  //   }
  //   if (excelChartDiv4.current && excelChart4) {
  //     excelChart4
  //       .render(excelChartDiv4.current)
  //       .then(() => {
  //         excelChart4.getData().then((result) => {
  //           return setChartData4({ chartName: library.overviewReport[3].chartName, ...result });
  //         });
  //       })
  //       .catch((err) => console.log('Error during Charts rendering.', err));
  //   }
  // }, [excelChart1, excelChart2, excelChart3, excelChart4]);

  useEffect(() => {
    updateData([chartData1, chartData2, chartData3, chartData4]);
  }, [chartData1, chartData2, chartData3, chartData4]);
  return (
    <div>
      <div className="top-charts" style={{ display: 'flex' }}>
        <div className="chart1" ref={chartDiv1} />
        <div className="chart2" style={{ marginLeft: 10 }} ref={chartDiv2} />
      </div>
      <div className="bottom-charts" style={{ marginTop: 10, display: 'flex' }}>
        <div className="chart3" ref={chartDiv3} />
        <div className="chart4" style={{ marginLeft: 10 }} ref={chartDiv4} />
      </div>
      <div className="data-chart" ref={dataDiv} hidden />
      {/* {excelChart1 && <div className="excel-chart" ref={excelChartDiv1} hidden />}
      {excelChart2 && <div className="excel-chart" ref={excelChartDiv2} hidden />}
      {excelChart3 && <div className="excel-chart" ref={excelChartDiv3} hidden />}
      {excelChart4 && <div className="excel-chart" ref={excelChartDiv4} hidden />} */}
    </div>
  );
};

export default OverviewReportDetailPage;
