import { FC } from 'react';
import { useIntl } from 'react-intl';
import { Button, Dialog, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { regionLocale } from 'src/app/i18n';
import { SendLinkDialogState } from 'src/app/modules/event-v2/types';
import { useCommonFormStyles } from '../../../../common';
import { useEventAssistantStyles } from '../../event-assistant-list.style';
import { useSendLinkDialog } from './send-link-dialog.hook';

type SendLinkDialogProps = {
  eventId: string;
  dialogState: SendLinkDialogState;
  onClose: () => void;
  resetDialogState: () => void;
};

export const SendLinkDialog: FC<SendLinkDialogProps> = ({ eventId, dialogState, onClose, resetDialogState }) => {
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);
  const { classes } = useEventAssistantStyles();
  const { classes: commonFormClasses } = useCommonFormStyles();
  const { open, selectedRows } = dialogState;
  const { formState, formDispatch, onSubmit } = useSendLinkDialog({ eventId, dialogState, resetDialogState });

  return (
    <Dialog classes={{ paper: classes.dialogPaper }} open={open}>
      <div className={classes.container}>
        <div className={classes.sendLinkDialogRemark}>
          {Translation('event.form.send_link_remark', { num: selectedRows.length.toString() })}:
        </div>
        <div className={classes.dialogFieldContainer}>
          <FormControl fullWidth className={classes.inputLabelField} variant="standard">
            <InputLabel id="lang-label" shrink className={commonFormClasses.inputLabel}>
              {Translation('event.form.language_preference')}
              <span className={commonFormClasses.mandatory}>*</span>
            </InputLabel>
            <Select
              labelId="lang-label"
              value={formState.lang || ''}
              onChange={(e) => {
                formDispatch({
                  type: 'MODIFY_FIELD',
                  payload: {
                    field: 'lang',
                    value: e.target.value,
                  },
                });
              }}
            >
              {regionLocale.map((option) => (
                <MenuItem key={option} value={option}>
                  {Translation(`navBar.lang.${option}`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className={classes.dialogFieldContainer}>
          <TextField
            disabled
            fullWidth
            variant="standard"
            label={Translation('event.common.title')}
            InputLabelProps={{ shrink: true, className: commonFormClasses.inputLabel }}
            value={formState.title[formState.lang]}
          />
        </div>
        <div className={classes.dialogFieldContainer}>
          <TextField
            disabled
            fullWidth
            multiline
            variant="standard"
            label={Translation('event.common.body')}
            InputLabelProps={{ shrink: true, className: commonFormClasses.inputLabel }}
            value={formState.body[formState.lang]}
          />
        </div>
        <div className={classes.footerContainer}>
          <Button variant="contained" color="inherit" onClick={() => onClose()}>
            {Translation('app.button.cancel')}
          </Button>
          <Button variant="contained" color="secondary" onClick={() => onSubmit()}>
            {Translation('app.button.confirm')}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
