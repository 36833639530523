import { makeStyles } from 'tss-react/mui';

const RADIO_GROUP_LABEL_WIDTH = 140;

export const useBasicsStyles = makeStyles()(() => ({
  tagFieldContainer: {
    marginBottom: 12,
  },
  radioGroupLabel: {
    width: RADIO_GROUP_LABEL_WIDTH,
    color: '#3F4254',
  },
  discardButton: {
    backgroundColor: 'white',
  },
}));
