import { AttachmentDef } from 'src/app/common/types/common-types';
import { I18nDataItem } from 'src/app/i18n';

export enum LeadSourceFormMode {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
}

export enum RegionalLeadSourceType {
  P_HEALTH_ASSESSMENT = 'Pulse - Health Assessment',
  P_COMMUNITY = 'Pulse - Community',
  P_PAYMENT = 'Pulse - Payment',
  P_SUBSCRIPTION = 'Pulse - Subscription',
  P_POLICY_ISSUED = 'Pulse - Policy Issued',
  P_OTHER = 'Pulse - Other',
  DM_WEBSITE = 'Digital Marketing - Website',
  DM_SOCIAL_MEDIA = 'Digital Marketing - Social Media',
  DM_PRODUCT_CAMPAIGNS = 'Digital Marketing - Product Campaigns',
  DM_OTHERS = 'Digital Marketing - Others (affiliate mktg, promotion partners, nuturing campaigns, producdt campaigns, etc)',
  ONE = 'Digital Marketing - PRULeads (nurturing campaigns)',
  DP = 'Digital Partnerships',
  CS_PRODUCT_PROMOTION = 'Cross-Sell - Product Promotion',
  CS_VIP = 'Cross-Sell - VIP',
  CS_TRIGGER_EVENTS = 'Cross-Sell - Trigger Events',
  CS_PULSE = 'Cross-Sell - Pulse',
  CS_DIGITAL_PORTAL = 'Cross-Sell - Digial/Direct Portal',
  CS_OTHER = 'Cross-Sell - Other',
  R_MATURITY_RECAPTURE = 'Retention - Maturity Recapture',
  R_PREVENT = 'Retention - Prevent [e.g. e-payment conversion, engagement type programs]',
  R_EARLY_WARNING = 'Retention - Early Warning [e.g. PH, payment past due, etc.]',
  R_KEEP = 'Retention - Keep [e.g. surrender prevention, interceptive programs, policy renewal]',
  R_REVIVE = 'Retention - Revive',
  R_OTHER = 'Retention - Other',
  DCR = 'Dormant Customer Recapture',
}

export type LeadSource = {
  _id: string;
  sourceId: string;
  sourceName: string;
  i18nData?: I18nDataItem;
  regionalLeadSource: string;
  channelCode?: string;
  image: AttachmentDef;
  parent?: string | LeadSource;
  createdBy: string;
  updatedBy: string;
  createdAt: Date;
  updatedAt: Date;
};
