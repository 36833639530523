import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { makeStyles } from 'tss-react/mui';
import { Button } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useStyles } from 'src/app/modules/AgencyCampaign/pages/CampaignType/Form/CampaignTypeDetailPage';
import { deepCopy } from 'src/app/modules/AgencyCampaign/utils/common-utils';
import {
  SectionFieldItem,
  RuleItem,
  EligibilityRuleOption,
  TypeEnum,
  RuleKeyEnum,
  RuleTypeEnum,
} from 'src/app/modules/AgencyCampaign/types/campaign-type-types';
import MandatoryRule from './Rules/MandatoryRule';
import MandatoryDependentRule from './Rules/MandatoryDependentRule';
import ValidationDaysRule from './Rules/ValidationDaysRule';
import ValidationNumberRule from './Rules/ValidationNumberRule';
import CheckEligibilityRule from './Rules/CheckEligibilityRule';
import UploadFileForm from './FieldContent/UploadFileForm';

type EditFieldFormProps = {
  initialField: SectionFieldItem;
  fieldPool: SectionFieldItem[];
  eligibilityRuleOptionList: EligibilityRuleOption[];
  handleClose: () => void;
  handleSubmit: (editedField: SectionFieldItem) => void;
};

const useFormStyles = makeStyles()((theme) => ({
  header: {
    fontSize: '1.4rem',
    fontWeight: 'bold',
    marginBottom: 16,
  },
  fieldContainer: {
    marginBottom: 8,
  },
  fieldValueContainer: {
    margin: '8px 0',
  },
  fieldValueFont: {
    fontSize: '1.15rem',
  },
}));

const EditFieldForm: FC<EditFieldFormProps> = ({
  initialField,
  fieldPool,
  eligibilityRuleOptionList,
  handleClose,
  handleSubmit,
}) => {
  const { classes } = useStyles();
  const { classes: formClasses } = useFormStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const [field, setField] = useState<SectionFieldItem>(() => deepCopy(initialField));
  const [errorState, _setErrorState] = useState<boolean[]>(() => initialField.rules.map((rule) => false));
  const setErrorState = (index: number, hasError: boolean) => {
    const newErrorState = [...errorState];
    newErrorState[index] = hasError;
    _setErrorState(newErrorState);
  };
  const [fieldContentErrorState, setFieldContentErrorState] = useState<boolean[]>(() =>
    Array.isArray(initialField.value) ? initialField.value.map((fieldValue) => false) : [],
  );

  const renderSwitchRule = (rule: RuleItem, index: number) => {
    switch (rule.key) {
      case RuleKeyEnum.MANDATORY:
      case RuleKeyEnum.MANDATORY_DEPENDS:
        return !rule.dependsOn ? (
          <MandatoryRule field={field} ruleIndex={index} setField={setField} />
        ) : (
          <MandatoryDependentRule field={field} ruleIndex={index} fieldPool={fieldPool} setField={setField} />
        );
      case RuleKeyEnum.COMPARE:
        if (rule.type === RuleTypeEnum.DATE) {
          return (
            <ValidationDaysRule field={field} ruleIndex={index} setField={setField} setErrorState={setErrorState} />
          );
        } else if (rule.type === RuleTypeEnum.NUMBER) {
          return (
            <ValidationNumberRule field={field} ruleIndex={index} setField={setField} setErrorState={setErrorState} />
          );
        } else {
          return <></>;
        }
      case RuleKeyEnum.LENGTH:
        return <></>;
      case RuleKeyEnum.ELIGIBILITY:
        return (
          <CheckEligibilityRule
            field={field}
            ruleIndex={index}
            eligibilityRuleOptionList={eligibilityRuleOptionList}
            setField={setField}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <div className={formClasses.header}>
        <span>{Translation('agencyCampaign.campaignType.section.editField')}</span>
        <span className={classes.closeBtn} onClick={handleClose}>
          <Close />
        </span>
      </div>

      <div className={formClasses.fieldContainer}>
        <div>
          <span>
            {Translation('agencyCampaign.campaignType.section.editField.fieldName')}
            <span className={classes.mandatory}>*</span>
          </span>
        </div>
        <div className={formClasses.fieldValueContainer}>
          <span className={formClasses.fieldValueFont}>{Translation(`agencyCampaign.field.${field.key}`)}</span>
        </div>
      </div>

      <div className={formClasses.fieldContainer}>
        <div>
          <span>
            {Translation('agencyCampaign.campaignType.section.editField.fieldFormat')}
            <span className={classes.mandatory}>*</span>
          </span>
        </div>
        <div className={formClasses.fieldValueContainer}>
          <span className={formClasses.fieldValueFont}>
            {Translation(`agencyCampaign.campaignType.section.editField.type.${field.type}`)}
          </span>
          {[TypeEnum.SINGLE_CHOICE, TypeEnum.MULTI_CHOICE, TypeEnum.DROPDOWN].includes(field.type) && (
            <div style={{ marginLeft: 16 }}>
              {field.options &&
                field.options.map((option, index) => (
                  <div className={formClasses.fieldValueContainer} style={{ display: 'flex' }}>
                    <div style={{ boxSizing: 'border-box', width: 85 }}>
                      {Translation('agencyCampaign.campaignType.section.editField.option')} {index + 1}
                    </div>
                    <div>{option.label}</div>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>

      <div className={formClasses.fieldContainer}>
        <div>
          <span>
            {Translation('agencyCampaign.campaignType.section.editField.fieldRule')}
            <span className={classes.mandatory}>*</span>
          </span>
        </div>
        <div className={formClasses.fieldValueContainer}>
          {field.rules.map((rule, index) => renderSwitchRule(rule, index))}
        </div>
      </div>

      {field.key === 'tnc' && (
        <div className={formClasses.fieldContainer}>
          <div>
            <span>
              {Translation('agencyCampaign.campaignType.section.editField.fieldContent')}
              <span className={classes.mandatory}>*</span>
            </span>
          </div>
          <div className={formClasses.fieldValueContainer}>
            <UploadFileForm
              field={field}
              setField={setField}
              errorState={fieldContentErrorState}
              setErrorState={setFieldContentErrorState}
            />
          </div>
        </div>
      )}

      <div className={classes.footerContainer}>
        <Button variant="contained" onClick={handleClose} style={{ marginRight: 20 }}>
          {Translation('app.button.cancel')}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            if (!errorState.includes(true) && !fieldContentErrorState.includes(true)) {
              if (field.key !== 'tnc' || (field.key === 'tnc' && field.value.length !== 0)) {
                handleSubmit(field);
              }
            }
          }}
        >
          {Translation('app.button.submit')}
        </Button>
      </div>
    </>
  );
};

export default EditFieldForm;
