import React, { FC } from 'react';
import Form from 'src/app/common/components/Form';
import { useIntl } from 'react-intl';
import { TextField, RadioGroup, FormControlLabel, Radio, IconButton } from '@mui/material';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { styles } from '../../style';
import SelectRuleTable from './selectRuleTable';
import Close from '@mui/icons-material/Close';
import { regionLocale } from 'src/app/i18n';
import { commonStyles, useStyles } from '../style';
import { commonSelectType } from '../../../../constants';
import { POINT_VIDATOR } from '../../util';

export const hasMaxPoint = {
  YES: 'yes',
  NO: 'no',
};

const ListItem: FC<any> = ({ onDelete, swapArray, index, setAllRules, allRules, length, form }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const DEFAULT_REQUIRED_RULES = { required: true, message: Translation('component.form-required-text') };
  const { classes } = useStyles();
  const { classes: detailClass } = styles();
  const regionLocales = regionLocale;

  const OVERRIDE_RULE_VIDATOR = [
    {
      validator(_: any, value: string) {
        let specifiyTargetType = form.getFieldValue(['groupList', index, 'enableGroupAction']) !== 'no';
        if (specifiyTargetType && !value) {
          return Promise.reject(new Error(Translation('component.form-required-text')));
        }
        if (specifiyTargetType && value && !/^[+]{0,1}(\d+)$/.test(value)) {
          return Promise.reject(new Error(Translation('must_be_an_integer_bigger_than_0')));
        }
        return Promise.resolve();
      },
    },
  ];

  return (
    <div className={detailClass.border} style={commonStyles.mt20}>
      <div style={commonStyles.groupRuleArea}>
        <span style={commonStyles.groupRuleTitle}>
          {Translation('pulseleads.activityPoint.ruleSettings.common.groupRuleName')}
          <span>
            {index !== 0 && (
              <ArrowUpward
                className={classes.moveBtn}
                onClick={() => {
                  swapArray(index, index - 1);
                }}
              />
            )}
            {index !== length - 1 && (
              <ArrowDownward
                className={classes.moveBtn}
                onClick={() => {
                  swapArray(index + 1, index);
                }}
              />
            )}
            <IconButton
              onClick={() => {
                onDelete(index);
              }}
            >
              <Close />
            </IconButton>
          </span>
        </span>
        {regionLocales.map((locale: string) => {
          return (
            <Form.Item
              label={Translation(`navBar.lang.${locale}`)}
              name={['groupList', index, 'name', locale]}
              rules={[DEFAULT_REQUIRED_RULES]}
              style={commonStyles.ml50}
              key={locale}
            >
              <TextField style={commonStyles.nameInput} margin="dense" variant="outlined" size="small" />
            </Form.Item>
          );
        })}
      </div>

      <div style={commonStyles.desArea}>
        <span>{Translation('pulseleads.activityPoint.ruleSettings.common.description')}</span>
        {regionLocales.map((locale: string) => {
          return (
            <Form.Item
              label={Translation(`navBar.lang.${locale}`)}
              name={['groupList', index, 'description', locale]}
              rules={[DEFAULT_REQUIRED_RULES]}
              style={commonStyles.ml50}
              key={locale}
            >
              <TextField style={commonStyles.desInput} margin="dense" variant="outlined" size="small" />
            </Form.Item>
          );
        })}
      </div>

      <Form.Item
        name={['groupList', index, 'enableGroupAction']}
        label={Translation('enable_override_rule')}
        style={commonStyles.ml20}
        rules={[DEFAULT_REQUIRED_RULES]}
        initialValue={commonSelectType.No.value}
      >
        <RadioGroup
          aria-label="enable_override_rule"
          name="enable_override_rule"
          style={commonStyles.flex_row_ml50}
          defaultValue={form.getFieldValue('groupList')?.[index]?.enableGroupAction}
        >
          {Object.keys(commonSelectType).map((key: string) => (
            <FormControlLabel
              key={commonSelectType[key].text}
              value={commonSelectType[key].value}
              control={<Radio />}
              label={commonSelectType[key].text}
            />
          ))}
        </RadioGroup>
      </Form.Item>

      <Form.Item>
        {(_, meta, { getFieldValue }) => {
          return getFieldValue('groupList')?.[index]?.enableGroupAction === 'true' ? (
            <div style={commonStyles.flex_row_center}>
              <Form.Item
                name={['groupList', index, 'groupActionPoint']}
                rules={POINT_VIDATOR}
                style={commonStyles.groupActionPoint}
              >
                <TextField style={commonStyles.w150} margin="dense" variant="outlined" size="small" />
              </Form.Item>
              <span className="tw-ml-2.5 tw-mr-2.5">{Translation('point_per')}</span>
              <Form.Item
                name={['groupList', index, 'groupActionCount']}
                rules={OVERRIDE_RULE_VIDATOR}
                style={commonStyles.groupActionCount}
              >
                <TextField style={commonStyles.minW150} margin="dense" variant="outlined" size="small" />
              </Form.Item>
              <span className="tw-ml-2.5">{Translation('point_action')}</span>
            </div>
          ) : null;
        }}
      </Form.Item>

      <div>
        <SelectRuleTable index={index} setAllRules={setAllRules} allRules={allRules} form={form} />
      </div>

      <div>
        <div style={commonStyles.maxPointArea}>
          <Form.Item style={commonStyles.maxPointTitle} name={['groupList', index, 'hasMaxPoint']}>
            <span style={commonStyles.flex_row}>
              <span style={commonStyles.pointTitleWidth}>
                <span>{Translation('pulseleads.activityPoint.ruleSettings.common.maximumPoint')}</span>
                <span style={commonStyles.colorRed}>*</span>
              </span>
              <RadioGroup
                aria-labelledby="radio-buttons-group-label"
                defaultValue={form.getFieldValue('groupList')?.[index]?.hasMaxPoint}
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value={hasMaxPoint.YES}
                  control={<Radio style={commonStyles.flex_flexStart_mt5} />}
                  label={
                    <Form.Item>
                      {(_, meta, { getFieldValue }) => {
                        return getFieldValue('groupList')?.[index]?.hasMaxPoint !== 'no' ? (
                          <span style={commonStyles.flex_row_center}>
                            <span style={commonStyles.mr15}>{'Yes'}</span>
                            <Form.Item name={['groupList', index, 'maxPoint']} rules={POINT_VIDATOR}>
                              <TextField style={commonStyles.minW150} margin="dense" variant="outlined" size="small" />
                            </Form.Item>
                          </span>
                        ) : (
                          <span style={commonStyles.mr15}>{'Yes'}</span>
                        );
                      }}
                    </Form.Item>
                  }
                />
                <FormControlLabel
                  value={hasMaxPoint.NO}
                  control={<Radio style={commonStyles.flex_flexStart} />}
                  label="No"
                />
              </RadioGroup>
            </span>
          </Form.Item>
        </div>
        <div style={commonStyles.suggestedPointArea}>
          <Form.Item name={['groupList', index, 'suggestedPoint']}>
            <span style={commonStyles.flex_row}>
              <span style={commonStyles.titleWidth}>
                <span>{Translation('pulseleads.activityPoint.ruleSettings.common.suggestedPoint')}</span>
              </span>
              <TextField
                style={commonStyles.minW100}
                margin="dense"
                variant="outlined"
                size="small"
                defaultValue={form.getFieldValue('groupList')?.[index]?.suggestedPoint}
              ></TextField>
            </span>
          </Form.Item>
        </div>
      </div>
    </div>
  );
};

export default ListItem;
