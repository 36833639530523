import React, { FC } from 'react';

import { useIntl } from 'react-intl';

import PruTable from 'src/app/common/components/PruTable/PruTable';

import { MassUploadStatusListItem } from 'src/app/modules/Profile/types/agent-profile-types';

type MassUploadFailListProps = {
  isLoading: boolean;
  failList?: MassUploadStatusListItem;
};

type DialogState = {
  open: boolean;
  id: string;
};

const MassUploadFailList: FC<MassUploadFailListProps> = ({ isLoading, failList }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  return (
    <>
      <PruTable
        title={Translation('agentProfile.ad_failed_list_header_title')}
        disableBulkSelect
        operationDef={[]}
        columnDef={[
          {
            isId: true,
            hidden: true,
            keyIndex: '_id',
            displayName: '',
            // eslint-disable-next-line
            renderData: (row) => '',
          },

          {
            keyIndex: 'rowNumber',
            align: 'left',
            displayName: Translation('agentProfile.ad_row_number_label'),
            renderData: (row) => row.rowNumber.toString() || '-',
          },
          {
            keyIndex: 'failureReason',
            align: 'left',
            displayName: Translation('failed_reason'),
            renderData: (row) => row.failureReason || '-',
          },
        ]}
        isLoading={isLoading}
        dataSource={failList?.failedDetails}
        disablePagination
      />
    </>
  );
};

export default MassUploadFailList;
