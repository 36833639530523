import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles<{ level: number }>()((_, { level }) => ({
  stepperContainer: {
    marginLeft: 32 * level,
  },
  stepLabel: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    padding: '15px 0',
    borderBottom: '1px solid #F0F0F0',
  },
  stepIcon: {
    width: 16,
    height: 16,
  },
  stepText: {
    flexGrow: 1,
    padding: '0 15px',
  },
  mainIcon: {
    width: 10,
    height: 10,
    margin: 7,
  },
  arrowIcon: {
    height: 16,
    width: 16,
    marginRight: 10,
  },
}));
