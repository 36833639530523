import { RuleItem, RuleOperatorItem } from "../enum/rule-enum"

export enum DeduplicationRuleFormMode {
  CREATE = "CREATE",
  EDIT = "EDIT"
}

export type DeduplicationRuleOperator = {
  type: string;
  operator: string;
  priority: number;
} & RuleOperatorItem;

export type DeduplicationRuleItem = {
  name?: string;
  items: DeduplicationRuleOperator[];
} & RuleItem

export type DeduplicationRule = {
  _id: string;
  name: string;
  description?: string;
  enforcedBy: string;
  rules: DeduplicationRuleItem[];
  redistributionAfterDays: number;
  createdBy: string;
  updatedBy: string;
  createdAt: Date;
  updatedAt: Date;
}