import React from "react";
import { Button } from '@mui/material';

const filterConfig = (Translation: any, onSearch: any, onReset: any, locale:string) => {
    return [
        [
            { type: 'header', label: Translation('form.pulseleads.engagement.topic.list'), sm: true },
            {
                type: 'custom',
                sm: false,
                render: () =>
                    <Button variant="contained" color="inherit"  onClick={onReset}>
                        {Translation('golbal.filters.reset')}
                    </Button>
            },
            {
                type: 'custom',
                sm: false,
                render: () =>
                    <Button variant="contained" color="secondary" onClick={onSearch} >
                        {Translation('golbal.filters.search')}
                    </Button>
            },
        ],
        [
            { type: 'input', label: Translation('component.formLabel.name'), prop: 'name', width: 0, placeholder: `${Translation('form.pulseleads.engagement.topic.placeholder')}` },
            {
                type: 'select', label: Translation('component.formLabel.publish-status'), prop: 'publishStatus', opts: [
                    { label: 'All', value: '' },
                    { label: Translation('event.publish.status.Draft.label'), value: '0' },
                    { label: Translation('event.publish.status.Published.label'), value: '1' },
                    { label: Translation('event.publish.status.Unpublished.label'), value: '2' },
                ],
            },
            { type: 'dateRange', label: Translation('component.formLabel.last-updated-time'), prop: 'timeRange', format: "DD/MM/YYYY HH:mm:ss", width: 0 },
        ],
    ];
};

export default filterConfig;