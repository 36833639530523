import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useDataProvider } from 'src/app/common/utils';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { PaginateList } from 'src/app/common/types/common-types';
import { BulkUploadFailedItem } from 'src/app/modules/PulseLeads/types/bulk-upload-types';
import {
  fetchBulkUploadFailedList,
  BulkUploadFailedListParam,
} from 'src/app/modules/PulseLeads/network/bulkUploadCrud';
import BulkUploadFailedList from './components/BulkUploadFailedList';

type BulkUploadFailedListingPageProps = {} & ParamsProps;

const initialState: BulkUploadFailedListParam = {
  page: 1,
  limit: 5,
};

const paramsInitiator = (initialParams?: Record<string, string>): BulkUploadFailedListParam => {
  return initialParams
    ? {
        page: 1,
        limit: 20,
      }
    : initialState;
};

const BulkUploadFailedListingPage: FC<BulkUploadFailedListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const { id } = useParams<{ id: string }>();
  const intl = useIntl();
  const history = useHistory();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const [filterState, setFilterState] = useState<BulkUploadFailedListParam>(paramsInitiator(initialParams));
  const [bulkUploadFailedList, setBulkUploadFailedList] = useState<PaginateList<BulkUploadFailedItem>>();

  const { isLoading, refreshData } = useDataProvider<PaginateList<BulkUploadFailedItem>>(
    async () => {
      if (id) {
        try {
          if (onChangeQueryParams) {
            onChangeQueryParams(filterState);
          }
          return await fetchBulkUploadFailedList(id, filterState, dispatch);
        } catch (err) {
          history.push(NOT_FOUND_ERROR_PAGE_PATH);
        }
      }
    },
    setBulkUploadFailedList,
    false,
  );

  return (
    <>
      <BulkUploadFailedList
        isLoading={isLoading}
        bulkUploadFailedList={bulkUploadFailedList}
        onRefresh={refreshData}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default BulkUploadFailedListingPage;
