import { apiClient, ADMIN_URL } from 'src/app/common/network';

const baseUrl = `${ADMIN_URL}/course-collections`;

export const getCollectionList = async (params?: any) => {
  return apiClient.get(`${baseUrl}/templates`, { params });
};

export const getCollection = async (id: string) => {
  return apiClient.get(`${baseUrl}/templates/${id}`);
};
