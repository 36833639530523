export const defaultRegistrationFormQuestionNames = [
  'page1',
  'registrant_firstName',
  'registrant_lastName',
  'registrant_email',
  'countryCode_phoneNumber',
  'registrant_countryCode',
  'registrant_phoneNumber',
  'register_sessions',
];

export const nameElements = [
  {
    type: 'text',
    name: 'registrant_firstName',
    title: {
      default: 'First Name',
      'zh-tw': '名',
    },
    isRequired: true,
    maxLength: 25,
    requiredErrorText: {
      default: 'Field is required',
      'zh-tw': '欄位為必填項',
    },
  },
  {
    type: 'text',
    name: 'registrant_lastName',
    title: {
      default: 'Last Name',
      'zh-tw': '姓',
    },
    isRequired: false,
    maxLength: 25,
  },
];

export const emailElement = {
  type: 'text',
  name: 'registrant_email',
  title: { default: 'Email', 'zh-tw': '電郵地址' },
  placeholder: {
    default: 'Please input',
    'zh-tw': '請輸入',
  },
  requiredErrorText: {
    default: 'Field is required',
    'zh-tw': '欄位為必填項',
  },
  validators: [
    {
      type: 'email',
      text: {
        default: 'Please enter a valid email address',
        'zh-tw': '請輸入正確格式之電郵地址',
      },
    },
  ],
  isRequired: true,
  allowAddPanel: false,
  allowRemovePanel: false,
};

export const phoneElement = {
  type: 'panel',
  name: 'countryCode_phoneNumber',
  title: { default: 'Phone Number', 'zh-tw': '電話號碼' },
  requiredErrorText: {
    default: 'Field is required',
    'zh-tw': '欄位為必填項',
  },
  placeholder: {
    default: 'Please input',
    'zh-tw': '請輸入',
  },
  elements: [
    {
      type: 'dropdown',
      name: 'registrant_countryCode',
      title: {
        default: 'Region',
        'zh-tw': '地區',
      },
      requiredErrorText: {
        default: 'Field is required',
        'zh-tw': '欄位為必填項',
      },
      isRequired: true,
      width: '40%',
      minWidth: '0',
    },
    {
      type: 'text',
      name: 'registrant_phoneNumber',
      startWithNewLine: true,
      title: {
        default: 'Phone',
        'zh-tw': '電話號碼',
      },
      requiredErrorText: {
        default: 'Field is required',
        'zh-tw': '欄位為必填項',
      },
      validators: [
        {
          type: 'expression',
          text: {
            default: 'Invalid phone number',
            'zh-tw': '無效的電話號碼',
          },
          expression: 'phoneNumberValidate({registrant_countryCode}, {registrant_phoneNumber})',
        },
      ],
      isRequired: true,
      width: '60%',
      minWidth: '0',
    },
  ],
  isRequired: true,
  allowAddPanel: false,
  allowRemovePanel: false,
  showNumber: true,
  showQuestionNumbers: 'off',
};
