import React, { FC, useState } from 'react';
import AgentList from './components/AgentList';
import { ConfigAgentItem, ConfigAgentPaginateList } from '../../../types/config-type';
import { ConfigAgentTypeParam, fetchConfigAgentItem, fetchConfigAgentList } from '../../../network/configCrud';
import { useDataProvider } from 'src/app/common/utils';
import { useDispatch } from 'react-redux';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { useIntl } from 'react-intl';

type ConfigAgentPageProps = {} & ParamsProps;

export const configAgentFilterKeys = ['agentCode'];

const initialState: ConfigAgentTypeParam = {
  agentCode: '',
};

const paramsInitiator = (initialParams?: Record<string, string>): ConfigAgentTypeParam => {
  return initialParams
    ? {
        agentCode: initialParams.agentCode ?? '',
      }
    : initialState;
};

const ConfigAgentListPage: FC<ConfigAgentPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const [filterState, setFilterState] = useState<ConfigAgentTypeParam>(paramsInitiator(initialParams));
  const [ConfigAgentList, setConfigAgentList] = useState<ConfigAgentPaginateList>();
  const [SpecificConfigAgent, setSpecificConfigAgent] = useState<ConfigAgentItem>();
  const [InFilterState, setInFilterState] = useState<boolean>(false);

  useDataProvider<ConfigAgentPaginateList>(
    async () => {
      try {
        return await fetchConfigAgentList(filterState, dispatch);
      } catch (err) {}
    },
    setConfigAgentList,
    false,
  );

  const { isLoading, refreshData } = useDataProvider<ConfigAgentItem>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        if (filterState.agentCode && filterState.agentCode !== '') {
          setInFilterState(true);
          return await fetchConfigAgentItem(filterState.agentCode, dispatch);
        } else {
          setInFilterState(false);
          setSpecificConfigAgent(undefined);
        }
      } catch (err) {}
    },
    setSpecificConfigAgent,
    false,
  );

  return (
    <>
      <PruFilter
        title={Translation('configAgent.filter')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            style: { width: 250 },
            field: 'agentCode',
            initialValue: filterState.agentCode,
            displayName: Translation('configAgent.common.agentCode'),
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            agentCode: data.agentCode,
          })
        }
        fetchData={refreshData}
      />
      {InFilterState ? (
        <AgentList
          isLoading={isLoading}
          onRefresh={refreshData}
          configAgentList={SpecificConfigAgent ? [SpecificConfigAgent] : undefined}
        />
      ) : (
        <AgentList isLoading={isLoading} onRefresh={refreshData} configAgentList={ConfigAgentList?.userConfig} />
      )}
    </>
  );
};

export default ConfigAgentListPage;
