import React, { FC, useEffect, useRef, useState } from 'react';
import { LayoutSplashScreen } from 'src/app/layout';
import { Tab, Tabs } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import PageOneDetail from './detail/page-one/page-one';
import PageTwoDetail from './detail/page-two/page-two';
import { MTmode } from 'src/app/common/constants';
import { getARLConfigs } from './network/api';

const AgentLeadFormPage: FC<RouteComponentProps> = ({ history, location }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [value, setValue] = useState(0);
  const [formStates, setFormStates] = useState<any[]>([]);
  const dispatch = useDispatch();
  const fetchConfig = async () => {
    setIsLoading(true);
    const configs = await getARLConfigs(dispatch);
    if (configs && configs.length > 0) {
      setValue(0);
      const formConfigs = configs.map((config: any) => {
        return {
          ...config,
          formMode: MTmode.VIEW,
        };
      });
      setFormStates(formConfigs);
    }
    setIsLoading(false);
  };

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const updateConfigs = (configs: any[]) => {
    setFormStates(configs);
  };

  useEffect(() => {
    fetchConfig();
  }, []);

  return (
    <div className="tw-h-full tw-w-full">
      <div className="tw-h-full tw-w-full">
        {formStates?.length > 1 ? (
          <Tabs indicatorColor="secondary" textColor="inherit" value={value} aria-label={'tab'} onChange={handleChange}>
            {formStates?.map((config, index) => {
              return <Tab key={config.name} label={String(config.sourceId).toUpperCase()} id={`config-${index}`} />;
            })}
          </Tabs>
        ) : (
          <></>
        )}

        {formStates?.map((config, index) => {
          return (
            <TabPanel key={config.name} value={value} index={index}>
              <AgentLeadFormDetail configs={formStates} index={index} setConfigs={updateConfigs} />
            </TabPanel>
          );
        })}
      </div>
    </div>
  );
};

const TabPanel: FC<any> = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className="tw-h-full tw-w-full"
    >
      {value === index && children}
    </div>
  );
};

const AgentLeadFormDetail: FC<any> = (props) => {
  const [pageIndex, setPageIndex] = useState<number>(0);

  const onPrevPage = () => {
    setPageIndex(pageIndex - 1);
  };
  const onNextPage = () => {
    setPageIndex(pageIndex + 1);
  };

  const renderSwitch = () => {
    switch (pageIndex) {
      case 0:
        return <PageOneDetail {...props} onNextPage={onNextPage} />;
      case 1:
        return <PageTwoDetail {...props} onPrevPage={onPrevPage} />;
      default:
        return <></>;
    }
  };

  return <div className="tw-h-full tw-w-full">{renderSwitch()}</div>;
};

export default AgentLeadFormPage;
