import { I18nGenericData } from 'src/app/i18n';
import {
  RegistrationMethodEnum,
  RegistrationNotificationMethodEnum,
  RegistrationAttendanceRuleEnum,
  EventConfirmationBodyItem,
  EventSessionItem,
} from 'src/app/modules/event-v2/types';

export type RegistrationAttendanceFormState = {
  regQuotaPerAgent?: string;
  regStartDate?: Date | null;
  regEndDate?: Date | null;
  regMethod?: RegistrationMethodEnum;
  regLink?: string;
  regNotificationMethod?: RegistrationNotificationMethodEnum;
  regConfirmationBody?: EventConfirmationBodyItem;
  regAttendanceRule?: RegistrationAttendanceRuleEnum;
  regAllowWalkIn?: boolean | null;
  sessions: EventSessionItem[];
};

type SetFormStateAction = {
  type: 'SET_FORM_STATE';
  payload: {
    value: Partial<RegistrationAttendanceFormState>;
  };
};

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof RegistrationAttendanceFormState;
    value: any;
  };
};

type ModifyRegConfirmationBodyAction = {
  type: 'MODIFY_REG_CONFIRMATION_BODY';
  payload: {
    field: keyof EventConfirmationBodyItem;
    value: I18nGenericData<string>;
  };
};

type ModifySessionAction = {
  type: 'MODIFY_SESSION';
  payload: {
    index: number;
    value: EventSessionItem;
  };
};

type ModifySubSessionAction = {
  type: 'MODIFY_SUB_SESSION';
  payload: {
    parentIndex: number;
    index: number;
    value: EventSessionItem;
  };
};

type FillInDetailAction = {
  type: 'FILL_IN_DETAIL';
  payload: {
    fromLocale: string;
    toLocale: string;
  };
};

export type RegistrationAttendanceFormAction =
  | SetFormStateAction
  | ModifyFieldAction
  | ModifyRegConfirmationBodyAction
  | ModifySessionAction
  | ModifySubSessionAction
  | FillInDetailAction;

export const registrationAttendanceFormReducer = (
  state: RegistrationAttendanceFormState,
  action: RegistrationAttendanceFormAction,
): RegistrationAttendanceFormState => {
  const newSessions = [...state.sessions];
  switch (action.type) {
    case 'SET_FORM_STATE':
      return {
        ...state,
        ...action.payload.value,
      };
    case 'MODIFY_FIELD':
      const newState = {
        ...state,
        [action.payload.field]: action.payload.value,
      };
      return newState;
    case 'MODIFY_REG_CONFIRMATION_BODY':
      return {
        ...state,
        regConfirmationBody: {
          ...(state.regConfirmationBody as EventConfirmationBodyItem),
          [action.payload.field]: {
            ...state.regConfirmationBody?.[action.payload.field],
            ...action.payload.value,
          },
        },
      };
    case 'MODIFY_SESSION':
      newSessions[action.payload.index] = action.payload.value;
      return {
        ...state,
        sessions: newSessions,
      };
    case 'MODIFY_SUB_SESSION':
      newSessions[action.payload.parentIndex].subSessions[action.payload.index] = action.payload.value;
      return {
        ...state,
        sessions: newSessions,
      };
    case 'FILL_IN_DETAIL':
      const fromLocale = action.payload.fromLocale;
      const toLocale = action.payload.toLocale;
      return {
        ...state,
        regConfirmationBody: {
          title: {
            ...state.regConfirmationBody?.title,
            [toLocale]: state.regConfirmationBody?.title?.[fromLocale],
          },
          body: {
            ...state.regConfirmationBody?.body,
            [toLocale]: state.regConfirmationBody?.body?.[fromLocale],
          },
        },
      };
    default:
      return state;
  }
};
