import { Dispatch } from 'react';
import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import { forEach } from 'lodash';
import { DistributionRule, DistributionRuleItem, OpenLeadOptionEnum } from '../types/distribution-rule-types';
import { PaginateList } from 'src/app/common/types/common-types';

const distributionRuleEndpoint = 'distribution-rules';

export type DistributionRuleListParam = {
  name: string;
  sortBy: string;
  page: number;
  limit: number;
};

export type CreateDistributionRuleBody = {
  name: string;
  description?: string;
  openLeadOption?: OpenLeadOptionEnum;
  method: string;
  rules: DistributionRuleItem[];
  createdBy: string;
  updatedBy: string;
};

export type UpdateDistributionRuleBody = {
  description?: string;
  openLeadOption?: OpenLeadOptionEnum;
  method: string;
  rules: DistributionRuleItem[];
  updatedBy: string;
};

export const fetchDistributionRuleList = async (
  params: DistributionRuleListParam,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<DistributionRule>> => {
  let queryPath = `${ADMIN_URL}/${distributionRuleEndpoint}?`;
  forEach(params, (param, key) => {
    if (key === 'page') {
      queryPath += `${key}=${encodeURIComponent(param as any)}&`;
    } else {
      queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
    }
  });
  return apiClient
    .get<PaginateList<DistributionRule>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchDistributionRule = async (id: string, dispatch?: Dispatch<any>): Promise<DistributionRule> => {
  return apiClient
    .get<DistributionRule>(`${ADMIN_URL}/${distributionRuleEndpoint}/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createDistributionRule = async (
  body: CreateDistributionRuleBody,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .post<void>(`${ADMIN_URL}/${distributionRuleEndpoint}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const modifyDistributionRule = async (
  id: string,
  body: UpdateDistributionRuleBody,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .put<void>(`${ADMIN_URL}/${distributionRuleEndpoint}/${id}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const deleteDistributionRule = async (id: string, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .delete<void>(`${ADMIN_URL}/${distributionRuleEndpoint}/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
