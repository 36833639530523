import React, { FC } from 'react';
import { useIntl } from "react-intl";
import { FormControlLabel } from '@mui/material';
import { CheckCircle, RadioButtonUnchecked } from '@mui/icons-material';
import AcmCheckbox from 'src/app/modules/AgencyCampaign/common/AcmCheckbox';
import { useStyles } from 'src/app/modules/AgencyCampaign/pages/CampaignType/Form/CampaignTypeDetailPage';
import { SectionFieldItem } from 'src/app/modules/AgencyCampaign/types/campaign-type-types';

type MandatoryRuleProps = {
  field: SectionFieldItem;
  ruleIndex: number;
  setField: (field: SectionFieldItem) => void;
}

const MandatoryRule: FC<MandatoryRuleProps> = ({ field, ruleIndex, setField }) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const rule = field.rules[ruleIndex];

  return (
    <div className={classes.sectionMargin}>
      <div>{ruleIndex + 1}. {Translation(`agencyCampaign.rule.${rule.key}`)} ?</div>
      <div>
        <FormControlLabel
          style={{ margin: "2px 12px 2px 0" }}
          disabled={field.forcedMandatory}
          control={
            <AcmCheckbox
              icon={<RadioButtonUnchecked />}
              checkedIcon={<CheckCircle />}
              checked={rule.value === true}
              onChange={e => {
                if (e.target.checked) {
                  const newField = {...field};
                  newField.rules[ruleIndex].value = true;
                  setField(newField);
                }
              }}
            />
          }
          label={Translation("app.checkbox.yes")}
          labelPlacement="end"
        />
        <FormControlLabel
          style={{ margin: "2px 0" }}
          disabled={field.forcedMandatory}
          control={
            <AcmCheckbox
              icon={<RadioButtonUnchecked />}
              checkedIcon={<CheckCircle />}
              checked={rule.value === false}
              onChange={e => {
                if (e.target.checked) {
                  const newField = {...field};
                  newField.rules[ruleIndex].value = false;
                  setField(newField);
                }
              }}
            />
          }
          label={Translation("app.checkbox.no")}
          labelPlacement="end"
        />
      </div>
    </div>
  )
}

export default MandatoryRule;