import { FC } from 'react';
import { useIntl } from 'react-intl';
import { map } from 'lodash';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { useEventListingPage } from './event-listing-page.hook';
import { EventAccessibilityEnum, EventStatusEnum, EventPublishStatusEnum } from '../../../types';
import { EventList } from './components/event-list.component';

type EventListingPageProps = ParamsProps;

export const EventListingPage: FC<EventListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const {
    filterState,
    eventList,
    isLoading,
    setFilterState,
    refreshData,
    fetchCategoryDropdown,
    onSort,
    onDownloadQRCode,
    onCopyAndNew,
  } = useEventListingPage({
    initialParams,
    onChangeQueryParams,
  });

  return (
    <>
      <PruFilter
        title={Translation('title.event.list.filter')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'search',
            initialValue: filterState.search,
            displayName: Translation('event.common.name'),
          },
          {
            type: PruFilterItemType.ASYNC_DROPDOWN,
            style: { width: 250 },
            field: 'category',
            initialValue: filterState.category,
            displayName: Translation('event.common.category'),
            initialOptions: [{ displayName: Translation('component.status.all'), value: '' }],
            fetchList: fetchCategoryDropdown,
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'accessibility',
            initialValue: filterState.accessibility,
            displayName: Translation('event.common.accessibility'),
            choices: [
              { displayName: Translation('component.status.all'), value: '' },
              ...map(EventAccessibilityEnum, (option) => ({
                displayName: Translation(`event.common.accessibility.${option.toLowerCase()}`),
                value: option,
              })),
            ],
          },
          {
            type: PruFilterItemType.DATE,
            field: 'eventDate',
            initialDate: filterState.eventDate,
            displayName: Translation('event.common.event_date'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'createdBy',
            initialValue: filterState.createdBy,
            displayName: Translation('event.common.created_by'),
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'eventStatus',
            initialValue: filterState.eventStatus,
            displayName: Translation('title.event.event.status'),
            choices: [
              { displayName: Translation('component.status.all'), value: '' },
              ...map(EventStatusEnum, (option) => ({
                displayName: Translation(`event.common.event_status.${option.toLowerCase()}`),
                value: option,
              })),
            ],
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'publishStatus',
            initialValue: filterState.publishStatus,
            displayName: Translation('component.formLabel.publish-status'),
            choices: [
              { displayName: Translation('component.status.all'), value: '' },
              ...map(EventPublishStatusEnum, (option) => ({
                displayName: Translation(`event.common.publish_status.${option.toLowerCase()}`),
                value: option,
              })),
            ],
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            search: data.search,
            category: data.category,
            accessibility: data.accessibility,
            eventDate: data.eventDate,
            createdBy: data.createdBy,
            eventStatus: data.eventStatus,
            publishStatus: data.publishStatus,
          })
        }
        fetchData={refreshData}
      />
      <EventList
        isLoading={isLoading}
        eventList={eventList}
        onRefresh={refreshData}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
        onSort={onSort}
        onDownloadQRCode={onDownloadQRCode}
        onCopyAndNew={onCopyAndNew}
      />
    </>
  );
};
