import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  content: {
    flexGrow: 1,
  },
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  fieldContainer: {
    width: 160,
    boxSizing: 'border-box',
  },
  subHeader: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
  },
  textRowHeight: {
    height: 50,
  },
  submitButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 10,
  },
}));
