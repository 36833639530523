import React, { FC, useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { useIntl } from 'react-intl';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { LibraryListParam, LibraryPaginateList } from '../../types/library-types';
import { CategoryPaginateList } from '../../../Category/types/category-types';
import { useDataProvider } from 'src/app/common/utils';
import { fetchAllCategoryList, fetchLibraryList } from '../../network/libraryCrud';
import { useDispatch } from 'react-redux';
import { useLang } from 'src/app/i18n';
import EditMaterialSetListTable from './components/EditMaterialSetListTable';

interface ResourceProps {
  blockName: string;
  blockType: string;
  moduleType: string;
  selectedMaterialSet: any;
  handleMaterialModal: (isOpen: boolean) => void;
  addResoureceToSet: (resources: any) => void;
}

const paramsInitiator = (moduleType: string): LibraryListParam => {
  return {
    page: 1,
    pageSize: 20,
    name: '',
    module: moduleType,
  };
};

const useStyles = makeStyles()((theme) => ({
  modalButtons: {
    marginTop: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialog: {
    padding: '15px',
  },
}));

const EditMaterialSetList: FC<ResourceProps> = ({
  blockType,
  blockName,
  moduleType,
  selectedMaterialSet,
  handleMaterialModal,
  addResoureceToSet,
}) => {
  const locale = useLang();
  const { classes } = useStyles();
  const [selectedRowsState, setSelectedRows] = useState<any[]>([]);
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [filterState, setFilterState] = useState<LibraryListParam>(paramsInitiator(moduleType));
  const [categoryOptionList, setCategoryOptionList] = useState<{ displayName: string; value: string }[]>();
  const dispatch = useDispatch();
  const [libraryList, setLibraryList] = useState<LibraryPaginateList>();
  const [sortKey, setSortKey] = useState<{ key: string; value?: string }[]>([]);

  const { isLoading, refreshData } = useDataProvider<LibraryPaginateList>(
    async () => {
      try {
        return await fetchLibraryList(locale, filterState, sortKey, dispatch);
      } catch (err) {}
    },
    setLibraryList,
    false,
  );

  const filterOptions = {
    rType: [
      { displayName: Translation('component.status.all'), value: '' },
      { displayName: 'File', value: 'file' },
      { displayName: 'Link', value: 'link' },
      { displayName: 'Content', value: 'content' },
      { displayName: 'Material Set', value: 'materialSet' },
      { displayName: 'Text', value: 'text' },
    ],
    status: [
      { displayName: Translation('component.status.all'), value: '' },
      { displayName: Translation('component.status.published'), value: 'Published' },
      { displayName: Translation('component.status.unpublished'), value: 'Unpublished' },
      { displayName: Translation('component.status.draft'), value: 'Draft' },
      { displayName: Translation('component.status.publishedToSetOnly'), value: 'PublishedToSetOnly' },
    ],
  };

  useDataProvider<CategoryPaginateList>(
    async () => {
      try {
        const allCategoryList = await fetchAllCategoryList(moduleType, dispatch);
        let optionList: { displayName: string; value: string }[] = [
          { displayName: Translation('component.status.all'), value: '' },
        ];
        allCategoryList.categoryList.map((item) => {
          return optionList.push({ displayName: item.name, value: item.name });
        });
        setCategoryOptionList(optionList);
        return allCategoryList;
      } catch (err) {}
    },
    () => {},
    false,
  );

  const onAddResourceToMaterialSet = () => {
    addResoureceToSet(selectedRowsState);
  };

  const updateSortingKey = (sortingKey: { key: string; value?: string }) => {
    const sortingKeyArray = sortKey.filter((currentValue, index, arr) => {
      return currentValue.key !== sortingKey.key;
    });
    sortingKeyArray.unshift(sortingKey);
    setSortKey(sortingKeyArray);
  };

  useEffect(() => {
    refreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortKey]);

  return (
    <>
      <PruFilter
        title={Translation('component.resource.selectNew')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'name',
            initialValue: filterState.name,
            displayName: Translation('component.formLabel.name'),
          },
          {
            type: PruFilterItemType.DROPDOWN,
            style: { width: 250 },
            field: 'category',
            initialValue: filterState.category,
            displayName: Translation('component.formLabel.category'),
            choices: categoryOptionList
              ? categoryOptionList
              : [{ displayName: Translation('component.status.all'), value: '' }],
          },
          {
            type: PruFilterItemType.DROPDOWN,
            style: { width: 150 },
            field: 'status',
            initialValue: filterState.status,
            displayName: Translation('component.formLabel.status'),
            choices: filterOptions.status,
          },
        ]}
        onChangeFilter={(data) => {
          setFilterState({
            ...filterState,
            name: data.name,
            category: data.category,
            status: data.status,
          });
        }}
        fetchData={refreshData}
      />
      <EditMaterialSetListTable
        isLoading={isLoading}
        libraryList={libraryList}
        libraryTableTitle={blockName}
        onRefresh={refreshData}
        onSort={(dataKey) => updateSortingKey(dataKey)}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            pageSize: rowsPerPage,
          });
          refreshData();
        }}
        enableUpdate={true}
        enableCreate={true}
        currentLanguage={locale}
        setCurrentSelectedRow={(data) => {
          setSelectedRows(data);
        }}
        selectedMaterialSet={selectedMaterialSet}
      />

      <div className={classes.modalButtons}>
        <Button
          onClick={() => {
            setOpenConfirmDialog(true);
          }}
          style={{ marginLeft: 20 }}
          variant="contained"
        >
          {Translation('app.button.cancel')}
        </Button>
        <Button onClick={onAddResourceToMaterialSet} style={{ marginLeft: 20 }} variant="contained" color="secondary">
          {Translation('app.button.submit')}
        </Button>
      </div>

      <Dialog
        open={openConfirmDialog}
        onClose={() => {
          setOpenConfirmDialog(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.dialog}
      >
        <DialogTitle id="alert-dialog-title">{Translation('component.hint.cancel-warning')}</DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenConfirmDialog(false);
            }}
            variant="contained"
          >
            {Translation('global.text.no')}
          </Button>
          <Button
            onClick={() => {
              handleMaterialModal(false);
            }}
            color="secondary"
            variant="contained"
            autoFocus
          >
            {Translation('global.text.yes')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditMaterialSetList;
