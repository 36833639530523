import { map } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import PruFilter, { PruFilterDropdownItem, PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { usersReportParams } from 'src/app/modules/PruleadInsightsReporting/types/report-types';
import UserReportDetailPage from './components/UserReportDetailPage';
import { useJWT } from 'src/app/common/utils';
const ExportJsonExcel = require('js-export-excel');

type UserReportListingPageProps = {} & ParamsProps;

type selectFilterType = {
  businessUnit: string[];
  region: string[];
  branch: string[];
  unit: string[];
  mda: string[];
  agentCode: string[];
  agentName: string[];
  position: string[];
  segment: string[];
  pvm: string[];
  others: string[];
  channel: string[];
};

const initialState: usersReportParams = {
  businessUnit: '',
  region: '',
  branch: '',
  unit: '',
  mda: '',
  agentCode: '',
  agentName: '',
  position: '',
  segment: '',
  pvm: '',
  dateRange: {
    startDate: undefined,
    endDate: undefined,
  },
  others: '',
  channel: '',
};

const paramsInitiator = (initialParams?: Record<string, string>): usersReportParams => {
  return initialParams
    ? {
        businessUnit: '',
        region: '',
        branch: '',
        unit: '',
        mda: '',
        agentCode: '',
        agentName: '',
        position: '',
        segment: '',
        pvm: '',
        dateRange: {
          startDate: undefined,
          endDate: undefined,
        },
        others: '',
        channel: '',
      }
    : initialState;
};

export const userReportFilterKeys = [];

const UserReportListingPage: FC<UserReportListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const jwt = useJWT() || {};
  const { region, channel } = jwt;
  const [filterState, setFilterState] = useState<usersReportParams>({
    ...paramsInitiator(initialParams),
    region,
    channel,
  });
  const [chartData, setChartData] = useState<{ fields: object; documents: object[] }>();

  const [selectFilter, setSelectFilter] = useState<selectFilterType>({
    businessUnit: [],
    region: [],
    branch: [],
    unit: [],
    mda: [],
    agentCode: [],
    agentName: [],
    position: [],
    segment: [],
    pvm: [],
    others: [],
    channel: [],
  });

  const downloadExcel = async () => {
    let sheetHeader: any[] = [];
    let sheetData: any[] = [];

    if (chartData) {
      type fieldsType = keyof typeof chartData.fields;

      let fields = chartData.fields;
      let fieldsKey: string[] = [];

      for (let key in fields) {
        fieldsKey.push(key as fieldsType);
        sheetHeader.push(fields[key as fieldsType]);
      }

      //adjust the order of data : group -> group_series_(index) -> value
      chartData.documents.forEach((doc: any) => {
        let tmpDoc: Record<string, string> = {};
        fieldsKey.forEach((key) => {
          tmpDoc[key] = doc[key] ?? '';
        });
        sheetData.push(tmpDoc);
      });
      // over

      var option = {
        fileName: Translation('insights.dashboard.filter.aolUsers'),
        datas: [
          {
            sheetData: sheetData,
            sheetName: 'sheet',
            sheetHeader: sheetHeader,
          },
        ],
      };
      var toExcel = new ExportJsonExcel(option);

      toExcel.saveExcel();
    }
  };

  return (
    <>
      <PruFilter
        title={Translation('insights.dashboard.filter.aolUsers')}
        itemDef={[
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'businessUnit',
            displayName: 'Business Unit',
            initialValue: filterState.businessUnit,
            choices: [
              {
                displayName: 'All',
                value: 'all',
              },
              ...map(selectFilter.businessUnit, (option: string) => ({ displayName: option, value: option })),
            ],
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'region',
            displayName: 'Region',
            initialValue: filterState.region,
            choices: [{ displayName: region, value: region }],
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'branch',
            displayName: 'Branch',
            initialValue: filterState.branch,
            choices: [
              {
                displayName: 'All',
                value: 'all',
              },
              ...map(selectFilter.branch, (option: string) => ({ displayName: option, value: option })),
            ],
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'unit',
            displayName: 'Unit',
            initialValue: filterState.unit,
            choices: [
              {
                displayName: 'All',
                value: 'all',
              },
              ...map(selectFilter.unit, (option: string) => ({ displayName: option, value: option })),
            ],
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'mda',
            displayName: 'MDA',
            initialValue: filterState.mda,
            choices: [
              {
                displayName: 'All',
                value: 'all',
              },
              ...map(selectFilter.mda, (option: string) => ({ displayName: option, value: option })),
            ],
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'agentCode',
            displayName: 'Agent Code',
            initialValue: filterState.agentCode,
            choices: [
              {
                displayName: 'All',
                value: 'all',
              },
              ...map(selectFilter.agentCode, (option: string) => ({ displayName: option, value: option })),
            ],
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'agentName',
            displayName: 'Agent Name',
            initialValue: filterState.agentName,
            choices: [
              {
                displayName: 'All',
                value: 'all',
              },
              ...map(selectFilter.agentName, (option: string) => ({ displayName: option, value: option })),
            ],
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'position',
            displayName: 'Position',
            initialValue: filterState.position,
            choices: [
              {
                displayName: 'All',
                value: 'all',
              },
              ...map(selectFilter.position, (option: string) => ({ displayName: option, value: option })),
            ],
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'segment',
            displayName: 'Segment',
            choices: [
              {
                displayName: 'All',
                value: 'all',
              },
              ...map(selectFilter.segment, (option: string) => ({ displayName: option, value: option })),
            ],
            initialValue: filterState.segment,
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'pvm',
            displayName: 'PVM',
            initialValue: filterState.pvm,
            choices: [
              {
                displayName: 'All',
                value: 'all',
              },
              ...map(selectFilter.pvm, (option: string) => ({ displayName: option, value: option })),
            ],
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'startDate',
            fieldTo: 'endDate',
            displayName: 'Date',
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'others',
            displayName: 'Others',
            initialValue: filterState.others,
            choices: [
              {
                displayName: 'All',
                value: 'all',
              },
              ...map(selectFilter.others, (option: string) => ({ displayName: option, value: option })),
            ],
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'channel',
            displayName: 'Channel',
            choices: [{ displayName: channel, value: channel }],
            initialValue: filterState.channel,
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            businessUnit: data.businessUnit,
            region: data.region,
            branch: data.branch,
            unit: data.unit,
            mda: data.mda,
            agentCode: data.agentCode,
            agentName: data.agentName,
            position: data.position,
            segment: data.segment,
            pvm: data.pvm,
            dateRange: {
              startDate: data.startDate,
              endDate: data.endDate,
            },
            others: data.others,
            channel: data.channel,
          })
        }
        fetchData={() => {}}
        downloadExcel={downloadExcel}
      />
      <UserReportDetailPage
        filter={filterState}
        selectFilter={setSelectFilter}
        updateData={(data) => setChartData(data)}
      />
    </>
  );
};

export default UserReportListingPage;
