import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import {
  RECRUITMENT_BASE_PATH,
  RECRUITMENT_ONBOARDING_TRAINING,
  RECRUITMENT_QUIZ_REPORT_PATH,
} from '../../../constants';
import { NOT_FOUND_ERROR_PAGE_PATH } from '../../../../../common/constants';
import PruRoute from '../../../../../common/components/PruRoute';
import RegistrationExportPage from './quiz-report-export-page';

export const quizReportBasePath = `${RECRUITMENT_BASE_PATH}${RECRUITMENT_ONBOARDING_TRAINING}${RECRUITMENT_QUIZ_REPORT_PATH}`;
const QuizReportRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect exact from={`${quizReportBasePath}`} to={`${quizReportBasePath}/export`} />
      <PruRoute exact path={`${quizReportBasePath}/export`} component={RegistrationExportPage} />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default QuizReportRoutes;
