export const PRODUCTION_BASE_PATH = '/production';
export const PRODUCTION_DEFAULT_TARGET_BASE_PATH = '/default-target';
export const PRODUCTION_TARGET_BASE_PATH = '/target';

export const MANDATORY_FIELD_ERROR_TEXT = 'This field is mandatory';

//  These constants map to aside menu, header menu to display title
export const PRODUCTION_TITLE = "title.production";
export const PRODUCTION_DEFAULT_TARGET_TITLE = "title.productionDefaultTarget";
export const PRODUCTION_TARGET_TITLE = "title.productionTarget";

//  These constants map to permission control, identical to permission enum defined below
export const PRODUCTION_DEFAULT_TARGET_NAMESPACE = "PRODUCTION-DEFAULT-TARGET";
export const PRODUCTION_TARGET_NAMESPACE = "PRODUCTION-TARGET";

export enum AdminProductionPermissionType {
  PRODUCTION_DEFAULT_TARGET_READ = "READ_ANY_PRODUCTION-DEFAULT-TARGET",
  PRODUCTION_DEFAULT_TARGET_CREATE = "CREATE_ANY_PRODUCTION-DEFAULT-TARGET",
  PRODUCTION_DEFAULT_TARGET_UPDATE = "UPDATE_ANY_PRODUCTION-DEFAULT-TARGET",
  PRODUCTION_TARGET_READ = "READ_ANY_PRODUCTION-TARGET",
  PRODUCTION_TARGET_CREATE = "CREATE_ANY_PRODUCTION-TARGET",
  PRODUCTION_TARGET_UPDATE = "UPDATE_ANY_PRODUCTION-TARGET",
};