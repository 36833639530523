export const PULSELEADS_BULKUPLOAD_TITLE = "title.pulseleads.bulkUpload";
export const PULSELEADS_BULKUPLOAD_UPLOAD_TITLE = "title.pulseleads.bulkUpload.upload";
export const PULSELEADS_BULKUPLOAD_STATUS_TITLE = "title.pulseleads.bulkUpload.status";
export const PULSELEADS_BULKUPLOAD_STATUS_BULK_UPLOAD_TITLE = "title.pulseleads.bulkUpload.bulkUpload";
export const PULSELEADS_BULKUPLOAD_STATUS_IMPORT_AGENT_TITLE = "title.pulseleads.bulkUpload.importAgent";

export const PULSELEADS_BULKUPLOAD_UPLOAD_NAMESPACE = "PULSELEADS-BULKUPLOAD-UPLOAD";
export const PULSELEADS_BULKUPLOAD_STATUS_NAMESPACE = "PULSELEADS-BULKUPLOAD-STATUS";


export const PULSELEADS_BULKUPLOAD_PATH = "/bulk-upload";
export const PULSELEADS_BULKUPLOAD_UPLOAD_PATH = "/upload";
export const PULSELEADS_BULKUPLOAD_STATUS_PATH = "/status";
export const PULSELEADS_BULKUPLOAD_STATUS_PATH_STATUS_LIST = "/bulkUpload";
export const PULSELEADS_BULKUPLOAD_STATUS_PATH_IMPORT_AGENT_LIST = "/importAgent";