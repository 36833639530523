import React, { FC, useState } from 'react';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useDataProvider } from 'src/app/common/utils';
import RuleSettingList from './component/RuleSettingsList';
import { fetchRuleSettingList } from 'src/app/modules/PulseLeads/network/ruleConfigCrud';
type RuleSettingPageProps = any & ParamsProps;

const List: FC<RuleSettingPageProps> = ({ initialParams }) => {
  const [ruleSettingsList, setRuleSettingsList] = useState<any>();
  const { isLoading, refreshData } = useDataProvider<any>(
    async () => {
      try {
        const list = await fetchRuleSettingList(initialParams);
        //sort by updatedAt
        return list.sort(
          (a: { updatedAt: string | number | Date; isDefault: boolean }, b: { updatedAt: string | number | Date }) => {
            const dateSort = a.isDefault ? 1 : new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
            return dateSort;
          },
        );
      } catch (err) {}
    },
    setRuleSettingsList,
    false,
  );

  return (
    <>
      <RuleSettingList isLoading={isLoading} ruleSettingsList={ruleSettingsList} onRefresh={refreshData} />
    </>
  );
};

export default List;
