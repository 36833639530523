import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { PaginateList } from 'src/app/common/types';
import { useDataProvider } from 'src/app/common/utils';
import { ComponentProps } from 'src/app/common/components/pru-stepped-form';
import {
  EventFormCommonProps,
  EventAssistantItem,
  EventAssistantDialogState,
  SendLinkDialogState,
} from 'src/app/modules/event-v2/types';
import {
  EventAssistantListParam,
  fetchEventAssistantList,
  deleteEventAssistantItem,
} from 'src/app/modules/event-v2/network';

type HookProps = ComponentProps<EventFormCommonProps>;

const initialSendLinkDialogState = {
  open: false,
  selectedRows: [],
};

export const useEventAssistantList = ({}: HookProps) => {
  const dispatch = useDispatch();
  const { id: eventId } = useParams<{ id?: string }>();

  const [filterState, setFilterState] = useState<EventAssistantListParam>({
    page: 1,
    limit: 20,
  });
  const [eventAssistantList, setEventAssistantList] = useState<PaginateList<EventAssistantItem>>();
  const [eventAssistantDialogState, setEventAssistantDialogState] = useState<EventAssistantDialogState>({
    open: false,
  });
  const [sendLinkDialogState, setSendLinkDialogState] = useState<SendLinkDialogState>(initialSendLinkDialogState);

  const { isLoading, refreshData: refreshEventAssistantList } = useDataProvider<PaginateList<EventAssistantItem>>(
    async () => {
      try {
        if (eventId) {
          return await fetchEventAssistantList(eventId, filterState, dispatch);
        }
      } catch (err) {}
    },
    setEventAssistantList,
    false,
  );

  const onRemove = async (id: string) => {
    if (eventId) {
      try {
        await deleteEventAssistantItem(eventId, id, dispatch);
        refreshEventAssistantList();
        setSendLinkDialogState({
          ...sendLinkDialogState,
          selectedRows: sendLinkDialogState.selectedRows.filter((row) => row._id !== id),
        });
      } catch (err) {}
    }
  };

  const resetSendLinkDialogState = () => {
    setSendLinkDialogState(initialSendLinkDialogState);
  };

  return {
    eventId,
    filterState,
    eventAssistantList,
    eventAssistantDialogState,
    sendLinkDialogState,
    isLoading,
    setFilterState,
    setEventAssistantDialogState,
    setSendLinkDialogState,
    refreshEventAssistantList,
    onRemove,
    resetSendLinkDialogState,
  };
};
