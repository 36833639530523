import { generateXlsx, toXlsxData } from './utils';
import { FeatureType, fetchMaterialPerformance } from '../../../../network/libraryCrud';
import { constants } from 'src/app/common/constants';

/**
 * export xlsx: Resource Library > Sales Material > Sales Library
 */
export async function getDateAndGenerateExcel(
  feature: FeatureType,
  leftTime: moment.Moment,
  rightTime: moment.Moment,
  name: string,
  id: string,
) {
  // get data
  const res = await fetchMaterialPerformance(
    feature,
    leftTime.startOf('day').format(constants.Standard_DateFormat),
    rightTime.endOf('day').format(constants.Standard_DateFormat),
    id,
  );
  generateXlsx(leftTime, rightTime, toXlsxData(res), name);
}
