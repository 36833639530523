import React, { useState, useEffect } from 'react';
import Form from 'src/app/common/components/Form';
import { useIntl } from "react-intl";
import { makeStyles } from 'tss-react/mui';
import { Button } from '@mui/material';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import CourseSet from './CourseSet';
import { styles as useDetailClass } from './../style';

const RequiredTrainingCourses: React.FC<any> = (props) => {
    const { classes: commonClasses } = useCommonStyles();
    const { classes: detailClass } = useDetailClass();
    const intl = useIntl();
    const Translation = (id: string) => intl.formatMessage({ id });
    const { form, disabled } = props;
    const [hasCouseSet, setHasCouseSet] = useState<boolean>(true);

    const COURSE_SET_VALIDATE = [{
        validator(_: any, data: any) {


            if (data && data.length === 0) {
                setHasCouseSet(false)
                return Promise.reject(new Error(Translation('mandatory.training.component.at.least.one.course.set.tips')))
            }

            setHasCouseSet(true)

            return Promise.resolve();
        }
    }];

    return (
        <div>
            <div className={detailClass.divideMargin} >
                <div className={commonClasses.sessionHeader}>{Translation("mandatory.training.component.required.training.course.title")} <span className={detailClass.mandatory}>*</span> <span className={detailClass.titleTips}>{Translation('mandatory.training.component.required.training.course.title.tips')}</span></div>
            </div>


            <Form.List
                name="trainingSetTemplates"
                rules={COURSE_SET_VALIDATE}
            >
                {
                    (fields, { add, remove }) => {
                        return (
                            <>
                                {
                                    fields.map((field, index) => {

                                        return (
                                            <CourseSet
                                                index={index}
                                                field={field}
                                                key={`trainingSet_${index}`}
                                                onRemove={(index: number) => { remove(index) }}
                                                form={form}
                                                disabled={disabled}
                                            />
                                        )
                                    })
                                }
                               {
                                    !disabled && <Form.Item>
                                        <div style={{ display: 'flex', flexDirection: 'column'}}>
                                            <div>
                                                <Button
                                                    style={{ float: 'left', marginTop: 15 }}
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={() => {
                                                        add();
                                                    }}
                                                >
                                                    {Translation("mandatory.training.component.required.training.button.add.set")}
                                                </Button>
                                            </div>
                                            {
                                                !hasCouseSet && <p className={commonClasses.errorRedText}>{Translation('mandatory.training.component.at.least.one.course.set.tips')}</p>
                                            }
                                        </div>
                                    </Form.Item>
                               }

                            </>
                        )
                    }
                }
            </Form.List>

        </div>
    )
};

export default RequiredTrainingCourses;