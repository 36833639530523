import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import PruRoute from 'src/app/common/components/PruRoute';
import { RECRUIT_BASE_PATH, RECRUIT_FOLLOW_UP_CASE_PATH } from '../../constants';
import ParamsProvider from 'src/app/common/components/ParamsProvider';
import FollowUpCaseListingPage, { followUpCaseFilterKeys } from './List/FollowUpCaseListingPage';

export const followUpCasePath = `${RECRUIT_BASE_PATH}${RECRUIT_FOLLOW_UP_CASE_PATH}`;

const FollowUpCaseRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect exact from={followUpCasePath} to={`${followUpCasePath}/list`} />
      <PruRoute exact path={`${followUpCasePath}/list`} render={(props) => (
        <ParamsProvider {...props} acceptKeys={followUpCaseFilterKeys}>
          <FollowUpCaseListingPage />
        </ParamsProvider>
      )}/>
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  )
}

export default FollowUpCaseRoutes;