import { makeStyles } from 'tss-react/mui';
import { LEFT_SIDE_WIDTH, FIELD_CONTAINER_SPACING } from '../target-applicant-radio-mode.style';

export const useStyles = makeStyles()(() => ({
  agentListButtonContainer: {
    display: 'flex',
    columnGap: 20,
    marginLeft: LEFT_SIDE_WIDTH,
  },
  agentListTableContainer: {
    marginTop: FIELD_CONTAINER_SPACING,
    marginBottom: FIELD_CONTAINER_SPACING,
    marginLeft: LEFT_SIDE_WIDTH,
  },
  deleteAgentDialogPaper: {
    width: 600,
    maxWidth: 600,
  },
  deleteAgentDialogActions: {
    padding: 18,
  },
}));
