import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { PaginateList } from 'src/app/common/types/common-types';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { getDefaultDisplayDate } from 'src/app/common/utils';
import ItemDeleteDialog from 'src/app/common/components/ItemDeleteDialog';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import { ExclusionRule } from 'src/app/modules/PulseLeads/types/exclusion-rule-types';
import { deleteExclusionRule } from 'src/app/modules/PulseLeads/network/exclusionRuleCrud';
import { exclusionRulePath } from '../../ExclusionRuleRoutes';

type ExclusionRuleListProps = {
  isLoading: boolean;
  exclusionRuleList?: PaginateList<ExclusionRule>;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
};

type DialogState = {
  open: boolean;
  id: string;
};

const ExclusionRuleList: FC<ExclusionRuleListProps> = ({ isLoading, exclusionRuleList, onRefresh, onChangePage }) => {
  const history = useHistory();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const [dialogState, setDialogState] = useState<DialogState>({
    open: false,
    id: '',
  });

  const onDelete = async (id: string) => {
    try {
      await deleteExclusionRule(id, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Exclusion Rule deleted successfully - ${id}`,
          },
        ]),
      );
    } catch (err) {}
  };

  return (
    <>
      <ItemDeleteDialog
        key={`delete-exclusion-rule-dialog-${dialogState.open}`}
        open={dialogState.open}
        onClose={() => setDialogState({ open: false, id: '' })}
        onRefresh={onRefresh}
        onDelete={() => onDelete(dialogState.id)}
      />
      <PruTable
        title={Translation('pulseleads.exclusionRule.list')}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('pulseleads.exclusionRule.newRule'),
            onClick: () => history.push(`${exclusionRulePath}/create`),
          },
        ]}
        disableBulkSelect
        operationDef={[
          {
            title: Translation('section.common.operation.edit'),
            tooltipText: 'Edit Exclusion Rule',
            onClick: (row) => history.push(`${exclusionRulePath}/edit/${row._id}`),
          },
          {
            title: Translation('section.common.operation.delete'),
            tooltipText: 'Delete Exclusion Rule',
            onClick: (row) => setDialogState({ open: true, id: row._id }),
          },
        ]}
        columnDef={[
          {
            isId: true,
            hidden: true,
            keyIndex: '_id',
            displayName: '',
            renderData: (row) => '',
          },
          {
            keyIndex: 'name',
            displayName: Translation('pulseleads.exclusionRule.common.name'),
            renderData: (row) => row.name || '-',
          },
          {
            keyIndex: 'description',
            align: 'center',
            displayName: Translation('pulseleads.exclusionRule.common.description'),
            renderData: (row) => row.description || '-',
          },
          {
            keyIndex: 'updatedBy',
            align: 'center',
            displayName: Translation('pulseleads.exclusionRule.common.updatedBy'),
            renderData: (row) => row.updatedBy || '-',
          },
          {
            keyIndex: 'createdAt',
            align: 'center',
            displayName: Translation('pulseleads.exclusionRule.common.createdAt'),
            renderData: (row) => getDefaultDisplayDate(new Date(row.createdAt), 'datetime'),
          },
          {
            keyIndex: 'updatedAt',
            align: 'center',
            displayName: Translation('pulseleads.exclusionRule.common.updatedAt'),
            renderData: (row) => getDefaultDisplayDate(new Date(row.updatedAt), 'datetime'),
          },
        ]}
        isLoading={isLoading}
        onRefresh={onRefresh}
        dataSource={exclusionRuleList?.docs}
        totalPages={exclusionRuleList?.totalPages}
        totalRecords={exclusionRuleList?.totalDocs}
        onChangePage={onChangePage}
      />
    </>
  );
};

export default ExclusionRuleList;
