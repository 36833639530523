export type EventAssistantFormState = {
  name?: string;
  email?: string;
};

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof EventAssistantFormState;
    value: any;
  };
};

export type EventAssistantFormAction = ModifyFieldAction;

export const eventAssistantFormReducer = (
  state: EventAssistantFormState,
  action: EventAssistantFormAction,
): EventAssistantFormState => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    default:
      return state;
  }
};
