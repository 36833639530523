import { makeStyles } from 'tss-react/mui';

export const styles = makeStyles()((theme) => ({
  bg: {
    backgroundColor: 'white',
  },
  divideMargin: {
    marginBottom: 10,
  },
  border: {
    borderColor: '#979797',
    borderWidth: 1,
    borderStyle: 'solid',
  },
  moveBtn: {
    margin: '5px',
    width: '16px',
    height: '16px',
    borderRadius: '50%',
    borderWidth: '1px',
    borderColor: 'black',
    borderStyle: 'solid',
    cursor: 'pointer',
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  errorText: {
    fontSize: 9,
    color: '#F018A6',
  },
  textArea: {
    lineHeight: 1.5,
    minHeight: 40,
  },
  marginLeft10: {
    marginLeft: 10,
  },
  paddingBottom20: {
    paddingBottom: 20,
  },
  flexDirectionRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  flexDirectionColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  marginLeft200: {
    marginLeft: 200,
  },
  weekTextArea: {
    minWidth: 200,
    marginLeft: 10,
  },
  remarkArea: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  remarkSubTitle: {
    marginLeft: 10,
    marginTop: 10,
    width: 170,
  },
  margin20: {
    margin: 20,
  },
  buttonGroup: {
    marginBottom: 15,
    display: 'flex',
    justifyContent: 'center',
  },
  backdrop: {
    position: 'fixed',
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.common.white,
    backgroundColor: `${theme.palette.common.white} !important`,
    opacity: '0.5 !important',
  },
  fixed: {
    '& label': {
      marginBottom: 0,
    },
    '& .pru-form-item': {
      padding: '4px 0',
      position: 'relative',
    },
    '& .MuiFormControl-root': {
      marginTop: 0,
      marginBottom: 0,
    },
    '& .MuiTableCell-root': {
      padding: '15px 24px',
    },
    '& .pru-form-item ul': {
      position: 'absolute',
      left: 0,
      whiteSpace: 'nowrap',
      fontSize: 12,
    },
    '& .flexRowTip ul': {
      left: 10,
    },
    '& .flexRowWeekTip ul': {
      left: 170,
    },
  },
}));

export const commonStyles = {
  paddingBottom20: {
    paddingBottom: 20,
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    alignItems: 'center',
  },
  flexCol: {
    display: 'flex',
    flexDirection: 'column' as 'column',
  },
  ml200: {
    marginLeft: 200,
  },
  textInput: {
    minWidth: 150,
    marginLeft: 10,
  },
  ml15: {
    marginLeft: 15,
  },
  w200: {
    width: 200,
  },
  w150: {
    width: 150,
    marginLeft: 10,
    marginTop: 0,
  },
  colorRed: {
    color: 'red',
  },
  mt20: {
    marginTop: 20,
  },
};
