import React, { useEffect, useState } from 'react';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { styles, useStyles } from '../../style';
import Form from 'src/app/common/components/Form';
import { Button, TextField } from '@mui/material';
import { MTmode } from '../../../../../constants';
import { regionLocale } from 'src/app/i18n';
import { FormSectionProps } from 'src/app/modules/lead-microsite/types/microsite.type';

const SubmissonSection: FC<FormSectionProps> = (props) => {
  const { form, formMode } = props;
  const isView = formMode === MTmode.VIEW;
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const DEFAULT_REQUIRED_RULES = { required: true, message: Translation('component.form-required-text') };
  const [data, setData] = useState(form.getFieldsValue(true));

  useEffect(() => {
    if (form.getFieldsValue(true) !== data) {
      setData(form.getFieldsValue(true));
    }
  }, [form.getFieldsValue(true)]);

  const handleChange = (value: string, key: string, language: string) => {
    const { ...body } = form.getFieldsValue(true);
    if (!body[key]) {
      body[key] = {};
    }
    body[key][language] = value;
    form.setFieldsValue(body);
    setData(body);
  };

  return (
    <div className="tw-bg-white tw-pt-6 tw-pb-6 tw-mt-6">
      <div className="tw-flex tw-mb-3">
        <div className={classes.subHeader}>{Translation('agent_referral_link_submission_setting')}</div>
      </div>

      {regionLocale?.map((language, index) => {
        return (
          <div className={classes.contentTextField} key={language}>
            <Form.Item
              name={['submissionResponseTitle', language]}
              label={`${Translation('agent_referral_link_title')}(${language.toLocaleUpperCase()})`}
              rules={[DEFAULT_REQUIRED_RULES]}
            >
              <div className="tw-flex tw-flex-row tw-items-center tw-flex-1">
                <TextField
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  disabled={isView}
                  value={data?.submissionResponseTitle?.[language] ?? ''}
                  onChange={(e) => {
                    handleChange(e.target.value, 'submissionResponseTitle', language);
                  }}
                  placeholder={Translation('survey.form.thankyou.title')}
                />
                {index !== 0 && (
                  <Button
                    size="small"
                    variant="text"
                    onClick={() => {
                      if (!isView) {
                        handleChange(
                          form.getFieldValue(['submissionResponseTitle', `${regionLocale[0]}`]),
                          'submissionResponseTitle',
                          language,
                        );
                      }
                    }}
                    style={styles.FieldCopyText}
                  >
                    {Translation('agent_referral_link_copy')}
                  </Button>
                )}
              </div>
            </Form.Item>
          </div>
        );
      })}

      {regionLocale?.map((language: string, index: number) => {
        return (
          <div className={classes.contentTextField} key={language}>
            <Form.Item
              name={['submissionResponseContent', language]}
              label={`${Translation('agent_referral_link_content')}(${language.toLocaleUpperCase()})`}
              required={false}
            >
              <div className="tw-flex tw-flex-row tw-items-center tw-flex-1">
                <TextField
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  disabled={isView}
                  value={data?.submissionResponseContent?.[language] ?? ''}
                  onChange={(e) => {
                    handleChange(e.target.value, 'submissionResponseContent', language);
                  }}
                  placeholder={Translation('survey.form.thankyou.content')}
                />
                {index !== 0 && (
                  <Button
                    size="small"
                    variant="text"
                    onClick={() => {
                      if (!isView) {
                        handleChange(
                          form.getFieldValue(['submissionResponseContent', `${regionLocale[0]}`]),
                          'submissionResponseContent',
                          language,
                        );
                      }
                    }}
                    style={styles.FieldCopyText}
                  >
                    {Translation('agent_referral_link_copy')}
                  </Button>
                )}
              </div>
            </Form.Item>
          </div>
        );
      })}
    </div>
  );
};
export default SubmissonSection;
