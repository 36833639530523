import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import PruRoute from 'src/app/common/components/PruRoute';
import { PULSELEADS_BASE_PATH, PULSELEADS_RULE_PATH, PULSELEADS_LEAD_DEDUPLICATION_RULE_PATH } from '../../../constants';
import ParamsProvider from 'src/app/common/components/ParamsProvider';
import DeduplicationRuleListingPage, { deduplicationRuleFilterKeys } from './List/DeduplicationRuleListingPage';
import DeduplicationRuleDetailPage from './Form/DeduplicationRuleDetailPage';

export const deduplicationRulePath = `${PULSELEADS_BASE_PATH}${PULSELEADS_RULE_PATH}${PULSELEADS_LEAD_DEDUPLICATION_RULE_PATH}`;

const LeadDeduplicationRuleRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect exact from={deduplicationRulePath} to={`${deduplicationRulePath}/list`} />
      <PruRoute exact path={`${deduplicationRulePath}/list`} render={(props) => (
        <ParamsProvider {...props} acceptKeys={deduplicationRuleFilterKeys}>
          <DeduplicationRuleListingPage />
        </ParamsProvider>
      )}/>
      <PruRoute exact path={`${deduplicationRulePath}/edit/:id`} component={DeduplicationRuleDetailPage}/>
      <PruRoute exact path={`${deduplicationRulePath}/create`} component={DeduplicationRuleDetailPage}/>
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  )
};

export default LeadDeduplicationRuleRoutes;