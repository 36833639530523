import React, { FC, createElement } from "react";
import { ModuleHierachy } from "./common/module/types";
import { NOT_FOUND_ERROR_PAGE_PATH } from "./common/constants";
import { Redirect, Switch } from "react-router-dom";
import PruRoute from "./common/components/PruRoute";

type PruSwitchProps = {
  isRoot?: boolean;
  currentPath?: string;
  nodeList: ModuleHierachy[];
}

const PruSwitch: FC<PruSwitchProps> = ({ isRoot, currentPath, nodeList }) => {

  const from = currentPath ?? "/";

  const redirector = () => {
    const item = nodeList.find(node => node.enableRead);
    return item ? item.path : NOT_FOUND_ERROR_PAGE_PATH;
  }

  return (
    <Switch>
      <Redirect exact from={from} to={redirector()} />
      {nodeList.map(node => {
        const isLeave: boolean = !node.child && !!node.namespace && !!node.component;
        return (
          <PruRoute
            key={`switch-route-${node.title}`}
            disabled={!node.enableRead}
            path={node.path}
            render={(props) => (
              isLeave && node.component ? (
                createElement(node.component, {
                  ...props,
                  enableRead: node.enableRead,
                  enableCreate: !!node.enableCreate,
                  enableUpdate: !!node.enableUpdate,
                })
              ) : (
                <PruSwitch
                  currentPath={node.path}
                  nodeList={node.child || []}
                />
              )
            )}
          />
        )
      })}
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  )
}

export default PruSwitch;