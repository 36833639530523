import { makeStyles } from 'tss-react/mui';

const FIELD_CONTAINER_SPACING = 15;
const DIALOG_WIDTH = 760;
const DIALOG_FIELD_CONTAINER_SPACING = 25;

export const useEventAssistantStyles = makeStyles()(() => ({
  fieldContainer: {
    marginBottom: FIELD_CONTAINER_SPACING,
  },
  buttonContainer: {
    display: 'flex',
    columnGap: 20,
    marginBottom: FIELD_CONTAINER_SPACING,
  },
  dialogPaper: {
    width: DIALOG_WIDTH,
    maxWidth: DIALOG_WIDTH,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '30px 70px',
  },
  dialogFieldContainer: {
    width: '100%',
    marginBottom: DIALOG_FIELD_CONTAINER_SPACING,
  },
  sendLinkDialogRemark: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: DIALOG_FIELD_CONTAINER_SPACING,
  },
  inputLabelField: {
    marginTop: 14,
  },
  footerContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 20,
  },
}));
