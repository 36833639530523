export interface CompleteMessage {
  title: MultiLanguage;
  content: MultiLanguage;
}

export interface CommentaryMessage {
  category: MultiLanguage;
  medical: MultiLanguage;
  criticalIllness: MultiLanguage;
  savings: MultiLanguage;
  others: MultiLanguage;
}

export interface Tnc {
  enUs: string;
  local: string;
}

export interface MultiLanguage {
  enUs: string;
  local: string;
}
export type InsuranceSummaryState = {
  // tnc: Tnc[];
  disclaimer: MultiLanguage;
  completeMessage: CompleteMessage;
  bannerId: string;

  commentaryMessage: CommentaryMessage;
  bannerData?: string;
};

export type InsuranceSummaryDetail = {
  _id: string;
  createdAt: Date;
  updatedAt: Date;
  completeMessage: CompleteMessage;
  disclaimer: MultiLanguage;
  commentaryMessage: CommentaryMessage;
  bannerData: string;
  bannerId: string;
};
export const InsuranceSummaryInitalState: InsuranceSummaryState = {
  disclaimer: { enUs: '', local: '' },
  commentaryMessage: {
    category: { enUs: '', local: '' },
    medical: { enUs: '', local: '' },
    criticalIllness: { enUs: '', local: '' },
    savings: { enUs: '', local: '' },
    others: { enUs: '', local: '' },
  },
  bannerId: '',

  completeMessage: { title: { enUs: '', local: '' }, content: { enUs: '', local: '' } },
  bannerData: undefined,
};

export type InsuranceSummaryBody = {
  disclaimer: MultiLanguage;
  completeMessage: CompleteMessage;
  commentaryMessage: CommentaryMessage;
  // insuranceSummaryImage: string;
  bannerId: string;
};

export type InsuranceSummaryErrorState = {
  mandatory: {
    completeMessage: boolean;
  };
  immediate: {
    endDateBeforeStartDate: boolean;
    flyerMessage: boolean;
  };
};

export type SummaryStatistic = {
  totalSaved: number;
  totalSubmitted: number;
  totalRecords: number;
  totalOpened: number;
};
export type prospectStatus = {
  status: string;
  statusModifiedDate: string;
  resultId: string;
  prospectId: string;
  prospectName: string;
  agentCode: string;
  agentName: string;
  type: string;
  sourceType: string;
  updatedAt: string;
};

export type InsuranceSummaryInsightResult = {
  results: prospectStatus[];

  statistic: SummaryStatistic;
};

export type summaryType = {
  totalSaved: string;
  totalSubmitted: string;
  // totalRecords: string;
  totalOpened: string;
};

export type InsightListItem = {
  id: string;
  agentName: string;
  agentCode: string;
  prospectName: string;
  status: string;
  statusName: string;
  updatedAt: Date;
};

export type PaginateList<T extends Record<string, unknown>> = {
  total: number;
  totalPages: number;
  limit: number;
  data: T[];
  docs: T[];
  hasNextPage: boolean;
  hasPrevPage: boolean;
  totalDocs: number;
};
