import React, { FC } from 'react';
import { useIntl } from "react-intl";
import { FormControlLabel, TextField } from '@mui/material';
import { CheckCircle, RadioButtonUnchecked } from '@mui/icons-material';
import { MANDATORY_FIELD_ERROR_TEXT } from "src/app/common/constants";
import AcmCheckbox from 'src/app/modules/AgencyCampaign/common/AcmCheckbox';
import { useStyles } from 'src/app/modules/AgencyCampaign/pages/CampaignType/Form/CampaignTypeDetailPage';
import { useWorkflowStyles } from 'src/app/modules/AgencyCampaign/pages/CampaignType/Form/pages/PageThree/CampaignTypeFormPageThree';
import { SelectSettingItem, ApprovalSettingItem, ApprovalRoleOptionList } from 'src/app/modules/AgencyCampaign/types/campaign-type-types';
import SelectSettingForm from '../common/SelectSettingForm';

type ApprovalLevelFormProps = {
  approvalSetting: ApprovalSettingItem[];
  approvalIndex: number;
  approvalRoleOptionList: ApprovalRoleOptionList;
  setApprovalSetting: (approvalSetting: ApprovalSettingItem[]) => void;
  setErrorState: (index: number, hasError: boolean) => void;
}

const ApprovalLevelForm: FC<ApprovalLevelFormProps> = ({ approvalSetting, approvalIndex, approvalRoleOptionList, setApprovalSetting, setErrorState }) => {
  const { classes } = useStyles();
  const { classes: workflowClasses } = useWorkflowStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const approvalItem = approvalSetting[approvalIndex];

  const handleChangeLabelName = (index: number, newName: string) => {
    setErrorState(index, newName === '');
    const newApprovalSetting = [...approvalSetting];
    newApprovalSetting[index].labelName = newName;
    setApprovalSetting(newApprovalSetting);
  }

  const setApprovalSelectSetting = (index: number, selectSettingFormState: SelectSettingItem) => {
    const newApprovalSetting = [...approvalSetting];
    newApprovalSetting[index] = {
      ...newApprovalSetting[index],
      ...selectSettingFormState,
    };
    setApprovalSetting(newApprovalSetting);
  }

  return (
    <div className={workflowClasses.rowContainer}>
      <div className="col-3" />
      <div className="col-9">
        <div className={workflowClasses.approvalLevelContainer}>
          <div className={workflowClasses.bottomSpacing}>
            <span>{Translation(`agencyCampaign.campaignType.workflow.approvalLevelOption.${approvalIndex + 1}`)}</span>
          </div>
          <div className={workflowClasses.approverSettingContainer}>
            <div>
              <span>1. {Translation("agencyCampaign.campaignType.workflow.approvalLabel")}<span className={classes.mandatory}>*</span></span>
            </div>
            <div className={workflowClasses.largerBottomSpacing}>
              <TextField
                style={{ width: 250 }}
                error={approvalItem.labelName === ''}
                helperText={approvalItem.labelName === '' && MANDATORY_FIELD_ERROR_TEXT}
                margin="dense"
                value={approvalItem.labelName}
                onChange={e => { handleChangeLabelName(approvalIndex, e.target.value) }}
              />
            </div>

            <div className={workflowClasses.bottomSpacing}>
              <span>2. {Translation("agencyCampaign.campaignType.workflow.approverSetting")}<span className={classes.mandatory}>*</span></span>
            </div>
            <div className={workflowClasses.approverSettingContainer}>
              <div className={classes.sectionMargin}>
                <div>
                  <span>2-1 {Translation("agencyCampaign.campaignType.workflow.selectApprover")}<span className={classes.mandatory}>*</span></span>
                </div>
                <SelectSettingForm
                  settingFor="approver"
                  settingFormState={approvalItem}
                  approvalRoleOptionList={approvalRoleOptionList}
                  setSettingFormState={(settingFormState) => setApprovalSelectSetting(approvalIndex, settingFormState)}
                />
              </div>

              <div>
                <div>
                  <span>2-2 {Translation("agencyCampaign.campaignType.workflow.goThroughApprovalAgain")}<span className={classes.mandatory}>*</span></span>
                </div>
                <div>
                  <FormControlLabel
                    style={{ margin: "2px 12px 2px 0" }}
                    disabled={true}
                    control={
                      <AcmCheckbox
                        className={classes.leftCheckboxPadding}
                        icon={<RadioButtonUnchecked />}
                        checkedIcon={<CheckCircle />}
                        checked={approvalItem.resetApprovalIndicator === true}
                      />
                    }
                    label={Translation("app.checkbox.yes")}
                    labelPlacement="end"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ApprovalLevelForm;