import {
  FollowUpStatusEnum,
  LeadStatusEnum,
  LeadAgentStatusEnum,
} from 'src/app/modules/PulseLeads/types/campaign-types';
import moment from 'moment';

export const convertStatus = (
  agentStatus?: string,
  lastFollowUpStatus?: string,
  isActive?: boolean,
  expiredAt?: Date,
  contactExpiredAt?: Date,
  followUp?: Array<{ expiredAt: Date }>,
) => {
  const today = new Date();
  const expiredTimeOfLastStatus = followUp && followUp?.length !== 0 ? followUp[followUp.length - 1].expiredAt : '';
  if (agentStatus == LeadAgentStatusEnum.pending) {
    if (!isActive && expiredAt && moment(expiredAt).toDate() < today) {
      return LeadStatusEnum.ACCEPT_EXPIRED;
    }
    return LeadStatusEnum.WAITING_FOR_ACCEPT;
  } else if (agentStatus == LeadAgentStatusEnum.accepted) {
    if (lastFollowUpStatus) {
      switch (lastFollowUpStatus) {
        case FollowUpStatusEnum.CONTACT_FAILURE:
          return LeadStatusEnum.DEAD;

        case 'quote':
          return moment(expiredTimeOfLastStatus).toDate() < today
            ? LeadStatusEnum.APPLICATION_MISSED
            : lastFollowUpStatus;
        case 'met':
          return moment(expiredTimeOfLastStatus).toDate() < today ? LeadStatusEnum.PROPOSAL_MISSED : lastFollowUpStatus;
        case 'contactSuccess':
          return moment(expiredTimeOfLastStatus).toDate() < today ? LeadStatusEnum.MEET_MISSED : lastFollowUpStatus;

        default:
          return lastFollowUpStatus;
      }
    } else if (!isActive && contactExpiredAt && moment(contactExpiredAt).toDate() < today) {
      return LeadStatusEnum.CONTACT_EXPIRED;
    } else return LeadStatusEnum.PENDING_FOR_CONTACT;
  } else if (agentStatus == LeadAgentStatusEnum.pendingServicing) {
    return LeadStatusEnum.PENDING_FOR_SERVICE;
  }
  return LeadStatusEnum.REJECTED;
};
