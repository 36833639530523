import { downloadFile } from 'src/app/common/utils';
import { Dispatch } from 'react';
import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';

export const getMeterialList = async (params: any, dispatch?: Dispatch<any>) => {
  return apiClient.get(`${ADMIN_URL}/history/courses`, { params }).catch((err) => {
    throw apiErrorHandler(err, dispatch);
  });
};

export const getCustomerSurveyList = async (params: any, locale: string, dispatch?: Dispatch<any>) => {
  return apiClient
    .get(`${ADMIN_URL}/customer-surveys`, { params })
    .then((response) => {
      return {
        libraryList: response.data.items.map((item: any) => {
          return {
            ...item,
            _id: item.id,
            effectiveDate: item.startDate,
            expiredDate: item.endDate,
            module: 'CustomerSurvey',
          };
        }),
        total: response.data.totalRecords,
      };
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const getEngagementTopicDetailById = (id: string, locale: string, dispatch?: Dispatch<any>) => {
  return apiClient.get(`${ADMIN_URL}/engagement-topics/${id}?locale=${locale || 'en'}`).catch((err) => {
    throw apiErrorHandler(err, dispatch);
  });
};

export const getTemplate = async (dispatch?: Dispatch<any>, Translation?: any) => {
  return downloadFile('template.csv', `${ADMIN_URL}/engagement-topics/download/topic`, {}, dispatch, Translation);
};

export const addEngagmentTopic = (dto: any, dispatch?: Dispatch<any>) => {
  return apiClient.post(`${ADMIN_URL}/engagement-topics`, dto).catch((err) => {
    throw apiErrorHandler(err, dispatch);
  });
};

export const updateEngagmentTopic = async (id: string, dto: any, dispatch?: Dispatch<any>) => {
  delete dto.createdAt;
  delete dto.publishedAt;

  return apiClient.put(`${ADMIN_URL}/engagement-topics/${id}`, dto).catch((err) => {
    throw apiErrorHandler(err, dispatch);
  });
};

export const checkTemplate = async (dto: any, dispatch?: Dispatch<any>) => {
  return apiClient.post(`${ADMIN_URL}/engagement-topics/check-template`, dto).catch((err) => {
    throw apiErrorHandler(err, dispatch);
  });
};

export const getTemplateById = async (id: string, dispatch?: Dispatch<any>) => {
  return apiClient.get(`${ADMIN_URL}/engagement-topics/template/${id}`).catch((err) => {
    throw apiErrorHandler(err, dispatch);
  });
};
export const getEngagementTasks = async (id: string, dispatch?: Dispatch<any>) => {
  try {
    const url = `${ADMIN_URL}/engagement-topics/prospect/engagement-task?id=${id}&limit=100`;
    const response = apiClient.get(url);
    return response;
  } catch (err: any) {
    throw apiErrorHandler(err, dispatch);
  }
};
