import { makeStyles } from 'tss-react/mui';

const DIALOG_WIDTH = 830;

export const useStyles = makeStyles()(() => ({
  dialogPaper: {
    width: DIALOG_WIDTH,
    maxWidth: DIALOG_WIDTH,
  },
  remarkMargin: {
    margin: '10px 0',
  },
}));
