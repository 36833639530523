import { FC, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { LayoutSplashScreen } from 'src/app/layout';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { dependentPermissionKeyList } from '../../constants';
import {
  SecretaryItem,
  SecretaryTypeItem,
  SecretaryPermissionItem,
  DependentPermissionItem,
  DependentPermissionKeyItem,
} from '../../types/secretary-types';
import { fetchSecretaryItem, fetchSecretaryTypes } from '../../network/secretaryCrud';
import SecretaryForm from './components/SecretaryForm';

const SecretaryDetailPage: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [secretaryItem, setSecretaryItem] = useState<SecretaryItem>();
  const [secretaryTypes, setSecretaryTypes] = useState<SecretaryTypeItem[]>([]);
  const [dependentPermissions, setDependentPermissions] = useState<DependentPermissionItem[]>([]);

  const initDependentPermission = (
    keyItem: DependentPermissionKeyItem,
    permissionList: SecretaryPermissionItem[],
    parent?: SecretaryPermissionItem,
  ) => {
    const permissionItem = permissionList.find((permission) => permission.name === keyItem.permission);
    if (permissionItem) {
      const dependencyPermissionItems = permissionList.filter((permission) =>
        keyItem.dependencies.includes(permission.name),
      );
      return {
        parent,
        permission: permissionItem,
        dependencies: dependencyPermissionItems,
      };
    }
  };

  const reloadData = async () => {
    if (id) {
      setIsLoading(true);
      try {
        const res = await fetchSecretaryItem(id, dispatch);
        setSecretaryItem(res);
        const newDependentPermissions: DependentPermissionItem[] = [];
        dependentPermissionKeyList.forEach((keyItem) => {
          const parentPermissionItem = keyItem.parent
            ? res.fullPermissionList?.find((permission) => permission.name === keyItem.parent)
            : undefined;
          const permissionList = keyItem.parent ? parentPermissionItem?.child : res.fullPermissionList;
          if (permissionList) {
            const newDependentPermission = initDependentPermission(keyItem, permissionList, parentPermissionItem);
            if (newDependentPermission) {
              newDependentPermissions.push(newDependentPermission);
            }
          }
        });
        setDependentPermissions(newDependentPermissions);
      } catch (err) {
        history.push(NOT_FOUND_ERROR_PAGE_PATH);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const reloadTypes = async () => {
    try {
      const res = await fetchSecretaryTypes(dispatch);
      setSecretaryTypes(res.types);
    } catch (err) {}
  };

  useEffect(() => {
    reloadTypes();
  }, []);

  useEffect(() => {
    reloadData();
  }, [id]);

  return (
    <>
      {isLoading || !secretaryTypes || !secretaryItem ? (
        <LayoutSplashScreen />
      ) : (
        <SecretaryForm
          id={id}
          secretaryItem={secretaryItem}
          secretaryTypes={secretaryTypes}
          dependentPermissions={dependentPermissions}
          reloadData={reloadData}
        />
      )}
    </>
  );
};

export default SecretaryDetailPage;
