import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { useIntl } from 'react-intl';
import { Button } from '@mui/material';
import { getType, renderDisplayStatusField } from '../../../../utils/common-utils';

export interface TableInputProps {
  value?: any[];
  onChange?: (value: any[]) => void;
  disabled?: boolean;
  getRemovedMaterial: (items: any) => void;
  initialValue: any;
}

const useStyles = makeStyles()((theme) => ({
  actionCell: {
    display: 'flex',
  },
  moveBtn: {
    margin: '5px',
    width: '22px',
    height: '22px',
    borderRadius: '50%',
    borderWidth: '1px',
    borderColor: 'black',
    borderStyle: 'solid',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#e0e0e0',
    },
  },
  actionBtns: {
    width: '80px',
    textAlign: 'left',
    marginLeft: '15px',
  },
}));

const TableInput: React.FC<TableInputProps> = (props) => {
  const { classes } = useStyles();
  const { value, onChange, disabled, getRemovedMaterial, initialValue } = props;
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const triggerChange = (changedValue: any[]) => {
    if (onChange) {
      onChange([...changedValue]);
    }
  };

  const onDelete = (index: number) => () => {
    const tableData = [...(value || [])];

    const removeItem = tableData[index];

    const removeId = removeItem.id;

    const removeItemInFact =
      initialValue &&
      initialValue.filter((item: any) => {
        return item.id === removeId;
      });

    removeItemInFact && getRemovedMaterial(removeItemInFact[0]);
    tableData.splice(index, 1);
    triggerChange(tableData);
  };

  const swapArray = (index1: number, index2: number) => {
    const tableData = [...(value || [])];
    if (tableData) {
      tableData[index1] = tableData.splice(index2, 1, tableData[index1])[0];
      triggerChange(tableData);
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="input table">
        <TableHead>
          <TableRow>
            <TableCell>{Translation('component.formLabel.name')}</TableCell>
            <TableCell align="right">{Translation('component.formLabel.type')}</TableCell>
            <TableCell align="right">{Translation('component.formLabel.status')}</TableCell>
            {/* <TableCell align="right">{Translation('component.formLabel.valid-status')}</TableCell> */}
            <TableCell align="right">{Translation('component.formLabel.action')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(value || []).map((row, index) => {
            return (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="right">{row.type ? getType(row.type) : ''}</TableCell>
                <TableCell align="right">{Translation(renderDisplayStatusField(row))}</TableCell>
                {/* <TableCell align="right">{showValidStatus(row)}</TableCell> */}
                <TableCell align="right" className={classes.actionCell}>
                  {disabled ? (
                    '--'
                  ) : (
                    <>
                      <span className={classes.actionBtns}>
                        {index === 0 ? null : (
                          <ArrowUpward
                            className={classes.moveBtn}
                            onClick={() => {
                              swapArray(index, index - 1);
                            }}
                          />
                        )}
                        {index === (value && value.length - 1) ? null : (
                          <ArrowDownward
                            className={classes.moveBtn}
                            onClick={() => {
                              swapArray(index, index + 1);
                            }}
                          />
                        )}
                      </span>
                      <Button key="add" onClick={onDelete(index)}>
                        {Translation('app.button.delete')}
                      </Button>
                    </>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableInput;
