export enum fieldsType {
    EngagementType = 'Engagement Type',
    CommucationMethod = 'Commucation Method',
    Effectivity = 'Effectivity'

}
export type UpdateEngagementFieldsBody = {
    field: string;
    i18nData: I18nData[];
    options: FieldOption[];
    format: string;
    mandatory: boolean;
    updatedBy: string;
    updatedAt?: string;
}
export type FieldOption = {
    _id?: string;
    i18nData: I18nData[];
    deleted: boolean;
}
export type I18nData = {
    locale: string;
    name: string;
}