import React, { useEffect, useReducer, useState } from 'react';
import { useIntl } from 'react-intl';
import { makeStyles } from 'tss-react/mui';
import { Button, TextField } from '@mui/material';
import AsyncCsvLink from '../../../../../../../common/components/AsyncCsvLink';
import {
  downloadParticipantUploadCsv,
  fetchDownlineList,
  verifyDownlineList,
  verifyNonDownlineList,
} from '../../../../../network/staffCampaignCrud';
import {
  AgentItem,
  AgentListParam,
  AgentPaginateList,
  CampaignStructureItem,
  ImportVerify,
} from '../../../../../types/staff-campaign-types';
import { SearchOutlined } from '@mui/icons-material';
import PruTable from '../../../../../../../common/components/PruTable/PruTable';
import { toAbsoluteUrl, useDataProvider, getDisplayName } from 'src/app/common/utils';
import { useDispatch } from 'react-redux';
import { useCommonStyles } from '../../../../../../../common/styles/common-styles';
import { ImportFailedList } from './ImportFailedList';
import { useLang } from '../../../../../../../i18n';
import { Translation } from 'src/app/i18n';
import { removeFirstLetterIfU } from 'src/app/modules/AgencyCampaign/utils/common-utils';

const useStyles = makeStyles()(() => ({
  menuItem: {
    display: 'flex',
    flexDirection: 'row',
    height: 40,
    alignItems: 'center',
    paddingLeft: 10,
    paddingRight: 10,
    '&:hover': {
      backgroundColor: '#E7E7E7',
    },
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
    flexDirection: 'row',
  },
  searchInput: {
    height: 46,
    padding: 0,
    fontFamily: 'Poppins, Helvetica, "sans-serif"',
  },
  btnGroupWrap: { flex: 1, padding: 20, display: 'flex', flexDirection: 'row', justifyContent: 'center' },
  btnGroup: { margin: '0 10px' },
  dialogWrap: { paddingLeft: 20, paddingRight: 20, minHeight: 200, minWidth: 500 },
  importFormat: { border: '1px solid #ccc', padding: 10, borderRadius: 8, marginBottom: 20 },
}));

const REACT_APP_AGENT_CODE_LENGTH = window.envConfig['REACT_APP_AGENT_CODE_LENGTH'];
const agentCodeLength = Number.parseInt(REACT_APP_AGENT_CODE_LENGTH) || 5;

interface ImportParticipantPopupProps {
  selectFromFile: boolean;
  campaignObjId: string;
  campaignTypeStructureData: CampaignStructureItem;
  doneImport: (data: AgentItem[]) => void;
  onCancel: () => void;
}

const initialState: any = {
  participantListfromFile: [],
  participantListFormatError: [],
  successImportList: [],
  failImportList: [],
  failEligibilityList: [],
  successEligibilityList: [],
  importedAgentList: [],
};

type participantUpdateState = {
  id: string;
  status: string;
  submissionDate: Date;
  policyNumber?: string;
};

type ManualUpdateFormState = {
  filename?: string;
  participantListfromFile: any[];
  participantListFormatError: string[];
  successImportList: AgentItem[];
  failImportList: AgentItem[];
  failEligibilityList: AgentItem[];
  successEligibilityList: AgentItem[];
  importedAgentList: AgentItem[];
};

const formReducer = (state: ManualUpdateFormState, action: ManualUpdateFormAction): ManualUpdateFormState => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    default:
      return state;
  }
};

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof ManualUpdateFormState;
    value: any;
  };
};

type ManualUpdateFormAction = ModifyFieldAction;

const paramsInitiator = (initialParams?: Record<string, string>): AgentListParam => {
  return {
    page: 1,
    limit: 20,
    search: '',
  };
};

export const checkEligibility = async (
  importList: AgentItem[],
  campaignTypeStructureData: any,
  campaignObjId: any,
  eligibilityRules: any,
  dispatch: any,
  lang: any,
): Promise<{ failList: any[]; successList: any[] }> => {
  const importListAgentList = importList.map((item) => item.agentCode);
  const campaignTypeObjId = campaignTypeStructureData._id;
  const param: ImportVerify = {
    agentCodeArr: importListAgentList,
    campaignID: campaignObjId,
    campaignTypeID: campaignTypeObjId,
    rules: eligibilityRules,
  };
  let { success = [], fail = [] } = await verifyNonDownlineList(param, dispatch);
  const arrFailedDisplay: ImportItem[] | undefined = fail?.map((item) => {
    const arrSource: ImportItem[] = importList.filter((sourceItem) => sourceItem.agentCode === item.agentCode);
    item.businessName = getDisplayName(arrSource[0], lang);
    item.unitCode = arrSource[0]?.unit;
    return item as ImportItem;
  });
  const arrSuccess: ImportItem[] | undefined = success?.map((item) => {
    const arrSource: ImportItem[] = importList.filter((sourceItem) => sourceItem.agentCode === item.agentCode);
    return arrSource[0];
  });
  return { failList: arrFailedDisplay, successList: arrSuccess };
};
export const checkAgentImportFail = async (
  importList: ImportItem[],
  campaignTypeStructureData: any,
  campaignObjId: any,
  eligibilityRules: any,
  dispatch: any,
  lang: any,
) => {
  const arrAgentCode = importList.map((item) => item.agentCode);
  const param: ImportVerify = {
    agentCodeArr: arrAgentCode,
    campaignID: campaignObjId,
    campaignTypeID: campaignTypeStructureData._id,
    rules: eligibilityRules,
  };
  /** only response succeed agent code */
  let { success, fail } = await verifyNonDownlineList(param, dispatch);
  const arrFailedDisplay: ImportItem[] | undefined = fail?.map((item) => {
    const arrSource: ImportItem[] = importList.filter((sourceItem) => sourceItem.agentCode === item.agentCode);
    item.businessName = getDisplayName(arrSource[0], lang);
    item.unitCode = arrSource[0]?.unit;
    return item as ImportItem;
  });
  const res = { failList: arrFailedDisplay || [], successList: success || [] };
  return res;
};
export const renderfailImportList = (
  failList: any,
  removeAndProceed: any,
  onCancel?: any,
  onPreviousFromFailImport?: any,
) => {
  const FailImportList = ({ failList, removeAndProceed, onCancel, onPreviousFromFailImport, onClose }: any) => {
    const { classes } = useStyles();
    const [processing, setProcessing] = useState(false);
    return (
      <>
        <div style={{ marginTop: 10, color: '#E8192C', fontWeight: 500 }}>
          {Translation('agencyCampaign.create.belowFailEligibilityCheck')}
        </div>

        <ImportFailedList dataSource={failList} />
        <div className={classes.btnGroupWrap}>
          {onCancel && (
            <Button
              className={classes.btnGroup}
              variant="outlined"
              color="inherit"
              onClick={async () => {
                await onCancel?.();
                onClose?.('cancel');
              }}
            >
              {Translation('app.button.cancel')}
            </Button>
          )}
          {onPreviousFromFailImport && (
            <Button
              className={classes.btnGroup}
              variant="outlined"
              color="inherit"
              onClick={async () => {
                await onPreviousFromFailImport?.();
                onClose?.('previous');
              }}
            >
              {Translation('app.button.previous')}
            </Button>
          )}
          <Button
            className={classes.btnGroup}
            variant="contained"
            disabled={processing}
            color="secondary"
            onClick={async () => {
              setProcessing(true);
              await removeAndProceed?.().then(
                () => {
                  setProcessing(false);
                  onClose?.();
                },
                () => {
                  setProcessing(false);
                  onClose?.({ type: 'reject' }, 'apiError' as any);
                },
              );
            }}
          >
            {Translation('app.button.removeAndProceed')}
          </Button>
        </div>
      </>
    );
  };
  return <FailImportList {...{ failList, removeAndProceed, onCancel, onPreviousFromFailImport }} />;
};

const ImportParticipantPopup: React.FC<ImportParticipantPopupProps> = ({
  selectFromFile,
  campaignObjId,
  campaignTypeStructureData,
  doneImport,
  onCancel,
}) => {
  const dispatch = useDispatch();
  const lang = useLang();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const TranslationWithVariable = (key: string, count: number | string) =>
    intl.formatMessage({ id: key }, { num: count });
  const { classes } = useStyles();
  const { classes: commonStyle } = useCommonStyles();
  const [formState, formDispatch] = useReducer(formReducer, initialState);
  const [filterState, setFilterState] = useState<AgentListParam>(paramsInitiator());
  const [downlineListData, setDownlineListData] = useState<AgentPaginateList>();
  const [selectedRowsState, setSelectedRows] = useState<any[]>([]);
  const [sortKey, setSortKey] = useState<{ key: string; value?: string }[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const participantSectionTypeStructureData = campaignTypeStructureData.sections.filter(
    (item: any) => item.key == 'participant',
  )[0];
  const participantNameRules =
    participantSectionTypeStructureData.sectionFields.filter((item: any) => item.key == 'participantName').length > 0
      ? participantSectionTypeStructureData.sectionFields.filter((item: any) => item.key == 'participantName')[0].rules
      : [];
  const eligibilityRules: string[] =
    participantNameRules.filter((item: any) => item.key == 'eligibility').length > 0
      ? participantNameRules.filter((item: any) => item.key == 'eligibility')[0].value
      : [];
  const needEligibilityCheck = (eligibilityRules as string[]).length > 0;
  const { isLoading, refreshData } = useDataProvider<AgentPaginateList>(
    async () => {
      try {
        return await fetchDownlineList(filterState, sortKey, dispatch);
      } catch (err) {}
    },
    setDownlineListData,
    false,
  );

  const updateSortingKey = (sortingKey: { key: string; value?: string }) => {
    const sortingKeyArray = sortKey.filter((currentValue, index, arr) => {
      return currentValue.key !== sortingKey.key;
    });
    sortingKeyArray.unshift(sortingKey);
    setSortKey(sortingKeyArray);
  };

  useEffect(() => {
    refreshData();
  }, [filterState, sortKey]);

  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      let reader = new FileReader();
      reader.onload = () => {
        let csvData = String(reader.result);
        let row = csvData.split('\n');
        let participantList: ImportItem[] = [];
        let arrParticipantListFormatError: any[] = [];
        row.shift();
        row.forEach((item, index) => {
          if (!item) {
            return;
          }
          // item format: 10006,Name F,U102(AgentCode|BusinessName|UnitCode)
          let [agentCode, businessName, unitCode] = item.split(',');
          agentCode = agentCode?.trim()?.padStart(agentCodeLength, '0');
          businessName = businessName?.trim();
          unitCode = unitCode?.trim();
          participantList.push({ agentCode, businessName, unitCode });
          let err = TranslationWithVariable('agencyCampaign.import.row', index + 1);
          if (!agentCode) {
            err += Translation('agencyCampaign.import.agentcode.required');
          } else if (agentCode.length !== agentCodeLength) {
            err += TranslationWithVariable('agencyCampaign.import.agentode.invalid', agentCodeLength);
          }
          if (!businessName) {
            err += Translation('agencyCampaign.import.businessName.required');
          }
          if (!unitCode) {
            err += Translation('agencyCampaign.import.unitCode.required');
          }
          if (err.length > 10) {
            arrParticipantListFormatError.push(err);
          }
          // let rowItems = item.split(',');
          // if (rowItems[0]) {
          //   participantList.push(preFillZero(rowItems[0].replace(/\D/g,'')));
          // }
        });
        formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'participantListfromFile', value: participantList } });
        formDispatch({
          type: 'MODIFY_FIELD',
          payload: { field: 'participantListFormatError', value: arrParticipantListFormatError },
        });
      };
      if (e.target.files) {
        reader.readAsBinaryString(e.target.files[0]);
        formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'filename', value: e.target.files[0].name } });
      }
    }
  };

  const removeFile = () => {
    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'filename', value: null } });
    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'participantListfromFile', value: [] } });
    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'importedAgentList', value: [] } });
  };

  const onImportFromFile = async () => {
    // downline and eligiblity check
    setLoading(true);
    const { failList, successList } = await checkAgentImportFail(
      formState.participantListfromFile,
      campaignTypeStructureData,
      campaignObjId,
      eligibilityRules,
      dispatch,
      lang,
    ).catch(() => {
      setLoading(false);
      return { failList: [], successList: [] };
    });
    setLoading(false);

    if (needEligibilityCheck && failList.length > 0) {
      formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'failImportList', value: failList } });
      if (successList.length > 0) {
        formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'successImportList', value: successList } });
        formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'importedAgentList', value: successList } });
      }
    } else {
      doneImport(needEligibilityCheck ? successList : successList.concat(failList as any));
    }
  };

  const onImportFromList = () => {
    if (needEligibilityCheck) {
      proceedEligiblity(selectedRowsState);
    } else {
      doneImport(selectedRowsState);
    }
  };

  const removeAndProceed = () => {
    doneImport(formState.importedAgentList);
  };

  const proceedEligiblity = async (importedList: AgentItem[]) => {
    setLoading(true);
    const { successList, failList } = await checkEligibility(
      importedList,
      campaignTypeStructureData,
      campaignObjId,
      eligibilityRules,
      dispatch,
      lang,
    );
    setLoading(false);
    if (failList?.length > 0) {
      formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'failImportList', value: failList } });
      // formDispatch({ type: "MODIFY_FIELD", payload: { field: "failEligibilityList", value: failList } });
      if (successList?.length > 0) {
        formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'successEligibilityList', value: successList } });
        formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'importedAgentList', value: successList } });
      }
    } else {
      doneImport(importedList);
    }
  };

  const onPreviousFromFailImport = () => {
    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'failImportList', value: [] } });
    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'successImportList', value: [] } });
    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'importedAgentList', value: [] } });
  };

  const onPreviousFromFailEligibility = () => {
    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'failEligibilityList', value: [] } });
    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'successEligibilityList', value: [] } });
  };

  const onRemoveAndProceed = () => {
    doneImport(formState.importedAgentList);
  };

  const _renderFileUpload = () => {
    return (
      <>
        <AsyncCsvLink
          isDisabled={false}
          filename={`AgentUpload.csv`}
          dataParser={(str) => str}
          asyncCall={() => downloadParticipantUploadCsv()}
        >
          <a className={commonStyle.link}>{Translation('agencyCampaign.create.downloadTemplate')}</a>
        </AsyncCsvLink>

        <div>
          {!formState.filename && (
            <>
              <input
                id="upload-participant-list-file-csv"
                hidden
                type="file"
                accept=".csv"
                onClick={(e) => {
                  const element = e.target as HTMLInputElement;
                  element.value = '';
                }}
                onChange={handleFile}
              />
              <div className={classes.rowContainer}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => document.getElementById('upload-participant-list-file-csv')!.click()}
                >
                  {Translation('app.button.upload')}
                </Button>
              </div>
            </>
          )}
          {formState.filename && (
            <>
              <div className={classes.rowContainer}>
                <div>{formState.filename}</div>
                <div onClick={removeFile}>
                  <img
                    src={toAbsoluteUrl('/media/icon/bin.png')}
                    alt={'Select from list'}
                    style={{ width: 24, height: 24, margin: 10 }}
                  />
                </div>
              </div>
              {formState.participantListFormatError.length > 0 ? (
                <>
                  <p style={{ color: '#E8192C' }}>{Translation('agencyCampaign.import.failed')}</p>
                  <div className={classes.importFormat}>
                    {formState.participantListFormatError.map((item, index) => (
                      <p key={index}>{Translation(item)}</p>
                    ))}
                  </div>
                </>
              ) : (
                <div className={classes.rowContainer}>
                  <div
                    style={{ flex: 1, paddingTop: 50, display: 'flex', flexDirection: 'row', justifyContent: 'center' }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      disabled={loading}
                      onClick={onImportFromFile}
                    >
                      {loading ? Translation('common.loading') : Translation('app.button.import')}
                    </Button>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </>
    );
  };

  /*
  const _renderEligibilityFailList = () => {
    return (<>
      <div>{Translation('agencyCampaign.create.eligibilityCheck')}</div>
      <div style={{ marginTop: 10, color: "#E8192C", fontWeight: 500 }}>{Translation('agencyCampaign.create.belowFailEligibilityCheck')}</div>
      <div>
        <table style={{ width: '100%' }}>
          <tr><th>Unit Code</th><th>Agent Code</th><th>Business Name</th><th>Ineligibility Reason</th></tr>
          {
            formState.failEligibilityList && formState.failEligibilityList.map((item, index) => {
              return (<tr><td>{'w38'}</td><td>{'xxx'}</td><td>{'Marvel'}</td><td>{'FC is under Pending Termination'}</td></tr>)
            })
          }


        </table>

      </div>


      <div style={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'center', padding: "15px 0px" }}>
        <Button
          variant="contained"
          color="secondary"
          style={{
            backgroundColor: "white", color: "#333333", width: 168, height: 36, borderRadius: 12, marginRight: 10, border: "2px solid #F0F0F0",
            fontFamily: 'Poppins, Helvetica, "sans-serif"'
          }}
          onClick={onCancel}
        >
          {Translation("app.button.cancel")}
        </Button>

        <Button
          variant="contained"
          color="secondary"
          style={{
            backgroundColor: "white", color: "#333333", width: 168, height: 36, borderRadius: 12, marginLeft: 10, marginRight: 10, border: "2px solid #F0F0F0",
            fontFamily: 'Poppins, Helvetica, "sans-serif"'
          }}
          onClick={onPreviousFromFailEligibility}
        >
          {Translation("app.button.previous")}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          style={{
            backgroundColor: "#E8192C", color: "white", width: 168, height: 36, marginLeft: 10, borderRadius: 12,
            fontFamily: 'Poppins, Helvetica, "sans-serif"'
          }}
          onClick={onRemoveAndProceed}
        >
          {Translation("app.button.removeAndProceed")}
        </Button>
      </div>
    </>)
  }*/

  const _renderSelectList = () => {
    return (
      <>
        <div style={{ display: 'flex', flex: 1, alignItems: 'center', padding: 8 }}>
          <TextField
            style={{
              background: '#F0F0F0',
              borderRadius: 50,
              minWidth: 200,
              paddingLeft: 10,
              height: 46,
              marginBottom: 0,
              marginTop: 0,
            }}
            margin="dense"
            // type='search'
            fullWidth
            placeholder="Search here"
            value={filterState.search}
            InputProps={{
              startAdornment: (
                <>
                  <SearchOutlined></SearchOutlined>
                </>
              ),
              classes: { input: classes.searchInput },
              disableUnderline: true,
            }}
            onChange={(e) => {
              setFilterState({
                ...filterState,
                search: e.target.value,
              });
            }}
          ></TextField>
          {filterState.search.length > 0 ? (
            <>
              <Button
                variant="contained"
                color="secondary"
                style={{
                  backgroundColor: 'white',
                  color: 'black',
                  width: 72,
                  height: 30,
                  borderRadius: 100,
                  marginLeft: 15,
                  border: '2px solid #F0F0F0',
                  fontFamily: 'Poppins, Helvetica, "sans-serif"',
                }}
                onClick={() => {
                  setFilterState({
                    ...filterState,
                    search: '',
                  });
                }}
              >
                {Translation('app.button.cancel')}
              </Button>
            </>
          ) : null}
        </div>

        {downlineListData && downlineListData.docs && (
          <PruTable
            disableToolbar={true}
            title={''}
            disableBulkSelect={false}
            operationDef={[]}
            currentSelectedRow={(data) => setSelectedRows(data)}
            columnDef={[
              {
                keyIndex: `unit`,
                align: 'center',
                displayName: Translation(`agencyCampaign.common.unitCode`),
                renderData: (row) => (row.unit ? (removeFirstLetterIfU(row.unit) as string) : '-'),
                sortable: true,
                onSort: (sort) => {
                  updateSortingKey({ key: 'unit', value: sort['unit'] });
                },
              },
              {
                keyIndex: 'displayName',
                align: 'center',
                displayName: Translation('agencyCampaign.common.businessName'),
                renderData: (row) => row?.name?.enUs?.displayName ?? '-',
                sortable: true,
                onSort: (sort) => {
                  updateSortingKey({ key: 'displayName', value: sort['displayName'] });
                },
              },
              {
                isId: true,
                keyIndex: 'agentCode',
                align: 'center',
                displayName: Translation('agencyCampaign.common.agentCode'),
                renderData: (row) => (row.agentCode ? (row.agentCode as string) : '-'),
                sortable: true,
                onSort: (sort) => {
                  updateSortingKey({ key: 'agentCode', value: sort['agentCode'] });
                },
              },
            ]}
            isLoading={false}
            onRefresh={refreshData}
            dataSource={downlineListData.docs}
            updateSelectedRow={selectedRowsState}
            totalPages={downlineListData.totalPages}
            totalRecords={downlineListData.totalDocs}
            onChangePage={(page, rowsPerPage) => {
              setFilterState({
                ...filterState,
                page,
                limit: rowsPerPage,
              });
            }}
            defaultRowsPerPage={20}
          />
        )}

        <div className={classes.rowContainer}>
          <div style={{ flex: 1, padding: 5, display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Button
              variant="contained"
              color="secondary"
              disabled={loading}
              style={{ width: 168, height: 36, borderRadius: 12, marginBottom: 12 }}
              onClick={onImportFromList}
            >
              {Translation('app.button.import')} {selectedRowsState?.length > 0 ? `(${selectedRowsState.length})` : ''}
            </Button>
          </div>
        </div>
      </>
    );
  };

  const _renderImportFromFileFlow = () => {
    return formState.filename && formState.participantListfromFile && formState.participantListfromFile.length > 0
      ? // formState.failEligibilityList && formState.failEligibilityList.length > 0 ?
        //   _renderEligibilityFailList() :
        formState.failImportList && formState.failImportList.length > 0
        ? renderfailImportList(formState.failImportList, removeAndProceed, onCancel, onPreviousFromFailImport)
        : _renderFileUpload()
      : _renderFileUpload();
    // : _renderFileUpload();
  };

  /** add from list */
  const _renderImportFromListFlow = () => {
    return formState.failImportList && formState.failImportList.length > 0
      ? renderfailImportList(formState.failImportList, removeAndProceed, onCancel, onPreviousFromFailImport)
      : _renderSelectList();
  };

  return (
    <div className={classes.dialogWrap}>
      {selectFromFile ? _renderImportFromFileFlow() : _renderImportFromListFlow()}
    </div>
  );
};
interface ImportItem {
  agentCode: string;
  businessName?: string;
  unitCode?: string;
  unit?: string;
  reason?: string;
}
interface verifiedDownlinedto extends ImportItem {
  agentCode: string;
  notEligibleReason?: string;
  isEligible?: boolean;
}
export default ImportParticipantPopup;
