import React, { FC, useMemo, Children, PropsWithChildren, isValidElement, cloneElement } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { getDayStart, getDayEnd } from '../utils';

type ParamsProviderProps = {
  acceptKeys: string[];
};

export type ParamsProps = {
  initialParams?: Record<string, string>;
  onChangeQueryParams?: <T extends Record<string, unknown>>(obj: T, exclude?: (keyof T)[]) => void;
};

const ParamsProvider: FC<PropsWithChildren<RouteComponentProps & ParamsProviderProps>> = ({ history, acceptKeys, children }) => {
  const onChangeQueryParams = (obj: Record<string, unknown>) => {
    let queryPath = '';
    acceptKeys.forEach((key) => {
      const param = obj[key];
      if (Array.isArray(param)) {
        param.forEach((element) => {
          queryPath += `${key}=${encodeURIComponent(element)}&`;
        });
      } else if (param && param instanceof Date) {
        if (key.toLowerCase().includes('from')) {
          queryPath += `${key}=${encodeURIComponent(getDayStart(param).toISOString())}&`;
        }
        if (key.toLowerCase().includes('to')) {
          queryPath += `${key}=${encodeURIComponent(getDayEnd(param).toISOString())}&`;
        }
      } else {
        queryPath +=
          param !== undefined && param !== null && param !== '' ? `${key}=${encodeURIComponent(param as any)}&` : '';
      }
    });
    queryPath = queryPath.slice(0, -1);
    history.push({
      search: queryPath,
    });
  };

  const childrenWithProps = useMemo(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const results: Record<string, string> = {};
    acceptKeys.forEach((key) => {
      const value = params.getAll(key);
      if (value.length > 0) {
        results[key] = value.join(',');
      }
    });
    return Children.map(children, (child) => {
      if (isValidElement<ParamsProps>(child)) {
        return cloneElement(child, { initialParams: results, onChangeQueryParams });
      }
    });
    // eslint-disable-next-line
  }, []);

  return <>{childrenWithProps}</>;
};

export default ParamsProvider;
