import { Dispatch } from 'react';
import { downloadFile } from 'src/app/common/utils';
import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import { forEach } from 'lodash';
import {
  ApplicationTrainingCourseListParam,
  CourseListDetailParam,
  CourseListQueryParam,
  OnboardingLessonFormState,
  OnboardingQuizFormState,
  OnboardingQuizReportParams,
  OnboardingTrainingOptions,
  PaginateList,
} from '../types/courses-types';

// const ADMIN_URL = 'http://localhost:3000';
const onboardingTrainingBaseEndPoint = 'onboarding-training';
const onboardingCourseEndPoint = `${onboardingTrainingBaseEndPoint}/course`;
export const moveOnboardingCourse = async (id: string, type: string, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .patch<void>(
      `${ADMIN_URL}/${onboardingCourseEndPoint}/${id}/order`,
      {
        direction: type,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchOnboardingTrainingCourseList = async (
  params: CourseListQueryParam,
  sortKeys: { key: string; value?: string }[],
  dispatch?: Dispatch<any>,
): Promise<PaginateList<ApplicationTrainingCourseListParam>> => {
  let queryPath = `${ADMIN_URL}/${onboardingCourseEndPoint}/list?`;
  forEach(params, (param, key) => {
    if (key === 'page') {
      queryPath += `${key}=${encodeURIComponent(param as any)}&`;
    } else {
      queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
    }
  });
  //Query for sorting
  let sortingCount = 0;
  queryPath += `sortBy=`;
  forEach(sortKeys, (sortParam, key) => {
    if (sortParam.value) {
      queryPath += `${sortingCount === 0 ? `` : `,`}${encodeURIComponent(sortParam.key)}:${encodeURIComponent(
        sortParam.value,
      )}`;
      sortingCount++;
    }
  });
  if (sortingCount === 0) {
    queryPath += `updatedAt:desc`;
  }

  return apiClient
    .get<PaginateList<ApplicationTrainingCourseListParam>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchOnboardingCourseDetail = async (courseId: string, dispatch?: Dispatch<any>): Promise<any> => {
  return apiClient
    .get<CourseListDetailParam>(`${ADMIN_URL}/${onboardingCourseEndPoint}/${courseId}/detail`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchOnboardingTrainingOptions = async (
  dispatch?: Dispatch<any>,
): Promise<OnboardingTrainingOptions[]> => {
  return apiClient
    .get<OnboardingTrainingOptions[]>(`${ADMIN_URL}/${onboardingTrainingBaseEndPoint}/options`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createOnboardingTrainingCourse = async (body: any, dispatch?: Dispatch<any>): Promise<any[]> => {
  return apiClient
    .post<any[]>(`${ADMIN_URL}/${onboardingCourseEndPoint}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const deleteOnboardingTrainingCourse = async (courseId: string, dispatch?: Dispatch<any>): Promise<any[]> => {
  return apiClient
    .delete<any[]>(`${ADMIN_URL}/${onboardingCourseEndPoint}/${courseId}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const deleteOnboardingTrainingLesson = async (
  courseId: string,
  lessonId: string,
  dispatch?: Dispatch<any>,
): Promise<any[]> => {
  return apiClient
    .delete<any[]>(`${ADMIN_URL}/${onboardingTrainingBaseEndPoint}/${courseId}/lesson/${lessonId}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const moveOnboardingLesson = async (
  courseId: string,
  lessonId: string,
  type: string,
  dispatch?: Dispatch<any>,
): Promise<any> => {
  return apiClient
    .patch<{ type: string }>(`${ADMIN_URL}/${onboardingTrainingBaseEndPoint}/${courseId}/lesson/${lessonId}/order`, {
      direction: type,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const updateOnboardingTrainingCourse = async (body: any, dispatch?: Dispatch<any>): Promise<any[]> => {
  return apiClient
    .put<any[]>(`${ADMIN_URL}/${onboardingCourseEndPoint}/${body.id}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createOnboardingTrainingLesson = async (
  body: OnboardingLessonFormState,
  courseId: string,
  dispatch?: Dispatch<any>,
): Promise<any[]> => {
  return apiClient
    .post<any[]>(`${ADMIN_URL}/${onboardingTrainingBaseEndPoint}/${courseId}/lesson`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const updateOnboardingTrainingLesson = async (
  body: OnboardingLessonFormState,
  courseId: string,
  lessonId: string,
  dispatch?: Dispatch<any>,
): Promise<any[]> => {
  return apiClient
    .put<any[]>(`${ADMIN_URL}/${onboardingTrainingBaseEndPoint}/${courseId}/lesson/${lessonId}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchOnboardingTrainingQuiz = async (dispatch?: Dispatch<any>): Promise<any[]> => {
  return apiClient
    .get<any[]>(`${ADMIN_URL}/${onboardingCourseEndPoint}/quizzes`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchOnboardingTrainingQuizReport = async (
  params: OnboardingQuizReportParams,
  courseId: string,
  quizId: string,
  dispatch?: Dispatch<any>,
) => {
  return downloadFile(
    'Quiz Report.csv',
    `${ADMIN_URL}/${onboardingCourseEndPoint}/${courseId}/quizzes/${quizId}/report`,
    params,
    dispatch,
  );
};

export const createOnboardingTrainingQuiz = async (
  body: OnboardingQuizFormState,
  courseId: string,
  dispatch?: Dispatch<any>,
): Promise<any[]> => {
  return apiClient
    .post<any[]>(`${ADMIN_URL}/${onboardingCourseEndPoint}/${courseId}/quizzes`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchOnboardingTrainingQuizDetail = async (
  courseId: string,
  quizId: string,
  params: { lessonId?: string },
  dispatch?: Dispatch<any>,
): Promise<any[]> => {
  return apiClient
    .get<any[]>(`${ADMIN_URL}/${onboardingCourseEndPoint}/${courseId}/quizzes/${quizId}/detail`, { params })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchOnboardingTrainingLessonDetail = async (
  courseId: string,
  lessonId: string,
  dispatch?: Dispatch<any>,
): Promise<any[]> => {
  return apiClient
    .get<any>(`${ADMIN_URL}/${onboardingCourseEndPoint}/${courseId}/lessons/${lessonId}/detail`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const deleteOnboardingTrainingQuiz = async (
  courseId: string,
  quizId: string,
  query: { lessonId?: string },
  dispatch?: Dispatch<any>,
): Promise<any[]> => {
  return apiClient
    .delete<any[]>(`${ADMIN_URL}/${onboardingCourseEndPoint}/${courseId}/quizzes/${quizId}`, { params: query })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const updateOnboardingTrainingQuiz = async (
  body: OnboardingQuizFormState,
  courseId: string,
  quizId: string,
  dispatch?: Dispatch<any>,
): Promise<any[]> => {
  return apiClient
    .put<any[]>(`${ADMIN_URL}/${onboardingCourseEndPoint}/${courseId}/quizzes/${quizId}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
