import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import {
  RECRUITMENT_BASE_PATH,
  RECRUITMENT_LICENSE_EXAM_PATH,
  RECRUITMENT_LICENSE_EXAM_SCHEDULE_PATH,
  RECRUITMENT_LICENSE_EXAM_REGISTRATION_PATH,
  RECRUITMENT_LICENSE_EXAM_RESULTS_PATH,
} from '../../../constants';
import { NOT_FOUND_ERROR_PAGE_PATH } from '../../../../../common/constants';
import PruRoute from '../../../../../common/components/PruRoute';
import ParamsProvider from 'src/app/common/components/ParamsProvider';
import RegistrationExportPage from './registration-export-page';

export const examRegistrationBasePath = `${RECRUITMENT_BASE_PATH}${RECRUITMENT_LICENSE_EXAM_PATH}${RECRUITMENT_LICENSE_EXAM_REGISTRATION_PATH}`;
const LicenseExamRegistrationRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect exact from={`${examRegistrationBasePath}`} to={`${examRegistrationBasePath}/export`} />
      <PruRoute exact path={`${examRegistrationBasePath}/export`} component={RegistrationExportPage} />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default LicenseExamRegistrationRoutes;
