import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import PruRoute from 'src/app/common/components/PruRoute';
import { PULSELEADS_BASE_PATH, } from '../../constants';
import { PULSELEADS_LEAD_PROSPECT_PATH, PULSELEADS_LEAD_PROSPECT_CATEGORY_PATH } from './constants';
import List from './Categrory/List';
import Detail from './Categrory/Detail';
import { ModulePermissionProps } from 'src/app/common/module/types';

export const leadProspectPath = `${PULSELEADS_BASE_PATH}${PULSELEADS_LEAD_PROSPECT_PATH}${PULSELEADS_LEAD_PROSPECT_CATEGORY_PATH}`;

const ProspectCategoryRoutes: FC<RouteComponentProps & ModulePermissionProps> = ({
    enableRead,
    enableCreate,
    enableUpdate
}) => {
    return (
        <Switch>
            <Redirect exact from={leadProspectPath} to={`${leadProspectPath}/list`} />
            <PruRoute exact path={`${leadProspectPath}/list`} component={List} />
            <PruRoute exact path={`${leadProspectPath}/edit/:id`} render={(props) => (
                <Detail
                    {...props}
                    enableRead={enableRead}
                    enableCreate={enableCreate}
                    enableUpdate={enableUpdate}
                />
            )} />
            <PruRoute exact path={`${leadProspectPath}/create`} component={Detail} />
            <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
        </Switch>
    )
};

export default ProspectCategoryRoutes;