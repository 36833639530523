import React, { FC, useReducer } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { Button, FormControl, TextField, Select, MenuItem, FormHelperText } from '@mui/material';
import { MANDATORY_FIELD_ERROR_TEXT } from 'src/app/common/constants';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { RootState } from 'src/redux/store';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import { AuthenticationState } from 'src/app/modules/Auth/_redux/authSlice';
import { ErrorFieldType, useErrorHandler } from 'src/app/common/utils';
import { CampaignMappingFormMode } from 'src/app/modules/PulseLeads/types/straight-through-types';
import { CampaignDropdownList, CampaignDropdownType } from 'src/app/modules/PulseLeads/types/campaign-types';
import { EventCampaignItem, EventCampaignOptions } from 'src/app/modules/PulseLeads/types/event-campaign-types';
import {
  CreateEventCampaignBody,
  UpdateEventCampaignBody,
  createEventCampaign,
  modifyEventCampaign,
} from 'src/app/modules/PulseLeads/network/eventCampaignCrud';
import { eventCampaignPath } from '../../EventCampaignRoutes';

type EventCampaignFormProps = {
  formMode: CampaignMappingFormMode;
  eventCampaignDropdownList?: CampaignDropdownList;
  eventCampaignOptions?: EventCampaignOptions;
  eventCampaignItem?: EventCampaignItem;
  config?: any;
};

const FIELD_CONTAINER_WIDTH = 180;

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  fieldContainer: {
    width: FIELD_CONTAINER_WIDTH,
    boxSizing: 'border-box',
  },
  innerFieldContainer: {
    width: FIELD_CONTAINER_WIDTH,
    boxSizing: 'border-box',
  },
  iconContainer: {
    width: 60,
    height: 60,
    overflow: 'hidden',
    borderRadius: 12,
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },
  sectionMargin: {
    marginBottom: 15,
  },
  divideMargin: {
    marginBottom: 10,
  },
  subHeader: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
  },
  textAreaRowContainer: {
    width: '100%',
    display: 'flex',
  },
  textAreaFieldContainer: {
    paddingTop: 15,
    minWidth: FIELD_CONTAINER_WIDTH,
    boxSizing: 'border-box',
  },
  textArea: {
    lineHeight: 1.5,
    minHeight: 40,
  },
  errorText: {
    fontSize: 9,
    color: '#F018A6',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));

type EventCampaignFormState = {
  eventCode?: string;
  campaign?: string;
  product?: string;
  interestedProductPlan?: string;
  leadDetail?: string;
};

const initialState: EventCampaignFormState = {
  eventCode: undefined,
  campaign: undefined,
  product: undefined,
  interestedProductPlan: undefined,
  leadDetail: undefined,
};

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof EventCampaignFormState;
    value: any;
  };
};

type EventCampaignFormAction = ModifyFieldAction;

const formReducer = (state: EventCampaignFormState, action: EventCampaignFormAction): EventCampaignFormState => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    default:
      return state;
  }
};

const detailToStateConvertor = (eventCampaignItem?: EventCampaignItem): EventCampaignFormState => {
  return eventCampaignItem
    ? {
        eventCode: eventCampaignItem.eventCode,
        campaign: eventCampaignItem.campaign,
        product: eventCampaignItem.product,
        interestedProductPlan: eventCampaignItem.interestedProductPlan,
        leadDetail: eventCampaignItem.leadDetail,
      }
    : initialState;
};

const getFieldConfig = (config: any) => {
  return {
    enableEventCampaignOptions: config?.content?.Sales?.campaign?.enableEventCampaignOptions ? true : false,
  };
};

const EventCampaignForm: FC<EventCampaignFormProps> = ({
  formMode,
  eventCampaignDropdownList,
  eventCampaignOptions,
  eventCampaignItem,
  config,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { classes: commonClasses } = useCommonStyles();
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { user } = useSelector<RootState, AuthenticationState>((state) => state.auth);

  const [formState, formDispatch] = useReducer(formReducer, detailToStateConvertor(eventCampaignItem));
  const fieldConfig = getFieldConfig(config);

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler } = useErrorHandler(formState, [
    {
      name: 'eventCode',
      fieldType: ErrorFieldType.MANDATORY,
    },
    {
      name: 'campaign',
      fieldType: ErrorFieldType.MANDATORY,
    },
  ]);

  const onSubmit = async () => {
    const { hasError } = onSubmitErrorValidator();
    if (!hasError) {
      if (formMode === CampaignMappingFormMode.CREATE) {
        const body: CreateEventCampaignBody = {
          eventCode: formState.eventCode || '',
          campaign: formState.campaign || '',
          product: formState.product,
          interestedProductPlan: formState.interestedProductPlan,
          leadDetail: formState.leadDetail,
          createdBy: user?.username || 'Default',
          updatedBy: user?.username || 'Default',
        };
        try {
          await createEventCampaign(body, dispatch);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: 'Event Campaign saved successfully',
              },
            ]),
          );
          history.push(eventCampaignPath);
        } catch (err) {}
      } else if (eventCampaignItem) {
        const body: UpdateEventCampaignBody = {
          eventCode: formState.eventCode || '',
          campaign: formState.campaign || '',
          product: formState.product,
          interestedProductPlan: formState.interestedProductPlan,
          leadDetail: formState.leadDetail,
          updatedBy: user?.username || 'Default',
        };
        try {
          await modifyEventCampaign(eventCampaignItem._id, body, dispatch);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: `Event Campaign updated successfully - ${eventCampaignItem._id}`,
              },
            ]),
          );
          history.push(eventCampaignPath);
        } catch (err) {}
      }
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <div className={classes.rowContainer}>
          <div className={commonClasses.header}>
            {Translation(
              `pulseleads.straightThrough.eventCampaign.form.title.${
                formMode === CampaignMappingFormMode.CREATE ? 'create' : 'edit'
              }`,
            )}
          </div>
        </div>
        <Button variant="contained" color="inherit" onClick={() => history.push(eventCampaignPath)}>
          {Translation('app.button.back')}
        </Button>
      </div>

      <div className={classes.sectionMargin}>
        <div className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>
              {Translation('pulseleads.straightThrough.eventCode')}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <div style={{ flexGrow: 1 }}>
            <TextField
              style={{ width: 250 }}
              error={errorState.mandatory.eventCode}
              margin="dense"
              variant="outlined"
              helperText={errorState.mandatory.eventCode && MANDATORY_FIELD_ERROR_TEXT}
              value={formState.eventCode}
              onChange={(e) => {
                onDismissErrorHandler('eventCode', e.target.value);
                formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'eventCode', value: e.target.value } });
              }}
            />
          </div>
        </div>

        <div className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>
              {Translation('pulseleads.straightThrough.campaignName')}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <FormControl error={errorState.mandatory.campaign} margin="dense" variant="outlined">
            <Select
              style={{ minWidth: 350 }}
              value={formState.campaign || ''}
              onChange={(e) => {
                onDismissErrorHandler('campaign', e.target.value);
                formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'campaign', value: e.target.value } });
              }}
            >
              {eventCampaignDropdownList &&
                eventCampaignDropdownList[CampaignDropdownType.CAMPAIGN] &&
                eventCampaignDropdownList[CampaignDropdownType.CAMPAIGN].map((dropdownItem) => (
                  <MenuItem key={dropdownItem.key} value={dropdownItem.key}>
                    {dropdownItem.value}
                  </MenuItem>
                ))}
            </Select>
            {errorState.mandatory.campaign && <FormHelperText>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>}
          </FormControl>
        </div>

        {fieldConfig.enableEventCampaignOptions && (
          <>
            {eventCampaignOptions?.products && (
              <div className={classes.rowContainer}>
                <div className={classes.fieldContainer}>
                  <span className={classes.field}>{Translation('pulseleads.straightThrough.matchedProduct')} :</span>
                </div>
                <FormControl margin="dense" variant="outlined">
                  <Select
                    style={{ minWidth: 350 }}
                    value={formState.product || ''}
                    onChange={(e) => {
                      formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'product', value: e.target.value } });
                    }}
                  >
                    {eventCampaignOptions.products.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            )}
            {eventCampaignOptions?.interestedProductPlans && (
              <div className={classes.rowContainer}>
                <div className={classes.fieldContainer}>
                  <span className={classes.field}>{Translation('pulseleads.straightThrough.interestedProduct')} :</span>
                </div>
                <FormControl margin="dense" variant="outlined">
                  <Select
                    style={{ minWidth: 350 }}
                    value={formState.interestedProductPlan || ''}
                    onChange={(e) => {
                      formDispatch({
                        type: 'MODIFY_FIELD',
                        payload: { field: 'interestedProductPlan', value: e.target.value },
                      });
                    }}
                  >
                    {eventCampaignOptions.interestedProductPlans.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            )}
            {eventCampaignOptions?.leadDetails && (
              <div className={classes.rowContainer}>
                <div className={classes.fieldContainer}>
                  <span className={classes.field}>{Translation('pulseleads.straightThrough.otherLeadComments')} :</span>
                </div>
                <FormControl margin="dense" variant="outlined">
                  <Select
                    style={{ minWidth: 350 }}
                    value={formState.leadDetail || ''}
                    onChange={(e) => {
                      formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'leadDetail', value: e.target.value } });
                    }}
                  >
                    {eventCampaignOptions.leadDetails.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            )}
          </>
        )}
      </div>

      <div className={classes.footerContainer}>
        <Button variant="contained" color="secondary" onClick={onSubmit}>
          {Translation('app.button.submit')}
        </Button>
      </div>
    </div>
  );
};

export default EventCampaignForm;
