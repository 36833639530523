import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import { Dispatch } from 'react';
import { ProductionTargetType, ProductionTarget } from '../types/production-target-types';
import { forEach } from 'lodash';

export type GetProductionTargetParams = {
  target: ProductionTargetType | '';
  targetRef: string;
  year: string;
  month?: string;
};

export type UpdateProductionTargetBody = {
  target: ProductionTargetType;
  targetRef: string;
  year: string;
  month?: string;
  netAfyp: number;
  netCase: number;
  netAfypHnp: number;
  defaults: {
    netAfyp: number;
    netCase: number;
    netAfypHnp: number;
  };
  enableEdit: boolean;
};

export const downloadDefaultTemplate = async (dispatch?: Dispatch<any>): Promise<string> => {
  return apiClient
    .get<string>(`${ADMIN_URL}/production-target/defaults/template`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createDefaultTarget = async (file: File, year: string, dispatch?: Dispatch<any>): Promise<void> => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('year', year);
  return apiClient
    .post<void>(`${ADMIN_URL}/production-target/defaults/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchProductionTarget = async (
  params: GetProductionTargetParams,
  dispatch?: Dispatch<any>,
): Promise<ProductionTarget[]> => {
  let queryPath = `${ADMIN_URL}/production-target?`;
  forEach(params, (param, key) => {
    queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
  });
  return apiClient
    .get<ProductionTarget[]>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const updateProductionTarget = async (
  body: UpdateProductionTargetBody,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .put<void>(`${ADMIN_URL}/production-target`, body, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
