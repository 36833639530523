export enum ExamTypeEnum {
  'Exam Type' = 'examType',
  'Exam Code' = 'examCode',
  'Exam Name (Local Language)' = 'examNameLocal',
  'Exam Name (English)' = 'examNameEnglish',
  'Exam Date' = 'examDate',
  'Start Time' = 'startTime',
  'End Time' = 'endTime',
  'Exam Centre' = 'examCentre',
  'Last Registration Date' = 'lastRegistrationDate',
  'Quota' = 'quota',
  'Minimum Quota' = 'minimumQuota',
  'isDeleted' = 'isDeleted',
}

export enum ExamResultsEnum {
  'Candidate ID' = 'candidateId',
  'Candidate Name' = 'candidateName',
  'Exam Type' = 'examType',
  'Exam Code' = 'examCode',
  'Exam Name (Local Language)' = 'examNameLocal',
  'Exam Name (English)' = 'examNameEnglish',
  'Exam Date' = 'examDate',
  'Start Time' = 'startTime',
  'End Time' = 'endTime',
  'Exam Centre' = 'examCentre',
  'Present' = 'isPresent',
  'Result' = 'result',
  'Score' = 'score',
}
