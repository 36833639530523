import React, { FC, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { fetchRuleNameList } from 'src/app/modules/PulseLeads/network/ruleConfigCrud';
import RuleNameList from './components/RuleNameList';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useDataProvider } from 'src/app/common/utils';
type RuleNameListProps = {} & RouteComponentProps & ParamsProps;

const ListPage: FC<RuleNameListProps> = () => {
  const [listData, setListData] = useState<any>();
  const { isLoading, refreshData } = useDataProvider<any>(
    async () => {
      try {
        return await fetchRuleNameList();
      } catch (err) {}
    },
    setListData,
    false,
  );

  return (
    <>
      <RuleNameList isLoading={isLoading} listData={listData} onRefresh={refreshData} />
    </>
  );
};

export default ListPage;
