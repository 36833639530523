import { useEffect, useRef, useState } from "react";
import { RequestData } from './index';
import { useDispatch } from 'react-redux';
import { appendAlertItem, AlertType } from '../../../../redux/common/commonSlice';
import { useIntl } from 'react-intl';

export const useUpdateEffect = (callback: () => any, deps: any) => {
  const ref = useRef<boolean | null>(null);

  useEffect(() => {
    if (!ref.current) {
      ref.current = true;
    } else {
      callback();
    }
  // eslint-disable-next-line
  }, deps);
};

interface PageInfo {
  page: number;
  pageSize: number;
  total: number;
}

export interface UseFetchDataAction<T extends RequestData<any>> {
  dataSource: T['data'];
  loading: boolean | undefined;
  page: number;
  pageSize: number;
  total: number;
  // cancel: () => void;
  reload: () => void;
  // fullScreen?: () => void;
  // resetPageIndex: () => void;
  // reset: () => void;
  // setPageInfo: (pageInfo: Partial<PageInfo>) => void;
}

export const useDataProvider = <T extends RequestData<any>, U>(callback: (params?: { pageSize: number; page: number }) => Promise<T>, onTableDataLoad?: (record: U[]) => void) : UseFetchDataAction<T> => {
  const [loading, setIsLoading] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<T['data']>([]);
  const [refresh, setRefresh] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [pageInfo, setPageInfo] = useState<PageInfo>({
    page: 0,
    total: 0,
    pageSize: 20,
  });
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });


  const loadData = async () => {
    setIsLoading(true);
    let dataSource = []
    try {
      const { data, success, total: dataTotal = 0 } = await callback();
      if (success !== false) {
        setDataSource(data)
        setPageInfo(pageInfo => ({
          ...pageInfo,
          total: dataTotal
        }))
      } else {
        setDataSource([])
        setPageInfo(pageInfo => ({
          ...pageInfo,
          page: 0,
          total: 0
        }))
      }
      dataSource = data || []
    } catch (e) {
      dispatch(appendAlertItem([{
        severity: AlertType.ERROR,
        title: Translation('global.submit.fail'),
        content: Translation('global.submit.fail')
      }]));
      setDataSource([])
      setPageInfo(pageInfo => ({
        ...pageInfo,
        page: 0,
        total: 0
      }))
    }
    if (onTableDataLoad) {
      onTableDataLoad(dataSource)
    }
    setIsLoading(false);
  }

  useEffect(() => {
    if (refresh) {
      loadData();
      setRefresh(false);
    }
  // eslint-disable-next-line
  }, [refresh]);

  return {
    loading,
    dataSource,
    page: pageInfo.page,
    total: pageInfo.total,
    pageSize: pageInfo.pageSize,
    reload: () => setRefresh(true)
  }
};
