import React, { createElement } from 'react';
import { ElementFactory, Question, Serializer } from 'survey-core';
import { SurveyQuestionElementBase, ReactQuestionFactory } from 'survey-react-ui';
import { PropertyGridEditorCollection } from 'survey-creator-react';
import { localization } from 'survey-creator-core';
import FileUploader from 'src/app/common/components/FileUploader';
import { fileUpload } from 'src/app/common/utils';
import { createBlob, getBlob } from 'src/app/common/network';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { Translation } from 'src/app/i18n';

const CUSTOM_TYPE = 'tnc-uploader';

class QuestionTnCUploader extends Question {
  getType() {
    return CUSTOM_TYPE;
  }
  get TnCLocale() {
    return this.getPropertyValue('TnCLocale');
  }
  set TnCLocale(val) {
    this.setPropertyValue('TnCLocale', val);
  }
}

const locale = localization.getLocale('');
locale.pe.TnCLocale = 'TnC File Locale';
locale.qt[CUSTOM_TYPE] = 'TnC Uploader';

//register `PdfUploader` as a model for the `pdf-uploader` type
export function registerTnCUploader() {
  ElementFactory.Instance.registerElement(CUSTOM_TYPE, (name) => {
    return new QuestionTnCUploader(name);
  });
}

// Add question type metadata for further serialization into JSON
Serializer.addClass(
  CUSTOM_TYPE,
  [],
  function () {
    return new QuestionTnCUploader('');
  },
  'question',
);

// A class that renders questions of the new type in the UI
export class SurveyQuestionTnCUploader extends SurveyQuestionElementBase {
  constructor(props) {
    super(props);
    this.state = { value: this.question.value };
    this.dispatch = props.dispatch;
  }
  get question() {
    return this.questionBase;
  }
  get value() {
    return isEmpty(this.question.value) ? undefined : this.question.value;
  }
  get locale() {
    return this.question.TnCLocale || 'en';
  }
  handleValueChange = (data) => {
    this.question.value = data;
  };

  uploadFileMockStatus = async ({ file, fileName, fileType, description = '' }) => {
    const createBlobRes = await createBlob(
      { mimeType: file.type, accessLevel: 'anonymous', module: 'recruitment' },
      this.dispatch,
    );
    await fileUpload(createBlobRes.url, file);
    const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, this.dispatch);
    const result = blobDetail[0];

    if (result) {
      return result;
    } else {
      throw new Error('upload failed');
    }
  };

  // Support the read-only and design modes
  get style() {
    return this.question.getPropertyValue('readOnly') || this.question.isDesignMode
      ? { pointerEvents: 'none' }
      : undefined;
  }

  renderTnCUploader() {
    return (
      <FileUploader
        maxFileSize={4}
        upload={this?.uploadFileMockStatus}
        value={this?.value}
        allowedFileTypes={['pdf']}
        disabled={this?.question.isReadOnly}
        onChange={this?.handleValueChange}
        allowDuplicateFile={true}
        maxFileNumber={1}
        maxFileNumberErrorText={Translation('recruitment.applicationForm.file.maxNumber')}
      />
    );
  }

  renderElement() {
    return <div style={this.style}>{this.renderTnCUploader()}</div>;
  }
}

// Register `SurveyQuestionTnCUploader` as a class that renders `tnc-uploader` questions
ReactQuestionFactory.Instance.registerQuestion(CUSTOM_TYPE, (props) => {
  return createElement(SurveyQuestionTnCUploader, props);
});

PropertyGridEditorCollection.register({
  fit: function (prop) {
    return prop.type === 'TnCUploader';
  },
  getJSON: function () {
    return {
      type: CUSTOM_TYPE,
    };
  },
});

export default connect(null)(QuestionTnCUploader);
