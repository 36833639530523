import React, { FC } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Button } from '@mui/material';
import { useIntl } from 'react-intl';
import SurveyCreator from '../../../../../../common/components/Survey/SurveyCreator';

type SurveyCreatorSectionProps = {
  surveyContent?: Record<string, unknown>;
  onChangeSurveyContent: (content: Record<string, unknown>) => void;
  onPreviousPage: () => void;
  onSubmit: () => Promise<void>;
};

const useStyles = makeStyles()((theme) => ({
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  sectionMargin: {
    marginBottom: 20,
  },
  divideMargin: {
    marginBottom: 10,
  },
  subHeader: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));

const SurveyCreatorSection: FC<SurveyCreatorSectionProps> = ({
  surveyContent,
  onChangeSurveyContent,
  onPreviousPage,
  onSubmit,
}) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  return (
    <div className={classes.sectionMargin}>
      <SurveyCreator content={surveyContent} onChangeContent={onChangeSurveyContent} />

      <div style={{ marginTop: 20 }} className={classes.footerContainer}>
        <Button variant="contained" color="secondary" onClick={onPreviousPage}>
          {Translation('app.button.previous')}
        </Button>
        <Button style={{ marginLeft: 25 }} variant="contained" color="secondary" onClick={onSubmit}>
          {Translation('app.button.submit')}
        </Button>
      </div>
    </div>
  );
};

export default SurveyCreatorSection;
