import { makeStyles } from 'tss-react/mui';
import { Button, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import React from 'react';
import { Translation } from 'src/app/i18n';

export interface ConfirmModalProps {
  onClose?: Function;
  description?: string;
  onConfirm: Function;
  confirmText?: string;
  cancelText?: string;
}

export const ConfirmModalView = (props: ConfirmModalProps) => {
  const { onClose, onConfirm, description, confirmText, cancelText } = props;
  return (
    <div className="tw-rounded-lg">
      {description && (
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions
        style={{
          margin: 10,
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          onClick={() => onClose?.({ type: 'reject' }, 'userCancelled' as any)}
          variant="outlined"
          color="inherit"
        >
          {cancelText ?? Translation('app.button.cancel')}
        </Button>
        <Button
          onClick={() => {
            const promiseInstance = onConfirm?.();
            onClose?.({ type: 'resolve' }, promiseInstance);
          }}
          variant="contained"
          color="secondary"
          autoFocus
        >
          {confirmText ?? Translation('app.button.confirm')}
        </Button>
      </DialogActions>
    </div>
  );
};
