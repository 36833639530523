import React, { FC } from 'react';
import { TableRow, TableCell, LinearProgress } from '@mui/material';

type PruTableLoadingProps = {
  isLoading: boolean;
};

const PruTableLoading: FC<PruTableLoadingProps> = ({ isLoading }) => (
  isLoading ? (
    <TableRow>
      <TableCell style={{ padding: 0 }} colSpan={12} align="center">
        <LinearProgress color="secondary" variant="indeterminate"/>
      </TableCell>
    </TableRow>
  ) : (
    <></>
  )
)

export default PruTableLoading;
