import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import { getQueryPath } from 'src/app/common/utils';
import { PaginateList } from '../../../common/types/common-types';
import { ReportDetail, ReportDownloadDetail, ReportItem } from '../types/report-types';
import { Dispatch } from 'react';

export type ReportListParams = {
  requestedDateFrom: Date | null;
  requestedDateTo: Date | null;
  page: number;
  limit: number;
};

export const fetchProductionTargetReportList = async (
  params: ReportListParams,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<ReportItem>> => {
  const queryPath = getQueryPath(`${ADMIN_URL}/report/production-target?`, params);

  return apiClient
    .get<PaginateList<ReportItem>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchIncentiveResultReportList = async (
  params: ReportListParams,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<ReportItem>> => {
  const queryPath = getQueryPath(`${ADMIN_URL}/report/incentive-result?`, params);
  return apiClient
    .get<PaginateList<ReportItem>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchProductionTargetMTDReportList = async (
  params: ReportListParams,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<ReportItem>> => {
  const queryPath = getQueryPath(`${ADMIN_URL}/report/production-target/mtd?`, params);
  return apiClient
    .get<PaginateList<ReportItem>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchAdbuTargetReportList = async (
  params: ReportListParams,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<ReportItem>> => {
  const queryPath = getQueryPath(`${ADMIN_URL}/report/adbu-target?`, params);
  return apiClient
    .get<PaginateList<ReportItem>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchReportDownloadLink = async (
  code: string,
  dispatch?: Dispatch<any>,
): Promise<ReportDownloadDetail> => {
  return apiClient
    .get<ReportDownloadDetail>(`${ADMIN_URL}/report/${code}/download`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createNewProductionTargetReport = async (
  params: Record<string, unknown>,
  dispatch?: Dispatch<any>,
): Promise<ReportDetail> => {
  return apiClient
    .post<ReportDetail>(
      `${ADMIN_URL}/report/production-target`,
      {
        parameters: params,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createNewIncentiveResultReport = async (
  params: Record<string, unknown>,
  dispatch?: Dispatch<any>,
): Promise<ReportDetail> => {
  return apiClient
    .post<ReportDetail>(
      `${ADMIN_URL}/report/incentive-result`,
      {
        parameters: params,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createNewProductionTargetMTDReport = async (
  params: Record<string, unknown>,
  dispatch?: Dispatch<any>,
): Promise<ReportDetail> => {
  return apiClient
    .post<ReportDetail>(
      `${ADMIN_URL}/report/production-target/mtd`,
      {
        parameters: params,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createNewAdbuTargetReport = async (
  params: Record<string, unknown>,
  dispatch?: Dispatch<any>,
): Promise<ReportDetail> => {
  return apiClient
    .post<ReportDetail>(
      `${ADMIN_URL}/report/adbu-target`,
      {
        parameters: params,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createNewAgentsReport = async (
  params: Record<string, unknown>,
  dispatch?: Dispatch<any>,
): Promise<ReportDetail> => {
  return apiClient
    .post<ReportDetail>(
      `${ADMIN_URL}/news-report/news-agents`,
      {
        parameters: params,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchNewsAgentReportList = async (
  params: ReportListParams,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<ReportItem>> => {
  const queryPath = getQueryPath(`${ADMIN_URL}/news-report/news-agents?`, params);
  return apiClient
    .get<PaginateList<ReportItem>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createNewCommentsReport = async (
  params: Record<string, unknown>,
  dispatch?: Dispatch<any>,
): Promise<ReportDetail> => {
  return apiClient
    .post<ReportDetail>(
      `${ADMIN_URL}/news-report/news-comments`,
      {
        parameters: params,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchNewsCommentsReportList = async (
  params: ReportListParams,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<ReportItem>> => {
  const queryPath = getQueryPath(`${ADMIN_URL}/news-report/news-comments?`, params);
  return apiClient
    .get<PaginateList<ReportItem>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createNewInfoReport = async (
  params: Record<string, unknown>,
  dispatch?: Dispatch<any>,
): Promise<ReportDetail> => {
  return apiClient
    .post<ReportDetail>(
      `${ADMIN_URL}/news-report/news-info`,
      {
        parameters: params,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchNewsInfoReportList = async (
  params: ReportListParams,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<ReportItem>> => {
  const queryPath = getQueryPath(`${ADMIN_URL}/news-report/news-info?`, params);
  return apiClient
    .get<PaginateList<ReportItem>>(queryPath)
    .then((response) => {
      debugger;
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createNewAgentActivityReport = async (
  params: Record<string, unknown>,
  dispatch?: Dispatch<any>,
): Promise<ReportDetail> => {
  return apiClient
    .post<ReportDetail>(
      `${ADMIN_URL}/news-report/news-activity`,
      {
        parameters: params,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchNewsAgentActivityReportList = async (
  params: ReportListParams,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<ReportItem>> => {
  const queryPath = getQueryPath(`${ADMIN_URL}/news-report/news-activity?`, params);
  return apiClient
    .get<PaginateList<ReportItem>>(queryPath)
    .then((response) => {
      debugger;
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
