import React, { FC, HTMLAttributes } from 'react';
import { makeStyles } from 'tss-react/mui';
import ModulePermissionItem from './ModulePermissionItem';
import { PermissionItem, PermissionPossession } from '../../../types/permission-types';
import { ModuleHierachy } from '../../../../../common/module/types';

type ModulePermissionTreeProps = {
  isRoot?: boolean;
  selectedModule?: string;
  permissions: PermissionItem[];
  treeNode: ModuleHierachy[];
  onUpdate: (resource: string, permissions: PermissionItem[]) => void;
};

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    padding: '10px 10px 10px 15px',
    borderRadius: 8,
    fontSize: '1rem',
    fontWeight: 'normal',
  },
  leave: {
    padding: '5px 0 5px 15px',
  },
  rootHeader: {
    fontSize: '1.15rem',
    fontWeight: 400,
  },
}));

const ModulePermissionTree: FC<ModulePermissionTreeProps & HTMLAttributes<HTMLDivElement>> = ({
  isRoot,
  selectedModule,
  permissions,
  treeNode,
  onUpdate,
  ...rest
}) => {
  const { classes } = useStyles();

  return (
    <div {...rest}>
      {treeNode
        .filter((node) => !node.disableRoleControl)
        .map(
          (node) =>
            (!selectedModule || selectedModule === node.title) && (
              <div
                key={`role-control-module-${node.title}`}
                className={`${!isRoot && !node.child ? classes.leave : ''} ${classes.root}`}
                style={{ marginBottom: isRoot ? 10 : 0 }}
              >
                {node.child ? (
                  <>
                    <div className={`${isRoot ? classes.rootHeader : ''}`}>{node.title}</div>
                    <ModulePermissionTree permissions={permissions} treeNode={node.child} onUpdate={onUpdate} />
                  </>
                ) : (
                  <>
                    {isRoot ? (
                      <>
                        <div className={classes.rootHeader}>{node.title}</div>
                        <ModulePermissionTree permissions={permissions} treeNode={[node]} onUpdate={onUpdate} />
                      </>
                    ) : (
                      <ModulePermissionItem
                        title={node.title}
                        onUpdate={onUpdate}
                        possession={PermissionPossession.ANY}
                        permissions={permissions}
                        namespace={node.namespace || ''}
                        extendOptions={node.options}
                      />
                    )}
                  </>
                )}
              </div>
            ),
        )}
    </div>
  );
};

export default ModulePermissionTree;
