import React, { FC, useState } from 'react';
import { ApplicationListParam, fetchApplicationList } from '../../network/applicationCrud';
import { ApplicationItem, ApplicationAccessLevel, ApplicationRole } from '../../types/application-types';
import { useDataProvider } from 'src/app/common/utils';
import { useDispatch } from 'react-redux';
import ApplicationList from './components/ApplicationList';
import { ParamsProps } from '../../../../common/components/ParamsProvider';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { useIntl } from 'react-intl';
import { map } from 'lodash';

type ApplicationListingPageProps = {} & ParamsProps;

const initialState: ApplicationListParam = {
  bundleIdentifier: '',
  name: '',
  role: '',
  isPublic: undefined,
};

const paramsInitiator = (initialParams?: Record<string, string>): ApplicationListParam => {
  return initialParams
    ? {
        bundleIdentifier: initialParams.bundleIdentifier ?? '',
        name: initialParams.name ?? '',
        role: initialParams.role ?? '',
        isPublic: initialParams.isPublic ?? undefined,
      }
    : initialState;
};

export const applicationFilterKeys = ['bundleIdentifier', 'name', 'role', 'isPublic'];

const ApplicationListingPage: FC<ApplicationListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const [filterState, setFilterState] = useState<ApplicationListParam>(paramsInitiator(initialParams));
  const [applicationList, setApplicationList] = useState<ApplicationItem[]>();

  const { isLoading, refreshData } = useDataProvider<ApplicationItem[]>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchApplicationList(filterState, dispatch);
      } catch (err) {}
    },
    setApplicationList,
    false,
  );

  return (
    <>
      <PruFilter
        title={Translation('application.common.bundleIdentifier')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'bundleIdentifier',
            initialValue: filterState.bundleIdentifier,
            displayName: Translation('application.common.bundleIdentifier'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'name',
            initialValue: filterState.name,
            displayName: Translation('application.common.name'),
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'accessLevel',
            initialValue:
              filterState.isPublic === undefined
                ? ''
                : filterState.isPublic
                ? ApplicationAccessLevel.PUBLIC
                : ApplicationAccessLevel.PRIVATE,
            displayName: Translation('application.common.accessLevel'),
            choices: [
              { displayName: '', value: '' },
              ...map(ApplicationAccessLevel, (option: string) => ({ displayName: option, value: option })),
            ],
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'role',
            initialValue: filterState.role,
            displayName: Translation('application.common.role'),
            choices: [
              { displayName: 'ALL', value: '' },
              ...map(ApplicationRole, (option: string) => ({ displayName: option, value: option })),
            ],
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            bundleIdentifier: data.bundleIdentifier,
            name: data.name,
            role: data.role,
            isPublic: data.accessLevel
              ? data.accessLevel === ApplicationAccessLevel.PUBLIC
                ? 'true'
                : 'false'
              : undefined,
          })
        }
        fetchData={refreshData}
      />
      <ApplicationList isLoading={isLoading} applicationList={applicationList} onRefresh={refreshData} />
    </>
  );
};

export default ApplicationListingPage;
