import React, { FC, memo, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, Dialog } from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './campaign.style';
import { useCampaign } from './campaign.hook';
import { getDefaultDisplayDate } from 'src/app/common/utils';
import { useLang } from 'src/app/i18n';
import ProTable from 'src/app/common/components/ProTable';
import { CampaignItem } from 'src/app/modules/PulseLeads/types/campaign-types';

type ComponentProps = ParamsProps & {
  onSelectItem: (campaign: CampaignItem) => void;
  onChange: (campaign: CampaignItem) => void;
  isView: boolean;
  selectId: string;
};

const columns = (Translation: any, locale: string) => {
  const columns = [
    {
      title: Translation('pulseleads.campaign.common.campaignId'),
      dataIndex: 'campaignId',
      hideInSearch: true,
    },
    {
      title: Translation('pulseleads.campaign.common.name'),
      dataIndex: 'name',
      hideInSearch: false,
    },
    {
      title: Translation('pulseleads.campaign.common.startDate'),
      dataIndex: 'startDate',
      hideInSearch: true,
      render: (data: any) => <span>{data ? getDefaultDisplayDate(data, 'datetime') : '-'}</span>,
    },
    {
      title: Translation('pulseleads.campaign.common.endDate'),
      dataIndex: 'endDate',
      hideInSearch: true,
      render: (data: any) => <span>{data ? getDefaultDisplayDate(data, 'datetime') : '-'}</span>,
    },
    {
      title: Translation('pulseleads.campaign.common.marketingOwnerName'),
      dataIndex: 'marketingOwnerName',
      hideInSearch: true,
      render: (data: any) => {
        return <span>{data ?? '-'}</span>;
      },
    },
    {
      title: Translation('pulseleads.campaign.common.status'),
      dataIndex: 'status',
      hideInSearch: true,
      render: (data: any) => {
        return <span>{data ? Translation(`pulseleads.campaign.status.option.${data}`) : '-'}</span>;
      },
    },
    {
      title: Translation('pulseleads.campaign.common.updatedBy'),
      dataIndex: 'updatedBy',
      hideInSearch: true,
      render: (data: any) => {
        return <span>{data ?? '-'}</span>;
      },
    },
  ];

  return columns;
};

export const CampaignListComponent: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  const { onSelectItem, onChange, isView, selectId } = props;
  // i18n
  const intl = useIntl();
  const locale = useLang();
  const Translation = (id: string) => intl.formatMessage({ id });

  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  // custom-hook
  const { selectedRowsState, setSelectedRows, popUpVisible, onCancel, onSelect, onSubmit, fetchData } = useCampaign({
    onSelectItem,
    selectId,
    onChange,
  });

  return (
    <div className={styles.container}>
      <div className={styles.buttonContainer}>
        {selectedRowsState && <span className={styles.content}>{`${selectedRowsState.name ?? ''}`}</span>}

        {!selectedRowsState && !isView && (
          <Button color="secondary" variant="contained" className={styles.marginTop} onClick={onSelect}>
            {Translation('deeplink.select.campaign')}
          </Button>
        )}

        {!!selectedRowsState && !isView && (
          <Button color="secondary" variant="contained" className={styles.marginTop} onClick={onSelect}>
            {Translation('deeplink.clear.select.event.label')}
          </Button>
        )}
      </div>

      <Dialog open={popUpVisible} onClose={onCancel} fullScreen>
        <>
          <div className={styles.backButtonContainer}>
            <Button onClick={onCancel} className={styles.backButton} variant="contained">
              {Translation('global.back.btnText')}
            </Button>
          </div>
          <div className={styles.tableContainer}>
            <ProTable
              rowKey="key"
              filterTitle={Translation('component.filterTitle.select-campaign')}
              headerTitle={''}
              columns={columns(Translation, locale)}
              request={(params, sorter, filter) => {
                return fetchData(params);
              }}
              toolBarRender={() => []}
              rowSelection={{
                onChange: (_: any, selectedRows: any) => {
                  console.log('selectedRows', selectedRows);
                  setSelectedRows(selectedRows[0]);
                },
                getCheckboxProps: (record: any) => {
                  return {
                    disabled: false,
                  };
                },
                selectItems: [selectedRowsState],
              }}
              singleSelect={true}
              pageSize={20}
            />
          </div>

          <div className={styles.modalButtons}>
            <Button onClick={onCancel} className={styles.modalButton} variant="contained">
              {Translation('app.button.cancel')}
            </Button>
            <Button onClick={onSubmit} className={styles.modalButton} variant="contained" color="secondary">
              {Translation('app.button.submit')}
            </Button>
          </div>
        </>
      </Dialog>
    </div>
  );
});
