import { Action, ThunkAction, createSlice } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { BASE_APP_QUICK_PATH_CONFIG_STORAGE_KEY, CONFIG_STORAGE_KEY } from 'src/app/common/constants';
import { Configuration } from '../types/config-type';

export type ConfigurationState = {
  data?: Configuration;
};

export const configPersistConfig = {
  key: 'config',
  storage,
};

const initialState: ConfigurationState = {
  data: undefined,
};

type SetConfigAction = {
  payload: Configuration;
};

export type ConfigThunkAction = ThunkAction<void, ConfigurationState, unknown, Action<string>>;

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setConfig: (state: ConfigurationState, action: SetConfigAction) => {
      if (typeof window !== undefined) {
        window.localStorage.setItem(CONFIG_STORAGE_KEY, JSON.stringify(action.payload));
      }
      return {
        ...state,
        data: action.payload,
      };
    },
    setAppQuickPaths: (state: ConfigurationState, action: SetConfigAction) => {
      if (typeof window !== undefined) {
        window.localStorage.setItem(BASE_APP_QUICK_PATH_CONFIG_STORAGE_KEY, JSON.stringify(action.payload));
      }
      return {
        ...state,
        appQuickPaths: action.payload,
      };
    },
  },
});

export const { setConfig, setAppQuickPaths } = configSlice.actions;
