import React, { FC, useState, useEffect } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { LayoutSplashScreen } from 'src/app/layout';
import { AgentProfileItem, ConfigBannerTierListItem } from 'src/app/modules/Profile/types/agent-profile-types';
import { fetchAgentProfile, fetchTierList } from 'src/app/modules/Profile/network/agentProfileCrud';
import AgentProfileForm from './components/AgentProfileForm';

const AgentProfileDetailPage: FC<RouteComponentProps> = ({ history }) => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [agentProfile, setAgentProfile] = useState<AgentProfileItem>();
  const [tierList, setTierList] = useState<any>();
  const reloadData = async () => {
    if (id) {
      setIsLoading(true);
      try {
        const res = await fetchAgentProfile(id, dispatch);
        const tier = await fetchTierList(undefined, dispatch).then((data) => {
          const list = data.docs;

          list.some((e: ConfigBannerTierListItem) => e.tierCode === 'custom').length > 0
            ? list
            : list.insert(list.length, { tierCode: 'custom' });
          setTierList(list);
        });

        setAgentProfile(res);
      } catch (err) {
        history.push(NOT_FOUND_ERROR_PAGE_PATH);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line
  }, [id]);

  return (
    <>
      {isLoading || !agentProfile ? (
        <LayoutSplashScreen />
      ) : (
        <AgentProfileForm agentProfile={agentProfile} tier={tierList} />
      )}
    </>
  );
};

export default AgentProfileDetailPage;
