import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import PruRoute from '../../../common/components/PruRoute';
import { APPLICATION_BASE_PATH } from '../constants';
import { NOT_FOUND_ERROR_PAGE_PATH } from '../../../common/constants';
import ApplicationListingPage, { applicationFilterKeys } from './List/ApplicationListingPage';
import ApplicationDetailPage from './Create/ApplicationDetailPage';
import ParamsProvider from '../../../common/components/ParamsProvider';

const ApplicationRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect exact from={`${APPLICATION_BASE_PATH}`} to={`${APPLICATION_BASE_PATH}/list`} />
      <PruRoute exact path={`${APPLICATION_BASE_PATH}/list`} render={(props) => (
        <ParamsProvider {...props} acceptKeys={applicationFilterKeys}>
          <ApplicationListingPage />
        </ParamsProvider>
      )}/>
      <PruRoute exact path={`${APPLICATION_BASE_PATH}/edit/:id`} component={ApplicationDetailPage}/>
      <PruRoute exact path={`${APPLICATION_BASE_PATH}/create`} component={ApplicationDetailPage}/>
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  )
};

export default ApplicationRoutes;
