import React, { FC, useReducer } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AsyncCsvLink from 'src/app/common/components/AsyncCsvLink';
import { useIntl } from 'react-intl';
import { makeStyles } from 'tss-react/mui';
import { Button } from '@mui/material';
import { RootState } from 'src/redux/store';
import { AuthenticationState } from 'src/app/modules/Auth/_redux/authSlice';
import { MANDATORY_FIELD_ERROR_TEXT } from 'src/app/common/constants';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { ErrorFieldType, useErrorHandler } from 'src/app/common/utils';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import {
  DownloadLeadListCsvBody,
  FollowUpStatusBody,
  UpdateLeadFollowUpStatusBody,
  downloadLeadListCsv,
  modifyLeadFollowUpStatus,
} from 'src/app/modules/PulseLeads/network/followUpCrud';
import moment from 'moment';

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  fieldContainer: {
    width: 240,
    boxSizing: 'border-box',
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  errorText: {
    fontSize: 9,
    color: '#F018A6',
  },
}));

type LeadUpdateState = {
  id: string;
  status: string;
  submissionDate: Date;
  policyNumber?: string;
};

type ManualUpdateFormState = {
  filename?: string;
  leadUpdateList?: LeadUpdateState[];
};

const initialState: ManualUpdateFormState = {
  filename: undefined,
  leadUpdateList: undefined,
};

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof ManualUpdateFormState;
    value: any;
  };
};

type ManualUpdateFormAction = ModifyFieldAction;

const formReducer = (state: ManualUpdateFormState, action: ManualUpdateFormAction): ManualUpdateFormState => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    default:
      return state;
  }
};

const ManualUpdateForm: FC<RouteComponentProps> = () => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const { classes: commonClasses } = useCommonStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { user } = useSelector<RootState, AuthenticationState>((state) => state.auth);

  const [formState, formDispatch] = useReducer(formReducer, initialState);

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler } = useErrorHandler(formState, [
    {
      name: 'leadUpdateList',
      fieldType: ErrorFieldType.MANDATORY,
    },
  ]);

  const onExportLead = async () => {
    const body: DownloadLeadListCsvBody = {
      page: 1,
      limit: 50,
    };
    try {
      return await downloadLeadListCsv(body, dispatch);
    } catch (err) {}
  };

  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      let reader = new FileReader();
      reader.onload = () => {
        let csvData = String(reader.result);
        let row = csvData.split('\n');
        let leadUpdateList: LeadUpdateState[] = [];
        row.shift();
        row.forEach((item) => {
          let rowItems = item.split(',');
          if (
            rowItems[0] &&
            rowItems[1] &&
            rowItems[3] &&
            rowItems[0].trim() &&
            rowItems[1].trim() &&
            rowItems[3].trim()
          ) {
            const submissionDateStr = rowItems[3].trim();
            let leadUpdateItem: LeadUpdateState = {
              id: rowItems[0].trim(),
              status: rowItems[1].trim(),
              submissionDate: submissionDateStr.includes('/')
                ? moment(submissionDateStr, 'DD/MM/YYYY hh:mm').toDate()
                : new Date(submissionDateStr),
            };
            if (rowItems[4] && rowItems[4].trim()) {
              leadUpdateItem.policyNumber = rowItems[4].trim();
            }
            leadUpdateList.push(leadUpdateItem);
          }
        });
        onDismissErrorHandler('leadUpdateList', leadUpdateList.length > 0);
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: 'Success',
              content: `Upload file successfully`,
            },
          ]),
        );
        formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'leadUpdateList', value: leadUpdateList } });
      };
      if (e.target.files) {
        reader.readAsBinaryString(e.target.files[0]);
        formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'filename', value: e.target.files[0].name } });
      }
    }
  };

  const onSubmit = async () => {
    const { hasError } = onSubmitErrorValidator();
    if (!hasError && formState.leadUpdateList) {
      try {
        for (const leadItem of formState.leadUpdateList) {
          const { id, status, submissionDate, policyNumber } = leadItem;
          const followUp: FollowUpStatusBody = { status, submissionDate };
          if (status == 'policy') {
            followUp.policyNumber = policyNumber;
          }
          let body: UpdateLeadFollowUpStatusBody = {
            followUp: [followUp],
            updatedBy: user?.username || 'Default',
          };
          await modifyLeadFollowUpStatus(id, body, dispatch);
        }
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: 'Success',
              content: 'Leads updated successfully',
            },
          ]),
        );
      } catch (err) {
      } finally {
        formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'leadUpdateList', value: undefined } });
        formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'filename', value: undefined } });
      }
    }
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <div className={classes.rowContainer}>
            <div className={commonClasses.header}>{Translation(`title.pulseleadsLead.followUp.manualUpdate`)}</div>
          </div>
        </div>
        <div>
          <div className={classes.rowContainer} style={{ marginTop: 8 }}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>
                {Translation('pulseleads.followUp.manualUpdate.exportLeadRemarks')} :
              </span>
            </div>
            <div>
              <div className={classes.rowContainer}>
                <AsyncCsvLink
                  isDisabled={false}
                  filename={'lead-list-by-agent.csv'}
                  dataParser={(str) => str ?? ''}
                  asyncCall={onExportLead}
                >
                  <Button variant="contained" color="secondary">
                    {Translation('pulseleads.followUp.manualUpdate.exportLead')}
                  </Button>
                </AsyncCsvLink>
              </div>
            </div>
          </div>
          <div className={classes.rowContainer} style={{ marginTop: 8 }}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>
                {Translation('pulseleads.followUp.manualUpdate.updatedLead')}
                <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <div>
              <input
                id="upload-agent-list-csv"
                hidden
                type="file"
                accept=".csv"
                onClick={(e) => {
                  const element = e.target as HTMLInputElement;
                  element.value = '';
                }}
                onChange={handleFile}
              />
              <div className={classes.rowContainer}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => document.getElementById('upload-agent-list-csv')!.click()}
                >
                  {Translation('app.button.chooseFile')}
                </Button>
                {formState.filename && <div style={{ marginLeft: 10 }}>{formState.filename}</div>}
                {errorState.mandatory.leadUpdateList && (
                  <div style={{ marginLeft: 10 }} className={classes.errorText}>
                    {MANDATORY_FIELD_ERROR_TEXT}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={classes.footerContainer}>
            <Button variant="contained" color="secondary" onClick={onSubmit}>
              {Translation('pulseleads.followUp.manualUpdate')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManualUpdateForm;
