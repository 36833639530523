import React, { FC, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { fetchNewsArticleItem } from '../../../network/newsCrud';
import { NewsArticleItem, NewsArticleFormMode } from '../../../types/news-type';
import { NOT_FOUND_ERROR_PAGE_PATH } from '../../../../../common/constants';
import { useDispatch } from 'react-redux';
import { ApiErrorResponse } from 'src/app/common/network';
import { LayoutSplashScreen } from 'src/app/layout';
import NewsDetail from './components/NewsDetail';

const NewsDetailPage: FC<RouteComponentProps> = ({ history }) => {
  const { id } = useParams<{ id: string }>();
  const { formMode, code } = useMemo(
    () => ({
      formMode: id ? NewsArticleFormMode.EDIT : NewsArticleFormMode.CREATE,
      code: id ? id : undefined,
    }),
    [id],
  );

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [newsArticle, setNewsArticle] = useState<NewsArticleItem>();
  const [lastFileName, setLastFileName] = useState<Pick<NewsArticleItem, 'agentListFileName'>>({
    agentListFileName: undefined,
  });

  const reloadData = () => {
    if (code) {
      setIsLoading(true);
      fetchNewsArticleItem(code, dispatch)
        .then((result) => {
          setNewsArticle(result);
          setLastFileName({
            agentListFileName: result.agentListFileName,
          });
        })
        .catch((err: ApiErrorResponse) => {
          history.push(NOT_FOUND_ERROR_PAGE_PATH);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line
  }, [code]);

  return (
    <>
      {formMode === NewsArticleFormMode.CREATE && (
        <NewsDetail
          formMode={formMode}
          onReload={reloadData}
          lastFileName={lastFileName}
          onRouteTo={(route) => history.push(route)}
        />
      )}
      {formMode === NewsArticleFormMode.EDIT &&
        (isLoading || !newsArticle ? (
          <LayoutSplashScreen />
        ) : (
          <NewsDetail
            code={code}
            formMode={formMode}
            newsArticle={newsArticle}
            lastFileName={lastFileName}
            onReload={reloadData}
            onRouteTo={(route) => history.push(route)}
          />
        ))}
    </>
  );
};

export default NewsDetailPage;
