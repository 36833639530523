import React, { FC, useEffect, useState } from 'react';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { useIntl } from 'react-intl';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useDispatch } from 'react-redux';
import { useDataProvider } from 'src/app/common/utils';
import { PaginateList } from 'src/app/common/types/common-types';
import {
  AgentProfileItem,
  TierEnum,
  StatusEnum,
  AboutMe,
  MyHighlight,
  ConfigBannerTierListItem,
} from 'src/app/modules/Profile/types/agent-profile-types';

import {
  AgentProfileListParam,
  fetchAgentProfileList,
  fetchTierList,
} from 'src/app/modules/Profile/network/agentProfileCrud';
import AgentProfileList from './components/AgentProfileList';
import { useLang } from 'src/app/i18n';

type AgentProfileListingPageProps = ParamsProps;

const initialState: AgentProfileListParam = {
  name: '',
  agentCode: '',
  tier: '',
  status: '',
  enableMyAboutMe: '',
  enableMyHighlight: '',
  page: 1,
  limit: 5,
};

export const agentProfileFilterKeys = ['name', 'agentCode', 'tier', 'status', 'enableMyAboutMe', 'enableMyHighlight'];

const paramsInitiator = (initialParams?: Record<string, string>): AgentProfileListParam => {
  return initialParams
    ? {
        name: initialParams.name || '',
        agentCode: initialParams.agentCode || '',
        tier: initialParams.tier || '',
        status: initialParams.status || '',
        enableMyAboutMe: initialParams.enableMyAboutMe || '',
        enableMyHighlight: initialParams.enableMyHighlight || '',
        page: 1,
        limit: 5,
      }
    : initialState;
};

const AgentProfileListingPage: FC<AgentProfileListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const locale = useLang();
  const [filterState, setFilterState] = useState<AgentProfileListParam>(paramsInitiator(initialParams));
  const [agentProfileList, setAgentProfileList] = useState<PaginateList<AgentProfileItem>>();
  const [tierList, setTierList] = useState<any>([]);
  const reloadData = async () => {
    try {
      const tier = await fetchTierList(undefined, dispatch).then((data) => {
        const list = data.docs;

        list.some((e: ConfigBannerTierListItem) => e.tierCode === 'custom').length > 0
          ? list
          : list.insert(list.length, { tierCode: 'custom' });
        setTierList(list);
      });
    } catch (err) {}
  };
  const { isLoading, refreshData } = useDataProvider<PaginateList<AgentProfileItem>>(
    async () => {
      reloadData();
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchAgentProfileList(filterState, locale, dispatch);
      } catch (err) {}
    },
    setAgentProfileList,
    false,
  );

  return (
    <>
      <PruFilter
        title={Translation('agentProfile.ad_agent_settings_header_title')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'name',
            initialValue: filterState.name,
            style: { width: 200 },
            displayName: Translation('agentProfile.ad_agent_name_label'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'agentCode',
            initialValue: filterState.agentCode,
            style: { width: 200 },
            displayName: Translation('agentProfile.ad_agent_code_label'),
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'tier',
            initialValue: filterState.tier,
            displayName: Translation('tier_label'),
            style: { width: 200 },
            choices: [
              { displayName: '', value: '' },
              ...(tierList.map((item: any) => {
                return {
                  displayName: item.tierCode,
                  value: item.tierCode,
                };
              }) || []),
            ],
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'status',
            initialValue: filterState.status,
            displayName: Translation('agentProfile.ad_status_label'),
            style: { width: 200 },
            choices: [
              { displayName: '', value: '' },
              ...Object.entries(StatusEnum).map(([key, value]) => ({
                displayName: Translation(`agentProfile.ad_${value}_label`),
                value: value,
              })),
            ],
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'enableMyAboutMe',
            initialValue: filterState.enableMyAboutMe,
            displayName: Translation('agentProfile.ad_introduction_label'),
            style: { width: 200 },
            choices: [
              { displayName: '', value: '' },
              ...Object.entries(AboutMe).map(([key, value]) => ({
                displayName: Translation(`agentProfile.ad_${value}_label`),
                value: value === AboutMe.CUSTOM ? 'true' : 'false',
              })),
            ],
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'enableMyHighlight',
            initialValue: filterState.enableMyHighlight,
            displayName: Translation('agentProfile.ad_my_highlights_label'),
            style: { width: 200 },
            choices: [
              { displayName: '', value: '' },
              ...Object.entries(MyHighlight).map(([, value]) => ({
                displayName: Translation(`agentProfile.ad_${value}_label`),
                value: value === MyHighlight.YES ? 'true' : 'false',
              })),
            ],
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            name: data.name,
            agentCode: data.agentCode,
            tier: data.tier,
            status: data.status,
            enableMyAboutMe: data.enableMyAboutMe,
            enableMyHighlight: data.enableMyHighlight,
          })
        }
        fetchData={refreshData}
      />
      <AgentProfileList
        isLoading={isLoading}
        agentProfileList={agentProfileList}
        onRefresh={refreshData}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default AgentProfileListingPage;
