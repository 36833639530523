import React, { FC } from 'react';
import HeaderMobile from './components/header/header-mobile';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';
import Aside from './components/aside/aside';
import { ContentContainer } from './components/container/content-container';

const Layout: FC = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.wrapper}>
      <HeaderMobile />
      <Aside />
      <ContentContainer />
    </div>
  );
};

export default Layout;

const useStyles = makeStyles()((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },
}));
