import {combineReducers} from "redux";

import { authSlice, authPersistConfig } from "../app/modules/Auth/_redux/authSlice";
import { configSlice, configPersistConfig } from "../app/modules/Config/_redux/configSlice";
import { commonSlice } from "./common/commonSlice";
import { persistReducer } from "redux-persist";
import { salesKitSlice } from "../app/modules/sales-kit/redux/saleskit.slice";
import { layoutSlice } from './layout/layout-reducer';

export const rootReducer = combineReducers({
  common: commonSlice.reducer,
  saleskit: salesKitSlice.reducer,
  auth: persistReducer(authPersistConfig, authSlice.reducer),
  config: persistReducer(configPersistConfig, configSlice.reducer),
  layout: layoutSlice.reducer,
});
