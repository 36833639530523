import React, { FC, useState, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import {
  Button,
  Checkbox,
  TextField,
  FormControl,
  FormHelperText,
  IconButton,
  Select,
  MenuItem,
} from '@mui/material';
import { Refresh } from '@mui/icons-material';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { preFillZeroList, ErrorFieldType, ErrorFieldDef, useErrorHandler } from 'src/app/common/utils';
import { MANDATORY_FIELD_ERROR_TEXT } from 'src/app/common/constants';
import { downloadAgentListTemplate } from 'src/app/common/network';
import { redistributeLeadPath } from '../RedistributeLeadRoutes';
import {
  ReassignSourceTypeEnum,
  LeadActivityStatusEnum,
  UpdateActivityStatusEnum,
  LeadScopingTypeEnum,
} from 'src/app/modules/PulseLeads/types/agent-types';
import { CampaignDropdownList } from 'src/app/modules/PulseLeads/types/campaign-types';
import {
  fetchScopeDropdown,
  redistributeLead,
  GetScopeDropdownParam,
  RedistributeLeadBody,
} from 'src/app/modules/PulseLeads/network/agentCrud';
import AsyncCsvLink from 'src/app/common/components/AsyncCsvLink';

const FIELD_CONTAINER_WIDTH = 300;

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  sectionMargin: {
    marginBottom: 15,
  },
  fieldContainer: {
    width: FIELD_CONTAINER_WIDTH,
    boxSizing: 'border-box',
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  checkboxPadding: {
    marginLeft: -9,
  },
  iconButton: {
    padding: 9,
  },
  icon: {
    width: '1.2em',
    height: '1.2em',
  },
}));

type RedistributeLeadFormState = {
  agentList?: string[];
  reassignSourceType?: string;
  specificCampaignList?: string[];
  specificLeadSourceList?: string[];
  leadActivityStatus?: string;
  isCopyEngagement: boolean;
  isCopyCalendar: boolean;
  updateActivityStatus?: string;
  isAddReassignLabel: boolean;
  isSendNotification: boolean;
};

const initialState: RedistributeLeadFormState = {
  agentList: undefined,
  reassignSourceType: undefined,
  specificCampaignList: undefined,
  specificLeadSourceList: undefined,
  leadActivityStatus: undefined,
  isCopyEngagement: true,
  isCopyCalendar: true,
  updateActivityStatus: undefined,
  isAddReassignLabel: true,
  isSendNotification: true,
};

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof RedistributeLeadFormState;
    value: any;
  };
};

type AgentLeadSourceFormAction = ModifyFieldAction;

const formReducer = (
  state: RedistributeLeadFormState,
  action: AgentLeadSourceFormAction,
): RedistributeLeadFormState => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    default:
      return state;
  }
};

const RedistributeLeadDetailPage: FC<RouteComponentProps> = ({ history }) => {
  const dispatch = useDispatch();
  const { classes: commonClasses } = useCommonStyles();
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [formState, formDispatch] = useReducer(formReducer, initialState);
  const [dropdownList, setDropdownList] = useState<CampaignDropdownList>();
  const [dropdownErrorState, setDropdownErrorState] = useState<boolean>(false);

  const reloadDropdown = async (reassignSourceType?: string) => {
    if (!formState.agentList || formState.agentList.length === 0) {
      setDropdownErrorState(true);
    } else if (
      reassignSourceType &&
      (reassignSourceType === ReassignSourceTypeEnum.SPECIFIC_LEAD_CAMPAIGN ||
        reassignSourceType === ReassignSourceTypeEnum.SPECIFIC_LEAD_SOURCE)
    ) {
      const scopeType =
        reassignSourceType === ReassignSourceTypeEnum.SPECIFIC_LEAD_CAMPAIGN
          ? LeadScopingTypeEnum.CAMPAIGN
          : LeadScopingTypeEnum.LEAD_SOURCE;
      const params: GetScopeDropdownParam = {
        agents: preFillZeroList(formState.agentList),
        scopeType,
      };
      const dropdownRes = await fetchScopeDropdown(params, dispatch);
      setDropdownList({ [scopeType]: dropdownRes });
    }
  };

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler } = useErrorHandler(formState, [
    {
      name: 'reassignSourceType',
      fieldType: ErrorFieldType.MANDATORY,
    },
    {
      name: 'leadActivityStatus',
      fieldType: ErrorFieldType.MANDATORY,
    },
    {
      name: 'updateActivityStatus',
      fieldType: ErrorFieldType.MANDATORY,
    },
    {
      name: 'agentList',
      fieldType: ErrorFieldType.MANDATORY,
      condition: () => !(formState.agentList && formState.agentList.length > 0),
    },
    ...(() => {
      const errorFieldDef: ErrorFieldDef[] = [];
      if (formState.reassignSourceType === ReassignSourceTypeEnum.SPECIFIC_LEAD_CAMPAIGN) {
        errorFieldDef.push(
          {
            name: 'specificCampaignList',
            fieldType: ErrorFieldType.MANDATORY,
          },
          {
            name: 'emptySpecificCampaignList',
            fieldType: ErrorFieldType.IMMEDIATE,
            condition: () => {
              if (formState.specificCampaignList) {
                return formState.specificCampaignList.length === 0;
              } else {
                return false;
              }
            },
          },
        );
      } else if (formState.reassignSourceType === ReassignSourceTypeEnum.SPECIFIC_LEAD_SOURCE) {
        errorFieldDef.push(
          {
            name: 'specificLeadSourceList',
            fieldType: ErrorFieldType.MANDATORY,
          },
          {
            name: 'emptySpecificLeadSourceList',
            fieldType: ErrorFieldType.IMMEDIATE,
            condition: () => {
              if (formState.specificLeadSourceList) {
                return formState.specificLeadSourceList.length === 0;
              } else {
                return false;
              }
            },
          },
        );
      }
      return errorFieldDef;
    })(),
  ]);

  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      let reader = new FileReader();
      reader.onload = () => {
        let csvData = String(reader.result);
        let row = csvData.split('\n');
        let agentList: string[] = [];
        row.shift();
        row.forEach((item) => {
          let rowItems = item.split(',');
          if (rowItems[0] && rowItems[0].trim()) {
            agentList.push(rowItems[0].trim());
          }
        });
        onDismissErrorHandler('agentList', agentList.length > 0);
        formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'agentList', value: agentList } });
      };
      if (e.target.files) {
        reader.readAsBinaryString(e.target.files[0]);
      }
    }
  };

  const onSubmit = async () => {
    const { hasError } = onSubmitErrorValidator();
    if (!hasError) {
      const body: RedistributeLeadBody = {
        agentList: formState.agentList ? preFillZeroList(formState.agentList) : [],
        reassignSourceType: formState.reassignSourceType || '',
        specificCampaignList:
          formState.reassignSourceType === ReassignSourceTypeEnum.SPECIFIC_LEAD_CAMPAIGN
            ? formState.specificCampaignList
            : undefined,
        specificLeadSourceList:
          formState.reassignSourceType === ReassignSourceTypeEnum.SPECIFIC_LEAD_SOURCE
            ? formState.specificLeadSourceList
            : undefined,
        leadActivityStatus: formState.leadActivityStatus || '',
        isCopyEngagement: formState.isCopyEngagement,
        isCopyCalendar: formState.isCopyCalendar,
        updateActivityStatus: formState.updateActivityStatus || '',
        isAddReassignLabel: formState.isAddReassignLabel,
        isSendNotification: formState.isSendNotification,
      };
      try {
        await redistributeLead(body, dispatch);
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: 'Success',
              content: 'Lead redistributed successfully',
            },
          ]),
        );
        history.push(redistributeLeadPath);
      } catch (err) {}
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <div className={classes.rowContainer}>
          <div className={commonClasses.header}>{Translation('title.pulseleads.agent.redistributeLead')}</div>
        </div>
      </div>

      <div className={classes.sectionMargin}>
        <div className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>
              {Translation('pulseleads.agent.redistributeLead.addAgents')}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>

          <div style={{ flexGrow: 1 }} className={classes.rowContainer}>
            <TextField
              style={{ minWidth: 350 }}
              error={errorState.mandatory.agentList || dropdownErrorState}
              margin="dense"
              variant="outlined"
              helperText={(errorState.mandatory.agentList || dropdownErrorState) && MANDATORY_FIELD_ERROR_TEXT}
              placeholder={'Input agent code separate by comma'}
              value={(formState.agentList || []).join(',')}
              onChange={(e) => {
                const agentCode = e.target.value.replace(/[^0-9,]/g, '');
                onDismissErrorHandler('agentList', agentCode);
                setDropdownErrorState(false);
                formDispatch({
                  type: 'MODIFY_FIELD',
                  payload: { field: 'agentList', value: agentCode ? agentCode.split(',') : [] },
                });
              }}
            />
            <AsyncCsvLink
              style={{ marginLeft: 20 }}
              isDisabled={false}
              filename={'agent-list-template.csv'}
              dataParser={(str) => str}
              asyncCall={() => downloadAgentListTemplate(dispatch)}
            >
              <Button variant="contained" color="secondary">
                {Translation('pulseleads.agent.redistributeLead.downloadTemplate')}
              </Button>
            </AsyncCsvLink>
          </div>
        </div>

        <div className={classes.rowContainer} style={{ marginTop: 4, marginBottom: 8 }}>
          <div className={classes.fieldContainer} />
          <div>
            <input
              id="upload-list-agent-csv"
              hidden
              type="file"
              accept=".csv"
              onClick={(e) => {
                const element = e.target as HTMLInputElement;
                element.value = '';
              }}
              onChange={handleFile}
            />
            <div className={classes.rowContainer}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => document.getElementById('upload-list-agent-csv')!.click()}
              >
                {Translation('app.button.chooseFile')}
              </Button>
            </div>
          </div>
        </div>

        <div className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span>
              {Translation('pulseleads.agent.redistributeLead.source')}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <FormControl error={errorState.mandatory.reassignSourceType} margin="dense" variant="outlined">
            <Select
              style={{ minWidth: 350 }}
              value={formState.reassignSourceType || ''}
              onChange={(e) => {
                onDismissErrorHandler('reassignSourceType', e.target.value);
                formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'reassignSourceType', value: e.target.value } });
                reloadDropdown(e.target.value as ReassignSourceTypeEnum);
              }}
            >
              {Object.values(ReassignSourceTypeEnum).map((value) => (
                <MenuItem key={value} value={value}>
                  {Translation(`pulseleads.agent.reassignLead.source.${value}`)}
                </MenuItem>
              ))}
            </Select>
            {errorState.mandatory.reassignSourceType && <FormHelperText>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>}
          </FormControl>
        </div>

        {formState.reassignSourceType === ReassignSourceTypeEnum.SPECIFIC_LEAD_CAMPAIGN && (
          <div className={classes.rowContainer} style={{ marginTop: 5 }}>
            <div className={classes.fieldContainer} />
            <div>
              <div>
                <span style={{ marginRight: 5 }}>
                  {Translation('pulseleads.agent.reassignLead.campaign')}
                  <span className={classes.mandatory}>*</span>
                </span>
                <IconButton className={classes.iconButton} onClick={() => reloadDropdown(formState.reassignSourceType)}>
                  <Refresh />
                </IconButton>
              </div>
              <FormControl
                error={errorState.immediate.emptySpecificCampaignList || errorState.mandatory.specificCampaignList}
                margin="dense"
                variant="outlined"
              >
                <Select
                  style={{ minWidth: 350, maxWidth: 350 }}
                  multiple
                  value={formState.specificCampaignList || []}
                  onChange={(e) => {
                    onDismissErrorHandler('specificCampaignList', e.target.value);
                    formDispatch({
                      type: 'MODIFY_FIELD',
                      payload: { field: 'specificCampaignList', value: e.target.value || [] },
                    });
                  }}
                >
                  {dropdownList &&
                    dropdownList[LeadScopingTypeEnum.CAMPAIGN] &&
                    dropdownList[LeadScopingTypeEnum.CAMPAIGN].map((dropdownItem) => (
                      <MenuItem key={dropdownItem.key} value={dropdownItem.key}>
                        {dropdownItem.value}
                      </MenuItem>
                    ))}
                </Select>
                {(errorState.immediate.emptySpecificCampaignList || errorState.mandatory.specificCampaignList) && (
                  <FormHelperText>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>
                )}
              </FormControl>
            </div>
          </div>
        )}

        {formState.reassignSourceType === ReassignSourceTypeEnum.SPECIFIC_LEAD_SOURCE && (
          <div className={classes.rowContainer} style={{ marginTop: 5 }}>
            <div className={classes.fieldContainer} />
            <div>
              <div>
                <span style={{ marginRight: 5 }}>
                  {Translation('pulseleads.agent.reassignLead.leadSource')}
                  <span className={classes.mandatory}>*</span>
                </span>
                <IconButton className={classes.iconButton} onClick={() => reloadDropdown(formState.reassignSourceType)}>
                  <Refresh />
                </IconButton>
              </div>
              <FormControl
                error={errorState.immediate.emptySpecificLeadSourceList || errorState.mandatory.specificLeadSourceList}
                margin="dense"
                variant="outlined"
              >
                <Select
                  style={{ minWidth: 350, maxWidth: 350 }}
                  multiple
                  value={formState.specificLeadSourceList || []}
                  onChange={(e) => {
                    onDismissErrorHandler('specificLeadSourceList', e.target.value);
                    formDispatch({
                      type: 'MODIFY_FIELD',
                      payload: { field: 'specificLeadSourceList', value: e.target.value || [] },
                    });
                  }}
                >
                  {dropdownList &&
                    dropdownList[LeadScopingTypeEnum.LEAD_SOURCE] &&
                    dropdownList[LeadScopingTypeEnum.LEAD_SOURCE].map((dropdownItem) => (
                      <MenuItem key={dropdownItem.key} value={dropdownItem.key}>
                        {dropdownItem.value}
                      </MenuItem>
                    ))}
                </Select>
                {(errorState.immediate.emptySpecificLeadSourceList || errorState.mandatory.specificLeadSourceList) && (
                  <FormHelperText>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>
                )}
              </FormControl>
            </div>
          </div>
        )}

        <div className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span>
              {Translation('pulseleads.agent.reassignLead.leadActivityStatus')}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <FormControl error={errorState.mandatory.leadActivityStatus} margin="dense" variant="outlined">
            <Select
              style={{ minWidth: 350 }}
              value={formState.leadActivityStatus || ''}
              onChange={(e) => {
                onDismissErrorHandler('leadActivityStatus', e.target.value);
                formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'leadActivityStatus', value: e.target.value } });
              }}
            >
              {Object.values(LeadActivityStatusEnum).map((value) => (
                <MenuItem key={value} value={value}>
                  {Translation(`pulseleads.agent.reassignLead.leadActivityStatus.${value}`)}
                </MenuItem>
              ))}
            </Select>
            {errorState.mandatory.leadActivityStatus && <FormHelperText>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>}
          </FormControl>
        </div>

        <div className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span>{Translation('pulseleads.agent.redistributeLead.isCopyEngagement')} :</span>
          </div>
          <Checkbox
            className={classes.checkboxPadding}
            checked={formState.isCopyEngagement}
            onChange={(e) => {
              formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'isCopyEngagement', value: e.target.checked } });
            }}
          />
        </div>

        <div className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span>{Translation('pulseleads.agent.redistributeLead.isCopyCalendar')} :</span>
          </div>
          <Checkbox
            className={classes.checkboxPadding}
            checked={formState.isCopyCalendar}
            onChange={(e) => {
              formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'isCopyCalendar', value: e.target.checked } });
            }}
          />
        </div>

        <div className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span>
              {Translation('pulseleads.agent.reassignLead.updateActivityStatus')}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <FormControl error={errorState.mandatory.updateActivityStatus} margin="dense" variant="outlined">
            <Select
              style={{ minWidth: 350 }}
              value={formState.updateActivityStatus || ''}
              onChange={(e) => {
                onDismissErrorHandler('updateActivityStatus', e.target.value);
                formDispatch({
                  type: 'MODIFY_FIELD',
                  payload: { field: 'updateActivityStatus', value: e.target.value },
                });
              }}
            >
              {Object.values(UpdateActivityStatusEnum).map((value) => (
                <MenuItem key={value} value={value}>
                  {Translation(`pulseleads.agent.reassignLead.updateActivityStatus.${value}`)}
                </MenuItem>
              ))}
            </Select>
            {errorState.mandatory.updateActivityStatus && <FormHelperText>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>}
          </FormControl>
        </div>

        <div className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span>{Translation('pulseleads.agent.redistributeLead.isAddRedistributeLabel')} :</span>
          </div>
          <Checkbox
            className={classes.checkboxPadding}
            checked={formState.isAddReassignLabel}
            onChange={(e) => {
              formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'isAddReassignLabel', value: e.target.checked } });
            }}
          />
        </div>

        <div className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span>{Translation('pulseleads.agent.reassignLead.isSendNotification')} :</span>
          </div>
          <Checkbox
            className={classes.checkboxPadding}
            checked={formState.isSendNotification}
            onChange={(e) => {
              formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'isSendNotification', value: e.target.checked } });
            }}
          />
          <span>{Translation('pulseleads.agent.reassignLead.isSendNotification.app')}</span>
        </div>
      </div>

      <div className={classes.footerContainer}>
        <Button variant="contained" color="secondary" onClick={onSubmit}>
          {Translation('title.pulseleads.agent.redistributeLead')}
        </Button>
      </div>
    </div>
  );
};

export default RedistributeLeadDetailPage;
