import { FC } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useLang } from 'src/app/i18n';
import { PaginateList } from 'src/app/common/types';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { getDefaultDisplayDate } from 'src/app/common/utils';
import { EventStatusEnum, EventPublishStatusEnum, EventItem } from '../../../../types';
import { eventListPath } from '../../event-list-routes';

type EventListProps = {
  isLoading: boolean;
  eventList?: PaginateList<EventItem>;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
  onSort: (newSortState: { key: string; value?: string }) => void;
  onDownloadQRCode: (item: EventItem) => Promise<void>;
  onCopyAndNew: (id: string) => Promise<void>;
};

export const EventList: FC<EventListProps> = ({
  isLoading,
  eventList,
  onRefresh,
  onChangePage,
  onSort,
  onDownloadQRCode,
  onCopyAndNew,
}) => {
  const history = useHistory();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const locale = useLang();

  return (
    <PruTable
      title={Translation('title.event.list.label')}
      disableBulkSelect
      headerBtnDef={[
        {
          color: 'secondary',
          title: Translation('app.button.add'),
          onClick: () => history.push(`${eventListPath}/create`),
        },
      ]}
      operationDef={[
        {
          title: Translation('section.common.operation.download_qr'),
          tooltipText: 'Download QR',
          onClick: (row) => onDownloadQRCode(row),
          condition: (row) => row.publishStatus === EventPublishStatusEnum.PUBLISHED,
        },
        {
          title: Translation('section.common.operation.view'),
          tooltipText: 'View',
          onClick: (row) => history.push(`${eventListPath}/view/${row._id}`),
        },
        {
          title: Translation('section.common.operation.edit'),
          tooltipText: 'Edit',
          onClick: (row) => history.push(`${eventListPath}/edit/${row._id}`),
          condition: (row) =>
            row.publishStatus === EventPublishStatusEnum.DRAFT || row.eventStatus === EventStatusEnum.UPCOMING,
        },
        {
          title: Translation('event.list.copy.new.label'),
          tooltipText: 'Copy & New',
          onClick: (row) => onCopyAndNew(row._id),
        },
      ]}
      columnDef={[
        {
          isId: true,
          hidden: true,
          keyIndex: '_id',
          displayName: '',
          renderData: () => '',
        },
        {
          keyIndex: 'name',
          displayName: Translation('event.common.name'),
          renderData: (row) => row.name[locale] || '-',
        },
        {
          keyIndex: 'category',
          displayName: Translation('event.common.category'),
          renderData: (row) => row.category.name[locale] || '-',
        },
        {
          keyIndex: 'accessibility',
          displayName: Translation('event.common.accessibility'),
          renderData: (row) => Translation(`event.common.accessibility.${row.category.accessibility.toLowerCase()}`),
        },
        {
          keyIndex: 'startTime',
          displayName: Translation('event.form.event_start_time'),
          renderData: (row) => getDefaultDisplayDate(row.startTime, 'datetime'),
          sortable: true,
          replaceSortState: true,
          onSort: (sort) => onSort({ key: 'startTime', value: sort['startTime'] }),
        },
        {
          keyIndex: 'endTime',
          displayName: Translation('event.form.event_end_time'),
          renderData: (row) => getDefaultDisplayDate(row.endTime, 'datetime'),
          sortable: true,
          replaceSortState: true,
          onSort: (sort) => onSort({ key: 'endTime', value: sort['endTime'] }),
        },
        {
          keyIndex: 'regStartDate',
          displayName: Translation('event.form.registration_start_time'),
          renderData: (row) => getDefaultDisplayDate(row.regStartDate, 'datetime'),
          sortable: true,
          replaceSortState: true,
          onSort: (sort) => onSort({ key: 'regStartDate', value: sort['regStartDate'] }),
        },
        {
          keyIndex: 'regEndDate',
          displayName: Translation('event.form.registration_end_time'),
          renderData: (row) => getDefaultDisplayDate(row.regEndDate, 'datetime'),
          sortable: true,
          replaceSortState: true,
          onSort: (sort) => onSort({ key: 'regEndDate', value: sort['regEndDate'] }),
        },
        {
          keyIndex: 'createdBy',
          displayName: Translation('event.common.created_by'),
          renderData: (row) => row.createdBy || '-',
        },
        {
          keyIndex: 'lastUpdatedBy',
          displayName: Translation('event.common.last_updated_by'),
          renderData: (row) => row.lastUpdatedBy || '-',
        },
        {
          keyIndex: 'updatedAt',
          displayName: Translation('component.formLabel.last-updated-time'),
          renderData: (row) => getDefaultDisplayDate(row.updatedAt, 'datetime'),
          sortable: true,
          replaceSortState: true,
          onSort: (sort) => onSort({ key: 'updatedAt', value: sort['updatedAt'] }),
        },
        {
          keyIndex: 'eventStatus',
          displayName: Translation('title.event.event.status'),
          renderData: (row) => Translation(`event.common.event_status.${row.eventStatus.toLowerCase()}`),
        },
        {
          keyIndex: 'publishStatus',
          displayName: Translation('component.formLabel.publish-status'),
          renderData: (row) => Translation(`event.common.publish_status.${row.publishStatus.toLowerCase()}`),
        },
      ]}
      isLoading={isLoading}
      onRefresh={onRefresh}
      dataSource={eventList?.docs}
      defaultRowsPerPage={20}
      totalPages={eventList?.totalPages}
      totalRecords={eventList?.totalDocs}
      onChangePage={onChangePage}
    />
  );
};
