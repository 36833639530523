import { makeStyles } from 'tss-react/mui';

export const LEFT_SIDE_WIDTH = 200;
export const FIELD_CONTAINER_SPACING = 10;

export const useRadioStyles = makeStyles()(() => ({
  fieldContainer: {
    marginBottom: FIELD_CONTAINER_SPACING,
  },
  radioGroupContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  radioGroup: {
    flexDirection: 'row',
  },
  radioGroupLabel: {
    width: LEFT_SIDE_WIDTH,
    color: '#3F4254',
  },
  rightSideMargin: {
    marginLeft: LEFT_SIDE_WIDTH,
  },
  mandatory: {
    color: 'red',
  },
}));
