import React, { ComponentType, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from 'tss-react/mui';

interface WithCloseButtonProps {
  onClose?: () => void;
  url?: string;
}

const withCloseButton = <P extends { [x: string]: unknown }>(
  WrappedComponent: ComponentType<P & WithCloseButtonProps>,
) => {
  const WithCloseButton: React.FC<P & WithCloseButtonProps> = (props) => {
    const { classes } = useStyles();

    const className = props.url ? classes.imageCloseButton : classes.closeButton;
    return (
      <>
        <WrappedComponent {...props} />
        {props.onClose && <CloseIcon className={className} onClick={props.onClose} />}
      </>
    );
  };

  return WithCloseButton;
};

const useStyles = makeStyles()((theme) => ({
  closeButton: {
    marginLeft: '5px',
    verticalAlign: 'middle',
    cursor: 'pointer',
  },
  imageCloseButton: {
    // display: 'flex',
    // position: 'absolute',
    // position: 'fixed',
    position: 'static',

    right: 0,
    top: 0,
  },
}));

export default withCloseButton;
