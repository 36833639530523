import { FC, ChangeEvent } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { IconButton, Tooltip } from '@mui/material';
import { Info, FiberManualRecord } from '@mui/icons-material';
import { useServerLocale } from 'src/app/i18n';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import { PaginateList } from 'src/app/common/types';
import { getDefaultDisplayDate, getFullName } from 'src/app/common/utils';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import AntSwitch from 'src/app/common/components/AntSwitch';
import {
  SecretaryStatusEnum,
  SecretaryStatusReasonEnum,
  UpdateSecretaryActionEnum,
  SecretaryTypeItem,
  SecretaryListItem,
  SecretaryAuthPolicyItem,
} from '../../../types/secretary-types';
import { UpdateSecretaryBody, modifySecretaryItem } from '../../../network/secretaryCrud';
import { secretaryPath } from '../../SecretaryRoutes';

type SecretaryListProps = {
  isLoading: boolean;
  secretaryList?: PaginateList<SecretaryListItem>;
  secretaryTypes: SecretaryTypeItem[];
  secretaryAuthPolicy?: SecretaryAuthPolicyItem;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
  onSort: (newSortState: { key: string; value?: string }) => void;
};

const useStyles = makeStyles()((theme) => ({
  tooltip: {
    maxWidth: 450,
  },
  statusContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const disableStatusConditions = [
  SecretaryStatusReasonEnum.APPLICATION_PENDING,
  SecretaryStatusReasonEnum.APPLICATION_REJECTED,
  SecretaryStatusReasonEnum.AGENT_LEADER_TERMINATED,
  SecretaryStatusReasonEnum.AGENT_LEADER_DEMOTED,
];

const SecretaryList: FC<SecretaryListProps> = ({
  isLoading,
  secretaryList,
  secretaryTypes,
  secretaryAuthPolicy,
  onRefresh,
  onChangePage,
  onSort,
}) => {
  const { classes } = useStyles();
  const history = useHistory();
  const intl = useIntl();
  const Translation = (id: string, params?: Record<string, string | number>) => intl.formatMessage({ id }, params);
  const dispatch = useDispatch();
  const locale = useServerLocale();

  const handleChangeStatus = async (e: ChangeEvent<HTMLInputElement>, row: SecretaryListItem) => {
    const checked = e.target.checked;
    try {
      const body: UpdateSecretaryBody = {
        action: checked ? UpdateSecretaryActionEnum.ACTIVATED : UpdateSecretaryActionEnum.DEACTIVATED,
      };
      await modifySecretaryItem(row.id, body, dispatch, {
        name: secretaryTypes.find((item) => item.code === row.type)?.name || row.type.toUpperCase(),
      });
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Account ${checked ? 'activated' : 'deactivated'}`,
          },
        ]),
      );
      onRefresh();
    } catch (err) {}
  };

  return (
    <>
      <PruTable
        title={Translation('secretary.list')}
        disableBulkSelect
        operationDef={[
          {
            title: Translation('section.common.operation.view_details'),
            tooltipText: 'Edit Lead Source',
            onClick: (row) => history.push(`${secretaryPath}/edit/${row.id}`),
          },
        ]}
        columnDef={[
          {
            isId: true,
            hidden: true,
            keyIndex: 'id',
            displayName: '',
            renderData: () => '',
          },
          {
            keyIndex: 'name',
            displayName: Translation('secretary.common.secretary_name'),
            renderData: (row) => getFullName(row.firstName, row.lastName),
            style: { minWidth: 140 },
          },
          {
            keyIndex: 'secretaryId',
            align: 'center',
            displayName: Translation('secretary.common.pruforce_id'),
            renderData: (row) => row.secretaryId || '-',
          },
          {
            keyIndex: 'type',
            align: 'center',
            displayName: Translation('secretary.common.access_type'),
            renderData: (row) => secretaryTypes.find((item) => item.code === row.type)?.name || row.type || '-',
            style: { minWidth: 140 },
          },
          {
            keyIndex: 'agentCode',
            align: 'center',
            displayName: Translation('secretary.common.agent_leader_code'),
            renderData: (row) => row.agentCode || '-',
            style: { minWidth: 120 },
          },
          {
            keyIndex: 'agentOfficeCode',
            align: 'center',
            displayName: Translation('secretary.common.agent_leader_office_code'),
            renderData: (row) => row.agentOfficeCode || '-',
            style: { minWidth: 120 },
          },
          {
            keyIndex: 'agentName',
            align: 'center',
            displayName: Translation('secretary.common.agent_leader_name'),
            renderData: (row) => row.agentName[locale] || '-',
            style: { minWidth: 120 },
          },
          {
            keyIndex: 'agentStatus',
            align: 'center',
            displayName: Translation('secretary.common.agent_leader_status'),
            renderData: (row) =>
              row.agentStatus ? Translation(`secretary.common.agent_leader_status.${row.agentStatus}`) : '-',
            style: { minWidth: 120 },
          },
          {
            keyIndex: 'createdAt',
            align: 'center',
            displayName: Translation('secretary.common.application_date'),
            renderData: (row) => getDefaultDisplayDate(row.createdAt),
            sortable: true,
            replaceSortState: true,
            onSort: (sort) => onSort({ key: 'createdAt', value: sort['createdAt'] }),
          },
          {
            keyIndex: 'decisionDate',
            align: 'center',
            displayName: Translation('secretary.common.decision_date'),
            renderData: (row) => getDefaultDisplayDate(row.decisionDate),
            sortable: true,
            replaceSortState: true,
            onSort: (sort) => onSort({ key: 'decisionDate', value: sort['decisionDate'] }),
          },
          {
            keyIndex: 'lastLoginDate',
            align: 'center',
            displayName: Translation('secretary.common.last_login_date'),
            renderData: (row) => getDefaultDisplayDate(row.lastLoginDate),
            sortable: true,
            replaceSortState: true,
            onSort: (sort) => onSort({ key: 'lastLoginDate', value: sort['lastLoginDate'] }),
            style: { minWidth: 120 },
          },
          {
            keyIndex: 'updatedAt',
            align: 'center',
            displayName: Translation('secretary.common.last_modified_date'),
            renderData: (row) => getDefaultDisplayDate(row.updatedAt),
            sortable: true,
            replaceSortState: true,
            onSort: (sort) => onSort({ key: 'updatedAt', value: sort['updatedAt'] }),
            style: { minWidth: 140 },
          },
          {
            keyIndex: 'lastUpdatedBy',
            align: 'center',
            displayName: Translation('secretary.common.modified_by'),
            renderData: (row) => row.lastUpdatedBy || '-',
          },
          {
            keyIndex: 'secretaryStatus',
            align: 'center',
            displayName: (
              <span>
                {Translation('secretary.common.status')}
                <Tooltip
                  classes={{ tooltip: classes.tooltip }}
                  arrow
                  title={
                    <ul style={{ listStyle: 'inside' }}>
                      {secretaryTypes.map((item) => (
                        <li key={`status-tooltip-${item.code}`}>
                          {Translation('secretary.common.status.tooltip.point1', {
                            name: item.name.toLowerCase(),
                            num: item.maxNumberOfActive,
                          })}
                        </li>
                      ))}
                      {secretaryAuthPolicy?.loginPolicy.maxDaysWithoutLogin && (
                        <li>
                          {Translation('secretary.common.status.tooltip.point2', {
                            num: secretaryAuthPolicy.loginPolicy.maxDaysWithoutLogin,
                          })}
                        </li>
                      )}
                    </ul>
                  }
                >
                  <IconButton size={'small'} style={{ padding: 5, color: 'white' }}>
                    <Info />
                  </IconButton>
                </Tooltip>
              </span>
            ),
            renderData: (row) => (
              <div className={classes.statusContainer}>
                <div>
                  <AntSwitch
                    color="primary"
                    disabled={disableStatusConditions.includes(row.statusReason)}
                    checked={row.secretaryStatus === SecretaryStatusEnum.ACTIVE}
                    onChange={(e) => handleChangeStatus(e, row)}
                  />
                </div>
                <div>{Translation(`secretary.common.secretary_status.${row.secretaryStatus}`)}</div>
              </div>
            ),
            style: { minWidth: 100 },
          },
          {
            keyIndex: 'statusReason',
            align: 'center',
            displayName: Translation('secretary.common.status_reason'),
            renderData: (row) =>
              row.statusReason ? (
                <span>
                  {row.statusReason === SecretaryStatusReasonEnum.APPLICATION_PENDING && (
                    <FiberManualRecord style={{ color: '#E8192C', fontSize: 8, marginRight: 8 }} />
                  )}
                  {Translation(`secretary.common.status_reason.${row.statusReason}`)}
                </span>
              ) : (
                '-'
              ),
            style: { minWidth: 220 },
          },
        ]}
        isLoading={isLoading}
        onRefresh={onRefresh}
        dataSource={secretaryList?.docs}
        defaultRowsPerPage={20}
        totalPages={secretaryList?.totalPages}
        totalRecords={secretaryList?.totalDocs}
        onChangePage={onChangePage}
      />
    </>
  );
};

export default SecretaryList;
