import { makeStyles } from 'tss-react/mui';
import { Button, DialogActions, DialogContent, DialogContentText, TextField } from '@mui/material';
import React from 'react';
import Form from 'src/app/common/components/Form';
import { getConvertedClasses } from 'src/app/common/helpers/tw-convert-helpers';
import { Translation } from 'src/app/i18n';

export interface ConfirmModalProps {
  onClose?: Function;
  onConfirm?: (data?: any) => void;
  confirmText?: string;
  cancelText?: string;
}

const useStyles = makeStyles()((theme) => ({
  textArea: {
    lineHeight: 1.5,
    minHeight: 250,
  },
}));
export const ConfirmModalWithReasonView = (props: ConfirmModalProps) => {
  const { onClose, onConfirm, confirmText, cancelText } = props;
  const { classes } = useStyles();
  const [form] = Form.useForm();
  const DEFAULT_REQUIRED_RULES = {
    required: true,
    message: Translation('component.pulseleads.prospect.form-required-text'),
  };
  const placeEnter = Translation('app.input.placeholder.please-enter');
  return (
    <>
      <Form form={form} className={`add-form-wraper form-wraper`} initialValues={{}}>
        <DialogContent
          style={{
            padding: 0,
          }}
        >
          <span>
            {Translation('agencyCampaign.approval.comment')}
            <span className="tw-text-red-500">*</span>
          </span>

          <Form.Item name={'reason'} rules={[DEFAULT_REQUIRED_RULES]}>
            <TextField
              type="text"
              rows={10}
              multiline
              InputProps={{
                classes: {
                  input: classes.textArea,
                },
              }}
              margin="dense"
              variant="outlined"
              fullWidth
              placeholder={placeEnter}
            />
          </Form.Item>
        </DialogContent>
        <div
          style={{
            padding: 0,
            marginTop: 25,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Button
            style={{ width: '100%' }}
            type="submit"
            onClick={async () => {
              await form.validateFields();
              onConfirm?.(form.getFieldsValue(true));
              onClose?.();
            }}
            variant="contained"
            color="secondary"
            autoFocus
          >
            {confirmText ?? Translation('app.button.confirm')}
          </Button>
          {/* <button className="tw-w-full tw-mt-12 tw-bg-white tw-shadow shad">11111</button> */}
          <Button
            style={getConvertedClasses('w-full mt-4 bg-white shadow')}
            onClick={() => onClose?.()}
            variant="outlined"
            color="inherit"
          >
            {cancelText ?? Translation('app.button.cancel')}
          </Button>
        </div>
      </Form>
    </>
  );
};
