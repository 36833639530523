import React, { FC, useState, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Button, CircularProgress } from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { LayoutSplashScreen } from 'src/app/layout';
import commonStyles from 'src/app/common/styles/page.module.css';
import { useStyles } from './survey-result.style';
import { useSurveyResult } from './survey-result.hook';
import SurveyAnalytics from 'src/app/common/components/Survey/SurveyAnalytics';
import { useParams } from 'react-router-dom';
import SurveySummary from 'src/app/common/components/Survey/SurveySummary';

type PageProps = ParamsProps &
  RouteComponentProps & {
    /* otherProp: string */
  };

export const SurveyResultPage: React.FC<PageProps> = (props: PageProps) => {
  const { id } = useParams<{ id: string }>();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  // custom-hook
  const { isLoading, surveyBody, surveyResults, surveySummaries, isLoadingAnswers } = useSurveyResult({ id: id });

  return (
    <div className={classes.dashboardContainer}>
      <div className={commonStyles.headerContainer}>
        <div className={commonStyles.rowContainer}>
          <div className={commonStyles.header}>{Translation('survey.viewResults')}</div>
        </div>
        <Button variant="contained" color="inherit" onClick={props.history.goBack}>
          {Translation('app.button.back')}
        </Button>
      </div>
      {isLoading ? (
        <LayoutSplashScreen />
      ) : surveyBody ? (
        <>
          <SurveySummary summary={surveySummaries} />
          {isLoadingAnswers ? (
            <div className={classes.loadingContainer}>
              <div className={classes.loadingBackground}>
                <CircularProgress color="secondary" size={32} thickness={5} />
                <div className={classes.loadingText}>{Translation('survey.loading_dashboard_text')}</div>
              </div>
            </div>
          ) : (
            <SurveyAnalytics
              questions={surveyBody}
              results={surveyResults}
              options={{
                haveCommercialLicense: true,
                hideInvisibleQuestion: true,
              }}
            />
          )}
        </>
      ) : null}
    </div>
  );
};
