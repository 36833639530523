import React from 'react';
import { getDefaultDisplayDate } from 'src/app/common/utils';
import { regionLocale } from 'src/app/i18n';

interface tableConfigType {
  title: string;
  prop: string;
  align?: any;
  render?: any;
  minWidth?: number;
  sorter?: boolean | false;
}

const getPropFromI18nData = (locale: string, row: any, prop: string) => {
  const data = row?.i18nData?.find((item: any) => {
    // return item.lang === locale;
    return item.locale === locale;
  });

  return data?.[prop];
};
const i18ConfigData = regionLocale;

const tableConfig = (Translation: any, locale: any): tableConfigType[] => {
  if (i18ConfigData.length === 0) {
    return [];
  }

  return [
    {
      title: `${Translation('component.formLabel.category')} (${Translation(`navBar.lang.${i18ConfigData[0]}`)})`,
      prop: `i18n_name_${i18ConfigData[0]}`,
      sorter: true,
      render: (row: any) => <span>{getPropFromI18nData(i18ConfigData[0], row, 'name')}</span>,
    },
    {
      title: `${Translation('component.formLabel.category')} (${Translation(`navBar.lang.${i18ConfigData[1]}`)})`,
      prop: `i18n_name_${i18ConfigData[1]}`,
      sorter: true,
      render: (row: any) => <span>{getPropFromI18nData(i18ConfigData[1], row, 'name')}</span>,
    },
    {
      title: Translation('title.event.category.table.header.description'),
      prop: 'description',
    },
    {
      title: Translation('title.event.category.table.header.weight'),
      prop: 'weight',
      sorter: true,
    },
    {
      title: Translation('title.event.category.table.header.status'),
      sorter: true,
      prop: 'status',
      render: (row: any) => (
        <span>
          {row.status === 'active'
            ? Translation('component.formSelectItem.active')
            : Translation('component.formSelectItem.inactive')}
        </span>
      ),
    },
    {
      title: Translation('component.formLabel.last-updated-time'),
      sorter: true,
      // prop: 'modifyTime',
      prop: 'updatedAt',
      render: (row: any) => <span>{getDefaultDisplayDate(row.updatedAt, 'datetime')}</span>,
    },
    {
      title: Translation('component.formLabel.last-updated-by'),
      prop: 'updatedBy',
      render: (row: any) => <span>{row.updatedBy || row.createdBy}</span>,
    },
  ];
};

export default tableConfig;
