import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import { Dispatch } from 'react';

export type RedistributeLeadBody = {
  leadRawIds: string[];
};

export type RedistributeAllLeadBody = {
  status?: string;
  campaignId?: string;
  fromDate?: string;
  toDate?: string;
  distributionStatus?: string[];
  isCustomerDedupApiConnectionFail?: boolean;
};

export const getList = async (dto: any, dispatch?: Dispatch<any>) => {
  try {
    return apiClient.get(`${ADMIN_URL}/leads-not-distributed`, { params: dto });
  } catch (err: any) {
    throw apiErrorHandler(err, dispatch);
  }
};

export const getOne = async (id: string, dispatch?: Dispatch<any>) => {
  try {
    return apiClient.get(`${ADMIN_URL}/leads-not-distributed/${id}`);
  } catch (err: any) {
    throw apiErrorHandler(err, dispatch);
  }
};

export const isAgentIdExisting = async (id: string, dispatch?: Dispatch<any>) => {
  try {
    return apiClient.get(`${ADMIN_URL}/leads-not-distributed/is-agent-existing/${id}`);
  } catch (err: any) {
    throw apiErrorHandler(err, dispatch);
  }
};

export const batchUpdate = async (body: any, dispatch?: Dispatch<any>) => {
  try {
    return apiClient.patch(`${ADMIN_URL}/leads-not-distributed`, {
      ...body,
    });
  } catch (err: any) {
    throw apiErrorHandler(err, dispatch);
  }
};

export const batchUpdateAgentCode = async (data: any, dispatch?: Dispatch<any>) => {
  try {
    return apiClient.post(`${ADMIN_URL}/leads-not-distributed/manualRedistribution`, data);
  } catch (err: any) {
    throw apiErrorHandler(err, dispatch);
  }
};

export const batchUpdateLeadStatus = async (data: any, dispatch?: Dispatch<any>) => {
  try {
    return apiClient.patch(`${ADMIN_URL}/leads-not-distributed/batch-update-lead-status`, data);
  } catch (err: any) {
    throw apiErrorHandler(err, dispatch);
  }
};

export const redistributeLead = async (body: RedistributeLeadBody, dispatch?: Dispatch<any>) => {
  return apiClient
    .patch<void>(`${ADMIN_URL}/leads-not-distributed/redistributeLead`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const redistributeAllLead = async (body: RedistributeAllLeadBody, dispatch?: Dispatch<any>) => {
  return apiClient
    .patch<void>(`${ADMIN_URL}/leads-not-distributed/redistributeAllLead`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const batchDelete = async (body: any, dispatch?: Dispatch<any>) => {
  try {
    return apiClient.delete(`${ADMIN_URL}/leads-not-distributed`, {
      data: body,
    });
  } catch (err: any) {
    throw apiErrorHandler(err, dispatch);
  }
};
export const exportCSV = async (dto: any, dispatch?: Dispatch<any>): Promise<string> => {
  delete dto.page;
  delete dto.limit;
  apiClient.defaults.timeout = 240000;

  return apiClient
    .get(`${ADMIN_URL}/leads-not-distributed/export-csv`, { params: dto })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    })
    .finally(() => {
      apiClient.defaults.timeout = 60000;
    });
};
