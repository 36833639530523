import { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useLang } from 'src/app/i18n';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { FormMode } from 'src/app/common/types';
import { StepStatusEnum } from 'src/app/common/components/pru-stepped-form';
import {
  EventPublishStatusEnum,
  RegistrationMethodEnum,
  EventCategoryItem,
  EventItem,
  EventTagItem,
} from '../../../types';
import { fetchEventCategoryList, fetchEventItem, fetchEventTagList } from '../../../network';
import { eventListPath } from '../event-list-routes';
import { isNowAtLeastOneDayBefore, validateRegAllowWalkIn } from './common';

export enum ComponentEnum {
  BASICS = 'basics',
  DETAILS = 'details',
  REGISTRATION_ATTENDANCE = 'registration_attendance',
  REGISTRATION_FORM = 'registration_form',
  WALK_IN_FORM = 'walk_in_form',
  PUBLISH_SETTING = 'publish_setting',
}

export const useEventDetailPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const locale = useLang();
  const { id } = useParams<{ id?: string }>();
  const { formMode } = useMemo(() => {
    let formMode: FormMode = FormMode.CREATE;
    if (id) {
      if (location.pathname.includes('/view')) {
        formMode = FormMode.VIEW;
      } else if (location.pathname.includes('/edit')) {
        formMode = FormMode.EDIT;
      }
    }
    return { formMode };
  }, [id]);

  const [initialFormMode, setInitialFormMode] = useState<FormMode>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [changesUnsaved, setChangesUnsaved] = useState<boolean>(false);
  const [isReset, setIsReset] = useState<boolean>(false);
  const [eventCategoryList, setEventCategoryList] = useState<EventCategoryItem[]>([]);
  const [eventTagList, setEventTagList] = useState<EventTagItem[]>([]);
  const [eventItem, setEventItem] = useState<EventItem>();

  const showLayoutSplashScreen =
    !eventCategoryList || !eventTagList || (formMode === initialFormMode && formMode !== FormMode.CREATE && !eventItem);

  const isPublished = eventItem?.publishStatus === EventPublishStatusEnum.PUBLISHED;
  // for fields that are editable after published and before event date
  const disableEdit = useMemo(() => {
    if (eventItem) {
      if (isPublished) {
        return !isNowAtLeastOneDayBefore(eventItem.eventDate);
      } else if (eventItem.publishStatus === EventPublishStatusEnum.UNPUBLISHED) {
        return true;
      }
    }
    return false;
  }, [eventItem?.publishStatus, eventItem?.eventDate]);

  const walkInAllowed = useMemo(() => {
    if (eventItem && eventItem.regMethod === RegistrationMethodEnum.TEMPLATE) {
      return (
        eventItem.regAllowWalkIn ||
        eventItem.sessions.some(
          (session) => session.regAllowWalkIn || session.subSessions.some((subSession) => subSession.regAllowWalkIn),
        )
      );
    }
    return false;
  }, [eventItem?.regMethod, eventItem?.regAllowWalkIn, eventItem?.sessions]);

  const reloadEventCategoryList = async () => {
    try {
      const res = await fetchEventCategoryList({ status: 'ACTIVE', pagination: false }, dispatch);
      setEventCategoryList(res.docs);
    } catch (err) {}
  };

  const reloadEventTagList = async () => {
    try {
      const res = await fetchEventTagList({ locale, pagination: false }, dispatch);
      setEventTagList(res.docs);
    } catch (err) {}
  };

  const reloadData = async () => {
    setIsLoading(true);
    await reloadEventCategoryList();
    await reloadEventTagList();
    if (id) {
      try {
        const res = await fetchEventItem(id, dispatch);
        setEventItem(res);
      } catch (err) {
        history.push(NOT_FOUND_ERROR_PAGE_PATH);
      }
    }
    setIsLoading(false);
  };

  const getDefaultStatus = (component: ComponentEnum) => {
    if (eventItem) {
      // check the first mandatory field
      switch (component) {
        case ComponentEnum.BASICS:
          return StepStatusEnum.FINISHED;
        case ComponentEnum.DETAILS:
          return eventItem.eventImage ? StepStatusEnum.FINISHED : undefined;
        case ComponentEnum.REGISTRATION_ATTENDANCE:
          return eventItem.regStartDate && validateRegAllowWalkIn(eventItem) ? StepStatusEnum.FINISHED : undefined;
        case ComponentEnum.REGISTRATION_FORM:
          return eventItem.regFormBody ? StepStatusEnum.FINISHED : undefined;
        case ComponentEnum.WALK_IN_FORM:
          return eventItem.walkInFormBody ? StepStatusEnum.FINISHED : undefined;
        case ComponentEnum.PUBLISH_SETTING:
          return eventItem.pubAgent ? StepStatusEnum.FINISHED : undefined;
        default:
          return undefined;
      }
    }
    return undefined;
  };

  const onBack = () => {
    history.push(eventListPath);
  };

  useEffect(() => {
    reloadData();
  }, [id]);

  useEffect(() => {
    if (!initialFormMode) {
      setInitialFormMode(formMode);
    }
  }, [formMode, initialFormMode]);

  return {
    formMode,
    isLoading,
    changesUnsaved,
    isReset,
    isPublished,
    disableEdit,
    walkInAllowed,
    eventCategoryList,
    eventTagList,
    eventItem,
    showLayoutSplashScreen,
    setIsLoading,
    setChangesUnsaved,
    setIsReset,
    reloadData,
    getDefaultStatus,
    onBack,
  };
};
