import React from 'react';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import TaskGroupTable from './components/TaskGroupTable';

type TaskGroupListProps = ParamsProps;

export const toDoListTaskGroupFilterKeys = [];

const TaskGroupListPage = ({}: TaskGroupListProps) => {
  return (
    <>
      <TaskGroupTable />
    </>
  );
};

export default TaskGroupListPage;
