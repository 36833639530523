export const templateFileData = [
  [
    'Candidate ID',
    'Candidate Name',
    'Exam Type',
    'Exam Code',
    'Exam Name (Local Language)',
    'Exam Name (English)',
    'Exam Date',
    'Start Time',
    'End Time',
    'Exam Centre',
    'Present',
    'Result',
    'Score',
  ],
  [
    '33981232',
    'Eddie Peng',
    'S',
    'X1234',
    'AASI Paper 1',
    'AASI Paper 1',
    '19/08/2023',
    '09:30',
    '11:30',
    'Jakarta Exam Centre',
    'Yes',
    'PASSED',
    '70',
  ],
  [
    '92334355',
    'Mei Yuk Chan',
    'F',
    'E1234',
    'AASI Paper 1',
    'AAJI Paper 1',
    '19/08/2023',
    '14:30',
    '17:30',
    'Jakarta Exam Centre',
    'No',
    'NA',
    'NA',
  ],
];
