import { TextField, Button } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { styles, useStyles } from '../../style';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import CloseIcon from '@mui/icons-material/Close';
import { MTmode } from '../../../../../constants';
import Form from 'src/app/common/components/Form';
import Tinymce from 'src/app/common/components/Tinymce-storage-blob';
import { regionLocale } from 'src/app/i18n';
import BannerSetting from './banner-settting';
import { FormSectionProps } from 'src/app/modules/lead-microsite/types/microsite.type';

const ContentSection: FC<FormSectionProps> = (props) => {
  const { form, formMode } = props;
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const DEFAULT_REQUIRED_RULES = { required: true, message: Translation('component.form-required-text') };
  const isView = formMode === MTmode.VIEW;
  const [data, setData] = useState(form.getFieldsValue(true));
  useEffect(() => {
    if (form.getFieldsValue(true) !== data) {
      setData(form.getFieldsValue(true));
    }
  }, [form.getFieldsValue(true)]);

  const handleChange = (value: string, key: string, language: string) => {
    const { ...body } = form.getFieldsValue(true);
    if (!body[key]) {
      body[key] = {};
    }
    body[key][language] = value;
    form.setFieldsValue(body);
    setData(body);
  };

  const addTnc = () => {
    const newTnc = form.getFieldValue('customerDisclaimer') ?? [];
    const result: any = {};
    regionLocale.forEach((lang) => {
      result[lang] = undefined;
    });
    newTnc.push(result);
    setData({ ...data, customerDisclaimer: newTnc });
    form.setFieldsValue({ customerDisclaimer: newTnc });
  };

  const TncForm = (index: number, regionLocale: string[]) => {
    const tncMatchRule = [
      {
        validator(_: any, data: any) {
          if (!data) {
            return Promise.resolve();
          }
          return Promise.resolve();
        },
      },
    ];
    const handleDelete = () => {
      if (!isView) {
        const { ...body } = form.getFieldsValue(true);
        body.customerDisclaimer.splice(index, 1);
        form.setFieldsValue(body);
        setData(body);
      }
    };

    const handleTncChange = (value: string, index: number, language: string) => {
      const { ...body } = form.getFieldsValue(true);
      body.customerDisclaimer[index][language] = value;
      form.setFieldsValue(body);
      setData(body);
    };

    return regionLocale?.map((language, lIndex) => {
      return (
        <div className={classes.rowContainer} key={language}>
          <div className={classes.titleArea}>
            <span>
              {Translation('survey.form.tnc')}
              {index + 1} :{`(${language.toLocaleUpperCase()})`}
            </span>
          </div>

          <div className={classes.flexRowFull}>
            <Form.Item name={['customerDisclaimer', index, language]} rules={tncMatchRule}>
              <TextField
                disabled={isView}
                variant="outlined"
                fullWidth
                margin="dense"
                value={data?.customerDisclaimer?.[index]?.[language] ?? ''}
                onChange={(e) => {
                  handleTncChange(e.target.value, index, language);
                }}
              />
            </Form.Item>

            <CloseIcon className={classes.formCloseIcon} onClick={handleDelete} />

            {lIndex !== 0 && (
              <Button
                size="small"
                variant="text"
                onClick={() => {
                  if (!isView) {
                    handleTncChange(
                      form.getFieldValue(['customerDisclaimer', index, `${regionLocale[0]}`]),
                      index,
                      language,
                    );
                  }
                }}
                style={styles.FieldCopyText}
              >
                {Translation('agent_referral_link_copy')}
              </Button>
            )}
          </div>
        </div>
      );
    });
  };

  const TncReminder = () => {
    return (
      <>
        <div className={classes.TNC}>
          <li> {'Please input [ ] to define file link name and ( ) to define file URL.'}</li>
          <div className="tw-ml-6">
            Example: We use cookies to provide necessary website functionality, improve your experience and analyze our
            traffic.By using our website, you agree to our [privacy policy](http://www.google.com.hk) and our cookies
            usage.
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="tw-bg-white tw-pt-6 tw-pb-6 tw-mt-6">
      <div className="tw-flex">
        <div className={classes.subHeader}>{Translation('agent_referral_link_content_setting')}</div>
      </div>

      <BannerSetting form={form} formMode={formMode} />

      {regionLocale?.map((language, index) => {
        return (
          <div className={classes.contentTextField} key={language}>
            <Form.Item
              name={['title', language]}
              label={`${Translation('agent_referral_link_title')}(${language.toLocaleUpperCase()})`}
              rules={[DEFAULT_REQUIRED_RULES]}
              labelTop={true}
            >
              <div className={classes.flexCol}>
                <div className="tw-flex tw-flex-row tw-items-center">
                  <TextField
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    inputProps={{ maxLength: 80 }}
                    disabled={isView}
                    value={data?.title?.[language] ?? ''}
                    onChange={(e) => {
                      handleChange(e.target.value, 'title', language);
                    }}
                  />
                  {index !== 0 && (
                    <Button
                      size="small"
                      variant="text"
                      onClick={() => {
                        if (!isView) {
                          handleChange(form.getFieldValue(['title', `${regionLocale[0]}`]), 'title', language);
                        }
                      }}
                      style={styles.FieldCopyText}
                    >
                      {Translation('agent_referral_link_copy')}
                    </Button>
                  )}
                </div>
                <div className={classes.notifyFont}>{`80 ${Translation('agent_referral_link_char_limit')}`}</div>
              </div>
            </Form.Item>
          </div>
        );
      })}

      {regionLocale?.map((language, index) => {
        return (
          <div className={classes.contentTextField} key={language}>
            <Form.Item
              name={['subTitle', language]}
              label={`${Translation('agent_referral_link_subTitle')}(${language.toLocaleUpperCase()})`}
              required={false}
              labelTop={true}
            >
              <div className={classes.flexCol}>
                <div className="tw-flex tw-flex-row tw-items-center">
                  <TextField
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    inputProps={{ maxLength: 80 }}
                    disabled={isView}
                    value={data?.subTitle?.[language] ?? ''}
                    onChange={(e) => {
                      handleChange(e.target.value, 'subTitle', language);
                    }}
                  />
                  {index !== 0 && (
                    <Button
                      size="small"
                      variant="text"
                      onClick={() => {
                        if (!isView) {
                          handleChange(form.getFieldValue(['subTitle', `${regionLocale[0]}`]), 'subTitle', language);
                        }
                      }}
                      style={styles.FieldCopyText}
                    >
                      {Translation('agent_referral_link_copy')}
                    </Button>
                  )}
                </div>
                <div className={classes.notifyFont}>{`80 ${Translation('agent_referral_link_char_limit')}`}</div>
              </div>
            </Form.Item>
          </div>
        );
      })}

      {regionLocale?.map((language, index) => {
        return (
          <div className={classes.contentTextField} key={language}>
            <Form.Item
              name={['body', language]}
              label={`${Translation('agent_referral_link_body')}(${language.toLocaleUpperCase()})`}
              rules={[DEFAULT_REQUIRED_RULES]}
              style={index !== 0 ? styles.mt24 : {}}
            >
              <div className="tw-flex tw-flex-col tw-flex-1">
                <Tinymce
                  disabled={isView}
                  value={data?.body?.[language] ?? ''}
                  onChange={(e) => {
                    handleChange(e, 'body', language);
                  }}
                  module="agent-referral-link"
                />
                {index !== 0 && (
                  <Button
                    size="small"
                    variant="text"
                    onClick={() => {
                      if (!isView) {
                        handleChange(form.getFieldValue(['body', `${regionLocale[0]}`]), 'body', language);
                      }
                    }}
                    style={styles.FieldCopyText}
                  >
                    {Translation('agent_referral_link_copy')}
                  </Button>
                )}
              </div>
            </Form.Item>
          </div>
        );
      })}

      {/*Tncs*/}
      <div className={classes.flexRow}>
        <div className={classes.marginVertical_24}>
          <span className={classes.titleArea}>{Translation('agent_referral_link_customer_disclaimer')}</span>

          <span className={classes.ml_24}>
            <Button
              variant="contained"
              color="secondary"
              disabled={isView}
              className={classes.button}
              size="small"
              onClick={addTnc}
            >
              {Translation('app.button.addNew')}
            </Button>
          </span>
        </div>
      </div>
      <div className={classes.tncContainer}>
        <TncReminder />
      </div>

      <div className="tw-mb-3">
        {data?.customerDisclaimer?.map((tnc: string, index: number) => {
          return TncForm(index, regionLocale);
        })}
      </div>

      {regionLocale?.map((language, index) => {
        return (
          <div className={classes.CTAtext} key={language}>
            <Form.Item
              name={['callToActionText', language]}
              label={`${Translation('agent_referral_link_call_to_action')}(${language.toLocaleUpperCase()}):`}
              rules={[DEFAULT_REQUIRED_RULES]}
              labelTop={true}
            >
              <div className={classes.flexCol}>
                <div className="tw-flex tw-flex-row tw-items-center">
                  <TextField
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    inputProps={{ maxLength: 30 }}
                    disabled={isView}
                    value={data?.callToActionText?.[language] ?? ''}
                    onChange={(e) => {
                      handleChange(e.target.value, 'callToActionText', language);
                    }}
                    placeholder="I'm Ready"
                  />
                  {index !== 0 && (
                    <Button
                      size="small"
                      variant="text"
                      onClick={() => {
                        if (!isView) {
                          handleChange(
                            form.getFieldValue(['callToActionText', `${regionLocale[0]}`]),
                            'callToActionText',
                            language,
                          );
                        }
                      }}
                      style={styles.FieldCopyText}
                    >
                      {Translation('agent_referral_link_copy')}
                    </Button>
                  )}
                </div>
                <div className={classes.notifyFont}>{`30 ${Translation('agent_referral_link_char_limit')}`}</div>
              </div>
            </Form.Item>
          </div>
        );
      })}
    </div>
  );
};
export default ContentSection;
