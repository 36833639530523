import React, { FC } from "react";
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import PruRoute from 'src/app/common/components/PruRoute';
import { PULSELEADS_BASE_PATH } from '../../../constants';
import { PULSELEADS_AGENT_PATH, PULSELEADS_AGENT_REASSIGN_LEAD_PATH } from '../constants';
import ReassignLeadPage from './Form/ReassignLeadPage';

export const reassignLeadPath = `${PULSELEADS_BASE_PATH}${PULSELEADS_AGENT_PATH}${PULSELEADS_AGENT_REASSIGN_LEAD_PATH}`;

const ReassignLeadRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <PruRoute exact path={`${reassignLeadPath}`} component={ReassignLeadPage}/>
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default ReassignLeadRoutes;