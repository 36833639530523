import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import PruRoute from 'src/app/common/components/PruRoute';
import { PULSELEADS_BASE_PATH } from '../../../constants';
import { PULSELEADS_BULKUPLOAD_PATH, PULSELEADS_BULKUPLOAD_STATUS_PATH, PULSELEADS_BULKUPLOAD_STATUS_PATH_STATUS_LIST } from '../constants';
import BulkUploadStatusListingPage from './List/BulkUploadStatusListingPage';
import BulkUploadStatusFailedListingPage from './FailedList/BulkUploadFailedListingPage';

export const statusPath = `${PULSELEADS_BASE_PATH}${PULSELEADS_BULKUPLOAD_PATH}${PULSELEADS_BULKUPLOAD_STATUS_PATH}${PULSELEADS_BULKUPLOAD_STATUS_PATH_STATUS_LIST}`;

const BulkUploadStatusRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect exact from={statusPath} to={`${statusPath}/list`} />
      <PruRoute exact path={`${statusPath}/list`} component={BulkUploadStatusListingPage}/>
      <PruRoute exact path={`${statusPath}/:id/failed/list`} component={BulkUploadStatusFailedListingPage} />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  )
};

export default BulkUploadStatusRoutes;


