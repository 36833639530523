import { FC } from 'react';
import { useIntl } from 'react-intl';
import { useStyles } from '../../../style';
import { MTmode } from 'src/app/common/constants';
import { FormSectionProps } from '../../../type/types';
import TargetAgent from './target-agent';

const PublishSection: FC<FormSectionProps & { initialValues: any }> = (props) => {
  const { form, formMode, initialValues } = props;
  const isView = formMode === MTmode.VIEW;
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  return (
    <div className="tw-bg-white tw-pt-6 tw-pb-6 tw-mt-6">
      <div className="tw-flex">
        <div className={classes.subHeader}>{Translation('survey.form.publishSetting')}</div>
      </div>
      <div className={classes.ml_24}>
        <TargetAgent form={form} disabled={isView} isEdit={formMode === MTmode.EDIT} initialValues={initialValues} />
      </div>
    </div>
  );
};
export default PublishSection;
