import React, { useState, useEffect } from 'react';
import Form from 'src/app/common/components/Form';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { makeStyles } from 'tss-react/mui';
import { Backdrop, CircularProgress, Grid, Paper, Divider } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { regionLocale } from 'src/app/i18n';
import { getOne } from '../api';
import { getLeadsInfos } from './util';

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginTop: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
    paddingBottom: 0,
  },
  textArea: {
    lineHeight: 1.5,
    minHeight: 40,
  },
  backdrop: {
    position: 'fixed',
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.common.white,
    backgroundColor: `${theme.palette.common.white} !important`,
    opacity: '0.5 !important',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  title: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
    lineHeight: 1.5,
  },
}));

const Detail: React.FC<any> = (props) => {
  const { history } = props;
  const { classes } = useStyles();
  const { classes: commonClasses } = useCommonStyles();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const { id } = useParams<{ id: string }>();

  const [initialValues, setInitialValues] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchAPI = async () => {
      try {
        setLoading(true);
        await getDetails();
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchAPI();

    return;
    // eslint-disable-next-line
  }, []);

  const getDetails = async () => {
    const result = await getOne(id);
    setInitialValues(result.data);
  };

  return (
    <>
      <div className={classes.container}>
        <div className={commonClasses.header} style={{ marginBottom: 20 }}>
          {`Lead Info`}
        </div>

        <Grid container spacing={0}>
          {getLeadsInfos(initialValues).map((item: any) => {
            return (
              <Grid item xs={3}>
                <div className={classes.paper}>
                  <p className={classes.title}>{item.title}</p>
                  <p>{item.render ? item.render(item.key) : item.key}</p>
                </div>
              </Grid>
            );
          })}
        </Grid>
      </div>

      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="secondary" />
      </Backdrop>
    </>
  );
};

export default Detail;
