import React, { useState, useEffect } from 'react';
import Form from 'src/app/common/components/Form';
import { useIntl } from 'react-intl';
import { makeStyles } from 'tss-react/mui';
import {
  Button,
  TextField,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { ModulePermissionProps } from 'src/app/common/module/types';
import { RootState } from 'src/redux/store';
import { AuthenticationState } from 'src/app/modules/Auth/_redux/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { styles } from './style';
import { MTmode, ReceiverType, PublishStatus, publishingCode, publishingSameVersionCode } from './../../../constants';
import CollectionDetail from './CollectionDetail/index';
import RequiredTrainingCourses from './RequiredTrainingCourses/index';
import AutoReminder from './AutoReminder';
import PublishSettings from './PublishSettings';
import { regionLocale } from 'src/app/i18n';
import { addCollection, updateCollection, getCollectionDetail } from '../../../network/collectionDetailCrud';
import { initialReminderI18nObj } from '../../../util';
import { collectionBasePath } from '../Routes';
import { getCategoryList } from '../../Category/api';
import { getCategoryLabel } from '../../../util';

const Detail: React.FC<RouteComponentProps & ModulePermissionProps> = ({
  history,
  location,
  enableRead,
  enableCreate,
  enableUpdate,
  formMode,
}) => {
  const { classes } = styles();
  const { classes: commonClasses } = useCommonStyles();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const DEFAULT_REQUIRED_RULES = [{ required: true, message: Translation('component.form-required-text') }];
  const placeEnter = Translation('app.input.placeholder.please-enter');
  const [loading, setLoading] = useState<boolean>(false);
  const [allCategoryList, setAllCategoryList] = useState<{ [key: string]: any }>([]);
  const [formStatus, setFormStatus] = useState<string>('');
  const [initialValues, setInitialValues] = useState<any>({
    categoryId: '',
    // completionDeadline: {
    //     type: '',
    //     value: ''
    // },
    reminder: {
      enable: null,
      // frequency: ReminderFrequencyType.ONCE,
      i18nData: initialReminderI18nObj(Translation),
    },
    receiverType: ReceiverType.Segment,
    trainingSetTemplates: [
      {
        courses: [],
      },
    ],
  });
  const { id } = useParams<{ id: string }>();
  const { user } = useSelector<RootState, AuthenticationState>((state) => state.auth);
  const isView = formMode === MTmode.VIEW;
  const isCreate = formMode === MTmode.CREATE;
  const isEdit = formMode === MTmode.EDIT;
  const isCopy = formMode === MTmode.COPY;
  const disabled = isView;

  useEffect(() => {
    const fetchAPI = async () => {
      await initialCategoryList();
      if (!isCreate) {
        await fetchDataById();
      }
    };

    fetchAPI();

    return;
    // eslint-disable-next-line
  }, []);

  const initialCategoryList = async () => {
    const params = {
      // status: '',
      publishStatus: '1',
      pageNum: 0,
      pageSize: 99999,
    };

    let categoryList: any = [
      {
        label: Translation('app.input.placeholder.please-select'),
        value: '',
        status: '',
      },
    ];

    const res = await getCategoryList(params);

    let categories = res.data?.data;

    categories = categories.sort((a: any, b: any) => (b.weight || 0) - (a.weight || 0));

    categories &&
      categories.map((item: any) => {
        categoryList.push({
          label: getCategoryLabel(item),
          value: item.id,
          status: item.status,
        });
      });

    setAllCategoryList(categoryList);
  };

  const fetchDataById = async () => {
    setLoading(true);
    try {
      const result = await getCollectionDetail(id);
      setInitialValues(result.data);
      form.setFieldsValue(result.data);
      setFormStatus(result.data.publishStatus.toString());
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const onSubmit = (getFieldValue?: any) => {
    const status = form.getFieldValue('publishOption').toString();
    const isDraft = status === PublishStatus.Draft.key;
    const isUnpublish = status === PublishStatus.UnPublish.key;
    const isWaittingPublished = status === PublishStatus.WaitingPublish.key;

    if (isDraft || isUnpublish || isWaittingPublished) {
      submitAnyway();
      return;
    }

    form.submit();
  };

  const submitAnyway = () => {
    const validationList = regionLocale.map((localeItem: any, index: number) => {
      return ['i18nData', index, 'name'];
    });

    form
      .validateFields(validationList)
      .then((res) => {
        onSave();
      })
      .catch((err) => {
        const { errorFields } = err;
        if (errorFields.length <= 0) {
          onSave();
        }
      });

    return false;
  };

  const onSave = async () => {
    setLoading(true);
    const allValues = form.getFieldsValue(true);

    const { receiverType, targetIncludeFile, publishImmediately, completionDeadline, publishOption } = allValues;
    const option = publishOption.toString();
    const isDraft = option === PublishStatus.Draft.key;
    const isUnpublish = option === PublishStatus.UnPublish.key;
    const isWaittingPublished = option === PublishStatus.WaitingPublish.key;

    if (receiverType.toString() === ReceiverType.TargetReceiver) {
      allValues.includeFile = targetIncludeFile || null;
      allValues.excludeFile = null;
      allValues.designationSet = [];
    }

    if (publishImmediately) {
      delete allValues.publishedAt;
    }

    if (completionDeadline && !completionDeadline.type && completionDeadline.type !== 0) {
      delete allValues.completionDeadline;
    }

    if (isDraft || isUnpublish) {
      delete allValues.publishImmediately;
      delete allValues.publishedAt;
    }

    if (isWaittingPublished) {
      allValues.publishOption = '1';
    }

    if (allValues.publishedAt) {
      allValues.publishTime = allValues.publishedAt;
    }

    try {
      if (isCreate || isCopy) {
        await addCollection(allValues);
        sucessOperation();
      } else {
        delete allValues.publishStatus;
        try {
          await updateCollection(id, allValues);
          sucessOperation();
        } catch (error: any) {
          setLoading(false);
          const code = error?.errors?.code || '';

          if (code === publishingCode) {
            dispatch(
              appendAlertItem([
                {
                  severity: AlertType.WARNING,
                  title: 'Reminder',
                  content: 'The template is in publishing or unpublishing, retry operation later please',
                },
              ]),
            );
            return;
          }

          if (code === publishingSameVersionCode) {
            dispatch(
              appendAlertItem([
                {
                  severity: AlertType.WARNING,
                  title: 'Reminder',
                  content: 'Template has been modified, please refresh the page and try again',
                },
              ]),
            );
            return;
          }

          if (error?.code === 400) {
            const reason = error?.errors?.reason || '';
            dispatch(
              appendAlertItem([
                {
                  severity: AlertType.WARNING,
                  title: 'Reminder',
                  content: reason,
                },
              ]),
            );
          }
        }
      }
    } catch (error) {
      console.log(error);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.ERROR,
            title: 'Error',
            content: 'Error',
          },
        ]),
      );
    }

    setLoading(false);
  };

  const sucessOperation = () => {
    dispatch(
      appendAlertItem([
        {
          severity: AlertType.SUCCESS,
          title: 'Success',
          content: 'Update Mandatory Training Collection Successfully',
        },
      ]),
    );
    goBackToList();
  };

  const goBackToList = () => {
    history.push(collectionBasePath);
  };

  return (
    <div className={classes.container}>
      <div className={classes.divideMargin}>
        <span className={commonClasses.header}>{Translation('mandatory.training.component.title')}</span>
      </div>

      <Form
        form={form}
        className={`${classes.container} add-form-wraper form-wraper`}
        onFinish={onSave}
        initialValues={{ ...initialValues }}
        style={{ paddingBottom: 20 }}
      >
        <Form.Item noStyle>
          {(_, meta, { getFieldValue }) => {
            return (
              <>
                <CollectionDetail form={form} disabled={disabled} allCategoryList={allCategoryList} />
              </>
            );
          }}
        </Form.Item>

        <Form.Item noStyle>
          {(_, meta, { getFieldValue }) => {
            return (
              <div>
                <RequiredTrainingCourses form={form} disabled={disabled} />
              </div>
            );
          }}
        </Form.Item>

        <Form.Item noStyle>
          {(_, meta, { getFieldValue }) => {
            return (
              <div>
                <AutoReminder form={form} disabled={disabled} />
              </div>
            );
          }}
        </Form.Item>

        <Form.Item noStyle>
          {(_, meta, { getFieldValue }) => {
            return (
              <div>
                <PublishSettings
                  form={form}
                  disabled={disabled}
                  isCreate={isCreate}
                  formStatus={formStatus}
                  designationSource={initialValues.designationSet}
                />
              </div>
            );
          }}
        </Form.Item>

        <div>
          <Form.Item noStyle style={{ margin: 20 }}>
            <div style={{ marginBottom: 15, display: 'flex', justifyContent: 'flex-end' }}>
              {!disabled && (
                <Button
                  variant="contained"
                  color="inherit"
                  disabled={loading}
                  style={{ marginRight: 15 }}
                  onClick={goBackToList}
                >
                  {Translation('app.button.cancel')}
                </Button>
              )}

              {!disabled && (
                <Button
                  style={{ marginRight: 15 }}
                  variant="contained"
                  disabled={loading}
                  color="secondary"
                  onClick={() => {
                    onSubmit();
                  }}
                >
                  {Translation('app.button.submit')}
                </Button>
              )}

              {disabled && (
                <Button
                  variant="contained"
                  color="inherit"
                  disabled={loading}
                  style={{ marginRight: 15 }}
                  onClick={() => {
                    history.push(`${collectionBasePath}/edit/${id}`);
                  }}
                >
                  {Translation('app.button.edit')}
                </Button>
              )}

              {disabled && (
                <Button
                  style={{ marginRight: 15 }}
                  variant="contained"
                  disabled={loading}
                  color="secondary"
                  onClick={goBackToList}
                >
                  {Translation('app.button.back')}
                </Button>
              )}
            </div>
          </Form.Item>
        </div>
      </Form>

      <Backdrop className={commonClasses.backdrop} open={loading}>
        <CircularProgress color="secondary" />
      </Backdrop>
    </div>
  );
};

export default Detail;
