export const template = `//  [id:123] [tag:basic] [tag:set 1]
::MC -Single Choice::
What is the main cause of the seasons on the earth?  {
    ~A. the earth's elliptical (slightly oval) orbit around the sun # single opetaion feedback
    =B. the tilt of earth's axis with respect to the sun
    ~C. the northern hemisphere has twice the land mass than the southern hemisphere
    ####General question feekback
}

//  [id:234] [tag:basic] [tag:set 1]
::MC -Single Choice::
The largest desert on Earth is\: {
    =Antarctica #Chioce feedback
    ~Sahara Desert
    ~Australian Desert
    ~Arabian Desert
    ####The largest desert on Earth is feekback
}`;
