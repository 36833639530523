import { makeStyles } from 'tss-react/mui';
import { LEFT_SIDE_WIDTH, FIELD_CONTAINER_SPACING } from '../target-applicant-radio-mode.style';

export const useStyles = makeStyles()(() => ({
  presetGroupContainer: {
    width: 700,
    border: '1px solid #CCCCCC',
    marginBottom: FIELD_CONTAINER_SPACING,
    marginLeft: LEFT_SIDE_WIDTH,
    padding: 10,
  },
  optionsContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  option: {
    width: '20%',
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  errorText: {
    color: '#F018A6',
  },
  extraLabel: {
    minWidth: 160,
  },
  extraButton: {
    marginRight: 20,
  },
  linkMargin: {
    marginRight: 5,
  },
}));
