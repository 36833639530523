import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { getDefaultDisplayDate } from 'src/app/common/utils';
import { PaginateList } from 'src/app/common/types/common-types';
import { campaignTypePath } from '../../CampaignTypeRoutes';
import { CampaignTypeItem } from 'src/app/modules/AgencyCampaign/types/campaign-type-types';

type CampaignTypeListProps = {
  isLoading: boolean;
  campaignTypeList?: PaginateList<CampaignTypeItem>;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
  onSort: (sortingState: { key: string; value?: string }) => void;
};

const CampaignTypeList: FC<CampaignTypeListProps> = ({
  isLoading,
  campaignTypeList,
  onRefresh,
  onChangePage,
  onSort,
}) => {
  const history = useHistory();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  return (
    <>
      <PruTable
        title={Translation('agencyCampaign.campaignType.list')}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('agencyCampaign.common.add'),
            onClick: () => history.push(`${campaignTypePath}/create`),
          },
        ]}
        disableBulkSelect
        operationDef={[
          {
            title: Translation('section.common.operation.view'),
            tooltipText: 'View Campaign Type',
            onClick: (row) => history.push(`${campaignTypePath}/view/${row._id}`),
          },
          {
            title: Translation('section.common.operation.edit'),
            tooltipText: 'Edit Campaign Type',
            onClick: (row) => history.push(`${campaignTypePath}/edit/${row._id}`),
          },
        ]}
        columnDef={[
          {
            isId: true,
            hidden: true,
            keyIndex: '_id',
            displayName: '',
            renderData: (row) => '',
          },
          {
            keyIndex: 'name',
            displayName: Translation('agencyCampaign.common.name'),
            renderData: (row) => row.name || '-',
          },
          {
            keyIndex: 'status',
            displayName: Translation('agencyCampaign.common.status'),
            renderData: (row) => row.status || '-',
          },
          {
            keyIndex: 'updatedBy',
            displayName: Translation('agencyCampaign.common.lastUpdatedBy'),
            renderData: (row) => row.updatedBy || '-',
          },
          {
            keyIndex: 'createdAt',
            align: 'center',
            displayName: Translation('agencyCampaign.common.createdTime'),
            renderData: (row) => getDefaultDisplayDate(new Date(row.createdAt), 'datetime'),
            sortable: true,
            onSort: (sort) => {
              onSort({ key: 'createdAt', value: sort['createdAt'] });
            },
          },
          {
            keyIndex: 'updatedAt',
            align: 'center',
            displayName: Translation('agencyCampaign.common.lastUpdatedTime'),
            renderData: (row) => getDefaultDisplayDate(new Date(row.updatedAt), 'datetime'),
            sortable: true,
            onSort: (sort) => {
              onSort({ key: 'updatedAt', value: sort['updatedAt'] });
            },
          },
        ]}
        isLoading={isLoading}
        onRefresh={onRefresh}
        dataSource={campaignTypeList?.docs}
        totalPages={campaignTypeList?.totalPages}
        totalRecords={campaignTypeList?.totalDocs}
        onChangePage={onChangePage}
      />
    </>
  );
};

export default CampaignTypeList;
