export const PULSELEADS_CONFIGURATION_TITLE = "title.pulseleads.configuration";
export const PULSELEADS_CONFIGURATION_LOCATION_TITLE = "title.pulseleads.configuration.location";
export const PULSELEADS_CONFIGURATION_LANGUAGE_TITLE = "title.pulseleads.configuration.language";
export const PULSELEADS_CONFIGURATION_PRODUCT_TITLE = "title.pulseleads.configuration.product";

export const PULSELEADS_CONFIGURATION_LOCATION_NAMESPACE = "PULSELEADS-CONFIGURATION-LOCATION";
export const PULSELEADS_CONFIGURATION_LANGUAGE_NAMESPACE = "PULSELEADS-CONFIGURATION-LANGUAGE";
export const PULSELEADS_CONFIGURATION_PRODUCT_NAMESPACE = "PULSELEADS-CONFIGURATION-PRODUCT";

export const PULSELEADS_CONFIGURATION_PATH = "/configuration";
export const PULSELEADS_CONFIGURATION_LOCATION_PATH = "/location";
export const PULSELEADS_CONFIGURATION_LANGUAGE_PATH = "/language";
export const PULSELEADS_CONFIGURATION_PRODUCT_PATH = "/product";