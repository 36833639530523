import { FC } from 'react';
import { useIntl } from 'react-intl';
import { lowerCase, map, upperFirst } from 'lodash';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { usePDFTemplateListingPage } from './pdf-template-listing-page.hook';
import { PDFTemplateList } from './components/pdf-template-list.component';

type PDFTemplateListingPageProps = ParamsProps;

export const PDFTemplateListingPage: FC<PDFTemplateListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { filterState, templateList, isLoading, pdfTypeOptions, setFilterState, refreshData, onSort, fetchPDFFile } =
    usePDFTemplateListingPage({
      initialParams,
      onChangeQueryParams,
    });

  return (
    <>
      <PruFilter
        title={Translation('pdf.template.filter')}
        itemDef={[
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'templateType',
            initialValue: filterState.templateType,
            displayName: Translation('onboarding.common.templateType'),
            style: { width: 200 },
            choices: [
              { displayName: 'ALL', value: '' },
              ...map(pdfTypeOptions, (option: string) => ({
                displayName: upperFirst(lowerCase(option)),
                value: option,
              })),
            ],
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            style: { width: 200 },
            field: 'description',
            initialValue: filterState.description,
            displayName: Translation('onboarding.common.description'),
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'from',
            fieldTo: 'to',
            initialDateFrom: filterState.validDateFrom,
            initialDateTo: filterState.validDateTo,
            displayName: Translation('validity_period'),
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            templateType: data.templateType,
            description: data.description,
            validDateFrom: data.from,
            validDateTo: data.to,
          })
        }
        fetchData={refreshData}
      />
      <PDFTemplateList
        isLoading={isLoading}
        templateList={templateList}
        onRefresh={refreshData}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
        onSort={onSort}
        fetchPDFFile={fetchPDFFile}
      />
    </>
  );
};
