import { NewsContentItem } from '../types/news-type';

export const getImageSizeFromUrl = (url: string): Promise<{ width: number; height: number } | null> => {
  return new Promise((res) => {
    const img = new Image();
    img.src = url;

    img.onload = () => {
      res({
        width: img.width,
        height: img.height,
      });
    };

    img.onerror = () => {
      res(null);
    };
  });
};

export const generateContentPlainText = (content: NewsContentItem): NewsContentItem => {
  if (!content) return {};
  const result: NewsContentItem = {};
  Object.keys(content).forEach((key) => {
    if (!key.startsWith('plainText')) {
      const plainTextKey = key.charAt(0).toUpperCase() + key.slice(1);
      result[key] = content[key] || '';
      result[`plainText${plainTextKey}`] = htmlToPlainText(content[key] || '');
    }
  });

  return result;
};

export const htmlToPlainText = (html: string): string => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  return (doc.body.textContent || '').trim();
};
