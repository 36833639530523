export const TODOLIST_BASE_PATH = '/todolist';
export const TODOLIST_TASKGROUP_PATH = '/taskgroup';
export const TODOLIST_TASKSECTION_PATH = '/tasksection';
export const TODOLIST_SETTING_PATH = '/setting';

export const TODOLIST_TITLE = 'title.todolist';

// Task Group
export const TODOLIST_TASKGROUP_TITLE = 'title.todolist.taskGroup';

// Task Section
export const TODOLIST_TASKSECTION_TITLE = 'title.todolist.taskSection';

// Setting
export const TODOLIST_SETTING_TITLE = 'title.todolist.setting';

//  These constants map to permission control, identical to permission enum defined below
export const TODOLIST_TASKGROUP_NAMESPACE = 'TODOLIST-TASKGROUP';
export const TODOLIST_TASKSECTION_NAMESPACE = 'TODOLIST-TASKSECTION';
export const TODOLIST_SETTING_NAMESPACE = 'TODOLIST-SETTING';

export enum RoleToDoListPermissionType {
  TODOLIST_TASKGROUP_READ = 'READ_ANY_TODOLIST-TASKGROUP',
  TODOLIST_TASKGROUP_CREATE = 'CREATE_ANY_TODOLIST-TASKGROUP',
  TODOLIST_TASKGROUP_UPDATE = 'UPDATE_ANY_TODOLIST-TASKGROUP',
  TODOLIST_TASKSECTION_READ = 'READ_ANY_TODOLIST-TASKSECTION',
  TODOLIST_TASKSECTION_CREATE = 'CREATE_ANY_TODOLIST-TASKSECTION',
  TODOLIST_TASKSECTION_UPDATE = 'UPDATE_ANY_TODOLIST-TASKSECTION',
  TODOLIST_SETTING_READ = 'READ_ANY_TODOLIST-SETTING',
  TODOLIST_SETTING_CREATE = 'CREATE_ANY_TODOLIST-SETTING',
  TODOLIST_SETTING_UPDATE = 'UPDATE_ANY_TODOLIST-SETTING',
}
