import { I18nDataItem } from 'src/app/i18n';

export enum CampaignFeedbackFormMode {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
}

export type CampaignFeedbackItem = {
  _id: string;
  name: string;
  i18nData?: I18nDataItem;
  createdAt: Date;
  updatedAt: Date;
};

export type CampaignFeedbackGroupItem = {
  _id: string;
  name: string;
  feedbacks: string[] | CampaignFeedbackItem[];
  createdAt: Date;
  updatedAt: Date;
};
