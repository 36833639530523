import { useEffect, useReducer } from 'react';
import { SessionFormState, sessionFormReducer } from 'src/app/modules/event-v2/pages/event-list/form/reducers';

interface HookProps {
  subSessionItem: SessionFormState;
  updateSessionForm: (newSubSessionItem: SessionFormState) => void;
}

const detailToStateConvertor = (subSessionItem: SessionFormState): SessionFormState => {
  return {
    key: subSessionItem.key,
    type: subSessionItem.type,
    startTime: subSessionItem.startTime,
    endTime: subSessionItem.endTime,
    name: subSessionItem.name,
    venue: subSessionItem.venue,
    multiSubSession: subSessionItem.multiSubSession,
    quota: subSessionItem.quota,
    subSessions: subSessionItem.subSessions,
    subSessionEnrollment: subSessionItem.subSessionEnrollment,
  };
};

export const useSubSessionForm = ({ subSessionItem, updateSessionForm }: HookProps) => {
  const [formState, formDispatch] = useReducer(sessionFormReducer, detailToStateConvertor(subSessionItem));

  useEffect(() => {
    updateSessionForm(formState);
  }, [formState]);

  return {
    formState,
    formDispatch,
  };
};
