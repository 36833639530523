import { FC } from 'react';
import { RouteComponentProps, Switch, Redirect } from 'react-router-dom';
import PruRoute from 'src/app/common/components/PruRoute';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { PULSELEADS_BASE_PATH } from '../../../constants';
import { agentLeadSourcePath } from '../LeadSource/AgentLeadSourceRoutes';
import { PULSELEADS_AGENT_PATH, PULSELEADS_AGENT_AUTO_DROP_LEAD_PATH } from '../constants';
import { LeadDropAgentRulePage } from './detail';

export const agentAutoDropLeadPath = `${PULSELEADS_BASE_PATH}${PULSELEADS_AGENT_PATH}${PULSELEADS_AGENT_AUTO_DROP_LEAD_PATH}`;

const AgentAutoDropLeadRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <PruRoute exact path={`${agentAutoDropLeadPath}`} component={LeadDropAgentRulePage} />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default AgentAutoDropLeadRoutes;
