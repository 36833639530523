import React, { FC, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { authenticate, setTokenInfo, setRegionChannelList } from '../_redux/authSlice';
import { useDispatch } from 'react-redux';
import { AlertType, appendAlertItem } from '../../../../redux/common/commonSlice';
import moment from 'moment';
import { TokenInfo } from '../types/auth-types';
import { getToken, getRegionChannelList, decodeJWT } from '../_redux/authCrud';
import { LayoutSplashScreen } from 'src/app/layout';
import { fetchConfigs } from '../../Config/network/configCrud';

const Callback: FC<RouteComponentProps> = ({ history, location }) => {
  const dispatch = useDispatch();

  const login = async () => {
    const search = location.search;
    const params = new URLSearchParams(search);
    const authorizationCode = params.get('authorizationCode');
    if (authorizationCode) {
      try {
        const response = await getToken(authorizationCode, dispatch);
        const { accessToken, expiresIn, refreshToken } = response;
        if (accessToken && expiresIn && refreshToken) {
          window.localStorage.setItem('jwt', accessToken);
          const jwt = await decodeJWT(accessToken);
          dispatch(authenticate(accessToken));
          dispatch(setTokenInfo(jwt));
          const expireDate = moment(new Date()).add(Number(expiresIn), 's').toDate();
          const abondonSession = moment(new Date()).add(1, 'd').toDate();
          window.localStorage.setItem('expireDate', expireDate.toISOString());
          window.localStorage.setItem('abondonSession', abondonSession.toISOString());
          window.localStorage.setItem('refreshToken', refreshToken);

          await fetchConfigs(dispatch);
          await reloadRegionChannel();

          const redirectLink = window.localStorage.getItem('redirect');
          if (redirectLink) {
            window.localStorage.removeItem('redirect');
            history.push(redirectLink);
          }
          return;
        }
      } catch (err) {
        history.push('/');
      }
    }
    dispatch(
      appendAlertItem([
        {
          severity: AlertType.ERROR,
          title: 'Error',
          content: 'Authentication failure',
        },
      ]),
    );
  };

  const reloadRegionChannel = async () => {
    const regionChannelListRes = await getRegionChannelList(dispatch);
    if (regionChannelListRes && regionChannelListRes.length > 0) {
      dispatch(setRegionChannelList(regionChannelListRes));
    }
  };

  useEffect(() => {
    login();
  }, []);

  return (
    <>
      <LayoutSplashScreen />
    </>
  );
};

export default Callback;
