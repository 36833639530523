import React, { FC, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import moment, { Moment } from 'moment-timezone';
import { PruDatePicker } from 'src/app/common/components/PruDatePicker';
import { Button } from '@mui/material';
import { FeatureType } from '../../../../network/libraryCrud';
// import { getDateAndGenerate } from './api';

export type MaterialDashboardExportProps = {
  feature: FeatureType;
  id: string;
  name: string;
  onExport: (feature: FeatureType, startTime: Moment, endTime: Moment, name: string, id: string) => void;
};

const FORMATE = 'DD-MM-YYYY';

export const MaterialDashboardExport: FC<MaterialDashboardExportProps> = ({ feature, id, name, onExport }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [right, setRight] = useState(moment());
  const [left, setLeft] = useState(moment().subtract(30, 'day'));
  return (
    <>
      <div style={wrap}>
        <span>{Translation('title.resource.library.performance.date')}</span>
        <div style={pickerWrap}>
          <PruDatePicker
            disabled={false}
            format={FORMATE}
            maxDate={right.toDate()}
            value={left.toDate()}
            onChange={(date) => {
              setLeft(moment(date));
            }}
          />
          <div>-</div>
          <PruDatePicker
            disabled={false}
            format={FORMATE}
            minDate={left.toDate()}
            value={right.toDate()}
            onChange={(date) => {
              setRight(moment(date));
            }}
          />
        </div>
      </div>
      <div className="tw-mt-5">
        <Button variant="contained" color="secondary" onClick={() => onExport(feature, left, right, name, id)}>
          {Translation('export.list.text')}
        </Button>
      </div>
    </>
  );
};
const wrap = { flexDirection: 'row' as const, display: 'flex', alignItems: 'center', paddingTop: 20 };
const pickerWrap = { marginLeft: 64, alignItems: 'center', display: 'flex' };
const keyboardButtonProps = { 'aria-label': 'change date' };
