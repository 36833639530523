import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { useIntl } from 'react-intl';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useDataProvider } from 'src/app/common/utils';
import { PaginateList } from 'src/app/common/types/common-types';
import {
  fetchEligibilityRuleList,
  EligibilityRuleListParam,
} from 'src/app/modules/PulseLeads/network/eligibilityRuleCrud';
import { EligibilityRule } from 'src/app/modules/PulseLeads/types/eligibility-rule-types';
import EligibilityRuleList from './components/EligibilityRuleList';

type EligibilityRuleListingPageProps = {} & ParamsProps;

const initialState: EligibilityRuleListParam = {
  name: '',
  limit: 5,
  page: 1,
};

const paramsInitiator = (initialParams?: Record<string, string>): EligibilityRuleListParam => {
  return initialParams
    ? {
        name: initialParams.name ?? '',
        limit: 5,
        page: 1,
      }
    : initialState;
};

export const eligibilityRuleFilterKeys = ['name'];

const EligibilityRuleListingPage: FC<EligibilityRuleListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const [filterState, setFilterState] = useState<EligibilityRuleListParam>(paramsInitiator(initialParams));

  const [eligibilityRuleList, setEligibilityRuleList] = useState<PaginateList<EligibilityRule>>();
  const { isLoading, refreshData } = useDataProvider<PaginateList<EligibilityRule>>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchEligibilityRuleList(filterState, dispatch);
      } catch (err) {}
    },
    setEligibilityRuleList,
    false,
  );

  return (
    <>
      <PruFilter
        title={Translation('pulseleads.eligibilityRule.filter')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'name',
            initialValue: filterState.name,
            displayName: Translation('pulseleads.eligibilityRule.common.name'),
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            name: data.name,
          })
        }
        fetchData={refreshData}
      />
      <EligibilityRuleList
        isLoading={isLoading}
        eligibilityRuleList={eligibilityRuleList}
        onRefresh={refreshData}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default EligibilityRuleListingPage;
