import React, { FC, useEffect } from 'react';
import { FormControl, Select, TextField, Button } from '@mui/material';
import { FetchStatus } from '../../../../types/notification-types';
import { map } from 'lodash';
import useFilterStyles from '../../../../../../common/styles/filter-styles';
import { useCommonStyles } from '../../../../../../common/styles/common-styles';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { AuthenticationState } from 'src/app/modules/Auth/_redux/authSlice';
import { RootState } from 'src/redux/store';
import { getCurrentConfig } from 'src/app/common/utils';

type IncentiveFilterProps = {
  onChangeFilter: (filterState: IncentiveFilterState) => void;
  filterState: IncentiveFilterState;
  onSearch: () => void;
};

export interface IncentiveFilterState {
  id?: string;
  msgTitle?: string;
  modifier?: string;
  inboxStatus?: string;
  category?: string;
}
const initState = {
  id: '',
  msgTitle: '',
  modifier: '',
  inboxStatus: '',
  category: '',
};

const NotificationFilter: FC<IncentiveFilterProps> = ({ onChangeFilter, filterState, onSearch }) => {
  const { classes: commonClasses } = useCommonStyles();
  const { classes: filterClasses } = useFilterStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const { user } = useSelector<RootState, AuthenticationState>((state) => state.auth);

  const currentConfig = getCurrentConfig(user);
  const CategoryType = currentConfig?.notificationCategoryType as { [key: string]: string };

  // useUpdateEffect(() => {
  //   onChangeFilter(filterState);
  // }, [filterState]);
  const onChange = (data: IncentiveFilterState) => {
    onChangeFilter({
      ...filterState,
      ...data,
    });
  };
  const onReset = () => {
    onChangeFilter(initState);
  };

  const keyPressSearch = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      onSearch();
    }
  };

  useEffect(() => {
    window.addEventListener('keypress', keyPressSearch);
    return () => {
      window.removeEventListener('keypress', keyPressSearch);
    };
    // eslint-disable-next-line
  }, [filterState]);

  return (
    <div style={{ marginBottom: 20 }} className={filterClasses.root}>
      {/* Header Row */}
      <div style={{ marginBottom: 5 }} className="PruFilter-header-container">
        <div className={commonClasses.header}>{Translation('component.notificationFilter.filterTitle')}</div>
        <div className="PruFilter-row">
          <Button style={{ marginRight: 20 }} variant="contained" color="inherit" onClick={onReset}>
            {Translation('golbal.filters.reset')}
          </Button>
          <Button variant="contained" color="secondary" onClick={onSearch}>
            {Translation('golbal.filters.search')}
          </Button>
        </div>
      </div>
      {/* First Row */}
      <div className="PruFilter-row">
        {/* <span className="PruFilter-criteria">{ Translation('component.formLabel.notification-id') } :</span>
        <TextField
          style={{ marginRight: 20 }}
          margin="dense"
          variant="outlined"
          value={filterState.id}
          onChange={e => filterDispatch({ type: 'CHANGE_FILTER', payload: { field: 'id', value: e.target.value } })}
        /> */}
        <span className="PruFilter-criteria">{Translation('component.formLabel.notification-title')} :</span>
        <TextField
          style={{ marginRight: 20 }}
          margin="dense"
          variant="outlined"
          value={filterState.msgTitle || ''}
          onChange={(e) => onChange({ msgTitle: e.target.value })}
        />
        <span className="PruFilter-criteria">{Translation('component.formLabel.last-updated-by')} :</span>
        <TextField
          style={{ marginRight: 20 }}
          margin="dense"
          variant="outlined"
          value={filterState.modifier || ''}
          onChange={(e) => onChange({ modifier: e.target.value })}
        />

        <span className="PruFilter-criteria">{Translation('component.formLabel.category')} :</span>
        <FormControl margin="dense" variant="outlined" style={{ marginRight: 20 }}>
          <Select
            native
            value={filterState.category || ''}
            onChange={(e) => onChange({ category: e.target.value as string })}
            inputProps={{
              name: 'category',
              id: 'filled-age-native-simple',
            }}
          >
            <option value="">{Translation('component.tagSelect.all')}</option>
            {map(CategoryType, (key: string, value: string) => (
              <option key={key} value={key}>
                {Translation(`notification.category.type.${value}`)}
              </option>
            ))}
          </Select>
        </FormControl>

        <span className="PruFilter-criteria">{Translation('component.formLabel.status')} :</span>
        <FormControl margin="dense" variant="outlined" style={{ marginRight: 20 }}>
          <Select
            native
            value={filterState.inboxStatus || ''}
            onChange={(e) => onChange({ inboxStatus: e.target.value as string })}
            inputProps={{
              name: 'incentiveStatus',
              id: 'filled-age-native-simple',
            }}
          >
            <option value="">{Translation('component.tagSelect.all')}</option>
            {map(FetchStatus, (option: string) => (
              <option key={option} value={option}>
                {Translation(`component.notification.${option}`)}
              </option>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

export default NotificationFilter;
