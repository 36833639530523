import { FC, PropsWithChildren, createContext, useMemo, useEffect } from 'react';
import { ModuleHierachy } from './types';
import { AuthenticationState } from 'src/app/modules/Auth/_redux/authSlice';
import { map } from 'lodash';
import { useIntl } from 'react-intl';
// import { incentiveModuleConfig } from '../../modules/Incentive/module-def';
// import { incentiveModuleConfig } from '../../modules/IncentiveV2/module-def';
import { productionModuleConfig } from '../../modules/Production/module-def';
import { resourceModuleConfig } from '../../modules/sales-kit/module-def';
import { operationModuleConfig } from '../../modules/Operation/module-def';
import { notificationModuleConfig } from '../../modules/Notification/module-def';
import { surveyModuleConfig } from '../../modules/Survey/module-def';
import { feedbackModuleConfig } from '../../modules/Feedback/module-def';
import { reportModuleConfig } from '../../modules/Report/module-def';
import { applicationModuleConfig } from '../../modules/Application/module-def';
import { roleControlModuleConfig } from '../../modules/RoleControl/module-def';
import { newsModuleConfig } from '../../modules/News/module-def';
import { toDoListModuleConfig } from '../../modules/ToDoList/module-def';
import { configModuleConfig } from '../../modules/Config/module-def';
import { useHistory } from 'react-router-dom';
import { FORBIDDEN_ERROR_PAGE_PATH } from '../constants';
import { pulseLeadsModuleConfig } from 'src/app/modules/PulseLeads/module-def';
import { recruitModuleConfig } from 'src/app/modules/Recruit/module-def';
import { recruitmentModuleConfig } from 'src/app/modules/Recruitment/module-def';
import { eventModuleConfig } from 'src/app/modules/event-v2/module-def';
import { MandatoryTrainingModuleConfig } from 'src/app/modules/MandatoryTraining/module-def';
import { agencyCampaignModuleConfig } from 'src/app/modules/AgencyCampaign/module-def';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { Configuration } from 'src/app/modules/Config/types/config-type';
import { insightsModuleConfig } from 'src/app/modules/PruleadInsightsReporting/module-def';
import { profileModuleConfig } from 'src/app/modules/Profile/module-def';
import { GlobalHelper } from '../helpers/GlobalHelpers';
import { secretaryModuleConfig } from 'src/app/modules/Secretary/module-def';
import { publicPortalConsentModuleConfig } from 'src/app/modules/public-portal-consent/module-def';
import { leadMicrositeModuleConfig } from 'src/app/modules/lead-microsite/module-def';
import { useJWT } from '../utils';

type ModuleContextInternal = {
  moduleMap: ModuleHierachy[];
};

export const ModuleContext = createContext<ModuleContextInternal>({
  moduleMap: [],
});

const filterModule = (moduleItem: ModuleHierachy) => {
  if (!moduleItem.child?.length) {
    return moduleItem;
  }

  if (
    moduleItem?.enableModule !== false &&
    (moduleItem.enableRead || moduleItem.enableCreate || moduleItem.enableUpdate)
  ) {
    moduleItem.child =
      (moduleItem.child || [])
        .map((item) => filterModule(item))
        .filter((item: any) => item?.enableModule !== false && (!item?.child || item.child?.length > 0)) || [];

    return moduleItem;
  }
  if (moduleItem.enableModule === false) {
    moduleItem.child = [];
  }
  return moduleItem;
};

const checkModuleRenderForChannel = (channelConfig: any, permissions: any, moduleList: any, intl: any): any[] => {
  const array: any = [];
  moduleList.map((item: any) => {
    const module = filterModule(item.moduleConfig(permissions, intl));
    return !channelConfig || channelConfig.enableModule[item.moduleName] ? array.push(module) : null;
  });
  return array;
};

const ModuleProvider: FC<PropsWithChildren> = ({ children }) => {
  const intl = useIntl();
  GlobalHelper.setGlobalIntlRef(intl);
  const history = useHistory();

  const { user } = useSelector<RootState, AuthenticationState>((state) => state.auth);
  const config = useSelector<RootState, Configuration | undefined>((state) => state.config.data);
  const jwt = useJWT();

  const moduleMap: ModuleHierachy[] = useMemo(() => {
    if (!jwt) {
      return [];
    }

    const permissions = map(jwt.permissions, (_, key) => key);
    const region = jwt.region;
    const channel = jwt.channel;

    let channelConfig;
    try {
      channelConfig = require(`../../../channelConfig/${region.toLowerCase()}_${channel.toLowerCase()}.json`);
    } catch (e) {
      channelConfig = require(`../../../channelConfig/default.json`);
    }

    localStorage.setItem('channelConfig', JSON.stringify(channelConfig));
    const moduleList = [
      // { moduleConfig: incentiveModuleConfig, moduleName: 'incentive' },
      { moduleConfig: productionModuleConfig, moduleName: 'production' },
      { moduleConfig: resourceModuleConfig, moduleName: 'resource' },
      { moduleConfig: operationModuleConfig, moduleName: 'operation' },
      { moduleConfig: notificationModuleConfig, moduleName: 'notification' },
      { moduleConfig: eventModuleConfig, moduleName: 'events' },
      { moduleConfig: surveyModuleConfig, moduleName: 'survey' },
      { moduleConfig: feedbackModuleConfig, moduleName: 'feedback' },
      { moduleConfig: reportModuleConfig, moduleName: 'report' },
      { moduleConfig: newsModuleConfig, moduleName: 'news' },
      { moduleConfig: toDoListModuleConfig, moduleName: 'todolist' },
      { moduleConfig: MandatoryTrainingModuleConfig, moduleName: 'mandatory_training' },
      { moduleConfig: configModuleConfig, moduleName: 'config' },
      { moduleConfig: pulseLeadsModuleConfig, moduleName: 'pulseLeads' },
      { moduleConfig: agencyCampaignModuleConfig, moduleName: 'agencyCampaign' },
      { moduleConfig: recruitModuleConfig, moduleName: 'recruit' },
      { moduleConfig: recruitmentModuleConfig, moduleName: 'recruitment' },
      { moduleConfig: insightsModuleConfig, moduleName: 'insights' },
      { moduleConfig: leadMicrositeModuleConfig, moduleName: 'lead_microsite' },
      { moduleConfig: profileModuleConfig, moduleName: 'profile' },
      { moduleConfig: secretaryModuleConfig, moduleName: 'secretary' },
      { moduleConfig: publicPortalConsentModuleConfig, moduleName: 'public_portal_consent' },
      { moduleConfig: applicationModuleConfig, moduleName: 'application' },
      { moduleConfig: roleControlModuleConfig, moduleName: 'roleControl' },
    ];
    const array = checkModuleRenderForChannel(channelConfig, permissions, moduleList, intl);
    return array;
    // eslint-disable-next-line
  }, [config]);

  useEffect(() => {
    let noPermissionFlag = true;
    moduleMap.forEach((moduleItem) => {
      if (moduleItem.enableRead || moduleItem.enableCreate || moduleItem.enableUpdate) {
        noPermissionFlag = false;
      }
    });
    if (noPermissionFlag) {
      history.push(FORBIDDEN_ERROR_PAGE_PATH);
    }
    // eslint-disable-next-line
  }, [user]);

  return <ModuleContext.Provider value={{ moduleMap }}>{children}</ModuleContext.Provider>;
};

export default ModuleProvider;
