import React, { useState, useEffect } from 'react';
import { useIntl } from "react-intl";
import { makeStyles } from 'tss-react/mui';
import { Button, Dialog } from '@mui/material';
import Form from 'src/app/common/components/Form';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { completionRuleType } from '../../../../../constants';
import RuleSelection from '../RuleSelection';
import styles from './style';
import CourseList from './../CourseList'
import MutiLangList from '../../../../../components/MutiLangList';

const CourseSet = (props: any) => {
    const { classes } = styles();
    const { classes: commonClasses } = useCommonStyles();
    const intl = useIntl();

    const Translation = (id: string) => intl.formatMessage({ id });

    const { form, index, field, onRemove, disabled } = props;
    const [ deleteConfirmPopupVisible, setDeleteConfirmPopupVisible ] = useState<boolean>(false);

    const RULE_VALIDATE = [{
        validator(_: any, data: any, a: any) {
            const selectCourses = form.getFieldValue(['trainingSetTemplates', index, 'courses']);
            const type = data.requiredCompletionType;
            const count = data.requiredCompletionCount;


            if (!data || (!type && !count)) {
                return Promise.reject(new Error(Translation('component.pulseleads.prospect.form-required-text')))
            }

            if (type === completionRuleType.SPECIFIY && !count) {
                return Promise.reject(new Error(Translation('component.pulseleads.prospect.form-required-text')))
            }

            if (type === completionRuleType.SPECIFIY && count <= 0) {
                return Promise.reject(new Error(Translation('value.need.greater.zero.msg')))
            }

            if (type === completionRuleType.SPECIFIY && selectCourses && count) {
                const courseCount = selectCourses.length;
                if (courseCount > 0 && (+count > courseCount)) {
                    return Promise.reject(new Error(Translation('mandatory.training.component.course.count.exceed.error')))
                }
            }

            return Promise.resolve();
        }
    }];

    const COURSE_LIST_VALIDATE = [{
        validator(_: any, data: any) {

            if (!data || data.length === 0) {
                return Promise.reject(new Error(Translation('mandatory.training.component.table.at.least.one.course.tips')))
            }

            return Promise.resolve();
        }
    }];

    return (
        <div className={classes.setBox}>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <div style={{ flex: 1 }}><label className={classes.setName} >{Translation('mandatory.training.component.required.training.set.name.title')}</label><label >{Translation('mandatory.training.component.required.training.set.name.length.limit')}</label></div>
                <div style={{width: 150}}>
                    {
                        (!disabled) ? <Button
                            style={{ float: 'right' }}
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                setDeleteConfirmPopupVisible(true);
                            }}
                        >
                            {Translation("mandatory.training.component.required.training.button.remove.set")}
                        </Button> : null
                    }
                </div>
            </div>


            <Form.Item
                noStyle
            >

                {(_, meta, { getFieldValue }) => {

                    return (
                        <MutiLangList
                            listName={[index, "i18nData"]}
                            name="name"
                            getFieldValue={getFieldValue}
                            label={`${Translation('component.formLabel.name')}`}
                            disabled={disabled}
                        />
                    )
                }}
            </Form.Item>

            <Form.Item
                label={Translation("mandatory.training.component.required.training.set.completion.rule.title")}
                name={[index]}
                rules={RULE_VALIDATE}
            >
                {control => {
                    return (
                        <RuleSelection {...control} {...field} form={form} disabled={disabled} />
                    );
                }}
            </Form.Item>

            <Form.Item
                name={[index, "courses"]}
                rules={COURSE_LIST_VALIDATE}
            >
                {control => {
                    return (
                        <CourseList CourseList {...control} {...field} disabled={disabled}/>
                    );
                }}

            </Form.Item>

            <Dialog open={deleteConfirmPopupVisible} fullWidth>
                <div style={{ padding: 20, textAlign: 'center' }}>
                    {Translation('mandatory.training.category.sure.to.delete')}
                </div>
                <div className={commonClasses.footerContainer}>
                    <>
                        <Button
                            variant="contained"
                            color="inherit"
                            onClick={() => { setDeleteConfirmPopupVisible(false) }}
                        >
                            {Translation('app.button.cancel')}
                        </Button>
                        <Button
                            style={{ marginLeft: 20 }}
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                setDeleteConfirmPopupVisible(false);
                                onRemove(index);
                            }}
                        >
                            {Translation('global.text.confirm')}
                        </Button>
                    </>
                </div>
            </Dialog>
        </div>
    );
}

export default CourseSet;