import { Dispatch } from 'react';
import { forEach } from 'lodash';
import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import { PaginateList } from 'src/app/common/types/common-types';
import { I18nDataItem } from 'src/app/i18n';
import { CampaignFeedbackItem, CampaignFeedbackGroupItem } from '../types/campaign-feedback-types';

const campaignFeedbackEndpoint = 'campaign-feedback';
const campaignFeedbackGroupEndpoint = 'campaign-feedback-group';

export type CampaignFeedbackListParam = {
  page: number;
  limit: number;
};

export type GenericCampaignFeedbackBody = {
  name: string;
  i18nData: I18nDataItem;
};

export type CampaignFeedbackGroupBody = {
  name: string;
  feedbacks: string[];
};

export const fetchCampaignFeedbackList = async (
  params: CampaignFeedbackListParam,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<CampaignFeedbackItem>> => {
  let queryPath = `${ADMIN_URL}/${campaignFeedbackEndpoint}?`;
  forEach(params, (param, key) => {
    if (key === 'page') {
      queryPath += `${key}=${encodeURIComponent(param as any)}&`;
    } else {
      queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
    }
  });
  return apiClient
    .get<PaginateList<CampaignFeedbackItem>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchCampaignFeedbackGroupList = async (
  params: CampaignFeedbackListParam,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<CampaignFeedbackGroupItem>> => {
  let queryPath = `${ADMIN_URL}/${campaignFeedbackGroupEndpoint}?`;
  forEach(params, (param, key) => {
    if (key === 'page') {
      queryPath += `${key}=${encodeURIComponent(param as any)}&`;
    } else {
      queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
    }
  });
  return apiClient
    .get<PaginateList<CampaignFeedbackGroupItem>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchCampaignFeedbackItem = async (
  id: string,
  dispatch?: Dispatch<any>,
): Promise<CampaignFeedbackItem> => {
  return apiClient
    .get<CampaignFeedbackItem>(`${ADMIN_URL}/${campaignFeedbackEndpoint}/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchCampaignFeedbackGroupItem = async (
  id: string,
  dispatch?: Dispatch<any>,
): Promise<CampaignFeedbackGroupItem> => {
  return apiClient
    .get<CampaignFeedbackGroupItem>(`${ADMIN_URL}/${campaignFeedbackGroupEndpoint}/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createCampaignFeedback = async (
  body: GenericCampaignFeedbackBody,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .post<void>(`${ADMIN_URL}/${campaignFeedbackEndpoint}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createCampaignFeedbackGroup = async (
  body: CampaignFeedbackGroupBody,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .post<void>(`${ADMIN_URL}/${campaignFeedbackGroupEndpoint}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const modifyCampaignFeedback = async (
  id: string,
  body: GenericCampaignFeedbackBody,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .put<void>(`${ADMIN_URL}/${campaignFeedbackEndpoint}/${id}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const modifyCampaignFeedbackGroup = async (
  id: string,
  body: CampaignFeedbackGroupBody,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .put<void>(`${ADMIN_URL}/${campaignFeedbackGroupEndpoint}/${id}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const deleteCampaignFeedback = async (id: string, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .delete<void>(`${ADMIN_URL}/${campaignFeedbackEndpoint}/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const deleteCampaignFeedbackGroup = async (id: string, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .delete<void>(`${ADMIN_URL}/${campaignFeedbackGroupEndpoint}/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
