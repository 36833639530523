import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { getDayStart, getDayEnd, useDataProvider } from 'src/app/common/utils';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { useIntl } from 'react-intl';
import { SurveyResultListParam, fetchCustomerSurveyResultList } from '../network/api';
import { SurveyPaginateList } from '../network/types';
import CustomerSurveyList from './component/CustomerSurveyList';

type CustomerSurveyListingPageProps = ParamsProps;

const initialState: SurveyResultListParam = {
  startDateFrom: null,
  startDateTo: null,
  endDateFrom: null,
  endDateTo: null,
  page: 1,
  limit: 5,
};

export const surveyFilterKeys = ['id', 'startDateFrom', 'startDateTo', 'endDateFrom', 'endDateTo'];

const paramsInitiator = (initialParams?: Record<string, string>): SurveyResultListParam => {
  return initialParams
    ? {
        startDateFrom: initialParams.startDateFrom ? getDayStart(new Date(initialParams.startDateFrom)) : null,
        startDateTo: initialParams.startDateTo ? getDayEnd(new Date(initialParams.startDateTo)) : null,
        endDateFrom: initialParams.endDateFrom ? getDayStart(new Date(initialParams.endDateFrom)) : null,
        endDateTo: initialParams.endDateTo ? getDayEnd(new Date(initialParams.endDateTo)) : null,
        page: 1,
        limit: 5,
      }
    : initialState;
};

const CustomerSurveyListingPage: FC<CustomerSurveyListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const [filterState, setFilterState] = useState<SurveyResultListParam>(paramsInitiator(initialParams));
  const [sortKey, setSortKey] = useState<{ key: string; value?: string }[]>([]);
  const [surveyList, setSurveyList] = useState<SurveyPaginateList>();

  const updateSortingKey = (sortingKey: { key: string; value?: string }) => {
    const sortingKeyArray = sortKey.filter((currentValue, index, arr) => {
      return currentValue.key !== sortingKey.key;
    });
    sortingKeyArray.unshift(sortingKey);
    setSortKey(sortingKeyArray);
  };
  const { isLoading, refreshData } = useDataProvider<SurveyPaginateList>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchCustomerSurveyResultList(filterState, sortKey, dispatch);
      } catch (err) {}
    },
    setSurveyList,
    false,
    undefined,
    true,
  );
  React.useEffect(() => {
    if (sortKey) refreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortKey]);

  return (
    <>
      <PruFilter
        title={Translation('survey.filter')}
        itemDef={[
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'startDateFrom',
            fieldTo: 'startDateTo',
            initialDateFrom: filterState.startDateFrom,
            initialDateTo: filterState.startDateTo,
            displayName: Translation('survey.common.startDate'),
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'endDateFrom',
            fieldTo: 'endDateTo',
            initialDateFrom: filterState.endDateFrom,
            initialDateTo: filterState.endDateTo,
            displayName: Translation('survey.common.endDate'),
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            startDateFrom: data.startDateFrom,
            startDateTo: data.startDateTo,
            endDateFrom: data.endDateFrom,
            endDateTo: data.endDateTo,
          })
        }
        fetchData={refreshData}
      />
      <CustomerSurveyList
        isLoading={isLoading}
        onRefresh={refreshData}
        surveyList={surveyList}
        onSort={(dataKey) => updateSortingKey(dataKey)}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default CustomerSurveyListingPage;
