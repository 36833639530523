import { useState, useReducer, useMemo, useEffect } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useErrorHandler, ErrorFieldType } from 'src/app/common/utils';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import {
  StepStatusEnum,
  ComponentProps,
  StepIndicator,
  useUpdateChecker,
} from 'src/app/common/components/pru-stepped-form';
import { initRadioModeConfig, TargetAgentsByEnum } from 'src/app/common/components/target-applicant-section-v2';
import {
  EventPublishStatusEnum,
  PublishAgentEnum,
  RegistrationMethodEnum,
  EventFormCommonProps,
  EventItem,
} from 'src/app/modules/event-v2/types';
import { CreateUpdateEventBody, modifyEventItem } from 'src/app/modules/event-v2/network';
import { eventListPath } from '../../../../event-list-routes';
import { PublishFormState, publishFormReducer } from '../../../reducers';
import { validateRegAllowWalkIn } from '../../../common';

type HookProps = ComponentProps<EventFormCommonProps>;

const detailToStateConvertor = (eventItem?: EventItem): PublishFormState => {
  return eventItem
    ? {
        pubAgent: eventItem.pubAgent,
        pubAgentConfig: eventItem.pubAgentConfig || initRadioModeConfig,
        agentForceEnrol: eventItem.agentForceEnrol,
        agentForceEnrolConfig: eventItem.agentForceEnrolConfig || initRadioModeConfig,
      }
    : {
        pubAgent: undefined,
        pubAgentConfig: initRadioModeConfig,
        agentForceEnrol: undefined,
        agentForceEnrolConfig: initRadioModeConfig,
      };
};

export const usePublishForm = ({ formCommonProps, componentUpdateStepStatus, setDiscardChanges }: HookProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isPublished, eventItem, isReset, setChangesUnsaved, setIsReset } = formCommonProps;
  const initialState = useMemo(() => detailToStateConvertor(eventItem), [eventItem]);
  const [formState, formDispatch] = useReducer(publishFormReducer, initialState);
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);

  const allowCancel = useMemo(() => {
    if (eventItem && isPublished) {
      return moment().isBefore(eventItem.startTime);
    }
    return false;
  }, [isPublished, eventItem?.startTime]);

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler } = useErrorHandler(formState, [
    {
      name: 'pubAgent',
      fieldType: ErrorFieldType.MANDATORY,
    },
    {
      name: 'pubAgentConfig-targetAgentBy',
      fieldType: ErrorFieldType.MANDATORY,
      condition: () => formState.pubAgent === PublishAgentEnum.TARGET && !!!formState.pubAgentConfig?.targetAgentBy,
    },
    {
      name: 'pubAgentConfig-eligibleDesignations',
      fieldType: ErrorFieldType.MANDATORY,
      condition: () =>
        formState.pubAgentConfig.targetAgentBy === TargetAgentsByEnum.PRESET_GROUP &&
        formState.pubAgentConfig.eligibleDesignations.length === 0,
    },
    {
      name: 'pubAgentConfig-eligibleZones',
      fieldType: ErrorFieldType.MANDATORY,
      condition: () =>
        formState.pubAgentConfig.targetAgentBy === TargetAgentsByEnum.PRESET_GROUP &&
        formState.pubAgentConfig.eligibleZones.length === 0,
    },
  ]);

  const resetFormState = () => {
    formDispatch({
      type: 'SET_FORM_STATE',
      payload: {
        value: initialState,
      },
    });
    componentUpdateStepStatus({
      newStatus: eventItem?.pubAgent ? StepStatusEnum.FINISHED : StepStatusEnum.PROGRESS,
      saveChanges: true,
    });
    setIsReset(true);
  };

  useEffect(() => {
    setDiscardChanges(resetFormState);
    return () => {
      setDiscardChanges(undefined);
    };
  }, []);

  useUpdateChecker({ formState, isReset, setChangesUnsaved, setIsReset });

  const onSubmit = async (publishStatus?: EventPublishStatusEnum) => {
    let hasError = false;
    if (!publishStatus) {
      hasError = onSubmitErrorValidator().hasError;
    }
    if (!hasError) {
      const body: CreateUpdateEventBody =
        publishStatus === EventPublishStatusEnum.UNPUBLISHED
          ? { publishStatus }
          : {
              pubAgent: formState.pubAgent,
              pubAgentConfig: formState.pubAgentConfig,
              agentForceEnrol: formState.agentForceEnrol,
              agentForceEnrolConfig: formState.agentForceEnrolConfig,
              publishStatus,
            };
      try {
        if (eventItem) {
          await modifyEventItem(eventItem._id, body, dispatch);
          if (!publishStatus) {
            dispatch(
              appendAlertItem([
                {
                  severity: AlertType.SUCCESS,
                  title: 'Success',
                  content: 'Event saved successfully',
                },
              ]),
            );
          } else if (publishStatus === EventPublishStatusEnum.PUBLISHED) {
            dispatch(
              appendAlertItem([
                {
                  severity: AlertType.SUCCESS,
                  title: 'Success',
                  content: 'Event published successfully',
                },
              ]),
            );
          }
          history.push(`${eventListPath}`);
        }
      } catch (err) {}
    } else {
      componentUpdateStepStatus({ newStatus: StepStatusEnum.WARNING });
    }
  };

  const publishValidation = () => {
    const errorStepList: StepIndicator[] = [];
    if (eventItem) {
      if (!eventItem.eventImage) {
        // Details Form
        errorStepList.push({ stepIndex: 0, child: { stepIndex: 1 } });
      }
      if (!eventItem.regStartDate || !validateRegAllowWalkIn(eventItem)) {
        // Registration & Attendance Form
        errorStepList.push({ stepIndex: 0, child: { stepIndex: 2 } });
      }
      if (eventItem.regMethod === RegistrationMethodEnum.TEMPLATE && !eventItem.regFormBody) {
        // Registration Form
        errorStepList.push({ stepIndex: 0, child: { stepIndex: 3 } });
      }
    }
    return errorStepList;
  };

  const onPublish = () => {
    const errorStepList = publishValidation();
    if (errorStepList.length === 0) {
      const { hasError } = onSubmitErrorValidator();
      if (!hasError) {
        if (eventItem) {
          const { startTime, regEndDate } = eventItem;
          const now = new Date();
          if (regEndDate && moment(now).isSameOrAfter(regEndDate)) {
            dispatch(
              appendAlertItem([
                {
                  severity: AlertType.ERROR,
                  title: 'Error',
                  content: 'Registration end time cannot be past time',
                },
              ]),
            );
          } else if (moment(now).isSameOrAfter(startTime)) {
            dispatch(
              appendAlertItem([
                {
                  severity: AlertType.ERROR,
                  title: 'Error',
                  content: 'Event date cannot be past date',
                },
              ]),
            );
          } else if (moment(now).isSame(startTime, 'day')) {
            setShowConfirmDialog(true);
          } else {
            onSubmit(EventPublishStatusEnum.PUBLISHED);
          }
        }
      } else {
        componentUpdateStepStatus({ newStatus: StepStatusEnum.WARNING });
      }
    } else {
      componentUpdateStepStatus({
        newStatus: StepStatusEnum.WARNING,
        forceNavigation: true,
        saveChanges: true,
        stepIndicatorList: errorStepList,
      });
    }
  };

  return {
    formState,
    showConfirmDialog,
    errorState,
    allowCancel,
    formDispatch,
    setShowConfirmDialog,
    onDismissErrorHandler,
    onSubmit,
    onPublish,
  };
};
