import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import {
  RECRUITMENT_BASE_PATH,
  RECRUITMENT_APPLICATION_FORM_EDIT_HISTORY_PATH,
  RECRUITMENT_APPLICATION_FORM_PATH,
} from '../../constants';
import EditHistoryListPage, { editHistoryFilterKeys } from './edit-history-listing-page';
import EditHistoryDetailPage from './edit-history-detail-page';
import { NOT_FOUND_ERROR_PAGE_PATH } from '../../../../common/constants';
import PruRoute from '../../../../common/components/PruRoute';
import ParamsProvider from 'src/app/common/components/ParamsProvider';

export const editHistoryBasePath = `${RECRUITMENT_BASE_PATH}${RECRUITMENT_APPLICATION_FORM_PATH}${RECRUITMENT_APPLICATION_FORM_EDIT_HISTORY_PATH}`;
const EditHistoryRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect exact from={`${editHistoryBasePath}`} to={`${editHistoryBasePath}/list`} />
      <PruRoute
        exact
        path={`${editHistoryBasePath}/list`}
        render={(props) => (
          <ParamsProvider {...props} acceptKeys={editHistoryFilterKeys}>
            <EditHistoryListPage />
          </ParamsProvider>
        )}
      />

      <PruRoute exact path={`${editHistoryBasePath}/detail/:detailId`} component={EditHistoryDetailPage} />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default EditHistoryRoutes;
