import { createContext, useState, useCallback, useContext } from 'react';
import { Snackbar } from '@mui/material';
import { useStyles } from './toast.style';

interface ToastContextType {
  hide?: () => void;
  show?: (args: ToastType) => void;
  toast?: ToastType;
}

export interface ToastType {
  visible?: boolean;
  message?: string;
  duration?: number;
  className?: string;
}
const initialToast: ToastType = {
  visible: false,
};

export const ToastContext = createContext<ToastContextType>({
  hide: undefined,
  show: undefined,
  toast: undefined,
});
let toastSnapshot: ToastType = initialToast;
export const ToastProvider = ({ children }: any) => {
  const [toast, setToast] = useState<ToastType>(toastSnapshot);

  const show = (args: any) => {
    toastSnapshot = { ...toastSnapshot, visible: true, ...args };
    setToast(toastSnapshot);
  };

  const hide = useCallback(() => {
    toastSnapshot = initialToast;
    setToast(initialToast);
  }, [toast]);
  return (
    <>
      <ToastContext.Provider
        value={{
          hide,
          show,
          toast,
        }}
      >
        {children}
        <ToastContextView />
      </ToastContext.Provider>
    </>
  );
};
export const ToastContextView = () => {
  const { toast, hide } = useContext(ToastContext);
  const { classes } = useStyles();
  return (
    <Snackbar
      className={`${classes.toast} ${toast?.className}`}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={toast?.visible}
      autoHideDuration={toast?.duration ?? 5000}
      onClose={hide}
      message={toast?.message}
    />
  );
};
