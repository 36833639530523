import React from "react";
import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/Add';
import { Button, IconButton, Tooltip } from '@mui/material';

const tableHeader = (Translation: any, AddEvent: any, refreshData: any, enableCreate: any) => [
    [
        { type: 'header', label: Translation('title.mandatory.training.collection.list'), sm: true },
        {
            type: 'custom',
            sm: false,
            render: () =>
                <Tooltip title="Refresh">
                    <IconButton style={{ marginRight: 15 }} onClick={refreshData}>
                        <RefreshIcon />
                    </IconButton>
                </Tooltip>
        },
        {
            type: 'custom',
            sm: false,
            render: () =>
                enableCreate && <Button variant="contained" color="secondary" onClick={AddEvent} >
                   <AddIcon />{Translation('app.button.add')}
                </Button>
        },
    ],
];


export default tableHeader;