import React from 'react';
import { getDefaultDisplayDate } from 'src/app/common/utils';
import { displayPublishStatus } from './utils';

interface tableConfigType {
  title: string;
  prop: string;
  align?: any;
  render?: any;
  minWidth?: number;
  sorter?: boolean | false;
}

const getNameFromI18nData = (code: string, row: any) => {
  const data = row.i18nData.find((item: any) => {
    // return item.lang === code;
    return item.locale === code;
  });

  return data?.name;
};

const tableConfig = (Translation: any, locale: any, categoriesData: any): tableConfigType[] => {
  return [
    {
      title: Translation('component.formLabel.name'),
      prop: `i18n_name_${locale}`,
      render: (row: any) => <span>{getNameFromI18nData(locale, row)}</span>,
    },
    {
      title: Translation('component.formLabel.category'),
      prop: 'category',
      render: (row: any) => <span>{row.categoryId ? categoriesData[row.categoryId]?.[locale]?.name : '-'}</span>,
    },
    {
      title: Translation('component.formLabel.publish-status'),
      prop: 'publishStatus',
      render: (row: any) => <span>{displayPublishStatus(row.publishStatus, Translation)?.label}</span>,
    },
    {
      title: Translation('component.formLabel.last-updated-time'),
      sorter: true,
      // prop: 'modifyTime',
      prop: 'updatedAt',
      render: (row: any) => <span>{row.updatedAt ? getDefaultDisplayDate(row.updatedAt, 'datetime') : '-'}</span>,
    },
    {
      title: Translation('component.formLabel.last-updated-by'),
      // prop: 'modifier'
      prop: 'updatedBy',
    },
  ];
};

export default tableConfig;
