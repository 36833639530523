import { Dispatch } from 'react';
import { forEach } from 'lodash';
import { getDayStart, getDayEnd, preFillZero } from 'src/app/common/utils';
import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import {
  FeedbackCategoryPaginateList,
  FeedbackCategoryBody,
  FeedbackResultPaginateList,
  FeedbackResultItem,
  CategoryName,
} from '../types/feedback-type';

export type FeedbackCategoryListParam = {
  limit: number;
  page: number;
};

export type FeedbackCategoryTypeParam = {
  category?: string;
};

export type FeedbackResultListParam = {
  from: Date | null;
  to: Date | null;
  category: string;
  subCategory: string;
  responsibleParty: string;
  agentCode: string;
  limit: number;
  page: number;
};

export const fetchFeedbackCategoryList = async (
  params: FeedbackCategoryListParam,
  dispatch?: Dispatch<any>,
): Promise<FeedbackCategoryPaginateList> => {
  let queryPath = `${ADMIN_URL}/feedback/category?`;
  forEach(params, (param, key) => {
    queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
  });
  return apiClient
    .get<FeedbackCategoryPaginateList>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createFeedbackCategory = async (body: FeedbackCategoryBody, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .post<void>(`${ADMIN_URL}/feedback/category`, body, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const modifyFeedbackCategory = async (
  id: string,
  body: FeedbackCategoryBody,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .put<void>(`${ADMIN_URL}/feedback/category/${id}`, body, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const deleteFeedbackCategory = async (id: string, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .delete(`${ADMIN_URL}/feedback/category/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchFeedbackCategoryType = async (
  params: FeedbackCategoryTypeParam,
  dispatch?: Dispatch<any>,
): Promise<CategoryName[]> => {
  let queryPath = `${ADMIN_URL}/feedback/category/type?`;
  forEach(params, (param, key) => {
    queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
  });
  return apiClient
    .get<CategoryName[]>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchFeedbackResultList = async (
  params: FeedbackResultListParam,
  dispatch?: Dispatch<any>,
): Promise<FeedbackResultPaginateList> => {
  let queryPath = `${ADMIN_URL}/feedback?`;
  forEach(params, (param, key) => {
    if (typeof param === 'object') {
      if (param) {
        if (key.toLowerCase().includes('from')) {
          queryPath += `${key}=${encodeURIComponent(getDayStart(param).toISOString())}&`;
        }
        if (key.toLowerCase().includes('to')) {
          queryPath += `${key}=${encodeURIComponent(getDayEnd(param).toISOString())}&`;
        }
      }
    } else {
      if (key === 'agentCode') {
        queryPath += param ? `${key}=${preFillZero(String(param))}&` : '';
      } else {
        queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
      }
    }
  });
  return apiClient
    .get<FeedbackResultPaginateList>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchFeedbackResultItem = async (id: string, dispatch?: Dispatch<any>): Promise<FeedbackResultItem> => {
  return apiClient
    .get<FeedbackResultItem>(`${ADMIN_URL}/feedback/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
