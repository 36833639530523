import React, { FC, useEffect, useReducer, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import {
  Button,
  TextField,
  FormControl,
  Select,
  MenuItem,
  LinearProgress,
  FormHelperText,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { getConfig } from 'src/app/i18n/I18nConfig';
import { useDispatch } from 'react-redux';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import {
  AgentProfileItem,
  StatusEnum,
  AboutMe,
  MyHighlight,
  MultiLanguage,
  imageUploadType,
} from 'src/app/modules/Profile/types/agent-profile-types';
import { agentProfilePath } from '../../AgentProfileRoutes';
import { UpdateAgentProfileBody, modifyAgentProfile } from 'src/app/modules/Profile/network/agentProfileCrud';
import { createBlob, getBlob } from 'src/app/common/network';
import {
  ErrorFieldType,
  useErrorHandler,
  deleteEmptyString,
  fileUpload,
  getFileExtendingName,
} from 'src/app/common/utils';
import ConfirmDialog from 'src/app/modules/PulseLeads/pages/Rule/ActivityPoint/PointRuleSettings/List/component/ComfirnDialog';
import { PruToast } from 'src/app/common/components/pru-toast';
import TextFieldWithLimit from 'src/app/common/components/TextFieldWithLimit';
import { REPORT_PERFORMANCE_PRODUCTION_TARGET_NAMESPACE } from 'src/app/modules/Report/constants';
import _ from 'lodash';
import TextWithCloseButton from 'src/app/common/components/TextWithCloseButton';

type AgentProfileFormProps = {
  agentProfile: AgentProfileItem;
  tier: any;
};

const FIELD_CONTAINER_WIDTH = 180;

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  sectionMargin: {
    marginBottom: 15,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,
  },
  fieldContainer: {
    width: FIELD_CONTAINER_WIDTH,
    boxSizing: 'border-box',
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'center',

    alignItems: 'center',
  },
  textAreaFieldContainer: {
    paddingTop: 15,
    minWidth: FIELD_CONTAINER_WIDTH,
    maxWidth: 180,
    boxSizing: 'border-box',
  },
  textArea: {
    lineHeight: 1.5,
    minHeight: 40,
  },
  downloadTemplateBtn: {
    color: 'blue',
    cursor: 'pointer',
    textDecoration: 'underline',
    whiteSpace: 'nowrap',
  },
  chooseFileBtn: {
    marginRight: 25,
  },
  sectionContainer: {
    marginBottom: 30,
  },
}));

type AgentProfileFormState = {
  name: string;
  agentCode: string;
  tier: string;
  status: string;
  myAboutMe: Record<string, string>;
  myHighlight: Record<string, string>;
  myBanner: Record<string, string>;
  enableMyAboutMe: boolean;
  enableMyHighlight: boolean;
  myBannerEnUrl: string;
  myBannerLocalUrl: string;
  myHighlightUrl: string;
};

type UploadFormState = {
  blobId?: string;
  filename?: string;
};
type AgentSettingUploadFormState = {
  myHighlight: UploadFormState;
  myBanner: UploadFormState;
};

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof AgentProfileFormState;
    value: any;
  };
};
type UploadFileAction = {
  type: 'UPLOAD_FILE';
  payload: {
    field: keyof AgentSettingUploadFormState;
    value: any;
  };
};
export type ModifyNestedAction = {
  type: 'MODIFY_CONTENT';
  payload: {
    field: keyof AgentProfileFormState;
    subField: any;
    value: any;
  };
};
type AgentProfileFormAction = ModifyFieldAction | UploadFileAction | ModifyNestedAction;

const formReducer = (state: AgentProfileFormState, action: AgentProfileFormAction): AgentProfileFormState => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    case 'UPLOAD_FILE':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    case 'MODIFY_CONTENT':
      if (action.payload.field === 'myAboutMe') {
        state.myAboutMe[action.payload.subField as keyof MultiLanguage] = action.payload.value;
      } else if (action.payload.field === 'myHighlight') {
        state.myHighlight[action.payload.subField as keyof MultiLanguage] = action.payload.value;
      } else if (action.payload.field === 'myBanner') {
        if (state.myBanner) {
          state.myBanner[action.payload.subField as keyof MultiLanguage] = action.payload.value;
        } else {
          state.myBanner = { enUs: '', zhHk: '' };
          state.myBanner[action.payload.subField as keyof MultiLanguage] = action.payload.value;
        }
      }
      return {
        ...state,
      };
    default:
      return state;
  }
};

const detailToStateConvertor = (agentProfile: AgentProfileItem, lang: string): AgentProfileFormState => {
  return {
    name: agentProfile.name[lang === 'en' ? 'enUs' : 'zhHK'] || '-',
    agentCode: agentProfile.agentCode,
    tier: agentProfile.tier,
    status: agentProfile.status,
    myAboutMe: agentProfile.myAboutMe || { enUs: '', zhHk: '' },
    myHighlight: agentProfile.myHighlight || { enUs: '', zhHk: '' },
    enableMyAboutMe: agentProfile.enableMyAboutMe,
    enableMyHighlight: agentProfile.enableMyHighlight,
    myBanner: agentProfile.myBanner,
    myBannerEnUrl: '',
    myBannerLocalUrl: '',
    myHighlightUrl: '',
  };
};
type DialogState = {
  open: boolean;
  id: string;
  method: string;
};
enum ToolTip {
  CANCEL = 'agentProfile.ad_reminder_cancel_message',
  DELETE = 'agentProfile.ad_delete_message',
}

const AgentProfileForm: FC<AgentProfileFormProps> = ({ agentProfile, tier }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const dispatch = useDispatch();
  const history = useHistory();
  const { classes: commonClasses } = useCommonStyles();
  const { classes } = useStyles();

  const TranslationWithVariable = (key: string, val: number | string) => intl.formatMessage({ id: key }, { lang: val });

  const lang = getConfig().selectedLang;
  const [formState, formDispatch] = useReducer(formReducer, detailToStateConvertor(agentProfile, lang));
  const [uploadFileProgress, setUploadFileProgress] = useState<number>();

  const [toolTip, setToolTip] = useState<string>();

  const [dialogState, setDialogState] = useState<DialogState>({
    open: false,
    id: '',
    method: '',
  });

  useEffect(() => {
    const blodId = lang ? formState.myHighlight?.enUs : formState.myHighlight?.zhHk || '';
    const bannerIdEn = formState.myBanner?.enUs || '';
    const bannerIdLocal = formState.myBanner?.zhHk || '';
    if (!blodId && !bannerIdEn && !bannerIdLocal) return;

    const action = getResourceById(blodId, 'myHighlightUrl');
    const bannerEn = getResourceById(bannerIdEn, 'myBannerEnUrl');
    const bannerLocal = getResourceById(bannerIdLocal, 'myBannerLocalUrl');
    return () => {
      action;
      bannerEn;
      bannerLocal;
    };
  }, [formState.myHighlight, formState.myBanner]);
  const { errorState, onSubmitErrorValidator, onDismissErrorHandler, immediateErrorValidator } = useErrorHandler(
    formState,
    [
      {
        name: 'myHighlight',
        fieldType: ErrorFieldType.MANDATORY,
        condition() {
          return !formState.enableMyHighlight ? false : formState.myHighlight?.enUs ? false : true;
        },
      },
      {
        name: 'tier',
        fieldType: ErrorFieldType.MANDATORY,
        condition() {
          return tier.some((item: any) => item.tierCode === formState.tier) ? false : true;
        },
      },
      {
        name: 'myAboutMe',
        fieldType: ErrorFieldType.MANDATORY,
        condition() {
          return !formState.enableMyAboutMe ? false : formState.myAboutMe.enUs ? false : true;
        },
      },
      {
        name: 'myBanner',
        fieldType: ErrorFieldType.MANDATORY,
        condition() {
          return formState.tier !== 'custom' ? false : formState.myBanner.enUs ? false : true;
        },
      },
    ],
  );
  const onSubmit = async () => {
    const { hasError, currentErrorState } = onSubmitErrorValidator();

    if (hasError) return;
    if (agentProfile) {
      console.log('agentProfile', agentProfile);

      const body: UpdateAgentProfileBody = {
        tier: formState.tier || '',
        status: formState.status || '',
        myAboutMe: formState.myAboutMe,

        enableMyAboutMe: formState.enableMyAboutMe,
        enableMyHighlight: formState.enableMyHighlight,

        myHighlight:
          lang === 'en'
            ? { enUs: formState.myHighlight?.enUs, zhHk: agentProfile.myHighlight?.zhHk }
            : { enUs: agentProfile.myHighlight.enUs, zhHk: formState.myHighlight?.zhHk },
        myBanner: formState.myBanner,
      };

      if (formState.tier !== 'custom') delete body.myBanner;
      if (!formState.enableMyAboutMe) delete body.myAboutMe;

      deleteEmptyString(body.myBanner);

      try {
        await modifyAgentProfile(agentProfile.id, body, dispatch);
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: 'Success',
              content: `Agent Profile updated successfully`,
            },
          ]),
        );
        history.push(agentProfilePath);
      } catch (err) {}
    }
  };

  const onCancel = () => {
    try {
      setToolTip(Translation(ToolTip.CANCEL));
      setDialogState({ open: true, id: agentProfile.id, method: 'onCancel' });
    } catch (err) {}
  };

  const onClose = () => {
    setDialogState({ open: false, id: '', method: '' });
    setToolTip('');
  };

  const onConfirm = () => {
    if (dialogState.method === 'onCancel') {
      PruToast({
        message: Translation('agentProfile.ad_cancelled_successfully_label'),
      });
      history.push(agentProfilePath);
      return;
    } else if (dialogState.method === 'onDelete') {
      onDelete();
      history.push(agentProfilePath);
    }
  };
  const onDelete = async () => {
    try {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `agent profile deleted successfully - ${agentProfile.id}`,
          },
        ]),
      );
      history.push(agentProfilePath);
    } catch (err) {}
  };

  const getResourceById = async (id: string, method: 'myBannerEnUrl' | 'myBannerLocalUrl' | 'myHighlightUrl') => {
    try {
      if (!id) return;
      const blobDetail = await getBlob({ resourceIds: id }, dispatch);

      const result = blobDetail[0];

      if (result) {
        formDispatch({ type: 'MODIFY_FIELD', payload: { field: method, value: result.blobName } });
      }
    } catch (e) {
      console.log('get resource error -->', e);
    }
  };

  const handleFile = async (
    e: React.ChangeEvent<HTMLInputElement>,
    fileType: keyof AgentSettingUploadFormState,

    language: string,
    fileName: 'myBannerEnUrl' | 'myBannerLocalUrl' | 'myHighlightUrl',
  ) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      console.log('handleFile', file);

      try {
        const createBlobRes = await createBlob(
          { mimeType: file.type, accessLevel: 'public', originalFilename: file.name, module: 'agentProfile' },
          dispatch,
        );
        await fileUpload(createBlobRes.url, file, setUploadFileProgress);
        const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);

        const result = blobDetail[0];

        if (result) {
          formDispatch({
            type: 'MODIFY_CONTENT',
            payload: {
              field: fileType,
              subField: language,
              value: result.blobId,
            },
          });
          formDispatch({
            type: 'MODIFY_FIELD',
            payload: {
              field: fileName,

              value: result.blobId,
            },
          });
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: `Upload file successfully - ${file.name}`,
              },
            ]),
          );
        }
      } finally {
        setUploadFileProgress(undefined);
      }
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <ConfirmDialog
          key={`delete-settings-rule-dialog-${dialogState.open}`}
          open={dialogState.open}
          onClose={() => onClose()}
          onRefresh={() => ''}
          onConfirm={async () => onConfirm()}
          title={Translation('agentProfile.ad_reminder_title')}
          content={toolTip}
        />
        <div className={classes.rowContainer}>
          <div className={commonClasses.header}>{Translation('agentProfile.ad_edit_agent_settings_header_title')}</div>
        </div>
      </div>

      <div className={classes.sectionMargin}>
        <div className={classes.rowContainer}>
          <div className="col">
            <div className={classes.fieldContainer}>
              <span className={classes.field}>
                {Translation('agentProfile.ad_agent_name_label')}
                <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <div>
              <TextField
                disabled={true}
                style={{ width: 400 }}
                margin="dense"
                value={formState.name}
                inputProps={{ maxLength: 50 }}
              />
            </div>
          </div>

          <div className="col">
            <div className={classes.fieldContainer}>
              <span className={classes.field}>
                {Translation('agentProfile.ad_agent_code_label')}
                <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <TextField
                disabled={true}
                style={{ width: 400 }}
                margin="dense"
                value={formState.agentCode}
                inputProps={{ maxLength: 50 }}
              />
            </div>
          </div>
        </div>

        <div className={classes.rowContainer}>
          <div className="col">
            <div className={classes.fieldContainer}>
              <span className={classes.field}>
                {Translation('agentProfile.ad_tier_name_label')}
                <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <FormControl margin="dense">
                <Select
                  variant="standard"
                  style={{ minWidth: 400 }}
                  value={formState.tier || ''}
                  onChange={(e) => {
                    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'tier', value: e.target.value } });
                  }}
                >
                  {tier.map((value: any, index: React.Key) => (
                    <MenuItem key={index} value={value.tierCode}>
                      {value?.tierCode}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {errorState?.mandatory.tier && (
                <FormHelperText style={{ color: '#f018a6' }}>
                  {Translation('agentProfile.ad_mandatory_field_reminder')}
                </FormHelperText>
              )}
            </div>
          </div>

          <div className="col">
            <div className={classes.fieldContainer}>
              <span className={classes.field}>
                {Translation('agentProfile.ad_status_label')}
                <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <FormControl margin="dense">
                <Select
                  variant="standard"
                  style={{ minWidth: 400 }}
                  value={formState.status || ''}
                  onChange={(e) => {
                    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'status', value: e.target.value } });
                  }}
                >
                  {Object.entries(StatusEnum).map(([key, value]) => (
                    <MenuItem key={key} value={value}>
                      {Translation(`agentProfile.ad_${value}_label`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>

        <div className={classes.rowContainer} style={{ alignItems: 'start' }}>
          <div className="col">
            {formState.tier === 'custom' && (
              <>
                <div className={classes.fieldContainer}>
                  <span>{Translation('agentProfile.ad_banner_local_label')}</span>
                </div>
                <div>
                  <input
                    id="upload-file-mybanner-local"
                    hidden
                    type="file"
                    accept="image/jpg, image/jpeg, image/png"
                    onClick={(e) => {
                      const element = e.target as HTMLInputElement;
                      element.value = '';
                    }}
                    onChange={(e) => {
                      if (e.target.files && e.target.files.length > 0) {
                        const file = e.target.files[0];
                        const fileExtend = getFileExtendingName(file.name).toLowerCase();
                        if (imageUploadType.has(fileExtend)) {
                          const fileSize = file.size / 1024 / 1024;
                          const errMsg = Translation('agentProfile.ad_exceed_max_file_size_reminder') || '';
                          if (fileSize > 2) {
                            dispatch(
                              appendAlertItem([
                                {
                                  severity: AlertType.ERROR,
                                  title: '',
                                  content: errMsg,
                                },
                              ]),
                            );
                            return;
                          }
                          handleFile(e, 'myBanner', 'zhHk', 'myBannerLocalUrl');
                        } else {
                          dispatch(
                            appendAlertItem([
                              {
                                severity: AlertType.WARNING,
                                title: Translation('fail_title'),
                                content: Translation('agentProfile.ad_upload_fail_file_format_message'),
                              },
                            ]),
                          );
                        }
                      }
                    }}
                  />

                  <div className={classes.rowContainer}>
                    {
                      formState.myBannerLocalUrl && (
                        <TextWithCloseButton
                          content={formState.myBannerLocalUrl}
                          onClose={() => {
                            formDispatch({
                              type: 'MODIFY_CONTENT',
                              payload: {
                                field: 'myBanner',
                                subField: 'zhHk',
                                value: '',
                              },
                            });
                            formDispatch({
                              type: 'MODIFY_FIELD',
                              payload: {
                                field: 'myBannerLocalUrl',
                                value: '',
                              },
                            });
                          }}
                        />
                      )
                      // <span className={classes.field}>{formState.myBannerLocalUrl}</span>
                    }
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => document.getElementById('upload-file-mybanner-local')!.click()}
                      className={classes.chooseFileBtn}
                    >
                      {Translation('app.button.chooseFile')}
                    </Button>
                    <span className={classes.field} style={{ marginRight: 0, maxWidth: 320 }}>
                      {Translation('agentProfile.ad_image_upload_recommendation')}:
                    </span>
                  </div>
                </div>

                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => {
                        if (e.target.checked) {
                          formDispatch({
                            type: 'MODIFY_FIELD',
                            payload: {
                              field: 'myBannerEnUrl',

                              value: formState.myBannerLocalUrl,
                            },
                          });
                          formDispatch({
                            type: 'MODIFY_CONTENT',
                            payload: {
                              field: 'myBanner',
                              subField: 'enUs',
                              value: formState.myBanner.zhHk,
                            },
                          });
                        }
                      }}
                      name="copyMainLangVersion"
                      color="secondary"
                    />
                  }
                  label={Translation('agentProfile.ad_use_local_option')}
                />
                <div>
                  <div className={classes.fieldContainer}>
                    <span>
                      {Translation('agentProfile.ad_banner_en_label')}
                      <span className={classes.mandatory}>*</span>
                    </span>
                  </div>
                  <input
                    id="upload-file-mybanner-en"
                    hidden
                    type="file"
                    accept="image/jpg, image/jpeg, image/png"
                    onClick={(e) => {
                      const element = e.target as HTMLInputElement;
                      element.value = '';
                    }}
                    onChange={(e) => {
                      if (e.target.files && e.target.files.length > 0) {
                        const file = e.target.files[0];
                        const fileExtend = getFileExtendingName(file.name).toLowerCase();
                        if (imageUploadType.has(fileExtend)) {
                          const fileSize = file.size / 1024 / 1024;
                          const errMsg = Translation('agentProfile.ad_exceed_max_file_size_reminder') || '';
                          if (fileSize > 2) {
                            dispatch(
                              appendAlertItem([
                                {
                                  severity: AlertType.ERROR,
                                  title: '',
                                  content: errMsg,
                                },
                              ]),
                            );
                            return;
                          }
                          handleFile(e, 'myBanner', 'enUs', 'myBannerEnUrl');
                        } else {
                          dispatch(
                            appendAlertItem([
                              {
                                severity: AlertType.WARNING,
                                title: Translation('fail_title'),
                                content: Translation('agentProfile.ad_upload_fail_file_format_message'),
                              },
                            ]),
                          );
                        }
                      }
                    }}
                  />

                  <div className={classes.rowContainer}>
                    {
                      formState.myBannerEnUrl && (
                        <TextWithCloseButton
                          content={formState.myBannerEnUrl}
                          onClose={() => {
                            formDispatch({
                              type: 'MODIFY_CONTENT',
                              payload: {
                                field: 'myBanner',
                                subField: 'enUs',
                                value: '',
                              },
                            });
                            formDispatch({
                              type: 'MODIFY_FIELD',
                              payload: {
                                field: 'myBannerEnUrl',

                                value: '',
                              },
                            });
                          }}
                        />
                      )
                      //  <span className={classes.field}>{formState.myBannerEnUrl}</span>
                    }
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => document.getElementById('upload-file-mybanner-en')!.click()}
                      className={classes.chooseFileBtn}
                    >
                      {Translation('app.button.chooseFile')}
                    </Button>

                    <span className={classes.field} style={{ marginRight: 0, maxWidth: 320 }}>
                      {Translation('agentProfile.ad_image_upload_recommendation')}:
                    </span>
                  </div>
                  {errorState?.mandatory.myBanner && !formState.myBanner.enUs && (
                    <FormHelperText style={{ color: '#f018a6' }}>This field is mandatory</FormHelperText>
                  )}
                  {/* {!!uploadFileProgress && (
                    <LinearProgress
                      style={{ marginTop: 10 }}
                      variant="determinate"
                      color="secondary"
                      value={uploadFileProgress}
                    />
                  )} */}
                </div>
              </>
            )}

            <div className={classes.fieldContainer}>
              <span className={classes.field}>{Translation('agentProfile.ad_introduction_label')}:</span>
            </div>
            <div>
              <FormControl margin="dense">
                <Select
                  variant="standard"
                  style={{ minWidth: 400 }}
                  value={formState.enableMyAboutMe === true ? 'custom' : 'standard' || ''}
                  onChange={(e) => {
                    const target = e.target.value === 'custom' ? true : false;
                    formDispatch({
                      type: 'MODIFY_FIELD',
                      payload: { field: 'enableMyAboutMe', value: target },
                    });
                  }}
                >
                  {Object.entries(AboutMe).map(([key, value]) => (
                    <MenuItem key={key} value={value}>
                      {Translation(`agentProfile.ad_${value}_label`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div>
                {formState.enableMyAboutMe && (
                  <>
                    <div style={{ maxWidth: 400 }}>
                      <span>{Translation('agentProfile.ad_introduction_local_label')}</span>
                      :
                      <TextFieldWithLimit
                        value={formState.myAboutMe?.zhHk || ''}
                        limitHelpText={Translation('agentProfile.ad_character_count_label')}
                        limit={500}
                        onChange={(e) => {
                          formDispatch({
                            type: 'MODIFY_CONTENT',
                            payload: {
                              field: 'myAboutMe',
                              subField: 'zhHk',
                              value: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) => {
                            if (e.target.checked) {
                              formDispatch({
                                type: 'MODIFY_CONTENT',
                                payload: {
                                  field: 'myAboutMe',
                                  subField: 'enUs',
                                  value: formState.myAboutMe.zhHk,
                                },
                              });
                            }
                          }}
                          name="copyMainLangVersion"
                          color="secondary"
                        />
                      }
                      label={Translation('agentProfile.ad_use_local_option')}
                    />
                    <div style={{ maxWidth: 400, height: 100 }}>
                      <span>{Translation('agentProfile.ad_introduction_en_label')}</span>
                      <span className={classes.mandatory}>*</span> :
                      <TextFieldWithLimit
                        value={formState.myAboutMe?.enUs || ''}
                        limitHelpText={Translation('agentProfile.ad_character_count_label')}
                        limit={500}
                        onChange={(e) => {
                          formDispatch({
                            type: 'MODIFY_CONTENT',
                            payload: { field: 'myAboutMe', subField: 'enUs', value: e.target.value },
                          });
                        }}
                      />
                      {errorState?.mandatory.myAboutMe && !formState.myAboutMe.enUs && (
                        <FormHelperText style={{ color: '#f018a6' }}>
                          {Translation('agentProfile.ad_mandatory_field_reminder')}
                        </FormHelperText>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="col">
            <div className={classes.fieldContainer}>
              <span className={classes.field}>
                {Translation('agentProfile.ad_my_highlights_label')}
                <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <FormControl margin="dense">
                <Select
                  variant="standard"
                  style={{ minWidth: 400 }}
                  value={formState.enableMyHighlight === true ? 'yes' : 'no' || ''}
                  onChange={(e) => {
                    const target = e.target.value === 'yes' ? true : false;
                    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'enableMyHighlight', value: target } });
                  }}
                >
                  {Object.entries(MyHighlight).map(([key, value]) => (
                    <MenuItem key={key} value={value}>
                      {Translation(`agentProfile.ad_${value}_label`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {errorState?.mandatory.myHighlight && !formState.myHighlightUrl && formState.enableMyHighlight && (
                <FormHelperText style={{ color: '#f018a6' }}>
                  {Translation('agentProfile.ad_mandatory_field_reminder')}
                </FormHelperText>
              )}
            </div>
            {formState.enableMyHighlight && (
              <div className="col">
                <div className={classes.sectionContainer}>
                  <div className={classes.rowContainer}>
                    <input
                      id="upload-file"
                      hidden
                      type="file"
                      // accept="video/mp4"
                      onClick={(e) => {
                        const element = e.target as HTMLInputElement;
                        element.value = '';
                      }}
                      onChange={(e) => {
                        if (e.target.files && e.target.files.length > 0) {
                          const file = e.target.files[0];
                          const fileType = file.type;

                          if (fileType === 'video/mp4') {
                            const fileSize = file.size / 1024 / 1024;
                            const errMsg = Translation('agentProfile.ad_exceed_max_file_size_reminder') || '';
                            if (fileSize > 20) {
                              dispatch(
                                appendAlertItem([
                                  {
                                    severity: AlertType.ERROR,
                                    title: '',
                                    content: errMsg,
                                  },
                                ]),
                              );
                              return;
                            }
                            handleFile(e, 'myHighlight', 'enUs', 'myHighlightUrl');
                          } else {
                            dispatch(
                              appendAlertItem([
                                {
                                  severity: AlertType.WARNING,
                                  title: Translation('fail_title'),
                                  content: Translation('agentProfile.ad_upload_fail_file_format_message'),
                                },
                              ]),
                            );
                          }
                        }
                      }}
                    />

                    <div className={classes.rowContainer}>
                      {formState.myHighlightUrl && (
                        <TextWithCloseButton
                          content={formState.myHighlightUrl}
                          onClose={() => {
                            formDispatch({
                              type: 'MODIFY_CONTENT',
                              payload: {
                                field: 'myHighlight',
                                subField: 'enUs',
                                value: null,
                              },
                            });
                            formDispatch({
                              type: 'MODIFY_FIELD',
                              payload: {
                                field: 'myHighlightUrl',

                                value: '',
                              },
                            });
                          }}
                        />
                      )}
                      {/* <span className={classes.field}>{formState.myHighlightUrl}</span> */}
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => document.getElementById('upload-file')!.click()}
                        className={classes.chooseFileBtn}
                      >
                        {Translation('app.button.chooseFile')}
                      </Button>

                      <span className={classes.field}>
                        {Translation('agentProfile.ad_video_upload_recommendation')}:
                      </span>
                    </div>

                    {!!uploadFileProgress && (
                      <div style={{ width: '100% ' }}>
                        <LinearProgress
                          style={{ marginTop: 10 }}
                          variant="determinate"
                          color="secondary"
                          value={uploadFileProgress}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className={classes.footerContainer}>
        <div
          style={{
            display: 'flex',
            boxSizing: 'border-box',
            minWidth: 350,
            justifyContent: 'space-around',
          }}
        >
          <Button variant="contained" color="secondary" onClick={onCancel}>
            {Translation('app.button.cancel')}
          </Button>
          <Button variant="contained" color="secondary" onClick={onSubmit}>
            {Translation('app.button.save')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AgentProfileForm;
