import React from 'react';
import { getDefaultDisplayDate } from 'src/app/common/utils';

const columns = (Translation: any, locale: string) => {
  const columns = [
    {
      title: Translation('survey.common.title'),
      dataIndex: 'title',
      hideInSearch: true,
    },
    {
      title: Translation('survey.common.noOfQuestions'),
      dataIndex: 'noOfQuestions',
      hideInSearch: true,
    },
    {
      title: Translation('survey.common.startDate'),
      dataIndex: 'startDate',
      hideInSearch: true,
      render: (data: any) => <span>{data ? getDefaultDisplayDate(data, 'datetime') : '-'}</span>,
    },
    {
      title: Translation('survey.common.endDate'),
      dataIndex: 'endDate',
      hideInSearch: true,
      render: (data: any) => <span>{data ? getDefaultDisplayDate(data, 'datetime') : '-'}</span>,
    },
    {
      title: Translation('survey.common.createdAt'),
      dataIndex: 'createdAt',
      hideInSearch: true,
      render: (data: any) => {
        return <span>{data ? getDefaultDisplayDate(data, 'datetime') : '-'}</span>;
      },
    },
    {
      title: Translation('survey.common.updatedAt'),
      dataIndex: 'updatedAt',
      hideInSearch: true,
      render: (data: any) => {
        return <span>{data ? getDefaultDisplayDate(data, 'datetime') : '-'}</span>;
      },
    },
  ];

  return columns;
};

export default columns;
