import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import PruRoute from 'src/app/common/components/PruRoute';
import ParamsProvider from 'src/app/common/components/ParamsProvider';
import { INSIGHTS_BASE_PATH, INSIGHTS_DASHBOARD_PATH, INSIGHTS_DASHBOARD_OVERVIEW_PATH } from '../../../constants';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import OverviewReportListingPage, { overviewReportFilterKeys } from './List/OverviewReportListingPage';

const overviewReportPath = `${INSIGHTS_BASE_PATH}${INSIGHTS_DASHBOARD_PATH}${INSIGHTS_DASHBOARD_OVERVIEW_PATH}`;

const OverviewReportRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect exact from={`${overviewReportPath}`} to={`${overviewReportPath}/list`} />
      <PruRoute
        exact
        path={`${overviewReportPath}/list`}
        render={(props) => (
          <ParamsProvider {...props} acceptKeys={overviewReportFilterKeys}>
            <OverviewReportListingPage />
          </ParamsProvider>
        )}
      />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default OverviewReportRoutes;
