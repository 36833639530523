export const PULSELEADS_AGENT_TITLE = "title.pulseleads.agent";
export const PULSELEADS_AGENT_LEAD_SOURCE_TITLE = "title.pulseleads.agent.leadSource";
export const PULSELEADS_AGENT_REASSIGN_LEAD_TITLE = "title.pulseleads.agent.reassignLead";
export const PULSELEADS_AGENT_REDISTRIBUTE_LEAD_TITLE = "title.pulseleads.agent.redistributeLead";
export const PULSELEADS_AGENT_AUTO_DROP_LEAD_TITLE = 'title.pulseleads.agent.autoDropLead';

export const PULSELEADS_AGENT_LEAD_SOURCE_NAMESPACE = "PULSELEADS-AGENT-LEAD-SOURCE";
export const PULSELEADS_AGENT_REASSIGN_LEAD_NAMESPACE = "PULSELEADS-AGENT-REASSIGN-LEAD";
export const PULSELEADS_AGENT_REDISTRIBUTE_LEAD_NAMESPACE = "PULSELEADS-AGENT-REDISTRIBUTE-LEAD";
export const PULSELEADS_AGENT_AUTO_DROP_LEAD_NAMESPACE = 'PULSELEADS-AGENT-AUTO-DROP-LEAD';

export const PULSELEADS_AGENT_PATH = "/agent";
export const PULSELEADS_AGENT_LEAD_SOURCE_PATH = "/lead-source";
export const PULSELEADS_AGENT_REASSIGN_LEAD_PATH = "/reassign-lead";
export const PULSELEADS_AGENT_REDISTRIBUTE_LEAD_PATH = '/redistribute-lead';
export const PULSELEADS_AGENT_AUTO_DROP_LEAD_PATH = '/auto-drop-lead';
