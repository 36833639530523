import React from 'react';
import Form from '../../../../../../../../common/components/Form';
import { useIntl } from 'react-intl';
import { ColumnPaginateList, ColumnPublishedMode } from '../../../../types/column-types';
import { makeStyles } from 'tss-react/mui';
import { Button, TextField, CircularProgress, FormControlLabel, RadioGroup, Radio } from '@mui/material';
import {
  NumberGreaterThanZeroPattern,
  disableInputInvalidValueForWeight,
  getEnabledCustomWeight,
} from 'src/app/common/utils';
import { forEach, get } from 'lodash';
import { regionLocale, regionLocaleMap } from 'src/app/i18n';
import { validateColumWeight } from '../../../../network/columnCrud';

const useStyles = makeStyles()((theme) => ({
  footerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  container: {
    padding: 20,
  },
}));

interface AddColumnProps {
  disabled: boolean;
  onSave: (formData: any) => void;
  onCancel: () => void;
  confirmLoading: boolean;
  initialValues: any;
  module: string;
}

const Create: React.FC<AddColumnProps> = ({ disabled, onSave, onCancel, confirmLoading, initialValues, module }) => {
  const { classes } = useStyles();
  const [form] = Form.useForm();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const regionLocales = regionLocale;
  const WEIGHT_VIDATOR = [
    { required: true, message: Translation('component.form-weight-text') },
    {
      pattern: NumberGreaterThanZeroPattern,
      message: Translation('recruit-library-weight-greater-zero'),
    },
    {
      async validator(_: any, value: string) {
        const isInactive = form.getFieldValue('status') === 'Inactive';

        if (isInactive) {
          return Promise.resolve();
        }

        if (!value) {
          return Promise.reject(new Error(Translation('component.form-weight-text')));
        }

        if (!(await validateColumWeight(module, value, initialValues.id))) {
          return Promise.reject(new Error(Translation('component.not.in.column.form-weight-diff-text')));
        }

        return Promise.resolve();
      },
    },
  ];

  const onFinish = (values: any) => {
    onSave(values);
  };

  const cancel = () => {
    onCancel();
  };

  const placeEnter = Translation('app.input.placeholder.please-enter');
  const emptyValue = Translation('component.hint.empty-value-not-allowed');

  return (
    <Form form={form} onFinish={onFinish} initialValues={{ ...initialValues }} className={classes.container}>
      {regionLocales.map((locale, index) => (
        <Form.Item
          name={locale}
          label={`${Translation('component.formLabel.name')} (${Translation(regionLocaleMap[locale].name)})`}
          rules={[
            {
              required: true,
              message: emptyValue,
            },
          ]}
        >
          <TextField margin="dense" variant="outlined" fullWidth placeholder={placeEnter} disabled={disabled} />
        </Form.Item>
      ))}

      <Form.Item
        name="description"
        required={false}
        label={Translation('component.formLabel.description')}
        rules={[{ required: false, message: emptyValue }]}
      >
        <TextField margin="dense" variant="outlined" fullWidth placeholder={placeEnter} disabled={disabled} />
      </Form.Item>
      <Form.Item
        name="status"
        label={Translation('component.formLabel.status')}
        rules={[{ required: true, message: emptyValue }]}
        // required={false}
      >
        <RadioGroup aria-label="contentType" name="contentType" style={{ display: 'flex', flexDirection: 'row' }}>
          <FormControlLabel
            value="Active"
            control={<Radio disabled={disabled} />}
            label={Translation('component.formSelectItem.active')}
          />
          <FormControlLabel
            value="Inactive"
            control={<Radio disabled={disabled} />}
            label={Translation('component.formSelectItem.inactive')}
          />
        </RadioGroup>
      </Form.Item>

      <Form.Item name="weight" label={Translation('component.formLabel.weight')} rules={WEIGHT_VIDATOR}>
        <TextField
          type="number"
          margin="dense"
          variant="outlined"
          fullWidth
          placeholder={placeEnter}
          disabled={disabled}
          inputProps={disableInputInvalidValueForWeight()}
        />
      </Form.Item>

      <div className={classes.footerContainer}>
        {!disabled ? (
          <>
            {/* todo: cancel warling */}
            <Button variant="contained" color="inherit" onClick={cancel}>
              {Translation('app.button.cancel')}
            </Button>
            <Button
              disabled={confirmLoading}
              type="submit"
              style={{ marginLeft: 20 }}
              variant="contained"
              color="secondary"
              onClick={() => {}}
            >
              {Translation('app.button.submit')}
              {confirmLoading && <CircularProgress style={{ marginLeft: 8 }} size={15} />}
            </Button>
          </>
        ) : null}
      </div>
    </Form>
  );
};

export default Create;
