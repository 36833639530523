import { FC } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import PruRoute from 'src/app/common/components/PruRoute';
import ParamsProvider from 'src/app/common/components/ParamsProvider';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { EVENT_BASE_PATH, EVENT_CATEGORY_PATH } from '../../constants';
import { eventCategoryFilterKeys } from './list/event-category-listing-page.hook';
import { EventCategoryListingPage } from './list/event-category-listing-page.screen';

export const eventCategoryPath = `${EVENT_BASE_PATH}${EVENT_CATEGORY_PATH}`;

const EventCategoryRoutes: FC = () => {
  return (
    <Switch>
      <Redirect exact from={eventCategoryPath} to={`${eventCategoryPath}/list`} />
      <PruRoute
        exact
        path={`${eventCategoryPath}/list`}
        render={(props) => (
          <ParamsProvider {...props} acceptKeys={eventCategoryFilterKeys}>
            <EventCategoryListingPage />
          </ParamsProvider>
        )}
      />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default EventCategoryRoutes;
