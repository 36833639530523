import React, { FC, HTMLAttributes } from 'react';
import { withStyles } from 'tss-react/mui';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
  TextField,
  IconButton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { makeStyles } from 'tss-react/mui';
import { ConfigAgentKeyValueItem } from '../../../../types/config-type';

type DisplayKeyValueTableProps = {
  isDisabled: boolean;
  keyValue: ConfigAgentKeyValueItem[];
  onAddRow: () => void;
  onDeleteRow: (index: number) => void;
  onMoveRow: (from: number, to: number) => void;
  onModifyField: (index: number, field: keyof ConfigAgentKeyValueItem, value: string) => void;
};

const useStyles = makeStyles()((theme) => ({
  inputBox: {
    paddingTop: 6,
    paddingBottom: 8
  },
  headerOperationField: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

const CustomTableCell = withStyles(TableCell, (theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: '1rem',
    paddingTop: 10,
    paddingBottom: 10
  },
  body: {
    fontWeight: 'bold',
    paddingTop: 10,
    paddingBottom: 10
  }
}));

const DisplayKeyValueTable: FC<DisplayKeyValueTableProps & HTMLAttributes<HTMLDivElement>> = ({
  isDisabled,
  keyValue,
  onAddRow,
  onMoveRow,
  onDeleteRow,
  onModifyField,
  ...rest
}) => {
  const { classes } = useStyles();

  return (
    <>
      {/* {keyValue && keyValue.length > 0 && */}
      <TableContainer {...rest} component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <CustomTableCell>
                Key
                </CustomTableCell>
              <CustomTableCell>Value</CustomTableCell>
              <CustomTableCell align="right">
                <IconButton
                  disabled={isDisabled}
                  style={{ padding: 5, color: 'white' }}
                  onClick={onAddRow}
                >
                  <AddIcon />
                </IconButton>
              </CustomTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {keyValue.map((keyValue, index) => (
              <TableRow key={`keyValue-row-${index}`}>
                <CustomTableCell>
                  <TextField
                    disabled={isDisabled}
                    fullWidth
                    style={{ margin: 0 }}
                    InputProps={{
                      classes: {
                        input: classes.inputBox
                      }
                    }}
                    margin="dense"
                    variant="outlined"
                    value={keyValue.key}
                    onChange={e => onModifyField(index, 'key', e.target.value)}
                  />
                </CustomTableCell>
                <CustomTableCell>
                  <TextField
                    disabled={isDisabled}
                    fullWidth
                    style={{ margin: 0 }}
                    InputProps={{
                      classes: {
                        input: classes.inputBox
                      }
                    }}
                    margin="dense"
                    variant="outlined"
                    value={keyValue.value}
                    onChange={e => onModifyField(index, 'value', e.target.value)}
                  />
                </CustomTableCell>
                <CustomTableCell align="right">
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <IconButton
                      style={{ padding: 5 }}
                      onClick={() => onDeleteRow(index)}
                      disabled={isDisabled}
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  </div>
                </CustomTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* } */}
    </>
  )
};

export default DisplayKeyValueTable;
