import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  standardDatePickerIcon: {
    '& .MuiIconButton-root': {
      marginRight: 2,
    },
  },
  dateTimePickerIcon: {
    marginRight: -12,
  },
  standardDateTimePickerIcon: {
    marginRight: 2,
  },
}));
