import React, { FC, useState, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Button, CircularProgress, Dialog, DialogTitle } from '@mui/material';
import {
  createNewCategory,
  deleteCategory,
  fetchCategoryItem,
  fetchCategoryList,
  modifyCategory,
  publishCategory,
} from '../../network/categoryCrud';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { appendAlertItem, AlertType } from '../../../../../../../redux/common/commonSlice';
import AddCategoryForm from './components/Create';
import { ModulePermissionProps } from '../../../../../../common/module/types';
import {
  CategoryEditItem,
  CategoryFormState,
  CategoryItem,
  CategoryLevel,
  CategoryListParam,
  CategoryPaginateList,
} from '../../types/category-types';
import { useDataProvider, useJWT } from 'src/app/common/utils';
import CategoryList from './components/categoryList';
import { fetchLibraryList } from '../../../Library/network/libraryCrud';
import { useLang } from 'src/app/i18n';

const useStyles = makeStyles()((theme) => ({
  dialogContainer: {
    padding: '20px',
  },
  btnContainer: {
    padding: '0 20px 20px 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

interface CategoryProps extends ModulePermissionProps {
  filterName: string;
  blockName: string;
  blockType: string;
  moduleType: string;
  addSuccessText: string;
}

const paramsInitiator = (moduleType: string): CategoryListParam => {
  return {
    page: 1,
    pageSize: 1000,
    isActive: '',
    module: moduleType,
  };
};

const DEDAULT_FORM_DATA: CategoryFormState = {
  category: '',
  subCategory: '',
  thirdCategory: '',
  description: '',
};

const ListingPage: FC<CategoryProps> = ({
  filterName,
  blockName,
  blockType,
  addSuccessText,
  moduleType,
  enableRead,
  enableCreate,
  enableUpdate,
}) => {
  const locale = useLang();
  const dispatch = useDispatch();
  const intl = useIntl();
  const { classes } = useStyles();

  const [filterState, setFilterState] = useState<CategoryListParam>(paramsInitiator(moduleType));
  const [sortKey, setSortKey] = useState<{ key: string; value?: string }[]>([]);

  const [createModalVisible, handleModalVisible] = useState<boolean>(false);
  const [modalType, handleModalType] = useState<string>(moduleType);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<object>({});
  const [selectedId, setSelectedId] = useState<string>('');
  const [categoryList, setCategoryList] = useState<CategoryPaginateList>();
  const [allCategory, setAllCategory] = useState<CategoryPaginateList>();
  const [isDiasbledForm, handleDisabledForm] = useState<boolean>(false);
  const [deleteDialogId, setDeleteDialogId] = useState<string>('');
  const [deleteDialog, setDeleteDialog] = useState<boolean>(false);
  const Translation = (id: string) => intl.formatMessage({ id });
  const jwt = useJWT();

  const { isLoading, refreshData } = useDataProvider<CategoryPaginateList>(
    async () => {
      try {
        let resultList = await fetchCategoryList(filterState, sortKey, dispatch);
        resultList = await appendChildrenDataToList(resultList);
        setAllCategory(resultList);
        return resultList;
      } catch (err) {}
    },
    setCategoryList,
    false,
  );

  useEffect(() => {
    refreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortKey]);

  const updateSortingKey = (sortingKey: { key: string; value?: string }) => {
    const sortingKeyArray = sortKey.filter((currentValue, index, arr) => {
      return currentValue.key !== sortingKey.key;
    });
    sortingKeyArray.unshift(sortingKey);
    setSortKey(sortingKeyArray);
  };

  const appendChildrenDataToList = async (originalList: CategoryPaginateList): Promise<CategoryPaginateList> => {
    let resultList = originalList.categoryList;

    //Remove if parentExist
    resultList = resultList.filter((listItem) => !listItem.parent);

    //Add childrenItem under
    for (let i = 0; i < resultList.length; i++) {
      //@ts-ignore
      if (resultList[i].children?.count && resultList[i].children?.count > 0) {
        let level2List = [];
        //Fetch level1 detail
        let listItemDetail = await fetchCategoryItem(resultList[i].id);

        //@ts-ignore
        for (let j = 0; j < listItemDetail.children.length; j++) {
          //Fetch level2 detail
          //@ts-ignore
          let level2listItemDetail = await fetchCategoryItem(listItemDetail.children[j].id);
          //@ts-ignore
          level2List.push({ ...level2listItemDetail, level: 'two' });
        }
        resultList[i]['children'] = level2List;
      }
      resultList[i]['level'] = CategoryLevel.ONE;
    }

    const resultData = { ...originalList, categoryList: resultList };
    return resultData;
  };

  const addCategory = (levelType: string) => {
    handleModalType(levelType);
    handleModalVisible(true);
  };

  const editCategory = async (rowData: CategoryItem, mode: string, lang: string = 'en') => {
    setSelectedId(rowData.id);
    const editParam = await convertEditParams(rowData, lang);
    setInitialValues({ ...editParam });
    if (mode === 'view') {
      handleDisabledForm(true);
    }
    handleModalVisible(true);
  };

  const convertEditParams = async (rowData: CategoryItem, lang: string) => {
    let result: any = {};
    const [category1, category2, category3] = await parentCategory(rowData);
    result = {
      ...DEDAULT_FORM_DATA,
      category: category1 || '',
      subCategory: category2 || '',
      thirdCategory: category3 || '',
      description: rowData.description || '',
    };
    return result;
  };

  const parentCategory = async (rowData: CategoryItem) => {
    let category1, category2, category3;
    try {
      if (rowData.level === CategoryLevel.ONE) {
        category1 = rowData.name;
        handleModalType(CategoryLevel.ONE);
      } else if (rowData.level === CategoryLevel.TWO) {
        //@ts-ignore
        category1 = rowData.parent?.id || '';
        category2 = rowData.name;
        handleModalType(CategoryLevel.TWO);
      } else {
        category2 = (rowData.parent as string) || '';
        const getLevel1Parent = await fetchCategoryItem(category2);
        //@ts-ignore
        category1 = getLevel1Parent.parent.id || '';
        category3 = rowData.name;
        handleModalType(CategoryLevel.THREE);
      }
    } catch (err) {
      console.log(err);
    }
    return [category1, category2, category3];
  };

  const onCancel = () => {
    handleDisabledForm(false);
    handleModalVisible(false);
    setSelectedId('');
    setInitialValues({});
  };

  const saveData = async (formData: CategoryFormState) => {
    setFormLoading(true);

    const submitData: CategoryEditItem = {
      name:
        formData.level === CategoryLevel.ONE
          ? formData.category
          : formData.level === CategoryLevel.TWO
            ? formData.subCategory
            : formData.thirdCategory,
      description: formData.description,
      parent:
        formData.level === CategoryLevel.ONE
          ? undefined
          : formData.level === CategoryLevel.TWO
            ? formData.category
            : formData.subCategory,
      module: moduleType,
    };
    if (selectedId) {
      //Edit
      await callSubmitEditAction(selectedId, submitData, formData);
    } else {
      // Create
      await callSubmitCreateAction(submitData, formData);
    }
  };

  const callSubmitEditAction = async (
    selectedId: string,
    sumbitData: CategoryEditItem,
    formData: CategoryFormState,
  ) => {
    try {
      const resultData = await modifyCategory(selectedId, sumbitData, dispatch);
      console.log(resultData);
      submitSuccess();
    } catch (err) {
      submitFail();
    }
  };

  const callSubmitCreateAction = async (sumbitData: CategoryEditItem, formData: CategoryFormState) => {
    try {
      const resultData = await createNewCategory(sumbitData, dispatch);
      // await publishCategory(resultData.id, dispatch); //Call publish again, all category should be in published mode
      submitSuccess();
    } catch (err) {
      submitFail();
    }
  };

  const submitSuccess = () => {
    dispatch(
      appendAlertItem([
        {
          severity: AlertType.SUCCESS,
          title: Translation('global.submit.success'),
          content: Translation('global.submit.success'),
        },
      ]),
    );
    handleModalVisible(false);
    refreshData();
    setFormLoading(false);
    setInitialValues({ ...DEDAULT_FORM_DATA });
    setSelectedId('');
  };

  const submitFail = () => {
    dispatch(
      appendAlertItem([
        {
          severity: AlertType.ERROR,
          title: Translation('global.submit.fail'),
          content: Translation('global.submit.fail'),
        },
      ]),
    );
    setFormLoading(false);
  };

  const deleteAnEntry = async (rowData: CategoryItem) => {
    console.log(rowData);
    //Check if any material link to this category
    const linkedMaterial = await fetchLibraryList('', { category: rowData.name, module: moduleType }, []);
    if (
      (rowData.children && (rowData.children as CategoryItem[]).length > 0) ||
      linkedMaterial.libraryList.length > 0
    ) {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.WARNING,
            title: Translation('global.submit.fail'),
            content: Translation('global.links.content'),
          },
        ]),
      );
    } else {
      setDeleteDialogId(rowData.id);
    }
  };

  const onConfirmDelete = async (e: any) => {
    setDeleteDialog(true);
    try {
      await deleteCategory(deleteDialogId);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: Translation('global.submit.success'),
            content: Translation('global.alert.updated'),
          },
        ]),
      );
      setDeleteDialogId('');
      setDeleteDialog(false);
      refreshData();
    } catch (err) {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.ERROR,
            title: Translation('global.submit.fail'),
            content: Translation('global.submit.fail'),
          },
        ]),
      );
      setDeleteDialogId('');
      setDeleteDialog(false);
    }
  };

  return (
    <>
      <CategoryList
        isLoading={isLoading}
        categoryList={categoryList}
        categoryTableTitle={blockName}
        onRefresh={refreshData}
        onSort={(dataKey) => updateSortingKey(dataKey)}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            pageSize: rowsPerPage,
          });
          refreshData();
        }}
        onAddCategory={(levelType) => addCategory(levelType)}
        onEditCategory={(rowData, mode) => editCategory(rowData, mode, locale)}
        onDeleteCategory={(rowData) => deleteAnEntry(rowData)}
        enableUpdate={enableUpdate}
        enableCreate={enableCreate}
      />

      <Dialog open={createModalVisible} onClose={isDiasbledForm ? onCancel : () => {}} fullWidth>
        <DialogTitle>
          {isDiasbledForm
            ? Translation('global.view.text')
            : selectedId
              ? Translation('global.edit.text')
              : Translation('global.add.text')}
        </DialogTitle>
        <AddCategoryForm
          onSave={saveData}
          onCancel={onCancel}
          type={modalType}
          confirmLoading={formLoading}
          initialValues={initialValues}
          allCategories={allCategory?.categoryList}
          disabled={isDiasbledForm}
          categoryId={selectedId}
        />
      </Dialog>
      <Dialog open={!!deleteDialogId} onClose={() => setDeleteDialogId('')}>
        <div className={classes.dialogContainer}>{Translation('global.text.delete')}</div>
        <div className={classes.btnContainer}>
          <Button style={{ marginRight: 20 }} variant="contained" color="inherit" onClick={() => setDeleteDialogId('')}>
            {Translation('global.text.no')}
          </Button>
          <Button variant="contained" color="secondary" onClick={onConfirmDelete}>
            {Translation('global.text.yes')}
            {deleteDialog ? <CircularProgress style={{ marginLeft: 8 }} size={15} /> : null}
          </Button>
        </div>
      </Dialog>
    </>
  );
};

export default ListingPage;
