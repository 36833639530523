import { PaginateList } from '../../../common/types/common-types';
import { AttachmentDef } from 'src/app/common/types/common-types';
export enum SurveyFormMode {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
}

interface completeMessage {
  title: string;
  content: string;
}

export type SurveyListItem = {
  id: string;
  title: string;
  noOfQuestions: number;
  status: number;
  startDate: Date;
  endDate: Date;
  createdAt: Date;
  updatedAt: Date;
};

export type SurveyDetail = {
  _id: string;
  startDate: Date;
  endDate: Date;
  eligibleAgents: string[];
  excludedAgents: string[];
  designations: string[];
  body: Record<string, unknown>;
  createdAt: Date;
  updatedAt: Date;
  consents: string[];
  numberofTnc: number;
};

export type SurveyResultItem = {
  agentCode: string;
  body: Record<string, unknown>;
  createdAt: Date;
  updatedAt: Date;
};
export type SurveyPaginateList = PaginateList<SurveyListItem> & {
  totalRecords: number;
};

export type SurveyResult = {
  surveyId: string;
  surveyBody: Record<string, unknown>;
  surveyResults: SurveyResultItem[];
  page: number;
  hasNextPage: boolean;
};

export type SurveySummaryResult = {
  totalViews: number;
  totalSubmits: number;
  totalRecords: number;
  totalUnread: number;
  totalIncomplete: number;
};
