import React, { FC } from "react";
import { useIntl } from 'react-intl';
import { makeStyles } from 'tss-react/mui';
import {
    Button,
    TextField,
    FormControlLabel,
    RadioGroup,
    Radio,
    CircularProgress,
    Dialog,
    DialogTitle,
} from '@mui/material';
import Form from 'src/app/common/components/Form';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import MutiLangList from '../../../../../components/MutiLangList';

const useStyles = makeStyles()((theme) => ({
    footerContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 20
    },
    container: {
        padding: 20
    }
}));

const ImportCourseForm: FC<any> = (props) => {
    // eslint-disable-next-line
    const {  setImportPopUpVisible, courseSource, onSave } = props;
    const intl = useIntl();
    const Translation = (id: string) => intl.formatMessage({ id });
    const { classes } = useStyles();
    const { classes: commonClasses } = useCommonStyles();
    const [form] = Form.useForm();


    const placeEnter = Translation('app.input.placeholder.please-enter');

    const onFinish = (values: any) => {
        const allValues = form.getFieldsValue(true);
        onSave(allValues);
    };

    const cancel = async () => {
        setImportPopUpVisible(false);
    };

    courseSource.name = courseSource.courseDesc || courseSource.name;


    return (
        <Form
            form={form}
            onFinish={onFinish}
            initialValues={courseSource}
            className={classes.container}
        >
            <Form.Item
                name="courseCode"
                label={Translation('mandatory.training.component.table.course.code')}
                required={false}
            >
                <TextField
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    disabled={true}
                    placeholder={placeEnter}
                />
            </Form.Item>

            <Form.Item
                name="name"
                label={Translation('mandatory.training.component.table.course.name')}
                rules={[{ required: true, message: Translation('component.hint.empty-value-not-allowed') },]}
            >
                <TextField
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    multiline
                    placeholder={placeEnter}
                    InputProps={{
                        classes: {
                            input: commonClasses.textArea
                        },
                    }}
                />
            </Form.Item>

            <p className={commonClasses.subHeader}>{Translation('mandatory.training.component.table.course.type')}</p>

            <Form.Item
                required={false}
                noStyle
            >

                {(_, meta, { getFieldValue }) => {

                    return (
                        <MutiLangList
                            listName="i18nData"
                            name="type"
                            subTitle={true}
                            getFieldValue={getFieldValue}
                        />
                    )
                }}
            </Form.Item>



            <Form.Item
                name="deepLink"
                label={Translation('mandatory.training.component.table.course.deeplink')}
                required={false}
            >
                <TextField
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    placeholder={placeEnter}
                />
            </Form.Item>

            <p className={commonClasses.subHeader}>{Translation('mandatory.training.component.table.course.description')}</p>

            <Form.Item
                noStyle
                required={false}
            >

                {(_, meta, { getFieldValue }) => {

                    return (
                        <MutiLangList
                            listName="i18nData"
                            name="description"
                            multiLine={true}
                            getFieldValue={getFieldValue}
                            subTitle={true}
                            label={` `}
                        />
                    )
                }}
            </Form.Item>


            <div className={classes.footerContainer}>
                <Button
                    variant="contained"
                    color="inherit"
                    onClick={cancel}
                >
                    {Translation('app.button.cancel')}
                </Button>
                <Button
                    type="submit"
                    style={{ marginLeft: 20 }}
                    variant="contained"
                    color="secondary"
                    onClick={() => { }}
                >
                    {Translation('app.button.submit')}
                </Button>
            </div>
        </Form>
    )
}

export default ImportCourseForm;