import React, { FC, useState, useEffect, useRef } from 'react';
import { Dialog, DialogTitle } from '@mui/material';
import { createNewColumn, modifyColumn, publishColumn, unpublishColumn } from '../../network/columnCrud';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { appendAlertItem, AlertType } from '../../../../../../../redux/common/commonSlice';
import AddColumnForm from './components/Create';
import { ModulePermissionProps } from '../../../../../../common/module/types';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import {
  ColumnEditItem,
  ColumnFormState,
  ColumnItem,
  ColumnListParam,
  ColumnPaginateList,
  ColumnPublishedMode,
} from '../../types/column-types';
import ColumnList from './components/columnList';
import { forEach } from 'lodash';
import { useLang } from 'src/app/i18n';
import { regionLocale } from 'src/app/i18n';
import { useJWT } from 'src/app/common/utils';

interface ColumnProps extends ModulePermissionProps {
  filterName: string;
  blockName: string;
  blockType: string;
  moduleType: string;
  addSuccessText: string;
}

const paramsInitiator = (moduleType: string): ColumnListParam => {
  return {
    page: 0,
    pageSize: 10,
    isActive: '',
    module: moduleType,
  };
};

const DEDAULT_FORM_DATA: ColumnFormState = {
  description: '',
  status: '',
  weight: null,
};

const ListingPage: FC<ColumnProps> = ({
  filterName,
  blockName,
  blockType,
  addSuccessText,
  moduleType,
  enableRead,
  enableCreate,
  enableUpdate,
}) => {
  const locale = useLang();
  const dispatch = useDispatch();
  const intl = useIntl();
  const regionLocales = regionLocale;

  const status = useRef(ColumnPublishedMode.ALL);

  const [createModalVisible, handleModalVisible] = useState<boolean>(false);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<object>({});
  const [selectedId, setSelectedId] = useState<string>('');
  const [isDiasbledForm, handleDisabledForm] = useState<boolean>(false);
  const tableRef = useRef();
  const Translation = (id: string) => intl.formatMessage({ id });
  const jwt = useJWT();

  const filterOptions = {
    activeStatus: [
      { displayName: Translation('component.status.all'), value: ColumnPublishedMode.ALL },
      { displayName: Translation('component.formSelectItem.active'), value: ColumnPublishedMode.ACTIVE },
      { displayName: Translation('component.formSelectItem.inactive'), value: ColumnPublishedMode.INACTIVE },
    ],
  };

  const refreshData = () => {
    if (tableRef.current) {
      (tableRef.current as any).refreshTable();
    }
  };

  const editColumn = async (rowData: ColumnItem, mode: string, lang: string = 'en') => {
    setSelectedId(rowData.id);
    const editParam = await convertEditParams(rowData, lang);
    setInitialValues({ ...editParam, id: rowData.id });
    if (mode === 'view') {
      handleDisabledForm(true);
    }
    handleModalVisible(true);
  };

  const convertEditParams = async (rowData: ColumnItem, lang: string) => {
    let result: any = {};
    result = {
      ...DEDAULT_FORM_DATA,
      description: rowData.description || '',
      weight: rowData.weight,
      status: rowData.publishedAt ? ColumnPublishedMode.ACTIVE : ColumnPublishedMode.INACTIVE,
    };

    forEach(regionLocales, (localeItem) => {
      //@ts-ignore
      result[localeItem] = rowData.name[localeItem] || '';
    });
    return result;
  };

  const onCancel = () => {
    handleDisabledForm(false);
    handleModalVisible(false);
    setSelectedId('');
    setInitialValues({});
  };

  const saveData = async (formData: ColumnFormState) => {
    setFormLoading(true);

    let nameValue: any = {};
    forEach(regionLocales, (localeItem) => {
      //@ts-ignore
      nameValue[localeItem] = formData[localeItem] || '';
    });

    const currentLocaleSubmitData: ColumnEditItem = {
      name: nameValue,
      description: formData.description,
      weight: formData.weight ? parseFloat(formData.weight.toString()) : null,
      module: moduleType,
    };
    if (selectedId) {
      //Edit
      await callSubmitEditAction(selectedId, currentLocaleSubmitData, formData);
    } else {
      // Create
      await callSubmitCreateAction(currentLocaleSubmitData, formData);
    }
  };

  const callSubmitEditAction = async (selectedId: string, sumbitData: ColumnEditItem, formData: ColumnFormState) => {
    try {
      const resultData = await modifyColumn(selectedId, sumbitData, dispatch);
      console.log(resultData);
      if (formData.status === ColumnPublishedMode.INACTIVE && resultData.publishedAt) {
        //If existing is in Publish mode, and user want to save as draft
        await unpublishColumn(resultData.id);
      }
      if (formData.status === ColumnPublishedMode.ACTIVE && !resultData.publishedAt) {
        //If existing is in draft mode, and user want to save as publish
        await publishColumn(resultData.id);
      }
      submitSuccess();
    } catch (err) {
      submitFail();
    }
  };

  const callSubmitCreateAction = async (sumbitData: ColumnEditItem, formData: ColumnFormState) => {
    try {
      const resultData = await createNewColumn(sumbitData, dispatch);
      if (formData.status === ColumnPublishedMode.ACTIVE) {
        // call publish again
        await publishColumn(resultData.id, dispatch);
      }
      submitSuccess();
    } catch (err) {
      submitFail();
    }
  };

  const submitSuccess = () => {
    dispatch(
      appendAlertItem([
        {
          severity: AlertType.SUCCESS,
          title: Translation('global.submit.success'),
          content: Translation('global.submit.success'),
        },
      ]),
    );
    handleModalVisible(false);
    refreshData();
    setFormLoading(false);
    setInitialValues({ ...DEDAULT_FORM_DATA });
    setSelectedId('');
  };

  const submitFail = () => {
    dispatch(
      appendAlertItem([
        {
          severity: AlertType.ERROR,
          title: Translation('global.submit.fail'),
          content: Translation('global.submit.fail'),
        },
      ]),
    );
    setFormLoading(false);
  };

  return (
    <>
      <PruFilter
        title={Translation(filterName)}
        itemDef={[
          {
            type: PruFilterItemType.DROPDOWN,
            style: { width: 150 },
            field: 'isActive',
            initialValue: ColumnPublishedMode.ALL,
            displayName: Translation('component.formLabel.status'),
            choices: filterOptions.activeStatus,
          },
        ]}
        onChangeFilter={(data) => {
          status.current = data.isActive;
        }}
        fetchData={refreshData}
      />

      <ColumnList
        ref={tableRef}
        module={moduleType}
        status={status}
        columnTableTitle={blockName}
        onAddColumn={() => handleModalVisible(true)}
        onEditColumn={(rowData, mode) => editColumn(rowData, mode, locale)}
        enableUpdate={enableUpdate}
        enableCreate={enableCreate}
      />

      <Dialog open={createModalVisible} onClose={isDiasbledForm ? onCancel : () => {}} fullWidth>
        <DialogTitle>
          {isDiasbledForm
            ? Translation('global.view.text')
            : selectedId
              ? Translation('global.edit.text')
              : Translation('global.add.text')}
        </DialogTitle>
        <AddColumnForm
          onSave={saveData}
          onCancel={onCancel}
          confirmLoading={formLoading}
          initialValues={initialValues}
          disabled={isDiasbledForm}
          module={moduleType}
        />
      </Dialog>
    </>
  );
};

export default ListingPage;
