import React, { FC, useState, useEffect, useMemo } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { LayoutSplashScreen } from 'src/app/layout';
import { CampaignMappingFormMode } from 'src/app/modules/PulseLeads/types/straight-through-types';
import { CampaignDropdownList, CampaignDropdownType } from 'src/app/modules/PulseLeads/types/campaign-types';
import { ProductCampaignItem } from 'src/app/modules/PulseLeads/types/product-campaign-types';
import {
  fetchCampaignDropdownList,
  CampaignDropdownListParam,
  ConfigParam,
  fetchConfig,
} from 'src/app/modules/PulseLeads/network/campaignCrud';
import {
  fetchProductCampaignItem,
  fetchProductCampaignOptions,
} from 'src/app/modules/PulseLeads/network/productCampaignCrud';
import ProductCampaignForm from './components/ProductCampaignForm';
import { EventCampaignOptions } from '../../../../types/event-campaign-types';

const ProductCampaignDetailPage: FC<RouteComponentProps> = ({ history }) => {
  const { id } = useParams<{ id: string }>();
  const { formMode } = useMemo(
    () => ({
      formMode: id ? CampaignMappingFormMode.EDIT : CampaignMappingFormMode.CREATE,
    }),
    [id],
  );

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [productCampaignItem, setProductCampaignItem] = useState<ProductCampaignItem>();
  const [productCampaignDropdownList, setProductCampaignDropdownList] = useState<CampaignDropdownList>();
  const [productCampaignOptions, setProductCampaignOptions] = useState<EventCampaignOptions>();
  const [config, setConfig] = useState<any>();

  const reloadData = async () => {
    if (id) {
      setIsLoading(true);
      try {
        const campaignItemRes = await fetchProductCampaignItem(id, dispatch);
        setProductCampaignItem(campaignItemRes);
      } catch (err) {
        history.push(NOT_FOUND_ERROR_PAGE_PATH);
      }
    }
    try {
      const params: CampaignDropdownListParam = {
        type: [CampaignDropdownType.CAMPAIGN],
      };
      const dropdownListRes = await fetchCampaignDropdownList(params, dispatch);
      setProductCampaignDropdownList(dropdownListRes);

      const optionRes = await fetchProductCampaignOptions(dispatch);
      setProductCampaignOptions(optionRes);
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  const reloadConfig = async () => {
    const configParams: ConfigParam = {
      name: 'sales-sdk',
      namespace: 'default',
    };
    const configRes = await fetchConfig(configParams, dispatch);
    setConfig(configRes.data[0]);
  };

  useEffect(() => {
    reloadConfig();
  }, []);

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line
  }, [id]);

  return (
    <>
      {isLoading ? (
        <LayoutSplashScreen />
      ) : (
        <>
          {formMode === CampaignMappingFormMode.CREATE && (
            <ProductCampaignForm
              formMode={formMode}
              productCampaignDropdownList={productCampaignDropdownList}
              productCampaignOptions={productCampaignOptions}
              config={config}
            />
          )}
          {formMode === CampaignMappingFormMode.EDIT &&
            (isLoading || !productCampaignItem ? (
              <LayoutSplashScreen />
            ) : (
              <ProductCampaignForm
                formMode={formMode}
                productCampaignDropdownList={productCampaignDropdownList}
                productCampaignOptions={productCampaignOptions}
                productCampaignItem={productCampaignItem}
                config={config}
              />
            ))}
        </>
      )}
    </>
  );
};

export default ProductCampaignDetailPage;
