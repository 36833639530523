import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { getConfig } from 'src/app/i18n/I18nConfig';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import ItemDeleteDialog from 'src/app/common/components/ItemDeleteDialog';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import { deleteAgentProfile } from 'src/app/modules/Profile/network/agentProfileCrud';
import { agentProfilePath } from '../../AgentProfileRoutes';
import { PaginateList } from 'src/app/common/types/common-types';
import { AgentProfileItem } from 'src/app/modules/Profile/types/agent-profile-types';

type AgentProfileListProps = {
  isLoading: boolean;
  agentProfileList?: PaginateList<AgentProfileItem>;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
};

type DialogState = {
  open: boolean;
  id: string;
};

const AgentProfileList: FC<AgentProfileListProps> = ({ isLoading, agentProfileList, onRefresh, onChangePage }) => {
  const history = useHistory();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const lang = getConfig().selectedLang;

  const [dialogState, setDialogState] = useState<DialogState>({
    open: false,
    id: '',
  });

  const onDelete = async (id: string) => {
    try {
      await deleteAgentProfile(id, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Agent Profile deleted successfully`,
          },
        ]),
      );
    } catch (err) {}
  };

  return (
    <>
      <ItemDeleteDialog
        key={`delete-application-dialog-${dialogState.open}`}
        open={dialogState.open}
        onClose={() => setDialogState({ open: false, id: '' })}
        onRefresh={onRefresh}
        onDelete={() => onDelete(dialogState.id)}
      />
      <PruTable
        title={Translation('agentProfile.ad_agent_list_header_title')}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('agentProfile.ad_add_list_button'),
            onClick: () => history.push(`${agentProfilePath}/massUpload`),
          },
        ]}
        disableBulkSelect
        operationDef={[
          {
            title: Translation('agentProfile.ad_edit_label'),
            tooltipText: 'Edit Agent Profile',
            onClick: (row) => history.push(`${agentProfilePath}/edit/${row._id}`),
          },
        ]}
        columnDef={[
          {
            isId: true,
            hidden: true,
            keyIndex: '_id',
            displayName: '',
            // eslint-disable-next-line
            renderData: (row) => '',
          },
          {
            keyIndex: 'name',
            align: 'center',
            displayName: Translation('agentProfile.ad_agent_name_label'),
            renderData: (row) => row.name[lang === 'en' ? 'enUs' : 'zhHk'] || '-',
          },
          {
            keyIndex: 'agentCode',
            align: 'center',
            displayName: Translation('profile.agent.common.agentCode'),
            renderData: (row) => row.agentCode || '-',
          },
          {
            keyIndex: 'tier',
            align: 'center',
            displayName: Translation('agentProfile.ad_tier_code_label'),
            renderData: (row) => row.tier || '-',
          },
          {
            keyIndex: 'status',
            align: 'center',
            displayName: Translation('agentProfile.ad_status_label'),
            renderData: (row) => Translation(`agentProfile.ad_${row.status}_label`) || '-',
          },
          {
            keyIndex: 'aboutMe',
            align: 'center',
            displayName: Translation('agentProfile.ad_introduction_label'),
            renderData: (row) => (row.enableMyAboutMe ? 'Custom' : 'Standard' || '-'),
          },
          {
            keyIndex: 'highlight',
            align: 'center',
            displayName: Translation('agentProfile.ad_my_highlights_label'),

            renderData: (row) => (row.enableMyHighlight ? 'Yes' : 'No' || '-'),
          },
        ]}
        isLoading={isLoading}
        onRefresh={onRefresh}
        dataSource={agentProfileList?.docs}
        totalPages={agentProfileList?.totalPages}
        totalRecords={agentProfileList?.totalDocs}
        onChangePage={onChangePage}
      />
    </>
  );
};

export default AgentProfileList;
