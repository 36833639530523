import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';
import React, { useContext } from 'react';
import { ModuleContext } from 'src/app/common/module/ModuleProvider';
import PruSwitch from 'src/app/PruSwitch';
import { useProjectSelector } from 'src/redux/store';
import { ASIDE_WIDTH, COLLAPSE_ASIDE_WIDTH, FOOTER_HEIGHT, HEADER_HEIGHT, MOBILE_HEADER_HEIGHT } from '../../const';
import ContentFooter from './content-footer';
import ContentHeader from './content-header';
import ScrollTop from './components/scroll-top';

export const ContentContainer = () => {
  const { classes } = useStyles();
  const { moduleMap } = useContext(ModuleContext);
  const isAsideMenuCollapse = useProjectSelector((state) => state.layout.isAsideMenuCollapse);

  return (
    <div
      className={classes.contentContainer}
      style={{ paddingLeft: isAsideMenuCollapse ? COLLAPSE_ASIDE_WIDTH : ASIDE_WIDTH }}
    >
      <ContentHeader />
      <div className={classes.content}>
        <div className="tw-flex tw-flex-col tw-flex-1 tw-h-full tw-overflow-auto tw-items-center">
          <div className={classes.container}>
            <PruSwitch isRoot nodeList={moduleMap} />
          </div>
        </div>
      </div>
      <ContentFooter />
      <ScrollTop />
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  contentContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: HEADER_HEIGHT,
    minWidth: 0,
    [theme.breakpoints.down('md')]: {
      paddingTop: MOBILE_HEADER_HEIGHT,
      paddingLeft: '0 !important',
    },
    [theme.breakpoints.up('md')]: {
      transition: theme.transitions.create('padding-left', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  content: {
    height: `calc(100vh - ${HEADER_HEIGHT + FOOTER_HEIGHT}px)`, // 100vh - headerHeight - footerHeight (Reference to metronic style content)
    display: 'flex',
    flexDirection: 'column',
    flex: '1 0 auto',
    [theme.breakpoints.down('md')]: {
      paddingBottom: 15,
    },
    [theme.breakpoints.up('md')]: {
      padding: '25px 0',
    },
  },
  container: {
    width: '100%',
    height: '100%',
    maxWidth: 1700,
    [theme.breakpoints.down('md')]: {
      padding: '0 15px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '0 25px',
    },
    // Reference to metronic style content
    '& .event-category-container': {
      height: 'calc(100% - 65px - 165px - 85px)',
    },
    '& .event-list-container': {
      height: 'calc(100% - 65px - 300px - 85px)',
    },
    '& .auto-notification-container': {
      height: 'calc(100% - 65px - 175px - 85px)',
    },
    '& .notification-container': {
      height: 'calc(100% - 65px - 125px - 85px)',
    },
  },
}));
