import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';
import { FC, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getConfigurations, useJWT } from 'src/app/common/utils';
import { TokenInfo } from 'src/app/modules/Auth/types/auth-types';
import {
  InsightsLibraryDEV,
  InsightsLibraryPROD,
  InsightsLibraryUAT,
} from 'src/app/modules/PruleadInsightsReporting/types/insights-types';
import { RootState } from 'src/redux/store';
import { getMongoChartToken } from '../../../../../Auth/_redux/authCrud';
// const baseUrl = window.envConfig['REACT_APP_MONGODB_CHART_BASE_URL'];
var libarary = InsightsLibraryDEV;

type ChartListProps = {
  filterId: string;
  getReceived: (received: any) => void;
  getDistributed: (distributed: any) => void;
  getProgress: (progress: any) => void;
  getClosed: (closed: any) => void;
};

const InsightDetailPage: FC<ChartListProps> = ({ filterId, getReceived, getDistributed, getProgress, getClosed }) => {
  const user = useSelector<RootState, TokenInfo | undefined>((state) => state.auth.user);
  const jwt = useJWT() || {};
  const { region, channel } = jwt;

  const getEnv = useMemo(() => {
    const env = window.envConfig['REACT_APP_ENV'];
    if (env === 'dev' || env === 'coedev') {
      libarary = InsightsLibraryDEV;
    }
    if (env === 'uat') {
      libarary = InsightsLibraryUAT;
    }
    if (env === 'prod') {
      libarary = InsightsLibraryPROD;
    }
  }, []);

  const baseUrl: string = getConfigurations()?.mongoChart?.baseUrl;

  const sdk = new ChartsEmbedSDK({
    baseUrl,
    getUserToken: async function () {
      const response = await getMongoChartToken();
      return response.accessToken;
    },
  });

  const leadsChartDiv = useRef<HTMLDivElement>(null);
  const missedChartDiv = useRef<HTMLDivElement>(null);
  const progressChartDiv = useRef<HTMLDivElement>(null);
  const closedChartDiv = useRef<HTMLDivElement>(null);

  const leadsChart = sdk.createChart({
    chartId: libarary.leadsChartId,
    height: 200,
    theme: 'light',
    filter: {
      $and: [
        { 'campaignInfo.campaignId': filterId },
        { 'agentViewInfo.region': region },
        { 'agentViewInfo.channel': channel },
      ],
    },
  });

  const missedSLAChart = sdk.createChart({
    chartId: libarary.missedSLAChart,
    height: 200,
    theme: 'light',
    filter: {
      $and: [
        { 'campaignInfo.campaignId': filterId },
        { 'agentViewInfo.region': region },
        { 'agentViewInfo.channel': channel },
      ],
    },
  });

  const progressChart = sdk.createChart({
    chartId: libarary.progressChart,
    height: 200,
    theme: 'light',
    filter: {
      $and: [
        { 'campaignInfo.campaignId': filterId },
        { 'agentViewInfo.region': region },
        { 'agentViewInfo.channel': channel },
      ],
    },
  });

  const closedChart = sdk.createChart({
    chartId: libarary.closedChart,
    height: 200,
    theme: 'light',
    filter: {
      $and: [
        { 'campaignInfo.campaignId': filterId },
        { 'agentViewInfo.region': region },
        { 'agentViewInfo.channel': channel },
      ],
    },
  });

  useEffect(() => {
    if (leadsChartDiv.current && missedChartDiv.current && progressChartDiv.current && closedChartDiv.current) {
      leadsChart
        .render(leadsChartDiv.current)
        .then(async () => {
          let data = await leadsChart.getData().then((res) => JSON.parse(JSON.stringify(res)).documents);
          let received = 0;
          let obj = data[0];
          for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
              if (key == 'value') received = obj[key];
              break;
            }
          }
          getReceived(received);
          getDistributed(received);
        })
        .catch((err) => console.log('Error during Charts rendering.', err));

      missedSLAChart.render(missedChartDiv.current).catch((err) => console.log('Error during Charts rendering.', err));

      progressChart
        .render(progressChartDiv.current)
        .then(async () => {
          let data = await progressChart.getData().then((res) => JSON.parse(JSON.stringify(res)).documents);
          let obj = data[0];
          for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
              if (key == 'value') getProgress(obj[key]);
              break;
            }
          }
        })
        .catch((err) => console.log('Error during Charts rendering.', err));

      closedChart
        .render(closedChartDiv.current)
        .then(async () => {
          let data = await closedChart.getData().then((res) => JSON.parse(JSON.stringify(res)).documents);
          let obj = data[0];
          for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
              if (key == 'value') getClosed(obj[key]);
              break;
            }
          }
        })
        .catch((err) => console.log('Error during Charts rendering.', err));
    } else {
      console.log('Error');
    }
  }, [leadsChartDiv, missedChartDiv, progressChartDiv, closedChartDiv]);

  return (
    <>
      <span style={{ fontSize: 18, fontWeight: 600, marginLeft: 45 }}># of Leads</span>
      <div className="chart" ref={leadsChartDiv} />
      <span style={{ fontSize: 18, fontWeight: 600, marginLeft: 45 }}># of SLA Missed</span>
      <div className="chart" ref={missedChartDiv} />
      <span style={{ fontSize: 18, fontWeight: 600, marginLeft: 45 }}># of In Progress Leads</span>
      <div className="chart" ref={progressChartDiv} />
      <span style={{ fontSize: 18, fontWeight: 600, marginLeft: 45 }}># of Closed Leads</span>
      <div className="chart" ref={closedChartDiv} />
    </>
  );
};

export default InsightDetailPage;
