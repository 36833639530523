import React, { FC, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { useIntl } from 'react-intl';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useDataProvider } from 'src/app/common/utils';
import { GetSLAConfigListParams, fetchSLAConfigList } from '../../../network/slaCrud';
import { SLAType, SLAConfigItem } from '../../../types/sla-types';
import { useLocation } from 'react-router-dom';
import { getSLAType } from '../SLARoutes';
import SLAList from './components/SLAList';
import { PaginateList } from 'src/app/common/types/common-types';

type SLAListingPageProps = {} & ParamsProps;

const initialState: GetSLAConfigListParams = {
  name: '',
  type: '',
  limit: 5,
  page: 1,
};

const paramsInitiator = (slaType: SLAType, initialParams?: Record<string, string>): GetSLAConfigListParams => {
  return initialParams
    ? {
        name: initialParams.name ?? '',
        type: slaType,
        limit: 5,
        page: 1,
      }
    : initialState;
};

export const slaFilterKeys = ['name'];

const SLAListingPage: FC<SLAListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const location = useLocation();
  const dispatch = useDispatch();

  // eslint-disable-next-line
  const slaType = useMemo(() => getSLAType(location.pathname) || SLAType.ACCEPT, []);

  const [filterState, setFilterState] = useState<GetSLAConfigListParams>(paramsInitiator(slaType, initialParams));

  const [slaConfigList, setSlaConfigList] = useState<PaginateList<SLAConfigItem>>();
  const { isLoading, refreshData } = useDataProvider<PaginateList<SLAConfigItem>>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchSLAConfigList(filterState, dispatch);
      } catch (err) {}
    },
    setSlaConfigList,
    false,
  );

  return (
    <>
      <PruFilter
        title={Translation(`pulseleads.sla.${slaType}.filter`)}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'name',
            initialValue: filterState.name,
            displayName: Translation('pulseleads.sla.common.name'),
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            name: data.name,
          })
        }
        fetchData={refreshData}
      />
      <SLAList
        isLoading={isLoading}
        slaConfigList={slaConfigList}
        onRefresh={refreshData}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default SLAListingPage;
