import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { PopupItem, PopupPaginateList, RedirectType } from '../../../../types/operation-type';
import PruTable from '../../../../../../common/components/PruTable/PruTable';
import { getDefaultDisplayDate, getCurrentConfig } from 'src/app/common/utils';
import {
  FREQUENCY_TRANSLTAE_MAP,
  LINK_TYPE_TRANSLATE_MAP,
  MAX_POPUP_PUBLISHED_LIMIT,
} from 'src/app/modules/Operation/constants';
import { useSelector } from 'react-redux';
import { AuthenticationState } from 'src/app/modules/Auth/_redux/authSlice';
import { RootState } from 'src/redux/store';
import { isEqual } from 'lodash';

type PopupListProps = {
  isLoading: boolean;
  popupList?: PopupPaginateList;
  enableUpdate: boolean;
  enableCreate: boolean;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
  onSort: (sortingState: { key: string; value?: string }) => void;
  onAddPopup: () => void;
  onEditPopup: (rowData: PopupItem, mode: string) => void;
  onPublishPopupClicked: (rowData: PopupItem) => void;
  onUnpublishPopupClicked: (rowData: PopupItem) => void;
};

const checkIsRecordValid = (row: PopupItem) => {
  return (
    row.publishedAt &&
    (!row.effectiveDate || new Date(row.effectiveDate) <= new Date()) &&
    (!row.expiredDate || new Date(row.expiredDate) >= new Date())
  );
};

const PopupList: FC<PopupListProps> = ({
  isLoading,
  popupList,
  enableUpdate,
  enableCreate,
  onRefresh,
  onChangePage,
  onSort,
  onAddPopup,
  onEditPopup,
  onPublishPopupClicked,
  onUnpublishPopupClicked,
}) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const TranslationWithVariable = (key: string, count: number | string) =>
    intl.formatMessage({ id: key }, { num: count });

  const linkTypeTransMap = (linkType: string): string => {
    return Translation(LINK_TYPE_TRANSLATE_MAP[linkType as keyof typeof LINK_TYPE_TRANSLATE_MAP]);
  };

  const frequencyTransMap = (frequency: string): string => {
    return Translation(FREQUENCY_TRANSLTAE_MAP[frequency as keyof typeof FREQUENCY_TRANSLTAE_MAP]);
  };

  const { user } = useSelector<RootState, AuthenticationState>((state) => state.auth);

  const currentConfig = getCurrentConfig(user);
  const AppRoutes = currentConfig?.AppRoutes as { [key: string]: string };

  const renderUrl = (row: PopupItem) => {
    const linkTo = row.linkTo;
    const linkType = row.linkType;
    const linkParams = row.linkParams;
    let showValue = linkTo;
    if (linkType === RedirectType.app) {
      Object.entries(AppRoutes).some(([label, value]: any) => {
        if (linkTo === value) {
          showValue = Translation(label);
          return true;
        }

        if (linkTo === value?.link && isEqual(linkParams, value?.params)) {
          showValue = Translation(label);
          return true;
        }

        return false;
      });
    }
    return showValue;
  };

  return (
    <PruTable
      title={Translation('component.formTitle.popup-table')}
      subTitle={`(${TranslationWithVariable('component.hint.published-popups-maximum', MAX_POPUP_PUBLISHED_LIMIT)})`}
      disableBulkSelect
      headerBtnDef={[
        {
          color: 'primary',
          title: Translation('app.button.add'),
          onClick: () => {
            onAddPopup();
          },
          condition: () => !!enableCreate,
        },
      ]}
      operationSticky={true}
      operationDef={[
        {
          title: Translation('app.button.view'),
          tooltipText: Translation('app.button.view'),
          onClick: (row) => {
            onEditPopup(row, 'view');
          },
        },
        {
          title: Translation('app.button.edit'),
          tooltipText: Translation('app.button.edit'),
          onClick: (row) => {
            onEditPopup(row, 'edit');
          },
          condition: () => !!enableUpdate,
        },
        // {
        //   title: Translation("app.button.publish"),
        //   tooltipText: Translation("app.button.publish"),
        //   onClick: (row) => onPublishPopupClicked(row),
        //   condition: (row) => !!!(row.published_at) && !!enableUpdate
        // },
        {
          title: Translation('app.button.unpublish'),
          tooltipText: Translation('app.button.unpublish'),
          onClick: (row) => onUnpublishPopupClicked(row),
          condition: (row) => !!row.publishedAt && !!enableUpdate,
        },
      ]}
      columnDef={[
        {
          isId: true,
          keyIndex: 'id',
          hidden: true,
          displayName: '',
          renderData: (row) => '',
        },
        {
          keyIndex: 'name',
          align: 'left',
          displayName: Translation('component.formLabel.name'),
          renderData: (row) => String(row.name ? row.name : ''),
          sortable: true,
          onSort: (sort) => {
            onSort({ key: 'name', value: sort['name'] });
          },
        },
        {
          keyIndex: 'linkType',
          align: 'left',
          displayName: Translation('component.formLabel.link-type'),
          renderData: (row) => String(row.linkType ? linkTypeTransMap(row.linkType) : ''),
          sortable: true,
          onSort: (sort) => {
            onSort({ key: 'linkType', value: sort['linkType'] });
          },
        },
        {
          keyIndex: 'linkTo',
          align: 'left',
          displayName: Translation('component.formLabel.link-to'),
          renderData: (row) => String(row.linkTo ? renderUrl(row) : ''),
          sortable: true,
          onSort: (sort) => {
            onSort({ key: 'linkTo', value: sort['linkTo'] });
          },
        },
        {
          keyIndex: 'frequency',
          align: 'left',
          displayName: Translation('component.formLabel.popup-frequency'),
          renderData: (row) => String(row.frequency ? frequencyTransMap(row.frequency) : ''),
          sortable: true,
          onSort: (sort) => {
            onSort({ key: 'frequency', value: sort['frequency'] });
          },
        },
        {
          keyIndex: 'isPublish',
          align: 'left',
          displayName: Translation('component.formLabel.publish-status'),
          renderData: (row) =>
            String(
              row.publishedAt ? Translation('component.status.published') : Translation('component.status.unpublished'),
            ),
          sortable: true,
          onSort: (sort) => {
            onSort({ key: 'publishedAt', value: sort['isPublish'] });
          },
        },
        {
          keyIndex: 'publishedAt',
          align: 'left',
          displayName: Translation('component.formLabel.published-time'),
          renderData: (row) =>
            row.lastPublishTime ? getDefaultDisplayDate(new Date(row.lastPublishTime), 'datetime') : '',
          sortable: true,
          onSort: (sort) => {
            onSort({ key: 'lastPublishTime', value: sort['lastPublishTime'] });
          },
        },
        {
          keyIndex: 'isValid',
          align: 'left',
          displayName: Translation('component.formLabel.valid-status'),
          renderData: (row) =>
            String(
              checkIsRecordValid(row) ? Translation('component.status.valid') : Translation('component.status.invalid'),
            ),
        },
        {
          keyIndex: 'lastUpdatedBy',
          align: 'left',
          displayName: Translation('component.formLabel.last-updated-by'),
          renderData: (row) => String(row.lastUpdatedBy ? row.lastUpdatedBy : ''),
          sortable: true,
          onSort: (sort) => {
            onSort({ key: 'lastUpdatedBy', value: sort['lastUpdatedBy'] });
          },
        },
        {
          keyIndex: 'effectiveDate',
          align: 'left',
          displayName: Translation('component.formLabel.effective-date-range-start'),
          renderData: (row) =>
            row.effectiveDate ? getDefaultDisplayDate(new Date(row.effectiveDate), 'datetime') : '',
          sortable: true,
          onSort: (sort) => {
            onSort({ key: 'effectiveDate', value: sort['effectiveDate'] });
          },
        },
        {
          keyIndex: 'expiredDate',
          align: 'left',
          displayName: Translation('component.formLabel.effective-date-range-end'),
          renderData: (row) => (row.expiredDate ? getDefaultDisplayDate(new Date(row.expiredDate), 'datetime') : ''),
          sortable: true,
          onSort: (sort) => {
            onSort({ key: 'expiredDate', value: sort['expiredDate'] });
          },
        },
      ]}
      defaultRowsPerPage={20}
      isLoading={isLoading}
      onRefresh={onRefresh}
      dataSource={popupList?.popupList}
      totalPages={popupList?.totalPages}
      totalRecords={popupList?.totalRecords}
      onChangePage={onChangePage}
    />
  );
};

export default PopupList;
