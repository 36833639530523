/**
 * Entry application component used to compose providers and render Routes.
 * */

import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { RootPersistor, RootStore } from '../redux/store';
import { I18nProvider } from './i18n';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LayoutSplashScreen, MaterialThemeProvider } from './layout';
import { ModalServiceProvider } from './common/components/pru-modal/modal-context';
import { ToastProvider } from './common/components/pru-toast/toast-context';
import { Routes } from './Routes';

type AppProps = {
  store: RootStore;
  persistor: RootPersistor;
  basename: string;
};

export default function App({ store, persistor, basename }: AppProps) {
  return (
    /* Provide Redux store */
    <Provider store={store}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
        {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
        <React.Suspense fallback={<LayoutSplashScreen />}>
          {/* Override `basename` (e.g: `homepage` in `package.json`) */}
          <BrowserRouter basename={basename}>
            {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
            <MaterialThemeProvider>
              {/* Provide `react-intl` context synchronized with Redux state.  */}
              <I18nProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <ModalServiceProvider>
                    <ToastProvider>
                      {/* Render routes with provided `Layout`. */}
                      <Routes />
                    </ToastProvider>
                  </ModalServiceProvider>
                </LocalizationProvider>
              </I18nProvider>
            </MaterialThemeProvider>
          </BrowserRouter>
        </React.Suspense>
      </PersistGate>
    </Provider>
  );
}
