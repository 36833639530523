import { CSSProperties, useEffect, useState } from 'react';
import { FC } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';
import { useIntl } from 'react-intl';
import SurveyForm from 'src/app/common/components/microsite-survey-form/survey-form';

type StylesObject = {
  [key: string]: CSSProperties;
};
const styles: StylesObject = {
  img: {
    height: 233,
    objectFit: 'cover',
  },
  paperMargin: {
    marginLeft: 24,
    marginRight: 24,
  },
  countryCodeWitdh: {
    width: 100,
    marginRight: 12,
  },
  radioGroup: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 6,
    flex: 1,
  },
  textYes: {
    borderWidth: 1,
    borderRadius: 8,
    borderColor: '#cccccc',
    flex: 1,
    marginLeft: 3,
  },
  textNo: {
    borderWidth: 1,
    borderRadius: 8,
    flex: 1,
    borderColor: '#cccccc',
    marginLeft: 3,
    marginRight: -3,
  },
  titleFont: {
    color: '#333333',
    fontSize: 20,
    fontWeight: 600,
  },
  subTitleFont: {
    color: '#666666',
    fontSize: 16,
    fontWeight: 500,
  },
  bodyFont: {
    color: '#333333',
    fontSize: 14,
    fontWeight: 400,
  },
  lableFont: {
    color: '#333333',
    fontSize: 14,
    fontWeight: 500,
  },
  tncFont: {
    color: '#333333',
    fontSize: 12,
    fontWeight: 500,
  },
  font: {
    color: '#333333',
    fontSize: 16,
    fontWeight: 400,
  },
  button: {
    marginTop: 24,
    marginBottom: 47,
  },
};

type PreviewProps = {
  banner: string;
  title: string;
  subTitle: string;
  inputFields: any;
  body: string;
  callToAction: string;
  tncList: string[];
  onClose: (value: boolean) => void;
};

const PreviewPage: FC<PreviewProps> = (props) => {
  const { banner, title, subTitle, inputFields, body, callToAction, tncList, onClose } = props;
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [bannerUrl, setBannerUrl] = useState('/media/users/agent_referral_banner.png');
  useEffect(() => {
    if (banner) {
      setBannerUrl(banner);
    }
  }, [banner]);

  return (
    <div className="tw-flex tw-flex-1  tw-flex-col">
      <span className="tw-flex tw-item-end tw-justify-end tw-h-8">
        <CloseIcon
          onClick={() => {
            onClose(false);
          }}
        />
      </span>

      <img src={bannerUrl} alt="logo" style={styles.img}></img>

      <div style={styles.paperMargin}>
        {title && (
          <div className="tw-mt-10  tw-break-words" style={styles.titleFont}>
            {title}
          </div>
        )}

        {subTitle && (
          <div className="tw-mt-3  tw-break-words" style={styles.subTitleFont}>
            {' '}
            {subTitle}
          </div>
        )}
        <div className="tw-mt-6  tw-break-words" dangerouslySetInnerHTML={{ __html: body }} style={styles.bodyFont} />

        <SurveyForm data={inputFields ?? {}} />

        <div className="tw-mt-9" style={styles.tncFont}>
          {tncList.map((item) => {
            return <div className="tw-mt-3 tw-break-words">{item}</div>;
          })}
        </div>
        <Button style={styles.button} variant="contained" disabled={true} color="secondary" fullWidth>
          {callToAction}
        </Button>
      </div>
    </div>
  );
};

export default PreviewPage;
