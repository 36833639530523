import React, { FC, useState } from 'react';
import { AlertType, appendAlertItem } from 'src/redux/common/commonSlice';

import { useDispatch } from 'react-redux';
import { useDataProvider, getDayStart, getDayEnd } from 'src/app/common/utils';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useIntl } from 'react-intl';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import HistoryList from './edit-history-list';
import { fetchApplicationFormEditHistoryList } from '../../network/application-form-crud';
import { ApplicationFormEditHistoryListParam, editHistoryParam, PaginateList } from '../../types/edit-history-types';
type EditHistoryListPageProps = ParamsProps;

const initialState: editHistoryParam = {
  lastUpdatedBy: undefined,
  lastUpdatedAtFrom: null,
  lastUpdatedAtTo: null,
  limit: 5,
  page: 1,
};

export const editHistoryFilterKeys = ['lastUpdatedBy', 'lastUpdatedAtFrom', 'lastUpdatedAtTo'];

interface ResWithMessage extends PaginateList<ApplicationFormEditHistoryListParam> {
  message: string;
  status: string;
}

const paramsInitiator = (initialParams?: Record<string, string>): editHistoryParam => {
  return initialParams
    ? {
        lastUpdatedAtFrom: initialParams.lastUpdatedAtFrom
          ? getDayStart(new Date(initialParams.lastUpdatedAtFrom))
          : null,
        lastUpdatedAtTo: initialParams.lastUpdatedAtTo ? getDayEnd(new Date(initialParams.lastUpdatedAtTo)) : null,
        lastUpdatedBy: initialParams.lastUpdatedBy || '',
        limit: 5,
        page: 1,
      }
    : initialState;
};

const EditHistoryListPage: FC<EditHistoryListPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const [filterState, setFilterState] = useState<editHistoryParam>(paramsInitiator(initialParams));
  const [historyList, setHistoryList] = useState<any>();

  const { isLoading, refreshData } = useDataProvider(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        const res = await fetchApplicationFormEditHistoryList(filterState, dispatch);
        if (res?.status) {
          const resWithMessage = res as ResWithMessage;
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.ERROR,
                title: resWithMessage.status,
                content: resWithMessage.message,
              },
            ]),
          );
        } else {
          return res;
        }
      } catch (err) {}
    },
    setHistoryList,
    false,
  );

  return (
    <>
      <PruFilter
        title={Translation('recruitment.applicationForm.editHistory.filter.title')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'lastUpdatedBy',
            initialValue: filterState.lastUpdatedBy,
            displayName: Translation('recruitment.common.lastUpdatedBy'),
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'lastUpdatedAtFrom',
            fieldTo: 'lastUpdatedAtTo',
            initialDateFrom: filterState.lastUpdatedAtFrom,
            initialDateTo: filterState.lastUpdatedAtTo,
            displayName: Translation('recruitment.common.lastUpdatedAt'),
          },
        ]}
        onChangeFilter={(filter) => {
          setFilterState({
            ...filterState,
            lastUpdatedBy: filter.lastUpdatedBy,
            lastUpdatedAtFrom: filter.lastUpdatedAtFrom,
            lastUpdatedAtTo: filter.lastUpdatedAtTo,
          });
        }}
        fetchData={refreshData}
      />
      <HistoryList
        isLoading={isLoading}
        historyList={historyList}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
        onRefresh={refreshData}
      />
    </>
  );
};
export default EditHistoryListPage;
