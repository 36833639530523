import TargetApplicationSection from 'src/app/common/components/TargetApplicantSection';
import { RadioGroup, FormControlLabel, Radio, Button } from '@mui/material';
import { targetReceiver, targetType } from '../../../../constants';
import Form from 'src/app/common/components/Form';
import { useIntl } from 'react-intl';
import { FormInstance } from 'rc-field-form';
import FileUploader from 'src/app/common/components/FileUploader';
import { useDispatch } from 'react-redux';
import { createBlob, getBlob } from 'src/app/common/network';
import { fileUpload } from 'src/app/common/utils';
import AgentList from './agent-list';
import { commonStyles } from './agent-list/style';
import { newTemplate } from './agent-list/util';
import { read, utils } from 'xlsx';
import AgentListUploader from './agent-uploader';

type TargetAgentProps = {
  initialValues: any;
  disabled: boolean;
  isEdit: boolean;
  form: FormInstance<any>;
};
const ENABLE_DESIGNATION = window.envConfig['ENABLE_DESIGNATION'];
const ENABLE_ZONE = window.envConfig['ENABLE_ZONE'];

const TargetAgent: React.FC<TargetAgentProps> = ({ initialValues, disabled, isEdit, form }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const isAdd = isEdit ? false : disabled ? false : true;
  const DEFAULT_REQUIRED_RULES = { required: true, message: Translation('component.form-required-text') };

  const setData = (key: string, value: any) => {
    form.setFieldsValue({ [`${key}`]: value });
  };
  const groupView = () => {
    return ENABLE_DESIGNATION === 'true' || ENABLE_ZONE === 'true' ? (
      <div>
        <TargetApplicationSection
          isDisabled={disabled}
          eligibleDesignations={initialValues.designations || []}
          eligibleZones={initialValues.zones || []}
          eligibleAgents={[]}
          excludedAgents={[]}
          isResoure={true}
          historyAgent={initialValues.agent}
          isNew={isAdd}
          onToggleDesignation={(data) => setData('designations', data)}
          onToggleZones={(data) => setData('zones', data)}
        />
        <Form.Item label={`${Translation('receiver.include.text')}:`} required={false}>
          {(_, meta, { getFieldValue }) => {
            return (
              <div className="tw-my-6">
                <AgentListUploader
                  maxFileSize={10}
                  disabled={disabled}
                  list={initialValues.includedAgents}
                  onChange={(data) => {
                    setData('includedAgents', data);
                  }}
                  type="included"
                  tips={Translation('component.template.includeTips')}
                />
              </div>
            );
          }}
        </Form.Item>
        <Form.Item>
          {(_, meta, { getFieldValue }) => {
            return (
              <div className="tw-my-6">
                <Form.Item name="excludedAgents" label={`${Translation('receiver.exclude.text')}:`} required={false}>
                  <div>
                    <AgentListUploader
                      maxFileSize={10}
                      disabled={disabled}
                      list={initialValues.excludedAgents}
                      onChange={(data) => {
                        setData('excludedAgents', data);
                      }}
                      type="excluded"
                      tips={Translation('component.template.excludeTips')}
                    />
                  </div>
                </Form.Item>
              </div>
            );
          }}
        </Form.Item>
        <div>
          <Button color="secondary" onClick={newTemplate}>
            {Translation('component.notification.downloadTemplate')}
          </Button>
        </div>
      </div>
    ) : null;
  };

  const listView = () => {
    const agentListData = form.getFieldValue('targetAgentList')?.map((item: string) => {
      return { agentCode: item };
    });
    const setList = (data: any) => {
      setData(
        'targetAgentList',
        data?.map((item: { agentCode: string }) => item.agentCode),
      );
    };

    return <AgentList data={agentListData ?? []} setData={setList} disabled={disabled} />;
  };

  return (
    <div>
      <Form.Item
        name="agentType"
        label={Translation('component.formLabel.target-receiver')}
        rules={[DEFAULT_REQUIRED_RULES]}
        initialValue={initialValues?.agentType}
      >
        <RadioGroup style={commonStyles.flexRow}>
          {Object.entries(targetReceiver(intl)).map(([key, value]) => {
            return (
              <FormControlLabel
                key={value.key}
                value={value.key}
                control={<Radio />}
                label={value.label}
                disabled={disabled}
              />
            );
          })}
        </RadioGroup>
      </Form.Item>

      <Form.Item>
        {(_, meta, { getFieldValue }) => {
          const toTarget = getFieldValue('agentType') === targetReceiver(intl).targetAgent.key;
          return toTarget ? (
            <Form.Item
              name="targetAgentType"
              label={Translation('component.formLabel.target-agents-by')}
              initialValue={initialValues?.targetAgentType}
              rules={[DEFAULT_REQUIRED_RULES]}
            >
              <RadioGroup style={commonStyles.flexRow}>
                {Object.entries(targetType(intl)).map(([key, value]) => {
                  return (
                    <FormControlLabel
                      key={value.key}
                      value={value.key}
                      control={<Radio />}
                      label={value.label}
                      disabled={disabled}
                    />
                  );
                })}
              </RadioGroup>
            </Form.Item>
          ) : (
            <></>
          );
        }}
      </Form.Item>

      <Form.Item>
        {(_, meta, { getFieldValue }) => {
          const toTarget = getFieldValue('agentType') === targetReceiver(intl).targetAgent.key;
          const isGroup = getFieldValue('targetAgentType') === targetType(intl).byGroup.key;
          const isList = getFieldValue('targetAgentType') === targetType(intl).byList.key;
          return (
            <div style={commonStyles.ml160}>
              {toTarget && isGroup && groupView()}
              {toTarget && isList && listView()}
            </div>
          );
        }}
      </Form.Item>
    </div>
  );
};

export default TargetAgent;
