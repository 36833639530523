import React, { FC, useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { find, get } from 'lodash';
import { useRequest } from 'ahooks';

import {
  AutoNotiTemplateEnum,
  AutoNotiTriggerMethodEnum,
  AutoNotiTriggerTypeEnum,
  AutoNotiChannelEnum,
  NotificationTemplateItem,
  i18nConfigItem,
} from '../../../types/notification-types';
import { RedirectType } from '../../../constants';
import { fetchNotificationTemplate, updateNotificationTemplate } from '../../../redux/notificationCrud';
import { AlertType, appendAlertItem } from 'src/redux/common/commonSlice';
import { regionLocale } from 'src/app/i18n';
import { isEmptyArray } from 'formik';

interface HookProps {
  template: AutoNotiTemplateEnum;
}

// Element for formStates
type InitialFormAction = { type: 'INITIAL_TEMPLATE'; payload: NotificationTemplateItem[] };
type UploadFormAction = {
  type: 'MODIFY_TEMPLATE';
  index: number;
  payload: { field: keyof NotificationTemplateItem; value: any };
};
type AddFormAction = { type: 'ADD_TEMPLATE'; payload: NotificationTemplateItem };
type RemoveFormAction = { type: 'REMOVE_TEMPLATE'; index: number };
export type AutoNotiFormAction = InitialFormAction | UploadFormAction | AddFormAction | RemoveFormAction;
const autoNotiFormReducer = (
  state: NotificationTemplateItem[],
  action: AutoNotiFormAction,
): NotificationTemplateItem[] => {
  switch (action.type) {
    case 'INITIAL_TEMPLATE':
      return action.payload;
    case 'MODIFY_TEMPLATE':
      return state.map((template, index) => {
        if (index === action.index) {
          return { ...template, [action.payload.field]: action.payload.value };
        }
        return template;
      });
    case 'ADD_TEMPLATE':
      return [...state, action.payload];
    case 'REMOVE_TEMPLATE':
      return state.filter((template, index) => index !== action.index);
  }
};
export const initialState: { [key: string]: NotificationTemplateItem } = {
  sla_expiry_reminder: {
    category: 'inbox_leads',
    template: AutoNotiTemplateEnum.SLA_EXPIRY_REMINDER,
    triggerTime: {
      method: AutoNotiTriggerMethodEnum.BEFORE,
      type: AutoNotiTriggerTypeEnum.ACCEPT_SLA_EXPIRY,
      param: '',
    },
    channel: [AutoNotiChannelEnum.Inbox],
    title: { en: '' },
    content: { en: '' },
    redirectParams: {
      redirectTo: RedirectType.notDirect,
    },
  },
  system_turns_off_accept_lead_toggle_reminder: {
    category: 'inbox_leads',
    template: AutoNotiTemplateEnum.SYS_TURN_OFF_ACCEPT_REMINDER,
    triggerTime: {
      method: AutoNotiTriggerMethodEnum.WHEN,
      type: AutoNotiTriggerTypeEnum.SYS_TURNS_OFF_ACCEPT_TOGGLE,
    },
    channel: [AutoNotiChannelEnum.Inbox],
    title: { en: '' },
    content: { en: '' },
    redirectParams: {
      redirectTo: RedirectType.notDirect,
    },
  },
  turned_off_accept_reminder: {
    category: 'inbox_leads',
    template: AutoNotiTemplateEnum.TURNED_OFF_ACCEPT_REMINDER,
    triggerTime: {
      method: AutoNotiTriggerMethodEnum.PERIODIC,
      type: AutoNotiTriggerTypeEnum.TURNED_OFF_ACCEPT_TOGGLE,
      param: '',
      value: 14,
    },
    channel: [AutoNotiChannelEnum.Inbox],
    title: { en: '' },
    content: { en: '' },
    redirectParams: {
      redirectTo: RedirectType.notDirect,
    },
  },
};

// Element for errorStates
export type ErrorState = {
  triggerTime: {
    method: boolean;
    type: boolean;
    param: boolean;
    value: boolean;
    matchReg: boolean;
    valueIsNum: boolean;
  };
  duplicatedTriggerTime: boolean;
  channel: boolean;
  title: {
    [code: string]: boolean;
  };
  content: { [code: string]: boolean };
  redirectParams: { redirectTo: boolean; openBy: boolean; link: boolean; params: boolean };
};
type InitialErrorAction = { type: 'INITIAL_ERROR'; payload: ErrorState[] };
type UploadErrorAction = {
  type: 'MODIFY_ERROR';
  index: number;
  payload: { field: keyof ErrorState; value: any };
};
type AddErrorAction = { type: 'ADD_ERROR'; payload: ErrorState };
type RemoveErrorAction = { type: 'REMOVE_ERROR'; index: number };
export type AutoNotiErrorAction = InitialErrorAction | UploadErrorAction | AddErrorAction | RemoveErrorAction;
const autoNotiErrorReducer = (state: ErrorState[], action: AutoNotiErrorAction): ErrorState[] => {
  switch (action.type) {
    case 'INITIAL_ERROR':
      return action.payload;
    case 'MODIFY_ERROR':
      return state.map((error, index) => {
        if (index === action.index) {
          return { ...error, [action.payload.field]: action.payload.value };
        }
        return error;
      });
    case 'ADD_ERROR':
      return [...state, action.payload];
    case 'REMOVE_ERROR':
      return state.filter((error, index) => index !== action.index);
  }
};
const initialError: ErrorState = {
  triggerTime: { method: false, type: false, param: false, value: false, matchReg: false, valueIsNum: false },
  duplicatedTriggerTime: false,
  channel: false,
  title: {},
  content: {},
  redirectParams: {
    redirectTo: false,
    openBy: false,
    link: false,
    params: false,
  },
};

export const useNotificationConfigEdit = ({ template }: HookProps) => {
  // i18n
  const intl = useIntl();
  const locale = regionLocale;
  const Translation = (id: string) => intl.formatMessage({ id });
  // State .etc
  const dispatch = useDispatch();
  // const [errorStates, setErrorStates] = useState<ErrorState[]>([]);
  // const i18nConfig = useRef<Array<i18nConfigItem>>([]);
  const [formStates, formDispatch] = useReducer(autoNotiFormReducer, []);
  const [errorStates, errorDispatch] = useReducer(autoNotiErrorReducer, []);
  const [isSubmit, setIsSubmit] = useState(false);

  // Error state modification
  const initialErrorStates = (count: number) => {
    let errorStatesArr: ErrorState[] = [];
    for (let index = 0; index < count; index++) {
      errorStatesArr = [...errorStatesArr, initialError];
    }
    return errorStatesArr;
  };

  // Data acquisition
  const {
    data,
    error,
    loading,
    run: fetchData,
  } = useRequest(() => fetchNotificationTemplate(template, dispatch), {
    manual: true,
  });
  useEffect(() => {
    fetchData();
  }, []);

  // Attach data
  useEffect(() => {
    if (data) {
      const formData = data;
      const errorData = initialErrorStates(formData.length);

      locale.forEach((i18nItem) => {
        formData.forEach((formItem) => {
          delete formItem._id;
          formItem.title[i18nItem] = formItem.title[i18nItem] || '';
          formItem.content[i18nItem] = formItem.content[i18nItem] || '';
        });
        errorData.forEach((errorItem) => {
          errorItem.title[i18nItem] = false;
          errorItem.content[i18nItem] = false;
        });
      });
      errorData.map((item, index) => {
        errorData[index] = {
          ...errorData[index],
          triggerTime: triggerTimeValidator(formData[index].triggerTime, formData[index].template),
        };
      });
      formDispatch({ type: 'INITIAL_TEMPLATE', payload: formData });
      errorDispatch({ type: 'INITIAL_ERROR', payload: errorData });
    }
  }, [data]);

  // Form validation
  const isLinkValid = (index: number, link?: string) => {
    if (
      formStates[index].redirectParams?.redirectTo === RedirectType.notDirect ||
      (link &&
        (formStates[index].redirectParams?.redirectTo === RedirectType.pageInApp ||
          link.startsWith('https://') ||
          link.startsWith('http://')))
    ) {
      return true;
    }
    return false;
  };

  const triggerTimeValidator = (
    triggerTime: { method: string; type: string; param?: string; value?: number },
    template: AutoNotiTemplateEnum,
  ): { method: boolean; type: boolean; param: boolean; value: boolean; matchReg: boolean; valueIsNum: boolean } => {
    const defaultValue = { method: false, type: false, param: false, value: false, matchReg: false, valueIsNum: false };
    if (
      template === AutoNotiTemplateEnum.SLA_EXPIRY_REMINDER &&
      triggerTime.method !== AutoNotiTriggerMethodEnum.WHEN
    ) {
      const regdhm = new RegExp('^(\\d+d)(\\s(?:1?\\d|2[0-3])h)?(?:\\s[1-5]?\\dm)?$');
      const reghm = new RegExp('^((?:1?\\d|2[0-3])h)(?:\\s[1-5]?\\dm)?$');
      const regm = new RegExp('^(?:[1-5]?\\dm)$');
      if (!triggerTime.param) {
        return { ...defaultValue, param: true };
      }
      if (typeof triggerTime.param === 'string') {
        if (regdhm.test(triggerTime.param) || reghm.test(triggerTime.param) || regm.test(triggerTime.param)) {
          return { ...defaultValue, matchReg: false, param: triggerTime.param === '' };
        } else {
          return { ...defaultValue, matchReg: true, param: triggerTime.param === '' };
        }
      }
    } else if (template === AutoNotiTemplateEnum.TURNED_OFF_ACCEPT_REMINDER) {
      if (typeof triggerTime.value === 'number') {
        return { ...defaultValue, value: false, valueIsNum: triggerTime.value <= 0 };
      } else {
        return { ...defaultValue, value: false, valueIsNum: true };
      }
    }
    return defaultValue;
  };

  const duplicatedTimeValidator = (formStates: NotificationTemplateItem[], index: number): boolean => {
    let isDuplicated = false;
    formStates.forEach((formState, idx) => {
      if (index !== idx) {
        if (formStates[index].template === AutoNotiTemplateEnum.SLA_EXPIRY_REMINDER) {
          isDuplicated =
            isDuplicated ||
            ((formStates[index].triggerTime.method === AutoNotiTriggerMethodEnum.WHEN
              ? true
              : formStates[index].triggerTime.param === formState.triggerTime.param) &&
              formStates[index].triggerTime.type === formState.triggerTime.type &&
              formStates[index].triggerTime.method === formState.triggerTime.method);
        } else if (formStates[index].template === AutoNotiTemplateEnum.TURNED_OFF_ACCEPT_REMINDER) {
          isDuplicated =
            isDuplicated ||
            (formStates[index].triggerTime.param === formState.triggerTime.param &&
              formStates[index].triggerTime.value === formState.triggerTime.value);
        }
      }
    });
    return isDuplicated;
  };

  const globalValidator = (locale: Array<string>) => {
    let noError = true;
    for (let index = 0; index < formStates.length; index++) {
      const formState = formStates[index];
      let currentErrorState = errorStates[index];
      currentErrorState = {
        ...currentErrorState,
        triggerTime: currentErrorState.triggerTime,
        duplicatedTriggerTime: currentErrorState.duplicatedTriggerTime,
        channel: !isEmptyArray(formState.channel) ? false : true,
        redirectParams: {
          redirectTo: formState.redirectParams?.redirectTo ? false : true,
          openBy:
            formState.redirectParams?.redirectTo === RedirectType.URL
              ? formState.redirectParams?.openBy
                ? false
                : true
              : false,
          link:
            formState.redirectParams?.redirectTo === RedirectType.notDirect
              ? false
              : formState.redirectParams?.redirectTo === RedirectType.URL
              ? !isLinkValid(index, formState.redirectParams.link)
              : formState.redirectParams.link === '',
          params: false,
        },
        title: {}, // Will be checked later.
        content: {}, // Will be checked later.
      };
      locale.forEach((item) => {
        currentErrorState.title[item] = formState.title[item] === '';
        currentErrorState.content[item] = formState.content[item] === '';
      });
      errorStates[index] = currentErrorState;
      noError =
        noError &&
        find(currentErrorState, (status) => status === true) === undefined &&
        find(currentErrorState.content, (status) => status === true) === undefined &&
        find(currentErrorState.title, (status) => status === true) === undefined &&
        find(currentErrorState.triggerTime, (status) => status === true) === undefined &&
        find(currentErrorState.redirectParams, (status) => status === true) === undefined;
    }
    errorDispatch({ type: 'INITIAL_ERROR', payload: errorStates });
    return noError;
  };

  // Submit template
  const submitTemplate = async () => {
    setIsSubmit(true);
    if (globalValidator(locale)) {
      const uploadData = { items: formStates };
      await updateNotificationTemplate(template, uploadData, dispatch)
        .then((res) => {
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: `Record saved successfully.`,
              },
            ]),
          );
        })
        .catch((err) => {
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.ERROR,
                title: Translation('global.submit.fail'),
                content: Translation('global.submit.fail'),
              },
            ]),
          );
        });
    } else {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.ERROR,
            title: Translation('global.submit.fail'),
            content: Translation('global.submit.fail'),
          },
        ]),
      );
    }
  };

  // Add template
  const addTemplate = () => {
    const updatedFormInitialState = initialState[template];
    const updatedErrorState = initialError;
    locale.forEach((i18nItem) => {
      updatedFormInitialState.title[i18nItem] = updatedFormInitialState.title[i18nItem] ?? '';
      updatedFormInitialState.content[i18nItem] = updatedFormInitialState.content[i18nItem] ?? '';
      updatedErrorState.title[i18nItem] = false;
      updatedErrorState.content[i18nItem] = false;
    });
    formDispatch({ type: 'ADD_TEMPLATE', payload: updatedFormInitialState });
    errorDispatch({ type: 'ADD_ERROR', payload: updatedErrorState });
  };

  // Remove template
  const removeTemplate = (index: number) => {
    formDispatch({ type: 'REMOVE_TEMPLATE', index: index });
    errorDispatch({ type: 'REMOVE_ERROR', index: index });
  };

  useEffect(() => {
    formStates.forEach((formState, index) => {
      errorDispatch({
        type: 'MODIFY_ERROR',
        index: index,
        payload: { field: 'triggerTime', value: triggerTimeValidator(formState.triggerTime, formState.template) },
      });
      errorDispatch({
        type: 'MODIFY_ERROR',
        index: index,
        payload: { field: 'duplicatedTriggerTime', value: duplicatedTimeValidator(formStates, index) },
      });
    });
  }, [formStates]);

  useEffect(() => {
    if (isSubmit) {
      setIsSubmit(false);
    }
  }, [isSubmit]);

  return {
    loading,
    locale,
    formStates,
    errorStates,
    isSubmit,
    formDispatch,
    errorDispatch,
    triggerTimeValidator,
    globalValidator,
    duplicatedTimeValidator,
    isLinkValid,
    addTemplate,
    removeTemplate,
    submitTemplate,
  };
};
