import { Dispatch } from 'react';
import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import { forEach } from 'lodash';
import { LeadSource } from '../types/source-types';
import { PaginateList } from 'src/app/common/types/common-types';
import { I18nDataItem } from 'src/app/i18n';

const sourceEndpoint = 'sources';

export type LeadSourceListParam = {
  sourceName: string;
  sortBy: string;
  page: number;
  limit: number;
};

export type AttachmentBody = {
  blobId: string;
  filename: string;
};

export type CreateLeadSourceBody = {
  sourceId: string;
  sourceName: string;
  i18nData: I18nDataItem;
  regionalLeadSource: string;
  channelCode?: string;
  image: AttachmentBody;
  parent?: string;
  createdBy: string;
  updatedBy: string;
};

export type UpdateLeadSourceBody = {
  sourceId: string;
  sourceName: string;
  i18nData: I18nDataItem;
  regionalLeadSource: string;
  channelCode?: string;
  image: AttachmentBody;
  parent?: string;
  updatedBy: string;
};

export const fetchLeadSourceList = async (
  params: LeadSourceListParam,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<LeadSource>> => {
  let queryPath = `${ADMIN_URL}/${sourceEndpoint}?`;
  forEach(params, (param, key) => {
    if (key === 'page') {
      queryPath += `${key}=${encodeURIComponent(param as any)}&`;
    } else {
      queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
    }
  });
  return apiClient
    .get<PaginateList<LeadSource>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchLeadSource = async (id: string, dispatch?: Dispatch<any>): Promise<LeadSource> => {
  return apiClient
    .get<LeadSource>(`${ADMIN_URL}/${sourceEndpoint}/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createLeadSource = async (body: CreateLeadSourceBody, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .post<void>(`${ADMIN_URL}/${sourceEndpoint}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const modifyLeadSource = async (
  id: string,
  body: UpdateLeadSourceBody,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .put<void>(`${ADMIN_URL}/${sourceEndpoint}/${id}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const deleteLeadSource = async (id: string, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .delete<void>(`${ADMIN_URL}/${sourceEndpoint}/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
