import { regionLocale } from 'src/app/i18n';

export const getCategoryLabel = (row: any) => {
  let label = '';
  regionLocale.forEach((code) => {
    if (!row.i18nData) {
      return;
    }

    const result = row.i18nData.find((item: any) => {
      return item.locale === code;
    });

    label = label + ' ' + ((result && result.name) || '');
  });

  return label;
};

export const emptyValue = 'component.hint.empty-value-not-allowed';

export const getItemByLang = (i18nData: any, key: any) => {
  const values = regionLocale.map((code, index) => {
    const item = i18nData.find((item: any) => item.locale === code);
    return item[key] || '';
  });

  return values;
};

export const initialI18nObj = () => {
  return regionLocale.map((code, index) => {
    return {
      locale: code,
    };
  });
};

export const initialReminderI18nObj = (Translation: any) => {
  return regionLocale.map((code, index) => {
    return {
      locale: code,
      msgContent: Translation(`mandatroy.training.reminder.notification.fixed.content.inbox.${code}`),
      pushContent: Translation(`mandatroy.training.reminder.notification.fixed.content.push.${code}`),
      msgTitle: Translation(`mandatroy.training.reminder.notification.fixed.content.notification.title.${code}`),
      pushTitle: Translation(`mandatroy.training.reminder.notification.fixed.content.notification.title.${code}`),
    };
  });
};
