import React, { FC, useState } from 'react';
import { ParamsProps } from '../../../../../../common/components/ParamsProvider';
import { useDataProvider } from 'src/app/common/utils';
import { useDispatch } from 'react-redux';
import BulkUploadStatusList from './components/BulkUploadStatusList';
import { BulkUploadStatusItem, BulkUploadTypeEnum } from '../../../../types/bulk-upload-types';
import { BulkUploadListParam, fetchBulkUploadStatusList } from '../../../../network/bulkUploadCrud';
import { useIntl } from 'react-intl';
import { PaginateList } from '../../../../../../common/types/common-types';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { PULSELEADS_BASE_PATH } from 'src/app/modules/PulseLeads/constants';
import { PULSELEADS_BULKUPLOAD_PATH, PULSELEADS_BULKUPLOAD_STATUS_PATH } from '../../constants';

type BulkUploadStatusListingPageProps = {} & ParamsProps;

const initialState: BulkUploadListParam = {
  limit: 5,
  page: 1,
};

const paramsInitiator = (initialParams?: Record<string, string>): BulkUploadListParam => {
  return initialParams
    ? {
        filename: initialParams.filename ?? '',
        page: 1,
        limit: 5,
      }
    : initialState;
};

const BulkUploadStatusListingPage: FC<BulkUploadStatusListingPageProps> = ({ onChangeQueryParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const [filterState, setFilterState] = useState<BulkUploadListParam>(paramsInitiator());
  const [bulkUploadStatusList, setBulkUploadStatusList] = useState<PaginateList<BulkUploadStatusItem>>();

  const { isLoading, refreshData } = useDataProvider<PaginateList<BulkUploadStatusItem>>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchBulkUploadStatusList(filterState, dispatch);
      } catch (err) {}
    },
    setBulkUploadStatusList,
    false,
  );

  // eslint-disable-next-line
  const statusPath = `${PULSELEADS_BASE_PATH}${PULSELEADS_BULKUPLOAD_PATH}${PULSELEADS_BULKUPLOAD_STATUS_PATH}`;

  return (
    <>
      <PruFilter
        title={Translation(`pulseleads.bulkUpload.filter`)}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'filename',
            initialValue: filterState.filename,
            displayName: Translation('pulseleads.bulkUpload.common.filename'),
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'bulkUploadType',
            initialValue: filterState.bulkUploadType,
            displayName: Translation('pulseleads.bulkUpload.common.bulkUploadType'),
            choices: [
              { displayName: '', value: '' },
              {
                displayName: Translation(`pulseleads.bulkUpload.common.${BulkUploadTypeEnum.BULK_LEAD}`),
                value: BulkUploadTypeEnum.BULK_LEAD,
              },
              {
                displayName: Translation(`pulseleads.bulkUpload.common.${BulkUploadTypeEnum.MASS_LEAD}`),
                value: BulkUploadTypeEnum.MASS_LEAD,
              },
            ],
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'updatedDateFrom',
            fieldTo: 'updatedDateTo',
            initialDateFrom: filterState.updatedDateFrom,
            initialDateTo: filterState.updatedDateTo,
            displayName: Translation('pulseleads.bulkUpload.common.updatedAt'),
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            filename: data.filename,
            bulkUploadType: data.bulkUploadType,
            updatedDateFrom: data.updatedDateFrom,
            updatedDateTo: data.updatedDateTo,
          })
        }
        fetchData={refreshData}
      />
      <BulkUploadStatusList
        isLoading={isLoading}
        bulkUploadStatusList={bulkUploadStatusList}
        onRefresh={() => {
          refreshData();
        }}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default BulkUploadStatusListingPage;
