import { UserDelegationKeyModel } from '@azure/storage-blob';
import { param } from 'jquery';
import { Dispatch } from 'react';
import { ADMIN_URL } from 'src/app/common/network';
import { DashboardItem, DashboardDetail, PlatformDashboard } from '../types/dashboard-types';
//import { useSelector } from 'react-redux';
import * as jose from 'jose';

const approvalEndpoint = 'agency-campaign-approval';

export type ChartListParam = {
  chartName: string;
  chartType: string;
  campaignId: string;
  consultantCode: string;
  campaignStatus: string;
  campaignType: string;
  campaignRecordType: string;
  leadSubSource: string;
  startDate: Date[];
  campaignStartDt: Date[];
  assignDate: Date[];
  assignedDate: Date[];
  type: string;
  leadSource: string;
  unitCode: string;
  businessManagerName: string;
  consultantName: string;
  region?: string;
  channel?: string;
};

export type DashboardBody = {
  name: string;
  ios: PlatformDashboard;
  android: PlatformDashboard;
};

export const createDashboard = async (id: string, dispatch?: Dispatch<any>): Promise<void> => {};

export const updateDashboard = async (id: string, body: DashboardBody, dispatch?: Dispatch<any>): Promise<void> => {};

export const fetchDashboardItem = async (id: string, dispatch?: Dispatch<any>): Promise<any> => {};

export const fetchDashboardList = async (params: ChartListParam, dispatch?: Dispatch<any>): Promise<any> => {};

export const fetchChartList = async (params: ChartListParam, dispatch?: Dispatch<any>): Promise<any> => {};

export const deleteDashboard = async (id: string, dispatch?: Dispatch<any>): Promise<void> => {};

export const fetchDashboardJWT = async (user: any, password: any): Promise<any> => {
  const secret = new TextEncoder().encode(password);
  const alg = 'HS256';
  return new jose.SignJWT({ username: user })
    .setProtectedHeader({ alg, typ: 'JWT' })
    .setIssuedAt()
    .setExpirationTime('1h')
    .sign(secret);
};
