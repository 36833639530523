import React, { useState, useEffect, FC } from 'react';
import Form from 'src/app/common/components/Form';
import { Dialog, Button, Menu, MenuItem } from '@mui/material';
import { useIntl } from 'react-intl';
import SearchMaterialsBox from './SearchMaterialsBox';
import { fetchLibraryList, fetchLibraryItem } from 'src/app/modules/sales-kit/components/Library/network/libraryCrud';
import CloseIcon from '@mui/icons-material/Close';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { styles } from './style';
import { ModuleTypeDisplay, ModuleTypeList } from './types';
import { getConfigurations } from 'src/app/common/utils';

const MaterialArea: FC<any> = ({ form, onDeleteMatieral, selectedMaterial, onSelectMaterial, onChange }) => {
  const intl = useIntl();
  const { classes } = styles();
  const { classes: commonClasses } = useCommonStyles();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [popUpVisible, setPopUpVisible] = useState<boolean>(false);
  const [moduleType, setModuleType] = useState<string>('');
  const [isSelect, setIsSelected] = useState<boolean>(false);
  const enableRecruitLead = !!getConfigurations()?.Sales?.lead?.enableRecruitLead;

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (selectedMaterial) {
      setModuleType(selectedMaterial.module);
      setIsSelected(true);
    }
  }, [selectedMaterial]);

  const selectMaterialHandler = (data: any) => {
    console.log('selectMaterialHandler', data);
    onChange(data.module);
    onSelectMaterial(data);
    setIsSelected(true);
  };

  const deleteMatieralHandler = () => {
    onChange('');
    onDeleteMatieral();
    setModuleType('');
    setIsSelected(false);
  };

  const pathMap: any = {
    Sales: '/saleskit/sales-material/library/view/',
    Promotion: '/saleskit/promotion-material/library/view/',
    CustomerSurvey: '/saleskit/customer/edit/',
    Recruit: '/saleskit/recruit/library/view/',
  };

  const selectJumpParams = {
    path: pathMap[selectedMaterial?.module || selectedMaterial?.resourceType.split(' ').join('')],
    id: selectedMaterial?.resourceId || selectedMaterial?.id,
    name: selectedMaterial?.name || selectedMaterial?.title || selectedMaterial?.resource?.name || '',
  };

  return (
    <div>
      <>
        {!isSelect && (
          <>
            <Button variant="contained" color="secondary" onClick={handleClick}>
              Select
            </Button>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
              {Object.entries(ModuleTypeList).map(([type, value]) => {
                if (!enableRecruitLead && type === 'Recruit') {
                  return null;
                } else
                  return (
                    <MenuItem
                      key={type}
                      onClick={() => {
                        setModuleType(type);
                        if (type !== ModuleTypeList.AgentProfile && type !== ModuleTypeList.AgentLeadForm) {
                          setPopUpVisible(true);
                        } else {
                          onSelectMaterial({
                            module: type,
                          });
                          setIsSelected(true);
                        }
                        handleClose();
                      }}
                    >
                      {ModuleTypeDisplay[value]}
                    </MenuItem>
                  );
              })}
            </Menu>
          </>
        )}

        {isSelect && (
          <div className={classes.materialName}>
            {moduleType !== ModuleTypeList.AgentProfile && moduleType !== ModuleTypeList.AgentLeadForm ? (
              <a href={`${selectJumpParams.path}${selectJumpParams.id}`} target="_blank" rel="noreferrer">
                {selectJumpParams.name}
              </a>
            ) : (
              <span>{ModuleTypeDisplay[moduleType]}</span>
            )}

            <CloseIcon className={classes.closeIcon} onClick={deleteMatieralHandler} />
          </div>
        )}
      </>

      <Dialog
        open={popUpVisible}
        onClose={() => {
          setPopUpVisible(false);
        }}
        scroll={'paper'}
        fullWidth
        maxWidth="lg"
      >
        <SearchMaterialsBox
          popUpVisible={popUpVisible}
          onSelectData={selectMaterialHandler}
          setPopUpVisible={setPopUpVisible}
          moduleType={moduleType}
        />
      </Dialog>
    </div>
  );
};

export default MaterialArea;
