import { useRef, useEffect } from 'react';
import { UpdateCheckerProps } from '../types/pru-stepped-form-types';

export const useUpdateChecker = ({ formState, isReset, setChangesUnsaved, setIsReset }: UpdateCheckerProps) => {
  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else if (isReset) {
      setIsReset(false);
    } else {
      setChangesUnsaved(true);
    }
  }, [formState]);
};
