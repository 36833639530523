import React, { FC, useState, useEffect, useReducer } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { RootState } from 'src/redux/store';
import { LayoutSplashScreen } from 'src/app/layout';
import { AuthenticationState } from 'src/app/modules/Auth/_redux/authSlice';
import { ApiErrorResponse } from 'src/app/common/network';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { ErrorFieldType, useErrorHandler, downloadBase64File } from 'src/app/common/utils';
import { MANDATORY_FIELD_ERROR_TEXT } from 'src/app/common/constants';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import { makeStyles } from 'tss-react/mui';
import { Button, TextField } from '@mui/material';
import {
  AppStatusEnum,
  ApprovalStatusEnum,
  PdfFileTypeEnum,
  PersonalParticularItem,
} from 'src/app/modules/Recruit/types/personal-particular-types';
import {
  GetDocumentParams,
  UpdateApplicationStatusBody,
  UpdateZoneFollowUpBody,
  UploadToCusSearchBody,
  fetchPersonalParticularItem,
  fetchDocument,
  fetchDocumentNameList,
  updateApplicationStatus,
  updateZoneFollowUp,
  uploadToCusSearch,
} from 'src/app/modules/Recruit/network/personalParticularCrud';
import { personalParticularPath } from '../PersonalParticularRoutes';

const FIELD_CONTAINER_WIDTH = 600;

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  headerRowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 20,
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  fieldContainer: {
    width: FIELD_CONTAINER_WIDTH,
    boxSizing: 'border-box',
    marginRight: 10,
  },
  innerFieldContainer: {
    width: FIELD_CONTAINER_WIDTH,
    boxSizing: 'border-box',
  },
  iconContainer: {
    width: 60,
    height: 60,
    overflow: 'hidden',
    borderRadius: 12,
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },
  sectionMargin: {
    marginBottom: 15,
  },
  divideMargin: {
    marginBottom: 10,
  },
  subHeader: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
  },
  textAreaRowContainer: {
    width: '100%',
    display: 'flex',
  },
  textAreaFieldContainer: {
    paddingTop: 15,
    minWidth: FIELD_CONTAINER_WIDTH,
    boxSizing: 'border-box',
  },
  textArea: {
    lineHeight: 1.5,
    minHeight: 40,
  },
  errorText: {
    fontSize: 9,
    color: '#F018A6',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  buttonMargin: {
    marginRight: 20,
  },
}));

type PersonalParticularDocFormState = {
  remarks?: string;
};

const initialState: PersonalParticularDocFormState = {
  remarks: undefined,
};

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof PersonalParticularDocFormState;
    value: any;
  };
};

type PersonalParticularDocFormAction = ModifyFieldAction;

const formReducer = (
  state: PersonalParticularDocFormState,
  action: PersonalParticularDocFormAction,
): PersonalParticularDocFormState => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    default:
      return state;
  }
};

const PersonalParticularDocPage: FC<RouteComponentProps> = ({ history }) => {
  const { appId } = useParams<{ appId: string }>();
  const dispatch = useDispatch();

  const { classes: commonClasses } = useCommonStyles();
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { user } = useSelector<RootState, AuthenticationState>((state) => state.auth);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [personalParticularItem, setPersonalParticularItem] = useState<PersonalParticularItem>();
  const [documentNameList, setDocumentNameList] = useState<string[]>([]);
  const { supplementary, followUpDetails } = personalParticularItem || {};

  const [formState, formDispatch] = useReducer(formReducer, initialState);
  const { errorState, onSubmitErrorValidator, onDismissErrorHandler } = useErrorHandler(formState, [
    {
      name: 'remarks',
      fieldType: ErrorFieldType.MANDATORY,
    },
  ]);

  const reloadData = async () => {
    if (appId) {
      setIsLoading(true);
      try {
        setDocumentNameList((await fetchDocumentNameList(appId, dispatch)).result);
      } catch {}
      fetchPersonalParticularItem(appId, dispatch)
        .then((result) => {
          setPersonalParticularItem(result.result);
        })
        .catch((err: ApiErrorResponse) => {
          history.push(NOT_FOUND_ERROR_PAGE_PATH);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const openFile = (filename: string, filenameTranslation: string) => {
    const params: GetDocumentParams = { filename };
    fetchDocument(appId, params, dispatch)
      .then((result) => {
        const fileBase64Str = result.result.fileBase64Str;
        if (fileBase64Str === '') {
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.ERROR,
                title: 'Error',
                content: `Document not found - ${filenameTranslation}`,
              },
            ]),
          );
        } else {
          downloadBase64File(fileBase64Str, 'application/pdf;base64');
        }
      })
      .catch((err: ApiErrorResponse) => {
        history.push(NOT_FOUND_ERROR_PAGE_PATH);
      });
  };

  const onUpdate = async (approvalStatus: ApprovalStatusEnum) => {
    const { hasError } = onSubmitErrorValidator();
    if (!hasError) {
      const body: UpdateApplicationStatusBody = {
        appId: appId,
        approvalStatus,
        remarks: formState.remarks || '',
        approverCode: user?.sub || 'Default',
      };
      try {
        await updateApplicationStatus(body, dispatch);
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: 'Success',
              content: 'Application status updated successfully',
            },
          ]),
        );
        history.push(personalParticularPath);
      } catch (err) {}
    }
  };

  const onZoneFollowUp = async () => {
    const body: UpdateZoneFollowUpBody = {
      appId: appId,
    };
    try {
      await updateZoneFollowUp(body, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: 'Application assigned to zone successfully',
          },
        ]),
      );
      history.push(personalParticularPath);
    } catch (err) {}
  };

  const onUploadToCusSearch = async (filename: string) => {
    const body: UploadToCusSearchBody = {
      appId: appId,
      fileName: filename,
    };
    try {
      await uploadToCusSearch(body, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: 'Document uploaded to CusSearch successfully',
          },
        ]),
      );
      history.push(personalParticularPath);
    } catch (err) {}
  };

  const commonDisableCriteria =
    personalParticularItem?.appStatus == AppStatusEnum.EDITING ||
    personalParticularItem?.appStatus == AppStatusEnum.COMPLETED ||
    personalParticularItem?.appStatus == AppStatusEnum.REJECTED ||
    personalParticularItem?.appStatus == AppStatusEnum.CANCELLED;

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line
  }, [appId]);

  return (
    <>
      {isLoading ? (
        <LayoutSplashScreen />
      ) : (
        <div className={classes.container}>
          <div className={classes.headerContainer}>
            <div className={classes.headerRowContainer}>
              <div className={commonClasses.header}>
                {Translation('recruit.personalParticular.documents') + `: ${appId}`}
              </div>
            </div>
            <Button variant="contained" color="inherit" onClick={() => history.push(personalParticularPath)}>
              {Translation('app.button.back')}
            </Button>
          </div>

          <div className={classes.sectionMargin}>
            <div className={classes.rowContainer}>
              <div className={classes.fieldContainer}>
                <span className={classes.field}>
                  {Translation('recruit.personalParticular.applicationAppointment')} :
                </span>
              </div>
              <div style={{ flexGrow: 1 }}>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.buttonMargin}
                  onClick={() =>
                    openFile(
                      PdfFileTypeEnum.ApplicationForAppointment,
                      Translation('recruit.personalParticular.applicationAppointment'),
                    )
                  }
                >
                  {Translation('section.common.operation.view')}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => onUploadToCusSearch(PdfFileTypeEnum.ApplicationForAppointment)}
                >
                  {Translation('recruit.personalParticular.uploadToCusSearch')}
                </Button>
              </div>
            </div>

            <div className={classes.rowContainer}>
              <div className={classes.fieldContainer}>
                <span className={classes.field}>{Translation('recruit.personalParticular.agencyAgreementPHKL')} :</span>
              </div>
              <div style={{ flexGrow: 1 }}>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.buttonMargin}
                  onClick={() =>
                    openFile(
                      PdfFileTypeEnum.AgencyAgreementPHKL,
                      Translation('recruit.personalParticular.agencyAgreementPHKL'),
                    )
                  }
                >
                  {Translation('section.common.operation.view')}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => onUploadToCusSearch(PdfFileTypeEnum.AgencyAgreementPHKL)}
                >
                  {Translation('recruit.personalParticular.uploadToCusSearch')}
                </Button>
              </div>
            </div>

            <div className={classes.rowContainer}>
              <div className={classes.fieldContainer}>
                <span className={classes.field}>{Translation('recruit.personalParticular.agencyAgreementPGHK')} :</span>
              </div>
              <div style={{ flexGrow: 1 }}>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.buttonMargin}
                  onClick={() =>
                    openFile(
                      PdfFileTypeEnum.AgencyAgreementPGHK,
                      Translation('recruit.personalParticular.agencyAgreementPGHK'),
                    )
                  }
                >
                  {Translation('section.common.operation.view')}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => onUploadToCusSearch(PdfFileTypeEnum.AgencyAgreementPGHK)}
                >
                  {Translation('recruit.personalParticular.uploadToCusSearch')}
                </Button>
              </div>
            </div>

            <div className={classes.rowContainer}>
              <div className={classes.fieldContainer}>
                <span className={classes.field}>
                  {Translation('recruit.personalParticular.supplementaryAgreementForManagers')} :
                </span>
              </div>
              <div style={{ flexGrow: 1 }}>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.buttonMargin}
                  onClick={() =>
                    openFile(
                      PdfFileTypeEnum.SupplementaryAgreementForManagers,
                      Translation('recruit.personalParticular.supplementaryAgreementForManagers'),
                    )
                  }
                >
                  {Translation('section.common.operation.view')}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => onUploadToCusSearch(PdfFileTypeEnum.SupplementaryAgreementForManagers)}
                >
                  {Translation('recruit.personalParticular.uploadToCusSearch')}
                </Button>
              </div>
            </div>

            <div className={classes.rowContainer}>
              <div className={classes.fieldContainer}>
                <span className={classes.field}>{Translation('recruit.personalParticular.financingAgreement')} :</span>
              </div>
              <div style={{ flexGrow: 1 }}>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.buttonMargin}
                  onClick={() =>
                    openFile(
                      PdfFileTypeEnum.FinancingAgreement,
                      Translation('recruit.personalParticular.financingAgreement'),
                    )
                  }
                >
                  {Translation('section.common.operation.view')}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => onUploadToCusSearch(PdfFileTypeEnum.FinancingAgreement)}
                >
                  {Translation('recruit.personalParticular.uploadToCusSearch')}
                </Button>
              </div>
            </div>

            <div className={classes.rowContainer}>
              <div className={classes.fieldContainer}>
                <span className={classes.field}>
                  {Translation('recruit.personalParticular.acknowledgmentAgainstUnauthorizedInsurance')} :
                </span>
              </div>
              <div style={{ flexGrow: 1 }}>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.buttonMargin}
                  onClick={() =>
                    openFile(
                      PdfFileTypeEnum.AcknowledgmentInMainlandChina,
                      Translation('recruit.personalParticular.acknowledgmentAgainstUnauthorizedInsurance'),
                    )
                  }
                >
                  {Translation('section.common.operation.view')}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => onUploadToCusSearch(PdfFileTypeEnum.AcknowledgmentInMainlandChina)}
                >
                  {Translation('recruit.personalParticular.uploadToCusSearch')}
                </Button>
              </div>
            </div>

            <div className={classes.rowContainer}>
              <div className={classes.fieldContainer}>
                <span className={classes.field}>
                  {Translation('recruit.personalParticular.authorizationForReferenceChecking')} :
                </span>
              </div>
              <div style={{ flexGrow: 1 }}>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.buttonMargin}
                  onClick={() =>
                    openFile(
                      PdfFileTypeEnum.AcademicQualification,
                      Translation('recruit.personalParticular.authorizationForReferenceChecking'),
                    )
                  }
                >
                  {Translation('section.common.operation.view')}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => onUploadToCusSearch(PdfFileTypeEnum.AcademicQualification)}
                >
                  {Translation('recruit.personalParticular.uploadToCusSearch')}
                </Button>
              </div>
            </div>

            <div className={classes.rowContainer}>
              <div className={classes.fieldContainer}>
                <span className={classes.field}>{Translation('recruit.personalParticular.uploadedImage')} :</span>
              </div>
              <div style={{ flexGrow: 1 }}>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.buttonMargin}
                  onClick={() =>
                    openFile(PdfFileTypeEnum.UploadedFile, Translation('recruit.personalParticular.uploadedImage'))
                  }
                >
                  {Translation('section.common.operation.view')}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => onUploadToCusSearch(PdfFileTypeEnum.UploadedFile)}
                >
                  {Translation('recruit.personalParticular.uploadToCusSearch')}
                </Button>
              </div>
            </div>

            {documentNameList
              .filter(
                (documentName) =>
                  !(Object.values(PdfFileTypeEnum) as string[]).includes(documentName) &&
                  documentName !== 'career_acknowledgement_ao',
              )
              .map((documentName) => (
                <div className={classes.rowContainer}>
                  <div className={classes.fieldContainer}>
                    <span className={classes.field}>
                      {documentName ? documentName.split('_').join(' ').toUpperCase() : ''} :
                    </span>
                  </div>
                  <div style={{ flexGrow: 1 }}>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.buttonMargin}
                      onClick={() => openFile(documentName, documentName)}
                    >
                      {Translation('section.common.operation.view')}
                    </Button>
                    <Button variant="contained" color="secondary" onClick={() => onUploadToCusSearch(documentName)}>
                      {Translation('recruit.personalParticular.uploadToCusSearch')}
                    </Button>
                  </div>
                </div>
              ))}

            <div className={classes.rowContainer}>
              <div className={classes.fieldContainer}>
                <span className={classes.field}>{Translation('recruit.common.recommendationReason')} :</span>
              </div>
              <div style={{ flexGrow: 1 }}>{supplementary?.reason || '-'}</div>
            </div>

            {followUpDetails?.remarksFromAMD && (
              <div className={classes.rowContainer}>
                <div className={classes.fieldContainer}>
                  <span className={classes.field}>{Translation('recruit.common.amdRemarks')} :</span>
                </div>
                <div style={{ flexGrow: 1 }}>{followUpDetails.remarksFromAMD || '-'}</div>
              </div>
            )}

            <div className={classes.rowContainer}>
              <div className={classes.fieldContainer}>
                <span className={classes.field}>
                  {Translation('recruit.common.newRemarks')}
                  <span className={classes.mandatory}>*</span> :
                </span>
              </div>
              <div style={{ flexGrow: 1 }}>
                <TextField
                  fullWidth
                  multiline
                  error={errorState.mandatory.remarks}
                  margin="dense"
                  variant="outlined"
                  helperText={errorState.mandatory.remarks && MANDATORY_FIELD_ERROR_TEXT}
                  value={formState.remarks}
                  InputProps={{
                    classes: {
                      input: classes.textArea,
                    },
                  }}
                  onChange={(e) => {
                    onDismissErrorHandler('remarks', e.target.value);
                    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'remarks', value: e.target.value } });
                  }}
                />
              </div>
            </div>

            <div className={classes.footerContainer}>
              <Button
                variant="contained"
                color="secondary"
                disabled={commonDisableCriteria}
                onClick={() => onUpdate(ApprovalStatusEnum.APPROVED)}
              >
                {Translation('recruit.button.approve')}
              </Button>
              <Button
                style={{ marginLeft: 25 }}
                variant="contained"
                color="secondary"
                disabled={commonDisableCriteria}
                onClick={() => onUpdate(ApprovalStatusEnum.REJECTED)}
              >
                {Translation('recruit.button.reject')}
              </Button>
              <Button
                style={{ marginLeft: 25 }}
                variant="contained"
                color="secondary"
                disabled={
                  commonDisableCriteria || personalParticularItem?.appStatus == AppStatusEnum.FOLLOW_UP_REQUIRED
                }
                onClick={() => onUpdate(ApprovalStatusEnum.FOLLOW_UP_REQUIRED)}
              >
                {Translation('recruit.button.followUp')}
              </Button>
              <Button
                style={{ marginLeft: 25 }}
                variant="contained"
                color="secondary"
                disabled={commonDisableCriteria}
                onClick={() => onZoneFollowUp()}
              >
                {Translation('recruit.button.zoneFollowUp')}
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PersonalParticularDocPage;
