import { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import PruRoute from 'src/app/common/components/PruRoute';
import ParamsProvider from 'src/app/common/components/ParamsProvider';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { SECRETARY_BASE_PATH } from '../constants';
import SecretaryListingPage, { secretaryFilterKeys } from '../pages/List/SecretaryListingPage';
import SecretaryDetailPage from './Form/SecretaryDetailPage';

export const secretaryPath = `${SECRETARY_BASE_PATH}`;

const SecretaryRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect exact from={secretaryPath} to={`${secretaryPath}/list`} />
      <PruRoute
        exact
        path={`${secretaryPath}/list`}
        render={(props) => (
          <ParamsProvider {...props} acceptKeys={secretaryFilterKeys}>
            <SecretaryListingPage />
          </ParamsProvider>
        )}
      />
      <PruRoute exact path={`${secretaryPath}/edit/:id`} component={SecretaryDetailPage} />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default SecretaryRoutes;
