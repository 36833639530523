import { mainLang, subLang } from 'src/app/modules/Operation/constants';
import { PaginateList } from '../../../../../common/types/common-types';
import { CategoryItem } from '../../Category/types/category-types';

export enum LibraryPublishedMode {
  ALL = 'ALL',
  PUBLISHED = 'Published',
  UNPUBLISHED = 'Unpublished',
  DRAFT = 'Draft',
  PUBLISHEDTOSETONLY = 'PublishedToSetOnly',
}

type UploadFile = {
  fileName: string;
  url: string;
  id: string;
} | null;

export type SharedInfoState = {
  name?: string;
  coverpage?: UploadFile;
  introduction?: string;
  tag?: string[];
  file?: UploadFile;
  link?: string;
  content?: string;
  canShared?: string;
  sharedTitle?: string;
  sharedCoverpage?: UploadFile;
  sharedIntroduction?: string;
  bottomBar?: string;
  bottomBarFor?: string;
  allowedForEProfile?: string;
};

export type LibraryListParam = {
  pageSize?: number;
  page?: number;
  name?: string;
  category?: string;
  status?: string;
  type?: string;
  column?: string;
  createTimeStart?: Date | null;
  createTimeEnd?: Date | null;
  module: string;
};

export type LibraryColumnRefItem = {
  id?: string;
  column: string | { id: string };
  module: string;
  weight: number;
};

export type LibraryFileItem = {
  fileName: string;
  importId: string;
  url: number;
};

export type LibraryLocaleItem = {
  id: string;
  locale: string;
  publishedAt: Date;
  _id: string;
};

export type LibraryItem = {
  createdAt: Date;
  id: string;
  locale: string;
  localizations?: LibraryLocaleItem[];
  publishedAt: Date;
  updatedAt: Date;
  lastUpdatedBy: string;
  canShare?: boolean;
  allowedForEProfile?: boolean;
  columnReferences: LibraryColumnRefItem[];
  coverpage: LibraryFileItem;
  data?: any;
  designations?: string[];
  effectiveDate: Date | null;
  expiredDate: Date | null;
  i18nData: any | null;
  introduction: string;
  module: string;
  name: string;
  publishToSetOnly: boolean;
  tag: string[];
  type: string;
  zones: string[];
  category: CategoryItem;
  lastPublishTime?: Date;
  childItems?: LibraryItem[] | string[] | null;
  parentItems?: LibraryItem[] | string[] | { count: number } | null;
  materialOrder?: string[];
  status?: string;
  agentType?: string;
  targetAgentType?: string;
  targetAgentList?: string[];
  agents?: string[] | null;
  includedAgents?: string[] | null;
  excludedAgents?: string[] | null;
  uuid?: string;
  productType?: string;
  maxWeight: string;
};

export type LibraryEditItem = {
  canShare?: boolean;
  allowedForEProfile?: boolean;
  columnReferences?: LibraryColumnRefItem[] | null;
  coverpage?: LibraryFileItem;
  data?: Object;
  designations?: string[];
  effectiveDate?: Date | null;
  expiredDate?: Date | null;
  i18nData?: any | null;
  introduction?: string;
  module?: string;
  name?: string;
  publishToSetOnly?: boolean;
  tag?: string[];
  type?: string;
  // lastUpdatedBy: string;
  zones?: string[];
  category?: string | null;
  columnIds?: string[] | null;
  lastPublishTime?: Date;
  childItems?: string[] | null;
  parentItems?: LibraryItem[] | string[] | { count: number } | null;
  materialOrder?: string[];
  status: string;
  productType?: string;
  agentType?: string;
  targetAgentType?: string;
  targetAgentList?: string[];
  agents?: string[] | null;
  includedAgents?: string[] | null;
  excludedAgents?: string[] | null;
};

export type LibraryPaginateList = PaginateList<LibraryItem> & { libraryList: LibraryItem[]; totalRecords: number };

export type ResourceLibraryFormState = {
  rType: string;
  category: string;
  productType?: string;
  lastUpdatedBy: string;
  status: string;
  materialSet: any[];
  [mainLang]?: SharedInfoState;
  [subLang]?: SharedInfoState;
  agent?: string;
  moduleColumn: { column: string; weight: number | null }[];
  effectiveRange?: [string, string];
  isPublish?: string;
  allWeights?: { [key: string]: string[] };
  designations?: string[] | [];
  zones?: string[] | [];
  resourceId?: string;
  underMaterialSetIDs?: string[];
  removedMaterials?: any[];
  data?: any;
  parentItems?: LibraryItem[] | string[] | { count: number } | null;
  agentType?: string;
  targetAgentType?: string;
  targetAgentList?: string[];
  agents?: string[] | [];
  includedAgents?: string[] | [];
  excludedAgents?: string[] | [];
};

interface TempResourceLibrarySubmitState extends Partial<Omit<ResourceLibraryFormState, 'materialSet'>> {
  materialSet?: {
    sys: {
      id: any;
      linkType: string;
      type: string;
    };
  }[];
  // agent: string[];
  columnsRefs: { sys: { id: string; linkType: 'Entry'; type: 'Link' } }[];
  categoryRef: { sys: { id: string; linkType: 'Entry'; type: 'Link' } };
  sharedInfo: { [key: string]: any };
  effectiveDate: string;
  expiredDate: string;
}

export interface ResourceLibrarySubmitState extends Partial<Omit<TempResourceLibrarySubmitState, 'agent'>> {
  agent: string;
}
