import { map } from 'lodash';
import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { overviewReportParams } from 'src/app/modules/PruleadInsightsReporting/types/report-types';
import OverviewReportDetailPage from './components/OverviewReportDetailPage';
import { useJWT } from 'src/app/common/utils';
const ExportJsonExcel = require('js-export-excel');

type OverviewReportListingPageProps = {} & ParamsProps;

type overViewChartType = {
  chartName: string;
  fields: object;
  documents: any[];
};

type selectFilterType = {
  region: string[];
  channel: string[];
};

const initialState: overviewReportParams = {
  startDt: undefined,
  endDt: undefined,
  region: '',
  channel: '',
};

const paramsInitiator = (initialParams?: Record<string, string>): overviewReportParams => {
  return initialParams
    ? {
        startDt: undefined,
        endDt: undefined,
        region: '',
        channel: '',
      }
    : initialState;
};

export const overviewReportFilterKeys = [];

const OverviewReportListingPage: FC<OverviewReportListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const jwt = useJWT() || {};
  const { region, channel } = jwt;
  const [filterState, setFilterState] = useState<overviewReportParams>({
    ...paramsInitiator(initialParams),
    region,
    channel,
  });
  const [chartData, setChartData] = useState<overViewChartType[]>();
  const [selectFilter, setSelectFilter] = useState<selectFilterType>({
    region: [],
    channel: [],
  });

  // const downloadExcel = () => {};
  const downloadExcel = () => {
    let datas: { sheetData: any[]; sheetName: string; sheetHeader: any[] }[] = [];

    if (chartData) {
      chartData.forEach((value) => {
        let sheetHeader: any[] = [];
        let sheetData: any[] = [];

        let fields = value.fields;
        let fieldsKey: string[] = [];
        type fieldsType = keyof typeof fields;

        for (let key in fields) {
          fieldsKey.push(key as fieldsType);
          sheetHeader.push(fields[key as fieldsType]);
        }

        //adjust the order of data : group -> group_series_(index) -> value
        value.documents.forEach((doc: any) => {
          let tmpDoc: Record<string, string> = {};
          fieldsKey.forEach((key) => {
            tmpDoc[key] = doc[key] ?? '';
          });
          sheetData.push(tmpDoc);
        });
        // over
        datas.push({
          sheetData: sheetData,
          sheetName: value.chartName,
          sheetHeader: sheetHeader,
        });
      });

      var option = {
        fileName: Translation('insights.dashboard.filter.aolOverview'),
        datas: datas,
      };
      var toExcel = new ExportJsonExcel(option);

      toExcel.saveExcel();
    }
  };

  return (
    <>
      <PruFilter
        title={Translation('insights.dashboard.filter.aolOverview')}
        itemDef={[
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'startDt',
            fieldTo: 'endDt',
            displayName: 'Date',
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'region',
            displayName: 'Region',
            initialValue: filterState.region,
            choices: [{ displayName: region, value: region }],
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'channel',
            displayName: 'Channel',
            initialValue: filterState.channel,
            choices: [{ displayName: channel, value: channel }],
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            region: data.region,
            channel: data.channel,
            startDt: !!data.startDt ? data.startDt : undefined,
            endDt: !!data.endDt ? data.endDt : undefined,
          })
        }
        fetchData={() => {}}
        downloadExcel={downloadExcel}
      />
      <OverviewReportDetailPage
        filter={filterState}
        selectFilter={(data) => setSelectFilter(data)}
        updateData={(data) => setChartData(data)}
      />
    </>
  );
};

export default OverviewReportListingPage;
