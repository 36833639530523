import React, { useEffect, useState, useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Checkbox, IconButton, TableFooter, TablePagination, Tooltip, Typography, Button } from '@mui/material';
import { useCommonStyles } from '../../styles/common-styles';
import PruTablePaginationActions from '../Table/PruTablePaginationActions';
import { ProColumns, ToolBarRender, HeaderTitle, RowSelection } from './index';
import ToolBarContainer from './ToolBarContainer';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
// import DeleteIcon from '@mui/icons-material/Delete';
import ProTableCell from './ProTableCell';
import ProTableRow from './ProTableRow';
import StickyTableCell from './StickyTableCell';
import PruTableLoading from '../Table/PruTableLoading';
import PruTableEmptyRow from '../Table/PruTableEmptyRow';
import { useIntl } from 'react-intl';

type ListProps<T> = {
  isLoading?: boolean;
  dataSource: T[];
  columns: ProColumns<T>[];
  onRefresh: () => void;
  onChangePage: (params: { page?: number; pageSize?: number }) => void;
  page: number;
  pageSize: number;
  total: number;
  toolBarRender: ToolBarRender;
  headerTitle: HeaderTitle;
  headerTitleStyle?: string;
  rowKey?: string;
  rowSelection?: RowSelection<T>;
  handleSort?: (sorter: { [key: string]: string }) => void;
  hidePagination?: boolean;
  hideToolBar?: boolean;
  singleSelect?: boolean;
  hideAllSelect?: boolean;
  titleStyle?: React.CSSProperties;
  titleFontStyle?: React.CSSProperties;
};

const useProTableStyles = makeStyles()((theme) => ({
  root: {
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
    overflowY: 'hidden',
  },
  table: {
    minWidth: 700,
    backgroundColor: theme.palette.common.white,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  tableHeader: {
    width: '100%',
    padding: '20px 0 20px 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  footer: {
    width: '100%',
  },
  operationContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  operationBtn: {
    color: 'blue',
    cursor: 'pointer',
    textDecoration: 'underline',
    fontSize: '0.85rem',
    whiteSpace: 'nowrap',
  },
  disabled: {
    color: '#BBBBBB',
    cursor: 'default',
  },
  title: {
    flex: '1 1 100%',
  },
  tableBodyWraper: {
    position: 'relative',
  },
  backdrop: {
    position: 'fixed',
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.common.white,
    backgroundColor: `${theme.palette.common.white} !important`,
    opacity: '0.5 !important',
  },
}));

let ProTableList = <T extends { [key: string]: any } = {}>({
  isLoading,
  onRefresh,
  onChangePage,
  columns,
  dataSource,
  total,
  page,
  pageSize,
  toolBarRender,
  headerTitle,
  headerTitleStyle,
  rowKey,
  rowSelection,
  handleSort,
  hidePagination,
  singleSelect,
  hideToolBar,
  hideAllSelect,
  titleStyle,
  titleFontStyle,
}: ListProps<T>) => {
  columns = columns.filter((item) => !item.hideInTable);

  const [selectedRow, setSelectedRow] = useState<T[]>(rowSelection?.selectItems || []);
  const { classes, cx } = useProTableStyles();
  const { classes: commonClasses } = useCommonStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const TranslationWithVariable = (key: string, count: number | string) =>
    intl.formatMessage({ id: key }, { num: count });

  const { onChange: onRowSelectChange, getCheckboxProps } = rowSelection || {
    onChange: () => {},
    getCheckboxProps: () => ({ disabled: false }),
  };

  const selectableRowNumber = useMemo(() => {
    return dataSource.map((item) => getCheckboxProps(item).disabled).length;
    // eslint-disable-next-line
  }, [dataSource]);
  const handleChangePage = (event: any, newPage: number) => {
    onChangePage({ page: newPage });
  };

  useEffect(() => {
    if (dataSource && page > Math.ceil(total / pageSize)) {
      onChangePage({ page: 0 });
    }
    // eslint-disable-next-line
  }, [total, page, pageSize]);

  const handleChangeRowsPerPage = (event: any) => {
    onChangePage({ page: 0, pageSize: parseInt(event.target.value) });
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      // todo: 请求参数要加上pageSize属性
      const newSelectedRow = [...dataSource.filter((row) => !getCheckboxProps(row).disabled)];
      setSelectedRow(newSelectedRow);
      return;
    }
    setSelectedRow([]);
  };

  const clearSelected = () => {
    setSelectedRow(rowSelection?.selectItems || []);
  };

  const handleClearSelect = () => {
    clearSelected();
  };

  const handleSelectSingleRow = (event: any, row: T) => {
    if (event.target.checked) {
      const newSelectedRow = singleSelect ? [row] : [...selectedRow, row];
      setSelectedRow(newSelectedRow);
      return;
    }
    const index = selectedRow.indexOf(row);
    if (index > -1) {
      const newSelectedRow = [...selectedRow];
      newSelectedRow.splice(index, 1);
      setSelectedRow(newSelectedRow);
    }
  };

  useEffect(() => {
    clearSelected();
    // eslint-disable-next-line
  }, [dataSource]);

  useEffect(() => {
    onRowSelectChange(undefined, [...selectedRow]);
    // eslint-disable-next-line
  }, [selectedRow]);

  return (
    <div className={`${classes.root} table-body`}>
      {!hideToolBar && (
        <ToolBarContainer>
          <div
            className={cx(
              headerTitle === '' ? commonClasses.noBorderHeader : commonClasses.header,
              classes.title,
              headerTitleStyle,
            )}
          >
            {headerTitle}
          </div>
          <Tooltip title="Refresh">
            <IconButton onClick={onRefresh}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
          {toolBarRender && (
            <div style={{ justifyContent: 'flex-end', marginLeft: 15 }} className={classes.rowContainer}>
              {toolBarRender().map((btn) => (
                <span style={{ marginRight: 16, whiteSpace: 'nowrap' }}>{btn}</span>
              ))}
            </div>
          )}
        </ToolBarContainer>
      )}
      {!hideAllSelect && selectedRow.length > 0 && !singleSelect && rowSelection ? (
        <ToolBarContainer highlight={selectedRow.length > 0}>
          <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
            {TranslationWithVariable('component.protable.selected-row', selectedRow.length)}
          </Typography>
          {/* <Tooltip title="Delete">
                <IconButton aria-label="delete" onClick={handleClearSelect}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip> */}
          <Button color="secondary" onClick={handleClearSelect}>
            {Translation('component.protable.selected-clear')}
          </Button>
        </ToolBarContainer>
      ) : null}
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {rowSelection ? (
                <ProTableCell padding="checkbox" style={titleStyle}>
                  {hideAllSelect || singleSelect ? (
                    <span></span>
                  ) : (
                    <Checkbox
                      disabled={selectableRowNumber === 0}
                      icon={
                        <CheckBoxOutlineBlankOutlinedIcon
                          style={titleFontStyle ? titleFontStyle : { color: '#FFFFFF' }}
                        />
                      }
                      indeterminateIcon={
                        <IndeterminateCheckBoxIcon style={titleFontStyle ? titleFontStyle : { color: '#FFFFFF' }} />
                      }
                      indeterminate={selectedRow.length > 0 && selectedRow.length < selectableRowNumber}
                      checked={selectableRowNumber > 0 && selectedRow.length === selectableRowNumber}
                      onChange={handleSelectAllClick}
                      inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                  )}
                </ProTableCell>
              ) : null}
              {columns.map((col, index) =>
                col.sticky ? (
                  <StickyTableCell className={`sticky-cell`}>{col.title}</StickyTableCell>
                ) : (
                  <ProTableCell
                    key={`header-${col.dataIndex}-${index}`}
                    align={col.align}
                    sorter={col.sorter}
                    dataIndex={col.dataIndex}
                    handleSort={handleSort}
                    filterDropdown={col.filterDropdown}
                    style={titleStyle}
                  >
                    <span style={titleFontStyle}>{col.title}</span>
                  </ProTableCell>
                ),
              )}
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBodyWraper}>
            <PruTableLoading isLoading={!!isLoading} />
            <PruTableEmptyRow isEmpty={!!(dataSource && dataSource.length <= 0)} />
            {dataSource &&
              dataSource.map((row, index) => {
                return (
                  <ProTableRow
                    key={`row-${rowKey ? row[rowKey] : ''}-${index}`}
                    columns={columns}
                    rowSelection={rowSelection}
                    row={row}
                    selectedRow={selectedRow}
                    rowIndex={index}
                    handleSelectSingleRow={handleSelectSingleRow}
                  />
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <TableFooter component="div" className={`table-footer-css`}>
        <TableRow component="div">
          {hidePagination ? null : (
            <TablePagination
              align="left"
              rowsPerPageOptions={[5, 10, 20, 50]}
              component="div"
              colSpan={rowSelection ? columns.length + 1 : columns.length}
              count={total ? total : 0}
              rowsPerPage={pageSize}
              page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={PruTablePaginationActions}
            />
          )}
        </TableRow>
      </TableFooter>
    </div>
  );
};

export default ProTableList;
