import React from 'react';
import Form from '../../../../../../../common/components/Form';
import { TextField } from '@mui/material';
import { useIntl } from 'react-intl';
import FileUploader from '../../../../../../../common/components/FileUploader';
import { createBlob, getBlob } from 'src/app/common/network';
import { fileUpload } from 'src/app/common/utils';
import { useDispatch } from 'react-redux';

interface FormListProp {
  disabled: boolean;
  activeLang: string;
  form: any;
}

export default function SubFormList(props: FormListProp) {
  const { activeLang, disabled } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const Translation = (id: string) => intl.formatMessage({ id });

  const DEFAULT_REQUIRED_RULES = [{ required: true, message: Translation('component.form-required-text') }];
  const placeEnter = Translation('app.input.placeholder.please-enter');

  const handleUploadFile = async ({
    file,
    fileName,
    fileType,
    description = '',
  }: {
    file: any;
    fileName: string;
    fileType: string;
    description?: string;
  }) => {
    try {
      const createBlobRes = await createBlob(
        { mimeType: fileType, accessLevel: 'anonymous', module: 'popup' },
        dispatch,
      );
      await fileUpload(createBlobRes.url, file, () => {});
      const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);
      const result = blobDetail[0];
      if (result) {
        return { id: result.blobId, fileName: fileName, url: result.url, type: fileType };
      }
    } catch (err) {
    } finally {
    }
  };

  return (
    <>
      <Form.Item
        name={[activeLang, 'name']}
        label={Translation('component.formLabel.name')}
        rules={DEFAULT_REQUIRED_RULES}
      >
        <TextField disabled={disabled} margin="dense" variant="outlined" fullWidth placeholder={placeEnter} />
      </Form.Item>
      <Form.Item
        name={[activeLang, 'popup']}
        label={Translation('component.formLabel.placeholder.popup')}
        rules={DEFAULT_REQUIRED_RULES}
      >
        <FileUploader
          color="secondary"
          disabled={disabled}
          showAllowText={Translation('component.hint.recommend-image-format')}
          upload={handleUploadFile}
          allowedFileTypes={['jpg', 'png', 'jpeg']}
          maxFileSize={5}
          showPreview
          needCompressImage={true}
        />
      </Form.Item>
    </>
  );
}
