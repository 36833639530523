import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import { Dispatch } from 'react';

export const getRule = async (id: string, dispatch?: Dispatch<any>) => {
  try {
    return apiClient.get(`${ADMIN_URL}/prospect-master-rules/${id}`);
  } catch (err: any) {
    throw apiErrorHandler(err, dispatch);
  }
};

export const updateRule = async (id: string, body: any, dispatch?: Dispatch<any>) => {
  try {
    return apiClient.patch(`${ADMIN_URL}/prospect-master-rules/${id}`, {
      ...body,
    });
  } catch (err: any) {
    throw apiErrorHandler(err, dispatch);
  }
};
