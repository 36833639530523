import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { TextField } from '@mui/material';

import { useIntl } from 'react-intl';
import { PruDatePicker } from 'src/app/common/components/PruDatePicker';
import { useStyles } from '../template-detail-page.style';
import { RecruitmentStepProps } from '../template-detail-page.type';
import { useTemplateDetailStep1Hook } from './template-detail-step1.hook';
import { getNextDay } from '../../../utils';

export function TemplateDetailStep1(props: RecruitmentStepProps) {
  const { classes } = useStyles();
  const intl = useIntl();
  const t = (id: string) => intl.formatMessage({ id });
  const isView = props.queryAction === 'view';
  // allow to use past-date for view mode
  const minDate = isView ? null : getNextDay();

  const {
    validateText,
    formType,
    formTypeOptions,

    validDateFrom,
    validTo,
    formTypeChangeHandler,
    dateChangeHandler,
  } = useTemplateDetailStep1Hook(props);

  return (
    <div className={`tw-text-black tw-mb-20 ${classes.step1}`}>
      <div className="step1-row">
        <div className="step1-label required">{t('form_type')}</div>
        <div>
          <FormControl variant="standard" sx={{ width: 456 }}>
            <Select
              value={formType}
              onChange={formTypeChangeHandler}
              displayEmpty
              disabled={props.queryAction !== 'create'}
            >
              {formTypeOptions.map((i: string) => (
                <MenuItem key={i} value={i}>
                  {t(i)}
                </MenuItem>
              ))}
            </Select>
            <div className="error-date">
              <FormHelperText>{props.version ? '' : t('unable_to_duplicate_desc')}</FormHelperText>
            </div>
          </FormControl>
        </div>
      </div>
      <div className="step1-row">
        <div className="step1-label required">{t('form_version')}</div>
        <div className={isView ? 'tw-text-gray-400' : ''}>{props.version}</div>
      </div>
      <div className="step1-row col-top">
        <div className="step1-label">{t('validity_period')}</div>
        <div className="step1-content">
          <div className={`${validateText.fromError ? 'error-date' : ''}`}>
            <div className="required valid-from-label">{t('valid_from')}</div>
            <FormControl variant="standard" sx={{ width: 456 }}>
              <PruDatePicker
                minDate={minDate}
                value={validDateFrom}
                format="DD/MM/YYYY 00:00"
                onChange={dateChangeHandler}
                slotProps={{ textField: { readOnly: true } as any }}
                disabled={isView}
              />
              <FormHelperText>{validateText.fromError}</FormHelperText>
            </FormControl>
          </div>
          <div className={`${validateText.toError ? 'error-date' : ''}`}>
            <div className="tw-mt-5 valid-from-label">{t('valid_to')}</div>
            <FormControl variant="standard" sx={{ width: 456 }}>
              <TextField disabled value={validTo} />
            </FormControl>
          </div>
        </div>
      </div>
    </div>
  );
}
