import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import PruRoute from '../../../../common/components/PruRoute';
import { NOT_FOUND_ERROR_PAGE_PATH } from '../../../../common/constants';
import { LEAD_MICROSITE_BASE_PATH, MICROSITE_BASE_PATH, MTmode } from '../../constants';
import List from './list';
import Detail from './detail';
import { SurveyResultPage } from './survey-result/survey-result.screen';

const MicrositeRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect
        exact
        from={`${LEAD_MICROSITE_BASE_PATH}${MICROSITE_BASE_PATH}`}
        to={`${LEAD_MICROSITE_BASE_PATH}${MICROSITE_BASE_PATH}/list`}
      />
      <PruRoute
        exact
        path={`${LEAD_MICROSITE_BASE_PATH}${MICROSITE_BASE_PATH}/list`}
        render={(props) => <List {...props} />}
      />
      <PruRoute
        exact
        path={`${LEAD_MICROSITE_BASE_PATH}${MICROSITE_BASE_PATH}/create`}
        render={(props) => <Detail formMode={MTmode.CREATE} {...props} />}
      />
      <PruRoute
        exact
        path={`${LEAD_MICROSITE_BASE_PATH}${MICROSITE_BASE_PATH}/edit/:id`}
        render={(props) => <Detail formMode={MTmode.EDIT} {...props} />}
      />
      <PruRoute
        exact
        path={`${LEAD_MICROSITE_BASE_PATH}${MICROSITE_BASE_PATH}/:id/results`}
        render={(props) => <SurveyResultPage {...props} />}
      />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default MicrositeRoutes;
