import React, { FC } from 'react';

import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import PruTable from 'src/app/common/components/PruTable/PruTable';
import { getDefaultDisplayDate } from 'src/app/common/utils';
import { MassUploadStatusPaginateList } from 'src/app/modules/Profile/types/agent-profile-types';
import { massUploadStatusListPath } from '../MassUploadStatusRoute';

type MassUploadStatusListProps = {
  isLoading: boolean;
  massUploadList?: MassUploadStatusPaginateList;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
};

type DialogState = {
  open: boolean;
  id: string;
};

const MassUploadStatusList: FC<MassUploadStatusListProps> = ({
  isLoading,
  massUploadList,
  onRefresh,
  onChangePage,
}) => {
  const history = useHistory();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  return (
    <>
      <PruTable
        title={Translation('agentProfile.ad_mass_upload_status_header_title')}
        headerBtnDef={[]}
        disableBulkSelect
        operationDef={[]}
        columnDef={[
          {
            isId: true,
            hidden: true,
            keyIndex: '_id',
            displayName: '',
            // eslint-disable-next-line
            renderData: (row) => '',
          },

          {
            keyIndex: 'fileName',
            align: 'left',
            displayName: Translation('agentProfile.ad_file_name_label'),
            renderData: (row) => row.fileName || '-',
          },
          {
            keyIndex: 'uploadType',
            align: 'left',
            displayName: Translation('agentProfile.ad_file_type_label'),
            renderData: (row) => row.uploadType || '-',
          },
          {
            keyIndex: 'totalRowCount',
            align: 'left',
            displayName: Translation('agentProfile.ad_total_row_label'),
            renderData: (row) => row.totalRowCount.toString() || '-',
          },
          {
            keyIndex: 'failedRowCount',
            align: 'left',
            displayName: Translation('agentProfile.ad_failed_row_label'),
            // row.failedRowCount.toString()
            renderData: (row) =>
              (
                <div
                  style={row.failedRowCount > 0 ? { color: 'red', cursor: 'pointer' } : {}}
                  onClick={
                    row.failedRowCount > 0
                      ? () => {
                          history.push(`${massUploadStatusListPath}/${row._id}`);
                        }
                      : () => ''
                  }
                >
                  {row.failedRowCount.toString()}
                </div>
              ) || '-',
          },
          {
            keyIndex: 'uploadedAt',
            align: 'left',
            displayName: Translation('agentProfile.ad_uploaded_time_label'),
            renderData: (row) => getDefaultDisplayDate(row.uploadedAt, 'datetime') || '-',
          },
        ]}
        isLoading={isLoading}
        onRefresh={onRefresh}
        dataSource={massUploadList?.docs}
        totalPages={massUploadList?.totalPages}
        totalRecords={massUploadList?.totalDocs}
        onChangePage={onChangePage}
      />
    </>
  );
};

export default MassUploadStatusList;
