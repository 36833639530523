import dayjs, { Dayjs } from 'dayjs';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';
import { TextFieldProps } from '@mui/material';
import { useStyles } from './date-picker.style';

type PruDatePickerProps = {
  value: Date | null;
  minDate?: Date | null;
  maxDate?: Date | null;
  onChange?: (value: Date | null) => void;
} & Omit<DatePickerProps<Dayjs>, 'value' | 'minDate' | 'maxDate' | 'onChange'>;

const PruDatePicker = ({ value, minDate, maxDate, onChange, slotProps, ...props }: PruDatePickerProps) => {
  const { classes } = useStyles();
  const textFieldProps = (slotProps?.textField as TextFieldProps) || {};
  return (
    <DatePicker
      value={value ? dayjs(value) : null}
      minDate={minDate ? dayjs(minDate) : undefined}
      maxDate={maxDate ? dayjs(maxDate) : undefined}
      onChange={(newValue) => onChange && onChange(newValue ? newValue.toDate() : null)}
      slotProps={{
        ...slotProps,
        textField: {
          ...textFieldProps,
          variant: textFieldProps.variant ? textFieldProps.variant : 'outlined',
          className: `
            ${textFieldProps.variant === 'standard' ? classes.standardDatePickerIcon : undefined}
            ${textFieldProps.className}
          `,
        },
      }}
      {...props}
    />
  );
};

export default PruDatePicker;

/** Some usage tips */
/*
<PruDatePicker
  minDate={minDate}
  value={validDateFrom}
  format="DD/MM/YYYY 00:00" // 00:00 disabled input HH:MM
  onChange={dateChangeHandler}
  slotProps={{ textField: { readOnly: true } as any }} // disabled the keyboard input, only allow picker to select date
/>
*/
