import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    columnGap: 15,
    rowGap: 8,
    marginTop: 15,
  },
  container: {
    padding: 20,
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    columnGap: 20,
  },
}));
