import React, { FC, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { NewsCategoryPaginateList } from '../../../types/news-type';
import { fetchNewsCategoryList, NewsCategoryListParam } from '../../../network/newsCrud';
import { useDataProvider } from 'src/app/common/utils';
import { useDispatch } from 'react-redux';
import CategoryList from './components/CategoryList';

const initialFilterState: NewsCategoryListParam = {
  page: 1,
  limit: 5,
};

const NewsCategoryListPage: FC<RouteComponentProps> = () => {
  const dispatch = useDispatch();

  const [filterState, setFilterState] = useState(initialFilterState);
  const [newsCategoryList, setNewsCategoryList] = useState<NewsCategoryPaginateList>();

  const { isLoading, refreshData } = useDataProvider<NewsCategoryPaginateList>(
    async () => {
      try {
        return await fetchNewsCategoryList(filterState, dispatch);
      } catch (err) {}
    },
    setNewsCategoryList,
    false,
  );

  return (
    <CategoryList
      isLoading={isLoading}
      newsCategoryList={newsCategoryList}
      onRefresh={refreshData}
      onChangePage={(page, rowsPerPage) => {
        setFilterState({
          ...filterState,
          page,
          limit: rowsPerPage,
        });
        refreshData();
      }}
    />
  );
};

export default NewsCategoryListPage;
