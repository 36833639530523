import * as ko from "knockout";
import { QuestionSignaturePadModel } from "survey-core";
import { Serializer } from "survey-core";
import { QuestionImplementor } from "./koquestion";
import { Question } from "survey-core";

export class QuestionSignaturePad extends QuestionSignaturePadModel {
  private _implementor: QuestionImplementor;
  constructor(name: string) {
    super(name);
  }
  protected onBaseCreating() {
    super.onBaseCreating();
    this._implementor = new QuestionImplementor(this);
  }
  public dispose() {
    this._implementor.dispose();
    this._implementor = undefined;
    super.dispose();
  }
}

Serializer.overrideClassCreator("signaturepad", function() {
  return new QuestionSignaturePad("");
});
