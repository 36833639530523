import React, { FC, useState, useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { RootState } from 'src/redux/store';
import { AuthenticationState } from 'src/app/modules/Auth/_redux/authSlice';
import { ApiErrorResponse } from 'src/app/common/network';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { PaginateList } from 'src/app/common/types/common-types';
import { LayoutSplashScreen } from 'src/app/layout';
import {
  ApprovalCampaignItem,
  ResourceItem,
  ParticipantIndividualItem,
  LouTemplateItem,
} from 'src/app/modules/AgencyCampaign/types/approval-types';
import {
  ParticipantListParam,
  fetchApprovalItem,
  fetchApprovalResources,
  fetchUserRole,
  fetchParticipantList,
  fetchLouTemplateList,
  cancelCampaignSubmission,
} from 'src/app/modules/AgencyCampaign/network/approvalCrud';
import ApprovalForm from './components/ApprovalForm';
import { ErrorFieldType, useErrorHandler } from '../../../../../common/utils/form-error-utils';
import { convertArrayToObject } from '../../../../../common/utils/common-utils';
import { approvalPath } from '../ApprovalRoutes';
import { AlertType, appendAlertItem } from 'src/redux/common/commonSlice';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';

import {
  validationCompareChecking,
  validationLengthChecking,
  validationMandatoryDependsChecking,
  validationCurrencyChecking,
} from '../../../utils/validations';
import moment from 'moment';

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    section: any;
    field: any;
    value: any;
  };
};

type InitFieldAction = {
  type: 'INIT_FORM_DATA';
  payload: {
    value: any;
  };
};

type infoDataFormAction = ModifyFieldAction | InitFieldAction;

const initialState: any = {};

const infoDataFormReducer = (state: any, action: infoDataFormAction): any => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.section]: {
          ...state[action.payload.section],
          [action.payload.field]: action.payload.value,
        },
      };
    case 'INIT_FORM_DATA':
      return {
        ...state,
        ...action.payload.value,
      };
  }
};

const previousDataFormReducer = (state: any, action: infoDataFormAction): any => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.section]: {
          ...state[action.payload.section],
          [action.payload.field]: action.payload.value,
        },
      };
    case 'INIT_FORM_DATA':
      return {
        ...state,
        ...action.payload.value,
      };
  }
};

const ApprovalDetailPage: FC<RouteComponentProps & ParamsProps> = ({ history, initialParams }) => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const { user } = useSelector<RootState, AuthenticationState>((state) => state.auth);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [approvalItem, setApprovalItem] = useState<ApprovalCampaignItem>();
  const [approvalResources, setApprovalResources] = useState<ResourceItem[]>([]);
  const [userRoleList, setUserRoleList] = useState<string[]>([]);
  const [louTemplateList, setLouTemplateList] = useState<LouTemplateItem[]>([]);

  const [filterState, setFilterState] = useState<ParticipantListParam>({
    id,
    page: 1,
    limit: 5,
  });
  const [participantList, setParticipantList] = useState<any>();
  const [validationArray, setValidationArray] = useState<any[]>([]);
  const [formState, formDispatch] = useReducer(infoDataFormReducer, initialState);
  const [previousDataState, previousDataDispatch] = useReducer(previousDataFormReducer, initialState);
  const validationHandler = useErrorHandler(formState.campaign, validationArray);
  const [campaignEndDateEqualToStartDate, setCampaignEndDateEqualToStartDate] = useState<any>();
  const [campaignEndDateGreaterThanTarget, setCampaignEndDateGreaterThanTarget] = useState<any>();

  const handleValidationArray = (dataStructure: any) => {
    const mandatoryArray: any[] = [];
    dataStructure.sections
      .filter(
        (item: any) => item.key != 'participant',
        //  && item.key != "attachments"
      )
      .map((sectionItem: any) => {
        sectionItem.sectionFields.map((fieldItem: any) => {
          let ruleObject = convertArrayToObject(fieldItem.rules, 'title');
          if (ruleObject.Mandatory && ruleObject.Mandatory.key == 'mandatory' && ruleObject.Mandatory.value) {
            mandatoryArray.push({
              section: sectionItem.key,
              name: fieldItem.key,
              fieldType: ErrorFieldType.MANDATORY,
            });
          }
          if (ruleObject.Mandatory && ruleObject.Mandatory.key == 'mandatoryDepends') {
            mandatoryArray.push({
              section: sectionItem.key,
              name: fieldItem.key,
              fieldType: ErrorFieldType.MANDATORY,
              condition: (updatedFormState: any) => {
                return validationMandatoryDependsChecking(
                  sectionItem.key,
                  fieldItem.key,
                  updatedFormState,
                  ruleObject.Mandatory,
                );
              },
            });
          }

          if (ruleObject.Validation) {
            switch (ruleObject.Validation.key) {
              case 'length':
                mandatoryArray.push({
                  section: sectionItem.key,
                  name: fieldItem.key,
                  fieldType: ErrorFieldType.IMMEDIATE,
                  condition: (updatedFormState: any) => {
                    return validationLengthChecking(
                      sectionItem.key,
                      fieldItem.key,
                      updatedFormState,
                      ruleObject.Validation,
                    );
                  },
                });
                break;
              case 'compare':
                if (
                  fieldItem.key == 'campaignEndDate' &&
                  ruleObject.Validation.dependsOn &&
                  ruleObject.Validation.dependsOn.indexOf('campaignStartDate') >= 0
                ) {
                  if (ruleObject.Validation.operator == 'eq' && ruleObject.Validation.value == 0) {
                    setCampaignEndDateEqualToStartDate({
                      setEqual: true,
                      sectionKey: sectionItem.key,
                      startDateItem: 'campaignStartDate',
                      fieldKey: fieldItem.key,
                    });
                  }
                  if (ruleObject.Validation.operator == 'gte' && ruleObject.Validation.value > 0) {
                    setCampaignEndDateGreaterThanTarget({
                      setGreateThan: true,
                      sectionKey: sectionItem.key,
                      startDateItem: 'campaignStartDate',
                      fieldKey: fieldItem.key,
                      targetDays: Number(ruleObject.Validation.value),
                    });
                  }
                } else {
                  mandatoryArray.push({
                    section: sectionItem.key,
                    name: fieldItem.key,
                    fieldType: ErrorFieldType.IMMEDIATE,
                    condition: (updatedFormState: any) => {
                      return validationCompareChecking(
                        sectionItem.key,
                        fieldItem.key,
                        updatedFormState,
                        ruleObject.Validation,
                      );
                    },
                  });
                  break;
                }
                break;

              default:
                break;
            }
          }
          if (fieldItem.type == 'currency') {
            mandatoryArray.push({
              section: sectionItem.key,
              name: fieldItem.key,
              fieldType: ErrorFieldType.IMMEDIATE,
              condition: (updatedFormState: any) => {
                return validationCurrencyChecking(sectionItem.key, fieldItem.key, updatedFormState);
              },
            });
          }
        });
      });

    const participantSection =
      dataStructure.sections.filter((item: any) => item.key == 'participant').length > 0
        ? dataStructure.sections.filter((item: any) => item.key == 'participant')[0]
        : undefined;

    if (participantSection) {
      const participantSectionField = convertArrayToObject(participantSection.sectionFields, 'key');
      if (participantSectionField['tnc']) {
        let ruleObject = convertArrayToObject(participantSectionField['tnc'].rules, 'title');
        if (ruleObject.Mandatory && ruleObject.Mandatory.value == true) {
          mandatoryArray.push({
            section: 'participant',
            name: 'tnc',
            fieldType: ErrorFieldType.MANDATORY,
          });
        }
      }
    }
    setValidationArray(mandatoryArray);
  };

  useEffect(() => {
    // Set campaignEndDate be same as campaignStartDate if validation rule applied
    if (campaignEndDateEqualToStartDate && campaignEndDateEqualToStartDate.setEqual) {
      const sectionKey = campaignEndDateEqualToStartDate.sectionKey;
      const fieldKey = campaignEndDateEqualToStartDate.fieldKey;
      const startDayKey = campaignEndDateEqualToStartDate.startDateItem;
      const currentEndDay =
        formState[sectionKey] && formState[sectionKey][fieldKey]
          ? formState[sectionKey] && formState[sectionKey][fieldKey]
          : null;
      const dayEndOfStartDay =
        formState[sectionKey] && formState[sectionKey][startDayKey]
          ? moment(formState[sectionKey][startDayKey]).endOf('day').toDate()
          : null;
      if (dayEndOfStartDay && !moment(currentEndDay).isSame(dayEndOfStartDay)) {
        formDispatch({
          type: 'MODIFY_FIELD',
          payload: { section: sectionKey, field: fieldKey, value: dayEndOfStartDay },
        });
      }
    }

    if (campaignEndDateGreaterThanTarget?.setGreateThan) {
      const sectionKey = campaignEndDateGreaterThanTarget.sectionKey;
      const fieldKey = campaignEndDateGreaterThanTarget.fieldKey;
      const startDayKey = campaignEndDateGreaterThanTarget.startDateItem;
      const currentEndDay =
        formState[sectionKey] && formState[sectionKey][fieldKey]
          ? formState[sectionKey] && formState[sectionKey][fieldKey]
          : null;
      const dayEndDate =
        formState[sectionKey] && formState[sectionKey][startDayKey]
          ? moment(formState[sectionKey][startDayKey]).add(campaignEndDateGreaterThanTarget.targetDays, 'days').toDate()
          : null;
      if (dayEndDate && !moment(currentEndDay).isSame(dayEndDate)) {
        validationHandler.onDismissErrorHandler(fieldKey, dayEndDate);
        formDispatch({
          type: 'MODIFY_FIELD',
          payload: { section: sectionKey, field: fieldKey, value: dayEndDate },
        });
      }
    }
    // eslint-disable-next-line
  }, [formState?.campaign?.campaignStartDate]);

  const refreshParticipantList = (newFilterState?: ParticipantListParam) => {
    const filter = newFilterState || filterState;
    fetchParticipantList(filter, dispatch)
      .then((result) => {
        setParticipantList(result);
      })
      .catch((err: ApiErrorResponse) => {
        history.push(NOT_FOUND_ERROR_PAGE_PATH);
      });
  };

  const refreshLouTemplateList = () => {
    fetchLouTemplateList(dispatch)
      .then((result) => {
        setLouTemplateList(result);
      })
      .catch((err: ApiErrorResponse) => {
        history.push(NOT_FOUND_ERROR_PAGE_PATH);
      });
  };

  const reloadData = () => {
    if (id) {
      setIsLoading(true);
      refreshParticipantList();
      refreshLouTemplateList();
      fetchApprovalItem(id, dispatch)
        .then((result) => {
          formDispatch({
            type: 'INIT_FORM_DATA',
            payload: { value: result.agencyCampaign },
          });
          previousDataDispatch({
            type: 'INIT_FORM_DATA',
            payload: { value: result.agencyCampaign },
          });
          setApprovalItem(result);
          handleValidationArray(result.agencyCampaign.typeId);
        })
        .catch((err: ApiErrorResponse) => {
          history.push(NOT_FOUND_ERROR_PAGE_PATH);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const reloadResources = () => {
    if (id) {
      fetchApprovalResources(id, dispatch)
        .then((result) => {
          setApprovalResources(result);
        })
        .catch((err: ApiErrorResponse) => {
          history.push(NOT_FOUND_ERROR_PAGE_PATH);
        });
    }
  };

  const loadUserRoleList = async () => {
    const result = await fetchUserRole({
      type: 'STAFF',
      user: user?.sub || '',
    });
    setUserRoleList(result);
  };

  useEffect(() => {
    loadUserRoleList();
  }, []);

  useEffect(() => {
    reloadResources();
    reloadData();
    // eslint-disable-next-line
  }, [id]);

  const onCancelCampaign = ({ reason }: any) => {
    const data = {
      id,
      reason,
    };
    cancelCampaignSubmission(data, dispatch).then(() => {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Cancel submission successfully`,
          },
        ]),
      );
    });
    history.push(`${approvalPath}/list?status=${initialParams?.status ?? 'pendingApproval'}`);
  };
  return (
    <>
      {isLoading || !approvalItem ? (
        <LayoutSplashScreen />
      ) : (
        <ApprovalForm
          previousDataDispatch={previousDataDispatch}
          previousDataState={previousDataState}
          formDispatch={formDispatch}
          formState={formState}
          errorState={validationHandler.errorState}
          validationHandler={validationHandler}
          approvalItem={approvalItem}
          approvalResources={approvalResources}
          user={user}
          userRoleList={userRoleList}
          participantList={participantList}
          louTemplateList={louTemplateList}
          reloadResources={reloadResources}
          onChangePage={(page, rowsPerPage) => {
            const newFilterState = {
              ...filterState,
              page,
              limit: rowsPerPage,
            };
            setFilterState(newFilterState);
            refreshParticipantList(newFilterState);
          }}
          refreshParticipantList={refreshParticipantList}
          onCancel={onCancelCampaign}
          campaignEndDateEqualToStartDate={campaignEndDateEqualToStartDate}
          campaignEndDateGreaterThanTarget={campaignEndDateGreaterThanTarget}
        />
      )}
    </>
  );
};

export default ApprovalDetailPage;
