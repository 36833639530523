import { Dispatch } from 'react';
import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import { forEach } from 'lodash';
import { SLAConfigItem } from '../types/sla-types';
import { PaginateList } from 'src/app/common/types/common-types';

const slaEndPoint = 'sla';

export type GetSLAConfigListParams = {
  type: string;
  name?: string;
  page: number;
  limit: number;
};

export type CreateSLAConfigItemBody = {
  name: string;
  type: string;
  startTime: string;
  endTime: string;
  durationInMinutes: number;
  durationInDays: number;
  isPullBackLeadEnabled: boolean;
  isWeekendIncluded: boolean;
  waitInMinutesToReassignLead?: number;
  createdBy: string;
  updatedBy: string;
};

export type UpdateSLAConfigItemBody = {
  name: string;
  startTime: string;
  endTime: string;
  durationInMinutes: number;
  durationInDays: number;
  isPullBackLeadEnabled: boolean;
  isWeekendIncluded: boolean;
  waitInMinutesToReassignLead?: number;
  updatedBy: string;
};

export const fetchSLAConfigList = async (
  params: GetSLAConfigListParams,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<SLAConfigItem>> => {
  let queryPath = `${ADMIN_URL}/${slaEndPoint}?`;
  forEach(params, (param, key) => {
    if (key === 'page') {
      queryPath += `${key}=${encodeURIComponent(param as any)}&`;
    } else {
      queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
    }
  });
  return apiClient
    .get<PaginateList<SLAConfigItem>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchSLAConfigItem = async (id: string, dispatch?: Dispatch<any>): Promise<SLAConfigItem> => {
  return apiClient
    .get<any>(`${ADMIN_URL}/${slaEndPoint}/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createSLAConfigItem = async (body: CreateSLAConfigItemBody, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .post<void>(`${ADMIN_URL}/${slaEndPoint}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const modifySLAConfigItem = async (
  id: string,
  body: UpdateSLAConfigItemBody,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .put<void>(`${ADMIN_URL}/${slaEndPoint}/${id}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const deleteSLAConfigItem = async (id: string, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .delete<void>(`${ADMIN_URL}/${slaEndPoint}/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
