import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useDispatch } from 'react-redux';
import { useDataProvider } from 'src/app/common/utils';
import { PaginateList } from 'src/app/common/types/common-types';
import {
  CampaignFeedbackItem,
  CampaignFeedbackGroupItem,
} from 'src/app/modules/PulseLeads/types/campaign-feedback-types';
import {
  CampaignFeedbackListParam,
  fetchCampaignFeedbackList,
  fetchCampaignFeedbackGroupList,
} from 'src/app/modules/PulseLeads/network/campaignFeedbackCrud';
import CampaignFeedbackList from './components/CampaignFeedbackList';
import CampaignFeedbackGroupList from './components/CampaignFeedbackGroupList';

type CampaignFeedbackListingPageProps = {} & ParamsProps;

const initialState: CampaignFeedbackListParam = {
  page: 1,
  limit: 5,
};

export const campaignFeedbackFilterKeys = [];

const paramsInitiator = (initialParams?: Record<string, string>): CampaignFeedbackListParam => {
  return initialParams
    ? {
        page: 1,
        limit: 5,
      }
    : initialState;
};

const CampaignFeedbackListingPage: FC<CampaignFeedbackListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const [filterState, setFilterState] = useState<CampaignFeedbackListParam>(paramsInitiator(initialParams));
  const [groupFilterState, setGroupFilterState] = useState<CampaignFeedbackListParam>(paramsInitiator(initialParams));
  const [campaignFeedbackList, setCampaignFeedbackList] = useState<PaginateList<CampaignFeedbackItem>>();
  const [campaignFeedbackGroupList, setCampaignFeedbackGroupList] = useState<PaginateList<CampaignFeedbackGroupItem>>();

  const feedbackDataProvider = useDataProvider<PaginateList<CampaignFeedbackItem>>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchCampaignFeedbackList(filterState, dispatch);
      } catch (err) {}
    },
    setCampaignFeedbackList,
    false,
  );

  const feedbackGroupDataProvider = useDataProvider<PaginateList<CampaignFeedbackGroupItem>>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(groupFilterState);
        }
        return await fetchCampaignFeedbackGroupList(groupFilterState, dispatch);
      } catch (err) {}
    },
    setCampaignFeedbackGroupList,
    false,
  );

  return (
    <>
      <CampaignFeedbackGroupList
        isLoading={feedbackGroupDataProvider.isLoading}
        campaignFeedbackGroupList={campaignFeedbackGroupList}
        onRefresh={feedbackGroupDataProvider.refreshData}
        onChangePage={(page, rowsPerPage) => {
          setGroupFilterState({
            ...groupFilterState,
            page,
            limit: rowsPerPage,
          });
          feedbackGroupDataProvider.refreshData();
        }}
      />
      <CampaignFeedbackList
        isLoading={feedbackDataProvider.isLoading}
        campaignFeedbackList={campaignFeedbackList}
        onRefresh={feedbackDataProvider.refreshData}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          feedbackDataProvider.refreshData();
        }}
      />
    </>
  );
};

export default CampaignFeedbackListingPage;
