import { Dispatch } from 'react';
import { forEach } from 'lodash';
import apiClient from '../axios';
import { apiErrorHandler } from '../apiErrorHandler';
import { ADMIN_URL } from '../constants';
import { PaginateList, AgentSegmentItem } from 'src/app/common/types';

export type AgentSegmentListParam = {
  search?: string;
  enableInPruNews?: boolean;
  enableInIncentives?: boolean;
  pagination?: boolean;
  page?: number;
  limit?: number;
};

export const fetchAgentSegmentList = async (
  params: AgentSegmentListParam,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<AgentSegmentItem>> => {
  let queryPath = `${ADMIN_URL}/agent-segment?`;
  forEach(params, (param, key) => {
    queryPath += param !== undefined ? `${key}=${encodeURIComponent(param as any)}&` : '';
  });
  return apiClient
    .get<PaginateList<AgentSegmentItem>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
