import React, { FC, useState, useEffect, useImperativeHandle } from 'react';
import { Button, Dialog, FormHelperText } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useIntl } from 'react-intl';
import ProTable from 'src/app/common/components/ProTable';
import columns from './columns';
import { getCategoryList } from 'src/app/modules/MandatoryTraining/pages/Category/api';
import { getCollectionList, getCollection } from 'src/app/modules/MandatoryTraining/pages/Collection/api';
import { useLang } from 'src/app/i18n';

const useStyles = makeStyles()((theme) => ({
  modalButtons: {
    marginTop: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialog: {
    padding: '15px',
  },
}));

const SpecificTrainingDeeplink: FC<any> = (props) => {
  const { selectId, onSelectItem, isView } = props;
  const locale = useLang();

  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [popUpVisible, setPopUpVisible] = useState<boolean>(false);
  const [categoryList, setCategoryList] = useState<any>({});
  const [selectedRowsState, setSelectedRows] = useState<any>(null);

  useEffect(() => {
    const fetchAPI = async () => {
      await fetchCategoryList();
      if (selectId) {
        await fecthItemById(selectId);
      }
    };

    fetchAPI();

    return;
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setPopUpVisible(false);
  };

  const onSelect = () => {
    setPopUpVisible(true);
  };

  const fetchCategoryList = async () => {
    const filterData = {
      publishStatus: '1',
      pageNum: 0,
      pageSize: 9999,
    };

    const categories = await getCategoryList(filterData).then((res) => res.data);

    const newCategories: any = {};

    categories.data &&
      categories.data.forEach((item: any, index: number) => {
        newCategories[item.id] = {};
        item?.i18nData?.forEach((data: any) => {
          newCategories[item.id][data.locale] = data;
        });
      });

    setCategoryList(newCategories);
  };

  const fecthItemById = async (id: string) => {
    const item: any = await getCollection(id);
    setSelectedRows(item.data);
  };

  const fetchData = async (newFilterState?: any): Promise<any> => {
    const newFilter = newFilterState || {};
    const requestParams = {
      ...newFilter,
      publishStatus: '1',
    };
    requestParams.pageNum = requestParams.page || 0;
    requestParams.pageSize = requestParams.pageSize || 20;
    delete requestParams.page;

    const result = await getCollectionList(requestParams).then((res) => res.data);
    const res = {
      data: result.data || [],
      success: true,
      total: result.total,
    };

    return res;
  };

  const onSubmit = () => {
    onSelectItem(selectedRowsState?.id);
    setPopUpVisible(false);
  };

  return (
    <div style={{ width: '100%', alignSelf: 'flex-start' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {selectedRowsState && (
          <span style={{ marginRight: 15 }}>{`${selectedRowsState.i18nData[0].name} | ${
            categoryList[selectedRowsState.categoryId]?.[locale]?.name || '-'
          }`}</span>
        )}

        {!selectedRowsState && !isView && (
          <Button color="secondary" variant="contained" style={{ marginTop: 15 }} onClick={onSelect}>
            {Translation('deeplink.select.training.label')}
          </Button>
        )}

        {!!selectedRowsState && !isView && (
          <Button color="secondary" variant="contained" style={{ marginTop: 15 }} onClick={onSelect}>
            {Translation('deeplink.clear.select.training.label')}
          </Button>
        )}
      </div>

      <Dialog open={popUpVisible} onClose={onCancel} style={{ backgroundColor: 'gray' }} fullScreen>
        <>
          <div style={{ display: 'flex', marginTop: 20 }}>
            <Button onClick={onCancel} style={{ marginLeft: 20 }} variant="contained">
              {Translation('global.back.btnText')}
            </Button>
          </div>
          <div style={{ padding: 20 }}>
            <ProTable
              rowKey="key"
              filterTitle={Translation('deeplink.specific.list.filter_title')}
              headerTitle={Translation('deeplink.specific.list.header_title')}
              columns={columns(Translation, categoryList, locale)}
              request={(params, sorter, filter) => {
                return fetchData(params);
              }}
              toolBarRender={() => []}
              rowSelection={{
                onChange: (_: any, selectedRows: any) => {
                  setSelectedRows(selectedRows[0]);
                },
                getCheckboxProps: (record: any) => {
                  return {
                    disabled: false,
                  };
                },
                selectItems: [selectedRowsState],
              }}
              singleSelect={true}
              pageSize={20}
            />
          </div>

          <div className={classes.modalButtons}>
            <Button onClick={onCancel} style={{ marginLeft: 20 }} variant="contained">
              {Translation('app.button.cancel')}
            </Button>
            <Button onClick={onSubmit} style={{ marginLeft: 20 }} variant="contained" color="secondary">
              {Translation('app.button.submit')}
            </Button>
          </div>
        </>
      </Dialog>
    </div>
  );
};

export default SpecificTrainingDeeplink;
