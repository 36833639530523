export enum TargetProspectType {
  ALL = 'ALL',
  SPECIFIY = 'SPECIFIC_RULE',
}

export const ModuleTypeList: { [key: string]: string } = {
  Sales: 'Sales Material',
  Promotion: 'Promotion Material',
  CustomerSurvey: 'Customer Survey',
  // AgentSurvey = 'Agent Survey',
};

export const ModuleTypeTranslate: { [key: string]: string } = {
  Sales: 'salesMaterial',
  Promotion: 'promotionMaterial',
  CustomerSurvey: 'customerSurvey',
  // AgentSurvey : 'agentSurvey',
};

export const getI18nDataByCurrentLang = (row: any, locale: string) => {
  const result = row.i18nData.find((item: any) => {
    return item.locale === locale;
  });

  return result;
};

export const PublishStatus: { [item: string]: any } = {
  Draft: {
    label: 'component.formSelectItem.save-as-draft',
    key: 'draft',
    value: 0,
  },
  Publish: {
    label: 'section.common.operation.publish',
    key: 'published',
    value: 1,
  },
  UnPublish: {
    label: 'section.common.operation.unpublish',
    key: 'unpublished',
    value: 2,
  },
};
