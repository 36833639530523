import { withStyles } from 'tss-react/mui';
import { Checkbox } from '@mui/material';

const AcmCheckbox = withStyles(Checkbox, (theme, _params, classes) => ({
  root: {
    [`&.${classes.checked}`]: {
      color: '#E8192C'
    },
    [`&.${classes.disabled}`]: {
      color: 'rgba(0, 0, 0, 0.26)'
    }
  },
  checked: {},
  disabled: {},
}));

export default AcmCheckbox;