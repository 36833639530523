import React, { FC } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useIntl } from 'react-intl';
import ProTable from 'src/app/common/components/ProTable';
import columns from './columns';
import { getList } from '../../api';


const useStyles = makeStyles()((theme) => ({
    colorBox: {
        width: 30,
        height: 30,
        marginLeft: "auto",
        marginRight: "auto",
        borderRadius: '50%'
    }
}));

const sortingListByType = ((data: any) => {
    return data.sort((a: any, b: any) => a.type.charCodeAt(0) - b.type.charCodeAt(0))
});

const List: FC = () => {
    const { classes } = useStyles();
    const intl = useIntl();
    const Translation = (id: string) => intl.formatMessage({ id });


    const fetchData = async (newFilterState?: any) => {
        const newFilter = newFilterState || {}
        const requestParams = {
            ...{
                page: 1,
                limit: 20
            },
            ...newFilter,
        }

        const result = await getList(requestParams);

        const docs = sortingListByType(result.data.docs);
        
        const res = {
            data: docs || [],
            success: true,
            total: result.data.totalDocs
        };

        return res;
    }


    return (
        <div style={{ width: '100%', alignSelf: 'flex-start' }}>
            <ProTable
                rowKey="key"
                filterTitle={''}
                headerTitle={''}
                columns={columns(Translation, classes)}
                pageSize={20}
                request={(params, sorter, filter) => {
                    return fetchData(params)
                }}
                toolBarRender={() => [

                ]}
            />
        </div>
    )
};

export default List;
