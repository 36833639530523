import React, { FC } from 'react';
import { Dialog } from '@mui/material';
import { useStyles } from 'src/app/modules/AgencyCampaign/pages/CampaignType/Form/CampaignTypeDetailPage';
import { SectionItem, SectionFieldItem } from 'src/app/modules/AgencyCampaign/types/campaign-type-types';
import SelectFieldForm from './SelectFieldForm';

type SelectFieldDialogProps = {
  sections: SectionItem[];
  sectionIndex: number;
  fieldPool: SectionFieldItem[];
  open: boolean;
  handleClose: () => void;
  setSections: (sections: SectionItem[]) => void;
}

const SelectFieldDialog: FC<SelectFieldDialogProps> = ({ sections, sectionIndex, fieldPool, open, handleClose, setSections }) => {
  const { classes } = useStyles();
  const initialSelectedFields: SectionFieldItem[] = sections[sectionIndex].sectionFields;
  const sectionKey: string = sections[sectionIndex].key;
  const isRestricted: boolean = sections[sectionIndex].isRestricted;

  const handleSubmit = (rowSelected: SectionFieldItem[]) => {
    rowSelected.forEach((field, index) => {
      field.priority = index + 1;
    });
    const newSections = [...sections];
    newSections[sectionIndex].sectionFields = rowSelected;
    setSections(newSections);
    handleClose();
  }

  return (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      open={open}
    >
      <SelectFieldForm
        initialSelectedFields={initialSelectedFields}
        sectionKey={sectionKey}
        isRestricted={isRestricted}
        fieldPool={fieldPool}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
      />
    </Dialog>
  )
}

export default SelectFieldDialog;