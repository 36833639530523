import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { personalParticularPath } from 'src/app/modules/Recruit/pages/PersonalParticular/PersonalParticularRoutes';
import { getDefaultDisplayDate } from 'src/app/common/utils';
import { PaginateList } from 'src/app/common/types/common-types';
import { PersonalParticularItem } from 'src/app/modules/Recruit/types/personal-particular-types';

type GpsLocationListProps = {
  isLoading: boolean;
  personalParticularList?: PaginateList<PersonalParticularItem>;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
};

const GpsLocationList: FC<GpsLocationListProps> = ({ isLoading, personalParticularList, onRefresh, onChangePage }) => {
  const history = useHistory();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const checkLocation = (latitude: number, longitude: number) => {
    if (latitude < 22.13 || latitude > 22.57 || longitude < 113.82 || longitude > 114.48) {
      return {
        location: 'Out of Hong Kong',
        result: 'Fail',
      };
    } else {
      return {
        location: 'Within Hong Kong',
        result: 'Pass',
      };
    }
  };

  return (
    <>
      <PruTable
        title={Translation('recruit.gpsLocation.list')}
        disableBulkSelect
        operationDef={[
          {
            title: Translation('recruit.common.documents'),
            tooltipText: 'View Documents',
            onClick: (row) => history.push(`${personalParticularPath}/doc/${row.appId}`),
          },
        ]}
        columnDef={[
          {
            isId: true,
            hidden: true,
            keyIndex: '_id',
            displayName: '',
            renderData: (row) => '',
          },
          {
            keyIndex: 'appId',
            displayName: Translation('recruit.common.applicationId'),
            renderData: (row) => row.appId || '-',
          },
          {
            keyIndex: 'location',
            displayName: Translation('recruit.gpsLocation.location'),
            renderData: (row) =>
              row.gpsLocation ? checkLocation(row.gpsLocation.latitude, row.gpsLocation.longitude).location : '-',
          },
          {
            keyIndex: 'result',
            align: 'center',
            displayName: Translation('recruit.gpsLocation.result'),
            renderData: (row) =>
              row.gpsLocation ? checkLocation(row.gpsLocation.latitude, row.gpsLocation.longitude).result : '-',
          },
          {
            keyIndex: 'latitude',
            align: 'center',
            displayName: Translation('recruit.gpsLocation.latitude'),
            renderData: (row) => (row.gpsLocation ? row.gpsLocation.latitude.toString() || '-' : '-'),
          },
          {
            keyIndex: 'longitude',
            align: 'center',
            displayName: Translation('recruit.gpsLocation.longitude'),
            renderData: (row) => (row.gpsLocation ? row.gpsLocation.longitude.toString() || '-' : '-'),
          },
          // {
          //   keyIndex: "lastUpdateUser",
          //   align: "center",
          //   displayName: Translation("recruit.common.lastUpdateUser"),
          //   renderData: (row) => row.lastUpdateUser || "-"
          // },
          {
            keyIndex: 'lastUpdatedDate',
            align: 'center',
            displayName: Translation('recruit.common.lastUpdatedDate'),
            renderData: (row) => getDefaultDisplayDate(new Date(row.lastUpdatedDate), 'datetime'),
          },
        ]}
        isLoading={isLoading}
        onRefresh={onRefresh}
        dataSource={personalParticularList?.docs}
        totalPages={personalParticularList?.totalPages}
        totalRecords={personalParticularList?.totalDocs}
        onChangePage={onChangePage}
      />
    </>
  );
};

export default GpsLocationList;
