import React, { FC } from 'react';
import { Button, Dialog } from '@mui/material';
import { useIntl } from 'react-intl';
import { useStyles } from './style';
import { formatRuleId } from '../utils';
const Detail: FC<any> = (props) => {
  const { popUpVisible, setPopUpVisible, ruleData } = props;
  const { classes } = useStyles();
  const intl = useIntl();
  const local = intl.locale;
  const Translation = (id: string) => intl.formatMessage({ id });
  const onCancel = () => {
    setPopUpVisible(false);
  };

  return (
    <div className={classes.dialogContainer}>
      <Dialog open={popUpVisible} onClose={onCancel} fullWidth maxWidth="lg">
        <div className={classes.dialog}>
          <div className={classes.buttonContainer}>
            <Button onClick={onCancel} variant="contained">
              {Translation('global.back.btnText')}
            </Button>
          </div>
          <div className={classes.dialogTitle}>{formatRuleId(ruleData.type)}</div>
          <div className={classes.dialogContent}>
            {Translation('pulseleads.activityPoint.ruleName.triggerAction')}
            <div className={classes.actionList}>
              {(ruleData.description.hasOwnProperty(local)
                ? ruleData.description[local]
                : ruleData.description['en']
              ).map((item: any, index: React.Key) => {
                return <p key={index}>{item}</p>;
              })}
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default Detail;
