import React, { FC } from 'react';
import NotificationListingPage from './List/NotificationListingPage';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import PruRoute from '../../../../common/components/PruRoute';
import notificationDetailForm from '../Create/notificationDetailForm';
import { NotificationConfigListPage } from './notification-config-list/notification-config-list.screen';
import {
  NOTIFICATION_BASE_PATH,
  NOTIFICATION_AUTO_PATH,
  NOTIFICATION_AUTO_LOG_PATH,
  NOTIFICATION_AUTO_CONFIG_PATH,
} from '../../constants';
import { NOT_FOUND_ERROR_PAGE_PATH } from '../../../../common/constants';
import { NotificationConfigEditPage } from './notification-config-edit/notification-config-edit.screen';

const AutoNotificationPage: FC<RouteComponentProps> = () => {
  const AUTO_PATH = NOTIFICATION_BASE_PATH + NOTIFICATION_AUTO_PATH;

  return (
    <Switch>
      <Redirect exact from={`${AUTO_PATH}`} to={`${AUTO_PATH}`} />
      <PruRoute exact path={`${AUTO_PATH}${NOTIFICATION_AUTO_LOG_PATH}`} component={NotificationListingPage} />
      <PruRoute exact path={`${AUTO_PATH}${NOTIFICATION_AUTO_CONFIG_PATH}`} component={NotificationConfigListPage} />
      <PruRoute exact path={`${AUTO_PATH}${NOTIFICATION_AUTO_LOG_PATH}/edit/:id`} component={notificationDetailForm} />
      <PruRoute exact path={`${AUTO_PATH}${NOTIFICATION_AUTO_LOG_PATH}/view/:id`} component={notificationDetailForm} />
      <PruRoute
        exact
        path={`${AUTO_PATH}${NOTIFICATION_AUTO_CONFIG_PATH}/edit/:template`}
        component={NotificationConfigEditPage}
      />
      <PruRoute
        exact
        path={`${AUTO_PATH}${NOTIFICATION_AUTO_LOG_PATH}/create/:from`}
        component={notificationDetailForm}
      />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default AutoNotificationPage;
