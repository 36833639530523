import { Dispatch } from 'react';
import { ADMIN_URL, apiClient, apiErrorHandler } from 'src/app/common/network';
import { PDFTemplateListParam } from '../types/pdf-template-type';
import { RecruitGenericItem } from '../types/recruit-generic-types';

const applicationFormSettingEndPoint = 'pdf-template';
// const ADMIN_URL = 'http://localhost:1111';

export const fetchPDFTemplateList = async (filterState: any, dispatch?: Dispatch<any>): Promise<any> => {
  const url = `${ADMIN_URL}/${applicationFormSettingEndPoint}`;
  return apiClient
    .get<RecruitGenericItem<PDFTemplateListParam>>(url, {
      params: filterState,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
