import { IntlShape } from 'react-intl';
import { ModuleHierachy } from '../../common/module/types';
import {
  INSIGHTS_TITLE,
  INSIGHTS_BASE_PATH,
  INSIGHTS_CAMPAIGN_TITLE,
  INSIGHTS_DASHBOARD_TITLE,
  INSIGHTS_DASHBOARD_USER_TITLE,
  INSIGHTS_DASHBOARD_REGION_TITLE,
  INSIGHTS_DASHBOARD_OVERVIEW_TITLE,
  INSIGHTS_DASHBOARD_SUMMARY_TITLE,
  INSIGHTS_CAMPAIGN_PATH,
  INSIGHTS_DASHBOARD_PATH,
  INSIGHTS_DASHBOARD_USER_PATH,
  INSIGHTS_DASHBOARD_REGION_PATH,
  INSIGHTS_DASHBOARD_OVERVIEW_PATH,
  INSIGHTS_DASHBOARD_SUMMARY_PATH,
  INSIGHTS_NAMESPACE,
  INSIGHTS_DASHBOARD_USER_NAMESPACE,
  INSIGHTS_DASHBOARD_REGION_NAMESPACE,
  INSIGHTS_DASHBOARD_OVERVIEW_NAMESPACE,
  INSIGHTS_DASHBOARD_SUMMARY_NAMESPACE,
  AdminInsightsPermissionType,
} from './constants';
import CampaignRoutes from './pages/Campaign/CampaignRoutes';
import UserReportRoutes from './pages/Dashboard/UserReport/UserReportRoutes';
import RegionReportRoutes from './pages/Dashboard/RegionReport/RegionReportRoutes';
import OverviewReportRoutes from './pages/Dashboard/OverviewReport/OverviewReportRoutes';
import SummaryReportRoutes from './pages/Dashboard/SummaryReport/SummaryReportRoutes';
import { isPermitted } from 'src/app/common/utils';

export const insightsModuleConfig = (permissions: string[], intl: IntlShape): ModuleHierachy => {
  const Translation = (id: string) => intl.formatMessage({ id });

  return {
    title: Translation(INSIGHTS_TITLE),
    path: INSIGHTS_BASE_PATH,
    icon: '/media/svg/icons/Devices/Diagnostics.svg',
    enableRead: isPermitted(
      [
        AdminInsightsPermissionType.INSIGHTS_LEAD_CAMPAIGN_READ,
        AdminInsightsPermissionType.INSIGHTS_LEAD_CAMPAIGN_CREATE,
        AdminInsightsPermissionType.INSIGHTS_LEAD_CAMPAIGN_UPDATE,
        AdminInsightsPermissionType.INSIGHTS_DASHBOARD_USER_READ,
        AdminInsightsPermissionType.INSIGHTS_DASHBOARD_USER_CREATE,
        AdminInsightsPermissionType.INSIGHTS_DASHBOARD_USER_UPDATE,
        AdminInsightsPermissionType.INSIGHTS_DASHBOARD_REGION_READ,
        AdminInsightsPermissionType.INSIGHTS_DASHBOARD_REGION_CREATE,
        AdminInsightsPermissionType.INSIGHTS_DASHBOARD_REGION_UPDATE,
        AdminInsightsPermissionType.INSIGHTS_DASHBOARD_OVERVIEW_READ,
        AdminInsightsPermissionType.INSIGHTS_DASHBOARD_OVERVIEW_CREATE,
        AdminInsightsPermissionType.INSIGHTS_DASHBOARD_OVERVIEW_UPDATE,
        AdminInsightsPermissionType.INSIGHTS_DASHBOARD_SUMMARY_READ,
        AdminInsightsPermissionType.INSIGHTS_DASHBOARD_SUMMARY_CREATE,
        AdminInsightsPermissionType.INSIGHTS_DASHBOARD_SUMMARY_UPDATE,
      ],
      permissions,
    ),
    child: [
      {
        title: Translation(INSIGHTS_CAMPAIGN_TITLE),
        path: `${INSIGHTS_BASE_PATH}${INSIGHTS_CAMPAIGN_PATH}`,
        namespace: INSIGHTS_NAMESPACE,
        component: CampaignRoutes,
        enableRead: isPermitted(
          [
            AdminInsightsPermissionType.INSIGHTS_LEAD_CAMPAIGN_READ,
            AdminInsightsPermissionType.INSIGHTS_LEAD_CAMPAIGN_CREATE,
            AdminInsightsPermissionType.INSIGHTS_LEAD_CAMPAIGN_UPDATE,
          ],
          permissions,
        ),
        enableCreate: isPermitted([AdminInsightsPermissionType.INSIGHTS_LEAD_CAMPAIGN_CREATE], permissions),
        enableUpdate: isPermitted([AdminInsightsPermissionType.INSIGHTS_LEAD_CAMPAIGN_UPDATE], permissions),
      },
      {
        title: Translation(INSIGHTS_DASHBOARD_TITLE),
        path: `${INSIGHTS_BASE_PATH}${INSIGHTS_DASHBOARD_PATH}`,
        enableRead: isPermitted(
          [
            AdminInsightsPermissionType.INSIGHTS_DASHBOARD_USER_READ,
            AdminInsightsPermissionType.INSIGHTS_DASHBOARD_USER_CREATE,
            AdminInsightsPermissionType.INSIGHTS_DASHBOARD_USER_UPDATE,
            AdminInsightsPermissionType.INSIGHTS_DASHBOARD_REGION_READ,
            AdminInsightsPermissionType.INSIGHTS_DASHBOARD_REGION_CREATE,
            AdminInsightsPermissionType.INSIGHTS_DASHBOARD_REGION_UPDATE,
            AdminInsightsPermissionType.INSIGHTS_DASHBOARD_OVERVIEW_READ,
            AdminInsightsPermissionType.INSIGHTS_DASHBOARD_OVERVIEW_CREATE,
            AdminInsightsPermissionType.INSIGHTS_DASHBOARD_OVERVIEW_UPDATE,
            AdminInsightsPermissionType.INSIGHTS_DASHBOARD_SUMMARY_READ,
            AdminInsightsPermissionType.INSIGHTS_DASHBOARD_SUMMARY_CREATE,
            AdminInsightsPermissionType.INSIGHTS_DASHBOARD_SUMMARY_UPDATE,
          ],
          permissions,
        ),
        child: [
          {
            title: Translation(INSIGHTS_DASHBOARD_USER_TITLE),
            path: `${INSIGHTS_BASE_PATH}${INSIGHTS_DASHBOARD_PATH}${INSIGHTS_DASHBOARD_USER_PATH}`,
            namespace: INSIGHTS_DASHBOARD_USER_NAMESPACE,
            component: UserReportRoutes,
            enableRead: isPermitted(
              [
                AdminInsightsPermissionType.INSIGHTS_DASHBOARD_USER_READ,
                AdminInsightsPermissionType.INSIGHTS_DASHBOARD_USER_CREATE,
                AdminInsightsPermissionType.INSIGHTS_DASHBOARD_USER_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted([AdminInsightsPermissionType.INSIGHTS_DASHBOARD_USER_CREATE], permissions),
            enableUpdate: isPermitted([AdminInsightsPermissionType.INSIGHTS_DASHBOARD_USER_UPDATE], permissions),
          },
          {
            title: Translation(INSIGHTS_DASHBOARD_REGION_TITLE),
            path: `${INSIGHTS_BASE_PATH}${INSIGHTS_DASHBOARD_PATH}${INSIGHTS_DASHBOARD_REGION_PATH}`,
            namespace: INSIGHTS_DASHBOARD_REGION_NAMESPACE,
            component: RegionReportRoutes,
            enableRead: isPermitted(
              [
                AdminInsightsPermissionType.INSIGHTS_DASHBOARD_REGION_READ,
                AdminInsightsPermissionType.INSIGHTS_DASHBOARD_REGION_CREATE,
                AdminInsightsPermissionType.INSIGHTS_DASHBOARD_REGION_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted([AdminInsightsPermissionType.INSIGHTS_DASHBOARD_REGION_CREATE], permissions),
            enableUpdate: isPermitted([AdminInsightsPermissionType.INSIGHTS_DASHBOARD_REGION_UPDATE], permissions),
          },
          {
            title: Translation(INSIGHTS_DASHBOARD_OVERVIEW_TITLE),
            path: `${INSIGHTS_BASE_PATH}${INSIGHTS_DASHBOARD_PATH}${INSIGHTS_DASHBOARD_OVERVIEW_PATH}`,
            namespace: INSIGHTS_DASHBOARD_OVERVIEW_NAMESPACE,
            component: OverviewReportRoutes,
            enableRead: isPermitted(
              [
                AdminInsightsPermissionType.INSIGHTS_DASHBOARD_OVERVIEW_READ,
                AdminInsightsPermissionType.INSIGHTS_DASHBOARD_OVERVIEW_CREATE,
                AdminInsightsPermissionType.INSIGHTS_DASHBOARD_OVERVIEW_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted([AdminInsightsPermissionType.INSIGHTS_DASHBOARD_OVERVIEW_CREATE], permissions),
            enableUpdate: isPermitted([AdminInsightsPermissionType.INSIGHTS_DASHBOARD_OVERVIEW_UPDATE], permissions),
          },
          {
            title: Translation(INSIGHTS_DASHBOARD_SUMMARY_TITLE),
            path: `${INSIGHTS_BASE_PATH}${INSIGHTS_DASHBOARD_PATH}${INSIGHTS_DASHBOARD_SUMMARY_PATH}`,
            namespace: INSIGHTS_DASHBOARD_SUMMARY_NAMESPACE,
            component: SummaryReportRoutes,
            enableRead: isPermitted(
              [
                AdminInsightsPermissionType.INSIGHTS_DASHBOARD_SUMMARY_READ,
                AdminInsightsPermissionType.INSIGHTS_DASHBOARD_SUMMARY_CREATE,
                AdminInsightsPermissionType.INSIGHTS_DASHBOARD_SUMMARY_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted([AdminInsightsPermissionType.INSIGHTS_DASHBOARD_SUMMARY_CREATE], permissions),
            enableUpdate: isPermitted([AdminInsightsPermissionType.INSIGHTS_DASHBOARD_SUMMARY_UPDATE], permissions),
          },
        ],
      },
    ],
  };
};
