import { makeStyles } from 'tss-react/mui';
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import Form from 'src/app/common/components/Form';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { AlertType, appendAlertItem } from 'src/redux/common/commonSlice';
import { MTmode, publishingCode, publishingSameVersionCode, PublishStatus } from '../../constants';
import { createBlob, getBlob } from 'src/app/common/network';
import { fileUpload } from 'src/app/common/utils';
import FileUploader from 'src/app/common/components/FileUploader';
import { addTask, checkTemplate, getTaskTemplate, updateTask, getTaskById } from '../../api';
import { useParams } from 'react-router-dom';
import ErrorList from '../../EngagementTopic/Detail/TargetProspect/ErrorList';

const styles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginTop: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
    paddingBottom: 0,
  },
  divideMargin: {
    marginBottom: 10,
  },
  mandatory: {
    color: 'red',
  },
  titleTips: {
    color: '#979797',
    fontSize: 14,
    fontWeight: 'normal',
    marginLeft: 15,
  },
  taskName: {
    marginBottom: 10,
    display: 'flex',
    alignItems: 'center',
  },
}));

const Detail: FC<any> = ({ history, location, enableRead, enableCreate, enableUpdate, formMode }) => {
  const intl = useIntl();
  const { classes } = styles();
  const { classes: commonClasses } = useCommonStyles();
  const maxLength = 25;
  const dispatch = useDispatch();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [form] = Form.useForm();
  const [formStatus, setFormStatus] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [checkError, setCheckError] = useState<boolean>(false);
  const [errorListVisible, setErrorListVisible] = useState<boolean>(false);
  const [errorLength, setErrorLength] = useState<number>(0);
  const [initialValues, setInitialValues] = useState<any>({});
  const [publishItems, SetPublishItems] = useState<any[]>([]);
  const DEFAULT_REQUIRED_RULES = {
    required: true,
    message: Translation('component.pulseleads.prospect.form-required-text'),
  };
  const placeEnter = Translation('app.input.placeholder.please-enter');
  const { id } = useParams<{ id: string }>();
  const isCreate = formMode === MTmode.CREATE;
  const isEdit = formMode === MTmode.EDIT;
  const currentStatus = formStatus.toString();
  const isPublished = currentStatus === PublishStatus.Publish.key;
  const isDraft = currentStatus === PublishStatus.Draft.key;
  const isUnpublished = currentStatus === PublishStatus.UnPublish.key;
  const isPublishing = currentStatus === PublishStatus.Publishing.key;
  useEffect(() => {
    const fetchAPI = async () => {
      if (!isCreate) {
        await fetchDataById();
      }
    };
    fetchAPI();
    return;
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    getDisplayStatus();
    // eslint-disable-next-line
  }, [formStatus]);
  const getDisplayStatus = () => {
    let displayStatus: any = [];
    if (isCreate || isDraft) {
      displayStatus = displayStatus.concat([PublishStatus.Draft, PublishStatus.Publish]);
    } else if (isPublished) {
      displayStatus = displayStatus.concat([PublishStatus.UnPublish]);
    } else if (isUnpublished) {
      displayStatus = displayStatus.concat([PublishStatus.Publish, PublishStatus.UnPublish]);
    } else if (isPublishing) {
      displayStatus = displayStatus.concat([PublishStatus.Draft, PublishStatus.Publish]);
    } else {
      displayStatus = displayStatus.concat([PublishStatus.Draft, PublishStatus.UnPublish]);
    }
    SetPublishItems(displayStatus);
  };

  const fetchDataById = async () => {
    setLoading(true);
    try {
      const result = await getTaskById(id);
      const blobDetail = await getBlob(
        { resourceIds: result.data.uploadFile._id || result.data.uploadFile.id },
        dispatch,
      );
      const formatResult = {
        ...result.data,
        publishStatus: PublishStatus.Publish.key,
        uploadFile: { ...blobDetail[0], fileName: result.data.uploadFile.fileName || result.data.uploadFile.name },
      };
      setInitialValues(formatResult);
      form.setFieldsValue(formatResult);
      setErrorLength(result.data.errorLog.length);
      if (result.data.errorLog?.length > 0) {
        setCheckError(true);
      }
      setFormStatus(result.data?.publishStatus.toString());
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const STRING_LENGTH_VALIDATE = [
    DEFAULT_REQUIRED_RULES,
    {
      validator(_: any, value: string) {
        if (value.length > maxLength) {
          return Promise.reject(
            new Error(Translation('form.pulseleads.engagement.fields.name.en.length.validate.message')),
          );
        }
        return Promise.resolve();
      },
    },
  ];
  const onSubmit = (getFieldValue?: any) => {
    if (isDraft || isUnpublished) {
      submitAnyway();
      return;
    } else if (isPublished && form.getFieldsValue(true).publishStatus === PublishStatus.UnPublish.key) {
      onUnpublish();
      return;
    } else if (isPublishing) {
      goBackToList();
    } else {
      // goBackToList()
      form.submit();
    }
  };

  const submitAnyway = () => {
    form
      .validateFields(['uploadFile'])
      .then((res) => {
        onSave();
      })
      .catch((err) => {
        const { errorFields } = err;
        if (errorFields.length <= 0) {
          onSave();
        }
      });

    return false;
  };

  const onUnpublish = async () => {
    setLoading(true);
    try {
      await updateTask(id, { publishStatus: PublishStatus.UnPublish.key });
      sucessOperation();
    } catch (error) {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.ERROR,
            title: 'Error',
            content: 'Error',
          },
        ]),
      );
    }
    setLoading(false);
  };

  const onSave = async () => {
    setLoading(true);
    const allValues = form.getFieldsValue(true);
    try {
      if (isCreate) {
        await onCheckFile(allValues.uploadFile.fileName);
        if (checkError && allValues.publishStatus === PublishStatus.Publish.key) {
          errorAlert();
          setLoading(false);
          return;
        }
        const formatAllValues = {
          ...allValues,
          uploadFile: { name: allValues.uploadFile.fileName, id: allValues.uploadFile.blobId },
          errorLog: allValues.errorLog || [],
          publishStatus:
            allValues.publishStatus === PublishStatus.Publish.key
              ? PublishStatus.Publishing.key
              : allValues.publishStatus,
        };

        await addTask(formatAllValues);
        sucessOperation();
      } else {
        try {
          const isError = await onCheckFile(allValues.uploadFile.fileName);
          if (isError && allValues.publishStatus === PublishStatus.Publish.key) {
            errorAlert();
            setLoading(false);
            return;
          } else {
            const formatAllValues = {
              ...allValues,
              uploadFile: { ...allValues.uploadFile, id: allValues.uploadFile._id },
              errorLog: allValues.errorLog || [],
              publishStatus:
                allValues.publishStatus === PublishStatus.Publish.key
                  ? PublishStatus.Publishing.key
                  : allValues.publishStatus,
            };
            await updateTask(id, formatAllValues);
            sucessOperation();
          }
        } catch (error: any) {
          setLoading(false);
          const code = error?.errors?.code || '';
          if (code === publishingCode) {
            dispatch(
              appendAlertItem([
                {
                  severity: AlertType.WARNING,
                  title: 'Reminder',
                  content: 'The template is in publishing or unpublishing, retry operation later please',
                },
              ]),
            );
            return;
          }
          if (code === publishingSameVersionCode) {
            dispatch(
              appendAlertItem([
                {
                  severity: AlertType.WARNING,
                  title: 'Reminder',
                  content: 'Template has been modified, please refresh the page and try again',
                },
              ]),
            );
            return;
          }

          if (error?.code === 400) {
            const reason = error?.errors?.reason || '';
            dispatch(
              appendAlertItem([
                {
                  severity: AlertType.WARNING,
                  title: 'Reminder',
                  content: reason,
                },
              ]),
            );
          }
        }
      }
    } catch (error) {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.ERROR,
            title: 'Error',
            content: 'Error',
          },
        ]),
      );
    }

    setLoading(false);
  };

  const sucessOperation = () => {
    dispatch(
      appendAlertItem([
        {
          severity: AlertType.SUCCESS,
          title: 'Success',
          content: 'Update Engagement Task Successfully',
        },
      ]),
    );
    goBackToList();
  };

  const errorAlert = () => {
    dispatch(
      appendAlertItem([
        {
          severity: AlertType.ERROR,
          title: 'Reminder',
          content: 'Please check the file errors',
        },
      ]),
    );
  };

  const goBackToList = () => {
    history.push('/pulseleads/engagement/task/list');
  };
  const displayErrorList = async () => {
    setErrorListVisible(true);
  };

  const setData = (key: string, value: any) => {
    setInitialValues({ [`${key}`]: value });
    form.setFieldsValue({ [`${key}`]: value });
  };

  const onDownloadTemplate = () => {
    getTaskTemplate(dispatch);
  };

  const onCheckFile = async (fileName?: string) => {
    const fileData = {
      name: fileName || form.getFieldValue('uploadFile').fileName,
      id: form.getFieldValue('uploadFile').blobId.toString(),
      type: 'task',
    };
    const errorList = await checkTemplate(fileData, dispatch);
    setErrorLength(errorList?.data.length);
    form.setFieldsValue({ errorLog: errorList.data });
    if (errorList?.data.length > 0) {
      setCheckError(true);
      return true;
    } else {
      setCheckError(false);
      return false;
    }
  };

  const uploadFileMockStatus = async ({
    file,
    fileName,
    fileType,
    description = '',
  }: {
    file: any;
    fileName: string;
    fileType: string;
    description?: string;
    setLoadPercentage: (p: number) => void;
  }) => {
    const createBlobRes = await createBlob(
      { mimeType: file.type, accessLevel: 'public', module: 'pulseLeads' },
      dispatch,
    );
    await fileUpload(createBlobRes.url, file);
    const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);
    const result = blobDetail[0];
    if (result) {
      form.setFieldsValue({ uploadFile: blobDetail[0] });
      await onCheckFile(fileName);
      return result;
    } else {
      throw new Error('upload failed');
    }
  };
  const publishingDisabled = formStatus == PublishStatus.Publishing.key;
  const publishedDisabled = formStatus == PublishStatus.Publish.key;

  return (
    <div className={classes.container}>
      {isCreate && (
        <div className={classes.divideMargin}>
          <span className={commonClasses.header}>{Translation('form.pulseleads.engagement.task.add')}</span>
        </div>
      )}
      {isEdit && (
        <div className={classes.divideMargin}>
          <span className={commonClasses.header}>{Translation('form.pulseleads.engagement.task.edit')}</span>
        </div>
      )}
      <Form
        form={form}
        className={`${classes.container} add-form-wraper form-wraper`}
        onFinish={onSave}
        initialValues={{ ...initialValues }}
      >
        {publishedDisabled && <span className={commonClasses.subHeader}>Task ID:{initialValues?._id}</span>}
        <Form.Item name={['name']}>
          <div className={classes.taskName}>
            <span className={commonClasses.subHeader}>{Translation('form.pulseleads.engagement.task.name')}</span>
            <span style={{ color: publishingDisabled || publishedDisabled ? 'grey' : 'red' }}>*</span>
            <span style={{ marginLeft: 70 }}>
              <Form.Item name={['name']} rules={STRING_LENGTH_VALIDATE} required={true}>
                <TextField
                  required={true}
                  style={{ minWidth: 300 }}
                  margin="dense"
                  variant="outlined"
                  placeholder={placeEnter}
                  size="small"
                  disabled={publishingDisabled || publishedDisabled}
                />
              </Form.Item>
            </span>
          </div>
        </Form.Item>
        <div style={{ marginBottom: 30 }}>
          <span className={commonClasses.subHeader}>{Translation('form.pulseleads.engagement.target.prospect')}</span>
          <span style={{ color: publishingDisabled || publishedDisabled ? 'grey' : 'red' }}>*</span>
          <span>{Translation('form.pulseleads.engagement.target.prospect.dec')}</span>
        </div>

        <div style={{ marginLeft: 30 }}>
          <Form.Item name="uploadFile" required={true} rules={[DEFAULT_REQUIRED_RULES]}>
            {(_, meta, { getFieldValue }) => {
              return (
                <div>
                  <FileUploader
                    maxFileSize={10}
                    upload={uploadFileMockStatus}
                    download={() => {}}
                    value={initialValues.uploadFile}
                    allowedFileTypes={['csv']}
                    onChange={(data) => {
                      setData('uploadFile', data);
                    }}
                    disabled={publishingDisabled || publishedDisabled} //disabled when publishing and published
                    linkStyle={{ color: publishingDisabled || publishedDisabled ? 'gray' : 'green' }}
                  />
                  {!initialValues?.uploadFile && (
                    <Button color="secondary" onClick={onDownloadTemplate}>
                      {Translation('pulseleads.eligibilityRule.form.downloadTemplate')}
                    </Button>
                  )}
                  {errorLength > 0 && initialValues?.uploadFile && (
                    <Button
                      style={{ marginLeft: 6, textDecoration: 'underline' }}
                      color="secondary"
                      onClick={displayErrorList}
                      className={classes.mandatory}
                    >
                      {`${errorLength} errors`}
                    </Button>
                  )}
                  {errorLength === 0 && initialValues?.uploadFile && (
                    <Button
                      style={{
                        color: publishingDisabled || publishedDisabled ? 'grey' : 'green',
                        marginLeft: 6,
                      }}
                    >
                      Passed
                    </Button>
                  )}
                </div>
              );
            }}
          </Form.Item>
        </div>
        <Form.Item
          label={Translation('mandatory.training.category.publish.option')}
          name="publishStatus"
          rules={[DEFAULT_REQUIRED_RULES]}
          style={{
            fontSize: '1.1rem',
            fontWeight: 'bold',
          }}
          initialValue={initialValues.publishStatus || '1'}
        >
          <RadioGroup style={{ display: 'flex', flexDirection: 'row' }}>
            {publishItems &&
              publishItems.map((item: any) => {
                return (
                  item && (
                    <FormControlLabel
                      key={item.key.toString()}
                      value={item.key.toString()}
                      control={<Radio />}
                      label={Translation(item.label)}
                      disabled={formStatus == PublishStatus.Publishing.key} // disabled when publishing
                    />
                  )
                );
              })}
          </RadioGroup>
        </Form.Item>

        <div>
          <Form.Item noStyle style={{ margin: 20 }}>
            <div style={{ marginBottom: 15, display: 'flex', justifyContent: 'flex-end' }}>
              {
                <Button
                  variant="contained"
                  color="inherit"
                  disabled={loading}
                  style={{ marginRight: 15 }}
                  onClick={goBackToList}
                >
                  {Translation('app.button.cancel')}
                </Button>
              }

              {
                <Button
                  style={{ marginRight: 15 }}
                  variant="contained"
                  disabled={loading || publishingDisabled}
                  color="secondary"
                  onClick={() => {
                    onSubmit();
                  }}
                >
                  {Translation('app.button.submit')}
                </Button>
              }
            </div>
          </Form.Item>
        </div>
      </Form>

      <Backdrop className={commonClasses.backdrop} open={loading}>
        <CircularProgress color="secondary" />
      </Backdrop>

      <Dialog
        open={errorListVisible}
        onClose={() => {
          setErrorListVisible(false);
        }}
        scroll={'paper'}
        fullWidth
      >
        <ErrorList
          errorList={form.getFieldValue('errorLog')}
          popUpVisible={errorListVisible}
          setPopUpVisible={setErrorListVisible}
        />
      </Dialog>
    </div>
  );
};

export default Detail;
