import { Button, Checkbox, DialogActions, DialogContent, DialogContentText, FormControlLabel } from '@mui/material';
import { useState } from 'react';
import { Translation } from 'src/app/i18n';

export interface ConfirmModalCheckedboxProps {
  onClose?: Function;
  description?: string;
  onConfirm: Function;
  confirmText?: string;
  cancelText?: string;
  checkboxText?: string;
  checkedAction?: () => void;
}

export const ConfirmModalCheckedboxView = (props: ConfirmModalCheckedboxProps) => {
  const { onClose, onConfirm, description, confirmText, cancelText, checkboxText, checkedAction } = props;
  const [checked, setChecked] = useState(false);

  const handleCheckboxChange = (event: any) => {
    setChecked(event.target.checked);
  };

  return (
    <div className="tw-rounded-lg">
      {description && (
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
        </DialogContent>
      )}
      {checkboxText && (
        <DialogContent>
          <FormControlLabel
            control={<Checkbox checked={checked} onChange={handleCheckboxChange} />}
            label={checkboxText}
          />
        </DialogContent>
      )}
      <DialogActions className="tw-mr-2.5 tw-flex tw-justify-end">
        <Button
          onClick={() => onClose?.({ type: 'reject' }, 'userCancelled' as any)}
          variant="outlined"
          color="inherit"
        >
          {cancelText ?? Translation('app.button.cancel')}
        </Button>
        <Button
          onClick={() => {
            if (checked === true && checkedAction) {
              checkedAction();
            }
            const promiseInstance = onConfirm?.();
            onClose?.({ type: 'resolve' }, promiseInstance);
          }}
          variant="contained"
          color="secondary"
          autoFocus
        >
          {confirmText ?? Translation('app.button.confirm')}
        </Button>
      </DialogActions>
    </div>
  );
};
