import React, { FC, useState, useEffect, useMemo, useReducer } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { ApiErrorResponse } from 'src/app/common/network';
import { useDispatch } from 'react-redux';
import { NOT_FOUND_ERROR_PAGE_PATH } from '../../../../common/constants';
import { LayoutSplashScreen } from 'src/app/layout';
import { createOrUpdateConsent, fetchConsent } from '../../network/public-portal-consent-crud';
import { PublicPortalConsent } from '../../types/public-portal-consent-types';
import { cloneDeep, isArray, isEmpty } from 'lodash';
import { regionLocale, RegionLocale, regionLocaleMap } from 'src/app/i18n';
import { Translation } from 'src/app/i18n';
import { makeStyles } from 'tss-react/mui';
import { Button, FormControlLabel, RadioGroup, TextField } from '@mui/material';
import { RadioButtonUnchecked, CheckCircle } from '@mui/icons-material';
import { getConvertedClasses } from 'src/app/common/helpers/tw-convert-helpers';
import AcmRadio from 'src/app/modules/AgencyCampaign/common/AcmRadio';
import { AlertType, appendAlertItem } from 'src/redux/common/commonSlice';
import { ModalService } from 'src/app/common/components/pru-modal/modal-service';
import { PUBLIC_PORTAL_CONSENT_CONTENT } from '../../constants';
import { useJWT } from 'src/app/common/utils';

const PublicPortalConsentPage: FC<RouteComponentProps> = ({ history, location }) => {
  const dispatch = useDispatch();
  const jwt = useJWT();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [consentConfig, setConsentConfig] = useState<PublicPortalConsent>();

  const reloadData = () => {
    setIsLoading(true);
    fetchConsent(jwt, dispatch)
      .then((result) => {
        setConsentConfig(result.content);
      })
      .catch(() => {
        history.push(NOT_FOUND_ERROR_PAGE_PATH);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line
  }, []);

  return <>{isLoading ? <LayoutSplashScreen /> : <PublicPortalConsentForm data={consentConfig} />}</>;
};

// eslint-disable-next-line @typescript-eslint/ban-types
type ConsentFormState = PublicPortalConsent & {
  submittedStateSnapshot?: PublicPortalConsent;
};

type ActionPayload = {
  field: keyof ConsentFormState;
  value: any;
};

type ModifyFieldAction =
  | {
      type: 'MODIFY_FIELD';
      payload: ActionPayload | ActionPayload[];
    }
  | {
      type: 'RESET';
    }
  | {
      type: 'SUBMIT';
    };
type ConsentFormAction = ModifyFieldAction;

const formReducer = (state: ConsentFormState, action: ConsentFormAction): ConsentFormState => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      const data = isArray(action.payload) ? action.payload : [action.payload];
      let patchData = {};
      for (let index = 0; index < data.length; index++) {
        const element: ActionPayload = data[index];
        patchData = {
          ...patchData,
          [element.field]: element.value,
        };
      }
      return {
        ...state,
        ...patchData,
      };
    case 'SUBMIT': {
      return {
        ...state,
        submittedStateSnapshot: cloneDeep(state),
      };
    }
    case 'RESET': {
      state = state.submittedStateSnapshot ?? state;
      const result = {
        ...state,
        submittedStateSnapshot: cloneDeep(state),
      };
      return result;
    }
    default:
      return state;
  }
};

const convertToState = (data?: PublicPortalConsent): ConsentFormState => {
  const allowedLanguages = regionLocale;
  const contentData: any = {};
  for (let q = 0; q < allowedLanguages.length; q++) {
    const lang = allowedLanguages[q];
    contentData[lang] = data?.content?.[lang] ?? PUBLIC_PORTAL_CONSENT_CONTENT;
  }
  const convertedData = {
    enabled: data?.enabled ?? true,
    content: contentData,
  };
  return {
    ...convertedData,
    submittedStateSnapshot: cloneDeep(convertedData),
  };
};

const getTranslations = (params?: any) => {
  return {
    customer_consent: Translation('customer_consent'),
    resource_library_and_e_name_card: Translation('resource_library_and_e_name_card'),
    require_customer_consent: Translation('require_customer_consent'),
    require_customer_consent_reminder: Translation('require_customer_consent_reminder'),
    are_you_sure_to_save_the_changes: Translation('are_you_sure_to_save_the_changes'),
    confirm_to_save_consent_desc: Translation('confirm_to_save_consent_desc'),
    save_content_successfully: 'Save successfully',
  };
};

const useStyles = makeStyles()((theme) => ({
  textArea: {
    lineHeight: 1.5,
    minHeight: 40,
  },
}));

const PublicPortalConsentForm = ({ data }: { data?: PublicPortalConsent }) => {
  const initeState = convertToState(data);
  const [formState, formDispatch] = useReducer(formReducer, initeState);
  const [canEdit, setCanEdit] = useState(false);
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const jwt = useJWT();
  const submitConsent = () => {
    setCanEdit(false);
    const data: PublicPortalConsent = {
      enabled: formState.enabled,
      content: formState.content,
    };
    createOrUpdateConsent(data, jwt)
      .then(() => {
        // window.location.reload();
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: 'Success',
              content: getTranslations().save_content_successfully,
            },
          ]),
        );
      })
      .catch((err: ApiErrorResponse) => {
        setCanEdit(true);
      });
  };
  return (
    <div className="">
      {/* <div className="tw-bg-white tw-leading-11 tw-h-11">{getTranslations().customer_consent}</div> */}
      <div className="tw-flex tw-flex-row tw-items-center tw-pb-6">
        <div className="tw-flex-1 tw-font-medium tw-text-sm">{getTranslations().resource_library_and_e_name_card}</div>
        <div className="tw-flex tw-flex-row tw-items-center">
          {!canEdit && (
            <div>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                onClick={async () => {
                  setCanEdit(true);
                }}
              >
                {Translation('app.button.edit')}
              </Button>
            </div>
          )}
          {canEdit && (
            <div className="tw-pr-2">
              <Button
                variant="outlined"
                color="inherit"
                size="large"
                onClick={() => {
                  setCanEdit(false);
                  formDispatch({
                    type: 'RESET',
                  });
                }}
              >
                {Translation('app.button.cancel')}
              </Button>
            </div>
          )}
          {canEdit && (
            <Button
              variant="contained"
              color="secondary"
              size="large"
              onClick={async () => {
                ModalService.confirm({
                  title: getTranslations().are_you_sure_to_save_the_changes,
                  description: getTranslations().confirm_to_save_consent_desc,
                  onConfirm: () => {
                    submitConsent();
                  },
                });
              }}
            >
              {Translation('app.button.save')}
            </Button>
          )}
        </div>
      </div>

      <div className={`tw-bg-white tw-py-6 tw-px-4 ${canEdit ? 'tw-pointer-events-auto' : 'tw-pointer-events-none'}`}>
        <div className="tw-flex tw-flex-row  tw-items-center">
          <div className="tw-w-1/6 tw-pr-4">
            <span className="tw-float-right tw-font-normal tw-text-sm">
              {getTranslations().require_customer_consent}
            </span>
          </div>
          <div className="tw-flex-1">
            <RadioGroup
              onChange={(e) => {
                formDispatch({
                  type: 'MODIFY_FIELD',
                  payload: {
                    field: 'enabled',
                    value: e.target.value === 'true',
                  },
                });
              }}
              value={formState.enabled ? 'true' : 'false'}
              style={{ ...getConvertedClasses('flex flex-row items-center pt-2'), flexDirection: 'row' }}
            >
              <FormControlLabel
                value="true"
                control={<AcmRadio icon={<RadioButtonUnchecked />} checkedIcon={<CheckCircle />} />}
                label={Translation(`app.checkbox.yes`)}
              />
              <FormControlLabel
                value="false"
                control={<AcmRadio icon={<RadioButtonUnchecked />} checkedIcon={<CheckCircle />} />}
                label={Translation(`app.checkbox.no`)}
              />
            </RadioGroup>
          </div>

          <div className="tw-w-1/6 tw-pr-4"></div>
        </div>
        {!!formState.enabled && (
          <>
            <div className="tw-flex tw-flex-row  tw-items-center">
              <div className="tw-w-1/6 tw-pr-4">
                <span className="tw-float-right"></span>
              </div>
              <div className="tw-flex-1 tw-italic tw-font-normal tw-text-sm tw-whitespace-pre-wrap">
                {getTranslations().require_customer_consent_reminder}
              </div>
              <div className="tw-w-1/6 tw-pr-4"></div>
            </div>
            {regionLocale.map((lang) => {
              return (
                <div className="tw-flex tw-flex-row  tw-items-center">
                  <div className="tw-w-1/6 tw-pr-4">
                    <span className="tw-float-right tw-font-normal tw-text-sm">
                      {Translation(regionLocaleMap[lang].name)}
                    </span>
                  </div>
                  <div className="tw-flex-1">
                    <TextField
                      fullWidth
                      // disabled={!enableDraftUpdate && !enableActiveUpdate}
                      // error={errorState.mandatory.description}
                      multiline
                      margin="dense"
                      variant="outlined"
                      // helperText={errorState.mandatory.description && MANDATORY_FIELD_ERROR_TEXT}
                      value={formState.content[lang]}
                      InputProps={{
                        classes: {
                          input: classes.textArea,
                        },
                      }}
                      onChange={(e) => {
                        // onDismissErrorHandler('description', e.target.value);
                        formDispatch({
                          type: 'MODIFY_FIELD',
                          payload: {
                            field: 'content',
                            value: {
                              ...formState.content,
                              [lang]: e.target.value,
                            },
                          },
                        });
                      }}
                    />
                  </div>

                  <div className="tw-w-1/6 tw-pr-4"></div>
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};

export default PublicPortalConsentPage;
