import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import PruRoute from 'src/app/common/components/PruRoute';
import { PULSELEADS_BASE_PATH } from '../../../constants';
import { PULSELEADS_BULKUPLOAD_PATH, PULSELEADS_BULKUPLOAD_UPLOAD_PATH } from '../constants';
import BulkUploadUploadForm from './components/BulkUploadUploadForm';

const uploadPath = `${PULSELEADS_BASE_PATH}${PULSELEADS_BULKUPLOAD_PATH}${PULSELEADS_BULKUPLOAD_UPLOAD_PATH}`;

const BulkUploadUploadRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect exact from={uploadPath} to={`${uploadPath}/list`} />
      <PruRoute exact path={`${uploadPath}/list`} component={BulkUploadUploadForm} />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  )
};

export default BulkUploadUploadRoutes;