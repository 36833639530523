import React, { useState, useEffect, useMemo, useCallback, Fragment } from 'react';
import { makeStyles, withStyles } from 'tss-react/mui';
import { Button, TextField, MenuItem, FormControlLabel, RadioGroup, Radio, IconButton, Tooltip } from '@mui/material';
import { useIntl } from 'react-intl';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useLang, regionLocale } from 'src/app/i18n';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { OnboardingCourseFormState, OnboardingTrainingOptionItem, OptionType } from '../../../types/courses-types';
import Form, { useForm } from 'src/app/common/components/Form';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import {
  RECRUITMENT_BASE_PATH,
  RECRUITMENT_ONBOARDING_TRAINING,
  RECRUITMENT_APPLICATION_COURSES,
} from '../../../constants';
import { utcToLocalDate } from '../../../utils';
import { useDispatch } from 'react-redux';
import { fetchOnboardingTrainingOptions, moveOnboardingLesson } from '../../../network/onboarding-course-crud';
import PruDialog from 'src/app/common/components/PruDialog';
import { AlertType, appendAlertItem } from 'src/redux/common/commonSlice';
import { YesAndNo } from '../../../utils';

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  accordContainer: {
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  divideMargin: {
    // marginBottom: 10
  },
  subHeader: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
  },
  accordHeading: {
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '15%',
    flexShrink: 0,
  },
  accordSecondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  addNewMaterialSetBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '10px',
  },
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalBg: {
    width: '100%',
    backgroundColor: theme.palette.common.white,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    height: '100%',
    overflow: 'scroll',
  },
  modalButtons: {
    marginTop: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  operationBtn: {
    color: 'blue',
    cursor: 'pointer',
    textDecoration: 'underline',
    fontSize: '0.85rem',
    whiteSpace: 'nowrap',
  },
}));

const StyledRadioGroup = withStyles(RadioGroup, {
  root: {
    flexDirection: 'row',
    display: 'flex',
    '& .MuiFormControlLabel-root': {
      marginBottom: 0,
      marginLeft: 0,
      marginRight: '15px',
    },
    '& .MuiButtonBase-root': {
      padding: 0,
    },
  },
});

interface CourseFormListProp {
  id: string;
  disabled: boolean;
  onSave: (formData: OnboardingCourseFormState, type: string) => void;
  formListData?: Partial<OnboardingCourseFormState>;
  isEdit: boolean;
  history: any;
  location?: any;
  match?: any;
  type: string;
  loadingFun?: () => void;
  refreshData: () => void;
  onDelete: () => void;
}

export type ColumnOptions = {
  label: string;
  value: string;
  id: string;
}[];

export enum PublishStatus {
  'unpublished' = 'UNPUBLISHED',
  'published' = 'PUBLISHED',
}

const initialDialogState = {
  status: false,
  type: 'save',
  text: '',
  isError: false,
};

const CourseFormList: React.FC<CourseFormListProp> = (props) => {
  const [dropdownOptions, setDropdownOptions] = useState<OnboardingTrainingOptionItem[]>([]);
  const locale = useLang();
  const [dialog, setDialog] = useState(initialDialogState);
  const { classes } = useStyles();
  const { classes: commonClasses } = useCommonStyles();
  const { id, disabled, onSave, formListData, isEdit, history, type, refreshData, location, onDelete } = props;
  const [form] = useForm();
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);
  const TranslationWithVariable = (key: string, type: string) => intl.formatMessage({ id: key }, { type });
  const DEFAULT_REQUIRED_RULES = { required: true, message: Translation('field_mandatory_helper_label') };
  const dispatch = useDispatch();
  const onFinish = () => {
    const formData = form.getFieldsValue(true);
    onSave(formData, type);
  };

  useEffect(() => {
    async function fetchData() {
      const result = await fetchOnboardingTrainingOptions(dispatch);
      if (result.length > 0) {
        result.forEach((data) => {
          if (data?.category === OptionType.CourseType) {
            setDropdownOptions(data?.options);
            return;
          }
        });
      }
    }
    fetchData();
  }, []);

  const contentList = useMemo(() => {
    let list: any = [];
    const lessons = formListData?.lessons ?? [];
    if (lessons && lessons?.length > 0) {
      lessons.map((item: any) => {
        item.type = 'Lesson';
      });
    }
    list = lessons ?? [];
    const quiz = formListData?.quiz;
    if (quiz) {
      quiz.type = 'Quiz';
      list.push(quiz);
    }
    return list;
  }, [formListData]);

  const closeWaringDialog = () => {
    setDialog({ ...dialog, status: false });
  };

  const onSaveDraft = async (submitType: string) => {
    const formData = form.getFieldsValue(true);
    const reqData: any = {};
    if (submitType === 'publish') {
      reqData.status = PublishStatus.published;
    }
    if (formData.systemReferral) {
      const systemReferral = JSON.parse(formData.systemReferral);
      reqData.systemReferral = systemReferral;
    }
    // const validationList = regionLocales.map((localeItem) => { return [localeItem, 'name'] });
    // set validate for all mandatroy fields not only information
    form
      .validateFields()
      .then(async (res) => {
        await onSave({ ...formData, ...reqData }, type);
        if (submitType === 'publish') {
          history.goBack();
        }
      })
      .catch(async (err) => {
        const { errorFields } = err;
        if (errorFields.length <= 0) {
          await onSave({ ...formData, ...reqData }, type);
          if (submitType === 'publish') {
            history.goBack();
          }
        }
      });
    return false;
  };

  const placeSelect = Translation('app.input.placeholder.please-select');
  const placeEnter = Translation('app.input.placeholder.please-enter');

  const BasePath = useMemo(() => {
    const courseId = id || location.state?.courseId;
    return `${RECRUITMENT_BASE_PATH}${RECRUITMENT_ONBOARDING_TRAINING}${RECRUITMENT_APPLICATION_COURSES}/${courseId}`;
  }, [id, location.state]);

  const handleDelete = useCallback(async () => {
    const text =
      type !== 'view' ? Translation('recruitment.course.editDelete') : Translation('recruitment.course.viewDelete');
    openDialog('delete', text, false);
  }, [type]);

  const handleSubmit = useCallback(
    async (submitType: string) => {
      closeWaringDialog();
      if (dialog?.isError) return;
      onSaveDraft(submitType);
    },
    [dialog],
  );

  const handleOk = useCallback(async () => {
    switch (dialog.type) {
      case 'publish':
        handleSubmit(dialog.type);
        break;
      case 'delete':
        onDelete();
        break;
      default:
        break;
    }
  }, [dialog]);

  const openDialog = useCallback(
    (dialogType: string, text: string, isError: boolean) => {
      const dialogState = {
        status: true,
        type: dialogType,
        text,
        isError: isError,
      };
      setDialog(dialogState);
    },
    [type],
  );

  const handleNavigate = useCallback(
    (row: any, pageType: string) => {
      return history.push(`${BasePath}/${row.type}/${pageType}/${row.id}`, {
        courseName: formListData?.courseName,
        courseId: id,
      });
    },
    [formListData, type],
  );

  const toLessonQuizPage = useCallback(
    (row: any, pageType: string) => {
      return history.push(`${BasePath}/lesson/${row.id}/quiz/${pageType}/${row.quiz.id}`, {
        courseName: formListData?.courseName,
        lessonName: row?.lessonName,
        courseId: id,
        lessonId: row?.id,
      });
    },
    [formListData, id],
  );

  const rowOperation = useCallback(() => {
    const pageType = type === 'view' ? 'view' : 'edit';
    return [
      {
        title: Translation(`section.common.operation.${pageType}`),
        tooltipText: Translation(`section.common.operation.${pageType}`),
        onClick: (row: any) => handleNavigate(row, pageType),
        condition: (row: any) => row.type === 'Lesson',
      },
      {
        title: Translation(`section.common.operation.${pageType}`),
        tooltipText: Translation(`section.common.operation.${pageType}`),
        onClick: (row: any) => handleNavigate(row, pageType),
        condition: (row: any) => row.type === 'Quiz',
      },
      {
        title: (
          <IconButton size={'small'}>
            <ArrowUpwardIcon />
          </IconButton>
        ),
        tooltipText: 'Move Category Upward',
        onClick: (row: any) => {
          onMoveButtonClicked(row.id as string, 'UP');
        },
        condition: (row: any) => row.type === 'Lesson' && type !== 'view',
      },
      {
        title: (
          <IconButton size={'small'}>
            <ArrowDownwardIcon />
          </IconButton>
        ),
        tooltipText: 'Move Category Downward',
        onClick: (row: any) => {
          onMoveButtonClicked(row.id as string, 'DOWN');
        },
        condition: (row: any) => row.type === 'Lesson' && type !== 'view',
      },
    ];
  }, [type, formListData]);

  const onMoveButtonClicked = async (lessonId: string, type: string) => {
    try {
      await moveOnboardingLesson(id, lessonId, type, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `moved ${type} successfully `,
          },
        ]),
      );
      setTimeout(() => {
        refreshData();
      }, 100);
    } catch (err) {}
  };

  const handleAdd = useMemo(() => {
    if (disabled) return true;
    if (location.state.courseId || id) {
      return false;
    } else {
      return true;
    }
  }, [location, disabled]);

  const checkCourseStatus = useMemo(() => {
    const hasQuiz = formListData?.quiz || formListData?.lessons?.some((lesson) => lesson?.quiz);
    const hasLesson = formListData?.lessons && formListData.lessons.length > 0;
    return hasQuiz && hasLesson;
  }, [formListData]);

  const handlePublish = useCallback(() => {
    let isError = false;
    let text = Translation('recruitment.course.publish');
    if (!checkCourseStatus) {
      text = Translation('recruitment.course.publish.noneQuiz');
      isError = true;
    }
    openDialog('publish', text, isError);
  }, [formListData]);

  const isHideCancelButton = useMemo(() => {
    return dialog.type === 'publish' && dialog.isError;
  }, [dialog]);

  const memoLessonQuiz = useCallback(
    (row: any) => {
      const pageType = type === 'view' ? 'view' : 'edit';
      return row?.quiz?.quizCode ? (
        <Fragment>
          <Tooltip title={row.quiz.quizCode}>
            <div className={classes.operationBtn} onClick={() => toLessonQuizPage(row, pageType)}>
              {row.quiz.quizCode}
            </div>
          </Tooltip>
        </Fragment>
      ) : (
        '-'
      );
    },
    [contentList, type],
  );

  const MemoCoursePageTitle = useMemo(() => {
    let title = '';
    switch (type) {
      case 'create':
        title = Translation('recruitment.course.add');
        break;
      case 'edit':
        title = Translation('recruitment.course.edit');
        break;
      case 'view':
        title = Translation('recruitment.course.view');
        break;
      default:
        break;
    }
    return title;
  }, [type]);

  const MemoTable = useMemo(() => {
    return (
      <PruTable
        disableBulkSelect
        isLoading={false}
        // disableRefresh
        disablePagination
        title={Translation('recruitment.course.content')}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('recruitment.lesson.add'),
            onClick: () => history.push(`${BasePath}/lesson/create`, { courseName: formListData?.courseName }),
            disabled: handleAdd,
          },
          {
            color: 'primary',
            title: TranslationWithVariable('recruitment.quiz.add', Translation('recruitment.course.text')),
            onClick: () =>
              history.push(`${BasePath}/quiz/create`, { courseId: id, courseName: formListData?.courseName }),
            disabled: handleAdd,
          },
        ]}
        operationDef={rowOperation()}
        columnDef={[
          {
            isId: true,
            hidden: true,
            keyIndex: 'id',
            displayName: '',
            renderData: () => '',
          },
          {
            keyIndex: 'name',
            displayName: `${Translation('component.formLabel.name')}`,
            renderData: (row) => `${row?.lessonName?.[locale] || row?.quizName?.[locale]}` || '-',
          },
          {
            keyIndex: 'type',
            displayName: `${Translation('recruitment.course.contentType')}`,
            renderData: (row) => `${row.type}` || '-',
          },
          {
            keyIndex: 'code',
            displayName: `${Translation('recruitment.course.contentCode')}`,
            renderData: (row) => `${row?.lessonCode || row?.quizCode}` || '-',
          },
          {
            keyIndex: 'lessonQuiz',
            displayName: `${Translation('recruitment.course.lessonQuiz')}`,
            renderData: (row) => memoLessonQuiz(row),
          },
          {
            keyIndex: 'lastUpdatedBy',
            displayName: `${Translation('recruitment.common.lastUpdatedBy')}`,
            renderData: (row) => `${row?.audit?.lastUpdatedBy}` || '-',
          },
          {
            keyIndex: 'lastUpdatedAt',
            displayName: `${Translation('recruitment.common.lastUpdatedAt')}`,
            renderData: (row) => `${utcToLocalDate(row?.audit?.lastUpdatedAt)}` || '-',
          },
        ]}
        dataSource={contentList || []}
        totalRecords={contentList?.length || 0}
        onRefresh={refreshData}
      />
    );
  }, [formListData, id, contentList]);

  return (
    <>
      <PruDialog
        dialogTitle={Translation('reminder_title')}
        open={dialog.status}
        canCloseDialog={true}
        onCancel={closeWaringDialog}
        onOk={handleOk}
        hideCancelBtn={isHideCancelButton}
        confirmBtnText={Translation('app.button.confirm')}
        canncelBtnText={Translation('app.button.cancel')}
      >
        {dialog.text}
      </PruDialog>
      <div className="tw-relative tw-container tw-bg-white tw-p-5 tw-mb-5 tw-rounded-md tw-w-full">
        <Form form={form} onFinish={onFinish} initialValues={{ ...formListData }}>
          <div className={classes.container}>
            <div className={classes.headerContainer}>
              <div className={classes.rowContainer}>
                <div className={commonClasses.header}>{MemoCoursePageTitle}</div>
              </div>
            </div>
            {regionLocale
              .sort((lng1, _) => (lng1 === 'en' ? 1 : -1))
              .map((locale) => {
                const labelText = locale === 'en' ? `navBar.lang.${locale}` : 'local_language';
                return (
                  <Form.Item
                    key={locale}
                    name={['courseName', locale]}
                    label={`${Translation('recruitment.course.name')}(${Translation(labelText)})`}
                    rules={[DEFAULT_REQUIRED_RULES]}
                  >
                    <TextField
                      disabled={disabled}
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      placeholder={placeEnter}
                    />
                  </Form.Item>
                );
              })}
            <Form.Item
              name="courseType"
              label={Translation('recruitment.course.type')}
              rules={[DEFAULT_REQUIRED_RULES]}
            >
              <TextField
                disabled={disabled}
                select
                margin="dense"
                variant="outlined"
                fullWidth
                placeholder={placeSelect}
              >
                {dropdownOptions.map((item) => (
                  <MenuItem key={item.key} value={item.value}>
                    {item.key}
                  </MenuItem>
                ))}
              </TextField>
            </Form.Item>
            <Form.Item
              name="courseCode"
              label={Translation('recruitment.course.code')}
              rules={[DEFAULT_REQUIRED_RULES]}
            >
              <TextField
                disabled={type !== 'create'}
                margin="dense"
                variant="outlined"
                fullWidth
                placeholder={placeEnter}
              />
            </Form.Item>
            <Form.Item
              name={'systemReferral'}
              label={`${Translation('recruitment.course.source_system_referral')}?`}
              rules={[DEFAULT_REQUIRED_RULES]}
              shouldUpdate
              initialValue={YesAndNo.no}
            >
              <StyledRadioGroup aria-label="contentType" name="contentType">
                {Object.entries(YesAndNo).map(([label, value]) => (
                  <FormControlLabel
                    key={label}
                    value={value}
                    control={<Radio disabled={disabled} />}
                    label={Translation(`app.select.${label}`)}
                  />
                ))}
              </StyledRadioGroup>
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues['systemReferral'] !== currentValues['systemReferral']
              }
            >
              {(_, meta, { getFieldValue }) => {
                const systemReferral = getFieldValue('systemReferral') === YesAndNo.yes.toString();
                return (
                  <>
                    {systemReferral && (
                      <Form.Item
                        name={'referralCode'}
                        label={<span>{Translation('recruitment.course.referral_code')}</span>}
                        rules={[DEFAULT_REQUIRED_RULES]}
                        shouldUpdate
                      >
                        <TextField
                          disabled={disabled}
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          placeholder={placeEnter}
                        />
                      </Form.Item>
                    )}
                  </>
                );
              }}
            </Form.Item>
            <Form.Item
              name={'status'}
              label={Translation('status_label')}
              rules={[DEFAULT_REQUIRED_RULES]}
              shouldUpdate
              initialValue={PublishStatus.unpublished}
            >
              <StyledRadioGroup aria-label="contentType" name="contentType">
                {Object.entries(PublishStatus).map(([label, value]) => (
                  <FormControlLabel
                    key={label}
                    value={value}
                    control={<Radio disabled />}
                    label={Translation(`component.status.${label}`)}
                  />
                ))}
              </StyledRadioGroup>
            </Form.Item>
          </div>
        </Form>
        <div className="tw-h-16" />
        {MemoTable}
        <div className="tw-mt-8 tw-w-full">
          <div className="tw-space-x-4 tw-flex tw-w-full tw-justify-end">
            {type !== 'create' && (
              <Button
                variant="contained"
                size="large"
                style={{ background: '#D32F2F', color: '#ffffff' }}
                onClick={handleDelete}
              >
                {Translation('app.button.delete')}
              </Button>
            )}
            <Button variant="contained" size="large" color="secondary" onClick={() => history.goBack()}>
              {Translation('app.button.back')}
            </Button>
            {type !== 'view' && !!id && (
              <Button variant="contained" size="large" color="secondary" onClick={handlePublish}>
                {Translation('app.button.publish')}
              </Button>
            )}
            {type !== 'view' && (
              <Button variant="contained" size="large" color="secondary" onClick={() => handleSubmit('save')}>
                {Translation('app.button.save')}
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseFormList;
