import React, { FC, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useDataProvider, getDayStart, getDayEnd } from 'src/app/common/utils';
import { PaginateList } from 'src/app/common/types/common-types';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { CampaignTypeListParam, fetchCampaignTypeList } from 'src/app/modules/AgencyCampaign/network/campaignTypeCrud';
import { CampaignTypeItem, CampaignTypeStatusEnum } from 'src/app/modules/AgencyCampaign/types/campaign-type-types';
import CampaignTypeList from './components/CampaignTypeList';

type CampaignTypeListingPageProps = {} & ParamsProps;

const initialState: CampaignTypeListParam = {
  name: '',
  status: '',
  updatedBy: '',
  createdDateFrom: null,
  createdDateTo: null,
  updatedDateFrom: null,
  updatedDateTo: null,
  page: 1,
  limit: 5,
};

export const campaignTypeFilterKeys = [
  'name',
  'status',
  'updatedBy',
  'createdDateFrom',
  'createdDateTo',
  'updatedDateFrom',
  'updatedDateTo',
];

const paramsInitiator = (initialParams?: Record<string, string>): CampaignTypeListParam => {
  return initialParams
    ? {
        name: initialParams.name || '',
        status: initialParams.status || '',
        updatedBy: initialParams.updatedBy || '',
        createdDateFrom: initialParams.createdDateFrom ? getDayStart(new Date(initialParams.createdDateFrom)) : null,
        createdDateTo: initialParams.createdDateTo ? getDayStart(new Date(initialParams.createdDateTo)) : null,
        updatedDateFrom: initialParams.updatedDateFrom ? getDayStart(new Date(initialParams.updatedDateFrom)) : null,
        updatedDateTo: initialParams.updatedDateTo ? getDayStart(new Date(initialParams.updatedDateTo)) : null,
        page: 1,
        limit: 5,
      }
    : initialState;
};

const CampaignTypeListingPage: FC<CampaignTypeListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const [filterState, setFilterState] = useState<CampaignTypeListParam>(paramsInitiator(initialParams));
  const [campaignTypeList, setCampaignTypeList] = useState<PaginateList<CampaignTypeItem>>();
  const [sortKey, setSortKey] = useState<{ key: string; value?: string }[]>([]);

  const { isLoading, refreshData } = useDataProvider<PaginateList<CampaignTypeItem>>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchCampaignTypeList(filterState, sortKey, dispatch);
      } catch (err) {}
    },
    setCampaignTypeList,
    false,
  );

  useEffect(() => {
    refreshData();
  }, [sortKey]);

  const updateSortingKey = (sortingKey: { key: string; value?: string }) => {
    const sortingKeyArray = sortKey.filter((currentValue, index, arr) => {
      return currentValue.key !== sortingKey.key;
    });
    sortingKeyArray.unshift(sortingKey);
    setSortKey(sortingKeyArray);
  };

  return (
    <>
      <PruFilter
        title={Translation('agencyCampaign.campaignType.filter')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'name',
            initialValue: filterState.name,
            displayName: Translation('agencyCampaign.common.name'),
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'status',
            initialValue: filterState.status,
            displayName: Translation('agencyCampaign.common.status'),
            choices: [
              { displayName: '', value: '' },
              ...Object.entries(CampaignTypeStatusEnum).map(([key, value]) => ({ displayName: key, value: value })),
            ],
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'updatedBy',
            initialValue: filterState.updatedBy,
            displayName: Translation('agencyCampaign.common.lastUpdatedBy'),
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'createdDateFrom',
            fieldTo: 'createdDateTo',
            initialDateFrom: filterState.createdDateFrom,
            initialDateTo: filterState.createdDateTo,
            displayName: Translation('agencyCampaign.common.createdTime'),
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'updatedDateFrom',
            fieldTo: 'updatedDateTo',
            initialDateFrom: filterState.updatedDateFrom,
            initialDateTo: filterState.updatedDateTo,
            displayName: Translation('agencyCampaign.common.lastUpdatedTime'),
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            name: data.name,
            status: data.status,
            updatedBy: data.updatedBy,
            createdDateFrom: data.createdDateFrom,
            createdDateTo: data.createdDateTo,
            updatedDateFrom: data.updatedDateFrom,
            updatedDateTo: data.updatedDateTo,
          })
        }
        fetchData={refreshData}
      />
      <CampaignTypeList
        isLoading={isLoading}
        campaignTypeList={campaignTypeList}
        onRefresh={refreshData}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
        onSort={(dataKey) => updateSortingKey(dataKey)}
      />
    </>
  );
};

export default CampaignTypeListingPage;
