type Styles = {
  [key: string]: React.CSSProperties;
};

export const commonStyles: Styles = {
  targetArea: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  colorRed: {
    color: 'red',
  },
  ml70: {
    marginLeft: 70,
  },
  uploadArea: {
    marginLeft: 10,
    width: '100%',
  },
  ml140: {
    marginLeft: 140,
  },
  ml20: {
    marginLeft: 20,
  },
  ml160: {
    marginLeft: 160,
  },
  errorMsg: {
    marginLeft: 140,
    marginTop: 10,
    color: 'red',
  },
  ml120: {
    marginLeft: 120,
  },
  tableBg: {
    marginTop: 10,
    marginRight: 50,
  },
  downloadText: {
    marginLeft: 20,
    textDecorationLine: 'underline',
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  tableBgColor: {
    backgroundColor: '#FAFAFA',
  },
  colorBlack: {
    color: 'black',
  },
  deleteButton: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginRight: -12,
    marginLeft: 24,
  },
};
