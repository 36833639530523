import React, { FC, useState, useEffect } from 'react';
import { Button, Dialog } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useIntl } from 'react-intl';
import ProTable from 'src/app/common/components/ProTable';
import columns from './columns';
import { getMeterialList } from '../../api';
import { fetchLibraryList, fetchLibraryItem } from 'src/app/modules/sales-kit/components/Library/network/libraryCrud';
import { useLang } from 'src/app/i18n';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles()((theme) => ({
  modalButtons: {
    marginTop: '15px',
    marginBottom: 15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialog: {
    padding: '15px',
  },
  dialogBox: {
    with: '12000px',
  },
  noCourseError: {
    textAlign: 'center',
    color: 'red',
  },
}));

const ErrorList: FC<any> = (props) => {
  const locale = useLang();
  const dispatch = useDispatch();
  const { onSelectData, popUpVisible, setPopUpVisible, moduleType, errorList } = props;

  const filterInitData = {
    name: '',
    module: moduleType,
  };

  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  // eslint-disable-next-line
  const [formLoading, setFormLoading] = useState<boolean>(false);
  // eslint-disable-next-line
  const [filterData, setFilterData] = useState<{ [key: string]: any }>(filterInitData);
  // eslint-disable-next-line
  const [pageData, setPageData] = useState<{ [key: string]: any }>({
    pageNum: 0,
    pageSize: 5,
  });
  const [selectedRowsState, setSelectedRows] = useState<any>(null);
  const [displayNoCourseError, setDisplayNoCourseError] = useState<boolean>(false);

  const onCancel = () => {
    setPopUpVisible(false);
  };

  const formatEventData = async (newFilterState?: any) => {
    const { page, pageSize } = newFilterState;
    const list = errorList.slice(page * pageSize, page * pageSize + pageSize);

    const res = {
      data: list || [],
      success: true,
      total: errorList.length,
    };

    return res;
  };

  const fetchData = (newFilterState?: any): Promise<any> => {
    setFormLoading(true);
    setDisplayNoCourseError(false);
    return formatEventData(newFilterState);
  };

  return (
    <div style={{ width: '100%', alignSelf: 'flex-start' }}>
      <Dialog open={popUpVisible} onClose={onCancel} scroll={'paper'} fullWidth maxWidth="lg">
        <>
          <div style={{ display: 'flex', marginTop: 20 }}>
            <Button onClick={onCancel} style={{ marginLeft: 20 }} variant="contained">
              {Translation('global.back.btnText')}
            </Button>
          </div>
          <div style={{ padding: 20, paddingTop: 0 }}>
            <ProTable
              rowKey="key"
              filterTitle={Translation('mandatory.training.component.search.course.filter')}
              headerTitle={''}
              columns={columns(Translation, filterData.courseName)}
              pageSize={20}
              request={(params, sorter, filter) => {
                return fetchData(params);
              }}
              toolBarRender={() => []}
            />
          </div>
        </>
      </Dialog>
    </div>
  );
};

export default ErrorList;
