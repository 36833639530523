import React, { FC, useReducer } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { Button, FormControl, TextField, Select, MenuItem, FormHelperText } from '@mui/material';
import { MANDATORY_FIELD_ERROR_TEXT } from 'src/app/common/constants';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { RootState } from 'src/redux/store';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import { AuthenticationState } from 'src/app/modules/Auth/_redux/authSlice';
import { ErrorFieldType, useErrorHandler } from 'src/app/common/utils';
import { CampaignMappingFormMode } from 'src/app/modules/PulseLeads/types/straight-through-types';
import { CampaignDropdownList, CampaignDropdownType } from 'src/app/modules/PulseLeads/types/campaign-types';
import { ChannelCampaignItem } from 'src/app/modules/PulseLeads/types/channel-campaign-types';
import {
  CreateChannelCampaignBody,
  UpdateChannelCampaignBody,
  createChannelCampaign,
  modifyChannelCampaign,
} from 'src/app/modules/PulseLeads/network/channelCampaignCrud';
import { channelCampaignPath } from '../../ChannelCampaignRoutes';

type ChannelCampaignFormProps = {
  formMode: CampaignMappingFormMode;
  channelCampaignDropdownList?: CampaignDropdownList;
  channelCampaignItem?: ChannelCampaignItem;
};

const FIELD_CONTAINER_WIDTH = 180;

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  fieldContainer: {
    width: FIELD_CONTAINER_WIDTH,
    boxSizing: 'border-box',
  },
  innerFieldContainer: {
    width: FIELD_CONTAINER_WIDTH,
    boxSizing: 'border-box',
  },
  iconContainer: {
    width: 60,
    height: 60,
    overflow: 'hidden',
    borderRadius: 12,
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },
  sectionMargin: {
    marginBottom: 15,
  },
  divideMargin: {
    marginBottom: 10,
  },
  subHeader: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
  },
  textAreaRowContainer: {
    width: '100%',
    display: 'flex',
  },
  textAreaFieldContainer: {
    paddingTop: 15,
    minWidth: FIELD_CONTAINER_WIDTH,
    boxSizing: 'border-box',
  },
  textArea: {
    lineHeight: 1.5,
    minHeight: 40,
  },
  errorText: {
    fontSize: 9,
    color: '#F018A6',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));

type ChannelCampaignFormState = {
  channelCode?: string;
  campaign?: string;
};

const initialState: ChannelCampaignFormState = {
  channelCode: undefined,
  campaign: undefined,
};

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof ChannelCampaignFormState;
    value: any;
  };
};

type ChannelCampaignFormAction = ModifyFieldAction;

const formReducer = (state: ChannelCampaignFormState, action: ChannelCampaignFormAction): ChannelCampaignFormState => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    default:
      return state;
  }
};

const detailToStateConvertor = (channelCampaignItem?: ChannelCampaignItem): ChannelCampaignFormState => {
  return channelCampaignItem
    ? {
        channelCode: channelCampaignItem.channelCode,
        campaign: channelCampaignItem.campaign,
      }
    : initialState;
};

const ChannelCampaignForm: FC<ChannelCampaignFormProps> = ({
  formMode,
  channelCampaignDropdownList,
  channelCampaignItem,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { classes: commonClasses } = useCommonStyles();
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { user } = useSelector<RootState, AuthenticationState>((state) => state.auth);

  const [formState, formDispatch] = useReducer(formReducer, detailToStateConvertor(channelCampaignItem));

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler } = useErrorHandler(formState, [
    {
      name: 'channelCode',
      fieldType: ErrorFieldType.MANDATORY,
    },
    {
      name: 'campaign',
      fieldType: ErrorFieldType.MANDATORY,
    },
  ]);

  const onSubmit = async () => {
    const { hasError } = onSubmitErrorValidator();
    if (!hasError) {
      if (formMode === CampaignMappingFormMode.CREATE) {
        const body: CreateChannelCampaignBody = {
          channelCode: formState.channelCode || '',
          campaign: formState.campaign || '',
          createdBy: user?.username || 'Default',
          updatedBy: user?.username || 'Default',
        };
        try {
          await createChannelCampaign(body, dispatch);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: 'Channel Campaign saved successfully',
              },
            ]),
          );
          history.push(channelCampaignPath);
        } catch (err) {}
      } else if (channelCampaignItem) {
        const body: UpdateChannelCampaignBody = {
          channelCode: formState.channelCode || '',
          campaign: formState.campaign || '',
          updatedBy: user?.username || 'Default',
        };
        try {
          await modifyChannelCampaign(channelCampaignItem._id, body, dispatch);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: `Channel Campaign updated successfully - ${channelCampaignItem._id}`,
              },
            ]),
          );
          history.push(channelCampaignPath);
        } catch (err) {}
      }
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <div className={classes.rowContainer}>
          <div className={commonClasses.header}>
            {Translation(
              `pulseleads.straightThrough.channelCampaign.form.title.${
                formMode === CampaignMappingFormMode.CREATE ? 'create' : 'edit'
              }`,
            )}
          </div>
        </div>
        <Button variant="contained" color="inherit" onClick={() => history.push(channelCampaignPath)}>
          {Translation('app.button.back')}
        </Button>
      </div>

      <div className={classes.sectionMargin}>
        <div className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>
              {Translation('pulseleads.straightThrough.channelCode')}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <div style={{ flexGrow: 1 }}>
            <TextField
              style={{ width: 250 }}
              error={errorState.mandatory.channelCode}
              margin="dense"
              variant="outlined"
              helperText={errorState.mandatory.channelCode && MANDATORY_FIELD_ERROR_TEXT}
              value={formState.channelCode}
              onChange={(e) => {
                onDismissErrorHandler('channelCode', e.target.value);
                formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'channelCode', value: e.target.value } });
              }}
            />
          </div>
        </div>

        <div className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>
              {Translation('pulseleads.straightThrough.campaignName')}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <FormControl error={errorState.mandatory.campaign} margin="dense" variant="outlined">
            <Select
              style={{ minWidth: 350 }}
              value={formState.campaign || ''}
              onChange={(e) => {
                onDismissErrorHandler('campaign', e.target.value);
                formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'campaign', value: e.target.value } });
              }}
            >
              {channelCampaignDropdownList &&
                channelCampaignDropdownList[CampaignDropdownType.CAMPAIGN] &&
                channelCampaignDropdownList[CampaignDropdownType.CAMPAIGN].map((dropdownItem) => (
                  <MenuItem key={dropdownItem.key} value={dropdownItem.key}>
                    {dropdownItem.value}
                  </MenuItem>
                ))}
            </Select>
            {errorState.mandatory.campaign && <FormHelperText>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>}
          </FormControl>
        </div>
      </div>

      <div className={classes.footerContainer}>
        <Button variant="contained" color="secondary" onClick={onSubmit}>
          {Translation('app.button.submit')}
        </Button>
      </div>
    </div>
  );
};

export default ChannelCampaignForm;
