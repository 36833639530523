// Redefine the menu toolbar, and item under each submenu
// Detail menu list see https://www.tiny.cloud/docs-4x/advanced/editor-control-identifiers/#menucontrols


const menu = {
    file: {title: 'File', items: 'newdocument | preview | print'},
    edit: {title: 'Edit', items: 'undo redo | cut copy paste pastetext | selectall | searchreplace'},
    view: {title: 'View', items: 'visualaid visualchars visualblocks | spellchecker | preview fullscreen'},
    insert: {title: 'Insert', items: 'image link media template | hr nonbreaking | insertdatetime'},
    format: {title: 'Format', items: 'bold italic underline strikethrough superscript subscript | blockformats align | removeformat'},
    tools: {title: 'Tools', items: 'spellchecker'},
    table: {title: 'Table', items: 'inserttable tableprops deletetable | cell row column'}
  }
export default menu
