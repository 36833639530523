import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { personalParticularPath } from '../../PersonalParticularRoutes';
import { PaginateList } from 'src/app/common/types/common-types';
import { getDefaultDisplayDate } from 'src/app/common/utils';
import { PersonalParticularItem } from 'src/app/modules/Recruit/types/personal-particular-types';

type PersonalParticularListProps = {
  isLoading: boolean;
  personalParticularList?: PaginateList<PersonalParticularItem>;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
};

const PersonalParticularList: FC<PersonalParticularListProps> = ({
  isLoading,
  personalParticularList,
  onRefresh,
  onChangePage,
}) => {
  const history = useHistory();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  return (
    <>
      <PruTable
        title={Translation('recruit.personalParticular.list')}
        disableBulkSelect
        operationDef={[
          {
            title: Translation('section.common.operation.view'),
            tooltipText: 'View Detail',
            onClick: (row) => history.push(`${personalParticularPath}/detail/${row.appId}`),
          },
          {
            title: Translation('recruit.common.documents'),
            tooltipText: 'View Documents',
            onClick: (row) => history.push(`${personalParticularPath}/doc/${row.appId}`),
          },
        ]}
        columnDef={[
          {
            isId: true,
            hidden: true,
            keyIndex: '_id',
            displayName: '',
            renderData: (row) => '',
          },
          {
            keyIndex: 'appId',
            displayName: Translation('recruit.common.applicationId'),
            renderData: (row) => row.appId || '-',
          },
          {
            keyIndex: 'isFollowUpByZone',
            displayName: Translation('recruit.personalParticular.zoneFollowUp'),
            renderData: (row) => (row.followUpDetails ? row.followUpDetails.isFollowUpByZone.toString() : '-'),
          },
          {
            keyIndex: 'recruiterName',
            align: 'center',
            displayName: Translation('recruit.personalParticular.recruiterName'),
            renderData: (row) => (row.recruiterInfo ? row.recruiterInfo.recruiterName || '-' : '-'),
          },
          {
            keyIndex: 'applicantName',
            align: 'center',
            displayName: Translation('recruit.personalParticular.applicantName'),
            renderData: (row) =>
              row.personalParticular
                ? row.personalParticular.engSurname
                  ? row.personalParticular.engSurname +
                    (row.personalParticular.engFirstName ? ' ' + row.personalParticular.engFirstName : '')
                  : '-'
                : '-',
          },
          {
            keyIndex: 'status',
            align: 'center',
            displayName: Translation('recruit.personalParticular.status'),
            renderData: (row) => row.appStatus || '-',
          },
          {
            keyIndex: 'lastUpdatedDate',
            align: 'center',
            displayName: Translation('recruit.common.lastUpdatedDate'),
            renderData: (row) => getDefaultDisplayDate(new Date(row.lastUpdatedDate), 'datetime'),
          },
        ]}
        isLoading={isLoading}
        onRefresh={onRefresh}
        dataSource={personalParticularList?.docs}
        totalPages={personalParticularList?.totalPages}
        totalRecords={personalParticularList?.totalDocs}
        onChangePage={onChangePage}
      />
    </>
  );
};

export default PersonalParticularList;
