import React, { FC, HTMLAttributes } from 'react';
import { RuleCriteria, RuleItem, RuleOperator } from '../enum/rule-enum';
import { makeStyles } from 'tss-react/mui';
import { FormControl, Select, MenuItem, FormHelperText } from '@mui/material';
import { useIntl } from 'react-intl';
import { range } from 'lodash';
import { ErrorState } from 'src/app/common/utils';
import { MANDATORY_FIELD_ERROR_TEXT } from 'src/app/common/constants';

type RuleCriteriaDropdownProps = {
  title: string;
  ruleCriteria: RuleCriteria;
  onChangeCriteria: (ruleItems: RuleItem[]) => void;
  errorState?: ErrorState;
  onDismissErrorHandler?: (field: string, value: any) => void;
  customFieldContainer?: any;
};

const useStyles = makeStyles()((theme) => ({
  subHeader: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
  },
  divideMargin: {
    marginBottom: 10,
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  fieldContainer: {
    width: 160,
    boxSizing: 'border-box',
  },
}));

const RuleCriteriaDropdown: FC<RuleCriteriaDropdownProps & HTMLAttributes<HTMLDivElement>> = ({
  title,
  ruleCriteria,
  onChangeCriteria,
  errorState,
  onDismissErrorHandler,
  customFieldContainer,
  ...rest
}) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const onChange = (value: string, currentLayer: number) => {
    let newRules = [...ruleCriteria.rules];
    const optionItem = ruleCriteria.dropdown.find((item) => item.optionId === value);
    if (optionItem !== undefined) {
      let foundIndex = newRules.findIndex(
        (rule) => rule.items && rule.items.length > 0 && rule.items[0].priority === currentLayer,
      );
      const newItem: RuleItem = {
        name: optionItem.optionId,
        items: optionItem.optionValue.map((option) => ({
          type: option,
          operator: RuleOperator.INCLUDE,
          priority: currentLayer,
        })),
      };
      if (foundIndex !== -1) {
        newRules[foundIndex] = newItem;
      } else {
        newRules.push(newItem);
      }
    } else {
      newRules = newRules.filter((_, index) => index < currentLayer - 1);
    }

    newRules = newRules.splice(0, currentLayer);
    newRules = newRules
      .filter((rule) => rule.items.length > 0 && rule.items[0].priority !== 0)
      .sort((ruleA, ruleB) => ruleA.items[0].priority - ruleB.items[0].priority);

    onChangeCriteria(newRules);
  };

  return (
    <div {...rest}>
      <div className={classes.divideMargin}>
        <span className={classes.subHeader}>{title}</span>
      </div>
      {range(ruleCriteria.maxLayer).map((index) => {
        const currentLayer = index + 1;
        const isDisplayRow =
          currentLayer === 1 ||
          ruleCriteria.mandatoryLayer >= currentLayer ||
          (currentLayer > 1 &&
            ruleCriteria.rules[index - 1] &&
            ruleCriteria.rules[index - 1].items[0].priority === currentLayer - 1);
        return (
          isDisplayRow && (
            <div key={`criteria-${currentLayer}`} className={classes.rowContainer} style={{ marginBottom: 10 }}>
              <div className={customFieldContainer ? customFieldContainer : classes.fieldContainer}>
                <span className={classes.field}>
                  {Translation(`pulseleads.ruleCriteria.priority.${currentLayer}`)}
                  {currentLayer <= ruleCriteria.mandatoryLayer && <span className={classes.mandatory}>*</span>} :
                </span>
              </div>
              <FormControl
                error={errorState?.mandatory[`criteriaPriority-${currentLayer}`]}
                style={{ margin: 0 }}
                margin="dense"
                variant="outlined"
              >
                <Select
                  style={{ minWidth: 200 }}
                  value={ruleCriteria.rules[index]?.name || Translation('common.dropdown.emptyValue')}
                  onChange={(e) => {
                    if (onDismissErrorHandler && ruleCriteria.mandatoryLayer >= currentLayer) {
                      onDismissErrorHandler(
                        `criteriaPriority-${currentLayer}`,
                        e.target.value !== Translation('common.dropdown.emptyValue'),
                      );
                    }
                    onChange(e.target.value as string, currentLayer);
                  }}
                >
                  <MenuItem key={'option-default'} value={Translation('common.dropdown.emptyValue')}>
                    {Translation('common.dropdown.emptyValue')}
                  </MenuItem>
                  {ruleCriteria.dropdown
                    .filter(
                      (dropdown) =>
                        !!!ruleCriteria.rules.find(
                          (rule, ruleIndex) => ruleIndex < index && rule.name === dropdown.optionId,
                        ),
                    )
                    .map((dropdown) => (
                      <MenuItem key={`option-${dropdown.optionId}`} value={dropdown.optionId}>
                        {dropdown.optionText}
                      </MenuItem>
                    ))}
                </Select>
                {errorState?.mandatory[`criteriaPriority-${currentLayer}`] && (
                  <FormHelperText>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>
                )}
              </FormControl>
            </div>
          )
        );
      })}
    </div>
  );
};

export default RuleCriteriaDropdown;
