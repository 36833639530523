import { Dispatch } from 'react';
import { getQueryPath } from 'src/app/common/utils';
import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import { SurveyDetail, SurveyPaginateList, SurveyResult } from '../types/survey-types';

export type SurveyResultListParam = {
  startDateFrom: Date | null;
  startDateTo: Date | null;
  endDateFrom: Date | null;
  endDateTo: Date | null;
  limit: number;
  page: number;
};

export type SurveyItemBody = {
  startDate: Date | null;
  endDate: Date | null;
  eligibleAgents: string[];
  excludedAgents: string[];
  designations: string[];
  body: Record<string, unknown>;
};

export const fetchSurveyResultList = async (
  params: SurveyResultListParam,
  dispatch?: Dispatch<any>,
): Promise<SurveyPaginateList> => {
  const queryPath = getQueryPath(`${ADMIN_URL}/survey?`, params);

  return apiClient
    .get<SurveyPaginateList>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

//--------------------agent survey---------------//
export const fetchSurveyItem = async (id: string, dispatch?: Dispatch<any>): Promise<SurveyDetail> => {
  return apiClient
    .get<SurveyDetail>(`${ADMIN_URL}/survey/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createSurvey = async (body: SurveyItemBody, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .post<void>(`${ADMIN_URL}/survey`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const modifySurvey = async (id: string, body: SurveyItemBody, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .put<void>(`${ADMIN_URL}/survey/${id}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchSurveyResults = async (id: string, dispatch?: Dispatch<any>): Promise<SurveyResult> => {
  return apiClient
    .get<SurveyResult>(`${ADMIN_URL}/survey/${id}/result`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const downloadSurveyResults = async (id: string, dispatch?: Dispatch<any>): Promise<string> => {
  return apiClient
    .get<string>(`${ADMIN_URL}/survey/${id}/result/download`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
