import React, { FC, useEffect, useState } from 'react';

import { RouteComponentProps } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchInsuranceConfig } from '../network/insuranceSummaryCrud';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { LayoutSplashScreen } from 'src/app/layout';

import InsuranceSummaryFrom from '../components/InsuranceSummaryForm';
import { InsuranceSummaryDetail } from '../type/insurance-summary-types';

const InsuranceSummaryPage: FC<RouteComponentProps> = ({ history }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [insuranceSummary, setInsuranceSummary] = useState<InsuranceSummaryDetail>();
  const fetchdata = async () => {
    setIsLoading(true);
    fetchInsuranceConfig(dispatch)
      .then((data) => {
        setInsuranceSummary(data);
      })
      .catch((err) => {
        history.push(NOT_FOUND_ERROR_PAGE_PATH);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchdata();
  }, []);

  return <>{insuranceSummary && <InsuranceSummaryFrom insuranceSummary={insuranceSummary} />}</>;
};

export default InsuranceSummaryPage;
