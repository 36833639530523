import { identity } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { getDefaultDisplayDate } from 'src/app/common/utils';
import { ApiErrorResponse } from 'src/app/common/network';
import { fetchCampaignItem, fetchLeadSource, fetchAgentLeadSource } from '../../../network/campaignCrud';
import { CampaignItem, LeadSource } from '../../../types/insights-types';
import InsightDetailPage from './components/InsightDetailPage';

type WhiteModalProps = {
  title: string;
  countNum: number;
  fromNum: number;
};

const WhiteSpanModal: FC<WhiteModalProps> = ({ title, countNum, fromNum }) => {
  return (
    <>
      <div
        className="whiteSpanModal"
        style={{
          flex: 1,
          backgroundColor: 'white',
          margin: 35,
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 10,
        }}
      >
        <span style={{ fontSize: 18, fontWeight: 600, margin: 20 }}>{title}</span>
        <span style={{ fontSize: 28, color: 'red', marginLeft: 20, fontWeight: 600 }}>
          {countNum}({((countNum / fromNum) * 100).toFixed(1)}%)
        </span>
        <span style={{ fontSize: 14, color: 'grey', marginLeft: 20, marginBottom: 20 }}>from {fromNum} received</span>
      </div>
    </>
  );
};

const InsightListingPage: FC<RouteComponentProps> = ({ history }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  //const Translation = (id: string) => intl.formatMessage({ id });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [campaignItem, setCampaignItem] = useState<CampaignItem>();
  const [leadsource, setLeadSource] = useState<LeadSource>();
  const { id, campaignId } = useParams<{ id: string; campaignId: string }>();
  const reloadCampaignData = async () => {
    if (id) {
      setIsLoading(true);
      fetchCampaignItem(id, dispatch)
        .then((result) => {
          setCampaignItem(result);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };
  const reloadsourceData = async (source: any) => {
    setIsLoading(true);
    console.log('source', source);
    await fetchLeadSource(source, dispatch)
      .then((result) => {
        console.log(result);
        setLeadSource(result);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const reloadAgencySourceData = async (source: any) => {
    setIsLoading(true);
    console.log('source', source);
    await fetchAgentLeadSource(source, dispatch)
      .then((result) => {
        console.log('result', result);
        setLeadSource(result);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const [received, setReceived] = useState(0);
  const [distributed, setDistributed] = useState(0);
  const [progress, setProgress] = useState(0);
  const [closed, setClosed] = useState(0);

  useEffect(() => {
    reloadCampaignData();
  }, [id]);

  useEffect(() => {
    if (campaignItem?.source) {
      const sourceId = campaignItem?.source ? campaignItem?.source : campaignItem?.agencyCampaign?.agentLeadSource;

      reloadsourceData(sourceId);
    } else if (campaignItem?.agencyCampaign?.agentLeadSource) {
      const sourceId = campaignItem?.source ? campaignItem?.source : campaignItem?.agencyCampaign?.agentLeadSource;

      reloadAgencySourceData(sourceId);
    }
  }, [campaignItem?.source, campaignItem?.agencyCampaign?.agentLeadSource]);

  return (
    <div className="main" style={{ backgroundColor: 'white' }}>
      <div className="campaignInfo" style={{ margin: 40, display: 'flex', flexDirection: 'column', fontSize: 18 }}>
        <div>
          <span>
            Lead Campaign ID：<span style={{ fontWeight: 'bold' }}>{campaignItem?.campaignId || ''}</span>
          </span>
        </div>
        <div style={{ marginTop: 10 }}>
          <span>
            Lead Campaign NAME：<span style={{ fontWeight: 'bold' }}>{campaignItem?.name}</span>
          </span>
        </div>
      </div>
      <div
        className="campaignInfo"
        style={{ backgroundColor: '#eaeaea', display: 'flex', flexDirection: 'row', marginBottom: 30 }}
      >
        <div className="left Modal" style={{ flex: 3, display: 'flex', flexDirection: 'row' }}>
          <WhiteSpanModal title={'Leads Distributed'} countNum={distributed} fromNum={received} />
          <WhiteSpanModal title={'In Progress Leads'} countNum={progress} fromNum={distributed} />
          <WhiteSpanModal title={'Closed Leads'} countNum={closed} fromNum={distributed} />
        </div>
        <div className="right Modal" style={{ flex: 2, margin: 35, display: 'flex', flexDirection: 'row' }}>
          <div className="left" style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            <span style={{ color: 'grey', margin: 10 }}>Status</span>
            <span style={{ fontWeight: 'bold', margin: 10 }}>{campaignItem?.status}</span>
            <span style={{ color: 'grey', margin: 10 }}>Start Date</span>
            <span style={{ margin: 10 }}>
              {campaignItem?.startDate ? getDefaultDisplayDate(campaignItem?.startDate) : '-'}
            </span>
          </div>
          <div className="right" style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            <span style={{ color: 'grey', margin: 10 }}>Lead Source</span>
            <span style={{ margin: 10 }}>{leadsource?.sourceName}</span>
            <span style={{ color: 'grey', margin: 10 }}>End Date</span>
            <span style={{ margin: 10 }}>
              {campaignItem?.endDate ? getDefaultDisplayDate(campaignItem?.endDate) : '-'}
            </span>
          </div>
        </div>
      </div>
      <InsightDetailPage
        getReceived={setReceived}
        getDistributed={setDistributed}
        getProgress={setProgress}
        getClosed={setClosed}
        filterId={campaignId}
      />
    </div>
  );
};

export default InsightListingPage;
