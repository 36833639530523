import React, { FC } from "react";
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import PruRoute from 'src/app/common/components/PruRoute';
import ParamsProvider from 'src/app/common/components/ParamsProvider';
import { PULSELEADS_BASE_PATH } from '../../constants';
import { PULSELEADS_CAMPAIGN_FEEDBACK_PATH } from './constants';
import CampaignFeedbackListingPage, { campaignFeedbackFilterKeys } from './List/CampaignFeedbackListingPage';
import CampaignFeedbackDetailPage from './Form/CampaignFeedbackDetailPage';
import CampaignFeedbackGroupDetailPage from './GroupForm/CampaignFeedbackGroupDetailPage';

export const campaignFeedbackPath = `${PULSELEADS_BASE_PATH}${PULSELEADS_CAMPAIGN_FEEDBACK_PATH}`;

const CampaignFeedbackRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect exact from={campaignFeedbackPath} to={`${campaignFeedbackPath}/list`} />
      <PruRoute exact path={`${campaignFeedbackPath}/list`} render={(props) => (
        <ParamsProvider {...props} acceptKeys={campaignFeedbackFilterKeys}>
          <CampaignFeedbackListingPage />
        </ParamsProvider>
      )}/>
      <PruRoute exact path={`${campaignFeedbackPath}/edit/:id`} component={CampaignFeedbackDetailPage}/>
      <PruRoute exact path={`${campaignFeedbackPath}/create`} component={CampaignFeedbackDetailPage}/>
      <PruRoute exact path={`${campaignFeedbackPath}/group/edit/:id`} component={CampaignFeedbackGroupDetailPage}/>
      <PruRoute exact path={`${campaignFeedbackPath}/group/create`} component={CampaignFeedbackGroupDetailPage}/>
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default CampaignFeedbackRoutes;