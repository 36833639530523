import { makeStyles } from 'tss-react/mui';

export const useDetailsStyles = makeStyles()(() => ({
  accordion: {
    width: '100%',
  },
  label: {
    color: '#3F4254',
  },
}));
