import React, { FC, useEffect } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import PruRoute from '../../../../../common/components/PruRoute';
import { NOT_FOUND_ERROR_PAGE_PATH } from '../../../../../common/constants';
import {
  SALESKIT_BASE_PATH,
  RECRUIT_LIBRARY_BASE_PATH,
  PROMOTION_MATERIAL_BASE_PATH,
  PROMOTION_LIBRARY_BASE_PATH,
} from '../../../constants';
import PromotionLibraryListPage from './PromotionLibraryListPage';
import PromotionLibraryCreatePage from './PromotionLibraryPageCreatePage';
import { ModulePermissionProps } from '../../../../../common/module/types';
import PromotionLibraryPerformancePage from './PromotionPerformancePage';
import { useDispatch } from 'react-redux';
import { resetState } from '../../../redux/saleskit.slice';

const PromotionLibraryRoutes: FC<RouteComponentProps & ModulePermissionProps> = ({
  enableRead,
  enableCreate,
  enableUpdate,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      // clear list state
      dispatch(resetState());
    };
  }, []);
  return (
    <Switch>
      <Redirect
        exact
        from={`${SALESKIT_BASE_PATH}${PROMOTION_MATERIAL_BASE_PATH}${PROMOTION_LIBRARY_BASE_PATH}`}
        to={`${SALESKIT_BASE_PATH}${PROMOTION_MATERIAL_BASE_PATH}${PROMOTION_LIBRARY_BASE_PATH}/list`}
      />
      <PruRoute
        exact
        path={`${SALESKIT_BASE_PATH}${PROMOTION_MATERIAL_BASE_PATH}${PROMOTION_LIBRARY_BASE_PATH}/list`}
        render={(props) => (
          <PromotionLibraryListPage
            {...props}
            enableRead={enableRead}
            enableCreate={enableCreate}
            enableUpdate={enableUpdate}
          />
        )}
      />
      <PruRoute
        exact
        path={`${SALESKIT_BASE_PATH}${PROMOTION_MATERIAL_BASE_PATH}${PROMOTION_LIBRARY_BASE_PATH}/create`}
        component={PromotionLibraryCreatePage}
      />
      <PruRoute
        exact
        path={`${SALESKIT_BASE_PATH}${PROMOTION_MATERIAL_BASE_PATH}${RECRUIT_LIBRARY_BASE_PATH}/edit/:id`}
        component={PromotionLibraryCreatePage}
      />
      <PruRoute
        exact
        path={`${SALESKIT_BASE_PATH}${PROMOTION_MATERIAL_BASE_PATH}${RECRUIT_LIBRARY_BASE_PATH}/view/:id`}
        component={PromotionLibraryCreatePage}
      />
      <PruRoute
        exact
        path={`${SALESKIT_BASE_PATH}${PROMOTION_MATERIAL_BASE_PATH}${RECRUIT_LIBRARY_BASE_PATH}/performance/:id`}
        component={PromotionLibraryPerformancePage}
      />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default PromotionLibraryRoutes;
