import React, { useState, useEffect } from 'react';
import Form from 'src/app/common/components/Form';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { makeStyles } from 'tss-react/mui';
import {
  Button,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { updateFields, getFieldsById } from '../../api';
import { regionLocale, RegionLocale } from 'src/app/i18n';
import { leadEngagementPath } from '../../EngagementFiledsRoutes';
import TableInput from '../components/TableInput';
import { RootState } from 'src/redux/store';
import { AuthenticationState } from 'src/app/modules/Auth/_redux/authSlice';

const useStyles = makeStyles()((theme) => ({
  textArea: {
    lineHeight: 1.5,
    minHeight: 40,
  },
  backdrop: {
    position: 'fixed',
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.common.white,
    backgroundColor: `${theme.palette.common.white} !important`,
    opacity: '0.5 !important',
  },
}));

export const mandatoryType: any = {
  yes: {
    value: 'true',
    text: 'Yes',
  },
  no: {
    value: 'false',
    text: 'No',
  },
};
export const formatType: any = {
  Dropdown: {
    value: 'dropdown',
    text: 'Dropdown',
  },
};

const Detail: React.FC<any> = (props) => {
  const { history } = props;
  const { classes } = useStyles();
  const { classes: commonClasses } = useCommonStyles();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const DEFAULT_REQUIRED_RULES = {
    required: true,
    message: Translation('component.pulseleads.prospect.form-required-text'),
  };
  const placeEnter = Translation('app.input.placeholder.please-enter');
  const [loading, setLoading] = useState<boolean>(false);
  const [defaultValues, setDefaultValues] = useState<any>();
  const { id } = useParams<{ id: string }>();
  const { user } = useSelector<RootState, AuthenticationState>((state) => state.auth);
  const [mandatory, setMandatory] = useState('');
  useEffect(() => {
    const fetchAPI = async () => {
      try {
        setLoading(true);
        await getFields();
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchAPI();

    return;
    // eslint-disable-next-line
  }, []);

  const getFields = async () => {
    const result = await getFieldsById(id);
    const { mandatory, i18nData, options } = result.data;
    let name = {};
    regionLocale.map((locale: string) => {
      const temp = i18nData.filter((item: any) => item.locale === locale)[0];
      name = { ...name, [locale]: temp?.name };
    });

    const values = {
      ...result.data,
      ...{
        name: name,
        options: options.filter((item: { deleted: boolean }) => item?.deleted !== true),
        deletedOptions: options.filter((item: { deleted: boolean }) => item?.deleted === true),
      },
    };
    mandatory ? setMandatory('true') : setMandatory('false');
    setDefaultValues({ ...values });
    form.setFieldsValue({ ...values });
  };
  const onFinish = async () => {
    const allValues = form.getFieldsValue(true);
    const saveData: any = {
      ...allValues,
      ...{
        mandatory: JSON.parse(mandatory),
        options: allValues.options.concat(allValues.deletedOptions),
        updatedBy: user?.username,
        i18nData: regionLocale.map((locale: string) => {
          return { locale: locale, name: form.getFieldValue('name')[locale] };
        }),
      },
    };
    delete saveData.deletedOptions;
    delete saveData.updatedAt;
    // delete saveData.name;
    setLoading(true);
    console.log(saveData);
    await updateFields(id, saveData, dispatch);
    setLoading(false);
    dispatch(
      appendAlertItem([
        {
          severity: AlertType.SUCCESS,
          title: 'Success',
          content: 'Update Engagement fields saved successfully',
        },
      ]),
    );

    goBackToList();
  };

  const goBackToList = () => {
    history.push(leadEngagementPath);
  };

  const STRING_LENGTH_VALIDATE_CHI = [
    DEFAULT_REQUIRED_RULES,
    {
      validator(_: any, value: string) {
        if (value.length > 12) {
          return Promise.reject(
            new Error(Translation('form.pulseleads.engagement.fields.name.chi.length.validate.message')),
          );
        }
        return Promise.resolve();
      },
    },
  ];

  const STRING_LENGTH_VALIDATE_EN = [
    DEFAULT_REQUIRED_RULES,
    {
      validator(_: any, value: string) {
        if (value.length > 25) {
          return Promise.reject(
            new Error(Translation('form.pulseleads.engagement.fields.name.en.length.validate.message')),
          );
        }
        return Promise.resolve();
      },
    },
  ];

  return (
    <div className={commonClasses.container}>
      <div className={commonClasses.header} style={{ marginBottom: 20 }}>
        {`${Translation('component.formTitle.edit')} ${Translation('pulseleads.engagement.fields')}`}
      </div>
      <Form
        form={form}
        className={`add-form-wraper form-wraper`}
        onFinish={onFinish}
        initialValues={{ ...defaultValues }}
      >
        <div style={{ marginBottom: 10, marginLeft: 8 }}>
          <span className={commonClasses.subHeader}>{Translation('pulseleads.engagement.fields')}</span>
          <span style={{ marginLeft: 150 }}>{form.getFieldValue('field')}</span>
        </div>
        <div style={{ marginBottom: 10, marginLeft: 8 }}>
          <span className={commonClasses.subHeader}>{Translation('component.formLabel.name')}</span>
        </div>
        {regionLocale.map((locale) => {
          return (
            <Form.Item
              style={{ marginLeft: 32 }}
              label={Translation(`navBar.lang.${locale}`)}
              name={['name', locale]}
              rules={
                locale === RegionLocale.TRADITIONAL_CHINESE ? STRING_LENGTH_VALIDATE_CHI : STRING_LENGTH_VALIDATE_EN
              }
            >
              <TextField margin="dense" variant="outlined" fullWidth placeholder={placeEnter} />
            </Form.Item>
          );
        })}
        <div className={commonClasses.subHeader} style={{ marginBottom: 10, marginLeft: 8 }}>
          <span className={commonClasses.subHeader}>{Translation('pulseleads.engagement.format&option')}</span>
        </div>
        <Form.Item
          name="format"
          label={Translation('form.pulseleads.engagement.fields.format')}
          style={{ marginLeft: 32 }}
        >
          <RadioGroup aria-label="Format" name="format" style={{ display: 'flex', flexDirection: 'row' }}>
            {Object.keys(formatType).map((key: string) => (
              <FormControlLabel
                key={formatType[key].text}
                value={formatType[key].value}
                control={<Radio />}
                checked={true}
                label={formatType[key].text}
                disabled={true}
              />
            ))}
          </RadioGroup>
        </Form.Item>
        <span
          style={{ position: 'relative', width: '100%', marginLeft: 32, display: 'flex', alignItems: 'flex-start' }}
        >
          {Translation('form.pulseleads.engagement.fields.option')}
          <span style={{ color: 'red' }}>*</span>
          <div style={{ marginLeft: 110 }}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 21, marginLeft: 100 }}>
              {regionLocale.map((locale: string) => {
                return (
                  <span style={{ flex: 1 }}>
                    {Translation(`navBar.lang.${locale}`)}
                    <span style={{ color: 'red' }}>*</span>
                  </span>
                );
              })}
            </div>
            <Form.Item name="options">
              <TableInput />
            </Form.Item>
          </div>
        </span>

        <Form.Item
          name="mandatory"
          label={Translation('form.pulseleads.engagement.fields.mandatory')}
          rules={[DEFAULT_REQUIRED_RULES]}
          style={{ marginLeft: 32, marginTop: 32 }}
        >
          <RadioGroup aria-label="Mandatory" name="mandatory" style={{ display: 'flex', flexDirection: 'row' }}>
            {Object.keys(mandatoryType).map((key: string) => (
              <FormControlLabel
                key={mandatoryType[key].text}
                value={mandatoryType[key].value}
                control={
                  <Radio
                    onChange={(e: any) => {
                      form.setFieldsValue({ mandatory: e.target.value.toString() });
                      setMandatory(e.target.value.toString());
                    }}
                  />
                }
                checked={mandatory === mandatoryType[key].value}
                label={mandatoryType[key].text}
              />
            ))}
          </RadioGroup>
        </Form.Item>
        <Form.Item noStyle>
          <>
            <Button type="submit" style={{ float: 'right' }} variant="contained" color="secondary">
              {Translation('app.button.submit')}
            </Button>

            <Button
              variant="contained"
              color="inherit"
              style={{ float: 'right', marginRight: 15 }}
              onClick={goBackToList}
            >
              {Translation('app.button.cancel')}
            </Button>
          </>
        </Form.Item>
      </Form>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="secondary" />
      </Backdrop>
    </div>
  );
};

export default Detail;
