import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import { Dispatch } from 'react';

export type UpdateProspectCategoryBody = {
  name: any;
  description: any;
  isDefault: boolean;
  colorLabel: string;
  backgroundColorLabel: string;
  targetNumber?: number;
  targetFrequency?: number;
  type: string;
  isActive: boolean;
  updatedBy?: string;
  color?: any;
};

export const getList = async (dto: any, dispatch?: Dispatch<any>) => {
  try {
    return apiClient.get(`${ADMIN_URL}/prospect-categories`, { params: dto });
  } catch (err: any) {
    throw apiErrorHandler(err, dispatch);
  }
};

export const getCategoryById = async (id: string, dispatch?: Dispatch<any>) => {
  try {
    return apiClient.get(`${ADMIN_URL}/prospect-categories/${id}`);
  } catch (err: any) {
    throw apiErrorHandler(err, dispatch);
  }
};

export const updateCategory = async (id: string, body: UpdateProspectCategoryBody, dispatch?: Dispatch<any>) => {
  try {
    return apiClient.patch(`${ADMIN_URL}/prospect-categories/${id}`, {
      ...body,
    });
  } catch (err: any) {
    throw apiErrorHandler(err, dispatch);
  }
};

export const checkColor = async (params: any, dispatch?: Dispatch<any>) => {
  try {
    return apiClient.get(`${ADMIN_URL}/prospect-categories/color-avalilability`, {
      params,
    });
  } catch (err: any) {
    throw apiErrorHandler(err, dispatch);
  }
};
