import React, { FC, useState, useReducer, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Dialog, Button, TextField } from '@mui/material';
import { appendAlertItem, AlertType } from '../../../../../../redux/common/commonSlice';
import { useDispatch } from 'react-redux';
import { createFeedbackCategory, modifyFeedbackCategory } from '../../../network/feedbackCrud';
import {
  FeedbackCategoryItem,
  FeedbackCategoryBody,
  SubCategoryBody,
  CategoryName,
} from '../../../types/feedback-type';
import { useErrorHandler, ErrorFieldType } from 'src/app/common/utils';
import { useIntl } from 'react-intl';
import { getAvailableServerLocale } from 'src/app/i18n';
import { MANDATORY_FIELD_ERROR_TEXT, EMAIL_FORMAT_ERROR_TEXT } from 'src/app/common/constants';

export enum CategoryDialogMode {
  ADD_MAIN = 'feedbackCategory.addMain',
  EDIT_MAIN = 'feedbackCategory.editMain',
  ADD_SUB = 'feedbackCategory.addSub',
  EDIT_SUB = 'feedbackCategory.editSub',
}

type CategoryContentDialogProps = {
  open: boolean;
  mode: CategoryDialogMode;
  id?: string;
  mainCatRow?: FeedbackCategoryItem;
  subCatIndex?: number;
  onClose: () => void;
  onRefresh: () => void;
};

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 25,
    minWidth: 600,
  },
  header: {
    fontSize: '1.2rem',
    lineHeight: 1.2,
    marginBottom: 10,
  },
  fieldContainer: {
    width: 300,
    boxSizing: 'border-box',
  },
  noPaddingFieldContainer: {
    flexBasis: '20%',
    minWidth: 120,
    boxSizing: 'border-box',
  },
  footer: {
    marginTop: 20,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },
}));

type SubCategoryInternal = SubCategoryBody;

type CategoryFormState = {
  name: CategoryName;
  email?: string;
  responsibleParty?: string;
  subCategories?: SubCategoryInternal[];
};

const initialState: CategoryFormState = {
  name: {},
  email: undefined,
  responsibleParty: undefined,
  subCategories: undefined,
};

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof CategoryFormState;
    value: any;
  };
};

type ModifyLocaleFieldAction = {
  type: 'MODIFY_LOCALE_FIELD';
  payload: {
    field: keyof Pick<CategoryFormState, 'name'>;
    locale: string;
    value: any;
  };
};

type CategoryFormAction = ModifyFieldAction | ModifyLocaleFieldAction;

const categoryReducer = (state: CategoryFormState, action: CategoryFormAction): CategoryFormState => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    case 'MODIFY_LOCALE_FIELD':
      return {
        ...state,
        [action.payload.field]: {
          ...state[action.payload.field],
          [action.payload.locale]: action.payload.value,
        },
      };
  }
};

const propsToStateConvertor = (
  mode: CategoryDialogMode,
  mainCat?: FeedbackCategoryItem,
  subCatIndex?: number,
): CategoryFormState => {
  switch (mode) {
    case CategoryDialogMode.ADD_MAIN:
      return initialState;
    case CategoryDialogMode.EDIT_MAIN:
      return mainCat
        ? {
            name: mainCat.name,
            email: undefined,
            responsibleParty: undefined,
            subCategories: mainCat.subCategories.map((cat) => ({
              name: cat.name,
              emails: cat.emails,
              responsibleParty: cat.responsibleParty,
            })),
          }
        : initialState;
    case CategoryDialogMode.ADD_SUB:
      return initialState;
    case CategoryDialogMode.EDIT_SUB:
      return mainCat && subCatIndex !== undefined
        ? {
            name: mainCat.subCategories[subCatIndex].name,
            email: mainCat.subCategories[subCatIndex].emails,
            responsibleParty: mainCat.subCategories[subCatIndex].responsibleParty,
            subCategories: undefined,
          }
        : initialState;
  }
};

const CategoryContentDialog: FC<CategoryContentDialogProps> = ({
  open,
  mode,
  id,
  mainCatRow,
  subCatIndex,
  onClose,
  onRefresh,
}) => {
  const availableLocales = getAvailableServerLocale();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formState, formDispatch] = useReducer(categoryReducer, propsToStateConvertor(mode, mainCatRow, subCatIndex));

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler, immediateErrorValidator } = useErrorHandler(
    formState,
    [
      ...availableLocales.map((locale) => ({
        name: `name${locale}`,
        fieldType: ErrorFieldType.MANDATORY,
        condition: () => {
          let flag = false;
          if (!formState.name || !formState.name[locale]) {
            flag = true;
          }
          return flag;
        },
      })),
      {
        name: 'email',
        fieldType: ErrorFieldType.MANDATORY,
        condition: () => {
          return (mode === CategoryDialogMode.ADD_SUB || mode === CategoryDialogMode.EDIT_SUB) && !formState.email;
        },
      },
      {
        name: 'responsibleParty',
        fieldType: ErrorFieldType.MANDATORY,
        condition: () => {
          return (
            (mode === CategoryDialogMode.ADD_SUB || mode === CategoryDialogMode.EDIT_SUB) && !formState.responsibleParty
          );
        },
      },
      {
        name: 'emailFormat',
        fieldType: ErrorFieldType.IMMEDIATE,
        condition: () => {
          if (formState.email) {
            // const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            const re = new RegExp(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            );
            return !re.test(String(formState.email).toLowerCase());
          } else {
            return false;
          }
        },
      },
    ],
  );

  useEffect(() => {
    immediateErrorValidator();
    // eslint-disable-next-line
  }, [formState.email]);

  const onSubmit = async () => {
    let { hasError } = onSubmitErrorValidator();
    if (!hasError) {
      try {
        setIsLoading(true);
        switch (mode) {
          case CategoryDialogMode.ADD_MAIN:
            const newMainCat: FeedbackCategoryBody = {
              name: formState.name || {},
              subCategories: mainCatRow?.subCategories || [],
            };
            await createFeedbackCategory(newMainCat, dispatch);
            dispatch(
              appendAlertItem([
                {
                  severity: AlertType.SUCCESS,
                  title: 'Success',
                  content: `Feedback Category created successfully`,
                },
              ]),
            );
            setIsLoading(false);
            onRefresh();
            onClose();
            return;
          case CategoryDialogMode.EDIT_MAIN:
            const editMainCat: FeedbackCategoryBody = {
              name: formState.name || {},
              subCategories: mainCatRow?.subCategories || [],
            };
            await modifyFeedbackCategory(id || '', editMainCat, dispatch);
            dispatch(
              appendAlertItem([
                {
                  severity: AlertType.SUCCESS,
                  title: 'Success',
                  content: `Feedback Category modified successfully - ${id}`,
                },
              ]),
            );
            setIsLoading(false);
            onRefresh();
            onClose();
            return;
          case CategoryDialogMode.ADD_SUB:
            if (mainCatRow) {
              const newSubCat: FeedbackCategoryBody = {
                ...mainCatRow,
                subCategories: [
                  ...mainCatRow.subCategories,
                  {
                    name: formState.name || {},
                    emails: formState.email || '',
                    responsibleParty: formState.responsibleParty || '',
                  },
                ],
              };
              await modifyFeedbackCategory(id || '', newSubCat, dispatch);
              dispatch(
                appendAlertItem([
                  {
                    severity: AlertType.SUCCESS,
                    title: 'Success',
                    content: `Feedback Sub Category created successfully`,
                  },
                ]),
              );
              setIsLoading(false);
              onRefresh();
              onClose();
            }
            return;
          case CategoryDialogMode.EDIT_SUB:
            if (mainCatRow && subCatIndex !== undefined) {
              let subCats: SubCategoryBody[] = mainCatRow.subCategories.map((category) => ({
                name: category.name,
                responsibleParty: category.responsibleParty,
                emails: category.emails,
              }));
              subCats[subCatIndex] = {
                name: formState.name || {},
                emails: formState.email || '',
                responsibleParty: formState.responsibleParty || '',
              };
              const editSubCat: FeedbackCategoryBody = {
                ...mainCatRow,
                subCategories: subCats,
              };
              await modifyFeedbackCategory(id || '', editSubCat, dispatch);
              dispatch(
                appendAlertItem([
                  {
                    severity: AlertType.SUCCESS,
                    title: 'Success',
                    content: `Feedback Sub Category modified successfully`,
                  },
                ]),
              );
              setIsLoading(false);
              onRefresh();
              onClose();
            }
            return;
        }
      } catch (err) {
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Dialog open={open}>
      <div className={classes.container}>
        <div className={classes.header}>{Translation(mode)}</div>
        {mode === CategoryDialogMode.ADD_SUB || mode === CategoryDialogMode.EDIT_SUB ? (
          <>
            {availableLocales.map((locale) => (
              <div key={`subcat-name-${locale}`} className={classes.rowContainer}>
                <div className={classes.fieldContainer}>
                  <span className={classes.field}>
                    {Translation(`feedbackCategory.common.subName.${locale}`)}
                    <span className={classes.mandatory}>*</span> :
                  </span>
                </div>
                <TextField
                  error={errorState.mandatory[`name${locale}`]}
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  helperText={errorState.mandatory[`name${locale}`] && MANDATORY_FIELD_ERROR_TEXT}
                  value={formState.name[locale]}
                  onChange={(e) => {
                    onDismissErrorHandler(`name${locale}`, e.target.value);
                    formDispatch({
                      type: 'MODIFY_LOCALE_FIELD',
                      payload: { field: 'name', locale, value: e.target.value },
                    });
                  }}
                />
              </div>
            ))}

            <div className={classes.rowContainer}>
              <div className={classes.fieldContainer}>
                <span className={classes.field}>
                  {Translation('feedbackCategory.common.emails')}
                  <span className={classes.mandatory}>*</span> :
                </span>
              </div>
              <TextField
                error={errorState.mandatory.email || errorState.immediate.emailFormat}
                fullWidth
                margin="dense"
                variant="outlined"
                helperText={
                  errorState.mandatory.email
                    ? MANDATORY_FIELD_ERROR_TEXT
                    : errorState.immediate.emailFormat && EMAIL_FORMAT_ERROR_TEXT
                }
                value={formState.email}
                onChange={(e) => {
                  onDismissErrorHandler('email', e.target.value);
                  formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'email', value: e.target.value } });
                }}
              />
            </div>
            <div className={classes.rowContainer}>
              <div className={classes.fieldContainer}>
                <span className={classes.field}>
                  {Translation('feedbackCategory.common.responsibleParty')}
                  <span className={classes.mandatory}>*</span> :
                </span>
              </div>
              <TextField
                error={errorState.mandatory.responsibleParty}
                fullWidth
                margin="dense"
                variant="outlined"
                helperText={errorState.mandatory.responsibleParty && MANDATORY_FIELD_ERROR_TEXT}
                value={formState.responsibleParty}
                onChange={(e) => {
                  onDismissErrorHandler('responsibleParty', e.target.value);
                  formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'responsibleParty', value: e.target.value } });
                }}
              />
            </div>
          </>
        ) : (
          <>
            {availableLocales.map((locale) => (
              <div className={classes.rowContainer}>
                <div className={classes.fieldContainer}>
                  <span className={classes.field}>
                    {Translation(`feedbackCategory.common.mainName.${locale}`)}
                    <span className={classes.mandatory}>*</span> :
                  </span>
                </div>
                <TextField
                  error={errorState.mandatory[`name${locale}`]}
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  helperText={errorState.mandatory[`name${locale}`] && MANDATORY_FIELD_ERROR_TEXT}
                  value={formState.name[locale]}
                  onChange={(e) => {
                    onDismissErrorHandler(`name${locale}`, e.target.value);
                    formDispatch({
                      type: 'MODIFY_LOCALE_FIELD',
                      payload: { field: 'name', locale, value: e.target.value },
                    });
                  }}
                />
              </div>
            ))}
          </>
        )}
        <div className={classes.footer}>
          <Button disabled={isLoading} variant="contained" onClick={onClose}>
            {Translation('app.button.cancel')}
          </Button>
          <Button
            disabled={isLoading}
            style={{ marginLeft: 25 }}
            variant="contained"
            color="secondary"
            onClick={() => onSubmit()}
          >
            {Translation('app.button.submit')}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default CategoryContentDialog;
