import React, { FC, useState, useEffect, useMemo } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ApiErrorResponse } from 'src/app/common/network';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { LayoutSplashScreen } from 'src/app/layout';
import {
  CampaignFeedbackFormMode,
  CampaignFeedbackItem,
} from 'src/app/modules/PulseLeads/types/campaign-feedback-types';
import { fetchCampaignFeedbackItem } from 'src/app/modules/PulseLeads/network/campaignFeedbackCrud';
import CampaignFeedbackForm from './components/CampaignFeedbackForm';

const CampaignFeedbackDetailPage: FC<RouteComponentProps> = ({ history, location }) => {
  const { id } = useParams<{ id: string }>();
  const { formMode } = useMemo(
    () => ({
      formMode: id ? CampaignFeedbackFormMode.EDIT : CampaignFeedbackFormMode.CREATE,
    }),
    [id],
  );

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [campaignFeedbackItem, setCampaignFeedbackItem] = useState<CampaignFeedbackItem>();

  const reloadData = async () => {
    if (id) {
      setIsLoading(true);
      try {
        const res = await fetchCampaignFeedbackItem(id, dispatch);
        setCampaignFeedbackItem(res);
      } catch (err) {
        history.push(NOT_FOUND_ERROR_PAGE_PATH);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line
  }, [id]);

  return (
    <>
      {formMode === CampaignFeedbackFormMode.CREATE && <CampaignFeedbackForm formMode={formMode} />}
      {formMode === CampaignFeedbackFormMode.EDIT &&
        (isLoading || !campaignFeedbackItem ? (
          <LayoutSplashScreen />
        ) : (
          <CampaignFeedbackForm formMode={formMode} campaignFeedbackItem={campaignFeedbackItem} />
        ))}
    </>
  );
};

export default CampaignFeedbackDetailPage;
