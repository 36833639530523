import { Switch, styled } from '@mui/material';

// Copied from https://mui.com/material-ui/react-switch/
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#165DFF',
      },
    },
    '&.Mui-checked.MuiSwitch-colorPrimary': {
      '& + .MuiSwitch-track': {
        backgroundColor: '#E8192C',
      },
    },
    '&.Mui-disabled': {
      color: '#BDBDBD',
      '& + .MuiSwitch-track': {
        backgroundColor: '#000',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: '#C9CDD4',
    boxSizing: 'border-box',
  },
}));

export default AntSwitch;
