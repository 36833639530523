import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import PruRoute from 'src/app/common/components/PruRoute';
import { AGENCY_CAMPAIGN_BASE_PATH, AGENCY_CAMPAIGN_CAMPAIGN_TYPE_PATH } from '../../constants';
import ParamsProvider from 'src/app/common/components/ParamsProvider';
import CampaignTypeListingPage, { campaignTypeFilterKeys } from './List/CampaignTypeListingPage';
import CampaignTypeDetailPage from './Form/CampaignTypeDetailPage';
import CampaignTypeViewPage from './View/CampaignTypeViewPage';

export const campaignTypePath = `${AGENCY_CAMPAIGN_BASE_PATH}${AGENCY_CAMPAIGN_CAMPAIGN_TYPE_PATH}`;

const CampaignTypeRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect exact from={campaignTypePath} to={`${campaignTypePath}/list`} />
      <PruRoute exact path={`${campaignTypePath}/list`} render={(props) => (
        <ParamsProvider {...props} acceptKeys={campaignTypeFilterKeys}>
          <CampaignTypeListingPage />
        </ParamsProvider>
      )}/>
      <PruRoute exact path={`${campaignTypePath}/create`} component={CampaignTypeDetailPage}/>
      <PruRoute exact path={`${campaignTypePath}/edit/:id`} component={CampaignTypeDetailPage}/>
      <PruRoute exact path={`${campaignTypePath}/view/:id`} component={CampaignTypeViewPage}/>
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  )
};

export default CampaignTypeRoutes;