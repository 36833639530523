import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ApiErrorResponse } from 'src/app/common/network';

export const useUpdateEffect = (callback: () => any, deps: any) => {
  const ref = useRef<boolean | null>(null);

  useEffect(() => {
    if (!ref.current) {
      ref.current = true;
    } else {
      callback();
    }
    // eslint-disable-next-line
  }, deps);
};

export const useDataProvider = <T = any>(
  callback: () => Promise<T | void | undefined>,
  setData: (data: T | undefined) => void,
  clearValue: T | undefined,
) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<ApiErrorResponse | undefined>(undefined);

  const [refresh, setRefresh] = useState<boolean>(false);

  const [counter, setCounter] = useState<number>(0);

  const loadData = async () => {
    const timer = setInterval(() => {
      setCounter((seconds) => seconds + 1);
    }, 1000);
    setError(undefined);
    setIsLoading(true);
    setData(clearValue);
    try {
      const response = await callback();
      if (response) {
        setData(response);
      }
    } catch (e: any) {
      setError(e);
    }
    setIsLoading(false);
    clearInterval(timer);
    setCounter(0);
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    if (refresh) {
      loadData();
      setRefresh(false);
    }
    // eslint-disable-next-line
  }, [refresh]);

  return {
    isLoading,
    error,
    counter,
    refreshData: () => setRefresh(true),
  };
};
