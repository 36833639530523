import { apiClient, ADMIN_URL } from 'src/app/common/network';

const baseUrl = `${ADMIN_URL}/course-collections`;

export const getCategoryList = async (params?: any) => {
  return apiClient.get(`${baseUrl}/categories`, { params });
};

export const addCategory = async (data?: any) => {
  return apiClient.post(`${baseUrl}/categories`, data);
};

export const updateCategory = async (id: string, data?: any) => {
  return apiClient.put(`${baseUrl}/categories/${id}`, data);
};

export const weightValidation = async (params?: any, data?: any) => {
  return apiClient.get(`${baseUrl}/categories/weight/validate`, { params });
};
