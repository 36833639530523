import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import PruTable from '../../../../../../common/components/PruTable/PruTable';
import { useHistory } from 'react-router-dom';
import { getDefaultDisplayDate } from 'src/app/common/utils';
import { LEAD_MICROSITE_BASE_PATH, MICROSITE_BASE_PATH } from 'src/app/modules/lead-microsite/constants';
import { MicrositePaginateList } from 'src/app/modules/lead-microsite/types/microsite.type';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { Dialog } from '@mui/material';
import PreviewPage from './preview-page';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import { useDispatch } from 'react-redux';
import { useLang } from 'src/app/i18n';

type MicrositeListProps = {
  isLoading: boolean;
  list?: MicrositePaginateList;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
  onSort: (sortingState: { key: string; value?: string }) => void;
};

const CopyIcon = () => {
  return <FileCopyOutlinedIcon color="secondary" />;
};

const ENV_URL = window.envConfig['COMMON_PUBLIC_FRONTEND_ENDPOINT'];

const MicrositeList: FC<MicrositeListProps> = ({ isLoading, onRefresh, onChangePage, list, onSort }) => {
  const history = useHistory();
  const intl = useIntl();
  const [popUpVisible, setPopUpVisible] = useState<boolean>(false);
  const [previewId, setPreviewId] = useState<string>('');
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const lang = useLang();
  return (
    <div>
      <PruTable
        disableBulkSelect
        dataSource={list?.docs}
        isLoading={isLoading}
        onRefresh={onRefresh}
        operationSticky={true}
        title={Translation('microsite_list')}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('microsite_new'),
            onClick: () => history.push(`${LEAD_MICROSITE_BASE_PATH}${MICROSITE_BASE_PATH}/create`),
          },
        ]}
        operationDef={[
          {
            title: Translation('microsite_preview'),
            tooltipText: 'Preview',
            onClick: (row) => {
              setPreviewId(row._id), setPopUpVisible(true);
            },
          },
          {
            title: Translation('section.common.operation.edit'),
            tooltipText: 'Edit',
            onClick: (row) => history.push(`${LEAD_MICROSITE_BASE_PATH}${MICROSITE_BASE_PATH}/edit/${row._id}`),
          },
          {
            title: Translation('microsite_copy_and_new'),
            tooltipText: 'Create',
            onClick: (row) => history.push(`${LEAD_MICROSITE_BASE_PATH}${MICROSITE_BASE_PATH}/create?id=${row._id}`),
          },
          {
            title: Translation('section.common.operation.viewResults'),
            tooltipText: 'View Survey Results',
            onClick: (row) => history.push(`${LEAD_MICROSITE_BASE_PATH}${MICROSITE_BASE_PATH}/${row.refId}/results`),
          },
          {
            title: CopyIcon(),
            tooltipText: 'Obtain link',
            condition: (row) => {
              return row.status === 'active' ? true : false;
            },
            onClick: async (row) => {
              const text = `${ENV_URL}/web/form/${row.refId}/microsite`;
              if (window.isSecureContext) {
                await navigator.clipboard.writeText(text).then(() =>
                  dispatch(
                    appendAlertItem([
                      {
                        severity: AlertType.SUCCESS,
                        title: 'Copy',
                        content: Translation('microsite_copy_success'),
                      },
                    ]),
                  ),
                );
              }
            },
          },
        ]}
        columnDef={[
          {
            keyIndex: 'refId',
            displayName: Translation('microsite_id'),
            renderData: (row) => row?.refId,
          },
          {
            keyIndex: 'title',
            displayName: Translation('microsite_title'),
            renderData: (row) => row?.title?.[lang] || '',
          },
          {
            keyIndex: 'status',
            align: 'center',
            displayName: Translation('microsite_status'),
            renderData: (row) => String(row?.status),
          },
          {
            keyIndex: 'campaignId',
            align: 'center',
            displayName: Translation('microsite_campaign_id'),
            renderData: (row) => row?.campaignId,
          },
          {
            keyIndex: 'leadSource',
            align: 'center',
            displayName: Translation('microsite_lead_source'),
            renderData: (row) => row?.leadSource,
          },
          {
            keyIndex: 'leadSubSource',
            align: 'center',
            displayName: Translation('microsite_lead_sub_source'),
            renderData: (row) => row?.leadSubSource,
          },
          {
            keyIndex: 'campaignStatus',
            align: 'center',
            displayName: Translation('microsite_campaign_status'),
            renderData: (row) => row?.campaignStatus,
          },
          {
            keyIndex: 'createdAt',
            align: 'center',
            displayName: Translation('microsite_createdAt'),
            renderData: (row) => getDefaultDisplayDate(new Date(row?.createdAt), 'datetime'),
          },

          {
            keyIndex: 'updatedAt',
            align: 'center',
            displayName: Translation('microsite_updatedAt'),
            sortable: true,
            onSort: (sort) => {
              onSort({
                key: 'updatedAt',
                value: sort['updatedAt'],
              });
            },
            renderData: (row) => getDefaultDisplayDate(new Date(row?.updatedAt), 'datetime'),
          },
        ]}
        totalPages={list?.totalPages}
        totalRecords={list?.totalDocs}
        onChangePage={onChangePage}
      />

      <Dialog open={popUpVisible} onClose={() => setPopUpVisible(false)} PaperProps={{ style: { width: '414px' } }}>
        <PreviewPage id={previewId} onClose={setPopUpVisible} />
      </Dialog>
    </div>
  );
};

export default MicrositeList;
