import { Dispatch } from 'react';
import { forEach } from 'lodash';
import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import {
  CategoryPaginateList,
  CategoryItem,
  CategoryEditItem,
  CategoryPublishedMode,
  CategoryListParam,
} from '../types/category-types';

const qs = require('qs');

export const fetchCategoryList = async (
  params: CategoryListParam,
  sortKeys: { key: string; value?: string }[],
  dispatch?: Dispatch<any>,
): Promise<CategoryPaginateList> => {
  let queryPath = `${ADMIN_URL}/content-manager/collection-types/api::category.category?`;

  // Query for active status filter
  queryPath += filterQueryForPublish(params['isActive'], queryPath);

  // Query for corresponding module
  // queryPath += `module_eq=${params['module']}&`
  queryPath += `filters[module][$eq]=${params['module']}&`;
  if (params['page'] && params['pageSize']) {
    queryPath += `page=${params['page']}&pageSize=${params['pageSize']}&`;
  }

  //Query for sorting
  let sortingCount = 0;
  queryPath += `sort=`;
  forEach(sortKeys, (sortParam, key) => {
    if (sortParam.value) {
      queryPath += `${sortingCount === 0 ? `` : `,`}${encodeURIComponent(sortParam.key)}:${encodeURIComponent(
        sortParam.value,
      )}`;
      sortingCount++;
    }
  });
  if (sortingCount === 0) {
    queryPath += `name:ASC`;
  }

  // Query for current language
  // queryPath += `&_locale=${locale}`;

  return apiClient
    .get(queryPath)
    .then((response) => {
      return {
        categoryList: response.data.results,
        total: response.data.pagination.total,
        totalPages: response.data.pagination.pageCount,
        limit: response.data.pagination.pageSize,
        items: response.data.results,
        totalRecords: response.data.pagination.total,
        docs: response.data.results,
        hasNextPage: true,
        hasPrevPage: false,
        totalDocs: response.data.pagination.total,
      };
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchCategoryItem = (id: string, dispatch?: Dispatch<any>): Promise<CategoryItem> => {
  let queryPath = `${ADMIN_URL}/content-manager/api/categories/${id}?${qs.stringify({
    populate: {
      children: {
        populate: '*',
      },
      parent: {
        populate: '*',
      },
    },
  })}`;
  function transformChildData(data: any) {
    const item = data.attributes;
    item.id = data.id;
    if (item.children) {
      item.children = item.children.data;
      item.children = item.children.map((data: any) => {
        const item = transformChildData(data);
        if (item.parent) {
          item.parent = item.parent?.data?.id;
        }
        return item;
      });
    }

    return item;
  }

  return apiClient
    .get(queryPath, {
      headers: {
        'raw-path': true,
      },
    })
    .then((response) => {
      const item = transformChildData(response.data.data);
      if (item.parent?.data) {
        item.parent.data.attributes.id = item.parent.data.id;
        item.parent = item.parent.data.attributes;
      }

      return item;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createNewCategory = async (data: CategoryEditItem, dispatch?: Dispatch<any>): Promise<CategoryItem> => {
  let createURL = `${ADMIN_URL}/content-manager/collection-types/api::category.category`;
  return apiClient
    .post<CategoryItem>(createURL, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const modifyCategory = async (
  code: string,
  data: CategoryEditItem,
  dispatch?: Dispatch<any>,
): Promise<CategoryItem> => {
  const modifyURL = `${ADMIN_URL}/content-manager/collection-types/api::category.category/${code}`;
  return apiClient
    .put<CategoryItem>(modifyURL, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const publishCategory = async (dataId: string, dispatch?: Dispatch<any>): Promise<void> => {
  const publishURL = `${ADMIN_URL}/content-manager/collection-types/api::category.category/${dataId}/actions/publish`;
  return apiClient
    .post<void>(
      publishURL,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const unpublishCategory = async (dataId: string, dispatch?: Dispatch<any>): Promise<void> => {
  const unpublishURL = `${ADMIN_URL}/content-manager/collection-types/api::category.category/${dataId}/actions/unpublish`;
  return apiClient
    .post<void>(
      unpublishURL,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const deleteCategory = async (dataId: string, dispatch?: Dispatch<any>): Promise<void> => {
  const deleteURL = `${ADMIN_URL}/content-manager/collection-types/api::category.category/${dataId}`;
  return apiClient
    .delete<void>(deleteURL, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const filterQueryForPublish = (isActiveParam: string = '', queryPath: string): string => {
  let extendQuery: string = '';
  let query: any;

  if (isActiveParam === CategoryPublishedMode.ACTIVE) {
    // extendQuery = `_publicationState=live&`;
    query = qs.stringify({
      filters: {
        publishedAt: {
          $notNull: true,
        },
      },
    });
  } else if (isActiveParam === CategoryPublishedMode.INACTIVE) {
    // extendQuery = `_publicationState=preview&published_at_null=true&`;
    query = qs.stringify({
      filters: {
        publishedAt: {
          $null: true,
        },
      },
    });
  } else {
  }
  // return extendQuery;
  return query ? `${query}&` : '';
};
