import { FormInstance } from 'rc-field-form';
import { IntlShape } from 'react-intl';
import { I18nGenericData } from 'src/app/i18n';
import { CampaignTypeEnum } from '../../PulseLeads/types/campaign-types';

export type MicrositeListItem = {
  _id: string;
  refId: string;
  title: I18nGenericData<string>;
  status: string;
  campaignId: string;
  leadSource: string;
  leadSubSource: string;
  campaignStatus: string;
  createdAt: Date | string;
  updatedAt: Date | string;
};

export type CampaignListParam = {
  name: string;
  startDateFrom: Date | null;
  startDateTo: Date | null;
  endDateFrom: Date | null;
  endDateTo: Date | null;
  sort: string;
  status: string;
  type: CampaignTypeEnum;
  page: number;
  limit: number;
};
export interface PaginateResult<T> {
  docs: T[];
  totalDocs: number;
  limit: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  page?: number | undefined;
  totalPages: number;
  offset?: number;
  prevPage?: number | null | undefined;
  nextPage?: number | null | undefined;
  pagingCounter: number;
  meta?: any;
  [customLabel: string]: T[] | number | boolean | null | undefined;
}
export enum LeadMicrositeStatusEnum {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum LeadMicrositeContentTypeEnum {
  DEFAULT = 'default',
  CUSTOM = 'custom',
}

export type I18nData<T> = {
  [locale: string]: T;
};
export type ResourceData = {
  id: string;
  fileName: string;
  fileUrl: string;
};

export type MircrositeDetail = {
  status: LeadMicrositeStatusEnum;
  productType?: string;
  bannerType: LeadMicrositeContentTypeEnum;
  bannerData?: ResourceData;
  title: I18nData<string>;
  subTitle: I18nData<string>;
  body: I18nData<string>;
  customerDisclaimer: I18nData<string>[];
  callToActionText: I18nData<string>;
  submissionResponseTitle: I18nData<string>;
  submissionResponseContent: I18nData<string>;
  campainId: string;
  inputFieldsConfig: any;
  keepOriginActive?: boolean;
};
export class PaginationDtoItem {
  sort?: Record<string, string>;
  page?: number;
  offset?: number;
  limit?: number;
}

export type MicrositeQueryParams = {
  refId?: string;
  title?: string;
  pagination?: PaginationDtoItem;
};

export type MicrositePaginateList = PaginateResult<MicrositeListItem>;

export const activeType: any = (intl: IntlShape) => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return {
    Active: {
      value: 'active',
      text: Translation('profile.agent.common.status.active'),
    },
    Inactive: {
      value: 'inactive',
      text: Translation('profile.agent.common.status.inactive'),
    },
  };
};

export const productType: any = (intl: IntlShape) => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return {
    conventional: {
      value: 'conventional',
      text: 'Conventional',
    },
    sharia: {
      value: 'sharia',
      text: 'Sharia',
    },
  };
};

export type FormSectionProps = {
  form: FormInstance<any>;
  formMode: string;
};

export const bannerStatus: any = (intl: IntlShape) => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return {
    default: {
      value: 'default',
      text: Translation('default_label'),
    },
    custom: {
      value: 'custom',
      text: Translation('profile.agent.common.tierType.custom'),
    },
  };
};
