import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';

import { useDataProvider, getDayEnd, getDayStart } from 'src/app/common/utils';
import { fetchMassUploadStatusList, MassUploadStatusListParam } from 'src/app/modules/Profile/network/agentProfileCrud';
import { BatchUploadTypeEnum, MassUploadStatusPaginateList } from 'src/app/modules/Profile/types/agent-profile-types';

import MassUploadStatusList from './MassUploadStatusList';

type MassUploadStatusListingPageProps = ParamsProps;

const initialState: any = {
  fileName: '',
  uploadTypes: '',
  createdAtFrom: null,
  createdAtTo: null,
  uploadedAtFrom: null,
  uploadedAtTo: null,
  page: 1,
  limit: 5,
};

export const massUploadStatusFilterKeys = [
  'fileName',
  'uploadTypes',
  'uploadedAtFrom',
  'uploadedAtTo',
  'createdAtFrom',
  'createdAtTo',
];

const paramsInitiator = (initialParams?: Record<string, string>): any => {
  return initialParams
    ? {
        fileName: initialParams.fileName || '',
        uploadTypes: initialParams.uploadTypes || '',
        uploadedAtFrom: initialParams.uploadedAtFrom ? getDayStart(new Date(initialParams.uploadedAtFrom)) : null,
        uploadedAtTo: initialParams.uploadedAtTo ? getDayEnd(new Date(initialParams.uploadedAtTo)) : null,
        createdAtFrom: initialParams.createdAtFrom ? getDayStart(new Date(initialParams.createdAtFrom)) : null,
        createdAtTo: initialParams.createdAtTo ? getDayEnd(new Date(initialParams.createdAtTo)) : null,
        page: 1,
        limit: 5,
      }
    : initialState;
};
export const MassUploadStatusListingPage: FC<MassUploadStatusListingPageProps> = ({
  initialParams,
  onChangeQueryParams,
}) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  // const lang = getConfig().selectedLang;
  const [filterState, setFilterState] = useState<MassUploadStatusListParam>(paramsInitiator(initialParams));

  const [massUploadStatusList, setMassUploadStatusList] = useState<MassUploadStatusPaginateList>();
  const { isLoading, refreshData } = useDataProvider<MassUploadStatusPaginateList>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchMassUploadStatusList(filterState, dispatch);
      } catch (err) {}
    },
    setMassUploadStatusList,
    false,
  );

  return (
    <>
      <PruFilter
        title={Translation('mass_upload_filter')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'fileName',
            initialValue: filterState.fileName,
            displayName: Translation('agentProfile.ad_file_name_label'),
          },

          {
            type: PruFilterItemType.DROPDOWN,
            field: 'uploadTypes',
            initialValue: filterState.uploadTypes,
            displayName: Translation('agentProfile.ad_file_type_label'),
            choices: [
              { displayName: '', value: '' },
              ...Object.entries(BatchUploadTypeEnum).map(([key, value]) => ({
                displayName: Translation(`dropdown_label_${value}`),
                value: value,
              })),
            ],
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'uploadedAtFrom',
            fieldTo: 'uploadedAtTo',
            initialDateFrom: filterState.uploadedAtFrom,
            initialDateTo: filterState.uploadedAtTo,
            displayName: Translation('agentProfile.ad_uploaded_time_label'),
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            fileName: data.fileName,
            uploadTypes: data.uploadTypes,
            uploadedAtFrom: data.uploadedAtFrom,
            uploadedAtTo: data.uploadedAtTo,
          })
        }
        fetchData={refreshData}
      />

      <MassUploadStatusList
        isLoading={isLoading}
        massUploadList={massUploadStatusList}
        onRefresh={refreshData}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};
