import React, { FC, HTMLAttributes } from "react";
import ClearIcon from '@mui/icons-material/Clear';
import { makeStyles } from 'tss-react/mui';
import { Link, IconButton } from '@mui/material';

type LinkItemProps = {
  disabled?: boolean;
  name: string;
  onSelectItem: () => void;
  onDeleteItem: () => void;
}

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    marginRight: 8
  }
}));

const LinkItem: FC<LinkItemProps & HTMLAttributes<HTMLDivElement>> = ({ disabled, name, onSelectItem, onDeleteItem, ...rest }) => {
  const { classes } = useStyles();
  return (
    <div {...rest} className={classes.root}>
      <Link
        style={{ fontSize: 12 }}
        component="button"
        variant="body2"
        color="secondary"
        onClick={onSelectItem}
      >
        {name}
      </Link>
      <IconButton
        disabled={disabled}
        style={{ marginLeft: 4, padding: 1, color: '#3783e7', fontSize: '0.3rem' }}
        onClick={onDeleteItem}
      >
        <ClearIcon />
      </IconButton>
    </div>
  )
}

export default LinkItem;