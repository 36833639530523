import { makeStyles } from 'tss-react/mui';
import { Icon } from '@mui/material';
import React, { useCallback } from 'react';
import { toAbsoluteUrl } from 'src/app/common/utils';
import {
  toggleShowMobileBreadcrumbs,
  toggleShowMobileSideMenu,
  toggleShowUserInfoHeader,
} from 'src/redux/layout/layout-reducer';
import { useProjectDispatch } from 'src/redux/store';
import { MOBILE_HEADER_HEIGHT } from '../../const';

export default function HeaderMobile() {
  const { classes } = useStyles();
  const dispatch = useProjectDispatch();

  const onCLickNavIcon = useCallback(() => {
    dispatch(toggleShowMobileSideMenu());
  }, [dispatch]);

  const onCLickUserIcon = useCallback(() => {
    dispatch(toggleShowUserInfoHeader());
  }, [dispatch]);

  const onCLickBreadcrumbIcon = useCallback(() => {
    dispatch(toggleShowMobileBreadcrumbs());
  }, [dispatch]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.image}>
        <img className={classes.logo} alt="logo" src={toAbsoluteUrl('/media/pruforce-logo/pruforce_logo_color.png')} />
      </div>
      <div className={classes.iconContainer}>
        <Icon className={`fa fa-bars ${classes.icon}`} onClick={onCLickNavIcon} />
        {/* <Icon className={`fa fa-bars ${classes.icon}`} onClick={onCLickBreadcrumbIcon} /> */}
        <Icon className={`fa fa-user ${classes.icon}`} onClick={onCLickUserIcon} />
      </div>
    </div>
  );
}

const useStyles = makeStyles()((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#1a1a27',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    width: '100%',
    height: MOBILE_HEADER_HEIGHT,
    padding: '0 15px',
    zIndex: 66,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  image: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  logo: {
    height: '80%',
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  icon: {
    marginLeft: 12,
    cursor: 'pointer',
  },
}));
