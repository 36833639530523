import { utils, writeFile } from 'xlsx';
import moment from 'moment-timezone';
import { constants } from 'src/app/common/constants';
import { InsightMaterialReportResponseDtoItem } from './interface';

const META_TYPE = 'xlsx';
export const generateXlsx = (leftTime: moment.Moment, rightTime: moment.Moment, data: string[][], name: string) => {
  const workbook = utils.book_new();
  // Resource Library/Sales Material/Sales Library/Performance export excel
  const exp = [
    [
      'Time range',
      `${leftTime.format(constants.Default_Date_Format)}-${rightTime.format(constants.Default_Date_Format)}`,
    ],
    [],
    ['Agent code', 'Total share', 'Total view', 'Total stay time(Secs)'], // PCAAEB-21746
    ...data,
  ];
  const worksheet = utils.aoa_to_sheet(exp);

  utils.book_append_sheet(workbook, worksheet, 'Performance');

  const date = moment().format(constants.Date_Minute_Scale_Format);
  const fileName = `${name}_${date}.${META_TYPE}`;
  writeFile(workbook, fileName, { bookType: META_TYPE });
};

export function toXlsxData(data: InsightMaterialReportResponseDtoItem[]): string[][] {
  const res: string[][] = [];
  data.forEach((d) => {
    res.push([
      d?.agentCode ?? '',
      d?.totalProspects?.toString() ?? '',
      d?.totalViewCount?.toString() ?? '',
      d?.totalViewTime?.toString() ?? '',
    ]);
  });
  return res;
}
