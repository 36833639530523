import { makeStyles } from 'tss-react/mui';
import React, { useState, useEffect } from 'react';
import { ModalContainer } from './modal-container';
import { PruModalProps } from './modal-context';

export const ModalView = (props: PruModalProps) => {
  const { title, open, isTransparent, children, onClose, subModalProps, ...rest } = props;
  const [visible, setVisible] = useState(open);
  const [transparent, setTransparent] = useState(isTransparent ?? false);
  const [subModalVisible, setSubModalVisible] = useState(subModalProps?.subModalOpen);

  useEffect(() => {
    setVisible(Boolean(open || subModalProps?.subModalOpen));
    setSubModalVisible(subModalProps?.subModalOpen);
    setTransparent(!Boolean(open || subModalProps?.subModalOpen));
  }, [open, subModalProps?.subModalOpen]);

  useEffect(() => {
    setTransparent(isTransparent ?? false);
  }, [isTransparent, visible]);

  return (
    <>
      <ModalContainer
        {...rest}
        title={title}
        onClose={(event: any, reason: any) => {
          onClose?.(event, reason);
        }}
        visible={visible}
        transparent={transparent}
      >
        {(() => {
          if (children) {
            return React.cloneElement(children, {
              onClose: (event: {}, reason: string) => {
                if (!transparent) {
                  return onClose?.(event, reason as any);
                }
              },
            });
          }
        })()}
        <ModalContainer
          title={subModalProps?.title}
          onClose={() => {
            subModalProps?.onSubClose?.();
          }}
          transparent={false}
          visible={Boolean(subModalVisible)}
        >
          {(() => {
            if (subModalProps?.subModalChildren) {
              return React.cloneElement(subModalProps?.subModalChildren, {
                onClose: subModalProps?.onSubClose,
              });
            }
          })()}
        </ModalContainer>
      </ModalContainer>
    </>
  );
};

export const useStyles = makeStyles()((theme) => ({
  container: {
    position: 'absolute',
  },
}));
