import React from 'react';
import { regionLocale } from 'src/app/i18n';
import { Link } from 'react-router-dom';
import { leadProspectPath } from '../../ProspectCategoryRoutes';

const renderMutiLangColumns = (name: string, Translation: any) => {
  let namesColumns = regionLocale.map((code) => {
    const lang = Translation('navBar.lang.' + code);
    const nameColumn = {
      title: `${Translation(`${Translation(`component.formLabel.${name}`)}`)} (${lang})`,
      dataIndex: 'i18nDataList',
      key: 'i18n_name_' + code,
      hideInSearch: true,
      render: (_: any, row: any) => {
        const data = row[`${name}`];
        return <div style={{ width: 150 }}>{data[`${code}`] || ''}</div>;
      },
    };

    return nameColumn;
  });

  return namesColumns;
};

const columns = (Translation: any, classes: any): any => {
  const nameColumns = renderMutiLangColumns('name', Translation);
  const descriptionColumns = renderMutiLangColumns('description', Translation);

  const columns = [
    {
      title: Translation('title.pulseleads.prospect.category'),
      hideInSearch: true,
      dataIndex: 'type',
    },
    ...nameColumns,
    ...descriptionColumns,
    {
      title: Translation('form.pulseleads.prospect.catefory.default'),
      dataIndex: 'isDefault',
      hideInSearch: true,
      render: (data: any) => {
        return (
          <span>
            {data
              ? Translation('form.pulseleads.prospect.catefory.option.default')
              : Translation('form.pulseleads.prospect.catefory.option..non-default')}
          </span>
        );
      },
    },
    {
      title: Translation('form.pulseleads.prospect.catefory.target.number'),
      dataIndex: 'targetNumber',
      hideInSearch: true,
    },
    {
      title: `${Translation('form.pulseleads.prospect.catefory.target.target.frequency')}`,
      hideInSearch: true,
      dataIndex: 'targetFrequency',
    },
    {
      title: Translation('newsCategory.common.color'),
      dataIndex: 'colorLabel',
      hideInSearch: true,
      render: (data: any) => (
        <div className={classes.colorBox} style={{ background: `${data ? data : '#ffffff'}` }}></div>
      ),
    },
    {
      title: Translation('pulseleads.campaign.common.status'),
      hideInSearch: true,
      dataIndex: 'isActive',
      render: (data: any) => {
        return (
          <span>
            {data ? Translation('component.formSelectItem.active') : Translation('component.formSelectItem.inactive')}
          </span>
        );
      },
    },
    {
      title: Translation('component.formLabel.operation'),
      dataIndex: 'option',
      key: 'option',
      valueType: 'option',
      hideInSearch: true,
      sticky: true,
      render: (_: any, row: any) => {
        return (
          <Link to={`${leadProspectPath}/edit/${row._id}`}>
            <div className={`${classes.operationBtn}`} style={{ marginRight: 10 }}>
              {Translation('app.button.edit')}
            </div>
          </Link>
        );
      },
    },
  ];

  return [...columns];
};

export default columns;
