import { FC, useEffect } from 'react';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import './survey-preview.css';

const SurveyPreview: FC<any> = (data) => {
  const survey = new Model(data.data);
  survey.mode = 'display';
  return <Survey id={'previewSurvey'} model={survey} />;
};

export default SurveyPreview;
