import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import PruRoute from 'src/app/common/components/PruRoute';
import ParamsProvider from 'src/app/common/components/ParamsProvider';
import { PROFILE_BASE_PATH, PROFILE_MASS_UPLOAD_PATH, PROFILE_MASS_UPLOAD_STATUS_PATH } from '../../constants';
import { massUploadStatusFilterKeys, MassUploadStatusListingPage } from './List/MassUploadStatusListingPage';
import MassUploadFailDetailPage from './List/MassUploadFailDetailPage';

export const massUploadStatusListPath = `${PROFILE_BASE_PATH}${PROFILE_MASS_UPLOAD_PATH}${PROFILE_MASS_UPLOAD_STATUS_PATH}`;

const MassUploadStatusRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect exact from={massUploadStatusListPath} to={`${massUploadStatusListPath}/list`} />
      <PruRoute
        exact
        path={`${massUploadStatusListPath}/list`}
        render={(props) => (
          <ParamsProvider {...props} acceptKeys={massUploadStatusFilterKeys}>
            <MassUploadStatusListingPage />
          </ParamsProvider>
        )}
      />
      <PruRoute exact path={`${massUploadStatusListPath}/:id`} component={MassUploadFailDetailPage} />

      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default MassUploadStatusRoutes;
