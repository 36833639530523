import React, { FC, useState, useRef, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { CSVLink } from 'react-csv';
import { PaginateList } from 'src/app/common/types/common-types';
import { StagingLeadItem } from 'src/app/modules/PulseLeads/types/straight-through-types';
import {
  BatchSelectedLeadRawBody,
  distributeSelectedLeadRaw,
  exportSelectedLeadRaw,
} from 'src/app/modules/PulseLeads/network/stagingLeadCrud';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { getDefaultDisplayDate } from 'src/app/common/utils';

type StagingLeadListProps = {
  isLoading: boolean;
  stagingLeadList?: PaginateList<StagingLeadItem>;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
};

const StagingLeadList: FC<StagingLeadListProps> = ({ isLoading, stagingLeadList, onRefresh, onChangePage }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [csvData, setCsvData] = useState<string>();
  const csvInstance = useRef<any>(null);

  useEffect(() => {
    if (csvData && csvInstance && csvInstance.current && csvInstance.current.link) {
      setTimeout(() => {
        csvInstance.current.link.click();
        setCsvData(undefined);
      });
    }
  }, [csvData]);

  const onDistribute = async (rows: StagingLeadItem[]) => {
    const body: BatchSelectedLeadRawBody = {
      idList: rows.map((row) => row._id),
    };
    try {
      await distributeSelectedLeadRaw(body, dispatch);
    } catch (err) {}
  };

  const onExport = async (rows: StagingLeadItem[]) => {
    const body: BatchSelectedLeadRawBody = {
      idList: rows.map((row) => row._id),
    };
    try {
      const result = await exportSelectedLeadRaw(body, dispatch);
      setCsvData(result);
    } catch (err) {}
  };

  return (
    <>
      <PruTable
        title={Translation('pulseleads.campaign.lead.list')}
        operationDef={[]}
        bulkSelectDef={[
          {
            color: 'primary',
            title: 'Distribute',
            onClick: async (rows) => await onDistribute(rows),
          },
          {
            color: 'secondary',
            title: 'Export',
            onClick: async (rows) => await onExport(rows),
          },
        ]}
        columnDef={[
          {
            isId: true,
            hidden: true,
            keyIndex: '_id',
            displayName: '',
            renderData: (row) => '',
          },
          {
            keyIndex: 'firstName',
            displayName: Translation('pulseleads.campaign.lead.firstName'),
            renderData: (row) => row.firstName || '-',
          },
          {
            keyIndex: 'lastName',
            displayName: Translation('pulseleads.campaign.lead.lastName'),
            renderData: (row) => row.lastName || '-',
          },
          {
            keyIndex: 'campaignId',
            displayName: Translation('pulseleads.straightThrough.stagingLead.campaignId'),
            renderData: (row) => row.campaignId || '-',
          },
          {
            keyIndex: 'leadSource',
            displayName: Translation('pulseleads.campaign.common.source'),
            renderData: (row) => row.leadSource || '-',
          },
          {
            keyIndex: 'micrositeId',
            displayName: Translation('pulseleads.straightThrough.stagingLead.micrositeId'),
            renderData: (row) => row.micrositeId || '-',
          },
          {
            keyIndex: 'distributionStatus',
            displayName: Translation('pulseleads.straightThrough.stagingLead.distributionStatus'),
            renderData: (row) => row.distributionStatus || '-',
          },
          {
            keyIndex: 'leadDate',
            displayName: Translation('pulseleads.straightThrough.stagingLead.leadDate'),
            renderData: (row) => getDefaultDisplayDate(new Date(row.leadDate)) || '-',
          },
        ]}
        isLoading={isLoading}
        onRefresh={onRefresh}
        dataSource={stagingLeadList?.docs}
        totalPages={stagingLeadList?.totalPages}
        totalRecords={stagingLeadList?.totalDocs}
        onChangePage={onChangePage}
      />
      {csvData && <CSVLink ref={csvInstance} data={csvData} filename={'staging-lead.csv'} target="_blank" />}
    </>
  );
};

export default StagingLeadList;
