import { GlobalHelper } from '../../helpers/GlobalHelpers';

export interface ToastProps {
  className?: string;
  message: string;
}

export const PruToast = (payload: ToastProps) => {
  const { show, hide } = GlobalHelper.getGlobalToastRef();
  show &&
    show({
      message: payload.message,
      className: payload.className,
    });
  return { hide };
};
