import React, { FC } from "react";
import { RouteComponentProps, Switch, Redirect } from "react-router-dom";
import { SURVEY_BASE_PATH, SURVEY_MANAGEMENT_PATH } from "../../constants";
import { NOT_FOUND_ERROR_PAGE_PATH } from "../../../../common/constants";
import PruRoute from "../../../../common/components/PruRoute";
import ParamsProvider from "../../../../common/components/ParamsProvider";
import SurveyListingPage, { surveyFilterKeys } from "./List/SurveyListingPage";
import SurveyDetailPage from "./Create/SurveyDetailPage";
import SurveyAnalyticPage from "./Analytics/SurveyAnalyticPage";

const SurveyManagementPage: FC<RouteComponentProps> = ({ history }) => {
  return (
    <Switch>
      <Redirect exact from={`${SURVEY_BASE_PATH}${SURVEY_MANAGEMENT_PATH}`} to={`${SURVEY_BASE_PATH}${SURVEY_MANAGEMENT_PATH}/list`} />
      <PruRoute exact path={`${SURVEY_BASE_PATH}${SURVEY_MANAGEMENT_PATH}/list`} render={(props) => (
        <ParamsProvider {...props} acceptKeys={surveyFilterKeys}>
          <SurveyListingPage />
        </ParamsProvider>
      )}/>
      <PruRoute exact path={`${SURVEY_BASE_PATH}${SURVEY_MANAGEMENT_PATH}/edit/:id`} component={SurveyDetailPage}/>
      <PruRoute exact path={`${SURVEY_BASE_PATH}${SURVEY_MANAGEMENT_PATH}/create`} component={SurveyDetailPage}/>
      <PruRoute exact path={`${SURVEY_BASE_PATH}${SURVEY_MANAGEMENT_PATH}/:id/results`} component={SurveyAnalyticPage}/>
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  )
}

export default SurveyManagementPage;