import { RuleOperator } from "../enum/rule-enum"

export enum RuleType {
  PHONE_NUMBER = "phoneNumber",
  EMAIL = "email",
  CLIENT_CODE = "clientCode",
  HKID = "hkid",
  REASON = "reason"
}

export const ruleTitleMap = {
  [RuleType.PHONE_NUMBER]: "pulseleads.common.phoneNumber",
  [RuleType.EMAIL]: "pulseleads.common.email",
  [RuleType.CLIENT_CODE]: "pulseleads.common.clientCode",
  [RuleType.HKID]: "pulseleads.common.hkid",
  [RuleType.REASON]: "pulseleads.common.reason"
}

export const ruleOperatorTitleMap = {
  [RuleOperator.EQUAL]: "common.operator.equal",
  [RuleOperator.INCLUDE]: "common.operator.include"
}

export type RuleItem = {
  type: string;
  operator: string;
  value: string | string[];
}

export type RuleSetItem = {
  _id: string;
  name: string;
  items: RuleItem[];
  createdAt: Date;
  updatedAt: Date;
}