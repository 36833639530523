import { I18nGenericData } from 'src/app/i18n';
import {
  EventAccessibilityEnum,
  EventAudienceEnum,
  EventCategoryStatusEnum,
} from 'src/app/modules/event-v2/types';

export type EventCategoryFormState = {
  name: I18nGenericData<string>;
  description?: string;
  accessibility?: EventAccessibilityEnum;
  audience?: EventAudienceEnum;
  status: EventCategoryStatusEnum;
};

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof EventCategoryFormState;
    value: any;
  };
};

type SetFormStateAction = {
  type: 'SET_FORM_STATE';
  payload: {
    value: Partial<EventCategoryFormState>;
  };
};

type EventCategoryFormAction = ModifyFieldAction | SetFormStateAction;

export const formReducer = (state: EventCategoryFormState, action: EventCategoryFormAction): EventCategoryFormState => {
  switch (action.type) {
    case 'SET_FORM_STATE':
      return {
        ...state,
        ...action.payload.value,
      };
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    default:
      return state;
  }
};
