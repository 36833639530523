import React, { useState, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import Form from 'src/app/common/components/Form';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { useIntl } from 'react-intl';
import { useProfileStyles } from 'src/app/modules/Profile/profileStyle';
import { Button } from '@mui/material';
import { configAboutMePath } from '../../ConfigAboutMeRoutes';
import { AboutMeformMode, MultiLanguage } from 'src/app/modules/Profile/types/agent-profile-types';
import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import { AlertType, appendAlertItem } from 'src/redux/common/commonSlice';

import {
  createAboutMeTemplate,
  deleteAboutmeTemplate,
  modifyAboutMeTemplate,
} from 'src/app/modules/Profile/network/agentProfileCrud';
import { ErrorFieldType, useErrorHandler } from 'src/app/common/utils';
import ConfirmDialog from 'src/app/modules/PulseLeads/pages/Rule/ActivityPoint/PointRuleSettings/List/component/ComfirnDialog';
import { PruToast } from 'src/app/common/components/pru-toast';
import TextFieldWithLimit from 'src/app/common/components/TextFieldWithLimit';
import { regionLocaleMap } from 'src/app/i18n';
import { useLang } from 'src/app/i18n';

type AboutMeFormProps = {
  formMode: any;
  aboutMeDetail?: any;
};
enum ToolTip {
  SUBMIT = 'agentProfile.ad_set_default_message',
  CANCEL = 'agentProfile.ad_reminder_cancel_message',
  DELETE = 'agentProfile.ad_delete_message',
}
export type AboutMeFormState = {
  templateCode: string;
  content: MultiLanguage;
  isDefault: boolean;
};

export type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof AboutMeFormState;
    value: any;
  };
};
export type ModifyNestedAction = {
  type: 'MODIFY_CONTENT';
  payload: {
    field: keyof AboutMeFormState;
    subField: any;
    value: any;
  };
};
type DialogState = {
  open: boolean;
  id: string;
  method: string;
};
type AboutMeFormAction = ModifyFieldAction | ModifyNestedAction;
export const AboutMeinitialState: AboutMeFormState = {
  templateCode: '',
  content: { enUs: '', zhHk: '' },
  isDefault: false,
};
const AboutMeReducer = (
  state: AboutMeFormState,
  // state: CustomerSurveyFormState,
  action: AboutMeFormAction,
) => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    case 'MODIFY_CONTENT':
      state.content[action.payload.subField as keyof MultiLanguage] = action.payload.value;

      return {
        ...state,
      };
    default:
      return AboutMeinitialState;
  }
};
export type I18DataItem = {
  content?: string;
};
const detailToStateConvertor = (aboutMeDetail?: any): AboutMeFormState => {
  return aboutMeDetail
    ? {
        templateCode: aboutMeDetail.templateCode,
        content: aboutMeDetail.content,
        isDefault: aboutMeDetail.isDefault,
      }
    : AboutMeinitialState;
};
function AboutMeForm({ formMode, aboutMeDetail }: AboutMeFormProps) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { classes: commonClasses } = useCommonStyles();
  const { classes } = useProfileStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const TranslationWithVariable = (key: string, val: number | string) => intl.formatMessage({ id: key }, { lang: val });
  const title =
    formMode === AboutMeformMode.EDIT
      ? 'profile.agent.form.title.template.edit'
      : 'agentProfile.ad_add_introduction_header_title';
  const [formState, formDispatch] = useReducer(AboutMeReducer, detailToStateConvertor(aboutMeDetail));
  const langOrigin = useLang();

  const checkBoxLabel = Translation('local_language');

  const onSubmit = async () => {
    const { hasError } = onSubmitErrorValidator();

    if (hasError) return;
    const aboutMeBody: any = {
      templateCode: formState.templateCode,
      content: formState.content,
      isDefault: formState.isDefault,
    };

    if (formMode === AboutMeformMode.CREATE) {
      await createAboutMeTemplate(aboutMeBody, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `AboutMe saved successfully`,
          },
        ]),
      );
    } else if (aboutMeDetail && formMode === AboutMeformMode.EDIT) {
      await modifyAboutMeTemplate(aboutMeBody, aboutMeDetail.id, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `AboutMe updated successfully - ${aboutMeDetail.id}`,
          },
        ]),
      );
    }
    history.push(`${configAboutMePath}`);
  };

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler, immediateErrorValidator } = useErrorHandler(
    formState,
    [
      {
        name: 'templateCode',
        fieldType: ErrorFieldType.MANDATORY,
      },
      {
        name: 'content',
        fieldType: ErrorFieldType.MANDATORY,
        condition() {
          return !formState.content?.enUs ? true : false;
        },
      },
    ],
  );
  const [dialogState, setDialogState] = useState<DialogState>({
    open: false,
    id: '',
    method: '',
  });
  const [toolTip, setToolTip] = useState<string>();
  const onClose = () => {
    setDialogState({ open: false, id: '', method: '' });
    setToolTip('');
  };
  const cancel = () => {
    setToolTip(Translation(ToolTip.CANCEL));
    setDialogState({ open: true, id: '', method: 'cancel' });
  };
  const submit = () => {
    if (formState.isDefault) {
      setToolTip(Translation(ToolTip.SUBMIT));
      setDialogState({ open: true, id: '', method: 'submit' });
    } else {
      onSubmit();
    }
  };
  const onDelete = async (id: string) => {
    try {
      await deleteAboutmeTemplate(id, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Aboutme deleted successfully - ${id}`,
          },
        ]),
      );
      history.push(`${configAboutMePath}`);
    } catch (err) {}
  };
  const onConfirm = () => {
    if (dialogState.method === 'cancel') {
      PruToast({
        message: Translation('agentProfile.ad_cancelled_successfully_label'),
      });
      history.push(`${configAboutMePath}`);
    } else if (dialogState.method === 'submit') {
      onSubmit();
    } else if (dialogState.method === 'onDelete') {
      onDelete(aboutMeDetail.id);
    }
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <ConfirmDialog
            key={`delete-settings-rule-dialog-${dialogState.open}`}
            open={dialogState.open}
            onClose={() => onClose()}
            onRefresh={() => ''}
            onConfirm={async () => onConfirm()}
            title={Translation('agentProfile.ad_reminder_title')}
            content={toolTip}
          />
          <div className={classes.rowContainer}>
            <div className={commonClasses.header}>{Translation(title)}</div>
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          <div className={classes.sectionMargin} style={{ flex: 0.5 }}>
            <div className={classes.rowContainer}>
              <div className={classes.fieldContainer} style={{ minWidth: 160 }}>
                <span className={classes.field}>
                  {Translation('template_code_label')}
                  <span className={classes.mandatory}>*</span> :
                </span>
              </div>
              <div style={{}}>
                <TextField
                  disabled={formMode === AboutMeformMode.CREATE ? false : true}
                  style={{ width: 300 }}
                  margin="dense"
                  variant="outlined"
                  value={formState.templateCode}
                  error={errorState.mandatory.templateCode}
                  helperText={errorState.mandatory.templateCode && 'This field is mandatory'}
                  onChange={(e) => {
                    formDispatch({
                      type: 'MODIFY_FIELD',
                      payload: { field: 'templateCode', value: e.target.value },
                    });
                  }}
                />
              </div>
            </div>
            <div>
              <div className={classes.rowContainer} style={{}}>
                <div className={classes.fieldContainer} style={{ minWidth: 160 }}>
                  <span className={classes.field}>{Translation('agentProfile.ad_template_local_label')}:</span>
                </div>
                <div style={{ display: 'flex' }}>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <TextFieldWithLimit
                      style={{ width: 400 }}
                      limitHelpText={Translation('common.helper.text.characters')}
                      value={formState.content.zhHk}
                      limit={500}
                      onChange={(e) => {
                        formDispatch({
                          type: 'MODIFY_CONTENT',
                          payload: {
                            field: 'content',
                            subField: 'zhHk',
                            value: e.target.value,
                          },
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => {
                      if (e.target.checked) {
                        formDispatch({
                          type: 'MODIFY_CONTENT',
                          payload: {
                            field: 'content',
                            subField: 'enUs',
                            value: formState.content.zhHk,
                          },
                        });
                      }
                    }}
                    name="copyMainLangVersion"
                    color="secondary"
                  />
                }
                label={Translation('agentProfile.ad_use_local_option')}
              />
              <div className={classes.rowContainer}>
                <div className={classes.fieldContainer} style={{ minWidth: 160 }}>
                  <span className={classes.field}>
                    {Translation('agentProfile.ad_template_en_label')}
                    <span className={classes.mandatory}>*</span> :
                  </span>
                </div>
                <div style={{ flexGrow: 1 }}>
                  <TextFieldWithLimit
                    style={{ width: 400 }}
                    limitHelpText={Translation('agentProfile.ad_character_count_label')}
                    error={errorState.mandatory.content}
                    helperText={
                      errorState.mandatory.content ? Translation('agentProfile.ad_mandatory_field_reminder') : ''
                    }
                    value={formState.content.enUs}
                    limit={500}
                    onChange={(e) => {
                      formDispatch({
                        type: 'MODIFY_CONTENT',
                        payload: {
                          field: 'content',
                          subField: 'enUs',
                          value: e.target.value,
                        },
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className={classes.rowContainer}>
              <div className={classes.fieldContainer} style={{ minWidth: 160 }}>
                <span className={classes.field}>
                  <span className={classes.field}>{Translation('agentProfile.ad_set_default_label')} :</span>:
                </span>
              </div>
              <FormControlLabel
                label={''}
                control={
                  <Checkbox
                    checked={formState.isDefault === true}
                    color="secondary"
                    onChange={(e: any) => {
                      const target = e.target.checked;
                      if (target === true) {
                        formDispatch({
                          type: 'MODIFY_FIELD',
                          payload: { field: 'isDefault', value: true },
                        });
                      } else if (target === false) {
                        formDispatch({
                          type: 'MODIFY_FIELD',
                          payload: { field: 'isDefault', value: false },
                        });
                      }
                    }}
                  />
                }
              />
            </div>
          </div>
          <div
            style={{
              flex: 0.5,
              display: 'flex',
              alignItems: 'center',
              whiteSpace: 'pre-line',
              justifyContent: 'center',
            }}
          >
            {Translation('agentProfile.ad_add_introduction_instruction')}
          </div>
        </div>
        <div className={classes.centerFooterContainer}>
          <div
            style={{
              display: 'flex',
              boxSizing: 'border-box',
              minWidth: 350,
              justifyContent: 'space-around',
            }}
          >
            {aboutMeDetail?.id && (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  setToolTip(Translation(ToolTip.DELETE));
                  setDialogState({ open: true, id: '', method: 'onDelete' });
                }}
              >
                {Translation('app.button.delete')}
              </Button>
            )}
            <Button variant="contained" color="secondary" onClick={() => cancel()}>
              {Translation('app.button.cancel')}
            </Button>
            <Button variant="contained" color="secondary" onClick={() => submit()}>
              {formMode === AboutMeformMode.CREATE ? Translation('app.button.add') : Translation('app.button.save')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutMeForm;
