import React, { FC, useState, useEffect } from 'react';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { map } from 'lodash';
import { campaignPath } from '../../CampaignRoutes';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useDataProvider, getDayStart, getDayEnd } from 'src/app/common/utils';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { PaginateList } from 'src/app/common/types/common-types';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import { CampaignLeadItem } from 'src/app/modules/PulseLeads/types/campaign-types';
import {
  fetchCampaignLeadList,
  CampaignLeadListParam,
  exportCampaignLeadList,
} from 'src/app/modules/PulseLeads/network/campaignCrud';
import CampaignLeadList from './components/CampaignLeadList';
import { LeadStatusEnum } from 'src/app/modules/PulseLeads/types/campaign-types';
import { saveAs } from 'file-saver';

type CampaignLeadListingPageProps = {} & ParamsProps;

const initialState: CampaignLeadListParam = {
  firstName: '',
  lastName: '',
  // phoneNumber: "",
  agentCode: '',
  leadDateFrom: null,
  leadDateTo: null,
  status: '',
  isActive: '',
  page: 1,
  limit: 5,
};

const MAX_DIRECT_EXPORT = 2000;

export const campaignLeadFilterKeys = [
  'firstName',
  'lastName',
  'agentCode',
  'leadDateFrom',
  'leadDateTo',
  'status',
  'isActive',
];

const paramsInitiator = (initialParams?: Record<string, string>): CampaignLeadListParam => {
  return initialParams
    ? {
        firstName: initialParams.firstName || '',
        lastName: initialParams.lastName || '',
        agentCode: initialParams.agentCode || '',
        leadDateFrom: initialParams.leadDateFrom ? getDayStart(new Date(initialParams.leadDateFrom)) : null,
        leadDateTo: initialParams.leadDateTo ? getDayEnd(new Date(initialParams.leadDateTo)) : null,
        status: initialParams.status || '',
        isActive: initialParams.isActive || '',
        page: 1,
        limit: 5,
      }
    : initialState;
};

const CampaignLeadListingPage: FC<CampaignLeadListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const { id } = useParams<{ id: string }>();
  const intl = useIntl();
  const history = useHistory();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const [filterState, setFilterState] = useState<CampaignLeadListParam>(paramsInitiator(initialParams));
  const [campaignLeadList, setCampaignLeadList] = useState<PaginateList<CampaignLeadItem>>();
  const [sortKey, setSortKey] = useState<{ key: string; value?: string }[]>([]);

  const { isLoading, refreshData } = useDataProvider<PaginateList<CampaignLeadItem>>(
    async () => {
      if (id) {
        try {
          if (onChangeQueryParams) {
            onChangeQueryParams(filterState);
          }
          return await fetchCampaignLeadList(id, filterState, sortKey, dispatch);
        } catch (err) {
          history.push(NOT_FOUND_ERROR_PAGE_PATH);
        }
      }
    },
    setCampaignLeadList,
    false,
  );

  useEffect(() => {
    // Temp Fix for performance issue PCAAEB-10472
    dispatch(
      appendAlertItem([
        {
          severity: AlertType.WARNING,
          title: 'Reminder',
          content: `Please input as many filters as possible`,
        },
      ]),
    );
  }, []);

  useEffect(() => {
    if (sortKey.length > 0) {
      refreshData();
    }
  }, [sortKey]);

  const updateSortingKey = (sortingKey: { key: string; value?: string }) => {
    const sortingKeyArray = sortKey.filter((currentValue, index, arr) => {
      return currentValue.key !== sortingKey.key;
    });
    sortingKeyArray.unshift(sortingKey);
    setSortKey(sortingKeyArray);
  };

  const exportLeadCsv = () => {
    return new Promise((resolve, reject) => {
      const reachMaxDirectExport = campaignLeadList && campaignLeadList?.totalDocs > MAX_DIRECT_EXPORT;
      exportCampaignLeadList(id, filterState, sortKey, dispatch, reachMaxDirectExport)
        .then((response) => {
          if (response.status === 204) {
            resolve('success');
            return;
          }
          const contentDisposition = response.headers['content-disposition'];
          const filenameMatch = contentDisposition.match(/filename="(.+)"/);
          const filename = filenameMatch ? filenameMatch[1] : 'export.csv';
          const blob = new Blob([response.data], { type: 'text/csv' });
          saveAs(blob, filename);
          resolve('success');
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  return (
    <>
      <PruFilter
        title={Translation('pulseleads.campaign.lead.filter')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'firstName',
            initialValue: filterState.firstName,
            displayName: Translation('pulseleads.campaign.lead.firstName'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'lastName',
            initialValue: filterState.lastName,
            displayName: Translation('pulseleads.campaign.lead.lastName'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'agentCode',
            initialValue: filterState.agentCode,
            displayName: Translation('pulseleads.campaign.lead.agentCode'),
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'leadDateFrom',
            fieldTo: 'leadDateTo',
            initialDateFrom: filterState.leadDateFrom,
            initialDateTo: filterState.leadDateTo,
            displayName: Translation('pulseleads.campaign.lead.leadDate'),
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'status',
            initialValue: filterState.status,
            displayName: Translation('pulseleads.campaign.common.status'),
            choices: [
              { displayName: '', value: '' },
              ...map(LeadStatusEnum, (option: string) => ({
                displayName: Translation(`pulseleads.campaign.lead.${option}`),
                value: option,
              })),
            ],
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'isActive',
            initialValue: filterState.isActive,
            displayName: Translation('pulseleads.campaign.lead.isActive'),
            choices: [
              { displayName: '', value: '' },
              { displayName: 'True', value: 'true' },
              { displayName: 'False', value: 'false' },
            ],
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            firstName: data.firstName,
            lastName: data.lastName,
            agentCode: data.agentCode,
            leadDateFrom: data.leadDateFrom,
            leadDateTo: data.leadDateTo,
            status: data.status,
            isActive: data.isActive,
          })
        }
        fetchData={refreshData}
        enableBack
        onBack={() => history.push(`${campaignPath}/list`)}
      />
      <CampaignLeadList
        isLoading={isLoading}
        campaignLeadList={campaignLeadList}
        onRefresh={refreshData}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
        onSort={(dataKey) => updateSortingKey(dataKey)}
        onExport={exportLeadCsv}
      />
    </>
  );
};

export default CampaignLeadListingPage;
