export const PROFILE_BASE_PATH = '/profile';
export const PROFILE_CONFIG_PATH = '/config';
export const PROFILE_CONFIG_BANNER_PATH = '/banner-tier';
export const PROFILE_CONFIG_PROMOTIONS_PATH = '/promotions';
export const PROFILE_CONFIG_INTRODUCTION_PATH = '/introduction';
export const PROFILE_AGENT_PATH = '/agent';
export const PROFILE_AWARD_PATH = '/award';
export const PROFILE_AWARD_TYPE_PATH = '/award-type';
export const PROFILE_AGENT_AWARD_PATH = '/agent-award';
export const PROFILE_AGENT_SETTING_PATH = '/setting';
export const PROFILE_MASS_UPLOAD_PATH = '/mass-upload';
export const PROFILE_MASS_UPLOAD_STATUS_PATH = '/status';
export const PROFILE_BULK_UPLOAD_STATUS_PATH = '/bulkUpload-status';

export const PROFILE_TITLE = 'agentProfile.ad_agent_profile_site_header_title';
export const PROFILE_CONFIG_TITLE = 'agentProfile.ad_configurations_header_title';
export const PROFILE_BULK_UPLOAD_STATUS_TITLE = 'agentProfile.ad_agent_records_header_title';
export const PROFILE_AGENT_TITLE = 'agentProfile.ad_agents_header_title';
export const PROFILE_AGENT_SETTING_TITLE = 'agentProfile.ad_agent_settings_header_title';
export const PROFILE_AWARD_AGENT_TITLE = 'agentProfile.ad_agents_awards_header_title';
export const PROFILE_AWARD_TITLE = 'agentProfile.ad_awards_header_title';
export const PROFILE_MASS_UPLOAD_TITLE = 'agentProfile.ad_mass_upload_header_title';
export const PROFILE_MASS_UPLOAD_STATUS = 'agentProfile.ad_status_label';
export const PROFILE_AGENT_AWARD_TITLE = 'agentProfile.ad_agent_awards_header_title';
export const PROFILE_CONFIG_BANNER_TITLE = 'agentProfile.ad_banner_tier_header_title';
export const PROFILE_CONFIG_ABOUTME_TITLE = 'agentProfile.ad_introduction_label';
export const PROFILE_CONFIG_PROMOTIONS_TITLE = 'agentProfile.ad_sales_materials_header_title';
export const PROFILE_CONFIG_TIER_TITLE = 'agentProfile.ad_agent_tier_header_title';
export const PROFILE_CONFIG_INTRODUCTION_TITLE = 'agentProfile.ad_introduction_header_title';

export const PROFILE_CONFIG_NAMESPACE = 'PROFILE-CONFIG';
export const PROFILE_CONFIG_BANNER_NAMESPACE = 'PROFILE-CONFIG-BANNER-TIER';
export const PROFILE_CONFIG_PROMTOIONS_NAMESPACE = 'PROFILE-CONFIG-PROMOTIONS';
export const PROFILE_CONFIG_INTRODUCTION_NAMESPACE = 'PROFILE-CONFIG-INTRODUCTION';

export const PROFILE_MASS_UPLOAD_STATUS_NAMESPACE = 'PROFILE-MASS-UPLOAD-STATUS';

export const PROFILE_AWARD_AGENT_NAMESPACE = 'PROFILE-AGENT-AWARD';
export const PROFILE_AGENT_NAMESPACE = 'PROFILE-AGENT';
export const PROFILE_AWARD_NAMESPACE = 'PROFILE-AWARD';

export enum AdminProfilePermissionType {
  PROFILE_CONFIG_READ = 'READ_ANY_PROFILE-CONFIG',
  PROFILE_CONFIG_CREATE = 'CREATE_ANY_PROFILE-CONFIG',
  PROFILE_CONFIG_UPDATE = 'UPDATE_ANY_PROFILE-CONFIG',

  PROFILE_AGENT_READ = 'READ_ANY_PROFILE-AGENT',
  PROFILE_AGENT_CREATE = 'CREATE_ANY_PROFILE-AGENT',
  PROFILE_AGENT_UPDATE = 'UPDATE_ANY_PROFILE-AGENT',

  PROFILE_AWARD_READ = 'READ_ANY_PROFILE-AWARD',
  PROFILE_AWARD_CREATE = 'CREATE_ANY_PROFILE-AWARD',
  PROFILE_AWARD_UPDATE = 'UPDATE_ANY_PROFILE-AWARD',

  PROFILE_AGENT_AWARD_READ = 'READ_ANY_PROFILE-AGENT-AWARD',
  PROFILE_AGENT_AWARD_CREATE = 'CREATE_ANY_PROFILE-AGENT-AWARD',
  PROFILE_AGENT_AWARD_UPDATE = 'UPDATE_ANY_PROFILE-AGENT-AWARD',

  PROFILE_CONFIG_BANNER_TIER_READ = 'READ_ANY_PROFILE-CONFIG-BANNER-TIER',
  PROFILE_CONFIG_BANNER_TIER_CREATE = 'CREATE_ANY_PROFILE-CONFIG-BANNER-TIER',
  PROFILE_CONFIG_BANNER_TIER_UPDATE = 'UPDATE_ANY_PROFILE-CONFIG-BANNER-TIER',

  PROFILE_CONFIG_INTRODUCTION_READ = 'READ_ANY_PROFILE-CONFIG-INTRODUCTION',
  PROFILE_CONFIG_INTRODUCTION_CREATE = 'CREATE_ANY_PROFILE-CONFIG-INTRODUCTION',
  PROFILE_CONFIG_INTRODUCTION_UPDATE = 'UPDATE_ANY_PROFILE-CONFIG-INTRODUCTION',

  PROFILE_CONFIG_PROMTOIONS_READ = 'READ_ANY_PROFILE-CONFIG-PROMOTIONS',
  PROFILE_CONFIG_PROMTOIONS_CREATE = 'CREATE_ANY_PROFILE-CONFIG-PROMOTIONS',
  PROFILE_CONFIG_PROMTOIONS_UPDATE = 'UPDATE_ANY_PROFILE-CONFIG-PROMOTIONS',

  PROFILE_MASS_UPLOAD_STATUS_READ = 'READ_ANY_PROFILE-MASS-UPLOAD-STATUS',
  PROFILE_MASS_UPLOAD_STATUS_CREATE = 'CREATE_ANY_PROFILE-MASS-UPLOAD-STATUS',
  PROFILE_MASS_UPLOAD_STATUS_UPDATE = 'UPDATE_ANY_PROFILE-MASS-UPLOAD-STATUS',
}

export enum FormModeEnum {
  CREATE = 'add',
  EDIT = 'edit',
  COPY = 'copy',
  VIEW = 'view',
}
