import React, { CSSProperties, useState } from 'react';

import { useIntl } from 'react-intl';
import { Button, LinearProgress } from '@mui/material';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import { InsuranceSummaryStyles } from '../style/insuranceSummaryStyle';
import { InsuranceSummaryState } from 'src/app/modules/sales-kit/pages/insurance-summary/type/insurance-summary-types';
import { fileUpload, formatBytes } from 'src/app/common/utils';
import { useDispatch } from 'react-redux';
import { createBlob, getBlob } from 'src/app/common/network';
import CloseIcon from '@mui/icons-material/Close';
import { INSURANCE_SUMMARY_RESOURCE } from '../../../constants';
type BannerModuleProps = {
  title: string;
  theme: string;
  fun: (item: any) => void;
  subFun: (item?: string) => void;
  formState: InsuranceSummaryState;
};
type StylesObject = {
  [key: string]: CSSProperties;
};

const styles: StylesObject = {
  banner: {
    width: 160,
    height: undefined,
    aspectRatio: '2/1/1',
  },
  tipsText: {
    marginLeft: 14,
    fontSize: 14,
  },
  ml30: {
    marginLeft: 30,
  },
};

const BannerModule = (props: BannerModuleProps) => {
  const { title, theme, fun, subFun, formState } = props;
  const dispatch = useDispatch();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { classes } = InsuranceSummaryStyles();
  const [imageUploadProgress, setImageUploadProgress] = useState<number>();

  const handleFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];

      const TranslationWithVariable = (key: string) => intl.formatMessage({ id: key });
      const errMsg = TranslationWithVariable('global.max.file.size.allow');
      const size = formatBytes(file.size);

      const overSize = size.type !== 1 || size.value > 300;
      if (overSize) {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.ERROR,
              title: '',
              content: errMsg,
            },
          ]),
        );
        return;
      }

      try {
        const createBlobRes = await createBlob(
          {
            mimeType: file.type,
            accessLevel: 'public',
            module: INSURANCE_SUMMARY_RESOURCE,
            originalFilename: file.name,
          },
          dispatch,
        );

        await fileUpload(createBlobRes.url, file, setImageUploadProgress);
        const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);

        const result = blobDetail[0];

        if (result) {
          fun(result.url);
          subFun(result.blobId);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: `Upload file successfully - ${file.name}`,
              },
            ]),
          );
        }
      } finally {
        setImageUploadProgress(undefined);
      }
    }
  };
  return (
    <>
      <div className="tw-w-full tw-flex tw-mb-3 tw-mt-3">
        <div className={classes.fieldContainer}>
          <span className={classes.title}>{Translation(title)}</span>
        </div>

        <div style={styles.ml30}>
          {formState.bannerData && (
            <div className="tw-flex tw-flex-row tw-overflow-hidden">
              <img
                style={styles.banner}
                src={
                  formState.bannerData.startsWith('http')
                    ? formState.bannerData
                    : `data:image/jpeg;base64,${formState.bannerData}`
                }
                alt="App Icon"
              />
              <span>
                <CloseIcon
                  className="tw-ml-3"
                  onClick={() => {
                    fun('');
                    subFun(undefined);
                  }}
                />
              </span>
            </div>
          )}

          {!formState.bannerData && (
            <div className="tw-flex-grow">
              <input
                id="upload-isr-image"
                hidden
                type="file"
                accept="image/jpeg, image/png, image/jpg"
                onClick={(e) => {
                  const element = e.target as HTMLInputElement;
                  element.value = '';
                }}
                onChange={(e) => handleFile(e)}
              />

              <div className={classes.rowContainer}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => document.getElementById('upload-isr-image')!.click()}
                >
                  {Translation('app.button.chooseFile')}
                </Button>

                <div style={styles.tipsText}>{Translation('protection.review.create.banner')}</div>
              </div>

              {!!imageUploadProgress && (
                <LinearProgress
                  style={{ marginTop: 10 }}
                  variant="determinate"
                  color="secondary"
                  value={imageUploadProgress}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default BannerModule;
