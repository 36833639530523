import React, { FC, useState, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { map, result } from 'lodash';
import { useDispatch } from 'react-redux';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import { ApiErrorResponse } from 'src/app/common/network';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { LayoutSplashScreen } from 'src/app/layout';
import { makeStyles } from 'tss-react/mui';
import {
  CampaignTypeDropdownListParam,
  CreateCampaignTypeBody,
  UpdateCampaignTypeBody,
  fetchCampaignTypeItem,
  fetchCampaignTypeDropdownList,
  fetchEligibilityRuleOptionList,
  fetchApprovalRoleOptionList,
  createCampaignType,
  modifyCampaignType,
  getLatestTemplate,
  fetchResources,
} from 'src/app/modules/AgencyCampaign/network/campaignTypeCrud';
import {
  CampaignTypeFormMode,
  CampaignTypeItem,
  CampaignTypeDropdownEnum,
  CampaignTypeDropdownList,
  EligibilityRuleOption,
  ApprovalRoleOptionList,
} from 'src/app/modules/AgencyCampaign/types/campaign-type-types';
import { campaignTypePath } from '../CampaignTypeRoutes';
import ProgressBar from './components/ProgressBar';
import CancelDialog from './components/CancelDialog';
import CampaignTypeFormPageOne from './pages/PageOne/CampaignTypeFormPageOne';
import CampaignTypeFormPageTwo from './pages/PageTwo/CampaignTypeFormPageTwo';
import CampaignTypeFormPageThree from './pages/PageThree/CampaignTypeFormPageThree';
import CampaignTypeFormPageFour from './pages/PageFour/CampaignTypeFormPageFour';
import { difference, intersection } from 'lodash';
import { fetchLouTemplateList } from '../../../network/approvalCrud';
import { LouTemplateItem, ResourceItem } from '../../../types/approval-types';

export type CampaignTypeFormPageProps = {
  formMode: CampaignTypeFormMode;
  campaignTypeItem?: CampaignTypeItem;
  renderProgressBar: () => JSX.Element;
  onPrevPage?: () => void;
  onNextPage?: () => void;
  onCancel: () => void;
  onShowCancelDialog: () => void;
  onSubmit: (body: CreateCampaignTypeBody | UpdateCampaignTypeBody) => void;
};

const FIELD_CONTAINER_WIDTH = 250;

export const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,
  },
  headerContainer: {
    marginBottom: 25,
  },
  headerRowContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
  },
  fieldContainer: {
    width: FIELD_CONTAINER_WIDTH,
    boxSizing: 'border-box',
  },
  header: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    paddingBottom: 10,
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },
  sectionMargin: {
    marginBottom: 15,
  },
  textAreaRowContainer: {
    width: '100%',
    display: 'flex',
  },
  textAreaFieldContainer: {
    paddingTop: 15,
    minWidth: FIELD_CONTAINER_WIDTH,
    boxSizing: 'border-box',
  },
  textArea: {
    lineHeight: 1.5,
    minHeight: 40,
  },
  errorText: {
    fontSize: 9,
    color: '#F018A6',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dialogPaper: {
    width: 620,
    height: 600,
    maxWidth: 620,
    padding: 16,
    background: 'white',
    // overflowY: 'hidden',
  },
  closeBtn: {
    float: 'right',
    cursor: 'pointer',
  },
  leftCheckboxPadding: {
    marginLeft: -9,
  },
}));

const CampaignTypeDetailPage: FC<RouteComponentProps> = ({ history, location }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { id } = useParams<{ id: string }>();
  const { formMode } = useMemo(
    () => ({
      formMode: id ? CampaignTypeFormMode.EDIT : CampaignTypeFormMode.CREATE,
    }),
    [id],
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showCancelDialog, setShowCancelDialog] = useState<boolean>(false);
  const [campaignTypeItem, setCampaignTypeItem] = useState<CampaignTypeItem>();
  const [campaignTypeDropdownList, setCampaignTypeDropdownList] = useState<CampaignTypeDropdownList>();
  const [eligibilityRuleOptionList, setEligibilityRuleOptionList] = useState<EligibilityRuleOption[]>([]);
  const [approvalRoleOptionList, setApprovalRoleOptionList] = useState<ApprovalRoleOptionList>();
  const [louTemplateList, setLouTemplateList] = useState<LouTemplateItem[]>([]);
  const [resources, setResources] = useState<ResourceItem[]>([]);
  const [formPage, setFormPage] = useState<number>(1);
  const stepNameList = [
    Translation('agencyCampaign.campaignType.step.basicInformation'),
    Translation('agencyCampaign.campaignType.step.setUpTemplate'),
    Translation('agencyCampaign.campaignType.step.setUpWorkflow'),
    Translation('agencyCampaign.campaignType.step.publish'),
  ];

  const compareTemplate = (now: any[], latest: any[]) => {
    const nowKeyList = now.map((item) => item.key);
    const latestKeyList = latest.map((item) => item.key);
    let diffList,
      sameList,
      resultList: any[] = [];
    if (nowKeyList !== latestKeyList) {
      diffList = difference(latestKeyList, nowKeyList);
      sameList = intersection(latestKeyList, nowKeyList);
      sameList.map((item) => {
        const index = now.findIndex((section) => section.key === item);
        resultList.push(now[index]);
      });

      diffList.map((item) => {
        // update new sections
        const index = latest.findIndex((section) => section.key === item);
        resultList.push(latest[index]);
      });
      return resultList;
    } else return now;
  };

  const refreshLouTemplateList = () => {
    fetchLouTemplateList(dispatch)
      .then((result) => {
        setLouTemplateList(result);
      })
      .catch((err: ApiErrorResponse) => {
        history.push(NOT_FOUND_ERROR_PAGE_PATH);
      });
  };

  const reloadData = async () => {
    if (id) {
      setIsLoading(true);
      refreshLouTemplateList();
      const latestTemplate = await getLatestTemplate();
      fetchCampaignTypeItem(id, dispatch)
        .then((result) => {
          const newSection = compareTemplate(result?.sections, latestTemplate?.defaultSection);
          result = {
            ...result,
            fieldPool: latestTemplate?.defaultOption,
            sections: newSection,
          };
          setCampaignTypeItem(result);
        })
        .catch((err: ApiErrorResponse) => {
          history.push(NOT_FOUND_ERROR_PAGE_PATH);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const reloadDropdown = async () => {
    const params: CampaignTypeDropdownListParam = {
      type: map(CampaignTypeDropdownEnum),
    };
    const dropdownListRes = await fetchCampaignTypeDropdownList(params, dispatch);
    setCampaignTypeDropdownList(dropdownListRes);
  };

  const reloadEligibilityRuleOptionList = async () => {
    const res = await fetchEligibilityRuleOptionList(dispatch);
    setEligibilityRuleOptionList(res);
  };

  const reloadApprovalRoleOptionList = async () => {
    const res = await fetchApprovalRoleOptionList(dispatch);
    setApprovalRoleOptionList(res);
  };

  const reloadResources = async (id: string) => {
    if (id) {
      const res = await fetchResources(id, dispatch);
      setResources(res);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    reloadDropdown();
    reloadEligibilityRuleOptionList();
    reloadApprovalRoleOptionList();
    reloadResources(id);
    reloadData();
    setIsLoading(false);

    // eslint-disable-next-line
  }, [id]);

  const onPrevPage = () => {
    setFormPage(formPage - 1);
  };
  const onNextPage = () => {
    setFormPage(formPage + 1);
    if (formMode === CampaignTypeFormMode.EDIT) {
      reloadData();
    }
  };
  const onCancel = () => {
    history.push(campaignTypePath);
  };
  const onSubmit = async (body: CreateCampaignTypeBody | UpdateCampaignTypeBody) => {
    if (formMode === CampaignTypeFormMode.CREATE) {
      try {
        const response = await createCampaignType(body as CreateCampaignTypeBody, dispatch);
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: 'Success',
              content: 'Campaign Type saved successfully',
            },
          ]),
        );
        history.push(`${campaignTypePath}/edit/${response._id}`);
      } catch (err) {}
    } else if (campaignTypeItem) {
      try {
        await modifyCampaignType(campaignTypeItem._id, body, dispatch);
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: 'Success',
              content: `Campaign Type updated successfully`,
            },
          ]),
        );
      } catch (err) {}
    }
  };
  const renderProgressBar = () => (
    // <ProgressBar
    //   currentStep={formPage}
    //   stepNameList={stepNameList}
    // />
    <></>
  );

  const renderSwitch = () => {
    switch (formPage) {
      case 1:
        return (
          <CampaignTypeFormPageOne
            formMode={formMode}
            campaignTypeItem={campaignTypeItem}
            campaignTypeDropdownList={campaignTypeDropdownList}
            renderProgressBar={renderProgressBar}
            onNextPage={onNextPage}
            onCancel={onCancel}
            onShowCancelDialog={() => setShowCancelDialog(true)}
            onSubmit={onSubmit}
          />
        );
      case 2:
        return (
          <CampaignTypeFormPageTwo
            formMode={formMode}
            campaignTypeItem={campaignTypeItem}
            eligibilityRuleOptionList={eligibilityRuleOptionList}
            renderProgressBar={renderProgressBar}
            onPrevPage={onPrevPage}
            onNextPage={onNextPage}
            onCancel={onCancel}
            onShowCancelDialog={() => setShowCancelDialog(true)}
            onSubmit={onSubmit}
          />
        );
      case 3:
        return (
          <CampaignTypeFormPageThree
            formMode={formMode}
            campaignTypeItem={campaignTypeItem}
            resources={resources}
            approvalRoleOptionList={approvalRoleOptionList}
            louTemplateList={louTemplateList}
            renderProgressBar={renderProgressBar}
            onPrevPage={onPrevPage}
            onNextPage={onNextPage}
            onCancel={onCancel}
            onShowCancelDialog={() => setShowCancelDialog(true)}
            onSubmit={onSubmit}
          />
        );
      case 4:
        return (
          <CampaignTypeFormPageFour
            formMode={formMode}
            campaignTypeItem={campaignTypeItem}
            campaignTypeDropdownList={campaignTypeDropdownList}
            renderProgressBar={renderProgressBar}
            onPrevPage={onPrevPage}
            onCancel={onCancel}
            onShowCancelDialog={() => setShowCancelDialog(true)}
            onSubmit={onSubmit}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      {isLoading ? (
        <LayoutSplashScreen />
      ) : (
        <>
          {formMode === CampaignTypeFormMode.CREATE && renderSwitch()}
          {formMode === CampaignTypeFormMode.EDIT &&
            (isLoading || !campaignTypeItem ? <LayoutSplashScreen /> : renderSwitch())}
          <CancelDialog
            open={showCancelDialog}
            handleClose={() => setShowCancelDialog(false)}
            handleConfirm={onCancel}
          />
        </>
      )}
    </>
  );
};

export default CampaignTypeDetailPage;
