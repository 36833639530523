import { makeStyles } from 'tss-react/mui';
import { Fab, Icon, Theme } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';

export default function ScrollTop() {
  const { classes } = useStyles();
  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = useCallback(() => {
    if (!showScroll && window.scrollY > 200) {
      setShowScroll(true);
    } else if (showScroll && window.scrollY <= 200) {
      setShowScroll(false);
    }
  }, [showScroll]);

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    window.addEventListener('scroll', checkScrollTop);
    return () => {
      window.removeEventListener('scroll', checkScrollTop);
    };
  }, [checkScrollTop]);

  return showScroll ? (
    <Fab color="primary" size="small" onClick={scrollTop} className={classes.wrapper}>
      <Icon className={`fa fa-arrow-up ${classes.icon}`} />
    </Fab>
  ) : null;
}

const useStyles = makeStyles()((theme: Theme) => ({
  wrapper: {
    position: 'fixed',
    bottom: 50,
    right: 8,
    opacity: 0.3,
    backgroundColor: '#3699FF',
    '&:hover': {
      opacity: 1,
      backgroundColor: '#3699FF',
    },
  },
  icon: {
    color: '#ffffff',
    fontSize: 24,
  },
}));
