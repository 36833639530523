import { FC } from 'react';
import { useIntl } from 'react-intl';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { PDFTemplateItem, PaginateList } from '../../../../../types/pdf-template-type';
import { getDefaultDisplayDate } from '../../../../../../../common/utils';
import { upperFirst, lowerCase } from 'lodash';

type PDFTemplateListProps = {
  isLoading: boolean;
  templateList?: PaginateList<PDFTemplateItem>;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
  onSort: (newSortState: { key: string; value?: string }) => void;
  fetchPDFFile: (blobId: string) => void;
};

export const PDFTemplateList: FC<PDFTemplateListProps> = ({
  isLoading,
  templateList,
  onRefresh,
  onChangePage,
  onSort,
  fetchPDFFile,
}) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  return (
    <PruTable
      title={Translation('pdf.template.list')}
      disableBulkSelect
      operationDef={[
        {
          title: Translation('section.common.operation.download'),
          tooltipText: 'Download',
          onClick: (row) => fetchPDFFile(row.blobId),
        },
      ]}
      columnDef={[
        {
          isId: true,
          hidden: true,
          keyIndex: 'id',
          displayName: '',
          renderData: () => '',
        },
        {
          keyIndex: 'type',
          displayName: Translation('onboarding.common.templateType'),
          renderData: (row) => (row.templateType ? upperFirst(lowerCase(row.templateType)) : '-'),
        },
        {
          keyIndex: 'description',
          displayName: Translation('onboarding.common.description'),
          renderData: (row) => row.description || '-',
        },
        {
          keyIndex: 'version',
          displayName: Translation('onboarding.common.displayVersion'),
          renderData: (row) => row.version || '-',
        },
        {
          keyIndex: 'validDateFrom',
          displayName: Translation('onboarding.common.validDateFrom'),
          renderData: (row) => getDefaultDisplayDate(row.validDateFrom, 'datetime') || '-',
        },
        {
          keyIndex: 'validDateTo',
          displayName: Translation('onboarding.common.validDateTo'),
          renderData: (row) => getDefaultDisplayDate(row.validDateTo, 'datetime') || '-',
        },
        {
          keyIndex: 'uploadedBy',
          displayName: Translation('onboarding.common.uploadedBy'),

          renderData: (row) => row.uploadedBy || '-',
        },
        {
          keyIndex: 'uploadedAt',
          displayName: Translation('onboarding.common.uploadedAt'),
          renderData: (row) => getDefaultDisplayDate(row.uploadedAt, 'datetime') || '-',
          sortable: true,
          onSort: (sort) => {
            onSort({ key: 'UPLOADED_AT', value: sort['uploadedAt'] });
          },
        },
      ]}
      isLoading={isLoading}
      onRefresh={onRefresh}
      dataSource={templateList?.data || []}
      totalPages={templateList?.totalPages}
      totalRecords={templateList?.totalNumbers}
      onChangePage={onChangePage}
    />
  );
};
