import { IntlShape } from 'react-intl';
import { ModuleHierachy } from 'src/app/common/module/types';
import { isPermitted } from 'src/app/common/utils';
import {
  EVENT_TITLE,
  EVENT_LIST_TITLE,
  EVENT_CATEGORY_TITLE,
  EVENT_BASE_PATH,
  EVENT_LIST_PATH,
  EVENT_CATEGORY_PATH,
  EVENT_LIST_NAMESPACE,
  EVENT_CATEGORIES_NAMESPACE,
  AdminEventPermissionType,
} from './constants';
import EventListRoutes from './pages/event-list/event-list-routes';
import EventCategoryRoutes from './pages/event-category/event-category-routes';

export const eventModuleConfig = (permissions: string[], intl: IntlShape): ModuleHierachy => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return {
    title: `${Translation(EVENT_TITLE)} V2`,
    path: EVENT_BASE_PATH,
    icon: '/media/svg/icons/Home/Book-open.svg',
    enableRead: isPermitted(
      [
        AdminEventPermissionType.EVENT_LIST_READ,
        AdminEventPermissionType.EVENT_LIST_CREATE,
        AdminEventPermissionType.EVENT_LIST_UPDATE,
        AdminEventPermissionType.EVENT_CATEGORY_READ,
        AdminEventPermissionType.EVENT_CATEGORY_CREATE,
        AdminEventPermissionType.EVENT_CATEGORY_UPDATE,
      ],
      permissions,
    ),
    child: [
      {
        title: Translation(EVENT_LIST_TITLE),
        path: `${EVENT_BASE_PATH}${EVENT_LIST_PATH}`,
        namespace: EVENT_LIST_NAMESPACE,
        component: EventListRoutes,
        enableRead: isPermitted(
          [
            AdminEventPermissionType.EVENT_LIST_READ,
            AdminEventPermissionType.EVENT_LIST_CREATE,
            AdminEventPermissionType.EVENT_LIST_UPDATE,
          ],
          permissions,
        ),
        enableCreate: isPermitted([AdminEventPermissionType.EVENT_LIST_CREATE], permissions),
        enableUpdate: isPermitted([AdminEventPermissionType.EVENT_LIST_UPDATE], permissions),
      },
      {
        title: Translation(EVENT_CATEGORY_TITLE),
        path: `${EVENT_BASE_PATH}${EVENT_CATEGORY_PATH}`,
        namespace: EVENT_CATEGORIES_NAMESPACE,
        component: EventCategoryRoutes,
        enableRead: isPermitted(
          [
            AdminEventPermissionType.EVENT_CATEGORY_READ,
            AdminEventPermissionType.EVENT_CATEGORY_CREATE,
            AdminEventPermissionType.EVENT_CATEGORY_UPDATE,
          ],
          permissions,
        ),
        enableCreate: isPermitted([AdminEventPermissionType.EVENT_CATEGORY_CREATE], permissions),
        enableUpdate: isPermitted([AdminEventPermissionType.EVENT_CATEGORY_UPDATE], permissions),
      },
    ],
  };
};
