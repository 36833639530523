import React, { useEffect, useReducer, useState } from 'react';
import { useIntl } from 'react-intl';
import { makeStyles } from 'tss-react/mui';
import { Button, Table } from '@mui/material';
import {
  campaignSectionFieldItem,
  CampaignStructureItem,
  campaignStructureSection,
} from '../../../../types/staff-campaign-types';
import { useDispatch } from 'react-redux';
import AsyncCsvLink from '../../../../../../common/components/AsyncCsvLink';
import { downloadLeadsTemplateCsv } from '../../../../network/staffCampaignCrud';
import moment from 'moment';
import { useCommonStyles } from '../../../../../../common/styles/common-styles';
import { toAbsoluteUrl, isEmail, getDefaultDisplayDate } from 'src/app/common/utils';
import { appendAlertItem, AlertType } from '../../../../../../../redux/common/commonSlice';
import { AgencyCampaignTypeStatusEnum } from '../../../../constants';
import { isEmpty } from 'lodash';

const useStyles = makeStyles()((theme) => ({
  container: {
    width: 224,
    borderRight: '1px solid #F0F0F0',
    display: 'flex',
    flexDirection: 'column',
    marginRight: 5,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 20,
    flexDirection: 'row',
  },
  tableRow: {
    border: '1px solid #000000',
    padding: 4,
  },
  importFail: { marginTop: 20 },
  importFormat: { border: '1px solid #ccc', paddingTop: 10, paddingBottom: 10, borderRadius: 8, marginBottom: 20 },
  red: { color: '#E8192C' },
  // btn: {
  //   color: "white", width: '56px', height: 30, borderRadius: 12, margin: 10,
  //   fontFamily: 'Poppins, Helvetica, "sans-serif"'
  // }
}));

interface LeadProps {
  campaignTypeStructureData: CampaignStructureItem;
  campaignStatus: string | undefined;
  formDispatch: (data: any) => void;
  formState: any;
  sectionKey: string;
  onLeadUploadList: (data: any) => void;
  onLeadFileUpload: (data: any) => void;
  campaignId?: string;
}

type ImportItem = {
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  euResident: string;
  countryCode: string;
  // pdpaConsent: string;
  referredAgentCode: string;
  leadDate: string;
};

const Lead: React.FC<LeadProps> = ({
  sectionKey,
  campaignTypeStructureData,
  formDispatch,
  formState,
  onLeadUploadList,
  onLeadFileUpload,
  campaignId,
  campaignStatus = '',
}) => {
  const { classes } = useStyles();
  const { classes: commonStyle } = useCommonStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const Translation = (id: string) => intl.formatMessage({ id });
  const TranslationWithVariable = (key: string, count: number | string) =>
    intl.formatMessage({ id: key }, { num: count });
  const [uploadLeadProgress, setUploadLeadProgress] = useState<number>();
  const [importFile, setImportFile] = useState<File>();
  const [importData, setImportData] = useState<any[]>();
  const [invalidField, setInvalidField] = useState<string[]>([]);

  const validateField = (rowData: ImportItem): string => {
    let errMsg = '';
    // const { firstName, lastName, email, mobile, euResident, countryCode } = rowData;
    const { firstName, lastName, email, mobile, euResident, countryCode, referredAgentCode, leadDate } = rowData;

    if (isEmpty(firstName) || isEmpty(lastName)) {
      errMsg = Translation('common.emptyField');
      return errMsg;
    }
    // both Email and Mobile are empty
    if (isEmpty(email) && isEmpty(mobile)) {
      errMsg = Translation('common.EitherMobileOrPhoneField');
      return errMsg;
    } else {
      // one of email or phone is mandatory
      if (!isEmpty(email) && !isEmail(email)) {
        errMsg += Translation('common.invalidEmail');
      }
      const regPhone = new RegExp(/^\d{8}$/);
      if (!isEmpty(mobile) && !regPhone.test(mobile)) {
        errMsg += Translation('common.invalidPhone');
      }

      if (!isEmpty(mobile) && isEmpty(countryCode)) {
        errMsg += Translation('common.MobileAndCountryField');
      }

      if (!isEmpty(countryCode) && !isEmpty(mobile)) {
        const regCountryCode1 = new RegExp(/^\d{2,3}$/); // 2-3 digit
        const fixedCountryCode = '65_SGP';
        if (countryCode !== fixedCountryCode && !regCountryCode1.test(countryCode)) {
          errMsg += Translation('common.invalidCountryCode');
        }
      }
    }
    // accept: alphabet, space, /, -, @, (, )
    const regName = new RegExp(/^([a-z ]|\/|-|@|\(|\))+$/, 'i');
    if (!regName.test(firstName) || !regName.test(lastName)) {
      errMsg += Translation('common.invalidName');
    }

    const arrBool = ['YES', 'NO'];
    if (!arrBool.includes(euResident)) {
      errMsg += Translation('common.EuResidentYesorNoField');
    }

    if (leadDate && !moment(leadDate, 'DD/MM/YYYY', true).isValid()) {
      errMsg += Translation('common.invalidDate');
    }

    return errMsg;
  };
  const uploadError = (content: string) => {
    removeFile();
    const title = Translation('common.uploadFail');
    dispatch(
      appendAlertItem([
        {
          severity: AlertType.ERROR,
          title: title,
          content: content,
        },
      ]),
    );
  };
  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      // The file type is different when open in VDI, therefore taking out the file type checking
      // not seeing above issue???
      if (e.target.files[0].type.indexOf('csv') < 0) {
        uploadError(Translation('agencyCampaign.lead.fileNotSupported'));
        return;
      }

      let reader = new FileReader();
      reader.onload = () => {
        let csvData = String(reader.result);
        let row = csvData.split('\n');

        // incorrect file format will have too many rows, don't even need to read data in this case.
        if (row.length > 300) {
          uploadError(Translation('agencyCampaign.lead.maxRecord'));
          return;
        }
        let leadList: any[] = [];
        let arrInvalidField: string[] = [];
        row.shift();
        row.forEach((item, rowIndex) => {
          if (!item) {
            return;
          }
          // firstName	lastName	email	phoneNumber	euResident	pdpaConsent	countryCode	referredAgentCode	leadDate
          let [
            firstName,
            lastName,
            email,
            mobile,
            euResident,
            // pdpaConsent,
            countryCode,
            referredAgentCode,
            leadDate,
          ] = item.split(',');
          let leadItem = {
            firstName: firstName == null || firstName == undefined || firstName == '' ? '' : String(firstName)?.trim(),
            lastName: lastName == null || lastName == undefined || lastName == '' ? '' : String(lastName)?.trim(),
            email: email == null || email == undefined || email == '' ? '' : String(email)?.trim(),
            mobile: mobile == null || mobile == undefined || mobile == '' ? '' : String(mobile)?.trim(),
            euResident:
              euResident == null || euResident == undefined || euResident == ''
                ? ''
                : String(euResident)?.toLocaleUpperCase().trim(),
            countryCode:
              countryCode == null || countryCode == undefined || countryCode == '' ? '' : String(countryCode)?.trim(),
            // pdpaConsent: pdpaConsent?.trim(),
            referredAgentCode: referredAgentCode?.trim(),
            leadDate: leadDate?.trim(),
          };
          const errMsg = validateField(leadItem);
          if (errMsg) {
            let row = TranslationWithVariable('agencyCampaign.import.row', rowIndex + 1);
            arrInvalidField.push(row + errMsg);
          }
          leadList.push(leadItem);

          // if (rowItems[0] && rowItems[1] && rowItems[2] && rowItems[3] && rowItems[4] && rowItems[5] &&
          //   rowItems[0].trim() && rowItems[1].trim() && rowItems[2].trim() && rowItems[3].trim() && rowItems[4].trim() && rowItems[5].trim()) {
          //   let leadItem = {
          //     firstName: rowItems[0],
          //     lastName: rowItems[1],
          //     email: rowItems[2],
          //     mobile: rowItems[3],
          //     euResident: rowItems[4],
          //     countryCode: rowItems[5]
          //   }
          //   leadList.push(leadItem);
          // }
        });

        if (leadList.length > 200) {
          uploadError(Translation('agencyCampaign.lead.maxRecord'));
          return;
        }

        if (leadList.length === 0) {
          uploadError(Translation('agencyCampaign.lead.noRecord'));
          return;
        }

        if (arrInvalidField.length > 0) {
          return setInvalidField(arrInvalidField);
        }

        setImportData(leadList);
        onLeadUploadList(leadList);
      };
      if (e.target.files) {
        reader.readAsBinaryString(e.target.files[0]);
        setImportFile(e.target.files[0]);
        onLeadFileUpload(e.target.files[0]);
      }
    }
  };

  const removeFile = () => {
    setImportData(undefined);
    setImportFile(undefined);
    setInvalidField([]);
    onLeadFileUpload(undefined);
  };

  const _renderLeadTable = () => {
    if (invalidField.length > 0) {
      return (
        <div className={classes.importFail}>
          <p className={classes.red}>{Translation('agencyCampaign.import.failed')}</p>
          <ul className={classes.importFormat}>
            {invalidField.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>
      );
    }
    return (
      <div className={classes.importFail}>
        <Table className={classes.tableRow}>
          <thead>
            <tr>
              <th className={classes.tableRow}>{`First Name`}</th>
              <th className={classes.tableRow}>{`Last Name`}</th>
              <th className={classes.tableRow}>{`Email`}</th>
              <th className={classes.tableRow}>{`Phone Number`}</th>
              <th className={classes.tableRow}>{`EU Resident`}</th>
              {/*<th className={classes.tableRow}>{`PDPA Consent`}</th>*/}
              <th className={classes.tableRow}>{`Country Code`}</th>
              {/*<th className={classes.tableRow}>{`Referred Agent Code`}</th>*/}
              {/*<th className={classes.tableRow}>{`Lead Date`}</th>*/}
            </tr>
          </thead>
          <tbody>
            {importData &&
              importData.map((rowItem, index) => {
                return (
                  <tr key={index}>
                    <td className={classes.tableRow}>{rowItem.firstName}</td>
                    <td className={classes.tableRow}>{rowItem.lastName}</td>
                    <td className={classes.tableRow}>{rowItem.email}</td>
                    <td className={classes.tableRow}>{rowItem.mobile}</td>
                    <td className={classes.tableRow}>{rowItem.euResident}</td>
                    {/*<td className={classes.tableRow}>{rowItem.pdpaConsent}</td>*/}
                    <td className={classes.tableRow}>{rowItem.countryCode}</td>
                    {/*<td className={classes.tableRow}>{rowItem.referredAgentCode}</td>*/}
                    {/*<td className={classes.tableRow}>{rowItem.leadDate}</td>*/}
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
    );
  };

  const _renderUploadedFileDetail = () => {
    return (
      <>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
            paddingLeft: 20,
            paddingRight: 20,
            height: 50,
            marginBottom: 8,
            fontWeight: 600,
            alignItems: 'center',
          }}
        >
          {`Uploaded File`}
        </div>
        <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            background: '#FFFFFF',
            padding: 20,
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '30px' }}>
            {`File Name: ${importFile?.name}`}
            <div onClick={removeFile}>
              <img
                src={toAbsoluteUrl('/media/icon/bin.png')}
                alt={'Select from list'}
                style={{ width: 20, height: 20, margin: 10 }}
              />
            </div>
          </div>
          <div
            style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '30px' }}
          >{`File Type: ${importFile?.type}`}</div>
          <div
            style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '30px' }}
          >{`File Size: ${importFile?.size}`}</div>
          <div>{`File Last Modified Time: ${
            importFile?.lastModified
              ? getDefaultDisplayDate(new Date(importFile?.lastModified))
              : getDefaultDisplayDate(new Date())
          }`}</div>
          {_renderLeadTable()}
        </div>
      </>
    );
  };

  const btnDisabled =
    !campaignId ||
    importFile !== undefined ||
    [AgencyCampaignTypeStatusEnum.CANCELED + '', AgencyCampaignTypeStatusEnum.DRAFT + ''].includes(campaignStatus);

  // const bgColor = btnDisabled ? { backgroundColor: '#ccc' } : { backgroundColor: '#E8192C' };
  return (
    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'space-between',
          paddingLeft: 20,
          paddingRight: 20,
          height: 50,
          marginBottom: 8,
          fontWeight: 600,
          alignItems: 'center',
        }}
      >
        {`Lead Upload Wizard`}
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', background: '#FFFFFF', padding: 20, height: 150 }}>
        <div>Leads Template File</div>
        <div style={{ marginTop: 20 }}>
          {(window as any).ReactNativeWebView ? (
            <div
              style={{ textDecoration: 'underline' }}
              onClick={() => {
                window.location.href =
                  'about:blob:https://iyirilebriefcase3.azureedge.net/public/62318af4b8e1994249ed3124.csv';
              }}
            >{`Download Leads Template`}</div>
          ) : (
            <AsyncCsvLink
              isDisabled={false}
              filename={`LeadUpload.csv`}
              dataParser={(str) => str}
              asyncCall={() => downloadLeadsTemplateCsv()}
            >
              <div style={{ textDecoration: 'underline' }}>
                <a className={commonStyle.link}>{`Download Leads Template`}</a>
              </div>
            </AsyncCsvLink>
          )}
        </div>

        <input
          id="upload-lead-participant-list-csv"
          hidden
          type="file"
          accept=".csv"
          onClick={(e) => {
            const element = e.target as HTMLInputElement;
            element.value = '';
          }}
          onChange={handleFile}
        />
        <div className={classes.rowContainer}>
          <Button
            variant="contained"
            color="secondary"
            disabled={btnDisabled}
            // className={classes.btn}
            // style={bgColor}
            onClick={() => document.getElementById('upload-lead-participant-list-csv')!.click()}
          >
            {Translation('app.button.upload')}
          </Button>
        </div>
      </div>

      {importFile && _renderUploadedFileDetail()}
    </div>
  );
};

export default Lead;
