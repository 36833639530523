import React from 'react';

const columns = (Translation: any, keyword: string) => {
  const columns = [
    {
      title: 'Row Number',
      dataIndex: 'rowNumber',
      hideInSearch: true,
    },
    {
      title: 'Failure Reason',
      dataIndex: 'reason',
      hideInSearch: true,
    },
  ];

  return [...columns];
};

export default columns;
