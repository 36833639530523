export const commonStyles = {
  targetArea: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  colorRed: {
    color: 'red',
  },
  ml70: {
    marginLeft: 70,
  },
  uploadArea: {
    marginLeft: 10,
    width: '100%',
  },
  ml140: {
    marginLeft: 140,
  },
  ml20: {
    marginLeft: 20,
  },
  errorMsg: {
    marginLeft: 140,
    marginTop: 10,
    color: 'red',
  },
  ml120: {
    marginLeft: 120,
  },
  tableBg: {
    marginLeft: 20,
    marginTop: 10,
    marginRight: 50,
  },
  upload: {
    flexGrow: 1,
    margin: 10,
    paddingLeft: 130,
  },
};
