import React, { FC } from "react";
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import PruRoute from 'src/app/common/components/PruRoute';
import { PULSELEADS_BASE_PATH } from '../../../constants';
import ParamsProvider from 'src/app/common/components/ParamsProvider';
import { PULSELEADS_AGENT_PATH, PULSELEADS_AGENT_LEAD_SOURCE_PATH } from '../constants';
import AgentLeadSourceListingPage, { agentLeadSourceFilterKeys } from './List/AgentLeadSourceListingPage';
import AgentLeadSourceDetailPage from './Form/AgentLeadSourceDetailPage';

export const agentLeadSourcePath = `${PULSELEADS_BASE_PATH}${PULSELEADS_AGENT_PATH}${PULSELEADS_AGENT_LEAD_SOURCE_PATH}`;

const AgentLeadSourceRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect exact from={agentLeadSourcePath} to={`${agentLeadSourcePath}/list`} />
      <PruRoute exact path={`${agentLeadSourcePath}/list`} render={(props) => (
        <ParamsProvider {...props} acceptKeys={agentLeadSourceFilterKeys}>
          <AgentLeadSourceListingPage />
        </ParamsProvider>
      )}/>
      <PruRoute exact path={`${agentLeadSourcePath}/edit/:id`} component={AgentLeadSourceDetailPage}/>
      <PruRoute exact path={`${agentLeadSourcePath}/create`} component={AgentLeadSourceDetailPage}/>
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default AgentLeadSourceRoutes;