import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { PaginateList } from 'src/app/common/types/common-types';
import { useDataProvider } from 'src/app/common/utils';

import { ConfigBannerTierListParam, fetchConfigBannerTierList } from 'src/app/modules/Profile/network/agentProfileCrud';
import { ConfigBannerTierListItem, isDefault } from 'src/app/modules/Profile/types/agent-profile-types';

import ConfigBannerTierList from './ConfigBannerTierList';

type ConfigBannerTierListingProps = ParamsProps;

const initialState: any = {
  tierCode: '',
  status: '',
  isDefault: '',
  tierName: '',
  page: 1,
  limit: 5,
};

export const configBannerTierFilterKeys = ['tierCode', 'status', 'isDefault', 'tierName'];

const paramsInitiator = (initialParams?: Record<string, string>): any => {
  return initialParams
    ? {
        tierCode: initialParams.tierCode || '',
        tierName: initialParams.tierName || '',
        status: initialParams.status || '',
        isDefault: initialParams.isDefault || '',
        page: 1,
        limit: 5,
      }
    : initialState;
};

export const ConfigBannerTierListingPage: FC<ConfigBannerTierListingProps> = ({
  initialParams,
  onChangeQueryParams,
}) => {
  const intl = useIntl();

  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const [filterState, setFilterState] = useState<ConfigBannerTierListParam>(paramsInitiator(initialParams));
  const [bannerTierList, setBannerTierList] = useState<PaginateList<ConfigBannerTierListItem>>();
  const { isLoading, refreshData } = useDataProvider<PaginateList<ConfigBannerTierListItem>>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchConfigBannerTierList(filterState, dispatch);
      } catch (err) {}
    },
    setBannerTierList,
    false,
  );

  return (
    <>
      <PruFilter
        title={Translation('agentProfile.ad_agent_tier_filter')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'tierName',
            initialValue: filterState.tierName,
            style: { width: 200 },
            displayName: Translation('agentProfile.ad_tier_name_label'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'tierCode',
            initialValue: filterState.tierCode,
            style: { width: 200 },
            displayName: Translation('agentProfile.ad_tier_code_label'),
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'isDefault',
            initialValue: filterState.isDefault,
            displayName: Translation('agentProfile.ad_default_label'),
            style: { width: 200 },
            choices: [
              { displayName: '', value: '' },
              ...Object.entries(isDefault).map(([, value]) => ({
                displayName: Translation(`agentProfile.ad_${value}_label`),
                value: value === 'yes' ? 'true' : 'false',
              })),
            ],
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            tierName: data.tierName,
            status: data.status,
            tierCode: data.tierCode,
            isDefault: data.isDefault,
          })
        }
        fetchData={refreshData}
      />
      <ConfigBannerTierList
        isLoading={isLoading}
        list={bannerTierList}
        onRefresh={refreshData}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};
