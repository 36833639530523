export enum BulkUploadTypeEnum {
  BULK_LEAD = 'bulkLead',
  MASS_LEAD = 'massLead',
  // SERVICING_LEAD = "servicingLead",
  // IMPORT_AGENT = "importAgent"
}

export type BulkUploadStatusItem = {
  _id: string;
  resourceId: string;
  filename: string;
  type: BulkUploadTypeEnum;
  totalRow: number;
  totalDistributed: number;
  notDistributed: number;
  rowSuccess: number;
  rowFailed: number;
  status: string;
  updatedAt: Date;
  scheduledUploadTime?: Date;
};

export type BulkUploadFailedItem = {
  _id: string;
  csvRowNumber: number;
  failureReason: string;
};
