import React, { useState, useEffect, FC } from 'react';
import Form from 'src/app/common/components/Form';
import { useIntl } from 'react-intl';
import {
  TextField,
  MenuItem,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useLang } from 'src/app/i18n';
import { useDispatch, useSelector } from 'react-redux';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import MaterialArea from './MaterialArea';
import TargetProspect from './TargetProspect';
import { styles } from './style';
import { getFieldsList } from '../../api';
import {
  getEngagementTopicDetailById,
  addEngagmentTopic,
  updateEngagmentTopic,
  getTemplateById,
  getEngagementTasks,
} from './api';
import { getI18nDataByCurrentLang, PublishStatus, ModuleTypeList, TargetProspectType } from './utils';
import { MTmode as FormModeEnum } from '../../constants';
import { fieldsType } from '../../util';
import { topicBasePath } from '../Routes';
import moment from 'moment';
import DateTimeRangePicker from 'src/app/common/components/DateTimeRangePicker';
import Autocomplete from '@mui/lab/Autocomplete';
import { useJWT } from 'src/app/common/utils';

const Detail: FC<any> = ({ history, formMode }) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const { id } = useParams<{ id: string }>();
  const { classes: commonClasses } = useCommonStyles();
  const { classes: detailClass } = styles();
  const Translation = (id: string) => intl.formatMessage({ id });
  const locale = useLang();
  const isCreate = formMode === FormModeEnum.CREATE;
  const isEdit = formMode === FormModeEnum.EDIT;
  const dispatch = useDispatch();
  const DEFAULT_REQUIRED_RULES = { required: true, message: Translation('component.form-required-text') };

  const [initialValues, setInitialValues] = useState<any>({
    targetType: null, // only allow enum value use null or undefined
    publishStatus: PublishStatus.Draft.key,
    engagementType: null,
  });
  const [allEngagementFields, setAllEngagementFields] = useState<{ [key: string]: any }>([]);
  const [publishItems, SetPublishItems] = useState<any[]>([]);
  const [formStatus, setFormStatus] = useState<string>('');
  const [selectedMaterial, setSelectedMaterial] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const jwt = useJWT();
  const [tasks, setTasks] = useState<string[]>(['']);
  const [taskId, setTaskId] = React.useState<string | null>(tasks[0]);

  useEffect(() => {
    const fetchAPI = async () => {
      if (isEdit) {
        await fetchDataById();
      }

      await initialEngagmentType();
    };

    fetchAPI();

    return;
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getDisplayStatus();
    form.setFieldsValue({ publishStatus: formStatus || PublishStatus.Draft.key });
    // eslint-disable-next-line
  }, [formStatus]);

  useEffect(() => {
    form.setFieldsValue({
      taskId: taskId,
    });
  }, [taskId]);

  const onDeleteMatieral = () => {
    setSelectedMaterial(null);
  };

  const onSelectMaterial = (data: any) => {
    setSelectedMaterial(data);
    form.setFieldsValue({
      effectiveRange: [
        data.effectiveDate ? moment(data.effectiveDate || data.startDate) : null,
        data.expiredDate ? moment(data.expiredDate || data.endDate) : null,
      ],
      resourceType: ModuleTypeList[data.module],
      resource: {
        name: data.title || '',
      },
      resourceId: (data._id || data.id || '').toString(),
    });
  };

  const fetchDataById = async () => {
    setLoading(true);
    try {
      const result = await getEngagementTopicDetailById(id, locale, dispatch);
      const { material, startTime, endTime, templateId } = result?.data;
      let template = null;

      if (templateId) {
        template = await getTemplateById(templateId, dispatch);
      }
      setInitialValues(result?.data);

      setSelectedMaterial(material || result.data);
      form.setFieldsValue({
        ...result?.data,
        materialUid: result?.data.materialUid || result?.data?.resourceId || '',
        effectiveRange: [startTime, endTime],
        targeProspectFile: template?.data?.targeProspectFile || null,
        errorList: template?.data?.errorLog || [],
      });

      setFormStatus(result.data.publishStatus.toString());
      setTaskId(result.data.taskId);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const SearchEngagementsById = async (id: string) => {
    try {
      const result = await getEngagementTasks(id);
      const docs = result?.data?.docs;
      const tasks = docs
        .filter((item: { publishStatus: number }) => item.publishStatus === PublishStatus.Publish.value)
        .map((item: { _id: any; publishStatus: number }) => {
          return item._id;
        });

      setTasks(tasks);
    } catch (error) {
      setTasks([]);
    }
  };

  const goBackToList = () => {
    history.push(topicBasePath);
  };

  const initialEngagmentType = async () => {
    const currentValue = form.getFieldValue('engagementType');

    const requestParams = {
      page: 1,
      // limit: 20
    };
    const result = await getFieldsList(requestParams);
    const dataList = result.data;

    let list: any = [
      {
        label: Translation('app.input.placeholder.please-select'),
        value: null,
        status: '',
      },
    ];

    const options = dataList.find((item: any) => item.field === fieldsType.EngagementType)?.options || [];

    options.map((option: any) => {
      if (!option.deleted || option._id === currentValue) {
        list.push({
          label: getI18nDataByCurrentLang(option, locale)?.name,
          value: option._id,
          deleted: option.deleted,
        });
      }
    });

    setAllEngagementFields(list);
  };

  const getDisplayStatus = () => {
    const currentStatus = formStatus.toString();
    const isPublishedItem = currentStatus === PublishStatus.Publish.key;
    const isDraft = currentStatus === PublishStatus.Draft.key;
    const isUnpublished = currentStatus === PublishStatus.UnPublish.key;

    let displayStatus: any = [];

    if (isCreate || isDraft) {
      displayStatus = displayStatus.concat([PublishStatus.Draft, PublishStatus.Publish]);
    } else if (isPublishedItem) {
      displayStatus = displayStatus.concat([PublishStatus.Publish, PublishStatus.UnPublish]);
    } else if (isUnpublished) {
      displayStatus = displayStatus.concat([PublishStatus.Publish, PublishStatus.UnPublish]);
    } else {
      displayStatus = displayStatus.concat([PublishStatus.Draft, PublishStatus.Publish, PublishStatus.UnPublish]);
    }

    SetPublishItems(displayStatus);
  };

  const sucessOperation = () => {
    setLoading(false);
    dispatch(
      appendAlertItem([
        {
          severity: AlertType.SUCCESS,
          title: 'Success',
          content: 'Update Engagement Topic Successfully',
        },
      ]),
    );
    goBackToList();
  };

  const onSave = async () => {
    const allValues = form.getFieldsValue(true);
    allValues.startTime =
      allValues.effectiveRange && allValues.effectiveRange[0] ? moment(allValues.effectiveRange[0]).format() : null;
    allValues.endTime =
      allValues.effectiveRange && allValues.effectiveRange[1] ? moment(allValues.effectiveRange[1]).format() : null;

    delete allValues.effectiveRange;
    delete allValues.lastUpdatedBy;

    if (allValues.targetType === TargetProspectType.ALL) {
      allValues.templateId = '';
      delete allValues.targeProspectFile;
    }

    const requestData = Object.assign({}, allValues);

    if (requestData?.resourceType && requestData?.resourceType.indexOf('Material') === -1) {
      delete requestData.materialUid;
    }

    requestData.resourceId = requestData.resourceId ? requestData.resourceId.toString() : '';

    try {
      if (isCreate) {
        setLoading(true);
        await addEngagmentTopic(requestData, dispatch);
        sucessOperation();
      } else {
        try {
          setLoading(true);
          await updateEngagmentTopic(id, requestData, dispatch);
          sucessOperation();
        } catch (error: any) {
          setLoading(false);
        }
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.ERROR,
            title: 'Error',
            content: 'Error',
          },
        ]),
      );
    }
  };

  const onSubmit = () => {
    const status = form.getFieldValue('publishStatus');
    const isDraft = status === PublishStatus.Draft.key;
    const isUnpublish = status === PublishStatus.UnPublish.key;
    const errorList = form.getFieldValue('errorList');
    const isSpecifiyTargetType = form.getFieldValue('targetType') === TargetProspectType.SPECIFIY;

    if (isDraft || isUnpublish) {
      submitAnyway();
      return;
    }

    checkErrorList();

    if (!isSpecifiyTargetType || !errorList || (errorList && errorList.length == 0)) {
      form.submit();
    }
  };

  const submitAnyway = () => {
    const validationList = ['materialUid', 'engagementType', 'effectiveRange'];

    form
      .validateFields(validationList)
      .then((res) => {
        onSave();
      })
      .catch((err) => {
        const { errorFields } = err;
        if (errorFields.length <= 0) {
          onSave();
        }
      });

    return false;
  };

  const checkErrorList = () => {
    const validationList = ['templateId'];

    form.validateFields(validationList);

    return false;
  };

  const DATE_VIDATOR = [
    {
      validator(_: any, value: string) {
        if (!value) {
          return Promise.reject(new Error(Translation('component.form-required-text')));
        }

        if (value && !value[0]) {
          return Promise.reject(new Error('Please select the Start Date'));
        }

        if (value && !value[1]) {
          return Promise.reject(new Error('Please select the End Date'));
        }

        if (value && value[0] && value[1]) {
          if (moment(value[0]).isAfter(moment(value[1]))) {
            return Promise.reject(new Error(Translation('component.form-date-compare')));
          }
        }

        if (selectedMaterial && value && value[0] && value[1]) {
          const materialStartDate = selectedMaterial?.effectiveDate || selectedMaterial?.startTime;
          const materialExpiredDate = selectedMaterial?.expiredDate || selectedMaterial?.endTime;

          if (materialStartDate || materialExpiredDate) {
            if (materialStartDate && moment(value[0]).isBefore(materialStartDate)) {
              return Promise.reject(new Error('Topic start time should be >= material start time'));
            }

            if (materialExpiredDate && moment(value[1]).isAfter(materialExpiredDate)) {
              return Promise.reject(new Error('Topic end time should be <= material end time'));
            }
          }
        }

        return Promise.resolve();
      },
    },
  ];

  const TEMPLATE_ID_VIDATOR = [
    {
      validator(_: any, value: string) {
        const specifiyTargetType = form.getFieldValue('targetType') === TargetProspectType.SPECIFIY;
        const errorList = form.getFieldValue('errorList');

        if (specifiyTargetType && !value) {
          return Promise.reject(new Error('Please upload the file'));
        }

        if (specifiyTargetType && value && errorList?.length > 0) {
          return Promise.reject(new Error('Please check the file errors'));
        }

        return Promise.resolve();
      },
    },
  ];

  return (
    <div className={commonClasses.container}>
      <div className={detailClass.divideMargin}>
        <span className={commonClasses.header}>
          {isCreate ? Translation('app.button.add') : Translation('app.button.edit')} Engagement Topic
        </span>
      </div>
      <Form form={form} onFinish={onSave} initialValues={{ ...initialValues }} style={{ paddingBottom: 20 }}>
        <Form.Item name="materialUid" rules={[{ required: true, message: 'Please select a material' }]}>
          <MaterialArea
            form={form}
            onDeleteMatieral={onDeleteMatieral}
            onSelectMaterial={onSelectMaterial}
            selectedMaterial={selectedMaterial}
          />
        </Form.Item>

        <Form.Item>
          {(_, meta, { getFieldValue }) => {
            return (
              <>
                <TargetProspect form={form} />
              </>
            );
          }}
        </Form.Item>

        {
          <Form.Item
            // noStyle
            name="templateId"
            rules={TEMPLATE_ID_VIDATOR}
            style={{ marginLeft: 20 }}
          >
            <></>
          </Form.Item>
        }
        <Form.Item label={'Engagement Type'} name="engagementType" rules={[DEFAULT_REQUIRED_RULES]}>
          <TextField
            // disabled={disabled}
            select
            margin="dense"
            variant="outlined"
            // fullWidth
          >
            {allEngagementFields &&
              allEngagementFields.map((item: any, index: number) => {
                return (
                  <MenuItem key={`category_${index}`} value={item.value}>
                    {item?.label}
                  </MenuItem>
                );
              })}
          </TextField>
        </Form.Item>

        <Form.Item label={'Engagement Task ID'} name="engagementTaskId" required={false}>
          <div>
            <Autocomplete
              value={taskId}
              onChange={(event: any, newValue: string | null) => {
                setTaskId(newValue);
              }}
              onInputChange={(event, newInputValue) => {
                SearchEngagementsById(newInputValue);
              }}
              id="controllable-states-demo"
              options={tasks}
              style={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Engagement Task ID" variant="outlined" />}
            />
          </div>
        </Form.Item>

        <Form.Item name="effectiveRange" label={Translation('component.formLabel.effective-date')} rules={DATE_VIDATOR}>
          <DateTimeRangePicker />
        </Form.Item>

        <Form.Item
          label={Translation('mandatory.training.category.publish.option')}
          name="publishStatus"
          rules={[DEFAULT_REQUIRED_RULES]}
        >
          <RadioGroup style={{ display: 'flex', flexDirection: 'row' }}>
            {publishItems &&
              publishItems.map((item: any) => {
                return (
                  item && (
                    <FormControlLabel
                      key={item.key}
                      value={item.key}
                      control={<Radio />}
                      label={Translation(item.label)}
                    />
                  )
                );
              })}
          </RadioGroup>
        </Form.Item>

        <div>
          <Form.Item noStyle style={{ margin: 20 }}>
            <div style={{ marginBottom: 15, display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant="contained"
                color="inherit"
                disabled={loading}
                style={{ marginRight: 15 }}
                onClick={goBackToList}
              >
                {Translation('app.button.cancel')}
              </Button>

              <Button
                style={{ marginRight: 15 }}
                variant="contained"
                disabled={loading}
                color="secondary"
                onClick={() => {
                  onSubmit();
                }}
              >
                {Translation('app.button.submit')}
              </Button>
            </div>
          </Form.Item>
        </div>
      </Form>

      <Backdrop className={commonClasses.backdrop} open={loading}>
        <CircularProgress color="secondary" />
      </Backdrop>
    </div>
  );
};

export default Detail;
