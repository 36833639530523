import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import PruRoute from 'src/app/common/components/PruRoute';
import { PROFILE_BASE_PATH, PROFILE_CONFIG_INTRODUCTION_PATH, PROFILE_CONFIG_PATH } from '../../../constants';
import ParamsProvider from 'src/app/common/components/ParamsProvider';
import ConfigAboutMeListPage, { configAboutMeFilterKeys } from './List/ConfigAboutMeListingPage';
import AboutMeDetailPage from './List/Component/AboutMeDetailPage';

export const configAboutMePath = `${PROFILE_BASE_PATH}${PROFILE_CONFIG_PATH}${PROFILE_CONFIG_INTRODUCTION_PATH}`;

const ConfigAboutMeRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect exact from={configAboutMePath} to={`${configAboutMePath}/list`} />
      <PruRoute
        exact
        path={`${configAboutMePath}/list`}
        render={(props) => (
          <ParamsProvider {...props} acceptKeys={configAboutMeFilterKeys}>
            <ConfigAboutMeListPage />
          </ParamsProvider>
        )}
      />

      <PruRoute exact path={`${configAboutMePath}/create`} component={AboutMeDetailPage} />
      <PruRoute exact path={`${configAboutMePath}/edit/:id`} component={AboutMeDetailPage} />

      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default ConfigAboutMeRoutes;
