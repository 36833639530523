import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Step,
  StepButton,
  Stepper,
} from '@mui/material';
import { useIntl } from 'react-intl';
import { useTemplateDetailPage } from './template-detail-page.hook';
import { useStyles } from './template-detail-page.style';
import { RecruitmentTemplate } from './template-detail-page.type';
import { TemplateDetailStep1 } from './step1/template-detail-step1';

import { OnboardingApplicationPage } from '../onboarding-application/onboarding-application-page';
import { LoadingComponent } from 'src/app/common/components/loading/loading.component';
import { Step3Component } from './step3/step3.component';
import { useRef } from 'react';

export function RecruitmentTemplateDetailPage(props: RecruitmentTemplate) {
  const { classes } = useStyles();
  const intl = useIntl();
  const t = (id: string) => intl.formatMessage({ id });

  const refNextElement = useRef(null);

  const {
    version,
    loading,
    loadingSave,
    stepIndex,
    isView,
    isValidFrom,
    setStepIndex,
    handleSaveDraft,
    handleNext,
    handlePrevious,
    template,
    setTemplate,
    showCancelDialog,
    hideCancelDialog,
    goBack,
    cancelDialog,
    validate,
    setValidate,
    handleTypeChange,
    setIsValidFrom,
  } = useTemplateDetailPage(refNextElement);

  if (loading) {
    return <LoadingComponent />;
  }
  return (
    <div className={`tw-bg-white tw-p-8 ${classes.page}`}>
      <div className="tw-flex tw-items-center tw-justify-between">
        <div className="detail-title">{t('template_title_add')}</div>
        <Button variant="contained" className="gray-btn" onClick={showCancelDialog}>
          {t('app.button.back')}
        </Button>
      </div>

      <Stepper activeStep={stepIndex} className={classes.step} alternativeLabel>
        <Step>
          <StepButton>{t('template_step_basic')}</StepButton>
        </Step>
        <Step disabled={false}>
          <StepButton ref={refNextElement} onClick={() => setStepIndex(1)}>
            {t('template_step_edit_application_form')}
          </StepButton>
        </Step>
        <Step disabled={false}>
          <StepButton>{t('template_step_link_and_submit')}</StepButton>
        </Step>
      </Stepper>

      {stepIndex === 0 && (
        <div className={isView ? 'tw-pointer-events-none' : ''}>
          <TemplateDetailStep1
            {...template}
            version={version}
            setTemplate={setTemplate}
            setValidate={setIsValidFrom}
            onTypeChange={handleTypeChange}
          />
        </div>
      )}
      {/* step2: surveyjs creator */}
      {stepIndex === 1 && (
        <div className={classes.step2}>
          <OnboardingApplicationPage
            surveyJson={template.content}
            templateType={template.type}
            onSurveyUpdate={setTemplate}
            readOnly={!!isView}
          />
        </div>
      )}
      {/* step3: pdf link and submit */}
      {stepIndex === 2 && (
        <div className={isView ? 'tw-pointer-events-none' : ''}>
          <Step3Component {...template} setTemplate={setTemplate} setValidate={setValidate} />
        </div>
      )}

      <div>
        <div className="tw-items-center tw-justify-end tw-flex step-btns">
          <Box marginRight="1rem" className={loadingSave ? 'tw-show' : 'tw-hidden'}>
            <CircularProgress size="2rem" color="secondary" />
          </Box>
          {stepIndex > 0 ? (
            <Box marginRight="1rem">
              <Button variant="contained" onClick={handlePrevious} disabled={loadingSave}>
                {t('app.button.previous')}
              </Button>
            </Box>
          ) : null}
          <Box marginRight="1rem" className={isView ? 'tw-hidden' : ''}>
            <Button variant="contained" onClick={handleSaveDraft} disabled={loadingSave || !version}>
              {t('app.button.saveAsDraft')}
            </Button>
          </Box>
          <Box className={stepIndex === 2 && isView ? 'tw-hidden' : ''}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleNext}
              disabled={(!validate && stepIndex === 2) || !isValidFrom || loadingSave || !version}
            >
              {stepIndex !== 2 || isView ? t('app.button.next') : t('app.button.save')}
            </Button>
          </Box>
        </div>
      </div>

      <Dialog open={cancelDialog} onClose={hideCancelDialog} maxWidth="xs">
        <DialogTitle>{t('pdf_dialog_title')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('pdf_dialog_content')}</DialogContentText>
        </DialogContent>
        <Box marginBottom="1rem">
          <DialogActions>
            <Button variant="contained" onClick={hideCancelDialog}>
              {t('pdf_dialog_cancel')}
            </Button>
            <Button variant="contained" color="secondary" onClick={goBack}>
              {t('pdf_dialog_leave')}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
}
