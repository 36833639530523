import React, { FC, useEffect, useState } from 'react';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useDataProvider } from 'src/app/common/utils';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { PaginateList } from 'src/app/common/types/common-types';
import { ProductCampaignItem } from 'src/app/modules/PulseLeads/types/product-campaign-types';
import {
  fetchProductCampaignList,
  ProductCampaignListParam,
} from 'src/app/modules/PulseLeads/network/productCampaignCrud';
import ProductCampaignList from './components/ProductCampaignList';
import { ConfigParam, fetchConfig } from '../../../../network/campaignCrud';

// eslint-disable-next-line
type ProductCampaignListingPageProps = {} & ParamsProps;

const initialState: ProductCampaignListParam = {
  productCode: '',
  campaignName: '',
  page: 1,
  limit: 5,
};

export const productCampaignFilterKeys = ['productCode', 'campaignName'];

const paramsInitiator = (initialParams?: Record<string, string>): ProductCampaignListParam => {
  return initialParams
    ? {
        productCode: initialParams.productCode || '',
        campaignName: initialParams.campaignName || '',
        page: 1,
        limit: 5,
      }
    : initialState;
};

const ProductCampaignListingPage: FC<ProductCampaignListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const intl = useIntl();
  const history = useHistory();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const [filterState, setFilterState] = useState<ProductCampaignListParam>(paramsInitiator(initialParams));
  const [productCampaignList, setProductCampaignList] = useState<PaginateList<ProductCampaignItem>>();
  const [config, setConfig] = useState<any>();

  const { isLoading, refreshData } = useDataProvider<PaginateList<ProductCampaignItem>>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchProductCampaignList(filterState, dispatch);
      } catch (err) {
        history.push(NOT_FOUND_ERROR_PAGE_PATH);
      }
    },
    setProductCampaignList,
    false,
  );

  const reloadConfig = async () => {
    const configParams: ConfigParam = {
      name: 'sales-sdk',
      namespace: 'default',
    };
    const configRes = await fetchConfig(configParams, dispatch);
    setConfig(configRes.data[0]);
  };

  useEffect(() => {
    reloadConfig();
  }, []);

  return (
    <>
      <PruFilter
        title={Translation('pulseleads.straightThrough.productCampaignFilter')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'productCode',
            initialValue: filterState.productCode,
            displayName: Translation('pulseleads.straightThrough.productCode'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'campaignName',
            initialValue: filterState.campaignName,
            displayName: Translation('pulseleads.campaign.common.name'),
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            productCode: data.productCode,
            campaignName: data.campaignName,
          })
        }
        fetchData={refreshData}
      />
      <ProductCampaignList
        isLoading={isLoading}
        productCampaignList={productCampaignList}
        config={config}
        onRefresh={refreshData}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default ProductCampaignListingPage;
