import React, { FC, useState, useEffect } from 'react';
import _, { map } from 'lodash';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { ApiErrorResponse } from 'src/app/common/network';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { LayoutSplashScreen } from 'src/app/layout';
import { makeStyles } from 'tss-react/mui';
import { Button } from '@mui/material';
import {
  CampaignTypeDropdownListParam,
  fetchCampaignTypeItem,
  fetchCampaignTypeDropdownList,
} from 'src/app/modules/AgencyCampaign/network/campaignTypeCrud';
import {
  CampaignTypeItem,
  CampaignTypeDropdownEnum,
  CampaignTypeDropdownList,
} from 'src/app/modules/AgencyCampaign/types/campaign-type-types';
import { campaignTypePath } from '../CampaignTypeRoutes';
import CampaignTypePreview from 'src/app/modules/AgencyCampaign/pages/CampaignType/Form/pages/PageFour/components/CampaignTypePreview';

const useStyles = makeStyles()((theme) => ({
  footerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const CampaignTypeViewPage: FC<RouteComponentProps> = ({ history, location }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { id } = useParams<{ id: string }>();
  const { classes } = useStyles();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [campaignTypeItem, setCampaignTypeItem] = useState<CampaignTypeItem>();
  const [campaignTypeDropdownList, setCampaignTypeDropdownList] = useState<CampaignTypeDropdownList>();
  const [subSourceDropdownList, setSubSourceDropdownList] = useState<CampaignTypeDropdownList>();

  const reloadData = async () => {
    if (id) {
      setIsLoading(true);
      fetchCampaignTypeItem(id, dispatch)
        .then((result) => {
          setCampaignTypeItem(result);
        })
        .catch((err: ApiErrorResponse) => {
          history.push(NOT_FOUND_ERROR_PAGE_PATH);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const reloadDropdown = async () => {
    const params: CampaignTypeDropdownListParam = {
      type: map(CampaignTypeDropdownEnum),
    };
    const dropdownListRes = await fetchCampaignTypeDropdownList(params, dispatch);
    setCampaignTypeDropdownList(dropdownListRes);
  };

  const reloadSubSourceDropdown = async () => {
    const params: CampaignTypeDropdownListParam = {
      type: map(CampaignTypeDropdownEnum),
      parent: campaignTypeItem?.agentLeadSource,
    };
    const dropdownListRes = await fetchCampaignTypeDropdownList(params, dispatch);
    setSubSourceDropdownList(dropdownListRes);
  };

  useEffect(() => {
    setIsLoading(true);
    reloadDropdown();
    reloadData();
    setIsLoading(false);
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    reloadSubSourceDropdown();
  }, [campaignTypeItem?.agentLeadSource]);

  return (
    <>
      {isLoading || !campaignTypeItem ? (
        <LayoutSplashScreen />
      ) : (
        <>
          {campaignTypeItem && campaignTypeDropdownList && subSourceDropdownList && (
            <>
              <CampaignTypePreview
                campaignTypeItem={campaignTypeItem}
                campaignTypeDropdownList={campaignTypeDropdownList}
                subSourceDropdownList={subSourceDropdownList}
                disableCloseBtn={true}
              />
              <div className={classes.footerContainer}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => history.push(`${campaignTypePath}/edit/${id}`)}
                >
                  {Translation('app.button.edit')}
                </Button>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default CampaignTypeViewPage;
