import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    columnGap: 15,
  },
  leftBarContainer: {
    width: 224,
    minWidth: 224,
    height: '100%',
    backgroundColor: 'white',
    overflow: 'auto',
  },
  backButtonContainer: {
    height: 52,
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
  },
  backIcon: {
    width: 20,
    height: 20,
    marginLeft: 5,
    marginRight: 10,
  },
  contentContainer: {
    flexGrow: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'auto',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 18,
  },
  component: {
    flexGrow: 1,
    backgroundColor: 'white',
    overflowY: 'auto',
  },
  hidden: {
    display: 'none',
  },
}));
