import { makeStyles } from 'tss-react/mui';

const FIELD_CONTAINER_SPACING = 10;

export const usePublishStyles = makeStyles()(() => ({
  dialogContainer: {
    padding: '20px',
  },
  dialogButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    columnGap: 20,
    padding: '0 20px 20px 20px',
  },
  fieldContainer: {
    marginBottom: FIELD_CONTAINER_SPACING,
  },
}));
