import React, { FC, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { LayoutSplashScreen } from 'src/app/layout';
import { RootState } from '../../../../redux/store';
import { logout } from '../_redux/authSlice';

const Logout: FC<{ hasAuthToken: boolean }> = ({ hasAuthToken }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const token = window.localStorage.getItem('jwt');
    if (token) {
      dispatch(logout());
    }
    // eslint-disable-next-line
  }, []);

  return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/auth/login" />;
};

export default connect((state: RootState) => ({ hasAuthToken: !!state.auth.token }))(Logout);
