import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getDesignations, getZones, fetchAgentSegmentList } from 'src/app/common/network';
import { DesignationItem, ZoneItem, AgentSegmentItem } from 'src/app/common/types';
import { ENABLE_DESIGNATION, ENABLE_ZONE } from './constants';
import { TargetApplicantMode, TargetApplicantSectionProps } from './types';
import { TargetApplicantSelectMode } from './select-mode';
import { TargetApplicantRadioMode } from './radio-mode';

export const TargetApplicantSection = ({ agentSegmentListParams, ...props }: TargetApplicantSectionProps) => {
  const dispatch = useDispatch();
  const [designationList, setDesignationList] = useState<DesignationItem[]>([]);
  const [zoneList, setZoneList] = useState<ZoneItem[]>([]);
  const [agentSegmentList, setAgentSegmentList] = useState<AgentSegmentItem[]>([]);

  const reloadData = async () => {
    try {
      if (ENABLE_DESIGNATION) {
        const designationRes = await getDesignations(dispatch);
        setDesignationList(designationRes);
      }
      if (ENABLE_ZONE && props.mode === TargetApplicantMode.RADIO) {
        const zoneRes = await getZones(dispatch);
        setZoneList(zoneRes.sort((a, b) => a.code.localeCompare(b.code)));
      }
      if (agentSegmentListParams) {
        const agentSegmentRes = await fetchAgentSegmentList(agentSegmentListParams, dispatch);
        setAgentSegmentList(agentSegmentRes.docs);
      }
    } catch (err) {}
  };

  useEffect(() => {
    reloadData();
  }, []);

  return (
    <div style={{ width: '100%' }}>
      {props.mode === TargetApplicantMode.SELECT && (
        <TargetApplicantSelectMode designationList={designationList} agentSegmentList={agentSegmentList} {...props} />
      )}
      {props.mode === TargetApplicantMode.RADIO && (
        <TargetApplicantRadioMode designationList={designationList} zoneList={zoneList} {...props} />
      )}
    </div>
  );
};
