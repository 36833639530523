import React from 'react';
import { Button, ListItemText, TextField } from '@mui/material';
import { PruTableButtonDef } from 'src/app/common/components/PruTable/PruTable';
import { RegionLocale, regionLocaleMap } from 'src/app/i18n';
import { formatRuleId } from '../utils';
export const headerBtn = (Translation: any, setEditMode: any, isEditMode: boolean, handleSave: any) => {
  const columns: PruTableButtonDef[] = [
    {
      color: 'primary',
      title: Translation('app.button.edit'),
      onClick: () => setEditMode(true),
      condition: () => !isEditMode,
    },
    {
      color: 'primary',
      title: Translation('app.button.save'),
      onClick: () => handleSave(),
      condition: () => isEditMode,
    },
  ];
  return columns;
};

export const columns = (
  Translation: any,
  showPopup: any,
  localeList: RegionLocale[],
  isEditMode: boolean,
  changeRow: (e: any, row: any, lang: any) => void,
) => {
  return [
    {
      keyIndex: 'ruleId',
      displayName: Translation('pulseleads.activityPoint.ruleName.form.ruleId'),
      renderData: (row: any, index?: number) => {
        return (
          <Button variant="text" color="secondary" onClick={(e) => showPopup(e, row)}>
            {formatRuleId(row.type)}
          </Button>
        );
      },
    },
  ].concat(
    localeList.map((lang) => {
      return {
        keyIndex: lang,
        displayName: Translation(regionLocaleMap[lang].name),
        renderData: (row) => {
          return isEditMode ? (
            <TextField
              fullWidth={true}
              required
              variant="outlined"
              helperText={
                !row.name[lang] && row.edited ? Translation('pulseleads.activityPoint.ruleName.form.helperText') : ''
              }
              type="text"
              value={row.name[lang]}
              onChange={(e) => changeRow(e, row, lang)}
              error={!row.name[lang] && row.edited}
            />
          ) : (
            <ListItemText>{row.name[lang]}</ListItemText>
          );
        },
      };
    }),
  );
};
