import React, { FC, useState, useEffect, useMemo } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { LayoutSplashScreen } from 'src/app/layout';
import { CampaignMappingFormMode } from 'src/app/modules/PulseLeads/types/straight-through-types';
import { CampaignDropdownList, CampaignDropdownType } from 'src/app/modules/PulseLeads/types/campaign-types';
import { ChannelCampaignItem } from 'src/app/modules/PulseLeads/types/channel-campaign-types';
import { fetchCampaignDropdownList, CampaignDropdownListParam } from 'src/app/modules/PulseLeads/network/campaignCrud';
import { fetchChannelCampaignItem } from 'src/app/modules/PulseLeads/network/channelCampaignCrud';
import ChannelCampaignForm from './components/ChannelCampaignForm';

const ChannelCampaignDetailPage: FC<RouteComponentProps> = ({ history }) => {
  const { id } = useParams<{ id: string }>();
  const { formMode } = useMemo(
    () => ({
      formMode: id ? CampaignMappingFormMode.EDIT : CampaignMappingFormMode.CREATE,
    }),
    [id],
  );

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [channelCampaignItem, setChannelCampaignItem] = useState<ChannelCampaignItem>();
  const [channelCampaignDropdownList, setChannelCampaignDropdownList] = useState<CampaignDropdownList>();

  const reloadData = async () => {
    if (id) {
      setIsLoading(true);
      try {
        const campaignItemRes = await fetchChannelCampaignItem(id, dispatch);
        setChannelCampaignItem(campaignItemRes);
      } catch (err) {
        history.push(NOT_FOUND_ERROR_PAGE_PATH);
      }
    }
    try {
      const params: CampaignDropdownListParam = {
        type: [CampaignDropdownType.CAMPAIGN],
      };
      const dropdownListRes = await fetchCampaignDropdownList(params, dispatch);
      setChannelCampaignDropdownList(dropdownListRes);
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line
  }, [id]);

  return (
    <>
      {isLoading ? (
        <LayoutSplashScreen />
      ) : (
        <>
          {formMode === CampaignMappingFormMode.CREATE && (
            <ChannelCampaignForm formMode={formMode} channelCampaignDropdownList={channelCampaignDropdownList} />
          )}
          {formMode === CampaignMappingFormMode.EDIT &&
            (isLoading || !channelCampaignItem ? (
              <LayoutSplashScreen />
            ) : (
              <ChannelCampaignForm
                formMode={formMode}
                channelCampaignDropdownList={channelCampaignDropdownList}
                channelCampaignItem={channelCampaignItem}
              />
            ))}
        </>
      )}
    </>
  );
};

export default ChannelCampaignDetailPage;
