import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import PruRoute from 'src/app/common/components/PruRoute';
import * as constants from '../../constants';
import CategoryList from './List';
import { ModulePermissionProps } from 'src/app/common/module/types';

const MandatoryTrainingCategoryPage: FC<RouteComponentProps & ModulePermissionProps> = ({
    history,
    location,
    match,
    enableRead,
    enableCreate,
    enableUpdate
}) => {
    return (
        <Switch>
            <Redirect exact from={match.path} to={`${match.path}/list`} />
            <PruRoute
                exact
                path={`${match.path}/list`}
                render={(props) => (
                    <CategoryList
                        {...props}
                        enableRead={enableRead}
                        enableCreate={enableCreate}
                        enableUpdate={enableUpdate}
                    />
                )}
            />
            <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
        </Switch>
    )
};

export default MandatoryTrainingCategoryPage;