import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import PruRoute from 'src/app/common/components/PruRoute';
import { PULSELEADS_BASE_PATH, PULSELEADS_LEAD_PATH, PULSELEADS_LEAD_SOURCE_PATH } from '../../../constants';
import ParamsProvider from 'src/app/common/components/ParamsProvider';
import ListSourceListingPage, { leadSourceFilterKeys } from './List/LeadSourceListingPage';
import LeadSourceDetailPage from './Form/LeadSourceDetailPage';

export const leadSourcePath = `${PULSELEADS_BASE_PATH}${PULSELEADS_LEAD_PATH}${PULSELEADS_LEAD_SOURCE_PATH}`;

const LeadSourceRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect exact from={leadSourcePath} to={`${leadSourcePath}/list`} />
      <PruRoute exact path={`${leadSourcePath}/list`} render={(props) => (
        <ParamsProvider {...props} acceptKeys={leadSourceFilterKeys}>
          <ListSourceListingPage />
        </ParamsProvider>
      )}/>
      <PruRoute exact path={`${leadSourcePath}/edit/:id`} component={LeadSourceDetailPage}/>
      <PruRoute exact path={`${leadSourcePath}/create`} component={LeadSourceDetailPage}/>
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  )
};

export default LeadSourceRoutes;