import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import PruRoute from 'src/app/common/components/PruRoute';
import { ModulePermissionProps } from 'src/app/common/module/types';
import { PULSELEADS_LEAD_ENGAGEMENT_FIELDS_PATH, PULSELEADS_LEAD_ENGAGEMENT_PATH } from './constants';
import List from './EngagementFields/List';
import Detail from './EngagementFields/Detail';

export const leadEngagementPath = `${PULSELEADS_LEAD_ENGAGEMENT_PATH}${PULSELEADS_LEAD_ENGAGEMENT_FIELDS_PATH}`;

const EngagementFieldsRoutes: FC<RouteComponentProps & ModulePermissionProps> = ({
    enableRead,
    enableCreate,
    enableUpdate
}) => {
    return (
        <Switch>
            <Redirect exact from={leadEngagementPath} to={`${leadEngagementPath}/list`} />
            <PruRoute exact path={`${leadEngagementPath}/list`} component={List} />
            <PruRoute exact path={`${leadEngagementPath}/edit/:id`} render={(props) => (
                <Detail
                    {...props}
                    enableRead={enableRead}
                    enableCreate={enableCreate}
                    enableUpdate={enableUpdate}
                />
            )} />
            <PruRoute exact path={`${leadEngagementPath}/create`} component={Detail} />
            <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
        </Switch>
    )
};

export default EngagementFieldsRoutes;