import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import PruRoute from 'src/app/common/components/PruRoute';
import { INSIGHTS_BASE_PATH, INSIGHTS_CAMPAIGN_PATH } from '../../constants';
import ParamsProvider from 'src/app/common/components/ParamsProvider';
import CampaignListingPage, { campaignFilterKeys } from './List/CampaignListingPage';
import InsightDetailPage from './List/components/InsightDetailPage';
import InsightListingPage from './List/InsightListingPage';

export const campaignPath = `${INSIGHTS_BASE_PATH}${INSIGHTS_CAMPAIGN_PATH}`;
export const insightPath = `${INSIGHTS_BASE_PATH}`;
const CampaignRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect exact from={insightPath} to={`${campaignPath}/list`} />
      <Redirect exact from={campaignPath} to={`${campaignPath}/list`} />
      <PruRoute exact path={`${campaignPath}/list`} render={(props) => (
        <ParamsProvider {...props} acceptKeys={campaignFilterKeys}>
          <CampaignListingPage />
        </ParamsProvider>
      )}/>
      <PruRoute
        exact
        path={`${campaignPath}/view/:campaignId/:id`}
        component={InsightListingPage}
      />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  )
};

export default CampaignRoutes;