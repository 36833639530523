import React, { FC, useState } from 'react';
import SetTargetSearch from './components/SetTargetSearch';
import { GetProductionTargetParams, fetchProductionTarget } from '../../network/productionCrud';
import { useDataProvider } from 'src/app/common/utils';
import { ProductionTarget } from '../../types/production-target-types';
import { useDispatch } from 'react-redux';
import { useUpdateEffect } from '../../../sales-kit/components/Library/utils/hook';
import ProductionTargetList from './components/ProductionTargetList';
import { makeStyles } from 'tss-react/mui';
import { CircularProgress } from '@mui/material';
import { ParamsProps } from '../../../../common/components/ParamsProvider';

type SetTargetDetailProps = {} & ParamsProps;

const useStyles = makeStyles()((theme) => ({
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '50px 0',
  },
}));

const SetTargetDetail: FC<SetTargetDetailProps> = ({ initialParams, onChangeQueryParams }) => {
  const dispatch = useDispatch();
  const { classes } = useStyles();

  const [searchParams, setSearchParams] = useState<GetProductionTargetParams>({
    target: '',
    targetRef: '',
    year: '',
    month: '',
  });

  const [productionTargets, setProductionTargets] = useState<ProductionTarget[] | undefined>();
  const { isLoading, refreshData } = useDataProvider<ProductionTarget[]>(
    async () => {
      try {
        return await fetchProductionTarget(searchParams, dispatch);
      } catch (err) {}
    },
    setProductionTargets,
    true,
    undefined,
  );

  useUpdateEffect(() => {
    if (onChangeQueryParams) {
      onChangeQueryParams(searchParams);
    }
    refreshData();
  }, [searchParams]);

  return (
    <>
      <SetTargetSearch initialParams={initialParams} searchData={(params) => setSearchParams(params)} />
      {isLoading && (
        <div className={classes.loadingContainer}>
          <CircularProgress color="secondary" size={60} />
        </div>
      )}
      {productionTargets !== undefined && (
        <ProductionTargetList productionTargets={productionTargets} refreshData={refreshData} />
      )}
    </>
  );
};

export default SetTargetDetail;
