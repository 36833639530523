import React, { useState, useEffect } from 'react';
import Form from 'src/app/common/components/Form';
import { useIntl } from 'react-intl';
import { makeStyles } from 'tss-react/mui';

import {
  Button,
  TextField,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  FormGroup,
} from '@mui/material';

import { RouteComponentProps } from 'react-router-dom';
import { ModulePermissionProps } from 'src/app/common/module/types';
import { RootState } from 'src/redux/store';
import { AuthenticationState } from 'src/app/modules/Auth/_redux/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { disableInputInvalidValueForWeight, NumberGreaterThanZeroPattern } from 'src/app/common/utils';
import { ReminderSwitchType, ReminderFrequencyType } from '../../../../constants';
import { styles as useDetailClass } from './style';
import { regionLocale } from 'src/app/i18n';
import MutiLangList from './../../../../components/MutiLangList';

const AutoReminder: React.FC<any> = (props) => {
  const { classes: commonClasses } = useCommonStyles();
  const { classes: detailClass } = useDetailClass();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { form, disabled } = props;

  const initialValues = form.getFieldValue('reminder');
  const DEFAULT_REQUIRED_RULES = {
    required: true,
    message: Translation('component.pulseleads.prospect.form-required-text'),
  };

  const TRIGGER_DATE_VALIDATE = [
    DEFAULT_REQUIRED_RULES,
    {
      pattern: NumberGreaterThanZeroPattern,
      message: Translation('value.need.greater.zero.msg'),
    },
  ];

  return (
    <>
      <div className={detailClass.divideMargin}>
        <div className={commonClasses.sessionHeader}>
          {Translation('mandatory.training.component.auto.reminder.title')}
        </div>
      </div>

      <Form.Item name={['reminder', 'enable']} rules={[DEFAULT_REQUIRED_RULES]}>
        <RadioGroup style={{ display: 'flex', flexDirection: 'row' }}>
          {Object.entries(ReminderSwitchType).map(([label, value]) => {
            return (
              <FormControlLabel
                key={`key_${label}`}
                value={value}
                disabled={disabled}
                checked={JSON.parse(initialValues?.enable) === JSON.parse(value)}
                control={<Radio />}
                label={Translation(`mandatory.training.component.reminder.${label}`)}
              />
            );
          })}
        </RadioGroup>
      </Form.Item>

      <Form.Item>
        {(_, meta, { getFieldValue }) => {
          const isEnable = getFieldValue(['reminder', 'enable']);

          if (!isEnable || !JSON.parse(isEnable)) {
            return null;
          }

          return (
            <FormGroup>
              <Form.Item label={Translation('mandatory.training.component.auto.reminder.trigger.date.title')}>
                {(control) => {
                  return (
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <Form.Item name={['reminder', 'triggerDaysBeforeDeadline']} rules={TRIGGER_DATE_VALIDATE}>
                        <FormControlLabel
                          disabled={disabled}
                          control={
                            <TextField
                              type="number"
                              margin="dense"
                              variant="outlined"
                              inputProps={disableInputInvalidValueForWeight()}
                              style={{ marginRight: 15 }}
                            />
                          }
                          label={Translation(`mandatory.training.component.reminder.before.day.hint`)}
                        />
                      </Form.Item>
                    </div>
                  );
                }}
              </Form.Item>

              <Form.Item
                name={['reminder', 'frequency']}
                label={Translation(`mandatory.training.component.reminder.frequency.title`)}
                rules={[DEFAULT_REQUIRED_RULES]}
              >
                <RadioGroup style={{ display: 'flex', flexDirection: 'row' }}>
                  {Object.entries(ReminderFrequencyType).map(([label, value]) => {
                    return (
                      <FormControlLabel
                        key={`key_${label}`}
                        disabled={disabled}
                        value={value}
                        control={<Radio />}
                        label={Translation(`mandatory.training.component.reminder.frequency.${label}`)}
                        checked={
                          initialValues.frequency !== null && value.toString() === initialValues?.frequency?.toString()
                        }
                      />
                    );
                  })}
                </RadioGroup>
              </Form.Item>

              <div className={commonClasses.sessionHeader}>
                {Translation('mandatory.training.component.auto.reminder.predefined.title')}:{' '}
              </div>

              <Form.Item
                label={Translation('mandatory.training.component.reminder.notification.type.text')}
                name={['reminder', 'notificationType']}
                initialValue={'push+inbox'}
                required={false}
              >
                <p>{Translation('component.formSelectItem.push-inbox')}</p>
              </Form.Item>

              <Form.Item
                required={false}
                label={Translation('mandatory.training.component.reminder.notification.title.text')}
                labelTop={true}
              >
                {(_, meta, { getFieldValue }) => {
                  return (
                    <MutiLangList
                      listName={['reminder', 'i18nData']}
                      name="msgTitle"
                      disabled={true}
                      subTitle={true}
                      required={false}
                      pureText={true}
                      getFieldValue={getFieldValue}
                    />
                  );
                }}
              </Form.Item>

              <Form.Item
                required={false}
                label={Translation('mandatory.training.component.reminder.fixed.content.inbox.text')}
                labelTop={true}
              >
                {(_, meta, { getFieldValue }) => {
                  return (
                    <MutiLangList
                      listName={['reminder', 'i18nData']}
                      name="msgContent"
                      disabled={true}
                      subTitle={true}
                      required={false}
                      pureText={true}
                      getFieldValue={getFieldValue}
                    />
                  );
                }}
              </Form.Item>

              <Form.Item
                required={false}
                label={Translation('mandatory.training.component.reminder.fixed.content.push.text')}
                labelTop={true}
              >
                {(_, meta, { getFieldValue }) => {
                  return (
                    <MutiLangList
                      listName={['reminder', 'i18nData']}
                      name="pushContent"
                      disabled={true}
                      subTitle={true}
                      required={false}
                      pureText={true}
                      getFieldValue={getFieldValue}
                    />
                  );
                }}
              </Form.Item>

              <Form.Item
                required={false}
                label={Translation('component.formLabel.redirectTo')}
                name={['reminder', 'redirectTo']}
              >
                {(_, meta, { getFieldValue }) => {
                  const collectionNames = getFieldValue(['i18nData', 0, 'name']);

                  return (
                    <Form.Item noStyle>
                      {(_, meta, { getFieldValue }) => {
                        return <p>{collectionNames}</p>;
                      }}
                    </Form.Item>
                  );
                }}
              </Form.Item>

              <Form.Item
                required={false}
                label={Translation('mandatroy.training.reminder.notification.send.time.text')}
                name={['reminder', 'sendTime']}
                initialValue="9:00"
              >
                <p>9:00</p>
              </Form.Item>
            </FormGroup>
          );
        }}
      </Form.Item>
    </>
  );
};

export default AutoReminder;
