import React, { useState, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Radio, RadioGroup, FormControlLabel, TextField } from '@mui/material';
import { useIntl } from 'react-intl';
import Form from 'src/app/common/components/Form';
import { disableInputInvalidValueForWeight } from 'src/app/common/utils';
import { completionRuleType } from '../../../../../constants';

const useStyles = makeStyles()({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  group: {
    margin: '0 10px',
    marginRight: 0,
  },
  radio: {
    margin: 0,
    marginRight: 10,
  },
});

const RuleSelection = (props: any) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { onChange, form, disabled } = props;
  const intialValues = props.value || {};

  return (
    <>
      <p>{Translation('mandatory.training.component.must.complete.text')}</p>
      <Form.Item name={[props.name, 'requiredCompletionType']} noStyle>
        <RadioGroup className={classes.group}>
          {Object.entries(completionRuleType).map(([label, value]) => {
            return (
              <FormControlLabel
                className={classes.radio}
                key={value}
                value={value}
                control={
                  <Radio
                    // style={{marginTop: -20}}
                    disabled={disabled}
                    onChange={(e) => {
                      const currentType = e.target.value;
                      if (currentType === completionRuleType.ALL.toString()) {
                        intialValues['requiredCompletionCount'] = '-1';
                      } else {
                        intialValues['requiredCompletionCount'] = '';
                      }

                      // onChange(intialValues);
                    }}
                    checked={value === intialValues['requiredCompletionType']}
                  />
                }
                label={Translation(`mandatory.training.component.formLabel.course.rule.${label}`)}
              />
            );
          })}
        </RadioGroup>
      </Form.Item>

      <Form.Item noStyle>
        {(_, meta, { getFieldValue }) => {
          const isSpecify = intialValues.requiredCompletionType === completionRuleType.SPECIFIY;

          return isSpecify ? (
            <Form.Item name={[props.name, 'requiredCompletionCount']} noStyle>
              {/* <TextField
                                type="number"
                                margin="dense"
                                variant="outlined"
                                disabled={disabled}
                                inputProps={disableInputInvalidValueForWeight()}
                                style={{ marginTop: 40 }}
                            /> */}

              <FormControlLabel
                className={classes.radio}
                control={
                  <TextField
                    type="number"
                    margin="dense"
                    variant="outlined"
                    disabled={disabled}
                    inputProps={disableInputInvalidValueForWeight()}
                    style={{ marginTop: 40 }}
                  />
                }
                label={
                  <div style={{ marginTop: 40, marginLeft: 15 }}>
                    {Translation('mandatory.training.component.must.complete.course.text')}
                  </div>
                }
              />
            </Form.Item>
          ) : null;
        }}
      </Form.Item>

      {/* <p style={{ marginLeft: 10}} >{Translation('mandatory.training.component.must.complete.course.text')}</p> */}
    </>
  );
};

export default RuleSelection;
