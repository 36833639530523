export const EVENT_BASE_PATH = '/event-v2';
export const EVENT_LIST_PATH = '/list';
export const EVENT_CATEGORY_PATH = '/category';

export const EVENT_TITLE = 'title.event';
export const EVENT_LIST_TITLE = 'title.event.list';
export const EVENT_CATEGORY_TITLE = 'title.event.category';

export const EVENT_LIST_NAMESPACE = 'EVENT-LIST';
export const EVENT_CATEGORIES_NAMESPACE = 'EVENT-CATEGORIES';

export enum AdminEventPermissionType {
  EVENT_LIST_READ = 'READ_ANY_EVENT-LIST',
  EVENT_LIST_CREATE = 'CREATE_ANY_EVENT-LIST',
  EVENT_LIST_UPDATE = 'UPDATE_ANY_EVENT-LIST',
  EVENT_CATEGORY_READ = 'READ_ANY_EVENT-CATEGORIES',
  EVENT_CATEGORY_CREATE = 'CREATE_ANY_EVENT-CATEGORIES',
  EVENT_CATEGORY_UPDATE = 'UPDATE_ANY_EVENT-CATEGORIES',
}
