import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import PruRoute from 'src/app/common/components/PruRoute';
import * as constants from './../constants';
import List from './List';
import Detail from './Detail';
import { ModulePermissionProps } from 'src/app/common/module/types';
import { MTmode } from './../constants';

export const topicBasePath = `${constants.PULSELEADS_LEAD_ENGAGEMENT_PATH}${constants.PULSELEADS_LEAD_ENGAGEMENT_TOPIC_PATH}`;


const EngagementTopicRoutes: FC<RouteComponentProps & ModulePermissionProps> = ({
    match,
    enableRead,
    enableCreate,
    enableUpdate
}) => {
    return (
        <Switch>
            <Redirect exact from={match.path} to={`${match.path}/list`} />
            <PruRoute
                exact
                path={`${match.path}/list`}
                render={(props) => (
                    <List
                        {...props}
                        enableRead={enableRead}
                        enableCreate={enableCreate}
                        enableUpdate={enableUpdate}
                    />
                )}
            />
            <PruRoute
                exact
                path={`${match.path}/add`}
                render={(props) => (
                    <Detail
                        {...props}
                        enableRead={enableRead}
                        enableCreate={enableCreate}
                        enableUpdate={enableUpdate}
                        formMode={MTmode.CREATE}
                    />
                )}
            />
            <PruRoute
                exact
                path={`${match.path}/edit/:id`}
                render={(props) => (
                    <Detail
                        {...props}
                        enableRead={enableRead}
                        enableCreate={enableCreate}
                        enableUpdate={enableUpdate}
                        formMode={MTmode.EDIT}
                    />
                )}
            />
            <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
        </Switch>
    )
};

export default EngagementTopicRoutes;