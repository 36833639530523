import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  dialog: {
    minHeight: 800,
  },
  dialogContainer: {
    width: '100%',
    alignSelf: 'flex-start',
  },
  dialogContent: {
    display: 'flex',
    alignItems: 'flex-start',
    marginLeft: 20,
    paddingTop: 20,
    fontSize: 18,
  },
  actionList: {
    display: 'flex',
    marginLeft: 67,
    paddingLeft: 67,
    flexDirection: 'column',
  },
  buttonContainer: {
    display: 'flex',
    marginTop: 20,
    marginLeft: 20,
  },
  dialogTitle: {
    display: 'flex',
    marginLeft: 20,
    paddingTop: 40,
    fontSize: 18,
  },
}));
