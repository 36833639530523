import { FC, useEffect, useState } from 'react';
import PageOneDetail from './page-one/page-one';
import { RouteComponentProps, useParams } from 'react-router-dom';
import PageTwoDetail from './page-two/page-two';
import { MTmode } from 'src/app/modules/MandatoryTraining/constants';
import { useDispatch, useSelector } from 'react-redux';
import { fetchActiveCampaignList, fetchMicrositeDetail } from '../../../network/api';
import { LayoutSplashScreen } from 'src/app/layout';
import { targetAgentType } from '../../../constants';
import { useIntl } from 'react-intl';
import { regionLocale } from 'src/app/i18n';
import { LeadMicrositeContentTypeEnum, LeadMicrositeStatusEnum } from '../../../types/microsite.type';
import { Configuration } from 'src/app/modules/Config/types/config-type';
import { RootState } from 'src/redux/store';
import { initJson } from 'src/app/common/components/microsite-survey-form/survey-json';
import { CampaignTypeEnum } from 'src/app/modules/PulseLeads/types/campaign-types';

type DetailProps = {
  formMode: MTmode;
};

const Detail: FC<RouteComponentProps & DetailProps> = ({ formMode, history, location }) => {
  const { id } = useParams<{ id: string }>();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const config = useSelector<RootState, Configuration | undefined>((state) => state.config.data);
  const displayProductTypes = config?.productTypes ?? [];
  const [campaignIdList, setCampaignIdList] = useState<string[]>(['']);
  const [data, setData] = useState<any>({
    status: LeadMicrositeStatusEnum.INACTIVE,
    bannerType: LeadMicrositeContentTypeEnum.DEFAULT,
    callToActionText: { [regionLocale[0]]: "I'm Ready" },
    customerDisclaimer: [{ [regionLocale[0]]: undefined }],
    submissionResponseTitle: { [regionLocale[0]]: Translation('survey.form.thankyou.title') },
    submissionResponseContent: { [regionLocale[0]]: Translation('survey.form.thankyou.content') },
    targetReceiver: targetAgentType.allAgent,
    inputFieldsConfig: initJson,
    productType: displayProductTypes[0]?.id,
  });
  const dispatch = useDispatch();
  const [originData, setOriginData] = useState<any>(data);
  const fetchDetail = async (id: string) => {
    setIsLoading(true);
    const configs = await fetchMicrositeDetail(id, dispatch);
    if (configs) {
      setData(configs);
      setOriginData(configs);
    }
    setIsLoading(false);
  };

  const fetchCampaignIdList = async () => {
    const list = await fetchActiveCampaignList({ status: 'active', type: CampaignTypeEnum.NORMAL });
    const idList = list.docs.map((item) => item.campaignId);
    setCampaignIdList(idList);
  };
  useEffect(() => {
    fetchCampaignIdList();
  }, []);
  useEffect(() => {
    if (formMode === MTmode.EDIT) {
      fetchDetail(id);
    } else {
      const urlParams = new URLSearchParams(window.location.search);
      const micrositeId = urlParams.get('id');
      if (micrositeId) {
        fetchDetail(micrositeId);
      }
    }
  }, []);

  const onPrevPage = () => {
    setPageIndex(pageIndex - 1);
  };
  const onNextPage = () => {
    setPageIndex(pageIndex + 1);
  };

  const renderSwitch = () => {
    switch (pageIndex) {
      case 0:
        return (
          <PageOneDetail
            formMode={formMode}
            data={{ ...data, campaignIdList }}
            setData={setData}
            originData={originData}
            onNextPage={onNextPage}
          />
        );
      case 1:
        return (
          <PageTwoDetail
            formMode={formMode}
            data={data}
            setData={setData}
            originData={originData}
            onPrevPage={onPrevPage}
          />
        );
      default:
        return <></>;
    }
  };

  return <div className="tw-h-full tw-w-full"> {isLoading ? <LayoutSplashScreen /> : <>{renderSwitch()}</>}</div>;
};
export default Detail;
