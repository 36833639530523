import { FC } from 'react';
import moment from 'moment';
import { map } from 'lodash';
import { useIntl } from 'react-intl';
import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton,
  InputLabel,
  RadioGroup,
  Radio,
  Select,
  MenuItem,
  TextField,
  Tooltip,
} from '@mui/material';
import { Info } from '@mui/icons-material';
import { regionLocale, useLang } from 'src/app/i18n';
import { MANDATORY_FIELD_ERROR_TEXT, NUMBER_ONLY_ERROR_TEXT, trueFalseOptions } from 'src/app/common/constants';
import { FormMode } from 'src/app/common/types';
import { ComponentProps } from 'src/app/common/components/pru-stepped-form';
import { PruDateTimePicker } from 'src/app/common/components/PruDatePicker';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import Tinymce from 'src/app/common/components/Tinymce-storage-blob';
import {
  EventAccessibilityEnum,
  RegistrationMethodEnum,
  RegistrationAttendanceRuleEnum,
  RegistrationNotificationMethodEnum,
  EventFormCommonProps,
} from 'src/app/modules/event-v2/types';
import { RegistrationAttendanceFormState } from '../../../reducers';
import { useCommonFormStyles, MultiLangAccordion } from '../../../common';
import { useRegistrationAttendanceStyles } from './registration-attendance-form.style';
import { useRegistrationAttendanceForm } from './registration-attendance-form.hook';

type EventRegistrationAttendanceFormProps = ComponentProps<EventFormCommonProps>;

export const EventRegistrationAttendanceForm: FC<EventRegistrationAttendanceFormProps> = ({
  formCommonProps,
  ...rest
}) => {
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);
  const locale = useLang();
  const { classes } = useRegistrationAttendanceStyles();
  const { classes: commonFormClasses } = useCommonFormStyles();
  const { formMode, isPublished, disableEdit, eventItem } = formCommonProps;
  const { formState, errorState, initI18nStringData, formDispatch, onDismissErrorHandler, onSubmit } =
    useRegistrationAttendanceForm({
      formCommonProps,
      ...rest,
    });
  const viewMode = formMode === FormMode.CREATE || formMode === FormMode.VIEW;

  return (
    <div className={commonFormClasses.container}>
      <div>
        <div className={commonFormClasses.sectionContainer}>
          <div className={commonFormClasses.titleContainer}>{Translation('event.form.registration')}</div>
          <div className={commonFormClasses.contentContainer}>
            {eventItem?.category.accessibility === EventAccessibilityEnum.PUBLIC && (
              <div className={commonFormClasses.fieldContainer}>
                <TextField
                  disabled={viewMode || isPublished || disableEdit}
                  className={classes.field}
                  variant="standard"
                  label={Translation('event.form.quota_per_agent')}
                  placeholder={Translation('event.form.quota_per_agent.placeholder')}
                  InputLabelProps={{ shrink: true, className: commonFormClasses.inputLabel }}
                  error={errorState.immediate.regQuotaPerAgentFormat}
                  helperText={errorState.immediate.regQuotaPerAgentFormat && NUMBER_ONLY_ERROR_TEXT}
                  value={formState.regQuotaPerAgent || ''}
                  onChange={(e) => {
                    formDispatch({
                      type: 'MODIFY_FIELD',
                      payload: { field: 'regQuotaPerAgent', value: e.target.value },
                    });
                  }}
                />
              </div>
            )}
            <div className={commonFormClasses.doubleFieldContainer}>
              <FormControl
                disabled={viewMode || disableEdit}
                className={commonFormClasses.doubleField}
                error={errorState.mandatory.regStartDate || errorState.immediate.endDateBeforeStartDate}
              >
                <InputLabel id="regStartDate-label" shrink className={commonFormClasses.inputLabel}>
                  {Translation('event.form.registration_start_time')}
                  <span className={commonFormClasses.mandatory}>*</span>
                </InputLabel>
                <PruDateTimePicker
                  disablePast
                  disabled={viewMode || disableEdit}
                  className={commonFormClasses.datePicker}
                  ampm={false}
                  slotProps={{
                    textField: {
                      variant: 'standard',
                      error: errorState.mandatory.regStartDate || errorState.immediate.endDateBeforeStartDate,
                      helperText: errorState.mandatory.regStartDate && MANDATORY_FIELD_ERROR_TEXT,
                    },
                  }}
                  format="DD/MM/YYYY HH:mm"
                  maxDateTime={eventItem?.startTime}
                  value={formState.regStartDate || null}
                  onChange={(date) => {
                    onDismissErrorHandler('regStartDate', date);
                    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'regStartDate', value: date } });
                  }}
                />
              </FormControl>
              <FormControl
                disabled={viewMode || disableEdit}
                className={commonFormClasses.doubleField}
                error={errorState.mandatory.regEndDate || errorState.immediate.endDateBeforeStartDate}
              >
                <InputLabel id="regEndDate-label" shrink className={commonFormClasses.inputLabel}>
                  {Translation('event.form.registration_end_time')}
                  <span className={commonFormClasses.mandatory}>*</span>
                </InputLabel>
                <PruDateTimePicker
                  disablePast
                  disabled={viewMode || disableEdit}
                  className={commonFormClasses.datePicker}
                  ampm={false}
                  slotProps={{
                    textField: {
                      variant: 'standard',
                      error: errorState.mandatory.regEndDate || errorState.immediate.endDateBeforeStartDate,
                      helperText: errorState.immediate.endDateBeforeStartDate
                        ? Translation('event.form.registration_time_error')
                        : errorState.mandatory.regEndDate && MANDATORY_FIELD_ERROR_TEXT,
                    },
                  }}
                  format="DD/MM/YYYY HH:mm"
                  maxDateTime={eventItem?.startTime}
                  value={formState.regEndDate || null}
                  onChange={(date) => {
                    onDismissErrorHandler('regEndDate', date);
                    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'regEndDate', value: date } });
                  }}
                />
              </FormControl>
            </div>
            <div className={commonFormClasses.fieldContainer}>
              <FormControl
                disabled={viewMode || isPublished || disableEdit}
                fullWidth
                className={classes.bottomSpacing}
                error={errorState.mandatory.regMethod}
              >
                <FormLabel className={classes.label}>
                  {Translation('event.form.registration_form')}
                  <span className={commonFormClasses.mandatory}>*</span>
                </FormLabel>
              </FormControl>
              <FormControl
                disabled={viewMode || isPublished || disableEdit}
                className={commonFormClasses.radioGroupContainer}
                error={errorState.mandatory.regMethod}
              >
                <RadioGroup
                  className={commonFormClasses.radioGroup}
                  value={formState.regMethod || ''}
                  onChange={(e) => {
                    let dismissFields = ['regMethod'];
                    const newFormState: Partial<RegistrationAttendanceFormState> = {
                      regMethod: e.target.value as RegistrationMethodEnum,
                    };
                    if (e.target.value === RegistrationMethodEnum.TEMPLATE) {
                      dismissFields.push('regLink');
                      newFormState.regLink = undefined;
                      if (!formState.regConfirmationBody) {
                        newFormState.regConfirmationBody = {
                          title: initI18nStringData,
                          body: initI18nStringData,
                        };
                      }
                    } else {
                      dismissFields.push('regNotificationMethod');
                      regionLocale.forEach((locale) => {
                        dismissFields = [
                          ...dismissFields,
                          `regConfirmationBody-title-${locale}`,
                          `regConfirmationBody-body-${locale}`,
                        ];
                      });
                      newFormState.regNotificationMethod = undefined;
                      newFormState.regConfirmationBody = undefined;
                    }
                    onDismissErrorHandler(dismissFields, true);
                    formDispatch({
                      type: 'SET_FORM_STATE',
                      payload: { value: newFormState },
                    });
                  }}
                >
                  {map(RegistrationMethodEnum, (option) => (
                    <FormControlLabel
                      className={classes.formControlLabel}
                      key={`regMethod-${option}`}
                      control={<Radio />}
                      label={Translation(`event.form.registration_form.${option.toLowerCase()}`)}
                      value={option}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
              {formState.regMethod && (
                <div
                  className={`
                    ${commonFormClasses.remark}
                    ${
                      formState.regMethod === RegistrationMethodEnum.TEMPLATE
                        ? classes.templateRemark
                        : classes.linkRemark
                    }
                  `}
                >
                  {Translation(`event.form.registration_form.${formState.regMethod.toLowerCase()}.remark`)}
                </div>
              )}
              {errorState.mandatory.regMethod && (
                <FormHelperText error={errorState.mandatory.regMethod}>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>
              )}
            </div>
            {formState.regMethod === RegistrationMethodEnum.TEMPLATE && (
              <>
                <div className={commonFormClasses.fieldContainer}>
                  <FormLabel className={classes.label}>{Translation('event.form.registration_confirmation')}</FormLabel>
                </div>
                <div className={commonFormClasses.fieldContainer}>
                  <FormControl
                    disabled={viewMode || isPublished || disableEdit}
                    className={commonFormClasses.radioGroupContainer}
                    error={errorState.mandatory.regNotificationMethod}
                  >
                    <FormLabel className={classes.radioGroupLabel}>
                      {Translation('event.form.send_via')}
                      <span className={commonFormClasses.mandatory}>*</span>
                    </FormLabel>
                    <RadioGroup
                      className={commonFormClasses.radioGroup}
                      value={formState.regNotificationMethod || ''}
                      onChange={(e) => {
                        const dismissFields = ['regNotificationMethod'];
                        const newFormState: Partial<RegistrationAttendanceFormState> = {
                          regNotificationMethod: e.target.value as RegistrationNotificationMethodEnum,
                        };
                        if (e.target.value === RegistrationNotificationMethodEnum.SMS) {
                          regionLocale.forEach((locale) => {
                            dismissFields.push(`regConfirmationBody-title-${locale}`);
                          });
                          newFormState.regConfirmationBody = {
                            title: initI18nStringData,
                            body: formState.regConfirmationBody?.body || initI18nStringData,
                          };
                        }
                        onDismissErrorHandler(dismissFields, true);
                        formDispatch({
                          type: 'SET_FORM_STATE',
                          payload: { value: newFormState },
                        });
                      }}
                    >
                      {map(RegistrationNotificationMethodEnum, (option) => (
                        <FormControlLabel
                          className={commonFormClasses.formControlLabel}
                          key={`send-via-${option}`}
                          control={<Radio />}
                          label={Translation(`event.form.send_via.${option.toLowerCase()}`)}
                          value={option}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                  <div className={`${commonFormClasses.remark} ${classes.radioGroupMargin}`}>
                    {Translation('event.form.send_via.remark')}
                  </div>
                  {errorState.mandatory.regNotificationMethod && (
                    <FormHelperText
                      className={classes.radioGroupMargin}
                      error={errorState.mandatory.regNotificationMethod}
                    >
                      {MANDATORY_FIELD_ERROR_TEXT}
                    </FormHelperText>
                  )}
                </div>
                <div className={commonFormClasses.fieldContainer}>
                  <div className={classes.bottomSpacing}>{Translation('event.form.dynamic_parameter')}</div>
                  <div className={commonFormClasses.remark}>
                    <div>{Translation('event.form.dynamic_parameter.part1')}</div>
                    <div>{Translation('event.form.dynamic_parameter.part2')}</div>
                  </div>
                </div>
                <div className={commonFormClasses.fieldContainer}>
                  <MultiLangAccordion
                    disabled={viewMode || disableEdit}
                    error={(locale) =>
                      errorState.mandatory[`regConfirmationBody-title-${locale}`] ||
                      errorState.mandatory[`regConfirmationBody-body-${locale}`]
                    }
                    fillInOnChange={(e, locale) => {
                      if (e.target.checked) {
                        onDismissErrorHandler(
                          [`regConfirmationBody-title-${locale}`, `regConfirmationBody-body-${locale}`],
                          true,
                        );
                        formDispatch({
                          type: 'FILL_IN_DETAIL',
                          payload: { fromLocale: regionLocale[0], toLocale: locale },
                        });
                      }
                    }}
                    renderChildren={(locale) => {
                      return (
                        <>
                          {formState.regNotificationMethod === RegistrationNotificationMethodEnum.EMAIL && (
                            <div className={commonFormClasses.fieldContainer}>
                              <TextField
                                disabled={viewMode || disableEdit}
                                fullWidth
                                variant="standard"
                                label={
                                  <>
                                    {Translation('event.common.title')}
                                    <span className={commonFormClasses.mandatory}>*</span>
                                  </>
                                }
                                placeholder={Translation('event.form.registration_confirmation.title.placeholder')}
                                InputLabelProps={{ shrink: true, className: commonFormClasses.inputLabel }}
                                error={errorState.mandatory[`regConfirmationBody-title-${locale}`]}
                                helperText={
                                  errorState.mandatory[`regConfirmationBody-title-${locale}`] &&
                                  MANDATORY_FIELD_ERROR_TEXT
                                }
                                value={formState.regConfirmationBody?.title[locale] || ''}
                                onChange={(e) => {
                                  onDismissErrorHandler(`regConfirmationBody-title-${locale}`, e.target.value);
                                  formDispatch({
                                    type: 'MODIFY_REG_CONFIRMATION_BODY',
                                    payload: { field: 'title', value: { [locale]: e.target.value } },
                                  });
                                }}
                              />
                            </div>
                          )}
                          <div className={commonFormClasses.fieldContainer}>
                            <FormControl
                              disabled={viewMode || disableEdit}
                              error={errorState.mandatory[`regConfirmationBody-body-${locale}`]}
                            >
                              <FormLabel className={classes.label}>
                                {Translation('event.common.body')}
                                <span className={commonFormClasses.mandatory}>*</span>
                              </FormLabel>
                            </FormControl>
                            <div className={`${commonFormClasses.remark} ${classes.bottomSpacing}`}>
                              {Translation('event.form.registration_confirmation.body.remark')}
                            </div>
                            <Tinymce
                              disabled={viewMode || disableEdit}
                              module="event"
                              accessLevel="anonymous"
                              value={formState.regConfirmationBody?.body[locale] || ''}
                              onChange={(value) => {
                                formDispatch({
                                  type: 'MODIFY_REG_CONFIRMATION_BODY',
                                  payload: { field: 'body', value: { [locale]: value } },
                                });
                              }}
                            />
                            {errorState.mandatory[`regConfirmationBody-body-${locale}`] && (
                              <FormHelperText error={errorState.mandatory[`regConfirmationBody-body-${locale}`]}>
                                {MANDATORY_FIELD_ERROR_TEXT}
                              </FormHelperText>
                            )}
                          </div>
                        </>
                      );
                    }}
                  />
                </div>
              </>
            )}
            {formState.regMethod === RegistrationMethodEnum.LINK && (
              <div className={commonFormClasses.fieldContainer}>
                <TextField
                  disabled={viewMode || isPublished || disableEdit}
                  className={classes.field}
                  variant="standard"
                  label={
                    <>
                      {Translation('event.form.registration_link')}
                      <span className={commonFormClasses.mandatory}>*</span>
                    </>
                  }
                  placeholder={Translation('event.form.registration_link.placeholder')}
                  InputLabelProps={{ shrink: true, className: commonFormClasses.inputLabel }}
                  error={errorState.mandatory.regLink || errorState.immediate.regLinkFormat}
                  helperText={
                    errorState.immediate.regLinkFormat
                      ? Translation('component.form-link-text')
                      : errorState.mandatory.regLink && MANDATORY_FIELD_ERROR_TEXT
                  }
                  value={formState.regLink || ''}
                  onChange={(e) => {
                    onDismissErrorHandler('regLink', e.target.value);
                    formDispatch({
                      type: 'MODIFY_FIELD',
                      payload: { field: 'regLink', value: e.target.value },
                    });
                  }}
                />
              </div>
            )}
          </div>
        </div>
        {formState.regMethod === RegistrationMethodEnum.TEMPLATE && (
          <div className={commonFormClasses.sectionContainer}>
            <div className={commonFormClasses.titleContainer}>{Translation('event.form.attendance')}</div>
            <div className={commonFormClasses.contentContainer}>
              {eventItem?.multiSession ? (
                <div className={commonFormClasses.fieldContainer}>
                  <div className={classes.attendanceRuleLabel}>
                    {Translation('event.form.session_attendance_rule')}
                    <span className={commonFormClasses.mandatory}>*</span>
                  </div>
                  <PruTable
                    disableBulkSelect
                    disablePagination
                    disableToolbar
                    renderChildren
                    headerBtnDef={[]}
                    operationDef={[]}
                    columnDef={[
                      {
                        keyIndex: 'session',
                        displayName: Translation('event.list.detail.sessions'),
                        renderData: (_, index, parent) =>
                          parent
                            ? `${Translation('event.form.sub_session')} ${index + 1}`
                            : `${Translation('event.form.session')} ${index + 1}`,
                      },
                      {
                        keyIndex: 'name',
                        displayName: Translation('event.list.detail.session_name'),
                        renderData: (row) => row.name[locale] || '-',
                      },
                      {
                        keyIndex: 'session_time',
                        displayName: Translation('event.form.session.time'),
                        renderData: (row) =>
                          `${moment(row.startTime).format('HH:mm')} - ${moment(row.endTime).format('HH:mm')}` || '-',
                      },
                      {
                        keyIndex: 'quota',
                        displayName: Translation('event.form.quota'),
                        renderData: (row) => row.quota || '-',
                      },
                      {
                        keyIndex: 'regAttendanceRule',
                        displayName: (
                          <span>
                            {Translation('event.form.attendance_rule')}
                            <Tooltip arrow placement="top" title={Translation('event.form.attendance_rule.tooltip')}>
                              <IconButton size={'small'} className={commonFormClasses.infoIcon}>
                                <Info />
                              </IconButton>
                            </Tooltip>
                          </span>
                        ),
                        renderData: (row, index, parent) =>
                          row.subSessions.length === 0 || parent ? (
                            <Select
                              disabled={viewMode || isPublished || disableEdit}
                              displayEmpty
                              className={classes.tableSelect}
                              variant="standard"
                              value={row.regAttendanceRule || ''}
                              renderValue={(selected) =>
                                selected ? (
                                  Translation(`event.form.attendance_rule.${selected.toLowerCase()}`)
                                ) : (
                                  <span className={commonFormClasses.placeholder}>
                                    {Translation('app.input.placeholder.please-select')}
                                  </span>
                                )
                              }
                              onChange={(e) => {
                                if (parent) {
                                  formDispatch({
                                    type: 'MODIFY_SUB_SESSION',
                                    payload: {
                                      parentIndex: parseInt(parent.key.replace('session', '')) - 1,
                                      index,
                                      value: {
                                        ...row,
                                        regAttendanceRule: e.target.value as RegistrationAttendanceRuleEnum,
                                      },
                                    },
                                  });
                                } else {
                                  formDispatch({
                                    type: 'MODIFY_SESSION',
                                    payload: {
                                      index,
                                      value: {
                                        ...row,
                                        regAttendanceRule: e.target.value as RegistrationAttendanceRuleEnum,
                                      },
                                    },
                                  });
                                }
                              }}
                            >
                              {map(RegistrationAttendanceRuleEnum, (option) => (
                                <MenuItem key={option} value={option}>
                                  {Translation(`event.form.attendance_rule.${option.toLowerCase()}`)}
                                </MenuItem>
                              ))}
                            </Select>
                          ) : (
                            '-'
                          ),
                      },
                      {
                        keyIndex: 'regAllowWalkIn',
                        displayName: (
                          <span>
                            {Translation('event.form.walk_in_allowed')}
                            <Tooltip arrow placement="top" title={Translation('event.form.walk_in_allowed.tooltip')}>
                              <IconButton size={'small'} className={commonFormClasses.infoIcon}>
                                <Info />
                              </IconButton>
                            </Tooltip>
                          </span>
                        ),
                        renderData: (row, index, parent) =>
                          row.subSessions.length === 0 || parent ? (
                            <FormControl
                              disabled={viewMode || isPublished || disableEdit}
                              variant="standard"
                              error={errorState.mandatory[`${row.key}-regAllowWalkIn`]}
                            >
                              <Select
                                displayEmpty
                                className={classes.tableSelect}
                                variant="standard"
                                value={row.regAllowWalkIn !== undefined ? row.regAllowWalkIn.toString() : ''}
                                renderValue={(selected) =>
                                  selected ? (
                                    Translation(`app.select.${selected === 'true' ? 'yes' : 'no'}`)
                                  ) : (
                                    <span className={commonFormClasses.placeholder}>
                                      {Translation('app.input.placeholder.please-select')}
                                    </span>
                                  )
                                }
                                onChange={(e) => {
                                  onDismissErrorHandler(`${row.key}-regAllowWalkIn`, true);
                                  if (parent) {
                                    formDispatch({
                                      type: 'MODIFY_SUB_SESSION',
                                      payload: {
                                        parentIndex: parseInt(parent.key.replace('session', '')) - 1,
                                        index,
                                        value: {
                                          ...row,
                                          regAllowWalkIn: e.target.value === 'true' ? true : false,
                                        },
                                      },
                                    });
                                  } else {
                                    formDispatch({
                                      type: 'MODIFY_SESSION',
                                      payload: {
                                        index,
                                        value: {
                                          ...row,
                                          regAllowWalkIn: e.target.value === 'true' ? true : false,
                                        },
                                      },
                                    });
                                  }
                                }}
                              >
                                {trueFalseOptions.map((option) => (
                                  <MenuItem key={option} value={option}>
                                    {Translation(`app.select.${option === 'true' ? 'yes' : 'no'}`)}
                                  </MenuItem>
                                ))}
                              </Select>
                              {errorState.mandatory[`${row.key}-regAllowWalkIn`] && (
                                <FormHelperText>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>
                              )}
                            </FormControl>
                          ) : (
                            '-'
                          ),
                      },
                      {
                        isId: true,
                        hidden: true,
                        keyIndex: 'key',
                        childKeyIndex: 'subSessions',
                        displayName: '',
                        renderData: () => '',
                      },
                    ]}
                    isLoading={false}
                    dataSource={formState.sessions}
                    defaultOpenedRows={formState.sessions.map((session) => session.key)}
                  />
                </div>
              ) : (
                <div className={commonFormClasses.doubleFieldContainer}>
                  <FormControl
                    disabled={viewMode || isPublished || disableEdit}
                    className={commonFormClasses.doubleField}
                    variant="standard"
                    error={errorState.mandatory.regAttendanceRule}
                  >
                    <InputLabel id="regAttendanceRule-label" shrink className={commonFormClasses.inputLabel}>
                      {Translation('event.form.attendance_rule')}
                      <span className={commonFormClasses.mandatory}>*</span>
                      <Tooltip arrow placement="top" title={Translation('event.form.attendance_rule.tooltip')}>
                        <IconButton size={'small'} className={commonFormClasses.infoIcon}>
                          <Info />
                        </IconButton>
                      </Tooltip>
                    </InputLabel>
                    <Select
                      labelId="regAttendanceRule-label"
                      displayEmpty
                      value={formState.regAttendanceRule || ''}
                      renderValue={(selected) =>
                        selected ? (
                          Translation(`event.form.attendance_rule.${selected.toLowerCase()}`)
                        ) : (
                          <span className={commonFormClasses.placeholder}>
                            {Translation('app.input.placeholder.please-select')}
                          </span>
                        )
                      }
                      onChange={(e) => {
                        onDismissErrorHandler('regAttendanceRule', true);
                        formDispatch({
                          type: 'MODIFY_FIELD',
                          payload: {
                            field: 'regAttendanceRule',
                            value: e.target.value,
                          },
                        });
                      }}
                    >
                      {map(RegistrationAttendanceRuleEnum, (option) => (
                        <MenuItem key={option} value={option}>
                          {Translation(`event.form.attendance_rule.${option.toLowerCase()}`)}
                        </MenuItem>
                      ))}
                    </Select>
                    {errorState.mandatory.regAttendanceRule && (
                      <FormHelperText>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>
                    )}
                  </FormControl>
                  <FormControl
                    disabled={viewMode || isPublished || disableEdit}
                    className={commonFormClasses.doubleField}
                    variant="standard"
                    error={errorState.mandatory.regAllowWalkIn}
                  >
                    <InputLabel id="regAllowWalkIn-label" shrink className={commonFormClasses.inputLabel}>
                      {Translation('event.form.walk_in_allowed')}
                      <span className={commonFormClasses.mandatory}>*</span>
                      <Tooltip arrow placement="top" title={Translation('event.form.walk_in_allowed.tooltip')}>
                        <IconButton size={'small'} className={commonFormClasses.infoIcon}>
                          <Info />
                        </IconButton>
                      </Tooltip>
                    </InputLabel>
                    <Select
                      labelId="regAllowWalkIn-label"
                      displayEmpty
                      value={typeof formState.regAllowWalkIn === 'boolean' ? formState.regAllowWalkIn.toString() : ''}
                      renderValue={(selected) =>
                        selected ? (
                          Translation(`app.select.${selected === 'true' ? 'yes' : 'no'}`)
                        ) : (
                          <span className={commonFormClasses.placeholder}>
                            {Translation('app.input.placeholder.please-select')}
                          </span>
                        )
                      }
                      onChange={(e) => {
                        onDismissErrorHandler('regAllowWalkIn', true);
                        formDispatch({
                          type: 'MODIFY_FIELD',
                          payload: {
                            field: 'regAllowWalkIn',
                            value: e.target.value === 'true' ? true : false,
                          },
                        });
                      }}
                    >
                      {trueFalseOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                          {Translation(`app.select.${option === 'true' ? 'yes' : 'no'}`)}
                        </MenuItem>
                      ))}
                    </Select>
                    {errorState.mandatory.regAllowWalkIn && (
                      <FormHelperText>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>
                    )}
                  </FormControl>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {!viewMode && !disableEdit && (
        <div className={commonFormClasses.footerContainer}>
          <Button variant="contained" color="secondary" onClick={() => onSubmit()}>
            {Translation('app.button.save_and_continue')}
          </Button>
        </div>
      )}
    </div>
  );
};
