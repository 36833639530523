import { getBaseAppQuickPaths, getConfigurations } from 'src/app/common/utils';
import { PRUdnaHomeEntryKeyPath } from '../../constants';

export const massPRUdnaSharingIsEnabled = () =>
  getBaseAppQuickPaths()?.find((path: string) => path.includes(PRUdnaHomeEntryKeyPath));

export const prudnaIsolatedModeIsEnabled = () => {
  const recruitmentConfigs = getConfigurations()?.Recruitment;
  return recruitmentConfigs?.enablePRUdnaIsolatedMode;
};
