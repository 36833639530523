import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  page: {
    '.detail-title': {
      fontSize: '1.2rem',
      fontWeight: 'bold',
      paddingLeft: '10px',
      borderLeft: 'solid 4px #ED1B2D',
      // marginBottom: '40px',
      color: '#000',
    },
    '.blue-btn': {
      background: '#4386E5',
      color: '#FFF',
      '&:hover': {
        color: '#333333',
      },
    },
    '.gray-btn': {
      background: '#E6E6E6',
      color: '#333333',
    },
    '.step-btns': {
      marginTop: '2rem',
      '.gray-btn,.blue-btn': {
        marginLeft: '10px',
      },
    },
    '.required': {
      '&:after': {
        content: '"*"',
        color: 'red',
        position: 'relative',
        top: '2px',
        left: '2px',
      },
    },
  },
  step: {
    pointerEvents: 'none',
    padding: '2rem 0',
    '.MuiStepConnector-line': {
      borderColor: '#E5E5E5',
    },
    '.MuiStep-root': {
      // cursor: 'pointer',
      '.Mui-completed': {
        '&.MuiStepIcon-root': {
          color: '#E8192C',
        },
        '&.MuiStepLabel-label': {
          color: '#333333',
        },
      },
      '.Mui-active': {
        '&.MuiStepIcon-root': {
          color: '#E8192C',
        },
        '&.MuiStepLabel-label': {
          color: '#E8192C',
        },
      },
    },
    '.MuiStepIcon-root': {
      color: '#CCC',
    },
    '.MuiStepIcon-text': {
      fill: 'white',
      fontSize: '1rem',
      fontWeight: 'bold',
    },
  },
  step1: {
    '.step1-row': {
      marginTop: '30px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '.step1-label': {
        minWidth: '150px',
      },
      '&.col-top': {
        alignItems: 'flex-start',
      },
    },
    '.error-date .MuiFormHelperText-root,.error-date .valid-from-label': {
      color: '#E8192C',
    },
    // height: 'calc(-210px + 100vh)',
    // background: '#F4F4F4',
    // padding: '16px',
  },
  step2: {
    background: '#f1f1f1',
    padding: '1rem',
  },
  step3: {
    '.download-key-btn': {
      color: '#4386E5',
    },
    '.download-keys': {
      textDecoration: 'underline',
      color: '#4386E5',
      marginLeft: '3px',
      fontWeight: '600',
      fontSize: '14px',
    },
    '.link-pdf-template': {
      marginBottom: '60px',
    },
    '.en-ct': {
      marginBottom: '40px',
    },
    '.ct-title': {
      marginBottom: '20px',
    },
  },
}));
