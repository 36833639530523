import React, { FC, useState, useEffect, useMemo } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { LayoutSplashScreen } from 'src/app/layout';
import { LeadSourceFormMode, LeadSource } from 'src/app/modules/PulseLeads/types/source-types';
import { CampaignDropdownList, CampaignDropdownType } from 'src/app/modules/PulseLeads/types/campaign-types';
import { fetchLeadSource } from 'src/app/modules/PulseLeads/network/sourceCrud';
import {
  fetchCampaignDropdownList,
  fetchConfig,
  CampaignDropdownListParam,
  ConfigParam,
} from 'src/app/modules/PulseLeads/network/campaignCrud';
import LeadSourceForm from './components/LeadSourceForm';

const LeadSourceDetailPage: FC<RouteComponentProps> = ({ history, location }) => {
  const { id } = useParams<{ id: string }>();
  const { formMode } = useMemo(
    () => ({
      formMode: id ? LeadSourceFormMode.EDIT : LeadSourceFormMode.CREATE,
    }),
    [id],
  );

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [leadSource, setLeadSource] = useState<LeadSource>();
  const [leadSourceDropdownList, setLeadSourceDropdownList] = useState<CampaignDropdownList>();
  const [config, setConfig] = useState<any>();

  const reloadData = async () => {
    if (id) {
      setIsLoading(true);
      try {
        const res = await fetchLeadSource(id, dispatch);
        setLeadSource(res);
      } catch (err) {
        history.push(NOT_FOUND_ERROR_PAGE_PATH);
      }
    }
    try {
      const params: CampaignDropdownListParam = {
        type: [CampaignDropdownType.LEAD_SOURCE],
      };
      const dropdownListRes = await fetchCampaignDropdownList(params, dispatch);
      setLeadSourceDropdownList(dropdownListRes);
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  const reloadConfig = async () => {
    const configParams: ConfigParam = {
      name: 'sales-sdk',
      namespace: 'default',
    };
    const configRes = await fetchConfig(configParams, dispatch);
    setConfig(configRes.data[0]);
  };

  useEffect(() => {
    reloadConfig();
  }, []);

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line
  }, [id]);

  return (
    <>
      {formMode === LeadSourceFormMode.CREATE && (
        <LeadSourceForm formMode={formMode} leadSourceDropdownList={leadSourceDropdownList} config={config} />
      )}
      {formMode === LeadSourceFormMode.EDIT &&
        (isLoading || !leadSource ? (
          <LayoutSplashScreen />
        ) : (
          <LeadSourceForm
            formMode={formMode}
            leadSource={leadSource}
            leadSourceDropdownList={leadSourceDropdownList}
            config={config}
          />
        ))}
    </>
  );
};

export default LeadSourceDetailPage;
