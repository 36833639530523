import React from 'react';

import { useIntl } from 'react-intl';
import { TextField } from '@mui/material';

import { InsuranceSummaryStyles } from '../style/insuranceSummaryStyle';
import {
  InsuranceSummaryState,
  CommentaryMessage,
} from 'src/app/modules/sales-kit/pages/insurance-summary/type/insurance-summary-types';

import Form from 'src/app/common/components/Form/index';
type CommentaryKey = keyof CommentaryMessage;
interface CommentaryModuleProps<T> {
  title: string;
  theme: string;

  fun: (item: any) => void;

  formState: InsuranceSummaryState;
}
const displayField = {
  life: 'Life',
  medical: 'Medical',
  criticalIllness: 'Critical Illness',
  savings: 'Saving',
  others: 'Others',
};
type values = (typeof displayField)[keyof typeof displayField];
const CommentaryModule = <T extends Record<string, unknown>>({ title, fun, formState }: CommentaryModuleProps<T>) => {
  const { classes } = InsuranceSummaryStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const renderList: React.ReactNode = Object.keys(displayField).map(function (item, index) {
    return (
      <div key={`${item}+${index}`}>
        <FormField
          fun={fun}
          title={displayField[item as keyof typeof displayField]}
          value={item as CommentaryKey}
          formState={formState}
          index={index}
        />
      </div>
    );
  });

  return (
    <>
      <div style={{ marginBottom: 20 }}>
        <Form.Item
          label={Translation(title)}
          required={false}
          style={{
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '22px',
          }}
        >
          <div
            style={{
              fontFamily: 'Inter',
              fontStyle: 'italic',
              fontWeight: 500,
              fontSize: '12px',
              lineHeight: '24px',
              paddingLeft: '22px',
            }}
          >
            Please input {'{}'} to define data field name which relevant data will be extracted.
            <br /> Example: In the case of the insured's advent of death, your beneficiaries will receive an amount of{' '}
            {'{totalAccidentalDeathBenefitCat1}'}.
          </div>
        </Form.Item>

        <div>{renderList}</div>
      </div>
    </>
  );
};

export default CommentaryModule;

const FormField = ({
  fun,
  title,
  formState,
  index,
  value,
}: {
  fun: (item: any) => void;
  title: values;
  formState: InsuranceSummaryState;
  value: CommentaryKey;
  index: number;
}) => {
  const { classes } = InsuranceSummaryStyles();

  return (
    <>
      <div>
        <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 30 }}>
          <div style={{ marginBottom: 10 }} className={classes.rowContainer}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'flex-end',
              }}
              className={classes.fieldContainer}
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span style={{ display: 'block', float: 'left' }} className={classes.field}>
                  Category {index + 1}
                </span>
              </div>
            </div>
            <div className={classes.textfieldContainer}>
              <span className={classes.field}> {title}</span>
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 30 }}>
          <div style={{ marginBottom: 10 }} className={classes.rowContainer}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'flex-end',
              }}
              className={classes.fieldContainer}
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span style={{ display: 'block', float: 'left' }} className={classes.field}>
                  Comments
                </span>
                <span className={classes.field}> (Local)</span>
              </div>
            </div>
            <div className={classes.textfieldContainer}>
              <TextField
                variant="outlined"
                fullWidth
                value={formState.commentaryMessage[value]['local'] || ''}
                onChange={(e) => {
                  formState.commentaryMessage[value]['local'] = e.target.value;

                  fun(formState.commentaryMessage);
                }}
              />
            </div>
          </div>
          <div style={{ marginBottom: 20 }} className={classes.rowContainer}>
            <div
              style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'flex-end' }}
              className={classes.fieldContainer}
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span className={classes.field}>Comments </span>
                <span className={classes.field}> (English) </span>
              </div>
            </div>
            <div className={classes.textfieldContainer}>
              <TextField
                variant="outlined"
                fullWidth
                value={formState.commentaryMessage[value]['enUs']}
                onChange={(e) => {
                  formState.commentaryMessage[value]['enUs'] = e.target.value;

                  fun(formState.commentaryMessage);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
