import React, { FC, useState, useEffect, useMemo } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { MassUploadStatusListItem } from 'src/app/modules/Profile/types/agent-profile-types';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { fetchMassUploadFailStatusById } from 'src/app/modules/Profile/network/agentProfileCrud';
import MassUploadFailList from './MassUploadFailList';

const MassUploadFailDetailPage: FC<RouteComponentProps> = ({ history }) => {
  const { id } = useParams<{ id: string }>();

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [failListItem, setFailListItem] = useState<MassUploadStatusListItem>();

  const reloadData = async () => {
    try {
      if (id) {
        setIsLoading(true);
        try {
          const data = await fetchMassUploadFailStatusById(id, dispatch);
          setFailListItem(data);
        } catch (err) {
          history.push(NOT_FOUND_ERROR_PAGE_PATH);
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line
  }, [id]);

  return (
    <>
      <MassUploadFailList isLoading={isLoading} failList={failListItem} />
    </>
  );
};

export default MassUploadFailDetailPage;
