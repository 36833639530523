import { FC, useReducer, useEffect, useRef } from 'react';
import { map } from 'lodash';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { FormControl, FormControlLabel, FormHelperText, FormLabel, RadioGroup, Radio } from '@mui/material';
import { createBlob, getBlob } from 'src/app/common/network';
import { fileUpload, preFillZero } from 'src/app/common/utils';
import { MANDATORY_FIELD_ERROR_TEXT } from 'src/app/common/constants';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import { TargetAgentsByEnum, TargetApplicantRadioModeProps } from '../types';
import { useRadioStyles } from './target-applicant-radio-mode.style';
import { radioModeFormReducer, RadioModeFormState } from './form-reducer';
import { UploadAgentList } from './components/upload-agent-list.component';
import { PresetAgentGroup } from './components/preset-agent-group.component';

export const TargetApplicantRadioMode: FC<TargetApplicantRadioModeProps> = ({
  isDisabled,
  module,
  designationList,
  zoneList,
  initialFormState,
  errorState,
  errorFieldPrefix = '',
  onChange,
  onDismissErrorHandler,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { classes } = useRadioStyles();

  const [formState, formDispatch] = useReducer(radioModeFormReducer, initialFormState);
  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      onChange(formState);
    }
  }, [formState]);

  const handleFile = async (
    e: React.ChangeEvent<HTMLInputElement>,
    fileField: keyof RadioModeFormState,
    valueField: keyof RadioModeFormState,
  ) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      try {
        const createBlobRes = await createBlob({ mimeType: file.type, accessLevel: 'public', module }, dispatch);
        await fileUpload(createBlobRes.url, file);
        const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);
        const result = blobDetail[0];
        if (result) {
          formDispatch({
            type: 'MODIFY_FIELD',
            payload: { field: fileField, value: { blobId: result.blobId, filename: file.name, url: result.url } },
          });
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: `Upload file successfully - ${file.name}`,
              },
            ]),
          );
          const reader = new FileReader();
          reader.onload = () => {
            const csvData = String(reader.result);
            const row = csvData.split('\n');
            const agentList: string[] = [];
            row.shift();
            row.forEach((item) => {
              const rowItems = item.split(',');
              if (rowItems[0] && rowItems[0].trim()) {
                agentList.push(preFillZero(rowItems[0].trim()));
              }
            });
            formDispatch({
              type: 'MODIFY_FIELD',
              payload: { field: valueField, value: agentList },
            });
          };
          reader.readAsBinaryString(file);
        }
      } catch (err) {}
    }
  };

  return (
    <>
      <div className={classes.fieldContainer}>
        <FormControl
          disabled={isDisabled}
          className={classes.radioGroupContainer}
          error={errorState.mandatory[`${errorFieldPrefix}targetAgentBy`]}
        >
          <FormLabel className={classes.radioGroupLabel}>
            {Translation('component.formLabel.target-agents-by')}
            <span className={classes.mandatory}>*</span>
          </FormLabel>
          <RadioGroup
            className={classes.radioGroup}
            value={formState.targetAgentBy || ''}
            onChange={(e) => {
              const dismissFields = [
                `${errorFieldPrefix}targetAgentBy`,
                `${errorFieldPrefix}eligibleDesignations`,
                `${errorFieldPrefix}eligibleZones`,
              ];
              onDismissErrorHandler(dismissFields, e.target.value);
              formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'targetAgentBy', value: e.target.value } });
            }}
          >
            {map(TargetAgentsByEnum, (option) => (
              <FormControlLabel
                key={`targetAgentBy-${option}`}
                control={<Radio />}
                label={Translation(`targetApplicantSection.common.target_agents_by.${option.toLowerCase()}`)}
                value={option}
              />
            ))}
          </RadioGroup>
        </FormControl>
        {errorState.mandatory[`${errorFieldPrefix}targetAgentBy`] && (
          <FormHelperText
            className={classes.rightSideMargin}
            error={errorState.mandatory[`${errorFieldPrefix}targetAgentBy`]}
          >
            {MANDATORY_FIELD_ERROR_TEXT}
          </FormHelperText>
        )}
      </div>
      {formState.targetAgentBy === TargetAgentsByEnum.UPLOAD_AGENT_LIST && (
        <UploadAgentList
          isDisabled={isDisabled}
          formState={formState}
          formDispatch={formDispatch}
          handleFile={handleFile}
        />
      )}
      {formState.targetAgentBy === TargetAgentsByEnum.PRESET_GROUP && (
        <PresetAgentGroup
          isDisabled={isDisabled}
          designationList={designationList}
          zoneList={zoneList}
          formState={formState}
          errorState={errorState}
          errorFieldPrefix={errorFieldPrefix}
          formDispatch={formDispatch}
          onDismissErrorHandler={onDismissErrorHandler}
          handleFile={handleFile}
        />
      )}
    </>
  );
};
