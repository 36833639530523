import { Button, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { Translation } from 'src/app/i18n';

export interface OptionsModalProps {
  handleOption1: Function;
  description?: string;
  handleOption2: Function;
  option1Text?: string;
  option2Text?: string;
  onClose?: Function;
}
const dialogStyle = {
  margin: 10,
  display: 'flex',
  justifyContent: 'flex-end',
};

export const OptionsModalView = (props: OptionsModalProps) => {
  const { handleOption1, handleOption2, description, option1Text, option2Text, onClose } = props;
  return (
    <div className="tw-rounded-lg">
      {description && (
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions style={dialogStyle}>
        <Button
          onClick={() => {
            const promiseInstance = handleOption1?.();
            onClose?.({ type: 'resolve' }, promiseInstance);
          }}
          variant="text"
          color="secondary"
        >
          {option1Text ?? Translation('app.button.cancel')}
        </Button>
        <Button
          onClick={() => {
            const promiseInstance = handleOption2?.();
            onClose?.({ type: 'resolve' }, promiseInstance);
          }}
          variant="text"
          color="secondary"
        >
          {option2Text ?? Translation('app.button.confirm')}
        </Button>
      </DialogActions>
    </div>
  );
};
