import React from 'react';
import { GlobalHelper } from '../../helpers/GlobalHelpers';
import { CloseModalProps, PruModalProps } from './modal-context';
import { ConfirmModalProps, ConfirmModalView } from './modals/confirm-modal-view';
import { OptionsModalProps, OptionsModalView } from './modals/options-modal-view';
import { ConfirmModalCheckedboxProps, ConfirmModalCheckedboxView } from './modals/confirm-modal-checkedbox-view';

class _ModalService {
  modalRef: any;
  promiseRef: any;
  // eslint-disable-next-line @typescript-eslint/lines-between-class-members
  constructor() {
    this.modalRef = GlobalHelper.getGlobalModalRef();
    this.promiseRef = new Promise<void>((resolve, reject) => resolve());
  }
  init() {
    this.modalRef = GlobalHelper.getGlobalModalRef();
    this.promiseRef = new Promise<void>((resolve, reject) => resolve());
  }
  /**
   *
   * @param {CloseModalProps} props
   *
   *
   * Description:
   *
   * close modal with a provided promise, if no promise provide, modal will dismiss directly
   *
   * but if a promise provided:
   *
   * 1. the modal will reopen when the promise resolved
   * 2. the modal will dismiss when the promise rejected
   *
   * Usage:
   *
   * ModalService.open({
      customHeader: <div />,
      children: (
        <SomeModal
          rawData={rawData}
          arrColumn={dataList.arrColumn}
          arrWeight={dataList.arrWeight}
          onSave={onSave}
        />
      ),
    });
    NOTE: ModalService.open() will auto inject the fn: onClose method to children <SomeModal/>, so inside SomeModal we can directly call onClose() to close the modal

   * ```jsx
   * const reopenPromiseRef = React.useRef<{
   *   resolve: () => void;
   *   reject: () => void;
   * }>();
   * ModalService.close({
   *   reopenPromise: new Promise<void>((resolve, reject) => {
   *     reopenPromiseRef.current = { resolve, reject };
   *   }),
   * });
   * ```
   */
  close(props?: CloseModalProps) {
    this.modalRef?.close?.(props ?? {});
  }
  open({ title, children, ...rest }: PruModalProps | any) {
    return this.promiseRef.finally(() => {
      this.promiseRef = this.modalRef?.open?.({
        title,
        children,
        ...rest,
      });
      return this.promiseRef;
    });
  }
  confirm(option: ConfirmModalProps & { title: string }) {
    const result = this.promiseRef.finally(() => {
      this.promiseRef = this.modalRef?.open?.({
        title: option.title,
        children: <ConfirmModalView {...option} />,
      });
      return this.promiseRef;
    });
    return result;
  }
  confirmWithCheckbox(option: ConfirmModalCheckedboxProps & { title: string }) {
    const result = this.promiseRef.finally(() => {
      this.promiseRef = this.modalRef?.open?.({
        title: option.title,
        children: <ConfirmModalCheckedboxView {...option} />,
      });
      return this.promiseRef;
    });
    return result;
  }
  options(option: OptionsModalProps & { title: string }) {
    const result = this.promiseRef.finally(() => {
      this.promiseRef = this.modalRef?.open?.({
        title: option.title,
        children: <OptionsModalView {...option} />,
      });
      return this.promiseRef;
    });
    return result;
  }
  openSubModal({ title, children }: { title?: string; children: JSX.Element }) {
    return this.modalRef?.openSubModal?.({
      title,
      children,
    });
  }
}

export const ModalService = new _ModalService();
