import { forEach } from 'lodash';
import { Dispatch } from 'react';
import { getDayStart, getDayEnd } from 'src/app/common/utils';
import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import { completeMessage } from '../redux/CustomerRedux';
import {
  CustomerSurveyDetail,
  SurveyPaginateList,
  CustomerSurveyResultsPaginate,
  SurveyResult,
  SurveySummaryResult,
} from './types';

export type CustomerSurveyItemBody = {
  startDate: Date | null;
  endDate: Date | null;
  body: Record<string, unknown>;
  consents: string[];
  completeMessage: completeMessage;
  surveyImage: string;
  // banner: string;
  flyerMessage: string;
  bannerId: string;
  status: string;
  enableTargetAgents: boolean;
  targetAgents: Array<{ agentCode: string }>;
};

export type CustomerSurveyResultsPaginateParam = {
  page: number;
  limit: number;
};
export type SurveyResultListParam = {
  startDateFrom: Date | null;
  startDateTo: Date | null;
  endDateFrom: Date | null;
  endDateTo: Date | null;
  limit: number;
  page: number;
};

export const fetchCustomerSurveyItem = async (id: string, dispatch?: Dispatch<any>): Promise<CustomerSurveyDetail> => {
  return apiClient
    .get<CustomerSurveyDetail>(`${ADMIN_URL}/customer-surveys/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchCustomerSurveyResultList = async (
  params: SurveyResultListParam,
  sortKeys: { key: string; value?: string }[],
  dispatch?: Dispatch<any>,
): Promise<SurveyPaginateList> => {
  let queryPath = `${ADMIN_URL}/customer-surveys?`;

  forEach(params, (param, key) => {
    if (typeof param === 'object') {
      if (param) {
        if (key.toLowerCase().includes('from')) {
          queryPath += `${key}=${encodeURIComponent(getDayStart(param).toISOString())}&`;
        }
        if (key.toLowerCase().includes('to')) {
          queryPath += `${key}=${encodeURIComponent(getDayEnd(param).toISOString())}&`;
        }
      }
    } else {
      queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
      // queryPath =
      //   "https://57cc42d6-2731-45c2-91d8-f0037d4649c4.mock.pstmn.io/customer-surveys";
    }
  });

  forEach(sortKeys, (sortParams, key) => {
    if (sortParams.value) {
      const sortValue = sortParams.value === 'asc' ? 'ascending' : 'descending';
      if (key === 0) {
        queryPath += `${sortParams.key}=${sortValue}&`;
      } else {
        queryPath += `${sortParams.key}=${sortValue}`;
      }
    }
  });

  if (!queryPath.includes('Sort')) queryPath += `lastUpdateDateSort=descending`;

  return apiClient
    .get<SurveyPaginateList>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createCustomerSurvey = async (body: CustomerSurveyItemBody, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .post<void>(`${ADMIN_URL}/customer-surveys`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const modifyCustomerSurvey = async (
  id: string,
  body: CustomerSurveyItemBody,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .patch<void>(`${ADMIN_URL}/customer-surveys/${id}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchCustomerSurveyResults = async (
  id: string,
  params: CustomerSurveyResultsPaginateParam,
  dispatch?: Dispatch<any>,
): Promise<SurveyResult> => {
  let queryPath = `${ADMIN_URL}/customer-surveys/${id}/results?`;
  forEach(params, (param, key) => {
    if (key === 'page') {
      queryPath += `${key}=${encodeURIComponent(param as any)}&`;
    } else {
      queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
    }
  });

  return apiClient
    .get<SurveyResult>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const downloadCustomerSurveyResults = async (id: string, dispatch?: Dispatch<any>): Promise<string> => {
  return apiClient
    .get<string>(`${ADMIN_URL}/customer-surveys/${id}/results/download`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const downloadCustomerSurveyResultsPaginate = async (
  id: string,
  params: CustomerSurveyResultsPaginateParam,
  dispatch?: Dispatch<any>,
): Promise<CustomerSurveyResultsPaginate> => {
  let queryPath = `${ADMIN_URL}/customer-surveys/${id}/results/csv-stream?`;
  forEach(params, (param, key) => {
    if (key === 'page') {
      queryPath += `${key}=${encodeURIComponent(param as any)}&`;
    } else {
      queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
    }
  });

  return apiClient
    .get<CustomerSurveyResultsPaginate>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchCustomerSurveySummary = async (
  id: string,
  dispatch?: Dispatch<any>,
): Promise<SurveySummaryResult> => {
  return apiClient
    .get<SurveySummaryResult>(`${ADMIN_URL}/customer-surveys/${id}/statistics`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
