import React, { FC, useEffect, useRef, useState } from 'react';
import Form from 'src/app/common/components/Form';
import { useIntl } from 'react-intl';
import { Button } from '@mui/material';
import { useParams } from 'react-router-dom';
import AgentsTable from './agentsTable';
import { ActionType } from 'src/app/common/components/ProTable';
import { commonStyles } from './style';
import { useDispatch } from 'react-redux';
import { AlertType, appendAlertItem } from 'src/redux/common/commonSlice';
import { utils, read } from 'xlsx';
import { flatten, difference } from 'lodash';
import { ModalService } from 'src/app/common/components/pru-modal/modal-service';
import { newTemplate } from './util';

export type AgentArray = Array<{ agentCode: string }>;

const AgentList: FC<any> = ({ setData, data, disabled }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [agentList, setAgentList] = useState<AgentArray>(data);
  const [selectAgent, setSeletAgent] = useState<AgentArray>([]);
  const actionRef = useRef<ActionType>();
  const dispatch = useDispatch();
  const [isLoading, setIsloading] = useState<boolean>(false);
  const agentCodeLength = Number(window.envConfig['REACT_APP_AGENT_CODE_LENGTH']) ?? 8;

  useEffect(() => {
    if (data?.length !== agentList?.length) {
      setData(agentList);
    }
  }, [agentList, data]);

  const onDelete = () => {
    const deleteAgent = () => {
      const tableData = agentList.filter((item: any) => !selectAgent.includes(item));
      setAgentList(tableData);
      actionRef.current?.reload();
    };
    ModalService.confirm({
      title: Translation('survey.common.delete.title'),
      onConfirm: () => {
        deleteAgent();
      },
      confirmText: Translation('button_confirm'),
      cancelText: Translation('button_cancel'),
    });
  };

  const transformAgentCode = (list: string[]) => {
    return list.map((agentCode) => {
      if (agentCode.length < agentCodeLength) {
        return agentCode.padStart(agentCodeLength, '0');
      } else {
        return agentCode;
      }
    });
  };

  const convertList = (data: any[]) => {
    let tempData = data;
    tempData.shift();
    tempData = flatten(tempData);
    tempData = tempData.map((item: any) => String(item));
    tempData = transformAgentCode(tempData);
    tempData = new Array(...new Set(tempData));
    return tempData;
  };

  const getTotalAgentList = (inputList: string[]) => {
    const agentStringList = agentList?.map((item: any) => item?.agentCode);
    const newAgentStringList = difference(inputList, agentStringList);
    let totalAgentList = [];
    if (newAgentStringList && newAgentStringList.length > 0) {
      const newAgentList = newAgentStringList.map((item) => {
        return { agentCode: item };
      });
      totalAgentList = agentList?.concat(newAgentList);
      totalAgentList = totalAgentList.sort((itemA: { agentCode: string }, itemB: { agentCode: string }) => {
        return Number(itemA.agentCode) - Number(itemB.agentCode);
      });
      return totalAgentList;
    } else {
      return inputList;
    }
  };

  const handleFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      try {
        setIsloading(true);
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = async (e) => {
          const fileData = e.target?.result;
          const workBook = read(fileData);
          const workSheetName = workBook.SheetNames[0];
          const workSheet = workBook.Sheets[workSheetName];
          const data = utils.sheet_to_json(workSheet, { header: 1 });
          let newAgentList = convertList(data);
          if (newAgentList.length > 0) {
            newAgentList = newAgentList.filter((item) => {
              return agentList?.every((oldItem) => item !== oldItem.agentCode);
            });
          }
          const totalAgentList: any[] = getTotalAgentList(newAgentList);
          if (totalAgentList.length > 0) {
            setAgentList(totalAgentList);
            actionRef.current?.reload();
          }

          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: Translation('global.submit.success'),
                content: `${Translation('common.upload.file.agentList')} - ${file.name}`,
              },
            ]),
          );
        };
        if (e.target.files) {
          reader.readAsArrayBuffer(file);
        }
      } finally {
        setIsloading(false);
      }
    }
  };

  return (
    <div className="tw-flex-grow">
      <div className="tw-pb-3">
        <input
          id="upload-agent-file"
          hidden
          type="file"
          accept=".xlsx"
          onClick={(e) => {
            const element = e.target as HTMLInputElement;
            element.value = '';
          }}
          disabled={disabled}
          onChange={handleFile}
        />
        <div className="tw-flex">
          <Button
            // disabled={!enableDraftUpdate && !enableActiveUpdate}
            variant="contained"
            color="secondary"
            disabled={disabled}
            onClick={() => {
              document.getElementById('upload-agent-file')!.click();
            }}
          >
            {Translation('app.button.upload')}
          </Button>
          {agentList?.length > 0 && (
            <span>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  onDelete();
                }}
                disabled={disabled}
                style={commonStyles.ml20}
              >
                {Translation('app.button.delete')}
              </Button>
            </span>
          )}
          <Button color="secondary" onClick={newTemplate} style={commonStyles.downloadText} disabled={disabled}>
            {Translation('pulseleads.eligibilityRule.form.downloadTemplate')}
          </Button>
        </div>
      </div>

      <div>
        {agentList?.length > 0 ? (
          <AgentsTable data={agentList} setSeletAgent={setSeletAgent} actionRef={actionRef} disabled={disabled} />
        ) : (
          <div>{Translation('survey.common.targetAgentType.noAgent')}</div>
        )}
      </div>
    </div>
  );
};

export default AgentList;
