import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  wrap: {
    borderRadius: 5,
    margin: theme.spacing(1),
  },
  error: {
    backgroundColor: 'red',
  },
  modalButtons: {
    marginTop: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    width: '100%',
    alignSelf: 'flex-start',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  marginTop: {
    marginTop: 15,
  },
  content: {
    marginRight: 15,
  },
  backButtonContainer: {
    display: 'flex',
    marginTop: 20,
  },
  backButton: {
    marginLeft: 20,
  },
  tableContainer: {
    padding: 20,
  },
  modalButton: {
    marginLeft: 20,
  },
}));
