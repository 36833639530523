import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import PruRoute from 'src/app/common/components/PruRoute';
import { RECRUIT_BASE_PATH, RECRUIT_GPS_LOCATION_PATH } from '../../constants';
import ParamsProvider from 'src/app/common/components/ParamsProvider';
import GpsLocationListingPage, { gpsLocationFilterKeys } from './List/GpsLocationListingPage';

export const gpsLocationPath = `${RECRUIT_BASE_PATH}${RECRUIT_GPS_LOCATION_PATH}`;

const GpsLocationRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect exact from={gpsLocationPath} to={`${gpsLocationPath}/list`} />
      <PruRoute exact path={`${gpsLocationPath}/list`} render={(props) => (
        <ParamsProvider {...props} acceptKeys={gpsLocationFilterKeys}>
          <GpsLocationListingPage />
        </ParamsProvider>
      )}/>
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  )
}

export default GpsLocationRoutes;