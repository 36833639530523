import React, { FC, useEffect, useRef, useState } from 'react';
import Form from 'src/app/common/components/Form';
import { useIntl } from 'react-intl';
import { Button } from '@mui/material';
import { useParams } from 'react-router-dom';
import AgentsTable from './agentsTable';
import { ActionType } from 'src/app/common/components/ProTable';
import { commonStyles } from './style';
import { ModalService } from '../../../../../../../../common/components/pru-modal/modal-service';
import { useDispatch } from 'react-redux';
import { styles } from '../style';
import { AlertType, appendAlertItem } from 'src/redux/common/commonSlice';
import { checkTargetAgentList } from '../../../api';
import { utils, read } from 'xlsx';
import { flatten, difference } from 'lodash';
import { newTemplate } from '../util';
import { MTmode } from '../../../constants';

const TargetAgent: FC<any> = ({ formMode, form, data }) => {
  const intl = useIntl();
  const { id } = useParams<{ id: string }>();
  const Translation = (id: string) => intl.formatMessage({ id });
  const defaultAgent = form.getFieldValue('isDefault') === true;
  const [agentList, setAgentList] = useState<any[]>([]);
  const [hasError, setHasError] = useState<boolean>(false);
  const [selectAgent, setSeletAgent] = useState<any[]>([]);
  const actionRef = useRef<ActionType>();
  const DEFAULT_REQUIRED_RULES = { required: true, message: Translation('component.form-required-text') };
  const { classes: detailClass } = styles();
  const dispatch = useDispatch();
  const [isLoading, setIsloading] = useState<boolean>(false);
  const isEdit = formMode === MTmode.EDIT;
  useEffect(() => {
    form.setFieldsValue({ agentList: agentList });
  }, [agentList]);

  useEffect(() => {
    if (data?.length > 0) {
      setAgentList(data);
    }
  }, [data?.length]);

  const onDelete = () => {
    const deleteAgent = () => {
      const tableData = agentList.filter((item: any) => !selectAgent.includes(item));
      if (tableData?.length < 1) {
        setHasError(false);
      }
      setAgentList(tableData);
      for (const i in agentList) {
        if (agentList[i].conflictStatus === true && agentList[i]?.isOverride !== true) {
          setHasError(true);
          break;
        }
        setHasError(false);
      }
      actionRef.current?.reload();
    };
    ModalService.confirm({
      title: Translation('remove_agent'),
      description: Translation('remove_agent_des'),
      onConfirm: () => {
        deleteAgent();
      },
      confirmText: Translation('button_confirm'),
      cancelText: Translation('button_cancel'),
    });
  };

  const onOverride = () => {
    const overrideAgent = () => {
      let tableData: any[] = agentList;
      for (const i in selectAgent) {
        let index = agentList.findIndex((item) => {
          return item.agentCode === selectAgent[i].agentCode;
        });
        if (index !== -1) {
          tableData[index] = { ...tableData[index], isOverride: true };
        }
      }
      setAgentList(tableData);
      for (const i in agentList) {
        if (agentList[i].conflictStatus === true && agentList[i]?.isOverride !== true) {
          setHasError(true);
          break;
        }
        setHasError(false);
      }
      actionRef.current?.reload();
    };
    ModalService.confirm({
      title: Translation('override_agent'),
      description: Translation('override_agent_des'),
      onConfirm: () => {
        overrideAgent();
      },
      confirmText: Translation('button_confirm'),
      cancelText: Translation('button_cancel'),
    });
  };

  const transformAgentCode = (list: string[]) => {
    return list.map((agentCode) => {
      if (agentCode.length < 8) {
        return agentCode.padStart(8, '0');
      } else {
        return agentCode;
      }
    });
  };

  const convertList = (data: any[]) => {
    let tempData = data;
    tempData.shift();
    tempData = flatten(tempData);
    tempData = tempData.map((item: any) => String(item));
    tempData = transformAgentCode(tempData);
    return tempData;
  };

  const getTotalAgentList = (inputList: string[]) => {
    const agentStringList = agentList?.map((item: any) => item?.agentCode);
    const newAgentStringList = difference(inputList, agentStringList);
    let totalAgentList = [];
    if (newAgentStringList && newAgentStringList.length > 0) {
      const newAgentList = newAgentStringList.map((item) => {
        return { agentCode: item };
      });
      totalAgentList = agentList?.concat(newAgentList);
      totalAgentList = totalAgentList.sort((itemA: { agentCode: string }, itemB: { agentCode: string }) => {
        return Number(itemA.agentCode) - Number(itemB.agentCode);
      });
      return totalAgentList;
    } else {
      return inputList;
    }
  };

  const handleFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      try {
        setIsloading(true);
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = async (e) => {
          const fileData = e.target?.result;
          const workBook = read(fileData);
          const workSheetName = workBook.SheetNames[0];
          const workSheet = workBook.Sheets[workSheetName];
          const data = utils.sheet_to_json(workSheet, { header: 1 });
          let newAgentList = convertList(data);
          if (newAgentList.length > 0) {
            newAgentList = newAgentList.filter((item) => {
              return agentList.every((oldItem) => item !== oldItem.agentCode);
            });
          }
          const totalAgentList: any[] = getTotalAgentList(newAgentList);
          if (totalAgentList.length > 0) {
            try {
              const totalAgentStringList: any[] = totalAgentList.map((item: any) => item.agentCode);
              let params: any = { agents: totalAgentStringList };
              if (isEdit) {
                params = { ...params, ruleSetId: id };
              }
              const res = await checkTargetAgentList(params);
              const conflictList = res?.data ?? [];
              if (conflictList.length > 0) {
                setHasError(true);
                conflictList.map((agentCode: string) => {
                  let index = totalAgentStringList.indexOf(agentCode);
                  if (index !== -1) {
                    totalAgentList[index] = { agentCode: totalAgentList[index].agentCode, conflictStatus: true };
                  }
                });
              }
              setAgentList(totalAgentList);
              actionRef.current?.reload();
            } catch (err) {
              console.log(err);
            }
          }
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: Translation('global.submit.success'),
                content: `${Translation('common.upload.file.agentList')} - ${file.name}`,
              },
            ]),
          );
        };
        if (e.target.files) {
          reader.readAsArrayBuffer(file);
        }
      } finally {
        setIsloading(false);
      }
    }
  };

  return (
    <div style={commonStyles.targetArea}>
      <span>{Translation('pulseleads.activityPoint.ruleSettings.common.targetAgent')}</span>
      <span style={commonStyles.colorRed}>*</span>

      {defaultAgent && (
        <span style={commonStyles.ml70}>{Translation('pulseleads.activityPoint.ruleSettings.common.allAgent')}</span>
      )}

      {!defaultAgent && (
        <Form.Item name="agentList" required={true} rules={[DEFAULT_REQUIRED_RULES]}>
          {(_) => {
            return (
              <div style={commonStyles.uploadArea}>
                <span>
                  <div style={commonStyles.upload}>
                    <input
                      id="upload-agent-file"
                      hidden
                      type="file"
                      accept=".xlsx"
                      onClick={(e) => {
                        const element = e.target as HTMLInputElement;
                        element.value = '';
                      }}
                      onChange={handleFile}
                    />
                    <div className={detailClass.rowContainer}>
                      <Button
                        // disabled={!enableDraftUpdate && !enableActiveUpdate}
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          document.getElementById('upload-agent-file')!.click();
                        }}
                      >
                        {Translation('app.button.add')}
                      </Button>
                      {agentList.length > 0 && (
                        <span>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                              onDelete();
                            }}
                            size="small"
                            style={commonStyles.ml20}
                          >
                            {Translation('app.button.delete')}
                          </Button>

                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                              onOverride();
                            }}
                            size="small"
                            style={commonStyles.ml20}
                          >
                            {Translation('pulseleads.activityPoint.ruleSettings.button.override')}
                          </Button>
                        </span>
                      )}
                      <Button color="secondary" onClick={newTemplate} style={commonStyles.ml20}>
                        {Translation('pulseleads.eligibilityRule.form.downloadTemplate')}
                      </Button>
                    </div>
                  </div>
                </span>

                {hasError && <div style={commonStyles.errorMsg}>{Translation('operate_below_errors')}</div>}

                <div style={commonStyles.ml120}>
                  {agentList.length > 0 && (
                    <AgentsTable data={agentList} form={form} setSeletAgent={setSeletAgent} actionRef={actionRef} />
                  )}
                </div>
              </div>
            );
          }}
        </Form.Item>
      )}
    </div>
  );
};

export default TargetAgent;
