import React from 'react';
import { getDefaultDisplayDate } from 'src/app/common/utils';
import { displayPublishStatus } from './utils';

interface tableConfigType {
  title: string;
  prop: string;
  align?: any;
  render?: any;
  minWidth?: number;
  sorter?: boolean | false;
}

const tableConfig = (Translation: any, locale: any): tableConfigType[] => {
  return [
    {
      title: Translation('component.formLabel.name'),
      prop: `row.name`,
      render: (row: any) => <span>{row.name}</span>,
    },
    {
      title: Translation('component.formLabel.publish-status'),
      prop: 'publishStatus',
      render: (row: any) => <span>{displayPublishStatus(row.publishStatus, Translation)?.label}</span>,
    },
    {
      title: Translation('component.formLabel.last-updated-time'),
      // prop: 'modifyTime',
      prop: 'updatedAt',
      render: (row: any) => <span>{row.updatedAt ? getDefaultDisplayDate(row.updatedAt, 'datetime') : '-'}</span>,
    },
    {
      title: Translation('component.formLabel.last-updated-by'),
      // prop: 'modifier'
      prop: 'updatedBy',
    },
  ];
};

export default tableConfig;
