import { Dispatch } from 'react';
import { forEach } from 'lodash';
import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import { PaginateList } from 'src/app/common/types/common-types';
import { getDayStart, getDayEnd } from 'src/app/common/utils';
import {
  CampaignTypeDropdownEnum,
  CampaignTypeStatusEnum,
  CampaignTypeItem,
  CampaignTypeDropdownList,
  EligibilityRuleOption,
  ApprovalRoleOptionList,
  SectionItem,
  CreatorSettingItem,
  ApprovalSettingItem,
  SectionFieldItem,
  AddLeadSettingItem,
  SendDocumentSettingItem,
} from 'src/app/modules/AgencyCampaign/types/campaign-type-types';
import { ResourceItem } from '../types/approval-types';

const campaignTypeEndpoint = 'agency-campaign-type';

export type CampaignTypeListParam = {
  name?: string;
  status?: string;
  updatedBy?: string;
  createdDateFrom?: Date | null;
  createdDateTo?: Date | null;
  updatedDateFrom?: Date | null;
  updatedDateTo?: Date | null;
  page?: number;
  limit?: number;
};

export type CampaignTypeDropdownListParam = {
  type: CampaignTypeDropdownEnum[];
  parent?: string;
};

export type CreateCampaignTypeBody = {
  name: string;
  description: string;
  code: string;
  agentLeadSource: string;
  agentLeadSubSource: string[];
  createdBy: string;
  updatedBy: string;
};

export type UpdateCampaignTypeBody = {
  name?: string;
  description?: string;
  code?: string;
  status?: CampaignTypeStatusEnum;
  agentLeadSource?: string;
  agentLeadSubSource?: string[];
  sections?: SectionItem[];
  creatorSetting?: CreatorSettingItem;
  approvalSetting?: ApprovalSettingItem[];
  addLeadSetting?: AddLeadSettingItem;
  sendDocumentSetting?: SendDocumentSettingItem;
  updatedBy: string;
};

export const fetchCampaignTypeList = async (
  params: CampaignTypeListParam,
  sortKeys: { key: string; value?: string }[],
  dispatch?: Dispatch<any>,
): Promise<PaginateList<CampaignTypeItem>> => {
  let queryPath = `${ADMIN_URL}/${campaignTypeEndpoint}?`;
  forEach(params, (param, key) => {
    if (typeof param === 'object') {
      if (param) {
        if (key.toLowerCase().includes('from')) {
          queryPath += `${key}=${encodeURIComponent(getDayStart(param).toISOString())}&`;
        }
        if (key.toLowerCase().includes('to')) {
          queryPath += `${key}=${encodeURIComponent(getDayEnd(param).toISOString())}&`;
        }
      }
    } else {
      if (key === 'page') {
        queryPath += `${key}=${encodeURIComponent(param as any)}&`;
      } else {
        queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
      }
    }
  });

  //Query for sorting
  let sortingCount = 0;
  queryPath += `sortBy=`;
  forEach(sortKeys, (sortParam, key) => {
    if (sortParam.value) {
      queryPath += `${sortingCount === 0 ? `` : `,`}${encodeURIComponent(sortParam.key)}:${encodeURIComponent(
        sortParam.value,
      )}`;
      sortingCount++;
    }
  });
  if (sortingCount === 0) {
    queryPath += `updatedAt:desc`;
  }

  return apiClient
    .get<PaginateList<CampaignTypeItem>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchCampaignTypeItem = async (id: string, dispatch?: Dispatch<any>): Promise<CampaignTypeItem> => {
  return apiClient
    .get<CampaignTypeItem>(`${ADMIN_URL}/${campaignTypeEndpoint}/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchCampaignTypeDropdownList = async (
  params: CampaignTypeDropdownListParam,
  dispatch?: Dispatch<any>,
): Promise<CampaignTypeDropdownList> => {
  return apiClient
    .get<CampaignTypeDropdownList>(`${ADMIN_URL}/${campaignTypeEndpoint}/dropdown`, { params })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchEligibilityRuleOptionList = async (dispatch?: Dispatch<any>): Promise<EligibilityRuleOption[]> => {
  return apiClient
    .get<EligibilityRuleOption[]>(`${ADMIN_URL}/${campaignTypeEndpoint}/eligibility-rule-option-list`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchResources = async (id: string, dispatch?: Dispatch<any>): Promise<ResourceItem[]> => {
  return apiClient
    .get<ResourceItem[]>(`${ADMIN_URL}/${campaignTypeEndpoint}/${id}/resources`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchApprovalRoleOptionList = async (dispatch?: Dispatch<any>): Promise<ApprovalRoleOptionList> => {
  // return {
  //   administratorOfficerRoles: [
  //     'GHKRHO-PCA-COE-PF-PORTAL-ACM-AS',
  //     'GHKRHO-PCA-COE-PF-PORTAL-ACM-LM',
  //     'GHKRHO_PCA-COE-PF-PORTAL-ACM-BDD',
  //     'GHKRHO_PCA-COE-PF-PORTAL-ACM-BDM',
  //   ],
  //   agentRoles: ['AL', 'FC'],
  // };
  return apiClient
    .get<ApprovalRoleOptionList>(`${ADMIN_URL}/${campaignTypeEndpoint}/approval-role-option-list`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createCampaignType = async (
  body: CreateCampaignTypeBody,
  dispatch?: Dispatch<any>,
): Promise<CampaignTypeItem> => {
  return apiClient
    .post<CampaignTypeItem>(`${ADMIN_URL}/${campaignTypeEndpoint}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const modifyCampaignType = async (
  id: string,
  body: UpdateCampaignTypeBody,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .patch<void>(`${ADMIN_URL}/${campaignTypeEndpoint}/${id}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const getLatestTemplate = async (dispatch?: Dispatch<any>): Promise<any> => {
  return apiClient
    .get(`${ADMIN_URL}/${campaignTypeEndpoint}/templateValue`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
