import { utils, writeFile } from 'xlsx';

export const newTemplate = () => {
  const exp = [['10001']];
  const worksheet = utils.json_to_sheet(exp);
  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, worksheet, 'AgentCode');
  utils.sheet_add_aoa(worksheet, [['AgentCode']], { origin: 'A1' });
  writeFile(workbook, 'new template.xlsx');
};
