import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { useDataProvider } from 'src/app/common/utils';

import InsightSummary from '../components/insight/InsightSummary';
import InsightSummaryList from '../components/insight/InsightSummaryList';
import {
  getInsuranceSummaryInsight,
  getInsuranceSummaryStatistic,
  InsuranceSummaryInsightParm,
} from '../network/insuranceSummaryCrud';
import { SummaryStatistic, PaginateList, InsightListItem } from '../type/insurance-summary-types';
export const InsuranceSummaryFilterKeys = ['id', 'startDateFrom', 'startDateTo', 'endDateFrom', 'endDateTo'];

type InsuranceSummaryInsightPageProps = Pick<RouteComponentProps, 'history'> & ParamsProps;
const initialState: InsuranceSummaryInsightParm = {
  page: 1,
  limit: 5,
};

const paramsInitiator = (initalParms?: Record<string, string>): InsuranceSummaryInsightParm => {
  return initalParms ? { page: 1, limit: 5 } : initialState;
};
const InsuranceSummaryInsightPage: FC<InsuranceSummaryInsightPageProps> = ({
  history,
  initialParams,
  onChangeQueryParams,
}) => {
  const dispatch = useDispatch();

  const [insightStatistic, setInsightStatistic] = useState<SummaryStatistic>();
  const [filterState, setFilterState] = useState<InsuranceSummaryInsightParm>(paramsInitiator(initialParams));
  const [insuranceSummary, setInsuranceSummary] = useState<PaginateList<InsightListItem>>();
  const { isLoading, refreshData } = useDataProvider<PaginateList<InsightListItem>>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await getInsuranceSummaryInsight(filterState, dispatch);
      } catch (err) {}
    },
    setInsuranceSummary,
    false,
  );
  const reloadData = async () => {
    try {
      const insight = await getInsuranceSummaryStatistic(dispatch);

      setInsightStatistic(insight);
    } catch (e) {
      history.push(NOT_FOUND_ERROR_PAGE_PATH);
    } finally {
    }
  };

  React.useEffect(() => {
    reloadData();
    // refreshData();
  }, []);

  return (
    <>
      {insightStatistic && <InsightSummary summary={insightStatistic} />}

      <InsightSummaryList
        insightList={insuranceSummary}
        isLoading={isLoading}
        onRefresh={refreshData}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default InsuranceSummaryInsightPage;
