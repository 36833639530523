import { makeStyles } from 'tss-react/mui';
import { Drawer, Theme } from '@mui/material';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { ModuleContext } from 'src/app/common/module/ModuleProvider';
import { ASIDE_WIDTH, COLLAPSE_ASIDE_WIDTH, RESPONSIVE_WIDTH } from '../../const';
import NestedMenu from './components/nested-menu';
import AsideHeader from './components/aside-header';
import { useProjectDispatch, useProjectSelector } from 'src/redux/store';
import { toggleShowMobileSideMenu } from 'src/redux/layout/layout-reducer';
import { useListenerResize } from '../../hooks/use-listener-resize';

function Aside() {
  const { isAsideMenuCollapse, showMobileSideMenu } = useProjectSelector((state) => state.layout);
  const dispatch = useProjectDispatch();
  const { classes, cx } = useStyles();
  const { moduleMap } = useContext(ModuleContext);
  const [isExpand, setIsExpand] = useState(false); // When the mouse is moved over the menu, the entire menu will be expanded.
  const [showWebMenu, setShowWebMenu] = useState(window.innerWidth > RESPONSIVE_WIDTH ? true : false);

  useEffect(() => {
    if (isAsideMenuCollapse) {
      setIsExpand(false);
    }
  }, [isAsideMenuCollapse]);

  const onClose = useCallback(() => {
    dispatch(toggleShowMobileSideMenu());
  }, [dispatch]);

  const handleResize = useCallback(() => {
    const windowWidth = window.innerWidth;
    if (windowWidth <= RESPONSIVE_WIDTH && showWebMenu) {
      setShowWebMenu(false);
      setIsExpand(false);
    }
    if (windowWidth > RESPONSIVE_WIDTH && !showWebMenu) {
      setShowWebMenu(true);
    }
  }, [showWebMenu]);

  useListenerResize(handleResize);

  const handleMouseEnter = useCallback(() => {
    if (isAsideMenuCollapse) {
      setIsExpand(true);
    }
  }, [isAsideMenuCollapse]);

  const handleMouseLeave = useCallback(() => {
    if (isAsideMenuCollapse) {
      setIsExpand(false);
    }
  }, [isAsideMenuCollapse]);

  const commonClasses = useMemo(() => {
    return {
      [classes.drawerOpen]: !isAsideMenuCollapse,
      [classes.drawerClose]: isAsideMenuCollapse,
      [classes.drawerExpand]: isExpand && isAsideMenuCollapse,
    };
  }, [classes, isAsideMenuCollapse, isExpand]);

  return (
    <Drawer
      variant={showWebMenu ? 'permanent' : 'temporary'}
      open={showWebMenu || showMobileSideMenu}
      onClose={onClose}
      className={cx(classes.drawer, commonClasses)}
      classes={{
        paper: cx(classes.drawerPaper, commonClasses),
      }}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      onMouseLeave={handleMouseLeave}
    >
      <AsideHeader isExpand={isExpand} />
      <div className={classes.menuList} onMouseEnter={handleMouseEnter}>
        <PerfectScrollbar className={classes.scrollbar}>
          <NestedMenu menuList={moduleMap} isExpand={isExpand} />
        </PerfectScrollbar>
      </div>
    </Drawer>
  );
}

export default Aside;

const useStyles = makeStyles()((theme: Theme) => ({
  drawer: {
    width: ASIDE_WIDTH,
    position: 'fixed',
    zIndex: 999,
  },
  drawerPaper: {
    width: ASIDE_WIDTH,
    backgroundColor: '#1E1E2D',
  },
  drawerOpen: {
    width: ASIDE_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: COLLAPSE_ASIDE_WIDTH,
  },
  drawerExpand: {
    width: ASIDE_WIDTH,
  },
  menuList: {
    backgroundColor: '#1E1E2D',
    paddingTop: 13,
    paddingBottom: 13,
    flex: 1,
    overflow: 'hidden',
    width: '100%',
  },
  scrollbar: {
    width: '100%',
    height: '100%',
    position: 'relative',
    '& .ps__rail-y': {
      width: 4,
      '& .ps__thumb-y': {
        width: 4,
        background: '#637099',
      },
    },
    '& .ps__rail-y.ps--clicking': {
      backgroundColor: 'transparent',
    },
    '& .ps__rail-y:hover': {
      backgroundColor: 'transparent',
    },
    '& .ps__rail-y:focus': {
      backgroundColor: 'transparent',
    },
  },
}));
