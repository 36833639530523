import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import PruRoute from 'src/app/common/components/PruRoute';
import ParamsProvider from 'src/app/common/components/ParamsProvider';
import { INSIGHTS_BASE_PATH, INSIGHTS_DASHBOARD_PATH, INSIGHTS_DASHBOARD_REGION_PATH } from '../../../constants';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import RegionReportListingPage, { regionReportFilterKeys } from './List/RegionReportListingPage';

const regionReportPath = `${INSIGHTS_BASE_PATH}${INSIGHTS_DASHBOARD_PATH}${INSIGHTS_DASHBOARD_REGION_PATH}`;

const RegionReportRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect exact from={`${regionReportPath}`} to={`${regionReportPath}/list`} />
      <PruRoute
        exact
        path={`${regionReportPath}/list`}
        render={(props) => (
          <ParamsProvider {...props} acceptKeys={regionReportFilterKeys}>
            <RegionReportListingPage />
          </ParamsProvider>
        )}
      />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default RegionReportRoutes;
