import React, { FC, useState } from 'react';
import { ParamsProps } from '../../../../../common/components/ParamsProvider';
import {
  FeedbackResultListParam,
  fetchFeedbackResultList,
  fetchFeedbackCategoryType,
} from '../../../network/feedbackCrud';
import { useDataProvider, getDayStart, getDayEnd } from 'src/app/common/utils';
import { FeedbackResultPaginateList } from '../../../types/feedback-type';
import { useDispatch } from 'react-redux';
import FeedbackResultList from './components/FeedbackResultList';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { useIntl } from 'react-intl';
import { useServerLocale } from 'src/app/i18n';

type FeedbackListingPageProps = {} & ParamsProps;

const initialState: FeedbackResultListParam = {
  from: null,
  to: null,
  category: '',
  subCategory: '',
  responsibleParty: '',
  agentCode: '',
  limit: 5,
  page: 1,
};

const paramsInitiator = (initialParams?: Record<string, string>): FeedbackResultListParam => {
  return initialParams
    ? {
        from: initialParams.from ? getDayStart(new Date(initialParams.from)) : null,
        to: initialParams.to ? getDayEnd(new Date(initialParams.to)) : null,
        category: initialParams.category ?? '',
        subCategory: initialParams.subCategory ?? '',
        responsibleParty: initialParams.responsibleParty ?? '',
        agentCode: initialParams.agentCode ?? '',
        page: 1,
        limit: 5,
      }
    : initialState;
};

export const feedbackResultFilterKeys = ['from', 'to', 'category', 'subCategory', 'responsibleParty', 'agentCode'];

const FeedbackListingPage: FC<FeedbackListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const locale = useServerLocale();
  const dispatch = useDispatch();

  const [filterState, setFilterState] = useState<FeedbackResultListParam>(paramsInitiator(initialParams));

  const [feedbackResultList, setFeedbackResultList] = useState<FeedbackResultPaginateList>();
  const { isLoading, refreshData } = useDataProvider<FeedbackResultPaginateList>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchFeedbackResultList(filterState, dispatch);
      } catch (err) {}
    },
    setFeedbackResultList,
    false,
  );

  return (
    <>
      <PruFilter
        title={Translation('feedbackResult.filter')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'agentCode',
            initialValue: filterState.agentCode,
            displayName: Translation('feedbackResult.common.agentCode'),
          },
          {
            type: PruFilterItemType.ASYNC_DROPDOWN,
            style: { width: 180 },
            field: 'category',
            initialValue: filterState.category,
            displayName: Translation('feedbackResult.common.category'),
            fetchList: async () => {
              const res = await fetchFeedbackCategoryType({}, dispatch);
              return res.map((item) => ({
                displayName: item[locale] || '-',
                value: item[locale] || '-',
              }));
            },
          },
          {
            type: PruFilterItemType.ASYNC_DROPDOWN,
            style: { width: 180 },
            field: 'subCategory',
            initialValue: filterState.subCategory,
            displayName: Translation('feedbackResult.common.subCategory'),
            enableIfExist: ['category'],
            fetchList: async () => {
              const res = await fetchFeedbackCategoryType(
                {
                  category: filterState.category,
                },
                dispatch,
              );
              return res.map((item) => ({
                displayName: item[locale] || '-',
                value: item[locale] || '-',
              }));
            },
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'responsibleParty',
            initialValue: filterState.responsibleParty,
            displayName: Translation('feedbackResult.common.responsibleParty'),
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'from',
            fieldTo: 'to',
            initialDateFrom: filterState.from,
            initialDateTo: filterState.to,
            displayName: Translation('feedbackResult.common.submitDate'),
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            from: data.from,
            to: data.to,
            category: data.category,
            subCategory: data.subCategory,
            responsibleParty: data.responsibleParty,
            agentCode: data.agentCode,
          })
        }
        fetchData={refreshData}
      />
      <FeedbackResultList
        isLoading={isLoading}
        feedbackResultList={feedbackResultList}
        onRefresh={refreshData}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default FeedbackListingPage;
