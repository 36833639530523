import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import { Dispatch } from 'react';

export class I18nData<T> {
  'en': T;
  [locale: string]: T;
}

export type RuleNameItem = {
  type: string;
  name: I18nData<string>;
  description: any;
  edited?: boolean;
};

export type ruleSettingListItem = {
  _id: string;
  name: string;
  isActive: boolean;
  updatedAt: Date;
  updatedBy: string;
  isDefault: boolean;
};

const leadEndpoint = 'leads';

export const fetchRuleNameList = async (dispatch?: Dispatch<any>): Promise<any> => {
  let queryPath = `${ADMIN_URL}/activity-point/rule-name`;
  return apiClient
    .get<any>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchRuleSettingList = async (params: any): Promise<any> => {
  let queryPath = `${ADMIN_URL}/activity-point/rule-settings/list`;
  return apiClient
    .get<any>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err);
    });
};

export const deleteRuleSetting = async (id: string, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .delete<void>(`${ADMIN_URL}/activity-point/rule-settings/delete/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const updateRuleNameList = async (editedList: RuleNameItem[]): Promise<void> => {
  let queryPath = `${ADMIN_URL}/activity-point/rule-name`;
  return apiClient
    .patch<void>(queryPath, editedList)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err);
    });
};

export const getMarketingCampaignLeadLimitRule = async (dispatch?: Dispatch<any>): Promise<any> => {
  return apiClient
    .get(`${ADMIN_URL}/leads/lead-limit-rule`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const updateMarketingCampaignLeadLimitRule = async (body: any, dispatch?: Dispatch<any>): Promise<any> => {
  return apiClient
    .put<void>(`${ADMIN_URL}/leads/lead-limit-rule`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
