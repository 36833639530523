import { Dispatch } from 'react';
import { forEach } from 'lodash';
import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import {
  InsuranceSummaryBody,
  InsuranceSummaryInsightResult,
} from 'src/app/modules/sales-kit/pages/insurance-summary/type/insurance-summary-types';

const insurance_summary_Endpoint = 'insurance-summaries';
export type InsuranceSummaryInsightParm = {
  limit: number;
  page: number;
};

//--------------------get insurance summary---------------//
export const fetchInsuranceConfig = async (dispatch?: Dispatch<any>): Promise<any> => {
  return apiClient
    .get<any>(`${ADMIN_URL}/${insurance_summary_Endpoint}/latest`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const submitInsuranceSummary = async (body: InsuranceSummaryBody, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .post<void>(`${ADMIN_URL}/${insurance_summary_Endpoint}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const getInsuranceSummaryInsight = async (
  params: InsuranceSummaryInsightParm,
  dispatch?: Dispatch<any>,
): Promise<any> => {
  let queryPath = `${ADMIN_URL}/${insurance_summary_Endpoint}/insight?`;

  forEach(params, (param, key) => {
    if (key === 'page') {
      queryPath += `${key}=${encodeURIComponent(param as any)}&`;
    } else {
      queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
    }
  });
  return apiClient
    .get<any>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const getInsuranceSummaryStatistic = async (dispatch?: Dispatch<any>): Promise<any> => {
  return apiClient
    .get<InsuranceSummaryInsightResult>(`${ADMIN_URL}/${insurance_summary_Endpoint}/insight/statistic`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const downloadInsuracneSummaryResults = async (dispatch?: Dispatch<any>): Promise<string> => {
  return apiClient
    .get<string>(`${ADMIN_URL}/${insurance_summary_Endpoint}/insight/download`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
