import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import { RootState } from 'src/redux/store';
import { AuthenticationState } from 'src/app/modules/Auth/_redux/authSlice';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { deepCopy } from 'src/app/modules/AgencyCampaign/utils/common-utils';
import PageFooter from 'src/app/modules/AgencyCampaign/pages/CampaignType/Form/components/PageFooter';
import {
  CampaignTypeFormPageProps,
  useStyles,
} from 'src/app/modules/AgencyCampaign/pages/CampaignType/Form/CampaignTypeDetailPage';
import {
  CampaignTypeFormMode,
  EligibilityRuleOption,
  FieldValueItem,
} from 'src/app/modules/AgencyCampaign/types/campaign-type-types';
import { UpdateCampaignTypeBody } from 'src/app/modules/AgencyCampaign/network/campaignTypeCrud';
import Section from './components/Section/Section';
import SelectFieldDialog from './components/Dialog/SelectField/SelectFieldDialog';
import EditFieldDialog from './components/Dialog/EditField/EditFieldDialog';

type CampaignTypeFormPageTwoProps = {
  eligibilityRuleOptionList?: EligibilityRuleOption[];
} & CampaignTypeFormPageProps;

type SelectFieldDialogState = {
  open: boolean;
  sectionIndex: number | undefined;
};

type EditFieldDialogState = {
  open: boolean;
  sectionIndex: number | undefined;
  fieldIndex: number | undefined;
};

const CampaignTypeFormPageTwo: FC<CampaignTypeFormPageTwoProps> = ({
  formMode,
  campaignTypeItem,
  eligibilityRuleOptionList,
  renderProgressBar,
  onPrevPage = () => {},
  onNextPage = () => {},
  onCancel,
  onShowCancelDialog,
  onSubmit,
}) => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const { classes: commonClasses } = useCommonStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { user } = useSelector<RootState, AuthenticationState>((state) => state.auth);

  const [sections, setSections] = useState(() =>
    campaignTypeItem?.sections ? deepCopy(campaignTypeItem.sections) : [],
  );
  const [selectFieldDialogState, setSelectFieldDialogState] = useState<SelectFieldDialogState>({
    open: false,
    sectionIndex: undefined,
  });
  const [editFieldDialogState, setEditFieldDialogState] = useState<EditFieldDialogState>({
    open: false,
    sectionIndex: undefined,
    fieldIndex: undefined,
  });

  const onSave = async (toNextPage: boolean = false) => {
    const tncFieldValue = sections
      .find((section) => section.key === 'participant')
      ?.sectionFields.find((field) => field.key === 'tnc')?.value as FieldValueItem[];
    if (tncFieldValue.length === 0) {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.ERROR,
            title: 'Error',
            content: 'At least one file is needed for Terms and Conditions in Participant section',
          },
        ]),
      );
    } else {
      const body: UpdateCampaignTypeBody = {
        sections,
        updatedBy: user?.username || 'Default',
      };
      await onSubmit(body);
      if (toNextPage) {
        onNextPage();
      } else {
        onCancel();
      }
    }
  };

  return (
    <>
      {selectFieldDialogState.sectionIndex !== undefined && (
        <SelectFieldDialog
          sections={sections}
          sectionIndex={selectFieldDialogState.sectionIndex}
          fieldPool={campaignTypeItem?.fieldPool || []}
          open={selectFieldDialogState.open}
          handleClose={() => setSelectFieldDialogState({ open: false, sectionIndex: undefined })}
          setSections={setSections}
        />
      )}

      {editFieldDialogState.sectionIndex !== undefined && editFieldDialogState.fieldIndex !== undefined && (
        <EditFieldDialog
          sections={sections}
          sectionIndex={editFieldDialogState.sectionIndex}
          fieldIndex={editFieldDialogState.fieldIndex}
          fieldPool={campaignTypeItem?.fieldPool || []}
          eligibilityRuleOptionList={eligibilityRuleOptionList || []}
          open={editFieldDialogState.open}
          handleClose={() => setEditFieldDialogState({ open: false, sectionIndex: undefined, fieldIndex: undefined })}
          setSections={setSections}
        />
      )}

      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <div className={classes.headerRowContainer}>
            <div className={commonClasses.header}>
              {Translation(
                `agencyCampaign.campaignType.form.title.${
                  formMode === CampaignTypeFormMode.CREATE ? 'create' : 'edit'
                }`,
              )}
            </div>
          </div>
          {renderProgressBar()}
        </div>

        {sections.map((section, sectionIndex) => (
          <Section
            sections={sections}
            sectionIndex={sectionIndex}
            key={section.key}
            title={Translation(`agencyCampaign.section.${section.key}`)}
            priority={section.priority}
            fieldPool={campaignTypeItem?.fieldPool || []}
            setSections={setSections}
            setSelectFieldDialogState={setSelectFieldDialogState}
            setEditFieldDialogState={setEditFieldDialogState}
          />
        ))}

        <PageFooter onPrevPage={onPrevPage} onCancel={onShowCancelDialog} onSave={onSave} />
      </div>
    </>
  );
};

export default CampaignTypeFormPageTwo;
