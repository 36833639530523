export const colors = [
    {
        fontColor: '#FF7927',
        bgColor: '#FFE0CD', 
    },
    {
        fontColor: '#21BA5E',
        bgColor: '#D0F6DF',
    },
    {
        fontColor: '#3994F2',
        bgColor: '#D2E8FF',
    },
    {
        fontColor: '#9983DC',
        bgColor: '#D2C8F3',
    },
    {
        fontColor: '#FF77A8',
        bgColor: '#FFBFD6',
    },
    {
        fontColor: '#FFC959',
        bgColor: '#FCE8BE',
    },
    {
        fontColor: '#009CBD',
        bgColor: '#DBF1F9'
    },
    {
        fontColor: '#82ECFB',
        bgColor: '#CBF7FD',
    },
    {
        fontColor: '#609FFF',
        bgColor: '#AECFFF',
    },
    {
        fontColor: '#CBCCE1',
        bgColor: '#E9EAFF',
    }
];

export const activeType: any = {
    'active': {
        value: 'true',
        text: 'Active'
    },
    'inactive': {
        value: 'false',
        text: 'Inactive'
    }
}

export enum categoryType {
    A = 'A',
    B = 'B',
    C = 'C',
    D = 'D',
    None ='None'
}