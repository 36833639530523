import React, { FC, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableRow, TableHead } from '@mui/material';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { useLang, I18nGenericData } from 'src/app/i18n';
import ProTableCell from 'src/app/common/components/ProTable/ProTableCell';
import { Checkbox } from '@mui/material';
import { commonStyles } from '../style';

type basicRule = {
  type: string;
  name: I18nGenericData<string>;
  checked: boolean;
};

const RuleGroup: FC<any> = ({ setRuleGroupVisible, setAllRules, allRules, ruleGroup, form, index }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { classes: commonClasses } = useCommonStyles();
  const [selectRules, setSelectRules] = useState<any[]>([]);

  const locale = useLang();
  let data = allRules || [];
  useEffect(() => {
    data = allRules;
  }, [allRules]);

  const onConfirm = (index: number) => {
    if (selectRules.length > 0) {
      refreshCheckedStatus(selectRules);
    }
    let groupList = form.getFieldValue('groupList');
    if (groupList[index].rules) {
      groupList[index].rules = groupList[index].rules.concat(selectRules);
    } else {
      groupList[index].rules = selectRules;
    }

    form.setFieldsValue({ groupList: groupList });
    setSelectRules([]);
    onClose();
  };

  const onClose = () => {
    setRuleGroupVisible(false);
  };

  const refreshCheckedStatus = (pickedRules: any[]) => {
    const idList = pickedRules?.map((item) => item?.type) || [];
    data = data?.map((item: any) => {
      if (idList.includes(item?.type)) {
        return { ...item, checked: true };
      } else {
        return item;
      }
    });
    setAllRules(data);
  };

  const handleSelectSingleRow = (event: any, row: any) => {
    if (event.target.checked) {
      const newSelectedRow = [...selectRules, row];
      setSelectRules(newSelectedRow);
      return;
    }
    const index = selectRules.indexOf(row);
    if (index > -1) {
      const newSelectedRow = [...selectRules];
      newSelectedRow.splice(index, 1);
      setSelectRules(newSelectedRow);
    }
  };

  return (
    <div>
      <Button
        variant="contained"
        color="inherit"
        style={commonStyles.backButton}
        onClick={() => {
          onClose();
        }}
      >
        {Translation('app.button.back')}
      </Button>
      <div style={commonStyles.Area}>
        <span className={commonClasses.header}>
          {Translation('pulseleads.activityPoint.ruleSettings.common.addRules')}
        </span>
        <div style={commonStyles.tableMargin}>
          <Paper elevation={0} variant="elevation">
            <TableContainer>
              <Table aria-label="input table">
                <TableHead>
                  <TableRow>
                    <ProTableCell>{''}</ProTableCell>
                    <ProTableCell>{Translation('pulseleads.activityPoint.ruleName.title')}</ProTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(data || [])?.map((row: basicRule, index: number) => {
                    return (
                      <TableRow key={row.type}>
                        <TableCell padding="checkbox" scope="medium" size="small" style={commonStyles.border}>
                          {row.checked ? (
                            <Checkbox disabled defaultChecked />
                          ) : (
                            <Checkbox onChange={(event) => handleSelectSingleRow(event, row)} />
                          )}
                        </TableCell>
                        <TableCell align="left" size="small" style={commonStyles.border}>
                          <span>{row.name[locale]}</span>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>

          <div style={commonStyles.buttonGroup}>
            <Button
              variant="contained"
              color="inherit"
              style={commonStyles.mr15}
              onClick={() => {
                onClose();
              }}
            >
              {Translation('app.button.cancel')}
            </Button>

            <Button style={commonStyles.mr15} variant="contained" color="secondary" onClick={() => onConfirm(index)}>
              {Translation('app.button.submit')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RuleGroup;
