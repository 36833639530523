import { Dispatch } from 'react';
import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import { forEach } from 'lodash';
import { PaginateList } from 'src/app/common/types/common-types';
import { ExclusionRule, ExclusionRuleItem } from '../types/exclusion-rule-types';

const exclusionRuleEndpoint = 'exclusion-rules';

export type ExclusionRuleListParam = {
  name: string;
  page: number;
  limit: number;
};

export type CreateExclusionRuleBody = {
  name: string;
  description?: string;
  enforcedBy: string;
  rules: ExclusionRuleItem[];
  isAllLead: boolean;
  daysOfAgingLead?: number;
  createdBy: string;
  updatedBy: string;
};

export type UpdateExclusionRuleBody = {
  name: string;
  description?: string;
  enforcedBy: string;
  rules: ExclusionRuleItem[];
  isAllLead: boolean;
  daysOfAgingLead?: number;
  updatedBy: string;
};

export const fetchExclusionRuleList = async (
  params: ExclusionRuleListParam,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<ExclusionRule>> => {
  let queryPath = `${ADMIN_URL}/${exclusionRuleEndpoint}?`;
  forEach(params, (param, key) => {
    if (key === 'page') {
      queryPath += `${key}=${encodeURIComponent(param as any)}&`;
    } else {
      queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
    }
  });
  return apiClient
    .get<PaginateList<ExclusionRule>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchExclusionRuleItem = async (id: string, dispatch?: Dispatch<any>): Promise<ExclusionRule> => {
  return apiClient
    .get<ExclusionRule>(`${ADMIN_URL}/${exclusionRuleEndpoint}/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createExclusionRule = async (body: CreateExclusionRuleBody, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .post<void>(`${ADMIN_URL}/${exclusionRuleEndpoint}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const modifyExclusionRule = async (
  id: string,
  body: UpdateExclusionRuleBody,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .put<void>(`${ADMIN_URL}/${exclusionRuleEndpoint}/${id}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const deleteExclusionRule = async (id: string, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .delete<void>(`${ADMIN_URL}/${exclusionRuleEndpoint}/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
