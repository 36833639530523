import React, { FC, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { LayoutSplashScreen } from 'src/app/layout';
import { Button } from '@mui/material';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import * as XLSX from 'xlsx';
import { templateFileData } from './results-template';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import { ExamResultsFileData } from '../../../types/license-exam-types';
import { TranslationWithParams, regionLocale } from 'src/app/i18n';
import { csvReader, dateFormatToServer } from '../../../utils';
import { ExamResultsEnum } from '../../../enum/license-exam.enum';
import { createLicenseExamResults } from '../../../network/license-exam-crud';
import moment from 'moment';
import { isEmpty } from 'lodash';
export interface setResultsFileDataParams {
  name: string;
  data: any[];
}

const initalResultsFileData: setResultsFileDataParams = {
  name: '',
  data: [],
};

const ExamResultsUploadPage: FC<RouteComponentProps> = ({ history }) => {
  const dispatch = useDispatch();
  const { classes: commonClasses } = useCommonStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const [resultsFileData, setResultsFileData] = useState(initalResultsFileData);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const TranslationWithVariable = (key: string, count: number | string) =>
    intl.formatMessage({ id: key }, { num: count });

  async function xlsxHandler(file: File): Promise<any[]> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = new Uint8Array(e.target?.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: 'array' });

        // get the first sheet as default work sheet
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];

        // transform the work sheet data to json
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1, raw: false });

        resolve(jsonData);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsArrayBuffer(file);
    });
  }

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const getResultsData = async (file: File) => {
    let result: ExamResultsFileData[] = [];
    try {
      const fileExtension = file?.name.split('.').pop()?.toLowerCase();
      let data;
      if (fileExtension === 'csv') {
        data = await csvReader(file);
      } else {
        data = await xlsxHandler(file);
      }
      const res = [];
      // map row header to row data
      const keys = data[0];
      for (let i = 1; i < data.length; i++) {
        const exam: any = {};
        const rowData = data[i];
        const isEmpty = rowData.every((element: any) => {
          return element === undefined || element === null || element === '';
        });
        if (isEmpty) {
          continue;
        }
        for (let j = 0; j < keys.length; j++) {
          const key = keys[j];
          const transformedKey = ExamResultsEnum[key as keyof typeof ExamResultsEnum] || key;
          exam[transformedKey] = rowData[j];
        }
        res.push(exam);
        const mandatoryFields = Object.keys(ExamResultsEnum).filter(
          (field) => !['Result', 'Score'].includes(field),
        ) as any;
        for (const field of mandatoryFields) {
          //@ts-ignore
          if (!exam[ExamResultsEnum[field]]) {
            const errMsg = TranslationWithParams('recruitment.exam.mandatory_column_missing_row', {
              field,
              row: i + 1,
            });
            throw new Error(errMsg);
          }
        }

        let startTime = moment(exam[ExamResultsEnum['Start Time']], 'HH:mm:ss');
        if (startTime.isValid()) {
          exam[ExamResultsEnum['Start Time']] = startTime.format('HH:mm');
        }
        startTime = moment(exam[ExamResultsEnum['Start Time']], 'HH:mm');
        if (!startTime.isValid()) {
          const errMsg = TranslationWithParams('recruitment.exam.data_format_error_row_file', {
            field: 'Start Time',
            row: i + 1,
          });
          throw new Error(errMsg);
        }
        let endTime = moment(exam[ExamResultsEnum['End Time']], 'HH:mm:ss');
        if (endTime.isValid()) {
          exam[ExamResultsEnum['End Time']] = endTime.format('HH:mm');
        }
        endTime = moment(exam[ExamResultsEnum['End Time']], 'HH:mm');
        if (!endTime.isValid()) {
          const errMsg = TranslationWithParams('recruitment.exam.data_format_error_row_file', {
            field: 'End Time',
            row: i + 1,
          });
          throw new Error(errMsg);
        }
        const examDate = moment(exam[ExamResultsEnum['Exam Date']], 'DD/MM/YYYY');
        if (!examDate.isValid()) {
          const errMsg = TranslationWithParams('recruitment.exam.data_format_error_row_file', {
            field: 'Exam Date',
            row: i + 1,
          });
          throw new Error(errMsg);
        }

        // checking last reg date with exam date
        const examDateString = exam[ExamResultsEnum['Exam Date']] + ' ' + exam[ExamResultsEnum['Start Time']];
        const examDatetime = moment(examDateString, 'DD/MM/YYYY HH:mm');
        const now = moment();
        if (examDatetime.isAfter(now)) {
          const errMsg = TranslationWithParams('recruitment.exam.examDate_later_than_uploadDate', {
            row: i + 1,
          });
          throw new Error(errMsg);
        }

        // checking start/end time
        if (startTime.isAfter(endTime)) {
          const errMsg = TranslationWithParams('recruitment.exam.end_time_earlier_than_start_time', {
            row: i + 1,
          });
          throw new Error(errMsg);
        }

        // checking Present=no
        const present: string = exam[ExamResultsEnum['Present']] ?? '';
        exam[ExamResultsEnum['Present']] = present.toLocaleUpperCase() === 'YES';
        if (present.toLocaleUpperCase() !== 'YES') {
          const result = exam[ExamResultsEnum['Result']] ?? '';
          if (['PASSED', 'FAILED'].includes(result.toLocaleUpperCase())) {
            const errMsg = TranslationWithParams('recruitment.exam.exam_column_requirement_miss', {
              row: i + 1,
            });
            throw new Error(errMsg);
          }
        } else {
          const result: string = exam[ExamResultsEnum['Result']] ?? '';
          if (!['PASSED', 'FAILED'].includes(result.toLocaleUpperCase())) {
            const errMsg = TranslationWithParams('recruitment.exam.mandatory_column_missing_row', {
              field: 'Result',
              row: i + 1,
            });
            throw new Error(errMsg);
          }
        }
        exam;
      }
      //map result key to request param
      result = transformArray(res);
      setResultsFileData({
        name: file.name,
        data: result,
      });
    } catch (e: any) {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.WARNING,
            title: Translation('global.submit.fail'),
            content: e.message,
          },
        ]),
      );
    }
    return result;
  };

  const downLoadTemplate = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(templateFileData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'template.xlsx');
  };

  const checkFile = (file: any, maxSize?: number, fileTypes?: string[]) => {
    if (!file) return false;
    if (fileTypes && !fileTypes.some((item) => file?.name?.toLowerCase().endsWith(item))) {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.WARNING,
            title: Translation('global.submit.fail'),
            content: Translation('recruitment.exam.incorrect_file_type'),
          },
        ]),
      );
      return false;
    }
    if (maxSize && file.size > maxSize * 1024 * 1024) {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.WARNING,
            title: Translation('global.submit.fail'),
            content: Translation('file_size_warn_label'),
          },
        ]),
      );
      return false;
    }
    return true;
  };

  const transformArray = (originalArray: Record<string, any>[]): Record<string, any>[] => {
    return originalArray.map((obj) => {
      const transformedObj: Record<string, any> = {};
      const examNameLocal = obj?.examNameLocal;
      const examNameEnglish = obj?.examNameEnglish;
      transformedObj.examName = { en: examNameEnglish };
      if (regionLocale?.length > 1) {
        regionLocale.map((locale) => {
          if (locale !== 'en') {
            transformedObj.examName[locale] = examNameLocal;
          }
        });
      }
      for (const key in obj) {
        if (key.toLocaleLowerCase().includes('date')) {
          transformedObj[key] = dateFormatToServer(obj[key]);
        } else if (key.toLocaleLowerCase().includes('result')) {
          transformedObj[key] = obj[key].toLocaleUpperCase();
        } else transformedObj[key] = obj[key];
      }
      return transformedObj;
    });
  };

  const handleFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e?.target?.files?.[0];
      // set max size 10MB
      const maxSize = 10;
      if (!checkFile(file, maxSize, ['csv', 'xlsx', 'xls'])) {
        return;
      }
      const result = await getResultsData(file);
      if (result && !isEmpty(result)) {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: Translation('global.submit.success'),
              content: ``,
            },
          ]),
        );
      }
    }
  };

  const handleSubmit = async () => {
    await createLicenseExamResults({ payload: resultsFileData.data }, dispatch);
    history.goBack();
  };

  return (
    <>
      {isLoading ? (
        <LayoutSplashScreen />
      ) : (
        <>
          <div className="tw-relative tw-container tw-bg-white tw-p-5 tw-mb-5 tw-rounded-md tw-h-full tw-w-full">
            <div className="tw-flex tw-items-center tw-p-1">
              <div className="tw-w-full tw-flex">
                <div className={commonClasses.header}>{Translation('recruitment.exam.results.title')}</div>
              </div>
            </div>
            <div className="tw-mb-4 tw-mt-10">
              <div className="tw-flex tw-w-full">
                <span className="tw-text-base tw-font-bold tw-mr-24">
                  {Translation('recruitment.exam.results.upload')} :
                </span>
                <div>
                  <input
                    id="upload-file"
                    hidden
                    type="file"
                    accept=".csv,.xlsx,.xls"
                    onClick={(e) => {
                      const element = e.target as HTMLInputElement;
                      element.value = '';
                    }}
                    onChange={(e) => handleFile(e)}
                  />
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => document.getElementById('upload-file')!.click()}
                  >
                    {Translation('app.button.upload')}
                  </Button>
                </div>
                <div className="tw-flex tw-flex-col tw-items-start tw-ml-5">
                  <span className="tw-text-base tw-ml-2">XLS/ XLSX/ CSV format, 10MB maximum</span>
                  <Button onClick={downLoadTemplate} color="secondary">
                    <span className="tw-underline tw-font-bold">{Translation('download_template_button')}</span>
                  </Button>
                  <div className="">{resultsFileData.name || Translation('app.file.noFileChosen')}</div>
                </div>
              </div>
            </div>

            <div className="tw-absolute tw-bottom-10 tw-right-10">
              <div className="tw-space-x-4">
                <Button variant="contained" size="large" color="secondary" onClick={() => history.goBack()}>
                  {Translation('app.button.back')}
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  color="secondary"
                  disabled={!resultsFileData?.name}
                  onClick={handleSubmit}
                >
                  {Translation('app.button.save')}
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ExamResultsUploadPage;
