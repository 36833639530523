import { getDefaultDisplayDate } from 'src/app/common/utils';

export const getLeadsInfos: any = (data: any) => {
  const {
    firstName,
    lastName,
    leadSource,
    matchedProduct,
    interestedProduct,
    referredAgentId,
    referredAgentPhoneNumber,
    contactMethod,
    preferredContactDay,
    preferredContactTime,
    updatedAt,
    qualifierId,
    leadReferredSource,
    campaignId,
    leadPartnerId,
    referredAgentCode,
    referredAgentPhone,
    preferredContactMethod,
  } = data || {};
  const { zipCode, passportNumber, gender, maritalStatus, language, race, nationality, city, state } = data || {};

  return [
    {
      key: firstName,
      title: 'First Name',
    },
    {
      key: lastName,
      title: 'Last Name',
    },
    // {
    //   key: zipCode,
    //   title: 'Postal Code',
    // },
    // {
    //   key: passportNumber,
    //   title: 'Passport',
    // },
    // {
    //   key: gender,
    //   title: 'Gender',
    // },
    // {
    //   key: maritalStatus,
    //   title: 'Marital Status',
    // },
    {
      key: language,
      title: 'Preferred Language',
    },
    // {
    //   key: race,
    //   title: 'Race',
    // },
    // {
    //   key: nationality,
    //   title: 'Nationality',
    // },
    // {
    //   key: city,
    //   title: 'City',
    // },
    // {
    //   key: state,
    //   title: 'State',
    // },
    {
      key: leadSource,
      title: 'Lead Source',
    },
    {
      key: campaignId,
      title: 'Lead Campaign',
    },
    {
      key: leadPartnerId,
      title: 'Lead Partner ID',
    },
    {
      key: matchedProduct,
      title: 'Matched Product',
    },
    {
      key: interestedProduct,
      title: 'Interested Product',
    },
    {
      key: referredAgentCode,
      title: 'Referred Agent ID',
    },
    {
      key: referredAgentPhone,
      title: 'Referred Agent Phone Number',
    },
    {
      key: qualifierId,
      title: 'Lead Qualifier',
    },
    {
      key: preferredContactMethod,
      title: 'Preferred Contact Method',
    },
    {
      key: preferredContactDay,
      title: 'Preferred Contact Day',
      render: (value: any) => {
        return value ? getDefaultDisplayDate(value) : '';
      },
    },
    {
      key: preferredContactTime,
      title: 'Preferred Contact Time',
    },
    {
      key: updatedAt,
      title: 'Distributed At',
      render: (value: any) => {
        return value ? getDefaultDisplayDate(value, 'datetime') : '';
      },
    },
  ];
};
