import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import PruRoute from 'src/app/common/components/PruRoute';
import { PULSELEADS_BASE_PATH, PULSELEADS_LEAD_PATH } from '../../../../constants';
import { PULSELEADS_LEAD_FOLLOW_UP_PATH, PULSELEADS_LEAD_FOLLOW_UP_MANUAL_UPDATE_PATH } from '../constants';
import ManualUpdateForm from './components/ManualUpdateForm';

const manualUpdatePath = `${PULSELEADS_BASE_PATH}${PULSELEADS_LEAD_PATH}${PULSELEADS_LEAD_FOLLOW_UP_PATH}${PULSELEADS_LEAD_FOLLOW_UP_MANUAL_UPDATE_PATH}`;

const ManualUpdateRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <PruRoute exact path={`${manualUpdatePath}`} component={ManualUpdateForm} />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  )
};

export default ManualUpdateRoutes;