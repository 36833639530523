import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { useLang } from 'src/app/i18n';
import { useJWT } from 'src/app/common/utils';

import { getCountryCodeAndCity, getMicrositeResult, getMicrositeAnswers } from '../../../network/api';
import { convert2SurveyLocale, setCityChoices, setRegionChoices } from './util/survey-result.util';

interface HookProps {
  id: string;
  // other params
}

const defaultParam = {
  page: 1,
  pageSize: 20,
  limit: 1000,
};

export const useSurveyResult = ({ id }: HookProps) => {
  const intl = useIntl();
  const jwt = useJWT() || {};
  const Translation = (id: string) => intl.formatMessage({ id });
  const locale = useLang();
  const [isLoading, setIsLoading] = useState(false);
  const [surveyBody, setSurveyBody] = useState<any>();
  const [surveyResults, setSurveyResults] = useState<any>();
  const [surveySummaries, setSurveySummary] = useState<any>();
  const [isLoadingAnswers, setIsLoadingAnswers] = useState(true);

  // redux
  const dispatch = useDispatch();

  const initData = useCallback(() => {
    const { region, channel } = jwt;
    setIsLoading(true);
    Promise.all([getCountryCodeAndCity(region, channel), getMicrositeResult(id)])
      .then((res) => {
        const [countryCodeAndCity, micrositeResult] = res;
        const { location, regionPhone } = countryCodeAndCity;
        const { microsite, summary } = micrositeResult;
        let surveyBody = get(microsite, 'inputFieldsConfig');
        surveyBody = setCityChoices(surveyBody, location as any);
        surveyBody = setRegionChoices(surveyBody, regionPhone as any);
        surveyBody['locale'] = convert2SurveyLocale(locale);
        setSurveyBody(surveyBody);

        const summaryResult = {
          totalViews: summary?.openCount ?? 0,
          totalSubmits: summary?.submitCount ?? 0,
        };

        setSurveySummary(summaryResult);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const getAnswers = useCallback(
    async (page: number, data: any[] = []) => {
      try {
        const res = await getMicrositeAnswers(
          id,
          { pagination: { page, limit: defaultParam.limit, sort: {} } },
          dispatch,
        );
        const result = res.docs
          ? res.docs.reduce((res: Record<string, unknown>[], item: any) => {
              if (item.answers) {
                res.push(item.answers);
              }
              return res;
            }, [])
          : [];

        const newData = [...data, ...result];
        if (res.hasNextPage) {
          return getAnswers(page + 1, newData);
        } else {
          setSurveyResults(newData);
          setIsLoadingAnswers(false);
        }
      } catch (error) {
        setIsLoadingAnswers(false);
      }
    },
    [id],
  );

  useEffect(() => {
    initData();
    getAnswers(1);
  }, [id]);

  return {
    isLoading,
    surveyBody,
    surveyResults,
    surveySummaries,
    isLoadingAnswers,
  };
};
