import React, { FC, Fragment } from 'react';
import { withStyles } from 'tss-react/mui';
import { IconButton, Tooltip } from '@mui/material';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { NewsHeadlinePaginateList } from '../../../../types/news-type';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { getDefaultDisplayDate, useJWT, isPermitted } from 'src/app/common/utils';
import { NEWS_BASE_PATH, NEWS_ARTICLE_PATH, AdminNewsPermissionType } from '../../../../constants';
import { useServerLocale, getAvailableServerLocale } from 'src/app/i18n';
import { useDispatch } from 'react-redux';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { AlertType, appendAlertItem } from 'src/redux/common/commonSlice';
import { moveNewsHeadlineDownward, moveNewsHeadlineUpward } from 'src/app/modules/News/network/newsCrud';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { map } from 'lodash';

type NewsHeadlineListProps = {
  isLoading: boolean;
  newsHeadlineList?: NewsHeadlinePaginateList;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
};

const LargerFontTooltip = withStyles(Tooltip, (theme) => ({
  tooltip: {
    fontSize: 12,
  },
}));

const NewsHeadlineList: FC<NewsHeadlineListProps> = ({ isLoading, newsHeadlineList, onRefresh, onChangePage }) => {
  const locale = useServerLocale();
  const availableLocales = getAvailableServerLocale();
  const history = useHistory();
  const intl = useIntl();
  const dispatch = useDispatch();
  const Translation = (id: string) => intl.formatMessage({ id });
  const jwt = useJWT() || {};
  let updateNewsPermission = false;
  let updateHeadlinePermission = false;

  if (jwt.permissions) {
    const jwtPermission = map(jwt.permissions, (_, key) => key);
    updateNewsPermission = isPermitted([AdminNewsPermissionType.NEWS_ARTICLE_UPDATE], jwtPermission);
    updateHeadlinePermission = isPermitted([AdminNewsPermissionType.NEWS_HEADLINE_UPDATE], jwtPermission);
  }

  const copyIdClicked = async (rowId: string) => {
    if (navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(rowId);
      } catch (err) {}
    }
  };

  const onUpwardButtonClicked = async (id: string) => {
    // Call Api and move the headline row upward
    const submitData = {
      newsId: id,
    };
    try {
      await moveNewsHeadlineUpward(submitData, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Headline moved upward successfully `,
          },
        ]),
      );
      onRefresh();
    } catch (err) {}
  };

  const onDownwardButtonClicked = async (id: string) => {
    // Call Api and move the headline row downward
    const submitData = {
      newsId: id,
    };
    try {
      await moveNewsHeadlineDownward(submitData, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Headline moved downward successfully `,
          },
        ]),
      );
      onRefresh();
    } catch (err) {}
  };

  return (
    <Fragment>
      <PruTable
        title={Translation('newsHeadline.list')}
        disableBulkSelect
        operationDef={
          !updateNewsPermission && !updateHeadlinePermission
            ? []
            : [
                {
                  title: Translation('section.common.operation.edit'),
                  tooltipText: 'Edit Article Detail',
                  onClick: (row) => history.push(`${NEWS_BASE_PATH}${NEWS_ARTICLE_PATH}/view/${row.newsId}`),
                  condition: () => updateNewsPermission,
                },
                {
                  title: (
                    <IconButton size={'small'}>
                      <ArrowUpwardIcon />
                    </IconButton>
                  ),
                  tooltipText: 'Move Category Upward',
                  onClick: (row) => onUpwardButtonClicked(row.newsId),
                  condition: () => updateHeadlinePermission,
                },
                {
                  title: (
                    <IconButton size={'small'}>
                      <ArrowDownwardIcon />
                    </IconButton>
                  ),
                  tooltipText: 'Move Category Downward',
                  onClick: (row) => onDownwardButtonClicked(row.newsId),
                  condition: () => updateHeadlinePermission,
                },
              ]
        }
        columnDef={[
          {
            isId: true,
            keyIndex: 'newsId',
            align: 'center',
            displayName: Translation('component.formLabel.id'),
            renderData: (row) => (
              <LargerFontTooltip title={row.newsId} style={{ fontSize: 20 }} placement="left">
                <IconButton
                  onClick={() => {
                    copyIdClicked(row.newsId);
                  }}
                >
                  <FileCopyIcon />
                </IconButton>
              </LargerFontTooltip>
            ),
          },
          {
            keyIndex: 'category',
            align: 'center',
            displayName: Translation('newsArticle.common.category'),
            renderData: (row) => row.category?.name[locale] || '-',
          },
          {
            keyIndex: `title${availableLocales[0]}`,
            align: 'center',
            displayName: Translation(`newsArticle.common.title.${availableLocales[0]}`),
            renderData: (row) => (row.title ? row.title[availableLocales[0]] : '-') || '-',
          },
          {
            keyIndex: 'publishDate',
            align: 'center',
            displayName: Translation('newsArticle.common.publishDate'),
            renderData: (row) => (row.publishDate ? getDefaultDisplayDate(row.publishDate, 'datetime') : '-'),
          },
          {
            keyIndex: 'readCount',
            align: 'center',
            displayName: Translation('newsArticle.common.readCount'),
            renderData: (row) => String(row.readCount ? row.readCount : 0),
          },
          {
            keyIndex: 'likes',
            align: 'center',
            displayName: Translation('newsArticle.common.likes'),
            renderData: (row) => String(row.likes ? row.likes : 0),
          },
          {
            keyIndex: 'bookmarked',
            align: 'center',
            displayName: Translation('newsArticle.common.bookmarked'),
            renderData: (row) => String(row.bookmarked ? row.bookmarked : 0),
          },
          {
            keyIndex: 'comments',
            align: 'center',
            displayName: Translation('newsArticle.common.comments'),
            renderData: (row) => String(row.comments ? row.comments : 0),
          },
          {
            keyIndex: 'createdAt',
            align: 'center',
            displayName: Translation('newsArticle.common.createdAt'),
            renderData: (row) => (row.createdAt ? getDefaultDisplayDate(new Date(row.createdAt), 'datetime') : ''),
          },
          {
            keyIndex: 'updatedAt',
            align: 'center',
            displayName: Translation('newsArticle.common.updatedAt'),
            renderData: (row) => (row.updatedAt ? getDefaultDisplayDate(new Date(row.updatedAt), 'datetime') : ''),
          },
          {
            keyIndex: 'updatedBy',
            align: 'center',
            displayName: Translation('newsArticle.common.updatedBy'),
            renderData: (row) => (row.updatedBy ? row.updatedBy : ''),
          },
          {
            keyIndex: 'status',
            align: 'center',
            displayName: Translation('newsArticle.common.status'),
            renderData: (row) => (row.status ? row.status : ''),
          },
        ]}
        isLoading={isLoading}
        onRefresh={onRefresh}
        dataSource={newsHeadlineList?.newsList}
        totalPages={newsHeadlineList?.totalPages}
        totalRecords={newsHeadlineList?.totalRecords}
        onChangePage={onChangePage}
      />
    </Fragment>
  );
};

export default NewsHeadlineList;
