import { forEach } from 'lodash';
import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import { BulkUploadStatusItem, BulkUploadFailedItem } from '../types/bulk-upload-types';
import { getDayStart, getDayEnd } from 'src/app/common/utils';
import { PaginateList } from '../../../common/types/common-types';
import { Dispatch } from 'react';

const bulkUploadEndpoint = 'bulk-upload';

export type BulkUploadListParam = {
  filename?: string;
  bulkUploadType?: string;
  updatedDateFrom?: Date | null;
  updatedDateTo?: Date | null;
  page: number;
  limit: number;
};

export type CheckInvalidCampaignParam = {
  campaignIdList: string[];
  validStatusList: string[];
};

export type BulkUploadFailedListParam = {
  page: number;
  limit: number;
};

export type CreateBulkUploadFileBody = {
  resourceId?: string;
  type?: string;
  filename?: string;
  scheduledUploadTime?: Date | null;
};

export const fetchBulkUploadStatusList = async (
  params: BulkUploadListParam,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<BulkUploadStatusItem>> => {
  let queryPath = `${ADMIN_URL}/${bulkUploadEndpoint}?`;
  forEach(params, (param, key) => {
    if (typeof param === 'object') {
      if (param) {
        if (key.toLowerCase().includes('from')) {
          queryPath += `${key}=${encodeURIComponent(getDayStart(param).toISOString())}&`;
        }
        if (key.toLowerCase().includes('to')) {
          queryPath += `${key}=${encodeURIComponent(getDayEnd(param).toISOString())}&`;
        }
      }
    } else {
      if (key === 'page') {
        queryPath += `${key}=${encodeURIComponent(param as any)}&`;
      } else {
        queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
      }
    }
  });
  return apiClient
    .get<PaginateList<BulkUploadStatusItem>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchBulkUploadFailedList = async (
  resourceId: string,
  params: BulkUploadFailedListParam,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<BulkUploadFailedItem>> => {
  let queryPath = `${ADMIN_URL}/${bulkUploadEndpoint}/${resourceId}?`;
  forEach(params, (param, key) => {
    if (key === 'page') {
      queryPath += `${key}=${encodeURIComponent(param as any)}&`;
    } else {
      queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
    }
  });
  return apiClient
    .get<PaginateList<BulkUploadFailedItem>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const isBulkUploadFileExist = async (filename: string, dispatch?: Dispatch<any>): Promise<boolean> => {
  return apiClient
    .get<boolean>(`${ADMIN_URL}/${bulkUploadEndpoint}/${filename}/exist`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const checkInvalidCampaign = async (
  params: CheckInvalidCampaignParam,
  dispatch?: Dispatch<any>,
): Promise<string[]> => {
  return apiClient
    .get<string[]>(`${ADMIN_URL}/${bulkUploadEndpoint}/check-invalid-campaign`, { params })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createBulkUploadFile = async (body: CreateBulkUploadFileBody, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .post<void>(`${ADMIN_URL}/${bulkUploadEndpoint}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const downloadBulkUploadCsv = async (uploadType: string, dispatch?: Dispatch<any>): Promise<string> => {
  return apiClient
    .get<string>(`${ADMIN_URL}/${bulkUploadEndpoint}/${uploadType}/download`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
